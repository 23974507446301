import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FilterControls from '../FilterControls';
import {StringType} from '@designeo/apibundle-js';
import FilterOperations from '../FilterOperations';
import {ArrayType} from '@designeo/apibundle-js';


export const FilterValuePropDef = {
  control: {
    type: new EntityType(FilterControls),
  },
  from: {
    type: new StringType(),
  },
  operation: {
    type: new EntityType(FilterOperations),
  },
  to: {
    type: new StringType(),
  },
  values: {
    type: new ArrayType(new StringType()),
  },
};
export default class GeneratedFilterValue extends createEntity(FilterValuePropDef) {}
