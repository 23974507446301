
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'StepSafeBagOverview',
  components: {WorkflowTransitions, HLStep},
  setup() {
    const stepType = WorkflowStepTypes.SafeBagOverview;

    const {dateFormat, currencyFormat} = useFilters();

    const todayDate = computed(() => new Date());

    return {
      stepType,
      dateFormat,
      currencyFormat,
      todayDate,
    };
  },
});
