import {AbstractPromoFlow, createNewReactive} from './AbstractPromoFlow';
import {RegisterState} from './../types';
import {FollowUpDocument} from '@designeo/pos-promotion-engine/src/blocks/types';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {merge} from 'lodash-es';
import {DocumentDto} from '@/Model/Entity';
import {DocumentTypes} from '@/constants/documentTypes';

export class FollowUpDocumentFlow extends AbstractPromoFlow {
  static getNextStateByFollowUpDocument(followUpDocument: FollowUpDocument): RegisterState {
    if (followUpDocument.document.documentSubType === DocumentTypes.AutomatedFollowUpDocument) {
      return RegisterState.SELECT_PAYMENT;
    } else if (followUpDocument.document.customer) {
      return RegisterState.ENTER_ARTICLE_NUMBER;
    } else {
      return RegisterState.ENTER_CUSTOMER_CARD;
    }
  }

  constructor(
    public followUpDocument: FollowUpDocument,
    public nextState: RegisterState = FollowUpDocumentFlow.getNextStateByFollowUpDocument(followUpDocument),
  ) {
    super(nextState);
  }

  static new = createNewReactive<FollowUpDocumentFlow>()

  /**
   * While we execute the init function, pos should be in this state
   * - On register
   * - With empty init receipt
   * - With no modal opened
   */
  async init() {
    await super.init();

    const registerStore = useRegisterStore();

    merge(
      // @ts-ignore
      registerStore.sellDocument.value._data,
      this.followUpDocument.document,
      <Partial<DocumentDto['_data']>> {
        disablePrintout: true,
        printoutAsPdf: false,
        printoutAsHtml: false,
      },
    );

    await registerStore.promotionHookFollowUpDocumentInjected();

    await this.destroy();
  }

  async destroy() {
    await super.destroy();
  }
}
