import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ArticleNumberRangeDtoPropDef = {
  fromArticleNumber: {
    type: new StringType(),
  },
  toArticleNumber: {
    type: new StringType(),
  },
};
export default class GeneratedArticleNumberRangeDto extends createEntity(ArticleNumberRangeDtoPropDef) {}
