import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import LoyaltyPointsTransactionDto from '../LoyaltyPointsTransactionDto';
import CustomerAttributeDto from '../CustomerAttributeDto';
import LoyaltyPointsDto from '../LoyaltyPointsDto';
import ExternalProgramsDto from '../ExternalProgramsDto';


export const DocumentLoyaltyDtoPropDef = {
  customerNumber: {
    type: new StringType(),
  },
  scannedCustomerCardNumber: {
    type: new StringType(),
  },
  transactions: {
    type: new ArrayType(new EntityType(LoyaltyPointsTransactionDto)),
  },
  attributes: {
    type: new ArrayType(new EntityType(CustomerAttributeDto)),
  },
  points: {
    type: new ArrayType(new EntityType(LoyaltyPointsDto)),
  },
  externalPrograms: {
    type: new EntityType(ExternalProgramsDto),
  },
};
export default class GeneratedDocumentLoyaltyDto extends createEntity(DocumentLoyaltyDtoPropDef) {}
