import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const FacadeRepeatBetTicketPartnerResourceV2PropDef = {
  ticketIds: {
    type: new ArrayType(new NumberType()),
  },
  drawIds: {
    type: new ArrayType(new NumberType()),
  },
  operationId: {
    type: new StringType(),
  },
};
export default class GeneratedFacadeRepeatBetTicketPartnerResourceV2 extends createEntity(FacadeRepeatBetTicketPartnerResourceV2PropDef) {}
