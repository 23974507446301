import {
  createEntity,
  EntityType,
  ObjectType,

} from '@designeo/apibundle-js';
import {
  WarehouseOperationLogisticDocumentAttributes,
  WarehouseOperationBaseAttributes,
  WarehouseOperationPatch,
  WarehouseOperationSummaryAttributes,

} from '@/Model/Entity/patches/WarehouseOperationMixin';
import VendorDto from '@/Model/Entity/VendorDto';

export default class WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom extends WarehouseOperationPatch(
  createEntity({
    ...WarehouseOperationBaseAttributes,
    ...WarehouseOperationLogisticDocumentAttributes,
    ...WarehouseOperationSummaryAttributes,
    vendor: {
      type: new EntityType(VendorDto),
    },
  }),
) {}
