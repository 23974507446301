import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentDto from '../DocumentDto';
import ResultDto from '../ResultDto';


export const TransactionDtoPropDef = {
  pidKey: {
    type: new StringType(),
  },
  // document: {
  //   type: new EntityType(DocumentDto),
  // },
  result: {
    type: new EntityType(ResultDto),
  },
};
export default class GeneratedTransactionDto extends createEntity(TransactionDtoPropDef) {}
