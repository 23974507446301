import {onBeforeMount, onBeforeUnmount} from 'vue';
import {AuthStoreEvent} from '@/Modules/Auth/types';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useInactivityStore} from '@/Modules/Core/store/InactiveStore';
import {logoutPostProcess} from './reset';

export const useAuth = ({onLoginWatcher = true, onLogoutWatcher = true} = {}) => {
  const authStore = useAuthStore();
  const inactivityStore = useInactivityStore();

  const onLogout = async () => {
    await logoutPostProcess();
  };

  const onLogin = () => {
    inactivityStore.ensureIntervalRun();
  };

  onBeforeMount(() => {
    if (onLoginWatcher) {
      authStore.addEventListener(
        AuthStoreEvent.LOGIN,
        onLogin,
      );
    }

    if (onLogoutWatcher) {
      authStore.addEventListener(
        AuthStoreEvent.LOGOUT,
        onLogout,
      );
    }
  });

  onBeforeUnmount(() => {
    if (onLoginWatcher) {
      authStore.removeEventListener(
        AuthStoreEvent.LOGIN,
        onLogin,
      );
    }

    if (onLogoutWatcher) {
      authStore.removeEventListener(
        AuthStoreEvent.LOGOUT,
        onLogout,
      );
    }
  });
};
