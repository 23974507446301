import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerPhoneDtoPropDef = {
  phone: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerPhoneDto extends createEntity(CustomerPhoneDtoPropDef) {}
