
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {useArticleLabel} from '@/Components/ArticleLabels/label';
import ArticleLabelMixin from '@/Components/ArticleLabels/ArticleLabelMixin.vue';
import {DocumentItemDto} from '@/Model/Entity';

export default defineComponent({
  name: 'ArticleLabelItemStornoDisabled',
  mixins: [ArticleLabelMixin],
  props: {
    item: {
      type: Object as PropType<DocumentItemDto>,
      default: null,
      required: false,
    },
  },
  setup(props) {
    const {theme, label} = useArticleLabel('itemStornoDisabled');

    const isDisplayed = computed(() => props.forceVisible ?? props.item?.isArticleLabelItemStornoDisabled);

    return {
      theme,
      label,
      isDisplayed,
    };
  },
});
