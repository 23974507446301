import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  DocumentDto,
  DocumentItemDto,
  StockDto,
} from '@/Model/Entity';
import {mapKeys} from 'lodash-es';
import BalanceStockItemDtoCustom from '../../../../Model/Entity/custom/BalanceStockItemDtoCustom';
import {WorkflowStepField} from '../../types';

const STEP_STOCK_LIST_MODEL = 'stockListModel';
const STEP_ARTICLE_LIST_MODEL = 'articleListModel';
const STEP_SELL_DOCUMENT_MODEL = 'sellDocumentModel';
const STEP_ARTICLES_ORDER_MODEL = 'articlesOrderModel';

export const workflowStepMixinBalanceStock = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinBalanceStock extends (superClass as MixinBase<WorkflowStep>) {
    get sellDocument(): DocumentDto {
      const model = this.getFieldValue(STEP_SELL_DOCUMENT_MODEL, null);

      if (!model) return new DocumentDto({});

      return new DocumentDto(model);
    }

    set sellDocument(value: DocumentDto) {
      this.dataSetter(STEP_SELL_DOCUMENT_MODEL, () => value.toJson());
    }

    get stockList(): StockDto[] {
      const model = this.getFieldValue(STEP_STOCK_LIST_MODEL, null);

      if (!model) return null;

      return model.map((item) => new StockDto(item));
    }

    set stockList(value: StockDto[]) {
      this.dataSetter(STEP_STOCK_LIST_MODEL, () => value.map((item) => item.toJson()));
    }

    get articleList(): DocumentItemDto[] {
      const model = this.getFieldValue(STEP_ARTICLE_LIST_MODEL, null);

      if (!model) return null;

      return model.map((item) => new DocumentItemDto(item));
    }

    set articleList(value: DocumentItemDto[]) {
      this.dataSetter(STEP_ARTICLE_LIST_MODEL, () => value.map((item) => item.toJson()));
    }

    get articleListByInternalNumber() {
      return mapKeys(this.articleList, 'internalNumber');
    }

    get balanceStockItems() {
      return this.stockList.map((stockItem) => new BalanceStockItemDtoCustom({
        stock: stockItem,
        documentItem: this.articleListByInternalNumber?.[stockItem.internalNumber],
      }));
    }

    get articlesOrder() {
      const articlesOrder = this.getFieldValue(STEP_ARTICLES_ORDER_MODEL, null);
      return articlesOrder ?? this.step?.articlesOrder ?? [];
    }

    setArticlesOrder(value?: string[]) {
      this.dataSetter(STEP_ARTICLES_ORDER_MODEL, () => this.step?.articlesOrder ?? value);
    }

    sanitizeFieldKey(internalNumber: string) {
      return `${WorkflowStepField.balanceStockItem}:${internalNumber}`;
    }

    desanitizeFieldKey(sanitizedKey: string) {
      const [stepField, internalNumber] = sanitizedKey.split(':');
      return internalNumber;
    }
  };


  return Derived as MergeCtor<typeof Derived, TBase>;
};
