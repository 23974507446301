import {
  InventoryActions,
  InventoryInputEvent,
  InventoryState,
  InventoryWarehouseCode,
  InventoryWarehouseType,
  InventoryPrintTemplate,
  InputTypeGroupList,
  InputTypeGroupRange,
} from '../types';
import {action} from '@designeo/vue-helpers/src/index';
import {
  sortBy,
  filter,
  find,
  findIndex,
  groupBy,
  map,
  mapKeys,
  reduce,
} from 'lodash-es';
import {InventoryStore} from './InventoryStore';
import {
  DocumentItemDto,
  DocumentLogisticItemDto,
  FiscalCommands,
  StockFilterDto,
  WarehouseType,
} from '@/Model/Entity';
import {apiStockGet} from '@/Model/Action';
import {DocumentTypes} from '@/constants/documentTypes';

export const createInventoryStockFilter = (data: StockFilterDto['_data'] = {}) => new StockFilterDto({
  warehouseTypes: [
    new WarehouseType(InventoryWarehouseType.OrdinaryWarehouse).toJson(),
    new WarehouseType(InventoryWarehouseType.ExchangeWarehouse).toJson(),
  ],
  ...data,
});

export const getGroupedStock = async ({stockFilter = createInventoryStockFilter(), stock = null} = {}) => {
  const newStock = stock ?? await apiStockGet({input: stockFilter});

  const stockByInternalNumberWithBatch = mapKeys(newStock, 'internalNumberWithBatch');

  const stockByWarehouse = groupBy(newStock, 'warehouseCode');

  const ordinaryStockByInternalNumberWithBatch = mapKeys(
    stockByWarehouse[InventoryWarehouseCode.OrdinaryWarehouse],
    'internalNumberWithBatch',
  );

  const exchangeStockByInternalNumberWithBatch = mapKeys(
    stockByWarehouse[InventoryWarehouseCode.ExchangeWarehouse],
    'internalNumberWithBatch',
  );

  return {
    stock: newStock,
    stockByInternalNumberWithBatch,
    ordinaryStockByInternalNumberWithBatch,
    exchangeStockByInternalNumberWithBatch,
  };
};

export const filterLogisticItemsByPrintTemplate = (
  data: DocumentLogisticItemDto['_data'][], printTemplate: InventoryPrintTemplate,
) => {
  switch (printTemplate) {
  case InventoryPrintTemplate.ALL:
    return sortBy(
      data, 'internalNumberWithBatch',
    );
  case InventoryPrintTemplate.ONLY_DIFFERENCES:
    return sortBy(
      filter(data, (item) => item.quantityDifference !== 0), 'internalNumberWithBatch',
    );
  case InventoryPrintTemplate.FIND_DIFFERENCES:
    return sortBy(
      filter(data, (item) => item.isValid && (item.quantityStockOrdinary !== item.quantityReal)),
      'internalNumberWithBatch',
    );
  }
};

export const getDocumentPropertiesByPrintTemplate = (printTemplate: InventoryPrintTemplate) => {
  switch (printTemplate) {
  case InventoryPrintTemplate.ALL:
    return {
      documentType: new FiscalCommands(DocumentTypes.InventorySummaryDocument),
      printOnlyDifferences: false,
    };
  case InventoryPrintTemplate.ONLY_DIFFERENCES:
    return {
      documentType: new FiscalCommands(DocumentTypes.InventorySummaryDocument),
      printOnlyDifferences: true,
    };
  case InventoryPrintTemplate.FIND_DIFFERENCES:
    return {
      documentType: new FiscalCommands(DocumentTypes.InventoryFindDiffsDocument),
      printOnlyDifferences: false,
    };
  }
};

export const findInventoryItem = function(this: InventoryStore, item: DocumentItemDto) {
  const foundItem = find(
    this.state.loadedData,
    (entityData) => entityData.internalNumberWithBatch === item.internalNumberWithBatch,
  );

  return foundItem;
};

export const isInputTypeGroupList = (
  input: InputTypeGroupList | InputTypeGroupRange,
): input is InputTypeGroupList => input.type === 'List';

export const isInputTypeGroupRange = (
  input: InputTypeGroupList | InputTypeGroupRange,
): input is InputTypeGroupRange => input.type === 'Range';

export const createTransitions = function(this: InventoryStore) {
  return {
    [InventoryActions.ADD_NUMBER]: action(async (event: InventoryInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [InventoryActions.ADD_CHAR]: action(async (event: InventoryInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [InventoryActions.ADD_MINUS]: action(async (event: InventoryInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [InventoryActions.ADD_PLUS]: action(async (event: InventoryInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [InventoryActions.ADD_PERIOD]: action(async (event: InventoryInputEvent) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = '.';
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += '.';
      }
    }),
    [InventoryActions.ADD_COMMA]: action(async (event: InventoryInputEvent) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = '.';
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += '.';
      }
    }),
    [InventoryActions.BACKSPACE]: action(async () => {
      this.state.inputBuffer = this.state.inputBuffer.slice(0, this.state.inputBuffer.length - 1);
      this.state.insertMode = false;
    }),
    [InventoryActions.CLEAR]: action(async (event: InventoryInputEvent) => {
      this.resetInputBuffer();
      this.state.insertMode = false;
    }),
    [InventoryActions.SCANNER]: action(async (event: InventoryInputEvent) => {
      this.state.inputBuffer = event.value;
    }),
  };
};

export const createArrowTransitions = function(this: InventoryStore) {
  const findValidItemFromIndex = (fromIndex, {reversed = false} = {}) => {
    let arr = map(
      this.gridDataWithAppliedFiltersAndSort.value,
      (item, index) => ({
        canBeManipulated: item.canBeManipulated,
        index,
      }),
    );

    arr = reversed ? [...arr].reverse() : arr;

    const arrIndex = findIndex(arr, {index: fromIndex});

    let index = find(arr, {canBeManipulated: true}, arrIndex)?.index ?? -1;

    if (index === -1) {
      index = find(arr, {canBeManipulated: true}, 0)?.index ?? -1;
    }

    return index;
  };

  const getNextValidItemIndex = () => {
    if (this.gridDataWithAppliedFiltersAndSort.value.length === 0) {
      return -1;
    }

    if (!this.state.activeDocumentLogisticItem) {
      return findValidItemFromIndex(0);
    }

    const activeItemIndex = this.findGridItemIndex(this.state.activeDocumentLogisticItem);

    if (activeItemIndex === -1) {
      return -1;
    }

    if (activeItemIndex === this.gridDataWithAppliedFiltersAndSort.value.length - 1) {
      return findValidItemFromIndex(0);
    }

    return findValidItemFromIndex(activeItemIndex + 1);
  };

  const getPrevValidItemIndex = () => {
    if (this.gridDataWithAppliedFiltersAndSort.value.length === 0) {
      return -1;
    }

    if (!this.state.activeDocumentLogisticItem) {
      return findValidItemFromIndex(this.gridDataWithAppliedFiltersAndSort.value.length - 1, {reversed: true});
    }

    const activeItemIndex = this.findGridItemIndex(this.state.activeDocumentLogisticItem);

    if (activeItemIndex === -1) {
      return -1;
    }

    if (activeItemIndex === 0) {
      return findValidItemFromIndex(this.gridDataWithAppliedFiltersAndSort.value.length - 1, {reversed: true});
    }

    return findValidItemFromIndex(activeItemIndex - 1, {reversed: true});
  };

  return {
    [InventoryActions.NEXT]: action(async () => {
      const list = this.gridDataWithAppliedFiltersAndSort.value;

      const index = getNextValidItemIndex();

      if (index === -1) {
        await this.setActiveDocumentLogisticItem(null);
      }

      await this.setActiveDocumentLogisticItem(list[index]);
    }),
    [InventoryActions.PREV]: action(async () => {
      const list = this.gridDataWithAppliedFiltersAndSort.value;

      const index = getPrevValidItemIndex();

      if (index === -1) {
        await this.setActiveDocumentLogisticItem(null);
      }

      await this.setActiveDocumentLogisticItem(list[index]);
    }),
  };
};

export const getCommonTransitions = function(
  this: InventoryStore, actions: keyof ReturnType<typeof createTransitions>,
) {
  return reduce(actions, (acc, val) => {
    const transitions = createTransitions.call(this);

    if (!Object.prototype.hasOwnProperty.call(transitions, val)) return acc;

    return {
      ...acc,
      [val]: transitions[val],
    };
  }, {});
};

export type TransitionDefinition = {[key in InventoryState]?: { [key in InventoryActions]?: any }};
