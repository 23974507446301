import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CreateCustomerContactDtoPropDef = {
  type: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
  label: {
    type: new StringType(),
  },
};
export default class GeneratedCreateCustomerContactDto extends createEntity(CreateCustomerContactDtoPropDef) {}
