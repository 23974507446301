import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const DocumentFooterDtoPropDef = {
  footerText1: {
    type: new StringType(),
  },
  footerText2: {
    type: new StringType(),
  },
  footerText3: {
    type: new StringType(),
  },
  footerText4: {
    type: new StringType(),
  },
  footerText5: {
    type: new StringType(),
  },
  footerText6: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentFooterDto extends createEntity(DocumentFooterDtoPropDef) {}
