import {BufferedInput} from './../KeyboardBuffer';
import {KeyPress} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransformStream,
} from '../streams';


export function createKeyPressToBufferedInput(bufferTimeout = 1200) {
  let buffer: KeyPress[] = [];
  let isBuffering: boolean = false;
  let timeout = null;

  function emitBuffer(controller) {
    if (buffer.length > 0) {
      controller.enqueue({
        keys: buffer,
        source: buffer[0].source,
      });
      buffer = [];
    }
    isBuffering = false;
  }

  function resetTimeout(controller) {
    clearTimeout(timeout);
    emitBuffer(controller);
    timeout = setTimeout(() => {
      emitBuffer(controller);
    }, bufferTimeout);
  }

  return new TransformStream<KeyPress, BufferedInput>({
    transform(key, controller) {
      if (key.dir === 'up') {
        return;
      } else if (key.code === 'STX') {
        resetTimeout(controller);
        isBuffering = true; // keep after resetTimeout
      } else if (key.code === 'ETX') {
        clearTimeout(timeout);
        emitBuffer(controller);
      } else if (isBuffering) {
        buffer.push(key);
      } else {
        controller.enqueue({
          keys: [key],
          source: key.source,
        });
      }
    },
  });
}
