import routes, {routesWithTitle} from './routes';
import LayoutWithTitle from '@/Layout/LayoutWithTitle.vue';
import {submitJournalEventEndPosFe, submitJournalEventStartPosFe} from '@/Helpers/journal';
import {localStoragePersistentVariable} from '@designeo/js-helpers/src/index';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem & {
  buildHash: string
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {}

export async function registerApps(ctx: RequiredContext) {
  const posStartFe = localStoragePersistentVariable('posStartFE', 'N/a');
  const posEndFe = localStoragePersistentVariable('posEndFE', 'N/a');

  submitJournalEventStartPosFe(posStartFe.get());

  posStartFe.set(new Date().toISOString());

  await ctx.registerRoutes(routes, {
    layout: 'apps',
  });

  await ctx.registerLayout('appsDetailWithTitle', {
    path: '/',
    component: LayoutWithTitle,
    children: [],
  });

  await ctx.registerRoutes(routesWithTitle, {
    layout: 'appsDetailWithTitle',
  });

  await ctx.registerHookBeforeEach((to, from, next) => {
    const authStore = useAuthStore();

    authStore.setIsInInventoryApp(to.name.toString().startsWith('apps-inventory'));

    next();
  });

  window.addEventListener('beforeunload', function(e) {
    submitJournalEventEndPosFe(posEndFe.get());

    posEndFe.set(new Date().toISOString());

    delete e['returnValue'];
  });

  return {};
}
