import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col flex-grow"
}
const _hoisted_3 = { class: "grid grid-cols-3 grid-rows-2 gap-4 mb-4" }
const _hoisted_4 = { class: "flex flex-row gap-4" }
const _hoisted_5 = { class: "flex flex-row justify-start mt-4 w-1/3" }
const _hoisted_6 = {
  key: 1,
  class: "flex flex-col flex-grow"
}
const _hoisted_7 = { class: "text-danger" }
const _hoisted_8 = { class: "flex flex-row mx-auto mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.workflowStep.fetchError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-sm btn-basic btn-outline-basic",
              onClick: _cache[0] || (_cache[0] = () => _ctx.workflowStep.generateCustomRandomNumbers({numbersCount: 1}))
            }, _toDisplayString(_ctx.workflowStep.messages.oneRandomNumber), 1),
            _createElementVNode("button", {
              class: "btn btn-sm btn-basic btn-outline-basic",
              onClick: _cache[1] || (_cache[1] = () => _ctx.workflowStep.generateCustomRandomNumbers({numbersCount: 2}))
            }, _toDisplayString(_ctx.workflowStep.messages.twoRandomNumbers), 1),
            _createElementVNode("button", {
              class: "btn btn-sm btn-basic btn-outline-basic",
              onClick: _cache[2] || (_cache[2] = () => _ctx.workflowStep.generateCustomRandomNumbers({numbersCount: 3}))
            }, _toDisplayString(_ctx.workflowStep.messages.threeRandomNumbers), 1),
            _createElementVNode("button", {
              class: "btn btn-sm btn-basic btn-outline-basic",
              onClick: _cache[3] || (_cache[3] = () => _ctx.workflowStep.generateCustomRandomNumbers({numbersCount: 4}))
            }, _toDisplayString(_ctx.workflowStep.messages.fourRandomNumbers), 1),
            _createElementVNode("button", {
              class: "btn btn-sm btn-basic btn-outline-basic",
              onClick: _cache[4] || (_cache[4] = () => _ctx.workflowStep.generateCustomRandomNumbers({numbersCount: 5}))
            }, _toDisplayString(_ctx.workflowStep.messages.fiveRandomNumbers), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity?.randomNumbers, (number, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: index,
                class: "korunka-circle-number"
              }, [
                _createTextVNode(_toDisplayString(number), 1)
              ], 512)), [
                [_directive_test, 'korunka-number']
              ])
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AsyncButton, {
              class: "btn-primary w-full shadow-md",
              onClick: () => _ctx.generateRandomNumbersOnEntity()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.workflowStep.messages?.generateNumbers), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('workflow.steps.korunka.workflowCannotContinue')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_AsyncButton, {
              class: "btn-primary w-full shadow-md",
              onClick: () => _ctx.workflowStep.fetchNumbers()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.korunka.repeat')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
  ]))
}