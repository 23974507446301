import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import GroupRangeDto from '../GroupRangeDto';


export const GroupRangeSearchDtoPropDef = {
  ranges: {
    type: new ArrayType(new EntityType(GroupRangeDto)),
  },
};
export default class GeneratedGroupRangeSearchDto extends createEntity(GroupRangeSearchDtoPropDef) {}
