import VoucherCheckDtoCustom from '@/Model/Entity/custom/VoucherCheckDtoCustom';

export default class VoucherCheckDto extends VoucherCheckDtoCustom {
  get canBeSold() {
    const now = new Date();

    return this.validFrom < now && now < this.validTill;
  }

  get errorReason() {
    return null;
  }

  get errorReasonForCustomer() {
    return null;
  }
}
