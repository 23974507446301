
import {defineComponent, PropType} from 'vue';
import ArticleLabel from '@/Components/ArticleLabels/ArticleLabel.vue';
import {DocumentItemDto} from '@/Model/Entity';

export default defineComponent({
  name: 'ArticleLabelMixin',
  components: {ArticleLabel},
  props: {
    item: {
      type: Object as PropType<DocumentItemDto>,
      required: true,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    forceVisible: {
      type: Boolean,
      default: null,
    },
  },
});
