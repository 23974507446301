import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const SafeBagDtoPropDef = {
  envelopeNumber: {
    type: new StringType(),
  },
  amount: {
    type: new NumberType(),
  },
  name: {
    type: new StringType(),
  },
  currency: {
    type: new StringType(),
  },
  status: {
    type: new StringType(),
  },
  unpackAllowed: {
    type: new BooleanType(),
  },
  envelopeScan: {
    type: new StringType(),
  },
};
export default class GeneratedSafeBagDto extends createEntity(SafeBagDtoPropDef) {}
