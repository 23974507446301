import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {
  TransitionDefinition,
  getCommonTransitions,
  createTransitions,
} from './helpers';
import {nominals} from '@/Helpers/nominals';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_ARTICLE_PRICE]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.ADD_PERIOD]: action(async (event: RegisterInputEvent) => {
        if ((this.state.inputBuffer ?? '').includes('.')) return;
        await (createTransitions.call(this)?.[RegisterActions.ADD_PERIOD](event));
      }),
      [RegisterActions.ADD_COMMA]: action(async (event: RegisterInputEvent) => {
        if ((this.state.inputBuffer ?? '').includes('.')) return;
        await (createTransitions.call(this)?.[RegisterActions.ADD_COMMA](event));
      }),
      [RegisterActions.INIT]: action(() => {
        this.state.inputBuffer = Math.abs(this.state.productFlow.product.priceNormal).toString(10);
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.inputBuffer) return;
        const validateNominals = this.configuration.value?.features?.sell?.serviceArticlePriceOnNominal ?? false;

        const bufferAsNumber = parseFloat(this.state.inputBuffer);

        if (bufferAsNumber >= Math.pow(10, 6)) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.MAX_PRICE_EXCEEDED, {
            detail: {
              price: bufferAsNumber,
              limit: Math.pow(10, 6) - 1,
            },
          }));
          return;
        }


        if (
          validateNominals &&
          !this.state.productFlow.product.isReturn &&
          nominals(
            parseFloat(this.state.inputBuffer),
            this.configurationStore.value.localCurrency.value.acceptableNominals,
          ).reminder !== 0
        ) {
          this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_NOMINAL));
          return;
        }


        const processed = this.processProductPropertyPriceNormal();

        if (processed) {
          await this.transitionToNextStep();
        }
      }),
    },
  };
}
