import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const InventoryGroupMetadataDtoPropDef = {
  inventoryGroup: {
    type: new StringType(),
  },
  inventoryGroupText: {
    type: new StringType(),
  },
  allowedLostValue: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  // TODO: Keep here
  originalAllowedLostValue: {
    type: new NumberType(),
  },
};
export default class GeneratedInventoryGroupMetadataDto extends createEntity(InventoryGroupMetadataDtoPropDef) {}
