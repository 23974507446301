import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.FILL_IN_CUSTOMER_CARD]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(async () => {
        if (this.customer.value) {
          await this.transitionToNextStep();
        }
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (event.value) {
          await this.changeState(RegisterState.FILL_IN_CUSTOMER_CARD_SEARCH);
        } else {
          await this.transitionToNextStep();
        }
      }),
      [RegisterActions.SKIP]: action(async (event: RegisterInputEvent) => {
        await this.transitionToNextStep();
      }),
    },
  };
}
