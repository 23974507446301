import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinEshop} from '../StepMixins/WorkflowStepMixinEshop';

export class WorkflowStepEshopPinVerification extends workflowStepMixinEshop(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.EshopPinVerification;
  }

  get type() {
    return WorkflowStepEshopPinVerification.type;
  }

  get component() {
    return markRaw(require('./StepEshopPinVerification.vue').default);
  }

  get isPinVerified() {
    return this.getFieldValue(WorkflowStepField.pinVerificationDate, null) !== null;
  }

  setPinVerificationDate() {
    this.disabledNextStep = false;
    this.dataSetter(WorkflowStepField.pinVerificationDate, () => new Date().toISOString());
  }

  async beforeEnter() {
    if (!this.isPinVerified) {
      this.disabledNextStep = true;
    }


    if (this.stepInit) {
      return;
    }

    try {
      await this.ensureOrder();
    } catch (err) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    }
    this.stepInit = true;
  }

  async finish() {
    if (!this.isPinVerified) {
      this.setPinVerificationDate();
    }

    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {};
  }
}
