import {createEntity, ObjectType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleResultDto from '../ArticleResultDto';
import VoucherResultDto from '../VoucherResultDto';
import DocumentCustomerDto from '../DocumentCustomerDto';
import DocumentPaymentDto from '../DocumentPaymentDto';
import ReceiptResultDto from '../ReceiptResultDto';
import SafebagResultDto from '../SafebagResultDto';
import InvoiceResultDto from '../InvoiceResultDto';


export const SearchResultDtoPropDef = {
  article: {
    type: new EntityType(ArticleResultDto),
  },
  voucher: {
    type: new EntityType(VoucherResultDto),
  },
  documentCustomer: {
    type: new EntityType(DocumentCustomerDto),
  },
  documentPayment: {
    type: new EntityType(DocumentPaymentDto),
  },
  receipt: {
    type: new EntityType(ReceiptResultDto),
  },
  safebag: {
    type: new EntityType(SafebagResultDto),
  },
  invoice: {
    type: new EntityType(InvoiceResultDto),
  },
  // NOTE: Keep here
  match: {
    type: new ObjectType<{gtinAddonExactMatch: boolean, gtinBaseExactMatch: boolean, gtinHasAddon: boolean}>(),
  },
};
export default class GeneratedSearchResultDto extends createEntity(SearchResultDtoPropDef) {}
