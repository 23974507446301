
import {
  computed,
  defineComponent,
  markRaw,
} from 'vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {isObject} from 'lodash-es';

export default defineComponent({
  setup() {
    const workflowStore = useWorkflowStore();

    const component = computed(() => {
      if (isObject(workflowStore.currentWorkflow.value?.instructions)) {
        return workflowStore.currentWorkflow.value.instructions;
      }

      return markRaw(require('@/Modules/Workflow/Instructions/Instructions.vue').default);
    });

    return {
      component,
    };
  },
});
