import {
  fromPairs,
  map,
} from 'lodash-es';
import {registerSyncRunnerWithStorage} from './syncRunners';
import ManualScheduler from '@designeo/sync-manager/src/schedulers/ManualScheduler';
import {StoredData} from '@designeo/sync-manager/src/types';

class ManualSchedulerWithTracking<I extends number|string, E> extends ManualScheduler<I, E> {
  trace: (data: StoredData<I, E>)=> boolean
  constructor({
    triggerOnNewData = true,
    trace = () => false,
  }: {
    triggerOnNewData?: boolean,
    trace?: (data: StoredData<I, E>)=> boolean,
  } = {}) {
    super({triggerOnNewData});
    this.trace = trace;
  }


  async onNewItem(data: StoredData<I, E>) {
    if (this.trace(data)) {
      // eslint-disable-next-line no-console
      console.trace('ManualSchedulerWithTracking.onNewItem', data);
      // eslint-disable-next-line no-debugger
      debugger;
    }

    if (this._triggerOnNewData) {
      await this.trigger();
      return data;
    } else {
      return data;
    }
  }
}

const createAsyncStorageProperty = (
  name: string,
): {
  get: ()=> string,
  set: (val: string)=> Promise<void>,
  reset: ()=> Promise<void>,
  hydrate: ()=> Promise<void>
} => {
  const {storage, syncRunner} = registerSyncRunnerWithStorage(name, async (syncedData) => ({
    ...syncedData,
    synced: true,
  }), {
    scheduler: new ManualScheduler(),
    // scheduler: new ManualSchedulerWithTracking({
    //   trace: (data) => {
    //     switch (data.id) {
    //     case 'posWorkflowStoreState':
    //       return true;
    //     default:
    //       return false;
    //     }
    //   },
    // }),
  });

  let hydrated = false;
  let data: string = undefined;


  return {
    get(): string {
      if (!hydrated) {
        throw new Error('Data not hydrated');
      }

      return data;
    },
    async set(val: string) {
      data = val;
      hydrated = true;

      await syncRunner.upload({
        id: name,
        data: JSON.parse(val),
        synced: false,
        deleted: false,
        modifiedAt: +new Date(),
      });
    },
    async reset() {
      data = JSON.stringify(null);
      hydrated = true;

      await syncRunner.upload({
        id: name,
        data: null,
        synced: false,
        deleted: true,
        modifiedAt: +new Date(),
      });
    },
    async hydrate() {
      const [{data: storageData} = {data: null}] = await storage.list({
        deleted: false,
      });

      data = JSON.stringify(storageData ?? null);
      hydrated = true;
    },
  };
};

export class AsyncStorage {
  static new(items: string[] = []) {
    return new AsyncStorage(items);
  }

  private storage: {[key: string]: ReturnType<typeof createAsyncStorageProperty>} = {}

  constructor(items: string[] = []) {
    for (const name of items) {
      this.storage[name] = createAsyncStorageProperty(name);
    }
  }

  add(name: string, asyncStorage = createAsyncStorageProperty(name)) {
    this.storage[name] = asyncStorage;

    return this;
  }

  getItem(key: string) {
    if (!this.storage[key]) {
      throw new Error(`Storage ${key} not found!`);
    }

    return this.storage[key].get() ?? null;
  }

  async setItem(key: string, val: string) {
    if (!this.storage[key]) {
      throw new Error(`Storage ${key} not found!`);
    }

    return await this.storage[key].set(val);
  }

  async removeItem(key: string) {
    if (!this.storage[key]) {
      throw new Error(`Storage ${key} not found!`);
    }

    await this.storage[key].reset();
  }

  async clear() {
    for (const [storageName, storage] of this.storageAsIterable) {
      await storage.reset();
    }
  }

  get storageAsIterable() {
    return Object.entries(this.storage);
  }

  set storageAsIterable(value) {
    this.storage = fromPairs(value);
  }

  get length() {
    return this.storageAsIterable.length;
  }

  key(index: number): string {
    return this.storageAsIterable[index]?.[0] ?? null;
  }

  serialize(): {[key: string]: string} {
    return fromPairs(map(this.storageAsIterable, ([storageName, storage]) => {
      return [storageName, JSON.parse(JSON.stringify(storage.get()))];
    }));
  }

  async deserialize(data: {[key: string]: string}) {
    this.storageAsIterable = await Promise.all(
      map(Object.entries(data), ([storageName, storageData]) => {
        return new Promise<[string, ReturnType<typeof createAsyncStorageProperty>]>((resolve) => {
          const storageProperty = createAsyncStorageProperty(storageName);
          storageProperty.set(storageData)
            .then(() => {
              resolve([storageName, storageProperty]);
            });
        });
      }),
    );
  }

  async hydrate() {
    for (const [storageName, storage] of this.storageAsIterable) {
      await storage.hydrate();
    }
  }
}

export function isAsyncStorageSupported() {
  return 'indexedDB' in globalThis;
}


export const asyncStorage = AsyncStorage.new();
