import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerReceiptPointsDto from '../CustomerReceiptPointsDto';


export const CustomerReceiptDtoPropDef = {
  id: {
    type: new StringType(),
  },
  place: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
  total: {
    type: new NumberType(),
  },
  documentDate: {
    type: new DateTimeType(),
  },
  points: {
    type: new ArrayType(new EntityType(CustomerReceiptPointsDto)),
  },
};
export default class GeneratedCustomerReceiptDto extends createEntity(CustomerReceiptDtoPropDef) {}
