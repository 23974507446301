import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Field from '../Field';


export default class GeneratedEditableField extends createEntity({
  field: {
    type: new EntityType(Field),
  },
}) {}
