import {RegisterRoutesOptions} from '@/ModuleSystem';
import {useIncorrectRestartStore} from '@/Modules/Core/store/IncorrectRestartStore';

export default [
  {
    path: '/register',
    name: 'register',
    component: () => import('./Register/RegisterPageErrorWrapper.vue'),
    async beforeEnter(to, from, next) {
      const incorrectRestartStore = useIncorrectRestartStore();

      if (incorrectRestartStore.isActive.value && from.name === 'login') {
        await new Promise((resolve) => incorrectRestartStore.setCallback(resolve));
      }

      next();
    },
  },
  {
    path: '/delayed-sell',
    name: 'delayed-sell',
    redirect: () => ({name: 'register', query: {delayedSell: true}}),
  },
] as RegisterRoutesOptions;
