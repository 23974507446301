import {GiftPoolWithAvailableGiftsSerialized} from '@/Modules/Register/PromoInteractionFlow';
import {RouteLocationRaw} from 'vue-router';
import {DocumentDto, DocumentPaymentDto} from '@/Model/Entity';
import {EventTarget} from 'event-target-shim';
import {wait} from '@designeo/js-helpers';
import {ProductFlow} from '@/Modules/Register/ProductFlow/ProductFlow';
import {Context} from '@/Helpers/Context';
import {toRaw} from 'vue';
import {isNil, pick} from 'lodash-es';
import {DocumentGiftPool} from '@designeo/pos-promotion-engine';
import {GecoGame, PromotionQuestion} from '@designeo/pos-promotion-engine';
import {AxiosError} from 'axios';
import {CustomerAlert} from '@/Modules/CustomerExternal/types';
import {Entity} from '@designeo/apibundle-js';

export enum BroadcastIOChannels {
  ALERT = 'ALERT',
  APP_LOADING = 'APP_LOADING',
  BTC_CONFIRM_RESULT = 'BTC_CONFIRM_RESULT',
  CUSTOMER_PIN_RESULT = 'CUSTOMER_PIN_RESULT',
  EXTERNAL_DISPLAY_LOADED = 'EXTERNAL_DISPLAY_LOADED',
  GDPR_REQUEST_RESULT = 'GDPR_REQUEST_RESULT',
  CUSTOMER_INFORMATION_CONFIRM = 'CUSTOMER_INFORMATION_CONFIRM',
  GECO_GAME_CONFIRM = 'GECO_GAME_CONFIRM',
  GECO_GAME_CUSTOMER_CONFIRM = 'GECO_GAME_CUSTOMER_CONFIRM',
  GECO_GAME_GAME_STATE = 'GECO_GAME_GAME_STATE',
  GECO_GAME_REQUEST_GAME_STATE = 'GECO_GAME_REQUEST_GAME_STATE',
  GECO_GAME_FINISHED = 'GECO_GAME_FINISHED',
  GECO_GAME_RESULT = 'GECO_GAME_RESULT',
  GET_WORKFLOW_STEP = 'GET_WORKFLOW_STEP',
  GIFT_OPTIONS_CHANGED = 'GIFT_OPTIONS_CHANGED',
  GIFT_SELECTED = 'GIFT_SELECTED',
  GIFT_UNSELECTED = 'GIFT_UNSELECTED',
  GIFT_SELECTION_REQUEST = 'GIFT_SELECTION_REQUEST',
  INIT = 'INIT',
  PAYMENT_TYPE_CHANGE = 'PAYMENT_TYPE_CHANGE',
  PHONE_NUMBER_CONFIRM = 'PHONE_NUMBER_CONFIRM',
  PHONE_NUMBER_CHANGE = 'PHONE_NUMBER_CHANGE',
  PIN_VERIFICATION_FAILED = 'PIN_VERIFICATION_FAILED',
  PLAY_GECO_GAME = 'PLAY_GECO_GAME',
  POINTS_BURNING_POINTS_OPTIONS = 'POINTS_BURNING_POINTS_OPTIONS',
  POINTS_BURNING_POINTS_REQUEST = 'POINTS_BURNING_POINTS_REQUEST',
  POINTS_BURNING_SELECTED = 'POINTS_BURNING_SELECTED',
  PRODUCT_FLOW_CHANGE = 'PRODUCT_FLOW_CHANGE',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  QUESTION_CHANGED = 'QUESTION_CHANGED',
  QUESTION_REQUEST = 'QUESTION_REQUEST',
  ROUTE_CHANGE = 'ROUTE_CHANGE',
  ROUTE_GET = 'ROUTE_GET',
  SELL_DOCUMENT_CHANGE = 'SELL_DOCUMENT_CHANGE',
  STATE_CHANGE = 'STATE_CHANGE',
  PRIMARY_WINDOW_PING = 'PRIMARY_WINDOW_PING',
  PRIMARY_WINDOW_PONG = 'PRIMARY_WINDOW_PONG',
  KORUNKA_LOTTERY_CONFIRM_RESULT = 'KORUNKA_LOTTERY_CONFIRM_RESULT',
  KORUNKA_LOTTERY_PREVIOUS_TICKET = 'KORUNKA_LOTTERY_PREVIOUS_TICKET',
  KORUNKA_LOTTERY_NEXT_TICKET = 'KORUNKA_LOTTERY_NEXT_TICKET',
  PRODUCT_RECOMMENDATIONS_OPTIONS = 'PRODUCT_RECOMMENDATIONS_OPTIONS',
  PRODUCT_RECOMMENDATIONS_REQUEST = 'PRODUCT_RECOMMENDATIONS_REQUEST',
  PRODUCT_RECOMMENDATIONS_SHOW_PRICE_PER_UNIT = 'PRODUCT_RECOMMENDATIONS_SHOW_PRICE_PER_UNIT',
  PRODUCT_RECOMMENDATION_HIGHLIGHTED = 'PRODUCT_RECOMMENDATION_HIGHLIGHTED',
}

export type BroadcastIOPayloads = {
  [BroadcastIOChannels.ALERT]: CustomerAlert,
  [BroadcastIOChannels.APP_LOADING]: boolean
  [BroadcastIOChannels.BTC_CONFIRM_RESULT]: boolean
  [BroadcastIOChannels.CUSTOMER_PIN_RESULT]: string
  [BroadcastIOChannels.EXTERNAL_DISPLAY_LOADED]: null,
  [BroadcastIOChannels.EXTERNAL_DISPLAY_LOADED]: null,
  [BroadcastIOChannels.GDPR_REQUEST_RESULT]: boolean
  [BroadcastIOChannels.GET_WORKFLOW_STEP]?: {[key: string]: any},
  [BroadcastIOChannels.GIFT_OPTIONS_CHANGED]: GiftPoolWithAvailableGiftsSerialized,
  [BroadcastIOChannels.GIFT_SELECTED]: DocumentGiftPool['articles'][0] | null,
  [BroadcastIOChannels.GIFT_SELECTION_REQUEST]: null,
  [BroadcastIOChannels.INIT]: RouteLocationRaw
  [BroadcastIOChannels.PAYMENT_TYPE_CHANGE]: DocumentPaymentDto
  [BroadcastIOChannels.PHONE_NUMBER_CONFIRM]: boolean
  [BroadcastIOChannels.PHONE_NUMBER_CHANGE]: string
  [BroadcastIOChannels.PRODUCT_FLOW_CHANGE]: ProductFlow
  [BroadcastIOChannels.QUESTION_CHANGED]: PromotionQuestion,
  [BroadcastIOChannels.ROUTE_CHANGE]: RouteLocationRaw
  [BroadcastIOChannels.ROUTE_GET]: RouteLocationRaw
  [BroadcastIOChannels.SELL_DOCUMENT_CHANGE]: DocumentDto
  [BroadcastIOChannels.PIN_VERIFICATION_FAILED]: AxiosError | Entity<any, any>
  [BroadcastIOChannels.GECO_GAME_CONFIRM]: boolean
  [BroadcastIOChannels.GECO_GAME_FINISHED]: GecoGame
  [BroadcastIOChannels.POINTS_BURNING_POINTS_OPTIONS]: number[]
  [BroadcastIOChannels.POINTS_BURNING_SELECTED]: number
  [BroadcastIOChannels.PRIMARY_WINDOW_PING]: null,
  [BroadcastIOChannels.PRIMARY_WINDOW_PONG]: null,
  [BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_OPTIONS]: {
    recommendations: any
    highlightedRecommendation: any
    product: any
    displayPricePerUnit: {
      enabled: boolean,
      active: boolean,
    },
  }
  [BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_REQUEST]: any
  [BroadcastIOChannels.PRODUCT_RECOMMENDATION_HIGHLIGHTED]: any
  [BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_SHOW_PRICE_PER_UNIT]: any
}

export type ChannelPayload = {
  channel: BroadcastIOChannels
  payload: ReturnType<typeof JSON.stringify>
}

const payloadSerialize = (channel: BroadcastIOChannels, payload) => {
  payload = toRaw(payload ?? null);
  switch (channel) {
  case BroadcastIOChannels.PIN_VERIFICATION_FAILED:
    return JSON.stringify(Object.assign(payload.toJSON?.() ?? payload.toJson?.(), {response: payload.response}));
  case BroadcastIOChannels.SELL_DOCUMENT_CHANGE:
    return JSON.stringify((<DocumentDto>payload).toJson());
  case BroadcastIOChannels.PRODUCT_FLOW_CHANGE:
    return JSON.stringify((<ProductFlow>payload).context.state);
  case BroadcastIOChannels.PAYMENT_TYPE_CHANGE:
    return JSON.stringify((<DocumentPaymentDto>payload)?.toJson());
  case BroadcastIOChannels.INIT:
  case BroadcastIOChannels.ROUTE_CHANGE:
  case BroadcastIOChannels.ROUTE_GET:
    return JSON.stringify(pick(payload, [
      'fullPath',
      'hash',
      'href',
      'meta',
      'name',
      'params',
      'path',
      'query',
    ]));
  default:
    return JSON.stringify(payload);
  }
};

const payloadDeserialize = (channel: BroadcastIOChannels, payload) => {
  payload = payload ?? null;

  switch (channel) {
  case BroadcastIOChannels.PIN_VERIFICATION_FAILED:
    return <ReturnType<AxiosError['toJSON']> & {isAxiosError: boolean, response: AxiosError['response']}>{
      isAxiosError: true,
      ...JSON.parse(payload),
    };
  case BroadcastIOChannels.SELL_DOCUMENT_CHANGE:
    return isNil(payload) ? null : new DocumentDto(<DocumentDto['_data']>JSON.parse(payload));
  case BroadcastIOChannels.PRODUCT_FLOW_CHANGE:
    return isNil(payload) ? null : new ProductFlow(new Context(<Context>JSON.parse(payload)));
  case BroadcastIOChannels.PAYMENT_TYPE_CHANGE:
    return isNil(payload) ? null : new DocumentPaymentDto(<DocumentPaymentDto['_data']>JSON.parse(payload));
  default:
    return JSON.parse(payload);
  }
};

export const createBroadcastChannel = (name) => {
  if (typeof BroadcastChannel !== 'undefined') {
    return new BroadcastChannel(name);
  } else {
    return {
      onmessage: null,
      postMessage(msg: any): void {},
      close(): void {},
    } as BroadcastChannel;
  }
};

const channel = createBroadcastChannel('broadcastIO');

export class BroadcastIO extends EventTarget {
  private messageQueue: Array<{
    delay: number,
    channel: BroadcastIOChannels,
    payload: BroadcastIOPayloads[keyof BroadcastIOPayloads]
  }> = []
  private messageBusIsMuted: boolean = false
  private messageQueueIsBeingProcessed: boolean = false
  constructor(private readonly io: BroadcastChannel = channel) {
    super();

    this.messageQueue = [];

    this.io.onmessage = (e: MessageEvent<ChannelPayload>) => {
      const {payload, channel} = e.data;
      this.dispatchEvent(new CustomEvent(channel, {
        detail: payloadDeserialize(channel, payload),
      }));
    };
  }

  postMessage<CHANNEL extends keyof BroadcastIOPayloads>(
    channel: BroadcastIOChannels,
    payload: BroadcastIOPayloads[CHANNEL] = null,
    {delay = 0} = {},
  ) {
    this.messageQueue.push({
      channel,
      payload,
      delay,
    });

    this.processMessageQueue();
  }

  postMessageWithCallback<
    CHANNEL extends keyof BroadcastIOPayloads,
    PAYLOAD extends BroadcastIOPayloads[CHANNEL],
    CALLBACK_CHANNEL extends keyof BroadcastIOPayloads,
    CALLBACK_PAYLOAD extends BroadcastIOPayloads[CALLBACK_CHANNEL],
  >(
    channel: CHANNEL,
    payload: PAYLOAD = null,
    callbackEvent: CALLBACK_CHANNEL,
    {
      delay = 0,
      timeout = null,
    } = {},
  ): Promise<CALLBACK_PAYLOAD> {
    return new Promise((resolve, reject) => {
      let timer = null;
      const callback = (event) => {
        clearTimeout(timer);
        resolve(event.detail);
      };

      broadcastIO.addEventListener(callbackEvent, callback, {once: true});

      if (timeout) {
        timer = setTimeout(() => {
          broadcastIO.removeEventListener(callbackEvent, callback);
          reject(new Error('timeout'));
        }, timeout);
      }


      this.messageQueue.push({
        channel,
        payload,
        delay,
      });

      this.processMessageQueue();
    });
  }

  async processMessageQueue() {
    if (this.messageBusIsMuted || this.messageQueueIsBeingProcessed) return;

    this.messageQueueIsBeingProcessed = true;

    while (this.messageQueue.length) {
      const message = this.messageQueue.shift();
      await wait(message.delay)(null);
      this.io.postMessage(<ChannelPayload>{
        channel: message.channel,
        payload: payloadSerialize(message.channel, message.payload),
      });
    }

    this.messageQueueIsBeingProcessed = false;
  }

  ensureMuteAndPauseQueue() {
    this.messageBusIsMuted = true;
  }

  ensureUnmuteAndResumeQueue() {
    this.messageBusIsMuted = false;

    this.processMessageQueue();
  }
}

export const broadcastIO = new BroadcastIO();
