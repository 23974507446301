import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentFinItemType from '../DocumentFinItemType';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const DocumentFinItemDtoPropDef = {
  type: {
    type: new EntityType(DocumentFinItemType),
  },
  description: {
    type: new StringType(),
  },
  envelopeNumber: {
    type: new StringType(),
  },
  valueBeforeDiscounts: {
    type: new NumberType(),
  },
  quantity: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentFinItemDto extends createEntity(DocumentFinItemDtoPropDef) {}
