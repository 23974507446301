import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const OrderNotificationCountDtoPropDef = {
  totalCount: {
    type: new NumberType(),
  },
};
export default class GeneratedOrderNotificationCountDto extends createEntity(OrderNotificationCountDtoPropDef) {}
