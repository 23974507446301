
import {
  defineComponent,
  PropType,
  shallowRef,
  watch,
} from 'vue';
import {
  getPhIcon,
  PhIcons,
} from '@/Helpers/phIcons';

export default defineComponent({
  name: 'PhIcon',
  inheritAttrs: false,
  props: {
    icon: {
      type: String as (PropType<PhIcons | (string & {})>),
      required: true,
    },
  },
  setup(props) {
    const component = shallowRef(null);

    const fetchIcon = async (icon) => {
      component.value = await getPhIcon(icon);
    };

    watch(() => props.icon, () => {
      fetchIcon(props.icon ?? 'n/a');
    }, {immediate: true});


    return {
      component,
    };
  },
});
