import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TransactionDto from '../TransactionDto';
import {ArrayType} from '@designeo/apibundle-js';


export const ReferentialDocumentsDtoPropDef = {
  transaction: {
    type: new EntityType(TransactionDto),
  },
  originalDocument: {
    type: new EntityType(TransactionDto),
  },
  referentialDocuments: {
    type: new ArrayType(new EntityType(TransactionDto)),
  },
};
export default class GeneratedReferentialDocumentsDto extends createEntity(ReferentialDocumentsDtoPropDef) {}
