import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const FiscalResultDtoPropDef = {
  eeT_Country: {
    type: new StringType(),
  },
  eeT_PKP: {
    type: new StringType(),
  },
  eeT_BKP: {
    type: new StringType(),
  },
  eeT_Guid: {
    type: new StringType(),
  },
  eeT_Status: {
    type: new StringType(),
  },
  eeT_RequestUID: {
    type: new StringType(),
  },
  eeT_ResponseUID: {
    type: new StringType(),
  },
  eeT_FIK: {
    type: new StringType(),
  },
  eeT_Date: {
    type: new StringType(),
  },
  eeT_Test: {
    type: new StringType(),
  },
  eeT_Duration: {
    type: new StringType(),
  },
  eeT_Message: {
    type: new StringType(),
  },
};
export default class GeneratedFiscalResultDto extends createEntity(FiscalResultDtoPropDef) {}
