import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TranslatedString from '../TranslatedString';
import {NumberType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import PaymentButtonStyle from '../PaymentButtonStyle';


export default class GeneratedPaymentButton extends createEntity({
  type: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  icon: {
    type: new StringType(),
  },
  keyboardShortcut: {
    type: new StringType(),
  },
  label: {
    type: new EntityType(TranslatedString),
  },
  paymentId: {
    type: new NumberType(),
  },
  pointsBurningClub: {
    type: new StringType(),
  },
  isCustomButton: {
    type: new BooleanType(),
  },
  style: {
    type: new EntityType(PaymentButtonStyle),
  },
  disabled: {
    type: new BooleanType(),
  },
}) {}
