import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const ParkingHistoryDtoPropDef = {
  cashierId: {
    type: new StringType(),
  },
  parkedAt: {
    type: new DateTimeType(),
  },
};
export default class GeneratedParkingHistoryDto extends createEntity(ParkingHistoryDtoPropDef) {}
