import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';

import {BufferedInput} from '@/Modules/Register/services/KeyboardBuffer';
import {CreateMessageDto, MessageDto} from '@/Model/Entity';
import {
  IPaginator,
  MessageTypes,
  PosMessageActions,
  PosMessageInputEvent,
  PosMessageState,
} from '@/Modules/Apps/PosMessage/types';

import {
  apiMessageAddressBook,
  apiMessageArchive,
  apiMessageAttachment,
  apiMessageCreate,
  apiMessageMarkAsRead,
  apiMessageUploadAttachment,
  apiV2MessageDetail,
  apiV2MessageList,

} from '@/Model/Action';

import {axiosBlob} from '@/Model/Action/axios';
import ArchiveMessageDto from '@/Model/Entity/ArchiveMessageDto';
import {filter} from 'lodash-es';
import MessageDetailDto from '@/Model/Entity/MessageDetailDto';
import MessageListDtoIPagingObject from '@/Model/Entity/MessageListDtoIPagingObject';
import MessageListDto from '@/Model/Entity/MessageListDto';

export interface IMessageStore {
  state: PosMessageState,
  paginator: IPaginator,
  modalNewMessageIsOpened: boolean,
  modalAttachmentsListIsOpened: boolean,
  modalImportantUnreadMessageIsOpened: boolean,
  messageDetail: MessageDetailDto,
  messages: Array<MessageListDto>,
  unreadMessages: Array<MessageListDto>,
}

export const pageSize = 100;

export class MessageStore extends Store<IMessageStore> {
  constructor() {
    super({
      state: PosMessageState.BASE_STATE,
      paginator: {
        limit: pageSize,
        offset: 0,
        total: 0,
      },
      messages: [],
      unreadMessages: [],
      messageDetail: null,
      modalNewMessageIsOpened: false,
      modalAttachmentsListIsOpened: false,
      modalImportantUnreadMessageIsOpened: null,
    });
  }

  transitions: { [key in PosMessageState]?: { [key in PosMessageActions]?: Function } } = {
    [PosMessageState.BASE_STATE]: {
      [PosMessageActions.NEW_MESSAGE_MODAL_STATE_ACTION]: action(async (event: PosMessageInputEvent<boolean>) => {
        this.state.modalNewMessageIsOpened = event.value;
      }),
      [PosMessageActions.ATTACHMENTS_LIST_MODAL_STATE_ACTION]:
        action(async (event: PosMessageInputEvent<boolean>) => {
          this.state.modalAttachmentsListIsOpened = event.value;
        }),
    },
  }

  onEventInput = action((event: PosMessageInputEvent) => {
    return this.transitions?.[this.state.state]?.[event.type](event);
  })

  changeState = action((state: PosMessageState) => {
    this.state.state = state;
  })

  openModalNewMessage = action(() => {
    this.changeState(PosMessageState.BASE_STATE);
    this.onEventInput({
      type: PosMessageActions.NEW_MESSAGE_MODAL_STATE_ACTION,
      value: true,
    });
    this.changeState(PosMessageState.NEW_MESSAGE_STATE);
  })

  closeModalNewMessage = action(() => {
    this.changeState(PosMessageState.BASE_STATE);
    this.onEventInput({
      type: PosMessageActions.NEW_MESSAGE_MODAL_STATE_ACTION,
      value: false,
    });
  })

  openModalAttachmentsList = action(() => {
    this.onEventInput({
      type: PosMessageActions.ATTACHMENTS_LIST_MODAL_STATE_ACTION,
      value: true,
    });
  })

  closeModalAttachmentsList = action(() => {
    this.onEventInput({
      type: PosMessageActions.ATTACHMENTS_LIST_MODAL_STATE_ACTION,
      value: false,
    });
  })

  setMessageDetail = action(async (message: MessageListDto) => {
    if (!message) {
      this.state.messageDetail = null;
      return;
    }

    try {
      this.state.messageDetail = await apiV2MessageDetail({
        params: {
          messageId: message.id,
        },
      });
    } catch (e) {
      this.state.messageDetail = null;
      throw e;
    }
  });

  paginatorSetTotal = action((total) => this.state.paginator.total = total)

  paginatorAddPage = action(() => {
    this.state.paginator = {
      ...this.state.paginator,
      offset: this.state.paginator.offset + pageSize,
      limit: pageSize,
    };
  })

  paginatorReset = action(() => {
    this.state.paginator.offset = 0;
    this.state.paginator.total = 0;
  })

  onKeyboardInput = action((bufferedInput: BufferedInput) => {
    this.onEventInput({
      type: PosMessageActions.MESSAGE_SET_VALUE_ACTION,
      value: bufferedInput,
    });
  })

  fetchUnreadMessages = action(async () => {
    try {
      this.state.unreadMessages = (<MessageListDtoIPagingObject> await this.getUnreadMessages()).data;

      if (!this.unreadImportantMessages.value.length) {
        this.state.modalImportantUnreadMessageIsOpened = null;
      } else if (this.state.modalImportantUnreadMessageIsOpened === null) {
        this.state.modalImportantUnreadMessageIsOpened = true;
      }
    } catch (e) {
      console.error(e);
    }
  })

  markAsRead = action(async (message: { id: MessageDto['_data']['id'] }) => {
    try {
      this.state.messages.find((item) => item.id === message.id).readDate = new Date();
      return await apiMessageMarkAsRead({
        params: {id: message.id},
      });
    } finally {
      await this.fetchUnreadMessages();
    }
  })

  setMessages = action((list: Array<MessageListDto>) => {
    this.state.messages = list;
  })

  setModalImportantUnreadMessage = action((val) => {
    if (val === null) {
      this.state.modalImportantUnreadMessageIsOpened = !!this.unreadImportantMessages.value.length;
    } else {
      this.state.modalImportantUnreadMessageIsOpened = val;
    }
  })

  getSentMessages = action(async () => {
    return <MessageListDtoIPagingObject> await apiV2MessageList({
      params: {
        limit: this.state.paginator.limit,
        offset: this.state.paginator.offset,
        type: MessageTypes.sent,
      },
    });
  })

  getReceivedMessages = action(async () => {
    return <MessageListDtoIPagingObject> await apiV2MessageList({
      params: {
        limit: this.state.paginator.limit,
        offset: this.state.paginator.offset,
        type: MessageTypes.received,
      },
    });
  })

  getArchivedMessages = action(async () => {
    return <MessageListDtoIPagingObject> await apiV2MessageList({
      params: {
        limit: this.state.paginator.limit,
        offset: this.state.paginator.offset,
        isArchived: true,
      },
    });
  },
  )

  getUnreadMessages = action(async () => {
    return <MessageListDtoIPagingObject> await apiV2MessageList({
      params: {
        limit: 100,
        type: MessageTypes.received,
        IsUnread: true,
      },
    });
  })

  refreshMessages = action(async () => {
    const oldPaginator = {...this.paginator.value};
    this.state.paginator.limit = oldPaginator.offset > 0 ? oldPaginator.offset : pageSize;
    this.state.paginator.offset = 0;
    let data: MessageListDto[] = [];
    const firstMessage = this.state.messages[0];
    if (firstMessage) {
      if (firstMessage.isArchived) {
        ({data} = (await this.getArchivedMessages()));
      } else if (firstMessage.type.value === MessageTypes.sent) {
        ({data} = await this.getSentMessages());
      } else {
        ({data} = await this.getReceivedMessages());
      }
    }
    this.state.paginator.offset = oldPaginator.offset;
    this.state.paginator.limit = pageSize;
    this.setMessages(data);
  })

  async archiveMessages(ids: Array<string>) {
    await apiMessageArchive({
      input: new ArchiveMessageDto({archiveIds: ids}),
    });
  }

  async restoreMessages(ids: Array<string>) {
    await apiMessageArchive({
      input: new ArchiveMessageDto({unArchiveIds: ids}),
    });
  }

  sendMessage = action(async (messageForSend: CreateMessageDto, attachments: File[] | null) => {
    messageForSend.attachmentIds = [];
    if (attachments && attachments.length) {
      messageForSend.attachmentIds = (await Promise.all(
        Array.from(attachments).map((file) => {
          const fd = new FormData();
          fd.append('file', file);
          return apiMessageUploadAttachment({
            input: fd,
          });
        }),
      )).map((attachment) => attachment ? attachment.id : null);
    }
    return apiMessageCreate({input: messageForSend});
  })

  fetchAddressBook = action(async () => await apiMessageAddressBook())

  fetchAttachment = action((attachmentId: string) => {
    try {
      return apiMessageAttachment({
        params: {
          attachmentId,
        },
        axios: axiosBlob,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  })

  modalNewMessageIsOpened = getter(() => this.state.modalNewMessageIsOpened)
  modalAttachmentsListIsOpened = getter(() => this.state.modalAttachmentsListIsOpened)
  modalImportantUnreadMessageIsOpened = getter(() => this.state.modalImportantUnreadMessageIsOpened)
  paginator = getter(() => this.state.paginator)
  messageDetail = getter(() => this.state.messageDetail)
  messages = getter(() => this.state.messages)
  unreadMessages = getter(() => this.state.unreadMessages)
  unreadSensitiveMessages = getter(() => {
    return filter(this.state.unreadMessages, (msg) => msg.isSensitive);
  })
  unreadImportantMessages = getter(() => {
    return filter(this.state.unreadMessages, (msg) => msg.isImportant);
  })
  unreadImportantAndSensitiveMessages = getter(() => {
    return filter(this.state.unreadMessages, (msg) => msg.isImportant && msg.isSensitive);
  })
  unreadImportantOrSensitiveMessages = getter(() => {
    return filter(this.state.unreadMessages, (msg) => msg.isImportant || msg.isSensitive);
  })
}

const storeIdentifier = 'MessageStore';

export const configureMessageStore = createConfigureStore<typeof MessageStore>(storeIdentifier);
export const useMessageStore = createUseStore(MessageStore, storeIdentifier);
