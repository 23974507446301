import GeneratedStockDto from './generated/StockDto';
import {createBatchKey} from '@/Helpers/batch';

export default class StockDto extends GeneratedStockDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get internalNumberWithBatch() {
    return this.batch ? createBatchKey({internalNumber: this.internalNumber, batch: this.batch}) : this.internalNumber;
  }

  get unitByEnum() {
    return StockDto.configurationStore.unitsByCode.value?.[this.unitOfQuantityCode]?.text ?? this.unitOfQuantityCode;
  }

  toString() {
    return this.count;
  }
}
