import {ArrayType, createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TranslatedString from '../TranslatedString';
import StornoReasonStyle from '../StornoReasonStyle';


export default class GeneratedStornoReasonClass extends createEntity({
  code: {
    type: new StringType(),
  },
  icon: {
    type: new StringType(),
  },
  label: {
    type: new EntityType(TranslatedString),
  },
  reasonId: {
    type: new StringType(),
  },
  style: {
    type: new EntityType(StornoReasonStyle),
  },
}) {}
