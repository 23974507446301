import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransitionDefinition,
  getCommonTransitions,
  createTransitions,
} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_ARTICLE_QUANTITY]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent) => {
        if (
          !this.state.productFlow.product.hasIntRounding &&
          !this.state.productFlow.product.itemRoundingValidator.test(this.state.inputBuffer) &&
          !this.state.insertMode
        ) {
          return;
        }
        await (createTransitions.call(this)?.[RegisterActions.ADD_NUMBER](event));
      }),
      [RegisterActions.ADD_PERIOD]: action(async (event: RegisterInputEvent) => {
        if (
          this.state.productFlow.product.hasIntRounding ||
          this.state.inputBuffer.includes('.') ||
          !this.state.inputBuffer
        ) {
          return;
        }

        await (createTransitions.call(this)?.[RegisterActions.ADD_PERIOD](event));
      }),
      [RegisterActions.ADD_COMMA]: action(async (event: RegisterInputEvent) => {
        if (
          this.state.productFlow.product.hasIntRounding ||
          this.state.inputBuffer.includes('.') ||
          !this.state.inputBuffer
        ) {
          return;
        }

        await (createTransitions.call(this)?.[RegisterActions.ADD_COMMA](event));
      }),
      [RegisterActions.INIT]: action(async () => {
        this.state.inputBuffer = Math.abs(this.state.productFlow.product.quantity ?? 1).toString(10);
        this.state.insertMode = true;

        if (
          this.state.productFlow.product.hasIntRounding &&
          this.state.productFlow.presetQuantityIsSet &&
          this.state.productFlow.presetQuantity % 1 !== 0
        ) {
          return;
        }

        if (this.state.productFlow.presetQuantityIsSet) {
          const group = this.currentEditGroup.value;
          let quantity = parseFloat(this.state.inputBuffer);

          if (group.editableItemIsEditableSet || group.editableItem.wasExpanded) {
            if (this.state.productFlow.isNew) {
              quantity *= this.state.productFlow.presetQuantity;
            } else {
              quantity += this.state.productFlow.presetQuantity;
            }
          } else {
            quantity = quantity - 1 + this.state.productFlow.presetQuantity;
          }

          const processed = this.processProductPropertyQuantity(quantity);

          if (processed) {
            await this.transitionToNextStep();
          }
        }
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        const quantity = parseFloat(this.state.inputBuffer);

        if (!quantity) return;

        const processed = this.processProductPropertyQuantity(quantity);

        if (processed) {
          await this.transitionToNextStep();
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        if (this.isArticleEditInBlockingState.value) {
          this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_UI_OPERATION));
          return;
        }

        this.state.productFlow.inputMode = InputSource.SCANNER;
        await this.search(event.value, 'product');
      }),
    },
  };
}
