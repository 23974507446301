import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const CustomerCardDtoPropDef = {
  cardNumber: {
    type: new StringType(),
  },
  customerId: {
    type: new StringType(),
  },
  issuedDate: {
    type: new DateTimeType(),
  },
  activeFrom: {
    type: new DateTimeType(),
  },
  activeTill: {
    type: new DateTimeType(),
  },
};
export default class GeneratedCustomerCardDto extends createEntity(CustomerCardDtoPropDef) {}
