
import {
  computed,
  defineComponent,
} from 'vue';
import {useKorunkaSyncTarget} from '../../syncTarget/KorunkaSyncTarget';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import {TicketPartnerResourceV2} from '@/Model/Entity';

export default defineComponent({
  setup() {
    const korunkaSyncTarget = useKorunkaSyncTarget();
    const configurationStore = useConfigurationStore();

    const {interpolate, currencyFormat: baseCurrencyFormat, dateFormat} = useFilters();

    const currentStepType = computed(() => korunkaSyncTarget.stepType.value);

    const isStepOverview = computed(() => currentStepType.value === WorkflowStepTypes.KorunkaOverview);

    const messages = computed(() => korunkaSyncTarget.messages.value);

    const entity = computed(() => korunkaSyncTarget.entity.value as KorunkaLotteryTicketDtoCustom);

    const ticket = computed(() => entity.value.ticket as TicketPartnerResourceV2);

    const badge = computed(() => {
      if (entity.value.betsDrawState.isWin && !entity.value.betsDrawState.isPaidOut) {
        return {
          class: 'bg-success text-basic-lightest',
          labels: [
            ...(entity.value.betsDrawState.isPartlyPaidOut ?
              [messages.value.ticketStatusIsPartlyPaidOut] :
              []
            ),
            messages.value.ticketStatusWon,
            ...(entity.value.betsDrawState.isWaitingForNextDraw ?
              [messages.value.ticketStatusWaitingForNextDraw] :
              []
            ),
          ],
        };
      }

      if (entity.value.betsDrawState.isLoss) {
        return {
          class: 'bg-danger text-basic-lightest',
          labels: [
            ...(entity.value.betsDrawState.isPartlyPaidOut ?
              [messages.value.ticketStatusIsPartlyPaidOut] :
              []
            ),
            messages.value.ticketStatusLoss,
            ...(entity.value.betsDrawState.isWaitingForNextDraw ?
              [messages.value.ticketStatusWaitingForNextDraw] :
              []
            ),
          ],
        };
      }

      if (entity.value.betsDrawState.isPaidOut) {
        return {
          class: 'bg-primary text-basic-lightest',
          labels: [messages.value.ticketStatusIsPaidOut],
        };
      }

      if (
        entity.value.betsDrawState.isCanceled ||
        entity.value.betsDrawState.isPartlyCanceled ||
        entity.value.betsDrawState.isPreCanceled ||
        entity.value.betsDrawState.isPartlyPreCanceled
      ) {
        return {
          class: 'bg-basic-darkest text-white',
          labels: [messages.value.ticketStatusCanceled],
        };
      }

      if (entity.value.betsDrawState.noWinsYet) {
        return {
          class: 'bg-warning text-basic-lightest',
          labels: [
            messages.value.ticketStatusNoWinsYet,
            ...(entity.value.betsDrawState.isWaitingForNextDraw ?
              [messages.value.ticketStatusWaitingForNextDraw] :
              []
            ),
          ],
        };
      }

      return {
        class: 'bg-warning text-basic-lightest',
        labels: [
          messages.value.ticketStatusNotEvaluated,
          ...(entity.value.betsDrawState.isWaitingForNextDraw ?
            [messages.value.ticketStatusWaitingForNextDraw] :
            []
          ),
        ],
      };
    });

    const resolvedIconByTicketState = computed(() => {
      if (entity.value.betsDrawState.isWin && !entity.value.betsDrawState.isPaidOut) {
        return require('@/assets/images/korunkaWin.svg').default;
      }

      if (entity.value.betsDrawState.isLoss) {
        return require('@/assets/images/korunkaLoss.svg').default;
      }

      if (
        entity.value.betsDrawState.isCanceled ||
        entity.value.betsDrawState.isPartlyCanceled ||
        entity.value.betsDrawState.isPreCanceled ||
        entity.value.betsDrawState.isPartlyPreCanceled
      ) {
        return require('@/assets/images/korunkaCanceled.svg').default;
      }

      if (entity.value.betsDrawState.isPaidOut) {
        return require('@/assets/images/korunkaPayedOut.svg').default;
      }

      return require('@/assets/images/korunkaNotEvaluated.svg').default;
    });

    const hasMultipleBetOnDraws = computed(() => {
      return ticket.value.betOnDraws.length > 1;
    });

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    return {
      interpolate,
      currentStepType,
      messages,
      isStepOverview,
      entity,
      ticket,
      korunkaSyncTarget,
      badge,
      resolvedIconByTicketState,
      currencyFormat,
      dateFormat,
      hasMultipleBetOnDraws,
    };
  },
});
