import ExchangeRate from './ExchangeRate';
import GeneratedOrderPaymentDto from './generated/OrderPaymentDto';
import PaymentTypeClass from './PaymentTypeClass';

export default class OrderPaymentDto extends GeneratedOrderPaymentDto {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get type(): PaymentTypeClass {
    return this.configurationStore.paymentButtonsByCode.value[this.paymentType.value]?.paymentType;
  }

  get isInLocalCurrency() {
    return this.currency === this.configurationStore.localCurrency.value.symbol;
  }

  get exchangeRateToLocalCurrency(): ExchangeRate {
    const exchangeRateKey = `${this.currency}:${this.configurationStore.localCurrency.value.symbol}`;
    return this.configurationStore.exchangeRatesByCurrencies.value[exchangeRateKey];
  }
}
