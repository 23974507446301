import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CashStateDto from '../CashStateDto';


export const CashStatesDtoPropDef = {
  cash: {
    type: new EntityType(CashStateDto),
  },
};
export default class GeneratedCashStatesDto extends createEntity(CashStatesDtoPropDef) {}
