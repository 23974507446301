import {
  ArrayType,
  createEntity,
} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class GIPChargeDtoCustom extends createEntity({
  receipt: {
    type: new ArrayType(new StringType()),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
