import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {apiPosGetSafeState} from '@/Model/Action';
import {
  every,
  toLower,
  toUpper,
} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {amIOnline} from '@/Helpers/onlineDetection';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export class WorkflowStepSafebagTransfer extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafebagTransfer;
  }

  get type() {
    return WorkflowStepSafebagTransfer.type;
  }

  get component() {
    return markRaw(require('./StepSafebagTransfer.vue').default);
  }

  get canBeReturnedTo() {
    return Promise.all([
      !this.step.isTechnicalStep,
      amIOnline(),
    ]).then(every);
  }

  async beforeEnter() {
    this.stepInit = false;
    const online = await amIOnline();

    if (!online) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const totalMoneyInSafe = (await apiPosGetSafeState())?.safe?.[toLower(this.step.currency)];
      const maxAllowedMoneyInSafe = this.configurationStore
        .configuration
        .value
        .features
        .cashLimits
        ?.[toUpper(this.step.currency)]
        .maxAmountInSafe;

      this.dataSetter('totalMoneyInSafe', () => totalMoneyInSafe);
      this.dataSetter('maxAllowedMoneyInSafe', () => maxAllowedMoneyInSafe);

      const safebagCreationNeeded = totalMoneyInSafe > maxAllowedMoneyInSafe;

      this.dataSetter('safebagCanContinue', () => !safebagCreationNeeded);
      this.disabledNextStep = safebagCreationNeeded;
      this.stepInit = true;

      emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:getSafeState`);
    } catch (e) {
      console.error(e);
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
