import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const DocumentItemGiftDtoPropDef = {
  giftUniqueIdentifier: {
    type: new StringType(),
  },
  promoCode: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentItemGiftDto extends createEntity(DocumentItemGiftDtoPropDef) {}
