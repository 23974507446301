import {AbstractPromoFlow, createNewReactive} from './AbstractPromoFlow';
import {RegisterState} from './../types';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {
  PromotionQuestion,
  promotionByType,
  PromotionMetaType,
} from '@designeo/pos-promotion-engine';
import {PromoInteractionFlowTypes} from '@/Modules/Register/PromoInteractionFlow/index';
import {action} from '@designeo/vue-helpers/src/index';
import {PromotionQuestionQuestionee} from '@designeo/pos-promotion-engine';

export class QuestionFlow extends AbstractPromoFlow {
  private questionAnsweredListener: any;
  private questionRequestListener: any;
  constructor(
    public questionId: PromotionQuestion['id'],
    public nextState?: RegisterState,
  ) {
    super(nextState);
  }

  static new = createNewReactive<QuestionFlow>()

  updateQuestion() {
    broadcastIO.postMessage(BroadcastIOChannels.QUESTION_CHANGED, this.question);
  }

  get isForCustomer() {
    return this.question?.questionee === PromotionQuestionQuestionee.CUSTOMER ||
      this.question?.questionee === PromotionQuestionQuestionee.CUSTOMER_AND_CASHIER;
  }

  get isForCashier() {
    return this.question?.questionee === PromotionQuestionQuestionee.CASHIER ||
      this.question?.questionee === PromotionQuestionQuestionee.CUSTOMER_AND_CASHIER;
  }

  async init() {
    if (this.isForCustomer) {
      const registerStore = useRegisterStore();

      this.questionRequestListener = action(() => {
        this.updateQuestion();
      });
      this.questionAnsweredListener = action(({detail: answer}) => {
        this.answerQuestion(answer);
      });

      broadcastIO.addEventListener(BroadcastIOChannels.QUESTION_REQUEST, this.questionRequestListener);
      broadcastIO.addEventListener(BroadcastIOChannels.QUESTION_ANSWERED, this.questionAnsweredListener, {once: true});
      setTimeout(() => {
        registerStore.ensureCustomerDisplayState();
        this.updateQuestion();
      });
    }
    if (!this.question) {
      await this.destroy(); // invalid question
    }
    await super.init();
  }

  async destroy() {
    if (this.isForCustomer) {
      broadcastIO.removeEventListener(BroadcastIOChannels.QUESTION_REQUEST, this.questionRequestListener);
      broadcastIO.removeEventListener(BroadcastIOChannels.QUESTION_ANSWERED, this.questionAnsweredListener);
    }
    await super.destroy();
  }


  get question() {
    const registerStore = useRegisterStore();
    // @ts-ignore
    return promotionByType(registerStore.sellDocument.value, PromotionMetaType.QUESTION)
      .find((question: PromotionQuestion) => question.id === this.questionId);
  }

  async answerQuestion(answer: PromotionQuestion['answers'][0]) {
    const question = this.question;
    const registerStore = useRegisterStore();
    if (question) {
      question.answer = answer ? answer.id : null;
      await registerStore.withAppLoader(async () => {
        await registerStore.fetchPromoEngineResult({triggerImmediatePromotions: false});
        await registerStore.persist();
      });
    } else {
      console.error('Answering invalid question');
    }
    await this.destroy();
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.QUESTION_FLOW,
      questionId: this.questionId,
      nextState: this.nextState,
    };
  }

  static deserialize(ctx): QuestionFlow {
    if (ctx === null) {
      return null;
    }
    const {
      questionId,
      nextState,
    } = ctx;
    return AbstractPromoFlow.baseDeserialize(
      QuestionFlow,
      {},
      [questionId, nextState],
    );
  }
}
