
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep, useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {BroadcastIOChannels, broadcastIO} from '@/Helpers/broadcastIO';
import {apiOrdersVerifyPin} from '@/Model/Action';
import {ErrorResponse, VerifyOrderPinRequestDto} from '@/Model/Entity';
import {WorkflowStepEshopPinVerification} from './WorkflowStepEshopPinVerification';
import {useI18n} from 'vue-i18n';
import OrderDetail from '../StepEshopOrderOverview/OrderDetail.vue';
import PhIcon from '@/Components/PhIcon.vue';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  name: 'StepEshopPinVerification',
  components: {
    WorkflowTransitions,
    HLStep,
    OrderDetail,
    PhIcon,
    AsyncButton,
  },
  setup() {
    const i18n = useI18n();
    const stepType = WorkflowStepTypes.EshopOrderOverview;
    const {
      workflowStep,
    } = useStepTools<WorkflowStepEshopPinVerification>(stepType);

    const onCustomerPinEnter = async ({detail: pin}: CustomEvent<string>) => {
      try {
        const verification = await apiOrdersVerifyPin({
          params: {
            id: workflowStep.value.order.data.id,
          },
          input: new VerifyOrderPinRequestDto({
            pin,
          }),
        });

        if (verification.isOk) {
          workflowStep.value.finish();
        } else {
          broadcastIO.postMessage(BroadcastIOChannels.PIN_VERIFICATION_FAILED, new ErrorResponse({
            errors: [
              {
                key: 'eshop_invalid_pin',
                title: 'Invalid PIN',
              },
            ],
          }));
        }
      } catch (err) {
        broadcastIO.postMessage(BroadcastIOChannels.PIN_VERIFICATION_FAILED, err);
      }
    };

    const onGetWorkflowStep = async () => {
      const eshop = workflowStep.value.eshop?.code;
      const orderNumber = workflowStep.value.order?.data.orderNumber;
      broadcastIO.postMessage(BroadcastIOChannels.GET_WORKFLOW_STEP, {
        customerInstructions: i18n.t('workflow.steps.eshopPinVerification.customerInstructions', {eshop, orderNumber}),
      });
    };

    onMounted(() => {
      if (!workflowStep.value.isPinVerified) {
        broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-pin-verification'});
        broadcastIO.addEventListener(BroadcastIOChannels.CUSTOMER_PIN_RESULT, onCustomerPinEnter);
        broadcastIO.addEventListener(BroadcastIOChannels.GET_WORKFLOW_STEP, onGetWorkflowStep);
      }
    });

    onBeforeUnmount(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      broadcastIO.removeEventListener(BroadcastIOChannels.CUSTOMER_PIN_RESULT, onCustomerPinEnter);
      broadcastIO.removeEventListener(BroadcastIOChannels.GET_WORKFLOW_STEP, onGetWorkflowStep);
    });

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
