import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {isNil} from 'lodash-es';
import {markRaw} from 'vue';

export class WorkflowStepBTCConditionsAgreement extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.BTCConditionsAgreement;
  }

  get type() {
    return WorkflowStepBTCConditionsAgreement.type;
  }

  get component() {
    return markRaw(require('./StepBTCConditionsAgreement.vue').default);
  }

  async beforeContinue() {
    // dont set step.finished
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.conditions, (fieldActions) => ({
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<boolean>) => {
          if (!isNil(event.value)) {
            if (event.value) {
              fieldActions[WorkflowActions.ENTER](event.value);
              this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
            } else {
              this.errorsSetter(
                WorkflowStepField.conditions,
                (errors) => errors.concat(WorkflowStepErrors.CONDITIONS_DECLINED),
              );
              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                detail: {
                  type: WorkflowStepErrors.CONDITIONS_DECLINED,
                },
              }));
            }
          }
        }),
      })),
    };
  }
}
