import {Context} from '@/Helpers/Context';
import ResolverByTwoWayCommunication, {
  IResolverByTwoWayCommunication,
} from '@/Modules/Payment/resolvers/ResolverByTwoWayCommunication';
import {
  IResolver,
  ResolverTypes,
} from '@/Modules/Payment/resolvers/IResolver';
import {DocumentDto, ResultDto} from '@/Model/Entity';

export enum PaymentModes {
  Create = 'create',
  Cancel = 'cancel',
  Refund = 'refund',
}

export enum PaymentEvents {
  resultsReceived = 'resultsReceived',
  triggerDocumentCreated = 'triggerDocumentCreated',
}

export interface IPaymentContextState {
  value: number,
  paymentId: number,

  mode: PaymentModes,

  referentialUniqueId: string,
  verifyDocumentId: string,
  payTerminalVirtualId: string,
  referenceNumber: string,

  resultBuffer: ResultDto[],
}

export type PaymentContext = Context & {state: IPaymentContextState}

export interface IPaymentEvent {
  event: PaymentEvents,
  callback: (...args: any[])=> Promise<void> | void
}

export interface IPaymentEventResultReceived extends IPaymentEvent {
  event: PaymentEvents.resultsReceived,
  callback: (results: ResultDto[])=> Promise<void> | void
}

export interface IPaymentEventTriggerDocumentCreated extends IPaymentEvent {
  event: PaymentEvents.triggerDocumentCreated,
  callback: (triggerDocument: DocumentDto)=> Promise<void> | void
}

export type PaymentEventToRegisterArgs<R extends IPaymentEvent> = [
  R['event'],
  R['callback']
]

export type PaymentEventToEmitArgs<R extends IPaymentEvent> = [
  R['event'],
  ...Parameters<R['callback']>
];

export type PaymentEventRegisterArgs = PaymentEventToRegisterArgs<IPaymentEventResultReceived>
  | PaymentEventToRegisterArgs<IPaymentEventTriggerDocumentCreated>;

export type PaymentEventEmitArgs = PaymentEventToEmitArgs<IPaymentEventResultReceived>
  | PaymentEventToEmitArgs<IPaymentEventTriggerDocumentCreated>;

export class Payment {
  protected resolver: IResolver

  public static deserialize(data: any): Payment {
    if (data.type === ResolverTypes.ByTwoWayCommunication) {
      return new Payment(ResolverByTwoWayCommunication.deserialize(data.contextState));
    } else {
      throw new Error(`Unknown resolver type: ${data.resolver.type}`);
    }
  }

  public static serialize(payment: Payment): any {
    if (payment.resolver.type === ResolverTypes.ByTwoWayCommunication) {
      return {
        type: payment.resolver.type,
        contextState: ResolverByTwoWayCommunication.serialize(payment.resolver as IResolverByTwoWayCommunication),
      };
    } else {
      throw new Error(`Unknown resolver type: ${payment.resolver.type}`);
    }
  }

  public static create(
    {
      value,
      paymentId,
      payTerminalVirtualId,
      referentialUniqueId = null,
      referenceNumber = null,
      resolverType = ResolverTypes.ByTwoWayCommunication,
      mode = PaymentModes.Create,
    }: {
      value: number,
      paymentId: number,
      payTerminalVirtualId?: string,
      referentialUniqueId?: string
      referenceNumber?: string
      resolverType?: ResolverTypes
      mode?: PaymentModes,
    },
  ) {
    if (resolverType === ResolverTypes.ByTwoWayCommunication) {
      const context = new Context(<Partial<IPaymentContextState>>{
        value,
        paymentId,
        payTerminalVirtualId,
        referentialUniqueId,
        referenceNumber,
        mode,
      });

      const resolver = new ResolverByTwoWayCommunication(context);

      return new Payment(resolver);
    } else {
      throw new Error(`Unknown resolver type: ${resolverType}`);
    }
  }

  constructor(resolver: IResolver) {
    this.resolver = resolver;
  }

  public static restore(
    verifyDocumentId: string,
    {
      resolverType = ResolverTypes.ByTwoWayCommunication,
    }: {
      resolverType?: ResolverTypes,
    } = {},
  ) {
    if (resolverType === ResolverTypes.ByTwoWayCommunication) {
      const context = new Context(<Partial<IPaymentContextState>>{
        verifyDocumentId,
      });

      const resolver = new ResolverByTwoWayCommunication(context);

      return new Payment(resolver);
    } else {
      throw new Error(`Unknown resolver type: ${resolverType}`);
    }
  }

  on(...args: PaymentEventToRegisterArgs<IPaymentEventResultReceived>): this
  on(...args: PaymentEventToRegisterArgs<IPaymentEventTriggerDocumentCreated>): this
  on(...args: PaymentEventRegisterArgs) {
    this.resolver.on(...args);
    return this;
  }

  get isSuccessful() {
    return this.resolver.isSuccessful;
  }

  get isResolved() {
    return this.resolver.isResolved;
  }

  get isTerminated() {
    return this.resolver.isTerminated;
  }

  get verifyDocumentId() {
    return this.resolver.verifyDocumentId;
  }

  async process() {
    return await this.resolver.process();
  }
}
