import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "pt-4 text-2xl pb-3 text-danger font-bold" }
const _hoisted_3 = { class: "pb-5 text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    name: 'synchronizationModal',
    open: _ctx.open,
    invalidateRoute: false
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('synchronizationState.title')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('synchronizationState.inProgress')), 1)
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}