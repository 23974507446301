
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,

} from '@/Modules/Workflow/Step/StepTools';
import FormInputNominal from '@/Components/FormInputNominal.vue';
import {useFilters} from '@/Helpers/filters';
import {HLScrollIntoView} from '@/Components/HLScrollIntoView';
import InputBuffer from '@/Components/InputBuffer.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {
  WorkflowDrawerCashStateResult,
} from '@/Modules/Workflow/Step/StepDrawerCashStateResult/WorkflowDrawerCashStateResult';

export default defineComponent({
  name: 'StepDrawerCashStateResult',
  components: {
    AsyncButton,
    InputBuffer,
    HLScrollIntoView,
    FormInputNominal,
    HLStep,
    HLInput,
  },
  setup() {
    const stepType = WorkflowStepTypes.DrawerCashStateResult;
    const {workflowStep} = useStepTools<WorkflowDrawerCashStateResult>(stepType);
    const {currencyFormat} = useFilters();

    const canPrint = computed(() => {
      return workflowStep.value.canPrint;
    });

    const print = async () => {
      await workflowStep.value.print();
    };

    return {
      currencyFormat,
      stepType,
      WorkflowStepField,
      print,
      canPrint,
    };
  },
});
