import {guid} from '@/Helpers/guid';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {Canceler} from 'axios';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  reloadApp,
} from '@/Helpers/app';
import {
  find,
  isNil,
  map,
  reject,
  last,
  orderBy,
} from 'lodash-es';
import {
  ConfirmTemplates,
  ConfirmTypes,
  Modal,
} from '@/Modules/Core/types';
import {KEYBOARD_KEY_ENTER, KEYBOARD_KEY_ESCAPE} from '@/constants/keyboardKeys';
import {ZLayer} from '@/constants/zLayer';
import {AppVersion} from '@/constants/appVersion';
import {LoaderControls} from '@/constants/loader';
import {seekApiErrors, useErrorParser} from '@/Helpers/errors';
import {recordCustomEventLogEntry} from '@/Helpers/logger';

export interface ICoreStore {
  appVersion: AppVersion,
  apiRequestCancelers: Map<string, Canceler>,
  keyboardBufferMute: {
    state: number,
  },
  modalStack: Modal[],
  loader: {
    state: number,
    opacity: number,
    timeout: ReturnType<typeof setTimeout>,
    timeoutExpired: boolean,
    timeoutDisabled: boolean,
    controls: Array<LoaderControls>
  },
  confirm: {
    promise: Promise<boolean>,
    message: string,
    resolve: (value?: boolean)=> void,
    reject?: (reason?: any)=> void,
    type: ConfirmTypes,
    template: ConfirmTemplates
  },
  requestCredentials: any,
}


const createInitState = () => ({
  appVersion: null,
  apiRequestCancelers: new Map(),
  keyboardBufferMute: {
    state: 0,
  },
  modalStack: [],
  loader: {
    state: 0,
    opacity: null,
    timeout: null,
    timeoutExpired: false,
    timeoutDisabled: false,
    controls: [],
  },
  confirm: null,
  requestCredentials: null,
});

export class CoreStore extends Store<ICoreStore> {
  constructor() {
    super(createInitState());

    this.setupModalListeners();
  }

  apiRequestCancelers = getter(() => {
    return Array.from(this.state.apiRequestCancelers.entries());
  })

  setApiRequestCanceler = action((key: string, canceler) => {
    this.state.apiRequestCancelers.set(key, canceler);
  })

  setRequestCredentials = action((credentials) => {
    this.state.requestCredentials = credentials;
  })

  getApiRequestCanceler = action((key) => {
    this.state.apiRequestCancelers.get(key);
  })

  clearApiRequestCanceler = action((key: string) => {
    this.state.apiRequestCancelers.delete(key);
  })

  setKeyboardBufferMute = action((state: boolean) => {
    this.state.keyboardBufferMute.state = this.state.keyboardBufferMute.state + (state ? 1 : -1);
  })

  setLoader = action((
    state: boolean,
    {
      opacity = this.state.loader.opacity ?? this.defaultLoaderOpacity.value,
      timeout = this.defaultLoaderTimeout.value,
      controls = this.defaultLoaderControls.value,
    } = {},
  ) => {
    this.state.loader.state = this.state.loader.state + (state ? 1 : -1);

    if (!this.loaderActive.value) { // reset
      this.state.loader.opacity = this.defaultLoaderOpacity.value;
      this.state.loader.controls = this.defaultLoaderControls.value;
      clearTimeout(this.state.loader.timeout);
      this.state.loader.timeoutExpired = false;
      this.state.loader.timeoutDisabled = false;
    } else {
      this.state.loader.opacity = opacity;
      this.state.loader.controls = controls;
      clearTimeout(this.state.loader.timeout);

      if (this.state.loader.timeoutDisabled) {
        timeout = null;
      }

      if (isNil(timeout)) {
        this.state.loader.timeoutDisabled = true;
      } else {
        this.state.loader.timeout = setTimeout(() => {
          recordCustomEventLogEntry('CoreStore setLoader', 'timeoutExpired');
          this.state.loader.timeoutExpired = true;
        }, timeout);
      }
    }
  })

  modalStackInZOrder = getter(() => {
    return orderBy(this.state.modalStack, ({zIndexBase, zIndexOffset}) => zIndexBase + zIndexOffset);
  });

  topModal = getter(() => {
    return last(this.modalStackInZOrder.value);
  });

  requestModal = action((closeCallback: Modal['closeCallback'], {
    id = guid(),
    zIndexBase = ZLayer.modal,
    silenceKeyboardBuffer = true,
    silenceCloseListeners = false,
    spawnedAtRoute,
  }: Partial<Modal> = {}) => {
    const zIndexOffset = Math.max(...map(this.state.modalStack, (modal) => modal.zIndexOffset).concat(0)) + 1;
    const newModal: Modal = {
      id,
      silenceKeyboardBuffer,
      silenceCloseListeners,
      zIndexBase,
      zIndexOffset,
      closeCallback,
      spawnedAtRoute,
    };
    this.state.modalStack.push(newModal);
    return newModal;
  })

  updateModal = action((id: string, {
    silenceKeyboardBuffer,
    zIndexBase,
  }: {
    silenceKeyboardBuffer?: boolean,
    zIndexBase?: number,
  }) => {
    const modal = this.state.modalStack.find(({id: modalId}) => modalId === id);
    if (modal) {
      modal.silenceKeyboardBuffer = silenceKeyboardBuffer ?? modal.silenceKeyboardBuffer;
      modal.zIndexBase = zIndexBase ?? modal.zIndexBase;
    }
  });

  removeModal = action((id: string) => {
    this.state.modalStack = reject(this.state.modalStack, ({id: modalId}) => modalId === id);
  })

  closeTopModal = action(async (state: boolean = false) => {
    const topModal = this.topModal.value;

    if (!topModal) {
      return;
    }

    this.topModal.value?.closeCallback(state);
  })

  setupModalListeners = action(() => {
    let wasOpenedOnKeyDown = false;

    const isEventTypeClose = (event: KeyboardEvent) => {
      if (this.topModal.value?.silenceCloseListeners ?? false) {
        return false;
      }


      /**
       * Don't we want to also cover KEYBOARD_KEY_ESCAPE under not silenced keyboard buffer?
       *
       * if silenceKeyboardBuffer !== true, some transition maybe wants to work with event CANCEL,
       * that is emitted by escape, and it is clash now
       */

      if (event.code === KEYBOARD_KEY_ESCAPE) {
        return true;
      }

      if (event.code === KEYBOARD_KEY_ENTER && this.topModal.value?.silenceKeyboardBuffer) {
        return true;
      }

      return false;
    };

    const onKeyDown = async (event: KeyboardEvent) => {
      if (!isEventTypeClose(event)) {
        return;
      }

      if (this.topModal.value) {
        event.preventDefault();
        wasOpenedOnKeyDown = true;
      }
    };

    const onKeyUp = async (event: KeyboardEvent) => {
      if (!isEventTypeClose(event)) {
        return;
      }
      /**
       * we want to execute this as last in keyboard buffers pipeline
       */
      // await wait(50)(null);

      if (this.topModal.value) {
        event.preventDefault();
      }

      if (wasOpenedOnKeyDown) {
        this.closeTopModal(event.code === KEYBOARD_KEY_ENTER);
        wasOpenedOnKeyDown = false;
      }
    };

    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keydown', onKeyDown);
  })

  resetState = action(() => {
    this.state = Object.assign(this.state, createInitState());
  })

  keyboardBufferMuteActive = getter(() => {
    return this.state.keyboardBufferMute.state !== 0;
  })

  loaderActive = getter(() => {
    return this.state.loader.state !== 0;
  });

  keyboardBufferIsMuted = getter(() => {
    return this.loaderActive.value || this.topModal.value?.silenceKeyboardBuffer || this.keyboardBufferMuteActive.value;
  })

  loaderOpacity = getter(() => {
    return this.state.loader.opacity * 100;
  })

  loaderControls = getter(() => {
    return this.state.loader?.controls ?? [];
  })

  defaultLoaderOpacity = getter(() => {
    return 0.4;
  })

  defaultLoaderControls = getter(() => {
    return [LoaderControls.cancelRequests, LoaderControls.wait, LoaderControls.reload];
  })

  defaultLoaderTimeout = getter(() => {
    return (useConfigurationStore().configuration.value?.general?.loaderTimeout ?? 30) * 1000;
  })

  loaderTimeoutExpired = getter(() => {
    return this.state.loader.timeoutExpired;
  })

  confirmContent = getter(() => this.state.confirm);

  closeAlertOrConfirm = action(() => {
    if (this.state.confirm?.reject) {
      this.state.confirm?.reject();
    } else if (this.state.confirm?.resolve) {
      this.state.confirm?.resolve();
    }
  })

  /**
   * TODO 5.0 and next
   * replace all `coreStore.alert(parseError(seekApiErrors(error)).join(', '), {type: ConfirmTypes.error})`
   * with coreStore.throw(error)!
   */
  throw = action(async (error: Error) => {
    const parseError = useErrorParser();
    await this.alert(parseError(seekApiErrors(error)).join(', '), {type: ConfirmTypes.error});
  })

  alert = action((message, {
    type = ConfirmTypes.default,
    template = ConfirmTemplates.default,
  }: {
    type?: ConfirmTypes,
    template?: ConfirmTemplates
  } = {}) => {
    let resolve = null;

    const promise = (new Promise<boolean>((onFulfilled, onRejected) => {
      resolve = () => onFulfilled(true);
    }))
      .then((result) => {
        this.state.confirm = null;
        return result;
      })
      .catch((err) => {
        this.state.confirm = null;
        throw err;
      });


    this.state.confirm = {
      promise,
      message,
      resolve,
      type,
      template,
    };

    return promise;
  })

  confirm = action((message, {
    alwaysResolve = true,
    type = ConfirmTypes.default,
    template = ConfirmTemplates.default,
  }: {
    alwaysResolve?: boolean,
    type?: ConfirmTypes,
    template?: ConfirmTemplates
  } = {}) => {
    let resolve = null;
    let reject = null;

    const promise = (new Promise<boolean>((onFulfilled, onRejected) => {
      [resolve, reject] = (alwaysResolve ? [
        () => onFulfilled(true),
        () => onFulfilled(false),
      ] : [
        onFulfilled,
        onRejected,
      ]);
    }))
      .then((result: boolean) => {
        this.state.confirm = null;
        return result;
      })
      .catch((err) => {
        this.state.confirm = null;
        throw err;
      });


    this.state.confirm = {
      promise,
      message,
      resolve,
      reject,
      type,
      template,
    };

    return promise;
  })

  processExpirationQuestionReload = action(() => {
    recordCustomEventLogEntry('CoreStore processExpirationQuestionReload', 'reloadApp');
    reloadApp();
  })

  processExpirationQuestionWait = action(() => {
    clearTimeout(this.state.loader.timeout);
    this.state.loader.timeoutExpired = false;
    this.state.loader.timeout = setTimeout(() => {
      recordCustomEventLogEntry('CoreStore processExpirationQuestionWait', 'timeoutExpired');
      this.state.loader.timeoutExpired = true;
    }, this.defaultLoaderTimeout.value);
  })

  processExpirationQuestionCancelRequests = action((reason: string) => {
    recordCustomEventLogEntry('CoreStore processExpirationQuestionCancelRequests', 'cancelRequests');
    for (const [, canceler] of this.apiRequestCancelers.value) {
      canceler(reason);
    }
  })

  setAppVersion = action((appVersion: AppVersion) => {
    this.state.appVersion = appVersion;
  })

  appVersion = getter(() => {
    return this.state.appVersion;
  })

  isAppVersionPos = getter(() => {
    return this.state.appVersion === AppVersion.Pos;
  })

  isAppVersionPda = getter(() => {
    return this.state.appVersion === AppVersion.Pda;
  })

  isAppVersionIframeWrapper = getter(() => {
    return this.state.appVersion === AppVersion.IframeWrapper;
  })

  isAppVersionIncorrectRestart = getter(() => {
    return this.state.appVersion === AppVersion.IncorrectRestart;
  })

  isAppVersionResetToShop = getter(() => {
    return this.state.appVersion === AppVersion.ResetToShop;
  })

  isAppVersionStartup = getter(() => {
    return this.state.appVersion === AppVersion.Startup;
  })

  requestCredentials = getter(() => {
    return this.state.requestCredentials;
  })
}

const storeIdentifier = 'CoreStore';

export const configureCoreStore = createConfigureStore<typeof CoreStore>(storeIdentifier);
export const useCoreStore = createUseStore(CoreStore, storeIdentifier);
