import {
  computed,
  defineComponent,
  h,
  inject,
  markRaw,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  ref,

} from 'vue';
import {
  find,
  findIndex,
  map,
} from 'lodash-es';
import {KeyboardShortcutsEvents, useKeyboardShortcutsStore} from '@/Modules/Core/store/KeyboardShortuctsStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

type MandatoryKeyboardEventProps = Pick<KeyboardEvent, 'key' | 'ctrlKey' | 'shiftKey' | 'altKey'>

const invalidateAndExtract = (arr: string[], regExp: RegExp) => {
  const index = findIndex(arr, (item) => regExp.test(item));

  if (index !== -1) {
    arr.splice(index, 1);
    return true;
  }

  return false;
};

const strToMandatoryKeyboardEventProps = (str): MandatoryKeyboardEventProps => {
  const props = str.split(/\+/g);


  const shiftKey = invalidateAndExtract(props, /^shift$/);
  const ctrlKey = invalidateAndExtract(props, /^ctrl$/);
  const altKey = invalidateAndExtract(props, /^alt$/);
  const key = props[props.length - 1];


  return {
    shiftKey,
    ctrlKey,
    altKey,
    key,
  };
};

const eventsAreEqual = (event1: MandatoryKeyboardEventProps, event2: MandatoryKeyboardEventProps): boolean => {
  const event1Code = event1.shiftKey ? event1.key.toUpperCase() : event1.key.toLowerCase();
  const event2Code = event2.shiftKey ? event2.key.toUpperCase() : event2.key.toLowerCase();
  return (
    event1Code === event2Code &&
    event1.altKey === event2.altKey &&
    event1.shiftKey === event2.shiftKey &&
    event1.ctrlKey === event2.ctrlKey
  );
};

export const silencedCombinations: MandatoryKeyboardEventProps[] = [];

export const indexOfKeyboardEvent = (keyboardEvent: MandatoryKeyboardEventProps) => {
  return findIndex(silencedCombinations, (combination) => eventsAreEqual(combination, keyboardEvent));
};

export const useKeyboardShortcuts = () => {
  const keyboardShortcutsStore = useKeyboardShortcutsStore();
  const coreStore = useCoreStore();

  const systemShortcuts: {
    [key: string]: {
      keys: MandatoryKeyboardEventProps,
      callback: (event: CustomEvent<MandatoryKeyboardEventProps>)=> void
    }
  } = {
    toggleHints: {
      keys: {
        key: 'K',
        altKey: false,
        ctrlKey: true,
        shiftKey: true,
      },
      callback: (event) => {
        if (!eventsAreEqual(event.detail, systemShortcuts.toggleHints.keys)) return;
        keyboardShortcutsStore.setVisible(!keyboardShortcutsStore.visible.value);
      },
    },
  };

  const interceptor = (event: KeyboardEvent) => {
    if (indexOfKeyboardEvent(event) !== -1) {
      event.preventDefault();
      if (!coreStore.keyboardBufferIsMuted.value) {
        keyboardShortcutsStore.dispatchEvent(new CustomEvent(KeyboardShortcutsEvents.shortcutHit, {
          detail: event,
        }));
      }
    }
  };

  const onRegisterShortcut = (event: CustomEvent<MandatoryKeyboardEventProps>) => {
    if (!keyboardShortcutsStore.active.value) {
      return;
    }

    const keyIndex = indexOfKeyboardEvent(event.detail);
    if (keyIndex === -1) {
      silencedCombinations.push(event.detail);
    } else {
      console.warn(`Shortcut already exists!`, event.detail);
    }
  };

  const onUnregisterShortcut = (event: CustomEvent<MandatoryKeyboardEventProps>) => {
    if (!keyboardShortcutsStore.active.value) {
      return;
    }

    const keyIndex = indexOfKeyboardEvent(event.detail);
    if (keyIndex !== -1) {
      silencedCombinations.splice(keyIndex, 1);
    }
  };

  const registerSystemShortcuts = () => {
    for (const systemShortcut of Object.keys(systemShortcuts)) {
      onRegisterShortcut(new CustomEvent(KeyboardShortcutsEvents.registerShortcut, {
        detail: systemShortcuts[systemShortcut].keys,
      }));

      keyboardShortcutsStore.addEventListener(
        KeyboardShortcutsEvents.shortcutHit,
        systemShortcuts[systemShortcut].callback,
      );
    }
  };

  const unregisterSystemShortcuts = () => {
    for (const systemShortcut of Object.keys(systemShortcuts)) {
      onUnregisterShortcut(new CustomEvent(KeyboardShortcutsEvents.unregisterShortcut, {
        detail: systemShortcuts[systemShortcut].keys,
      }));

      keyboardShortcutsStore.removeEventListener(
        KeyboardShortcutsEvents.shortcutHit,
        systemShortcuts[systemShortcut].callback,
      );
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', interceptor);
    keyboardShortcutsStore.addEventListener(KeyboardShortcutsEvents.registerShortcut, onRegisterShortcut);
    keyboardShortcutsStore.addEventListener(KeyboardShortcutsEvents.unregisterShortcut, onUnregisterShortcut);
    registerSystemShortcuts();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', interceptor);
    keyboardShortcutsStore.removeEventListener(KeyboardShortcutsEvents.registerShortcut, onRegisterShortcut);
    keyboardShortcutsStore.removeEventListener(KeyboardShortcutsEvents.unregisterShortcut, onUnregisterShortcut);
    unregisterSystemShortcuts();
  });
};

export const HLKeyboardShortcutInjectionKey = 'HLKeyboardShortcutIO';

interface HLKeyboardShortcutIOInterface {
  /**
   * TODO
   */
}

export const HLKeyboardShortcutGroup = defineComponent({
  name: 'HLKeyboardShortcutGroup',
  props: {

  },
  setup(props, {emit}) {
    provide(HLKeyboardShortcutInjectionKey, <HLKeyboardShortcutIOInterface>{});

    /** *
     * Todo:
     * group active?
     */

    return {

    };
  },
  render() {
    const slotData = {};
    const content = (this.$slots?.default?.(slotData) ?? []);

    return map(content, (slot) => h(slot));
  },
});

const shortcutKeysToAlias = {
  'Backspace': '↤',
};

export const normalizeShortcut = (val): MandatoryKeyboardEventProps => {
  if (typeof val === 'string') {
    return strToMandatoryKeyboardEventProps(val);
  }

  return val;
};
export const minimizeShortcut = (shortcut: MandatoryKeyboardEventProps) => {
  return [
    ...(shortcut.ctrlKey ? ['c'] : []),
    ...(shortcut.shiftKey ? ['s'] : []),
    ...(shortcut.altKey ? ['a'] : []),
    shortcutKeysToAlias[shortcut.key] ?? shortcut.key,
  ].join('+');
};

/**
 * !NOTE! in electron some shortcuts are prevented on electron layer
 * app/src/inputSanitizer.js
 */
export const HLKeyboardShortcutItem = defineComponent({
  name: 'HLKeyboardShortcutItem',
  props: {
    shortcut: {
      type: null as PropType<string | MandatoryKeyboardEventProps>,
      required: true,
    },
    wrap: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, {emit}) {
    const elements = [];
    const keyboardShortcutsStore = useKeyboardShortcutsStore();

    const io: HLKeyboardShortcutIOInterface = inject(HLKeyboardShortcutInjectionKey, {});

    if (!io) {
      throw new Error('HLKeyboardShortcutGroup not found!');
    }

    const normalizedShortcut = computed<MandatoryKeyboardEventProps>(() => normalizeShortcut(props.shortcut));

    const shortcutXs = computed(() => minimizeShortcut(normalizedShortcut.value));

    const registerShortcut = () => {
      keyboardShortcutsStore.dispatchEvent(
        new CustomEvent(KeyboardShortcutsEvents.registerShortcut, {
          detail: normalizedShortcut.value,
        }),
      );
    };

    const unregisterShortcut = () => {
      keyboardShortcutsStore.dispatchEvent(
        new CustomEvent(KeyboardShortcutsEvents.unregisterShortcut, {
          detail: normalizedShortcut.value,
        }),
      );
    };

    const clickOnElement = (el: HTMLElement) => {
      el.dispatchEvent(new MouseEvent('mousedown'));
      el.click();
      el.dispatchEvent(new MouseEvent('mouseup'));
    };

    const onShortcutHit = (event: CustomEvent<MandatoryKeyboardEventProps>) => {
      if (!eventsAreEqual(event.detail, normalizedShortcut.value)) return;

      const element = find(elements, (el) => {
        return !Object.prototype.hasOwnProperty.call({
          ...el?.$el?.dataset ?? {},
          ...el?.dataset ?? {},
        }, 'hint');
      });

      if (!element) return;

      if (element instanceof Text) {
        element.parentElement.click();
      } else if (element instanceof HTMLElement) {
        clickOnElement(element);
      } else {
        clickOnElement(element.$el);
      }
    };

    onMounted(async () => {
      await nextTick();
      registerShortcut();

      keyboardShortcutsStore.addEventListener(KeyboardShortcutsEvents.shortcutHit, onShortcutHit);
    });

    onBeforeUnmount(() => {
      unregisterShortcut();

      keyboardShortcutsStore.removeEventListener(KeyboardShortcutsEvents.shortcutHit, onShortcutHit);
    });

    return {
      elements,
      shortcutXs,
    };
  },
  render() {
    const slotData = {
      shortcut: this.shortcutXs,
    };

    const content = (this.$slots?.default?.(slotData) ?? []);

    this.elements.splice(0, this.elements.length);

    const slotElements = map(content, (slot, index) => {
      return h(
        slot,
        {
          ref: (el) => {
            if (!el) return;

            this.elements.push(el);
          },
          key: index,
        },
      );
    });

    if (this.wrap) {
      return h(this.wrap, slotElements);
    }

    return slotElements;
  },
});
