
import {defineComponent, PropType} from 'vue';
import PlantDto from '@/Model/Entity/PlantDto';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<{isActive: boolean, entity: PlantDto}>,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
  },
});
