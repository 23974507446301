
import {
  computed,
  defineComponent,
} from 'vue';
import {useFilters} from '@/Helpers/filters';
import KorunkaLotterySestkaDtoCustom from '@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom';
import {useKorunkaSyncTarget} from '../../syncTarget/KorunkaSyncTarget';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import KorunkaLotteryScanResultDtoCustom from '@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom';

export default defineComponent({
  setup() {
    const {dateFormat, currencyFormat: baseCurrencyFormat, interpolate} = useFilters();
    const korunkaSyncTarget = useKorunkaSyncTarget();
    const configurationStore = useConfigurationStore();

    const currentStepType = computed(() => korunkaSyncTarget.stepType.value);

    const isStepOverview = computed(() => currentStepType.value === WorkflowStepTypes.KorunkaOverview);

    const entity = computed(() => {
      return korunkaSyncTarget.entity.value as KorunkaLotteryScanResultDtoCustom;
    });

    const activeTicketEntity = computed(() => {
      return entity.value.activeTicketGameEntity as KorunkaLotterySestkaDtoCustom;
    });

    const messages = computed(() => korunkaSyncTarget.messages.value);

    const drawsDates = computed(() => {
      if (!entity.value?.firstAndLastDraw) {
        return null;
      }

      return entity.value.firstAndLastDraw
        .map((value) => dateFormat(value.deadlineForBets, {format: 'dd. MM.'}))
        .join(' - ');
    });

    const canBeDeclined = computed(() => {
      if (!entity.value) {
        return false;
      }

      if (entity.value.isFromScan) {
        return false;
      }


      return true;
    });

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    const onAccept = () => {
      broadcastIO.postMessage(BroadcastIOChannels.KORUNKA_LOTTERY_CONFIRM_RESULT, true);
    };

    const onDecline = () => {
      broadcastIO.postMessage(BroadcastIOChannels.KORUNKA_LOTTERY_CONFIRM_RESULT, false);
    };

    const onPreviousTicket = () => {
      broadcastIO.postMessage(BroadcastIOChannels.KORUNKA_LOTTERY_PREVIOUS_TICKET);
    };

    const onNextTicket = () => {
      broadcastIO.postMessage(BroadcastIOChannels.KORUNKA_LOTTERY_NEXT_TICKET);
    };

    return {
      currentStepType,
      currencyFormat,
      drawsDates,
      entity,
      activeTicketEntity,
      messages,
      isStepOverview,
      onAccept,
      onDecline,
      korunkaSyncTarget,
      canBeDeclined,
      onPreviousTicket,
      onNextTicket,
      interpolate,
    };
  },
});
