import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const MessageAttachmentDtoPropDef = {
  id: {
    type: new StringType(),
  },
  messageGuid: {
    type: new StringType(),
  },
  fileName: {
    type: new StringType(),
  },
  mimeType: {
    type: new StringType(),
  },
};
export default class GeneratedMessageAttachmentDto extends createEntity(MessageAttachmentDtoPropDef) {}
