import {
  StockDto,
  DocumentDto,
  DocumentItemDto,
  DocumentLogisticItemDto,
} from '@/Model/Entity';
import ReferentialDocumentsDto from '@/Model/Entity/ReferentialDocumentsDto';

export enum InputInvalidReason {
  ARTICLE_IS_NOT_EXPANDABLE_SET = 'articleIsNotExpandableSet',
  ARTICLE_IS_TYPE_SERVICE = 'articleIsTypeService',
  ARTICLE_NOT_FOUND = 'articleNotFound',
  ARTICLE_ALREADY_IN_DOCUMENT = 'articleAlreadyInDocument',
  INPUT_INVALID = 'inputInvalid'
}

export interface InventorySearchResult {
  documentLogisticItem: DocumentLogisticItemDto
  documentItem: DocumentItemDto
  match: {
    gtinAddonExactMatch: boolean,
    gtinBaseExactMatch: boolean,
    gtinHasAddon: boolean
  }
}

export interface InventoryStockInput {
  invalidReason: InputInvalidReason | null,
  code: string,
  item: DocumentLogisticItemDto
  value: number
  lastValue: number | undefined
}

export interface InventoryStockInputSet {
  item: DocumentItemDto
  value: number
}

export type InventoryStockInputEvent<ValueType = any> = {
  type: InventoryStockActions,
  value?: ValueType
}

export enum InventoryStockType {
  MOVABLE_STOCK = 'floating',
  IMMOVABLE_STOCK = 'fixed',
}

export enum InventoryStockActions {
  ADD_CHAR = 'addChar',
  ADD_NUMBER = 'addNumber',
  ENTER = 'enter',
  BACKSPACE = 'backspace',
  CLEAR = 'clear',
  CANCEL = 'cancel',
  NEXT = 'next',
  ADD_PLUS = 'addPlus',
  ADD_MINUS = 'addMinus',
  SCANNER = 'scanner',
  INIT = 'init'
}

export enum InventoryStockEvent {
  CANCEL_INVENTORY = 'cancelInventory',
  ARTICLE_REMOVE = 'articleRemove',
  SCROLL_TO = 'scrollTo',
  SEARCH = 'search',
}

export enum InventoryStockState {
  ENTER_CODE = 'enterCode',
  ENTER_QUANTITY = 'enterQuantity',
  SELECT_ITEM_VARIATION = 'selectItemVariation',
  ITEM_DETAIL = 'itemDetail',
  ENTER_EDIT_RECORD = 'enterEditRecord',
  SEARCH_PRODUCTS = 'searchProducts',
  SEARCH_DOCUMENT_BY_ARTICLE = 'searchDocumentByArticle',
  INPUT_SET = 'inputSet'
}

export enum InventoryStockErrors {
  INVALID_LOGISTIC_ITEMS = 'invalidLogisticItems',
}

export interface InventoryStockStoreState {
  state: InventoryStockState,
  previousState: InventoryStockState,
  inventoryStockInput: InventoryStockInput,
  inventoryStockInputSet: InventoryStockInputSet,
  document: DocumentDto,
  productDetail: {
    documentItem: DocumentItemDto,
    canSelectVariation: boolean
  },
  loadedItems: Array<InventorySearchResult>,
  insertMode: boolean
  networkError: boolean
  lastProcessedItem: DocumentLogisticItemDto,
  fractionDocumentList: ReferentialDocumentsDto[]
  fractionDocumentSearchKeyboard: boolean
  stockType: InventoryStockType,
}

export enum InventoryKeyboardMode {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ACTION_BAR = 'actionBar'
}

