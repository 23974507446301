import {
  ArrayType,
  createEntity,
  EntityType,
} from '@designeo/apibundle-js';
import {
  WarehouseOperationLogisticDocumentAttributes,
  WarehouseOperationBaseAttributes,
  WarehouseOperationFilterAttributes,
  WarehouseOperationPatch,
  WarehouseOperationSummaryAttributes,

} from '@/Model/Entity/patches/WarehouseOperationMixin';
import ShipmentNotificationListDto from '@/Model/Entity/ShipmentNotificationListDto';

export default class WarehouseOperationReceiptOfPrintedMattersDtoCustom extends WarehouseOperationPatch(
  createEntity({
    ...WarehouseOperationBaseAttributes,
    ...WarehouseOperationFilterAttributes,
    ...WarehouseOperationLogisticDocumentAttributes,
    ...WarehouseOperationSummaryAttributes,
    list: {
      type: new ArrayType(new EntityType(ShipmentNotificationListDto)),
    },
  }),
) {}
