import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PaymentTypeType from '../PaymentTypeType';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const DocumentPaymentDtoPropDef = {
  paymentID: {
    type: new NumberType(),
  },
  id: {
    type: new NumberType(),
  },
  paymentType: {
    type: new EntityType(PaymentTypeType),
  },
  sapTransactionCode: {
    type: new StringType(),
  },
  value: {
    type: new NumberType(),
  },
  pointsValue: {
    type: new NumberType(),
  },
  description: {
    type: new StringType(),
  },
  valueinFCurrency: {
    type: new NumberType(),
  },
  exchangeRate: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  customXml: {
    type: new StringType(),
  },
  subID: {
    type: new NumberType(),
  },
  isMoneyBack: {
    type: new BooleanType(),
  },
  printerTypeID: {
    type: new StringType(),
  },
  documentIndex: {
    type: new NumberType(),
  },
  active: {
    type: new BooleanType(),
  },
  variableSymbol: {
    type: new StringType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  verifyDocumentId: {
    type: new StringType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  cardType: {
    type: new StringType(),
  },
  payTerminalVirtualId: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentPaymentDto extends createEntity(DocumentPaymentDtoPropDef) {}
