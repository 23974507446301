import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const ConfigurationInfoDtoPropDef = {
  name: {
    type: new StringType(),
  },
  version: {
    type: new NumberType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  isApplied: {
    type: new BooleanType(),
  },
  lastEditedDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedConfigurationInfoDto extends createEntity(ConfigurationInfoDtoPropDef) {}
