import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {apiStockGetOnlyLoadedArticles, apiStockPrint} from '@/Model/Action';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import {map} from 'lodash-es';
import {useSignalR} from '@/Helpers/signalR';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {DocumentDto} from '@/Model/Entity';
import StockFilterOnlyLoadedArticlesDto from '@/Model/Entity/StockFilterOnlyLoadedArticlesDto';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowStepStockCheck extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.StockCheck;
  }

  get type() {
    return WorkflowStepStockCheck.type;
  }

  async fetchAndSetStock() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const stockList = await apiStockGetOnlyLoadedArticles({
        input: new StockFilterOnlyLoadedArticlesDto({
          onlyNegative: true,
        }),
      });

      this.dataSetter('stock', () => map(stockList, (stockItem) => stockItem.toJson()));
      this.dataSetter('errorLoadingStock', () => false);
    } catch (e) {
      this.dataSetter('errorLoadingStock', () => true);
      console.error(e);
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async printStock() {
    const {notificationsConnection} = useSignalR();

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      let document: DocumentDto;

      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== document.header.uniqueidentifier) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        document = await apiStockPrint({params: {onlyNegative: true}});
        return document;
      });

      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
        await this.printContentStore.open(result.printContent);
      }
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.PRINT_FAILED,
          value: e,
        },
      }));
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeEnter() {
    if (this.data.stock) return;

    this.dataSetter('errorLoadingStock', () => false);
  }

  get component() {
    return markRaw(require('./StepStockCheck.vue').default);
  }

  get transitions() {
    return {

    };
  }
}
