
/* eslint-disable max-len */
import {
  computed,
  defineComponent,
} from 'vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import StepEnterCustomerCard from './StepEnterCustomerCard/StepEnterCustomerCard.vue';

export default defineComponent({
  name: 'Step',
  components: {StepEnterCustomerCard},
  setup() {
    const workflowStore = useWorkflowStore();

    const componentByStepType = computed(() => {
      return workflowStore.currentWorkflow.value.activeWorkflowStep.component;
    });

    const stepType = computed(() => {
      return workflowStore.currentWorkflow.value.activeWorkflowStep.type;
    });

    const workflowCode = computed(() => workflowStore.currentWorkflow.value.code);

    return {
      workflowStore,
      componentByStepType,
      stepType,
      workflowCode,
    };
  },
});
