import {
  AxiosAdapter,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {HubConnectionInterceptor} from '@/Helpers/mock/signalR';
const settle = require('axios/lib/core/settle');


const axiosInterceptorByActionName: {[key: string]: (config: AxiosRequestConfig)=> Promise<AxiosResponse>} = {};

let hubConnectionInterceptor: HubConnectionInterceptor = null;

export const registerSignalRInterceptor = (interceptor) => {
  hubConnectionInterceptor = interceptor;

  if (process.env.NODE_ENV === 'development') {
    console.warn(`[Interceptor] interceptor for signalR has been set!`);
  }
};

export const getSignalRInterceptor = () => {
  return hubConnectionInterceptor;
};

export const registerAxiosApiInterceptor = (
  actionName,
  interceptor: (config: AxiosRequestConfig)=> Promise<AxiosResponse>,
) => {
  axiosInterceptorByActionName[actionName] = interceptor;
};

export const withAxiosApiInterceptorAdapter = (axiosAdapter: AxiosAdapter): AxiosAdapter => (config): AxiosPromise => {
  const originalActionName = config.headers.ActionName;
  if (Object.prototype.hasOwnProperty.call(axiosInterceptorByActionName, config.headers.ActionName)) {
    return new Promise((resolve, reject) => {
      axiosInterceptorByActionName[config.headers.ActionName](config)
        .then((response: AxiosResponse) => {
          settle(resolve, reject, response);
        })
        .catch(reject)
        .finally(() => {
          if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line max-len
            console.warn(`[Interceptor] Action "${originalActionName}" has been intercepted and replaced with result from "${config.headers.ActionName}"`);
          }
        });
    });
  }

  return new Promise((resolve, reject) => {
    axiosAdapter(config).then((response) => {
      settle(resolve, reject, response);
    })
      .catch(reject);
  });
};
