
import * as yup from 'yup';
import {
  defineComponent,
  PropType,
} from 'vue';

export default defineComponent({
  name: 'FormInputMixin',
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    active: {
      type: Boolean,
      required: false,
      default: null,
    },
    error: {
      type: Object as PropType<yup.ValidationError>,
      required: false,
      default: null,
    },
    errorOutput: {
      type: String as PropType<'text' | 'modal'>,
      required: false,
      default: 'text',
      enum: [
        'text',
        'modal',
      ],
    },
  },
  data() {
    return {
      modalError: false,
    };
  },
  computed: {
    errorTitle() {
      if (!this.error) return null;

      return this.$t('general.form.errorModal.title', {field: this.label});
    },
    errorMessage() {
      if (!this.error) return null;

      return this.$t(this.error.message, this.error.params);
    },
    hasTextErrorOutput() {
      return this.errorOutput === 'text';
    },
    hasModalErrorOutput() {
      return this.errorOutput === 'modal';
    },
    hasActiveError() {
      if (this.hasModalErrorOutput && this.modalError && this.errorMessage) {
        return true;
      }

      if (this.hasTextErrorOutput && this.errorMessage) {
        return true;
      }

      return false;
    },
  },
  watch: {
    active(isActive, wasActive) {
      if (isActive) {
        this.modalError = this.error;
      }
      this.modalError = this.modalError || wasActive;
    },
  },
});
