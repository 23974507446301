
import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import {
  draf,
  wait,
} from '@designeo/js-helpers';
import {useTransitions} from '@/Helpers/transitions';

class WithAbort {
  private isAborted: boolean
  public inProgress: boolean
  constructor(private fn: Function) {
    this.isAborted = false;
    this.inProgress = false;
  }
  abort() {
    this.isAborted = true;
    this.inProgress = false;
  }
  restore() {
    this.isAborted = false;
  }
  get canProceed() {
    return !this.isAborted;
  }
  async executeFn() {
    if (this.inProgress) return;
    this.inProgress = true;
    await this.fn();
    this.inProgress = false;
  }
  run() {
    this.executeFn();
    return this;
  }
}

export default defineComponent({
  name: 'TransitionWrapper',
  props: {
    startDelay: {
      type: Number,
      required: false,
      default: 0,
    },
    enterTime: {
      type: Number,
      required: false,
      default: 300,
    },
    leaveTime: {
      type: Number,
      required: false,
      default: 200,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['entered', 'left', 'ended'],
  setup(props, {emit}) {
    const entering = ref(false);
    const leaving = ref(false);
    const entered = ref(false);
    const isRendered = ref(false);

    useTransitions({entering, leaving});

    const enter = new WithAbort(async function() {
      if (!enter.canProceed) return;
      isRendered.value = true;
      await draf(null);
      if (!enter.canProceed) return;
      leaving.value = false;
      entering.value = true;
      await wait(props.enterTime)(null);
      if (!enter.canProceed) return;
      entering.value = false;
      entered.value = true;
      emit('entered');
      emit('ended');
    });

    const leave = new WithAbort(async function() {
      if (!leave.canProceed) return;
      leaving.value = true;
      await wait(props.leaveTime)(null);
      if (!leave.canProceed) return;
      leaving.value = false;
      entering.value = false;
      entered.value = false;
      isRendered.value = false;
      emit('left');
      emit('ended');
    });

    watch(() => props.isVisible, async (newValue) => {
      enter.restore();
      leave.restore();

      if (newValue) {
        leave.abort();
        if (!enter.inProgress && !leave.inProgress) {
          await wait(props.startDelay)(null);
        }
        enter.run();
      } else {
        enter.abort();
        leave.run();
      }
    }, {
      immediate: true,
    });

    /**
     * Debug
     */
    // const log = watch(() => ({
    //   entering,
    //   leaving,
    //   entered,
    //   isRendered,
    // }), ({
    //   entering,
    //   leaving,
    //   entered,
    //   isRendered,
    // }) => console.log({
    //   entering: entering.value,
    //   leaving: leaving.value,
    //   entered: entered.value,
    //   isRendered: isRendered.value,
    // }), {deep: true});

    return {
      entering,
      leaving,
      entered,
      isRendered,
    };
  },
});
