import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import MessageListDto from '../MessageListDto';
import {NumberType} from '@designeo/apibundle-js';


export const MessageListDtoIPagingObjectPropDef = {
  data: {
    type: new ArrayType(new EntityType(MessageListDto)),
  },
  limit: {
    type: new NumberType(),
  },
  offset: {
    type: new NumberType(),
  },
  total: {
    type: new NumberType(),
  },
};
export default class GeneratedMessageListDtoIPagingObject extends createEntity(MessageListDtoIPagingObjectPropDef) {}
