import {
  isArray,
  isObject,
  map,
  mapValues,
} from 'lodash-es';

type Node = string[] | string | {[key: string]: any};

const formatNodeTypeArray = (val: string[]) => {
  return {
    'type': 'array',
    'items': map(val, formatNodeTypeString),
  };
};

const formatNodeTypeObject = (val: {[key: string]: any}) => {
  return {
    'type': 'object',
    'properties': mapValues(val, transformNode),
  };
};

const formatNodeTypeString = (val: string) => {
  return {
    'type': 'string',
    'default': val,
  };
};

const transformNode = (val: Node, key: string) => {
  if (isArray(val)) {
    return formatNodeTypeArray(val);
  }

  if (isObject(val)) {
    return formatNodeTypeObject(val);
  }

  return formatNodeTypeString(<string>val);
};

export const jsonToJsonSchema = (json) => {
  return mapValues(json, transformNode);
};
