import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const DialogItemDtoPropDef = {
  code: {
    type: new StringType(),
  },
  text: {
    type: new StringType(),
  },
};
export default class GeneratedDialogItemDto extends createEntity(DialogItemDtoPropDef) {}
