
import {
  defineComponent,
} from 'vue';
import FormInput from '@/Components/FormInput.vue';
import {
  HLInput,
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {SexOptions} from '@/constants/sexOptions';
import {useFilters} from '@/Helpers/filters';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'UpdateCustomerPhone',
  components: {WorkflowTransitions,
    HLInput,
    HLStep,
    FormInput},
  setup() {
    const {phoneFormat} = useFilters();
    const stepType = WorkflowStepTypes.UpdateCustomerPhone;

    return {
      stepType,
      WorkflowStepField,
      SexOptions,
      phoneFormat,
    };
  },
});
