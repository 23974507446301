export enum CheckAndChargeCheckResponseObjectTypes {
  // Service type - LOT
  PackCanSellDto = 'PackCanSellDto',
  TicketCanSellDto = 'TicketCanSellDto',
  TicketValidationDto = 'TicketValidationDto',

  // Provider - GIP
  GIPCheckDto = 'GIPCheckDto',

  // Provider - Korunka
  KorunkaCheckDto = 'KorunkaCheckDto',
  KorunkaErrorDto = 'KorunkaErrorDto',

  // EasyCoin
  EasyCoinOrderDto = 'EasyCoinOrderDto',

  // VoucherCheckDto
  VoucherCheckDto = 'VoucherCheckDto',
}

export enum CheckAndChargeChargeResponseObjectTypes {
  // Service type - LOT
  PackSellDto = 'PackSellDto',
  TicketSellDto = 'TicketSellDto',

  // Provider - GIP
  GIPChargeDto = 'GIPChargeDto',

  // Provider - Korunka
  KorunkaChargeDto = 'KorunkaChargeDto',
  KorunkaChargeDtoV2 = 'KorunkaChargeDtoV2',
  KorunkaErrorDto = 'KorunkaErrorDto',

  // EasyCoin
  EasyCoinOrderDto = 'EasyCoinOrderDto',

  // VoucherChargeDto
  VoucherChargeDto = 'VoucherChargeDto',
}

export enum CheckAndChargeRevokeResponseObjectTypes {
  // Service type - LOT
  PackReturnDto = 'PackReturnDto',
  TicketReturnDto = 'TicketReturnDto',

  // Provider - Korunka
  KorunkaRevokeDto = 'KorunkaRevokeDto',
  KorunkaErrorDto = 'KorunkaErrorDto',

  // Provider - GIP
  GIPRevokeDto = 'GIPRevokeDto',

  // VoucherRevokeDto
  VoucherRevokeDto = 'VoucherRevokeDto',
}

export enum CheckAndChargeCancelResponseObjectTypes {
  // Service type - LOT
  PackReturnDto = 'PackReturnDto',
  TicketReturnDto = 'TicketReturnDto',

  // Provider - Korunka
  KorunkaCancelDto = 'KorunkaCancelDto',
  KorunkaCancelDtoV2 = 'KorunkaCancelDtoV2',
  KorunkaErrorDto = 'KorunkaErrorDto',

  // Provider - GIP
  GIPCancelDto = 'GIPCancelDto',

  // VoucherCancelDto
  VoucherCancelDto = 'VoucherCancelDto',
}
