import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';

export default class LotCheckRequestDtoCustom extends createEntity({
  logisticCode: {
    type: new StringType(),
  },
  validationCode: {
    type: new StringType(),
  },
  salePackage: {
    type: new BooleanType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
