
import {
  defineComponent,
  nextTick,
  PropType,
  ref,
  watch,

} from 'vue';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ArticleLabelBatch from '@/Components/ArticleLabels/ArticleLabelBatch.vue';
import TaxStamp from '@/Components/TaxStamp.vue';
import {DocumentLogisticItemDto} from '@/Model/Entity';
import {scrollIntoView} from '@/Helpers/scroll';

export default defineComponent({
  components: {TaxStamp, ArticleLabelBatch},
  props: {
    item: {
      type: Object as PropType<{
        entity: DocumentLogisticItemDto
        isActive: boolean,
        hasVisiblePrices: boolean
      }>,
      required: true,
    },
  },
  emits: ['select'],
  setup(props) {
    const configurationStore = useConfigurationStore();
    const {currencyFormat, dateFormat} = useFilters();
    const rootRef = ref<HTMLDivElement>(null);

    watch([
      () => props.item.isActive,
      () => props.item.entity.quantity,
    ], async () => {
      if (props.item.isActive) {
        await nextTick();
        await scrollIntoView(rootRef.value);
      }
    }, {immediate: true});

    return {
      rootRef,
      dateFormat,
      currencyFormat: (val) => {
        return currencyFormat(val, configurationStore.localCurrency.value.symbol);
      },
    };
  },
});
