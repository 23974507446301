
import {
  computed,
  defineComponent,
} from 'vue';
import {useKorunkaSyncTarget} from '../../syncTarget/KorunkaSyncTarget';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';

export default defineComponent({
  setup() {
    const korunkaSyncTarget = useKorunkaSyncTarget();
    const configurationStore = useConfigurationStore();

    const {interpolate, currencyFormat: baseCurrencyFormat} = useFilters();

    const currentStepType = computed(() => korunkaSyncTarget.stepType.value);

    const isStepOverview = computed(() => currentStepType.value === WorkflowStepTypes.KorunkaOverview);

    const messages = computed(() => korunkaSyncTarget.messages.value);

    const entity = computed(() => korunkaSyncTarget.entity.value as KorunkaLotteryTicketDtoCustom);

    const badge = computed(() => {
      if (entity.value.isWon || entity.value.isWonWithPayoutAmountExceeded) {
        return {
          class: 'bg-success text-basic-lightest',
          label: messages.value.ticketStatusWon,
        };
      } else if (entity.value.isPaidOut) {
        return {
          class: 'bg-primary text-basic-lightest',
          label: messages.value.ticketStatusIsPaidOut,
        };
      } else if (entity.value.isLoss) {
        return {
          class: 'bg-danger text-basic-lightest',
          label: messages.value.ticketStatusLoss,
        };
      } else if (entity.value.isCanceled || entity.value.isPreCanceled) {
        return {
          class: 'bg-basic-darkest text-white',
          label: messages.value.ticketStatusCanceled,
        };
      }


      return {
        class: 'bg-warning text-basic-lightest',
        label: messages.value.ticketStatusNotEvaluated,
      };
    });

    const resolvedIconByTicketState = computed(() => {
      if (entity.value.isWon || entity.value.isWonWithPayoutAmountExceeded) {
        return require('@/assets/images/korunkaWin.svg').default;
      } else if (entity.value.isLoss) {
        return require('@/assets/images/korunkaLoss.svg').default;
      } else if (entity.value.isCanceled || entity.value.isPreCanceled) {
        return require('@/assets/images/korunkaCanceled.svg').default;
      } else if (entity.value.isPaidOut) {
        return require('@/assets/images/korunkaPayedOut.svg').default;
      }


      return require('@/assets/images/korunkaNotEvaluated.svg').default;
    });

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    return {
      interpolate,
      currentStepType,
      messages,
      isStepOverview,
      ticket: entity,
      korunkaSyncTarget,
      badge,
      resolvedIconByTicketState,
      currencyFormat,
    };
  },
});
