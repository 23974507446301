import {
  SequenceMatched,
  isSequence,
  someSequenceMatched,
} from './helpers';
import {
  TransformStream,
} from '../streams';
import {KeyPress} from '@/Modules/Register/services/KeyboardBuffer';

export function createAltTransform() {
  let buffer: KeyPress[] = [];
  const isAlt4Sequence = (buffer: KeyPress[]) => {
    return someSequenceMatched(
      isSequence([
        (key: KeyPress) => key.key === 'Alt' && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => (key.code === 'AltLeft' || key.key === 'Alt') && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
      ])(buffer),
      isSequence([
        (key: KeyPress) => key.key === 'Alt' && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => (key.code === 'AltLeft' || key.key === 'Alt') && key.dir === 'up',
      ])(buffer));
  };
  const isAlt3Sequence = (buffer: KeyPress[]) => {
    return someSequenceMatched(
      isSequence([
        (key: KeyPress) => key.key === 'Alt' && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => (key.code === 'AltLeft' || key.key === 'Alt') && key.dir === 'up',
      ])(buffer),
      isSequence([
        (key: KeyPress) => key.key === 'Alt' && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'down',
        (key: KeyPress) => (key.code === 'AltLeft' || key.key === 'Alt') && key.dir === 'up',
        (key: KeyPress) => /Numpad[0-9]/.test(key.code) && key.dir === 'up',
      ])(buffer));
  };

  const numpadToNumber = ({code}: KeyPress) => code.match(/Numpad([0-9])/)[1];

  const constructAlt3 = (): KeyPress[] => {
    const char = String.fromCharCode(parseInt([
      numpadToNumber(buffer[1]),
      numpadToNumber(buffer[3]),
      numpadToNumber(buffer[5]),
    ].join(''), 10));
    const key: KeyPress = {
      alt: false,
      shift: false,
      ctrl: false,
      code: 'ALT',
      key: char,
    };
    return [
      {...key, dir: 'down'},
      {...key, dir: 'up'},
    ];
  };

  const constructAlt4 = (): KeyPress[] => {
    const char = String.fromCharCode(parseInt([
      numpadToNumber(buffer[1]),
      numpadToNumber(buffer[3]),
      numpadToNumber(buffer[5]),
      numpadToNumber(buffer[7]),
    ].join(''), 10));
    const key: KeyPress = {
      alt: false,
      shift: false,
      ctrl: false,
      code: 'ALT',
      key: char,
    };
    return [
      {...key, dir: 'down'},
      {...key, dir: 'up'},
    ];
  };

  return new TransformStream<KeyPress, KeyPress>({
    transform(chunk, controller) {
      buffer.push(chunk);
      while (buffer.length > 0) {
        const alt3Matched = isAlt3Sequence(buffer);
        const alt4Matched = isAlt4Sequence(buffer);
        if (alt3Matched === SequenceMatched.NO && alt4Matched === SequenceMatched.NO) {
          controller.enqueue(buffer.shift());
        } else if (alt3Matched === SequenceMatched.YES) {
          for (const key of constructAlt3()) {
            controller.enqueue(key);
          }
          buffer = [];
          break;
        } else if (alt4Matched === SequenceMatched.YES) {
          for (const key of constructAlt4()) {
            controller.enqueue(key);
          }
          buffer = [];
          break;
        } else {
          break;
        }
      }
    },
  });
}
