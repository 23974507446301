import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BatchDto from '../BatchDto';


export const VariantDtoPropDef = {
  batch: {
    type: new EntityType(BatchDto),
  },
};
export default class GeneratedVariantDto extends createEntity(VariantDtoPropDef) {}
