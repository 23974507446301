import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {markRaw, toRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.SELECT_CHECK_AND_CHARGE_LOT_VARIATION]: {
      [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
        this.changeState(RegisterState.ENTER_ARTICLE_NUMBER);
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!event.value) return;

        await this.startProductFlow(markRaw(event.value));
      }),
    },
  };
}
