import routes from './routes';
import LayoutAnalogDisplay from '@/Layout/LayoutAnalogDisplay.vue';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerAnalogDisplay(ctx: RequiredContext) {
  /**
   * Route serve as debug tool, main communication is with printer service!
   */
  await ctx.registerLayout('analogDisplay', {
    path: '/',
    component: LayoutAnalogDisplay,
    children: [],
  });

  await ctx.registerRoutes(routes, {
    layout: 'analogDisplay',
  });
  return {};
}
