
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import {useCustomerInteractionStore} from '@/Modules/Core/store/CustomerInteractionStore';
import {ConfirmTypes, CustomerInteractionActions} from '@/Modules/Core/types';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';

export default defineComponent({
  name: 'ModalCustomerInteraction',
  components: {
    ModalDetachedButtons,
    ModalDetachedBody,
    ModalDetachedClose,
    Modal,
  },
  setup() {
    const customerInteractionStore = useCustomerInteractionStore();

    const reject = () => {
      customerInteractionStore.onEventInput({
        type: CustomerInteractionActions.CLOSE,
        value: true,
      });
    };

    const content = computed(() => {
      return customerInteractionStore.interaction.value.message;
    });

    return {
      content,
      reject,
      customerInteractionStore,
      ConfirmTypes,
    };
  },
});
