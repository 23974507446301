import {Context} from '@/Helpers/Context';
import {DocumentDto, ResultDto} from '@/Model/Entity';
import {PaymentEventRegisterArgs} from '@/Modules/Payment/payment';

export enum ResolverTypes {
  ByTwoWayCommunication = 'ByTwoWayCommunication',
}

export interface IResolverConstructor<R extends IResolver = IResolver> {
  new (context?: Context): R
  deserialize(data: any): R
  serialize(resolver: R): any
}

export interface IResolver {
  type: ResolverTypes
  context: Context
  on: (...args: PaymentEventRegisterArgs)=> IResolver
  isTerminated: boolean
  isSuccessful: boolean
  isResolved: boolean
  verifyDocumentId: string
  process(): Promise<{
    isTerminated: boolean,
    isSuccessful: boolean,
    /**
     * This tell us, if process failed without warning for user
     */
    handled: boolean,
    results: ResultDto[],
    verifyDocumentId: string,
    card?: {
      cardNumber?: string,
      cardType?: string,
    }
  }>
}
