import {createEntity} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const SyncStatusDtoPropDef = {
  lastArticlesSyncDate: {
    type: new DateTimeType(),
  },
  lastPromotionsSyncDate: {
    type: new DateTimeType(),
  },
  lastConfigurationSyncDate: {
    type: new DateTimeType(),
  },
  lastCashiersSyncDate: {
    type: new DateTimeType(),
  },
  lastPosDataSyncDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedSyncStatusDto extends createEntity(SyncStatusDtoPropDef) {}
