import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow bg-basic-lightest m-4 px-4" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "font-bold text-3xl pt-2" }
const _hoisted_4 = { class: "grid grid-cols-2 my-1" }
const _hoisted_5 = { class: "flex flex-col gap-2 py-4 pr-2" }
const _hoisted_6 = {
  class: "grid items-center justify-start gap-2",
  style: {"grid-template-rows":"repeat(8, 1fr)","grid-auto-flow":"column"}
}
const _hoisted_7 = { class: "font-bold text-sm m-auto" }
const _hoisted_8 = {
  key: 0,
  class: "grid gap-1 border-t pt-2",
  style: {"grid-template-columns":"50px repeat(5, 16px)"}
}
const _hoisted_9 = { class: "text-xs my-auto" }
const _hoisted_10 = { class: "pl-2 border-l border-basic" }
const _hoisted_11 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = {
  key: 2,
  class: "mt-4 font-bold"
}
const _hoisted_16 = {
  key: 0,
  class: "flex flex-row justify-between p-8 pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.messages.gameTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity?.randomNumbers, (randomNumbersRow, rowIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: rowIndex,
                class: "grid gap-1 items-center",
                style: {"grid-template-columns":"1rem repeat(5, minmax(0, 1fr))"}
              }, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLetterFromIndex(rowIndex)) + ". ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(randomNumbersRow, (number, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "korunka-circle-number korunka-circle-number--2xs"
                  }, _toDisplayString(number), 1))
                }), 128))
              ]))
            }), 128))
          ]),
          (_ctx.entity.tutovka.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.messages.tutovka) + ": ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.tutovka, (number, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "korunka-circle-number korunka-circle-number--2xs"
                  }, _toDisplayString(number), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.drawsDates)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.messages.drawsDates) + ": ", 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.drawsDates), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.entity?.drawsCount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createTextVNode(_toDisplayString(_ctx.messages.drawsCount) + ": ", 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.entity?.drawsCount ?? 0), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.entity?.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.messages.total) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.total ?? 0)), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.isStepOverview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _withDirectives(_createElementVNode("button", {
            class: "btn btn-success text-2xl",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAccept && _ctx.onAccept(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.messages.confirmAction), 1)
          ], 512), [
            [_directive_test, 'accept']
          ]),
          (_ctx.canBeDeclined)
            ? _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-danger text-2xl",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDecline && _ctx.onDecline(...args)))
              }, [
                _createTextVNode(_toDisplayString(_ctx.messages.declineAction), 1)
              ], 512)), [
                [_directive_test, 'decline']
              ])
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}