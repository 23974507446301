import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const TransactionDiffPropDef = {
  id: {
    type: new StringType(),
  },
  // transaction: {
  //   type: new EntityType(Transaction),
  // },
  transactionId: {
    type: new StringType(),
  },
  jsonDataDiff: {
    type: new StringType(),
  },
  resultJsonDataDiff: {
    type: new StringType(),
  },
  createdAt: {
    type: new DateTimeType(),
  },
};
export default class GeneratedTransactionDiff extends createEntity(TransactionDiffPropDef) {}
