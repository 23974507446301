
import {defineComponent} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useWorkflowStore} from '../../store/WorkflowStore';

export default defineComponent({
  name: 'StepBTCTransactionOverview',
  components: {HLStep, IconCheck, AsyncButton},
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.BTCTransactionOverview;

    const {
      currentWorkflow,
    } = useStepTools(workflowStore.currentWorkflow.value.activeWorkflowStep.type);

    const requestNextStep = () => currentWorkflow.value.requestNextStep();

    return {
      stepType,
      requestNextStep,
    };
  },
});
