export function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64).split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function jwtIsExpired(token) {
  if (!token) return true;
  const parsed = parseJwt(token);
  if (!parsed) return true;
  return +new Date(parsed.exp * 1000) < +new Date();
}
