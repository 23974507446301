
import {
  computed,
  defineComponent,
  ref,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useFourEyesStore} from '@/Modules/Core/store/FourEyesStore';
import {CoreWSEvents, FourEyesOperations} from '@/Modules/Core/types';
import {apiAdminUnregisterPos, apiDocumentCreate} from '@/Model/Action';
import {useRouter} from 'vue-router';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useSignalR} from '@/Helpers/signalR';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';

export default defineComponent({
  name: 'ModalBlockedState',
  components: {
    ModalDetachedButtons,
    AsyncButton,
    Modal,
  },
  setup() {
    const configurationStore = useConfigurationStore();
    const authStore = useAuthStore();
    const fourEyesStore = useFourEyesStore();
    const fourEyesInProgress = ref(false);
    const router = useRouter();
    const helpStore = useHelpStore();
    const registerStore = useRegisterStore();
    const loading = ref(false);
    const {isSecondaryDisplay} = useSecondaryDisplay();

    const open = computed(() => {
      if (isSecondaryDisplay) {
        return false;
      }

      return (configurationStore.configuration.value?.isPosBlocked ?? false) && !fourEyesInProgress.value;
    });

    const fourEyes = async (): Promise<{isConfirmed: false} | {isConfirmed: true, accessToken: string}> => {
      try {
        fourEyesInProgress.value = true;
        return await fourEyesStore
          .openFourEyesConfirm(FourEyesOperations.POS_BLOCKED_STATE_MANAGE, {resolveByActivePerson: false});
      } catch (e) {
        console.error(e);
        await helpStore.show(helpStore.getErrorId(e.message));
        return {isConfirmed: false};
      } finally {
        fourEyesInProgress.value = false;
      }
    };

    const unregister = async () => {
      const fourEyesResult = await fourEyes();

      if (fourEyesResult.isConfirmed) {
        try {
          loading.value = true;
          if (registerStore.sellDocument.value?.isTouched) { // cancel like this??
            registerStore.sellDocument.value.cancel();

            registerStore.sellDocument.value.preflightSetup();

            if (registerStore.sellDocument.value.isModeStorno) {
              registerStore.sellDocument.value.header.referentialUniqueidentifier = null;
            }

            await apiDocumentCreate({
              input: registerStore.sellDocument.value.toApiClone(),
            });
          }

          const {notificationsConnection} = useSignalR();

          await notificationsConnection.addEventListenerWithTrigger(CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED)(
            async () => await apiAdminUnregisterPos({
              headers: {
                'Authorization': authStore.createAuthorizationHeader(fourEyesResult.accessToken),
              },
            }),
          );

          await router.push({name: 'pos-configuration'});
        } catch (e) {
          console.error(e);
        } finally {
          loading.value = false;
        }
      }
    };

    return {
      open,
      unregister,
      loading,
    };
  },
});
