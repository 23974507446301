import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import MessageType from '../MessageType';
import {BooleanType} from '@designeo/apibundle-js';
import MessageAttachmentDto from '../MessageAttachmentDto';


export const MessageDetailDtoPropDef = {
  id: {
    type: new StringType(),
  },
  body: {
    type: new StringType(),
  },
  messageDate: {
    type: new DateTimeType(),
  },
  from: {
    type: new StringType(),
  },
  to: {
    type: new ArrayType(new StringType()),
  },
  bcc: {
    type: new ArrayType(new StringType()),
  },
  subject: {
    type: new StringType(),
  },
  shopId: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(MessageType),
  },
  isSensitive: {
    type: new BooleanType(),
  },
  isImportant: {
    type: new BooleanType(),
  },
  readDate: {
    type: new DateTimeType(),
  },
  isArchived: {
    type: new BooleanType(),
  },
  attachments: {
    type: new ArrayType(new EntityType(MessageAttachmentDto)),
  },
};
export default class GeneratedMessageDetailDto extends createEntity(MessageDetailDtoPropDef) {}
