import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentHeaderDto from '../DocumentHeaderDto';
import FiscalCommands from '../FiscalCommands';
import {BooleanType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const DocumentListReportItemDtoPropDef = {
  header: {
    type: new EntityType(DocumentHeaderDto),
  },
  documentType: {
    type: new EntityType(FiscalCommands),
  },
  documentSubType: {
    type: new EntityType(FiscalCommands),
  },
  isCanceled: {
    type: new BooleanType(),
  },
  itemsCount: {
    type: new NumberType(),
  },
  documentTypeName: {
    type: new StringType(),
  },
  documentSubTypeName: {
    type: new StringType(),
  },
  sourceName: {
    type: new StringType(),
  },
  destinationName: {
    type: new StringType(),
  },
  supplierName: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentListReportItemDto extends createEntity(DocumentListReportItemDtoPropDef) {}
