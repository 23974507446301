import {Constructor} from '@/Model/Entity/patches/patch';
import {EntityType} from '@designeo/apibundle-js';
import {DocumentDto} from '@/Model/Entity';

export const TransactionDtoPatch = <TBase extends Constructor>(superClass: TBase) =>
  class TransactionDtoPatch extends superClass {
    get document(): DocumentDto {
      // @ts-ignore
      return new EntityType(DocumentDto).parse(this._data.document);
    }
    set document(val: DocumentDto) {
      // @ts-ignore
      this._data.document = new EntityType(DocumentDto).format(val);
    }
  };
