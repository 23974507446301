import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {apiDocumentGet, apiDocumentRepeatablePrinting} from '@/Model/Action';
import RepeatablePrintingCommand from '../../../../Model/Entity/RepeatablePrintingCommand';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {WarehouseOperationStepFieldGroups} from '@/constants/warehouseOperations';
import {DocumentDto} from '@/Model/Entity';
import OutputDto from '../../../../Model/Entity/OutputDto';
import {PrintoutTypes} from '@/constants/printoutTypes';
import {OutputTypes} from '@/constants/outputTypes';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowStepWarehouseOperationPrint extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationPrint;
  }

  get type() {
    return WorkflowStepWarehouseOperationPrint.type;
  }

  get component() {
    return markRaw(require('./StepWarehouseOperationPrint.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get title() {
    return this.titleByWarehouseOperation;
  }

  createAdditionalInfoFieldDataPath(field: string) {
    return `${WarehouseOperationStepFieldGroups.additionalInfo}.${field}`;
  }

  get logisticOperation(): DocumentDto {
    return new DocumentDto(this.getFieldValue(this.createAdditionalInfoFieldDataPath(WorkflowStepField.document)));
  }

  async printOutput(output: OutputDto) {
    const {notificationsConnection} = useSignalR();

    try {
      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== this.logisticOperation.header.uniqueidentifier) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => await apiDocumentRepeatablePrinting({
        input: new RepeatablePrintingCommand({
          uniqueidentifier: this.logisticOperation.header.uniqueidentifier,
          copies: 1,
          printoutType: output.target?.value ?? PrintoutTypes.ReceiptPrinter,
          outputType: output.outputType?.value ?? OutputTypes.Primary,
          ...(output.templateCode ? {
            templateCode: output.templateCode,
          } : {}),
        }),
      }));

      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
        await this.printContentStore.open(result.printContent);
      }
    } catch (e) {
      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      throw e;
    }
  }

  async print() {
    try {
      const transaction = await apiDocumentGet({
        params: {
          id: this.logisticOperation.header.uniqueidentifier,
        },
      });

      for (const output of transaction?.result?.output ?? []) {
        await this.printOutput(output);
      }
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      throw e;
    }
  }

  get transitions() {
    return {};
  }
}
