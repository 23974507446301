import {
  defineComponent,
  h,
  onMounted,
  ref,
  watch,
} from 'vue';
import {map} from 'lodash-es';
import {scrollIntoView} from '@/Helpers/scroll';
import {KEYBOARD_KEY_ARROW_DOWN, KEYBOARD_KEY_ARROW_UP} from '@/constants/keyboardKeys';

export const HLScrollIntoView = defineComponent({
  name: 'HLScrollIntoView',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {emit}) {
    const element = ref(null);

    watch(() => props.active, (value) => {
      if (value) {
        scrollIntoView(element.value);
      }
    }, {immediate: true});

    const preventArrowScroll = (e) => {
      if ([KEYBOARD_KEY_ARROW_UP, KEYBOARD_KEY_ARROW_DOWN].includes(e.key)) {
        e.preventDefault();
      }
    };

    onMounted(() => {
      if (element.value.$el) {
        element.value.$el.addEventListener('keydown', preventArrowScroll);
      }
    });

    return {
      element,
    };
  },
  render() {
    const slotData = {};
    const content = (this.$slots?.default?.(slotData) ?? []);

    if (content.length > 1) throw new Error('HLScrollIntoView is build as a single child abstract component');

    return map(content, (slot) => h(slot, {ref: 'element'}));
  },
});
