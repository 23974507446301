import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Target from '../Target';
import OutputType from '../OutputType';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const RepeatablePrintingCommandPropDef = {
  printoutAsHtml: {
    type: new BooleanType(),
  },
  printoutAsPdf: {
    type: new BooleanType(),
  },
  printoutType: {
    type: new EntityType(Target),
  },
  disablePrintout: {
    type: new BooleanType(),
  },
  outputType: {
    type: new EntityType(OutputType),
  },
  templateCode: {
    type: new StringType(),
  },
  uniqueidentifier: {
    type: new StringType(),
  },
  copies: {
    type: new NumberType(),
  },
  attachmentName: {
    type: new StringType(),
  },
};
export default class GeneratedRepeatablePrintingCommand extends createEntity(RepeatablePrintingCommandPropDef) {}
