import {
  identity,
  last,
  map,
  mapKeys,
  mapValues,
  omitBy,
} from 'lodash-es';
import {createPersistentVariable, localStoragePersistentVariable} from '@designeo/js-helpers/src/index';
import {
  AuthInput,
  AuthPerson,
  AuthState,
} from '@/Modules/Auth/types';
import {
  CreateMessageDto,
  DocumentDto,
  DocumentInventoryGroup,
  DocumentItemDto,
  DocumentLogisticItemDto,
  DocumentPaymentDto,
  InventoryMetadataDto,
  PermissionDto,
  PointOfSaleActivatedDto,
  StockDto,
} from '@/Model/Entity';
import {IRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {IWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {Workflow} from '@/Modules/Workflow/Workflow/Workflow';
import {Context} from '@/Helpers/Context';
import {ITrainingStore} from '@/Modules/Training/store/TrainingStore';
import {ProductFlow} from '@/Modules/Register/ProductFlow/ProductFlow';
import {InventoryStockStoreState} from '@/Modules/Apps/Inventory/types';
import {deserializePromoInteractionFlow} from '@/Modules/Register/PromoInteractionFlow';
import {IConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {createPosConfiguration, IPosConfigurationStore} from '@/Modules/PosConfiguration/store/PosConfigurationStore';
import {PosConfigurationState} from '@/Modules/PosConfiguration/types';
import {IInventoryStore} from '@/Modules/Inventory/store/InventoryStore';
import {IMessageFormStore} from '@/Modules/Apps/PosMessage/store/FormStore';
import DelaySellDataDtoCustom from '@/Model/Entity/custom/DelaySellDataDtoCustom';
import {IPdaHandshakeStore} from '@/Modules/Core/store/PdaHandshakeStore';
import MessageDetailDto from '@/Model/Entity/MessageDetailDto';
import CustomerAdditionalDataDto from '@/Model/Entity/CustomerAdditionalDataDto';
import {SaveFlow} from '@/Modules/Register/SaveFlow/SaveFlow';
import {asyncStorage, isAsyncStorageSupported} from './asyncStore';
import {createPersistentAsyncVariable} from './variable';


export const sessionStoragePersistentVariable = createPersistentVariable({localStorage: globalThis['sessionStorage']});
export const asyncStoragePersistentVariable = !isAsyncStorageSupported() ?
  localStoragePersistentVariable :
  createPersistentAsyncVariable({
    asyncStorage: asyncStorage
      .add('consoleCollector')
      .add('posInventoryStoreState')
      .add('posWorkflowStoreState'),
  });

export interface IPersist<T> {
  get(): T
  set(val: T): void | Promise<void>
  reset(): void | Promise<void>
}

type MigrationResult = {
  data: any,
  migration: string
}

type Migration = {
  from: string,
  to: string,
  transform: (content: any)=> any
}

type Migrations = Array<Migration>;

export const withMigrations = <T>(migrations: Migrations = []) => {
  const transform = (data: MigrationResult) => {
    const migrationsByFrom = mapKeys(migrations, 'from');
    let result = data;

    while (data && Object.prototype.hasOwnProperty.call(migrationsByFrom, data.migration)) {
      result = {
        data: migrationsByFrom[data.migration].transform(data),
        migration: null,
      };
    }

    return result;
  };

  return (
    persist: IPersist<any>,
    {
      serialize = identity,
      deserialize = identity,
    }: {
      serialize?: (val: T)=> any,
      deserialize?: (val: any)=> T
    } = {},
  ) => ({
    get(): T {
      const val = transform(<MigrationResult>(persist.get()))?.data;
      if (!val) return null;
      return deserialize(val);
    },
    async set(val: T) {
      await persist.set(<MigrationResult>{
        data: val ? serialize(val) : null,
        migration: getCurrentBuildHash(),
      });
    },
    reset: persist.reset,
  });
};

export const coldStartSessionsStorage = sessionStoragePersistentVariable('coldStart', {
  startedAt: new Date().toISOString(),
  value: true,
});

export const e2eScenarioIdSessionStorage = sessionStoragePersistentVariable('e2eScenarioId', undefined);

const accessTokensSessionsStorageField = sessionStoragePersistentVariable('accessTokens', {});

const currentAccessTokenSessionsStorageField = sessionStoragePersistentVariable('currentAccessToken', null);

export const consoleCollector = asyncStoragePersistentVariable('consoleCollector', {
  history: [],
});

export function getCurrentBuildHash(): string {
  try {
    return process.env.NODE_ENV === 'development' ? 'dev' : globalThis.document?.body?.dataset?.buildHash || null;
  } catch (err) {
    console.warn(`Failed to build hash`);
  }
  return null;
}

export const posBuildHashHistory = localStoragePersistentVariable('posBuildHashHistory', []);

export const persistBuildHash = () => {
  const savedBuildHashHistory: Array<{buildHash: string, saved: number}> = posBuildHashHistory.get();
  const lastSavedBuildHash = last(savedBuildHashHistory) || null;
  const currentBuildHash = getCurrentBuildHash();

  if (!lastSavedBuildHash || lastSavedBuildHash.buildHash !== currentBuildHash) {
    posBuildHashHistory.set([...savedBuildHashHistory, {buildHash: currentBuildHash, saved: +new Date()}]);
  }

  return currentBuildHash;
};

const createAsyncStoragePersistentVariableWithMigration = (name): IPersist<MigrationResult> => {
  return asyncStoragePersistentVariable(
    name,
    <MigrationResult>{
      data: null,
      migration: getCurrentBuildHash(),
    },
  );
};

const createLocalStoragePersistentVariableWithMigration = (name): IPersist<MigrationResult> => {
  return localStoragePersistentVariable(
    name,
    <MigrationResult>{
      data: null,
      migration: getCurrentBuildHash(),
    },
  );
};

const createSessionStoragePersistentVariableWithMigration = (name): IPersist<MigrationResult> => {
  return sessionStoragePersistentVariable(
    name,
    <MigrationResult>{
      data: null,
      migration: getCurrentBuildHash(),
    },
  );
};

export const pdaHandshakeStoreStatePersist = withMigrations<IPdaHandshakeStore>([])(
  createSessionStoragePersistentVariableWithMigration('posPdaHandshakeStoreState'),
  {
    deserialize: ({credentials}) => ({
      callback: null,
      credentials,
    }),
    serialize: ({credentials}) => ({
      callback: null,
      credentials,
    }),
  },
);

export const configurationStoreStatePersist = withMigrations<IConfigurationStore>([])(
  createLocalStoragePersistentVariableWithMigration('posConfigurationStoreState'),
  {
    deserialize: ({
      syncAvailable,
    }) => ({
      configuration: null,
      syncAvailable,
    }),
    serialize: ({
      syncAvailable,
    }) => ({
      syncAvailable,
    }),
  },
);

export const registrationStoreStatePersist = withMigrations<IPosConfigurationStore>([])(
  createLocalStoragePersistentVariableWithMigration('posRegistrationStoreState'),
  {
    deserialize: ({
      registrationResult,
    }) => ({
      registrationResult: registrationResult ? new PointOfSaleActivatedDto(registrationResult) : null,
      state: PosConfigurationState.SELECT_LANGUAGE,
      errors: [],
      posConfiguration: createPosConfiguration(),
    }),
    serialize: ({
      registrationResult,
    }) => ({
      registrationResult: registrationResult ? registrationResult.toJson() : null,
    }),
  },
);

export const authStoreStatePersist = withMigrations<{
  state: AuthState,
  previousState: AuthState,
  authInput: AuthInput,
  accessToken: string,
  people: Array<AuthPerson>,
  lastLoggedPerson: AuthInput['username'],
  isInInventoryApp: boolean,
}>([])(
  createLocalStoragePersistentVariableWithMigration('posAuthStoreState'),
  {
    deserialize: ({
      state,
      previousState,
      people,
      accessToken,
      lastLoggedPerson,
      authInput,
      isInInventoryApp,
    }) => ({
      state,
      previousState,
      people: map(people ?? [], (person) => {
        return {
          ...person,
          accessToken: accessTokensSessionsStorageField.get()[person.username] ?? null,
          permissions: map(person.permissions ?? [], (permission) => new PermissionDto(permission)),
        };
      }),
      accessToken: currentAccessTokenSessionsStorageField.get(),
      lastLoggedPerson,
      authInput,
      isInInventoryApp,
    }),
    serialize: ({
      state,
      previousState,
      people,
      accessToken,
      lastLoggedPerson,
      authInput,
      isInInventoryApp,
    }) => ({
      state,
      previousState,
      people: map(people, (person) => {
        return {
          ...person,
          accessToken: accessTokensSessionsStorageField.set({
            ...accessTokensSessionsStorageField.get(),
            [person.username]: person.accessToken,
          })[person.username] ?? null,
          permissions: map(person.permissions ?? [], (permission) => permission.toJson()),
        };
      }),
      accessToken: currentAccessTokenSessionsStorageField.set(accessToken),
      lastLoggedPerson,
      authInput,
      isInInventoryApp,
    }),
  },
);

export const registerStoreStatePersist = withMigrations<IRegisterStore>([])(
  createLocalStoragePersistentVariableWithMigration('posRegisterStoreState'),
  {
    deserialize: ({
      loadedProducts,
      state,
      previousState,
      inputBuffer,
      sellDocument,
      sellDocumentBackup,
      payment,
      lastSellDocument,
      lastSearchedSubject,
      promoFlow,
      productFlow,
      productDetail,
      customerDetail,
      quickCallActiveMap,
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow,
      customerAuthentication,
      customData,
      delayedSellData,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    } = {}) => ({
      loadedProducts: map(loadedProducts, (product) => new DocumentItemDto(product ?? {})),
      state,
      previousState,
      inputBuffer,
      sellDocument: sellDocument ? new DocumentDto(sellDocument) : null,
      sellDocumentBackup: sellDocumentBackup ? new DocumentDto(sellDocumentBackup) : null,
      payment: payment ? new DocumentPaymentDto(payment) : null,
      lastSellDocument: lastSellDocument ? new DocumentDto(lastSellDocument) : null,
      lastSearchedSubject,
      promoFlow: deserializePromoInteractionFlow(promoFlow),
      productFlow: productFlow ? ProductFlow.deserialize(productFlow) : null,
      productDetail: !productDetail ? null : {
        item: productDetail.item ? new DocumentItemDto(productDetail.item) : null,
        stock: productDetail.stock ? new StockDto(productDetail.stock) : null,
        stockInStores: productDetail.stockInStores,
      },
      customerDetail,
      quickCallActiveMap: new Map(quickCallActiveMap),
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow: saveFlow ? SaveFlow.deserialize(saveFlow) : new SaveFlow(),
      customerAuthentication,
      customData: customData ? {
        formEntity: new CustomerAdditionalDataDto(customData.formEntity),
        activeField: customData.activeField,
      } : null,
      delayedSellData: delayedSellData ? new DelaySellDataDtoCustom(delayedSellData) : null,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
      paymentsVerificationInProgress: false,
    }),
    serialize: ({
      loadedProducts,
      state,
      previousState,
      inputBuffer,
      payment,
      sellDocument,
      sellDocumentBackup,
      lastSellDocument,
      lastSearchedSubject,
      promoFlow,
      productFlow,
      productDetail,
      customerDetail,
      quickCallActiveMap,
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow,
      customData,
      delayedSellData,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    }) => ({
      loadedProducts: map(loadedProducts || [], (product) => product.toJson()),
      state,
      previousState,
      inputBuffer,
      payment: payment ? payment.toJson() : null,
      sellDocument: sellDocument ? sellDocument.toJson() : null,
      sellDocumentBackup: sellDocumentBackup ? sellDocumentBackup.toJson() : null,
      lastSellDocument: lastSellDocument ? lastSellDocument.toJson() : null,
      lastSearchedSubject,
      promoFlow: promoFlow?.serialize?.(),
      productFlow: productFlow ? ProductFlow.serialize(productFlow) : null,
      productDetail: !productDetail ? null : {
        item: productDetail.item ? productDetail.item.toJson() : null,
        stock: productDetail.stock ? productDetail.stock.toJson() : null,
        stockInStores: productDetail.stockInStores,
      },
      customerDetail,
      quickCallActiveMap: Array.from(quickCallActiveMap.entries()),
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow: saveFlow ? SaveFlow.serialize(saveFlow) : null,
      customerAuthentication: null,
      paymentsVerificationInProgress: null,
      customData: customData ? {
        formEntity: customData.formEntity.toJson(),
        activeField: customData.activeField,
      } : null,
      delayedSellData: delayedSellData ? delayedSellData.toJson() : null,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    }),
  },
);

export const registerStoreStatesPersist = withMigrations<Array<IRegisterStore>>([])(
  createLocalStoragePersistentVariableWithMigration('posRegisterStoreStates'),
  {
    deserialize: (states) => map(states, ({
      loadedProducts,
      state,
      previousState,
      inputBuffer,
      payment,
      sellDocument,
      sellDocumentBackup,
      lastSellDocument,
      lastSearchedSubject,
      promoFlow,
      productFlow,
      productDetail,
      customerDetail,
      quickCallActiveMap,
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow,
      customerAuthentication,
      customData,
      delayedSellData,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    }) => ({
      loadedProducts: map(loadedProducts, (product) => new DocumentItemDto(product || {})),
      state,
      previousState,
      inputBuffer,
      payment: payment ? new DocumentPaymentDto(payment) : null,
      sellDocument: sellDocument ? new DocumentDto(sellDocument) : null,
      sellDocumentBackup: sellDocumentBackup ? new DocumentDto(sellDocumentBackup) : null,
      lastSellDocument: lastSellDocument ? new DocumentDto(lastSellDocument) : null,
      lastSearchedSubject,
      promoFlow: deserializePromoInteractionFlow(promoFlow),
      productFlow: productFlow ? ProductFlow.deserialize(productFlow) : null,
      productDetail: !productDetail ? null : {
        item: productDetail.item ? new DocumentItemDto(productDetail.item) : null,
        stock: productDetail.stock ? new StockDto(productDetail.stock) : null,
        stockInStores: productDetail.stockInStores,
      },
      customerDetail,
      quickCallActiveMap: new Map(quickCallActiveMap),
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow: saveFlow ? SaveFlow.deserialize(saveFlow) : new SaveFlow(),
      customerAuthentication,
      paymentsVerificationInProgress: false,
      customData: customData ? {
        formEntity: new CustomerAdditionalDataDto(customData.formEntity),
        activeField: customData.activeField,
      } : null,
      delayedSellData: delayedSellData ? new DelaySellDataDtoCustom(delayedSellData) : null,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    })),
    serialize: (states) => map(states, ({
      loadedProducts,
      state,
      previousState,
      inputBuffer,
      payment,
      sellDocument,
      sellDocumentBackup,
      lastSellDocument,
      lastSearchedSubject,
      promoFlow,
      productFlow,
      productDetail,
      customerDetail,
      quickCallActiveMap,
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow,
      customData,
      delayedSellData,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    }) => ({
      loadedProducts: map(loadedProducts || [], (product) => product.toJson()),
      state,
      previousState,
      inputBuffer,
      payment: payment ? payment.toJson() : null,
      sellDocument: sellDocument ? sellDocument.toJson() : null,
      sellDocumentBackup: sellDocumentBackup ? sellDocumentBackup.toJson() : null,
      lastSellDocument: lastSellDocument ? lastSellDocument.toJson() : null,
      lastSearchedSubject,
      promoFlow: promoFlow?.serialize(),
      productFlow: productFlow ? ProductFlow.serialize(productFlow) : null,
      productDetail: !productDetail ? null : {
        item: productDetail.item ? productDetail.item.toJson() : null,
        stock: productDetail.stock ? productDetail.stock.toJson() : null,
        stockInStores: productDetail.stockInStores,
      },
      customerDetail,
      quickCallActiveMap: Array.from(quickCallActiveMap.entries()),
      returnMode,
      insertMode,
      editOf,
      displayError,
      quickCallPanelPriority,
      saveFlow: saveFlow ? SaveFlow.serialize(saveFlow) : null,
      customerAuthentication: null,
      paymentsVerificationInProgress: null,
      customData: customData ? {
        formEntity: customData.formEntity.toJson(),
        activeField: customData.activeField,
      } : null,
      delayedSellData: delayedSellData ? delayedSellData.toJson() : null,
      unreturnableGroupsSeen,
      unassignedCard,
      disablePromotionTrigger,
    })),
  },
);

export const workflowStoreStatePersist = withMigrations<IWorkflowStore>([])(
  createAsyncStoragePersistentVariableWithMigration('posWorkflowStoreState'),
  {
    deserialize: (val) => {
      return {
        ...val ?? {},
        buckets: val?.buckets ? new Map(Object.entries(val.buckets)) : new Map(),
        workflowByCode: omitBy(mapValues(val?.workflowByCode ?? {}, (workflow) => {
          try {
            return new Workflow(workflow.code, {
              context: new Context(workflow.context ?? {}),
              referer: workflow.referer,
              activeStepIndex: workflow.activeStepIndex,
              previousActiveField: workflow.previousActiveField,
              activeField: workflow.activeField,
              checksum: workflow.checksum,
            });
          } catch (err) {
            console.warn(`Failed to deserialize workflow ${workflow.code}`, err);
            return undefined;
          }
        }), (value) => value === undefined),
      };
    },
    serialize: (val) => {
      return {
        ...val,
        buckets: val?.buckets ? Object.fromEntries(val.buckets) : null,
        workflowByCode: mapValues(val?.workflowByCode ?? {}, (workflow: IWorkflowStore['workflowByCode'][string]) => ({
          context: workflow.context.state,
          referer: workflow.referer,
          code: workflow.code,
          activeStepIndex: workflow.activeStepIndex,
          previousActiveField: workflow.previousActiveField,
          activeField: workflow.activeField,
          checksum: workflow.checksum,
        })),
      };
    },
  },
);

export const trainingStoreStatePersist = withMigrations<ITrainingStore>([])(
  createLocalStoragePersistentVariableWithMigration('posTrainingStoreState'),
);

export const messageFormStoreStatePersist = withMigrations<IMessageFormStore>([])(
  createLocalStoragePersistentVariableWithMigration('posMessageStoreState'),
  {
    deserialize: (
      {
        focus,
        cursor,
        message,
        replyTo,
      },
    ) => (
      {
        focus,
        cursor,
        message: message ? new CreateMessageDto(message) : null,
        replyTo: replyTo ? new MessageDetailDto(message) : null,
        attachments: [],
      }
    ),
    serialize: (
      {
        focus,
        cursor,
        message,
        replyTo,
      },
    ) => (
      {
        focus,
        cursor,
        message: message ? message.toJson() : null,
        replyTo: replyTo ? replyTo.toJson() : null,
      }
    ),
  },
);

export const inventoryStockStoreStatePersist = withMigrations<InventoryStockStoreState>([])(
  createLocalStoragePersistentVariableWithMigration('posInventoryStockStoreState'),
  {
    deserialize: ({
      state,
      previousState,
      inventoryStockInput,
      inventoryStockInputSet,
      document,
      productDetail,
      loadedItems,
      insertMode,
      networkError,
      lastProcessedItem,
      fractionDocumentList,
      fractionDocumentSearchKeyboard,
      stockType,
    }) => ({
      state,
      previousState,
      inventoryStockInput: {
        invalidReason: inventoryStockInput.invalidReason,
        code: inventoryStockInput.code,
        item: inventoryStockInput.item ? new DocumentLogisticItemDto(inventoryStockInput.item) : null,
        value: inventoryStockInput.value,
        lastValue: inventoryStockInput.lastValue,
      },
      inventoryStockInputSet: {
        item: inventoryStockInputSet?.item ? new DocumentItemDto(inventoryStockInputSet.item) : null,
        value: inventoryStockInputSet?.value ?? null,
      },
      productDetail: !productDetail ? null : {
        documentItem: productDetail.documentItem ? new DocumentItemDto(productDetail.documentItem) : null,
        canSelectVariation: productDetail.canSelectVariation,
      },
      document: new DocumentDto(document),
      loadedItems: map(loadedItems ?? [], ({documentItem, documentLogisticItem, match}) => ({
        documentItem: new DocumentItemDto(documentItem),
        documentLogisticItem: new DocumentLogisticItemDto(documentLogisticItem),
        match,
      })),
      insertMode,
      networkError,
      lastProcessedItem: lastProcessedItem ? new DocumentLogisticItemDto(lastProcessedItem) : null,
      fractionDocumentList: null,
      fractionDocumentSearchKeyboard: false,
      stockType,
    }),
    serialize: ({
      state,
      previousState,
      inventoryStockInput,
      inventoryStockInputSet,
      productDetail,
      document,
      loadedItems,
      insertMode,
      networkError,
      lastProcessedItem,
      fractionDocumentList,
      fractionDocumentSearchKeyboard,
      stockType,
    }) => ({
      state,
      previousState,
      inventoryStockInput: {
        invalidReason: inventoryStockInput.invalidReason,
        code: inventoryStockInput.code,
        item: inventoryStockInput.item ? inventoryStockInput.item.toJson() : null,
        value: inventoryStockInput.value,
        lastValue: inventoryStockInput.lastValue,
      },
      inventoryStockInputSet: {
        item: inventoryStockInputSet?.item ? inventoryStockInputSet.item.toJson() : null,
        value: inventoryStockInputSet?.value ?? null,
      },
      productDetail: !productDetail ? null : {
        documentItem: productDetail.documentItem ? productDetail.documentItem.toJson() : null,
        canSelectVariation: productDetail.canSelectVariation,
      },
      document: document.toJson(),
      loadedItems: map(loadedItems ?? [], ({documentItem, documentLogisticItem, match}) => ({
        documentItem: documentItem.toJson(),
        documentLogisticItem: documentLogisticItem.toJson(),
        match,
      })),
      insertMode,
      networkError,
      lastProcessedItem: lastProcessedItem ? lastProcessedItem.toJson() : null,
      fractionDocumentList: null,
      fractionDocumentSearchKeyboard: false,
      stockType,
    }),
  },
);

export const inventoryStoreStatePersist = withMigrations<IInventoryStore>([])(
  createAsyncStoragePersistentVariableWithMigration('posInventoryStoreState'),
  {
    deserialize: ({
      state,
      previousState,
      inventoryCalculationType,
      partialMode,
      inventoryDocument,
      inventoryModeSelected,
      insertMode,
      inputBuffer,
      loadedData,
      gridData,
      activeDocumentLogisticItem,
      filters,
      productDetail,
      inventoryGroups,
      previewOnly,
      inventoryMetadata,
      inventoryGroupInputs,
    }) => ({
      state,
      previousState,
      inventoryCalculationType,
      partialMode,
      inventoryDocument: inventoryDocument ? new DocumentDto(inventoryDocument) : null,
      inventoryModeSelected,
      insertMode,
      inputBuffer,
      loadedData,
      gridData,
      activeDocumentLogisticItem: activeDocumentLogisticItem ?
        new DocumentLogisticItemDto(activeDocumentLogisticItem) :
        null,
      filters: filters ? JSON.parse(filters) : null,
      productDetail: !productDetail ? null : {
        item: productDetail.item ? new DocumentLogisticItemDto(productDetail.item) : null,
      },
      inventoryGroups: map(inventoryGroups ?? [], (group) => new DocumentInventoryGroup(group)),
      previewOnly,
      inventoryMetadata: inventoryMetadata ?
        new InventoryMetadataDto(inventoryMetadata) :
        null,
      stock: null,
      inventoryGroupInputs,
    }),
    serialize: ({
      state,
      previousState,
      inventoryCalculationType,
      partialMode,
      inventoryDocument,
      inventoryModeSelected,
      insertMode,
      inputBuffer,
      loadedData,
      gridData,
      activeDocumentLogisticItem,
      filters,
      productDetail,
      inventoryGroups,
      previewOnly,
      inventoryMetadata,
      inventoryGroupInputs,
    }) => ({
      state,
      previousState,
      inventoryCalculationType,
      partialMode,
      inventoryDocument: inventoryDocument ? inventoryDocument.toJson() : null,
      inventoryModeSelected,
      insertMode,
      inputBuffer,
      loadedData,
      gridData,
      activeDocumentLogisticItem: activeDocumentLogisticItem ?
        activeDocumentLogisticItem.toJson() :
        null,
      filters: filters ? JSON.stringify(filters) : null,
      productDetail: !productDetail ? null : {
        item: productDetail.item ? productDetail.item.toJson() : null,
      },
      inventoryGroups: map(inventoryGroups ?? [], (group) => group.toJson()),
      previewOnly,
      inventoryMetadata: inventoryMetadata ?
        inventoryMetadata.toJson() :
        null,
      stock: null,
      inventoryGroupInputs,
    }),
  },
);
