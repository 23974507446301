
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import Modal from '../../../Components/Modal.vue';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';
import {useHelpTranslations} from '@/Helpers/translations';
import Html from '@/Components/Html.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import {ZLayer} from '@/constants/zLayer';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';

const createInitError = () => ({
  id: 'error',
  name: null,
  help: null,
});

export default defineComponent({
  name: 'ModalHelp',
  components: {
    ModalDetachedClose,
    Html,
    Modal,
  },
  setup() {
    const helpTranslations = useHelpTranslations();
    const helpStore = useHelpStore();
    const error = ref(createInitError());
    const description = computed(() => helpStore.error.value?.description);
    const documentStatusStore = useDocumentStatusStore();

    const zIndex = computed(() => {
      if (!documentStatusStore.dialog.value) {
        return ZLayer.modal;
      }

      return ZLayer.aboveSpinner;
    });

    watch( // TODO refactor setting error.value
      () => helpStore.error.value,
      (val) => {
        if (val && val.id) {
          error.value = helpTranslations.getError(val.id, val.params);
        } else {
          error.value = createInitError();
        }
      },
    );

    function close() {
      helpStore.close();
    }

    return {
      close,
      error,
      helpStore,
      description,
      zIndex,
    };
  },
});
