import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {WorkflowStepNominalsCount} from '@/Modules/Workflow/Step/StepNominalsCount/WorkflowStepNominalsCount';
import {
  Currency,
  DocumentDto,
  ReferentialDocumentsDto,
} from '@/Model/Entity';
import {
  isEmpty,
  map,
  toUpper,
} from 'lodash-es';
import {useFilters} from '@/Helpers/filters';

export class WorkflowStepSafeBagEditNominalsCount extends WorkflowStepNominalsCount {
  static get type() {
    return WorkflowStepTypes.SafeBagEditNominalsCount;
  }

  get type() {
    return WorkflowStepSafeBagEditNominalsCount.type;
  }

  get minValueValidationValue() {
    return this.configurationStore.configuration.value
      ?.features
      ?.cashLimits
      ?.[toUpper(this.step.currency)]
      ?.minAmountInSafebag;
  }

  get safeBag() {
    const value = this.getFieldValue(WorkflowStepField.safeBagDocument, {});
    return new DocumentDto(value);
  }

  get formInputs() {
    const {currencyFormat} = useFilters();

    return [
      ...this.availableNominals.map((nominal) => ({
        field: this.createNominalKey(nominal),
        label: currencyFormat(nominal, this.currency.symbol, {
          minimumFractionDigits: 0,
        }),
        amount: nominal,
      })),
      ...(this.enterCoinsTogether ? [
        {
          field: WorkflowStepField.coins,
          label: this.step.coins,
        },
      ] : []),
    ];
  }

  get nominalsFromSafebagItems() {
    const items = this.safeBag?.items ?? [];

    return map(items, (item) => {
      return {
        nominal: item.description,
        count: item.quantity,
      };
    });
  }

  get fieldsValues() {
    return this.nominalsFromSafebagItems.map(({nominal, count}) => {
      const field = this.createNominalKey(nominal);
      const fieldValue = parseInt(this.getFieldValue(field));

      return {
        field,
        edited: fieldValue !== count,
        diff: Math.abs(fieldValue - count),
      };
    });
  }

  get fieldsChanged() {
    return this.fieldsValues.findIndex((value) => value.edited) !== -1;
  }

  async beforeEnter() {
    if (this.stepInit) return;

    this.setNominalsSettings();

    this.nominalsFromSafebagItems.forEach(({nominal, count}) => {
      this.dataSetter(this.createNominalKey(nominal), () => count);
    });

    this.stepInit = true;
  }

  async beforeContinue() {
    if (!this.validateMinValue()) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.MIN_VALUE,
          args: {
            minValue: this.minValueValidationValue,
          },
        },
      }));

      throw new Error('invalid');
    }

    if (!await this.validate()) {
      throw new Error('invalid');
    }

    if (this.automaticMode) {
      this.setNominalFields();
    }

    if (this.fieldsChanged) {
      const nominals = DocumentDto.createNominalsCountDocument({
        currency: this.data.nominalsSettings.currency,
        envelopeNumber: this.data.safeBagEnvelopeCode,
        documentBarcode: this.data.safeBagEnvelopeCode,
        externalReference: this.data.safeBagEnvelopeCode,
        items: this.acceptableNominalsWithUnitedCoinsAsItems,
        isPreview: false,
      });

      nominals.refreshTotalPrice();

      this.dataSetter(WorkflowStepField.safeBagDocumentEdited, () => nominals.toJson());
    }
  }
}
