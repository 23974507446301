import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerClubDto from '../CustomerClubDto';
import {ArrayType} from '@designeo/apibundle-js';
import AccountTransactionPreviewDto from '../AccountTransactionPreviewDto';


export const CustomerAccountDtoPropDef = {
  id: {
    type: new StringType(),
  },
  points: {
    type: new NumberType(),
  },
  lastTransactionDate: {
    type: new DateTimeType(),
  },
  club: {
    type: new EntityType(CustomerClubDto),
  },
  latestTransactions: {
    type: new ArrayType(new EntityType(AccountTransactionPreviewDto)),
  },
};
export default class GeneratedCustomerAccountDto extends createEntity(CustomerAccountDtoPropDef) {}
