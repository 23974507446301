
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {ConfirmTypes} from '@/Modules/Core/types';

export default defineComponent({
  name: 'ModalDetachedBody',
  props: {
    type: {
      type: String as PropType<ConfirmTypes>,
      required: false,
      default: ConfirmTypes.default,
    },
  },
  setup(props) {
    const typeClasses = computed(() => {
      switch (props.type) {
      case ConfirmTypes.error:
        return 'text-danger';
      case ConfirmTypes.warning:
        return 'text-warning';
      case ConfirmTypes.info:
        return 'text-info';
      case ConfirmTypes.success:
        return 'text-success';
      case ConfirmTypes.default:
        return null;
      default: return null;
      }
    });


    return {
      typeClasses,
    };
  },
});
