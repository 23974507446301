import {RegisterRoutesOptions} from '@/ModuleSystem';

export default [
  {
    path: '/404',
    name: '404',
    meta: {authorization: false},
    component: () => import('./404/404Page.vue'),
  },
  // ...(process.env.NODE_ENV === 'development' ? [
  {
    path: '/playground-modals',
    name: 'playground-modals',
    component: () => import('./Playground/Projects/ProjectModal.vue'),
  },
  {
    path: '/playground-authorization',
    name: 'playground-authorization',
    component: () => import('./Playground/Projects/ProjectAuthorization.vue'),
  },
  {
    path: '/playground-qwerty',
    name: 'playground-qwerty',
    component: () => import('./Playground/Projects/ProjectQwerty.vue'),
  },
  {
    path: '/playground-article',
    name: 'playground-article',
    component: () => import('./Playground/Projects/ProjectArticle.vue'),
  },
  {
    path: '/playground-style-guide',
    name: 'playground-style-guide',
    component: () => import('./Playground/Projects/ProjectStyleGuide.vue'),
  },
  {
    path: '/playground',
    name: 'playground',
    component: () => import('./Playground/PlaygroundPage.vue'),
  },
  // ] : []),
  {
    path: '/signpost',
    name: 'signpost',
    component: () => import('./Signpost/Signpost.vue'),
  },
] as RegisterRoutesOptions;
