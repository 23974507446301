/* eslint-disable max-len */
export const PERMISSION_STORNO = 'PERMISSION_STORNO';
export const PERMISSION_STORNO_REQUEST_FOUR_EYES = 'PERMISSION_STORNO_REQUEST_FOUR_EYES';
export const PERMISSION_STORNO_PROCESS_FOUR_EYES = 'PERMISSION_STORNO_PROCESS_FOUR_EYES';
export const PERMISSION_CANCEL = 'PERMISSION_CANCEL';
export const PERMISSION_CANCEL_REQUEST_FOUR_EYES = 'PERMISSION_CANCEL_REQUEST_FOUR_EYES';
export const PERMISSION_CANCEL_PROCESS_FOUR_EYES = 'PERMISSION_CANCEL_PROCESS_FOUR_EYES';
export const PERMISSION_RETURN = 'PERMISSION_RETURN';
export const PERMISSION_RETURN_REQUEST_FOUR_EYES = 'PERMISSION_RETURN_REQUEST_FOUR_EYES';
export const PERMISSION_RETURN_PROCESS_FOUR_EYES = 'PERMISSION_RETURN_PROCESS_FOUR_EYES';
export const PERMISSION_STORNO_BEFORE_TIMEOUT = 'PERMISSION_STORNO_BEFORE_TIMEOUT';
export const PERMISSION_STORNO_BEFORE_TIMEOUT_REQUEST_FOUR_EYES = 'PERMISSION_STORNO_BEFORE_TIMEOUT_REQUEST_FOUR_EYES';
export const PERMISSION_STORNO_BEFORE_TIMEOUT_PROCESS_FOUR_EYES = 'PERMISSION_STORNO_BEFORE_TIMEOUT_PROCESS_FOUR_EYES';
export const PERMISSION_STORNO_AFTER_TIMEOUT = 'PERMISSION_STORNO_AFTER_TIMEOUT';
export const PERMISSION_STORNO_AFTER_TIMEOUT_REQUEST_FOUR_EYES = 'PERMISSION_STORNO_AFTER_TIMEOUT_REQUEST_FOUR_EYES';
export const PERMISSION_STORNO_AFTER_TIMEOUT_PROCESS_FOUR_EYES = 'PERMISSION_STORNO_AFTER_TIMEOUT_PROCESS_FOUR_EYES';
export const PERMISSION_CANCEL_OF_STORNO = 'PERMISSION_CANCEL_OF_STORNO';
export const PERMISSION_CANCEL_OF_STORNO_REQUEST_FOUR_EYES = 'PERMISSION_CANCEL_OF_STORNO_REQUEST_FOUR_EYES';
export const PERMISSION_CANCEL_OF_STORNO_PROCESS_FOUR_EYES = 'PERMISSION_CANCEL_OF_STORNO_PROCESS_FOUR_EYES';
export const PERMISSION_QUICKLOGIN_MANAGE = 'PERMISSION_QUICKLOGIN_MANAGE';
export const PERMISSION_QUICKLOGIN_MANAGE_REQUEST_FOUR_EYES = 'PERMISSION_QUICKLOGIN_MANAGE_REQUEST_FOUR_EYES';
export const PERMISSION_QUICKLOGIN_MANAGE_PROCESS_FOUR_EYES = 'PERMISSION_QUICKLOGIN_MANAGE_PROCESS_FOUR_EYES';
export const PERMISSION_POS_BLOCK_STATE_MANAGE = 'PERMISSION_POS_BLOCK_STATE_MANAGE';
export const PERMISSION_POS_BLOCK_STATE_MANAGE_REQUEST_FOUR_EYES = 'PERMISSION_POS_BLOCK_STATE_MANAGE_REQUEST_FOUR_EYES';
export const PERMISSION_POS_BLOCK_STATE_MANAGE_PROCESS_FOUR_EYES = 'PERMISSION_POS_BLOCK_STATE_MANAGE_PROCESS_FOUR_EYES';
export const PERMISSION_POS_RE_REGISTRATION_MANAGE = 'PERMISSION_POS_RE_REGISTRATION_MANAGE';
export const PERMISSION_POS_RE_REGISTRATION_MANAGE_REQUEST_FOUR_EYES = 'PERMISSION_POS_RE_REGISTRATION_MANAGE_REQUEST_FOUR_EYES';
export const PERMISSION_POS_RE_REGISTRATION_MANAGE_PROCESS_FOUR_EYES = 'PERMISSION_POS_RE_REGISTRATION_MANAGE_PROCESS_FOUR_EYES';
export const PERMISSION_RECOVERY = 'PERMISSION_RECOVERY';
export const PERMISSION_RECOVERY_REQUEST_FOUR_EYES = 'PERMISSION_RECOVERY_REQUEST_FOUR_EYES';
export const PERMISSION_RECOVERY_PROCESS_FOUR_EYES = 'PERMISSION_RECOVERY_PROCESS_FOUR_EYES';
export const PERMISSION_DEV_TOOLS = 'PERMISSION_DEV_TOOLS';
export const PERMISSION_DEV_TOOLS_REQUEST_FOUR_EYES = 'PERMISSION_DEV_TOOLS_REQUEST_FOUR_EYES';
export const PERMISSION_DEV_TOOLS_PROCESS_FOUR_EYES = 'PERMISSION_DEV_TOOLS_PROCESS_FOUR_EYES';
export const PERMISSION_MESSAGES_APP_ACCESS = 'PERMISSION_MESSAGES_APP_ACCESS';
export const PERMISSION_MESSAGES_APP_ACCESS_REQUEST_FOUR_EYES = 'PERMISSION_MESSAGES_APP_ACCESS_REQUEST_FOUR_EYES';
export const PERMISSION_MESSAGES_APP_ACCESS_PROCESS_FOUR_EYES = 'PERMISSION_MESSAGES_APP_ACCESS_PROCESS_FOUR_EYES';
export const PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS = 'PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS';
export const PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS_REQUEST_FOUR_EYES = 'PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS_REQUEST_FOUR_EYES';
export const PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS_PROCESS_FOUR_EYES = 'PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS_PROCESS_FOUR_EYES';
export const PERMISSION_MESSAGES_DISPLAY_SENSITIVE = 'PERMISSION_MESSAGES_DISPLAY_SENSITIVE';
export const PERMISSION_MESSAGES_DISPLAY_SENSITIVE_ACCESS_REQUEST_FOUR_EYES = 'PERMISSION_MESSAGES_DISPLAY_SENSITIVE_ACCESS_REQUEST_FOUR_EYES';
export const PERMISSION_MESSAGES_DISPLAY_SENSITIVE_ACCESS_PROCESS_FOUR_EYES = 'PERMISSION_MESSAGES_DISPLAY_SENSITIVE_ACCESS_PROCESS_FOUR_EYES';
export const PERMISSION_INVENTORY_REFRESH = 'PERMISSION_INVENTORY_REFRESH';
export const PERMISSION_WORKFLOW_ACCESS_CUSTOM = 'PERMISSION_WORKFLOW_ACCESS_CUSTOM';


