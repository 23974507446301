import {map} from 'lodash-es';
import {
  Ref,
  ref,
  watch,
} from 'vue';

export const maskString = (str: string, visible = 1) =>
  map(str, (char, index, array) => index === array.length - visible ? char : '*').join('');

export const useMaskString = (val: Ref, visible = 0) => {
  const maskedValue = ref('');
  let maskTimeout = null;

  watch(val, (newValue, oldValue) => {
    clearTimeout(maskTimeout);
    if (visible === 0) {
      maskedValue.value = maskString(newValue, 0);
    } else if (oldValue.length > newValue.length) {
      maskedValue.value = maskString(newValue, 0);
    } else {
      maskedValue.value = maskString(newValue, visible);
      maskTimeout = setTimeout(() => {
        maskedValue.value = maskString(newValue, 0);
      }, 600);
    }
  });

  return {
    maskedValue,
  };
};
