import {
  setWith,
  get,
} from 'lodash-es';
import {reactive} from 'vue';

export class Context {
  public state: {[key: string]: any}
  constructor(state = {}) {
    this.state = reactive(state);
  }

  clear() {
    for (const key of Object.keys(this.state)) {
      delete this.state[key];
    }
  }

  get(key: string, {section = 'data'} = {}) {
    return this.state[key]?.[section] ?? {};
  }

  set(key: string, value, {section = 'data'} = {}) {
    this.state[key] = {
      ...this.state[key] ?? {},
      [section]: value,
    };
  }

  modifyAttribute<V extends any>(
    key: string,
    attribute: string,
    modifier: (currentValue: V)=> V,
    {section = 'data'} = {},
  ) {
    const data = this.get(key, {section});
    const newData = {...data};

    setWith(newData, attribute, modifier(get(newData, attribute)), Object);

    this.set(key, newData, {section});
  }

  modifySection<V extends {[key: string]: any}>(
    key: string,
    modifier: (currentValue: V)=> V,
    {section = 'data'} = {},
  ) {
    const data = this.get(key, {section});

    this.set(key, {...data, ...modifier(data)}, {section});
  }
}
