
import {
  defineComponent,
  PropType,
  provide,
} from 'vue';
import AppLoader from '../Modules/Core/AppLoader/AppLoader.vue';
import ModalConfirm from '../Modules/Core/Confirm/ModalConfirm.vue';
import {useInactivityDetection} from '@/Helpers/inactivity';
import ModalFourEyes from '@/Modules/Core/FourEyes/ModalFourEyes.vue';
import ModalHelp from '@/Modules/Core/Help/ModalHelp.vue';
import ModalPrintContent from '@/Modules/Core/PrintContent/ModalPrintContent.vue';
import {mountDevToolsKeyboardListener} from '@/Helpers/devTools';
import ModalDocumentStatus from '@/Modules/Core/DocumentStatus/ModalDocumentStatus.vue';
import ModalCustomerInteraction from '@/Modules/Core/CustomerInteraction/ModalCustomerInteraction.vue';
import ModalCashAmountValidator from '@/Modules/Core/CashAmountValidator/ModalCashAmountValidator.vue';
import ModalBlockedState from '@/Modules/Core/BlockedState/ModalBlockedState.vue';
import ModalUpdateState from '@/Modules/Core/UpdateState/ModalUpdateState.vue';
import {useUpdate} from '@/Helpers/update';
import ModalCrashState from '@/Modules/Core/CrashState/ModalCrashState.vue';
import ModalIncorrectRestart from '@/Modules/IncorrectRestart/IncorrectRestart/ModalIncorrectRestart.vue';
import {useRecovery} from '@/Helpers/recovery';
import ModalPosCoreDisconnectState from '@/Modules/Core/PosCoreDisconnectState/ModalPosCoreDisconnectState.vue';
import ModalPrinterServiceDisconnectState
  from '@/Modules/Core/PrinterServiceDisconnectState/ModalPrinterServiceDisconnectState.vue';
import ModalImportantUnreadMessages from '@/Modules/Apps/PosMessage/Modals/ModalImportantUnreadMessages.vue';
import {useMessageReminder} from '@/Helpers/messages';
import Theme from '@/Components/Theme.vue';
import ModalSynchronizationState from '@/Modules/Core/SynchronizationState/ModalSynchronizationState.vue';
import {useKeyboardShortcuts} from '@/Components/KeyboardShortcuts';
import InactivityBar from '@/Components/InactivityBar.vue';
import ModalInactivity from '@/Modules/Core/Inactivity/ModalInactivity.vue';
import {useAuth} from '@/Helpers/auth';
import {AppVersion} from '@/constants/appVersion';
import {useAppVersion} from '@/Helpers/appVersion';
import {useAnalogDisplay} from '@/Helpers/analogDisplay';
import ModalPrinterStatus from '@/Modules/Core/PrinterStatus/ModalPrinterStatus.vue';
import {useReferentialDocumentsTimeBasedEvents}
  from '@/Modules/Apps/ReferentialDocuments/referentialDocumentsTimeBasedEvents';
import {useEshopOrdersNotifications} from '@/Modules/Apps/EshopOrders/eshopOrders';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {emitTestEvent} from '@/Helpers/testEvent';
import ModalPosDeactivatedState from '@/Modules/Core/PosDeactivatedState/ModalPosDeactivatedState.vue';

export default defineComponent({
  components: {
    ModalPrinterStatus,
    InactivityBar,
    ModalSynchronizationState,
    ModalImportantUnreadMessages,
    ModalPosCoreDisconnectState,
    ModalPrinterServiceDisconnectState,
    ModalIncorrectRestart,
    ModalCrashState,
    ModalUpdateState,
    ModalBlockedState,
    ModalCashAmountValidator,
    ModalCustomerInteraction,
    ModalDocumentStatus,
    ModalHelp,
    ModalPrintContent,
    ModalFourEyes,
    ModalConfirm,
    ModalInactivity,
    AppLoader,
    Theme,
    ModalPosDeactivatedState,
  },
  props: {
    appId: {
      type: null,
      required: true,
    },
    appVersion: {
      type: String as PropType<AppVersion>,
      required: true,
    },
  },
  setup(props) {
    provide('appId', props.appId);

    useAuth();

    useAppVersion(props.appVersion);

    useKeyboardShortcuts();

    useInactivityDetection();

    useUpdate();

    useRecovery();

    useMessageReminder();

    useAnalogDisplay();

    useReferentialDocumentsTimeBasedEvents();

    useEshopOrdersNotifications({
      passive: false,
      onOrderNotification: () => {
        emitTestEvent(TestEvent.ESHOP_NOTIFICATIONS_UPDATE);
      },
    });

    mountDevToolsKeyboardListener();
  },
});
