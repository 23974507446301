import {
  BooleanType,
  createEntity,
} from '@designeo/apibundle-js';


export default class Articles extends createEntity({
  displayArticleImage: {
    type: new BooleanType(),
  },
}) {}
