import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col gap-12 flex-grow" }
const _hoisted_3 = { class: "flex flex-col gap-2 uppercase" }
const _hoisted_4 = { class: "grid grid-cols-7 font-bold" }
const _hoisted_5 = { class: "col-span-2" }
const _hoisted_6 = { class: "col-span-5" }
const _hoisted_7 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationConfirm.question')), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary, (summaryItem) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: summaryItem.field
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(summaryItem.label), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(summaryItem.value), 1)
              ], 64))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_WorkflowTransitions, null, {
            transitionNext: _withCtx(({goNext}) => [
              _createVNode(_component_AsyncButton, {
                test: 'wfTransitionsNext',
                onClick: goNext,
                class: "py-2.5 px-7 btn-success"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconCheck, { class: "inline h-3" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('workflow.stepUI.finish')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}