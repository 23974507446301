import {createApp, toRaw} from 'vue';
import {registerVueI18n} from '@/Plugins/i18n';
import {registerModuleSystem} from '@/ModuleSystem';
import {createContext} from '@designeo/js-helpers/src/index';
import {isElectron, reloadAppFromRoot} from '@/Helpers/app';
import {registerTestHelpers} from '@/Plugins/testHelpers';
import {registerAuthPos} from '@/Modules/Auth/index.pos';
import {registerRouter} from '@/Plugins/router';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {InactivityCondition} from '@/Helpers/inactivity';
import ResetToShopPage from '@/Modules/ResetToShop/ResetToShop/ResetToShopPage.vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useResetToShopStore} from '@/Modules/Core/store/ResetToShopStore';
import {seekApiErrors, useErrorParser} from '@/Helpers/errors';
import {ConfirmTypes} from '@/Modules/Core/types';
import {RouterTree} from '@/ModuleSystem/routerTree';
import {registerFeatures} from '@/Plugins/features';

export async function createResetToShop(routerTree: RouterTree) {
  const app = createApp(ResetToShopPage, {
    isElectron: isElectron(),
  });

  const ctx = (
    createContext({
      app,
      appId: {},
      isRestoreMode: false,
      restoredErrorRecord: null,
      routerTree,
    })
      .register(registerFeatures)
      .register(registerTestHelpers)
      .register(registerModuleSystem())
      .register(registerAuthPos)
      .register(async (ctx) => {
        const configurationStore = useConfigurationStore();
        const coreStore = useCoreStore();
        const resetToShopStore = useResetToShopStore();
        const parseError = useErrorParser();

        ctx.locale = configurationStore.configuration.value.general.language.value?.toLowerCase();
        await ctx.registerTranslations({});
        await ctx.registerRoutes([
          {
            path: '/register',
            name: 'register',
            redirect: () => {
              (async () => {
                try {
                  coreStore.setLoader(true);
                  await resetToShopStore.execute();

                  // TODO: extra page?
                  await coreStore.alert('Migration successful');

                  await reloadAppFromRoot();
                } catch (e) {
                  console.error(e);
                  await coreStore.alert(parseError(seekApiErrors(e)).join(', '), {type: ConfirmTypes.error});
                } finally {
                  coreStore.setLoader(false);
                }
              })();
              return {name: 'login'};
            },
          },
        ]);
        await ctx.registerHookAfterEach(InactivityCondition.inactivityRouterHook);
      })
      .register(registerVueI18n)
      .register(registerRouter)
  );

  const resolvedCtx = await ctx.resolve();

  resolvedCtx.app.use(resolvedCtx.router);

  await resolvedCtx.router.isReady();

  return resolvedCtx.app;
}
