import {
  App,
} from 'vue';
import {Router} from 'vue-router';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {IRestoredErrorRecord} from '@/Helpers/restoreMode';
import {createResetToShop} from '@/createResetToShop';
import {useResetToShopStore} from '@/Modules/Core/store/ResetToShopStore';
import {RouterTree} from '@/ModuleSystem/routerTree';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
  ensureStartupClose: ()=> void
  ensureStartupOpen: ()=> Promise<void>
  router: Router
  isRestoreMode: boolean,
  restoredErrorRecord: IRestoredErrorRecord
  replicateRouterTree: ()=> RouterTree
}

export type RegisterTestHelpersContext = {
}

export async function registerResetToShop(ctx: CoreRequiredContext) {
  const resetToShopStore = useResetToShopStore();
  const configurationStore = useConfigurationStore();

  const {isSecondaryDisplay, currentRouterPath} = useSecondaryDisplay({router: ctx.router});

  if (isSecondaryDisplay) {
    return {};
  }

  if (configurationStore.configuration.value?.isPosNotConfigured) {
    return {};
  }

  if (!resetToShopStore.isActive.value) {
    return {};
  }

  ctx.ensureStartupClose();

  const resetToShop = await createResetToShop(ctx.replicateRouterTree());

  resetToShop.mount('#resetToShop');

  return new Promise((resolve) => {
    resetToShopStore.setCallback(resolve);
  })
    // note: .then should not be called, because success will refresh app, and we bypass this process
    .then((path = currentRouterPath) => {
      resetToShop.unmount();

      return Promise.resolve();
    })
    .then(() => ctx.ensureStartupOpen());
}
