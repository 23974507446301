
/* eslint-disable max-len */
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,

} from 'vue';
import {
  WorkflowActions,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,

} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepWarehouseOperationDistributionCenter} from '@/Modules/Workflow/Step/StepWarehouseOperationDistributionCenter/WorkflowStepWarehouseOperationDistributionCenter';
import {apiInStoreManagementDistributionCenterList} from '@/Model/Action';
import DistributionCenterDto from '@/Model/Entity/DistributionCenterDto';
import WarehouseOperationDistributionCenterList
  from '@/Modules/Workflow/Step/StepWarehouseOperationDistributionCenter/WarehouseOperationDistributionCenterList.vue';
import {
  debounce,
  map,
} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import FormInput from '@/Components/FormInput.vue';

export default defineComponent({
  name: 'StepWarehouseOperationDistributionCenter',
  components: {
    FormInput,
    HLInput,
    WarehouseOperationDistributionCenterList,
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.WarehouseOperationDistributionCenter;
    const {workflowStep, currentWorkflow} = useStepTools<WorkflowStepWarehouseOperationDistributionCenter>(stepType);
    const managementDistributionCenterList = ref<DistributionCenterDto[]>([]);

    const fetchDistributionCenterList = async () => {
      try {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

        managementDistributionCenterList.value = await apiInStoreManagementDistributionCenterList({
          params: {
            ...(workflowStep.value.searchString.length < 2 ? {} : {
              name: workflowStep.value.searchString,
            }),
          },
        });
      } catch (e) {
        managementDistributionCenterList.value = [];
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      } finally {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    };

    const debouncedFetchDistributionCenterList = debounce(fetchDistributionCenterList, 400);

    const distributionCenters = computed(() => {
      return map(managementDistributionCenterList.value, (listItem: DistributionCenterDto) => {
        return {
          entity: listItem,
          isActive: workflowStep.value.operationEntityPropDistributionCenter
            ?.dc === listItem.dc,
        };
      });
    });

    const onDistributionCenterSelect = (distributionCenter: DistributionCenterDto) => {
      workflowStep.value.operationEntityPropDistributionCenter = distributionCenter;
    };

    onMounted(async () => {
      workflowStep.value.messageBus.addEventListener(WorkflowActions.ENTER, debouncedFetchDistributionCenterList);
      await fetchDistributionCenterList();
    });

    onBeforeUnmount(() => {
      if (!currentWorkflow.value) {
        return;
      }

      workflowStep.value.messageBus.removeEventListener(WorkflowActions.ENTER, debouncedFetchDistributionCenterList);
    });

    const searchField = computed(() => {
      return workflowStep.value.createFieldDataPath(WorkflowStepField.searchString);
    });

    watch(() => workflowStep.value.searchString, () => {
      debouncedFetchDistributionCenterList();
    }, {flush: 'post'});

    return {
      stepType,
      distributionCenters,
      onDistributionCenterSelect,
      searchField,
    };
  },
});
