import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import SafeBagDto from '../SafeBagDto';


export const SafeBagsReportDtoPropDef = {
  safeBags: {
    type: new ArrayType(new EntityType(SafeBagDto)),
  },
};
export default class GeneratedSafeBagsReportDto extends createEntity(SafeBagsReportDtoPropDef) {}
