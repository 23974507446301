import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderPaymentType from '../OrderPaymentType';
import {DateTimeType} from '@designeo/apibundle-js';


export const NewOrderPaymentDtoPropDef = {
  id: {
    type: new StringType(),
  },
  total: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  terminalVirtualId: {
    type: new StringType(),
  },
  paymentReferenceId: {
    type: new StringType(),
  },
  paymentReferenceNumber: {
    type: new StringType(),
  },
  paymentType: {
    type: new EntityType(OrderPaymentType),
  },
  paymentDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedNewOrderPaymentDto extends createEntity(NewOrderPaymentDtoPropDef) {}
