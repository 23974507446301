import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col flex-grow" }
const _hoisted_3 = {
  class: "grid gap-x-4 font-bold",
  style: {"grid-template-columns":"240px 70px 110px"}
}
const _hoisted_4 = { class: "text-right" }
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = { class: "flex-grow h-0 overflow-y-auto" }
const _hoisted_7 = { class: "text-sm" }
const _hoisted_8 = { class: "text-right" }
const _hoisted_9 = { class: "text-right" }
const _hoisted_10 = { class: "grid grid-cols-3 gap-x-4 mb-0" }
const _hoisted_11 = { class: "col-span-3 text-right" }
const _hoisted_12 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, _toDisplayString(_ctx.workflowStep.step.articleLabel), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.workflowStep.step.quantityLabel), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.workflowStep.step.priceLabel), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: index,
                class: "grid gap-x-4 pb-2 items-center",
                style: {"grid-template-columns":"240px 70px 110px"}
              }, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(item.description), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(item.quantity), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.currencyFormat(item.quantity * item.priceNormal)), 1)
              ], 512)), [
                [
                  _directive_test,
                  item.internalNumber,
                  void 0,
                  { internalNumber: true }
                ],
                [
                  _directive_test,
                  item.quantity,
                  void 0,
                  { quantity: true }
                ],
                [
                  _directive_test,
                  item.quantity * item.priceNormal,
                  void 0,
                  { priceNormal: true }
                ]
              ])
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.workflowStep.step.totalLabel), 1),
              _createTextVNode(": " + _toDisplayString(_ctx.currencyFormat(_ctx.workflowStep.sellDocument?.header?.total)), 1)
            ], 512), [
              [
                _directive_test,
                _ctx.workflowStep.sellDocument?.header?.total,
                void 0,
                { total: true }
              ]
            ])
          ])
        ]),
        _createVNode(_component_WorkflowTransitions, { class: "mb-0" })
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}