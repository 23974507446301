import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_Countdown = _resolveComponent("Countdown")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    name: "inactivityMessage",
    invalidateRoute: false,
    zIndex: _ctx.zIndex,
    onClose: _ctx.onContinue
  }, {
    body: _withCtx(() => [
      _createVNode(_component_ModalDetachedClose, { onClose: _ctx.onContinue }, null, 8, ["onClose"]),
      _createVNode(_component_ModalDetachedBody, {
        type: _ctx.ConfirmTypes.error
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('inactivity.modal.title')) + ": ", 1),
          _createVNode(_component_Countdown, {
            milliseconds: _ctx.limit - _ctx.progress,
            withLead: false,
            ticks: _ctx.CountdownTicks.external
          }, null, 8, ["milliseconds", "ticks"])
        ]),
        _: 1
      }, 8, ["type"]),
      _createVNode(_component_ModalDetachedButtons, { single: true }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("button", {
            class: "btn-md btn-success",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onContinue && _ctx.onContinue(...args)))
          }, [
            _renderSlot(_ctx.$slots, "confirmBtn", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.$t('action.continue')), 1)
            ])
          ], 512), [
            [_directive_test, 'modalConfirm']
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["open", "zIndex", "onClose"]))
}