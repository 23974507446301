import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center bg-white px-8 py-10 rounded-xl shadow"
}
const _hoisted_2 = { class: "pb-4 text-lg" }
const _hoisted_3 = { class: "flex flex-row gap-6 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLoaderPulse = _resolveComponent("IconLoaderPulse")!
  const _component_TransitionWrapper = _resolveComponent("TransitionWrapper")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#app-loader" }, [
    (_ctx.loaderActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fixed top-0 left-0 opacity-0 w-screen h-screen",
          style: _normalizeStyle({
        zIndex: _ctx.ZLayer.spinner - 1
      })
        }, null, 4))
      : _createCommentVNode("", true),
    _createVNode(_component_TransitionWrapper, {
      enterTime: 300,
      leaveTime: 200,
      startDelay: 300,
      isVisible: _ctx.loaderActive
    }, {
      default: _withCtx(({entering, leaving, entered}) => [
        _createElementVNode("div", {
          class: _normalizeClass(["app-loader", [
            'opacity-0',
            `bg-opacity-${_ctx.loaderOpacity}`,
            {
              ['ease-out duration-300']: entering,
              ['ease-in duration-200 pointer-events-none']: leaving,
              ['opacity-100']: (entered || entering) && !leaving,
            }
          ]]),
          style: _normalizeStyle({
            zIndex: _ctx.ZLayer.spinner
          })
        }, [
          (_ctx.isVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('loader.reloadMessage')), 1),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.hasReload)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn-danger btn-md",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
                      }, _toDisplayString(_ctx.$t('action.reload')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.hasCancelRequests)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "btn-warning btn-md",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelRequests && _ctx.cancelRequests(...args)))
                      }, _toDisplayString(_ctx.$t('loader.cancelRequests')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.hasWait)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 2,
                        class: "btn-primary btn-md",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.wait && _ctx.wait(...args)))
                      }, _toDisplayString(_ctx.$t('action.wait')), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            : (_openBlock(), _createBlock(_component_IconLoaderPulse, { key: 1 }))
        ], 6)
      ]),
      _: 1
    }, 8, ["isVisible"])
  ]))
}