import {toRaw} from 'vue';
import {map} from 'lodash-es';
import GeneratedChargeResponseDto from './generated/ChargeResponseDto';
import {
  CheckAndChargeChargeResponseObjectTypes,
} from '@/constants/checkAndChargeResponseObjectTypes';
import PackSellDto from '@/Model/Entity/PackSellDto';
import TicketSellDto from '@/Model/Entity/TicketSellDto';
import GIPChargeDto from '@/Model/Entity/GIPChargeDto';
import DocumentDto from '@/Model/Entity/DocumentDto';
import DocumentItemDto from '@/Model/Entity/DocumentItemDto';
import {b64EncodeUnicode} from '@/Helpers/base64';
import EasyCoinOrderDto from './EasyCoinOrderDto';
import {ContentBase64Sources} from '@/constants/contentBase64Sources';
import DocumentContentDto from '@/Model/Entity/DocumentContentDto';
import VoucherChargeDto from '@/Model/Entity/VoucherChargeDto';
import KorunkaChargeDto from '@/Model/Entity/KorunkaChargeDto';
import {DocumentContentFormats} from '@/constants/documentContentFormats';
import {DocumentContentTypes} from '@/constants/documentContentTypes';
import {guid} from '@/Helpers/guid';
import KorunkaErrorDto from '@/Model/Entity/KorunkaErrorDto';
import KorunkaChargeRequestDto from '@/Model/Entity/KorunkaChargeRequestDto';

export default class ChargeResponseDto extends GeneratedChargeResponseDto {
  get entity() {
    switch (<CheckAndChargeChargeResponseObjectTypes> this.responseObjectType) {
    case CheckAndChargeChargeResponseObjectTypes.PackSellDto:
      return new PackSellDto(this.responseObject);
    case CheckAndChargeChargeResponseObjectTypes.TicketSellDto:
      return new TicketSellDto(this.responseObject);

    case CheckAndChargeChargeResponseObjectTypes.EasyCoinOrderDto:
      return new EasyCoinOrderDto(this.responseObject);

    case CheckAndChargeChargeResponseObjectTypes.GIPChargeDto:
      return new GIPChargeDto(this.responseObject);

    case CheckAndChargeChargeResponseObjectTypes.VoucherChargeDto:
      return new VoucherChargeDto(this.responseObject);

    case CheckAndChargeChargeResponseObjectTypes.KorunkaChargeDto:
    case CheckAndChargeChargeResponseObjectTypes.KorunkaChargeDtoV2:
      return new KorunkaChargeDto(this.responseObject);

    case CheckAndChargeChargeResponseObjectTypes.KorunkaErrorDto:
      return new KorunkaErrorDto(this.responseObject);
    }
  }

  get isSuccessful() {
    return this.success;
  }

  get errorReason() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCashierMessage ??
        this.errorMessage?.errorCashierMessage ??
        null;
    }

    if (this.entity instanceof KorunkaErrorDto) {
      return this.entity.errorReason ?? null;
    }

    return null;
  }

  get errorReasonForCustomer() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCustomerMessage ??
        this.errorMessage?.errorCustomerMessage ??
        null;
    }

    if (this.entity instanceof KorunkaErrorDto) {
      return this.entity.errorReasonForCustomer ?? null;
    }

    return null;
  }

  process(document: DocumentDto, itemAtIndex: number, displayItem: DocumentItemDto) {
    // https://docs.google.com/document/d/1K9uLJPOkbW9FdBIpg65kOMyTK9fuq3pyrKD6v2KTgps/edit#heading=h.genoz1i71kgc
    // currently unsolved
    // if (!this.isSuccessful) {
    //   displayItem.setItemStornoDisabledWithinContext(
    //     document,
    //     itemAtIndex,
    //     false,
    //   );
    //
    //   displayItem.setDocumentStornoDisabledWithinContext(
    //     document,
    //     itemAtIndex,
    //     false,
    //   );
    // }
    const documentItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.isSuccessful && this.entity instanceof GIPChargeDto) {
      document.addContent(map(toRaw(this.entity.receipt), (receiptContent) => (new DocumentContentDto({
        contentBase64: b64EncodeUnicode(receiptContent),
        source: ContentBase64Sources.CheckAndCharge,
        ownerId: documentItem.uniqueIdentifier,
        id: guid(),
      }))));
    }

    if (this.isSuccessful && this.entity instanceof EasyCoinOrderDto) {
      documentItem.setPriceNormalWithinContext(
        document,
        itemAtIndex,
        this?.responseObject?.amount ?? 0,
      );
    }

    /**
     * we redistribute charge response to other korunka articles
     */
    if (this.isSuccessful && this.entity instanceof KorunkaChargeDto) {
      document.addContent(
        map(
          toRaw(this.entity.korunkaResponse.printerDataWithOperationId),
          ({data, operationId}) => {
            return new DocumentContentDto({
              contentBase64: data,
              source: ContentBase64Sources.CheckAndCharge,
              format: DocumentContentFormats.Base64Raw,
              ownerId: operationId,
              type: DocumentContentTypes.StandalonePrintout,
              isSensitive: true,
              id: guid(),
            });
          },
        ),
      );

      this.entity.korunkaResponse.sanitize();

      for (const group of document.itemsGroupedBySets) {
        if (!group.mainItem.isServiceTypeKorunka) {
          continue;
        }

        const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
          .deserializeKorunkaEntity(group.editableItem.checkRequest.metadata);

        if (KorunkaChargeDto.isGameEntity(korunkaEntity) && korunkaEntity.isCancel) {
          group.editableItem.setItemStornoDisabledWithinContext(
            document,
            group.index,
            true,
          );
        }

        if (itemAtIndex === group.index) {
          continue;
        }

        group.editableItem.setChargeRequestWithinContext(
          document,
          group.index,
          new KorunkaChargeRequestDto(displayItem.chargeRequest),
        );

        group.editableItem.setChargeResponseWithinContext(
          document,
          group.index,
          this,
          {process: false},
        );
      }
    }

    if (!this.isSuccessful && this.entity instanceof KorunkaErrorDto) {
      for (const group of document.itemsGroupedBySets) {
        if (!group.mainItem.isServiceTypeKorunka) {
          continue;
        }

        group.editableItem.setChargeRequestWithinContext(
          document,
          group.index,
          new KorunkaChargeRequestDto(displayItem.chargeRequest),
        );

        group.editableItem.setChargeResponseWithinContext(
          document,
          group.index,
          this,
          {process: false},
        );
      }
    }
  }

  hasErrorFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(editableItem.checkRequest.metadata);
      const entityOperationIds: string[] = editableItem?.chargeRequest?.korunkaRequest?.bondsToEntities?.[korunkaEntity.id] ?? [];
      const errorOperationIds = map(this.entity.errors ?? [], ({operationId}) => operationId);
      const hasError = entityOperationIds.some((operationId) => errorOperationIds.includes(operationId));

      return hasError;
    }

    return false;
  }

  getErrorMessageDetailFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      return `${this.errorReason}`;
    }

    return !this.errorReason ?
      `${editableItem.description}` :
      `${editableItem.description}, ${this.errorReason}`;
  }
}
