import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderPreviewDto from '../OrderPreviewDto';


export const OrderListDtoPropDef = {
  items: {
    type: new ArrayType(new EntityType(OrderPreviewDto)),
  },
};
export default class GeneratedOrderListDto extends createEntity(OrderListDtoPropDef) {}
