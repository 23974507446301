import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerAttributeDtoPropDef = {
  id: {
    type: new StringType(),
  },
  key: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
  promoCode: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerAttributeDto extends createEntity(CustomerAttributeDtoPropDef) {}
