import GeneratedOverpay from './generated/Overpay';
import {isNil} from 'lodash-es';

export default class Overpay extends GeneratedOverpay {
  get minIsSet() {
    return !isNil(this.min);
  }

  get maxIsSet() {
    return !isNil(this.max);
  }
}
