import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FunctionButtons from '../FunctionButtons';
import {ArrayType} from '@designeo/apibundle-js';
import PaymentButton from '../PaymentButton';


export default class GeneratedButtons extends createEntity({
  adminButtons: {
    type: new ObjectType(),
  },
  functionButtons: {
    type: new EntityType(FunctionButtons),
  },
  paymentButtons: {
    type: new ArrayType(new EntityType(PaymentButton)),
  },
  remoteAppButtons: {
    type: new ObjectType(),
  },
}) {}
