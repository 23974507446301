import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-basic" }
const _hoisted_2 = {
  key: 0,
  class: "w-16 py-2 pr-3 pl-4"
}
const _hoisted_3 = { class: "py-2 px-3" }
const _hoisted_4 = { class: "flex flex-row items-center flex-wrap gap-x-2" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "space-x-2" }
const _hoisted_7 = { class: "py-2 px-3 text-right" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "py-2 px-3 text-right" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = { class: "text-sm whitespace-nowrap" }
const _hoisted_14 = {
  key: 1,
  class: "py-2 pl-3 pr-4 text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconMagnifier = _resolveComponent("IconMagnifier")!
  const _component_ProductName = _resolveComponent("ProductName")!
  const _component_ValueAfterDiscounts = _resolveComponent("ValueAfterDiscounts")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (_ctx.detail)
      ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
          _withDirectives(_createElementVNode("button", {
            class: "btn-basic bg-basic-lightest w-12 h-10",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('detail')))
          }, [
            _createVNode(_component_IconMagnifier)
          ], 512), [
            [_directive_test, 'openProductDetail']
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ProductName, {
            value: _ctx.product.item.description,
            item: {documentItem: _ctx.product.item}
          }, null, 8, ["value", "item"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labelsComponents, (label, index) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(label), {
              key: index,
              item: _ctx.product.item
            }, null, 8, ["item"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.getArticleId(_ctx.product.item)), 1)
    ]),
    _createElementVNode("td", _hoisted_7, [
      (_ctx.stockDisplayed && _ctx.hasStock)
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_8, [
            _createTextVNode(_toDisplayString(_ctx.stockQuantity), 1)
          ], 512)), [
            [_directive_test, 'productStock']
          ])
        : (_ctx.stockDisplayed)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('register.productVariation.stock.none')), 1))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_10, [
      (_ctx.displayPriceAndSaleValid)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_ValueAfterDiscounts, {
                value: _ctx.product.item
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('general.from')) + " " + _toDisplayString(_ctx.dateFormat(_ctx.product.item.saleValidFrom)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.select)
      ? (_openBlock(), _createElementBlock("td", _hoisted_14, [
          _withDirectives(_createElementVNode("button", {
            class: "btn-primary font-bold",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('action.select')), 1)
          ], 512), [
            [
              _directive_test,
              'select',
              void 0,
              { productVariationAction: true }
            ]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}