import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center text-2xl" }
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = { class: "text-warning" }
const _hoisted_4 = { class: "font-medium py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCross = _resolveComponent("IconCross")!
  const _component_IconWarning = _resolveComponent("IconWarning")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.cashAmountValidatorStore.isActive.value,
    invalidateRoute: false,
    buttonClose: false,
    type: "sm"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "font-bold cursor-pointer px-3",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_IconCross, { class: "w-3 h-3 text-basic" })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_IconWarning, { class: "inline w-10 h-10" })
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.content), 1)
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn-outline-basic",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, _toDisplayString(_ctx.$t('action.close')), 1),
      (_ctx.hasWorkflowRedirect)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn-outline-basic",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.workflowRedirect && _ctx.workflowRedirect(...args)))
          }, _toDisplayString(_ctx.$t('cashAmountValidator.workflowRedirect')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}