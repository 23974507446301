import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentErrorDto from '../DocumentErrorDto';


export const CreateErrorDocumentDtoPropDef = {
  document: {
    type: new ObjectType(),
  },
  state: {
    type: new ObjectType(),
  },
  service: {
    type: new StringType(),
  },
  error: {
    type: new EntityType(DocumentErrorDto),
  },
};
export default class GeneratedCreateErrorDocumentDto extends createEntity(CreateErrorDocumentDtoPropDef) {}
