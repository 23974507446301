export function isActiveFeatureEshop() {
  const configurationStore = require('@/Modules/Core/store/ConfigurationStore').useConfigurationStore();

  return configurationStore.configuration.value?.features?.eshop?.enabled ?? false;
}

export function isActiveFeatureMessages() {
  const configurationStore = require('@/Modules/Core/store/ConfigurationStore').useConfigurationStore();

  /**
    * fallback is true because of GECO
    */
  return configurationStore.configuration.value?.features?.messages?.enabled ?? true;
}

export function isActiveFeatureInTransitStock() {
  const configurationStore = require('@/Modules/Core/store/ConfigurationStore').useConfigurationStore();

  /**
   * fallback is false because of GECO
   */
  return configurationStore.configuration.value?.features?.stock?.inTransit?.enabled ?? false;
}

export function isActiveFeaturePrintDisplayOnScreen(operation?) {
  const configurationStore = require('@/Modules/Core/store/ConfigurationStore').useConfigurationStore();

  const config = configurationStore.configuration.value?.features?.print?.displayOnScreen ?? {enabled: false};

  if (!config.enabled) {
    return false;
  }

  if (!operation) {
    return true;
  }

  /**
   * TODO: determine if operation is supported and has it's own config
   */

  return true;
}
