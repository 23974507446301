import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-grow flex-col" }
const _hoisted_3 = { class: "text-xl font-bold uppercase mb-6" }
const _hoisted_4 = { class: "flex flex-col justify-between pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(workflowStep.step.transactionQuestionLabel), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AsyncButton, {
              test: 'btcTransactionCheckYes',
              onClick: () => _ctx.abortWorkflow(),
              class: "btn-secondary py-2.5 px-7 mb-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(workflowStep.step.buttonYesLabel), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_AsyncButton, {
              test: 'btcTransactionCheckYesButLess',
              onClick: () => _ctx.requestNextStep(),
              class: "btn-secondary py-2.5 px-7 mb-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(workflowStep.step.buttonYesButLessLabel), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_AsyncButton, {
              test: 'btcTransactionCheckNo',
              onClick: () => _ctx.requestNextStep(),
              class: "btn-secondary py-2.5 px-7"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(workflowStep.step.buttonNoLabel), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}