import {
  createEntity,
  NumberType,
  StringType,
} from '@designeo/apibundle-js';

export default class PaymentTransitionEnvelope extends createEntity({
  currency: {
    type: new StringType(),
  },
  value: {
    type: new NumberType(),
  },
}) {}
