import KorunkaCheckRequestDtoCustom from '@/Model/Entity/custom/KorunkaCheckRequestDtoCustom';
import {
  KorunkaPatch,
} from '@/Model/Entity/patches/KorunkaMixin';

export default class KorunkaCheckRequestDto extends KorunkaPatch(KorunkaCheckRequestDtoCustom) {
  getSanitizedEntity() {
    const clone = this.clone();
    delete clone._data.metadata.entity.verificationCode;

    return clone;
  }
}
