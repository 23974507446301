import {
  ArrayType,
  createEntity,
  DateTimeType,
  ObjectType,
  StringType,
} from '@designeo/apibundle-js';


export default class KorunkaErrorDtoCustom extends createEntity({
  date: {
    type: new DateTimeType(),
  },
  errors: {
    type: new ArrayType(new ObjectType<{code: string, operationId: string, field: string, errorMessage: {title: string, message: string}}>()),
  },
  id: {
    type: new StringType(),
  },
  validationErrors: {
    type: new ArrayType(new ObjectType<{code: string, message: string}>()),
  },
}) { }
