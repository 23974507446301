import {DocumentInventoryGroupPropDef} from './generated/DocumentInventoryGroup';
import {
  createEntity,
  NumberType,
  StringType,
} from '@designeo/apibundle-js';

export default class DocumentInventoryGroup extends createEntity({
  ...DocumentInventoryGroupPropDef,
  originalAllowedLostValue: {
    type: new NumberType(),
  },
  // TODO: Migrate to BE?
  lastInventoryNumber: {
    type: new StringType(),
  },
  nextInventoryNumberOrder: {
    type: new NumberType(),
  },
}) {

}
