import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col gap-8 flex-grow" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "flex flex-grow justify-center items-center" }
const _hoisted_5 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationPrint.question')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AsyncButton, {
              test: 'print',
              onClick: _ctx.print,
              class: "py-2.5 px-7 btn-outline-basic"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.warehouseOperationPrint.print')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_WorkflowTransitions)
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}