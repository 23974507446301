import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';

export async function withAsyncModalInteractionResolve(fn, {closeImmediate = true} = {}) {
  const coreStore = useCoreStore();
  const helpStore = useHelpStore();

  if (closeImmediate) {
    helpStore.close();
    coreStore.closeAlertOrConfirm();
  }

  await Promise.all([
    coreStore.confirmContent.value?.promise ?? Promise.resolve(),
    helpStore.error.value?.promise ?? Promise.resolve(),
  ]);

  await fn();
}
