
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';
import IconMagnifier from '../../../Components/Icons/IconMagnifier.vue';
import ValueAfterDiscounts from './ValueAfterDiscounts.vue';
import {useFilters} from '@/Helpers/filters';
import {
  ArticleLabelDisplayType,
  ArticleLabelTaxStamp,
  ArticleLabelNetto,
  ArticleLabelSellout,
  ArticleLabelItemStornoDisabled,
  ArticleLabelItemReturnDisabled,
  ArticleLabelAction,
  ArticleLabelSalePackage,
  ArticleLabelBatch,
} from '@/Components/ArticleLabels';
import ProductName from '@/Components/ProductName.vue';
import {useArticleHelpers} from '@/Helpers/article';
import {includes, map} from 'lodash-es';
import {StockDto, DocumentItemDto} from '@/Model/Entity';

const labelsToComponents = {
  'DisplayType': ArticleLabelDisplayType,
  'TaxStamp': ArticleLabelTaxStamp,
  'Netto': ArticleLabelNetto,
  'Sellout': ArticleLabelSellout,
  'ItemStornoDisabled': ArticleLabelItemStornoDisabled,
  'ItemReturnDisabled': ArticleLabelItemReturnDisabled,
  'Action': ArticleLabelAction,
  'SalePackage': ArticleLabelSalePackage,
  'Batch': ArticleLabelBatch,
};

export default defineComponent({
  name: 'ProductVariation',
  components: {
    ArticleLabelSalePackage,
    ProductName,
    ValueAfterDiscounts,
    IconMagnifier,
    ArticleLabelDisplayType,
    ArticleLabelTaxStamp,
    ArticleLabelNetto,
    ArticleLabelSellout,
    ArticleLabelItemStornoDisabled,
    ArticleLabelItemReturnDisabled,
    ArticleLabelAction,
    ArticleLabelBatch,
  },
  props: {
    detail: {
      type: Boolean,
      default: true,
    },
    select: {
      type: Boolean,
      default: true,
    },
    product: {
      type: Object as PropType<{
        item: DocumentItemDto,
        stock?: StockDto,
      }>,
      required: true,
    },
    labels: {
      type: Array as PropType<Array<keyof typeof labelsToComponents>>,
      required: false,
      default: () => [
        'TaxStamp',
        'DisplayType',
        'Netto',
        'Sellout',
        'ItemStornoDisabled',
        'ItemReturnDisabled',
        'Action',
        'Batch',
      ],
    },
  },
  emits: ['click', 'detail'],
  setup(props) {
    const {getArticleId} = useArticleHelpers();
    const {numberFormat, dateFormat} = useFilters();

    const labelsComponents = computed(() => map(props.labels, (label) => labelsToComponents[label] ?? 'div'));

    const displayPriceAndSaleValid = computed(() => {
      if (!props.product.item.isCheckAndCharge) {
        return true;
      }

      if (!includes(props.labels, 'SalePackage')) { // NOTE: this is a cheat how to show price for fulltext search
        return !props.product.item.isNegative;
      }

      return !props.product.item.salePackage && !props.product.item.isNegative;
    });

    const stockQuantity = computed(() => {
      return props.product.item.getStockAndInTransitQuantityByStock(props.product.stock);
    });

    const hasStock = computed(() => {
      return !!props.product.stock;
    });

    const stockDisplayed = computed(() => {
      return Object.prototype.hasOwnProperty.call(props.product, 'stock');
    });

    return {
      numberFormat,
      dateFormat,
      labelsComponents,
      displayPriceAndSaleValid,
      getArticleId,
      stockQuantity,
      stockDisplayed,
      hasStock,
    };
  },
});
