import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinBalanceStock} from '../StepMixins/WorkflowStepMixinBalanceStock';

export class WorkflowStepBalanceStockSuccess extends workflowStepMixinBalanceStock(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.BalanceStockSuccess;
  }

  get type() {
    return WorkflowStepBalanceStockSuccess.type;
  }

  get component() {
    return markRaw(require('./StepBalanceStockSuccess.vue').default);
  }

  get amount() {
    return this.sellDocument.header.total;
  }

  get transitions() {
    return {};
  }
}
