import {
  isEmpty,
  map,
  reject,
} from 'lodash-es';
import {
  DocumentCustomerDto,
  DocumentItemDto,
  DocumentLogisticItemDto,
  DocumentPaymentDto,
} from '@/Model/Entity';
import ReceiptResultDto from '@/Model/Entity/ReceiptResultDto';
import SafebagResultDto from '@/Model/Entity/SafebagResultDto';
import VoucherResultDto from '@/Model/Entity/VoucherResultDto';
import InvoiceResultDto from '@/Model/Entity/InvoiceResultDto';

export const sanitizeApiSearch = (data) => {
  const result = map(
    data,
    ({
      documentItem,
      documentLogisticItem,
      documentCustomer,
      documentPayment,
      receipt,
      safebag,
      voucher,
      invoice,
      match,
    }) => {
      const documentItemData = documentItem?.toJson();
      const documentLogisticItemData = documentLogisticItem?.toJson();
      const documentCustomerData = documentCustomer?.toJson();
      const documentPaymentData = documentPayment?.toJson();
      const receiptData = receipt?.toJson();
      const safeBagData = safebag?.toJson();
      const voucherData = voucher?.toJson();
      const invoiceData = invoice?.toJson();
      const matchData = match;

      return {
        ...(!isEmpty(documentItemData) ? {
          documentItem: new DocumentItemDto({
            ...documentItemData,
          }),
        } : {
          documentItem: null,
        }),
        ...(!isEmpty(documentLogisticItemData) ? {
          documentLogisticItem: new DocumentLogisticItemDto({
            ...documentLogisticItemData,
          }),
        } : {
          documentLogisticItem: null,
        }),
        ...(!isEmpty(documentCustomerData) ? {
          documentCustomer: new DocumentCustomerDto({
            ...documentCustomerData,
          }),
        } : {
          documentCustomer: null,
        }),
        ...(!isEmpty(documentPaymentData) ? {
          documentPayment: new DocumentPaymentDto({
            ...documentPaymentData,
          }),
        } : {
          documentPayment: null,
        }),
        ...(!isEmpty(receiptData) ? {
          receipt: new ReceiptResultDto({
            ...receiptData,
          }),
        } : {
          receipt: null,
        }),
        ...(!isEmpty(safeBagData) ? {
          safebag: new SafebagResultDto({
            ...safeBagData,
          }),
        } : {
          safebag: null,
        }),
        ...(!isEmpty(voucherData) ? {
          voucher: new VoucherResultDto({
            ...voucherData,
          }),
        } : {
          voucher: null,
        }),
        ...(!isEmpty(invoiceData) ? {
          invoice: new InvoiceResultDto({
            ...invoiceData,
          }),
        } : {
          invoice: null,
        }),
        ...(!isEmpty(matchData) ? {
          match: ({
            ...matchData,
          }),
        } : {
          match: null,
        }),
      };
    },
  );

  return {
    result,
    documentItems: map(reject(result, {documentItem: null}), 'documentItem'),
    documentLogisticItems: map(reject(result, {documentLogisticItem: null}), 'documentLogisticItem'),
    documentCustomers: map(reject(result, {documentCustomer: null}), 'documentCustomer'),
    documentPayments: map(reject(result, {documentPayment: null}), 'documentPayment'),
    receipts: map(reject(result, {receipt: null}), 'receipt'),
    safeBags: map(reject(result, {safebag: null}), 'safebag'),
    vouchers: map(reject(result, {voucher: null}), 'voucher'),
    invoices: map(reject(result, {invoice: null}), 'invoice'),
    matches: map(reject(result, {match: null}), 'match'),
  };
};
