import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import ArticleType from '../ArticleType';
import SetPrintType from '../SetPrintType';
import {BooleanType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import ItemDiscountDto from '../ItemDiscountDto';
import KeyAndValueDto from '../KeyAndValueDto';
import {DateTimeType} from '@designeo/apibundle-js';
import SetPriceSourceEnum from '../SetPriceSourceEnum';
import VariantDto from '../VariantDto';
import {ObjectType} from '@designeo/apibundle-js';
import CheckResponseDto from '../CheckResponseDto';
import ChargeResponseDto from '../ChargeResponseDto';
import RevokeResponseDto from '../RevokeResponseDto';
import CancelResponseDto from '../CancelResponseDto';
import SetDisplayType from '../SetDisplayType';
import DocumentItemDto from '../DocumentItemDto';
import DocumentItemGiftDto from '../DocumentItemGiftDto';
import DocumentItemPointsDto from '../DocumentItemPointsDto';
import AssortmentInfoDto from '../AssortmentInfoDto';


export default class DocumentItemDtoCustom extends createEntity({
  assortmentInfo: {
    type: new EntityType(AssortmentInfoDto),
  },
  customXml: {
    type: new StringType(),
  },
  itemType: {
    type: new StringType(),
  },
  internalNumber: {
    type: new StringType(),
  },
  extID: {
    type: new StringType(),
  },
  description: {
    type: new StringType(),
  },
  descriptionShort: {
    type: new StringType(),
  },
  size: {
    type: new StringType(),
  },
  color: {
    type: new StringType(),
  },
  unit: {
    type: new StringType(),
  },
  sellerID: {
    type: new StringType(),
  },
  seller: {
    type: new StringType(),
  },
  itemBarCode: {
    type: new StringType(),
  },
  logisticCode: {
    type: new StringType(),
  },
  validationCode: {
    type: new StringType(),
  },
  referentialDocumentId: {
    type: new StringType(),
  },
  referenceItem: {
    type: new StringType(),
  },
  promoNumber: {
    type: new NumberType(),
  },
  promoText: {
    type: new StringType(),
  },
  discountedPrice: {
    type: new NumberType(),
  },
  setType: {
    type: new EntityType(ArticleType),
  },
  setNumber: {
    type: new NumberType(),
  },
  setPosition: {
    type: new NumberType(),
  },
  setPrintType: {
    type: new EntityType(SetPrintType),
  },
  setCorrection: {
    type: new NumberType(),
  },
  priceNormal: {
    type: new NumberType(),
  },
  originalPrice: {
    type: new NumberType(),
  },
  priceAfterItemDiscounts: {
    type: new NumberType(),
  },
  voucherPayment: {
    type: new BooleanType(),
  },
  priceAction: {
    type: new NumberType(),
  },
  priceRecyclingFee: {
    type: new NumberType(),
  },
  valueAfterDiscounts: {
    type: new NumberType(),
  },
  quantity: {
    type: new NumberType(),
  },
  valueBeforeDiscounts: {
    type: new NumberType(),
  },
  gtin: {
    type: new StringType(),
  },
  articleGroup: {
    type: new StringType(),
  },
  supplierColorText: {
    type: new StringType(),
  },
  vat: {
    type: new NumberType(),
  },
  vatid: {
    type: new NumberType(),
  },
  vatShort: {
    type: new StringType(),
  },
  vatValue: {
    type: new NumberType(),
  },
  itemHeader1: {
    type: new StringType(),
  },
  itemHeader2: {
    type: new StringType(),
  },
  itemHeader3: {
    type: new StringType(),
  },
  itemHeader4: {
    type: new StringType(),
  },
  itemHeader5: {
    type: new StringType(),
  },
  itemHeader6: {
    type: new StringType(),
  },
  itemFooter1: {
    type: new StringType(),
  },
  itemFooter2: {
    type: new StringType(),
  },
  itemFooter3: {
    type: new StringType(),
  },
  itemFooter4: {
    type: new StringType(),
  },
  itemFooter5: {
    type: new StringType(),
  },
  itemFooter6: {
    type: new StringType(),
  },
  definitionNumber: {
    type: new StringType(),
  },
  itemDetails: {
    type: new ArrayType(new StringType()),
  },
  voucherNo: {
    type: new StringType(),
  },
  serialNo: {
    type: new StringType(),
  },
  invoiceNo: {
    type: new StringType(),
  },
  netto: {
    type: new BooleanType(),
  },
  documentDiscount: {
    type: new NumberType(),
  },
  loyaltyForm: {
    type: new StringType(),
  },
  loyaltyValue: {
    type: new NumberType(),
  },
  itemRounding: {
    type: new NumberType(),
  },
  discounts: {
    type: new ArrayType(new EntityType(ItemDiscountDto)),
  },
  add: {
    type: new ArrayType(new EntityType(KeyAndValueDto)),
  },
  isCanceled: {
    type: new BooleanType(),
  },
  canceledByID: {
    type: new StringType(),
  },
  canceledAt: {
    type: new DateTimeType(),
  },
  maxQuantity: {
    type: new NumberType(),
  },
  isNegative: {
    type: new BooleanType(),
  },
  originalDocumentReference: {
    type: new StringType(),
  },
  originalDocumentFiscalReference: {
    type: new StringType(),
  },
  returnReasonCode: {
    type: new StringType(),
  },
  isBonRounding: {
    type: new BooleanType(),
  },
  position: {
    type: new NumberType(),
  },
  isReturn: {
    type: new BooleanType(),
  },
  isDynamicSet: {
    type: new BooleanType(),
  },
  itemReturnDisabled: {
    type: new BooleanType(),
  },
  originalItemID: {
    type: new StringType(),
  },
  itemSpecialRegulation: {
    type: new StringType(),
  },
  taxStamp: {
    type: new StringType(),
  },
  hasManualPrice: {
    type: new BooleanType(),
  },
  setPriceSource: {
    type: new EntityType(SetPriceSourceEnum),
  },
  priceSourceConditions: {
    type: new StringType(),
  },
  cardType: {
    type: new StringType(),
  },
  shortCode: {
    type: new StringType(),
  },
  weight: {
    type: new NumberType(),
  },
  saleValidFrom: {
    type: new DateTimeType(),
  },
  saleValidTill: {
    type: new DateTimeType(),
  },
  serialNumber: {
    type: new BooleanType(),
  },
  invoiceNumber: {
    type: new BooleanType(),
  },
  paymentID: {
    type: new NumberType(),
  },
  paymentSubID: {
    type: new NumberType(),
  },
  needsPhoneNumber: {
    type: new BooleanType(),
  },
  phoneNumber: {
    type: new StringType(),
  },
  minPrice: {
    type: new NumberType(),
  },
  maxPrice: {
    type: new NumberType(),
  },
  // setItems: {
  //   type: new ArrayType(new EntityType(DocumentItemDto)),
  // },
  sellerVATID: {
    type: new StringType(),
  },
  isEditableSet: {
    type: new BooleanType(),
  },
  lotteryCode: {
    type: new StringType(),
  },
  parentId: {
    type: new StringType(),
  },
  prep: {
    type: new NumberType(),
  },
  batch: {
    type: new StringType(),
  },
  batchLabel: {
    type: new StringType(),
  },
  emissionId: {
    type: new StringType(),
  },
  ageSaleRestriction: {
    type: new StringType(),
  },
  variant: {
    type: new EntityType(VariantDto),
  },
  documentStornoDisabled: {
    type: new BooleanType(),
  },
  salePackage: {
    type: new BooleanType(),
  },
  cancelRequest: {
    type: new ObjectType(),
  },
  cancelResponse: {
    type: new EntityType(CancelResponseDto),
  },
  checkRequest: {
    type: new ObjectType(),
  },
  checkResponse: {
    type: new EntityType(CheckResponseDto),
  },
  chargeRequest: {
    type: new ObjectType(),
  },
  chargeResponse: {
    type: new EntityType(ChargeResponseDto),
  },
  revokeRequest: {
    type: new ObjectType(),
  },
  revokeResponse: {
    type: new EntityType(RevokeResponseDto),
  },
  setDisplayType: {
    type: new EntityType(SetDisplayType),
  },
  itemStornoDisabled: {
    type: new BooleanType(),
  },
  provider: {
    type: new StringType(),
  },
  promoCode: {
    type: new StringType(),
  },
  serviceType: {
    type: new StringType(),
  },
  serviceParam: {
    type: new StringType(),
  },
  sellout: {
    type: new BooleanType(),
  },
  prodh: {
    type: new StringType(),
  },
  imageId: {
    type: new StringType(),
  },
  btcPaymentNumber: {
    type: new StringType(),
  },
  btcPin: {
    type: new StringType(),
  },
  inventoryGroup: {
    type: new StringType(),
  },
  itemAddedDate: {
    type: new DateTimeType(),
  },
  systemAttributes: {
    type: new ObjectType(),
  },
  customAttributes: {
    type: new ArrayType(new ObjectType()),
  },
  count: {
    type: new NumberType(),
  },
  uniqueIdentifier: {
    type: new StringType(),
  },
  originalUniqueIdentifier: {
    type: new StringType(),
  },
  disableExportToSap: {
    type: new BooleanType(),
  },
  points: {
    type: new ArrayType(new EntityType(DocumentItemPointsDto)),
  },
  gifts: {
    type: new ArrayType(new EntityType(DocumentItemGiftDto)),
  },
}) {
  get setItems() {
    // @ts-ignore
    return new ArrayType(new EntityType(DocumentItemDto)).parse(this._data.setItems);
  }
  set setItems(val) {
    // @ts-ignore
    this._data.setItems = new ArrayType(new EntityType(DocumentItemDto)).format(val);
  }
}
