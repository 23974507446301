/* eslint-disable max-len */
import {logger} from '@/Helpers/app';
import {AxiosResponse} from 'axios';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {
  get,
  has,
  isFunction,
  pick,
  set,
} from 'lodash-es';

const replaceNodes = (obj, nodes: Array<[string, any]>) => {
  for (const [path, replaceWith] of nodes) {
    if (!has(obj, path)) {
      continue;
    }

    set(obj, path, isFunction(replaceWith) ? replaceWith(get(obj, path)) : replaceWith);
  }

  return obj;
};

const canUseLogger = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return logger().isActive;
};

enum SanitizationNotes {
  tooLarge = '<Data too large>',
  redacted = '<Redacted>',
  void = '<Void>',
  failToParse = '<Fail to parse>',
}

const bypassAxiosUrl = (response: AxiosResponse) => {
  switch (response.config?.headers?.ActionName) {
  default: return false;
  }
};

export const loggerTags = new Map<string, string>();

const sanitizeAxiosResponseData = (response: AxiosResponse) => {
  response = JSON.parse(JSON.stringify(response));

  response = replaceNodes(response, [['config.headers.Authorization', SanitizationNotes.redacted]]);

  switch (response.config?.headers?.ActionName) {
  // TODO: modify specific actions in needed
  case 'api.document.create':
    return replaceNodes(response, [
      [
        'config.data',
        (doc) => {
          try {
            const parsedDoc = JSON.parse(doc);

            return parsedDoc?.header?.uniqueidentifier ?
              {header: {uniqueidentifier: parsedDoc?.header?.uniqueidentifier}} :
              SanitizationNotes.redacted;
          } catch (e) {
            return SanitizationNotes.failToParse;
          }
        },
      ],
      ['data', SanitizationNotes.redacted],
    ]);
  case 'api.journal.v2.add':
    return replaceNodes(response, [
      [
        'config.data', (record) => {
          try {
            return JSON.parse(record);
          } catch (e) {
            return SanitizationNotes.failToParse;
          }
        },
      ],
      ['data', SanitizationNotes.redacted],
    ]);
  default:
    return replaceNodes(response, [
      ['config.data', SanitizationNotes.redacted],
      ['data', SanitizationNotes.redacted],
    ]);
  }
};

export function recordAxiosLogEntry(response: AxiosResponse) {
  if (!canUseLogger()) return;

  if (bypassAxiosUrl(response)) return;

  try {
    const sanitizedResponse = sanitizeAxiosResponseData(response) as Partial<AxiosResponse>;

    const sentryContext = require('@/Helpers/sentry').sentryCurrentUnclosedContext();

    logger().record({
      level: 'info',
      message: `Axios: ${sanitizedResponse.config.url} ${sanitizedResponse.status}`,
      fields: {
        Type: 'Axios',
        Url: sanitizedResponse.config.url,
        SentryTransaction: sentryContext && sentryContext.transaction ? {
          name: sentryContext.transaction.name,
          traceId: sentryContext.transaction.traceId,
        } : null,
        Request: {
          params: sanitizedResponse.config?.params ?? {},
          headers: sanitizedResponse.config?.headers ?? {},
          data: sanitizedResponse.config?.data ?? {},
        },
        Response: {
          headers: sanitizedResponse.headers ?? {},
          data: sanitizedResponse.data ?? {},
        },
        ...Object.fromEntries(loggerTags.entries()),
      },
    });
  } catch (e) {
    console.error(e);
  }
}


const bypassSignalREvent = (event: string) => {
  switch (event) {
  default: return false;
  }
};

const sanitizeSignalRData = (event, data: any[]) => {
  data = JSON.parse(JSON.stringify(data));

  switch (event) {
  case PrinterWSEvents.PROCESSED_DOC_MESSAGE:
    return (() => {
      if (!data) {
        return SanitizationNotes.void;
      }

      if (!data?.[0]) {
        return SanitizationNotes.void;
      }

      const result = data[0]?.result ?? {};

      return {
        ...pick(result, ['operation', 'statusCode', 'errorText', 'date', 'processedAt']),
        uniqueIdentifier: data?.[1] ?? 'N/a',
      };
    })();
  default:
    return SanitizationNotes.redacted;
  }
};

export function recordSignalRLogEntry(event, direction, ...data) {
  if (!canUseLogger()) return;

  if (bypassSignalREvent(event)) return;

  try {
    const sanitizedData = sanitizeSignalRData(event, data);

    const sentryContext = require('@/Helpers/sentry').sentryCurrentUnclosedContext();

    logger().record({
      level: 'info',
      message: `SignalR: ${event} ${direction}`,
      fields: {
        Type: 'SignalR',
        Event: event,
        Direction: direction,
        SentryTransaction: sentryContext && sentryContext.transaction ? {
          name: sentryContext.transaction.name,
          traceId: sentryContext.transaction.traceId,
        } : null,
        Response: {
          data: sanitizedData,
        },
        ...Object.fromEntries(loggerTags.entries()),
      },
    });
  } catch (e) {
    console.error(e);
  }
}


export function recordCustomEventLogEntry(type: string, data: string) {
  if (!canUseLogger()) return;

  try {
    const sentryContext = require('@/Helpers/sentry').sentryCurrentUnclosedContext();

    logger().record({
      level: 'info',
      message: `Custom: ${type}`,
      fields: {
        Type: type,
        SentryTransaction: sentryContext && sentryContext.transaction ? {
          name: sentryContext.transaction.name,
          traceId: sentryContext.transaction.traceId,
        } : null,
        EventData: data,
        ...Object.fromEntries(loggerTags.entries()),
      },
    });
  } catch (e) {
    console.error(e);
  }
}
