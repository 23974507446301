import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-end py-2" }
const _hoisted_2 = { class: "text-2xl font-bold text-danger" }
const _hoisted_3 = { class: "py-3" }
const _hoisted_4 = { class: "text-right py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCross = _resolveComponent("IconCross")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.isOpen,
    onClose: _ctx.onClose
  }, {
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", {
          class: "font-bold cursor-pointer px-3",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, [
          _createVNode(_component_IconCross, { class: "w-4 h-4 text-basic" })
        ], 512), [
          [_directive_test, 'closeModal']
        ])
      ], 512), [
        [_directive_test, 'formInputErrorModal']
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn-outline-basic btn-md",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, _toDisplayString(_ctx.$t('confirm.buttonClose')), 1)
      ])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}