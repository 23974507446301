import GeneratedCancelResponseDto from './generated/CancelResponseDto';
import {
  CheckAndChargeCancelResponseObjectTypes,
} from '@/constants/checkAndChargeResponseObjectTypes';
import DocumentDto from '@/Model/Entity/DocumentDto';
import DocumentItemDto from '@/Model/Entity/DocumentItemDto';
import PackReturnDto from '@/Model/Entity/PackReturnDto';
import TicketReturnDto from '@/Model/Entity/TicketReturnDto';
import GIPCancelDto from '@/Model/Entity/GIPCancelDto';
import VoucherCancelDto from '@/Model/Entity/VoucherCancelDto';
import KorunkaCancelDto from '@/Model/Entity/KorunkaCancelDto';
import KorunkaErrorDto from '@/Model/Entity/KorunkaErrorDto';
import {map, uniqBy} from 'lodash-es';
import {deserializeKorunkaEntity} from '@/Helpers/korunka';

export default class CancelResponseDto extends GeneratedCancelResponseDto {
  get entity() {
    switch (<CheckAndChargeCancelResponseObjectTypes> this.responseObjectType) {
    case CheckAndChargeCancelResponseObjectTypes.PackReturnDto:
      return new PackReturnDto(this.responseObject);
    case CheckAndChargeCancelResponseObjectTypes.TicketReturnDto:
      return new TicketReturnDto(this.responseObject);

    case CheckAndChargeCancelResponseObjectTypes.GIPCancelDto:
      return new GIPCancelDto(this.responseObject);

    case CheckAndChargeCancelResponseObjectTypes.VoucherCancelDto:
      return new VoucherCancelDto(this.responseObject);

    case CheckAndChargeCancelResponseObjectTypes.KorunkaCancelDto:
    case CheckAndChargeCancelResponseObjectTypes.KorunkaCancelDtoV2:
      return new KorunkaCancelDto(this.responseObject);

    case CheckAndChargeCancelResponseObjectTypes.KorunkaErrorDto:
      return new KorunkaErrorDto(this.responseObject);
    }
  }

  get isSuccessful() {
    return this.success;
  }

  get errorReason() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCashierMessage ??
        this.errorMessage?.errorCashierMessage ??
        null;
    }

    if (this.entity instanceof KorunkaErrorDto) {
      return this.entity.errorReason ?? null;
    }

    return null;
  }

  get errorReasonForCustomer() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCustomerMessage ??
        this.errorMessage?.errorCustomerMessage ??
        null;
    }

    if (this.entity instanceof KorunkaErrorDto) {
      return this.entity.errorReasonForCustomer ?? null;
    }

    return null;
  }

  get canBeCanceled() {
    return !this.cannotBeCanceled;
  }

  process(document: DocumentDto, itemAtIndex: number, displayItem: DocumentItemDto) {
    const documentItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (!this.isSuccessful && this.cannotBeCanceled) {
      documentItem.setItemStornoDisabledWithinContext(
        document,
        itemAtIndex,
        true,
      );

      documentItem.setItemReturnDisabledWithinContext(
        document,
        itemAtIndex,
        true,
      );
    }

    if (this.entity instanceof KorunkaCancelDto) {
      const tickets = KorunkaCancelDto.resolveDocumentItemFinishedBetTickets(displayItem);

      for (const ticket of uniqBy(tickets, 'operationId')) {
        document.sanitizeContentOfOwner(ticket.operationId);
      }
    } else {
      document.sanitizeContentOfOwner(documentItem.uniqueIdentifier);
    }
  }

  hasErrorFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(editableItem.checkRequest.metadata);
      const entityOperationIds: string[] = editableItem?.chargeRequest?.korunkaRequest?.bondsToEntities?.[korunkaEntity.id] ?? [];
      const errorOperationIds = map(this.entity.errors ?? [], ({operationId}) => operationId);
      const hasError = entityOperationIds.some((operationId) => errorOperationIds.includes(operationId));

      return hasError;
    }

    return false;
  }

  getErrorMessageDetailFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      return `${this.errorReason}`;
    }

    return !this.errorReason ?
      `${editableItem.description}` :
      `${editableItem.description}, ${this.errorReason}`;
  }
}
