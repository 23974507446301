import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const LocalIPDtoPropDef = {
  ipAddress: {
    type: new StringType(),
  },
};
export default class GeneratedLocalIPDto extends createEntity(LocalIPDtoPropDef) {}
