import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-row gap-4 mt-4" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row mt-4"
}
const _hoisted_4 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_5 = { class: "uppercase text-secondary font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleList = _resolveComponent("ToggleList")!
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["grid gap-4", {
          'grid-cols-2 w-1/2': _ctx.depositOptions.length === 2,
          'grid-cols-3 w-full': _ctx.depositOptions.length === 3,
          'grid-cols-auto w-full': _ctx.depositOptions.length > 3,
        }])
      }, [
        _createVNode(_component_ToggleList, {
          modelValue: _ctx.innerModel,
          options: _ctx.depositOptions,
          "onUpdate:modelValue": _ctx.updateModel
        }, null, 8, ["modelValue", "options", "onUpdate:modelValue"])
      ], 2)
    ]),
    (_ctx.workflowStep.customDepositEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass([_ctx.customDeposit ? 'toggle-active' : 'toggle-default']),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCustomDeposit && _ctx.toggleCustomDeposit(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.customDepositToggleLabel), 1)
          ], 2), [
            [_directive_test, 'custom-deposit-toggle']
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.customDeposit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.workflowStep.messages.customDepositInputLabel), 1),
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.customDepositValue
          }, {
            default: _withCtx(({isActive, value, setActive, error}) => [
              _withDirectives(_createVNode(_component_FormInput, {
                layout: 'col',
                label: '',
                error: error,
                active: isActive,
                errorOutput: 'modal',
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputBuffer, {
                    value: value.value,
                    disableCaretNavigator: ""
                  }, null, 8, ["value"])
                ]),
                _: 2
              }, 1032, ["error", "active", "onClick"]), [
                [_directive_test, 'custom-deposit-input']
              ])
            ]),
            _: 1
          }, 8, ["field"])
        ]))
      : _createCommentVNode("", true)
  ]))
}