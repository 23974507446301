import {App} from 'vue';
import {
  camelCase,
  isEmpty,
  isNil,
  keys,
} from 'lodash-es';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
}

export type RegisterTestHelpersContext = {
}

export function registerTestHelpers(ctx: CoreRequiredContext) {
  ctx.app.directive('test', (el, binding) => {
    const {value, modifiers} = binding;

    let key = 'test';

    if (!isEmpty(modifiers)) {
      const [val] = keys(modifiers);

      key = camelCase(`test-${val}`);
    }

    if (!isNil(value)) {
      el.dataset[key] = value;
    }
  });

  return {};
}
