import {markRaw} from 'vue';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import {apiStockGetCashState} from '@/Model/Action';

export class WorkflowStepApiRequestCashState extends workflowStepMixinNominals(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiRequestCashState;
  }

  get type() {
    return WorkflowStepApiRequestCashState.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const cashState = (await apiStockGetCashState({})).toJson();

      this.dataSetter(WorkflowStepField.cashState, () => cashState);

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
