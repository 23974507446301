import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {useFilters} from '@/Helpers/filters';
import {useSignalR, SignalRErrors} from '@/Helpers/signalR';
import {apiOrdersNotificationCount, apiOrdersPrintDeliveryNote} from '@/Model/Action';
import {
  DocumentDto,
  OrderDto,
  OrderDtoOrderEnvelopeDto,
  OrderPreviewDto,
} from '@/Model/Entity';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {usePrintContentStore} from '@/Modules/Core/store/PrintContentStore';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {
  computed,
  onBeforeUnmount,
  ref,
  Ref,
  watch,
} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import {useEshopOrdersStore} from './store/EshopOrdersStore';
import {EshopOrdersOrderStatus, EshopOrdersWSEvents} from './types';

export async function printEshopOrder(order: OrderDtoOrderEnvelopeDto) {
  const {notificationsConnection} = useSignalR();
  const documentStatusStore = useDocumentStatusStore();
  const coreStore = useCoreStore();
  const printDocument = ref(null) as Ref<DocumentDto>;
  const printContentStore = usePrintContentStore();


  try {
    coreStore.setLoader(true);

    let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
      PrinterWSEvents.PROCESSED_DOC_MESSAGE,
      async (...args) => {
        const [
          {result, document} = {
            result: null,
            document: null,
          }, sellDocumentUniqueId,
        ] = args;

        if (sellDocumentUniqueId !== printDocument.value.header.uniqueidentifier) return false;

        const solvingResult = (await documentStatusStore.solve(result, document)).pop();

        Object.assign(result, solvingResult ?? result);

        return !!solvingResult;
      },
    )(async () => {
      printDocument.value = await apiOrdersPrintDeliveryNote({
        input: order,
      });
    });

    result = new PrinterResult(result ?? {});

    if (result.hasError) {
      throw new Error(result.errorMessage);
    }

    if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
      await printContentStore.open(result.printContent);
    }
  } catch (e) {
    if (e.message === SignalRErrors.timeout) {
      documentStatusStore.terminate();
    }

    throw e;
  } finally {
    coreStore.setLoader(false);
  }
}

export function useEshopOrdersNotifications({
  passive = true,
  onOrderNotification = (orderNotification) => {},
} = {}) {
  const router = useRouter();
  const {notificationsConnection} = useSignalR();
  const coreStore = useCoreStore();
  const authStore = useAuthStore();
  const eshopOrdersStore = useEshopOrdersStore();

  const isEnabled = computed(() => {
    if (!router.hasRoute('apps-eshop-orders')) {
      return false;
    }

    if (!authStore.isLoggedIn.value) {
      return false;
    }

    return true;
  });

  const orderNotification = computed(() => {
    return eshopOrdersStore.orderNotification.value;
  });

  const onNewNotification = (newOrderNotificationCount) => {
    if (!passive) {
      eshopOrdersStore.setOrderNotification(newOrderNotificationCount);
    }

    onOrderNotification(orderNotification.value);
  };

  const fetchOrderStatus = async () => {
    if (passive) {
      return;
    }

    try {
      eshopOrdersStore.setOrderNotification(await apiOrdersNotificationCount());
      onOrderNotification(orderNotification.value);
    } catch (e) {
      coreStore.throw(e);
    }
  };

  const cleanOrderNotification = () => {
    if (passive) {
      return;
    }

    eshopOrdersStore.setOrderNotification(null);
  };

  const registerListener = () => {
    notificationsConnection.addEventListener(
      EshopOrdersWSEvents.ORDER_NOTIFICATION_COUNT_CHANGED,
      onNewNotification,
    );
  };

  const unregisterListener = () => {
    notificationsConnection.removeEventListener(
      EshopOrdersWSEvents.ORDER_NOTIFICATION_COUNT_CHANGED,
      onNewNotification,
    );
  };


  watch(isEnabled, (isEnabled) => {
    if (isEnabled) {
      registerListener();
      fetchOrderStatus();
    } else {
      unregisterListener();
      cleanOrderNotification();
    }
  }, {
    immediate: true,
  });

  onBeforeUnmount(() => {
    unregisterListener();
  });

  return {
    orderNotification,
    isEnabled,
  };
}

export function useEshopOrders() {
  const i18n = useI18n();
  const configurationStore = useConfigurationStore();
  const {translatedString} = useFilters();

  const integrations = computed(() => {
    return configurationStore.configuration.value?.features?.eshop?.integrations ?? {};
  });

  const translateOrderStatus = (order: OrderDto | OrderPreviewDto) => {
    return translatedString(
      integrations.value
        ?.[order.eshop.code]
        ?.statusConfigurations?.[order.statusCode.value]
        ?.translations ?? Object.fromEntries(i18n.availableLocales.map((locale) => [locale, 'N/a'])),
    );
  };

  const orderStatusColor = (order: OrderDto | OrderPreviewDto): {color: string, backgroundColor: string} => {
    return {
      color: integrations.value
        ?.[order.eshop.code]
        ?.statusConfigurations?.[order.statusCode.value]
        ?.color ?? '#222222',
      backgroundColor: integrations.value
        ?.[order.eshop.code]
        ?.statusConfigurations?.[order.statusCode.value]
        ?.backgroundColor ?? '#afafaf',
    };
  };

  return {
    integrations,
    translateOrderStatus,
    orderStatusColor,
  };
}
