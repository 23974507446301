import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PaymentsReportDto from '../PaymentsReportDto';
import {ArrayType} from '@designeo/apibundle-js';
import TurnoverPerDayReportDto from '../TurnoverPerDayReportDto';
import TurnoverReportDto from '../TurnoverReportDto';
import StatisticsReportDto from '../StatisticsReportDto';
import LogisticDocumentsReportDto from '../LogisticDocumentsReportDto';
import SafeBagsReportDto from '../SafeBagsReportDto';
import SafeStateReportDto from '../SafeStateReportDto';
import StockReportDto from '../StockReportDto';
import InventoryDto from '../InventoryDto';
import DocumentListReportItemDto from '../DocumentListReportItemDto';
import DocumentAttachmentDto from '../DocumentAttachmentDto';
import {ObjectType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import Target from '../Target';
import PrintCheck from '../PrintCheck';
import OutputType from '../OutputType';
import {DateTimeType} from '@designeo/apibundle-js';
import FiscalCommands from '../FiscalCommands';
import DocumentHeaderDto from '../DocumentHeaderDto';
import DocumentLoyaltyDto from '../DocumentLoyaltyDto';
import DocumentItemDto from '../DocumentItemDto';
import DocumentLogisticItemDto from '../DocumentLogisticItemDto';
import DocumentInventoryGroup from '../DocumentInventoryGroup';
import DocumentFinItemDto from '../DocumentFinItemDto';
import DocumentDiscountDto from '../DocumentDiscountDto';
import DocumentPaymentDto from '../DocumentPaymentDto';
import DocumentFooterDto from '../DocumentFooterDto';
import DocumentCustomerDto from '../DocumentCustomerDto';
import OrderDeliveryNoteDto from '../OrderDeliveryNoteDto';
import DocumentContentDto from '../DocumentContentDto';
import VATDto from '../VATDto';
import CustomDataDto from '../CustomDataDto';
import CustomerAdditionalDataDto from '../CustomerAdditionalDataDto';
import DocumentValidation from '../DocumentValidation';


export const DocumentDtoPropDef = {
  initPrinterIP: {
    type: new StringType(),
  },
  initPrinterType: {
    type: new StringType(),
  },
  initCountry: {
    type: new StringType(),
  },
  terminalID: {
    type: new StringType(),
  },
  paymentsReport: {
    type: new EntityType(PaymentsReportDto),
  },
  turnoverPerDayReports: {
    type: new ArrayType(new EntityType(TurnoverPerDayReportDto)),
  },
  turnoverReport: {
    type: new EntityType(TurnoverReportDto),
  },
  statisticsReport: {
    type: new EntityType(StatisticsReportDto),
  },
  logisticDocumentsReport: {
    type: new EntityType(LogisticDocumentsReportDto),
  },
  safeBagsReport: {
    type: new EntityType(SafeBagsReportDto),
  },
  safeStateReport: {
    type: new EntityType(SafeStateReportDto),
  },
  stockReport: {
    type: new EntityType(StockReportDto),
  },
  inventory: {
    type: new EntityType(InventoryDto),
  },
  reportDocumentList: {
    type: new ArrayType(new EntityType(DocumentListReportItemDto)),
  },
  attachments: {
    type: new ArrayType(new EntityType(DocumentAttachmentDto)),
  },
  paymentResults: {
    type: new ObjectType(),
  },
  promotions: {
    type: new ObjectType(),
  },
  printoutAsHtml: {
    type: new BooleanType(),
  },
  printoutAsPdf: {
    type: new BooleanType(),
  },
  emergencyPrint: {
    type: new BooleanType(),
  },
  printoutType: {
    type: new EntityType(Target),
  },
  disablePrintout: {
    type: new BooleanType(),
  },
  printCheck: {
    type: new EntityType(PrintCheck),
  },
  printTemplateType: {
    type: new EntityType(OutputType),
  },
  templateCode: {
    type: new StringType(),
  },
  isCanceled: {
    type: new BooleanType(),
  },
  isSensitive: {
    type: new BooleanType(),
  },
  canceledByID: {
    type: new StringType(),
  },
  canceledAt: {
    type: new DateTimeType(),
  },
  documentType: {
    type: new EntityType(FiscalCommands),
  },
  documentSubType: {
    type: new EntityType(FiscalCommands),
  },
  authorizationCode: {
    type: new StringType(),
  },
  referenceNumber: {
    type: new StringType(),
  },
  header: {
    type: new EntityType(DocumentHeaderDto),
  },
  loyalty: {
    type: new EntityType(DocumentLoyaltyDto),
  },
  // items: {
  //   type: new ArrayType(new EntityType(DocumentItemDto)),
  // },
  logisticItems: {
    type: new ArrayType(new EntityType(DocumentLogisticItemDto)),
  },
  inventoryGroups: {
    type: new ArrayType(new EntityType(DocumentInventoryGroup)),
  },
  finItems: {
    type: new ArrayType(new EntityType(DocumentFinItemDto)),
  },
  discounts: {
    type: new ArrayType(new EntityType(DocumentDiscountDto)),
  },
  payments: {
    type: new ArrayType(new EntityType(DocumentPaymentDto)),
  },
  footer: {
    type: new EntityType(DocumentFooterDto),
  },
  customer: {
    type: new EntityType(DocumentCustomerDto),
  },
  orderDeliveryNote: {
    type: new EntityType(OrderDeliveryNoteDto),
  },
  setting: {
    type: new StringType(),
  },
  content: {
    type: new StringType(),
  },
  contentBase64: {
    type: new ArrayType(new StringType()),
  },
  contents: {
    type: new ArrayType(new EntityType(DocumentContentDto)),
  },
  disablePrintPaymentTerminalResult: {
    type: new BooleanType(),
  },
  vatValues: {
    type: new ArrayType(new EntityType(VATDto)),
  },
  canceledPayments: {
    type: new ArrayType(new EntityType(DocumentPaymentDto)),
  },
  dialogResult: {
    type: new StringType(),
  },
  originalOperation: {
    type: new StringType(),
  },
  customData: {
    type: new EntityType(CustomDataDto),
  },
  additionalData: {
    type: new EntityType(CustomerAdditionalDataDto),
  },
  documentValidations: {
    type: new ArrayType(new EntityType(DocumentValidation)),
  },
};
export default class GeneratedDocumentDto extends createEntity(DocumentDtoPropDef) {}
