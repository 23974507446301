import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const DocumentErrorDtoPropDef = {
  message: {
    type: new StringType(),
  },
  createAt: {
    type: new DateTimeType(),
  },
  description: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentErrorDto extends createEntity(DocumentErrorDtoPropDef) {}
