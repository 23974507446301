import {
  App,
} from 'vue';
import {isRestoreMode, requestRestoreData} from '@/Helpers/restoreMode';
import {persistBuildHash, posBuildHashHistory} from '@/Helpers/persist';
import {useRestoreModeStore} from '@/Modules/Core/store/RestoreModeStore';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
}

export type RegisterTestHelpersContext = {
}

export async function registerRestoreMode(ctx: CoreRequiredContext) {
  const restoreMode = isRestoreMode();
  const restoreModeStore = useRestoreModeStore();

  restoreModeStore.setState(restoreMode);

  if (restoreMode) {
    return {
      isRestoreMode: true,
      restoredErrorRecord: await requestRestoreData(),
      buildHash: posBuildHashHistory.get(),
    };
  }

  console.error = new Proxy(console.error, <ProxyHandler<typeof console['error']>>{
    apply: function(target, thisArg, argumentsList) {
      restoreModeStore.consoleCollector.value.addError(argumentsList);
      return target.call(thisArg, ...argumentsList);
    },
  });
  console.warn = new Proxy(console.warn, <ProxyHandler<typeof console['warn']>>{
    apply: function(target, thisArg, argumentsList) {
      restoreModeStore.consoleCollector.value.addWarning(argumentsList);
      return target.call(thisArg, ...argumentsList);
    },
  });

  return {
    isRestoreMode: false,
    restoredErrorRecord: null,
    buildHash: persistBuildHash(),
  };
}
