
import {defineComponent} from 'vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';

export default defineComponent({
  setup() {
    const workflowStore = useWorkflowStore();

    return {
      workflowStore,

    };
  },
});
