import {markRaw} from 'vue';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {apiV1PosCustomersUpdatePhone} from '@/Model/Action';
import CustomerPhoneDto from '../../../../Model/Entity/CustomerPhoneDto';

export class WorkflowStepApiUpdateCustomerPhone extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiUpdateCustomerPhone;
  }

  get type() {
    return WorkflowStepApiUpdateCustomerPhone.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get customerNumber() {
    return this.data[WorkflowStepField.customerNumber] ?? this.params.customerNumber;
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      await apiV1PosCustomersUpdatePhone({
        input: new CustomerPhoneDto(this.data),
        params: {
          customerNumber: this.customerNumber,
        },
      });

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.SET_REFERER_QUERY_ARGS, {
        detail: {
          customerNumber: this.customerNumber,
        },
      }));

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.USER_UPDATE_FAILED,
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
