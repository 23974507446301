

import {
  computed,
  defineComponent,
  watch,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import {ConfirmTypes} from '@/Modules/Core/types';
import Countdown from '@/Components/Countdown.vue';
import {useInactivityStore} from '@/Modules/Core/store/InactiveStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {ZLayer} from '@/constants/zLayer';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {CountdownTicks} from '@/constants/countdown';
import {emitTestEvent} from '../../../Helpers/testEvent';
import {TestEvent} from '../../../tests/e2e/helpers/testEvents';

export default defineComponent({
  name: 'ModalInactivity',
  components: {
    Countdown,
    ModalDetachedButtons,
    ModalDetachedBody,
    ModalDetachedClose,
    Modal,
  },
  emits: ['onSubmit', 'onClose'],
  setup(props, {emit}) {
    const inactivityStore = useInactivityStore();
    const coreStore = useCoreStore();
    const {isSecondaryDisplay} = useSecondaryDisplay();

    const zIndex = computed(() => {
      return ZLayer.critical;
    });

    const onContinue = () => {
      // Note: keep it empty, global detection do its job
    };

    const limit = computed(() => {
      return inactivityStore.limit.value;
    });

    const progress = computed(() => {
      return inactivityStore.now.value;
    });

    const open = computed(() => {
      if (isSecondaryDisplay) {
        return false;
      }

      if (coreStore.loaderActive.value) {
        return false;
      }

      return inactivityStore.showProgress.value;
    });

    watch(open, (value) => {
      emitTestEvent(`${TestEvent.INACTIVITY_MODAL_OPEN}:${value}`);
    });

    return {
      open,
      limit,
      progress,
      onContinue,
      ConfirmTypes,
      zIndex,
      CountdownTicks,
    };
  },
});
