import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {workflowStepMixinPayment} from '../StepMixins/WorkflowStepMixinPayment';
import {workflowStepMixinSaveFinDocument} from '../StepMixins/WorkflowStepMixinSaveFinDocument';
import {flow} from 'lodash-es';
import {recordCustomEventLogEntry} from '@/Helpers/logger';
import DocumentSave from '@/Helpers/document/save';
import DocumentDto from '@/Model/Entity/DocumentDto';
import DocumentFinItemDto from '@/Model/Entity/DocumentFinItemDto';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {useFilters} from '@/Helpers/filters';
import {SignalRErrors} from '@/Helpers/signalR';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {AppLoaderEvent} from '@/Modules/Core/types';
import DocumentPaymentDto from '@/Model/Entity/DocumentPaymentDto';

export class WorkflowStepPaymentCreateFinDocument extends flow(
  (ctor) => workflowStepMixinPayment(ctor),
  (ctor) => workflowStepMixinSaveFinDocument(ctor),
)(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.PaymentCreateFinDocument;
  }

  get type() {
    return WorkflowStepPaymentCreateFinDocument.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get transitions() {
    return {};
  }

  get payments() {
    const payments: DocumentPaymentDto['_data'][] = this.getFieldValue(WorkflowStepField.payments, []);
    return payments.map((paymentDto) => new DocumentPaymentDto(paymentDto));
  }

  get canSkip() {
    if (this.stepInit) {
      return true;
    }

    return false;
  }

  get hasInvalidState() {
    return !this.payments.length;
  }

  get transaction() {
    const paymentId = this.getFieldValue(WorkflowStepField.paymentMethod, null);

    const paymentCode = this.step.transactionCodes[paymentId];

    return this.configurationStore.financialTransactionsByCode.value?.[paymentCode];
  }

  async beforeEnter() {
    const {currencyFormat} = useFilters();
    const {configuration} = useConfigurationStore();
    const amount = this.paymentTransitionEnvelope.value;

    if (this.canSkip) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.hasInvalidState) {
      recordCustomEventLogEntry('PaymentCreateFinDocument invalid state', JSON.stringify({
        payments: this.payments.map((payment) => payment.toJson()),
      }));

      // this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
      //   detail: {
      //     type: WorkflowStepErrors.INVALID_ESHOP_ORDER_FIN_DOCUMENT_STATE,
      //   },
      // }));

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
      return;
    }


    let finDocumentResult: Awaited<ReturnType<WorkflowStepPaymentCreateFinDocument['saveFinDocument']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      finDocumentResult = await DocumentSave.run(async () => {
        const transaction = this.transaction;

        const maxFinDocumentAmount = configuration.value?.features?.transactionLimits?.maxFinDocumentAmount;
        if (maxFinDocumentAmount && amount > maxFinDocumentAmount) {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
            detail: {
              type: WorkflowStepErrors.TRANSACTION_LIMIT_EXCEEDED,
              args: {
                limit: currencyFormat(maxFinDocumentAmount),
                value: currencyFormat(amount),
              },
            },
          }));
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          return;
        }

        const document = DocumentDto.createFinancialDocument({
          header: {
            finDocumentCode: transaction.code,
            finDocumentName: transaction.name,
            finDocumentTransactionNumber: transaction.number,
            total: amount,
            currency: transaction.currency ?? this.paymentTransitionEnvelope.currency,
            source: transaction.transactionSource,
            destination: transaction.transactionDestination,
            sapTransactionCode: transaction.sapTransactionCode,
            note: this.getFieldValue(WorkflowStepField.note, undefined),
          },
          items: [],
        });

        for (let documentPayment of this.payments) {
          documentPayment = documentPayment.clone();

          if (!documentPayment.type.hasConfirmationMethodTerminal) {
            document.addPayment(documentPayment, {refreshTotalPrice: false});
            continue;
          }

          const payment = this.paymentById(documentPayment.verifyDocumentId);

          if (!payment.isResolved) {
            continue;
          }

          const {
            results,
            card,
            verifyDocumentId,
          } = await payment.process(); // Returns result object immediately

          document.processPaymentResults(results);

          if (verifyDocumentId) {
            documentPayment.verifyDocumentId = verifyDocumentId;
          }

          if (card) {
            documentPayment.cardNumber = card.cardNumber;
            documentPayment.cardType = card.cardType;
          }

          document.addPayment(documentPayment, {refreshTotalPrice: false});
        }

        if (amount) {
          document.finItems = [
            new DocumentFinItemDto({
              valueBeforeDiscounts: amount,
              quantity: 1,
            }),
          ];
        }

        return document;
      }, {
        reference: {
          get: () => {
            return this.getFieldValue(WorkflowStepField.paymentFinDocument, null);
          },
          set: async (documentId: string) => {
            this.dataSetter(WorkflowStepField.paymentFinDocument, () => documentId);
            await this.workflowStore.persist();
          },
        },
      });

      finDocumentResult.result = new PrinterResult(finDocumentResult.result ?? {});

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      if (!finDocumentResult?.result?.hasError) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));
      }
    } finally {
      await (async () => {
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));

        if (!finDocumentResult?.created) {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          return;
        }

        if (isActiveFeaturePrintDisplayOnScreen() && finDocumentResult?.result?.hasValidPrintContent) {
          await this.printContentStore.open(finDocumentResult.result.printContent);
        }

        this.stepInit = true;

        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      })();
    }
  }
}
