
import {
  defineComponent,
} from 'vue';
import FormInput from '@/Components/FormInput.vue';
import {
  HLInput,
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import PhIcon from '@/Components/PhIcon.vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepPaymentSelect',
  components: {
    WorkflowTransitions,
    FormInput,
    HLInput,
    HLStep,
    PhIcon,
  },
  setup() {
    const stepType = WorkflowStepTypes.PaymentSelect;

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
