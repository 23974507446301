import * as yup from 'yup';
import {BufferedInput, InputSource} from '../../Register/services/KeyboardBuffer';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {
  AppLoaderEvent,
  FourEyesActions,
  FourEyesErrors,
  FourEyesInputEvent,
  FourEyesOperations,
  FourEyesState,
} from '../types';
import {
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import * as aclKeys from '@/constants/aclKeys';
import {
  apiAccountPermissionsGetList,
  apiAccountVerifyCashier,
  apiLogin,
} from '@/Model/Action';
import {useAcl} from '@/Helpers/acl';
import {AxiosError, AxiosInstance} from 'axios';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {submitJournalEventFourEyesAuthorization} from '@/Helpers/journal';
import {validateOneTimeCode} from '@/Helpers/oneTimeCodes';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {isNil} from 'lodash-es';
import {VerifyCashierStatus} from '../../Auth/types';
import CashierVerifyResultDto from '@/Model/Entity/CashierVerifyResultDto';

export interface IFourEyesStore {
  state: FourEyesState
  auth: ReturnType<typeof createAuth>
  callback: Function
  operation: FourEyesOperations,
  resolveByActivePerson: boolean,
  subject: any,
  verifyStatus: CashierVerifyResultDto | AxiosError,
}

export type IFourEyesResult = Promise<{
  isConfirmed: boolean,
  accessToken: string
  operation: FourEyesOperations,
  oneTimeCodeAuth: boolean,
  wasImmediate?: boolean,
}>

export enum FourEyesStoreErrors {
  ONE_TIME_CODE_FAILED = 'oneTimeCodeFailed',
  LOGIN_FAILED = 'loginFailed',
  INVALID_USERNAME = 'invalidUsername',
}

export enum FourEyesStoreEvents {
  USERNAME_VALID = 'usernameValid',
}

export enum UsernameValidations {
  SIZE='size',
  API='api',
  STATUS='status'
}


const createAuth = () => ({
  username: '',
  password: '',
  accessToken: null,
  oneTimeCodeAuth: false,
});

const createInitState = (data?: Partial<IFourEyesStore>) => ({
  state: FourEyesState.CLOSED,
  auth: createAuth(),
  callback: null,
  operation: null,
  resolveByActivePerson: null,
  subject: null,
  verifyStatus: null,
  ...data,
});

export class FourEyesStore extends Store<IFourEyesStore> {
  private readonly fourEyesAxios: AxiosInstance
  constructor() {
    super(createInitState());

    this.fourEyesAxios = require('axios').create();

    this.fourEyesAxios.interceptors.request.use((config) => {
      config.headers['Authorization'] = `Bearer ${this.state.auth.accessToken}`;
      return config;
    });
  }

  operationToPermissions: {[key in FourEyesOperations]?: {
    action: keyof typeof aclKeys,
    request: keyof typeof aclKeys,
    process: keyof typeof aclKeys,
  }} = {
    [FourEyesOperations.PRODUCT_CANCEL]: {
      action: aclKeys.PERMISSION_CANCEL,
      request: aclKeys.PERMISSION_CANCEL_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_CANCEL_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.RECEIPT_CANCEL]: {
      action: aclKeys.PERMISSION_CANCEL,
      request: aclKeys.PERMISSION_CANCEL_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_CANCEL_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.PRODUCT_RETURN]: {
      action: aclKeys.PERMISSION_RETURN,
      request: aclKeys.PERMISSION_RETURN_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_RETURN_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.RECEIPT_STORNO_BEFORE_TIMEOUT]: {
      action: aclKeys.PERMISSION_STORNO_BEFORE_TIMEOUT,
      request: aclKeys.PERMISSION_STORNO_BEFORE_TIMEOUT_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_STORNO_BEFORE_TIMEOUT_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.RECEIPT_STORNO_AFTER_TIMEOUT]: {
      action: aclKeys.PERMISSION_STORNO_AFTER_TIMEOUT,
      request: aclKeys.PERMISSION_STORNO_AFTER_TIMEOUT_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_STORNO_AFTER_TIMEOUT_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.CANCEL_OF_RECEIPT_STORNO]: {
      action: aclKeys.PERMISSION_CANCEL_OF_STORNO,
      request: aclKeys.PERMISSION_CANCEL_OF_STORNO_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_CANCEL_OF_STORNO_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.QUICK_LOGIN_MANAGE]: {
      action: aclKeys.PERMISSION_QUICKLOGIN_MANAGE,
      request: aclKeys.PERMISSION_QUICKLOGIN_MANAGE_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_QUICKLOGIN_MANAGE_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.POS_BLOCKED_STATE_MANAGE]: {
      action: aclKeys.PERMISSION_POS_BLOCK_STATE_MANAGE,
      request: aclKeys.PERMISSION_POS_BLOCK_STATE_MANAGE_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_POS_BLOCK_STATE_MANAGE_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.POS_RE_REGISTRATION_MANAGE]: {
      action: aclKeys.PERMISSION_POS_RE_REGISTRATION_MANAGE,
      request: aclKeys.PERMISSION_POS_RE_REGISTRATION_MANAGE_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_POS_RE_REGISTRATION_MANAGE_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.RECOVERY]: {
      action: aclKeys.PERMISSION_RECOVERY,
      request: aclKeys.PERMISSION_RECOVERY_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_RECOVERY_PROCESS_FOUR_EYES,
    },
    [FourEyesOperations.DEV_TOOLS]: {
      action: aclKeys.PERMISSION_DEV_TOOLS,
      request: aclKeys.PERMISSION_DEV_TOOLS_REQUEST_FOUR_EYES,
      process: aclKeys.PERMISSION_DEV_TOOLS_PROCESS_FOUR_EYES,
    },
  }

  transitions: {[key in FourEyesState]?: {[key in FourEyesActions]?: any}} = {
    [FourEyesState.CLOSED]: {
      [FourEyesActions.ENTER]: action(async (event: FourEyesInputEvent) => {
        this.state.state = FourEyesState.ENTER_OPERATOR_PERSONAL_NUMBER;
      }),
    },
    [FourEyesState.ENTER_OPERATOR_PERSONAL_NUMBER]: {
      [FourEyesActions.ADD_NUMBER]: action(async (event: FourEyesInputEvent) => {
        const oneTimeCodeLength = this.configurationStore.value.configuration.value.general.oneTimeCodeLength;
        let max = this.configurationStore.value.configuration.value.features.login.usernameMaxLength;

        if (!isNil(oneTimeCodeLength)) {
          max = Math.max(max, oneTimeCodeLength);
        }

        if (this.state.auth.username.length >= max) {
          return;
        }

        this.state.auth.username += event.value;
        this.validateUsernameLength();
      }),
      [FourEyesActions.BACKSPACE]: action(async () => {
        this.state.auth.username = this.state.auth.username.slice(0, this.state.auth.username.length - 1);
        this.validateUsernameLength();
      }),
      [FourEyesActions.BACK]: action(async (event: FourEyesInputEvent) => {
        this.state.callback?.(false);
      }),
      [FourEyesActions.CANCEL]: action(async (event: FourEyesInputEvent) => {
        this.state.callback?.(false);
      }),
      [FourEyesActions.ENTER]: action(async (event: FourEyesInputEvent) => {
        if (!this.state.auth.username) return;

        const oneTimeCodeLength = this.configurationStore.value.configuration.value.general.oneTimeCodeLength;

        if (this.state.auth.username.length === oneTimeCodeLength) {
          const isValid = validateOneTimeCode({
            cashierPersonalNumber: this.authStore.value.activePerson.value.username,
            shopCode: this.configurationStore.value.configuration.value.general.pos.shop.code,
            posCode: this.configurationStore.value.configuration.value.general.pos.code,
          }, this.state.auth.username);

          if (isValid) {
            this.state.auth.oneTimeCodeAuth = true;
            this.state.callback?.(true);
          } else {
            this.dispatchEvent(new CustomEvent(FourEyesStoreErrors.ONE_TIME_CODE_FAILED));
          }
        } else if (this.validateUsernameLength() && await this.verifyCashier()) {
          this.state.state = FourEyesState.ENTER_OPERATOR_PERSONAL_PIN;
        }
      }),
    },
    [FourEyesState.ENTER_OPERATOR_PERSONAL_PIN]: {
      [FourEyesActions.ADD_NUMBER]: action(async (event: FourEyesInputEvent) => {
        this.state.auth.password += event.value;
      }),
      [FourEyesActions.BACKSPACE]: action(async () => {
        this.state.auth.password = this.state.auth.password.slice(0, this.state.auth.password.length - 1);
      }),
      [FourEyesActions.BACK]: action(async (event: FourEyesInputEvent) => {
        this.state.state = FourEyesState.ENTER_OPERATOR_PERSONAL_NUMBER;
        this.state.verifyStatus = null;
      }),
      [FourEyesActions.CANCEL]: action(async (event: FourEyesInputEvent) => {
        this.state.state = FourEyesState.ENTER_OPERATOR_PERSONAL_NUMBER;
        this.state.verifyStatus = null;
      }),
      [FourEyesActions.ENTER]: action(async (event: FourEyesInputEvent) => {
        if (!this.state.auth.password) return;

        if (
          this.state.resolveByActivePerson &&
          this.state.auth.username === this.authStore.value.activePerson.value?.username
        ) {
          this.state.callback?.(new Error(FourEyesErrors.SAME_OPERATOR));
          return;
        }

        this.dispatchEvent(new Event(AppLoaderEvent.ON));

        try {
          const response = await apiLogin({
            input: {
              UserName: this.state.auth.username,
              Password: this.state.auth.password,
            },
          });

          this.state.auth.accessToken = response.access_token;

          const {process} = this.operationToPermissions[this.state.operation];

          const canProcess = this.acl.value(process, {
            source: await apiAccountPermissionsGetList({
              axios: this.fourEyesAxios,
            }),
          });

          if (canProcess) {
            this.state.callback?.(true);
          } else {
            this.state.callback?.(new Error(FourEyesErrors.PROCESS));
          }
        } catch (e) {
          this.state.auth.password = '';
          this.dispatchEvent(new CustomEvent(
            FourEyesStoreErrors.LOGIN_FAILED,
            {detail: {type: UsernameValidations.API, error: e}},
          ));
          console.error(e);
        }

        this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }),
    },
  }

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    if (bufferedInput.source === InputSource.SCANNER) {
      await this.onEventInput({
        type: FourEyesActions.SCANNER,
        value: bufferedInput,
      });
      return; // or sanitize buffered input and fallthrough?
    }

    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: FourEyesActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: FourEyesActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: FourEyesActions.CANCEL,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: FourEyesActions.BACKSPACE,
        });
      }
    }
  });

  setUsernameState = action(() => {
    this.state.state = FourEyesState.ENTER_OPERATOR_PERSONAL_NUMBER;
  })

  setPasswordState = action(() => {
    this.state.state = FourEyesState.ENTER_OPERATOR_PERSONAL_PIN;
  })

  onEventInput = action(async (event: FourEyesInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  getOperationPermissions = action((operation: FourEyesOperations) => {
    const {action, request} = this.operationToPermissions[operation];

    return {
      action: this.acl.value(action),
      request: this.acl.value(request),
    };
  })

  openFourEyesConfirm = action(async (operation: FourEyesOperations, {
    subject = null,
    resolveByActivePerson = true,
  } = {}) => {
    if (resolveByActivePerson) {
      const permissions = this.getOperationPermissions(operation);

      if (!this.authStore.value.activePerson.value) {
        throw new Error(FourEyesErrors.UNAUTHORIZED);
      }


      if (permissions.action) {
        return Promise.resolve({
          isConfirmed: true,
          accessToken: this.authStore.value.activePerson.value.accessToken,
          wasImmediate: true,
          oneTimeCodeAuth: false,
          operation,
        });
      }

      if (!permissions.request) {
        throw new Error(FourEyesErrors.REQUEST);
      }
    }
    const promise: IFourEyesResult = (new Promise((onFulfilled, onReject) => {
      this.state.operation = operation;
      this.state.subject = subject;
      this.state.resolveByActivePerson = resolveByActivePerson;
      this.setUsernameState();
      this.state.callback = (result) => {
        const username = this.state.auth.username;

        try {
          if (result instanceof Error) {
            onReject(result);
          } else {
            onFulfilled({
              isConfirmed: result,
              oneTimeCodeAuth: this.state.auth.oneTimeCodeAuth,
              accessToken: this.state.auth.accessToken,
              operation,
            });
            submitJournalEventFourEyesAuthorization(username, operation);
          }
        } finally {
          Object.assign(this.state, createInitState());
        }
      };
    }));

    return await promise;
  })

  closeFourEyesConfirm = action(async () => {
    await this.onEventInput({
      type: FourEyesActions.CANCEL,
    });
  })

  verifyCashier = action(async () => {
    try {
      const result = (await apiAccountVerifyCashier({
        params: {cashierCode: this.state.auth.username},
      }));

      this.state.verifyStatus = result;

      if (result.status.value === VerifyCashierStatus.VALID) {
        return true;
      } else {
        this.dispatchEvent(new CustomEvent(
          FourEyesStoreErrors.INVALID_USERNAME,
          {detail: {type: UsernameValidations.STATUS, status: result.status.value}},
        ));
        return false;
      }
    } catch (e) {
      this.dispatchEvent(new CustomEvent(
        FourEyesStoreErrors.INVALID_USERNAME,
        {detail: {type: UsernameValidations.API, error: e}},
      ));

      return false;
    }
  })

  validateUsernameLength = action(() => {
    const oneTimeCodeLength = this.configurationStore.value.configuration.value.general.oneTimeCodeLength;
    const min = this.configurationStore.value.configuration.value.features.login.usernameMinLength;
    let max = this.configurationStore.value.configuration.value.features.login.usernameMaxLength;

    if (!isNil(oneTimeCodeLength)) {
      max = Math.max(max, oneTimeCodeLength);
    }

    try {
      yup.string()
        .test(
          'fourEyesUsernameLength',
          'validations.string.fourEyesUsernameLength',
          (val) => {
            return val.length >= min && val.length <= max;
          },
        )
        .validateSync(this.username.value);

      this.dispatchEvent(new CustomEvent(FourEyesStoreEvents.USERNAME_VALID));
      return true;
    } catch (e) {
      this.dispatchEvent(
        new CustomEvent(FourEyesStoreErrors.INVALID_USERNAME, {detail: {min, max, type: UsernameValidations.SIZE}}),
      );
      return false;
    }
  })

  resetState = action(() => {
    this.state = Object.assign(this.state, createInitState());
  })

  acl = getter(() => {
    return useAcl();
  })

  authStore = getter(() => {
    return useAuthStore();
  })

  configurationStore = getter(() => {
    return useConfigurationStore();
  })

  cashier = getter(() => {
    if (this.state.verifyStatus instanceof CashierVerifyResultDto) {
      const cashier = this.state.verifyStatus.cashier;
      return `${cashier.firstName} ${cashier.lastName}`;
    }

    if (this.state.verifyStatus) {
      return this.username.value;
    }

    return null;
  })

  password = getter(() => {
    return this.state.auth?.password;
  })

  username = getter(() => {
    return this.state.auth?.username;
  })

  subject = getter(() => {
    return this.state.subject;
  })

  resolveByActivePerson = getter(() => {
    return this.state.resolveByActivePerson;
  })

  operation = getter(() => {
    return this.state.operation;
  })

  isActive = getter(() => {
    return this.state.state !== FourEyesState.CLOSED;
  })

  isActiveStateEnterOperatorPersonalNumber = getter(() => {
    return this.state.state === FourEyesState.ENTER_OPERATOR_PERSONAL_NUMBER;
  })

  isActiveStateEnterOperatorPersonalPin = getter(() => {
    return this.state.state === FourEyesState.ENTER_OPERATOR_PERSONAL_PIN;
  })
}

const storeIdentifier = 'FourEyesStore';

export const configureFourEyesStore = createConfigureStore<typeof FourEyesStore>(storeIdentifier);
export const useFourEyesStore = createUseStore(FourEyesStore, storeIdentifier);
