
import {
  defineComponent,
} from 'vue';
import {
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowStepEvents,
} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import IconWarning from '@/Components/Icons/IconWarning.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useWorkflowStore} from '../../store/WorkflowStore';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'StepSafebagTransfer',
  components: {
    HLStep,
    IconWarning,
    IconCheck,
    WorkflowTransitions,
    AsyncButton,
  },
  setup() {
    const stepType = WorkflowStepTypes.SafebagTransfer;
    const workflowStore = useWorkflowStore();

    const {currencyFormat} = useFilters();

    const onCreateSafebag = () => {
      workflowStore.currentWorkflow.value.activeWorkflowStep.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {detail: {
          workflowCode: workflowStore.currentWorkflow.value.activeWorkflowStep.step.redirectWorkflowCode,
        }}),
      );
    };

    return {
      stepType,
      WorkflowStepField,
      onCreateSafebag,
      currencyFormat,
    };
  },
});
