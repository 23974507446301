import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "uppercase text-xs text-basic-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.customerInteractionStore.isActive.value,
    invalidateRoute: false,
    buttonClose: false
  }, {
    body: _withCtx(() => [
      (_ctx.customerInteractionStore.interaction.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ModalDetachedClose, { onClose: _ctx.reject }, null, 8, ["onClose"]),
            _createVNode(_component_ModalDetachedBody, {
              type: _ctx.ConfirmTypes.error
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.content), 1)
              ]),
              _: 1
            }, 8, ["type"]),
            _createVNode(_component_ModalDetachedButtons, { class: "justify-between" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('customerInteraction.title')), 1)
                ]),
                _createElementVNode("button", {
                  class: "btn-outline-basic btn-md",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reject && _ctx.reject(...args)))
                }, _toDisplayString(_ctx.$t('confirm.buttonCancel')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open"]))
}