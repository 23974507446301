import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow bg-basic-lightest m-4 px-4" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "font-bold text-3xl pt-2" }
const _hoisted_4 = { class: "grid grid-cols-2 my-1" }
const _hoisted_5 = { class: "flex flex-col pr-2 border-r border-basic" }
const _hoisted_6 = { class: "flex flex-row justify-between items-center" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "flex flex-col gap-1 pt-2" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "pl-2" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = {
  key: 2,
  class: "mt-2"
}
const _hoisted_16 = { class: "font-bold" }
const _hoisted_17 = {
  key: 3,
  class: "mt-2"
}
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = {
  key: 4,
  class: "mt-2"
}
const _hoisted_20 = { class: "font-bold" }
const _hoisted_21 = {
  key: 5,
  class: "mt-2 font-bold"
}
const _hoisted_22 = {
  key: 0,
  class: "flex flex-row justify-between p-8 pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.messages.gameTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn shadow-md",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPreviousTicket && _ctx.onPreviousTicket(...args)))
            }, [
              _createVNode(_component_PhIcon, { icon: "PhCaretLeft" })
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.interpolate(_ctx.messages.ticketNumber, {number: _ctx.entity.activeTicketIndex + 1})), 1),
            _createElementVNode("button", {
              class: "btn shadow-md",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onNextTicket && _ctx.onNextTicket(...args)))
            }, [
              _createVNode(_component_PhIcon, { icon: "PhCaretRight" })
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTicketEntity?.randomNumbers, (randomNumbersRow, rowIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: rowIndex,
                class: "grid grid-cols-7 gap-2"
              }, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(rowIndex + 1) + ". ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(randomNumbersRow, (number, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "korunka-circle-number korunka-circle-number--xs"
                  }, _toDisplayString(number), 1))
                }), 128))
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.drawsDates)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.messages.drawsDates) + ": ", 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.drawsDates), 1)
              ], 512)), [
                [
                  _directive_test,
                  _ctx.drawsDates,
                  void 0,
                  { customerDrawsDates: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.entity?.drawsCount)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createTextVNode(_toDisplayString(_ctx.messages.drawsCount) + ": ", 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.entity?.drawsCount ?? 0), 1)
              ], 512)), [
                [
                  _directive_test,
                  _ctx.entity.drawsCount,
                  void 0,
                  { customerDrawsCount: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.entity?.ticketCount)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createTextVNode(_toDisplayString(_ctx.messages.ticketCount) + ": ", 1),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.entity?.ticketCount ?? 0), 1)
              ], 512)), [
                [
                  _directive_test,
                  _ctx.entity.ticketCount,
                  void 0,
                  { customerTicketCount: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.entity?.maxPrizePerTicket)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createTextVNode(_toDisplayString(_ctx.messages.maxPrizePerTicket) + ": ", 1),
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.currencyFormat(_ctx.entity?.maxPrizePerTicket ?? 0)), 1)
              ], 512)), [
                [
                  _directive_test,
                  _ctx.entity.maxPrizePerTicket,
                  void 0,
                  { customerMaxPrizePerTicket: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.activeTicketEntity?.deposit?.value)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createTextVNode(_toDisplayString(_ctx.messages.deposit) + ": ", 1),
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.currencyFormat(_ctx.activeTicketEntity?.deposit?.value ?? 0 )), 1)
              ], 512)), [
                [
                  _directive_test,
                  _ctx.activeTicketEntity.deposit.value,
                  void 0,
                  { customerDeposit: true }
                ]
              ])
            : _createCommentVNode("", true),
          (_ctx.entity?.total)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createTextVNode(_toDisplayString(_ctx.messages.total) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.total ?? 0)), 1)
              ], 512)), [
                [
                  _directive_test,
                  _ctx.entity.total,
                  void 0,
                  { customerTotal: true }
                ]
              ])
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.isStepOverview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _withDirectives(_createElementVNode("button", {
            class: "btn btn-success text-2xl",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onAccept && _ctx.onAccept(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.messages.confirmAction), 1)
          ], 512), [
            [_directive_test, 'accept']
          ]),
          (_ctx.canBeDeclined)
            ? _withDirectives((_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-danger text-2xl",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onDecline && _ctx.onDecline(...args)))
              }, [
                _createTextVNode(_toDisplayString(_ctx.messages.declineAction), 1)
              ], 512)), [
                [_directive_test, 'decline']
              ])
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}