import {
  createEntity,
  ArrayType,
  EntityType,
  StringType,
  NumberType,
  ObjectType,
} from '@designeo/apibundle-js';
import {
  deserializeKorunkaEntity,
  KorunkaGameEntities,
  serializeKorunkaEntity,
} from '@/Helpers/korunka';
import KorunkaLotteryScanTicketDtoCustom from './KorunkaLotteryScanTicketDtoCustom';
import {
  every,
  orderBy,
  sumBy,
} from 'lodash-es';
import {guid} from '@/Helpers/guid';

export default class KorunkaLotteryScanResultDtoCustom extends createEntity({
  tickets: {
    type: new ArrayType(new EntityType(KorunkaLotteryScanTicketDtoCustom)),
  },
  ticketGames: {
    type: new ArrayType(new ObjectType()),
  },
  activeTicketIndex: {
    type: new NumberType(),
  },
  id: {
    type: new StringType(),
  },
}) {
  get gameType() {
    return this.tickets[0].gameType.value;
  }

  get ticketGameEntities() {
    return this.ticketGames.map((ticketGame) => deserializeKorunkaEntity(ticketGame)) as KorunkaGameEntities[];
  }

  get activeTicketGameEntity() {
    return deserializeKorunkaEntity(this.ticketGames[this.activeTicketIndex]) as KorunkaGameEntities;
  }

  get firstAndLastDraw() {
    const tickets = this.ticketGameEntities;
    const drawDeadlines = tickets.map((ticket) => ticket.draws)
      .flat();

    const sortedDraws = orderBy(drawDeadlines, (deadline) => deadline.deadlineForBets.toISOString(), 'asc');

    const {0: firstDraw, [sortedDraws.length - 1]: lastDraw} = sortedDraws;

    return [firstDraw, lastDraw].filter(Boolean);
  }

  get isFromScan() {
    return true;
  }

  get ticketCount() {
    return this.tickets.length;
  }

  get drawsCount() {
    return this.activeTicketGameEntity.drawsCount;
  }

  get maxPrizePerTicket() {
    return orderBy(this.ticketGameEntities, (ticket) => ticket.maxPrizePerTicket, 'desc')?.[0]?.maxPrizePerTicket ?? 0;
  }

  get total() {
    return sumBy(this.ticketGameEntities, (ticket) => ticket.total);
  }

  previousTicket() {
    this.activeTicketIndex = Math.max(0, this.activeTicketIndex - 1);
  }

  nextTicket() {
    this.activeTicketIndex = Math.min(this.tickets.length - 1, this.activeTicketIndex + 1);
  }

  async calculateMaxPossibleWin() {
    return await Promise.all(this.ticketGameEntities.map((ticket) => ticket.calculateMaxPossibleWin()));
  }

  async validate() {
    return every(this.tickets, (ticket: KorunkaLotteryScanTicketDtoCustom) => ticket.isSupportedGame());
  }

  async setup() {
    this.activeTicketIndex = 0;

    this.tickets.forEach((ticket) => ticket.id = guid());

    const ticketGameEntities = await Promise.all(this.tickets.map((ticket) => ticket.toGameEntity()));
    this.ticketGames = ticketGameEntities.map((entity) => serializeKorunkaEntity(entity));
  }
}
