
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useRouter} from 'vue-router';
import {useFilters} from '@/Helpers/filters';
import Countdown from '@/Components/Countdown.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  name: 'ModalUpdateState',
  components: {
    ModalDetachedClose,
    Countdown,
    AsyncButton,
    ModalDetachedButtons,
    Modal,
    PhIcon,
  },
  setup() {
    const updateStore = useUpdateStore();
    const router = useRouter();
    const {dateTimeFormat} = useFilters();
    const {isSecondaryDisplay} = useSecondaryDisplay();

    const startUpdateIn = computed(() => {
      return updateStore.startUpdateIn.value;
    });

    const updateTriggered = computed(() => {
      return updateStore.updateTriggered.value;
    });

    const countdown = computed(() => {
      return updateStore.timeRemaining.value;
    });

    const appVersion = computed(() => {
      return updateStore.appVersion.value;
    });

    const updateStatus = computed(() => {
      return updateStore.updateStatus.value;
    });

    const isUpdateTypeOnDemand = computed(() => {
      return updateStore.isUpdateTypeOnDemand.value;
    });

    const isUpdateTypeImmediate = computed(() => {
      return updateStore.isUpdateTypeImmediate.value;
    });

    const allUpdateConditionsAreMet = computed(() => {
      return updateStore.allUpdateConditionsAreMet.value;
    });

    const updateValidFrom = computed(() => {
      return updateStore.updateValidFrom.value;
    });

    const isCurrentRouteRegisterPage = computed(() => {
      return router.currentRoute.value.name === 'register';
    });

    const isCurrentRouteWorkflowPage = computed(() => {
      return router.currentRoute.value.name === 'workflow';
    });

    const isSuitableRoute = computed(() => {
      return !isCurrentRouteRegisterPage.value && !isCurrentRouteWorkflowPage.value;
    });

    const open = computed(() => {
      if (isSecondaryDisplay) {
        return false;
      }

      if (updateTriggered.value) {
        return true;
      }

      if (updateStore.forcedWarning.value) {
        return true;
      }

      if (!allUpdateConditionsAreMet.value) {
        return false;
      }

      return updateStore.hasActiveWarning.value && isSuitableRoute.value;
    });

    const hasVisibleActions = computed(() => {
      if (!isSuitableRoute.value) {
        return false;
      }

      if (!allUpdateConditionsAreMet.value) {
        return false;
      }

      if (!isUpdateTypeOnDemand.value) {
        return false;
      }

      if (updateStore.postponedUpdateValidFrom.value) {
        const postponedUpdateValidFromAsMs = +updateStore.postponedUpdateValidFrom.value;
        const apiUpdateValidFromAsMs = +updateStore.apiUpdateValidFrom.value;

        return (postponedUpdateValidFromAsMs - apiUpdateValidFromAsMs) > updateStore.timeRemaining.value;
      }

      return !updateStore.timeRemaining.value;
    });

    const canBeClosed = computed(() => {
      if (!isSuitableRoute.value) {
        return true;
      }

      if (!updateStore.allUpdateConditionsAreMet.value) {
        return true;
      }

      if (updateStore.isScheduled.value) {
        return false;
      }

      if (updateStore.updateTriggered.value) {
        return false;
      }

      return true;
    });

    const update = async () => {
      await updateStore.startUpdate();
    };

    const postpone = async () => {
      updateStore.closeDetail();
      await updateStore.postponeUpdate();
    };

    const close = async () => {
      updateStore.closeDetail();
    };

    return {
      dateTimeFormat,
      appVersion,
      updateStatus,
      open,
      close,
      countdown,
      update,
      postpone,
      isUpdateTypeOnDemand,
      isUpdateTypeImmediate,
      updateTriggered,
      startUpdateIn,
      canBeClosed,
      hasVisibleActions,
      updateValidFrom,
    };
  },
});
