import {
  ArrayType,
  createEntity,
  NumberType,
  ObjectType,
  StringType,

} from '@designeo/apibundle-js';

export default class PrinterResultCustom extends createEntity({
  statusCode: {
    type: new NumberType(),
  },
  warningCode: {
    type: new NumberType(),
  },
  description: {
    type: new StringType(),
  },
  errorText: {
    type: new StringType(),
  },
  printout: {
    type: new ObjectType(),
  },
  output: {
    type: new ArrayType(new ObjectType()),
  },
}) {}
