
import {onBeforeUnmount} from 'vue';
import {wait} from '@designeo/js-helpers/src/index';

export default {
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: 'default',
      enum: [
        'default',
        'primary',
        'fn',
        'active-red',
        'active-orange',
      ],
    },
  },
  emits: ['click'],
  setup(props, {emit}) {
    let timeout;
    let isPressed;

    const setRepeatTimeout = (event) => {
      clearTimeout(timeout);

      isPressed = true;

      timeout = setTimeout(async () => {
        while (isPressed) {
          await wait(50, null);
          emit('click', event);
        }
      }, 300);
    };

    onBeforeUnmount(() => {
      isPressed = false;
      clearTimeout(timeout);
    });


    const onClick = () => {}; // VOID

    const onMouseup = (event) => {
      isPressed = false;
      emit('click', event);
    };

    const onMousedown = (event) => {
      setRepeatTimeout(event);
    };

    return {
      onClick,
      onMouseup,
      onMousedown,
    };
  },
};
