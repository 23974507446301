import {
  ArrayType,
  createEntity,
  EntityType,
  ObjectType,
  StringType,
} from '@designeo/apibundle-js';
import {ActionPayOutTicket} from '../patches/KorunkaMixin';
import Actions from '../Actions';
import TicketPartnerResource from '../TicketPartnerResource';
import TicketPartnerResourceV2 from '../TicketPartnerResourceV2';
import {guid} from '@/Helpers/guid';
import KorunkaLotterySestkaDtoCustom from '@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom';
import KorunkaLotteryDvacetZa20DtoCustom from '@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom';
import KorunkaLotteryHopTropDtoCustom from '@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom';
import KorunkaLotteryKombiDtoCustom from './KorunkaLotteryKombiDtoCustom';
import KorunkaLotteryKombiPlusDtoCustom from './KorunkaLotteryKombiPlusDtoCustom';
import {
  KorunkaCantPayoutReasons,
  KorunkaEntityTypes,
  TicketStatus,
} from '@/Helpers/korunkaTypes';
import {EntityTypeKorunka} from '@/Model/Type/korunka';
import {KorunkaVersion} from '@/constants/korunka';
import {throwUnsupportedKorunkaVersionError} from '../../../Helpers/korunka';

export default class KorunkaLotteryTicketDtoCustom extends createEntity({
  operationId: {
    type: new StringType(),
  },
  ticket: {
    type: new EntityTypeKorunka({
      [KorunkaVersion.v1]: TicketPartnerResource,
      [KorunkaVersion.v2]: TicketPartnerResourceV2,
    }),
  },
  actions: {
    type: new EntityType(Actions),
  },
  verificationCode: {
    type: new StringType(),
  },
  id: {
    type: new StringType(),
  },
}) {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  isTicketV1(ticket: any): ticket is TicketPartnerResource {
    return ticket instanceof TicketPartnerResource;
  }

  isTicketV2(ticket: any): ticket is TicketPartnerResourceV2 {
    return ticket instanceof TicketPartnerResourceV2;
  }

  get isNotPaid() {
    return this.ticket.status.value === TicketStatus.NotPaid;
  }

  get isNotEvaluated() {
    return this.ticket.status.value === TicketStatus.NotEvaluated;
  }

  get isEvaluating() {
    return this.ticket.status.value === TicketStatus.Evaluating;
  }

  get isEvaluated() {
    return this.ticket.status.value === TicketStatus.Evaluated;
  }

  get isPartlyEvaluated() {
    return this.ticket.status.value === TicketStatus.PartlyEvaluated;
  }

  get isPartlyWon() {
    if (this.isTicketV2(this.ticket)) {
      return this.isPartlyEvaluated && this.ticket.betOnDraws.some((betOnDraw) => betOnDraw.status.value === TicketStatus.Won);
    }

    return false;
  }

  get isPreEvaluating() {
    return this.ticket.status.value === TicketStatus.PreEvaluating;
  }

  get isWon() {
    return this.ticket.status.value === TicketStatus.Won && this.actions.canPayOut === true;
  }

  // NOTE: Pseudo state, used for displaying payout amount exceeded error with won status instead of paidOut status
  get isWonWithPayoutAmountExceeded() {
    return this.ticket.status.value === TicketStatus.Won && (this.payoutAmountExceeded.headOffice || this.payoutAmountExceeded.branch);
  }

  get isPaidOut() {
    return this.ticket.status.value === TicketStatus.Won && this.actions.canPayOut === false;
  }

  get isLoss() {
    return this.ticket.status.value === TicketStatus.Loss;
  }

  get isPreCanceled() {
    return this.ticket.status.value === TicketStatus.PreCanceled;
  }

  get isCanceled() {
    if (this.isTicketV2(this.ticket)) {
      return this.ticket.status.value === TicketStatus.Canceled && this.ticket.betOnDraws.some((betOnDraw) => !!betOnDraw.dateCanceled);
    }

    if (this.isTicketV1(this.ticket)) {
      return this.ticket.status.value === TicketStatus.Canceled && !!this.ticket.dateCanceled;
    }

    throwUnsupportedKorunkaVersionError();
  }

  get betsDrawState() {
    if (this.isTicketV2(this.ticket)) {
      const isPaidOut = this.ticket.betOnDraws.every((betOnDraw) => !!betOnDraw.datePaidOut);

      const isPartlyPaidOut = isPaidOut ? false : this.ticket.betOnDraws.some((betOnDraw) => !!betOnDraw.datePaidOut);

      const isWin = this.ticket.betOnDraws.some((betOnDraw) => betOnDraw.status.value === TicketStatus.Won);

      const isWaitingForNextDraw = this.ticket.betOnDraws.some((betOnDraw) => betOnDraw.status.value === TicketStatus.NotEvaluated);

      const noWinsYet = !isWin && isWaitingForNextDraw;

      const canPayOut = this.actions.canPayOut && !!this.ticket.amountWonToPayout;

      const isLoss = this.ticket.betOnDraws.every((betOnDraw) => betOnDraw.status.value === TicketStatus.Loss);

      const isCanceled = this.ticket.betOnDraws.every((betOnDraw) => !!betOnDraw.dateCanceled);

      const isPartlyCanceled = isCanceled ? false : this.ticket.betOnDraws.some((betOnDraw) => !!betOnDraw.dateCanceled);

      const isPreCanceled = this.ticket.betOnDraws.some((betOnDraw) => betOnDraw.status.value === TicketStatus.PreCanceled);

      const isPartlyPreCanceled = isPreCanceled ? false : this.ticket.betOnDraws.some((betOnDraw) => betOnDraw.status.value === TicketStatus.PreCanceled);


      return {
        isPartlyPaidOut,
        isWin,
        isWaitingForNextDraw,
        noWinsYet,
        isPaidOut,
        canPayOut,
        isLoss,
        isCanceled,
        isPartlyCanceled,
        isPreCanceled,
        isPartlyPreCanceled,
      };
    }

    throwUnsupportedKorunkaVersionError();
  }

  get total() {
    return this.ticket.amountWon;
  }

  get cantPayoutReasonsErrors() {
    return this.actions.cantPayoutReasons.map(({error}) => error);
  }

  get payoutAmountExceeded() {
    return {
      headOffice: this.cantPayoutReasonsErrors.includes(KorunkaCantPayoutReasons.PayoutAtHeadOffice),
      branch: this.cantPayoutReasonsErrors.includes(KorunkaCantPayoutReasons.PayoutAtBranch),
    };
  }

  get isSupportedGame() {
    return this.ticket.isSupportedGame;
  }

  toActionPayOutTicket() {
    return new ActionPayOutTicket({
      ticketId: this.ticket.id,
      operationId: guid(),
      verificationCode: this.verificationCode,
    });
  }

  toGameCancel() {
    switch (this.ticket.game.value) {
    case KorunkaEntityTypes.Sestka:
      return KorunkaLotterySestkaDtoCustom.toGameCancel(this.ticket, this.id);
    case KorunkaEntityTypes.DvacetZa20:
      return KorunkaLotteryDvacetZa20DtoCustom.toGameCancel(this.ticket, this.id);
    case KorunkaEntityTypes.HopTrop:
      return KorunkaLotteryHopTropDtoCustom.toGameCancel(this.ticket, this.id);
    case KorunkaEntityTypes.Kombi:
      return KorunkaLotteryKombiDtoCustom.toGameCancel(this.ticket, this.id);
    case KorunkaEntityTypes.KombiPlus:
      return KorunkaLotteryKombiPlusDtoCustom.toGameCancel(this.ticket, this.id);
    }
  }

  toGameRepeat() {
    switch (this.ticket.game.value) {
    case KorunkaEntityTypes.Sestka:
      return KorunkaLotterySestkaDtoCustom.toGameRepeat(this.ticket, this.id);
    case KorunkaEntityTypes.DvacetZa20:
      return KorunkaLotteryDvacetZa20DtoCustom.toGameRepeat(this.ticket, this.id);
    case KorunkaEntityTypes.HopTrop:
      return KorunkaLotteryHopTropDtoCustom.toGameRepeat(this.ticket, this.id);
    case KorunkaEntityTypes.Kombi:
      return KorunkaLotteryKombiDtoCustom.toGameRepeat(this.ticket, this.id);
    case KorunkaEntityTypes.KombiPlus:
      return KorunkaLotteryKombiPlusDtoCustom.toGameRepeat(this.ticket, this.id);
    }
  }
}
