import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {apiV1PosSafeBagsGetByTransport} from '@/Model/Action';
import {
  isEmpty,
  map,
  mapKeys,
} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export class WorkflowStepSafeBagScanDeliveryList extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagScanDeliveryList;
  }

  get type() {
    return WorkflowStepSafeBagScanDeliveryList.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagScanDeliveryList.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.safeBagDeliveryListCode]: yup.string()
        .test({
          name: 'safeBagDeliveryListCode',
          message: 'validations.string.invalidSafeBagDeliveryListCode',
          test: (value: string, context) => {
            if (this.manualMode) {
              return this.hasExactManualCode;
            }

            return !!value;
          },
        }),
    });
  }

  get title() {
    if (!this.step.title) return null;
    return `${this.step.title}${this.manualMode ? ' (M)' : ''}`;
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.safeBagDeliveryListCode, () => '');
  }

  get hasExactManualCode() {
    const listCode = this.data[WorkflowStepField.safeBagDeliveryListCode];
    return listCode && this.step.manualCode === this.data[WorkflowStepField.safeBagDeliveryListCode];
  }

  get manualMode() {
    return this.data[WorkflowStepField.manualMode] ?? false;
  }

  set manualMode(val) {
    this.dataSetter(WorkflowStepField.manualMode, () => val);
  }

  get disabledNextStep() {
    return !this.getFieldValue(WorkflowStepField.safeBagDeliveryListCode, '');
  }

  async processSafeBagDeliveryListCode() {
    try {
      const safeBagDeliveryListCode = this.getFieldValue(WorkflowStepField.safeBagDeliveryListCode, '');
      this.stepFinished = true;

      if (this.hasExactManualCode && !this.manualMode) {
        this.stepFinished = false;
      }

      if (this.hasExactManualCode) {
        this.manualMode = true;
      }

      try {
        if (!await this.validate()) return;
        let list;
        try {
          this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
          list = this.manualMode ? [] : await apiV1PosSafeBagsGetByTransport({
            params: {
              transportId: safeBagDeliveryListCode,
              currency: this.step.currency,
            },
          });

          if (!this.manualMode && isEmpty(list)) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
              detail: {
                type: WorkflowStepErrors.TRANSPORT_LIST_IS_EMPTY,
              },
            }));
            return;
          }
        } finally {
          this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
        }

        this.dataSetter(WorkflowStepField.safeBagDeliveryList, () => {
          return mapKeys(map(list, (safeBag) => ({
            safeBag: safeBag.toJson(),
            scanned: false,
          })), 'safeBag.envelopeNumber');
        });

        this.dataSetter(WorkflowStepField.name, () => list[0]?.name ?? '');

        if (this.stepFinished) {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }
      } catch (e) {
        console.error(e);
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));

        this.stepFinished = false;

        if (e.response.status === 500) {
          this.manualMode = true;
        }

        this.dataSetter(WorkflowStepField.safeBagDeliveryListCode, () => '');
      }
    } finally {
      emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:processSafeBagDeliveryListCode`);
    }
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.safeBagDeliveryListCode, (fieldActions) => ({
        [WorkflowActions.ADD_CHAR]: action(async (event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action(async (event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<string>) => {
          let safeBagDeliveryListCode = event.value;

          if (!safeBagDeliveryListCode) {
            safeBagDeliveryListCode = '';
          }

          fieldActions[WorkflowActions.ENTER](safeBagDeliveryListCode);

          await this.processSafeBagDeliveryListCode();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.processSafeBagDeliveryListCode();
        }),
      })),
    };
  }
}
