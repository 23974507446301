export const KEYBOARD_KEY_00 = '00';
export const KEYBOARD_KEY_0 = '0';
export const KEYBOARD_KEY_1 = '1';
export const KEYBOARD_KEY_2 = '2';
export const KEYBOARD_KEY_3 = '3';
export const KEYBOARD_KEY_4 = '4';
export const KEYBOARD_KEY_5 = '5';
export const KEYBOARD_KEY_6 = '6';
export const KEYBOARD_KEY_7 = '7';
export const KEYBOARD_KEY_8 = '8';
export const KEYBOARD_KEY_9 = '9';
export const KEYBOARD_KEYS_NUMBERS = [
  KEYBOARD_KEY_00,
  KEYBOARD_KEY_0,
  KEYBOARD_KEY_1,
  KEYBOARD_KEY_2,
  KEYBOARD_KEY_3,
  KEYBOARD_KEY_4,
  KEYBOARD_KEY_5,
  KEYBOARD_KEY_6,
  KEYBOARD_KEY_7,
  KEYBOARD_KEY_8,
  KEYBOARD_KEY_9,
];

export const KEYBOARD_KEY_MINUS = '-';

export const KEYBOARD_KEY_PLUS = '+';

export const KEYBOARD_KEY_PERIOD = '.';

export const KEYBOARD_KEY_COMMA = ',';

export const KEYBOARD_KEY_ENTER = 'Enter';

export const KEYBOARD_KEY_SPACE = ' ';

export const KEYBOARD_KEY_TAB = 'Tab';

export const KEYBOARD_KEY_BACKSPACE = 'Backspace';

export const KEYBOARD_KEY_DELETE = 'Delete';

export const KEYBOARD_KEY_ESCAPE = 'Escape';

export const KEYBOARD_KEY_ARROW_UP = 'ArrowUp';
export const KEYBOARD_KEY_ARROW_RIGHT = 'ArrowRight';
export const KEYBOARD_KEY_ARROW_DOWN = 'ArrowDown';
export const KEYBOARD_KEY_ARROW_LEFT = 'ArrowLeft';
export const KEYBOARD_KEY_ARROWS = [
  KEYBOARD_KEY_ARROW_UP,
  KEYBOARD_KEY_ARROW_RIGHT,
  KEYBOARD_KEY_ARROW_DOWN,
  KEYBOARD_KEY_ARROW_LEFT,
];
