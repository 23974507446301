
import {
  computed,
  defineComponent,
} from 'vue';
import PhIcon from '@/Components/PhIcon.vue';
import {WorkflowStepKorunkaLotteryNumbers} from '../WorkflowStepKorunkaLotteryNumbers';
import {WorkflowStepTypes} from '../../../types';
import {useStepTools} from '../../StepTools';
import KorunkaLotteryDvacetZa20DtoCustom from '@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom';
import AsyncButton from '@/Components/AsyncButton.vue';
import InputCheckbox from '@/Components/InputCheckbox.vue';

export default defineComponent({
  components: {
    PhIcon,
    AsyncButton,
    InputCheckbox,
  },
  setup() {
    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryNumbers>(WorkflowStepTypes.KorunkaNumbers);

    const entity = computed(() => workflowStep.value.entity as KorunkaLotteryDvacetZa20DtoCustom);

    const generateRandomNumbersOnEntity = async () => await workflowStep.value.generateRandomNumbersOnEntity();

    const onUpdateModel = (value: boolean) => {
      entity.value.booster = value;
      entity.value.deposit = null;
    };

    return {
      entity,
      workflowStep,
      generateRandomNumbersOnEntity,
      onUpdateModel,
    };
  },
});
