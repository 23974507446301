
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import {useCashAmountValidatorStore} from '@/Modules/Core/store/CashAmountValidatorStore';
import IconWarning from '@/Components/Icons/IconWarning.vue';
import {useI18n} from 'vue-i18n';
import IconCross from '@/Components/Icons/IconCross.vue';
import {useWorkflowEntries} from '@/Modules/Workflow/entries';
import {
  filter,
  find,
  map,
  some,
} from 'lodash-es';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'ModalCashAmountValidator',
  components: {
    IconCross,
    IconWarning,
    Modal,
  },
  setup() {
    const i18n = useI18n();
    const cashAmountValidatorStore = useCashAmountValidatorStore();
    const {openGenericWorkflow} = useWorkflowEntries();
    const filters = useFilters();

    const close = () => {
      cashAmountValidatorStore.terminate();
    };

    const workflowRedirect = async () => {
      const exceededCashAmount = find(exceededCashAmounts.value, ({config}) => !!config.maxAmountInPOSExceededWorkflow);

      try {
        await openGenericWorkflow(exceededCashAmount.config.maxAmountInPOSExceededWorkflow, {
          referer: '/',
        });
      } finally {
        await cashAmountValidatorStore.terminate();
      }
    };

    const exceededCashAmounts = computed(() => {
      const cashStates = cashAmountValidatorStore.validation.value?.cashStates ?? null;

      return filter(cashStates?.validatedCashAmounts ?? [], {exceeded: true});
    });

    const hasWorkflowRedirect = computed(() => {
      return some(exceededCashAmounts.value, ({config}) => !!config.maxAmountInPOSExceededWorkflow);
    });

    const content = computed(() => {
      const cashStates = cashAmountValidatorStore.validation.value?.cashStates ?? null;

      if (!cashStates) return;

      const exceededCashAmounts = filter(cashStates.validatedCashAmounts, {exceeded: true});

      return i18n.t('cashAmountValidator.description', exceededCashAmounts.length, {
        named: {
          currencies: map(exceededCashAmounts, ({currency, config}) => {
            return `${filters.currencyFormat(config.maxAmountInPOS, currency)}`;
          }).join('; '),
        },
      });
    });

    return {
      content,
      close,
      cashAmountValidatorStore,
      workflowRedirect,
      hasWorkflowRedirect,
    };
  },
});
