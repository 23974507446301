import {useSignalR} from '@/Helpers/signalR';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';

export enum OnlineDetectionEvents {
  CHECK_STATE = 'CheckState',
  GET_STATE = 'GetState',
  RESET_FORCE = 'ResetForce',
}

export const amIOnline = async () => {
  const {notificationsConnection} = useSignalR();

  const trigger = () => notificationsConnection
    .dispatchEvent(OnlineDetectionEvents.CHECK_STATE, null);
  const [online, forcedOnline] = await notificationsConnection
    .addEventListenerWithTrigger<[boolean, boolean]>(OnlineDetectionEvents.GET_STATE)(trigger);

  return forcedOnline ?? online;
};

export const resetForcedOnline = async () => {
  const {notificationsConnection} = useSignalR();

  const trigger = () => notificationsConnection
    .dispatchEvent(OnlineDetectionEvents.RESET_FORCE);
  const [online, forcedOnline] = await notificationsConnection
    .addEventListenerWithTrigger<[boolean, boolean]>(OnlineDetectionEvents.GET_STATE)(trigger);

  return forcedOnline ?? online;
};

export const useOnlineDetection = () => {
  const {notificationsConnection} = useSignalR();
  const online = ref(true);
  const realOnline = ref(true);
  const forcedOnline = ref(null);

  const isForcedOnline = computed(() => {
    return forcedOnline.value !== null;
  });

  const onGetState = async (...args) => {
    realOnline.value = args[0];
    forcedOnline.value = args[1];
    online.value = args[1] ?? args[0];
  };

  const attach = async () => {
    await notificationsConnection.addEventListener(OnlineDetectionEvents.GET_STATE, onGetState);
  };

  const detach = async () => {
    await notificationsConnection.removeEventListener(OnlineDetectionEvents.GET_STATE, onGetState);
  };

  onMounted(async () => {
    await attach();
    await amIOnline();
  });

  onBeforeUnmount(async () => {
    await detach();
  });

  return {
    amIOnline,
    resetForcedOnline,
    online,
    realOnline,
    forcedOnline,
    isForcedOnline,
  };
};
