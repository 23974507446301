import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const DocumentPrintoutDtoPropDef = {
  pdfBase64: {
    type: new StringType(),
  },
  htmlBase64: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentPrintoutDto extends createEntity(DocumentPrintoutDtoPropDef) {}
