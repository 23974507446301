import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Barcode from '../Barcode';


export default class GeneratedHardware extends createEntity({
  barcode: {
    type: new EntityType(Barcode),
  },
}) {}
