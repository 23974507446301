import {
  RegisterActions,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {GecoGameFlow} from '@/Modules/Register/PromoInteractionFlow/GecoGameFlow';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.GECO_GAME]: {
      [RegisterActions.INIT]: action(async ({previousState, counterName, promoCode}) => {
        const nextStep = previousState;

        if (promoCode && counterName) {
          await this.setPromoFlow(await GecoGameFlow.new(
            nextStep,
            promoCode,
            counterName,
          ));
          await this.persist();
        } else {
          console.error('Attempted to create geco game with invalid counter or promoCode', {promoCode, counterName});
          await this.changeState(previousState);
        }
      }),
    },
  };
}
