import {
  TransformStream as PonyTransformStream,
  WritableStream as PonyWritableStream,
  ReadableStream as PonyReadableStream,
} from 'web-streams-polyfill/ponyfill';

export const TransformStream = (
  globalThis.TransformStream ?? PonyTransformStream
) as (typeof globalThis.TransformStream);
export const WritableStream = (
  globalThis.WritableStream ?? PonyWritableStream
) as (typeof globalThis.WritableStream);
export const ReadableStream = (
  globalThis.ReadableStream ?? PonyReadableStream
) as (typeof globalThis.ReadableStream);
