import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  apiSearch,
  apiV1PosSafeBagVerify,
} from '@/Model/Action';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {emitTestEvent} from '@/Helpers/testEvent';

export class WorkflowStepSafeBagScanBarcode extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagScanBarcode;
  }

  get type() {
    return WorkflowStepSafeBagScanBarcode.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagScanBarcode.vue').default);
  }

  async beforeEnter() {
    this.disabledNextStep = true;
    this.dataSetter(WorkflowStepField.safeBagEnvelopeCode, () => '');
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.safeBagEnvelopeCode, (fieldActions) => ({
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<string>) => {
          try {
            this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

            this.disabledNextStep = true;
            fieldActions[WorkflowActions.ENTER]('');

            const {safeBags} = sanitizeApiSearch(await apiSearch({params: {code: event.value ?? ''}}));

            const [safeBag] = safeBags;

            if (!safeBag) {
              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                detail: {
                  type: WorkflowStepErrors.INVALID_SAFE_BAG_CODE,
                  value: event.value,
                },
              }));
              return;
            }

            await apiV1PosSafeBagVerify({
              params: {
                code: event.value ?? '',
              },
            });

            this.disabledNextStep = false;
            fieldActions[WorkflowActions.ENTER](safeBag.envelopeNumber);
          } catch (e) {
            console.error(e);
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
              detail: {
                value: e,
              },
            }));
          } finally {
            emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:scannedSafeBagEnvelopeCode`);
            this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
          }
        }),
        [WorkflowActions.ENTER]: action(async () => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
