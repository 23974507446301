import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const AssortmentInfoDtoPropDef = {
  deniedByAssortmentShopException: {
    type: new BooleanType(),
  },
  allowedByAssortmentGroup: {
    type: new BooleanType(),
  },
};
export default class GeneratedAssortmentInfoDto extends createEntity(AssortmentInfoDtoPropDef) {}
