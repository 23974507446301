import {createEntity, StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CashierDisplay from '../CashierDisplay';
import KeyboardLayout from '../KeyboardLayout';


export default class GeneratedUiGeneral extends createEntity({
  cashierDisplay: {
    type: new EntityType(CashierDisplay),
  },
  customerDisplay: {
    type: new BooleanType(),
  },
  keyboardLayout: {
    type: new EntityType(KeyboardLayout),
  },
  showKeyboardShortcuts: {
    type: new BooleanType(),
  },
  headerCurrentDateTimeFormat: {
    type: new StringType(),
  },
}) {}
