
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepBalanceStockOverview} from './WorkflowStepBalanceStockOverview';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';
import {sortByArray} from '@/Helpers/array';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export default defineComponent({
  name: 'StepBalanceStockOverview',
  components: {
    HLStep,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.BalanceStockOverview;

    const configurationStore = useConfigurationStore();

    const {workflowStep} = useStepTools<WorkflowStepBalanceStockOverview>(stepType);

    const {currencyFormat: originalCurrencyFormat} = useFilters();

    const currencyFormat = (value) => originalCurrencyFormat(value, configurationStore.localCurrency.value.symbol);

    const items = computed(() => sortByArray(
      workflowStep.value.sellDocument.items,
      workflowStep.value.articlesOrder,
      'internalNumber',
    ));

    return {
      workflowStep,
      stepType,
      items,
      currencyFormat,
    };
  },
});
