
import {
  defineComponent, PropType,
} from 'vue';
import ModalConfirm from '@/Modules/Core/Confirm/ModalConfirm.vue';
import ModalCrashState from '@/Modules/Core/CrashState/ModalCrashState.vue';
import {mountDevToolsKeyboardListener} from '@/Helpers/devTools';
import {useInactivityDetection} from '@/Helpers/inactivity';
import {useAuth} from '@/Helpers/auth';
import InactivityBar from '@/Components/InactivityBar.vue';
import ModalInactivity from '@/Modules/Core/Inactivity/ModalInactivity.vue';
import Theme from '@/Components/Theme.vue';
import AppLoader from '@/Modules/Core/AppLoader/AppLoader.vue';
import ModalHelp from '@/Modules/Core/Help/ModalHelp.vue';
import ModalFourEyes from '@/Modules/Core/FourEyes/ModalFourEyes.vue';
import ModalPosCoreDisconnectState from '@/Modules/Core/PosCoreDisconnectState/ModalPosCoreDisconnectState.vue';
import ModalPrinterServiceDisconnectState
  from '@/Modules/Core/PrinterServiceDisconnectState/ModalPrinterServiceDisconnectState.vue';
import {AppVersion} from '@/constants/appVersion';
import {useAppVersion} from '@/Helpers/appVersion';

export default defineComponent({
  name: 'IncorrectRestartPage',
  components: {
    InactivityBar,
    ModalInactivity,
    ModalPosCoreDisconnectState,
    ModalPrinterServiceDisconnectState,
    ModalFourEyes,
    ModalHelp,
    AppLoader,
    Theme,
    ModalCrashState,
    ModalConfirm,
  },
  props: {
    appVersion: {
      type: String as PropType<AppVersion>,
      required: true,
    },
    isElectron: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    useAppVersion(props.appVersion);

    useAuth({onLogoutWatcher: false});

    useInactivityDetection();

    mountDevToolsKeyboardListener();

    return {};
  },
});
