import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {PermissionDtoPropDef} from '@/Model/Entity/generated/PermissionDto';

export default class PermissionTargetDtoCustom extends createEntity({
  ...PermissionDtoPropDef,
  parametersStrategy: {
    type: new StringType(),
  },
}) {}
