import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {Context} from '@/Helpers/Context';
import MD5 from 'crypto-js/md5';
import {
  WorkflowActions,
  WorkflowEvents,
  WorkflowStepEvents,
  WorkflowCodes,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {RouteLocationRaw} from 'vue-router';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {EventTarget} from 'event-target-shim';
import {
  filter,
  findIndex,
  findLastIndex,
  first,
  isNil,
  map,
  mapKeys,
  mapValues,
  some,
} from 'lodash-es';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import * as workflowStepConstructors from '../Step/steps';
import {guid} from '@/Helpers/guid';
import {nextTick} from 'vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export class Workflow {
  public guid: string
  public context: Context
  public referer: RouteLocationRaw
  public code: WorkflowCodes
  public _activeStepIndex: number
  public activeField: string
  public previousActiveField: string
  public messageBus: EventTarget
  public workflowSteps: any[]
  public createdDate: Date
  public checksum: string
  constructor(code: WorkflowCodes, {
    context = new Context(),
    referer = '/',
    activeStepIndex = 0,
    previousActiveField = null,
    activeField = null,
    checksum = null,
  } = {}) {
    this.guid = guid();
    this.createdDate = new Date();
    this.context = context;
    this.referer = referer;
    this.code = code;
    this.activeStepIndex = activeStepIndex;
    this.previousActiveField = previousActiveField;
    this.messageBus = new EventTarget();

    try {
      this.workflowSteps = map(this.steps, (step, index) => {
        try {
          return new (this.workflowStepConstructorsByType[step.type])(
            // @ts-ignore // TODO: remove when backend fix their step types
            JSON.parse(JSON.stringify(step)),
            this.context,
            this.code,
            index,
          );
        } catch (e) {
          console.error(`Error creating step ${step.type}`);
          throw e;
        }
      });
    } catch (e) {
      console.error(e);
      this.workflowSteps = map(this.steps, (step, index) => {
        return new (this.workflowStepConstructorsByType[WorkflowStepTypes.InvalidConfiguration])(
          {
            // @ts-ignore
            type: WorkflowStepTypes.InvalidConfiguration,
          },
          this.context,
          this.code,
          index,
        );
      });
    }

    if (activeField) {
      this.activeField = activeField;
    } else {
      this.initWorkflowStepFirstField();
    }

    this.calculateChecksum();

    if (checksum && checksum !== this.checksum) {
      throw new Error('Workflow checksum mismatch');
    }
  }

  get activeStepIndex() {
    return this._activeStepIndex;
  }

  set activeStepIndex(value) {
    this.activeWorkflowStep?.stopCachedScopes();
    this._activeStepIndex = value;
  }

  stopCacheScope() {
    for (const step of this.workflowSteps) {
      step.stopCachedScopes();
    }
  }

  get activeStep(): any {
    return this.steps[this.activeStepIndex];
  }

  get activeStepType() {
    return this.activeStep.type;
  }

  get activeWorkflowStep(): WorkflowStep {
    return this.workflowSteps?.[this.activeStepIndex];
  }

  get isStepTransitionLocked(): Promise<boolean> {
    return (async () => {
      return !!this.coreStore.loaderActive.value;
    })();
  }

  get isActiveStepFirst() {
    return this.activeStepIndex === findIndex(this.steps, (step) => !step.isTechnicalStep);
  }

  get isActiveStepLast() {
    return this.activeStepIndex === findLastIndex(this.steps, (step) => !step.isTechnicalStep);
  }

  get configuration() {
    return this.configurationStore.configuration.value.workflow[this.code];
  }

  calculateChecksum() {
    this.checksum = MD5(JSON.stringify(this.configuration)).toString();
  }

  get configurationStore() {
    return useConfigurationStore();
  }

  get coreStore() {
    return useCoreStore();
  }

  get title() {
    return this.activeWorkflowStep.title ?? this.workflowTitle ?? null;
  }

  get workflowTitle() {
    return this.configuration.title;
  }

  get instructions() {
    return this.activeWorkflowStep?.instructions ?? this.workflowInstructions ?? null;
  }

  get workflowInstructions(): string {
    return this.configuration.instructions;
  }

  get canLeaveUnfinishedWF(): boolean {
    return this.configuration.canLeaveUnfinishedWF ?? false;
  }

  get steps(): any[] {
    return this.configuration.steps;
  }

  get visibleSteps() {
    let lastVisibleStepNumber = this.steps[0].isTechnicalStep ? 0 : 1;
    const stepNumbers = mapValues(this.steps, (val, index) => {
      const currentStepNumber = lastVisibleStepNumber;
      if (!this.steps[parseInt(index) + 1]?.isTechnicalStep) {
        lastVisibleStepNumber++;
      }
      return currentStepNumber;
    });
    return {
      step: stepNumbers[this.activeStepIndex],
      of: stepNumbers[this.steps.length - 1],
    };
  }

  get transitionsByActiveStep(): {[key in any]?: {[key in WorkflowActions]?: any}} {
    return this.activeWorkflowStep.transitions;
  }

  get workflowStepByType() {
    return mapKeys(this.workflowSteps, 'type');
  }

  get workflowStepConstructorsByType() {
    return mapKeys(workflowStepConstructors, 'type');
  }

  get layout() {
    return this.activeWorkflowStep.layout;
  }

  exit() {
    this.messageBus.dispatchEvent(new Event(WorkflowEvents.EXIT));
  }

  abort() {
    this.activeWorkflowStep.messageBus.dispatchEvent(new Event(WorkflowStepEvents.ABORT_WORKFLOW));
  }

  pointOfNoReturn() {
    this.activeWorkflowStep.messageBus.dispatchEvent(new Event(WorkflowStepEvents.POINT_OF_NO_RETURN));
  }

  async changeActiveField(activeField: string, {validate = true} = {}) {
    if (validate && this.activeField) {
      const isValid = await this.activeWorkflowStep.validateAt(this.activeField, {clearOnFail: true});
      const currentActiveField = this.activeField;
      this.activeField = null;
      await nextTick();
      this.activeField = currentActiveField;
      this.previousActiveField = this.activeField;

      if (isValid) {
        this.activeField = activeField;
      } else {
        this.activeField = this.previousActiveField;
      }
    } else {
      const currentActiveField = this.activeField;
      this.activeField = null;
      await nextTick();
      this.activeField = currentActiveField;
      this.previousActiveField = this.activeField;
      this.activeField = activeField;
    }

    this.activeWorkflowStep.insertMode = this.activeWorkflowStep.activateInsertMode(this.activeField);
    nextTick()
      .then(() => {
        emitTestEvent(TestEvent.WORKFLOW_FIELD_CHANGED);
      });
  }

  async requestNextStep() {
    if (await this.isStepTransitionLocked) return;

    await this.activeWorkflowStep.beforeContinue();

    if (this.activeWorkflowStep.disabledNextStep) return;

    if (this.activeStepIndex === this.steps.length - 1) {
      this.exit();
      return;
    }

    if (this.steps.length - 1 > this.activeStepIndex) {
      this.activeStepIndex += 1;
    }

    await this.initWorkflowStepFirstField();
  }

  get resolvedWorkflowSteps() {
    return Promise.all(
      this.workflowSteps.map(async (step, index) => ({
        index: index,
        canBeReturnedTo: await step.canBeReturnedTo,
        isPointOfNoReturn: await step.isPointOfNoReturn,
        step,
      })),
    ).then((resolvedWorkflowSteps) => {
      const previousSteps = Object.entries([...this.steps])
        .reverse()
        .slice(this.steps.length - 1 - this.activeStepIndex + 1)
        .map(([index, stepConfig]) => {
          const indexAsInt = parseInt(index);
          return {
            index: resolvedWorkflowSteps[indexAsInt].index,
            step: resolvedWorkflowSteps[indexAsInt].step,
            canBeReturnedTo: resolvedWorkflowSteps[indexAsInt].canBeReturnedTo,
            isPointOfNoReturn: resolvedWorkflowSteps[indexAsInt].isPointOfNoReturn,
          };
        }) as Array<{
        index: number,
        step: WorkflowStep,
        canBeReturnedTo: boolean,
        isPointOfNoReturn: boolean,
      }>;

      const previousVisibleSteps = filter(previousSteps, ({step}) => !step.step.isTechnicalStep);

      const previousTechnicalSteps = previousVisibleSteps.length ?
        previousSteps.slice(0, Math.max(findIndex(previousSteps, ({step}) => !step.step.isTechnicalStep), 0)) :
        previousSteps;

      const firstStepThatCanBeReturnedToIndex = first(filter(resolvedWorkflowSteps, 'canBeReturnedTo'))?.index;
      const hasPreviousStepThatCanBeReturnedTo = (
        !isNil(firstStepThatCanBeReturnedToIndex) && this.activeStepIndex > firstStepThatCanBeReturnedToIndex
      );

      const arePreviousTechnicalStepsPointOfNoReturn = some(previousTechnicalSteps, {isPointOfNoReturn: true}) ?? false;

      const isPreviousVisibleStepPointOfNoReturn = first(previousVisibleSteps)?.isPointOfNoReturn ?? false;

      const pointOfNoReturn = arePreviousTechnicalStepsPointOfNoReturn || isPreviousVisibleStepPointOfNoReturn;

      return {
        resolvedWorkflowSteps,
        hasPreviousStepThatCanBeReturnedTo,
        pointOfNoReturn,
        previousSteps,
        previousVisibleSteps,
      };
    });
  }

  async requestPreviousStep() {
    if (await this.isStepTransitionLocked) return;

    const {
      pointOfNoReturn,
      previousSteps,
      hasPreviousStepThatCanBeReturnedTo,
    } = await this.resolvedWorkflowSteps;

    if (pointOfNoReturn) {
      this.pointOfNoReturn();
      return;
    }

    if (!hasPreviousStepThatCanBeReturnedTo) {
      this.abort();
      return;
    }

    let foundIndex = -1;

    for (const {index, canBeReturnedTo} of previousSteps) {
      if (canBeReturnedTo) {
        foundIndex = index;
        break;
      }
    }

    await this.activeWorkflowStep.beforeReturn();

    this.activeStepIndex = foundIndex;

    await this.initWorkflowStepFirstField();
  }

  async setActiveStepByCode(code) {
    if (await this.isStepTransitionLocked) return;

    this.activeStepIndex = findIndex(this.steps, (step) => {
      return step.type === code;
    });

    await this.initWorkflowStepFirstField();
  }

  async setActiveStepByIndex(index) {
    if (await this.isStepTransitionLocked) return;

    this.activeStepIndex = index;

    await this.initWorkflowStepFirstField();
  }

  async initWorkflowStepFirstField() {
    const [firstState = null] = Object.keys(this.activeWorkflowStep.transitions);
    await this.changeActiveField(firstState, {validate: false});
    emitTestEvent(TestEvent.WORKFLOW_STEP_READY);
    emitTestEvent(`${TestEvent.WORKFLOW_STEP_READY}:${this.activeStepIndex}`);
  }
}
