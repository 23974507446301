import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FacadeFinishPartnerSessionRequest from '../FacadeFinishPartnerSessionRequest';


export const ValidateSessionCommandPropDef = {
  request: {
    type: new EntityType(FacadeFinishPartnerSessionRequest),
  },
};
export default class GeneratedValidateSessionCommand extends createEntity(ValidateSessionCommandPropDef) {}
