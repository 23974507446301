import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const PinDtoPropDef = {
  pin: {
    type: new StringType(),
  },
};
export default class GeneratedPinDto extends createEntity(PinDtoPropDef) {}
