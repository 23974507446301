

import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import {useMessageStore} from '@/Modules/Apps/PosMessage/store/MessageStore';
import {useAcl} from '@/Helpers/acl';
import * as aclKeys from '@/constants/aclKeys';
import {useRouter} from 'vue-router';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import {ConfirmTypes} from '@/Modules/Core/types';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {usePrintContentStore} from '@/Modules/Core/store/PrintContentStore';
import {useI18n} from 'vue-i18n';
import {debounce} from 'lodash-es';

export default defineComponent({
  name: 'ModalImportantUnreadMessages',
  components: {
    ModalDetachedButtons,
    ModalDetachedBody,
    ModalDetachedClose,
    Modal,
  },
  emits: ['onSubmit', 'onClose'],
  setup(props, {emit}) {
    const authStore = useAuthStore();
    const messageStore = useMessageStore();
    const printContentStore = usePrintContentStore();
    const acl = useAcl();
    const router = useRouter();
    const i18n = useI18n();
    const debouncedOpen = ref(false);

    const onClose = () => {
      messageStore.setModalImportantUnreadMessage(false);
    };

    const redirectToMessages = () => {
      onClose();
      router.push({name: 'apps-messages'});
    };

    const open = computed(() => {
      if (!authStore.isLoggedIn.value) {
        return false;
      }

      if (authStore.isStateTypeQuickLoginConfirm.value) {
        return false;
      }

      if (printContentStore.isActive.value) {
        return false;
      }

      if (!acl(aclKeys.PERMISSION_MESSAGES_APP_ACCESS)) {
        return false;
      }

      if (router.currentRoute.value.name !== 'login') {
        return false;
      }

      if (
        !acl(aclKeys.PERMISSION_MESSAGES_DISPLAY_SENSITIVE) &&
          messageStore.unreadMessages.value.length &&
          messageStore.unreadMessages.value.length === messageStore.unreadSensitiveMessages.value.length
      ) {
        return false;
      }

      return !!messageStore.modalImportantUnreadMessageIsOpened.value;
    });

    const type = computed(() => {
      if (messageStore.unreadImportantMessages.value.length) {
        return ConfirmTypes.error;
      }

      if (messageStore.unreadSensitiveMessages.value.length) {
        return ConfirmTypes.warning;
      }

      return ConfirmTypes.default;
    });

    const unreadImportantOrSensitiveMessages = computed(() => {
      return messageStore.unreadImportantOrSensitiveMessages.value;
    });

    const message = computed(() => {
      return i18n.t(
        'apps.messages.unreadMessages.title',
        unreadImportantOrSensitiveMessages.value.length === 1 ? 1 : 0,
      );
    });

    watch(
      () => open.value,
      debounce((value) => {
        debouncedOpen.value = value;
      }, 500),
      {immediate: true},
    );

    return {
      open,
      debouncedOpen,
      onClose,
      message,
      type,
      redirectToMessages,
      ConfirmTypes,
    };
  },
});
