import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';

export interface IPrinterServiceStatusStore {
    isRunning: boolean,
}

const createInitState = (data?: Partial<IPrinterServiceStatusStore>) => ({
  isRunning: false,
});

export class PrinterServiceStatusStore extends Store<IPrinterServiceStatusStore> {
  constructor() {
    super(createInitState());
  }
    setIsRunning = action((val) => {
      this.state.isRunning = val;
    })

    isRunning = getter(() => {
      return this.state.isRunning;
    })
}

const storeIdentifier = 'PrinterServiceStatusStore';

export const configurePrinterServiceStatusStore = createConfigureStore<typeof PrinterServiceStatusStore>(
  storeIdentifier,
);
export const usePrinterServiceStatusStore = createUseStore(PrinterServiceStatusStore, storeIdentifier);
