import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ArchiveMessageDtoPropDef = {
  archiveIds: {
    type: new ArrayType(new StringType()),
  },
  unArchiveIds: {
    type: new ArrayType(new StringType()),
  },
};
export default class GeneratedArchiveMessageDto extends createEntity(ArchiveMessageDtoPropDef) {}
