import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const TransactionExternalPosSyncItemPropDef = {
  synced: {
    type: new BooleanType(),
  },
  posCode: {
    type: new StringType(),
  },
  documentDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedTransactionExternalPosSyncItem extends createEntity(TransactionExternalPosSyncItemPropDef) {}
