
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import InputBuffer from '@/Components/InputBuffer.vue';
import ArticleLabelBatch from '@/Components/ArticleLabels/ArticleLabelBatch.vue';
import TaxStamp from '@/Components/TaxStamp.vue';
import {useFilters} from '@/Helpers/filters';
import ImageFallback from '@/Components/ImageFallback.vue';
import {DocumentLogisticItemDto} from '@/Model/Entity';
import {WorkflowStepEvents} from '@/Modules/Workflow/types';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {apiInStoreManagementArticleSearch, apiStockGet} from '@/Model/Action';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import StockFilterDto from '../../../../../../Model/Entity/StockFilterDto';
import {
  map,
} from 'lodash-es';
import {mapStockList} from '@/Helpers/batch';
import IconThreeDots from '@/Components/Icons/IconThreeDots.vue';
import PhIcon from '@/Components/PhIcon.vue';
import {
  WorkflowStepWarehouseOperationGoodsList,
} from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WorkflowStepWarehouseOperationGoodsList';

export default defineComponent({
  components: {
    PhIcon,
    IconThreeDots,
    ImageFallback,
    TaxStamp,
    ArticleLabelBatch,
    InputBuffer,
  },
  setup(props, {attrs}) {
    const {currencyFormat, dateFormat, numberWithPolarityFormat} = useFilters();
    const workflowStore = useWorkflowStore();
    const workflowStep = computed(() => {
      return workflowStore.currentWorkflow.value.activeWorkflowStep as WorkflowStepWarehouseOperationGoodsList;
    });
    const stockQuantity = ref(null);
    const inTransitQuantityValue = ref(null);
    const stockIsLoading = ref(null);

    const article = computed(() => {
      return attrs.article as DocumentLogisticItemDto;
    });

    const quantity = computed(() => {
      return attrs.quantity as number;
    });

    const insertMode = computed(() => {
      return attrs.insertMode as boolean;
    });

    const isModeReadonly = computed(() => {
      return attrs.isModeReadonly as boolean;
    });

    const displayStock = computed(() => {
      return attrs.displayStock as boolean;
    });

    const calculateStockQuantity = async () => {
      if (!displayStock.value) {
        return;
      }

      if (!article.value) {
        return;
      }

      try {
        stockIsLoading.value = true;

        const {documentItems} = sanitizeApiSearch(await apiInStoreManagementArticleSearch({
          params: {
            code: article.value.gtin,
          },
        }));

        if (documentItems.length === 0) {
          return;
        }

        const [item] = documentItems;

        const stockMap = mapStockList(await apiStockGet({
          input: new StockFilterDto({
            internalNumbers: map(documentItems, 'internalNumber'),
          }),
        }));

        stockQuantity.value = item.getStockQuantityByStockMap(stockMap);
        inTransitQuantityValue.value = item.getInTransitQuantityValueByStockMap(stockMap);
      } catch (e) {
        console.error(e);
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      } finally {
        stockIsLoading.value = false;
      }
    };

    watch(article, async (newArticle, oldArticle) => {
      if (!newArticle) {
        return;
      }

      if (newArticle.internalNumberWithBatch === oldArticle?.internalNumberWithBatch) {
        return;
      }

      await calculateStockQuantity();
    }, {flush: 'post', immediate: true});

    const openStockInStores = () => {
      workflowStep.value.openStockInStores();
    };

    return {
      article,
      quantity,
      insertMode,
      isModeReadonly,
      currencyFormat,
      dateFormat,
      numberWithPolarityFormat,
      stockQuantity,
      inTransitQuantityValue,
      stockIsLoading,
      openStockInStores,
    };
  },
});
