import {SyncTarget} from '@/Helpers/syncedStore';
import {
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {WorkflowStepTypes} from '../../Workflow/types';
import {Entity} from '@designeo/apibundle-js';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import {deserializeKorunkaEntity, throwUnsupportedKorunkaVersionError} from '@/Helpers/korunka';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';

export type KorunkaSyncTargetState = {
  // NOTE: Use only korunka step types
  stepType?: WorkflowStepTypes
  korunkaType?: KorunkaEntityTypes
  messages?: Record<string, string>
  entity?: Entity<any, any>['_data']
  ticket?: KorunkaLotteryTicketDtoCustom['_data'],
  numbersModalOpen?: boolean,
  isSynced?: boolean,
  isMultipleTickets: boolean,
}

const createInitState = () => ({
  stepType: null,
  korunkaType: null,
  messages: null,
  entity: null,
  ticket: null,
  numbersModalOpen: false,
  isSynced: false,
  isMultipleTickets: false,
});

export class KorunkaSyncTarget extends SyncTarget<'Korunka', KorunkaSyncTargetState> {
  constructor() {
    super('Korunka', createInitState());
  }

  entity = getter(() => {
    return deserializeKorunkaEntity({
      entity: this.state.entity,
      entityType: this.state.isMultipleTickets ? KorunkaEntityTypes.ScanResult : this.state.korunkaType,
    });
  })

  stepType = getter(() => this.state.stepType)

  korunkaType = getter(() => this.state.korunkaType)

  messages = getter(() => this.state.messages)

  numbersModalOpen = getter(() => this.state.numbersModalOpen ?? false)

  isSynced = getter(() => this.state.isSynced ?? false)

  isMultipleTickets = getter(() => {
    return this.state.isMultipleTickets ?? false;
  })

  resolvedDisplayComponent = getter(() => {
    const components = {
      [WorkflowStepTypes.KorunkaNumbers]: {
        [KorunkaEntityTypes.Sestka]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/SestkaBet.vue').default;
        },
        [KorunkaEntityTypes.DvacetZa20]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/DvacetZa20Bet.vue').default;
        },
        [KorunkaEntityTypes.HopTrop]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/HopTropBet.vue').default;
        },
      },
      [WorkflowStepTypes.KorunkaDeposit]: {
        [KorunkaEntityTypes.Sestka]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/SestkaBet.vue').default;
        },
        [KorunkaEntityTypes.DvacetZa20]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/DvacetZa20Bet.vue').default;
        },
        [KorunkaEntityTypes.HopTrop]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/HopTropBet.vue').default;
        },
      },
      [WorkflowStepTypes.KorunkaDraw]: {
        [KorunkaEntityTypes.Sestka]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/SestkaBet.vue').default;
        },
        [KorunkaEntityTypes.DvacetZa20]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/DvacetZa20Bet.vue').default;
        },
        [KorunkaEntityTypes.HopTrop]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/HopTropBet.vue').default;
        },
      },
      [WorkflowStepTypes.KorunkaOverview]: {
        [KorunkaEntityTypes.Sestka]: () => {
          return this.isMultipleTickets.value ?
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/SestkaMultipleBet.vue').default :
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/SestkaBet.vue').default;
        },
        [KorunkaEntityTypes.DvacetZa20]: () => {
          return this.isMultipleTickets.value ?
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/DvacetZa20MultipleBet.vue').default :
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/DvacetZa20Bet.vue').default;
        },
        [KorunkaEntityTypes.HopTrop]: () => {
          return this.isMultipleTickets.value ?
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/HopTropMultipleBet.vue').default :
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/HopTropBet.vue').default;
        },
        [KorunkaEntityTypes.Kombi]: () => {
          return this.isMultipleTickets.value ?
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/KombiMultipleBet.vue').default :
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/KombiBet.vue').default;
        },
        [KorunkaEntityTypes.KombiPlus]: () => {
          return this.isMultipleTickets.value ?
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/KombiPlusMultipleBet.vue').default :
            require('@/Modules/CustomerExternal/KorunkaLottery/Components/KombiPlusBet.vue').default;
        },
      },
      [WorkflowStepTypes.KorunkaEnterCode]: {
        [KorunkaEntityTypes.Ticket]: () => {
          if (!(this.entity.value instanceof KorunkaLotteryTicketDtoCustom)) {
            return null;
          }

          if (!this.entity.value.ticket) {
            return null;
          }

          if (this.entity.value.isTicketV1(this.entity.value.ticket)) {
            return !this.numbersModalOpen.value ?
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResult.vue').default :
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultWinningNumbers.vue').default;
          }

          if (this.entity.value.isTicketV2(this.entity.value.ticket)) {
            return !this.numbersModalOpen.value ?
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultV2.vue').default :
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultWinningNumbers.vue').default;
          }

          throwUnsupportedKorunkaVersionError();
        },
      },
      [WorkflowStepTypes.KorunkaTicketResult]: {
        [KorunkaEntityTypes.Ticket]: () => {
          if (!(this.entity.value instanceof KorunkaLotteryTicketDtoCustom)) {
            return null;
          }

          if (!this.entity.value.ticket) {
            return null;
          }

          if (!this.entity.value.isSupportedGame) {
            return require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultUnsupported.vue').default;
          }

          if (this.entity.value.isTicketV1(this.entity.value.ticket)) {
            return !this.numbersModalOpen.value ?
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResult.vue').default :
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultWinningNumbers.vue').default;
          }

          if (this.entity.value.isTicketV2(this.entity.value.ticket)) {
            return !this.numbersModalOpen.value ?
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultV2.vue').default :
              require('@/Modules/CustomerExternal/KorunkaLottery/Components/TicketResultWinningNumbers.vue').default;
          }

          throwUnsupportedKorunkaVersionError();
        },
      },
      [WorkflowStepTypes.KorunkaCardOverview]: {
        [KorunkaEntityTypes.Card]: () => {
          return require('@/Modules/CustomerExternal/KorunkaLottery/Components/CardOverview.vue').default;
        },
      },
    };

    return components?.[this.stepType.value]?.[this.korunkaType.value]?.();
  });
}

const storeIdentifier = 'KorunkaSyncTarget';

export const configureKorunkaSyncTarget = createConfigureStore<typeof KorunkaSyncTarget>(storeIdentifier);
export const useKorunkaSyncTarget = createUseStore(KorunkaSyncTarget, storeIdentifier);
