
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,

} from 'vue';
import Modal from '@/Components/Modal.vue';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {CustomerAlert} from '@/Modules/CustomerExternal/types';
import Countdown from '@/Components/Countdown.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import {ConfirmTypes} from '@/Modules/Core/types';
import AsyncButton from '@/Components/AsyncButton.vue';
import {wait} from '@designeo/js-helpers';

const createModalInitState = () => ({
  message: null,
  open: false,
  autoClose: null,
});

export default defineComponent({
  name: 'ModalAlert',
  components: {AsyncButton, ModalDetachedBody, ModalDetachedButtons, ModalDetachedClose, Countdown, Modal},
  setup() {
    const modal = reactive<CustomerAlert>(createModalInitState());
    const modalRef = ref(null);

    const timer = ref(null);

    const close = async () => {
      Object.assign(modal, {
        open: false,
      });

      await wait(modalRef.value.leaveTime)(null);

      Object.assign(modal, createModalInitState());
    };

    const onAlert = (event: CustomEvent<CustomerAlert>) => {
      clearTimeout(timer.value);
      Object.assign(modal, {
        message: event.detail.message ?? null,
        open: event.detail.open ?? !!event.detail.message,
        autoClose: event.detail.autoClose ?? event.detail.message ? 15000 : null,
      });

      if (!modal.open) return;

      timer.value = setTimeout(() => {
        close();
      }, modal.autoClose);
    };


    onMounted(() => {
      broadcastIO.addEventListener(BroadcastIOChannels.ALERT, onAlert);
    });

    onBeforeUnmount(() => {
      clearTimeout(timer.value);
      broadcastIO.removeEventListener(BroadcastIOChannels.ALERT, onAlert);
    });

    return {
      modal,
      modalRef,
      close,
      ConfirmTypes,
    };
  },
});
