import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerAdditionalDataDto from '../CustomerAdditionalDataDto';
import CustomerDisplayDto from '../CustomerDisplayDto';
import {ObjectType} from '@designeo/apibundle-js';


export const CustomDataDtoPropDef = {
  customerData: {
    type: new EntityType(CustomerAdditionalDataDto),
  },
  display: {
    type: new EntityType(CustomerDisplayDto),
  },
  workflowData: {
    type: new ObjectType(),
  },
  events: {
    type: new ObjectType(),
  },
  followUpDocument: {
    type: new ObjectType<{link: string}>(),
  },
};
export default class GeneratedCustomDataDto extends createEntity(CustomDataDtoPropDef) {}
