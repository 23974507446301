
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import ModalStockInStores from '@/Components/ModalStockInStores/ModalStockInStores.vue';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {
  WorkflowStepWarehouseOperationGoodsList,
} from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WorkflowStepWarehouseOperationGoodsList';
import {WorkflowStepEvents, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {IModalStockInStore} from '@/Components/ModalStockInStores/stockInStores';

export default defineComponent({
  components: {ModalStockInStores},
  setup() {
    const workflowStore = useWorkflowStore();
    const {workflowStep, currentWorkflow} = useStepTools<WorkflowStepWarehouseOperationGoodsList>(
      WorkflowStepTypes.WarehouseOperationGoodsList,
    );
    const stockInStoresRef = ref<IModalStockInStore | null>(null);

    const product = computed(() => {
      return workflowStep.value.updateArticle;
    });

    const open = computed(() => {
      if (!product.value) {
        return false;
      }

      return workflowStep.value.isCurrentStateStockInStores;
    });

    const inputBuffer = computed(() => {
      return workflowStep.value.searchString;
    });

    const onClose = async () => {
      await workflowStep.value.openPreviousState();
    };

    const onKeyboardInput = (key) => {
      workflowStore.onKeyboardInput({
        source: InputSource.KEYBOARD,
        keys: [key],
      });
    };

    const onStockInStoreSearch = () => {
      stockInStoresRef.value.searchStockInStore();
    };

    onMounted(() => {
      workflowStep.value.messageBus.addEventListener(
        `${WorkflowStepEvents.METHOD_EXECUTE}:searchStockInStore`,
        onStockInStoreSearch,
      );
    });

    onBeforeUnmount(() => {
      if (!currentWorkflow.value) {
        return;
      }

      workflowStep.value.messageBus.removeEventListener(
        `${WorkflowStepEvents.METHOD_EXECUTE}:searchStockInStore`,
        onStockInStoreSearch,
      );
    });

    return {
      stockInStoresRef,
      product,
      open,
      inputBuffer,
      onClose,
      onKeyboardInput,
    };
  },
});
