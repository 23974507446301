import {RegisterInputEvent} from './../../types';
import {PointsBurningFlow} from './../../PromoInteractionFlow/PointsBurningFlow';
import {
  RegisterActions,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {QuickCall} from '../../QuickCall/Classes/QuickCall';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.BURN_POINTS]: {
      [RegisterActions.INIT]: action(async ({
        previousState,
        clubCode,
      }) => {
        const availablePointsBurning = this.getAvailablePointsBurning(clubCode);
        if (availablePointsBurning) {
          this.state.promoFlow = await PointsBurningFlow.new(
            availablePointsBurning,
            previousState,
          );
          await this.persist();
        } else {
          await this.changeState(previousState);
        }
      }),
      [RegisterActions.CANCEL]: action(async () => {
        this.state.promoFlow.destroy();
      }),
    },
  };
}
