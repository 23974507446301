import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderPaymentType from '../OrderPaymentType';
import {DateTimeType} from '@designeo/apibundle-js';
import OrderPaymentOrigin from '../OrderPaymentOrigin';


export const OrderPaymentDtoPropDef = {
  id: {
    type: new StringType(),
  },
  eventId: {
    type: new StringType(),
  },
  total: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  paymentType: {
    type: new EntityType(OrderPaymentType),
  },
  paymentDate: {
    type: new DateTimeType(),
  },
  origin: {
    type: new EntityType(OrderPaymentOrigin),
  },
  paymentReferenceNumber: {
    type: new StringType(),
  },
};
export default class GeneratedOrderPaymentDto extends createEntity(OrderPaymentDtoPropDef) {}
