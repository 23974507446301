import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const OrderCancelResultDtoPropDef = {
  isOk: {
    type: new BooleanType(),
  },
};
export default class GeneratedOrderCancelResultDto extends createEntity(OrderCancelResultDtoPropDef) {}
