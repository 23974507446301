import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ErrorMessagePropDef = {
  errorCashierMessage: {
    type: new StringType(),
  },
  errorCustomerMessage: {
    type: new StringType(),
  },
};
export default class GeneratedErrorMessage extends createEntity(ErrorMessagePropDef) {}
