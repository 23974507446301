import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col flex-grow" }
const _hoisted_3 = { class: "flex flex-col flex-grow gap-4" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "mt-auto mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.interpolate(_ctx.workflowStep.messages.cardNumber, {cardNumber: _ctx.numberFormat(_ctx.entity.cardNumber)})), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.entity.username), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.balance) + ": ", 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.numberFormat(_ctx.entity.accountBalanceVirtual)) + " " + _toDisplayString(_ctx.workflowStep.messages.accountBalanceVirtual), 1)
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.kbMonth) + ": ", 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.numberFormat(_ctx.entity.kbMonthAmount)) + " / " + _toDisplayString(_ctx.numberFormat(_ctx.entity.kbMonthPlace)), 1)
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.kbYear) + ": ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.numberFormat(_ctx.entity.kbYearAmount)) + " / " + _toDisplayString(_ctx.numberFormat(_ctx.entity.kbYearPlace)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_WorkflowTransitions)
          ])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}