import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DiscountType from '../DiscountType';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const DocumentDiscountDtoPropDef = {
  order: {
    type: new NumberType(),
  },
  type: {
    type: new EntityType(DiscountType),
  },
  value: {
    type: new NumberType(),
  },
  reason: {
    type: new StringType(),
  },
  description: {
    type: new StringType(),
  },
  priceConditionSap: {
    type: new StringType(),
  },
  promoCode: {
    type: new StringType(),
  },
  percentage: {
    type: new NumberType(),
  },
  id: {
    type: new StringType(),
  },
  discountID: {
    type: new NumberType(),
  },
  discountReasonID: {
    type: new NumberType(),
  },
  discountReasonText: {
    type: new StringType(),
  },
  discountReasonCode: {
    type: new StringType(),
  },
  isDocumentDiscount: {
    type: new BooleanType(),
  },
  isRoundingDiscount: {
    type: new BooleanType(),
  },
};
export default class GeneratedDocumentDiscountDto extends createEntity(DocumentDiscountDtoPropDef) {}
