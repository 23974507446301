import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow flex flex-col gap-4" }
const _hoisted_3 = { class: "uppercase" }
const _hoisted_4 = { class: "uppercase" }
const _hoisted_5 = { class: "pt-10 text-center" }
const _hoisted_6 = { class: "flex-shrink text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(workflowStep.step.printA4), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(workflowStep.step.checkPriceAmounts), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AsyncButton, {
              class: "btn-outline-basic",
              onClick: () => workflowStep.printSaveBagNominals(true)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(workflowStep.step.repeatablePrint), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_WorkflowTransitions)
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}