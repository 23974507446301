import GeneratedSearchResultDto from './generated/SearchResultDto';

export default class SearchResultDto extends GeneratedSearchResultDto {
  get documentItem() {
    return this.article?.documentItem;
  }

  get documentLogisticItem() {
    return this.documentItem?.toDocumentLogisticItem();
  }
}
