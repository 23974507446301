import {RecoveryEvents} from '@/Modules/Core/types';
import {onBeforeUnmount, onMounted} from 'vue';
import {useRecoveryStore} from '@/Modules/Core/store/RecoveryStore';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';

export const useRecovery = () => {
  const recoverStore = useRecoveryStore();
  const helpStore = useHelpStore();

  const onFourEyesError = async (event: CustomEvent) => {
    const errorId = helpStore.getErrorId(event.detail);
    await helpStore.show(errorId);
  };

  onMounted(() => {
    recoverStore.addEventListener(RecoveryEvents.FOUR_EYES_ERROR, onFourEyesError);
  });

  onBeforeUnmount(() => {
    recoverStore.removeEventListener(RecoveryEvents.FOUR_EYES_ERROR, onFourEyesError);
  });
};
