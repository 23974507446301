/* eslint-disable max-len */
export enum HelpTypes {
  ARTICLE_CAN_NOT_BE_SOLD_IN_TRAINING_MODE = 'articleCanNotBeSoldInTrainingMode',
  ARTICLE_IS_NOT_PART_OF_ITEM_GROUP = 'articleIsNotPartOfItemGroup',
  ARTICLE_IS_TYPE_SET = 'articleIsTypeSet',
  CHECK_AND_CHARGE_ERROR = 'checkAndChargeError',
  DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT = 'duplicityErrorCheckAndChargeTypeLot',
  DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_VOUCHER_DISCOUNT = 'duplicityErrorCheckAndChargeTypeVoucherDiscount',
  FOUR_EYES_PROCESS = 'fourEyesProcess',
  FOUR_EYES_REQUEST = 'fourEyesRequest',
  FOUR_EYES_SAME_OPERATOR = 'fourEyesSameOperator',
  INVENTORY_INVALID_GROUP = 'inventoryInvalidGroup',
  OFFLINE_ERROR = 'offlineError',
  PIN_STORE_NEW_PINS_NOT_SAME = 'changePinStoreNewPinsNotSame',
  PIN_STORE_UNFILLED_FIELDS = 'changePinStoreUnfilledFields',
  RECEIPT_DOES_NOT_EXISTS = 'documentDoesntExists',
  REGISTER_STORE_EDIT_DISABLED = 'registerStoreEditDisabled',
  REGISTER_STORE_INVALID_LOGISTIC_CODE = 'registerStoreInvalidLogisticCode',
  REGISTER_STORE_INVALID_NOMINAL = 'registerStoreInvalidNominal',
  REGISTER_STORE_INVALID_PAYMENT_VALUE = 'registerStoreInvalidPaymentValue',
  REGISTER_STORE_INVALID_INVOICE_NUMBER = 'registerStoreInvalidInvoiceNumber',
  REGISTER_STORE_INVALID_PHONE_NUMBER = 'registerStoreInvalidPhoneNumber',
  REGISTER_STORE_INVALID_ROUNDING_ARTICLE = 'registerStoreInvalidRoundingArticle',
  REGISTER_STORE_MAX_PRICE = 'registerStoreMaxPrice',
  REGISTER_STORE_MAX_QUANTITY = 'registerStoreMaxQuantity',
  REGISTER_STORE_MIN_PRICE = 'registerStoreMinPrice',
  REGISTER_STORE_NO_CUSTOMERS_FOUND = 'registerStoreNoCustomersFound',
  REGISTER_STORE_NO_PRODUCTS_FOUND = 'registerStoreNoProductsFound',
  REGISTER_STORE_PAYMENT_OVERPAY = 'registerStorePaymentOverpay',
  REGISTER_STORE_PAYMENT_OVERPAY_MAX = 'registerStorePaymentOverpayMax',
  REGISTER_STORE_PAYMENT_OVERPAY_MIN = 'registerStorePaymentOverpayMin',
  REGISTER_STORE_PAYMENT_UNDERPAY = 'registerStorePaymentUnderpay',
  REGISTER_STORE_UNRETURNABLE = 'registerStoreUnreturnable',
  REGISTER_STORE_ZERO_PRICE = 'registerStoreZeroPrice',
  REGISTER_STORE_PRICE_CHANGE_NOT_ALLOWED = 'registerStorePriceChangeNotAllowed',
  REGISTER_STORE_DYNAMIC_SET_SELECTION_ABORTED = 'registerStoreDynamicSetSelectionAborted',
  REGISTER_STORE_DYNAMIC_SET_SELECTION_ARTICLE_NOT_FOUND = 'registerStoreDynamicSetSelectionArticleNotFound',
  REGISTER_STORE_DYNAMIC_SET_SELECTION_ARTICLE_QUANTITY_CAN_NOT_BE_CHANGED = 'registerStoreDynamicSetSelectionArticleQuantityCanNotBeChanged',
  REQUEST_TIMEOUT = 'requestTimeout',
  TRANSACTION_LIMIT_EXCEEDED = 'transactionLimitExceeded',
  PAYMENT_UNHANDLED_ERROR = 'paymentUnhandledError',
  UNKNOWN = 'unknown',
}

export default HelpTypes;
