import {fixDecimals, toInt} from '@/Helpers/math';
import {
  keys,
} from 'lodash-es';
import {DocumentLogisticItemDtoPropDef} from './generated/DocumentLogisticItemDto';
import {DocumentItemTypes} from '@/constants/documentItemTypes';
import {StockDto} from '@/Model/Entity';
import {createBatchKey} from '@/Helpers/batch';
import {
  createEntity,
  EntityType,
  NumberType,
  StringType,
} from '@designeo/apibundle-js';
import AssortmentInfoDto from '@/Model/Entity/AssortmentInfoDto';

export default class DocumentLogisticItemDto extends createEntity({
  /**
   * @property overrides, some fields will be FE only
   */
  ...DocumentLogisticItemDtoPropDef,
  assortmentInfo: {
    type: new EntityType(AssortmentInfoDto),
  },
  quantityStockOrdinary: {
    type: new NumberType(),
  },
  quantityStockExchange: {
    type: new NumberType(),
  },
  quantityStockOrdinaryAndExchange: {
    type: new NumberType(),
  },
  vatCode: {
    type: new StringType(),
  },
  vatAmount: {
    type: new NumberType(),
  },
}) {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  public static get fields() {
    return keys(new DocumentLogisticItemDto({}).$fields);
  }

  get isTypeService() {
    return this.itemType === DocumentItemTypes.service;
  }

  get unitByEnum() {
    return DocumentLogisticItemDto.configurationStore.unitsByCode.value?.[this.unit]?.text ?? this.unit;
  }

  get isValid() {
    const now = new Date();
    const validFrom = this.saleValidFrom ?? now;
    const validTill = this.saleValidTill ?? now;

    return validFrom <= now && now <= validTill;
  }

  // https://designeo.atlassian.net/wiki/spaces/POSIGNEO/pages/63504402/PDA+Skener#Chov%C3%A1n%C3%AD-p%C5%99i-skenov%C3%A1n%C3%AD-polo%C5%BEek---pokladna-vs.-PDA
  get canBeManipulated() {
    if (!this.isValid) {
      return false;
    }

    if (!this.assortmentInfo) {
      return true;
    }

    // Case 4
    if (!this.assortmentInfo.allowedByAssortmentGroup && this.assortmentInfo.deniedByAssortmentShopException) {
      if (this.quantityStockOrdinaryAndExchange !== 0 && this.quantityReal === 0) {
        return false;
      }

      return true;
    }

    // Case 3
    if (!this.assortmentInfo.allowedByAssortmentGroup && !this.assortmentInfo.deniedByAssortmentShopException) {
      if (this.quantityStockOrdinaryAndExchange !== 0 && this.quantityReal === 0) {
        return false;
      }

      return true;
    }

    // Case 2
    if (this.assortmentInfo.allowedByAssortmentGroup && this.assortmentInfo.deniedByAssortmentShopException) {
      return true;
    }

    // Case 1
    if (this.assortmentInfo.allowedByAssortmentGroup && !this.assortmentInfo.deniedByAssortmentShopException) {
      return true;
    }

    return true;
  }

  get image() {
    if (
      !DocumentLogisticItemDto.configurationStore.configuration.value.features?.articles?.displayArticleImage ||
      !this.imageId
    ) return null;

    const middlewareUrl = DocumentLogisticItemDto.configurationStore.configuration.value.general.middleware.url;
    return `${middlewareUrl}/sap-content-server/${this.imageId}`;
  }

  get internalNumberWithBatch() {
    return this.batch ? createBatchKey({internalNumber: this.internalNumber, batch: this.batch}) : this.internalNumber;
  }

  getStockByStockMap(map: {[key: string]: StockDto}) {
    return this.batch ?
      (map?.[createBatchKey({internalNumber: this.internalNumber, batch: this.batch})] ?? null) :
      (map?.[this.internalNumber] ?? null);
  }

  calculateInventoryProperties() {
    this.quantityStockOrdinaryAndExchange = this.quantityStockOrdinary + this.quantityStockExchange;
    this.quantityDifference = this.quantityReal - this.quantityStockOrdinaryAndExchange;
    this.valueDifference = fixDecimals(this.quantityDifference * (this.price || 0));

    /**
     * Backwards compatibility for PS template
     */
    this.quantityAccounting = this.quantityStockOrdinary;
    this.quantityCorrection = this.quantityStockExchange;
  }

  addQuantity(val: number | string) {
    const quantity = toInt(val) || 0;
    this.quantity = quantity;
  }

  get isQuantityEqualWithoutCorrection() {
    return this.quantityStockOrdinary === this.quantityReal;
  }

  toJson(internalNumberWithBatch = false) {
    return {
      ...this._data,
      ...(internalNumberWithBatch ? {internalNumberWithBatch: this.internalNumberWithBatch} : {}),
      unitByEnum: this.unitByEnum,
      isQuantityEqualWithoutCorrection: this.isQuantityEqualWithoutCorrection,
      isValid: this.isValid,
      canBeManipulated: this.canBeManipulated,
    };
  }
}
