import GeneratedCashStatesDto from './generated/CashStatesDto';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  flow,
  isNil,
  map,
  some,
  toPairs,
} from 'lodash-es';

export default class CashStatesDto extends GeneratedCashStatesDto {
  public static get configurationStore(): ReturnType<typeof useConfigurationStore> {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get cashLimits() {
    return CashStatesDto.configurationStore.configuration.value?.features?.cashLimits ?? {};
  }

  get hasExceededCashAmount() {
    return some(this.validatedCashAmounts, {exceeded: true});
  }

  get validatedCashAmounts(): Array<{currency: string, config: any, exceeded: boolean}> {
    return flow([
      toPairs,
      (val) => map(val, ([currency, config]) => {
        const maxAmountInPOS = config?.maxAmountInPOS ?? null;
        return {
          currency,
          config,
          exceeded: isNil(maxAmountInPOS) ? false : this.cash?.[currency.toLowerCase()]?.total > maxAmountInPOS,
        };
      }),
    ])(this.cashLimits);
  }
}
