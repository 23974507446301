import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const GroupRangeDtoPropDef = {
  from: {
    type: new StringType(),
  },
  to: {
    type: new StringType(),
  },
};
export default class GeneratedGroupRangeDto extends createEntity(GroupRangeDtoPropDef) {}
