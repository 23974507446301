import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,

} from '@/Modules/Register/types';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {action} from '@designeo/vue-helpers';
import DelaySellDataDtoCustom from '@/Model/Entity/custom/DelaySellDataDtoCustom';

export default function(this: RegisterStore): TransitionDefinition {
  const clearErrors = () => this.state.delayedSellData.errors = null;

  return {
    [RegisterState.ENTER_DELAYED_SELL_DATA]: {
      [RegisterActions.INIT]: action(async () => {
        this.state.delayedSellData = DelaySellDataDtoCustom.new(
          this.sellDocument.value.header.delayedSellDate,
          this.sellDocument.value.header.delayedSellNumber,
        );
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent<{field: string, value: any}>) => {
        if (event.value) {
          this.state.delayedSellData[event.value.field] = event.value.value;
          return;
        }

        if (!this.state.delayedSellData.validate({required: true})) {
          return;
        }

        const {delayedSellDate, delayedSellNumber} = this.state.delayedSellData.toDocumentDtoAttributes();

        this.sellDocument.value.header.isDelayedSell = true;
        this.sellDocument.value.header.delayedSellDate = delayedSellDate;
        this.sellDocument.value.header.delayedSellNumber = delayedSellNumber;

        await this.changeState(this.state.previousState);
        this.state.delayedSellData = null;
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent<string>) => {
        if (!this.state.delayedSellData.isActiveDelayedSellNumber) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] = event.value;
        clearErrors();
      }),
      [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] += event.value;
        clearErrors();
      }),
      [RegisterActions.ADD_CHAR]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] += event.value;
        clearErrors();
      }),
      [RegisterActions.ADD_MINUS]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] += event.value;
        clearErrors();
      }),
      [RegisterActions.ADD_PLUS]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] += event.value;
        clearErrors();
      }),
      [RegisterActions.ADD_PERIOD]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] += event.value;
        clearErrors();
      }),
      [RegisterActions.ADD_COMMA]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] += event.value;
        clearErrors();
      }),
      [RegisterActions.BACKSPACE]: action(async (event: RegisterInputEvent<string>) => {
        if (this.state.delayedSellData.isActiveDelayedSellDate) return;

        this.state.delayedSellData[this.state.delayedSellData.activeField] = this.state
          .delayedSellData[this.state.delayedSellData.activeField]
          .slice(0, this.state.delayedSellData[this.state.delayedSellData.activeField].length - 1);
        clearErrors();
      }),
      [RegisterActions.CANCEL]: action(async () => {
        this.state.delayedSellData = null;
        await this.changeState(this.state.previousState);
      }),
      /**
       * NTH
       */
      // [RegisterActions.PREV]: action(async (event: RegisterInputEvent<string>) => {
      //   const fieldOrder = this.state.delayedSellData.fieldOrder;
      //   const activeField = this.state.delayedSellData.activeField;
      //
      //   if (event.value) {
      //     this.state.delayedSellData.activeField = fieldOrder[fieldOrder.indexOf(event.value)];
      //     return;
      //   }
      //
      //   const index = fieldOrder.indexOf(activeField);
      //
      //   this.state.delayedSellData.activeField = index === 0 ?
      //     fieldOrder[fieldOrder.length - 1] :
      //     fieldOrder[index - 1];
      // }),
      [RegisterActions.NEXT]: action(async (event: RegisterInputEvent<string>) => {
        const fieldOrder = this.state.delayedSellData.fieldOrder;
        // const activeField = this.state.delayedSellData.activeField;

        if (event.value) {
          this.state.delayedSellData.activeField = fieldOrder[fieldOrder.indexOf(event.value)];
          return;
        }

        /**
         * NTH
         */
        // const index = fieldOrder.indexOf(activeField);
        //
        // this.state.delayedSellData.activeField = index === fieldOrder.length - 1 ?
        //   fieldOrder[0] :
        //   fieldOrder[index + 1];
      }),
    },
  };
}
