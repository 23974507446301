import {KeyPress, KeyPressWithTimestamp} from '../KeyboardBuffer';
import {
  TransformStream,
} from '../streams';

/**
 * Story of this transform:
 * Datawedge on android sends Unidentified key for STX and ETX.
 * On zebra devices ETX events can be fired out of order (their timestamps is before already processed keypress)
 * Sometimes it sends key presses as [STX, B, A, R, ETX, C, O, D, E]
 * when reordered by timestamp it becomes [STX, ETX, B, A, R, C, O, D, E]
 * This transform will move the ETX either to the end of the {delay} window, or just before the next STX:
 * [STX, B, A, R, C, O, D, E, ETX, STX, B, A, R, ...]
 * Other times it sends key presses as [STX, B, A, R, ETX, C, O, D, E]
 * when reordered by timestamp it becomes [STX, B, A, R, C, O, D, E, ETX]
 *
 *
 * In most cases it works fine, and ETX is at the end. We should not delay that case.
 * But I can't figure out how to distinguish between these two cases and normal case.
 * So I'm delaying all ETX events by {delay} milliseconds or until next STX.
 */

/**
 * The Unidentified key down and up events are absolutely unreliable. Lot of time it's not fired in the right order.
 * Sometimes it's not fired at all. This setup required configuring datawedge advanded data formatting:
 * - Send char "2"
 * - Send remaining
 * - Send char "3"
 * This is basically just STX DATA ETX, but it wouldn't datawedge if it wasn't fucked up.
 * Ctrl key is not pressed and it's not set on the keydown/up events, but browser kinda detect it's STX and ETX,
 * it's not firing keypress and input events, so if it's
 * - [keydown b] [keypress b] [keyup b] then it's b
 * - [keydown b] [keyup b] then it's STX
 * @param delay - maximum delay of out-of-order ETX, in milliseconds. It can be shorter if next STX is received.
 * @returns
 */
export function createAndroidPdaFixTransform(delay: number = 100) {
  let stxDown: KeyPress = null;
  let stxKeypressed = false;

  let etxDown: KeyPress = null;
  let etxKeypressed = false;

  let ctrlReceived = false;

  return new TransformStream<KeyPressWithTimestamp, KeyPress>({
    transform(key: KeyPressWithTimestamp, controller) {
      if (key.key === 'Unidentified') {
        return;
      }

      // We're doing this because zebra is not sending Ctrl,
      // let's disable the whole thing if we received ctrl at some point
      if (key.key === 'Control') {
        ctrlReceived = true;
      }

      if (key.key === 'b' && !ctrlReceived) {
        if (key.dir === 'down') {
          stxDown = key;
          stxKeypressed = false;
        } else if (key.dir === 'press') {
          stxKeypressed = true;
        } else if (key.dir === 'up') {
          // it's not sending Ctrl+B, just B, but if it's CTRL+B, press is not fired between down and up
          if (stxKeypressed) {
            // it's b
            if (stxDown) {
              controller.enqueue(stxDown);
            }
            controller.enqueue(key);
          } else {
            // it's ctrl+b
            controller.enqueue({
              'code': 'STX',
              'alt': false,
              'ctrl': false,
              'shift': false,
              'key': '',
              'source': 'scanner',
              'dir': 'down',
            });
          }
          stxKeypressed = false;
          stxDown = null;
        }
      } else if (key.key === 'c' && !ctrlReceived) {
        if (key.dir === 'down') {
          etxDown = key;
          etxKeypressed = false;
        } else if (key.dir === 'press') {
          etxKeypressed = true;
        } else if (key.dir === 'up') {
          // it's not sending Ctrl+C, just C, but if it's CTRL+C, press is not fired between down and up
          if (etxKeypressed) {
            // it's c
            if (etxDown) {
              controller.enqueue(etxDown);
            }
            controller.enqueue(key);
          } else {
            // it's ctrl+c
            controller.enqueue({
              'code': 'ETX',
              'alt': false,
              'ctrl': false,
              'shift': false,
              'key': '',
              'source': 'scanner',
              'dir': 'down',
            });
          }
          etxKeypressed = false;
          etxDown = null;
        }
      } else {
        if (key.dir !== 'press') {
          controller.enqueue(key);
        }
      }
    },
  });
}
