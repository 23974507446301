import {
  App,
} from 'vue';
import {createIncorrectRestart} from '@/createIncorrectRestart';
import {useIncorrectRestartStore} from '@/Modules/Core/store/IncorrectRestartStore';
import {Router} from 'vue-router';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {clearStorage, reloadApp} from '@/Helpers/app';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {IRestoredErrorRecord} from '@/Helpers/restoreMode';
import {RouterTree} from '@/ModuleSystem/routerTree';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
  ensureStartupClose: ()=> void
  ensureStartupOpen: ()=> Promise<void>
  router: Router
  isRestoreMode: boolean,
  restoredErrorRecord: IRestoredErrorRecord
  replicateRouterTree: ()=> RouterTree
}

export type RegisterTestHelpersContext = {
}

export async function registerIncorrectRestart(ctx: CoreRequiredContext) {
  const incorrectRestartStore = useIncorrectRestartStore();
  const configurationStore = useConfigurationStore();

  const {isSecondaryDisplay, currentRouterPath} = useSecondaryDisplay({router: ctx.router});

  if (ctx.isRestoreMode) {
    console.warn('[RestoreMode] incorrect restart bypassed');
    return {};
  }

  if (isSecondaryDisplay) {
    return {};
  }

  if (!incorrectRestartStore.isActive.value) {
    return {};
  }

  if (configurationStore.configuration.value?.isPosNotConfigured) { // WTF is this state? ==> sanitize
    await clearStorage();
    reloadApp();
    return {};
  }

  ctx.ensureStartupClose();

  const incorrectRestart = await createIncorrectRestart(ctx.replicateRouterTree());
  incorrectRestart.mount('#incorrectRestart');

  return new Promise((resolve) => {
    incorrectRestartStore.setCallback(resolve);
  })
    .then((path = currentRouterPath) => {
      incorrectRestart.unmount();

      return ctx.router.replace(ctx.router.resolve(path));
    })
    .then(() => ctx.ensureStartupOpen());
}
