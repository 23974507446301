import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {workflowStepMixinPayment} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinPayment';
import {Payment, PaymentEvents} from '@/Modules/Payment/payment';
import HelpTypes from '@/constants/helpTypes';

export class WorkflowStepPayment extends workflowStepMixinPayment(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.Payment;
  }

  get type() {
    return WorkflowStepPayment.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async processPayments() {
    while (this.nextUnfinishedPaymentRequest) {
      const paymentRequest = this.nextUnfinishedPaymentRequest;

      if (!paymentRequest) {
        return;
      }

      const payment = Payment.deserialize(paymentRequest.paymentData);

      const result = await payment
        .on(PaymentEvents.resultsReceived, async (results) => {
          await this.modifyPaymentRequest(paymentRequest.id, (currentPaymentRequest) => {
            return {
              ...currentPaymentRequest,
              paymentData: Payment.serialize(payment),
            };
          });
        })
        .on(PaymentEvents.triggerDocumentCreated, async (triggerDocument) => {
          await this.modifyPaymentRequest(paymentRequest.id, (currentPaymentRequest) => {
            return {
              ...currentPaymentRequest,
              triggerDocument: triggerDocument.clone().toJson(),
              paymentData: Payment.serialize(payment),
            };
          });
        })
        .process();

      if (!result.isSuccessful && !result.handled) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: HelpTypes.PAYMENT_UNHANDLED_ERROR,
          },
        }));
      }

      if (!result.isSuccessful) {
        throw new Error('Payment process failed');
      }
    }
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      await this.processPayments();

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
