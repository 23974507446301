import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {
  commonWfFieldValidations,
} from '@/Helpers/validations';

export class WorkflowStepEnterAmount extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterAmount;
  }

  get type() {
    return WorkflowStepEnterAmount.type;
  }

  get component() {
    return markRaw(require('./StepEnterAmount.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.amount]: yup.string()
        .test(commonWfFieldValidations(this.step.validations?.[WorkflowStepField.amount])),
    });
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.amount, (fieldActions) => ({
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
          if (
            this.data[WorkflowStepField.amount].includes('.') ||
            this.data[WorkflowStepField.amount][this.data[WorkflowStepField.amount].length - 1] === '-'
          ) {
            return;
          }
          fieldActions[WorkflowActions.ADD_COMMA](event.value);
        }),
        [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
          if (this.data[WorkflowStepField.amount].includes('.')) return;
          fieldActions[WorkflowActions.ADD_PERIOD](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ADD_MINUS]: action(() => {
          if (!this.data[WorkflowStepField.amount]?.length) {
            fieldActions[WorkflowActions.ADD_MINUS]();
          }
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;

          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
