import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col flex-grow m-4 px-4 gap-4"
}
const _hoisted_2 = { class: "text-2xl font-bold text-center uppercase" }
const _hoisted_3 = { class: "mx-auto pt-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "mx-auto font-bold"
}
const _hoisted_6 = { class: "mx-auto pt-4" }
const _hoisted_7 = {
  key: 0,
  class: "font-bold text-center whitespace-pre"
}
const _hoisted_8 = {
  key: 1,
  class: "font-bold text-center whitespace-pre"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ticket?.ticket?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.interpolate(_ctx.messages.ticket, {ticketCode: _ctx.ticket.ticket.ticketNumber})), 1),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.badge.class, "w-1/2 text-center font-bold py-4 px-6 mx-auto uppercase rounded-full"])
        }, _toDisplayString(_ctx.badge.label), 3),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.resolvedIconByTicketState,
            alt: "korunka-state-icon"
          }, null, 8, _hoisted_4)
        ]),
        (!_ctx.ticket.isCanceled && !_ctx.ticket.isNotEvaluated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.messages.win) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.ticket.ticket.amountWon)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.ticket.payoutAmountExceeded.branch && !_ctx.ticket.isPaidOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.messages.winAmountTooLargeBranch), 1))
            : _createCommentVNode("", true),
          (_ctx.ticket.payoutAmountExceeded.headOffice && !_ctx.ticket.isPaidOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.messages.winAmountTooLargeHeadOffice), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}