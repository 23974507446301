import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Position from '../Position';


export default class GeneratedAdClass extends createEntity({
  activeFrom: {
    type: new StringType(),
  },
  activeTill: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  displayTime: {
    type: new NumberType(),
  },
  mediaUrl: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  position: {
    type: new EntityType(Position),
  },
}) {}
