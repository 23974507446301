import {computed, getCurrentInstance} from 'vue';
import {useI18n} from 'vue-i18n';
import {getVueApp} from '@/Helpers/app';

export const useI18nLocale = () => {
  const i18nLocale = computed(() => {
    return getCurrentInstance() ?
      useI18n().locale.value :
      (getVueApp()?.config.globalProperties.i18nInstall.global.locale ?? 'en');
  });
  return {
    i18nLocale,
  };
};
