import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col flex-grow overflow-y-auto border-b border-basic-light" }
const _hoisted_3 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WarehouseOperationPlantList = _resolveComponent("WarehouseOperationPlantList")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HLInput, { field: _ctx.searchField }, {
            default: _withCtx(({value, error, isActive, setActive}) => [
              _createVNode(_component_FormInput, {
                error: error,
                errorOutput: 'modal',
                layout: 'col',
                size: 'sm',
                active: isActive,
                showLabel: false,
                onClick: ($event: any) => (setActive())
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value.value ?? '\xa0'), 1)
                ]),
                _: 2
              }, 1032, ["error", "active", "onClick"])
            ]),
            _: 1
          }, 8, ["field"]),
          _createVNode(_component_WarehouseOperationPlantList, {
            class: "max-h-0",
            list: _ctx.plants,
            onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPlantSelect($event)))
          }, null, 8, ["list"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_WorkflowTransitions, {
            nextDisabled: workflowStep.disabledNextStep
          }, null, 8, ["nextDisabled"])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}