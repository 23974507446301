import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  apiSearch,
  apiV1PosSafeBagVerify,
} from '@/Model/Action';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {emitTestEvent} from '@/Helpers/testEvent';

export class WorkflowStepSafeBagEnterBarcode extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagEnterBarcode;
  }

  get type() {
    return WorkflowStepSafeBagEnterBarcode.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagEnterBarcode.vue').default);
  }

  get disabledNextStep() {
    return !this.safeBagCode;
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.safeBagEnvelopeCode, () => '');
  }

  get safeBagCode() {
    return this.getFieldValue(WorkflowStepField.safeBagEnvelopeCode);
  }

  async fetchSafeBag() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const {safeBags} = sanitizeApiSearch(await apiSearch({params: {code: this.safeBagCode ?? ''}}));

      const [safeBag] = safeBags;

      if (!safeBag) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.INVALID_SAFE_BAG_CODE,
            value: this.safeBagCode,
          },
        }));
        return;
      }

      await apiV1PosSafeBagVerify({
        params: {
          code: this.safeBagCode ?? '',
        },
      });

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    } finally {
      emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:scannedSafeBagEnvelopeCode`);
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.safeBagEnvelopeCode, (fieldActions) => ({
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ENTER](event.value);
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.fetchSafeBag();
        }),
      })),
    };
  }
}
