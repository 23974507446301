import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinBalanceStock} from '../StepMixins/WorkflowStepMixinBalanceStock';
import {action} from '@designeo/vue-helpers';
import {isEmpty, map} from 'lodash-es';
import {DocumentDto, DocumentItemDto} from '@/Model/Entity';
import {sortByArray} from '../../../../Helpers/array';
import IMask from 'imask';

export class WorkflowStepBalanceStockEdit extends workflowStepMixinBalanceStock(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.BalanceStockEdit;
  }

  get type() {
    return WorkflowStepBalanceStockEdit.type;
  }

  get component() {
    return markRaw(require('./StepBalanceStockEdit.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    const schema = this.inputs.reduce((acc, input) => {
      const {field, originalCount} = input;

      const validation = yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .min(0 - originalCount);

      return {
        ...acc,
        [field]: validation,
      };
    }, {});

    return yup.object().shape({
      ...schema,
    });
  }

  get inputs() {
    const inputs = this.balanceStockItems.map(({documentItem, stock}) => {
      if (!documentItem || !stock) return null;

      const field = this.sanitizeFieldKey(documentItem.internalNumber);
      const currentFieldValue = parseInt(this.getFieldValue(field));

      return {
        description: documentItem.description,
        internalNumber: documentItem.internalNumber,
        field,
        currentFieldValue,
        originalCount: stock.count,
        unit: stock.unitByEnum,
        isNegative: documentItem.isNegative,
        result: {
          price: isNaN(currentFieldValue) ? 0 : (currentFieldValue * -1) * documentItem.priceNormal,
          stock: isNaN(currentFieldValue) ? stock.count : currentFieldValue + stock.count,
        },
      };
    }).filter(Boolean);
    return sortByArray(inputs, this.articlesOrder, 'internalNumber');
  }

  inputValueChanged(input: WorkflowStepBalanceStockEdit['inputs'][number]) {
    const fieldValue = this.getFieldValue(input.field);
    const fieldValueAsInt = parseInt(fieldValue);

    return !isNaN(fieldValueAsInt) && fieldValueAsInt !== 0;
  }

  processChangedArticles() {
    const changedArticlesInternalNumbers = this.inputs
      .filter((input) => this.inputValueChanged(input))
      .map((input) => input.internalNumber);

    const sellDocument: DocumentDto = DocumentDto.createEmptyStockBalancingDocument();

    changedArticlesInternalNumbers.forEach((internalNumber, index) => {
      const field = this.sanitizeFieldKey(internalNumber);

      const article: DocumentItemDto = this.articleListByInternalNumber?.[internalNumber].clone();

      sellDocument.addItem(article);

      const quantity = parseInt(this.getFieldValue(field)) * -1;

      const isArticleNegative = article.isNegative;

      const isQuantityNegative = quantity < 0;

      if (isArticleNegative) {
        article.setPriceNormalWithinContext(sellDocument, index, article.priceNormal * -1);
      }

      article.setQuantityWithinContext(sellDocument, index, quantity);
      article.setIsReturnWithinContext(sellDocument, index, isQuantityNegative);
      article.setIsNegativeWithinContext(sellDocument, index, isQuantityNegative);
      article.setCount();

      if (article.valueAfterDiscounts < 0 && this.step.customOriginalDocumentFiscalReference) {
        article.setOriginalDocumentFiscalReferenceWithinContext(
          sellDocument,
          index,
          this.step.customOriginalDocumentFiscalReference,
        );
      }
    });


    this.sellDocument = sellDocument;
  }

  async beforeEnter() {
    this.setArticlesOrder();
  }

  async beforeContinue() {
    this.processChangedArticles();
  }

  get transitions() {
    return {
      ...map(this.inputs, ({field, currentFieldValue}, index, array) => {
        const fields = array.map((item) => item.field);
        const currentFieldValueIsNegative = currentFieldValue < 0;

        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_CHAR](event.value);
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ADD_MINUS]: action(() => {
              if (!this.data[field]?.length) {
                fieldActions[WorkflowActions.ADD_MINUS]();
              }
            }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = fields[index + 1] ?? fields[index];

              if (fields.length - 1 === index) {
                if (!await this.validate()) return;
                this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
              } else {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                  detail: {
                    field: nextField,
                  },
                }));
              }
            }),
            ...this.createArrowMovementTransitions(fields, index),
          }), {
            mask: IMask.createMask({
              mask: Array.from({length: 6 + (currentFieldValueIsNegative ? 1 : 0)}, () => '*').join(),
            }),
          }),
        };
      }).reduce((acc, val) => ({...acc, ...val}), {}),
    };
  }
}
