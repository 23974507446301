
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import {
  WorkflowActions,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {apiCustomersGenerateAndSendPin} from '@/Model/Action';
import SendPinDto from '../../../../Model/Entity/SendPinDto';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {withAsyncModalInteractionResolve} from '@/Helpers/modal';

export default defineComponent({
  name: 'StepEnterCustomerPin',
  components: {
    WorkflowTransitions,
    AsyncButton,
    HLStep,
  },
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.EnterCustomerPin;
    const {workflowStep} = useStepTools(stepType);
    const coreStore = useCoreStore();

    const customerNumber = computed<string>(() => {
      // @ts-ignore
      return workflowStep.value.customerNumber;
    });

    const onCustomerPinEnter = async (event: CustomEvent) => {
      await withAsyncModalInteractionResolve(async () => {
        await workflowStore.onEventInput({
          type: WorkflowActions.ENTER,
          value: event.detail,
        });
      });
    };

    const generateNewPin = async () => {
      if (
        workflowStep.value.step.generateNewPinConfirm &&
          !await coreStore.confirm(workflowStep.value.step.generateNewPinConfirm)
      ) {
        return;
      }

      try {
        await apiCustomersGenerateAndSendPin({
          input: new SendPinDto({smsTemplate: workflowStep.value.step.generateNewPinTemplate}),
          params: {
            customerNumber: customerNumber.value,
          },
        });

        await coreStore.alert(workflowStep.value.step.generateNewPinSuccessfully);
      } catch (e) {
        console.error(e);
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      }
    };

    onMounted(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-pin-verification'});
      broadcastIO.addEventListener(BroadcastIOChannels.CUSTOMER_PIN_RESULT, onCustomerPinEnter);
    });

    onBeforeUnmount(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      broadcastIO.removeEventListener(BroadcastIOChannels.CUSTOMER_PIN_RESULT, onCustomerPinEnter);
    });

    return {
      stepType,
      WorkflowStepField,
      generateNewPin,
    };
  },
});
