import {
  ArrayType,
  createEntity,
  NumberType,
  ObjectType,
} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import AclSource from '../AclSource';
import Configured from '../Configured';
import {BooleanType} from '@designeo/apibundle-js';
import Language from '../Language';
import {StringType} from '@designeo/apibundle-js';
import Pos from '../Pos';
import PrintAndPayment from '../PrintAndPayment';


export default class GeneratedConfigurationGeneral extends createEntity({
  aclSource: {
    type: new EntityType(AclSource),
  },
  configured: {
    type: new EntityType(Configured),
  },
  isActive: {
    type: new BooleanType(),
  },
  language: {
    type: new EntityType(Language),
  },
  localCountryCode: {
    type: new StringType(),
  },
  localCurrency: {
    type: new StringType(),
  },
  localPhonePrefix: {
    type: new StringType(),
  },
  pos: {
    type: new EntityType(Pos),
  },
  printAndPayment: {
    type: new EntityType(PrintAndPayment),
  },
  roundingArticleId: {
    type: new StringType(),
  },
  middleware: {
    type: new ObjectType(),
  },
  oneTimeCodeTTL: {
    type: new NumberType(),
  },
  oneTimeCodeLength: {
    type: new NumberType(),
  },
  phoneMasks: {
    type: new ObjectType(),
  },
  defaultPhonePrefix: {
    type: new StringType(),
  },
  phoneRechargePrefix: {
    type: new StringType(),
  },
  loaderTimeout: {
    type: new NumberType(),
  },
  appVersion: {
    type: new ObjectType(),
  },
  cashRoundingStrategy: {
    type: new StringType(),
  },
  defaultConditionNormalPrice: {
    type: new StringType(),
  },
  defaultConditionActionPrice: {
    type: new StringType(),
  },
}) {}
