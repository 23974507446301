import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-grow flex flex-col items-center justify-center text-basic-dark"
}
const _hoisted_2 = { class: "text-sm w-56 text-center mt-3.5 font-bold uppercase text-xl" }
const _hoisted_3 = {
  key: 1,
  class: "flex-grow flex flex-col items-center justify-center text-basic-dark"
}
const _hoisted_4 = { class: "text-sm w-56 text-center mt-3.5" }
const _hoisted_5 = { class: "mt-3 text-sm" }
const _hoisted_6 = {
  key: 3,
  class: "flex-grow flex items-center justify-center text-basic-dark"
}
const _hoisted_7 = {
  key: 4,
  class: "absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center z-10"
}
const _hoisted_8 = { class: "flex flex-row items-center justify-between w-full h-10 px-4" }
const _hoisted_9 = { class: "ml-10 text-2xl text-secondary flex-grow" }
const _hoisted_10 = { class: "self-center flex flex-row items-center gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCube = _resolveComponent("IconCube")!
  const _component_WarehouseOperationGoodsVariations = _resolveComponent("WarehouseOperationGoodsVariations")!
  const _component_IconLoaderPulse = _resolveComponent("IconLoaderPulse")!
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_Button = _resolveComponent("Button")!
  const _component_IconStock = _resolveComponent("IconStock")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_HLGridPaginator = _resolveComponent("HLGridPaginator")!
  const _component_Qwerty = _resolveComponent("Qwerty")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_HLGrid = _resolveComponent("HLGrid")!

  return (_openBlock(), _createBlock(_component_HLGrid, {
    config: _ctx.gridConfig,
    model: _ctx.gridModel
  }, {
    default: _withCtx(({items}) => [
      _createVNode(_component_Modal, {
        ref: "modal",
        open: _ctx.isOpen,
        type: "full",
        height: "limited",
        contentClass: "py-2 px-3",
        innerContentClass: "flex flex-col flex-grow",
        silenceKeyboardBuffer: false,
        onClose: _ctx.close
      }, _createSlots({
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.searchProducts.title')), 1)
        ]),
        bodyWrapper: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["modal-body overflow-auto modal-body--limited relative flex flex-grow pt-1.5", {'h-screen-73-h': !_ctx.showKeyboard}])
          }, [
            (_ctx.loadFailed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.searchProducts.loadFailed')), 1)
                ]))
              : (!_ctx.hasMeetMinLength)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_IconCube),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.searchProducts.initInfo')), 1),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.searchProducts.queryMinLength', {
                  length: _ctx.minProductSearchLength
                })), 1)
                  ]))
                : (items.value.length && !(_ctx.innerIsLoading || _ctx.isRunning))
                  ? (_openBlock(), _createBlock(_component_WarehouseOperationGoodsVariations, {
                      key: 2,
                      products: _ctx.mapItems(items.value),
                      style: _normalizeStyle({maxHeight: _ctx.showKeyboard ? '328px' : 'none'}),
                      class: "flex-grow",
                      onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectItem($event.item)))
                    }, null, 8, ["products", "style"]))
                  : (!items.value.length && !(_ctx.innerIsLoading || _ctx.isRunning))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('general.noResults')), 1))
                    : _createCommentVNode("", true),
            (_ctx.innerIsLoading || _ctx.isRunning)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createVNode(_component_IconLoaderPulse, { class: "w-16 h-16 text-center self-center stroke-current" })
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        buttons: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_InputBuffer, {
                value: _ctx.inputBuffer,
                sanitizeDots: false,
                limit: 50
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Button, {
                class: "btn-outline-basic self-start",
                onClick: _ctx.toggleShowKeyboard
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`general.keyboard.${_ctx.showKeyboard ? 'hide' : 'show'}`)), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.stockActive && items.value.length)
                ? (_openBlock(), _createBlock(_component_AsyncButton, {
                    key: 0,
                    class: "btn-outline-basic self-start",
                    onClick: _ctx.hideStock
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IconStock),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.searchProducts.hideStock')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (items.value.length && _ctx.online)
                  ? (_openBlock(), _createBlock(_component_AsyncButton, {
                      key: 1,
                      class: "btn-outline-basic self-start",
                      test: 'toggleStock',
                      onClick: () => _ctx.fetchStock(_ctx.gridModel.loadedData.value)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_IconStock),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.searchProducts.loadStock')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
              _createVNode(_component_HLGridPaginator, null, {
                default: _withCtx(({
                nextPage,
                pageSize,
              }) => [
                  (items.value.length === pageSize)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "btn-outline-basic",
                        loading: _ctx.innerIsLoading || _ctx.isRunning,
                        onClick: nextPage
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('action.loadMore')), 1)
                        ]),
                        _: 2
                      }, 1032, ["loading", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ]),
        _: 2
      }, [
        (_ctx.showKeyboard)
          ? {
              name: "keyboard",
              fn: _withCtx(() => [
                _createVNode(_component_Qwerty, {
                  attachTo: null,
                  onClick: _ctx.onKeyboardInput,
                  onHide: _ctx.toggleShowKeyboard
                }, null, 8, ["onClick", "onHide"])
              ])
            }
          : undefined
      ]), 1032, ["open", "onClose"])
    ]),
    _: 1
  }, 8, ["config", "model"]))
}