
import {
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import IconCross from '@/Components/Icons/IconCross.vue';

export default defineComponent({
  name: 'FormInputErrorModal',
  components: {
    IconCross,
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: null,
      required: true,
    },
    message: {
      type: null,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, {emit}) {
    const onClose = () => {
      emit('close');
    };

    return {
      onClose,
    };
  },
});
