import {emitTestEvent} from '@/Helpers/testEvent';
import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {apiCustomersCardAvailable, apiSearch} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export class WorkflowStepEnterCustomerCard extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterCustomerCard;
  }

  get type() {
    return WorkflowStepEnterCustomerCard.type;
  }

  get component() {
    return markRaw(require('./StepEnterCustomerCard.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.cardNumber]: yup.string()
        .required()
        .test({
          name: 'invalidCardNumber',
          message: 'validations.string.invalidCardNumber',
          test: async (value: string, context) => {
            if (value === null) {
              return false;
            }

            if (this.stepFinished) {
              return true;
            }

            if (value === '') {
              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                detail: {
                  type: WorkflowStepErrors.INVALID_CARD_NUMBER,
                },
              }));
              return false;
            }

            try {
              this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
              await apiCustomersCardAvailable({
                params: {
                  customerCardNumber: value,
                },
              });

              emitTestEvent(TestEvent.CUSTOMER_CARD_AVAILABLE_VERIFIED);

              return true;
            } catch (e) {
              console.error(e);
              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                detail: {
                  value: e,
                },
              }));

              return false;
            } finally {
              this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
            }
          },
        }),
    });
  }

  get bypassCardNumber() {
    return this.getFieldValue(WorkflowStepField.bypassCardNumber, false);
  }

  async beforeEnter() {
    this.disabledNextStep = false;
    if (this.bypassCardNumber) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } else if (!this.getFieldValue(WorkflowStepField.cardNumber, null)) {
      this.dataSetter(WorkflowStepField.cardNumber, () => this.params.cardNumber ?? null);

      if (!this.params.cardNumber) {
        this.disabledNextStep = true;
      }
    }
  }

  async beforeContinue() {
    this.stepFinished = true;
  }

  get canBeReturnedTo() {
    return !this.bypassCardNumber;
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.cardNumber, (fieldActions) => ({
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<string>) => {
          try {
            this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
            const {documentCustomers} = sanitizeApiSearch(await apiSearch({
              params: {
                code: event.value,
              },
            }));

            this.stepFinished = false;

            if (!documentCustomers.length) {
              await fieldActions[WorkflowActions.ENTER]('');
            } else {
              await fieldActions[WorkflowActions.ENTER](documentCustomers[0].cardNumber);
            }

            this.disabledNextStep = this.hasValidationError;
          } catch (e) {
            console.error(e);
          } finally {
            emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:scannedCardNumber`);
            this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
          }
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
