import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';

export class WorkflowStepContinueWithoutCustomerCard extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ContinueWithoutCustomerCard;
  }

  get type() {
    return WorkflowStepContinueWithoutCustomerCard.type;
  }

  get component() {
    return markRaw(require('./StepContinueWithoutCustomerCard.vue').default);
  }

  async beforeEnter() {
    if (this.hasVisited(WorkflowStepTypes.EnterCustomerCard)) {
      this.dataSetter(WorkflowStepField.bypassCardNumber, () => null);
    } else if (this.params[WorkflowStepField.cardNumber]) {
      this.confirm();
    }
  }

  get disabledNextStep() {
    return (this.getFieldValue(WorkflowStepField.bypassCardNumber, null) ?? null) === null;
  }

  reject() {
    this.dataSetter(WorkflowStepField.bypassCardNumber, () => true);
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  confirm() {
    this.dataSetter(WorkflowStepField.bypassCardNumber, () => false);
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {};
  }
}
