import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentValidationCashier from '../DocumentValidationCashier';
import {ObjectType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const DocumentValidationPropDef = {
  operation: {
    type: new StringType(),
  },
  oneTimeCodeAuth: {
    type: new BooleanType(),
  },
  cashier: {
    type: new EntityType(DocumentValidationCashier),
  },
  data: {
    type: new ObjectType(),
  },
  date: {
    type: new DateTimeType(),
  },
};
export default class GeneratedDocumentValidation extends createEntity(DocumentValidationPropDef) {}
