import routes from './routes';
import LayoutCustomerExternal from '../../Layout/LayoutCustomerExternal.vue';
import {useKorunkaSyncTarget} from '@/Modules/CustomerExternal/syncTarget/KorunkaSyncTarget';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerCustomerExternal(ctx: RequiredContext) {
  await ctx.registerLayout('customerExternal', {
    path: '/',
    component: LayoutCustomerExternal,
    children: [],
  });

  await ctx.registerRoutes(routes, {
    layout: 'customerExternal',
  });

  // TODO: We need this because e2e secondary display (store needs to be constucted before sync), REFACTOR
  if (useSecondaryDisplay().isSecondaryDisplay) {
    useKorunkaSyncTarget();
  }

  return {};
}
