import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import Theme from '../Theme';


export default class GeneratedUiStyle extends createEntity({
  logo: {
    type: new StringType(),
  },
  theme: {
    type: new EntityType(Theme),
  },
  variables: {
    type: new ObjectType(),
  },
}) {}
