
import {
  defineComponent,
  PropType,
} from 'vue';
import CaretNavigator from './CaretNavigator.vue';
import FormInputMixin from '@/Components/FormInputMixin.vue';
import FormInputErrorModal from '@/Components/FormInputErrorModal.vue';

export default defineComponent({
  name: 'FormInput',
  components: {
    FormInputErrorModal,
    CaretNavigator,
  },
  mixins: [FormInputMixin],
  props: {
    labelUppercase: {
      type: Boolean,
      required: false,
      default: true,
    },
    labelFontBold: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    padding: {
      type: Boolean,
      required: false,
      default: true,
    },
    marginBottom: {
      type: Boolean,
      required: false,
      default: true,
    },
    layout: {
      type: String as PropType<'row' | 'col' | 'row-wide'>,
      required: false,
      default: 'row',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | '2xs'>,
      required: false,
      default: 'md',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
