import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ExpirationPredictionDto from '../ExpirationPredictionDto';


export const CustomerAccountExpirationPredictionDtoPropDef = {
  clubCode: {
    type: new StringType(),
  },
  predictions: {
    type: new ArrayType(new EntityType(ExpirationPredictionDto)),
  },
};
export default class GeneratedCustomerAccountExpirationPredictionDto extends createEntity(CustomerAccountExpirationPredictionDtoPropDef) {}
