import {RegisterState} from '@/Modules/Register/types';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';

const nextArticleRegularStateByCurrentState = (currentState: RegisterState) => {
  switch (currentState) {
  case RegisterState.ENTER_ARTICLE_NUMBER:
  case RegisterState.SELECT_PRODUCT_VARIATION:
  case RegisterState.PRODUCT_SEARCH:
    return RegisterState.VALIDATE_AGE_RESTRICTION;

  case RegisterState.VALIDATE_AGE_RESTRICTION:
    return RegisterState.ENTER_ARTICLE_QUANTITY;

  case RegisterState.ENTER_ARTICLE_QUANTITY:
    return RegisterState.ENTER_ARTICLE_PRICE;

  case RegisterState.ENTER_ARTICLE_PRICE:
    return RegisterState.RETURN_REASON_CODE;

  case RegisterState.RETURN_REASON_CODE:
    return RegisterState.ORIGINAL_DOCUMENT_REFERENCE;

  case RegisterState.ORIGINAL_DOCUMENT_REFERENCE:
    return RegisterState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE;

  case RegisterState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE:
    return RegisterState.ENTER_SERIAL_NUMBER;

  case RegisterState.ENTER_SERIAL_NUMBER:
    return RegisterState.ENTER_INVOICE_NUMBER;

  case RegisterState.ENTER_INVOICE_NUMBER:
    return RegisterState.ENTER_PHONE_NUMBER;

  case RegisterState.ENTER_PHONE_NUMBER:
    return RegisterState.PHONE_NUMBER_CONFIRM;

  case RegisterState.PHONE_NUMBER_CONFIRM:
    return null;
  }
};

export const resolveStepsForArticleTypeRegular = async function(
  this: RegisterStore,
  currentState: RegisterState,
  nextState = nextArticleRegularStateByCurrentState(currentState),
) {
  switch (nextState) {
  case RegisterState.VALIDATE_AGE_RESTRICTION:
    if (!this.state.sellDocument.itemHasConfirmedAgeRestriction(this.state.productFlow.product)) {
      await this.changeState(RegisterState.VALIDATE_AGE_RESTRICTION);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ENTER_ARTICLE_QUANTITY:
    if (this.state.productFlow.product.isReadOnly) {
      await this.changeState(RegisterState.READONLY_ARTICLE_SELECTED);
    } else if (this.state.productFlow.product.hasMandatoryFieldQuantity) {
      await this.changeState(RegisterState.ENTER_ARTICLE_QUANTITY);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ENTER_ARTICLE_PRICE:
    if (this.state.productFlow.product.hasMandatoryFieldPriceNormal) {
      await this.changeState(RegisterState.ENTER_ARTICLE_PRICE);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.RETURN_REASON_CODE:
    if (this.state.productFlow.product.hasMandatoryFieldReturnReasonCode) {
      await this.changeState(RegisterState.RETURN_REASON_CODE);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ORIGINAL_DOCUMENT_REFERENCE:
    if (this.state.productFlow.product.hasMandatoryFieldOriginalDocumentReference) {
      await this.changeState(RegisterState.ORIGINAL_DOCUMENT_REFERENCE);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE:
    if (this.state.productFlow.product.hasMandatoryFieldOriginalDocumentFiscalReference) {
      await this.changeState(RegisterState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ENTER_SERIAL_NUMBER:
    if (this.state.productFlow.product.hasMandatoryFieldSerialNo) {
      await this.changeState(RegisterState.ENTER_SERIAL_NUMBER);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ENTER_INVOICE_NUMBER:
    if (this.state.productFlow.product.hasMandatoryFieldInvoiceNo) {
      await this.changeState(RegisterState.ENTER_INVOICE_NUMBER);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.ENTER_PHONE_NUMBER:
    if (this.state.productFlow.product.hasMandatoryFieldPhoneNumber) {
      await this.changeState(RegisterState.ENTER_PHONE_NUMBER);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  case RegisterState.PHONE_NUMBER_CONFIRM:
    if (this.state.productFlow.product.hasMandatoryFieldPhoneNumber) {
      await this.changeState(RegisterState.PHONE_NUMBER_CONFIRM);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  default:
    await this.saveProduct();
    return;
  }
};

const nextArticleServiceTypeLotStateByCurrentState = function(this: RegisterStore, currentState: RegisterState) {
  switch (currentState) {
  case RegisterState.SELECT_CHECK_AND_CHARGE_LOT_VARIATION:
    return RegisterState.VALIDATE_AGE_RESTRICTION;
  case RegisterState.VALIDATE_AGE_RESTRICTION:
    return RegisterState.ENTER_LOGISTIC_CODE;
  case RegisterState.ENTER_LOGISTIC_CODE:
    return RegisterState.ENTER_VALIDATION_CODE;
  case RegisterState.ENTER_VALIDATION_CODE:
    return this.isReturnModeActive.value ? RegisterState.ENTER_ARTICLE_QUANTITY : RegisterState.ENTER_ARTICLE_PRICE;
  default:
    return nextArticleRegularStateByCurrentState(currentState);
  }
};

const nextArticleServiceTypeKorunkaStateByCurrentState = function(this: RegisterStore, currentState: RegisterState) {
  switch (currentState) {
  default:
    return nextArticleRegularStateByCurrentState(currentState);
  }
};

const resolveStepsForServiceTypeLot = async function(this: RegisterStore, currentState: RegisterState) {
  const nextState = nextArticleServiceTypeLotStateByCurrentState.call(this, currentState);

  switch (nextState) {
  case RegisterState.ENTER_LOGISTIC_CODE:
    if (this.state.productFlow.product.hasMandatoryFieldLogisticCode && !this.state.productFlow.product.logisticCode) {
      await this.changeState(RegisterState.ENTER_LOGISTIC_CODE);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;
  case RegisterState.ENTER_VALIDATION_CODE:
    if (this.state.productFlow.product.hasMandatoryFieldValidationCode) {
      await this.changeState(RegisterState.ENTER_VALIDATION_CODE);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;

  default:
    await resolveStepsForArticleTypeRegular.call(this, currentState, nextState);
    return;
  }
};

const resolveStepsForServiceTypeKorunka = async function(this: RegisterStore, currentState: RegisterState) {
  const nextState = nextArticleServiceTypeKorunkaStateByCurrentState.call(this, currentState);

  switch (nextState) {
  default:
    // await resolveStepsForArticleTypeRegular.call(this, currentState, nextState);
    await this.saveProduct();
    return;
  }
};

const nextArticleServiceTypePRStateByCurrentState = function(this: RegisterStore, currentState: RegisterState) {
  switch (currentState) {
  case RegisterState.ENTER_ARTICLE_NUMBER:
  case RegisterState.SELECT_PRODUCT_VARIATION:
  case RegisterState.PRODUCT_SEARCH:
    return RegisterState.VALIDATE_AGE_RESTRICTION;
  case RegisterState.VALIDATE_AGE_RESTRICTION:
    return RegisterState.FILL_IN_CUSTOMER_CARD;
  case RegisterState.FILL_IN_CUSTOMER_CARD:
    return RegisterState.FILL_IN_CUSTOMER_CARD_SEARCH;
  case RegisterState.FILL_IN_CUSTOMER_CARD_SEARCH:
    return RegisterState.ENTER_ARTICLE_PRICE;
  default:
    return nextArticleRegularStateByCurrentState(currentState);
  }
};

const nextArticleServiceTypeVoucherDiscountStateByCurrentState = function(
  this: RegisterStore,
  currentState: RegisterState,
) {
  switch (currentState) {
  case RegisterState.ENTER_ARTICLE_NUMBER:
  case RegisterState.SELECT_PRODUCT_VARIATION:
  case RegisterState.PRODUCT_SEARCH:
    return RegisterState.ENTER_SERIAL_NUMBER;

  case RegisterState.ENTER_SERIAL_NUMBER:
    return null;
  }
};

const resolveStepsForServiceTypePR = async function(this: RegisterStore, currentState: RegisterState) {
  const nextState = nextArticleServiceTypePRStateByCurrentState.call(this, currentState);
  switch (nextState) {
  case RegisterState.FILL_IN_CUSTOMER_CARD:
    if (!this.customer.value) {
      await this.changeState(RegisterState.FILL_IN_CUSTOMER_CARD);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;
  case RegisterState.FILL_IN_CUSTOMER_CARD_SEARCH:
    await this.transitionToNextStep({currentState: nextState});
    return;
  default:
    await resolveStepsForArticleTypeRegular.call(this, currentState, nextState);
    return;
  }
};

const resolveStepsForServiceTypeVoucherDiscount = async function(this: RegisterStore, currentState: RegisterState) {
  const nextState = nextArticleServiceTypeVoucherDiscountStateByCurrentState.call(this, currentState);
  switch (nextState) {
  case RegisterState.ENTER_SERIAL_NUMBER:
    if (this.state.productFlow.product.hasMandatoryFieldSerialNo && !this.state.productFlow.product.serialNo) {
      await this.changeState(RegisterState.ENTER_SERIAL_NUMBER);
    } else {
      await this.transitionToNextStep({currentState: nextState});
    }
    return;
  default:
    await this.saveProduct();
    return;
  }
};


export const resolveStepsForArticleTypeCheckAndCharge = async function(
  this: RegisterStore,
  currentState: RegisterState,
) {
  if (this.state.productFlow.product.isServiceTypeLot) {
    await resolveStepsForServiceTypeLot.call(this, currentState);
  } else if (this.state.productFlow.product.isServiceTypePOR) {
    await resolveStepsForServiceTypeLot.call(this, currentState);
  } else if (this.state.productFlow.product.isServiceTypePOSA) {
    await resolveStepsForServiceTypeLot.call(this, currentState);
  } else if (this.state.productFlow.product.isServiceTypeKorunka) {
    await resolveStepsForServiceTypeKorunka.call(this, currentState);
  } else if (this.state.productFlow.product.isServiceTypePR) {
    await resolveStepsForServiceTypePR.call(this, currentState);
  } else if (this.state.productFlow.product.isServiceTypeVoucherDiscount) {
    await resolveStepsForServiceTypeVoucherDiscount.call(this, currentState);
  }
};
