import GeneratedDocumentPaymentDto from './generated/DocumentPaymentDto';
import {nominals} from '@/Helpers/nominals';
import PaymentTypeClass from '@/Model/Entity/PaymentTypeClass';
import ExchangeRate from '@/Model/Entity/ExchangeRate';
import {DocumentPaymentDtoPatch} from '@/Model/Entity/patches/DocumentPaymentDto';

export default class DocumentPaymentDto extends DocumentPaymentDtoPatch(GeneratedDocumentPaymentDto) {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get acceptableNominals() {
    return this.configurationStore.currenciesByCurrencySymbol.value[this.currency].acceptableNominals;
  }

  get type(): PaymentTypeClass {
    return this.configurationStore.paymentTypesByPaymentId.value[this.paymentID];
  }

  get isInLocalCurrency() {
    return this.currency === this.configurationStore.localCurrency.value.symbol;
  }

  get code() {
    return this.configurationStore.paymentButtonsByPaymentId.value[this.paymentID]?.code;
  }

  get exchangeRateToLocalCurrency(): ExchangeRate {
    const exchangeRateKey = `${this.currency}:${this.configurationStore.localCurrency.value.symbol}`;
    return this.configurationStore.exchangeRatesByCurrencies.value[exchangeRateKey];
  }

  get hasPendingSynchronization() {
    if (this.isValidated) {
      return false;
    }

    return !!this.verifyDocumentId;
  }

  exchangeToLocalCurrency(value) {
    if (this.isInLocalCurrency) {
      return value;
    }

    return this.exchangeRateToLocalCurrency.exchangeToLocalCurrency(value);
  }

  exchangeFromLocalCurrency(value) {
    if (this.isInLocalCurrency) {
      return value;
    }

    return this.exchangeRateToLocalCurrency.exchangeFromLocalCurrency(value);
  }

  isValidDecomposableValue(val: number) {
    return !(this.type.rounding && nominals(val, this.acceptableNominals).reminder !== 0);
  }

  get valueByCurrency() {
    return this.isInLocalCurrency ? this.value : this.valueinFCurrency;
  }

  setValue(value: number) {
    if (this.isInLocalCurrency) {
      this.value = value;
    } else {
      const {baseAmount, quoteAmount} = this.exchangeRateToLocalCurrency;

      this.valueinFCurrency = value;
      this.value = this.exchangeToLocalCurrency(value);
      this.exchangeRate = quoteAmount / baseAmount;
    }
  }
}
