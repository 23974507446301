import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ChangeOrderStatusHeaderDto from '../ChangeOrderStatusHeaderDto';
import {StringType} from '@designeo/apibundle-js';


export const CancelOrderDtoPropDef = {
  header: {
    type: new EntityType(ChangeOrderStatusHeaderDto),
  },
  cancelReason: {
    type: new StringType(),
  },
  cancelReasonText: {
    type: new StringType(),
  },
  cancelReasonNote: {
    type: new StringType(),
  },
};
export default class GeneratedCancelOrderDto extends createEntity(CancelOrderDtoPropDef) {}
