import {
  flatMap,
  flatten,
  flow,
  intersection,
  join,
  map,
  mapKeys,
  orderBy,
  sumBy,
} from 'lodash-es';
import GeneratedDocumentCustomerDto from './generated/DocumentCustomerDto';
import {VOID_GUID} from '@/constants/guid';
import {DateTime} from 'luxon';
import CustomerDto from '@/Model/Entity/CustomerDto';
import CustomerAccountExpirationPredictionDto from '@/Model/Entity/CustomerAccountExpirationPredictionDto';
import CustomerAccountDto from './CustomerAccountDto';

export default class DocumentCustomerDto extends GeneratedDocumentCustomerDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  set authenticated(val: boolean) {
    // @ts-ignore
    this._data.authenticated = val;
  }

  get authenticated(): boolean {
    // @ts-ignore
    return this._data.authenticated;
  }

  get isFetched() {
    return this.id !== VOID_GUID;
  }

  get name() {
    return join([this.firstName, this.lastName], ' ');
  }

  get birthDate() {
    return new Date(this.yearOfBirth, this.monthOfBirth, this.dayOfBirth);
  }

  get clubs() {
    return flatMap(this.accounts, 'club');
  }

  get accountsByClubCode() {
    return mapKeys(this.accounts ?? [], 'club.code');
  }

  get lastFiveTransactions() {
    return map(this.accounts, 'lastTransactionDate');
  }

  get totalPoints() {
    return sumBy(this.accounts, 'points');
  }

  get totalPointsByMainClubCode() {
    const mainClubCode = DocumentCustomerDto.configurationStore.mainClubCode.value;

    return this.accountsByClubCode?.[mainClubCode]?.points ?? 0;
  }

  get lastTransactionDayDiff() {
    if (!this.lastTransactionDate?.getMilliseconds?.()) return null;

    return Math.max(Math.floor(
      DateTime
        .fromJSDate(this.lastTransactionDate)
        .diffNow('days')
        .negate()
        .days,
    ), 0);
  }

  get accountByMainClubCode() {
    const mainClubCode = DocumentCustomerDto.configurationStore.mainClubCode.value;

    return this.accountsByClubCode?.[mainClubCode] ?? null;
  }

  get pointsHistoryByClubCode() {
    return mapKeys(this.pointsHistory, 'clubCode');
  }

  get pointsHistoryByMainClubCode() {
    const mainClubCode = DocumentCustomerDto.configurationStore.mainClubCode.value;

    return this.pointsHistoryByClubCode?.[mainClubCode] ?? null;
  }


  get pointsExpirationPredictionsByClubCode() {
    return mapKeys(this.pointsExpirationPredictions, 'clubCode');
  }

  get pointsExpirationPredictionByMainClubCode(): CustomerAccountExpirationPredictionDto {
    const mainClubCode = DocumentCustomerDto.configurationStore.mainClubCode.value;

    return this.pointsExpirationPredictionsByClubCode?.[mainClubCode] ?? null;
  }

  get currentMonthPointsExpirationPredictionByMainClubCode() {
    return this.pointsExpirationPredictionByMainClubCode?.predictions?.[0] ?? null;
  }

  get lastPointsExpirationsByMainClubCode() {
    return mapKeys(this.previousMonthPointsExpirations, 'clubCode');
  }

  latestTransactionsByClubCodes(clubCodes = Object.keys(this.accountsByClubCode)) {
    const accountsByClubCode = this.accountsByClubCode;

    return flow([
      (clubCodes) => map(clubCodes, (clubCode) => accountsByClubCode[clubCode]?.latestTransactions ?? []),
      (latestTransactions) => flatten(latestTransactions),
      (latestTransactions) => orderBy(latestTransactions, 'transactionDate', 'desc'),
    ])(clubCodes);
  }

  get lastPointsExpirationByMainClubCode() {
    const mainClubCode = DocumentCustomerDto.configurationStore.mainClubCode.value;

    return this.lastPointsExpirationsByMainClubCode?.[mainClubCode] ?? null;
  }
  get customerCards() {
    return this.isFetched ? map(this.cards, 'cardNumber') : [this.cardNumber];
  }

  isSameCustomer(customer: DocumentCustomerDto | CustomerDto) {
    const oldCustomerCards = this.customerCards;
    const newCustomerCards = customer instanceof DocumentCustomerDto && customer.isFetched ?
      map(customer.cards, 'cardNumber') :
      [customer.cardNumber];

    if (!oldCustomerCards.length || !newCustomerCards.length) {
      return this.customerNumber === customer.customerNumber;
    }

    return !!intersection(oldCustomerCards, newCustomerCards).length;
  }

  get numberIdentification() {
    if (this.isFetched) {
      return this.customerNumber;
    }

    return this.cardNumber;
  }

  toStringWithCustomerNumber() {
    return `${this.name} (${this.customerNumber})`;
  }
}
