import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';

export class WorkflowStepInvalidConfiguration extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.InvalidConfiguration;
  }

  get type() {
    return WorkflowStepInvalidConfiguration.type;
  }

  get component() {
    return markRaw(require('./StepInvalidConfiguration.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/invalidConfiguration/Layout.vue').default);
  }

  get transitions() {
    return {};
  }
}
