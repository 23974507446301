
import {
  defineComponent,
} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  name: 'StepGenericSuccess',
  components: {
    HLStep,
    WorkflowTransitions,
    AsyncButton,
  },
  setup() {
    const stepType = WorkflowStepTypes.GenericSuccess;

    return {
      stepType,
    };
  },
});
