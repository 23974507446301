import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Error from '../Error';


export const ErrorResponsePropDef = {
  identifier: {
    type: new StringType(),
  },
  errors: {
    type: new ArrayType(new EntityType(Error)),
  },
};
export default class GeneratedErrorResponse extends createEntity(ErrorResponsePropDef) {}
