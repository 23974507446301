import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ORIGINAL_DOCUMENT_REFERENCE]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(() => {
        this.state.inputBuffer = this.state.productFlow.product.originalDocumentReference ?? '';
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.inputBuffer) return;

        this.state.productFlow.product.setStornoReferenceWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          this.state.inputBuffer,
        );

        await this.transitionToNextStep();
      }),
    },
  };
}
