import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import EshopDto from '../EshopDto';
import {NumberType} from '@designeo/apibundle-js';
import OrderStatus from '../OrderStatus';
import {BooleanType} from '@designeo/apibundle-js';
import OrderCustomerDto from '../OrderCustomerDto';
import {ArrayType} from '@designeo/apibundle-js';
import OrderItemDto from '../OrderItemDto';
import OrderEventDto from '../OrderEventDto';
import OrderPaymentDto from '../OrderPaymentDto';
import {DateTimeType} from '@designeo/apibundle-js';


export const OrderDtoPropDef = {
  id: {
    type: new StringType(),
  },
  eshop: {
    type: new EntityType(EshopDto),
  },
  orderNumber: {
    type: new StringType(),
  },
  totalPrice: {
    type: new NumberType(),
  },
  totalPriceWithoutVat: {
    type: new NumberType(),
  },
  currencyCode: {
    type: new StringType(),
  },
  statusCode: {
    type: new EntityType(OrderStatus),
  },
  statusReason: {
    type: new StringType(),
  },
  statusReasonText: {
    type: new StringType(),
  },
  statusReasonNote: {
    type: new StringType(),
  },
  isPaid: {
    type: new BooleanType(),
  },
  customer: {
    type: new EntityType(OrderCustomerDto),
  },
  items: {
    type: new ArrayType(new EntityType(OrderItemDto)),
  },
  events: {
    type: new ArrayType(new EntityType(OrderEventDto)),
  },
  payments: {
    type: new ArrayType(new EntityType(OrderPaymentDto)),
  },
  orderDate: {
    type: new DateTimeType(),
  },
  orderEditedDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedOrderDto extends createEntity(OrderDtoPropDef) {}
