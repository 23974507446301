import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerDisplayDtoPropDef = {
  displayLine1: {
    type: new StringType(),
  },
  displayLine2: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerDisplayDto extends createEntity(CustomerDisplayDtoPropDef) {}
