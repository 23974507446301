import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
  RegisterStoreEvent,

} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {DocumentCustomerDto} from '@/Model/Entity';
import {useCustomerSearchTransitions} from '@/Modules/Register/store/transitions/customerSearch';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.FILL_IN_CUSTOMER_CARD_SEARCH]: {
      ...useCustomerSearchTransitions.call(this),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (event.value) {
          const added = await this.addCustomer(
            new DocumentCustomerDto({customerNumber: event.value.customerNumber}),
            'customerNumber',
          );

          if (!added) {
            return;
          }

          if (this.state.previousState === RegisterState.FILL_IN_CUSTOMER_CARD) {
            await this.changeState(this.state.previousState);
          } else {
            await this.transitionToNextStep();
          }
        } else {
          this.dispatchEvent(new Event(RegisterStoreEvent.SEARCH_CUSTOMER));
        }
      }),
      [RegisterActions.CANCEL]: action(async () => {
        await this.transitionToNextStep();
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        try {
          const {documentCustomers} = await this.searchSubject(event.value);

          if (!documentCustomers.length) return;

          const inputMode = this.state.productFlow.inputMode;
          this.state.productFlow.inputMode = InputSource.SCANNER;

          try {
            const added = await this.addCustomer(documentCustomers[0], 'cardNumber', {
              skipVerificationCheck: true,
              unassignedCardProcess: false,
            });

            if (!added) {
              return;
            }
          } finally {
            this.state.productFlow.inputMode = inputMode;
          }

          if (this.state.previousState === RegisterState.FILL_IN_CUSTOMER_CARD) {
            await this.changeState(this.state.previousState);
          } else {
            await this.transitionToNextStep();
          }
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
    },
  };
}
