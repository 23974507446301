import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedPaymentButtonStyle extends createEntity({
  backgroundColor: {
    type: new StringType(),
  },
  textColor: {
    type: new StringType(),
  },
  fontSize: {
    type: new StringType(),
  },
}) {}
