
import {
  defineComponent,
  PropType,
} from 'vue';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepTypes} from '../types';

export default defineComponent({
  name: 'TechnicalStep',
  components: {HLStep},
  props: {
    stepType: {
      type: String as PropType<WorkflowStepTypes>,
      required: true,
    },
  },
});
