import {Constructor} from '@/Model/Entity/patches/patch';
import {EntityType} from '@designeo/apibundle-js';
import Transaction from '../Transaction';

export const TransactionItemPatch = <TBase extends Constructor>(superClass: TBase) =>
  class TransactionItemPatch extends superClass {
    get transaction(): Transaction {
      // @ts-ignore
      return new EntityType(Transaction).parse(this._data.transaction);
    }
    set transaction(val: Transaction) {
      // @ts-ignore
      this._data.transaction = new EntityType(Transaction).format(val);
    }
  };
