import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const ProblemDetailsPropDef = {
  type: {
    type: new StringType(),
  },
  title: {
    type: new StringType(),
  },
  status: {
    type: new NumberType(),
  },
  detail: {
    type: new StringType(),
  },
  instance: {
    type: new StringType(),
  },
};
export default class GeneratedProblemDetails extends createEntity(ProblemDetailsPropDef) {}
