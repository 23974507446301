
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFilters} from '@/Helpers/filters';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {DocumentItemDto} from '@/Model/Entity';
import {sumLotteryFields} from '@/Helpers/lotteryFields';

export default defineComponent({
  props: {
    article: {
      type: Object as PropType<DocumentItemDto>,
      required: true,
    },
  },
  setup(props) {
    const stepType = WorkflowStepTypes.EnterLotteryOverview;

    const configurationStore = useConfigurationStore();
    const {currencyFormat} = useFilters();
    const {workflowStep}: {workflowStep} = useStepTools(stepType);

    const fieldValue = computed(() => {
      return sumLotteryFields(workflowStep.value.getFieldValue(
        workflowStep.value.getFieldKeyByInternalNumber(props.article.internalNumber, true),
      ));
    });

    const quantity = computed(() => {
      return workflowStep.value.lotteryTurnovers.getArticleQuantity(props.article, fieldValue.value);
    });

    const totalAmount = computed(() => {
      return workflowStep.value.lotteryTurnovers.getArticleTotalAmount(props.article);
    });

    const correctionPrice = computed(() => {
      return workflowStep.value.lotteryTurnovers.getArticleCorrection(props.article, fieldValue.value);
    });

    return {
      quantity,
      totalAmount,
      currencyFormat,
      correctionPrice,
      configurationStore,
      workflowStep,
    };
  },
});
