import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {WorkflowStepNominalsCount} from '@/Modules/Workflow/Step/StepNominalsCount/WorkflowStepNominalsCount';
import {toUpper} from 'lodash-es';

export class WorkflowStepSafeBagEnterNominalsCount extends WorkflowStepNominalsCount {
  static get type() {
    return WorkflowStepTypes.SafeBagEnterNominalsCount;
  }

  get type() {
    return WorkflowStepSafeBagEnterNominalsCount.type;
  }

  get minValueValidationValue() {
    return this.configurationStore.configuration.value
      ?.features
      ?.cashLimits
      ?.[toUpper(this.step.currency)]
      ?.minAmountInSafebag;
  }
}
