import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {
  apiDocumentGet,
  apiDocumentRepeatablePrinting,
  apiFinancialCloseDayCreate,
  apiStockGetCashState,
} from '@/Model/Action';
import {submitJournalEventFinancialCloseDay} from '@/Helpers/journal';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {
  CashDataDto,
  CashStatesDto,
  DocumentDto,
  DocumentFinItemDto,
  RepeatablePrintingCommand,
} from '@/Model/Entity';
import {workflowStepMixinSaveFinDocument} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinSaveFinDocument';
import {
  flatMap,
  flow,
  groupBy,
  toUpper,
} from 'lodash-es';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

type TransferTransaction = {fromCashDrawerToSafe?: string, fromSafeToCashDrawer?: string};
type TransferTransactions = {[key: string]: TransferTransaction};

type QueueItem = {
  queueStage: QueueStages,
  metadata: {[key: string]: any},
  displayed: boolean,
  print: string,
  finished: boolean,
  error: string,
}

type Queue = Array<QueueItem>
enum QueueStages {
  fromCashDrawerToSafe = 'fromCashDrawerToSafe',
  financialClosure = 'financialClosure',
  fromSafeToCashDrawer = 'fromSafeToCashDrawer',
}

export class WorkflowStepCreateFinancialReport extends workflowStepMixinSaveFinDocument(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.CreateFinancialReport;
  }

  get type() {
    return WorkflowStepCreateFinancialReport.type;
  }

  get component() {
    return markRaw(require('./StepCreateFinancialReport.vue').default);
  }

  async beforeEnter() {
    if (this.stepInit) return;
  }

  get transferTransactions(): TransferTransactions {
    return this.step.transferTransactions ?? {};
  }

  getFinancialDocumentByTransactionCodeAndAmount(transactionCode, amount) {
    const transaction = this.configurationStore.financialTransactionsByCode.value[transactionCode];

    const payment = this.configurationStore.createPayment(transaction.paymentId);

    payment.setValue(amount);

    const document = DocumentDto.createFinancialDocument({
      documentType: transaction.transactionType,
      documentSubType: transaction.transactionSubType,
      header: {
        finDocumentCode: transaction.code,
        finDocumentName: transaction.name,
        finDocumentTransactionNumber: transaction.number,
        total: payment.value,
        currency: transaction.currency,
        source: transaction.transactionSource,
        destination: transaction.transactionDestination,
        sapTransactionCode: transaction.sapTransactionCode,
      },
    });

    document.addPayment(payment, {refreshTotalPrice: false});

    if (amount) {
      document.finItems = [
        new DocumentFinItemDto({
          valueBeforeDiscounts: amount,
          quantity: 1,
        }),
      ];
    }

    return document;
  }

  async getCashStateByCurrency() {
    try {
      this.messageBus.dispatchEvent(new CustomEvent(AppLoaderEvent.ON, {
        detail: {
          timeout: null,
        },
      }));

      return await apiStockGetCashState();
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));

      throw e;
    } finally {
      this.messageBus.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  }

  set financialReportId(val: string) {
    this.dataSetter(WorkflowStepField.document, () => val);
  }

  get financialReportId() {
    return this.getFieldValue(WorkflowStepField.document, null);
  }

  async createFinancialCloseDay() {
    try {
      this.messageBus.dispatchEvent(new CustomEvent(AppLoaderEvent.ON, {
        detail: {
          timeout: null,
        },
      }));

      const {notificationsConnection} = useSignalR();

      let financialReport: DocumentDto = this.financialReportId ?
        new DocumentDto({header: {uniqueidentifier: this.financialReportId}}) :
        null;

      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== financialReport.header.uniqueidentifier) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
        {timeout: 1000 * 60 * 5},
      )(async () => {
        try {
          if (this.financialReportId) {
            let transaction: Awaited<ReturnType<typeof apiDocumentGet>> = null;
            try {
              transaction = await apiDocumentGet({
                params: {
                  id: this.financialReportId,
                },
              });
            } catch (e) {
              if (e?.response?.status === 404) {
                financialReport = await apiFinancialCloseDayCreate();

                this.financialReportId = financialReport.header.uniqueidentifier;

                await this.workflowStore.persist();

                return financialReport;
              }

              console.error(e);

              throw e;
            }

            const [
              {
                target: printoutType,
                outputType,
                templateCode,
              },
            ] = transaction.result.output;

            return await apiDocumentRepeatablePrinting({
              input: new RepeatablePrintingCommand({
                uniqueidentifier: financialReport.header.uniqueidentifier,
                printoutType: printoutType?.value,
                outputType: outputType?.value,
                templateCode: templateCode,
                copies: 1,
              }),
            });
          }

          financialReport = await apiFinancialCloseDayCreate();

          this.financialReportId = financialReport.header.uniqueidentifier;

          await this.workflowStore.persist();

          return financialReport;
        } catch (e) {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
            detail: {
              type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
              value: e,
            },
          }));

          throw e;
        }
      });

      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      submitJournalEventFinancialCloseDay();

      return result;
    } catch (e) {
      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async createTransferToNextDay(finDocument: DocumentDto) {
    let finDocumentResult: Awaited<ReturnType<WorkflowStepCreateFinancialReport['saveFinDocument']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      finDocumentResult = await this.saveFinDocument(finDocument);

      if (!finDocumentResult.result && finDocumentResult.error) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: finDocumentResult.error,
          },
        }));
      }

      if (!finDocumentResult.mandatory && finDocumentResult.created && !finDocumentResult.printed) {
        return null;
      }

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      finDocumentResult.result = new PrinterResult(finDocumentResult.result ?? {});

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }

      return finDocumentResult.result;
    } catch (e) {
      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  createQueueItem(stage: QueueStages, metadata = {}): QueueItem {
    return {
      queueStage: stage,
      metadata: metadata,
      displayed: false,
      print: null,
      finished: false,
      error: null,
    };
  }

  async createQueue(): Promise<Queue> {
    const cashState = await this.getCashStateByCurrency();
    return flow([
      (val: CashStatesDto) => val.cash.toJson(),
      (val: CashStatesDto['cash']['_data']) => flatMap(val, (data: CashDataDto, key) => {
        if (!this.transferTransactions[toUpper(key)]) {
          return [];
        }

        if (data.total === 0) {
          return [];
        }

        const {
          fromCashDrawerToSafe,
          fromSafeToCashDrawer,
        } = this.transferTransactions[toUpper(key)];

        const result = [];

        if (fromCashDrawerToSafe) {
          result.push(this.createQueueItem(QueueStages.fromCashDrawerToSafe, {
            amount: data.total,
            transaction: fromCashDrawerToSafe,
          }));
        }

        if (fromSafeToCashDrawer) {
          result.push(this.createQueueItem(QueueStages.fromSafeToCashDrawer, {
            amount: data.total,
            transaction: fromSafeToCashDrawer,
          }));
        }

        return result;
      }),
      (val: Queue) => [this.createQueueItem(QueueStages.financialClosure)].concat(val),
    ])(cashState) as Queue;
  }

  get queue() {
    return this.getFieldValue('queue', null);
  }

  set queue(queue: Queue) {
    this.dataSetter('queue', () => queue);
  }

  persistQueue(queue: Queue) {
    this.queue = queue;
  }

  async ensureQueue(): Promise<Queue> {
    let queue = this.queue;

    if (!queue) {
      queue = await this.createQueue();
      this.persistQueue(queue);
    }

    return queue;
  }

  async executeQueueItem(queueItem: QueueItem) {
    switch (queueItem.queueStage) {
    case QueueStages.fromCashDrawerToSafe:
      return await this.createTransferToNextDay(
        this.getFinancialDocumentByTransactionCodeAndAmount(
          queueItem.metadata.transaction,
          queueItem.metadata.amount,
        ),
      );
    case QueueStages.financialClosure:
      return await this.createFinancialCloseDay();
    case QueueStages.fromSafeToCashDrawer:
      return await this.createTransferToNextDay(
        this.getFinancialDocumentByTransactionCodeAndAmount(
          queueItem.metadata.transaction,
          queueItem.metadata.amount,
        ),
      );
    default:
      throw new Error(`Unknown queue stage: ${queueItem.queueStage}`);
    }
  }

  async processQueueItem(queueItem: QueueItem) {
    if (queueItem.finished) {
      return queueItem;
    }

    try {
      const result = await this.executeQueueItem(queueItem);


      if (result?.hasValidPrintContent) {
        queueItem.print = result.printContent;
      }

      queueItem.finished = true;
      queueItem.error = null;

      return queueItem;
    } catch (e) {
      queueItem.error = e.message;
      throw e;
    }
  }

  async beforeContinue() {
    try {
      const queue = await this.ensureQueue();
      const queueByStages = groupBy(queue, 'queueStage') as {[key in QueueStages]: QueueItem[]};

      const queueOrderByStages = [
        ...queueByStages?.[QueueStages.fromCashDrawerToSafe] ?? [],
        ...queueByStages?.[QueueStages.financialClosure] ?? [],
        ...queueByStages?.[QueueStages.fromSafeToCashDrawer] ?? [],
      ];

      for (const queueItem of queueOrderByStages) {
        try {
          await this.processQueueItem(queueItem);
        } finally {
          this.persistQueue(queueOrderByStages);
        }
      }

      for (const queueItem of queueOrderByStages) {
        if (!queueItem.finished) {
          continue;
        }

        if (!queueItem.print) {
          continue;
        }

        if (queueItem.error) {
          continue;
        }

        if (isActiveFeaturePrintDisplayOnScreen()) {
          if (queueItem.displayed) {
            continue;
          }

          await this.printContentStore.open(queueItem.print);

          queueItem.displayed = true;
        }

        this.persistQueue(queueOrderByStages);
      }
    } catch (e) {
      console.error(e);

      throw e;
    }
  }

  get transitions() {
    return {};
  }
}
