
import {
  defineComponent,
  onBeforeMount,
  onMounted,
} from 'vue';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {
  WorkflowStepErrors,
  WorkflowStepTypes,
} from '../../types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepKorunkaLotteryScanTicket} from './WorkflowStepKorunkaLotteryScanTicket';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'StepKorunkaLotteryScanTicket',
  components: {
    HLStep,
    WorkflowTransitions,
  },
  setup() {
    const coreStore = useCoreStore();
    const stepType = WorkflowStepTypes.KorunkaScanTicket;
    const i18n = useI18n();

    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryScanTicket>(stepType);

    const onScan = () => {
      workflowStep.value.onScan();
    };

    const onKorunkaUnsupportedGame = async () => {
      await coreStore.alert(i18n.t('workflow.korunka.unsupportedGame'));
    };

    onMounted(() => {
      workflowStep.value.messageBus.addEventListener(
        WorkflowStepErrors.KORUNKA_UNUPPORTED_GAME_TYPE,
        onKorunkaUnsupportedGame,
      );
    });

    onBeforeMount(() => {
      workflowStep.value.messageBus.removeEventListener(
        WorkflowStepErrors.KORUNKA_UNUPPORTED_GAME_TYPE,
        onKorunkaUnsupportedGame,
      );
    });

    return {
      stepType,
      workflowStep,
      onScan,
    };
  },
});
