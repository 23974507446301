import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {usePhoneNumberMaskResolver} from '@/Helpers/phoneNumberMaskResolver';

export class WorkflowStepUpdateCustomerPhone extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.UpdateCustomerPhone;
  }

  get type() {
    return WorkflowStepUpdateCustomerPhone.type;
  }

  get component() {
    return markRaw(require('./StepUpdateCustomerPhone').default);
  }

  private phoneNumberMaskResolver = usePhoneNumberMaskResolver()
  get resolvedPhoneNumberMask() {
    return this.phoneNumberMaskResolver.resolvePhoneNumberMask(this.phone);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.phone]: yup.string()
        .required()
        .test(this.resolvedPhoneNumberMask.validation),
    });
  }

  async beforeEnter() {
    this.dataSetter(
      WorkflowStepField.phone,
      () => this.phoneNumberMaskResolver.resolveDefaultPhoneNumber(this.phone),
    );
  }

  get phone() {
    return this.getFieldValue(WorkflowStepField.phone, null);
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.phone, (fieldActions) => ({
        [WorkflowActions.ADD_PLUS]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_PLUS](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;

          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      }), {
        mask: this.resolvedPhoneNumberMask.phoneNumberMask,
      }),
    };
  }
}
