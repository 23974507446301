import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "grid w-full app-header shadow px-5 py-3 items-center",
  style: {"grid-template-columns":"14% 19% 43% auto min-content"}
}
const _hoisted_2 = {
  key: 0,
  class: "inline-flex items-center justify-center py-0.5 px-1.5 bg-success text-basic-lightest rounded-full text-xs uppercase bg-warning"
}
const _hoisted_3 = { class: "w-1/6 flex justify-center" }
const _hoisted_4 = { class: "w-5/6" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex-grow flex flex-row items-center justify-center space-x-2" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center font-bold"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_10 = {
  key: 1,
  class: "inline-flex items-center justify-center py-0.5 px-1.5 bg-success text-basic-lightest rounded-full text-xs uppercase bg-danger"
}
const _hoisted_11 = {
  key: 3,
  class: "flex items-center"
}
const _hoisted_12 = { class: "btn-warning btn-sm" }
const _hoisted_13 = { class: "flex items-center justify-end gap-3" }
const _hoisted_14 = { class: "flex items-center justify-end gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_Countdown = _resolveComponent("Countdown")!
  const _component_IconPerson = _resolveComponent("IconPerson")!
  const _component_EshopOrdersNotifier = _resolveComponent("EshopOrdersNotifier")!
  const _component_MessageNotifier = _resolveComponent("MessageNotifier")!
  const _component_Now = _resolveComponent("Now")!
  const _component_IconHome = _resolveComponent("IconHome")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_Logo, { style: {"height":"40px"} })
      ], 512), [
        [_directive_test, 'logo']
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.resetToShopLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('resetToShop.title')) + ": " + _toDisplayString(_ctx.resetToShopLabel), 1))
        : _createCommentVNode("", true),
      (_ctx.updateStore.hasActiveCountdown.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "flex flex-row items-center justify-center",
            onClick: _cache[0] || (_cache[0] = () => _ctx.updateStore.openDetail())
          }, [
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.updateStore.isScheduled.value)
                ? (_openBlock(), _createBlock(_component_PhIcon, {
                    key: 0,
                    icon: "PhArrowsCounterClockwise",
                    size: 20
                  }))
                : (_openBlock(), _createBlock(_component_PhIcon, {
                    key: 1,
                    icon: "PhArrowsCounterClockwise",
                    class: _normalizeClass(_ctx.updateStore.allUpdateConditionsAreMet.value ? 'text-warning' : 'text-danger'),
                    size: 20
                  }, null, 8, ["class"]))
            ]),
            _createElementVNode("div", _hoisted_4, [
              (!_ctx.updateStore.isScheduled.value)
                ? (_openBlock(), _createBlock(_component_Countdown, {
                    key: 0,
                    milliseconds: _ctx.updateStore.timeRemaining.value,
                    withLead: false,
                    xs: "",
                    ticks: _ctx.CountdownTicks.external
                  }, null, 8, ["milliseconds", "ticks"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.dateTimeFormat(_ctx.updateStore.updateValidFrom.value, {format: 'dd. MM. HH:mm'})), 1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.authStore.activePerson.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_IconPerson, {
              class: "mr-1 w-5 h-5",
              bold: ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.authStore.activePerson.value?.tokenInfo?.first_name) + " " + _toDisplayString(_ctx.authStore.activePerson.value?.tokenInfo?.last_name), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.authStore.activePerson.value && !_ctx.trainingStore.trainingIsActive.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, " / "))
        : _createCommentVNode("", true),
      (!_ctx.trainingStore.trainingIsActive.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_PhIcon, {
              icon: "storefront",
              class: "mr-1",
              size: 25
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.shopLabel), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex items-center gap-1", {
          'pl-3': _ctx.trainingStore.trainingIsActive.value
        }])
      }, [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["inline-flex items-center justify-center py-0.5 px-1.5 bg-success text-basic-lightest rounded-full text-xs uppercase", {
            'bg-success': _ctx.online,
            'bg-danger': !_ctx.online,
          }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t(`general.${_ctx.online ? 'online' : 'offline'}`)), 1)
        ], 2), [
          [
            _directive_test,
            !!_ctx.online,
            void 0,
            { middlewareOnline: true }
          ]
        ]),
        (_ctx.isForcedOnline)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "font-bold",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetForcedOnline && _ctx.resetForcedOnline(...args)))
            }, "F"))
          : _createCommentVNode("", true),
        (_ctx.restoreModeStore.active.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, " RM "))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.trainingStore.trainingIsActive.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('training.title')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_13, [
      (_ctx.$features.isActiveFeatureEshop())
        ? (_openBlock(), _createBlock(_component_EshopOrdersNotifier, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.$features.isActiveFeatureMessages())
        ? (_openBlock(), _createBlock(_component_MessageNotifier, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_Now),
      (!_ctx.isLoginPage && _ctx.appsRouteExists)
        ? _withDirectives((_openBlock(), _createBlock(_component_RouterLink, {
            key: 0,
            to: {name: 'apps'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconHome, { class: "w-8 h-8" })
            ]),
            _: 1
          }, 512)), [
            [_directive_test, 'appsLink']
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}