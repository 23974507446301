import {
  BaseType,
  Entity,
  EntityClass,
  EntityOptions,
  ExtractEntityData,
  Type,
  TypeOptions,
} from '@designeo/apibundle-js';
import KorunkaLotterySestkaDtoCustom from '@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom';
import KorunkaLotteryDvacetZa20DtoCustom from '@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom';
import KorunkaLotteryHopTropDtoCustom from '@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom';
import KorunkaLotteryKombiDtoCustom from '@/Model/Entity/custom/KorunkaLotteryKombiDtoCustom';
import KorunkaLotteryKombiPlusDtoCustom from '@/Model/Entity/custom/KorunkaLotteryKombiPlusDtoCustom';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import KorunkaLotteryScanTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryScanTicketDtoCustom';
import KorunkaLotteryPreparedTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryPreparedTicketDtoCustom';
import KorunkaLotteryCardDtoCustom from '@/Model/Entity/custom/KorunkaLotteryCardDtoCustom';
import KorunkaLotteryScanResultDtoCustom from '@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom';
import {
  deserializeEntity,
  EntitySerialization,
  serializeEntity,
} from '@/Helpers/entityPersist';
import {api3rdPartyKorunkaGenerateDraws} from '@/Model/Action';
import {DateTime} from 'luxon';
import {filter} from 'lodash-es';
import {KorunkaDrawStatus, KorunkaEntityTypes} from './korunkaTypes';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import TicketPartnerResourceV2 from '@/Model/Entity/TicketPartnerResourceV2';
import TicketPartnerResource from '@/Model/Entity/TicketPartnerResource';
import {KorunkaVersion} from '@/constants/korunka';

export type KorunkaGameEntities =
    KorunkaLotterySestkaDtoCustom
  | KorunkaLotteryDvacetZa20DtoCustom
  | KorunkaLotteryHopTropDtoCustom
  | KorunkaLotteryKombiDtoCustom
  | KorunkaLotteryKombiPlusDtoCustom

export type KorunkaEntities =
    KorunkaGameEntities
  | KorunkaLotteryTicketDtoCustom
  | KorunkaLotteryScanTicketDtoCustom
  | KorunkaLotteryPreparedTicketDtoCustom
  | KorunkaLotteryCardDtoCustom
  | KorunkaLotteryScanResultDtoCustom

export const fetchKorunkaDraws = async () => {
  const result = await api3rdPartyKorunkaGenerateDraws();

  const now = DateTime.now().set({hour: 0, minute: 0, second: 0});

  const draws = filter(result, (draw) => {
    const {days: daysDiff} = DateTime
      .fromJSDate(draw.deadlineForBets)
      .set({hour: 0, minute: 0, second: 0})
      .diff(now, 'days')
      .toObject();

    return daysDiff <= 31 && draw.status.value === KorunkaDrawStatus.Planned;
  });

  return draws;
};

export function deserializeKorunkaEntity(val: EntitySerialization): KorunkaEntities {
  return deserializeEntity(val)
    .match(KorunkaLotterySestkaDtoCustom, KorunkaEntityTypes.Sestka)
    .match(KorunkaLotteryDvacetZa20DtoCustom, KorunkaEntityTypes.DvacetZa20)
    .match(KorunkaLotteryHopTropDtoCustom, KorunkaEntityTypes.HopTrop)
    .match(KorunkaLotteryTicketDtoCustom, KorunkaEntityTypes.Ticket)
    .match(KorunkaLotteryScanTicketDtoCustom, KorunkaEntityTypes.ScanTicket)
    .match(KorunkaLotteryPreparedTicketDtoCustom, KorunkaEntityTypes.PreparedTicket)
    .match(KorunkaLotteryKombiDtoCustom, KorunkaEntityTypes.Kombi)
    .match(KorunkaLotteryKombiPlusDtoCustom, KorunkaEntityTypes.KombiPlus)
    .match(KorunkaLotteryCardDtoCustom, KorunkaEntityTypes.Card)
    .match(KorunkaLotteryScanResultDtoCustom, KorunkaEntityTypes.ScanResult)
    .resolve();
}

export function serializeKorunkaEntity(val: Entity<any, any>) {
  return serializeEntity(val)
    .match(KorunkaLotterySestkaDtoCustom, KorunkaEntityTypes.Sestka)
    .match(KorunkaLotteryDvacetZa20DtoCustom, KorunkaEntityTypes.DvacetZa20)
    .match(KorunkaLotteryHopTropDtoCustom, KorunkaEntityTypes.HopTrop)
    .match(KorunkaLotteryTicketDtoCustom, KorunkaEntityTypes.Ticket)
    .match(KorunkaLotteryScanTicketDtoCustom, KorunkaEntityTypes.ScanTicket)
    .match(KorunkaLotteryPreparedTicketDtoCustom, KorunkaEntityTypes.PreparedTicket)
    .match(KorunkaLotteryKombiDtoCustom, KorunkaEntityTypes.Kombi)
    .match(KorunkaLotteryKombiPlusDtoCustom, KorunkaEntityTypes.KombiPlus)
    .match(KorunkaLotteryCardDtoCustom, KorunkaEntityTypes.Card)
    .match(KorunkaLotteryScanResultDtoCustom, KorunkaEntityTypes.ScanResult)
    .resolve();
}

export function getCurrentKorunkaVersion(): KorunkaVersion {
  const configurationStore = useConfigurationStore();

  return configurationStore.configuration.value?.features?.korunka?.apiVersion ?? KorunkaVersion.v1;
}

export function isKorunkaVersion(version: KorunkaVersion) {
  return getCurrentKorunkaVersion() === version;
}

export function isKorunkaVersionV1() {
  return isKorunkaVersion(KorunkaVersion.v1);
}

export function isKorunkaVersionV2() {
  return isKorunkaVersion(KorunkaVersion.v2);
}

export function throwUnsupportedKorunkaVersionError() {
  throw new Error('Unsupported Korunka version');
}


export type TicketPartnerResourceEntities = TicketPartnerResource | TicketPartnerResourceV2
