import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "-my-2 -mx-6 pb-2",
  style: {"max-height":"550px"}
}
const _hoisted_2 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductVariation = _resolveComponent("ProductVariation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
        return (_openBlock(), _createBlock(_component_ProductVariation, {
          key: product.item.internalNumber,
          product: product,
          class: _normalizeClass({'border-b border-basic-light': _ctx.products.length - 1 !== index}),
          detail: false,
          onDetail: ($event: any) => (_ctx.$emit('detail', product)),
          onClick: ($event: any) => (_ctx.$emit('select', product))
        }, null, 8, ["product", "class", "onDetail", "onClick"]))
      }), 128))
    ])
  ]))
}