
import {
  computed,
  defineComponent,
} from 'vue';
import {useFilters} from '@/Helpers/filters';
import {useArticleLabel} from '@/Components/ArticleLabels/label';
import ArticleLabelMixin from '@/Components/ArticleLabels/ArticleLabelMixin.vue';

export default defineComponent({
  name: 'ArticleLabelAction',
  mixins: [ArticleLabelMixin],
  setup(props) {
    const {numberFormat} = useFilters();
    const {theme, label} = useArticleLabel('action');

    const isDisplayed = computed(() => props.forceVisible ?? props.item.isArticleLabelAction);

    return {
      theme,
      label,
      numberFormat,
      isDisplayed,
    };
  },
});
