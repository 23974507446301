import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "pt-4 text-2xl pb-3 text-danger font-bold" }
const _hoisted_2 = { class: "pb-4 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    type: "lg",
    open: _ctx.incorrectRestartStore.isVisible.value,
    buttonClose: false,
    invalidateRoute: false,
    onClose: _cache[0] || (_cache[0] = (event) => _ctx.incorrectRestartStore.continue())
  }, {
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('incorrectRestart.title')), 1),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.subject)))
        ]),
        _createVNode(_component_ModalDetachedButtons, null, {
          default: _withCtx(() => [
            (_ctx.incorrectRestartStore.canContinue.value)
              ? (_openBlock(), _createBlock(_component_AsyncButton, {
                  key: 0,
                  test: 'incorrectRestartButtonContinue',
                  type: "button",
                  class: "btn-basic bg-basic-light btn-md",
                  onClick: () => _ctx.incorrectRestartStore.continue()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('incorrectRestart.continue')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.incorrectRestartStore.canCancelWorkflow.value)
              ? (_openBlock(), _createBlock(_component_AsyncButton, {
                  key: 1,
                  test: 'incorrectRestartButtonCancel',
                  type: "button",
                  class: "btn-basic bg-basic-light btn-md",
                  onClick: () => _ctx.incorrectRestartStore.cancelWorkflow()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('incorrectRestart.cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.incorrectRestartStore.canCancelSellDocument.value)
              ? (_openBlock(), _createBlock(_component_AsyncButton, {
                  key: 2,
                  test: 'incorrectRestartButtonCancelSellDocument',
                  type: "button",
                  class: "btn-basic bg-basic-light btn-md",
                  onClick: () => _ctx.incorrectRestartStore.cancelSellDocument()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('incorrectRestart.cancelSellDocument')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 512), [
        [_directive_test, 'incorrectRestartModal']
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}