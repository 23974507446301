import routes from './routes';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {includes, some} from 'lodash-es';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import customerExternalRoutes from '../CustomerExternal/routes';
import {ConfirmTemplates} from '@/Modules/Core/types';
import {wait} from '@designeo/js-helpers';
import {sentryFinishCurrentSellDocumentTransaction} from '@/Helpers/sentry';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerRegister(ctx: RequiredContext) {
  const registerStore = useRegisterStore();
  const coreStore = useCoreStore();
  const configurationStore = useConfigurationStore();
  const authStore = useAuthStore();

  await ctx.registerRoutes(routes);

  const purchaseRoutes = [
    'workflow',
    'customer-detail',
    'register',
  ];

  await ctx.registerHookBeforeEach(async (to, from, next) => {
    const sellDocument = registerStore.sellDocument.value;

    if (some([].concat(customerExternalRoutes), {name: to.name})) {
      // this is second display, it can go anywhere
      next();
    } else if (configurationStore.configuration.value.isPosNotConfigured) {
      // you just dc down & dc up backend, right?
      next();
    } else if (!authStore.activePerson.value) {
      // should we limit this to login screens?
      next();
    } else if (sellDocument?.isTouched) { // leaving register
      if (includes(purchaseRoutes, to.name)) {
        next();
      } else if (sellDocument?.canBeDiscarded ?? true) {
        // not an interesting receipt, just reset it
        registerStore.resetReceipt()
          .then(() => next());
      } else if (sellDocument?.canBeCanceledWithoutConfirmation) {
        // interesting receipt, save it
        try {
          await registerStore.cancelSellDocument({fourEyes: false});
        } catch (e) {
          console.error(e);
        } finally {
          next();
        }
      } else {
        // we can't leave there is a receipt that must be dealt with first!
        next({name: 'register'});
        if (to.redirectedFrom?.name !== 'register') {
          await wait(300)(null); // better idea how to wait for router navigation complete?
          // @ts-ignore we already has i18n in ctx by this time
          await coreStore.alert(ctx.i18n.global.t('register.exitInProgressError'), {
            template: ConfirmTemplates.defaultWithRecovery,
          });
        }
      }
    } else if (registerStore.isReturnModeActive.value) {
      await registerStore.toggleReturnMode();
      next();
    } else {
      next();
    }
  });

  await ctx.registerHookAfterEach(async (to, from, next) => {
    if (from.name === 'register' && !includes(purchaseRoutes, to.name)) {
      sentryFinishCurrentSellDocumentTransaction();
    }
  });

  let lastSellDocumentTimeout;

  const createLastSellDocumentMuteTimeout = () => {
    clearTimeout(lastSellDocumentTimeout);
    lastSellDocumentTimeout = setTimeout(() => {
      broadcastIO.ensureUnmuteAndResumeQueue();
    }, 10000);
  };

  const destroyLastSellDocumentMuteTimeout = () => {
    clearTimeout(lastSellDocumentTimeout);
    broadcastIO.ensureUnmuteAndResumeQueue();
  };

  await ctx.registerHookAfterEach(async (to, from) => {
    if (registerStore.lastSellDocument.value && from.name === 'register' && to.name === 'login') {
      createLastSellDocumentMuteTimeout();
    } else {
      destroyLastSellDocumentMuteTimeout();
    }

    if (!includes(purchaseRoutes, to.name)) {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
    }
  });

  return {};
}
