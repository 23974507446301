import KorunkaCheckDtoCustom from '@/Model/Entity/custom/KorunkaCheckDtoCustom';
import {KorunkaPatch} from '@/Model/Entity/patches/KorunkaMixin';

export default class KorunkaCheckDto extends KorunkaPatch(KorunkaCheckDtoCustom) {
  get canBeSold() {
    return new Date() <= new Date(this.deadline ?? new Date());
  }

  get errorReason() {
    return null;
  }

  get errorReasonForCustomer() {
    return null;
  }
}
