import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", _mergeProps({ src: _ctx.logoBase64 }, _ctx.$attrs, {
    class: {
      'opacity-25': _ctx.isBlurred
    }
  }), null, 16, _hoisted_1))
}