import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinEshop} from '../StepMixins/WorkflowStepMixinEshop';

export class WorkflowStepEshopCustomerVerification extends workflowStepMixinEshop(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.EshopCustomerVerification;
  }

  get type() {
    return WorkflowStepEshopCustomerVerification.type;
  }

  get component() {
    return markRaw(require('./StepEshopCustomerVerification.vue').default);
  }

  get canBeReturnedTo() {
    return this.customerVerificationRequired;
  }

  get isCustomerVerified() {
    return this.getFieldValue(WorkflowStepField.customerVerificationDate, null) !== null;
  }

  get customerVerificationRequired() {
    return this.order?.data?.customer?.ageConfirmationRequired;
  }

  get birthDateFormat() {
    return this.step?.birthDateFormat;
  }

  setCustomerVerificationDate() {
    this.disabledNextStep = false;
    this.dataSetter(WorkflowStepField.customerVerificationDate, () => new Date().toISOString());
  }

  async beforeEnter() {
    if (!this.customerVerificationRequired) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (!this.isCustomerVerified) {
      this.disabledNextStep = true;
    }

    if (this.stepInit) {
      return;
    }

    try {
      await this.ensureOrder();
    } catch (err) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    }

    this.stepInit = true;
  }

  async finish() {
    this.stepFinished = true;

    if (!this.isCustomerVerified) {
      this.setCustomerVerificationDate();
    }

    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {};
  }
}
