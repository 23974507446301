/* eslint-disable array-bracket-newline */
import {
  get,
  flow,
} from 'lodash-es';
import {App} from 'vue';
import {withTrainingMockAdapter} from '@/Helpers/mock/axios';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {Router} from 'vue-router';
import {
  sentryEnsureStandaloneAjaxRequestTransaction,
  sentryFinishCurrentStandaloneAjaxRequestTransaction,
} from '@/Helpers/sentry';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import coreAxios,
{
  AxiosAdapter,
  AxiosInstance,
} from 'axios';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {guid} from '@/Helpers/guid';
import {getVueApp} from '@/Helpers/app';
import {recordAxiosLogEntry, recordCustomEventLogEntry} from '@/Helpers/logger';
import {withAxiosApiInterceptorAdapter} from '@/Helpers/interceptors';
const httpAdapter = require('axios/lib/adapters/http');

function withAxiosInterceptors(axios: AxiosInstance) {
  axios.interceptors.request.use((config) => {
    config.headers['requestId'] = guid();

    if (!config.cancelToken) {
      const coreStore = useCoreStore();

      const cancelToken = new (coreAxios.CancelToken)((canceler) => {
        config.headers['cancelTokenId'] = guid();
        coreStore.setApiRequestCanceler(config.headers.cancelTokenId, canceler);
      });

      return {
        ...config,
        cancelToken,
      };
    }

    return config;
  }, (error) => {
    if (error?.config?.headers?.cancelTokenId) {
      useCoreStore().clearApiRequestCanceler(error.config.headers.cancelTokenId);
    }

    return Promise.reject(error);
  });

  axios.interceptors.response.use((response) => {
    if (response?.config?.headers?.cancelTokenId) {
      useCoreStore().clearApiRequestCanceler(response.config.headers.cancelTokenId);
    }

    return response;
  }, (error) => {
    if (error?.config?.headers?.cancelTokenId) {
      useCoreStore().clearApiRequestCanceler(error.config.headers.cancelTokenId);
    }

    return Promise.reject(error);
  });

  axios.interceptors.request.use((config) => {
    const appLocale = getVueApp()?.config.globalProperties.$context.i18n.global.locale ?? null;
    const configLocale = useConfigurationStore().configuration.value?.general.language ?? null;

    config.headers['locale'] = appLocale ?? configLocale?.value?.toLowerCase() ?? 'en';

    return config;
  });

  axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = config.headers['Authorization'] ?? useAuthStore().authorizationHeader.value;
    return config;
  });

  axios.interceptors.request.use((config) => {
    const e2eScenarioId = require('@/Helpers/persist').e2eScenarioIdSessionStorage.get();

    if (e2eScenarioId) {
      config.headers['e2eScenarioId'] = e2eScenarioId;
    }

    return config;
  });

  axios.interceptors.request.use((config) => {
    sentryEnsureStandaloneAjaxRequestTransaction();

    return config;
  }, (error) => {
    sentryFinishCurrentStandaloneAjaxRequestTransaction();
    return Promise.reject(error);
  });

  axios.interceptors.response.use(
    (response) => {
      sentryFinishCurrentStandaloneAjaxRequestTransaction();
      return response;
    },
    (error) => {
      sentryFinishCurrentStandaloneAjaxRequestTransaction();
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response, async function(error) {
      if (get(error, 'request.status') === 401) {
        const authStore = useAuthStore();

        if (authStore.activePerson.value) {
          await authStore.logout();
        }

        const vueApp = <App>getVueApp();
        if (vueApp) {
          const vueAppInstance = vueApp.config.globalProperties;
          const router = vueAppInstance.$router as Router;
          await router.push({
            name: 'unauthorized',
          });
        }
      }
      if (get(error, 'request.status') === 423) {
        const vueApp = <App>getVueApp();
        if (vueApp) {
          const vueAppInstance = vueApp.config.globalProperties;
          vueAppInstance.$router.push({
            name: 'pos-configuration',
          });
        }
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => {
      recordAxiosLogEntry(response);
      return response;
    },
    (error) => {
      if (coreAxios.isAxiosError(error) && error.response) {
        recordAxiosLogEntry(error.response);
      } else if (typeof error === 'string') {
        recordCustomEventLogEntry('AxiosError', error);
      } else {
        recordCustomEventLogEntry('AxiosError', error?.message ?? 'Unknown error');
      }

      return Promise.reject(error);
    },
  );

  return axios;
}

const axiosOnlineOnlyAdapter: AxiosAdapter = withTrainingMockAdapter(httpAdapter);

const axios: AxiosInstance = withAxiosInterceptors(coreAxios.create({
  adapter: flow([
    withTrainingMockAdapter,
    withAxiosApiInterceptorAdapter,
  ])(httpAdapter),
}));

const axiosOnlineOnly: AxiosInstance = withAxiosInterceptors(coreAxios.create({
  adapter: axiosOnlineOnlyAdapter,
}));

const axiosBlob: AxiosInstance = withAxiosInterceptors(coreAxios.create({
  adapter: flow([
    withTrainingMockAdapter,
    withAxiosApiInterceptorAdapter,
  ])(httpAdapter),
  responseType: 'blob',
}));

export {
  axios,
  axiosOnlineOnly,
  axiosBlob,
  axiosOnlineOnlyAdapter,
};
