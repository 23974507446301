import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_VALIDATION_CODE]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(() => {
        this.state.inputBuffer = this.state.productFlow.product.validationCode ?? '';
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.inputBuffer) return;

        this.state.productFlow.product.setValidationCodeWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          this.state.inputBuffer,
        );

        if (!this.state.productFlow.product.validateCheckAndChargeTypeLotWithinContext(this.state.sellDocument)) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT));
          return;
        }

        await this.transitionToNextStep();
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        this.state.productFlow.product.setValidationCodeWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          event.value,
        );

        if (!this.state.productFlow.product.validateCheckAndChargeTypeLotWithinContext(this.state.sellDocument)) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT));
          return;
        }

        await this.transitionToNextStep();
      }),
    },
  };
}
