
import {
  defineComponent,
  computed,
  ref,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepEshopCustomerVerification} from './WorkflowStepEshopCustomerVerification';
import {useFilters} from '@/Helpers/filters';
import OrderDetail from '../StepEshopOrderOverview/OrderDetail.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  name: 'StepEshopPinVerification',
  components: {
    WorkflowTransitions,
    HLStep,
    OrderDetail,
    AsyncButton,
    PhIcon,
  },
  setup() {
    const stepType = WorkflowStepTypes.EshopOrderOverview;
    const {
      workflowStep,
    } = useStepTools<WorkflowStepEshopCustomerVerification>(stepType);
    const {dateFormat} = useFilters();

    const customerNameVerified = ref(false);

    const customerBirthDateVerified = ref(false);

    const customer = computed(() => workflowStep.value.order.data.customer);

    const canContinue = computed(() => {
      return (
        customerNameVerified.value && customerBirthDateVerified.value
      ) || workflowStep.value.isCustomerVerified;
    });

    const birthDateFormat = computed(() => {
      return workflowStep.value.birthDateFormat;
    });

    return {
      stepType,
      WorkflowStepField,
      customer,
      dateFormat,
      customerNameVerified,
      customerBirthDateVerified,
      canContinue,
      birthDateFormat,
    };
  },
});
