import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {markRaw, toRaw} from 'vue';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {inventoryStoreStatePersist} from '@/Helpers/persist';
import {
  DocumentDto,
  DocumentInventoryGroup,
  DocumentItemDto,
  DocumentLogisticItemDto,
  FiscalCommands,
  GroupRangeDto,
  GroupRangeSearchDto,
  InventoryGroupDto,
  InventoryGroupMetadataDto,
  InventoryGroupResultsByVatDto,
  InventoryMetadataDto,
  InventoryType,
  StockDto,

} from '@/Model/Entity';
import {
  InventoryPrintTemplate,
  InventoryActions,
  InventoryCalculationType,
  InventoryDisplayState,
  InventoryErrors,
  InventoryEvent,
  InventoryInputEvent,
  InventoryPartialMode,
  InventoryQuantityDifferenceFilter,
  InventoryState,
  InventoryGroup,
  InventoryGroupInput,
  InventoryGroupInputs,
  InventoryGroupInputType,
  InputTypeGroupList,
  InputTypeGroupRange,
  InventoryGroupRangeIndex,
  InventoryCustomType,

} from '../types';
import {
  deburr,
  difference,
  differenceBy,
  every,
  filter,
  find,
  findIndex,
  flow,
  forEach,
  isEmpty,
  isNil,
  map,
  mapKeys,
  orderBy,
  pick,
  reduce,
  reject,
  some,
  sortBy,
  sumBy,
  toLower,
  union,
  unionBy,
  uniq,
  uniqBy,
  values,
} from 'lodash-es';
import {
  apiDocumentCreate,
  apiInventoryCustomArticlesGet,
  apiInventoryDataForReport,
  apiInventoryGroupsList,
  apiInventoryMetadata,
  apiSearchByGroup,
  apiSearchByGroupRange,
  apiSearchListFiltered,
  apiStockGet,

} from '@/Model/Action';
import {
  KEYBOARD_KEY_ARROW_DOWN,
  KEYBOARD_KEY_ARROW_UP,
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_COMMA,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEY_MINUS,
  KEYBOARD_KEY_PERIOD,
  KEYBOARD_KEY_PLUS,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {BufferedInput, InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  createArrowTransitions,
  createInventoryStockFilter,
  createTransitions,
  findInventoryItem,
  getCommonTransitions,
  getGroupedStock,
  filterLogisticItemsByPrintTemplate,
  getDocumentPropertiesByPrintTemplate,
} from './helpers';
import {
  AppLoaderEvent,
  PrinterWSEvents,
} from '@/Modules/Core/types';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {usePrintContentStore} from '@/Modules/Core/store/PrintContentStore';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';
import {ModalTypes} from '@/constants/modal';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ArticleListFilterDto from '@/Model/Entity/ArticleListFilterDto';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import ArticleFilterDescriptionDto from '@/Model/Entity/ArticleFilterDescriptionDto';
import {fixDecimals} from '@/Helpers/math';
import {isActiveFeatureInTransitStock, isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {DocumentTypes} from '@/constants/documentTypes';
import DocumentSave from '@/Helpers/document/save';

export interface IInventoryStore {
  state: InventoryState,
  previousState: InventoryState,
  inventoryCalculationType: InventoryCalculationType,
  partialMode?: InventoryPartialMode,
  customType?: InventoryCustomType,
  showCustomTypes?: boolean,
  inventoryModeSelected: boolean,
  inventoryGroups: DocumentInventoryGroup[],
  inventoryDocument: DocumentDto
  insertMode: boolean,
  stock: StockDto[],
  loadedData: DocumentLogisticItemDto['_data'][]
  gridData: DocumentLogisticItemDto['_data'][]
  inputBuffer: string
  activeDocumentLogisticItem: DocumentLogisticItemDto,
  previewOnly: boolean,
  itemGroup?: string,
  filters: {
    search: string,
    quantityDifference: InventoryQuantityDifferenceFilter,
  },
  productDetail: {
    item: DocumentLogisticItemDto,
  },
  inventoryMetadata: InventoryMetadataDto,
  inventoryGroupInputs: InventoryGroupInputs
}

export const createInitGroupInputList = (data?: Partial<InventoryGroupInput>): InputTypeGroupList => {
  return {
    type: InventoryGroupInputType.LIST,
    inputs: [
      {
        active: false,
        value: '',
        ...data,
      },
    ],
  };
};

export const createInitGroupInputRange = (
  fromData?: Partial<InventoryGroupInput>,
  toData?: Partial<InventoryGroupInput>,
): InputTypeGroupRange => {
  return {
    type: InventoryGroupInputType.RANGE,
    inputs: [
      {
        active: false,
        value: '',
        ...fromData,
      },
      {
        active: false,
        value: '',
        ...toData,
      },
    ],
  };
};

export const createInitState = (data?: Partial<IInventoryStore>) => {
  const inventoryDocument = DocumentDto.createInventorySummaryDocument();

  return {
    state: InventoryState.DEFAULT,
    previousState: null,
    loadedData: [],
    gridData: [],
    inventoryCalculationType: InventoryCalculationType.FULL,
    inventoryModeSelected: false,
    inventoryGroups: [],
    customType: null,
    showCustomTypes: false,
    partialMode: null,
    stock: null,
    inventoryDocument,
    insertMode: false,
    inputBuffer: '',
    activeDocumentLogisticItem: null,
    filters: {
      search: '',
      quantityDifference: InventoryQuantityDifferenceFilter.ONLY_DIFFERENCES,
    },
    productDetail: null,
    previewOnly: false,
    itemGroup: null,
    inventoryMetadata: null,
    inventoryGroupInputs: [],
    ...data,
  };
};


export class InventoryStore extends PersistentStore<IInventoryStore> {
  constructor() {
    super(createInitState(), inventoryStoreStatePersist, {autoPersist: false});
  }

  transitions: {[key in InventoryState]?: {[key in InventoryActions]?: any}} = {
    [InventoryState.DEFAULT]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.ADD_CHAR,
        InventoryActions.ADD_PERIOD,
        InventoryActions.ADD_COMMA,
        InventoryActions.BACKSPACE,
      ]),
      [InventoryActions.INIT]: action(async () => {
        this.state.insertMode = false;

        if (isEmpty(this.state.loadedData)) {
          await this.fetchInventoryData();
        }
      }),
      [InventoryActions.CANCEL]: action(async () => {
        this.dispatchCancelInventory(true);
      }),
      ...createArrowTransitions.call(this),
    },
    [InventoryState.SEARCH]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.ADD_CHAR,
        InventoryActions.ADD_PERIOD,
        InventoryActions.ADD_COMMA,
        InventoryActions.BACKSPACE,
      ]),
      [InventoryActions.INIT]: action(() => {
        this.state.insertMode = false;
        this.setActiveDocumentLogisticItem(null, {changeState: false});
      }),
    },
    [InventoryState.SEARCH_ITEM_GROUP_LIST]: {
      [InventoryActions.ADD_NUMBER]: action(async (event: InventoryInputEvent<string>) => {
        const activeGroup = find(this.state.inventoryGroupInputs, (group) => {
          return find(group.inputs, 'active');
        }) as InputTypeGroupList;

        const activeInputIndex = findIndex(activeGroup.inputs, 'active');

        activeGroup.inputs[activeInputIndex].value += event.value;
      }),
      [InventoryActions.ADD_CHAR]: action(async (event: InventoryInputEvent<string>) => {
        const activeGroup = find(this.state.inventoryGroupInputs, (group) => {
          return find(group.inputs, 'active');
        }) as InputTypeGroupList;

        const activeInputIndex = findIndex(activeGroup.inputs, 'active');

        activeGroup.inputs[activeInputIndex].value += event.value;
      }),
      [InventoryActions.BACKSPACE]: action(async (event: InventoryInputEvent<string>) => {
        const activeGroup = find(this.state.inventoryGroupInputs, (group) => {
          return find(group.inputs, 'active');
        }) as InputTypeGroupList;

        const activeInputIndex = findIndex(activeGroup.inputs, 'active');

        activeGroup.inputs[activeInputIndex].value = activeGroup.inputs[activeInputIndex].value.slice(0, -1);
      }),
      [InventoryActions.ENTER]: action(async () => {
        const activeGroupIndex = findIndex(
          this.state.inventoryGroupInputs,
          (group: any) => find(group.inputs, 'active'),
        );

        const nextGroupIndex = activeGroupIndex + 1;

        const isLastGroup = activeGroupIndex === this.state.inventoryGroupInputs.length - 1;

        if (isLastGroup) {
          return;
        }

        this.setActiveInventoryGroupInput(nextGroupIndex, 0);
      }),
    },
    [InventoryState.SEARCH_ITEM_GROUP_RANGE]: {
      [InventoryActions.ADD_NUMBER]: action(async (event: InventoryInputEvent<string>) => {
        const activeGroup = find(this.state.inventoryGroupInputs, (group) => {
          return find(group.inputs, 'active');
        }) as InputTypeGroupRange;

        const activeInputIndex = findIndex(activeGroup.inputs, 'active');

        activeGroup.inputs[activeInputIndex].value += event.value;
      }),
      [InventoryActions.ADD_CHAR]: action(async (event: InventoryInputEvent<string>) => {
        const activeGroup = find(this.state.inventoryGroupInputs, (group) => {
          return find(group.inputs, 'active');
        }) as InputTypeGroupRange;

        const activeInputIndex = findIndex(activeGroup.inputs, 'active');

        activeGroup.inputs[activeInputIndex].value += event.value;
      }),
      [InventoryActions.BACKSPACE]: action(async (event: InventoryInputEvent<string>) => {
        const activeGroup = find(this.state.inventoryGroupInputs, (group) => {
          return find(group.inputs, 'active');
        }) as InputTypeGroupRange;

        const activeInputIndex = findIndex(activeGroup.inputs, 'active');

        activeGroup.inputs[activeInputIndex].value = activeGroup.inputs[activeInputIndex].value.slice(0, -1);
      }),
      [InventoryActions.ENTER]: action(async () => {
        const activeGroupIndex = findIndex(
          this.state.inventoryGroupInputs,
          (group: any) => find(group.inputs, 'active'),
        );

        const activeInputIndex = findIndex(
          this.state.inventoryGroupInputs[activeGroupIndex].inputs,
          'active',
        );

        const nextGroupIndex = activeGroupIndex + 1;
        const nextInputIndex = activeInputIndex + 1;

        const isLastGroup = activeGroupIndex === this.state.inventoryGroupInputs.length - 1;
        const isLastInput = activeInputIndex === this.state.inventoryGroupInputs[activeGroupIndex].inputs.length - 1;

        if (isLastGroup && isLastInput) {
          return;
        }

        if (isLastInput) {
          this.setActiveInventoryGroupInput(nextGroupIndex, 0);
        } else {
          this.setActiveInventoryGroupInput(activeGroupIndex, nextInputIndex);
        }
      }),
    },
    [InventoryState.SHOW_SUMMARY]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.ADD_CHAR,
        InventoryActions.ADD_PERIOD,
        InventoryActions.ADD_COMMA,
        InventoryActions.BACKSPACE,
      ]),
      [InventoryActions.INIT]: action(() => {
        this.state.insertMode = false;
      }),
    },
    [InventoryState.SHOW_IMAGE]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.ADD_CHAR,
        InventoryActions.ADD_PERIOD,
        InventoryActions.ADD_COMMA,
        InventoryActions.BACKSPACE,
      ]),
    },
    [InventoryState.EDIT_QUANTITY]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.BACKSPACE,
      ]),
      [InventoryActions.INIT]: action(() => {
        this.state.insertMode = true;
        this.state.inputBuffer = String(this.state.activeDocumentLogisticItem.quantityReal);
      }),
      [InventoryActions.BEFORE_LEAVE]: action(async () => {
        const quantity = parseInt(this.state.inputBuffer);

        if (!this.isValidQuantity(quantity)) {
          return false;
        }

        await this.editActiveDocumentLogisticItemQuantityReal(quantity);
        await this.calculateInventoryGroups({withFetch: false});
      }),
      [InventoryActions.CANCEL]: action(() => {
        this.setActiveDocumentLogisticItem(null);
      }),
      [InventoryActions.ENTER]: action(async () => {
        await this.onEventInput({type: InventoryActions.NEXT});
      }),
      ...createArrowTransitions.call(this),
    },
    [InventoryState.SEARCH_PRODUCTS]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.BACKSPACE,
        InventoryActions.SCANNER,
      ]),
      ...reduce([
        InventoryActions.ADD_CHAR,
        InventoryActions.ADD_MINUS,
        InventoryActions.ADD_PLUS,
        InventoryActions.ADD_PERIOD,
        InventoryActions.ADD_COMMA,
      ], (acc, val) => {
        acc[val] = action((event: InventoryInputEvent<string>) => {
          createTransitions.call(this)[InventoryActions.ADD_CHAR](event);
        });

        return acc;
      }, {}),
      [InventoryActions.CANCEL]: action(async () => {
        await this.changeState(this.state.previousState);
      }),
      [InventoryActions.ENTER]: action(async () => {
        this.dispatchEvent(new Event(InventoryEvent.SEARCH_PRODUCT));
      }),
    },
    [InventoryState.STOCK_IN_STORES]: {
      ...getCommonTransitions.call(this, [
        InventoryActions.ADD_NUMBER,
        InventoryActions.CLEAR,
        InventoryActions.BACKSPACE,
      ]),
      ...reduce([
        InventoryActions.ADD_CHAR,
        InventoryActions.ADD_MINUS,
        InventoryActions.ADD_PLUS,
        InventoryActions.ADD_PERIOD,
        InventoryActions.ADD_COMMA,
      ], (acc, val) => {
        acc[val] = action((event: InventoryInputEvent<string>) => {
          createTransitions.call(this)[InventoryActions.ADD_CHAR](event);
        });

        return acc;
      }, {}),
      [InventoryActions.CANCEL]: action(async (event: InventoryInputEvent) => {
        await this.closeStockInStores();
      }),
      [InventoryActions.ENTER]: action(async (event: InventoryInputEvent) => {
        this.dispatchEvent(new Event(InventoryEvent.SEARCH_STOCK_IN_STORE));
      }),
      [InventoryActions.SCANNER]: action(async (event: InventoryInputEvent) => {
        this.state.inputBuffer = event.value;
        this.dispatchEvent(new Event(InventoryEvent.SEARCH_STOCK_IN_STORE));
      }),
    },
  }

  isValidQuantity = action((quantity: number) => {
    return !Number.isNaN(quantity) && quantity >= 0;
  })

  editActiveDocumentLogisticItemQuantityReal = action(async (quantity: number) => {
    const index = findIndex(
      this.state.loadedData,
      (item) => item.internalNumberWithBatch === this.state.activeDocumentLogisticItem.internalNumberWithBatch,
    );

    this.state.activeDocumentLogisticItem.quantityReal = quantity;
    this.state.loadedData[index].quantityReal = this.state.activeDocumentLogisticItem.quantityReal;
    this.state.loadedData = markRaw(toRaw(map(this.state.loadedData, (entityData) => {
      const item = new DocumentLogisticItemDto(entityData);
      item.calculateInventoryProperties();
      return item.toJson();
    })));

    await this.updateGridData();
    this.dispatchEvent(new CustomEvent(InventoryEvent.UPDATE_GRID_MODEL));
  })

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    if (bufferedInput.source === InputSource.SCANNER) {
      await this.onEventInput({
        type: InventoryActions.SCANNER,
        value: map(bufferedInput.keys, 'key').join(''),
      });
      return; // or sanitize buffered input and fallthrough?
    }

    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: InventoryActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_PERIOD) {
        await this.onEventInput({
          type: InventoryActions.ADD_PERIOD,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_COMMA) {
        await this.onEventInput({
          type: InventoryActions.ADD_COMMA,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: InventoryActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: InventoryActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: InventoryActions.CANCEL,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_UP) {
        await this.onEventInput({
          type: InventoryActions.PREV,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_DOWN) {
        await this.onEventInput({
          type: InventoryActions.NEXT,
        });
      } else if (key.key === KEYBOARD_KEY_PLUS) {
        await this.onEventInput({
          type: InventoryActions.ADD_PLUS,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_MINUS) {
        await this.onEventInput({
          type: InventoryActions.ADD_MINUS,
          value: key.key,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        await this.onEventInput({
          type: InventoryActions.ADD_CHAR,
          value: key.key,
        });
      }
    }
  });

  changeState = action(async (state: InventoryState, {
    previousState = this.state.state,
    resetBuffer = true,
    initArg = {},
  }: Partial<{
    previousState: InventoryState,
    resetBuffer: boolean,
    initArg: {[key: string]: any}
  }> = {}) => {
    if (this.transitions[previousState]?.[InventoryActions.BEFORE_LEAVE]) {
      const transition = this.transitions[previousState]?.[InventoryActions.BEFORE_LEAVE];
      if (!(await transition({from: previousState, to: state}) ?? true)) return;
    }

    if (this.transitions[state]?.[InventoryActions.BEFORE_ENTER]) {
      const transition = this.transitions[state]?.[InventoryActions.BEFORE_ENTER];
      if (!(await transition({from: state, to: state}) ?? true)) return;
    }

    if (resetBuffer) {
      this.resetInputBuffer();
    }

    this.state.previousState = previousState;
    this.state.state = state;
    if (this.transitions[state]?.[InventoryActions.INIT]) {
      this.dispatchEvent(new Event(AppLoaderEvent.ON));
      try {
        await this.transitions[state][InventoryActions.INIT]({
          previousState,
          ...initArg,
        });
      } finally {
        this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    }

    await this.persist();
    emitTestEvent(`${TestEvent.INVENTORY_STATE_CHANGED}:${state}`);
  })

  onEventInput = action(async (event: InventoryInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  resetInputBuffer = action(() => {
    this.state.inputBuffer = '';
  })

  fetchStock = action(async () => {
    this.state.stock = await apiStockGet({input: createInventoryStockFilter({})});

    return this.state.stock;
  })

  ensureStock = action(async ({clearCache = false} = {}) => {
    if (!this.state.stock) {
      await this.fetchStock();
    }

    try {
      return this.state.stock;
    } finally {
      if (clearCache) {
        this.state.stock = null;
      }
    }
  })

  validateInTransitMoves = action(async () => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON, {detail: {timeout: 90000}}));
      const stock = await this.ensureStock();
      return {
        stock: stock,
        canContinue: every(stock, (stockItem) => !stockItem.inTransitCount),
      };
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  });

  ensureInventoryStart = action(async () => {
    if (!this.isStateDefault.value && !this.isStateSelectMode) {
      return;
    }

    // if (isActiveFeatureInTransitStock()) {
    //   try {
    //     const {canContinue, stock} = await this.validateInTransitMoves();
    //
    //     if (!canContinue) {
    //       this.state.loadedData = flow([
    //         (stock) => reject(stock, (stockItem) => !stockItem.inTransitCount),
    //         (stock) => map(stock, (stockItem) => new DocumentLogisticItemDto({
    //           description: stockItem.description,
    //           internalNumber: stockItem.internalNumber,
    //           quantity: stockItem.inTransitCount,
    //           unit: stockItem.unitOfQuantityCode,
    //         }).toJson()),
    //       ])(stock);
    //
    //       await this.changeState(InventoryState.NON_EMPTY_IN_TRANSIT);
    //       return;
    //     }
    //   } catch (e) {
    //     console.error(e);
    //     this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
    //     return;
    //   }
    // }

    if (!this.isStateDefault.value) return;

    if (this.inventoryModeSelected.value) {
      return;
    }

    await this.changeState(InventoryState.SELECT_MODE);
  });

  inputBuffer = getter(() => this.state.inputBuffer)

  insertMode = getter(() => this.state.insertMode)

  loadedData = getter(() => this.state.loadedData)

  gridDataWithAppliedFiltersAndSort = getter(() => {
    let data = this.state.gridData;

    if (this.state.filters.search) {
      data = filter(data, (item) => {
        return !!flow(
          (props: string[]) => map(props, (prop) => toLower(deburr(prop))),
          (props: string[]) => map(props, (prop) => prop.includes(toLower(deburr(this.state.filters.search)))),
          (props: boolean[]) => some(props, (prop) => prop === true),
        )([item.internalNumber, item.gtin, item.description]);
      });
    }

    if (this.state.filters.quantityDifference === InventoryQuantityDifferenceFilter.ONLY_DIFFERENCES) {
      data = filter(data, (item) => item.quantityDifference !== 0);
    }

    return sortBy(data, 'internalNumberWithBatch');
  })

  filters = getter(() => this.state.filters)

  inventoryCalculationType = getter(() => this.state.inventoryCalculationType)

  inventoryDocument = getter(() => this.state.inventoryDocument)

  partialMode = getter(() => this.state.partialMode)

  itemGroup = getter(() => this.state.itemGroup)

  inventoryModeSelected = getter(() => this.state.inventoryModeSelected)

  previewOnly = getter(() => this.state.previewOnly)

  activeDocumentLogisticItem = getter(() => this.state.activeDocumentLogisticItem)

  productDetail = getter(() => this.state.productDetail)

  displayState = getter(() => {
    if (this.state.state === InventoryState.DEFAULT || this.state.state === InventoryState.SHOW_SUMMARY) {
      return InventoryDisplayState.SEARCH_ARTICLE;
    } else if (this.state.state === InventoryState.SHOW_IMAGE || this.state.state === InventoryState.EDIT_QUANTITY) {
      return InventoryDisplayState.EDIT_QUANTITY;
    } else {
      return InventoryDisplayState.SEARCH_ARTICLE;
    }
  })

  currentState = getter(() => this.state.state)

  previousState = getter(() => this.state.previousState)

  isStateDefault = getter(
    () => this.state.state === InventoryState.DEFAULT,
  )

  isStateSearch = getter(
    () => this.state.state === InventoryState.SEARCH,
  )

  isStateShowSummary = getter(
    () => this.state.state === InventoryState.SHOW_SUMMARY,
  )

  isStateEditQuantity = getter(
    () => this.state.state === InventoryState.EDIT_QUANTITY,
  )

  isStateShowImage = getter(
    () => this.state.state === InventoryState.SHOW_IMAGE,
  )

  isStateSearchProducts = getter(
    () => this.state.state === InventoryState.SEARCH_PRODUCTS,
  )

  isStateSelectMode = getter(
    () => this.state.state === InventoryState.SELECT_MODE,
  )

  isStateSearchItemGroupList = getter(
    () => this.state.state === InventoryState.SEARCH_ITEM_GROUP_LIST,
  )

  isStateSearchItemGroupRange = getter(
    () => this.state.state === InventoryState.SEARCH_ITEM_GROUP_RANGE,
  )

  isStateNonEmptyInTransit = getter(
    () => this.state.state === InventoryState.NON_EMPTY_IN_TRANSIT,
  )

  isStateSelectPrintTemplate= getter(
    () => this.state.state === InventoryState.SELECT_PRINT_TEMPLATE,
  )

  isStateItemDetail = getter(
    () => this.state.state === InventoryState.ITEM_DETAIL,
  )

  isStateStockInStores = getter(
    () => this.state.state === InventoryState.STOCK_IN_STORES,
  )

  isInventoryCalculationTypeFull = getter(() => {
    return this.inventoryCalculationType.value === InventoryCalculationType.FULL;
  })

  isInventoryCalculationTypePartial = getter(() => {
    return this.inventoryCalculationType.value === InventoryCalculationType.PARTIAL;
  })

  showCustomTypes = getter(() => {
    return this.state.showCustomTypes === true;
  })

  setInventoryCalculationType = action(async (type: InventoryCalculationType) => {
    if (type !== this.state.inventoryCalculationType) {
      this.state.loadedData = [];
    }

    this.state.inventoryCalculationType = type;
  });

  setPartialMode = action(async (mode: InventoryPartialMode) => {
    this.state.partialMode = mode;
  });

  setShowCustomTypes = action(async (val: boolean) => {
    this.state.showCustomTypes = val;
  });

  setCustomType = action(async (val: InventoryCustomType) => {
    this.state.customType = val;
  });

  printContentStore = getter(() => {
    return usePrintContentStore();
  })

  documentStatusStore = getter(() => {
    return useDocumentStatusStore();
  })

  inventoryGroups = getter(() => {
    return this.state.inventoryGroups;
  })

  configurationStore = getter(() => {
    return useConfigurationStore();
  })

  customTypes = getter(() => {
    return this
      .configurationStore.value
      .configuration.value
      ?.features
      ?.inventory
      ?.customTypes ?? [];
  })

  customTypesByCodes = getter(() => {
    return mapKeys(this.customTypes.value, 'code');
  })

  activeCustomType = getter(() => {
    return this.state.customType;
  })

  disableEditForInvalidArticles = getter(() => {
    return this
      .configurationStore.value
      .configuration.value
      ?.features
      ?.inventory
      ?.disableEditForInvalidArticles ?? false;
  })

  disableFinishWithNegativeQuantityArticles = getter(() => {
    return this
      .configurationStore.value
      .configuration.value
      ?.features
      ?.inventory
      ?.disableFinishWithNegativeQuantityArticles ?? false;
  })

  inventoryMetadata = getter(() => {
    return this.state.inventoryMetadata;
  })

  inventoryGroupInputs = getter(() => {
    return this.state.inventoryGroupInputs;
  })

  isInventoryGroupInputsValid = getter(() => {
    return some(this.state.inventoryGroupInputs, (group) => {
      return some(group.inputs, (input) => {
        return !!input.value;
      });
    });
  })

  isInventoryGroupInputsFromToRangeValid = getter(() => {
    const groupInputsWithFilledValue = this.state.inventoryGroupInputs.filter((group) => {
      return group.inputs.some((input) => !!input.value);
    });

    return every(groupInputsWithFilledValue, (group) => {
      return every(group.inputs, (input) => {
        return !!input.value;
      });
    });
  })

  changeGroupModeToList = action(() => {
    this.setPartialMode(InventoryPartialMode.ITEM_GROUP_LIST);
    this.changeState(InventoryState.SEARCH_ITEM_GROUP_LIST);
    this.state.inventoryGroupInputs = [createInitGroupInputList({active: true})];
  })

  changeGroupModeToRange = action(() => {
    this.setPartialMode(InventoryPartialMode.ITEM_GROUP_RANGE);
    this.changeState(InventoryState.SEARCH_ITEM_GROUP_RANGE);
    this.state.inventoryGroupInputs = [createInitGroupInputRange({active: true})];
  })

  addInventoryGroupInput = action((type: InventoryGroupInputType) => {
    const newInput = type === InventoryGroupInputType.LIST ?
      createInitGroupInputList() :
      createInitGroupInputRange();

    this.state.inventoryGroupInputs = [
      ...this.state.inventoryGroupInputs,
      newInput,
    ];
  })

  removeInventoryGroupInput = action((index: number) => {
    this.state.inventoryGroupInputs = [
      ...this.state.inventoryGroupInputs.slice(0, index),
      ...this.state.inventoryGroupInputs.slice(index + 1),
    ];
  })

  setActiveInventoryGroupInput = action((groupIndex, inputIndex) => {
    this.state.inventoryGroupInputs = map(this.state.inventoryGroupInputs, (group, groupKey) => {
      // @ts-expect-error
      group.inputs = map(group.inputs, (input, index) => {
        input.active = groupKey === groupIndex && index === inputIndex;
        return input;
      });
      return group;
    });
  })

  solvePrinterStatus = action(async (result: any, document: DocumentDto) => {
    try {
      return await this.documentStatusStore.value.solve(result, document);
    } catch (e) {
      console.error(e);
    }
  })

  refreshInventoryData = action(async () => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const dataForReportInternalNumbers = this.isInventoryCalculationTypePartial.value ?
        map(this.state.loadedData, 'internalNumber') :
        [];

      const {
        stockByInternalNumberWithBatch,
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({
        stockFilter: createInventoryStockFilter({internalNumbers: dataForReportInternalNumbers}),
      });

      this.state.loadedData = markRaw(toRaw(map(this.state.loadedData, (logisticItem) => {
        const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
        const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];

        const newLogisticItem = new DocumentLogisticItemDto({
          ...logisticItem,
          quantity: stockItem?.count ?? 0,
          quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          unit: stockItem?.unitOfQuantityCode ??
            ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.unitOfQuantityCode,
        });

        newLogisticItem.calculateInventoryProperties();

        return newLogisticItem.toJson();
      })));

      await this.calculateInventoryGroups();
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
    } finally {
      await this.updateGridData();
      this.dispatchEvent(new CustomEvent(InventoryEvent.UPDATE_GRID_MODEL));
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  fetchInventoryData = action(async () => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));
      this.state.inventoryDocument = DocumentDto.createInventorySummaryDocument();

      const isFullInventory = this.inventoryCalculationType.value === InventoryCalculationType.FULL;

      const isPartialInventory = this.inventoryCalculationType.value === InventoryCalculationType.PARTIAL;

      const isGroupListInventory = isPartialInventory &&
        this.partialMode.value === InventoryPartialMode.ITEM_GROUP_LIST;

      const isGroupRangeInventory = isPartialInventory &&
        this.partialMode.value === InventoryPartialMode.ITEM_GROUP_RANGE;

      const isCustomInventory = isPartialInventory &&
        this.partialMode.value === InventoryPartialMode.CUSTOM;

      if (isFullInventory) {
        this.state.loadedData = await this.fetchFullInventoryData();
        await this.calculateInventoryGroups();
        return;
      } else if (isGroupListInventory) {
        this.state.loadedData = await this.fetchGroupListInventoryData();
        await this.calculateInventoryGroups();
        return;
      } else if (isGroupRangeInventory) {
        this.state.loadedData = await this.fetchGroupRangeInventoryData();
        await this.calculateInventoryGroups();
        return;
      } else if (isCustomInventory) {
        this.state.loadedData = await this.fetchCustomInventoryData();
        await this.calculateInventoryGroups();
      } else if (isPartialInventory) {
        this.state.loadedData = await this.fetchPartialInventoryData();
        await this.calculateInventoryGroups();
        return;
      } else {
        this.state.loadedData = [];
        return;
      }
    } catch (e) {
      console.error(e);
    } finally {
      await this.updateGridData();
      this.dispatchEvent(new CustomEvent(InventoryEvent.UPDATE_GRID_MODEL));
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
      emitTestEvent(TestEvent.INVENTORY_DATA_FETCHED);
    }
  })

  fetchGroupListInventoryData = action(async () => {
    try {
      await this.fetchInventoryMetadata();
      const dataForReport = (await apiInventoryDataForReport({})).logisticItems;
      const dataForReportByInternalNumberWithBatch = mapKeys(dataForReport, 'internalNumberWithBatch');

      const groupListPromises = this.inventoryGroupInputs.value
        .filter((group) => group.inputs.every((input) => input.value))
        .map((group) => apiSearchByGroup({params: {group: group.inputs[0].value}}));


      const searchResults = sanitizeApiSearch(
        (await Promise.all(groupListPromises)).flat(),
      ).documentItems;

      const {length: skippedArticlesCount} = differenceBy(dataForReport, searchResults, 'internalNumberWithBatch');

      if (skippedArticlesCount) {
        this.dispatchEvent(new CustomEvent(InventoryEvent.SKIPPED_ARTICLES, {detail: skippedArticlesCount}));
      }

      const inventoryArticlesData = flow(
        (documentItems) => filter(documentItems, (documentItem) => !documentItem.isSet),
        (documentItems) => map(documentItems, (documentItem: DocumentItemDto) => {
          const documentLogisticItem: DocumentLogisticItemDto = documentItem.toDocumentLogisticItem();
          const dataForReportItem = dataForReportByInternalNumberWithBatch[documentItem.internalNumberWithBatch];

          documentLogisticItem.quantity = dataForReportItem?.quantity ?? 0;
          documentLogisticItem.quantityReal = dataForReportItem?.quantityReal ?? 0;
          documentLogisticItem.quantityStockOrdinary = 0;
          documentLogisticItem.quantityStockExchange = 0;
          documentLogisticItem.price = documentItem.toJson().priceNormal ?? 0;
          documentLogisticItem.scannedDateTime = dataForReportItem?.scannedDateTime ?? new Date();
          documentLogisticItem.vatCode = documentItem?.vatShort;
          documentLogisticItem.vatAmount = documentItem?.vatValue;

          return documentLogisticItem;
        }),
      )(searchResults);


      const dataForReportInternalNumbers = map(inventoryArticlesData, 'internalNumber');

      const {
        stockByInternalNumberWithBatch,
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({
        stockFilter: createInventoryStockFilter({internalNumbers: dataForReportInternalNumbers}),
      });

      const searchList = sanitizeApiSearch((await apiSearchListFiltered({
        input: new ArticleListFilterDto({
          articles: map(
            inventoryArticlesData,
            (item) => new ArticleFilterDescriptionDto({
              internalNumber: item.internalNumber,
              batch: item.batch || null,
            }).toJson(),
          ),
        }),
      }))).documentItems;

      const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

      const articleListByInternalNumberWithBatch = mapKeys(articleList, 'internalNumberWithBatch');

      return map(inventoryArticlesData, (logisticItem) => {
        const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
        const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];
        const articleListItem = articleListByInternalNumberWithBatch[internalNumberWithBatch];

        const calculatedLogisticItem = new DocumentLogisticItemDto({
          ...(stockItem ? {...pick(stockItem.toJson(), ['batch', 'batchLabel'])} : {}),
          ...logisticItem.toJson(),
          quantity: stockItem?.count ?? 0,
          quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          unit: stockItem?.unitOfQuantityCode ??
            ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.unitOfQuantityCode,
          vatCode: logisticItem?.vatCode ?? articleListItem?.vatShort,
          vatAmount: logisticItem?.vatAmount ?? articleListItem?.vatValue,
        });

        calculatedLogisticItem.calculateInventoryProperties();
        return markRaw(toRaw(calculatedLogisticItem.toJson(true)));
      });
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      this.changeState(this.previousState.value);
      throw e;
    }
  });

  fetchGroupRangeInventoryData = action(async () => {
    try {
      await this.fetchInventoryMetadata();
      const dataForReport = (await apiInventoryDataForReport({})).logisticItems;
      const dataForReportByInternalNumberWithBatch = mapKeys(dataForReport, 'internalNumberWithBatch');

      const groupRangeValues = this.inventoryGroupInputs.value
        .map((group) => group.inputs.map((input) => input.value))
        .filter(([fromValue, toValue]) => fromValue && toValue)
        .map(([fromValue, toValue]) => ({from: fromValue, to: toValue}));

      const groupSearchInput = new GroupRangeSearchDto({});

      groupSearchInput.setGroupRanges(groupRangeValues.map((range) => new GroupRangeDto({...range})));

      const searchResults = sanitizeApiSearch(await apiSearchByGroupRange({input: groupSearchInput})).documentItems;

      const {length: skippedArticlesCount} = differenceBy(dataForReport, searchResults, 'internalNumberWithBatch');

      if (skippedArticlesCount) {
        this.dispatchEvent(new CustomEvent(InventoryEvent.SKIPPED_ARTICLES, {detail: skippedArticlesCount}));
      }

      const inventoryArticlesData = flow(
        (documentItems) => filter(documentItems, (documentItem) => !documentItem.isSet),
        (documentItems) => map(documentItems, (documentItem: DocumentItemDto) => {
          const documentLogisticItem: DocumentLogisticItemDto = documentItem.toDocumentLogisticItem();
          const dataForReportItem = dataForReportByInternalNumberWithBatch[documentItem.internalNumberWithBatch];

          documentLogisticItem.quantity = dataForReportItem?.quantity ?? 0;
          documentLogisticItem.quantityReal = dataForReportItem?.quantityReal ?? 0;
          documentLogisticItem.quantityStockOrdinary = 0;
          documentLogisticItem.quantityStockExchange = 0;
          documentLogisticItem.price = documentItem.toJson().priceNormal ?? 0;
          documentLogisticItem.scannedDateTime = dataForReportItem?.scannedDateTime ?? new Date();
          documentLogisticItem.vatCode = documentItem?.vatShort;
          documentLogisticItem.vatAmount = documentItem?.vatValue;

          return documentLogisticItem;
        }),
      )(searchResults);


      const dataForReportInternalNumbers = map(inventoryArticlesData, 'internalNumber');

      const {
        stockByInternalNumberWithBatch,
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({
        stockFilter: createInventoryStockFilter({internalNumbers: dataForReportInternalNumbers}),
      });

      const searchList = sanitizeApiSearch((await apiSearchListFiltered({
        input: new ArticleListFilterDto({
          articles: map(
            inventoryArticlesData,
            (item) => new ArticleFilterDescriptionDto({
              internalNumber: item.internalNumber,
              batch: item.batch || null,
            }).toJson(),
          ),
        }),
      }))).documentItems;

      const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

      const articleListByInternalNumberWithBatch = mapKeys(articleList, 'internalNumberWithBatch');

      return map(inventoryArticlesData, (logisticItem) => {
        const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
        const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];
        const articleListItem = articleListByInternalNumberWithBatch[internalNumberWithBatch];

        const calculatedLogisticItem = new DocumentLogisticItemDto({
          ...(stockItem ? {...pick(stockItem.toJson(), ['batch', 'batchLabel'])} : {}),
          ...logisticItem.toJson(),
          quantity: stockItem?.count ?? 0,
          quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          unit: stockItem?.unitOfQuantityCode ??
            ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.unitOfQuantityCode,
          vatCode: logisticItem?.vatCode ?? articleListItem?.vatShort,
          vatAmount: logisticItem?.vatAmount ?? articleListItem?.vatValue,
        });

        calculatedLogisticItem.calculateInventoryProperties();
        return markRaw(toRaw(calculatedLogisticItem.toJson(true)));
      });
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      this.changeState(this.previousState.value);
      throw e;
    }
  });

  fetchCustomInventoryData = action(async () => {
    try {
      await this.fetchInventoryMetadata();
      const dataForReport = (await apiInventoryDataForReport({})).logisticItems;
      const dataForReportByInternalNumberWithBatch = mapKeys(dataForReport, 'internalNumberWithBatch');

      const articlesGet = await apiInventoryCustomArticlesGet({params: {code: this.activeCustomType.value.code}});

      const searchResults = sanitizeApiSearch(articlesGet).documentItems;

      const {length: skippedArticlesCount} = differenceBy(dataForReport, searchResults, 'internalNumberWithBatch');

      if (skippedArticlesCount) {
        this.dispatchEvent(new CustomEvent(InventoryEvent.SKIPPED_ARTICLES, {detail: skippedArticlesCount}));
      }

      const inventoryArticlesData = flow(
        (documentItems) => filter(documentItems, (documentItem) => !documentItem.isSet),
        (documentItems) => map(documentItems, (documentItem: DocumentItemDto) => {
          const documentLogisticItem: DocumentLogisticItemDto = documentItem.toDocumentLogisticItem();
          const dataForReportItem = dataForReportByInternalNumberWithBatch[documentItem.internalNumberWithBatch];

          documentLogisticItem.quantity = dataForReportItem?.quantity ?? 0;
          documentLogisticItem.quantityReal = dataForReportItem?.quantityReal ?? 0;
          documentLogisticItem.quantityStockOrdinary = 0;
          documentLogisticItem.quantityStockExchange = 0;
          documentLogisticItem.price = documentItem.toJson().priceNormal ?? 0;
          documentLogisticItem.scannedDateTime = dataForReportItem?.scannedDateTime ?? new Date();
          documentLogisticItem.vatCode = documentItem?.vatShort;
          documentLogisticItem.vatAmount = documentItem?.vatValue;

          return documentLogisticItem;
        }),
      )(searchResults);


      const dataForReportInternalNumbers = map(inventoryArticlesData, 'internalNumber');

      const {
        stockByInternalNumberWithBatch,
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({
        stockFilter: createInventoryStockFilter({internalNumbers: dataForReportInternalNumbers}),
      });

      const searchList = sanitizeApiSearch((await apiSearchListFiltered({
        input: new ArticleListFilterDto({
          articles: map(
            inventoryArticlesData,
            (item) => new ArticleFilterDescriptionDto({
              internalNumber: item.internalNumber,
              batch: item.batch || null,
            }).toJson(),
          ),
        }),
      }))).documentItems;

      const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

      const articleListByInternalNumberWithBatch = mapKeys(articleList, 'internalNumberWithBatch');

      return map(inventoryArticlesData, (logisticItem) => {
        const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
        const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];
        const articleListItem = articleListByInternalNumberWithBatch[internalNumberWithBatch];

        const calculatedLogisticItem = new DocumentLogisticItemDto({
          ...(stockItem ? {...pick(stockItem.toJson(), ['batch', 'batchLabel'])} : {}),
          ...logisticItem.toJson(),
          quantity: stockItem?.count ?? 0,
          quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          unit: stockItem?.unitOfQuantityCode ??
              ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.unitOfQuantityCode,
          vatCode: logisticItem?.vatCode ?? articleListItem?.vatShort,
          vatAmount: logisticItem?.vatAmount ?? articleListItem?.vatValue,
        });

        calculatedLogisticItem.calculateInventoryProperties();
        return markRaw(toRaw(calculatedLogisticItem.toJson(true)));
      });
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      this.changeState(this.previousState.value);
      throw e;
    }
  })

  fetchPartialInventoryData = action(async () => {
    try {
      await this.fetchInventoryMetadata();
      const dataForReport = (await apiInventoryDataForReport({})).logisticItems;
      const dataForReportInternalNumbers = map(dataForReport, 'internalNumber');

      const {
        stockByInternalNumberWithBatch,
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({
        stockFilter: createInventoryStockFilter({internalNumbers: dataForReportInternalNumbers}),
      });

      const searchList = sanitizeApiSearch((await apiSearchListFiltered({
        input: new ArticleListFilterDto({
          articles: map(
            dataForReport,
            (item) => new ArticleFilterDescriptionDto({
              internalNumber: item.internalNumber,
              batch: item.batch || null,
            }).toJson(),
          ),
        }),
      }))).documentItems;

      const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

      const articleListByInternalNumberWithBatch = mapKeys(articleList, 'internalNumberWithBatch');

      return map(dataForReport, (logisticItem) => {
        const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
        const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];
        const articleListItem = articleListByInternalNumberWithBatch[internalNumberWithBatch];

        const calculatedLogisticItem = new DocumentLogisticItemDto({
          ...(stockItem ? {...pick(stockItem.toJson(), ['batch', 'batchLabel'])} : {}),
          ...logisticItem.toJson(),
          quantity: stockItem?.count ?? 0,
          quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          unit: stockItem?.unitOfQuantityCode ??
            ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.unitOfQuantityCode,
          vatCode: logisticItem?.vatCode ?? articleListItem?.vatShort,
          vatAmount: logisticItem?.vatAmount ?? articleListItem?.vatValue,
        });

        calculatedLogisticItem.calculateInventoryProperties();
        return markRaw(toRaw(calculatedLogisticItem.toJson(true)));
      });
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      throw e;
    }
  })

  fetchFullInventoryData = action(async () => {
    try {
      const stock = await this.ensureStock({clearCache: true});
      const dataForReport = (await apiInventoryDataForReport({})).logisticItems;

      const stockByInternalNumberWithBatch = mapKeys(stock, 'internalNumberWithBatch');

      const dataForReportByInternalNumberWithBatch = mapKeys(dataForReport, 'internalNumberWithBatch');

      const dataForReportInternalNumbersWithBatch = map(dataForReport, 'internalNumberWithBatch');
      const stockInternalNumbersWithBatch = map(stock, 'internalNumberWithBatch');

      const internalNumbersWithBatch = union(dataForReportInternalNumbersWithBatch, stockInternalNumbersWithBatch);

      const stockMissingInternalNumbersWithBatch = difference(internalNumbersWithBatch, stockInternalNumbersWithBatch);
      const dataForReportMissingInternalNumbersWithBatch = difference(
        internalNumbersWithBatch, dataForReportInternalNumbersWithBatch,
      );

      const unifiedStock: StockDto[] = [].concat(
        stock,
        map(stockMissingInternalNumbersWithBatch, (internalNumberWithBatch) => {
          const dataForReportItem = dataForReportByInternalNumberWithBatch[internalNumberWithBatch];

          return new StockDto({
            internalNumber: dataForReportItem.internalNumber,
            batch: dataForReportItem.batch || null,
            description: dataForReportItem?.description,
            count: 0,
          });
        }),
      );

      const {
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({stock: unifiedStock, stockFilter: null});

      const searchList = sanitizeApiSearch((await apiSearchListFiltered({
        input: new ArticleListFilterDto({
          articles: map(
            unifiedStock,
            (stockItem) => new ArticleFilterDescriptionDto({
              internalNumber: stockItem.internalNumber,
              batch: stockItem.batch || null,
            }).toJson(),
          ),
        }),
      }))).documentItems;

      const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

      const articleListByInternalNumberWithBatch = mapKeys(articleList, 'internalNumberWithBatch');

      const missingStockItems = dataForReport
        .filter(({internalNumberWithBatch}) => {
          const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];

          return !stockItem;
        })
        .map((logisticItem) => logisticItem.internalNumber);

      const result = flow(
        () => {
          return [].concat(
            dataForReport,
            map(dataForReportMissingInternalNumbersWithBatch, (internalNumberWithBatch) => {
              const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];

              if (!stockItem) {
                return null;
              }

              return new DocumentLogisticItemDto({
                ...pick(stockItem.toJson(), ['batch', 'batchLabel', 'internalNumber']),
              });
            }),
          );
        },
        (logisticItems: DocumentLogisticItemDto[]) => filter(logisticItems, (logisticItem) => {
          if (logisticItem) {
            const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
            const articleListItem = articleListByInternalNumberWithBatch[internalNumberWithBatch];

            return !!articleListItem;
          }

          return false;
        }),
        (logisticItems: DocumentLogisticItemDto[]) => map(logisticItems, (logisticItem) => {
          const internalNumberWithBatch = logisticItem.internalNumberWithBatch;
          const dataForReportItem = dataForReportByInternalNumberWithBatch[internalNumberWithBatch];
          const articleListItem = articleListByInternalNumberWithBatch[internalNumberWithBatch];
          const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];

          const calculatedLogisticItem = new DocumentLogisticItemDto({
            ...logisticItem.toJson(),
            ...(stockItem ? pick(stockItem.toJson(), ['batch', 'batchLabel']) : {}),
            gtin: dataForReportItem?.gtin ?? articleListItem?.gtin,
            taxStamp: dataForReportItem?.taxStamp ?? articleListItem?.taxStamp,
            internalNumber: stockItem?.internalNumber ?? dataForReportItem?.internalNumber,
            description: stockItem?.description ?? dataForReportItem?.description,
            price: articleListItem?.priceNormal ?? dataForReportItem?.price ?? 0,
            assortmentInfo: articleListItem?.assortmentInfo?.toJson() ?? dataForReportItem?.assortmentInfo?.toJson(),
            saleValidFrom: articleListItem?.saleValidFrom?.toISOString(),
            saleValidTill: articleListItem?.saleValidTill?.toISOString(),
            unit: stockItem?.unitOfQuantityCode ??
              ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.unitOfQuantityCode ??
              dataForReportItem?.unit,
            imageId: articleListItem?.imageId,
            inventoryGroup: articleListItem?.systemAttributes?.inventoryGroup,
            vatCode: articleListItem?.vatShort,
            vatAmount: articleListItem?.vatValue,
            quantity: stockItem?.count ?? 0,
            quantityReal: dataForReportItem?.quantityReal ?? 0,
            quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
            quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
          });

          calculatedLogisticItem.calculateInventoryProperties();

          return markRaw(toRaw(calculatedLogisticItem.toJson(true)));
        }),
      )();

      if (missingStockItems.length) {
        this.dispatchEvent(new CustomEvent(InventoryEvent.MISSING_STOCK_ITEMS, {detail: missingStockItems}));
      }

      return result;
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      throw e;
    }
  })

  finishInventoryProcess = action(async ({template = InventoryPrintTemplate.ALL} = {}) => {
    let inventoryDocumentResult: Awaited<ReturnType<DocumentSave['run']>>;

    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON, {detail: {timeout: null}}));

      inventoryDocumentResult = await DocumentSave.run(async () => {
        const document: DocumentDto = this.state.inventoryDocument.clone();

        document.logisticItems = map(
          filterLogisticItemsByPrintTemplate(this.loadedData.value, template),
          (entityData) => new DocumentLogisticItemDto(entityData),
        );

        document.header.previewOnly = this.previewOnly.value;
        document.header.inventoryType = new InventoryType(
          this.state.inventoryCalculationType,
        );

        if (this.activeCustomType.value) {
          document.inventory.customInventoryCode = this.activeCustomType.value.code;
          document.inventory.customInventoryName = this.customTypesByCodes
            .value[this.activeCustomType.value.code]
            ?.name;
        }

        const {documentType, printOnlyDifferences} = getDocumentPropertiesByPrintTemplate(template);

        document.documentType = documentType;
        document.documentSubType = new FiscalCommands(
          this.state.inventoryCalculationType === InventoryCalculationType.FULL ?
            DocumentTypes.InventoryFull : DocumentTypes.InventoryPartial,
        );

        document.inventory.printOnlyDifferences = this.previewOnly.value ? printOnlyDifferences : true;

        document.calculateInventoryStock(
          map(
            this.loadedData.value,
            (entityData) => new DocumentLogisticItemDto(entityData),
          ),
        );

        document.inventoryGroups = await this.calculateInventoryGroups({withFetch: false});

        document.setInventoryInfo(this.inventoryMetadata.value);

        return document;
      });

      inventoryDocumentResult.result = new PrinterResult(inventoryDocumentResult.result ?? {});

      if (inventoryDocumentResult.error) {
        throw inventoryDocumentResult.error;
      }

      if (inventoryDocumentResult.result.hasError) {
        throw new Error(inventoryDocumentResult.result.errorMessage);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.value.terminate();
      }

      if (!inventoryDocumentResult?.result?.hasError) {
        this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      }

      throw e;
    } finally {
      await (async () => {
        this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));

        if (!inventoryDocumentResult?.created) {
          return;
        }

        if (isActiveFeaturePrintDisplayOnScreen() && inventoryDocumentResult?.result?.hasValidPrintContent) {
          await this.printContentStore.value.open(
            inventoryDocumentResult.result.printContent,
            {
              modalType: ModalTypes.xl,
            },
          );
        }

        if (!this.previewOnly.value) {
          await this.resetState();
          this.dispatchEvent(new CustomEvent(InventoryEvent.EXIT));
        }
      })();
    }
  })

  fetchInventoryMetadata = action(async () => {
    const inventoryMetadata = await apiInventoryMetadata(
      {params: {inventoryType: this.inventoryCalculationType.value}},
    );
    this.state.inventoryMetadata = inventoryMetadata;
    return inventoryMetadata;
  })

  calculateInventoryGroups = action(async ({withFetch = true} = {}) => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      if (withFetch) {
        this.state.inventoryGroups = await this.calculateInventoryGroupsWithFetch();
      } else {
        this.state.inventoryGroups = await this.calculateInventoryGroupsWithoutFetch();
      }

      return this.state.inventoryGroups;
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
      throw e;
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  calculateVatResults = action((groupItems) => {
    return flow(
      (vatObj) => values(vatObj),
      (vatArr) => uniqBy(vatArr, 'amount'),
      (vatArr) => map(vatArr, (vatItem) => {
        const itemsInVat = filter(groupItems, (item) => item?.vatAmount === vatItem?.amount);

        const totalSum = fixDecimals(sumBy(itemsInVat, 'valueDifference')) * -1;
        const vatAmount = vatItem.amount;

        return new InventoryGroupResultsByVatDto({
          vatAmount,
          totalSum,
        }).toJson();
      }),
      (vatResults) => orderBy(vatResults, 'vatAmount', 'desc'),
    )(this.configurationStore.value.configuration.value.enums.vat);
  })

  calculateInventoryGroupsWithFetch = action(async () => {
    const {inventoryGroups: inventoryGroupsFromApi} = await this.fetchInventoryMetadata();

    for (const inventoryGroupFromApi of inventoryGroupsFromApi) {
      if (this.isInventoryCalculationTypePartial.value) {
        inventoryGroupFromApi.allowedLostValue = 0;
      }

      inventoryGroupFromApi.originalAllowedLostValue = inventoryGroupFromApi.allowedLostValue;
    }

    const inventoryGroupsFromApiByInventoryGroup = mapKeys(inventoryGroupsFromApi, 'inventoryGroup');

    const inventoryGroupsFromArticles = flow(
      (loadedData) => map(loadedData, (item) => item?.inventoryGroup),
      (inventoryGroups) => uniq(inventoryGroups),
      (inventoryGroups) => sortBy(inventoryGroups),
      (inventoryGroups: (string | null)[]) => map(
        inventoryGroups,
        (inventoryGroup) => {
          const inventoryGroupFromApi: InventoryGroupMetadataDto = inventoryGroupsFromApiByInventoryGroup
            ?.[inventoryGroup];

          if (inventoryGroupFromApi) {
            return new InventoryGroupDto(inventoryGroupFromApi);
          } else if (!isNil(inventoryGroup)) {
            return new InventoryGroupDto({
              inventoryGroup: inventoryGroup,
              inventoryGroupText: inventoryGroup,
              allowedLostValue: 0,
              originalAllowedLostValue: 0,
              currency: inventoryGroupFromApi?.currency,
              lastInventoryDate: this.inventoryMetadata.value?.lastInventoryDate?.toISOString(),
              shopCode: this.inventoryMetadata.value?.shopCode,
              lastInventoryNumber: this.inventoryMetadata.value?.lastInventoryNumber,
              nextInventoryNumberOrder: this.inventoryMetadata.value?.nextInventoryNumberOrder,
            });
          } else {
            const unknownGroupItem = this.configurationStore
              ?.value
              ?.configuration
              ?.value
              ?.features
              ?.inventory
              ?.inventoryGroups
              ?.unknown;

            return new InventoryGroupDto({
              inventoryGroup: InventoryGroup.UNKNOWN,
              inventoryGroupText: unknownGroupItem?.inventoryGroupText,
              allowedLostValue: 0,
              originalAllowedLostValue: 0,
              currency: inventoryGroupFromApi?.currency,
              lastInventoryDate: this.inventoryMetadata.value?.lastInventoryDate?.toISOString(),
              shopCode: this.inventoryMetadata.value?.shopCode,
              lastInventoryNumber: this.inventoryMetadata.value?.lastInventoryNumber,
              nextInventoryNumberOrder: this.inventoryMetadata.value?.nextInventoryNumberOrder,
            });
          }
        },
      ),
    )(this.loadedData.value);

    const inventoryGroups = unionBy(inventoryGroupsFromApi, inventoryGroupsFromArticles, 'inventoryGroup' );

    return map(inventoryGroups, (group) => {
      const items = filter(
        this.state.loadedData,
        (item) => {
          return (item?.inventoryGroup ?? InventoryGroup.UNKNOWN) === group.inventoryGroup;
        },
      );

      const quantityDifference = fixDecimals(sumBy(items, 'quantityDifference'));
      const valueDifference = fixDecimals(sumBy(items, 'valueDifference'));
      const lostValue = valueDifference < 0 ? Math.abs(valueDifference) : 0;
      const allowedLostValue = Math.min(group.originalAllowedLostValue, lostValue);
      const lostValueToPay = fixDecimals(lostValue - allowedLostValue);

      const resultsByVat = this.calculateVatResults(items);

      return new DocumentInventoryGroup({
        ...group.toJson(),
        lostValue,
        lostValueToPay: lostValueToPay > 0 ? lostValueToPay : 0,
        allowedLostValue,
        resultsByVat,
        valueDifference,
        quantityDifference,
      });
    });
  });

  calculateInventoryGroupsWithoutFetch = action(async () => {
    return map(this.state.inventoryGroups, (group) => {
      const items = filter(
        this.state.loadedData,
        (item) => {
          return (item?.inventoryGroup ?? InventoryGroup.UNKNOWN) === group.inventoryGroup;
        },
      );

      const summarizedValueDifference = fixDecimals(sumBy(items, 'valueDifference'));
      const lostValue = summarizedValueDifference < 0 ? Math.abs(summarizedValueDifference) : 0;
      const allowedLostValue = Math.min(group.originalAllowedLostValue, lostValue);
      const lostValueToPay = fixDecimals(lostValue - allowedLostValue);

      const resultsByVat = this.calculateVatResults(items);

      return new DocumentInventoryGroup({
        ...group.toJson(),
        lostValue,
        lostValueToPay,
        allowedLostValue,
        resultsByVat,
      });
    });
  });

  setInventoryModeSelected = action(() => this.state.inventoryModeSelected = true)

  setPreviewOnly = action((value: boolean) => {
    this.state.previewOnly = value;
  })

  setSearchFilter = action((searchString: string) => {
    this.state.filters.search = searchString;
  })

  setQuantityDifferenceFilter = action(async (filter: InventoryQuantityDifferenceFilter) => {
    this.state.filters.quantityDifference = filter;
    this.dispatchEvent(new CustomEvent(InventoryEvent.UPDATE_GRID_MODEL));

    const itemIndex = findIndex(
      this.gridDataWithAppliedFiltersAndSort.value,
      (item) => item.internalNumberWithBatch === this.activeDocumentLogisticItem.value?.internalNumberWithBatch,
    );

    if (itemIndex < 0) {
      this.setActiveDocumentLogisticItem(null, {changeState: false});
    } else {
      this.dispatchEvent(new CustomEvent(InventoryEvent.SCROLL_TO, {detail: itemIndex}));
    }

    await this.persist();
  });

  isDocumentLogisticItemEditable = action((itemData: DocumentLogisticItemDto['_data']) => {
    if (this.disableEditForInvalidArticles.value) {
      return new DocumentLogisticItemDto(itemData).canBeManipulated;
    }

    return true;
  })

  setActiveDocumentLogisticItem = action(async (
    entityData: DocumentLogisticItemDto['_data'],
    {
      changeState = true,
      scrollTo = true,
    } = {},
  ) => {
    if (!entityData) {
      if (changeState) {
        await this.changeState(InventoryState.DEFAULT);
      }

      if (this.state.state === InventoryState.DEFAULT) {
        this.state.activeDocumentLogisticItem = null;
      }
      return;
    }

    if (!this.isDocumentLogisticItemEditable(entityData)) {
      return;
    }

    if (entityData.internalNumberWithBatch === this.activeDocumentLogisticItem.value?.internalNumberWithBatch) {
      if (changeState) {
        await this.changeState(InventoryState.DEFAULT);
      }

      if (this.state.state === InventoryState.DEFAULT) {
        this.state.activeDocumentLogisticItem = null;
      }
      return;
    }

    if (this.state.activeDocumentLogisticItem) {
      await this.changeState(InventoryState.DEFAULT);

      if (this.state.state === InventoryState.DEFAULT) {
        this.state.activeDocumentLogisticItem = null;
      } else {
        return;
      }
    }

    this.state.activeDocumentLogisticItem = new DocumentLogisticItemDto(entityData);

    if (scrollTo) {
      const index = this.findGridItemIndex(this.state.activeDocumentLogisticItem);

      if (index >= 0) {
        this.dispatchEvent(new CustomEvent(InventoryEvent.SCROLL_TO, {detail: index}));
      }
    }

    if (changeState) {
      await this.changeState(InventoryState.EDIT_QUANTITY);
    }
  })

  findGridItemIndex = action((gridItem: DocumentLogisticItemDto['_data'] | DocumentLogisticItemDto) => {
    return findIndex(
      this.gridDataWithAppliedFiltersAndSort.value,
      (entityData) => entityData.internalNumberWithBatch === gridItem.internalNumberWithBatch,
    );
  })

  selectProductSearchItem = action(async (item: DocumentItemDto) => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const documentItem = item.expandExpandableSet();

      if (documentItem.isSet) {
        await useHelpStore().show(InventoryErrors.ARTICLE_IS_TYPE_SET);
        return;
      }

      const productGroup = documentItem.systemAttributes.productGroup;

      if (this.itemGroup.value !== productGroup && this.partialMode.value === InventoryPartialMode.ITEM_GROUP) {
        await useHelpStore().show(InventoryErrors.ARTICLE_IS_NOT_PART_OF_ITEM_GROUP);
        return;
      }

      const data = pick(documentItem.toJson(), DocumentLogisticItemDto.fields);

      const foundItem = findInventoryItem.call(this, documentItem);

      if (foundItem) {
        const documentLogisticItem = new DocumentLogisticItemDto({
          quantity: 0,
          quantityReal: 0,
          quantityStockOrdinary: 0,
          quantityStockExchange: 0,
          price: documentItem.priceNormal ?? 0,
          vatCode: documentItem?.vatShort,
          vatAmount: documentItem?.vatValue,
          // NOTE: Watch out for different vat field namings from picked fields, spread could be problematic
          ...data,
          ...foundItem,
        });

        documentLogisticItem.scannedDateTime = new Date();
        documentLogisticItem.calculateInventoryProperties();
        this.setActiveDocumentLogisticItem(documentLogisticItem.toJson(true));
        return;
      }

      const internalNumber = documentItem.internalNumber;
      const internalNumberWithBatch = documentItem.internalNumberWithBatch;

      const {
        stockByInternalNumberWithBatch,
        ordinaryStockByInternalNumberWithBatch,
        exchangeStockByInternalNumberWithBatch,
      } = await getGroupedStock({
        stockFilter: createInventoryStockFilter({internalNumbers: [internalNumber]}),
      });

      const stockItem = stockByInternalNumberWithBatch[internalNumberWithBatch];

      const documentLogisticItem = new DocumentLogisticItemDto({
        // NOTE: Watch out for different vat field namings from picked fields, spread could be problematic
        ...data,
        quantity: stockItem?.count ?? 0,
        quantityReal: 0,
        quantityStockOrdinary: ordinaryStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
        quantityStockExchange: exchangeStockByInternalNumberWithBatch[internalNumberWithBatch]?.count ?? 0,
        price: documentItem?.priceNormal ?? 0,
        vatCode: documentItem?.vatShort,
        vatAmount: documentItem?.vatValue,
      });

      documentLogisticItem.scannedDateTime = new Date();
      documentLogisticItem.calculateInventoryProperties();

      const documentLogisticItemData = documentLogisticItem.toJson(true);

      this.state.loadedData = markRaw(toRaw([documentLogisticItemData, ...this.state.loadedData]));

      await this.calculateInventoryGroups();
      this.setActiveDocumentLogisticItem(documentLogisticItemData);
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  updateGridData = action(async () => {
    this.state.gridData = this.state.loadedData;
    await this.persist();
  })

  updateGridModel = action(async (persist = true) => {
    this.dispatchEvent(new CustomEvent(InventoryEvent.UPDATE_GRID_MODEL));

    if (persist) {
      await this.persist();
    }
  })

  resetState = action(async () => {
    this.state = Object.assign(this.state, createInitState());
    await this.persist();
  })

  setInputBuffer = action((value) => {
    this.state.inputBuffer = value;
  })

  openProductDetail = action(async (documentLogisticItem) => {
    this.state.productDetail = {
      item: documentLogisticItem,
    };
    this.changeState(InventoryState.ITEM_DETAIL);
  })

  closeProductDetail = action(() => {
    this.state.productDetail = null;
  })

  openStockInStores = action(async () => {
    await this.changeState(InventoryState.STOCK_IN_STORES);
  })

  closeStockInStores = action(async () => {
    await this.changeState(this.state.previousState);
  })

  dispatchCancelInventory = action((confirm: boolean) => {
    this.dispatchEvent(new CustomEvent(InventoryEvent.CANCEL_INVENTORY, {detail: {confirm}}));
  })

  cancelInventoryProcess = action(async () => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));
      await this.resetState();
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new CustomEvent(InventoryEvent.API_ERROR, {detail: e}));
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  validateQuantityReal = action(async () => {
    if (this.disableFinishWithNegativeQuantityArticles.value === false) {
      return true;
    }

    const negativeQuantityRealItems = this.loadedData.value
      .filter((item) => item.quantityReal < 0)
      .map((item) => item.internalNumberWithBatch);

    if (negativeQuantityRealItems.length > 0) {
      await useHelpStore().show(InventoryErrors.INVENTORY_NEGATIVE_QUANTITY_ARTICLES, {params: {
        internalNumbers: negativeQuantityRealItems,
      }});
      return false;
    }

    return true;
  })
}

const storeIdentifier = 'InventoryStore';

export const configureInventoryStore = createConfigureStore<typeof InventoryStore>(storeIdentifier);
export const useInventoryStore = createUseStore(InventoryStore, storeIdentifier);
