import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderDeliveryNoteItemDto from '../OrderDeliveryNoteItemDto';


export const OrderDeliveryNoteDtoPropDef = {
  orderNumber: {
    type: new StringType(),
  },
  orderDate: {
    type: new DateTimeType(),
  },
  customerName: {
    type: new StringType(),
  },
  items: {
    type: new ArrayType(new EntityType(OrderDeliveryNoteItemDto)),
  },
};
export default class GeneratedOrderDeliveryNoteDto extends createEntity(OrderDeliveryNoteDtoPropDef) {}
