import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import LayoutDefault from '@/Layout/LayoutDefault.vue';
import LayoutWithoutHeader from '@/Layout/LayoutWithoutHeader.vue';
import {RouterTree} from '@/ModuleSystem/routerTree';

export function getPosRouterTree() {
  const getRouterRootRouteConfig = (component, options = {}) => ({
    path: '/',
    component,
    children: [],
    redirect: '/login',
    props: {
      view: 'default',
    },
    async beforeEnter(to, from, next) {
      const configurationStore = useConfigurationStore();

      if (configurationStore.configuration.value?.isPosNotConfigured) {
        next('/pos-configuration');
      } else {
        next();
      }
    },
    ...options,
  });

  const routerTree: RouterTree = {
    default: getRouterRootRouteConfig(LayoutDefault),
    apps: getRouterRootRouteConfig(LayoutDefault, {
      redirect: '/apps',
      props: {
        view: 'apps',
      },
    }),
    login: getRouterRootRouteConfig(LayoutDefault, {
      redirect: '/login',
      props: {
        view: 'login',
      },
    }),
    withoutHeader: getRouterRootRouteConfig(LayoutWithoutHeader),
  };

  return routerTree;
}
