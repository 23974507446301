
import {defineComponent} from 'vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import {useConfirmContent} from '@/Modules/Core/Confirm/contents/content';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useTransitions} from '@/Helpers/transitions';

export default defineComponent({
  name: 'ModalConfirmContentTypeReRegistration',
  components: {ModalDetachedButtons, ModalDetachedClose, ModalDetachedBody},
  setup() {
    const coreStore = useCoreStore();
    const {transitionLeaving} = useTransitions();
    const {
      onConfirm,
      onReject,
      hasRejectFn,
      type,
    } = useConfirmContent();

    return {
      transitionLeaving,
      type,
      onConfirm,
      onReject,
      hasRejectFn,
      coreStore,
    };
  },
});
