export enum TestEvent {
  AD_CHANGED = 'adChanged',
  APP_READY = 'appReady',
  ARTICLE_SAVED = 'articleSaved',
  CONFIGURATION_CHANGES_PROCESSED = 'configurationChangesProcessed',
  CUSTOMER_ADDED = 'customerAdded',
  CUSTOMER_CARD_AVAILABLE_VERIFIED = 'customerCardAvailableVerified',
  CUSTOMER_CARD_REMOVED = 'customerCardRemoved',
  CUSTOMER_CREATED = 'customerCreated',
  CUSTOMER_PIN_REGENERATED = 'customerPinRegenerated',
  ERROR = 'error',
  FRACTION_DOCUMENTS_GRID_CHANGED = 'fractionDocumentsGridChanged',
  GIFT_OPTIONS_CHANGED = 'giftOptionsChanged',
  INACTIVITY_MODAL_OPEN = 'inactivityModalOpen',
  INVENTORY_DATA_FETCHED = 'inventoryDataFetched',
  INVENTORY_GRID_CHANGED = 'inventoryGridChanged',
  INVENTORY_PDA_PRINTED = 'inventoryPdaPrinted',
  INVENTORY_PDA_STATE_CHANGED = 'inventoryPDAStateChanged',
  INVENTORY_STATE_CHANGED = 'inventoryStateChanged',
  LOGIN = 'login',
  MODAL_CLOSED = 'modalClosed',
  MODAL_OPENED = 'modalOpened',
  PAYMENT_CHANGED = 'paymentChanged',
  RECEIPT_CANCELED = 'receiptCanceled',
  REFERENTIAL_DOCUMENT_DETAIL_OPENED = 'referentialDocumentDetailOpened',
  REFERENTIAL_DOCUMENT_GRID_CHANGED = 'referentialDocumentGridChanged',
  REFERENTIAL_DOCUMENTS_STATE_CHANGED = 'referentialDocumentsStateChanged',
  REFERENTIAL_DOCUMENTS_FILTERS_RESET = 'referentialDocumentsFiltersReset',
  REGISTER_PAGE_READY = 'registerPageReady',
  REGISTER_STATE_CHANGED = 'registerStateChanged',
  ROUTE_NAVIGATION = 'routeNavigation',
  SAFE_BAG_SCANNED = 'safeBagScanned',
  SEARCH = 'search',
  SEARCH_CUSTOMERS = 'searchCustomers',
  SEARCH_PRODUCTS = 'searchProducts',
  SEARCH_SUBJECT = 'searchSubject',
  SEARCH_STOCK_IN_STORES = 'searchStockInStores',
  SELL_DOCUMENT_SAVED = 'sellDocumentSaved',
  STOCK_REPORT_STATE_CHANGED = 'stockReportStateChanged',
  WORKFLOW_FIELD_CHANGED = 'workflowFieldChanged',
  WORKFLOW_METHOD_FINISHED = 'workflowMethodFinished',
  WORKFLOW_STEP_READY = 'workflowStepReady',
  GECO_GAME_STATE_CHANGED = 'gecoGameStateChanged',
  GECO_GAME_VIDEO_ERROR = 'gecoGameVideoError',
  GECO_GAME_VIDEO_ENDED = 'gecoGameVideoEnded',
  GECO_GAME_VIDEO_CAN_PLAY = 'gecoGameVideoCanPlay',
  WORKFLOW_STEP_AFTER_ENTER = 'workflowStepAfterEnter',
  ARTICLE_MANAGEMENT_LOADED = 'articleManagementLoaded',
  ARTICLE_MANAGEMENT_PLU_ACTIVATED = 'articleManagementPluActivated',
  ESHOP_ORDERS_GRID_CHANGED = 'eshopOrdersGridChanged',
  ESHOP_ORDER_DETAIL_LOADED = 'eshopOrderDetailLoaded',
  ESHOP_NOTIFICATIONS_UPDATE = 'eshopNotificationsUpdate',
  PAYMENT_VERIFICATION_EVENT = 'paymentVerificationEvent',
}

export enum TestEventPayload {
  UNAUTHORIZED = 'UNAUTHORIZED',
}
