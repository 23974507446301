import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TicketCalcWinsRequestResource from '../TicketCalcWinsRequestResource';


export const GeneratePossibleWinsCommandPropDef = {
  korunkaRequest: {
    type: new EntityType(TicketCalcWinsRequestResource),
  },
};
export default class GeneratedGeneratePossibleWinsCommand extends createEntity(GeneratePossibleWinsCommandPropDef) {}
