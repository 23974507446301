
import {defineComponent} from 'vue';
import Key from '@/Components/KeyboardKey.vue';
import {FourEyesActions} from '@/Modules/Core/types';
import IconEnter from '@/Components/Icons/IconEnter.vue';
import IconBack from '@/Components/Icons/IconUArrowLeft.vue';
import IconBackspace from '@/Components/Icons/IconBackspace.vue';

export default defineComponent({
  name: 'Keyboard',
  components: {
    IconEnter,
    IconBack,
    IconBackspace,
    Key,
  },
  emits: ['input', 'enter'],
  setup() {
    return {
      FourEyesActions,
    };
  },
});
