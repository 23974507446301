
import {
  computed,
  defineComponent,
} from 'vue';
import {useArticleLabel} from '@/Components/ArticleLabels/label';
import ArticleLabelMixin from '@/Components/ArticleLabels/ArticleLabelMixin.vue';

export default defineComponent({
  name: 'ArticleLabelDisplayType',
  mixins: [ArticleLabelMixin],
  setup(props) {
    const {theme, label} = useArticleLabel('setDisplayType');

    const isDisplayed = computed(() => props.forceVisible ?? props.item.isArticleLabelPackage);

    return {
      theme,
      label,
      isDisplayed,
    };
  },
});
