import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_LOGISTIC_CODE]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(() => {
        this.state.inputBuffer = this.state.productFlow.product.logisticCode ?? '';
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        try {
          if (!this.state.inputBuffer) return;

          this.state.productFlow.product.setLogisticCodeWithinContext(
            this.state.sellDocument,
            this.state.editOf,
            this.state.inputBuffer,
          );

          const {documentItems} = await this.searchSubject(this.state.inputBuffer);

          if (!documentItems.length) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_LOGISTIC_CODE, {
              detail: {
                value: this.state.inputBuffer,
              },
            }));
            return;
          }

          const [documentItem] = documentItems;

          if (!this.state.productFlow.product.validateLotteryCode(documentItem.lotteryCode)) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_LOGISTIC_CODE, {
              detail: {
                value: this.state.inputBuffer,
              },
            }));
            return;
          }

          if (!this.state.productFlow.product.validateCheckAndChargeTypeLotWithinContext(this.state.sellDocument)) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT));
            return;
          }

          await this.transitionToNextStep();
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        try {
          const {documentItems} = await this.searchSubject(event.value);

          this.state.productFlow.product.setLogisticCodeWithinContext(
            this.state.sellDocument,
            this.state.editOf,
            event.value,
          );

          if (!documentItems.length) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_LOGISTIC_CODE, {
              detail: {
                value: event.value,
              },
            }));
            return;
          }

          const [documentItem] = documentItems;

          if (!this.state.productFlow.product.validateLotteryCode(documentItem.lotteryCode)) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_LOGISTIC_CODE, {
              detail: {
                value: event.value,
              },
            }));
            return;
          }

          if (!this.state.productFlow.product.validateCheckAndChargeTypeLotWithinContext(this.state.sellDocument)) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT));
            return;
          }

          await this.transitionToNextStep();
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
    },
  };
}
