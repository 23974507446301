import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';
import {
  computed,
  watch,
} from 'vue';
import {useRouter} from 'vue-router';

export const useUpdate = () => {
  const updateStore = useUpdateStore();
  const router = useRouter();

  const updateParts = computed(() => ({
    route: router.currentRoute.value,
    isUpdateTypeImmediate: updateStore.isUpdateTypeImmediate.value,
    isScheduled: updateStore.isScheduled.value,
    allUpdateConditionsAreMet: updateStore.allUpdateConditionsAreMet.value,
  }));


  watch(() => updateParts.value, ({route, isUpdateTypeImmediate, isScheduled, allUpdateConditionsAreMet}) => {
    if (isUpdateTypeImmediate && route.name === 'login' && isScheduled && allUpdateConditionsAreMet) {
      updateStore.startUpdate();
    }
  });
};
