import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const PromotionDtoPropDef = {
  id: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  state: {
    type: new StringType(),
  },
  isValid: {
    type: new BooleanType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  createdDate: {
    type: new DateTimeType(),
  },
  createdById: {
    type: new StringType(),
  },
  createdByName: {
    type: new StringType(),
  },
  editedDate: {
    type: new DateTimeType(),
  },
  editedById: {
    type: new StringType(),
  },
  editedByName: {
    type: new StringType(),
  },
  triggerEvents: {
    type: new StringType(),
  },
  promotionScript: {
    type: new StringType(),
  },
};
export default class GeneratedPromotionDto extends createEntity(PromotionDtoPropDef) {}
