import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TurnoverByVatDto from '../TurnoverByVatDto';
import {DateTimeType} from '@designeo/apibundle-js';


export const TurnoverPerDayReportDtoPropDef = {
  turnoverBrutto: {
    type: new NumberType(),
  },
  turnoverNetto: {
    type: new NumberType(),
  },
  discounts: {
    type: new ArrayType(new NumberType()),
  },
  turnoverByVats: {
    type: new ArrayType(new EntityType(TurnoverByVatDto)),
  },
  date: {
    type: new DateTimeType(),
  },
};
export default class GeneratedTurnoverPerDayReportDto extends createEntity(TurnoverPerDayReportDtoPropDef) {}
