import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-grow flex-col" }
const _hoisted_3 = { class: "uppercase mb-6" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "text-xl font-bold uppercase whitespace-pre-line mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, _toDisplayString(workflowStep.step.labelOrderNumber) + ": " + _toDisplayString(workflowStep.checkResponse.entity.id), 1),
            _createElementVNode("div", null, _toDisplayString(workflowStep.step.labelOrderCode) + ": " + _toDisplayString(workflowStep.checkResponse.entity.code), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(workflowStep.step.labelOrderStatus) + ": " + _toDisplayString(_ctx.orderState?.[workflowStep.checkResponse.entity.status]
                || workflowStep.checkResponse.entity.status), 1),
            _createElementVNode("div", null, _toDisplayString(workflowStep.step.labelAmount) + ": " + _toDisplayString(_ctx.currencyFormat(
                workflowStep.checkResponse.entity.fiat_amount,
                workflowStep.checkResponse.entity.fiat_code
              )), 1)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.interpolate(
              workflowStep.step.labelDeposit,
              {
                'amountWithCurrency': _ctx.currencyFormat(
                  workflowStep.checkResponse.entity.fiat_amount,
                  workflowStep.checkResponse.entity.fiat_code
                ),
              }
            )), 1),
          _createVNode(_component_WorkflowTransitions)
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}