import {
  App,
} from 'vue';
import {createStartup} from '@/createStartup';
import {RouterTree} from '@/ModuleSystem/routerTree';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
  replicateRouterTree: ()=> RouterTree
}

export type RegisterTestHelpersContext = {
}

export async function registerStartup(ctx: CoreRequiredContext) {
  let startup = await createStartup(ctx.replicateRouterTree());
  startup.mount('#startup');

  let closed = false;

  const ensureStartupOpen = async () => {
    if (!closed) {
      ensureStartupClose();
    }

    startup = await createStartup(ctx.replicateRouterTree());
    startup.mount('#startup');
    closed = false;
  };

  const ensureStartupClose = () => {
    if (!closed) {
      closed = true;
      startup.unmount();
    }
  };

  return {
    ensureStartupOpen,
    ensureStartupClose,
  };
}
