import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import StockDto from '../StockDto';


export const StockReportDtoPropDef = {
  stock: {
    type: new ArrayType(new EntityType(StockDto)),
  },
};
export default class GeneratedStockReportDto extends createEntity(StockReportDtoPropDef) {}
