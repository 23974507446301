import {h, markRaw} from 'vue';
import InstructionsWarehouseArticleUpdate
  from '@/Modules/Workflow/Workflow/layout/custom/warehouseOperations/InstructionsWarehouseArticleUpdate.vue';
import InstructionsWarehouseSummary
  from '@/Modules/Workflow/Workflow/layout/custom/warehouseOperations/InstructionsWarehouseSummary.vue';

export function createWarehouseArticleUpdate({attrs = {}, slots = {}} = {}) {
  return markRaw(h(InstructionsWarehouseArticleUpdate, attrs, slots));
}

export function createWarehouseSummary({attrs = {}, slots = {}} = {}) {
  return markRaw(h(InstructionsWarehouseSummary, attrs, slots));
}
