import {
  shallowRef,
  watchEffect,
  readonly,
  effectScope,
} from 'vue';

/**
 * This is default behavior of computed property in Vue 3.4
 */

function futureComputed(fn) {
  const result = shallowRef();
  watchEffect(() => {
    result.value = fn();
  });

  return readonly(result);
}

export const createReactiveGetter = <T>(fn: ()=> T) => {
  let cache = null;
  return function() {
    if (!cache) {
      const cachedScope = effectScope(true);

      cache = cachedScope.run(() => {
        return futureComputed(fn.bind(this));
      });

      this.cachedScopes.push({
        stop: () => {
          cachedScope.stop();
          cache = null;
        },
      });
    }

    return cache.value;
  };
};
