
import {computed, defineComponent} from 'vue';
import Key from '@/Components/KeyboardKey.vue';
import IconBack from '@/Components/Icons/IconUArrowLeft.vue';
import IconBackspace from '@/Components/Icons/IconBackspace.vue';
import IconEnter from '@/Components/Icons/IconEnter.vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {WorkflowActions, WorkflowKeyboards} from '@/Modules/Workflow/types';
import PhIcon from '@/Components/PhIcon.vue';
import IconClear from '@/Components/Icons/IconClear.vue';
import {classPropertyGuard} from '@/Helpers/guards';

export default defineComponent({
  components: {
    IconClear,
    IconBackspace,
    IconEnter,
    IconBack,
    PhIcon,
    Key,
  },
  emits: ['input', 'layout'],
  setup() {
    const workflowStore = useWorkflowStore();

    const openArticleSearch = () => {
      if (classPropertyGuard(activeWorkflowStep.value, 'openArticleSearch')) {
        return activeWorkflowStep.value.openArticleSearch();
      }
    };
    const print = () => {
      if (classPropertyGuard(activeWorkflowStep.value, 'print')) {
        return activeWorkflowStep.value.print();
      }
    };

    const activeWorkflowStep = computed(() => {
      return workflowStore.currentWorkflow.value?.activeWorkflowStep ?? null;
    });

    const isKeyboardKeyArticlesDisabled = computed(() => {
      if (classPropertyGuard(activeWorkflowStep.value, 'keyboardKeyArticles')) {
        return activeWorkflowStep.value.keyboardKeyArticles;
      }

      return false;
    });
    const isKeyboardKeyPrintDisabled = computed(() => {
      if (classPropertyGuard(activeWorkflowStep.value, 'keyboardKeyPrint')) {
        return activeWorkflowStep.value.keyboardKeyPrint;
      }

      return false;
    });
    const isKeyboardKeyClearVisible = computed(() => {
      if (classPropertyGuard(activeWorkflowStep.value, 'keyboardKeyClear')) {
        return activeWorkflowStep.value.keyboardKeyClear;
      }

      return false;
    });

    return {
      workflowStore,
      WorkflowActions,
      WorkflowKeyboards,
      openArticleSearch,
      print,
      isKeyboardKeyArticlesDisabled,
      isKeyboardKeyPrintDisabled,
      isKeyboardKeyClearVisible,
    };
  },
});
