import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const KeyAndValueDtoPropDef = {
  value: {
    type: new StringType(),
  },
  key: {
    type: new StringType(),
  },
};
export default class GeneratedKeyAndValueDto extends createEntity(KeyAndValueDtoPropDef) {}
