import GeneratedPaymentButton from './generated/PaymentButton';
import PaymentTypeClass from '@/Model/Entity/PaymentTypeClass';

export default class PaymentButton extends GeneratedPaymentButton {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get paymentType(): PaymentTypeClass {
    return this.configurationStore.paymentTypesByPaymentId.value[this.paymentId];
  }
}
