import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Permission from '../Permission';


export default class GeneratedRole extends createEntity({
  name: {
    type: new StringType(),
  },
  permissions: {
    type: new ArrayType(new EntityType(Permission)),
  },
}) {}
