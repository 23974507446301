import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BetOnDraw from '../BetOnDraw';
import TicketPartnerResourceV2Status from '../TicketPartnerResourceV2Status';
import TicketPartnerResourceV2GameType from '../TicketPartnerResourceV2GameType';
import {DateTimeType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import TicketResourceCombinations from '../TicketResourceCombinations';
import {BooleanType} from '@designeo/apibundle-js';


export const TicketPartnerResourceV2PropDef = {
  id: {
    type: new NumberType(),
  },
  betOnDraws: {
    type: new ArrayType(new EntityType(BetOnDraw)),
  },
  branchId: {
    type: new NumberType(),
  },
  status: {
    type: new EntityType(TicketPartnerResourceV2Status),
  },
  gameType: {
    type: new EntityType(TicketPartnerResourceV2GameType),
  },
  dateCanceled: {
    type: new DateTimeType(),
  },
  betTime: {
    type: new DateTimeType(),
  },
  datePaidOut: {
    type: new DateTimeType(),
  },
  amountPaid: {
    type: new NumberType(),
  },
  amountPaidSum: {
    type: new NumberType(),
  },
  amountWonRealSum: {
    type: new NumberType(),
  },
  amountWonRealNotPaidOutSum: {
    type: new NumberType(),
  },
  ticketNumber: {
    type: new NumberType(),
  },
  numbers: {
    type: new ObjectType(),
  },
  tutovka: {
    type: new ArrayType(new NumberType()),
  },
  betsCombinations: {
    type: new ArrayType(new EntityType(TicketResourceCombinations)),
  },
  possibleWins: {
    type: new ObjectType(),
  },
  usedRandomBet: {
    type: new BooleanType(),
  },
  booster: {
    type: new BooleanType(),
  },
  win: {
    type: new BooleanType(),
  },
};
export default class GeneratedTicketPartnerResourceV2 extends createEntity(TicketPartnerResourceV2PropDef) {}
