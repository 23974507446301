import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {
  findIndex,
  first,
  flow,
  mapKeys,
  reduce,
} from 'lodash-es';
import {workflowStepMixinEshop} from '../StepMixins/WorkflowStepMixinEshop';
import {workflowStepMixinPayment} from '../StepMixins/WorkflowStepMixinPayment';
import {Payment} from '@/Modules/Payment/payment';
import {DocumentPaymentDto} from '@/Model/Entity';
import {nominals} from '@/Helpers/nominals';
import {PaymentTypeMethods} from '@/constants/paymentTypeMethods';

export class WorkflowStepEshopPayment extends flow(
  (ctor) => workflowStepMixinPayment(ctor),
  (ctor) => workflowStepMixinEshop(ctor),
)(WorkflowStep) {
  public paymentConfirm: boolean = false;
  static get type() {
    return WorkflowStepTypes.EshopPayment;
  }

  get type() {
    return WorkflowStepEshopPayment.type;
  }

  get component() {
    return markRaw(require('./StepEshopPayment.vue').default);
  }

  get preselectedPaymentIndex() {
    return this.paymentButtons.findIndex(({preselect}) => preselect);
  }

  get paymentButtons() {
    return this.step.paymentButtons as Array<{
      paymentId: string;
      icon: string;
      label: string,
      preselect: boolean,
    }>;
  }

  get canContinue() {
    return this.order.data.isPaid ? true : !!this.paymentMethod;
  }

  async beforeEnter() {
    if (!this.stepInit) {
      try {
        await this.ensureOrder();
        this.stepInit = true;
      } catch (e) {
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
      }
    }


    this.dataSetter(WorkflowStepField.paymentMethod, (currentValue) => {
      if (currentValue) {
        return currentValue;
      }

      if (this.preselectedPaymentIndex === -1) {
        return;
      }

      return this.paymentButtons[this.preselectedPaymentIndex].paymentId;
    });

    this.dataSetter(WorkflowStepField.amount, () => this.roundedAmount);
  }

  get paymentMethod() {
    return this.getFieldValue(WorkflowStepField.paymentMethod);
  }

  set paymentMethod(value) {
    this.dataSetter(WorkflowStepField.paymentMethod, () => value);
    this.dataSetter(WorkflowStepField.amount, () => this.roundedAmount);
  }

  get roundedAmount() {
    if (this.isPaid) {
      return 0;
    } else if (this.activePayment?.type.value === PaymentTypeMethods.cash) {
      const currency = this.configurationStore.currenciesByCurrencySymbol.value[this.activePayment.currency];
      return nominals(this.order.data.totalPrice, currency.acceptableNominals).decomposableRounded;
    } else {
      return this.order.data.totalPrice;
    }
  }

  requestEshopPayment() {
    if (this.isPaid) {
      return;
    }

    const paymentId = this.getFieldValue(WorkflowStepField.paymentMethod);
    const amount = parseFloat(this.getFieldValue(WorkflowStepField.amount));
    const payment = this.configurationStore.createPayment(paymentId);
    payment.setValue(amount);

    let payments: DocumentPaymentDto[] = [];

    if (this.returnAmount > 0) {
      const returnPayment = this.configurationStore.createPayment(paymentId);
      returnPayment.isMoneyBack = true;
      returnPayment.setValue(this.returnAmount * -1);

      payments = [payment, returnPayment];
    } else {
      payments = [payment];
    }

    for (const payment of payments) {
      if (!payment.type.hasConfirmationMethodTerminal) {
        continue;
      }

      payment.verifyDocumentId = this.createPaymentRequest(Payment.create({
        paymentId: payment.paymentID,
        value: payment.isMoneyBack ? amount * -1 : amount,
      }));
    }

    this.payments = payments;
  }

  get activePayment() {
    return this.paymentTypesByPaymentId[this.paymentMethod];
  }

  get paymentTypesByPaymentId() {
    return this.configurationStore.paymentTypesByPaymentId.value;
  }

  get hasOverpay() {
    return this.activePayment?.type?.value === PaymentTypeMethods.cash;
  }

  get returnAmount() {
    return Math.max(this.getFieldValue(WorkflowStepField.amount) - this.roundedAmount, 0);
  }

  get isPaymentRequestValid() {
    return this.getFieldValue(WorkflowStepField.amount) >= this.roundedAmount;
  }

  get orderIsPaid() {
    return this.order.data.isPaid;
  }

  onPaymentConfirm() {
    this.paymentConfirm = false;
    this.requestEshopPayment();
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  onPaymentReject() {
    this.paymentConfirm = false;
  }

  async finish() {
    if (!this.canContinue) {
      return;
    }

    if (this.hasUnfinishedPaymentRequests || this.hasFinishedPaymentRequests) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.order.data.isPaid) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.order.data.totalPrice === 0) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (!this.isPaymentRequestValid) {
      return;
    }

    if (!(this.step.confirm ?? false)) {
      this.requestEshopPayment();
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    this.paymentConfirm = true;
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.paymentMethod, (fieldActions) => ({
        [WorkflowActions.PREV]: action(() => {
          const currentPaymentId = this.getFieldValue(WorkflowStepField.paymentMethod);
          const index = findIndex(this.paymentButtons, ({paymentId}) => paymentId == currentPaymentId);
          const nextId = this.paymentButtons[index - 1]?.paymentId ?? currentPaymentId;
          this.paymentMethod = nextId;
        }),
        [WorkflowActions.NEXT]: action(() => {
          const currentPaymentId = this.getFieldValue(WorkflowStepField.paymentMethod);
          const index = findIndex(this.paymentButtons, ({paymentId}) => paymentId == currentPaymentId);
          const nextId = this.paymentButtons[index + 1]?.paymentId ?? currentPaymentId;
          this.paymentMethod = nextId;
        }),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent) => {
          if (this.hasOverpay) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
              detail: {
                field: WorkflowStepField.amount,
              },
            }));
          } else {
            await this.finish();
          }
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.amount, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_NUMBER,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.finish();
        }),
      })),
    };
  }
}
