import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow uppercase" }
const _hoisted_3 = { class: "pb-4" }
const _hoisted_4 = { class: "list-inside list-disc" }
const _hoisted_5 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(workflowStep.step.note), 1),
          _createElementVNode("div", null, _toDisplayString(workflowStep.step.check), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workflowStep.step.points, (point, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(point), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_WorkflowTransitions)
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}