import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const OrderActionsDtoPropDef = {
  canBeFulfilled: {
    type: new BooleanType(),
  },
  canBeCancelled: {
    type: new BooleanType(),
  },
};
export default class GeneratedOrderActionsDto extends createEntity(OrderActionsDtoPropDef) {}
