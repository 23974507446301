import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {
  AppLoaderEvent,
  PrinterWSEvents,
} from '@/Modules/Core/types';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {
  DocumentDto,
  DocumentFinItemDto,
  DocumentPaymentDto,
} from '@/Model/Entity';
import {apiDocumentCreate} from '@/Model/Action';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {workflowStepMixinSaveFinDocument} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinSaveFinDocument';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import DocumentSave from '@/Helpers/document/save';

// eslint-disable-next-line max-len
export class WorkflowStepSafeBagSaveToSap extends workflowStepMixinSaveFinDocument(workflowStepMixinNominals(WorkflowStep)) {
  static get type() {
    return WorkflowStepTypes.SafeBagSaveToSap;
  }

  get type() {
    return WorkflowStepSafeBagSaveToSap.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagSaveToSap.vue').default);
  }

  async beforeContinue() {
    if (this.stepFinished) return;
    if (this.step.createNominalDocument ?? false) {
      await this.createNominalsDocument();
    }
    await this.createSafeBag();
    this.stepFinished = true;
  }

  async createNominalsDocument() {
    let nominalsDocumentResult: Awaited<ReturnType<DocumentSave['run']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      nominalsDocumentResult = await DocumentSave.run(() => {
        const nominalDocument = DocumentDto.createNominalsCountDocument({
          currency: this.data.nominalsSettings.currency,
          envelopeNumber: this.data.safeBagEnvelopeCode,
          documentBarcode: this.data.safeBagEnvelopeCode,
          externalReference: this.data.safeBagEnvelopeCode,
          items: this.acceptableNominalsWithUnitedCoinsAsItems,
          isPreview: false,
        });

        nominalDocument.refreshTotalPrice();

        return nominalDocument;
      }, {
        reference: {
          get: () => {
            return this.getFieldValue(`nominalsDocument:${this.index}`, null);
          },
          set: async (documentId: string) => {
            this.dataSetter(`nominalsDocument:${this.index}`, () => documentId);
            await this.workflowStore.persist();
          },
        },
      });

      nominalsDocumentResult.result = new PrinterResult(nominalsDocumentResult.result ?? {});

      if (nominalsDocumentResult.error) {
        throw nominalsDocumentResult.error;
      }

      if (nominalsDocumentResult.result.hasError) {
        throw new Error(nominalsDocumentResult.result.errorMessage);
      }
    } catch (e) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
          value: e,
        },
      }));

      if (!nominalsDocumentResult?.created) {
        throw e;
      }
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async createSafeBag() {
    let finDocumentResult: Awaited<ReturnType<WorkflowStepSafeBagSaveToSap['saveFinDocument']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      finDocumentResult = await DocumentSave.run(() => {
        const transaction = this.configurationStore.financialTransactionsByCode.value[this.step.transactionCode];

        const safeBag = DocumentDto.createSafeBag({
          envelopeNumber: this.data.safeBagEnvelopeCode,
          documentBarcode: this.data.safeBagEnvelopeCode,
          items: this.availableNominalsAsItems,
          header: {
            finDocumentCode: transaction.code,
            finDocumentName: transaction.name,
            finDocumentTransactionNumber: transaction.number,
            source: transaction.transactionSource,
            destination: transaction.transactionDestination,
            sapTransactionCode: transaction.sapTransactionCode,
            note: this.data.note,
          },
        });

        safeBag.finItems = [
          new DocumentFinItemDto({
            valueBeforeDiscounts: safeBag.balance,
            quantity: 1,
          }),
        ];

        const payment = this.configurationStore.createPayment(transaction.paymentId);

        payment.sapTransactionCode = transaction.sapTransactionCode;

        payment.setValue(safeBag.balance);

        safeBag.addPayment(payment, {refreshTotalPrice: false});

        return safeBag;
      }, {
        reference: {
          get: () => {
            return this.getFieldValue(`finDocument:${this.index}`, null);
          },
          set: async (documentId: string) => {
            this.dataSetter(`finDocument:${this.index}`, () => documentId);
            await this.workflowStore.persist();
          },
        },
      });

      finDocumentResult.result = new PrinterResult(finDocumentResult.result);

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }

      if (isActiveFeaturePrintDisplayOnScreen() && finDocumentResult.result.hasValidPrintContent) {
        await this.printContentStore.open(finDocumentResult.result.printContent);
      }
    } catch (e) {
      if (!finDocumentResult?.result?.hasError) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));
      }

      if (!finDocumentResult?.created) {
        throw e;
      }
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
