import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {createWarehouseArticleUpdate} from '@/Modules/Workflow/Workflow/layout/custom/warehouseOperations/helpers';
import {
  WarehouseOperationGoodsListStates,
  WarehouseOperations,
  WarehouseOperationStepFieldGroups,
} from '@/constants/warehouseOperations';
import {action} from '@designeo/vue-helpers';
import {
  clamp,
  find,
  isNil,
  reduce,
} from 'lodash-es';
import {DocumentItemDto, DocumentLogisticItemDto} from '@/Model/Entity';
import {apiInStoreManagementArticleSearch, apiInStoreManagementArticleTextSearch} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {sanitizeApiSearch} from '@/Helpers/sanitize';

export class WorkflowStepWarehouseOperationGoodsList extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationGoodsList;
  }

  get type() {
    return WorkflowStepWarehouseOperationGoodsList.type;
  }

  get component() {
    return markRaw(require('./StepWarehouseOperationGoodsList.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get canContinue() {
    if (this.isModeReadonly) {
      return true;
    }

    if (this.disabledNextStep) {
      return false;
    }

    return this.isCurrentStateListView;
  }

  get disabledNextStep() {
    if (this.isModeReadonly) {
      return false;
    }

    if (this.isCurrentStateListView) {
      return !this.operationEntityPropLogisticDocument?.logisticItems?.length;
    }

    return false;
  }

  get title() {
    return this.titleByWarehouseOperation;
  }

  get keyboardKeyArticles() {
    if (this.isModeReadonly) {
      return false;
    }

    return true;
  }

  get keyboardKeyClear() {
    if (this.isModeReadonly) {
      return false;
    }

    return this.isCurrentStateArticleUpdate;
  }

  changeState(state: WarehouseOperationGoodsListStates) {
    this.dataSetter(this.createFieldDataPath(WorkflowStepField.previousState), () => this.currentState);
    this.dataSetter(this.createFieldDataPath(WorkflowStepField.state), () => state);
  }

  get currentState() {
    return this.getFieldValue(this.createFieldDataPath(WorkflowStepField.state), null);
  }

  get previousState() {
    return this.getFieldValue(this.createFieldDataPath(WorkflowStepField.previousState), null);
  }

  get isCurrentStateListView() {
    return this.currentState === WarehouseOperationGoodsListStates.listView;
  }

  get isCurrentStateArticleSearch() {
    return this.currentState === WarehouseOperationGoodsListStates.articleSearch;
  }

  get isCurrentStateArticleUpdate() {
    return this.currentState === WarehouseOperationGoodsListStates.articleUpdate;
  }

  get isCurrentStateStockInStores() {
    return this.currentState === WarehouseOperationGoodsListStates.stockInStores;
  }

  openStockInStores() {
    this.changeState(WarehouseOperationGoodsListStates.stockInStores);
    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: this.createFieldDataPath(WorkflowStepField.searchString),
      },
    }));
  }

  openArticleSearch() {
    this.changeState(WarehouseOperationGoodsListStates.articleSearch);
    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: this.createFieldDataPath(WorkflowStepField.searchString),
      },
    }));
  }

  openArticleUpdate(article: any) {
    this.insertMode = true;
    this.quantityInputBuffer = article.quantity.toString();
    this.updateArticle = article;
    this.changeState(WarehouseOperationGoodsListStates.articleUpdate);
    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: this.createFieldDataPath(WorkflowStepField.quantity),
      },
    }));
  }

  openListView() {
    this.changeState(WarehouseOperationGoodsListStates.listView);
    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: this.createFieldDataPath(WorkflowStepField.searchString),
      },
    }));
  }

  openPreviousState() {
    switch (this.previousState) {
    case WarehouseOperationGoodsListStates.articleSearch:
      return this.openArticleSearch();
    case WarehouseOperationGoodsListStates.articleUpdate:
      return this.openArticleUpdate(this.updateArticle);
    case WarehouseOperationGoodsListStates.stockInStores:
      return this.openStockInStores();
    case WarehouseOperationGoodsListStates.listView:
    default:
      this.openListView();
    }
  }

  resetState() {
    this.searchString = '';
    this.quantityInputBuffer = '';
    this.updateArticle = null;
    this.openListView();
  }

  get displayStock() {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.orderOfGoodsFromDistributionCenter:
    case WarehouseOperations.returnOfGoodsToDistributionCenter:
    case WarehouseOperations.returnOfPrintedMatters:
    case WarehouseOperations.returnOfGoodsToExternalSupplier:
    case WarehouseOperations.reloadingOfGoodsTypeDispense:
    case WarehouseOperations.reloadingOfGoodsTypeReceiving:
    case WarehouseOperations.depreciationOfGoodsTypeConsumption:
    case WarehouseOperations.depreciationOfGoodsTypeDisposal:
      return true;
    default:
      return false;
    }
  }

  get updateArticle() {
    const articleData = this.getFieldValue(this.createFieldDataPath(WorkflowStepField.detail), null);

    if (!articleData) {
      return null;
    }

    return new DocumentLogisticItemDto(articleData);
  }

  set updateArticle(value: DocumentLogisticItemDto) {
    this.dataSetter(this.createFieldDataPath(WorkflowStepField.detail), () => value?.toJson());
  }

  get instructions() {
    if (this.updateArticle) {
      return createWarehouseArticleUpdate({
        attrs: {
          article: this.updateArticle,
          quantity: this.quantityInputBuffer,
          insertMode: this.insertMode,
          isModeReadonly: this.isModeReadonly,
          displayStock: this.displayStock,
        },
      });
    }

    return null;
  }

  sanitizeLogisticItem(item: DocumentLogisticItemDto) {
    if (isNil(item.quantity)) {
      item.quantity = 1;
    }

    return item;
  }

  findLogisticItemWithinCurrentList(documentLogisticItem: DocumentLogisticItemDto) {
    return find(
      this.operationEntityPropLogisticDocument.logisticItems,
      (item) => item.gtin === documentLogisticItem.gtin,
    );
  }

  processDocumentItemAdd(documentItem: DocumentItemDto) {
    documentItem = documentItem.expandExpandableSet();

    if (documentItem.isSet) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.SET_CAN_NOT_BE_ADDED,
        },
      }));
      return;
    }

    const documentLogisticItem = this.sanitizeLogisticItem(documentItem.expandExpandableSet().toDocumentLogisticItem()
      .clone());

    let item = this.findLogisticItemWithinCurrentList(documentLogisticItem);

    if (item) {
      item.quantity = item.quantity + documentLogisticItem.quantity;
    } else {
      item = documentLogisticItem;
      this.operationEntityPropLogisticDocument.processDocumentLogisticItemAddition(item);
    }

    this.resetState();
    this.openArticleUpdate(item);
  }

  processLogisticItemRemove(documentLogisticItem: DocumentLogisticItemDto) {
    this.resetState();
    this.operationEntityPropLogisticDocument.removeLogisticItemByGtin(documentLogisticItem.gtin);
  }

  processLogisticItemUpdate(documentLogisticItem: DocumentLogisticItemDto) {
    documentLogisticItem = this.sanitizeLogisticItem(documentLogisticItem.clone());

    const item = this.findLogisticItemWithinCurrentList(documentLogisticItem);

    item.quantity = documentLogisticItem.quantity;

    this.resetState();
  }

  get isModeReadonly() {
    return this.step.readonly ?? false;
  }

  createFieldDataPath(field: string) {
    return `${WarehouseOperationStepFieldGroups.goodsList}.${field}`;
  }

  async beforeReturn() {
    this.resetState();
  }

  async beforeEnter() {
    if (!this.currentState) {
      this.openListView();
    }
  }

  async beforeContinue() {
    this.stepFinished = true;
    this.resetState();
  }

  get searchString() {
    return this.getFieldValue(this.createFieldDataPath(WorkflowStepField.searchString), '');
  }

  set searchString(val) {
    this.dataSetter(this.createFieldDataPath(WorkflowStepField.searchString), () => val);
  }

  get canEnterZeroQuantity() {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.returnOfPrintedMatters:
      return true;
    default:
      return false;
    }
  }

  get quantity() {
    const quantity = parseInt(this.quantityInputBuffer, 10);

    if (Number.isNaN(quantity)) {
      return null;
    }

    return quantity;
  }

  get quantityInputBuffer() {
    return this.getFieldValue(this.createFieldDataPath(WorkflowStepField.quantity), '');
  }

  set quantityInputBuffer(val: string) {
    this.dataSetter(this.createFieldDataPath(WorkflowStepField.quantity), () => val);
  }

  async searchArticleByCode(text, {paginator = {offset: 0}, sort = undefined} = {}) {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      return sanitizeApiSearch(await apiInStoreManagementArticleSearch({
        params: {
          code: text,
          limit: paginator.offset + 50,
          offset: 0,
          sort: sort,
          ...(this.step.vendor ? {
            vendor: this.step.vendor,
          } : this.entityPropVendorGuard(this.operationEntity) && this.operationEntityPropVendor ? {
            vendor: this.operationEntityPropVendor.vendor,
          } : {}),
        },
      })).documentItems;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async searchArticleByText(text, {paginator = {offset: 0}, sort = undefined} = {}) {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      return sanitizeApiSearch(await apiInStoreManagementArticleTextSearch({
        params: {
          text: text,
          limit: paginator.offset + 50,
          offset: 0,
          sort: sort,
          ...(this.step.vendor ? {
            vendor: this.step.vendor,
          } : this.entityPropVendorGuard(this.operationEntity) && this.operationEntityPropVendor ? {
            vendor: this.operationEntityPropVendor.vendor,
          } : {}),
        },
      })).documentItems;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    if (this.isModeReadonly) {
      switch (this.currentState) {
      case WarehouseOperationGoodsListStates.listView:
        return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.searchString), (fieldActions) => ({
          [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
          }),
        }));
      case WarehouseOperationGoodsListStates.articleUpdate:
        return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.quantity), (fieldActions) => ({
          [WorkflowActions.CANCEL]: action((event: WorkflowInputEvent) => {
            this.resetState();
          }),
          [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
            this.resetState();
          }),
        }));
      case WarehouseOperationGoodsListStates.stockInStores:
        return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.searchString), (fieldActions) => ({
          [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
            fieldActions[WorkflowActions.ADD_NUMBER](event.value);
          }),
          [WorkflowActions.CLEAR]: action((event: WorkflowInputEvent) => {
            fieldActions[WorkflowActions.CLEAR](event.value);
          }),
          [WorkflowActions.BACKSPACE]: action(() => {
            fieldActions[WorkflowActions.BACKSPACE]();
          }),
          ...reduce([
            WorkflowActions.ADD_CHAR,
            WorkflowActions.ADD_MINUS,
            WorkflowActions.ADD_PLUS,
            WorkflowActions.ADD_PERIOD,
            WorkflowActions.ADD_COMMA,
          ], (acc, val) => {
            acc[val] = action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_CHAR](event.value);
            });

            return acc;
          }, {}),
          [WorkflowActions.CANCEL]: action(async (event: WorkflowInputEvent) => {
            this.openPreviousState();
          }),
          [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent) => {
            this.messageBus.dispatchEvent(new Event(`${WorkflowStepEvents.METHOD_EXECUTE}:searchStockInStore`));
          }),
          [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent) => {
            fieldActions[WorkflowActions.ENTER](event.value);
            this.messageBus.dispatchEvent(new Event(`${WorkflowStepEvents.METHOD_EXECUTE}:searchStockInStore`));
          }),
        }));
      default: return {};
      }
    }

    switch (this.currentState) {
    case WarehouseOperationGoodsListStates.listView:
      return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.searchString), (fieldActions) => ({
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<any>) => {
          try {
            const articles = await this.searchArticleByCode(event.value);

            if (articles.length === 1) {
              this.processDocumentItemAdd(articles[0]);
            } else {
              await this.openArticleSearch();
              this.searchString = event.value;
            }
          } catch (e) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
              detail: {
                value: e,
              },
            }));
          }
        }),
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      }));
    case WarehouseOperationGoodsListStates.articleSearch:
      return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.searchString), (fieldActions) => ({
        [WorkflowActions.CANCEL]: action((event: WorkflowInputEvent) => {
          this.openPreviousState();
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_PERIOD](event.value);
        }),
        [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
          this.messageBus.dispatchEvent(new Event(WorkflowActions.ENTER));
        }),
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<any>) => {
          fieldActions[WorkflowActions.ENTER](event.value);
        }),
      }));
    case WarehouseOperationGoodsListStates.articleUpdate:
      return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.quantity), (fieldActions) => ({
        [WorkflowActions.CANCEL]: action((event: WorkflowInputEvent) => {
          this.resetState();
        }),
        [WorkflowActions.CLEAR]: action((event: WorkflowInputEvent) => {
          this.processLogisticItemRemove(this.updateArticle);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ARROW_UP]: action((event: WorkflowInputEvent<any>) => {
          const index = this.findPreviousLogisticItemIndex();
          this.setActiveLogisticItemByIndex(index);
        }),
        [WorkflowActions.ARROW_DOWN]: action((event: WorkflowInputEvent<any>) => {
          const index = this.findNextLogisticItemIndex();
          this.setActiveLogisticItemByIndex(index);
        }),
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
          if (this.quantity === null) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
              detail: {
                type: WorkflowStepErrors.MIN_VALUE,
                args: {
                  minValue: this.canEnterZeroQuantity ? 0 : 1,
                },
              },
            }));
            return;
          }

          if (!this.canEnterZeroQuantity && this.quantity === 0) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
              detail: {
                type: WorkflowStepErrors.MIN_VALUE,
                args: {
                  minValue: 1,
                },
              },
            }));
            return;
          }

          if (!isNil(this.step.maxQuantity) && this.quantity > this.step.maxQuantity) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
              detail: {
                type: WorkflowStepErrors.MAX_VALUE,
                args: {
                  maxValue: this.step.maxQuantity,
                },
              },
            }));
            return;
          }

          this.updateArticle.quantity = this.quantity;

          const currentIndex = this.findCurrentActiveLogisticItemIndex();
          const nextIndex = this.findNextLogisticItemIndex();

          const isLastItem = currentIndex === this.operationEntityPropLogisticDocument.logisticItems.length - 1;

          this.processLogisticItemUpdate(this.updateArticle);

          if (!isLastItem) {
            this.setActiveLogisticItemByIndex(nextIndex);
          }
        }),
      }));
    case WarehouseOperationGoodsListStates.stockInStores:
      return this.withFieldActions(this.createFieldDataPath(WorkflowStepField.searchString), (fieldActions) => ({
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.CLEAR]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.CLEAR](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        ...reduce([
          WorkflowActions.ADD_CHAR,
          WorkflowActions.ADD_MINUS,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_COMMA,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(async (event: WorkflowInputEvent) => {
          this.openPreviousState();
        }),
        [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent) => {
          this.messageBus.dispatchEvent(new Event(`${WorkflowStepEvents.METHOD_EXECUTE}:searchStockInStore`));
        }),
        [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ENTER](event.value);
          this.messageBus.dispatchEvent(new Event(`${WorkflowStepEvents.METHOD_EXECUTE}:searchStockInStore`));
        }),
      }));
    default: return {};
    }
  }

  findCurrentActiveLogisticItemIndex() {
    const currentIndex = this.operationEntityPropLogisticDocument.logisticItems.findIndex(
      (item) => {
        return item.gtin === this.updateArticle?.gtin;
      },
    );

    return currentIndex;
  }

  findNextLogisticItemIndex() {
    const currentIndex = this.findCurrentActiveLogisticItemIndex();

    return clamp(currentIndex + 1, 0, this.operationEntityPropLogisticDocument.logisticItems.length - 1);
  }

  findPreviousLogisticItemIndex() {
    const currentIndex = this.findCurrentActiveLogisticItemIndex();

    return clamp(currentIndex - 1, 0, this.operationEntityPropLogisticDocument.logisticItems.length - 1);
  }

  setActiveLogisticItemByIndex(index: number) {
    const logisticItem = this.operationEntityPropLogisticDocument.logisticItems?.[index];

    if (!logisticItem) {
      return;
    }

    this.openArticleUpdate(logisticItem);
  }
}
