import {
  RouteRecordRaw,
  NavigationGuard,
  NavigationGuardNext,
  NavigationHookAfter,
} from 'vue-router';
import {
  LocaleMessages, VueMessageType,
} from 'vue-i18n';
import {
  isArray,
  isFunction,
  mergeWith,
  values,
} from 'lodash-es';
import defaultTranslations from '@/Plugins/translations/defaultTranslations.json';
import devTranslations from '@/Plugins/translations/devTranslations.json';
import {jsonSchemaToJson} from '@/Plugins/translations/jsonSchemaToJson';
import {Languages} from '@/constants/languages';
import {RouterTree} from '@/ModuleSystem/routerTree';

export type RegisterRoutesOptions = RouteRecordRaw[]
type registerRouteFn = (
  routes: RouteRecordRaw[]|(()=> Promise<RouteRecordRaw[]>),
  opts?: {method?: 'push' | 'unshift', layout?: string},
)=> Promise<void>
type registerLayoutFn = (name: string, layout: RouteRecordRaw|(()=> Promise<RouteRecordRaw>))=> Promise<void>
type registerTranslationsFn = (messages: LocaleMessages<any>|(()=> Promise<LocaleMessages<any>[]>))=> Promise<void>
type registerHookBeforeEachFn = (hook: NavigationGuard)=> Promise<void>
type registerHookAfterEachFn = (hook: NavigationHookAfter)=> Promise<void>


export interface ModuleSystem extends Object {
  registerRoutes: registerRouteFn
  registerLayout: registerLayoutFn
  registerTranslations: registerTranslationsFn
  registerHookBeforeEach: registerHookBeforeEachFn
  registerHookAfterEach: registerHookAfterEachFn
  getRegisteredRoutes(): RouteRecordRaw[]
  getTranslations(): LocaleMessages<VueMessageType>
  getHooks(): {beforeEach: Array<NavigationGuard>, afterEach: Array<NavigationHookAfter>}
  locale: string
}

/**
 * Defines what this module needs
 */
export type CoreRequiredContext = {
  appId: any
  routerTree: RouterTree,
}

/**
 * Define what this module provides through context
 */
export type CoreContext = CoreRequiredContext & {
}


export function registerModuleSystem({fallbackRoute = 'login'} = {}) {
  return async (ctx: CoreRequiredContext): Promise<ModuleSystem> => {
    const messages: LocaleMessages<VueMessageType> = jsonSchemaToJson(defaultTranslations);

    const hooks = {
      beforeEach: [],
      afterEach: [],
    };

    /**
     * use createDefaultTranslations from devTools.ts to merge devTranslations to defaultTranslations
     */
    const devMessages: LocaleMessages<VueMessageType> = devTranslations as unknown as LocaleMessages<VueMessageType>;

    let locale;

    return {
      async registerTranslations(newMessages) {
        function customizer(oldValue, newValue) {
          if (isArray(oldValue)) {
            return newValue;
          }
        }
        mergeWith(messages, newMessages, devMessages, customizer);
      },
      async registerRoutes(
        newRoutes,
        {method = 'push', layout = 'default'} = {},
      ) {
        if (isFunction(newRoutes)) {
          (ctx.routerTree[layout]['children'][method])(...(await newRoutes()));
        } else {
          (ctx.routerTree[layout]['children'][method])(...(newRoutes));
        }
      },
      async registerLayout(name, newLayout) {
        if (ctx.routerTree[name]) {
          return;
        }

        if (isFunction(newLayout)) {
          ctx.routerTree[name] = await newLayout();
        } else {
          ctx.routerTree[name] = newLayout;
        }
      },
      async registerHookBeforeEach(hook) {
        hooks.beforeEach.push(hook);
      },
      async registerHookAfterEach(hook) {
        hooks.afterEach.push(hook);
      },
      getRegisteredRoutes() {
        return [
          ...values(ctx.routerTree),
          {
            path: '/:catchAll(.*)*',
            name: 'NotFound',
            redirect: (to) => {
              return {
                name: fallbackRoute,
              };
            },
          },
        ];
      },
      getTranslations() {
        return messages;
      },
      getHooks() {
        return hooks;
      },
      set locale(val: Languages) {
        locale = val;
      },
      get locale() {
        return locale;
      },
    };
  };
}
