import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {apiFinancialCloseDayCanStart} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';

export class WorkflowStepCheckFinancialReportAvailable extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.CheckFinancialReportAvailable;
  }

  get type() {
    return WorkflowStepCheckFinancialReportAvailable.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  async beforeContinue() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      const result = await apiFinancialCloseDayCanStart();
      if (!result) {
        this.disabledNextStep = true;
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.FINANCIAL_REPORT_NOT_AVAILABLE,
          },
        }));
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
      }
    } catch (e) {
      console.error({e});
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
