import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreEvent,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {
  TransitionDefinition,
  getCommonTransitions,
  createTransitions,
} from './helpers';
import {reduce} from 'lodash-es';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.STOCK_IN_STORES]: {
      [RegisterActions.BEFORE_LEAVE]: action(async () => {
        this.state.inputBuffer = this.state.lastSearchedSubject;
      }),
      [RegisterActions.BEFORE_ENTER]: action(async ({from: previousState, to: currentState}) => {
        this.state.lastSearchedSubject = this.state.inputBuffer;
      }),
      [RegisterActions.INIT]: action(async () => {
        this.resetInputBuffer();
      }),
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.CLEAR,
        RegisterActions.BACKSPACE,
      ]),
      ...reduce([
        RegisterActions.ADD_CHAR,
        RegisterActions.ADD_MINUS,
        RegisterActions.ADD_PLUS,
        RegisterActions.ADD_PERIOD,
        RegisterActions.ADD_COMMA,
      ], (acc, val) => {
        acc[val] = action((event: RegisterInputEvent<string>) => {
          createTransitions.call(this)[RegisterActions.ADD_CHAR](event);
        });

        return acc;
      }, {}),
      [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
        await this.closeStockInStores();
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        this.dispatchEvent(new Event(RegisterStoreEvent.SEARCH_STOCK_IN_STORE));
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        this.state.inputBuffer = event.value;
        this.dispatchEvent(new Event(RegisterStoreEvent.SEARCH_STOCK_IN_STORE));
      }),
    },
  };
}
