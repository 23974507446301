import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TransactionDirectionType from '../TransactionDirectionType';
import {BooleanType} from '@designeo/apibundle-js';
import LogisticsLocationDto from '../LogisticsLocationDto';
import {DateTimeType} from '@designeo/apibundle-js';
import DocumentModeType from '../DocumentModeType';
import StockType from '../StockType';
import InventoryType from '../InventoryType';
import {ArrayType} from '@designeo/apibundle-js';
import KeyAndValueDto from '../KeyAndValueDto';
import ParkingHistoryDto from '../ParkingHistoryDto';


export const DocumentHeaderDtoPropDef = {
  finDocumentCode: {
    type: new StringType(),
  },
  finDocumentName: {
    type: new StringType(),
  },
  finDocumentTransactionNumber: {
    type: new NumberType(),
  },
  source: {
    type: new EntityType(TransactionDirectionType),
  },
  destination: {
    type: new EntityType(TransactionDirectionType),
  },
  isShipmentNotification: {
    type: new BooleanType(),
  },
  externalReference: {
    type: new StringType(),
  },
  logisticsSource: {
    type: new EntityType(LogisticsLocationDto),
  },
  logisticsDestination: {
    type: new EntityType(LogisticsLocationDto),
  },
  deliveryDateFrom: {
    type: new DateTimeType(),
  },
  deliveryDateTo: {
    type: new DateTimeType(),
  },
  totalQuantity: {
    type: new NumberType(),
  },
  itemsCount: {
    type: new NumberType(),
  },
  sapTransactionCode: {
    type: new StringType(),
  },
  note: {
    type: new StringType(),
  },
  mode: {
    type: new EntityType(DocumentModeType),
  },
  currency: {
    type: new StringType(),
  },
  stockType: {
    type: new EntityType(StockType),
  },
  inventoryType: {
    type: new EntityType(InventoryType),
  },
  previewOnly: {
    type: new BooleanType(),
  },
  documentDate: {
    type: new DateTimeType(),
  },
  returnReasonCode: {
    type: new StringType(),
  },
  returnReasonName: {
    type: new StringType(),
  },
  financialCloseDayId: {
    type: new StringType(),
  },
  cashierId: {
    type: new StringType(),
  },
  cashierPersonalNumber: {
    type: new StringType(),
  },
  cashier: {
    type: new StringType(),
  },
  userName: {
    type: new StringType(),
  },
  sellerID: {
    type: new StringType(),
  },
  seller: {
    type: new StringType(),
  },
  shopName: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
  posCode: {
    type: new StringType(),
  },
  shopId: {
    type: new StringType(),
  },
  posId: {
    type: new StringType(),
  },
  total: {
    type: new NumberType(),
  },
  subtotal: {
    type: new NumberType(),
  },
  order: {
    type: new NumberType(),
  },
  orderOverriddenId: {
    type: new StringType(),
  },
  rounding: {
    type: new NumberType(),
  },
  uniqueidentifier: {
    type: new StringType(),
  },
  referentialUniqueidentifier: {
    type: new StringType(),
  },
  journalNumber: {
    type: new NumberType(),
  },
  documentNumber: {
    type: new StringType(),
  },
  delayedSellDate: {
    type: new DateTimeType(),
  },
  delayedSellNumber: {
    type: new StringType(),
  },
  isDelayedSell: {
    type: new BooleanType(),
  },
  description: {
    type: new ArrayType(new StringType()),
  },
  copies: {
    type: new NumberType(),
  },
  documentBarcode: {
    type: new StringType(),
  },
  origReference: {
    type: new StringType(),
  },
  reference: {
    type: new StringType(),
  },
  confirmedAgeRestrictions: {
    type: new ArrayType(new NumberType()),
  },
  internalDocumentNumber: {
    type: new StringType(),
  },
  externalNumber: {
    type: new StringType(),
  },
  reasonID: {
    type: new NumberType(),
  },
  reason: {
    type: new StringType(),
  },
  reasonTxt: {
    type: new StringType(),
  },
  voucherSale: {
    type: new NumberType(),
  },
  voucherPay: {
    type: new NumberType(),
  },
  usedThings: {
    type: new NumberType(),
  },
  additionalProps: {
    type: new ArrayType(new EntityType(KeyAndValueDto)),
  },
  desc1: {
    type: new StringType(),
  },
  desc2: {
    type: new StringType(),
  },
  desc3: {
    type: new StringType(),
  },
  desc4: {
    type: new StringType(),
  },
  desc5: {
    type: new StringType(),
  },
  desc6: {
    type: new StringType(),
  },
  desc7: {
    type: new StringType(),
  },
  desc8: {
    type: new StringType(),
  },
  desc9: {
    type: new StringType(),
  },
  desc10: {
    type: new StringType(),
  },
  parkingHistories: {
    type: new ArrayType(new EntityType(ParkingHistoryDto)),
  },
  gpsType: {
    type: new StringType(),
  },
  gpsX: {
    type: new StringType(),
  },
  gpsY: {
    type: new StringType(),
  },
  gpsStreet: {
    type: new StringType(),
  },
  gpsRegNr: {
    type: new StringType(),
  },
  gpsZip: {
    type: new StringType(),
  },
  gpsCity: {
    type: new StringType(),
  },
  gpsBuildingNr: {
    type: new StringType(),
  },
  gpsOther: {
    type: new StringType(),
  },
  sellerIDType: {
    type: new StringType(),
  },
  sellerIDValue: {
    type: new StringType(),
  },
  saleSlip: {
    type: new StringType(),
  },
  saleSlipDate: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  cashInTransitService: {
    type: new StringType(),
  },
  envelopeNumber: {
    type: new StringType(),
  },
  variableSymbol: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentHeaderDto extends createEntity(DocumentHeaderDtoPropDef) {}
