
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'TaxStamp',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'w-6 h-6 text-sm',
    },
  },
});
