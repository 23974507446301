import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {reduce} from 'lodash-es';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import * as yup from 'yup';
import {action} from '@designeo/vue-helpers';

export class WorkflowStepSafeBagDeliveryDriver extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagDeliveryDriver;
  }

  get type() {
    return WorkflowStepSafeBagDeliveryDriver.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagDeliveryDriver.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.name]: yup.string().required(),
    });
  }

  get manualMode() {
    return this.data[WorkflowStepField.manualMode] ?? false;
  }

  async finish() {
    if (!await this.validate()) return;

    this.stepFinished = true;
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.name, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_CHAR,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            if (!this.manualMode) return;
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          if (!this.manualMode) return;
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.finish();
        }),
      })),
    };
  }
}
