
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {HLInputSelect} from '@designeo/vue-forms/src/Input/InputSelect';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  components: {
    PhIcon,
    IconCheck,
    HLInputSelect,
  },
  props: {
    options: {
      type: Array as PropType<Array<{ id: any, label: string }>>,
      required: true,
    },
    modelValue: {
      type: null,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: null,
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | '2xs'>,
      required: false,
      default: 'md',
    },
    direction: {
      type: String as PropType<'up' | 'down'>,
      required: false,
      default: 'down',
    },
    iconSize: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const innerModel = computed({
      get: () => {
        return props.modelValue;
      },
      set: (val) => {
        emit('update:modelValue', val);
      },
    });

    return {
      innerModel,
    };
  },
});
