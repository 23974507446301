import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import LotteryTurnoverByCategory from '../LotteryTurnoverByCategory';
import LotteryTurnoverByArticle from '../LotteryTurnoverByArticle';


export const LotteryTurnoversDtoPropDef = {
  totalAmount: {
    type: new NumberType(),
  },
  turnoverByCategories: {
    type: new ArrayType(new EntityType(LotteryTurnoverByCategory)),
  },
  turnoverByArticles: {
    type: new ArrayType(new EntityType(LotteryTurnoverByArticle)),
  },
};
export default class GeneratedLotteryTurnoversDto extends createEntity(LotteryTurnoversDtoPropDef) {}
