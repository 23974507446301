import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
  RegisterStoreEvent,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransitionDefinition,
  getCommonTransitions,
  createTransitions,
} from './helpers';
import {usePhoneNumberMaskResolver} from '@/Helpers/phoneNumberMaskResolver';

export default function(this: RegisterStore): TransitionDefinition {
  const {resolvePhoneRechargeMask} = usePhoneNumberMaskResolver();

  return {
    [RegisterState.ENTER_PHONE_NUMBER]: {
      ...getCommonTransitions.call(this, [RegisterActions.CANCEL]),
      [RegisterActions.BACKSPACE]: action(async (event: RegisterInputEvent<string>) => {
        createTransitions.call(this)[RegisterActions.BACKSPACE](event);
        const {unmaskedPhoneNumber} = resolvePhoneRechargeMask(this.state.inputBuffer);
        this.state.inputBuffer = unmaskedPhoneNumber;
      }),
      [RegisterActions.CLEAR]: action(async (event: RegisterInputEvent<string>) => {
        createTransitions.call(this)[RegisterActions.CLEAR](event);
        const {unmaskedPhoneNumber} = resolvePhoneRechargeMask(this.state.inputBuffer);
        this.state.inputBuffer = unmaskedPhoneNumber;
      }),
      [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent<string>) => {
        const {unmaskedPhoneNumber} = resolvePhoneRechargeMask(this.state.inputBuffer + event.value);
        this.state.inputBuffer = unmaskedPhoneNumber;
      }),
      [RegisterActions.ADD_PLUS]: action(async () => {
        const {unmaskedPhoneNumber} = resolvePhoneRechargeMask(this.state.inputBuffer + '+');
        this.state.inputBuffer = unmaskedPhoneNumber;
      }),
      [RegisterActions.INIT]: action(async ({previousState}) => {
        if (!this.customer.value && previousState === RegisterState.PHONE_NUMBER_CONFIRM) {
          this.dispatchEvent(new Event(RegisterStoreEvent.REQUEST_CUSTOMER_PHONE_NUMBER));
        }

        this.ensureCustomerDisplayState();

        this.state.inputBuffer = resolvePhoneRechargeMask(this.state.inputBuffer).unmaskedPhoneNumber;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        const {
          isPhoneNumberValid,
          phoneNumberGroup,
          unmaskedPhoneNumber,
          maskedPhoneNumber,
        } = resolvePhoneRechargeMask(event.value ?? this.state.inputBuffer);
        this.state.inputBuffer = unmaskedPhoneNumber;

        if (!this.state.inputBuffer || this.state.inputBuffer === phoneNumberGroup.prefix) return;

        if (!isPhoneNumberValid) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_PHONE_NUMBER, {
            detail: {
              phoneNumber: maskedPhoneNumber,
            },
          }));
          return;
        }

        this.state.productFlow.product.setPhoneNumberWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          this.state.inputBuffer,
        );

        await this.transitionToNextStep();
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        this.state.productFlow.inputMode = InputSource.SCANNER;
        await this.search(event.value, 'customer', {resetToDefaultStep: false});
      }),
    },
  };
}
