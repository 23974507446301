
import {computed, defineComponent} from 'vue';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {Theme} from '@/constants/theme';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export default defineComponent({
  name: 'Logo',
  inheritAttrs: false,
  setup() {
    const configurationStore = useConfigurationStore();
    const coreStore = useCoreStore();


    const isBlurred = computed(() => {
      if (coreStore.isAppVersionIncorrectRestart.value) {
        return true;
      }

      if (coreStore.isAppVersionResetToShop.value) {
        return true;
      }

      return false;
    });

    const logoBase64 = computed(() => {
      if (configurationStore.configuration.value?.ui?.style?.logo) {
        return configurationStore.configuration.value?.ui?.style?.logo;
      }

      const isLightTheme = configurationStore.configuration.value?.ui?.style?.theme.value === Theme.light;

      return isLightTheme ?
        require('@/assets/images/logo.svg').default :
        require('@/assets/images/logo_white.svg').default;
    });

    return {
      logoBase64,
      isBlurred,
    };
  },
});
