
import {defineComponent, PropType} from 'vue';
import WarehouseOperationVendorListItem
  from '@/Modules/Workflow/Step/StepWarehouseOperationVendor/WarehouseOperationVendorListItem.vue';
import VendorDto from '@/Model/Entity/VendorDto';

export default defineComponent({
  components: {WarehouseOperationVendorListItem},
  props: {
    list: {
      type: Array as PropType<Array<{isActive: boolean, entity: VendorDto}>>,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
    return {};
  },
});
