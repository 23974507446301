import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class PRCancelRequestDtoCustom extends createEntity({
  authorizationRequestId: {
    type: new StringType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
