import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FiscalDriver from '../FiscalDriver';
import {NumberType} from '@designeo/apibundle-js';
import LocationDataType from '../LocationDataType';
import PayTerminalType from '../PayTerminalType';
import PrinterType from '../PrinterType';


export default class GeneratedPrintAndPayment extends createEntity({
  buildingNumber: {
    type: new StringType(),
  },
  cashRegisterCode: {
    type: new StringType(),
  },
  certificateEet: {
    type: new StringType(),
  },
  companyIdEet: {
    type: new StringType(),
  },
  culture: {
    type: new StringType(),
  },
  dic: {
    type: new StringType(),
  },
  disablePrintWithoutVat: {
    type: new BooleanType(),
  },
  fiscalDriver: {
    type: new EntityType(FiscalDriver),
  },
  gpsx: {
    type: new NumberType(),
  },
  gpsy: {
    type: new NumberType(),
  },
  headerLine1: {
    type: new StringType(),
  },
  headerLine2: {
    type: new StringType(),
  },
  headerLine3: {
    type: new StringType(),
  },
  headerLine4: {
    type: new StringType(),
  },
  locationDataType: {
    type: new EntityType(LocationDataType),
  },
  logo: {
    type: new StringType(),
  },
  mandatoryReceiptPrint: {
    type: new BooleanType(),
  },
  modeEet: {
    type: new StringType(),
  },
  municiality: {
    type: new StringType(),
  },
  other: {
    type: new StringType(),
  },
  passwordEet: {
    type: new StringType(),
  },
  payTerminalIp: {
    type: new StringType(),
  },
  payTerminalPort: {
    type: new NumberType(),
  },
  payTerminalPortListener: {
    type: new NumberType(),
  },
  payTerminalVirtualId: {
    type: new StringType(),
  },
  payTerminalType: {
    type: new EntityType(PayTerminalType),
  },
  posIdEet: {
    type: new StringType(),
  },
  postalCode: {
    type: new StringType(),
  },
  printerIp: {
    type: new StringType(),
  },
  printerPort: {
    type: new NumberType(),
  },
  printerType: {
    type: new EntityType(PrinterType),
  },
  printerUrl: {
    type: new StringType(),
  },
  registrationNumber: {
    type: new StringType(),
  },
  shopIdEet: {
    type: new StringType(),
  },
  streetName: {
    type: new StringType(),
  },
  taxPayer: {
    type: new BooleanType(),
  },
  urlEet: {
    type: new StringType(),
  },
  windowsPrinterName: {
    type: new StringType(),
  },
  windowsPrinterNameA4: {
    type: new StringType(),
  },
}) {}
