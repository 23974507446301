import {
  map,
  mapValues,
} from 'lodash-es';

type Node<T> = T extends 'string' ? {
  'type': T,
  'description': string,
  'default': string,
} : T extends 'array' ? {
  'type': T,
  'description': string,
  'items': Array<Node<'string'>>,
} : T extends 'object' ? {
  'type': T,
  'description': string,
  'properties': Node<'string'> | Node<'array'> | Node<'object'>,
} : never

const parseNodeTypeArray = (val: Node<'array'> ) => {
  return map(val.items, parseNodeTypeString);
};

const parseNodeTypeObject = (val: Node<'object'>) => {
  return mapValues(val.properties, transformNode);
};

const parseNodeTypeString = (val: Node<'string'>) => {
  return val.default;
};

const transformNode = (node: Node<'string'> | Node<'array'> | Node<'object'>) => {
  if (node.type === 'array') {
    return parseNodeTypeArray(node);
  }

  if (node.type === 'object') {
    return parseNodeTypeObject(node);
  }

  return parseNodeTypeString(node);
};

export const jsonSchemaToJson = (json) => {
  return mapValues(json, transformNode);
};
