import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col flex-grow"
}
const _hoisted_3 = { class: "grid grid-cols-3 gap-4 mb-2" }
const _hoisted_4 = { class: "flex flex-col gap-2 pr-24" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-row items-center mt-auto mb-0"
}
const _hoisted_8 = { class: "font-bold pl-2" }
const _hoisted_9 = {
  key: 1,
  class: "flex flex-col flex-grow"
}
const _hoisted_10 = { class: "text-danger" }
const _hoisted_11 = { class: "flex flex-row mx-auto mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.workflowStep.fetchError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("button", {
              class: "btn btn-basic btn-outline-basic",
              onClick: _cache[0] || (_cache[0] = () => _ctx.workflowStep.generateCustomRandomNumbers({groupsCount: 1}))
            }, [
              _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.oneNumber), 1)
            ], 512), [
              [_directive_test, 'generate-one-number-row']
            ]),
            _withDirectives(_createElementVNode("button", {
              class: "btn btn-basic btn-outline-basic",
              onClick: _cache[1] || (_cache[1] = () => _ctx.workflowStep.generateCustomRandomNumbers({groupsCount: 5}))
            }, [
              _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.fiveNumbers), 1)
            ], 512), [
              [_directive_test, 'generate-five-number-row']
            ]),
            _withDirectives(_createElementVNode("button", {
              class: "btn btn-basic btn-outline-basic",
              onClick: _cache[2] || (_cache[2] = () => _ctx.workflowStep.generateCustomRandomNumbers({groupsCount: 10}))
            }, [
              _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.tenNumbers), 1)
            ], 512), [
              [_directive_test, 'generate-ten-number-row']
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.randomNumbers, (randomNumbersRow, rowIndex) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: rowIndex,
                class: "grid grid-cols-8 gap-4"
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(rowIndex + 1) + ". ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(randomNumbersRow, (number, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "korunka-circle-number korunka-circle-number--sm"
                  }, [
                    _createTextVNode(_toDisplayString(number), 1)
                  ], 512)), [
                    [_directive_test, 'korunka-number']
                  ])
                }), 128)),
                _createVNode(_component_AsyncButton, {
                  test: 'korunka-number-row-remove',
                  class: "btn btn-danger p-0",
                  onClick: () => _ctx.workflowStep.removeRandomNumbersRow(rowIndex)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PhIcon, {
                      icon: "trash",
                      size: 15
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ], 512)), [
                [_directive_test, 'korunka-number-row']
              ])
            }), 128))
          ]),
          (_ctx.entity?.randomNumbers?.length < 10)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_AsyncButton, {
                  class: "btn btn-basic btn-outline-basic mt-2",
                  onClick: () => _ctx.workflowStep.addRandomNumbersRow()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.addRandomNumbersRow), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.entity?.randomNumbers?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _withDirectives(_createVNode(_component_InputCheckbox, {
                  modelValue: _ctx.entity.booster,
                  "onUpdate:modelValue": value => _ctx.onUpdateModel(value),
                  size: "sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue"]), [
                  [_directive_test, 'booster-checkbox']
                ]),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.workflowStep.messages.enableBooster), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('workflow.steps.korunka.workflowCannotContinue')), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_AsyncButton, {
              class: "btn-primary w-full shadow-md",
              onClick: () => _ctx.workflowStep.fetchNumbers()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.korunka.repeat')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
  ]))
}