
import {computed, defineComponent} from 'vue';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useI18n} from 'vue-i18n';
import {useContextMixin} from '@/Modules/IncorrectRestart/IncorrectRestart/Contents/contextMixin';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'ContentSellDocument',
  setup() {
    const registerStore = useRegisterStore();
    const i18n = useI18n();
    const {lastLoggedUser} = useContextMixin();
    const {dateTimeFormat} = useFilters();


    const documentType = computed(() => {
      return registerStore.sellDocument.value?.isModeStorno ?
        i18n.t('incorrectRestart.types.sellDocument.flowTypes.return') :
        i18n.t('incorrectRestart.types.sellDocument.flowTypes.sell');
    });

    const cashier = computed(() => {
      return registerStore.sellDocument.value?.header?.cashier ?? lastLoggedUser.value;
    });

    const date = computed(() => {
      return dateTimeFormat(registerStore.sellDocument.value?.header?.documentDate ?? new Date());
    });


    return {
      documentType,
      cashier,
      date,
    };
  },
});
