
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '../../../Components/Modal.vue';
import {usePrintContentStore} from '@/Modules/Core/store/PrintContentStore';
import {ZLayer} from '@/constants/zLayer';
import {FileUrl} from '@/Helpers/file';
import PrintContent from '@/Components/PrintContent.vue';

export default defineComponent({
  name: 'ModalPrintContent',
  components: {
    PrintContent,
    Modal,
  },
  setup() {
    const printContentStore = usePrintContentStore();

    const close = () => {
      printContentStore.close();
    };

    const content = computed(() => {
      if (!printContentStore.printContent.value?.content) {
        return null;
      }

      return FileUrl.fromDataString(printContentStore.printContent.value?.content);
    });

    const modalType = computed(() => {
      return printContentStore.printContent.value?.modalType;
    });

    return {
      content,
      close,
      modalType,
      printContentStore,
      ZLayer,
    };
  },
});
