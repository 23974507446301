import {
  createEntity,
  DateTimeType,
  NumberType,
} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class VoucherCheckDtoCustom extends createEntity({
  voucherId: {
    type: new StringType(),
  },
  emissionId: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  type: {
    type: new StringType(),
  },
  voucherNumber: {
    type: new StringType(),
  },
  amount: {
    type: new NumberType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  articleNumber: {
    type: new StringType(),
  },
}) {}
