import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const OrderDeliveryNoteItemDtoPropDef = {
  title: {
    type: new StringType(),
  },
  internalNumber: {
    type: new StringType(),
  },
  unit: {
    type: new StringType(),
  },
  quantity: {
    type: new NumberType(),
  },
};
export default class GeneratedOrderDeliveryNoteItemDto extends createEntity(OrderDeliveryNoteItemDtoPropDef) {}
