import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';

export default class GeneratedCurrency extends createEntity({
  localSymbol: {
    type: new StringType(),
  },
  nominals: {
    type: new ArrayType(new NumberType()),
  },
  smallestAcceptedNominal: {
    type: new NumberType(),
  },
  maxAcceptedCashAmount: {
    type: new NumberType(),
  },
  symbol: {
    type: new StringType(),
  },
  isoNumber: {
    type: new StringType(),
  },
  coinThreshold: {
    type: new NumberType(),
  },
}) {}
