/* eslint-disable max-len */
export enum JournalEventTypes {
  StartPosBe = 'StartPosBe',
  StartPosFe = 'StartPosFe',
  EndPosFe = 'EndPosFe',
  MoPortalOpen = 'MoPortalOpen',
  OperatorSuccessfulLogin = 'OperatorSuccessfulLogin',
  OperatorSuccessfulLogout = 'OperatorSuccessfulLogout',
  OperatorLoginFailed = 'OperatorLoginFailed',
  SellDocumentCreate = 'SellDocumentCreate',
  SellDocumentCancel = 'SellDocumentCancel',
  DocumentItemCancel = 'DocumentItemCancel',
  CustomerAdd = 'CustomerAdd',
  SelectPaymentEnter = 'SelectPaymentEnter',
  SelectPaymentLeaveToEnterArticle = 'SelectPaymentLeaveToEnterArticle',
  PaymentInLocalCurrency = 'PaymentInLocalCurrency',
  PaymentInForeignCurrency = 'PaymentInForeignCurrency',
  FourEyesAuthorization = 'FourEyesAuthorization',
  DocumentItemChange = 'DocumentItemChange',
  DocumentItemPriceChange = 'DocumentItemPriceChange',
  CashManipulation = 'CashManipulation',
  Promotion = 'Promotion',
  StornoDocumentCreate = 'StornoDocumentCreate',
  WorkflowStarted = 'WorkflowStarted',
  WorkflowFinished = 'WorkflowFinished',
  WorkflowCancelled = 'WorkflowCancelled',
  PosStateReport = 'PosStateReport',
  FinancialCloseDay = 'FinancialCloseDay',
  TrainingModeStarted = 'TrainingModeStarted',
  TrainingModeEnded = 'TrainingModeEnded',
  CardPaymentStart = 'CardPaymentStart',
  PrintStart = 'PrintStart',
  ErrorDocumentCreate = 'ErrorDocumentCreate',
  LocalStorageClear = 'LocalStorageClear',
}
