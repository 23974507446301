import {fixDecimals} from '@/Helpers/math';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export const nominals = (amount: number, nominals: number[]) => {
  const isNegative = amount < 0;
  amount = Math.abs(amount);

  let decomposableTotal: number = 0;

  for (const nominal of nominals) {
    const times = Math.floor(fixDecimals(amount / nominal));
    amount = fixDecimals(amount - (nominal * times), 10);
    decomposableTotal = fixDecimals(decomposableTotal + (nominal * times));
  }

  const reminder = amount === 0 ? 0 : amount; // neg zero
  const decomposableRoundedUp = fixDecimals(decomposableTotal + (amount === 0 ? 0 : Math.min(...nominals)));
  const decomposableRoundedDown = decomposableTotal;
  const decomposableRounded = (reminder / Math.min(...nominals)) < 0.5 ?
    decomposableRoundedDown :
    decomposableRoundedUp;

  return {
    decomposableTotal: isNegative ? decomposableTotal * -1 : decomposableTotal,
    reminder: isNegative ? reminder * -1 : reminder,
    decomposableRounded: isNegative ? decomposableRounded * -1 : decomposableRounded,
    decomposableRoundedUp: isNegative ? decomposableRoundedUp * -1 : decomposableRoundedUp,
    decomposableRoundedDown: isNegative ? decomposableRoundedDown * -1 : decomposableRoundedDown,
    infiniteReminder: reminder === fixDecimals(Math.min(...nominals) / 2, 3),
  };
};

export enum CashNominalRoundingStrategies {
  SK = 'SK'
}

export const cashNominalRoundingByStrategy = (
  value,
  sellDocumentValue,
  {
    cashRoundingStrategy = useConfigurationStore().configuration.value
      ?.general
      ?.cashRoundingStrategy as CashNominalRoundingStrategies,
  }: {
    cashRoundingStrategy?: CashNominalRoundingStrategies
  } = {},
) => {
  if (cashRoundingStrategy === CashNominalRoundingStrategies.SK) {
    const isNegative = value < 0;
    if (value !== 0 && sellDocumentValue !== 0 && Math.abs(sellDocumentValue) < 0.05) {
      return 0.05 * (isNegative ? -1 : 1);
    }
  }

  return value;
};
