import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const OrderCustomerPreviewDtoPropDef = {
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  phoneNumber: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
};
export default class GeneratedOrderCustomerPreviewDto extends createEntity(OrderCustomerPreviewDtoPropDef) {}
