import {
  createEntity,
  EntityType,
  NumberType,
} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import ErrorMessage from '../ErrorMessage';

export default class PackCanSellDtoCustom extends createEntity({
  canSell: {
    type: new BooleanType(),
  },
  errorMessage: {
    type: new EntityType(ErrorMessage),
  },
  packNumber: {
    type: new StringType(),
  },
  numberOfTickets: {
    type: new NumberType(),
  },
  packPrice: {
    type: new NumberType(),
  },
  issueNumber: {
    type: new StringType(),
  },
  issueName: {
    type: new StringType(),
  },
}) {}
