import EasyCoinOrderDtoCustom from './custom/EasyCoinOrderDtoCustom';

export default class EasyCoinOrderDto extends EasyCoinOrderDtoCustom {
  get canBeSold() {
    return !!this.id;
  }

  get errorReason() {
    return null;
  }

  get errorReasonForCustomer() {
    return null;
  }
}
