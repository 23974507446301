import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FiscalCommands from '../FiscalCommands';
import TransactionDirectionType from '../TransactionDirectionType';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import DocumentPaymentDto from '../DocumentPaymentDto';
import {ArrayType} from '@designeo/apibundle-js';
import DocumentFinItemDto from '../DocumentFinItemDto';


export const PaymentReportDtoPropDef = {
  documentNumber: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(FiscalCommands),
  },
  source: {
    type: new EntityType(TransactionDirectionType),
  },
  destination: {
    type: new EntityType(TransactionDirectionType),
  },
  documentDateTime: {
    type: new DateTimeType(),
  },
  sapTransactionCode: {
    type: new StringType(),
  },
  note: {
    type: new StringType(),
  },
  finDocumentName: {
    type: new StringType(),
  },
  finDocumentCode: {
    type: new StringType(),
  },
  finDocumentTransactionNumber: {
    type: new NumberType(),
  },
  payment: {
    type: new EntityType(DocumentPaymentDto),
  },
  finItems: {
    type: new ArrayType(new EntityType(DocumentFinItemDto)),
  },
  paymentFinalValue: {
    type: new NumberType(),
  },
  paymentFinalValueinFCurrency: {
    type: new NumberType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  cardType: {
    type: new StringType(),
  },
  reference: {
    type: new StringType(),
  },
};
export default class GeneratedPaymentReportDto extends createEntity(PaymentReportDtoPropDef) {}
