import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {
  apiSearchPromotions,
} from '@/Model/Action';
import {PointsBurningFlow} from './../PromoInteractionFlow/PointsBurningFlow';
import {QuestionFlow} from '@/Modules/Register/PromoInteractionFlow/QuestionFlow';
import {DynamicSetSelectionFlow} from '@/Modules/Register/PromoInteractionFlow/DynamicSetSelectionFlow';
import {PromoFlow} from './../PromoInteractionFlow/index';
import {
  PromoSendSmsPriority,
  PromotionAvailablePointsBurning,
  promotionByType,
  PromotionMeta,
  PromotionMetaType,
} from '@designeo/pos-promotion-engine';
import {BufferedInput, InputSource} from '../services/KeyboardBuffer';
import {
  every,
  filter,
  find,
  first,
  flow,
  has,
  includes,
  isNil,
  last,
  map,
  mapKeys,
  orderBy,
  reject,
  some,
  sumBy,
  uniqBy,
} from 'lodash-es';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {
  CustomerAuthenticationState,
  DisplayState,
  DisplayVariants,
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
  RegisterStoreEvent,
} from '../types';
import {markRaw, toRaw} from 'vue';
import {
  KEYBOARD_KEY_ARROW_DOWN,
  KEYBOARD_KEY_ARROW_UP,
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_COMMA,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEY_MINUS,
  KEYBOARD_KEY_PERIOD,
  KEYBOARD_KEY_PLUS,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {
  api3rdPartyCancel,
  api3rdPartyCharge,
  api3rdPartyCheck,
  api3rdPartyRevoke,
  apiCustomersByCard,
  apiCustomersCardAvailable,
  apiCustomersContactsDelete,
  apiCustomersGet,
  apiCustomersVerifyPin,
  apiDocumentCreate,
  apiSearch,
  apiSms,
  apiStockGet,
  apiV1PosCustomersDeactivateCard,
} from '../../../Model/Action';
import {
  CustomerContactDto,
  DocumentCustomerDto,
  DocumentDto,
  DocumentItemDto,
  DocumentPaymentDto,
  PinDto,
  ResultDto,
  SendSmsDto,
  StockDto,
} from '../../../Model/Entity';
import {
  AppLoaderEvent,
  FourEyesOperations,
  PrinterWSEvents,
} from '../../Core/types';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {useConfigurationStore} from '../../Core/store/ConfigurationStore';
import {registerStoreStatePersist, registerStoreStatesPersist} from '@/Helpers/persist';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {useFourEyesStore} from '@/Modules/Core/store/FourEyesStore';
import {amIOnline} from '@/Helpers/onlineDetection';
import {
  submitJournalEventCardPaymentStart,
  submitJournalEventCustomerAdd,
  submitJournalEventDocumentItemChange,
  submitJournalEventPrintStart,
  submitJournalEventSelectPaymentEnter,
  submitJournalEventSellDocumentCancel,
  submitJournalEventSellDocumentCreate,

} from '@/Helpers/journal';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {getTransitions} from '@/Modules/Register/store/transitions';
import {ProductFlow} from '@/Modules/Register/ProductFlow/ProductFlow';
import {fixDecimals} from '@/Helpers/math';
import {GroupBySets} from '@/Model/Entity/DocumentDto';
import {
  sentryEnsureSellDocumentTransaction,
  sentryRecordSellDocumentCancel,
  sentryRecordSellDocumentInit,
} from '@/Helpers/sentry';
import {setResultProcessedAt, useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {
  resolveStepsForArticleTypeCheckAndCharge,
  resolveStepsForArticleTypeRegular,
} from '@/Modules/Register/store/steps';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {useCashAmountValidatorStore} from '@/Modules/Core/store/CashAmountValidatorStore';
import {usePrintContentStore} from '@/Modules/Core/store/PrintContentStore';
import StockFilterDto from '@/Model/Entity/StockFilterDto';
import {
  DocumentGiftPool,
  DynamicSetLevelsSelection,
  DynamicSetSelection,
  GecoGameAvailable,
  FollowUpDocument,
  InteractionPriority,
  PromotionQuestion,
  PromotionRequestPointsBurn,
} from '@designeo/pos-promotion-engine/src/blocks/types';
import {CUSTOMER_STATE_IN_VERIFICATION} from '@/constants/customerStates';
import CustomerCardPreviewDto from '../../../Model/Entity/CustomerCardPreviewDto';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {emitTestEvent} from '@/Helpers/testEvent';
import {mapStockList} from '@/Helpers/batch';
import {syncToTarget} from '@/Helpers/syncedStore';
import {MediaType} from '../../CustomerExternal/syncTarget/PromotionSyncTarget';
import {InputRestrictionTypes} from '@/constants/inputRestrictionTypes';
import {useTrainingStore} from '@/Modules/Training/store/TrainingStore';
import {AskForCustomerCard} from '@/constants/askForCustomerCard';
import {CustomerAlert} from '@/Modules/CustomerExternal/types';
import {getResponseCodeConfiguration} from '@/Helpers/printerServiceResponseCodes';
import {DocumentCreateMode} from '@/constants/documentModeTypes';
import {QuickCall, QuickCallData} from '../QuickCall/Classes/QuickCall';
import {
  QuickCallDisplayWhen,
  QuickCallPanelPriority,
  QuickCallTypes,
} from '../QuickCall/types';
import PromoPointsBurningButton from '../../../Model/Entity/custom/PromoPointsBurningButton';
import {usePromoEngine} from '@/Modules/Register/PromoEngine/PromoEngine';
import {CashNominalRoundingStrategies} from '@/Helpers/nominals';
import DelaySellDataDtoCustom from '@/Model/Entity/custom/DelaySellDataDtoCustom';
import {CustomerDataField} from '@/constants/additionalData';
import VoucherResultDto from '@/Model/Entity/VoucherResultDto';
import CustomerAdditionalDataDto from '@/Model/Entity/CustomerAdditionalDataDto';
import {PromoFlowLock, withLock} from '@/Helpers/lock';
import {Tabs} from '@/Modules/Register/Modal/ModalCustomerDetail/customerDetail';
import {DocumentSubmitProcessResult, SaveFlow} from '@/Modules/Register/SaveFlow/SaveFlow';
import {preventParallel} from '@/Helpers/promise';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {recordCustomEventLogEntry} from '@/Helpers/logger';
import {FollowUpDocumentFlow} from '@/Modules/Register/PromoInteractionFlow/FollowUpDocumentFlow';
import {
  Payment,
  PaymentEvents,
  PaymentModes,
} from '@/Modules/Payment/payment';

type InteractivePromotion = PromotionQuestion | DocumentGiftPool | GecoGameAvailable | PromotionRequestPointsBurn;

type ICustomerDetail = {
  activeTab?: Tabs,
}

export interface IRegisterStore {
  loadedProducts: Array<DocumentItemDto>,
  state: RegisterState,
  previousState: RegisterState,
  inputBuffer: string,
  payment: DocumentPaymentDto,
  sellDocument: DocumentDto,
  sellDocumentBackup: DocumentDto,
  lastSellDocument: DocumentDto,
  lastSearchedSubject: string,
  promoFlow: PromoFlow,
  paymentsVerificationInProgress: boolean,
  productFlow: ProductFlow,
  productDetail: {
    item: DocumentItemDto,
    stock: StockDto,
    stockInStores: boolean,
  },
  customerDetail: ICustomerDetail,
  quickCallActiveMap: Map<string, {
    code: String,
    isCustomButton?: boolean,
  }>
  returnMode: boolean,
  insertMode: boolean,
  editOf: number,
  displayError: any,
  saveFlow: SaveFlow,
  quickCallPanelPriority: QuickCallPanelPriority,
  customerAuthentication: {
    state: CustomerAuthenticationState,
    callback: Function,
    description?: String,
  },
  customData: {
    formEntity: CustomerAdditionalDataDto,
    activeField: CustomerDataField,
  },
  delayedSellData: DelaySellDataDtoCustom,
  unreturnableGroupsSeen: boolean,
  unassignedCard: string,
  disablePromotionTrigger: boolean,
}

export const createCustomerDetailInitState = (state: Partial<ICustomerDetail> = {}) => {
  return state;
};

export const createInitState = (data?: Partial<IRegisterStore>) => ({
  state: RegisterState.ENTER_CUSTOMER_CARD,
  previousState: null,
  loadedProducts: [],
  payment: null,
  promoFlow: null,
  productFlow: new ProductFlow(),
  paymentsVerificationInProgress: false,
  productDetail: null,
  customerDetail: null,
  customerAuthentication: null,
  sellDocument: null,
  sellDocumentBackup: null,
  lastSellDocument: null,
  lastSearchedSubject: '',
  inputBuffer: '',
  quickCallActiveMap: new Map(),
  returnMode: false,
  insertMode: false,
  editOf: null,
  displayError: null,
  saveFlow: new SaveFlow(),
  quickCallPanelPriority: QuickCallPanelPriority.QuickCall,
  customData: null,
  delayedSellData: null,
  unreturnableGroupsSeen: false,
  unassignedCard: null,
  disablePromotionTrigger: false,
  ...data,
});

export class RegisterStore extends PersistentStore<IRegisterStore> {
  constructor() {
    super(createInitState(), registerStoreStatePersist, {autoPersist: false});
    this.ensureCustomerDisplayState();
    broadcastIO.addEventListener(BroadcastIOChannels.EXTERNAL_DISPLAY_LOADED, () => {
      this.ensureCustomerDisplayState();
    });

    for (const error of Object.values(RegisterStoreErrors)) {
      this.addEventListener(error, (event: CustomEvent) => {
        emitTestEvent(TestEvent.ERROR, {
          type: 'PurchaseError',
          event: event.type,
          ...(event?.detail !== undefined ? {
            data: JSON.parse(JSON.stringify(event.detail)),
          } : {}),
        });
      });
    }
  }

  // transitions

  transitions = <ReturnType<typeof getTransitions>>getTransitions.call(this);

  // actions

  addCustomer = action(
    async (customer: DocumentCustomerDto, fetchBy: 'cardNumber' | 'customerNumber' = 'cardNumber', {
      skipVerificationCheck = false,
      unassignedCardProcess = true,
    } = {}): Promise<boolean> => {
      if (await amIOnline()) {
        customer = await this.fetchCustomer(customer, fetchBy, {unassignedCardProcess});
      }

      if (this.customer.value && !this.customer.value.isSameCustomer(customer)) {
        const canBeReplaced = await new Promise((resolve) => {
          this.dispatchEvent(new CustomEvent(RegisterStoreEvent.CONFIRM_CUSTOMER_REPLACEMENT, {
            detail: {
              callback: resolve,
            },
          }));
        });

        if (!canBeReplaced) {
          return false;
        }

        await this.closeCustomerFeaturedAttributes();
      }

      submitJournalEventCustomerAdd(customer, {
        action: this.customer.value ? null : true,
        productFlow: this.state.productFlow,
      });

      this.state.sellDocument.addCustomer(customer);

      await this.promotionHookCustomerChanged();

      this.ensureCustomerDisplayState();

      if (
        !skipVerificationCheck &&
        this.customer.value.state.value === CUSTOMER_STATE_IN_VERIFICATION &&
        await amIOnline()
      ) {
        this.dispatchEvent(new CustomEvent(RegisterStoreEvent.UNVERIFIED_CUSTOMER_ADDED));
      }

      emitTestEvent(TestEvent.CUSTOMER_ADDED);

      return true;
    },
  )

  removeCustomer = action(async () => {
    submitJournalEventCustomerAdd(this.customer.value, {
      action: false,
      productFlow: this.state.productFlow,
    });

    await this.closeCustomerFeaturedAttributes();

    this.state.sellDocument.removeCustomer();

    await this.promotionHookCustomerChanged();
    await this.persist();
  })

  updateCustomer = action(async (customer: DocumentCustomerDto) => {
    this.state.sellDocument.customer = new DocumentCustomerDto({
      ...this.state.sellDocument.customer.clone().toJson(),
      ...customer.toJson(),
    });
    await this.promotionHookCustomerChanged();
    await this.persist();
  })

  refreshCustomer = action(async () => {
    if (this.customer.value) {
      const isCustomerFetched = this.customer.value.isFetched;
      const searchBy = isCustomerFetched ? 'customerNumber' : 'cardNumber';
      const customer = await this.fetchCustomer(this.customer.value, searchBy, {
        unassignedCardProcess: false,
      });

      await this.updateCustomer(customer);
      await this.persist();
    }
  })

  addCustomerContact = action(async (customerContact: CustomerContactDto) => {
    this.state.sellDocument.customer.contacts = [
      ...this.state.sellDocument.customer.contacts,
      customerContact,
    ];

    await this.persist();
  })

  deleteCustomerContact = action(async (contactId) => {
    try {
      await apiCustomersContactsDelete({
        params: {
          contactId,
          customerNumber: this.customer.value.customerNumber,
        },
      });
    } catch (e) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
        detail: e,
      }));

      throw e;
    }

    await this.updateCustomer(await this.fetchCustomer(this.customer.value, 'customerNumber'));
  })

  deleteCustomerCard = action(async (cardNumber) => {
    try {
      await apiV1PosCustomersDeactivateCard({
        input: new CustomerCardPreviewDto({cardNumber}),
        params: {
          customerNumber: this.customer.value.customerNumber,
        },
      });
    } catch (e) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
        detail: e,
      }));

      throw e;
    }

    await this.updateCustomer(await this.fetchCustomer(this.customer.value, 'customerNumber'));

    emitTestEvent(TestEvent.CUSTOMER_CARD_REMOVED);
  })

  amIOnline = action(async () => {
    const online = await amIOnline();

    if (!online) {
      this.dispatchEvent(new Event(RegisterStoreErrors.OFFLINE));
    }

    return online;
  })

  archiveState = action(() => {
    // this.state.sellDocument.header //TODO after BE add attributes for parking => fill them

    const archivedStates = uniqBy([
      this.state,
      ...(this.listArchivedStates()),
    ], 'sellDocument.header.uniqueidentifier');

    registerStoreStatesPersist.set(archivedStates);

    this.state = Object.assign(this.state, createInitState());
  })

  cancelGroup = action(async (group: GroupBySets) => {
    if (!await this.fourEyes(FourEyesOperations.PRODUCT_CANCEL, group.editableItem)) return;
    if (group.editableItem.isCheckAndCharge && group.editableItem.isCheckAndChargeCancellable) {
      await this.ensureCheckAndChargeCancel([group]);
    }
    this.state.sellDocument.cancelItem(group, {id: null});
    await this.fetchPromoEngineResult();
    await this.persist();
  })

  cancelPayment = action(async (index: number) => {
    const payment: DocumentPaymentDto = this.state.sellDocument.validPayments[index];

    if (!payment) return;

    if (
      payment.type.isCardMethod &&
      !this.state.sellDocument.isModeStorno &&
      index === this.state.sellDocument.validPayments.length - 1
    ) {
      await this.cancelLastCardPayment();
    } else if (payment.type.isCardMethod) {
      await this.refundCardPayments([payment]);
    } else {
      payment.isCanceled = true;
      this.state.sellDocument.removeRounding();
    }
  })

  setPayment = action((payment: DocumentPaymentDto) => {
    if (!payment) {
      this.clearQuickCallActiveMap();
    } else if (payment.code) {
      this.clearQuickCallActiveMap();
      this.quickCallActiveMap.value.set(payment.code, payment.toJson());
    }

    this.state.payment = payment;
  })

  cancelSellDocumentCardPayments = action(async () => {
    if (!this.state.sellDocument.isModeStorno) {
      await this.cancelLastCardPayment();
    }

    await this.refundCardPayments();
  })

  cancelSellDocument = action(async ({fourEyes = true} = {}) => {
    if (fourEyes && !await this.fourEyes(FourEyesOperations.RECEIPT_CANCEL)) {
      return;
    }

    await this.cancelSellDocumentCardPayments();

    const cancellableGroups = this.state.sellDocument.validCheckAndChargeCancellableGroups;

    try {
      await this.ensureCheckAndChargeCancel(cancellableGroups);
    } finally {
      for (const cancellableGroup of cancellableGroups) {
        if (
          cancellableGroup.editableItem.revokeResponse?.isSuccessful ||
          cancellableGroup.editableItem.cancelResponse?.isSuccessful
        ) {
          this.state.sellDocument.cancelItem(cancellableGroup, {id: null});
        }
      }

      await this.fetchPromoEngineResult();
      await this.persist();
    }


    this.state.sellDocument.cancel();

    try {
      this.dispatchEvent(new Event(AppLoaderEvent.ON));

      this.state.sellDocument.preflightSetup();

      if (this.state.sellDocument.isModeStorno) {
        this.state.sellDocument.header.referentialUniqueidentifier = null;
      }

      await apiDocumentCreate({
        input: this.state.sellDocument.toApiClone(),
      });

      sentryRecordSellDocumentCancel(this.state.sellDocument);

      submitJournalEventSellDocumentCancel(this.state.sellDocument);

      await this.resetReceipt();

      emitTestEvent(TestEvent.RECEIPT_CANCELED);
    } catch (e) {
      console.error(e);
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  })

  commitProduct = action(async () => {
    const isCheckAndChargeTypeVoucherDiscountAndUnique = this.state.productFlow.product
      .validateCheckAndChargeTypeVoucherDiscountWithinContext(this.state.sellDocument);

    if (!isCheckAndChargeTypeVoucherDiscountAndUnique) {
      this.dispatchEvent(
        new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_VOUCHER_DISCOUNT, {
          detail: this.state.productFlow.product.serialNo,
        }),
      );
      throw new Error(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_VOUCHER_DISCOUNT);
    }

    const isCheckAndChargeTypeLotAndUnique = this.state.productFlow.product
      .validateCheckAndChargeTypeLotWithinContext(this.state.sellDocument);

    if (!isCheckAndChargeTypeLotAndUnique) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT));
      throw new Error(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT);
    }

    this.state.sellDocument.addItem(this.state.productFlow.product);
  })

  ensurePurchaseFlowReset = action(async () => {
    await this.restoreSellDocumentBackup();
    this.state.productFlow.reset();
    await this.resetToDefaultStep();
  })

  saveProduct = action(async () => {
    try {
      this.productFlow.value.isSaving = true;
      this.dispatchEvent(new Event(AppLoaderEvent.ON));
      const product = this.currentEditGroup.value.editableItem;
      const group = this.currentEditGroup.value;

      if (product.isCheckAndCharge && product.isFilled) { // ?? isFilled
        const productClone: DocumentItemDto = product?.clone();
        await this.ensureCheckAndChargeCheck(group);

        if (!product.hasPriceSourceConditionVariable && productClone.priceAction !== product.priceAction) {
          try {
            await this.ensureCheckAndChargeCancel([group]);

            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.PRICE_CHANGE_NOT_ALLOWED));

            throw new Error(RegisterStoreErrors.PRICE_CHANGE_NOT_ALLOWED);
          } catch (e) {
            if (e.message === RegisterStoreErrors.PRICE_CHANGE_NOT_ALLOWED) {
              await this.ensurePurchaseFlowReset();
              throw e;
            }

            console.error(e);
          }
        }
      }


      if (this.state.productFlow.isNew) {
        await submitJournalEventDocumentItemChange(this.currentEditGroup.value, {
          productFlow: this.state.productFlow,
        });
      }

      this.state.productFlow.reset();
      await this.resetToDefaultStep();

      if (
        (
          this.configuration.value?.features?.storno?.autoUntoggleStornoAfterAddingArticle ||
          (
            this.configuration.value?.features?.storno?.allowAddingStornoOnlyToEmptyBon &&
            this.state.sellDocument.validItems.length
          )
        ) && this.isReturnModeActive.value
      ) {
        await this.toggleReturnMode();
      }

      await this.promotionHookItemsChanged();
      emitTestEvent(TestEvent.ARTICLE_SAVED);
    } catch (e) {
      console.error(e);
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      this.productFlow.value.isSaving = false;
    }
  })

  removeSellDocumentBackup = action(async () => {
    this.state.sellDocumentBackup = null;
    await this.persist();
  });

  createSellDocumentBackup = action(async () => {
    this.state.sellDocumentBackup = this.state.sellDocument.clone();
    await this.persist();
  })

  editGroup = action(async (group: GroupBySets) => {
    this.state.editOf = group.index;
    this.state.productFlow.product = this.currentEditGroup.value.editableItem.clone();
    await this.transitionToNextStep();

    await this.promotionHookActiveArticleChanged();

    if (this.hasAvailableContextualProductRecommendations.value) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.ContextualProductRecommendations);
    }
  })

  ensureCheckAndChargeCheck = action(async (group: GroupBySets) => {
    if (this.state.productFlow.product.isReturn) {
      return;
    }

    let request;

    if (this.state.productFlow.product.isCheckAndChargeCancellable) {
      await this.ensureCheckAndChargeCancel([group]);
      request = this.state.productFlow.product.ensureCheckAndChargeCheckRequest(
        this.sellDocument.value,
        group.index,
        {cache: false},
      );
    } else {
      request = this.state.productFlow.product.ensureCheckAndChargeCheckRequest(
        this.sellDocument.value,
        group.index,
      );
    }


    let response;

    try {
      response = await api3rdPartyCheck({
        input: request.toJson(),
        params: {
          provider: this.state.productFlow.product.provider,
          serviceType: this.state.productFlow.product.serviceType,
          ...this.state.productFlow.product.getCommonCheckAndChargeParams(this.sellDocument.value),
        },
      });
    } catch (e) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
        detail: e,
      }));
      await this.ensurePurchaseFlowReset();
      throw e;
    }

    if (!response.isSuccessful) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.CHECK_AND_CHARGE_FAILED, {
        detail: response.getErrorMessageDetailFromGroup(this.state.sellDocument, group.index),
      }));

      if (response.errorReasonForCustomer) {
        this.dispatchEvent(new CustomEvent<CustomerAlert>(RegisterStoreErrors.CUSTOMER_ERROR, {
          detail: {
            message: response.errorReasonForCustomer,
          },
        }));
      }

      if (!response.canBeSell) {
        await this.ensurePurchaseFlowReset();
      }

      throw new Error(response.errorReason ?? 'Error N/A');
    }

    this.state.productFlow.product.setCheckResponseWithinContext(
      this.state.sellDocument,
      this.state.editOf,
      response,
    );
  })

  ensureCheckAndChargeCharge = action(async () => {
    for (const group of this.state.sellDocument.validCheckAndChargeUnchargedGroups) {
      /**
       * Some CH&CH types could charge other articles, so bypass isSuccessful charge responses
       */
      if (group.editableItem.isReturn || group.editableItem.chargeResponse?.isSuccessful) {
        continue;
      }

      const request = group.editableItem.ensureCheckAndChargeChargeRequest(this.sellDocument.value, group.index);

      try {
        this.dispatchEvent(new Event(AppLoaderEvent.ON));

        const response = await api3rdPartyCharge({
          input: request.toJson(),
          params: {
            provider: group.editableItem.provider,
            serviceType: group.editableItem.serviceType,
            ...group.editableItem.getCommonCheckAndChargeParams(this.sellDocument.value),
          },
        });

        group.editableItem.setChargeResponseWithinContext(
          this.state.sellDocument,
          group.index,
          response,
        );

        await this.persist();

        if (!response.isSuccessful) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.CHECK_AND_CHARGE_FAILED, {
            detail: response.getErrorMessageDetailFromGroup(this.state.sellDocument, group.index),
          }));

          if (response.errorReasonForCustomer) {
            this.dispatchEvent(new CustomEvent<CustomerAlert>(RegisterStoreErrors.CUSTOMER_ERROR, {
              detail: {
                message: response.errorReasonForCustomer,
              },
            }));
          }

          throw new Error('isNotSuccessful');
        }
      } catch (e) {
        if (e.message !== 'isNotSuccessful') {
          if (e?.response?.data?.additionalProperties?.shouldCancel) { // ch & ch api edge case!
            group.editableItem.setCheckAndChargeShouldCancelWithinContext(
              this.state.sellDocument,
              group.index,
              true,
            );

            await this.persist();
          }

          e?.response?.data?.errors?.unshift?.(group.editableItem.description);
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));

          /**
           * TODO: rework
           */
          throw new Error('isNotSuccessful');
        }

        throw e;
      } finally {
        this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    }
  })

  ensureCheckAndChargeCancel = action(async (groups: GroupBySets[]) => {
    for (const group of groups) {
      if (
        group.editableItem.revokeResponse?.isSuccessful ||
        group.editableItem.cancelResponse?.isSuccessful ||
        group.editableItem.isReturn
      ) {
        continue;
      }

      try {
        this.dispatchEvent(new Event(AppLoaderEvent.ON));

        if (group.editableItem.checkAndChargeShouldCancel || group.editableItem.chargeResponse?.isSuccessful) {
          const request = group.editableItem.ensureCheckAndChargeCancelRequest(this.sellDocument.value, group.index);

          const response = await api3rdPartyCancel({
            input: request.toJson(),
            params: {
              provider: group.editableItem.provider,
              serviceType: group.editableItem.serviceType,
              ...group.editableItem.getCommonCheckAndChargeParams(this.sellDocument.value),
            },
          });

          group.editableItem.setCancelResponseWithinContext(
            this.state.sellDocument,
            group.index,
            response,
          );

          await this.persist();

          if (!response.isSuccessful) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.CHECK_AND_CHARGE_FAILED, {
              detail: response.getErrorMessageDetailFromGroup(this.state.sellDocument, group.index),
            }));

            if (response.errorReasonForCustomer) {
              this.dispatchEvent(new CustomEvent<CustomerAlert>(RegisterStoreErrors.CUSTOMER_ERROR, {
                detail: {
                  message: response.errorReasonForCustomer,
                },
              }));
            }

            throw new Error('isNotSuccessful');
          }
        } else {
          const request = group.editableItem.ensureCheckAndChargeRevokeRequest(this.sellDocument.value, group.index);

          const response = await api3rdPartyRevoke({
            input: request.toJson(),
            params: {
              provider: group.editableItem.provider,
              serviceType: group.editableItem.serviceType,
              ...group.editableItem.getCommonCheckAndChargeParams(this.sellDocument.value),
            },
          });

          group.editableItem.setRevokeResponseWithinContext(
            this.state.sellDocument,
            group.index,
            response,
          );

          await this.persist();

          if (!response.isSuccessful) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.CHECK_AND_CHARGE_FAILED, {
              detail: response.getErrorMessageDetailFromGroup(this.state.sellDocument, group.index),
            }));

            if (response.errorReasonForCustomer) {
              this.dispatchEvent(new CustomEvent<CustomerAlert>(RegisterStoreErrors.CUSTOMER_ERROR, {
                detail: {
                  message: response.errorReasonForCustomer,
                },
              }));
            }

            throw new Error('isNotSuccessful');
          }
        }
      } catch (e) {
        if (e.message !== 'isNotSuccessful') {
          e?.response?.data?.errors?.unshift?.(group.editableItem.description);
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }

        throw e;
      } finally {
        this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    }
  })

  ensureSellDocument = action((onInitFinish = () => {}) => {
    if (!this.state.sellDocument) {
      this.state.sellDocument = DocumentDto.createEmptySellDocument();
      this.state.sellDocument.setBasicInfo();

      sentryRecordSellDocumentInit(this.state.sellDocument);

      (async () => {
        await Promise.all([
          usePromoEngine().updateConfiguration(),
          this.ensureInitState(), // calls persist on its own
        ]);

        onInitFinish();
      })();
    }

    sentryEnsureSellDocumentTransaction(this.state.sellDocument);
  })

  enterProductSearch = action(async () => {
    if (!await this.ensureProductSave()) return;

    await this.changeState(RegisterState.PRODUCT_SEARCH);
  })

  enterCustomerSearch = action(async () => {
    if (!await this.amIOnline()) {
      return;
    }

    if (!await this.ensureProductSave()) {
      return;
    }

    await this.changeState(RegisterState.CUSTOMER_SEARCH);
  })

  solvePrinterStatus = action(async (result: any, document: DocumentDto['_data']) => {
    try {
      return await this.documentStatusStore.value.solve(result, document);
    } catch (e) {
      console.error(e);
    }
  })

  fourEyes = action(async (operation: FourEyesOperations, subject?: DocumentItemDto | {[key: string]: any}) => {
    try {
      const fourEyesStore = useFourEyesStore();
      const result = await fourEyesStore.openFourEyesConfirm(operation, {subject: subject?.toString?.()});

      subject = subject ?? {};

      if (result.isConfirmed && !result.wasImmediate) {
        this.state.sellDocument.recordFourEyes({
          accessToken: result.accessToken,
          oneTimeCodeAuth: result.oneTimeCodeAuth,
          operation: fourEyesStore.operationToPermissions[operation]?.process,
          data: subject instanceof DocumentItemDto ? subject.toJson() : {},
        });
      }


      return result.isConfirmed;
    } catch (e) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.FOUR_EYES, {
        detail: e.message,
      }));
      return false;
    }
  })

  ensureInitState = action(async () => {
    if (this.sellDocument.value?.isTouched) {
      return;
    }

    if (this.configuration.value.features.customer.askForCustomerCard.value === AskForCustomerCard.never) {
      if (this.state.state !== RegisterState.ENTER_ARTICLE_NUMBER) {
        await this.changeState(RegisterState.ENTER_ARTICLE_NUMBER);
      }
      return;
    }

    /**
     * else is ENTER_CUSTOMER_CARD which is default of askForCustomerCard (onSellStart)
     */

    if (this.state.state !== RegisterState.ENTER_CUSTOMER_CARD) {
      await this.changeState(RegisterState.ENTER_CUSTOMER_CARD);
    }
  })

  changeState = action(async (state: RegisterState, {
    previousState = this.state.state,
    resetBuffer = true,
    initArg = {},
  }: Partial<{
    previousState: RegisterState,
    resetBuffer: boolean,
    initArg: {[key: string]: any}
  }> = {}) => {
    try {
      if (this.transitions[previousState]?.[RegisterActions.BEFORE_LEAVE]) {
        const transition = this.transitions[previousState]?.[RegisterActions.BEFORE_LEAVE];
        if (!(await transition({from: previousState, to: state}) ?? true)) return;
      }

      if (this.transitions[state]?.[RegisterActions.BEFORE_ENTER]) {
        const transition = this.transitions[state]?.[RegisterActions.BEFORE_ENTER];
        if (!(await transition({from: previousState, to: state}) ?? true)) return;
      }

      if (resetBuffer) {
        this.resetInputBuffer();
      }

      this.state.previousState = previousState;
      this.state.state = state;

      if (this.transitions[state]?.[RegisterActions.INIT]) {
        try {
          this.dispatchEvent(new Event(AppLoaderEvent.ON));

          await this.transitions[state][RegisterActions.INIT]({
            previousState,
            ...initArg,
          });
        } finally {
          this.dispatchEvent(new Event(AppLoaderEvent.OFF));
          this.ensureCustomerDisplayState();
        }
      }

      emitTestEvent(TestEvent.REGISTER_STATE_CHANGED);
      emitTestEvent(`${TestEvent.REGISTER_STATE_CHANGED}:${state}`);
      await this.persist();
    } catch (e) {
      console.error(e);
      throw e;
    }
  })

  checkSellFunctionPresetQuantity = action((): boolean => {
    if (this.isEditModeActive.value) {
      return false;
    }

    if (!this.state.inputBuffer) {
      return false;
    }

    if (!(/^[0-9]+$/.test(this.state.inputBuffer))) {
      return false;
    }

    const numberOfDigitsRecognizedAsQuantity = this.configuration.value.features
      ?.sell
      ?.numberOfDigitsRecognizedAsQuantity ?? 2;

    if (numberOfDigitsRecognizedAsQuantity === 0) {
      return false;
    }

    const inputBufferAsNumber = parseFloat(this.state.inputBuffer);

    if (inputBufferAsNumber === 0) {
      return false;
    }

    if (numberOfDigitsRecognizedAsQuantity < Math.floor(inputBufferAsNumber).toString(10).length) {
      return false;
    }

    this.state.productFlow.presetQuantity = inputBufferAsNumber;

    return true;
  })

  ensureCustomerDisplayState = action((event = new Event(RegisterStoreEvent.INIT_CUSTOMER_DISPLAY)) => {
    this.dispatchEvent(event);
  })

  listArchivedStates = action(() => {
    return registerStoreStatesPersist.get() || [];
  })

  onEventInput = action(async (event: RegisterInputEvent) => {
    try {
      return await this.transitions?.[this.state.state]?.[event.type]?.(event);
    } finally {
      /**
       * do not use this.persist, otherwise every char from scanner or paste will be delayed by persist to storage
       */
      this.persistWithDebounce();
    }
  })

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    const inputBuffer = this.state.inputBuffer;

    if (bufferedInput.source === InputSource.SCANNER) {
      await this.onEventInput({
        type: RegisterActions.SCANNER,
        value: map(bufferedInput.keys, 'key').join(''),
      });
      return; // or sanitize buffered input and fallthrough?
    }

    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: RegisterActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_PERIOD) {
        await this.onEventInput({
          type: RegisterActions.ADD_PERIOD,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_COMMA) {
        await this.onEventInput({
          type: RegisterActions.ADD_COMMA,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: RegisterActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: RegisterActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: RegisterActions.CANCEL,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_UP) {
        await this.onEventInput({
          type: RegisterActions.PREV,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_DOWN) {
        await this.onEventInput({
          type: RegisterActions.NEXT,
        });
      } else if (key.key === KEYBOARD_KEY_PLUS) {
        await this.onEventInput({
          type: RegisterActions.ADD_PLUS,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_MINUS) {
        await this.onEventInput({
          type: RegisterActions.ADD_MINUS,
          value: key.key,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        await this.onEventInput({
          type: RegisterActions.ADD_CHAR,
          value: key.key,
        });
      }
    }

    if (inputBuffer !== this.state.inputBuffer) {
      this.state.displayError = null;
    }
  });

  openCashDrawer = action(async () => {
    const {notificationsConnection} = useSignalR();

    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const cashDrawer = DocumentDto.createCashDrawerDocument();

      const sellDocumentHeaderGUID = cashDrawer.header.uniqueidentifier;

      await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== sellDocumentHeaderGUID) return false;

          const solvingResult = (await this.solvePrinterStatus(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        cashDrawer.preflightSetup();

        return await apiDocumentCreate({
          input: cashDrawer.toApiClone(),
        });
      });
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.value.terminate();
      }
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  printCustomerExpiredPoints = action(async () => {
    const {notificationsConnection} = useSignalR();

    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const expiredPointsPrint = DocumentDto.createCustomerExpiredPointsPrint(this.sellDocument.value.customer);

      const sellDocumentHeaderGUID = expiredPointsPrint.header.uniqueidentifier;

      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== sellDocumentHeaderGUID) return false;

          const solvingResult = (await this.solvePrinterStatus(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        expiredPointsPrint.preflightSetup();

        return await apiDocumentCreate({
          input: expiredPointsPrint.toApiClone(),
        });
      });


      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
        await this.printContentStore.value.open(result.printContent);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.value.terminate();
      }
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  openProductDetail = action(async (documentItem: DocumentItemDto, {stockInStores = true} = {}) => {
    if (!isNil(this.state.editOf)) {
      const sellDocument = this.state.sellDocument?.clone();

      documentItem.setQuantityWithinContext(sellDocument, this.state.editOf, 1);

      documentItem = documentItem.getContextGroup(sellDocument, this.state.editOf)?.editableItem;
    }

    let stock = null;

    try {
      if (await amIOnline()) {
        const stockMap = mapStockList(await apiStockGet({
          input: new StockFilterDto({
            internalNumbers: [documentItem.internalNumber],
          }),
        }));

        stock = documentItem.getStockByStockMap(stockMap);
      }
    } catch (e) {
      console.error(e);
    }


    this.state.productDetail = {
      item: documentItem,
      stock,
      stockInStores,
    };

    await this.persist();
  })

  closeProductDetail = action(async () => {
    this.state.productDetail = null;
    await this.persist();
  })

  openStockInStores = action(async () => {
    await this.changeState(RegisterState.STOCK_IN_STORES, {
      resetBuffer: false,
    });
  })

  closeStockInStores = action(async () => {
    await this.changeState(this.state.previousState, {
      resetBuffer: false,
    });
  })

  fetchCustomer = action(async (
    customer: DocumentCustomerDto,
    fetchBy: 'cardNumber' | 'customerNumber',
    {
      unassignedCardProcess = true,
    } = {},
  ) => {
    try {
      this.dispatchEvent(new Event(AppLoaderEvent.ON));
      return new DocumentCustomerDto({
        ...customer.toJson(),
        ...(fetchBy === 'cardNumber' ? {
          ...(await apiCustomersByCard({
            params: {
              cardNumber: customer.cardNumber,
            },
          })).toJson(),
        } : {
          ...(await apiCustomersGet({
            params: {
              customerNumber: customer.customerNumber,
            },
          })).toJson(),
        }),
      });
    } catch (e) {
      console.error(e);
      if (e.response.status === 404 && fetchBy === 'cardNumber' && unassignedCardProcess && await this.amIOnline()) {
        try {
          await apiCustomersCardAvailable({params: {customerCardNumber: customer.cardNumber}});

          await this.changeState(RegisterState.UNASSIGNED_CARD, {
            initArg: {
              cardNumber: customer.cardNumber,
            },
          });
        } catch (e) {
          console.error(e);
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      } else if (e.response.status === 404) {
        this.dispatchEvent(new Event(RegisterStoreErrors.NO_CUSTOMERS_FOUND));
      } else {
        this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
          detail: e,
        }));
      }
      throw e;
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  })

  setCustomerDetail = action((state: ICustomerDetail) => {
    if (!state) {
      this.state.customerDetail = null;
      return;
    }

    this.state.customerDetail = {
      ...(this.state.customerDetail ?? {}),
      ...state,
    };
  })

  openCustomerDetail = action(async (state = createCustomerDetailInitState()) => {
    if (this.isArticleEditInBlockingState.value) {
      this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_UI_OPERATION));
      return;
    }

    if (!await this.ensureProductSave()) {
      return;
    }

    try {
      this.dispatchEvent(new Event(AppLoaderEvent.ON));

      if (!await this.amIOnline()) return;

      if (this.customer.value && !this.customer.value.isFetched) {
        await this.updateCustomer(await this.fetchCustomer(this.customer.value, 'cardNumber'));
      }

      this.setCustomerDetail(state ?? createCustomerDetailInitState());
      await this.persist();
    } catch (e) {
      console.error(e);
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  })

  closeCustomerDetail = action(() => {
    this.setCustomerDetail(null);
    this.persist();
  })

  openDelayedSellData = action(async () => {
    await this.changeState(RegisterState.ENTER_DELAYED_SELL_DATA);
  })

  verifyCustomerPin = action(async (pin) => {
    try {
      this.dispatchEvent(new Event(RegisterStoreEvent.CUSTOMER_VERIFICATION_STARTED));
      await apiCustomersVerifyPin({
        input: new PinDto({pin}),
        params: {
          customerNumber: this.customer.value.customerNumber,
        },
      });
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      this.dispatchEvent(new Event(RegisterStoreEvent.CUSTOMER_VERIFICATION_SUCCEEDED));
      await this.onCustomerVerified();
    } catch (e) {
      broadcastIO.postMessage(BroadcastIOChannels.PIN_VERIFICATION_FAILED, e);
      this.dispatchEvent(new Event(RegisterStoreEvent.CUSTOMER_VERIFICATION_FAILED));
      console.error(e);
    }
  })

  verifyCustomerCard = action(async (cardNumber) => {
    try {
      this.dispatchEvent(new Event(AppLoaderEvent.ON));

      const {documentCustomers} = sanitizeApiSearch(await apiSearch({
        params: {
          code: cardNumber,
        },
      }));

      const customerCards = map(this.customer.value.cards ?? [], ({cardNumber}) => cardNumber.toUpperCase());

      if (
        !documentCustomers.length ||
        !some(documentCustomers, ({cardNumber}) => includes(customerCards, cardNumber.toUpperCase()))
      ) {
        this.dispatchEvent(new Event(RegisterStoreEvent.CUSTOMER_VERIFICATION_FAILED));
      } else {
        await this.onCustomerVerified();
      }
    } catch (e) {
      console.error(e);
      this.dispatchEvent(new Event(RegisterStoreEvent.CUSTOMER_VERIFICATION_FAILED));
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  })

  onCustomerVerified = action(async () => {
    this.customer.value.authenticated = true;
    this.customerAuthentication.value.callback && this.customerAuthentication.value.callback();
    this.finishCustomerAuthentication();
    await this.promotionHookCustomerChanged();
  })

  ensureCustomerAuthentication = action(({
    callback = () => {},
    description = '',
  }: {
    callback: Function,
    description: String
  }) => {
    // if (this.customer.value.authenticated) { // NOTE: Authorization memorization is currently bypassed
    //   return callback();
    // }

    this.state.customerAuthentication = {
      state: CustomerAuthenticationState.VERIFICATION_CHOICE,
      callback,
      description,
    };
  })

  setCustomerAuthenticationState = action((customerAuthenticationState) => {
    this.state.customerAuthentication.state = customerAuthenticationState;
  })

  finishCustomerAuthentication = action(() => {
    this.state.customerAuthentication = null;
  })

  startBurningPoints = action((clubCode) => {
    return withLock(PromoFlowLock, async () => {
      this.ensureCustomerAuthentication({
        description: 'register.pointsBurning.customerAuthentication',
        callback: async () => {
          await this.changeState(RegisterState.BURN_POINTS, {initArg: {clubCode}});
          broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-points-burning'});
        },
      });
    }, () => {
      this.fetchPromoEngineResult();
    });
  })

  finishBurningPoints = action(() => {
    broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
  })

  openGroupEdit = action(async (group: GroupBySets) => {
    await this.withoutPromotionTrigger(async () => {
      if (!this.editIsUsable.value) return;

      if (!await this.ensureProductSave()) return;

      if (this.isReturnModeActive.value && !group.editableItem.canBeReturned) {
        this.dispatchEvent(new CustomEvent(RegisterStoreErrors.NON_RETURNABLE, {
          detail: group.editableItem,
        }));
        return;
      }

      if (group.editableItem.isCheckAndCharge || !group.editableItem.hasEditableField) {
        this.dispatchEvent(new Event(RegisterStoreErrors.EDIT_DISABLED));
        return;
      }

      await this.createSellDocumentBackup();
      this.state.productFlow.isNew = false;

      submitJournalEventDocumentItemChange(group, {
        productFlow: this.state.productFlow,
      });

      if (this.isReturnModeActive.value && !group.editableItem.isReturn) {
        group.editableItem.setIsNegativeWithinContext(
          this.state.sellDocument,
          group.index,
          !group.editableItem.isNegative,
        );

        group.editableItem.setIsReturnWithinContext(
          this.state.sellDocument,
          group.index,
          true,
        );
      }

      this.editGroup(group);
    });
  })

  customDataMandatoryThreshold = getter(() => {
    return this.configuration.value.features?.sell?.additionalData?.mandatoryFromAmount ?? null;
  })

  customDataFields = getter(() => {
    return this.configuration.value.features?.sell?.additionalData?.fields ?? [];
  })

  areCustomDataMandatory = getter(() => {
    if (isNil(this.customDataMandatoryThreshold.value)) {
      return false;
    }

    if (!this.customDataFields.value.length) {
      return false;
    }

    return this.sellDocument.value.itemsNettoTotalValue >= this.customDataMandatoryThreshold.value;
  })

  areCustomDataFilled = getter(() => {
    return this.sellDocument.value.customData?.customerData?.validate({
      required: this.areCustomDataMandatory.value,
    }).valid ?? false;
  })

  customData = getter(() => {
    return this.state.customData;
  })

  delayedSellData = getter(() => {
    return this.state.delayedSellData;
  })

  promoFlow = getter(() => {
    return this.state.promoFlow;
  })

  setPromoFlow = action(async (promoFlow) => {
    if (this.state.promoFlow) {
      await this.state.promoFlow.destroy();
    }
    this.state.promoFlow = promoFlow;
  })

  removePromoFlow = action(async ({
    triggerOtherPromotions = true,
    instanceToRemove = null,
    nextState = null,
  } = {}) => {
    if (!instanceToRemove || toRaw(instanceToRemove) === toRaw(this.state.promoFlow)) {
      this.state.promoFlow = null;
    }
    if (nextState) {
      await this.changeState(nextState);
    }

    await this.persist();

    if (triggerOtherPromotions) {
      await this.triggerImmediatePromotions();
    }
  })

  processPayment = action(async ({bypassInvalidRoundingArticleMessage = false} = {}) => {
    const value = parseFloat(this.state.inputBuffer);

    if (Number.isNaN(value)) {
      this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_PAYMENT_VALUE));
      return;
    }

    const roundingArticle = await this.configurationStore.value.getRoundingArticle();

    // 1. check => valid rounding value
    if (roundingArticle && !this.state.payment.isValidDecomposableValue(value)) {
      this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_NOMINAL));
      return;
    }

    const balanceInPaymentCurrency = this.state.payment.exchangeFromLocalCurrency(this.state.sellDocument.balance);

    // 2. check => overpay conditions
    if (value > balanceInPaymentCurrency) {
      if (!this.state.payment.type.overpay.allow) {
        this.dispatchEvent(new CustomEvent(RegisterStoreErrors.PAYMENT_OVERPAY, {
          detail: {
            parameters: {
              totalRemaining: balanceInPaymentCurrency,
              currency: this.state.payment.currency,
            },
          },
        }));

        this.state.inputBuffer = (
          await this.state.sellDocument.balanceByActiveDocumentPayment(this.state.payment)
        )
          .toString(10);
        return;
      }

      if (
        this.state.payment.type.overpay.minIsSet &&
        fixDecimals(value - balanceInPaymentCurrency) < this.state.payment.type.overpay.min
      ) {
        this.dispatchEvent(new CustomEvent(RegisterStoreErrors.PAYMENT_OVERPAY_MIN, {
          detail: {
            parameters: {
              value: fixDecimals(balanceInPaymentCurrency + this.state.payment.type.overpay.min),
              currency: this.state.payment.currency,
            },
          },
        }));
        return;
      }

      if (
        this.state.payment.type.overpay.minIsSet &&
        fixDecimals(value - balanceInPaymentCurrency) > this.state.payment.type.overpay.max
      ) {
        this.dispatchEvent(new CustomEvent(RegisterStoreErrors.PAYMENT_OVERPAY_MAX, {
          detail: {
            parameters: {
              value: fixDecimals(balanceInPaymentCurrency + this.state.payment.type.overpay.max),
              currency: this.state.payment.currency,
            },
          },
        }));
        return;
      }
    }

    // 3. check => underpay conditions
    if (value < balanceInPaymentCurrency && !this.state.payment.type.underpay.allow) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.PAYMENT_UNDERPAY, {
        detail: {
          parameters: {
            totalRemaining: balanceInPaymentCurrency,
            currency: this.state.payment.currency,
          },
        },
      }));
      return;
    }

    this.state.payment.setValue(value);

    /**
     * Prepare for validation process of payment
     * TODO: should isValidated be manipulated by payment type within getter?
     * it could have a dependency on some workflows and other processes
     */
    if (this.state.payment.type.hasConfirmationMethodTerminal) {
      this.state.payment.isValidated = false;
    }

    if (this.state.sellDocument.balanceIsZero) {
      await this.saveSellDocument();
      return;
    }

    if (this.state.sellDocument.isOverMaxAcceptedCashAmount(this.state.payment)) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.TRANSACTION_LIMIT_EXCEEDED, {
        detail: {
          value: sumBy([...this.state.sellDocument.payments, this.state.payment], 'value'),
          limit: this.configurationStore.value.localCurrency.value.maxAcceptedCashAmount,
        },
      }));
      return;
    }

    if (
      this.state.payment.type.isCashMethod &&
        !roundingArticle &&
        !this.state.payment.isValidDecomposableValue(value) &&
        !bypassInvalidRoundingArticleMessage
    ) {
      this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_ROUNDING_ARTICLE));
      return;
    }

    if (value !== 0) {
      this.state.sellDocument.addPayment(this.state.payment);
    }


    if (this.state.payment.type.rounding) {
      await this.state.sellDocument.addRounding();
    }

    await this.ensureSellDocumentPaymentsFlow();
  })

  ensureSellDocumentPaymentsFlow = action(async () => {
    if (!this.isRegisterStateSelectPayment.value) {
      return;
    }

    try {
      await this.processUnvalidatedPayments();
    } catch (e) {
      return;
    }

    this.resetInputBuffer();

    await this.validateTotalRemaining();

    await this.promotionHookPaymentsChanged();
  })

  validateTotalRemaining = action(async () => {
    if (this.state.sellDocument.balanceIsZero) {
      await this.saveSellDocument();
      return;
    }

    const balanceDecomposition = this.state.sellDocument.balanceDecomposition;
    const smallestLocalCurrencyNominal = Math
      .min(...this.configurationStore.value.localCurrency.value.nominals);

    if (
      this.configuration.value?.general?.cashRoundingStrategy === CashNominalRoundingStrategies.SK &&
        Math.abs(this.state.sellDocument.itemsNettoTotalValue) < 0.05 &&
        !!this.state.sellDocument.validPayments.length
    ) {
      await this.state.sellDocument.addRounding(balanceDecomposition.decomposableRounded, {
        decomposableValueValidation: false,
      });
      await this.validateTotalRemaining();
      return;
    }


    const absoluteDecomposableRounded = Math.abs(balanceDecomposition.decomposableRounded);
    const absoluteDecomposableRoundedDown = Math.abs(balanceDecomposition.decomposableRoundedDown);
    const absoluteBalance = Math.abs(this.state.sellDocument.balance);
    const middleValueIsRoundedDown = this.state.sellDocument.middleValueIsRoundedDown(balanceDecomposition);

    const isBalanceSmallerThanSmallestCurrencyNominal = absoluteBalance < smallestLocalCurrencyNominal;
    const middleValue = middleValueIsRoundedDown ? absoluteDecomposableRoundedDown : absoluteDecomposableRounded;


    if (isBalanceSmallerThanSmallestCurrencyNominal && (middleValue === 0 || middleValueIsRoundedDown)) {
      await this.state.sellDocument.addRounding();
      await this.saveSellDocument();
      return;
    }

    if (this.state.sellDocument.balanceIsNegative && !this.state.sellDocument.isPayroll) {
      await this.state.sellDocument.addReturnPayment();
      await this.state.sellDocument.addRounding();
      await this.validateTotalRemaining();
      return;
    }

    await this.changeState(RegisterState.SELECT_PAYMENT);
  });

  processProductPropertyQuantity = action((quantity: number) => {
    const maxQuantity = this.state.productFlow.product.maxQuantity ?? this.configuration.value.features
      ?.sell
      ?.maxQuantity ?? null;

    if (!isNil(maxQuantity) && quantity > maxQuantity) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.MAX_QUANTITY_EXCEEDED, {
        detail: {
          quantity,
          limit: maxQuantity,
        },
      }));
      return false;
    }

    this.state.productFlow.product.setQuantityWithinContext(
      this.state.sellDocument,
      this.state.editOf,
      quantity * (this.state.productFlow.product.isNegative ? -1 : 1),
    );

    return true;
  })

  processProductPropertyPriceNormal = action(() => {
    const maxPrice = this.state.productFlow.product.maxPrice ?? null;
    const minPrice = this.state.productFlow.product.minPrice ?? null;

    const allowZeroPriceArticle = this.configuration.value?.features?.sell?.allowZeroPriceArticle ?? false;

    const price = parseFloat(this.state.inputBuffer) || 0;

    if (!isNil(maxPrice) && price > maxPrice) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.MAX_PRICE_EXCEEDED, {
        detail: {
          price,
          limit: maxPrice,
        },
      }));
      return false;
    }

    if (!isNil(minPrice) && price < minPrice) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.MIN_PRICE_EXCEEDED, {
        detail: {
          price,
          limit: minPrice,
        },
      }));
      return false;
    }

    if (price === 0 && !allowZeroPriceArticle) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.ZERO_PRICE));
      return false;
    }

    this.state.productFlow.product.setPriceNormalWithinContext(
      this.state.sellDocument,
      this.state.editOf,
      price,
    );

    return true;
  })

  resetInputBuffer = action(() => {
    this.state.displayError = null;
    this.state.inputBuffer = '';
  })

  resetReceipt = action(async (
    {
      stateData = {},
      onInitFinish,
    }: {
      stateData?: Partial<IRegisterStore>
      onInitFinish?: ()=> void,
    } = {},
  ) => {
    this.state = Object.assign(this.state, createInitState({
      lastSellDocument: this.state.lastSellDocument,
      ...stateData,
    }));

    this.ensureSellDocument(onInitFinish);

    await this.persist();
  })

  setLastSellDocument = action((doc: DocumentDto) => {
    this.state.lastSellDocument = doc;
  })

  restoreArchivedState = action((sellDocumentId) => {
    const restoredState = find(registerStoreStatesPersist.get() || [], ({sellDocument}) => {
      return sellDocument.header.uniqueidentifier === sellDocumentId;
    });

    if (restoredState) {
      this.state = Object.assign(this.state, restoredState);
    }
  })

  restoreSellDocumentBackup = action(async () => {
    if (this.state.sellDocumentBackup) {
      const customer = this.state.sellDocument.hasCustomer ? this.state.sellDocument.customer.clone() : null;
      this.state.sellDocument = this.state.sellDocumentBackup.clone();
      if (customer && !this.state.sellDocument.hasCustomer) {
        this.state.sellDocument.customer = customer;
      }
      this.state.sellDocumentBackup = null;
      await this.persist();
    }
  })

  resetToDefaultStep = action(async ({canBeReset = this.productFlow.value.canBeSaved} = {}) => {
    if (!canBeReset) {
      return;
    }
    this.state.editOf = null;
    this.state.displayError = null;
    this.state.insertMode = false;
    this.state.productFlow.product = null;
    this.state.loadedProducts = markRaw([]);
    await this.removeSellDocumentBackup();
    await this.changeState(RegisterState.ENTER_ARTICLE_NUMBER);
    this.dispatchEvent(new Event(RegisterStoreEvent.INIT_CUSTOMER_DISPLAY));

    this.clearQuickCallActiveMap();

    await this.persist();
  });

  documentSubmitCall = action(async (
    documentId,
    trigger: ()=> Promise<any>,
    {timeout = 15 * 60 * 1000} = {},
  ): Promise<ResultDto['_data']> => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON, {detail: 0.2}));

      const {notificationsConnection} = useSignalR();
      const [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, eventDocumentId,
          ] = args;

          if (eventDocumentId !== documentId) return false;

          const solvingResult = (await this.solvePrinterStatus(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
        {timeout},
      )(trigger);

      return result;
    } catch (e) {
      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.value.terminate();
      }

      throw e;
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  createDocumentSubmitProcess = action(async (
    documentId: string,
    trigger: (mode: DocumentCreateMode)=> Promise<any>,
    {mandatoryPrint = true} = {},
  ): Promise<DocumentSubmitProcessResult> => {
    let result = null;

    if (mandatoryPrint) {
      try {
        result = new PrinterResult(await this.documentSubmitCall(documentId, () => {
          return trigger(DocumentCreateMode.createAndPrint);
        }));

        if (!getResponseCodeConfiguration(result).finished) {
          return {
            result,
            created: false,
            printed: false,
            error: null,
          };
        }
      } catch (error) {
        return {
          result,
          created: false,
          printed: false,
          error,
        };
      }
    } else {
      try {
        result = new PrinterResult(await this.documentSubmitCall(documentId, () => {
          return trigger(DocumentCreateMode.create);
        }));

        if (!getResponseCodeConfiguration(result).finished) {
          return {
            result,
            created: false,
            printed: false,
            error: null,
          };
        }
      } catch (error) {
        return {
          result,
          created: false,
          printed: false,
          error,
        };
      }

      try {
        result = new PrinterResult(await this.documentSubmitCall(documentId, () => {
          return trigger(DocumentCreateMode.print);
        }));
      } catch (error) {
        return {
          result,
          created: true,
          printed: false,
          error,
        };
      }
    }

    return {
      result,
      created: true,
      printed: getResponseCodeConfiguration(result).finished,
      error: null,
    };
  })

  saveSellDocument = action(async () => {
    if (this.areCustomDataMandatory.value && !this.areCustomDataFilled.value) {
      await this.changeState(RegisterState.ENTER_CUSTOM_DATA_QUESTION);
      return;
    }

    if (this.state.sellDocument.needPaymentPrintoutConfirmation) {
      this.dispatchEvent(new Event(RegisterStoreEvent.REQUEST_PAYMENT_TERMINAL_PRINTOUT));
      return;
    }

    try {
      await this.state.saveFlow.process();
    } catch (e) {
      console.error(e);
    }
  })

  hasSellDocumentAlreadyBeenSaved = action(async () => {
    return await this.withAppLoader(async () => {
      return await this.state.sellDocument.hasAlreadyBeenSaved();
    });
  })

  search = action(async (
    str,
    primary: 'product' | 'customer' = 'product',
    {
      paginator,
      resetToDefaultStep = true,
    }: {
      paginator?,
      resetToDefaultStep?
    } = {},
  ) => {
    try {
      const {
        documentCustomers,
        documentItems,
        documentPayments,
        receipts,
        vouchers,
      } = await this.searchSubject(str, paginator);

      if (this.isAllowedArticleInput.value && documentItems.length) {
        await this.searchProductsResultEvaluate(documentItems, {resetToDefaultStep});
      } else if (this.isAllowedCustomerInput.value && documentCustomers.length) {
        await this.searchCustomersResultEvaluate(documentCustomers, {resetToDefaultStep});
      } else if (this.isAllowedPaymentInput.value && documentPayments.length) {
        await this.searchPaymentsResultEvaluate(documentPayments, {resetToDefaultStep});
      } else if (this.isAllowedReceiptInput.value && receipts.length) {
        await this.searchReceiptsResultEvaluate(receipts, {resetToDefaultStep});
      } else if (this.isAllowedVoucherInput.value && vouchers.length) {
        await this.searchVouchersResultEvaluate(vouchers, {resetToDefaultStep});
      } else if (primary === 'product' || this.state.returnMode) {
        this.dispatchEvent(new Event(RegisterStoreErrors.NO_PRODUCTS_FOUND));
      } else if (primary === 'customer') {
        this.dispatchEvent(new Event(RegisterStoreErrors.NO_CUSTOMERS_FOUND));
      }
    } catch (e) {
      console.error(e);
    } finally {
      emitTestEvent(`${TestEvent.SEARCH}:${str}`);
    }
  })

  searchByPromotionList = action(async (promotionListCode: string) => {
    const results = await apiSearchPromotions({params: {promotionCode: promotionListCode}});
    return results.map((result) => {
      const documentItem = result.documentItem;
      documentItem.systemAttributes ??= {};
      documentItem.systemAttributes.promotionList = promotionListCode;
      return documentItem;
    });
  });

  searchSubject = action(async (code: string, {offset = 0, limit = 50} = {}) => {
    return await this.withAppLoader(async () => {
      try {
        this.state.lastSearchedSubject = code;
        const {
          result,
          documentItems,
          documentCustomers,
          documentPayments,
          receipts,
          vouchers,
          invoices,
        } = sanitizeApiSearch(await apiSearch({
          params: {
            code,
            limit,
            offset,
          },
        }));

        return {
          result,
          documentItems,
          documentCustomers,
          documentPayments,
          receipts,
          vouchers,
          invoices,
        };
      } finally {
        emitTestEvent(`${TestEvent.SEARCH_SUBJECT}:${code}`);
      }
    });
  });

  searchCustomersResultEvaluate = action(async (documentCustomers: DocumentCustomerDto[], {resetToDefaultStep}) => {
    if (resetToDefaultStep) {
      await this.resetToDefaultStep();
    }

    await this.addCustomer(first(documentCustomers));
  })

  searchProductsResultEvaluate = action(async (documentItems: DocumentItemDto[], {resetToDefaultStep}) => {
    if (
      !this.isRegisterStateEnterArticleNumber.value &&
      !this.isRegisterStateEnterCustomerCard.value
    ) {
      await this.onEventInput({type: RegisterActions.ENTER});
    }

    if (!this.isRegisterStateEnterArticleNumber.value && !this.isRegisterStateEnterCustomerCard.value) return;

    if (resetToDefaultStep) {
      await this.resetToDefaultStep();
    }

    const firstDocumentItem = first(documentItems);

    // check and charge
    if (
      firstDocumentItem.isCheckAndCharge &&
      firstDocumentItem.isServiceTypeLot
    ) {
      // article has been scanned over gtin/internal number === it has no logistic code and it is sale
      if (!firstDocumentItem.logisticCode) {
        if (this.isReturnModeActive.value) {
          await this.startProductFlow(markRaw(new DocumentItemDto({
            ...firstDocumentItem.clone().toJson(),
            salePackage: false,
          })));
        } else if (firstDocumentItem.salePackage) { // sale article has package option
          this.state.loadedProducts = markRaw([
            new DocumentItemDto({
              ...firstDocumentItem.clone().toJson(),
              salePackage: false,
            }),
            new DocumentItemDto({
              ...firstDocumentItem.clone().toJson(),
            }),
          ]);
          this.changeState(RegisterState.SELECT_CHECK_AND_CHARGE_LOT_VARIATION);
        } else {
          await this.startProductFlow(markRaw(firstDocumentItem));
        }
      } else {
        const sellArticle = find(documentItems, {isNegative: false});
        const payoffArticle = find(documentItems, {isNegative: true});

        this.state.loadedProducts = markRaw([
          ...(sellArticle ? [
            new DocumentItemDto({
              ...sellArticle.clone().toJson(),
              salePackage: false,
            }),
            ...(sellArticle.salePackage && !this.isReturnModeActive.value ? [
              new DocumentItemDto({
                ...sellArticle.clone().toJson(),
              }),
            ] : []),
          ] : []),
          ...(payoffArticle ? [
            new DocumentItemDto({
              ...payoffArticle.clone().toJson(),
            }),
          ] : []),
        ]);
        this.changeState(RegisterState.SELECT_CHECK_AND_CHARGE_LOT_VARIATION);
      }
      return;
    }

    // regular workflow
    if (documentItems.length > 1) {
      this.state.loadedProducts = markRaw(documentItems);
      this.changeState(RegisterState.SELECT_PRODUCT_VARIATION);
    } else {
      await this.startProductFlow(markRaw(firstDocumentItem));
    }
  })

  searchPaymentsResultEvaluate = action(async (documentPayments: DocumentPaymentDto[], {resetToDefaultStep}) => {
    if (resetToDefaultStep) {
      await this.resetToDefaultStep();
    }

    const firstPayment = first(documentPayments);

    this.state.sellDocument.addPayment(firstPayment);

    if (firstPayment.type.rounding) {
      await this.state.sellDocument.addRounding();
    }
  })

  searchReceiptsResultEvaluate = action(async (receipts, {resetToDefaultStep}) => {
    if (resetToDefaultStep) {
      await this.resetToDefaultStep();
    }

    this.dispatchEvent(new CustomEvent(RegisterStoreEvent.REDIRECT_TO_RECEIPT, {
      detail: first(receipts),
    }));
  })

  searchVouchersResultEvaluate = action(async (vouchers, {resetToDefaultStep}) => {
    const documentItems = map(vouchers, (voucher: VoucherResultDto) => {
      return voucher.documentItem;
    });

    await this.searchProductsResultEvaluate(documentItems, {resetToDefaultStep});
  })

  startProductFlow = action(async (product: DocumentItemDto) => {
    if (product.isCheckAndCharge && this.trainingStore.value.trainingIsActive.value) {
      this.dispatchEvent(new Event(RegisterStoreErrors.ARTICLE_IN_TRAINING_MODE));
      return;
    }

    if (product.isCheckAndCharge && !await amIOnline()) {
      this.dispatchEvent(new Event(RegisterStoreErrors.OFFLINE));
      return;
    }

    if (!product.canBeReturned && this.isReturnModeActive.value) {
      this.dispatchEvent(new CustomEvent(RegisterStoreErrors.NON_RETURNABLE, {
        detail: product,
      }));
      return;
    }

    if (product.isCheckAndCharge && product.needExternalConfiguration) {
      this.dispatchEvent(new CustomEvent(RegisterStoreEvent.START_EXTERNAL_CONFIGURATION, {
        detail: product,
      }));
      return;
    }

    await this.createSellDocumentBackup();

    // TODO: put this code somewhere before product is saved and DocumentItemChanged event is called
    if (!this.state.sellDocument.items.length) {
      this.state.sellDocument.setBasicInfo();
      await submitJournalEventSellDocumentCreate(this.state.sellDocument);
    }

    product = product.expandExpandableSet();

    product.isReturn = this.isReturnModeActive.value ? true : (product.isReturn ?? false);
    product.isNegative = this.isReturnModeActive.value ? !product.isNegative : product.isNegative;
    product.setItems = map(product.setItems ?? [], (item) => new DocumentItemDto({
      ...item.toJson(),
      isNegative: product.isNegative,
      isReturn: product.isReturn,
    }));

    if (product.isCheckAndCharge && this.isReturnModeActive.value) {
      product.itemReturnDisabled = true;
    }

    this.state.productFlow.product = product.clone();

    const lastItemCanBeMerged = this.state.sellDocument.isLastItemEditable(product);

    try {
      await this.commitProduct();
    } catch (e) {
      await this.resetToDefaultStep({canBeReset: true});
      return;
    }

    const groupedItems = this.state.sellDocument.itemsGroupedBySets;
    const lastGroup = last(this.state.sellDocument.nonPromotionsItems);

    /**
     * If we work with editable set, preset quantity must be manipulated here,
     * because later we will lose info about original quantity
     *
     * when we commit new item, it (main item) has automatically +/- 1
     */
    if (lastItemCanBeMerged) {
      this.state.productFlow.isNew = false;

      this.state.productFlow.product = lastGroup?.editableItem?.clone();
      this.state.editOf = groupedItems.length - 1;

      await submitJournalEventDocumentItemChange(this.currentEditGroup.value, {
        productFlow: this.state.productFlow,
      });

      if (lastGroup.editableItemIsEditableSet) {
        const originalQuantity = find(
          product.setItems,
          {internalNumber: this.state.productFlow.product.internalNumber},
        )?.quantity;

        if (lastGroup.mainItem.isNegative) {
          this.state.productFlow.product.quantity -= originalQuantity;
          lastGroup.mainItem.quantity -= this.state.productFlow.presetQuantityIsSet ?
            (this.state.productFlow.presetQuantity - 1) : 0;
        } else {
          this.state.productFlow.product.quantity += originalQuantity;
          lastGroup.mainItem.quantity += this.state.productFlow.presetQuantityIsSet ?
            (this.state.productFlow.presetQuantity - 1) : 0;
        }

        if (this.state.productFlow.presetQuantityIsSet) {
          this.state.productFlow.presetQuantity *= originalQuantity;
          this.state.productFlow.presetQuantity -= originalQuantity;
        }
      } else {
        if (this.state.productFlow.presetQuantityIsSet && lastGroup.editableItem.wasExpanded) {
          this.state.productFlow.presetQuantity *= product.quantity;
          this.state.productFlow.presetQuantity -= product.quantity;
        } else if (lastGroup.mainItem.isNegative) {
          this.state.productFlow.product.quantity -= 1;
        } else {
          this.state.productFlow.product.quantity += 1;
        }
      }
    } else if (lastGroup.editableItemIsEditableSet && this.state.productFlow.presetQuantityIsSet) {
      if (lastGroup.mainItem.isNegative) {
        lastGroup.mainItem.quantity = -this.state.productFlow.presetQuantity;
      } else {
        lastGroup.mainItem.quantity = this.state.productFlow.presetQuantity;
      }
    }

    lastGroup.editableItem.sanitizeCustomOriginalDocumentFiscalReferenceWithinContext(
      this.state.sellDocument,
      lastGroup.index,
    );

    await this.editGroup(lastGroup);
  })

  clearQuickCallActiveMap = action(() => {
    this.state.quickCallActiveMap = new Map(Array.from(this.quickCallActiveMap.value.entries())); // reactivity trigger

    this.quickCallActiveMap.value.forEach((item, key) => {
      if (!item.isCustomButton) {
        this.quickCallActiveMap.value.delete(key);
      }
    });
  });

  /**
   * Return:
   * - true if article was successfully saved, removed or there was none in display
   * - false we are stacked in article fill process
   */
  ensureProductSave = action(async () => {
    if (this.isEditModeActive.value && this.state.productFlow.product.isFilled) {
      const currentState = this.state.state;
      await this.onEventInput({type: RegisterActions.ENTER});

      if (this.state.state === currentState) {
        // NOTE: we tried to transition to next step, and it does not happen
        // eslint-disable-next-line max-len
        console.warn(`State ${currentState} unchanged, article: ${this.productFlow.value?.product?.gtin ?? 'n/a'} (${this.productFlow.value?.product?.description ?? 'n/a'})`);
        return false;
      }

      if (this.isEditModeActive.value) {
        // We do not use try catch here, because method internally does not throw and solves its errors by itself
        await this.saveProduct();
      }

      return true;
    }

    return true;
  })

  ensureClosedReturnMode = action(async () => {
    if (this.isReturnModeActive.value && !this.isRegisterStateCancelMode.value) {
      await this.toggleReturnMode();
    }
  })

  ensureClosedCancelMode = action(async () => {
    if (this.isRegisterStateCancelMode.value && !this.isReturnModeActive.value) {
      await this.toggleCancelMode();
    }
  })

  toggleCancelMode = action(async () => {
    if (!this.cancelModeIsUsable.value) return;

    if (!await this.ensureProductSave()) return;

    await this.ensureClosedReturnMode();

    try {
      if (this.state.state === RegisterState.CANCEL_MODE) {
        await this.changeState(this.state.previousState, {resetBuffer: false});
        await this.triggerImmediatePromotions();
      } else {
        await this.changeState(RegisterState.CANCEL_MODE, {resetBuffer: false});
      }
      await this.persist();
    } catch (e) {
      console.error(e);
    }
  })

  toggleReturnMode = action(async () => {
    if (!this.returnModeIsUsable.value) return;

    if (!this.state.returnMode && !await this.fourEyes(FourEyesOperations.PRODUCT_RETURN)) return;

    await this.ensureClosedCancelMode();

    if (!await this.ensureProductSave()) return;

    this.state.returnMode = !this.state.returnMode;
  })

  toggleSumMode = action(async () => {
    if (!this.sumIsUsable.value) return;

    // immediate promotions trigger on the select payment transition anyway,
    // we can skip them here and let SELECT_PAYMENT handle it
    // we would have 2 parallel changeStates otherwise
    if (await this.withoutPromotionTrigger(async () => {
      if (!await this.ensureProductSave()) return true;

      await this.ensureClosedReturnMode();

      await this.ensureClosedCancelMode();
    })) {
      return;
    }

    await this.changeState(RegisterState.SELECT_PAYMENT);
  })

  transitionToNextStep = action(async ({currentState = this.state.state} = {}) => {
    this.state.insertMode = false;

    if (this.state.productFlow.product.isCheckAndCharge) {
      await resolveStepsForArticleTypeCheckAndCharge.call(this, currentState);
    } else {
      await resolveStepsForArticleTypeRegular.call(this, currentState);
    }
  })

  setQuickCallPanelPriority = action(async (priority: QuickCallPanelPriority) => {
    this.state.quickCallPanelPriority = priority;
    await this.persist();
  });

  toggleQuickCall = action(async () => {
    if (this.state.quickCallPanelPriority !== QuickCallPanelPriority.QuickCall) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.QuickCall);
      return;
    }

    if (this.hasAvailableContextualProductRecommendations.value) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.ContextualProductRecommendations);
      return;
    }

    if (this.hasAvailableGuidedSellingOptions.value) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.GuidedSelling);
      return;
    }
  })

  toggleCustomerFeaturedAttributes = action(async () => {
    if (this.state.quickCallPanelPriority !== QuickCallPanelPriority.CustomerFeaturedAttributes) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.CustomerFeaturedAttributes);
      return;
    }

    await this.closeCustomerFeaturedAttributes();
  })

  closeCustomerFeaturedAttributes = action(async () => {
    if (this.hasAvailableContextualProductRecommendations.value) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.ContextualProductRecommendations);
      return;
    }

    if (this.hasAvailableGuidedSellingOptions.value) {
      await this.setQuickCallPanelPriority(QuickCallPanelPriority.GuidedSelling);
      return;
    }

    await this.toggleQuickCall();
  })

  changePayment = action(async (payment: DocumentPaymentDto, {
    value = this.state.sellDocument.balanceByActiveDocumentPayment(payment).then((val) => val.toString(10)),
    insertMode = true,
  }: {
    value?: string | Promise<string>
    insertMode?: boolean
  } = {}) => {
    if (this.state.state !== RegisterState.SELECT_PAYMENT) {
      await this.changeState(RegisterState.SELECT_PAYMENT);
    }
    payment.setValue(parseFloat(await value ?? ''));
    this.setPayment(payment);
    this.state.returnMode = false;
    this.state.insertMode = insertMode;
    this.state.inputBuffer = await value ?? '';

    submitJournalEventSelectPaymentEnter(this.state.inputBuffer, payment);

    emitTestEvent(TestEvent.PAYMENT_CHANGED);

    await this.persist();
  })

  processUnvalidatedPayments = action(async () => {
    try {
      this.state.paymentsVerificationInProgress = true;
      this.dispatchEvent(new Event(AppLoaderEvent.ON));

      for (const unvalidatedPayment of this.state.sellDocument?.validUnvalidatedPayments ?? []) {
        const payment = (
          unvalidatedPayment.verifyDocumentId ?
            Payment.restore(unvalidatedPayment.verifyDocumentId) :
            Payment.create({
              value: unvalidatedPayment.valueByCurrency,
              paymentId: unvalidatedPayment.paymentID,
              referentialUniqueId: this.state.sellDocument.header.uniqueidentifier,
              payTerminalVirtualId: unvalidatedPayment.payTerminalVirtualId,
            })
        )
          .on(PaymentEvents.resultsReceived, async (results) => {
            this.state.sellDocument.processPaymentResults(results);
            await this.persist();
          })
          .on(PaymentEvents.triggerDocumentCreated, async (document) => {
            unvalidatedPayment.verifyDocumentId = document.header.uniqueidentifier;
            await this.persist();
          });

        const result = await payment.process();

        unvalidatedPayment.isValidated = true;

        if (!result.isSuccessful && !result.handled) {
          this.dispatchEvent(new Event(RegisterStoreErrors.PAYMENT_ERROR));
        }

        if (!result.isSuccessful) {
          unvalidatedPayment.isCanceled = true;
          throw new Error('Payment process failed');
        }

        unvalidatedPayment.isValidated = true;
        unvalidatedPayment.cardNumber = result.card?.cardNumber;
        unvalidatedPayment.cardType = result.card?.cardType;
      }
    } catch (e) {
      console.error(e);
      recordCustomEventLogEntry('RegisterStore processUnvalidatedPayments', e.message);
      throw e;
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      this.state.paymentsVerificationInProgress = false;
      await this.persist();
    }
  });

  cancelLastCardPayment = action(async () => {
    try {
      this.dispatchEvent(new Event(AppLoaderEvent.ON));

      const validPaymentsWithCard = this.state.sellDocument.validPaymentsWithCard;

      if (!validPaymentsWithCard.length) return;

      const lastCardPayment = last(validPaymentsWithCard);

      if (!lastCardPayment.type.hasConfirmationMethodTerminal) {
        lastCardPayment.isCanceled = true;
        return;
      }

      const lastCardPaymentResult = this.state.sellDocument.findSuccessPaymentResultByPayment(lastCardPayment);

      const payment = Payment.create({
        value: lastCardPayment.valueByCurrency,
        paymentId: lastCardPayment.paymentID,
        referentialUniqueId: this.state.sellDocument.header.uniqueidentifier,
        payTerminalVirtualId: lastCardPayment.payTerminalVirtualId,
        referenceNumber: lastCardPaymentResult.referenceNumber,
        mode: PaymentModes.Cancel,
      });


      const result = await payment.process();

      if (!result.isSuccessful && !result.handled) {
        this.dispatchEvent(new Event(RegisterStoreErrors.PAYMENT_ERROR));
      }

      if (!result.isSuccessful) {
        throw new Error('Payment process failed');
      }

      lastCardPayment.isCanceled = true;
    } catch (e) {
      console.error(e);
      recordCustomEventLogEntry('RegisterStore cancelLastCardPayment', e.message);
      throw e;
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
      await this.persist();
    }
  })

  refundCardPayments = action(async (
    validPayments = this.state.sellDocument.validPaymentsWithCard,
  ) => {
    for (const validPayment of validPayments) {
      try {
        this.dispatchEvent(new Event(AppLoaderEvent.ON));

        if (!validPayment.type.hasConfirmationMethodTerminal) {
          validPayment.isRefunded = true;
          continue;
        }

        const lastCardPaymentResult = this.state.sellDocument.findSuccessPaymentResultByPayment(validPayment);

        const payment = Payment.create({
          value: validPayment.valueByCurrency,
          paymentId: validPayment.paymentID,
          referentialUniqueId: this.state.sellDocument.header.uniqueidentifier,
          payTerminalVirtualId: validPayment.payTerminalVirtualId,
          referenceNumber: lastCardPaymentResult.referenceNumber,
          mode: PaymentModes.Refund,
        });

        const result = await payment.process();

        if (!result.isSuccessful && !result.handled) {
          this.dispatchEvent(new Event(RegisterStoreErrors.PAYMENT_ERROR));
        }

        if (!result.isSuccessful) {
          throw new Error('Payment process failed');
        }

        validPayment.isRefunded = true;
      } catch (e) {
        console.error(e);
        recordCustomEventLogEntry('RegisterStore refundCardPayments', e.message);
        throw e;
      } finally {
        this.dispatchEvent(new Event(AppLoaderEvent.OFF));
        await this.persist();
      }
    }
  })

  setUncancellableGroupsSeen = action((seen: boolean) => {
    this.state.unreturnableGroupsSeen = seen;
  })

  // getters

  activePerson = getter(() => {
    return this.authStore.value.activePerson.value;
  })

  authStore = getter(() => {
    return useAuthStore();
  })

  editOf = getter(() => {
    return this.state.editOf;
  })

  cashAmountValidatorStore = getter(() => {
    return useCashAmountValidatorStore();
  })

  cancelIsUsable = getter(() => {
    if (this.state.state !== RegisterState.SELECT_PAYMENT) {
      return true;
    }

    if (this.hasPendingUnverifiedPayment.value) {
      return false;
    }

    if (this.state.sellDocument?.isModeStorno) {
      return this.cancelModeIsUsable.value;
    }

    return true;
  })

  canCancelSingleItem = getter(() => {
    if (this.state.previousState === 'selectPayment') {
      return false;
    }

    if (this.sellDocument.value?.isModeStorno) {
      return false;
    }

    return this.isRegisterStateCancelMode.value;
  })

  canCancelSinglePayment = getter(() => {
    return this.isRegisterStateCancelMode.value;
  })

  canCancelEntireDocument = getter(() => {
    if (!this.sellDocument.value?.isModeStorno) {
      return this.isRegisterStateCancelMode.value;
    }


    return this.isRegisterStateCancelMode.value &&
      this.configurationStore.value.configuration.value?.features?.storno?.allowEntireDocumentStorno;
  })

  cancelModeIsUsable = getter(() => {
    if (this.isRegisterStateCancelMode.value) {
      return true;
    }

    if (this.hasPendingUnverifiedPayment.value) {
      return false;
    }

    if (this.state.sellDocument?.canBeCanceledWithoutConfirmation) {
      return false;
    }

    if (this.isRegisterStateBurnPoints.value) {
      return false;
    }

    if (this.sellDocument.value?.isModeStorno) {
      return (
        (
          this.configuration.value?.features?.storno?.allowPartialDocumentStorno ||
          this.configuration.value?.features?.storno?.allowEntireDocumentStorno
        ) && !this.sellDocument.value.validCheckAndChargeGroups.length
      );
    }

    if (this.isEditModeActive.value) {
      return this.state.productFlow.product.isFilled;
    }

    if (this.state.returnMode) {
      return this.state.productFlow.product?.isFilled ?? true;
    }

    return true;
  })

  configuration = getter(() => {
    return this.configurationStore.value?.configuration?.value;
  })

  configurationStore = getter(() => {
    return useConfigurationStore();
  })

  clearIsUsable = getter(() => {
    return !!this.state.inputBuffer || this.state.productFlow.presetQuantityIsSet;
  })

  backspaceIsUsable = getter(() => {
    return !!this.state.inputBuffer || this.state.productFlow.presetQuantityIsSet;
  })

  cashDrawerIsUsable = getter(() => {
    return true;
  })

  customer = getter(() => {
    if (!this.state.sellDocument?.hasCustomer) {
      return null;
    }

    return this.state.sellDocument?.customer;
  })

  displayState = getter((): DisplayVariants => {
    switch (this.state.state) {
    case RegisterState.ENTER_CUSTOMER_CARD:
    case RegisterState.FILL_IN_CUSTOMER_CARD:
      return {
        type: DisplayState.ENTER_CUSTOMER_CARD,
        inputValue: this.state.inputBuffer,
        returnMode: this.state.returnMode,
      };
    case RegisterState.ENTER_ARTICLE_NUMBER:
      return {
        type: DisplayState.ENTER_PRODUCT,
        inputValue: this.state.inputBuffer,
        returnMode: this.state.returnMode,
        error: this.state.displayError,
        quantityPreset: this.state.productFlow.presetQuantityAsString ?? null,
        hasCustomer: !!this.customer.value,
      };
    case RegisterState.ENTER_ARTICLE_QUANTITY:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_PRODUCT_QUANTITY,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ENTER_ARTICLE_PRICE:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_PRODUCT_PRICE,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ENTER_SERIAL_NUMBER:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_PRODUCT_SERIAL_NUMBER,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ENTER_INVOICE_NUMBER:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_PRODUCT_INVOICE_NUMBER,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ENTER_PHONE_NUMBER:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_PRODUCT_PHONE_NUMBER,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        error: this.state.displayError,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.PHONE_NUMBER_CONFIRM:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.PRODUCT_PHONE_NUMBER_CONFIRM,
        product: this.state.productFlow.product,
      };
    case RegisterState.SELECT_PAYMENT:
    case RegisterState.ENTER_CUSTOM_DATA:
    case RegisterState.ENTER_CUSTOM_DATA_QUESTION:
      return {
        type: DisplayState.PAYMENT,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.CANCEL_MODE:
      return {
        type: DisplayState.CANCEL,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ORIGINAL_DOCUMENT_REFERENCE:
      return {
        type: DisplayState.ENTER_STORNO_REFERENCE,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE:
      return {
        type: DisplayState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.RETURN_REASON_CODE:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_STORNO_REASON,
        product: this.state.productFlow.product,
      };
    case RegisterState.VALIDATE_AGE_RESTRICTION:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.VALIDATE_AGE_RESTRICTION,
        product: this.state.productFlow.product,
      };
    case RegisterState.ENTER_LOGISTIC_CODE:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_LOGISTIC_CODE,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.ENTER_VALIDATION_CODE:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.ENTER_VALIDATION_CODE,
        product: this.state.productFlow.product,
        insertMode: this.state.insertMode,
        inputValue: this.state.inputBuffer,
      };
    case RegisterState.READONLY_ARTICLE_SELECTED:
      return !this.state.productFlow.product ? {type: DisplayState.VOID} : {
        type: DisplayState.DISPLAY_READ_ONLY_PRODUCT,
        product: this.state.productFlow.product,
      };
    default:
      return {
        type: DisplayState.VOID,
      };
    }
  })

  documentStatusStore = getter(() => {
    return useDocumentStatusStore();
  })

  currentEditGroup = getter(() => {
    if (isNil(this.state.editOf)) {
      return null;
    }

    return this.state.sellDocument?.itemsGroupedBySets[this.state.editOf] ?? null;
  })

  editIsUsable = getter(() => {
    if (this.isRegisterStateEnterArticleNumber.value) {
      return true;
    }

    if (this.isRegisterStateEnterArticleQuantity.value) {
      return this.state.productFlow.product.isFilled;
    }

    if (this.isRegisterStateReadonlyArticleSelected.value) {
      return true;
    }

    return false;
  })

  isArticleEditInBlockingState = getter(() => {
    if (!this.isEditModeActive.value) {
      return false;
    }

    if (this.state.productFlow.product) {
      return !this.state.productFlow.product.isFilled;
    }

    return false;
  })

  inputBuffer = getter(() => this.state.inputBuffer)

  isUserInteractionActive = getter(() => {
    return !(this.isRegisterStateEnterArticleNumber.value || this.isRegisterStateEnterCustomerCard.value);
  })

  isReturnModeActive = getter(
    () => this.state.returnMode,
  )

  isEditModeActive = getter(
    () => !isNil(this.state.editOf),
  )

  canGuidedSellingBeDisplayed = getter(() => {
    if (this.state.returnMode) {
      return false;
    }

    return includes([
      RegisterState.ENTER_ARTICLE_NUMBER,
      RegisterState.ENTER_ARTICLE_PRICE,
      RegisterState.ENTER_ARTICLE_QUANTITY,
      RegisterState.ENTER_SERIAL_NUMBER,
      RegisterState.ENTER_INVOICE_NUMBER,
      RegisterState.READONLY_ARTICLE_SELECTED,
    ], this.state.state);
  })

  isRegisterStateCancelMode = getter(() => this.state.state === RegisterState.CANCEL_MODE)

  isRegisterStateConnectCardToCustomer = getter(() => this.state.state === RegisterState.CONNECT_CARD_TO_CUSTOMER)

  isRegisterStateSelectProductVariation = getter(() => this.state.state === RegisterState.SELECT_PRODUCT_VARIATION)

  isRegisterStateEnterArticleNumber = getter(() => this.state.state === RegisterState.ENTER_ARTICLE_NUMBER)

  isRegisterStateEnterArticleQuantity = getter(() => this.state.state === RegisterState.ENTER_ARTICLE_QUANTITY)

  isRegisterStateEnterCustomerCard = getter(() => this.state.state === RegisterState.ENTER_CUSTOMER_CARD)

  isRegisterStateEnterPhoneNumber = getter(() => this.state.state === RegisterState.ENTER_PHONE_NUMBER)

  isRegisterStateEnterStornoReason = getter(() => this.state.state === RegisterState.RETURN_REASON_CODE)

  isRegisterStateFillInCustomerCard = getter(() => this.state.state === RegisterState.FILL_IN_CUSTOMER_CARD)

  isRegisterStateFillInCustomerCardSearch = getter(
    () => this.state.state === RegisterState.FILL_IN_CUSTOMER_CARD_SEARCH,
  )

  isRegisterStatePaymentsExit = getter(() => this.state.state === RegisterState.PAYMENTS_EXIT)

  isRegisterStateSearchCustomers = getter(() => this.state.state === RegisterState.CUSTOMER_SEARCH)

  isRegisterStateSearchProducts = getter(() => this.state.state === RegisterState.PRODUCT_SEARCH)

  isRegisterStateSelectPayment = getter(() => this.state.state === RegisterState.SELECT_PAYMENT)

  isRegisterStateBurnPoints = getter(() => this.state.state === RegisterState.BURN_POINTS)

  isRegisterStateEnterCustomData = getter(() => this.state.state === RegisterState.ENTER_CUSTOM_DATA)

  isRegisterStateEnterCustomDataQuestion = getter(() => this.state.state === RegisterState.ENTER_CUSTOM_DATA_QUESTION)

  isRegisterStateEnterDelayedSellData = getter(() => this.state.state === RegisterState.ENTER_DELAYED_SELL_DATA)

  isRegisterStateSelectCheckAndChargeLotVariation = getter(
    () => this.state.state === RegisterState.SELECT_CHECK_AND_CHARGE_LOT_VARIATION,
  )

  isRegisterStateUnassignedCard = getter(() => this.state.state === RegisterState.UNASSIGNED_CARD)

  isRegisterStateSelectGift = getter(() => this.state.state === RegisterState.SELECT_GIFT)

  isRegisterStateDynamicSetSelection = getter(() => this.state.state === RegisterState.DYNAMIC_SET_SELECTION)

  isRegisterStateDynamicSetLevelsSelection = getter(() => {
    return this.state.state === RegisterState.DYNAMIC_SET_LEVELS_SELECTION;
  })

  isRegisterStateReadonlyArticleSelected = getter(() => this.state.state === RegisterState.READONLY_ARTICLE_SELECTED)

  isRegisterStateValidateAgeRestriction = getter(() => this.state.state === RegisterState.VALIDATE_AGE_RESTRICTION)

  isRegisterStateStockInStores = getter(() => this.state.state === RegisterState.STOCK_IN_STORES)

  isActiveArticleInputRestrictionState = getter(() => {
    return !this.isRegisterStateEnterCustomerCard.value;
  })

  isActiveCustomerInputRestrictionState = getter(() => {
    return this.isRegisterStateEnterCustomerCard.value;
  })

  isActivePaymentInputRestrictionState = getter(() => {
    return this.isRegisterStateSelectPayment.value;
  })

  invalidateInputRestrictionsByRestrictions = action((restrictions: Array<InputRestrictionTypes>) => {
    if (restrictions === undefined) return true;

    if (restrictions === null || restrictions?.length === 0) return false;

    return restrictions.includes({
      [InputSource.KEYBOARD]: InputRestrictionTypes.manual,
      [InputSource.PASTE]: InputRestrictionTypes.manual,
      [InputSource.SCANNER]: InputRestrictionTypes.scanner,
      [InputSource.QUICK_CALL]: InputRestrictionTypes.quickCall,
    }[this.productFlow.value?.inputMode] ?? InputRestrictionTypes.manual);
  })

  invalidateInputRestrictionsBySubject = action((subject) => {
    const restrictions = this.configuration.value?.features?.sell?.inputs?.[subject] ?? null;

    if (!restrictions) {
      return true;
    }

    /**
     * Note: isActiveArticleInputRestrictionState is last because the nature of its condition
     */

    if (this.isActiveCustomerInputRestrictionState.value) {
      return this.invalidateInputRestrictionsByRestrictions(restrictions.customers);
    } else if (this.isActivePaymentInputRestrictionState.value) {
      return this.invalidateInputRestrictionsByRestrictions(restrictions.payments);
    } else if (this.isActiveArticleInputRestrictionState.value) {
      return this.invalidateInputRestrictionsByRestrictions(restrictions.articles);
    } else {
      return true;
    }
  })

  isAllowedArticleInput = getter(() => {
    return this.invalidateInputRestrictionsBySubject('article');
  })

  isAllowedCustomerInput = getter(() => {
    return this.invalidateInputRestrictionsBySubject('customer');
  })

  isAllowedPaymentInput = getter(() => {
    return this.invalidateInputRestrictionsBySubject('payment');
  })

  isAllowedReceiptInput = getter(() => {
    return this.invalidateInputRestrictionsBySubject('receipt');
  })

  isAllowedVoucherInput = getter(() => {
    return this.invalidateInputRestrictionsBySubject('voucher');
  })

  lastSellDocument = getter(() => this.state.lastSellDocument)

  loadedProducts = getter(() => this.state.loadedProducts)

  payment = getter(() => this.state.payment)

  productFlow = getter(() => this.state.productFlow)

  quickCallActiveMap = getter(() => this.state.quickCallActiveMap)

  canToggleQuickCall = getter(() => {
    if (this.isQuickCallPanelPriorityPendingPayment.value) {
      return false;
    }

    if (this.isQuickCallPanelPriorityInfoMessage.value) {
      return false;
    }

    if (this.areQuickCallsImportant.value) {
      return false;
    }

    if (this.isQuickCallPanelPriorityGuidedSelling.value) {
      return true;
    }

    if (this.hasAvailableContextualProductRecommendations.value) {
      return true;
    }

    if (this.isQuickCallPanelPriorityCustomerFeaturedAttributes.value) {
      return true;
    }

    return this.hasAvailableGuidedSellingOptions.value;
  })

  quickCallPanelByStateAndPriority = getter(() => {
    if (!this.paymentsVerificationInProgress.value && this.hasPendingUnverifiedPayment.value) {
      return QuickCallPanelPriority.PendingPayment;
    }

    if (this.areQuickCallsImportant.value) {
      return QuickCallPanelPriority.QuickCall;
    }

    if (this.isRegisterStateBurnPoints.value) {
      return QuickCallPanelPriority.InfoMessage;
    }

    if (
      this.state.quickCallPanelPriority === QuickCallPanelPriority.GuidedSelling &&
      !this.hasAvailableGuidedSellingOptions.value
    ) {
      return QuickCallPanelPriority.QuickCall;
    }

    if (
      this.state.quickCallPanelPriority === QuickCallPanelPriority.ContextualProductRecommendations &&
      !this.hasAvailableContextualProductRecommendations.value
    ) {
      return QuickCallPanelPriority.QuickCall;
    }

    if (
      this.state.quickCallPanelPriority === QuickCallPanelPriority.CustomerFeaturedAttributes &&
      !this.customer.value
    ) {
      return QuickCallPanelPriority.QuickCall;
    }

    return this.state.quickCallPanelPriority ?? QuickCallPanelPriority.QuickCall;
  })

  isQuickCallPanelPriorityPendingPayment = getter(() => {
    return this.quickCallPanelByStateAndPriority.value === QuickCallPanelPriority.PendingPayment;
  })

  isQuickCallPanelPriorityQuickCall = getter(() => {
    return this.quickCallPanelByStateAndPriority.value === QuickCallPanelPriority.QuickCall;
  })

  isQuickCallPanelPriorityCustomerFeaturedAttributes = getter(() => {
    return this.quickCallPanelByStateAndPriority.value === QuickCallPanelPriority.CustomerFeaturedAttributes;
  })

  isQuickCallPanelPriorityContextualProductRecommendations = getter(() => {
    return this.quickCallPanelByStateAndPriority.value === QuickCallPanelPriority.ContextualProductRecommendations;
  })

  isQuickCallPanelPriorityInfoMessage = getter(() => {
    return this.quickCallPanelByStateAndPriority.value === QuickCallPanelPriority.InfoMessage;
  })

  isQuickCallPanelPriorityGuidedSelling = getter(() => {
    return this.quickCallPanelByStateAndPriority.value === QuickCallPanelPriority.GuidedSelling;
  })

  areQuickCallsImportant = getter(() => {
    if (
      this.state.state === RegisterState.BURN_POINTS ||
      this.state.state === RegisterState.RETURN_REASON_CODE ||
      this.state.state === RegisterState.SELECT_PAYMENT ||
      this.state.state === RegisterState.ENTER_CUSTOM_DATA ||
      this.state.state === RegisterState.ENTER_CUSTOM_DATA_QUESTION
    ) {
      return true;
    }

    return false;
  })

  productDetail = getter(() => this.state.productDetail);

  customerDetail = getter(() => this.state.customerDetail);

  customerAuthentication = getter(() => this.state.customerAuthentication);

  customerAuthenticationState = getter(() => this.state.customerAuthentication?.state);

  getAvailablePointsBurning = action((clubCode) => <PromotionAvailablePointsBurning>find(
    this.sellDocument.value.promotions, (promo) => {
      return promo.type === PromotionMetaType.AVAILABLE_POINTS_BURNING && promo.clubCode === clubCode;
    }),
  )

  quickCallDisplayState = getter((): QuickCallDisplayWhen | null => {
    if (
      (this.state.state === RegisterState.SELECT_PAYMENT) ||
      (this.state.state === RegisterState.ENTER_CUSTOM_DATA) ||
        (this.state.state === RegisterState.ENTER_CUSTOM_DATA_QUESTION)
    ) {
      return QuickCallDisplayWhen.Payment;
    } else if (
      (this.state.state === RegisterState.ENTER_ARTICLE_NUMBER) ||
      (this.state.state === RegisterState.ENTER_ARTICLE_QUANTITY) ||
      (this.state.state === RegisterState.READONLY_ARTICLE_SELECTED) ||
      (this.state.state === RegisterState.ENTER_CUSTOMER_CARD)
    ) {
      return QuickCallDisplayWhen.Receipt;
    } else {
      return null;
    }
  })

  quickCallState = getter((): {upperContent: QuickCall, lowerContent?: QuickCall} => {
    if (this.state.state === RegisterState.BURN_POINTS && this.promoFlow.value instanceof PointsBurningFlow) {
      return {
        upperContent: new QuickCall({
          children: map(
            // this.promoFlow.value is reactive, there is no .value on quickCallButtons here
            // @ts-ignore
            this.promoFlow.value.quickCallButtons,
            (button: PromoPointsBurningButton) => button.toJson(),
          ),
        }),
      };
    } else if (this.state.state === RegisterState.RETURN_REASON_CODE) {
      return {
        upperContent: new QuickCall({
          children: map(
            this.configuration.value.features.storno.stornoReasons,
            (button) => ({...button.toJson(), type: QuickCallTypes.StornoReason}),
          ),
        }),
      };
    } else {
      const quickCallButtons = this.configuration.value?.quickCall?.[this.quickCallDisplayState.value]?.toJson();

      if (this.quickCallDisplayState.value === QuickCallDisplayWhen.Payment && this.payment.value?.type?.isCashMethod) {
        return {
          upperContent: new QuickCall(quickCallButtons),
          lowerContent: new QuickCall({
            children: map(
              this.configuration.value?.features?.payment?.cashQuickPick?.[this.payment.value?.currency] ?? [],
              (option) => (<QuickCallData>{
                isCustomButton: true,
                label: option.label,
                value: option.value,
                code: option.value?.toString(),
                type: QuickCallTypes.CashQuickPick,
              }),
            ),
          }),
        };
      }

      return {
        upperContent: new QuickCall(quickCallButtons),
      };
    }
  })

  registerState = getter(() => {
    return this.state.state;
  })

  saveFlow = getter(() => {
    return this.state.saveFlow;
  })

  previousRegisterState = getter(() => {
    return this.state.previousState;
  })

  printContentStore = getter(() => {
    return usePrintContentStore();
  })

  trainingStore = getter(() => {
    return useTrainingStore();
  })

  returnModeIsUsable = getter(() => {
    if (this.isReturnModeActive.value) {
      return this.state.productFlow.product?.isFilled ?? true;
    }

    if (this.isRegisterStateSelectPayment.value) {
      return false;
    }

    if (
      this.configuration.value?.features?.storno?.allowAddingStornoOnlyToEmptyBon &&
      this.state.sellDocument.validItems.length > 0
    ) {
      return false;
    }

    if (this.isEditModeActive.value) {
      return this.state.productFlow.product.isFilled;
    }

    return this.state.productFlow.product?.isFilled ?? true;
  })

  searchProductsIsUsable = getter(() => {
    if (
      !this.isRegisterStateEnterArticleNumber.value &&
      !this.isRegisterStateEnterCustomerCard.value &&
      !this.isRegisterStateEnterArticleQuantity.value
    ) {
      return false;
    }

    if (this.state.sellDocument.isModeStorno) {
      return false;
    }

    return this.state.productFlow.product?.isFilled ?? true;
  })

  searchCustomerIsUsable = getter(() => {
    if (
      !this.isRegisterStateEnterArticleNumber.value &&
      !this.isRegisterStateEnterCustomerCard.value &&
      !this.isRegisterStateEnterArticleQuantity.value
    ) {
      return false;
    }

    if (this.state.sellDocument.isModeStorno) {
      return false;
    }

    if (!(this.configurationStore.value?.configuration?.value?.features?.sell?.customerSearchEnabled ?? true)) {
      return false;
    }

    return this.state.productFlow.product?.isFilled ?? true;
  })

  sellDocument = getter(() => this.state.sellDocument)

  hasPendingUnverifiedPayment = getter(() => {
    if (!this.state.sellDocument) {
      return false;
    }

    return some(this.state.sellDocument.validUnvalidatedPayments, (payment) => {
      return payment.hasPendingSynchronization;
    });
  })

  paymentsVerificationInProgress = getter(() => {
    return this.state.paymentsVerificationInProgress;
  })

  sumIsUsable = getter(() => {
    if (!this.state.sellDocument.validItems.length) {
      return false;
    }

    if (!every(this.state.sellDocument.validItems, 'isFilled')) {
      return false;
    }

    return this.isRegisterStateEnterArticleNumber.value || (this.state.productFlow.product?.isFilled ?? true);
  })

  enterIsPulsing = getter(() => {
    if (this.isRegisterStateSelectPayment.value) {
      return this.state.inputBuffer !== '' && this.state.payment;
    }

    return false;
  })

  sumIsVisible = getter(() => {
    if (
      this.isRegisterStateSelectPayment.value ||
      this.isRegisterStateEnterCustomerCard.value
    ) {
      return false;
    }

    return true;
  })

  unassignedCard = getter(() => {
    return this.state.unassignedCard;
  })

  unreturnableGroupsSeen = getter(() => {
    return this.state.unreturnableGroupsSeen;
  })

  withAppLoader = action(async (fn) => {
    this.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      return await fn();
    } finally {
      this.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  });

  withoutPromotionTrigger = action(async (fn) => {
    this.state.disablePromotionTrigger = true;
    try {
      return await fn();
    } finally {
      this.state.disablePromotionTrigger = false;
    }
  })

  /** Promo engine and Guided Selling */
  fetchPromoEngineResult = action(async ({
    triggerImmediatePromotions = true,
    ignorePromoRemovals = false,
  } = {}) => {
    return await this.withAppLoader(async () => {
      try {
        if (this.state.sellDocument.isModeStorno) {
          return this.state.sellDocument;
        }

        if (this.isEditModeActive.value) {
          return this.state.sellDocument;
        }

        const {document} = await DocumentDto.promoEngine.processDocument(this.state.sellDocument, null);
        for (const promo of promotionByType(document, PromotionMetaType.SEND_SMS)) {
          if (promo.shouldSend && promo.priority === PromoSendSmsPriority.IMMEDIATELY) {
            try {
              await apiSms({
                input: new SendSmsDto({
                  message: promo.text,
                  phoneNumber: promo.phone,
                }),
              });
              promo.sent = true;
            } catch (err) {
              console.warn('Failed to send sms', err);
              // ignore, we might be offline
            }
          }
        }

        if (ignorePromoRemovals) {
          document.promotions = (document.promotions ?? [])
            .filter((promo) => promo.type !== PromotionMetaType.PROMO_REMOVED);
        }

        this.state.sellDocument = document;

        if (this.hasAvailableGuidedSellingOptions.value) {
          await this.setQuickCallPanelPriority(QuickCallPanelPriority.GuidedSelling);
        }
      } catch (e) {
        console.error(e);
      }

      if (triggerImmediatePromotions) {
        await this.triggerImmediatePromotions();
      }

      return this.state.sellDocument;
    });
  })

  fetchPromoEngineContextualProductRecommendations = action(async () => {
    const {document} = await DocumentDto.promoEngine.processDocument(
      this.state.sellDocument,
      null,
      {
        currentArticle: this.currentEditGroup.value?.editableItem,
      },
    );

    this.productFlow.value.promotions = {
      ...this.productFlow.value.promotions,
      recommendations: [
        ...promotionByType(document, PromotionMetaType.DYNAMIC_SET_SELECTION),
        ...promotionByType(document, PromotionMetaType.DYNAMIC_SET_LEVELS_SELECTION),
      ],
    };

    await this.persist();
  })

  promotionHookPaymentsChanged = action(async () => {
    this.dispatchEvent(new Event(RegisterStoreEvent.PAYMENTS_CHANGED));
  })

  promotionHookItemsChanged = action(async () => {
    this.dispatchEvent(new Event(RegisterStoreEvent.ITEMS_CHANGE));
    await this.fetchPromoEngineResult();
  })

  promotionHookCustomerChanged = action(async () => {
    this.dispatchEvent(new Event(RegisterStoreEvent.CUSTOMER_CHANGED));
    await this.fetchPromoEngineResult();
    await this.fetchPromoEngineContextualProductRecommendations();
  })

  promotionHookActiveArticleChanged = action(async () => {
    // this.dispatchEvent(new Event(RegisterStoreEvent.ACTIVE_ARTICLE_CHANGED));
    await this.fetchPromoEngineContextualProductRecommendations();
  })

  promotionHookFollowUpDocumentInjected = action(async () => {
    this.dispatchEvent(new Event(RegisterStoreEvent.FOLLOW_UP_DOCUMENT_INJECTED));
    await this.fetchPromoEngineResult();
  })

  promotionHookCancelPayment = action(() => {
    this.state.sellDocument.promotions = this.state.sellDocument.promotions.map((promotion) => {
      if (promotion.type === PromotionMetaType.GECO_GAME) {
        promotion.played = null;
      } else if (promotion.type === PromotionMetaType.REQUEST_POINTS_BURN) {
        promotion.finished = false;
      }
      return promotion;
    });
  })

  giftPoolsWithSelectedGifts = getter(() => {
    if (!this.sellDocument.value?.promotions) {
      return [];
    }
    // @ts-ignore
    const selectedGifts = promotionByType(this.sellDocument.value, PromotionMetaType.GIFT_SELECTED);
    // @ts-ignore
    const giftPools = promotionByType(this.sellDocument.value, PromotionMetaType.GIFT_POOL);
    const selectedGiftsByGiftPool = mapKeys(selectedGifts, ({giftPool}) => giftPool);
    return map(giftPools, (giftPool) => ({
      giftPool,
      selectedGifts: selectedGiftsByGiftPool[giftPool.giftPool],
    }));
  })

  pendingGifts = getter(() => {
    const giftPoolsWithSelectedGifts = filter(this.giftPoolsWithSelectedGifts.value, (({giftPool, selectedGifts}) => {
      // we declared finished selection on this gift pool
      if (selectedGifts?.selectionFinished) {
        return false;
      }
      // there is no article with that can by bought with remainingPoints
      // also triggers for no articles and poolPointsRemaining <= 0
      if (!some(giftPool.articles, (article) => article.bulkPointPrice <= giftPool.poolPointsRemaining)) {
        return false;
      }
      return true;
    }));
    return map(giftPoolsWithSelectedGifts, ({giftPool}) => giftPool);
  });

  promoSuggestions = getter(() => {
    return promotionByType(this.state.sellDocument, PromotionMetaType.SUGGESTION);
  });

  pendingGecoGames = getter(() => {
    return promotionByType(this.sellDocument.value, PromotionMetaType.GECO_GAME_AVAILABLE)
      .filter(({promoCode, counterName}) => promoCode && counterName);
  });

  pendingQuestions = getter(() => {
    return promotionByType(this.sellDocument.value, PromotionMetaType.QUESTION)
      .filter((question) => !has(question, 'answer'));
  });

  pendingPointsBurnRequests = getter(() => {
    return filter(
      promotionByType(this.sellDocument.value, PromotionMetaType.REQUEST_POINTS_BURN),
      {finished: false, shouldOpen: true},
    );
  });

  pendingContextualProductRecommendations = getter(() => {
    if (!this.currentEditGroup.value) {
      return [];
    }

    return this.productFlow.value.promotions.recommendations;
  });

  syncableContextualProductRecommendationsForCustomer = getter(() => {
    const recommendationsTypeDynamicSetSelection = promotionByType(
      {
        promotions: this.productFlow.value.promotions.recommendations,
      },
      PromotionMetaType.DYNAMIC_SET_SELECTION,
    )
      .filter((promo) => promo.selection.some((selectionItem) => {
        return selectionItem.prices.some((price) => !price.keepTriggerArticle);
      }))
      .map((promo) => ({
        ...promo,
        selection: promo.selection
          .filter((selectionItem) => selectionItem.prices.some((price) => !price.keepTriggerArticle))
          .map((selectionItem) => ({
            ...selectionItem,
            prices: selectionItem.prices.filter((price) => !price.keepTriggerArticle),
          })),
      }));

    const recommendationsTypeDynamicSetLevelsSelection = promotionByType(
      {
        promotions: this.productFlow.value.promotions.recommendations,
      },
      PromotionMetaType.DYNAMIC_SET_LEVELS_SELECTION,
    );

    return [
      ...recommendationsTypeDynamicSetSelection,
      ...recommendationsTypeDynamicSetLevelsSelection,
    ] as (DynamicSetSelection | DynamicSetLevelsSelection)[];
  })

  pendingPromoInteractions = getter(() => {
    return orderBy([
      ...this.pendingQuestions.value,
      ...this.pendingGifts.value,
      ...this.pendingPointsBurnRequests.value,
      ...this.pendingGecoGames.value,
    ], 'order');
  });

  pendingFollowUpDocuments = getter(() => {
    return promotionByType(this.sellDocument.value, PromotionMetaType.FOLLOW_UP_DOCUMENT);
  });

  hasPendingPromoInteractions = getter(() => {
    return this.pendingPromoInteractions.value.length > 0;
  });

  immediatePromotions = getter(() => {
    return this.pendingPromoInteractions.value.filter((promo) => {
      return 'priority' in promo && promo.priority === InteractionPriority.IMMEDIATE;
    });
  });

  currentPromotion = getter((): PromotionMeta => {
    if (!this.hasPendingPromoInteractions) {
      return null;
    }
    return this.pendingPromoInteractions.value[0];
  });

  startQuestionFlow = action(async (questionId: string, answer = null) => {
    return withLock(PromoFlowLock, async () => {
      if (
        (this.isEditModeActive.value && !await this.ensureProductSave()) ||
        (this.promoFlow.value)
      ) {
        return;
      }

      if (answer) {
        const promoFlow = await QuestionFlow.new(questionId);
        await this.setPromoFlow(promoFlow);
        await promoFlow.answerQuestion(answer);
        await this.persist();
      } else {
        await this.changeState(RegisterState.ANSWER_QUESTION, {initArg: {questionId}});
      }
    }, () => {
      this.fetchPromoEngineResult();
    });
  })

  startFollowUpDocumentFlow = action(async (followUpDocument: FollowUpDocument) => {
    await FollowUpDocumentFlow.new(followUpDocument);
  })

  startGiftSelectionFlow = action(async (giftPool: DocumentGiftPool, nextStep?) => {
    return withLock(PromoFlowLock, async () => {
      if (
        (this.isEditModeActive.value && !await this.ensureProductSave()) ||
        (this.promoFlow.value)
      ) {
        return;
      }

      await this.changeState(RegisterState.SELECT_GIFT, {initArg: {giftPool}});
    }, () => {
      this.fetchPromoEngineResult();
    });
  })

  startDynamicSetSelectionFlow = action(async <DSS extends (DynamicSetSelection | DynamicSetLevelsSelection)>(
    recommendation: DSS,
    selection: DSS['selection'][number],
  ) => {
    return withLock(PromoFlowLock, async () => {
      if (this.promoFlow.value) {
        return;
      }

      const recommendationWithSelection = {recommendation, selection};

      function isDynamicSetSelection(recommendationWithSelection): recommendationWithSelection is {
        recommendation: DynamicSetSelection,
        selection: DynamicSetSelection['selection'][number]
      } {
        return recommendationWithSelection.recommendation.type === PromotionMetaType.DYNAMIC_SET_SELECTION;
      }


      if (isDynamicSetSelection(recommendationWithSelection)) {
        if (recommendationWithSelection.selection.allowCombination) { // start of dynamic set selection flow
          await this.changeState(RegisterState.DYNAMIC_SET_SELECTION, {
            initArg: {
              dynamicSetSelection: recommendationWithSelection.recommendation,
              selection: recommendationWithSelection.selection,
              previousState: RegisterState.ENTER_ARTICLE_QUANTITY,
            },
          });
        } else { // immediate selection of set
          const promoFlow = await DynamicSetSelectionFlow.new(
            recommendationWithSelection.recommendation,
            recommendationWithSelection.selection,
            RegisterState.ENTER_ARTICLE_QUANTITY,
          );

          await this.setPromoFlow(promoFlow);

          await promoFlow.submitDynamicSetSelectedPromotion();

          await this.persist();
        }
      } else {
        await this.changeState(RegisterState.DYNAMIC_SET_LEVELS_SELECTION, {
          initArg: {
            dynamicSetLevelsSelection: recommendationWithSelection.recommendation,
            selection: recommendationWithSelection.selection,
            previousState: RegisterState.ENTER_ARTICLE_QUANTITY,
          },
        });
      }
    }, () => {
      this.fetchPromoEngineResult();
    });
  })

  startGecoGameFlow = action(async ({counterName, promoCode}: {counterName: string, promoCode: string}) => {
    return withLock(PromoFlowLock, async () => {
      if (
        (this.isEditModeActive.value && !await this.ensureProductSave()) ||
        (this.promoFlow.value)
      ) {
        return;
      }

      await this.changeState(RegisterState.GECO_GAME, {initArg: {counterName, promoCode}});
    }, () => {
      this.fetchPromoEngineResult();
    });
  });

  startContextualProductRecommendationsSelectionFlow = action(async () => {
    return withLock(PromoFlowLock, async () => {
      const recommendations = this.syncableContextualProductRecommendationsForCustomer.value;

      if (!recommendations.length || this.promoFlow.value) {
        return;
      }

      await this.changeState(RegisterState.CONTEXTUAL_PRODUCT_RECOMMENDATION_SELECTION, {
        initArg: {
          recommendations,
          previousState: RegisterState.ENTER_ARTICLE_QUANTITY,
        },
      });
    }, () => {
      this.fetchPromoEngineResult();
    });
  });

  triggerPromo = action(async (promo: InteractivePromotion) => {
    if (promo.type === PromotionMetaType.QUESTION) {
      await this.startQuestionFlow(promo.id, undefined);
    } else if (promo.type === PromotionMetaType.GIFT_POOL) {
      await this.startGiftSelectionFlow(promo);
    } else if (promo.type === PromotionMetaType.GECO_GAME_AVAILABLE) {
      await this.startGecoGameFlow(promo);
    } else if (promo.type === PromotionMetaType.REQUEST_POINTS_BURN) {
      this.startBurningPoints(promo.clubCode);
      promo.finished = true;
    } else {
      throw new Error(`Invalid Interaction promo ${(<any>promo)?.type}`);
    }
  });

  triggerImmediatePromotions = action(async () => {
    if (this.state.disablePromotionTrigger) {
      return;
    }
    const isAllowedRegisterState = (state: RegisterState) => {
      return new Set([RegisterState.ENTER_ARTICLE_NUMBER]).has(state);
    };

    if (this.state.promoFlow || !isAllowedRegisterState(this.registerState.value)) {
      return;
    }
    const nextImmediatePromotion = first(this.immediatePromotions.value);
    if (nextImmediatePromotion) {
      await this.triggerPromo(nextImmediatePromotion);
    }
  });

  availableGuidedSellingOptions = getter(
    () => [
      ...this.pendingQuestions.value
        .filter((question) => question.priority === InteractionPriority.ANYTIME),
      ...this.pendingGifts.value
        .filter((giftPool) => giftPool.priority === InteractionPriority.ANYTIME),
      ...this.promoSuggestions.value,
    ],
  )

  availableContextualProductRecommendations = getter(
    () => [
      ...this.pendingContextualProductRecommendations.value
        .filter((recommendation) => recommendation.selection.length),
    ],
  )

  availableFollowUpDocuments = getter(() => this.pendingFollowUpDocuments.value)

  hasAvailableGuidedSellingOptions = getter(() => !!this.availableGuidedSellingOptions.value.length)

  hasAvailableContextualProductRecommendations = getter(() => {
    if (this.isRegisterStateEnterArticleQuantity.value) {
      return !!this.availableContextualProductRecommendations.value.length;
    }

    return !!this.availableContextualProductRecommendations.value.length;
  })

  hasAvailableFollowUpDocuments = getter(() => {
    return !!this.availableFollowUpDocuments.value.length;
  })

  advertismentPromotions = syncToTarget('Promotion', 'advertismentPromotions', getter(() => {
    const promotionsMedia: Array<{type: MediaType, code: string}> = [];
    if (this.registerState.value === RegisterState.SELECT_PAYMENT) {
      // @ts-ignore
      const gecoGames = promotionByType(this.sellDocument.value._data, PromotionMetaType.GECO_GAME);
      promotionsMedia.push(...gecoGames
        .filter(({played}) => played)
        .map((game) => ({
          type: MediaType.imageCode,
          code: game.resultImageCode,
        })));
    }
    return promotionsMedia;
  }));

  onPromoRemovedSeen = action(() => {
    this.state.sellDocument.promotions = reject(
      this.state.sellDocument.promotions,
      {type: PromotionMetaType.PROMO_REMOVED},
    );
  })
}

const storeIdentifier = 'RegisterStore';

export const configureRegisterStore = createConfigureStore<typeof RegisterStore>(storeIdentifier);
export const useRegisterStore = createUseStore(RegisterStore, storeIdentifier);
