import {
  action,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {BufferedInput, InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_DELETE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {inventoryStockStoreStatePersist} from '@/Helpers/persist';
import {
  InputInvalidReason,
  InventorySearchResult,
  InventoryStockActions,
  InventoryStockErrors,
  InventoryStockEvent,
  InventoryStockInput,
  InventoryStockInputEvent,
  InventoryStockInputSet,
  InventoryStockState,
  InventoryStockStoreState,
  InventoryStockType,
} from '@/Modules/Apps/Inventory/types';
import {HelpStoreErrors, useHelpStore} from '@/Modules/Core/store/HelpStore';
import {
  DocumentDto,
  DocumentItemDto,
  DocumentLogisticItemDto,
} from '@/Model/Entity';
import {
  filter,
  find,
  flow,
  includes,
  isEmpty,
  map,
  parseInt,
  reject,
  some,
  deburr,
  toLower,
  flatten,
  isNil,
} from 'lodash-es';
import {
  apiDocumentGetList,
  apiInventoryCreate,
  apiSearch,
  apiSearchMiddleware,
} from '@/Model/Action';
import {
  AppLoaderEvent,
  CoreSounds,
  PrinterWSEvents,
} from '@/Modules/Core/types';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import useSoundEffects from '@/Modules/Core/Sounds/sounds';
import {RequestErrors} from '@/constants/requestErrors';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {download} from '@/Helpers/download';
import {parseBarcode} from '@designeo/pos-search-engine/src/common/barcodes/BarcodeParser';
import {useVibrate} from '@/Helpers/vibrate';
import {LRUCache} from '@/Helpers/cache';
import {serialized} from '@/Helpers/promise';


const createInput = (data: Partial<InventoryStockInput> = {}): InventoryStockInput => ({
  invalidReason: false,
  code: '',
  item: null,
  value: 0,
  lastValue: undefined,
  ...data,
} as InventoryStockInput);

const createInputSet = (data: Partial<InventoryStockInputSet> = {}) => ({
  item: null,
  value: 1,
  ...data,
});

export const createInitState = (): InventoryStockStoreState => ({
  state: InventoryStockState.ENTER_CODE,
  previousState: null,
  inventoryStockInput: createInput(),
  inventoryStockInputSet: createInputSet(),
  document: DocumentDto.createInventoryDocument({}),
  productDetail: null,
  loadedItems: null,
  insertMode: false,
  networkError: false,
  lastProcessedItem: null,
  fractionDocumentList: null,
  fractionDocumentSearchKeyboard: false,
  stockType: null,
});

export class InventoryStockStore extends PersistentStore<InventoryStockStoreState> {
  private articleLRUCache = new LRUCache<string, {
    expandedLogisticItem: DocumentLogisticItemDto,
    quantity: number,
  }>(5, 60_000);

  constructor() {
    super(createInitState(), inventoryStockStoreStatePersist, {autoPersist: false});
    const {preloadSoundEffect} = useSoundEffects();
    preloadSoundEffect(CoreSounds.ARTICLE_NOT_FOUND);
  }

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    if (bufferedInput.source === InputSource.SCANNER) {
      await this.onEventInput({
        type: InventoryStockActions.SCANNER,
        value: map(bufferedInput.keys, 'key').join(''),
      });
      return; // or sanitize buffered input and fallthrough?
    }

    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: InventoryStockActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: InventoryStockActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: InventoryStockActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_DELETE) {
        await this.onEventInput({
          type: InventoryStockActions.CLEAR,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: InventoryStockActions.CLEAR,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        await this.onEventInput({
          type: InventoryStockActions.ADD_CHAR,
          value: key.key,
        });
      }
    }
  });

  onEventInput = action(async (event: InventoryStockInputEvent) => {
    this.vibrate();
    this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  validateScannerInput = action(async (event: InventoryStockInputEvent) => {
    const value = event?.value?.trim();

    if (!value) {
      return false;
    }

    if (!parseBarcode(value).valid) {
      await this.playSound(CoreSounds.ARTICLE_NOT_FOUND);
      return false;
    }

    return true;
  })

  transitions: {[key in InventoryStockState]?: {[key in InventoryStockActions]?: any}} = {
    [InventoryStockState.ENTER_CODE]: {
      [InventoryStockActions.ADD_CHAR]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.invalidReason) {
          this.state.inventoryStockInput.code = '';
        }
        this.setInputInvalid(null);

        this.state.inventoryStockInput.code += event.value;
      }),
      [InventoryStockActions.ADD_NUMBER]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.invalidReason) {
          this.state.inventoryStockInput.code = '';
        }
        this.setInputInvalid(null);

        this.state.inventoryStockInput.code += event.value;
      }),
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInputEvent) => {
        await this.processArticleCode();
      }),
      [InventoryStockActions.BACKSPACE]: action(async (event: InventoryStockInputEvent) => {
        this.setInputInvalid(null);
        if (this.state.inventoryStockInput.code.length) {
          this.state.inventoryStockInput.code = this.state.inventoryStockInput.code.slice(0, -1);
        }
      }),
      [InventoryStockActions.CANCEL]: action(async (event: InventoryStockInputEvent) => {
        this.cancelInventory();
      }),
      [InventoryStockActions.CLEAR]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code = '';
      }),
      [InventoryStockActions.SCANNER]: action(async (event: InventoryStockInputEvent) => {
        if (!(await this.validateScannerInput(event))) return;
        await this.processArticleCode({code: event.value});
      }),
    },
    [InventoryStockState.ENTER_QUANTITY]: {
      [InventoryStockActions.INIT]: action(() => {
        this.state.insertMode = true;
        this.state.inventoryStockInput.lastValue = this.state.inventoryStockInput.value;
      }),
      [InventoryStockActions.ADD_NUMBER]: action(async (event: InventoryStockInputEvent) => {
        if (!event.value) {
          return;
        }

        if (this.state.insertMode) {
          this.state.inventoryStockInput.value = parseInt(event.value);
          this.state.insertMode = false;
        } else {
          this.state.inventoryStockInput.value = parseInt(
            this.state.inventoryStockInput.value + event.value,
          );
        }
      }),
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.value <= 0) {
          return;
        }

        const inputValue = this.state.inventoryStockInput.value;

        const isValueValidEan = parseBarcode(inputValue.toString()).valid;
        const isValueValidQuantity = inputValue.toString().length <= 4;

        if (isValueValidQuantity) {
          await this.commitCurrentInputArticle();
          return;
        }

        if (isValueValidEan) {
          await this.commitCurrentInputArticle({quantity: this.inventoryStockInput.value.lastValue});
          await this.processArticleCode({code: inputValue.toString()});
          return;
        }

        this.setInputInvalid(InputInvalidReason.INPUT_INVALID);
        this.playSound(CoreSounds.ARTICLE_NOT_FOUND);
        this.state.inventoryStockInput.value = this.state.inventoryStockInput.lastValue;
      }),
      [InventoryStockActions.BACKSPACE]: action(async (event: InventoryStockInputEvent) => {
        if (String(this.state.inventoryStockInput.value).length > 0) {
          this.state.inventoryStockInput.value = parseInt(
            String(this.state.inventoryStockInput.value).slice(0, -1),
          );
        }
        if (!this.state.inventoryStockInput.value) {
          this.state.inventoryStockInput.value = 0;
        }
      }),
      [InventoryStockActions.CANCEL]: action(async (event: InventoryStockInputEvent) => {
        this.resetInput();
        await this.changeState(InventoryStockState.ENTER_CODE);
      }),
      [InventoryStockActions.CLEAR]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.value === 0) {
          this.dispatchEvent(new Event(InventoryStockEvent.ARTICLE_REMOVE));
        } else {
          this.state.inventoryStockInput.value = 0;
        }
      }),
      [InventoryStockActions.ADD_PLUS]: action(async (event: InventoryStockInput) => {
        this.state.inventoryStockInput.value = this.state.inventoryStockInput.value + 1;
      }),
      [InventoryStockActions.ADD_MINUS]: action(async (event: InventoryStockInput) => {
        if (this.state.inventoryStockInput.value > 0) {
          this.state.inventoryStockInput.value = this.state.inventoryStockInput.value - 1;
        }
      }),
      [InventoryStockActions.SCANNER]: action(async (event: InventoryStockInputEvent) => {
        if (!(await this.validateScannerInput(event))) return;
        await this.onEventInput({type: InventoryStockActions.ENTER});
        await this.processArticleCode({code: event.value});
      }),
    },
    [InventoryStockState.SELECT_ITEM_VARIATION]: {
      [InventoryStockActions.CANCEL]: action(async (event: InventoryStockInputEvent) => {
        this.state.loadedItems = [];
        await this.changeState(InventoryStockState.ENTER_CODE);
      }),
      [InventoryStockActions.NEXT]: action(async (event: InventoryStockInputEvent<{offset: number, limit: number}>) => {
        this.state.loadedItems = await this.searchItems(this.state.inventoryStockInput.code, event.value);
      }),
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInputEvent<InventorySearchResult>) => {
        if (!event.value) return;

        try {
          await this.addArticle(event.value.documentItem, {shouldOpenInputSet: true});
          this.state.loadedItems = [];
        } catch (e) {
          await this.changeState(InventoryStockState.ENTER_CODE);
        }
      }),
    },
    [InventoryStockState.ENTER_EDIT_RECORD]: {
      [InventoryStockActions.INIT]: action(() => {
        this.state.insertMode = true;
        this.state.inventoryStockInput.lastValue = this.state.inventoryStockInput.value;
      }),
      [InventoryStockActions.ADD_NUMBER]: action(async (event: InventoryStockInputEvent) => {
        if (!event.value) {
          return;
        }

        if (this.state.insertMode) {
          this.state.inventoryStockInput.value = parseInt(event.value);
          this.state.insertMode = false;
        } else {
          this.state.inventoryStockInput.value = parseInt(
            this.state.inventoryStockInput.value + event.value,
          );
        }
      }),
      [InventoryStockActions.BACKSPACE]: action(async (event: InventoryStockInputEvent) => {
        if (String(this.state.inventoryStockInput.value).length > 0) {
          this.state.inventoryStockInput.value = parseInt(
            String(this.state.inventoryStockInput.value).slice(0, -1),
          );
        }
        if (!this.state.inventoryStockInput.value) {
          this.state.inventoryStockInput.value = 0;
        }
      }),
      [InventoryStockActions.CANCEL]: action(async (event: InventoryStockInputEvent) => {
        this.resetInput();
        await this.changeState(InventoryStockState.ENTER_CODE);
      }),
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.value <= 0) {
          return;
        }

        const inputValue = this.state.inventoryStockInput.value;

        const isValueValidEan = parseBarcode(inputValue.toString()).valid;
        const isValueValidQuantity = inputValue.toString().length <= 4;

        if (isValueValidQuantity) {
          this.state.inventoryStockInput.item.quantity = inputValue;
          this.state.lastProcessedItem = this.state.inventoryStockInput.item.clone();
          this.resetInput();
          await this.changeState(InventoryStockState.ENTER_CODE);
          return;
        }

        if (isValueValidEan) {
          this.state.inventoryStockInput.item.quantity = this.state.inventoryStockInput.lastValue;
          this.state.lastProcessedItem = this.state.inventoryStockInput.item.clone();
          this.resetInput();
          this.changeState(InventoryStockState.ENTER_CODE);
          this.onEventInput({type: InventoryStockActions.SCANNER, value: inputValue.toString()});
          return;
        }

        this.setInputInvalid(InputInvalidReason.INPUT_INVALID);
        this.playSound(CoreSounds.ARTICLE_NOT_FOUND);
        this.state.inventoryStockInput.value = this.state.inventoryStockInput.lastValue;
      }),
      [InventoryStockActions.CLEAR]: action(async (event: InventoryStockInputEvent) => {
        this.dispatchEvent(new Event(InventoryStockEvent.ARTICLE_REMOVE));
      }),
      [InventoryStockActions.ADD_PLUS]: action(async (event: InventoryStockInput) => {
        this.state.inventoryStockInput.value = this.state.inventoryStockInput.value + 1;
      }),
      [InventoryStockActions.ADD_MINUS]: action(async (event: InventoryStockInput) => {
        if (this.state.inventoryStockInput.value > 0) {
          this.state.inventoryStockInput.value = this.state.inventoryStockInput.value - 1;
        }
      }),
      [InventoryStockActions.SCANNER]: action(async (event: InventoryStockInputEvent) => {
        if (!(await this.validateScannerInput(event))) return;
        this.onEventInput({type: InventoryStockActions.ENTER});
        await this.processArticleCode({code: event.value});
      }),
    },
    [InventoryStockState.SEARCH_PRODUCTS]: {
      [InventoryStockActions.ADD_CHAR]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code += event.value;
      }),
      [InventoryStockActions.ADD_NUMBER]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code += event.value;
      }),
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInputEvent) => {
        this.dispatchEvent(new Event(InventoryStockEvent.SEARCH));
      }),
      [InventoryStockActions.BACKSPACE]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.code.length) {
          this.state.inventoryStockInput.code = this.state.inventoryStockInput.code.slice(0, -1);
        }
      }),
      [InventoryStockActions.CANCEL]: action(async (event: InventoryStockInputEvent) => {
        this.resetInput();
        this.changeState(InventoryStockState.ENTER_CODE);
      }),
      [InventoryStockActions.CLEAR]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code = '';
      }),
      [InventoryStockActions.SCANNER]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code = event.value;
      }),
    },
    [InventoryStockState.SEARCH_DOCUMENT_BY_ARTICLE]: {
      [InventoryStockActions.ADD_CHAR]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code += event.value;
      }),
      [InventoryStockActions.ADD_NUMBER]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code += event.value;
      }),
      [InventoryStockActions.BACKSPACE]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.inventoryStockInput.code.length) {
          this.state.inventoryStockInput.code = this.state.inventoryStockInput.code.slice(0, -1);
        }
      }),
      [InventoryStockActions.CANCEL]: action(async (event: InventoryStockInputEvent) => {
        this.resetInput();
        await this.changeState(InventoryStockState.ENTER_CODE);
      }),
      [InventoryStockActions.CLEAR]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code = '';
      }),
      [InventoryStockActions.SCANNER]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInput.code = event.value;
      }),
    },
    [InventoryStockState.INPUT_SET]: {
      [InventoryStockActions.INIT]: action(() => {
        this.state.insertMode = true;
        this.state.inventoryStockInputSet.value = 1;
      }),
      [InventoryStockActions.ADD_NUMBER]: action(async (event: InventoryStockInputEvent) => {
        if (this.state.insertMode) {
          this.state.inventoryStockInputSet.value = parseInt(event.value);
          this.state.insertMode = false;
        } else {
          this.state.inventoryStockInputSet.value = parseInt(
            this.state.inventoryStockInputSet.value + event.value,
          );
        }
      }),
      [InventoryStockActions.BACKSPACE]: action(async (event: InventoryStockInputEvent) => {
        if (String(this.state.inventoryStockInputSet.value).length > 0) {
          this.state.inventoryStockInputSet.value = parseInt(
            String(this.state.inventoryStockInputSet.value).slice(0, -1),
          );
        }
        if (!this.state.inventoryStockInputSet.value) {
          this.state.inventoryStockInputSet.value = 0;
        }
      }),
      [InventoryStockActions.CLEAR]: action(async (event: InventoryStockInputEvent) => {
        this.state.inventoryStockInputSet.value = 0;
      }),
      [InventoryStockActions.ADD_PLUS]: action(async (event: InventoryStockInput) => {
        this.state.inventoryStockInputSet.value = this.state.inventoryStockInputSet.value + 1;
      }),
      [InventoryStockActions.ADD_MINUS]: action(async (event: InventoryStockInput) => {
        if (this.state.inventoryStockInputSet.value > 0) {
          this.state.inventoryStockInputSet.value = this.state.inventoryStockInputSet.value - 1;
        }
      }),
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInput) => {
        if (this.state.inventoryStockInputSet.value === 0) {
          return;
        }

        await this.commitCurrentInputSetArticle();
      }),
    },
    [InventoryStockState.ITEM_DETAIL]: {
      [InventoryStockActions.ENTER]: action(async (event: InventoryStockInputEvent<DocumentItemDto>) => {
        if (!event.value) return;

        try {
          await this.addArticle(event.value, {shouldOpenInputSet: true});
          this.state.loadedItems = [];
        } catch (e) {
          console.error(e);
          await this.changeState(InventoryStockState.ENTER_CODE);
        }
      }),
    },
  }

  setFractionDocumentSearchKeyboard = action((val: boolean) => {
    this.state.fractionDocumentSearchKeyboard = val;
  })

  fetchFractionDocumentList = action(async (params: URLSearchParams) => {
    this.state.fractionDocumentList = await (apiDocumentGetList({
      params,
    }));

    return this.state.fractionDocumentList;
  })

  documentAlreadyContainsLogisticItem = action((item: DocumentLogisticItemDto) => {
    const internalNumbersWithBatch = map(this.state.document.logisticItems, (item) => item.internalNumberWithBatch);
    return includes(internalNumbersWithBatch, item.internalNumberWithBatch);
  })

  changeState = action(async (state: InventoryStockState) => {
    this.state.previousState = this.state.state;
    this.state.state = state;

    if (this.transitions?.[this.state.state]?.[InventoryStockActions.INIT]) {
      await this.transitions?.[this.state.state]?.[InventoryStockActions.INIT]();
    }

    emitTestEvent(`${TestEvent.INVENTORY_PDA_STATE_CHANGED}:${state}`);
    await this.persist();
  })

  setInputInvalid = action((invalidReason: InputInvalidReason | null) => {
    this.state.inventoryStockInput = createInput({
      ...this.state.inventoryStockInput,
      invalidReason,
    });
  })

  openItemDetail = action(async (item: DocumentLogisticItemDto) => {
    await this.changeState(InventoryStockState.ITEM_DETAIL);
  })

  cancelInventory = action(() => {
    this.dispatchEvent(new CustomEvent(InventoryStockEvent.CANCEL_INVENTORY));
  })

  async addArticle(
    documentItem: DocumentItemDto,
    {code, shouldOpenInputSet = false}: {code?: string, shouldOpenInputSet?: boolean} = {},
  ) {
    const expandedLogisticItem = documentItem.expandExpandableSet().toDocumentLogisticItem();

    if (documentItem.isTypeService) {
      this.setInputInvalid(InputInvalidReason.ARTICLE_IS_TYPE_SERVICE);
      await this.playSound(CoreSounds.ARTICLE_IS_TYPE_SERVICE);
      throw new Error(InputInvalidReason.ARTICLE_IS_TYPE_SERVICE);
    }

    if (documentItem.isSet && documentItem.isExpandableSet) {
      // NOTE: We only open the set input modal when we came from selecting variation
      if (shouldOpenInputSet) {
        this.state.inventoryStockInputSet.item = documentItem;
        this.state.inventoryStockInputSet.value = 1;
        this.changeState(InventoryStockState.INPUT_SET);
        return;
      }
    }

    if (documentItem.isSet && !documentItem.isExpandableSet) {
      this.setInputInvalid(InputInvalidReason.ARTICLE_IS_NOT_EXPANDABLE_SET);
      await this.playSound(CoreSounds.ARTICLE_IS_TYPE_SERVICE);
      throw new Error(InputInvalidReason.ARTICLE_IS_NOT_EXPANDABLE_SET);
    }

    if (code) {
      this.articleLRUCache.set(
        code,
        {expandedLogisticItem, quantity: documentItem.isSet ? documentItem.prep : documentItem.quantity},
      );
    }

    if (this.documentAlreadyContainsLogisticItem(expandedLogisticItem)) {
      await this.enterEditRecord(
        expandedLogisticItem,
        true,
        documentItem.isSet ? documentItem.prep : documentItem.quantity,
      );
      if (this.lastProcessedItem.value.internalNumberWithBatch !== expandedLogisticItem.internalNumberWithBatch) {
        await this.playSound(CoreSounds.ARTICLE_ALREADY_IN_DOCUMENT);
      }
      return;
    }

    this.state.inventoryStockInput.item = expandedLogisticItem;
    this.state.inventoryStockInput.value = expandedLogisticItem?.quantity ?? 1;

    await this.changeState(InventoryStockState.ENTER_QUANTITY);
  }

  async removeArticle(item: DocumentLogisticItemDto = this.state.inventoryStockInput.item) {
    this.state.document.removeLogisticItemByInternalNumberWithBatch(item.internalNumberWithBatch);
    this.resetInput();
    await this.changeState(InventoryStockState.ENTER_CODE);
  }

  processArticleCode = action(serialized(async ({code = null} = {}) => {
    const newCode = code ?? this.state?.inventoryStockInput?.code;

    this.state.inventoryStockInput = createInput({code: newCode});

    // FastTrack - Add article that was recently added to document using this code
    // intended for repeated scanning of same article in a row for counting purposes
    const cached = this.articleLRUCache.get(code);

    if (cached) {
      const {quantity, expandedLogisticItem} = cached;
      if (this.documentAlreadyContainsLogisticItem(expandedLogisticItem)) {
        await this.enterEditRecord(expandedLogisticItem, true, quantity);
        if (this.lastProcessedItem.value.internalNumberWithBatch !== expandedLogisticItem.internalNumberWithBatch) {
          await this.playSound(CoreSounds.ARTICLE_ALREADY_IN_DOCUMENT);
        }
        return;
      }
    }

    if (!newCode) {
      this.setInputInvalid(InputInvalidReason.ARTICLE_NOT_FOUND);
      return;
    }

    try {
      this.state.loadedItems = await this.searchItems(newCode);

      const items = this.state.loadedItems;

      if (items.length === 1) {
        try {
          const {documentItem, match} = items[0];
          if (!match) {
            // response came from api instead of search engine
            await this.addArticle(documentItem, {code});
          } else if (match?.gtinAddonExactMatch === true) {
            // query and result has addons and match exactly
            await this.addArticle(documentItem, {code});
          } else if (!match.gtinHasAddon) {
            //  if result doesn't have addon we don't care about addon in query
            await this.addArticle(documentItem, {code});
          } else if (match?.gtinAddonExactMatch === null) {
            // query doesn't have addon but result does, user must select/confirm variant
            await this.changeState(InventoryStockState.SELECT_ITEM_VARIATION);
            await this.playSound(CoreSounds.ARTICLE_VARIATIONS);
          } else {
            this.setInputInvalid(InputInvalidReason.ARTICLE_NOT_FOUND);
            await this.playSound(CoreSounds.ARTICLE_NOT_FOUND);
          }
        } catch (e) {
          await this.changeState(InventoryStockState.ENTER_CODE);
        }
      } else if (items.length > 1) {
        await this.changeState(InventoryStockState.SELECT_ITEM_VARIATION);
        await this.playSound(CoreSounds.ARTICLE_VARIATIONS);
      } else if (items.length === 0) {
        this.setInputInvalid(InputInvalidReason.ARTICLE_NOT_FOUND);
        await this.playSound(CoreSounds.ARTICLE_NOT_FOUND);
      } else {
        await useHelpStore().show(HelpStoreErrors.UNKNOWN);
      }
    } catch (e) {
      console.error(e);
      this.setInputInvalid(InputInvalidReason.ARTICLE_NOT_FOUND);
      await this.playSound(CoreSounds.ARTICLE_NOT_FOUND);
    }
  }))

  search = action(async (code: string, {offset = 0, limit = 50} = {}) => {
    let response = [];
    try {
      response = await apiSearch({
        params: {
          code,
          limit,
          offset,
        },
      });

      if (isEmpty(response)) {
        response = await apiSearchMiddleware({
          params: {
            code,
            limit,
            offset,
          },
        });
      }

      const {result} = sanitizeApiSearch(response);

      return reject(result, ({documentItem, documentLogisticItem}) => {
        return !documentItem && !documentLogisticItem;
      });
    } catch (err) {
      console.error({err});
      this.catchNetworkError(err);
    }
  });

  searchItems = action(async (code: string, {offset = 0, limit = 50} = {}): Promise<Array<InventorySearchResult>> => {
    // get only documentLogisticItems and filter empty ones
    return flow([
      (results) => map(
        results,
        ({documentLogisticItem, documentItem, match}) => ({documentLogisticItem, documentItem, match}),
      ),
      (results) => filter(results, (result) => result),
    ])(await this.search(code, {offset, limit}));
  })

  saveDocument = action(async ({stockType}: {stockType: InventoryStockType}) => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const inventoryDocument = this.state.document;
      inventoryDocument.setStockType(stockType);

      const invalidItems = inventoryDocument.findInvalidLogisticItems();

      if (invalidItems.length) {
        await useHelpStore().show(InventoryStockErrors.INVALID_LOGISTIC_ITEMS, {
          params: {items: invalidItems.map((item) => item.description).join(', ')},
        });
        return false;
      }

      inventoryDocument.preflightSetup();

      await apiInventoryCreate({
        input: inventoryDocument.toApiClone(),
      });

      // TODO: Disabled for version 6.1 - make configurable for version 7.0
      // const shopAndPosCode = [
      //   inventoryDocument.header.shopCode,
      //   inventoryDocument.header.posCode,
      // ].join('/');

      // const fileName = [
      //   shopAndPosCode,
      //   inventoryDocument.header.uniqueidentifier,
      //   inventoryDocument.header.documentDate.toISOString(),
      // ].join('_');

      // await download(
      //   new File([JSON.stringify(inventoryDocument.toJson())], `${fileName}.json`, {type: 'application/json'}),
      // );

      this.resetState();
      emitTestEvent(TestEvent.INVENTORY_PDA_PRINTED, inventoryDocument.header.uniqueidentifier);
      return true;
    } catch (e) {
      console.error(e);
      this.catchNetworkError(e);
      throw e;
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
      await this.persist();
    }
  })

  solvePrinterStatus = action(async (result: any, document: DocumentDto) => {
    try {
      return await this.documentStatusStore.value.solve(result, document);
    } catch (e) {
      console.error(e);
    }
  })

  resetState = action(() => {
    this.state = Object.assign(this.state, createInitState());
  })

  resetInput = action(() => {
    this.state.inventoryStockInput = createInput();
  })

  enterEditRecord = action(async (
    logisticItem: DocumentLogisticItemDto,
    editExisting: boolean = false,
    quantityToAdd: number = 1,
  ) => {
    const itemToEdit = editExisting ?
      find(this.state.document.logisticItems, {internalNumberWithBatch: logisticItem.internalNumberWithBatch}) :
      logisticItem;

    this.resetInput();
    this.state.inventoryStockInput.item = itemToEdit;
    this.state.inventoryStockInput.value = editExisting ?
      itemToEdit.quantity + (quantityToAdd ?? 1) :
      itemToEdit.quantity;

    await this.changeState(InventoryStockState.ENTER_EDIT_RECORD);
    this.dispatchEvent(new CustomEvent(InventoryStockEvent.SCROLL_TO, {detail: itemToEdit.clone()}));
  });

  openProductDetail = action(async (
    logisticItem: DocumentLogisticItemDto, {canSelectVariation = false} = {},
  ) => {
    try {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const [documentItem] = sanitizeApiSearch(await this.search(logisticItem.gtin, {limit: 1})).documentItems;

      this.state.productDetail = {
        documentItem,
        canSelectVariation,
      };

      await this.changeState(InventoryStockState.ITEM_DETAIL);
    } catch (e) {
      console.error(e);
      this.catchNetworkError(e);
    } finally {
      this.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  })

  openProductSearch = action(async () => {
    await this.changeState(InventoryStockState.SEARCH_PRODUCTS);
  })

  closeProductDetail = action(async () => {
    await this.changeState(this.inventoryStockPreviousState.value);
    this.state.productDetail = null;
  })

  playSound = action(async (type: CoreSounds) => {
    const {playSoundEffect} = useSoundEffects();
    await playSoundEffect(type);
  })

  vibrate = action(() => {
    const {vibrate} = useVibrate();
    vibrate(75);
  })

  catchNetworkError = action((error) => {
    this.state.networkError = error.message === RequestErrors.network;
  })

  fractionDocumentLogisticItemMatches = action(
    (item: DocumentLogisticItemDto, searchStr: string, params: Array<keyof DocumentLogisticItemDto['_data']>) => {
      const itemData = item.toJson();

      return flow([
        (params) => map(params, (param) => param.toString()),
        (params) => map(params, (param) => itemData[param]),
        (values) => some(values, (value) => {
          const parts = ((value ?? '').toString()).split(/ /g);

          return some(parts, (part) => {
            const sanitizedPart = deburr(toLower(part));
            const sanitizedSearchStr = deburr(toLower(searchStr));

            return sanitizedPart.indexOf(sanitizedSearchStr) !== -1;
          });
        }),
      ])(params);
    },
  )

  setStockType = action((stockType: InventoryStockType) => {
    this.state.stockType = stockType;
  })

  fractionDocumentSearchResultByArticle = getter(() => {
    if (!this.isStateSearchDocumentByArticle.value) {
      return null;
    }

    const searchStr = this.state.inventoryStockInput.code;

    if (!this.state.fractionDocumentList) {
      return null;
    }

    if (isEmpty(searchStr) || searchStr.length < 3) {
      return null;
    }


    return flow(
      (referentialDocuments) => map(referentialDocuments, (referentialDocument, index) => ({
        index,
        referentialDocument,
        logisticItemMatches: filter(referentialDocument.transaction.document.logisticItems, (logisticItem) => {
          return this.fractionDocumentLogisticItemMatches(logisticItem, searchStr, [
            'description',
            'internalNumber',
            'gtin',
          ]);
        }),
      })),
      (results) => filter(results, ({logisticItemMatches}) => !!logisticItemMatches.length),
      (results) => map(results, ({referentialDocument, logisticItemMatches, index}) => {
        return map(logisticItemMatches, (logisticItem) => ({
          index,
          logisticItem,
          referentialDocument,
        }));
      }),
      (items) => flatten(items),
    )(this.state.fractionDocumentList);
  })

  commitCurrentInputArticle = action(async ({quantity = this.state.inventoryStockInput.value} = {}) => {
    const item = this.state.inventoryStockInput.item;

    item.quantity = quantity;

    this.state.document.processDocumentLogisticItemAddition(item);

    this.dispatchEvent(new CustomEvent(InventoryStockEvent.SCROLL_TO, {detail: item.clone()}));
    this.state.lastProcessedItem = item.clone();
    this.resetInput();
    await this.changeState(InventoryStockState.ENTER_CODE);
  });

  commitCurrentInputSetArticle = action(async ({quantity = this.state.inventoryStockInputSet.value} = {}) => {
    const documentItem = this.state.inventoryStockInputSet.item.clone();
    const expandedLogisticItem = this.state.inventoryStockInputSet.item.expandExpandableSet().toDocumentLogisticItem();

    const newQuantity = quantity * documentItem.prep;

    if (this.documentAlreadyContainsLogisticItem(expandedLogisticItem)) {
      const item = find(
        this.state.document.logisticItems,
        {internalNumberWithBatch: expandedLogisticItem.internalNumberWithBatch},
      );

      await this.updateExistingLogisticItemFromSet(item, newQuantity);
      return;
    }

    expandedLogisticItem.quantity = newQuantity;

    this.state.document.processDocumentLogisticItemAddition(expandedLogisticItem);

    this.dispatchEvent(new CustomEvent(InventoryStockEvent.SCROLL_TO, {detail: expandedLogisticItem.clone()}));
    this.state.lastProcessedItem = expandedLogisticItem.clone();
    this.enterEditRecord(expandedLogisticItem, true, 0);
  });

  updateExistingLogisticItemFromSet = action(async (item: DocumentLogisticItemDto, quantity: number) => {
    await this.playSound(CoreSounds.ARTICLE_ALREADY_IN_DOCUMENT);
    this.enterEditRecord(item, true, quantity);
  })

  inventoryStockState = getter(() => this.state.state)

  inventoryStockPreviousState = getter(() => this.state.previousState)

  inventoryStockInput = getter(() => this.state.inventoryStockInput)

  inventoryStockInputSet = getter(() => this.state.inventoryStockInputSet)

  loadedItems = getter(() => this.state.loadedItems)

  document = getter(() => this.state.document)

  productDetail = getter(() => this.state.productDetail)

  fractionDocumentSearchKeyboard = getter(() => this.state.fractionDocumentSearchKeyboard)

  isStateEnterCode = getter(() => this.state.state === InventoryStockState.ENTER_CODE)

  isStateEnterEditRecord = getter(() => this.state.state === InventoryStockState.ENTER_EDIT_RECORD)

  isStateEnterQuantity = getter(() => this.state.state === InventoryStockState.ENTER_QUANTITY)

  isStateSearchProducts = getter(() => this.state.state === InventoryStockState.SEARCH_PRODUCTS)

  isStateSearchDocumentByArticle = getter(() => this.state.state === InventoryStockState.SEARCH_DOCUMENT_BY_ARTICLE)

  documentStatusStore = getter(() => {
    return useDocumentStatusStore();
  })

  insertMode = getter(() => this.state.insertMode)

  networkError = getter(() => this.state.networkError)

  lastProcessedItem = getter(() => this.state.lastProcessedItem)

  stockType = getter(() => this.state.stockType)

  isDocumentInProgess = getter(() => this.state.document.logisticItems.length > 0)
}

export const useInventoryStockStore = createUseStore<typeof InventoryStockStore>(
  InventoryStockStore,
  'InventoryStockStore',
);
