import GeneratedCustomerReceiptDto from './generated/CustomerReceiptDto';
import {groupBy, mapKeys} from 'lodash-es';

export default class CustomerReceiptDto extends GeneratedCustomerReceiptDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get customerReceiptPointsByClubCode() {
    return groupBy(this.points, 'clubCode');
  }

  get customerReceiptPointsByMainClubCode() {
    const mainClubCode = CustomerReceiptDto.configurationStore.mainClubCode.value;

    return this.customerReceiptPointsByClubCode?.[mainClubCode] ?? [];
  }
}
