
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import FormInputNominal from '@/Components/FormInputNominal.vue';
import {useFilters} from '@/Helpers/filters';
import {HLScrollIntoView} from '@/Components/HLScrollIntoView';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import Button from '@/Components/Button.vue';
import {WorkflowStepNominalsCount} from '@/Modules/Workflow/Step/StepNominalsCount/WorkflowStepNominalsCount';
import InputBuffer from '@/Components/InputBuffer.vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';

export default defineComponent({
  name: 'StepNominalsCount',
  components: {
    InputBuffer,
    Button,
    WorkflowTransitions,
    HLScrollIntoView,
    FormInputNominal,
    HLStep,
    HLInput,
  },
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = workflowStore.currentWorkflow.value.activeStepType;
    const {currencyFormat} = useFilters();
    const {workflowStep} = useStepTools<WorkflowStepNominalsCount>(stepType);

    const manualMode = computed({
      get: () => {
        return workflowStep.value.manualMode;
      },
      set: (val) => {
        workflowStep.value.manualMode = val;
      },
    });

    return {
      manualMode,
      currencyFormat,
      stepType,
      WorkflowStepField,
    };
  },
});
