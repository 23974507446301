import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {
  ReferentialDocumentsActions,
  ReferentialDocumentsStateCommonFilterOptions,
  ReferentialDocumentsDocumentStatusFilterOptions,
  ReferentialDocumentsInputEvent,
  ReferentialDocumentsState,

} from '@/Modules/Apps/types';
import {BufferedInput} from '@/Modules/Register/services/KeyboardBuffer';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export interface IReferentialDocumentsStore {
  state: ReferentialDocumentsState,
  filters: {
    date: Date[],
    customerIdentifier: string,
    documentTypes: string,
    documentType: string,
    documentSubType: string,
    shop: string,
    pos: string,
    currency: string,
    articleIdentifier: string,
    documentStatus: string,
    vendor: string,
  },
  asyncOptions: {
    vendor: any[]
  }
}

const createFiltersInitState = () => ({
  date: [new Date(), new Date()],
  documentTypes: ReferentialDocumentsStateCommonFilterOptions.ALL_OPTIONS as string,
  documentType: ReferentialDocumentsStateCommonFilterOptions.ALL_OPTIONS as string,
  documentSubType: ReferentialDocumentsStateCommonFilterOptions.ALL_OPTIONS as string,
  shop: ReferentialDocumentsStateCommonFilterOptions.CONFIG_DEFAULT as string,
  pos: ReferentialDocumentsStateCommonFilterOptions.CONFIG_DEFAULT as string,
  documentStatus: ReferentialDocumentsDocumentStatusFilterOptions.NOT_CANCELED as string,
  articleIdentifier: '',
  customerIdentifier: '',
  currency: null,
  vendor: null,
});

const createInitState = (data: Partial<IReferentialDocumentsStore> = {}) => ({
  state: ReferentialDocumentsState.LIST_DATA,
  filters: createFiltersInitState(),
  asyncOptions: {
    vendor: [],
  },
  ...data,
});

export class ReferentialDocumentsStore extends Store<IReferentialDocumentsStore> {
  constructor() {
    super(createInitState());
  }

  transitions: {[key in ReferentialDocumentsState]?: {[key in ReferentialDocumentsActions]?: any}} = {
    [ReferentialDocumentsState.CHOSE_DATE]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.date = event.value;
      }),
    },
    [ReferentialDocumentsState.ENTER_ARTICLE_IDENTIFIER]: {
      [ReferentialDocumentsActions.ADD_NUMBER]: action((event: ReferentialDocumentsInputEvent) => {
        this.state.filters.articleIdentifier += event.value;
      }),
      [ReferentialDocumentsActions.ADD_CHAR]: action((event: ReferentialDocumentsInputEvent) => {
        this.state.filters.articleIdentifier += event.value;
      }),
      [ReferentialDocumentsActions.BACKSPACE]: action(async () => {
        this.state.filters.articleIdentifier = this.state.filters.articleIdentifier
          .slice(0, this.state.filters.articleIdentifier.length - 1);
      }),
    },
    [ReferentialDocumentsState.ENTER_CUSTOMER_IDENTIFIER]: {
      [ReferentialDocumentsActions.ADD_NUMBER]: action((event: ReferentialDocumentsInputEvent) => {
        this.state.filters.customerIdentifier += event.value;
      }),
      [ReferentialDocumentsActions.BACKSPACE]: action(async () => {
        this.state.filters.customerIdentifier = this.state.filters.customerIdentifier
          .slice(0, this.state.filters.customerIdentifier.length - 1);
      }),
    },
    [ReferentialDocumentsState.SELECT_DOCUMENT_TYPES]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.documentTypes = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_DOCUMENT_TYPE]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.documentType = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_SUB_DOCUMENT_TYPE]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.documentSubType = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_DOCUMENT_STATUS]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.documentStatus = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_SHOP]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.shop = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_POS]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.pos = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_CURRENCY]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.currency = event.value;
      }),
    },
    [ReferentialDocumentsState.SELECT_VENDOR]: {
      [ReferentialDocumentsActions.ENTER]: action((event: ReferentialDocumentsInputEvent) => {
        if (!event.value) return;
        this.state.filters.vendor = event.value;
      }),
    },
  }

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: ReferentialDocumentsActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: ReferentialDocumentsActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: ReferentialDocumentsActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: ReferentialDocumentsActions.CANCEL,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        await this.onEventInput({
          type: ReferentialDocumentsActions.ADD_CHAR,
          value: key.key,
        });
      }
    }
  });

  changeState = action((state: ReferentialDocumentsState) => {
    this.state.state = state;
    emitTestEvent(TestEvent.REFERENTIAL_DOCUMENTS_STATE_CHANGED);
    emitTestEvent(`${TestEvent.REFERENTIAL_DOCUMENTS_STATE_CHANGED}:${state}`);
  })

  resetFilters = action((state = createFiltersInitState()) => {
    this.state.filters = state;
  })

  onEventInput = action(async (event: ReferentialDocumentsInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  filters = getter(() => this.state.filters)

  isActiveStateListData = getter(() => {
    return this.state.state === ReferentialDocumentsState.LIST_DATA;
  })

  isActiveStateChoseDate = getter(() => {
    return this.state.state === ReferentialDocumentsState.CHOSE_DATE;
  })

  isActiveStateEnterArticleIdentifier = getter(() => {
    return this.state.state === ReferentialDocumentsState.ENTER_ARTICLE_IDENTIFIER;
  })

  isActiveStateEnterCustomerIdentifier = getter(() => {
    return this.state.state === ReferentialDocumentsState.ENTER_CUSTOMER_IDENTIFIER;
  })

  isActiveStateSelectCurrency = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_CURRENCY;
  })

  isActiveStateSelectDocumentTypes = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_DOCUMENT_TYPES;
  })

  isActiveStateSelectDocumentType = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_DOCUMENT_TYPE;
  })

  isActiveStateSelectSubDocumentType = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_SUB_DOCUMENT_TYPE;
  })

  isActiveStateSelectShop = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_SHOP;
  })

  isActiveStateSelectPos = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_POS;
  })

  isActiveStateSelectDocumentStatus = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_DOCUMENT_STATUS;
  })

  isActiveStateSelectVendor = getter(() => {
    return this.state.state === ReferentialDocumentsState.SELECT_VENDOR;
  })
}

const storeIdentifier = 'ReferentialDocumentsStore';

export const configureReferentialDocumentsStore = createConfigureStore<typeof ReferentialDocumentsStore>(
  storeIdentifier,
);
export const useReferentialDocumentsStore = createUseStore(ReferentialDocumentsStore, storeIdentifier);
