import {InventoryGroupDtoPropDef} from './generated/InventoryGroupDto';
import {createEntity, NumberType} from '@designeo/apibundle-js';

export default class InventoryGroupDto extends createEntity({
  ...InventoryGroupDtoPropDef,
  originalAllowedLostValue: {
    type: new NumberType(),
  },
}) {

}
