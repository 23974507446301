import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {
  reduce,
} from 'lodash-es';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';

export const createTransitions = function(this: RegisterStore) {
  return {
    [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [RegisterActions.ADD_CHAR]: action(async (event: RegisterInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [RegisterActions.ADD_MINUS]: action(async (event: RegisterInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [RegisterActions.ADD_PLUS]: action(async (event: RegisterInputEvent<string>) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = event.value;
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += event.value;
      }
    }),
    [RegisterActions.ADD_PERIOD]: action(async (event: RegisterInputEvent) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = '.';
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += '.';
      }
    }),
    [RegisterActions.ADD_COMMA]: action(async (event: RegisterInputEvent) => {
      if (this.state.insertMode) {
        this.state.inputBuffer = '.';
        this.state.insertMode = false;
      } else {
        this.state.inputBuffer += '.';
      }
    }),
    [RegisterActions.BACKSPACE]: action(async () => {
      this.state.inputBuffer = this.state.inputBuffer.slice(0, this.state.inputBuffer.length - 1);
      this.state.insertMode = false;
    }),
    [RegisterActions.CLEAR]: action(async (event: RegisterInputEvent) => {
      this.resetInputBuffer();
      this.state.insertMode = false;
    }),
    [RegisterActions.CANCEL]: action(async () => {
      await this.ensurePurchaseFlowReset();
    }),
    [RegisterActions.SKIP]: action(async () => {
      await this.resetToDefaultStep();
    }),
  };
};

export const getCommonTransitions = function(this: RegisterStore, actions: keyof ReturnType<typeof createTransitions>) {
  return reduce(actions, (acc, val) => {
    const transitions = createTransitions.call(this);

    if (!Object.prototype.hasOwnProperty.call(transitions, val)) return acc;

    return {
      ...acc,
      [val]: transitions[val],
    };
  }, {});
};


export type TransitionDefinition = {[key in RegisterState]?: { [key in RegisterActions]?: any }};
