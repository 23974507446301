import {
  isEmpty,
  isNil,
  values,
} from 'lodash-es';
import {
  ArrayType,
  createEntity,
  NumberType,
} from '@designeo/apibundle-js';
import {
  api3rdPartyKorunkaGenerateNumbers,
  api3rdPartyKorunkaTicketPossibleWins,
} from '@/Model/Action';
import {
  ActionBetTicket,
  ActionCancelTicketNumber,
  ActionRepeatBetTicket,
} from '../patches/KorunkaMixin';
import {guid} from '@/Helpers/guid';
import {getLetterFromIndex} from '@/Helpers/alphabetIndex';
import {Korunka} from '../../../../types/korunka';
import GeneratePossibleWinsCommand from '../GeneratePossibleWinsCommand';
import {KorunkaLotteryGameCommonProperties} from './KorunkaLotteryGameCommon';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  fetchKorunkaDraws,
  isKorunkaVersionV2,
  TicketPartnerResourceEntities,
} from '../../../Helpers/korunka';
import TicketPartnerResourceV2 from '../TicketPartnerResourceV2';

export default class KorunkaLotteryHopTropDtoCustom extends createEntity({
  randomNumbers: {
    type: new ArrayType(new NumberType()),
  },
  ...KorunkaLotteryGameCommonProperties,
}) {
  public static get configurationStore(): ReturnType<typeof useConfigurationStore> {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get drawsCount() {
    return this.draws.length;
  }

  get firstAndLastDraw() {
    if (this.draws.length === 1) return this.draws;

    const {0: firstDraw, [this.draws.length - 1]: lastDraw} = this.draws;
    return [firstDraw, lastDraw].filter(Boolean);
  }

  get total() {
    if (this.isCancel && !isNil(this.amountPaid)) {
      if (isKorunkaVersionV2()) {
        return this.amountPaidSum;
      }

      return this.amountPaid;
    }

    return this.drawsCount * (this.deposit?.value ?? 0);
  }

  get gameType(): Korunka['BetTicketPartnerResource']['gameType'] {
    return 'HOPTROP';
  }

  get isRepeatBetTicket() {
    return this.isRepeatBet && !isEmpty(this.repeatTicketIds);
  }

  async generateRandomNumbers({numbersCount = this.randomNumbers.length || 5} = {}) {
    const [result] = await api3rdPartyKorunkaGenerateNumbers({params: {
      numberCount: numbersCount,
      groups: 1,
    }});

    this._data.randomNumbers = result.groups[0];
  }

  async fetchDraws() {
    return await fetchKorunkaDraws();
  }

  async calculateMaxPossibleWin() {
    const input = new GeneratePossibleWinsCommand({
      korunkaRequest: {
        gameType: this.gameType,
        bet: this.deposit.value,
        numbers: this.randomNumbersToObject(),
        tutovka: this.tutovka,
      },
    });

    const {amountsToWin} = await api3rdPartyKorunkaTicketPossibleWins({input});

    // @ts-ignore - entity is objectType
    // we sort amountsToWin "table" to find the max prize from the most guessed numbers
    // most guessed numbers changes based on game type
    const [index, {max}] = Object.entries(amountsToWin)
      .sort(([a], [b])=> parseInt(a) - parseInt(b))
      .reverse()[0];

    this.maxPrizePerTicket = max;
    this.amountsToWin = amountsToWin;
    return max;
  }

  randomNumbersToObject() {
    return [this.randomNumbers].reduce((acc, numbers, index) => {
      return {...acc, [getLetterFromIndex(index)]: numbers};
    }, {});
  }

  toActionBetTicket() {
    return new ActionBetTicket({
      gameType: this.gameType,
      drawIds: this.draws.map((draw) => draw.id),
      amountPaid: this.deposit.value,
      amountPaidAll: this.total,
      numbers: this.randomNumbersToObject(),
      operationId: guid(),
    });
  }

  toActionRepeatBetTicket() {
    return new ActionRepeatBetTicket({
      ticketIds: this.repeatTicketIds,
      drawIds: this.draws.map((draw) => draw.id),
      operationId: guid(),
    });
  }

  toActionCancelTicket(ticketNumber) {
    return new ActionCancelTicketNumber({
      ticketNumber,
      operationId: guid(),
    });
  }

  public static toGameRepeat(ticket: TicketPartnerResourceEntities, id) {
    return new KorunkaLotteryHopTropDtoCustom({
      deposit: {
        value: ticket.depositValue,
      },
      repeatTicketIds: [ticket.id],
      randomNumbers: values(ticket.numbers)[0],
      isRepeatBet: true,
      id,
    });
  }

  public static toGameCancel(ticket: TicketPartnerResourceEntities, id) {
    return new KorunkaLotteryHopTropDtoCustom({
      isCancel: true,
      ticketNumber: ticket.ticketNumber,
      amountPaid: ticket.amountPaid,
      // TODO: Possibly refactor with better type checking ???
      ...(ticket instanceof TicketPartnerResourceV2 ? {amountPaidSum: ticket.amountPaidSum} : {}),
      id,
      ticketsToCancel: [
        {
          ticketNumber: ticket.ticketNumber,
        },
      ],
    });
  }
}
