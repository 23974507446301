import {
  CustomerAuthenticationState,
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {createTransitions, TransitionDefinition} from './helpers';
import {first, isNil} from 'lodash-es';
import {preventParallel} from '@/Helpers/promise';

const setDefaultPayment = async function(this: RegisterStore) {
  if (this.inputBuffer.value && !this.payment.value) {
    await this.changePayment(
      this.configurationStore.value.createPayment(),
      {value: this.inputBuffer.value, insertMode: this.state.insertMode},
    );
  }
};

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.SELECT_PAYMENT]: {
      [RegisterActions.BEFORE_ENTER]: action(async ({to, from}) => {
        const {
          maxSellDocumentAmountWithoutExternalArticles = null,
          maxSellDocumentAmount = null,
          minSellDocumentAmount = null,
        } = this.configuration.value?.features?.transactionLimits ?? {};

        if (this.state.sellDocument.isModeStorno) {
          return;
        }

        if (!isNil(maxSellDocumentAmount) && this.state.sellDocument.totalPrice > maxSellDocumentAmount) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.TRANSACTION_LIMIT_EXCEEDED, {
            detail: {
              value: this.state.sellDocument.totalPrice,
              limit: maxSellDocumentAmount,
            },
          }));
          return false;
        }

        if (
          !isNil(maxSellDocumentAmountWithoutExternalArticles) &&
          this.state.sellDocument.itemsNettoTotalValueWithoutExternal > maxSellDocumentAmountWithoutExternalArticles
        ) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.TRANSACTION_LIMIT_EXCEEDED, {
            detail: {
              value: this.state.sellDocument.itemsNettoTotalValueWithoutExternal,
              limit: maxSellDocumentAmountWithoutExternalArticles,
            },
          }));
          return false;
        }

        if (!isNil(minSellDocumentAmount) && this.state.sellDocument.totalPrice < minSellDocumentAmount) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.TRANSACTION_LIMIT_EXCEEDED, {
            detail: {
              value: this.state.sellDocument.totalPrice,
              limit: minSellDocumentAmount,
            },
          }));
          return false;
        }

        if (
          this.areCustomDataMandatory.value &&
            !this.areCustomDataFilled.value &&
            from !== RegisterState.ENTER_CUSTOM_DATA &&
            from !== RegisterState.ENTER_CUSTOM_DATA_QUESTION
        ) {
          await this.changeState(RegisterState.ENTER_CUSTOM_DATA_QUESTION);
          return false;
        }
      }),
      [RegisterActions.BEFORE_LEAVE]: action(async ({to}) => {
        try {
          if (this.state.sellDocument.balanceIsZero && await this.hasSellDocumentAlreadyBeenSaved()) {
            this.dispatchEvent(new Event(RegisterStoreErrors.FROZEN_RECEIPT));
            return false;
          }
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
          return false;
        }


        if (to === RegisterState.ENTER_ARTICLE_NUMBER) {
          this.promotionHookCancelPayment();

          await this.changeState(RegisterState.PAYMENTS_EXIT, {previousState: to});
          return false;
        }
      }),
      [RegisterActions.INIT]: action(async () => {
        const pendingInteraction = first(this.pendingPromoInteractions.value);

        if (pendingInteraction) {
          await this.triggerPromo(pendingInteraction);
          return;
        }

        if (this.state.sellDocument.isDocumentSubTypeAutomatedFollowUpDocument) {
          await this.validateTotalRemaining();
          return;
        }

        const currentPaymentOptions = this.configuration.value.getPaymentButtonsBySellDocument(this.state.sellDocument);

        /**
         * Uncomment if quickCall should not be reset after payment select
         */
        // if (!find(currentPaymentOptions, {paymentId: this.state.payment?.paymentID})) {
        //   this.setPayment(null);
        // }

        this.setPayment(null);

        if (!this.state.sellDocument.balanceIsZero) {
          this.state.inputBuffer = (
            await this.state.sellDocument.balanceByActiveDocumentPayment(
              this.state.payment ? this.state.payment : this.configurationStore.value.createPayment(),
            )
          ).toString(10);

          this.state.insertMode = true;
        }

        this.ensureCustomerDisplayState();
      }),
      [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent) => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (!this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        await setDefaultPayment.call(this);
        await (createTransitions.call(this)?.[RegisterActions.ADD_NUMBER](event));
      }),
      [RegisterActions.ADD_MINUS]: action(async (event: RegisterInputEvent) => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (!this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        await setDefaultPayment.call(this);
        await (createTransitions.call(this)?.[RegisterActions.ADD_MINUS](event));
      }),
      [RegisterActions.ADD_PERIOD]: action(async (event: RegisterInputEvent) => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (!this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        await setDefaultPayment.call(this);
        await (createTransitions.call(this)?.[RegisterActions.ADD_PERIOD](event));
      }),
      [RegisterActions.ADD_COMMA]: action(async (event: RegisterInputEvent) => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (!this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        await setDefaultPayment.call(this);
        await (createTransitions.call(this)?.[RegisterActions.ADD_COMMA](event));
      }),
      [RegisterActions.BACKSPACE]: action(async () => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (!this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        await (createTransitions.call(this)?.[RegisterActions.BACKSPACE]());
      }),
      [RegisterActions.CLEAR]: action(async (event: RegisterInputEvent) => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (!this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        await (createTransitions.call(this)?.[RegisterActions.CLEAR](event));
      }),
      [RegisterActions.CANCEL]: action(async () => {
        if (this.customerAuthentication.value) {
          return;
        }

        if (this.state.sellDocument.isModeFullStorno) {
          await this.toggleCancelMode();
        } else {
          await this.resetToDefaultStep();
        }
      }),
      [RegisterActions.ENTER]: action(preventParallel(async (event: RegisterInputEvent<number>) => {
        if (this.hasPendingUnverifiedPayment.value) {
          await this.ensureSellDocumentPaymentsFlow();
          return;
        }

        if (this.customerAuthentication.value) {
          return;
        }

        if (event.value && !this.state.sellDocument.partialPaymentsAreAllowed) {
          return;
        }

        if (event.value) {
          if (this.state.insertMode) {
            this.state.inputBuffer = event.value.toString();
            this.state.insertMode = false;
          } else {
            event.value = /^-/.test(this.state.inputBuffer) ? -event.value : event.value;

            this.state.inputBuffer = ((parseFloat(this.state.inputBuffer) || 0) + event.value).toString();
          }
          return;
        }

        if (this.state.sellDocument.balanceIsZero) {
          this.resetInputBuffer();
          await this.saveSellDocument();
          return;
        }

        if (!this.state.payment) {
          return;
        }

        if (!(/^-?\d+(?:[.]\d+)?$/.test(this.state.inputBuffer))) {
          this.dispatchEvent(new Event(RegisterStoreErrors.INVALID_PAYMENT_VALUE));
          return;
        }

        await this.processPayment();
      })),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        if (this.customerAuthenticationState.value === CustomerAuthenticationState.VERIFY_BY_CARD) {
          await this.verifyCustomerCard(event.value);
        }
      }),
    },
  };
}
