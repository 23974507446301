import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowStepEvents,
  WorkflowInputEvent,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowStepErrors,

} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {apiCustomersVerifyPhoneByPin, apiCustomersVerifyPin} from '@/Model/Action';
import {PinDto} from '@/Model/Entity';
import {markRaw} from 'vue';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';

export class WorkflowStepEnterCustomerPin extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterCustomerPin;
  }

  get type() {
    return WorkflowStepEnterCustomerPin.type;
  }

  get component() {
    return markRaw(require('./StepEnterCustomerPin.vue').default);
  }

  get customerNumber() {
    return this.data[WorkflowStepField.customerNumber] ?? this.params.customerNumber;
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.cardNumber, () => ({
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent<string>) => {
          if (this.stepFinished) {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
            return;
          }

          if (event.value) {
            try {
              const verificationMethod = this.step.finishRegistration ?
                apiCustomersVerifyPhoneByPin :
                apiCustomersVerifyPin;

              await verificationMethod({
                input: new PinDto({
                  pin: event.value,
                }),
                params: {
                  customerNumber: this.customerNumber,
                },
              });

              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.SET_REFERER_QUERY_ARGS, {
                detail: {
                  customerNumber: this.customerNumber,
                },
              }));

              this.stepFinished = true;
              this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
            } catch (e) {
              broadcastIO.postMessage(BroadcastIOChannels.PIN_VERIFICATION_FAILED, e);
              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                detail: {
                  value: e,
                },
              }));
              console.error(e);
            }
          }
        }),
      })),
    };
  }
}
