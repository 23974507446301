import {map} from 'lodash-es';
import {Theme} from '@/constants/theme';

export const switchTheme = (theme: Theme) => {
  if (!theme) return;

  theme = theme.toLowerCase() as Theme;

  for (const classItem of map(document.body.classList)) {
    if (/^theme-/.test(classItem ?? '')) {
      document.body.classList.remove(classItem);
    }
  }

  document.body.classList.add(`theme-${theme}`);
};
