type Fn = (...args: any)=> any

export function latestPromiseResolver<T extends((...args: any)=> Promise<any>)>(fn: T): T {
  let activePromise: Promise<any> = null;
  let callbacks = [];


  const wrapper = async (...args) => {
    const promise = fn(...args);

    activePromise = promise;

    try {
      await promise;
    } catch (e) {
      console.error(e);
      // this is bypassed intentionally, next await will rethrow it if it is last promise in queue
    }

    return new Promise((resolve, reject) => {
      callbacks.push({resolve, reject});

      promise
        .then((result) => {
          if (activePromise !== promise) {
            return;
          }

          for (const {resolve} of callbacks) {
            resolve(result);
          }
        })
        .catch((err) => {
          if (activePromise !== promise) {
            return;
          }

          for (const {reject} of callbacks) {
            reject(err);
          }
        })
        .finally(() => {
          if (activePromise !== promise) {
            return;
          }

          activePromise = null;
          callbacks = [];
        });
    });
  };

  return wrapper as T;
}


/**
 * wait for function to finish before letting other function call it again
 */
export function preventParallel<T extends Fn>(fn: T): T {
  let isInProgress = false;
  let result: ReturnType<T>;
  // @ts-ignore
  return (...args: Parameters<T>) => {
    if (!isInProgress) {
      isInProgress = true;
      // @ts-ignore
      result = fn(...args);
      Promise.resolve(result).finally(() => {
        isInProgress = false;
      });
    }
    return result;
  };
}

/**
 * delay function call until previous call is finished
 * @param fn
 * @returns
 */
export function serialized<T extends Fn>(fn: T): T {
  let lastPromise: Promise<any> = Promise.resolve();
  return ((...args: Parameters<T>) => {
    lastPromise = lastPromise.finally(() => fn(...[].concat(args)));
    return lastPromise as ReturnType<T>;
  }) as T;
}
