import {
  createEntity,
  EntityType,
} from '@designeo/apibundle-js';
import DocumentItemDto from '../DocumentItemDto';
import StockDto from '../StockDto';

export default class BalanceStockItemDtoCustom extends createEntity({
  stock: {
    type: new EntityType(StockDto),
  },
  documentItem: {
    type: new EntityType(DocumentItemDto),
  },
}) {

}
