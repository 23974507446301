
import AsyncButton from '@/Components/AsyncButton.vue';
import {OrderDtoOrderEnvelopeDto} from '@/Model/Entity';
import {printEshopOrder} from '@/Modules/Apps/EshopOrders/eshopOrders';
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  components: {AsyncButton},
  props: {
    order: {
      type: Object as PropType<OrderDtoOrderEnvelopeDto>,
      required: true,
    },
  },
  setup(props) {
    const onPrint = async () => {
      await printEshopOrder(props.order);
    };

    return {
      onPrint,
    };
  },
});
