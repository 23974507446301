import {createEntity} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const ValidatePartnerSessionResponsePropDef = {
  deadline: {
    type: new DateTimeType(),
  },
};
export default class GeneratedValidatePartnerSessionResponse extends createEntity(ValidatePartnerSessionResponsePropDef) {}
