import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import HelpTypes from '@/constants/helpTypes';
import {RegisterStoreErrors} from '@/Modules/Register/types';
import {FourEyesErrors} from '@/Modules/Core/types';
import {InventoryErrors} from '@/Modules/Inventory/types';

export enum HelpStoreErrors {
  UNKNOWN = 'unknown',
  NETWORK = 'network',
}

export interface IHelpStore {
  error: {
    promise: Promise<boolean>,
    id: string,
    params: any,
    description: string,
  },
  resolve: (value?: boolean)=> void,
}

const createInitState = (data?: Partial<IHelpStore>) => ({
  error: null,
  resolve: null,
});

export class HelpStore extends Store<IHelpStore> {
  constructor() {
    super(createInitState());
  }

  close = action(() => {
    this.state.resolve?.();
  })

  getErrorId = action((errorType) => ({
    [HelpStoreErrors.UNKNOWN]: HelpTypes.UNKNOWN,
    [RegisterStoreErrors.NO_PRODUCTS_FOUND]: HelpTypes.REGISTER_STORE_NO_PRODUCTS_FOUND,
    [RegisterStoreErrors.CHECK_AND_CHARGE_FAILED]: HelpTypes.CHECK_AND_CHARGE_ERROR,
    [FourEyesErrors.REQUEST]: HelpTypes.FOUR_EYES_REQUEST,
    [FourEyesErrors.PROCESS]: HelpTypes.FOUR_EYES_PROCESS,
    [FourEyesErrors.SAME_OPERATOR]: HelpTypes.FOUR_EYES_SAME_OPERATOR,
    [InventoryErrors.INVENTORY_INVALID_GROUP]: HelpTypes.INVENTORY_INVALID_GROUP,
    [InventoryErrors.ARTICLE_IS_TYPE_SET]: HelpTypes.ARTICLE_IS_TYPE_SET,
    [InventoryErrors.ARTICLE_IS_NOT_PART_OF_ITEM_GROUP]: HelpTypes.ARTICLE_IS_NOT_PART_OF_ITEM_GROUP,
  })[errorType] || errorType)

  show = action((errorId, {params = {}, description = null} = {}) => {
    let resolve = null;

    const promise = (new Promise<boolean>((onFulfilled) => {
      resolve = () => onFulfilled(true);
    }))
      .then((result) => {
        this.state.error = null;
        this.state.resolve = null;
        return result;
      })
      .catch((err) => {
        this.state.error = null;
        this.state.resolve = null;
        throw err;
      });

    this.state.error = {
      id: errorId,
      promise,
      params,
      description,
    };

    this.state.resolve = resolve;

    return promise;
  })

  resetState = action(() => {
    this.state = Object.assign(this.state, createInitState());
  })

  error = getter(() => {
    return this.state.error;
  })

  isActive = getter(() => {
    return !!this.state.error;
  })
}

const storeIdentifier = 'HelpStore';

export const configureHelpStore = createConfigureStore<typeof HelpStore>(storeIdentifier);
export const useHelpStore = createUseStore(HelpStore, storeIdentifier);
