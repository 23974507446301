import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col gap-2 mt-2 bg-white rounded-md p-4" }
const _hoisted_3 = { class: "font-bold text-xl" }
const _hoisted_4 = { class: "text-danger font-bold mt-4 text-center" }
const _hoisted_5 = { class: "flex flex-col items-start gap-4 p-4" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "flex flex-row justify-between items-center" }
const _hoisted_8 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_ModalNumbers = _resolveComponent("ModalNumbers")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.drawsDates) + ": " + _toDisplayString(_ctx.drawsDates), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.drawsDates,
          void 0,
          { drawsDates: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.ticketCount) + ": " + _toDisplayString(_ctx.entity?.ticketCount), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.ticketCount,
          void 0,
          { ticketCount: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.drawsCount) + ": " + _toDisplayString(_ctx.entity?.drawsCount), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.drawsCount,
          void 0,
          { drawsCount: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.maxPrizePerTicket) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.maxPrizePerTicket ?? 0 )), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.maxPrizePerTicket ?? 0,
          void 0,
          { maxPrizePerTicket: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.total) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.total ?? 0)), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.total ?? 0,
          void 0,
          { total: true }
        ]
      ])
    ]),
    _createElementVNode("button", {
      class: "btn btn-basic btn-outline-basic mt-4 mx-auto w-4/12",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNumbersModal && _ctx.openNumbersModal(...args)))
    }, _toDisplayString(_ctx.workflowStep.messages.showNumbers), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.workflowStep.messages.waitingForCustomerConfirm), 1),
    _createVNode(_component_ModalNumbers, {
      title: _ctx.workflowStep.messages.modalNumbersTitle,
      isOpen: _ctx.workflowStep.numbersModalIsOpen,
      onClose: _ctx.closeNumbersModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTicketEntity?.randomNumbers, (randomNumbersRow, rowIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: rowIndex,
              class: "grid grid-cols-7 gap-4"
            }, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(rowIndex + 1) + ". ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(randomNumbersRow, (number, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "korunka-circle-number korunka-circle-number--sm"
                }, _toDisplayString(number), 1))
              }), 128))
            ]))
          }), 128))
        ])
      ]),
      paginator: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: "btn shadow-md",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPreviousTicket && _ctx.onPreviousTicket(...args)))
          }, [
            _createVNode(_component_PhIcon, { icon: "PhCaretLeft" })
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.interpolate(_ctx.workflowStep.messages.ticketNumber, {number: _ctx.entity.activeTicketIndex + 1})), 1),
          _createElementVNode("button", {
            class: "btn shadow-md",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onNextTicket && _ctx.onNextTicket(...args)))
          }, [
            _createVNode(_component_PhIcon, { icon: "PhCaretRight" })
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isOpen", "onClose"])
  ]))
}