import {DateTime} from 'luxon';
import {isDate, isString} from 'lodash-es';

type DateValue = DateTime|Date|String

export const useDateTime = (format) => {
  const toDateTime = (value: DateValue) => {
    if (isDate(value)) {
      return DateTime.fromJSDate(value);
    } else if (isString(value) && DateTime.fromFormat(value, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'').isValid) {
      return DateTime.fromJSDate(new Date(value));
    } else if (isString(value)) {
      return DateTime.fromFormat(value, format);
    }

    return value as DateTime;
  };
  const formatDate = (value: DateValue) => {
    return toDateTime(value)?.toFormat(format);
  };
  const toJSDate = (value: DateValue) => {
    return toDateTime(value)?.toJSDate();
  };

  return {
    toDateTime,
    formatDate,
    toJSDate,
  };
};

export const isValidDateTime = (date: Date) => {
  return !isNaN(date.getTime());
};
