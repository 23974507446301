import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleLabel from '../ArticleLabel';


export default class GeneratedArticleLabels extends createEntity({
  action: {
    type: new EntityType(ArticleLabel),
  },
  itemStornoDisabled: {
    type: new EntityType(ArticleLabel),
  },
  documentStornoDisabled: {
    type: new EntityType(ArticleLabel),
  },
  itemReturnDisabled: {
    type: new EntityType(ArticleLabel),
  },
  netto: {
    type: new EntityType(ArticleLabel),
  },
  salePackage: {
    type: new EntityType(ArticleLabel),
  },
  sellout: {
    type: new EntityType(ArticleLabel),
  },
  setDisplayType: {
    type: new EntityType(ArticleLabel),
  },
  taxStamp: {
    type: new EntityType(ArticleLabel),
  },
  batch: {
    type: new EntityType(ArticleLabel),
  },
}) {}
