
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  components: {PhIcon},
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
      required: false,
      default: 'md',
    },
    active: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const onClick = () => {
      emit('update:modelValue', !props.modelValue);
    };

    const sizeAsNumber = computed(() => {
      return {
        xs: 8,
        sm: 12,
        md: 16,
        lg: 20,
      }[props.size];
    });

    return {onClick, sizeAsNumber};
  },
});
