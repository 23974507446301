import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {ContextualProductRecommendationsSelectionFlow}
  from '../../PromoInteractionFlow/ContextualProductRecommendationsSelectionFlow';


export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.CONTEXTUAL_PRODUCT_RECOMMENDATION_SELECTION]: {
      [RegisterActions.INIT]: action(async ({
        recommendations,
        previousState,
      }) => {
        const nextStep = previousState;

        await this.withAppLoader(async () => {
          const promoFlow = await ContextualProductRecommendationsSelectionFlow.new(
            recommendations,
            nextStep,
          );

          await this.setPromoFlow(promoFlow);
          await this.persist();
        });
      }),
    },
  };
}
