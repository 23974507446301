import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const VerifyOrderPinResultDtoPropDef = {
  isOk: {
    type: new BooleanType(),
  },
};
export default class GeneratedVerifyOrderPinResultDto extends createEntity(VerifyOrderPinResultDtoPropDef) {}
