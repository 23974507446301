import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "text-sm leading-4" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('workflow.steps.eshopOrderOverview.order', {
            orderNumber: _ctx.order.data.orderNumber,
            code: _ctx.order.data.eshop.code,
          })), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('workflow.steps.eshopOrderOverview.date')) + ": " + _toDisplayString(_ctx.dateTimeFormat(_ctx.order.data.orderDate)), 1),
      (_ctx.order.data.customer?.firstName || _ctx.order.data.customer?.lastName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('workflow.steps.eshopOrderOverview.customer')) + ": " + _toDisplayString(_ctx.order.data.customer.firstName) + " " + _toDisplayString(_ctx.order.data.customer.lastName), 1))
        : _createCommentVNode("", true),
      (_ctx.order.data.customer?.email)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('workflow.steps.eshopOrderOverview.email')) + ": " + _toDisplayString(_ctx.order.data.customer.email), 1))
        : _createCommentVNode("", true),
      (_ctx.order.data.customer?.phoneNumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('workflow.steps.eshopOrderOverview.phone')) + ": " + _toDisplayString(_ctx.phoneFormat(_ctx.order.data.customer.phoneNumber)), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.showStatus)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "btn btn-sm border-0 text-white text-2xs mx-2",
            style: _normalizeStyle({
          backgroundColor: _ctx.orderStatusColor(_ctx.order.data).backgroundColor,
        })
          }, _toDisplayString(_ctx.translateOrderStatus(_ctx.order.data)), 5))
        : _createCommentVNode("", true)
    ])
  ]))
}