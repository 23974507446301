import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const CounterDtoPropDef = {
  count: {
    type: new NumberType(),
  },
};
export default class GeneratedCounterDto extends createEntity(CounterDtoPropDef) {}
