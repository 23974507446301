import {
  createRouter,
  Router,
  createWebHashHistory,
} from 'vue-router';
import {App} from 'vue';
import {IRestoredErrorRecord} from '@/Helpers/restoreMode';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = import('../ModuleSystem').ModuleSystem & {
  app: App<any>
  isRestoreMode?: boolean,
  restoredErrorRecord?: IRestoredErrorRecord
}

export type RegisterRouterContext = {
  router: Router,
}

export async function registerRouter(ctx: CoreRequiredContext) {
  const router = createRouter({
    history: createWebHashHistory('/'),
    routes: ctx.getRegisteredRoutes(),
  });

  if (ctx.isRestoreMode) {
    console.warn('[RestoreMode] currentRoute restored');
    await router.replace(ctx.restoredErrorRecord?.state?.currentRoute);
  }

  for (const beforeEachHook of ctx.getHooks()?.beforeEach ?? []) {
    router.beforeEach(beforeEachHook);
  }

  for (const afterEachHook of ctx.getHooks()?.afterEach ?? []) {
    router.afterEach(afterEachHook);
  }

  return {
    router,
  };
}
