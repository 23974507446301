import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BetTicketPartnerResource from '../BetTicketPartnerResource';
import FacadeRepeatBetTicketPartnerResourceV2 from '../FacadeRepeatBetTicketPartnerResourceV2';
import BetPreparedTicketPartnerResource from '../BetPreparedTicketPartnerResource';
import CancelTicketNumberResource from '../CancelTicketNumberResource';
import FacadePayOutTicketRequestResource from '../FacadePayOutTicketRequestResource';
import {NumberType} from '@designeo/apibundle-js';


export const FacadeFinishPartnerSessionRequestV2PropDef = {
  requestId: {
    type: new StringType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  betTickets: {
    type: new ArrayType(new EntityType(BetTicketPartnerResource)),
  },
  repeatBetTickets: {
    type: new ArrayType(new EntityType(FacadeRepeatBetTicketPartnerResourceV2)),
  },
  preparedTickets: {
    type: new ArrayType(new EntityType(BetPreparedTicketPartnerResource)),
  },
  cancelTicketNumbers: {
    type: new ArrayType(new EntityType(CancelTicketNumberResource)),
  },
  payOutTickets: {
    type: new ArrayType(new EntityType(FacadePayOutTicketRequestResource)),
  },
  finallyClearedCash: {
    type: new NumberType(),
  },
};
export default class GeneratedFacadeFinishPartnerSessionRequestV2 extends createEntity(FacadeFinishPartnerSessionRequestV2PropDef) {}
