import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {isEmpty, reduce} from 'lodash-es';
import {apiDocumentGetList} from '@/Model/Action';
import {ReferentialDocumentsDto} from '@/Model/Entity';

export class WorkflowStepSafeBagValidate extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagValidate;
  }

  get type() {
    return WorkflowStepSafeBagValidate.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagValidate.vue').default);
  }

  get safeBagEnvelopeCode() {
    return this.getFieldValue(WorkflowStepField.safeBagEnvelopeCode);
  }

  get disabledNextStep() {
    return !this.safeBagEnvelopeCode;
  }

  async fetchAndValidateSafeBag(): Promise<ReferentialDocumentsDto | undefined> {
    const [safebag] = await apiDocumentGetList({params: {
      limit: 1,
      externalReference: this.safeBagEnvelopeCode,
    }});

    if (!safebag) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.SAFEBAG_DOESNT_EXIST,
        },
      }));

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
      return;
    }

    const referentialDocumentsIsEmpty = isEmpty(safebag.referentialDocuments);

    if (!referentialDocumentsIsEmpty) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.SAFEBAG_ALREADY_SENT,
        },
      }));

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
      return;
    }

    return safebag;
  }

  async beforeContinue() {
    try {
      const safebag = await this.fetchAndValidateSafeBag();

      if (safebag) {
        this.dataSetter(WorkflowStepField.safeBagDocument, () => safebag.transaction.document.toJson() );
      }
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    }
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.safeBagEnvelopeCode, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_CHAR,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.SCANNER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ENTER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
