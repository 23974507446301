
import {
  computed,
  defineComponent,
} from 'vue';
import {useRouter} from 'vue-router';
import PhIcon from '@/Components/PhIcon.vue';
import {useEshopOrdersNotifications} from '../eshopOrders';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';

export default defineComponent({
  components: {
    PhIcon,
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const {
      isEnabled,
      orderNotification,
    } = useEshopOrdersNotifications();


    const isEshopOrdersPage = computed(() => router.currentRoute.value.name === 'apps-eshop-orders');

    const unfulfilledOrders = computed(() => {
      return orderNotification.value?.totalCount ?? null;
    });

    const isVisible = computed(() => {
      if (authStore.isStateTypeQuickLoginConfirm.value) {
        return false;
      }

      return isEnabled.value;
    });

    return {
      isEshopOrdersPage,
      isVisible,
      unfulfilledOrders,
    };
  },
});
