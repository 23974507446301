import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(workflowStep.step.documentNumber), 1),
            _createTextVNode(": " + _toDisplayString(workflowStep?.safeBag?.header?.envelopeNumber), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(workflowStep.step.date), 1),
            _createTextVNode(": " + _toDisplayString(_ctx.dateFormat(workflowStep?.safeBag?.header?.documentDate ?? _ctx.todayDate)), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(workflowStep.step.sum), 1),
            _createTextVNode(": " + _toDisplayString(_ctx.currencyFormat(
            workflowStep?.safeBag?.header?.total,
            workflowStep?.safeBag?.header?.currency
          )), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_WorkflowTransitions, {
            nextDisabled: workflowStep.disabledNextStep
          }, null, 8, ["nextDisabled"])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}