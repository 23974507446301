import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';

export class WorkflowStepLotterySuccess extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.LotterySuccess;
  }

  get type() {
    return WorkflowStepLotterySuccess.type;
  }

  get component() {
    return markRaw(require('./StepLotterySuccess.vue').default);
  }

  get amount() {
    return this.getFieldValue(WorkflowStepField.lotteryDocumentTotalAmount);
  }

  get transitions() {
    return {};
  }
}
