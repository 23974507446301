import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PaperNearEndType from '../PaperNearEndType';
import NoYes from '../NoYes';
import {ArrayType} from '@designeo/apibundle-js';
import DialogDto from '../DialogDto';
import {BooleanType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import VATDto from '../VATDto';
import OutputDto from '../OutputDto';
import DocumentPrintoutDto from '../DocumentPrintoutDto';
import FiscalResultDto from '../FiscalResultDto';


export const ResultDtoPropDef = {
  culture: {
    type: new StringType(),
  },
  ipAddress: {
    type: new StringType(),
  },
  port: {
    type: new NumberType(),
  },
  paperNearEnd: {
    type: new EntityType(PaperNearEndType),
  },
  documentNumber: {
    type: new StringType(),
  },
  dayIdentifier: {
    type: new StringType(),
  },
  journalNumber: {
    type: new StringType(),
  },
  printerDate: {
    type: new StringType(),
  },
  training: {
    type: new EntityType(NoYes),
  },
  operation: {
    type: new StringType(),
  },
  uniqueIdentifier: {
    type: new StringType(),
  },
  displayBase64: {
    type: new StringType(),
  },
  documentBarcode: {
    type: new StringType(),
  },
  dialog: {
    type: new ArrayType(new EntityType(DialogDto)),
  },
  statusCode: {
    type: new NumberType(),
  },
  errorText: {
    type: new StringType(),
  },
  errorKey: {
    type: new StringType(),
  },
  shouldProceedAgain: {
    type: new BooleanType(),
  },
  surelyNOTPrinted: {
    type: new BooleanType(),
  },
  translation: {
    type: new StringType(),
  },
  description: {
    type: new StringType(),
  },
  date: {
    type: new DateTimeType(),
  },
  vatValues: {
    type: new ArrayType(new EntityType(VATDto)),
  },
  vatValuesDiff: {
    type: new ArrayType(new EntityType(VATDto)),
  },
  printingTime: {
    type: new StringType(),
  },
  output: {
    type: new ArrayType(new EntityType(OutputDto)),
  },
  warningCode: {
    type: new NumberType(),
  },
  trxInfo: {
    type: new StringType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  aid: {
    type: new StringType(),
  },
  cardType: {
    type: new StringType(),
  },
  terminalID: {
    type: new StringType(),
  },
  responseMessage: {
    type: new StringType(),
  },
  authorizationCode: {
    type: new StringType(),
  },
  payAuthorizationCode: {
    type: new StringType(),
  },
  sequenceNumber: {
    type: new StringType(),
  },
  variableSymbol: {
    type: new StringType(),
  },
  cardHash: {
    type: new StringType(),
  },
  resultTID: {
    type: new StringType(),
  },
  pin: {
    type: new StringType(),
  },
  signatureCheck: {
    type: new BooleanType(),
  },
  reasonNotAccepted: {
    type: new StringType(),
  },
  transactionDate: {
    type: new DateTimeType(),
  },
  referenceNumber: {
    type: new StringType(),
  },
  printout: {
    type: new EntityType(DocumentPrintoutDto),
  },
  fiscal: {
    type: new ArrayType(new EntityType(FiscalResultDto)),
  },
};
export default class GeneratedResultDto extends createEntity(ResultDtoPropDef) {}
