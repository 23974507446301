
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import {WorkflowActions, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {useWorkflowStore} from '../../store/WorkflowStore';
import AsyncButton from '@/Components/AsyncButton.vue';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepBTCConditionsAgreement',
  components: {WorkflowTransitions, HLStep, AsyncButton},
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.BTCConditionsAgreement;

    const repeatRequestIsVisible = ref(false);

    const {
      abortWorkflow,
    } = useStepTools(workflowStore.currentWorkflow.value.activeWorkflowStep.type);


    const onCustomerBTCConfirmResult = async (event: CustomEvent) => {
      try {
        repeatRequestIsVisible.value = !event.detail;
        await workflowStore.onEventInput({
          type: WorkflowActions.ENTER,
          value: event.detail,
        });

        broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      } catch (e) {
        console.error(e);
      }
    };

    const repeatRequest = async () => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-btc-confirm'});
      repeatRequestIsVisible.value = false;
    };

    onMounted(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-btc-confirm'});
      broadcastIO.addEventListener(BroadcastIOChannels.BTC_CONFIRM_RESULT, onCustomerBTCConfirmResult);
    });

    onBeforeUnmount(() => {
      broadcastIO.removeEventListener(BroadcastIOChannels.BTC_CONFIRM_RESULT, onCustomerBTCConfirmResult);
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
    });

    return {
      stepType,
      repeatRequestIsVisible,
      repeatRequest,
      abortWorkflow,
    };
  },
});
