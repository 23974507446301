import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ShipmentNotificationStatusEnum from '../ShipmentNotificationStatusEnum';
import FiscalCommands from '../FiscalCommands';
import {DateTimeType} from '@designeo/apibundle-js';
import DocumentDto from '../DocumentDto';


export const ShipmentNotificationDetailDtoPropDef = {
  id: {
    type: new StringType(),
  },
  status: {
    type: new EntityType(ShipmentNotificationStatusEnum),
  },
  documentSubType: {
    type: new EntityType(FiscalCommands),
  },
  documentDate: {
    type: new DateTimeType(),
  },
  supplierName: {
    type: new StringType(),
  },
  distributionCenter: {
    type: new StringType(),
  },
  vendor: {
    type: new StringType(),
  },
  reference: {
    type: new StringType(),
  },
  documentJson: {
    type: new EntityType(DocumentDto),
  },
};
export default class GeneratedShipmentNotificationDetailDto extends createEntity(ShipmentNotificationDetailDtoPropDef) {}
