import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CreateCustomerAttributeDtoPropDef = {
  key: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
};
export default class GeneratedCreateCustomerAttributeDto extends createEntity(CreateCustomerAttributeDtoPropDef) {}
