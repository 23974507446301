import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Theme = _resolveComponent("Theme")!
  const _component_InactivityBar = _resolveComponent("InactivityBar")!
  const _component_ModalFourEyes = _resolveComponent("ModalFourEyes")!
  const _component_ModalHelp = _resolveComponent("ModalHelp")!
  const _component_ModalConfirm = _resolveComponent("ModalConfirm")!
  const _component_ModalUpdateState = _resolveComponent("ModalUpdateState")!
  const _component_ModalIncorrectRestart = _resolveComponent("ModalIncorrectRestart")!
  const _component_ModalInactivity = _resolveComponent("ModalInactivity")!
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_ModalPosDeactivatedState = _resolveComponent("ModalPosDeactivatedState")!
  const _component_ModalPosCoreDisconnectState = _resolveComponent("ModalPosCoreDisconnectState")!
  const _component_ModalPrinterServiceDisconnectState = _resolveComponent("ModalPrinterServiceDisconnectState")!
  const _component_ModalCrashState = _resolveComponent("ModalCrashState")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_router_view, null, null, 512), [
      [
        _directive_test,
        _ctx.$route.fullPath,
        void 0,
        { pageFullPath: true }
      ],
      [
        _directive_test,
        _ctx.$route.name,
        void 0,
        { pageName: true }
      ],
      [
        _directive_test,
        'incorrectRestart',
        void 0,
        { appVersion: true }
      ]
    ]),
    _createVNode(_component_Theme),
    _createVNode(_component_InactivityBar),
    _createVNode(_component_ModalFourEyes),
    _createVNode(_component_ModalHelp),
    _createVNode(_component_ModalConfirm),
    _createVNode(_component_ModalUpdateState),
    _createVNode(_component_ModalIncorrectRestart),
    _createVNode(_component_ModalInactivity),
    _createVNode(_component_AppLoader),
    _createVNode(_component_ModalPosDeactivatedState),
    _createVNode(_component_ModalPosCoreDisconnectState),
    _createVNode(_component_ModalPrinterServiceDisconnectState),
    _createVNode(_component_ModalCrashState)
  ], 64))
}