import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerSex from '../CustomerSex';
import {DateTimeType} from '@designeo/apibundle-js';
import CustomerState from '../CustomerState';
import {ArrayType} from '@designeo/apibundle-js';
import CustomerAttributeDto from '../CustomerAttributeDto';
import CustomerAccountDto from '../CustomerAccountDto';
import CustomerContactDto from '../CustomerContactDto';
import CustomerCardDto from '../CustomerCardDto';
import AccountTransactionPreviewDto from '../AccountTransactionPreviewDto';
import CustomerAccountExpirationPredictionDto from '../CustomerAccountExpirationPredictionDto';
import CustomerAccountPointsHistoryDto from '../CustomerAccountPointsHistoryDto';
import PreviousMonthPointsExpirationDto from '../PreviousMonthPointsExpirationDto';


export const DocumentCustomerDtoPropDef = {
  id: {
    type: new StringType(),
  },
  customerNumber: {
    type: new StringType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  cardType: {
    type: new StringType(),
  },
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
  yearOfBirth: {
    type: new NumberType(),
  },
  dayOfBirth: {
    type: new NumberType(),
  },
  monthOfBirth: {
    type: new NumberType(),
  },
  sex: {
    type: new EntityType(CustomerSex),
  },
  gdprConsentDate: {
    type: new DateTimeType(),
  },
  gdprConsentPlace: {
    type: new StringType(),
  },
  phoneVerificationDate: {
    type: new DateTimeType(),
  },
  emailVerificationDate: {
    type: new DateTimeType(),
  },
  state: {
    type: new EntityType(CustomerState),
  },
  createdDate: {
    type: new DateTimeType(),
  },
  editedDate: {
    type: new DateTimeType(),
  },
  attributes: {
    type: new ArrayType(new EntityType(CustomerAttributeDto)),
  },
  accounts: {
    type: new ArrayType(new EntityType(CustomerAccountDto)),
  },
  contacts: {
    type: new ArrayType(new EntityType(CustomerContactDto)),
  },
  cards: {
    type: new ArrayType(new EntityType(CustomerCardDto)),
  },
  latestTransactions: {
    type: new ArrayType(new EntityType(AccountTransactionPreviewDto)),
  },
  pointsExpirationPredictions: {
    type: new ArrayType(new EntityType(CustomerAccountExpirationPredictionDto)),
  },
  pointsHistory: {
    type: new ArrayType(new EntityType(CustomerAccountPointsHistoryDto)),
  },
  previousMonthPointsExpirations: {
    type: new ArrayType(new EntityType(PreviousMonthPointsExpirationDto)),
  },
  lastTransactionDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedDocumentCustomerDto extends createEntity(DocumentCustomerDtoPropDef) {}
