import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {
  map,
  mapKeys,
  mapValues,
  min,
  reject,
  sumBy,

} from 'lodash-es';
import {action} from '@designeo/vue-helpers/src/index';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import {nominals} from '@/Helpers/nominals';
import {
  createMaxFinDocumentAmountValidation,
  validationInvalidNominalsMinValue,
  wfMaxLengthValidation,
} from '@/Helpers/validations';
import {fixDecimals} from '@/Helpers/math';

export class WorkflowStepEnterTransactionAmount extends workflowStepMixinNominals(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.EnterTransactionAmount;
  }

  get type() {
    return WorkflowStepEnterTransactionAmount.type;
  }

  get component() {
    return markRaw(require('./StepEnterTransactionAmount').default);
  }

  async beforeContinue() {
    if (!await this.validate()) {
      throw new Error('invalid');
    }

    this.dataSetter(null, (section) => ({
      value: null,
      currency: null, // it disables items
      amount: this.total,
    }));
  }

  setNominalFields(value = this.total) {
    for (const nominal of this.availableNominals) {
      const decomposition = nominals(value, [nominal]);
      value -= decomposition.decomposableTotal;

      if (decomposition.decomposableTotal) {
        this.dataSetter(this.createNominalKey(nominal), () => decomposition.decomposableTotal / nominal);
      } else {
        this.dataSetter(this.createNominalKey(nominal), () => '');
      }
    }

    if (this.step.enterCoinsTogether) {
      this.dataSetter(WorkflowStepField.coins, () => value || '');
    }
  }

  get manualMode() {
    return this.getFieldValue(WorkflowStepField.manualMode, false);
  }

  get automaticMode() {
    return !this.manualMode;
  }

  set manualMode(val: boolean) {
    if (val) {
      this.setNominalFields(0);
    } else {
      this.dataSetter(WorkflowStepField.amount, () => '');
    }

    this.dataSetter(WorkflowStepField.manualMode, () => val);
    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: val ? this.fields[0] : WorkflowStepField.amount,
      },
    }));
  }

  get amountAsNominals() {
    let value = parseFloat(this.getFieldValue(WorkflowStepField.amount, '0') || '0') ?? 0;
    return [
      ...reject(map(this.availableNominals, (nominal) => {
        const decomposition = nominals(value, [nominal]);
        value -= decomposition.decomposableTotal;
        return {
          nominal,
          amount: decomposition.decomposableTotal / nominal,
          coins: false,
        };
      }), {amount: 0}),
      ...(this.step.enterCoinsTogether && value ? [
        ...reject(map(this.acceptableNominalsOnlyCoins, (nominal) => {
          const decomposition = nominals(value, [nominal]);
          value -= decomposition.decomposableTotal;
          return {
            nominal,
            amount: decomposition.decomposableTotal / nominal,
            coins: false,
          };
        }), {amount: 0}),
      ] : []),
    ];
  }

  get total() {
    if (this.automaticMode) {
      return fixDecimals(sumBy(this.amountAsNominals, ({nominal, amount}) => amount * nominal));
    }

    return super.total;
  }

  get disabledNextStep() {
    return this.total === 0;
  }

  get validator(): yup.AnyObjectSchema {
    if (this.automaticMode) {
      return yup.object().shape({
        [WorkflowStepField.amount]: yup.string()
          .test(wfMaxLengthValidation())
          .test(validationInvalidNominalsMinValue(this.currency, {acceptZero: true}))
          .test(createMaxFinDocumentAmountValidation(this.total)),
      });
    }

    return yup.object().shape({
      ...mapValues(mapKeys(this.fields), (val, field) => {
        if (this.step.enterCoinsTogether && field === WorkflowStepField.coins) {
          return yup.string()
            .test(this.createInvalidNominalsCountMaxQuantityValidation(field))
            .test(validationInvalidNominalsMinValue(this.currency, {
              acceptableNominals: this.currency.nominals,
              smallestAcceptedNominal: min(this.currency.nominals),
              acceptZero: true,
            }))
            .test(createMaxFinDocumentAmountValidation(this.total, {required: true}));
        }

        if (field === this.fields[this.fields.length - 1]) {
          return yup.string()
            .test(this.createInvalidNominalsCountMaxQuantityValidation(field))
            .test(createMaxFinDocumentAmountValidation(this.total, {required: true}));
        }

        return yup.string()
          .test(this.createInvalidNominalsCountMaxQuantityValidation(field))
          .test(createMaxFinDocumentAmountValidation(this.total));
      }),
    });
  }

  get transitions() {
    if (this.automaticMode) {
      return {
        ...this.withFieldActions(WorkflowStepField.amount, (fieldActions) => ({
          [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          }),
          [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
            if (this.getFieldValue(WorkflowStepField.amount, '').includes('.')) return;
            fieldActions[WorkflowActions.ADD_COMMA](event.value);
          }),
          [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
            if (this.getFieldValue(WorkflowStepField.amount, '').includes('.')) return;
            fieldActions[WorkflowActions.ADD_PERIOD](event.value);
          }),
          [WorkflowActions.BACKSPACE]: action(() => {
            fieldActions[WorkflowActions.BACKSPACE]();
          }),
          [WorkflowActions.ADD_MINUS]: action(() => {
            if (!this.getFieldValue(WorkflowStepField.amount, '').length) {
              fieldActions[WorkflowActions.ADD_MINUS]();
            }
          }),
          [WorkflowActions.ENTER]: action(async () => {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
          }),
        })),
      };
    }

    const fields = this.fields;

    return {
      ...map(fields, (field, index) => {
        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_CHAR](event.value);
            }),
            ...(field === WorkflowStepField.coins ? {
              [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
                if (this.getFieldValue(field, '').includes('.')) return;
                fieldActions[WorkflowActions.ADD_COMMA](event.value);
              }),
              [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
                if (this.getFieldValue(field, '').includes('.')) return;
                fieldActions[WorkflowActions.ADD_PERIOD](event.value);
              }),
            } : {}),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            /**
             * TODO configurable?
             */
            // [WorkflowActions.ADD_MINUS]: action(() => {
            //   if (!this.getFieldValue(field, '').length) {
            //     fieldActions[WorkflowActions.ADD_MINUS]();
            //   }
            // }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = fields[index + 1] ?? fields[index];

              if (fields.length - 1 === index) {
                this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
              } else {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                  detail: {
                    field: nextField,
                  },
                }));
              }
            }),
            ...this.createArrowMovementTransitions(fields, index),
          })),
        };
      }).reduce((acc, val) => ({...acc, ...val}), {}),
    };
  }
}
