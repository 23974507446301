import {markRaw} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';

export class WorkflowStepWarehouseOperationSetup extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationSetup;
  }

  get type() {
    return WorkflowStepWarehouseOperationSetup.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    try {
      this.ensureOperationEntity();
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    }
  }

  get transitions() {
    return {};
  }
}
