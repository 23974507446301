import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {RegisterState} from './../types';
import {
  reactive,
  UnwrapRef,
  toRaw,
} from 'vue';
import {broadcastIO} from '@/Helpers/broadcastIO';

type ClassOf<T> = new (...args: any[])=> T;

const reactivePromoFlows = new WeakMap<AbstractPromoFlow, UnwrapRef<AbstractPromoFlow>>();

export function createNewReactive<T extends AbstractPromoFlow>() {
  return async function<C extends ClassOf<T>>(this: C, ...args: ConstructorParameters<C>): Promise<T> {
    const instance: T = new this(...args);
    reactivePromoFlows.set(instance, reactive(instance));
    setTimeout(() => instance.init());
    // @ts-ignore
    return reactivePromoFlows.get(await instance.ready);
  };
}

export class AbstractPromoFlow {
  protected destroyCalled: boolean = false;
  public ready: Promise<typeof this>;
  private initCalled;
  private broadcastIOListeners = {};

  constructor(
    public nextState?: RegisterState,
  ) {
    this.destroyCalled = false;
    this.ready = new Promise((resolve) => this.initCalled = resolve);
  }

  get reactive(): UnwrapRef<this> {
    return reactivePromoFlows.get(toRaw(this)) as UnwrapRef<this>;
  }

  async init({broadcastIO: broadcastIOListeners = {}} = {}) {
    this.broadcastIOListeners = broadcastIOListeners;
    for (const [subject, listener] of Object.entries(broadcastIOListeners)) {
      // @ts-ignore
      broadcastIO.addEventListener(subject, listener);
    }
    this.initCalled?.(this);
  }

  async destroy() {
    if (this.destroyCalled) {
      return;
    }
    this.destroyCalled = true;
    for (const [subject, listener] of Object.entries(this.broadcastIOListeners)) {
      // @ts-ignore
      broadcastIO.removeEventListener(subject, listener);
    }

    const registerStore = useRegisterStore();
    await registerStore.removePromoFlow({instanceToRemove: this, nextState: this.nextState});
    registerStore.ensureCustomerDisplayState();
  }

  static baseDeserialize<T extends AbstractPromoFlow>(
    Ctor: ClassOf<T>,
    ctx: any,
    params: any[],
  ): T {
    const flow: T = new Ctor(...params);
    for (const [key, value] of Object.entries(ctx)) {
      flow[key] = value;
    }
    setTimeout(() => flow.init());
    // @ts-ignore
    return reactive(flow);
  }
}
