import {
  createConfigureStore,
  createUseStore,
  getter,
  Store,
  action,
} from '@designeo/vue-helpers';
import {consoleCollector} from '@/Helpers/persist';
import {
  first,
  last,
  map,
} from 'lodash-es';

export class ConsoleCollector {
  public history: any[] = []

  public static serializeArg(arg: any) {
    try {
      if (arg instanceof Proxy) {
        return '{Proxy}';
      }
    } catch (e) {
      return `{Error ${e.message}}`;
    }

    if (arg instanceof Error) {
      return {
        message: arg.message,
        name: arg.name,
        stack: arg.stack,
      };
    }

    return arg;
  }

  public static deserializeArg(arg: any) {
    if (arg?.stack) {
      const error = new Error();
      Object.assign(error, arg);
      return error;
    }

    return arg;
  }

  constructor() {
    const persistData = consoleCollector.get();
    this.history = persistData.history;
  }

  get limitReached() {
    const timeThrottle = 400;
    const messageThrottle = 30;
    const slice = this.history.slice(messageThrottle * -1);
    const sliceStart = first(slice);
    const sliceEnd = last(slice);

    if (
      // we have start and end
      sliceStart && sliceEnd &&
      // we already have filled queue
      slice.length === messageThrottle &&
      // time between start and end is lower than debounce
      (+new Date(sliceStart.date) + timeThrottle) >= +new Date(sliceEnd.date) &&
      // we do not block now
      (+new Date(sliceEnd.date) + timeThrottle) >= +new Date()
    ) {
      return true;
    }

    return this.history.length >= 500;
  }

  addError(args: Parameters<typeof console['error']>) {
    if (this.limitReached) return;
    this.history.push({
      date: new Date().toISOString(),
      args: map(args, (arg) => ConsoleCollector.serializeArg(arg)),
      type: 'error',
    });
    this.persist();
  }

  addWarning(args: Parameters<typeof console['warn']>) {
    if (this.limitReached) return;
    this.history.push({
      date: new Date().toISOString(),
      args: map(args, (arg) => ConsoleCollector.serializeArg(arg)),
      type: 'warn',
    });
    this.persist();
  }

  persist() {
    consoleCollector.set({history: this.history});
  }

  flush() {
    consoleCollector.reset();
    this.history = [];
  }

  ejectAndFlush() {
    try {
      return {
        history: this.history,
      };
    } finally {
      this.flush();
    }
  }
}

export interface IRestoreModeStore {
  active: boolean
  consoleCollector: ConsoleCollector
}

const createInitState = (data?: Partial<IRestoreModeStore>): IRestoreModeStore => ({
  active: false,
  consoleCollector: new ConsoleCollector(),
});

export class RestoreModeStore extends Store<IRestoreModeStore> {
  constructor() {
    super(createInitState());
  }

  setState = action((state: boolean) => {
    this.state.active = state;
  })

  active = getter(() => {
    return this.state.active;
  })

  consoleCollector = getter(() => {
    return this.state.consoleCollector;
  })
}

const storeIdentifier = 'RestoreModeStore';

export const configureRestoreModeStore = createConfigureStore<typeof RestoreModeStore>(storeIdentifier);
export const useRestoreModeStore = createUseStore(RestoreModeStore, storeIdentifier);
