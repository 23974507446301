import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Articles from '../Articles';
import Customer from '../Customer';
import Login from '../Login';
import Lottery from '../Lottery';
import Btc from '../Btc';
import Payment from '../Payment';
import Sell from '../Sell';
import Storno from '../Storno';
import Loyalty from '../Loyalty';
import TranslatedString from '../TranslatedString';


export default class GeneratedFeatures extends createEntity({
  articles: {
    type: new EntityType(Articles),
  },
  cashLimits: {
    type: new ObjectType(),
  },
  transactionLimits: {
    type: new ObjectType(),
  },
  customer: {
    type: new EntityType(Customer),
  },
  journal: {
    type: new ObjectType(),
  },
  login: {
    type: new EntityType(Login),
  },
  lottery: {
    type: new EntityType(Lottery),
  },
  loyalty: {
    type: new EntityType(Loyalty),
  },
  btc: {
    type: new EntityType(Btc),
  },
  korunka: {
    type: new ObjectType(),
  },
  payment: {
    type: new EntityType(Payment),
  },
  sell: {
    type: new EntityType(Sell),
  },
  storno: {
    type: new EntityType(Storno),
  },
  eshop: {
    type: new ObjectType<{
      enabled?: boolean,
      tabs: Array<{
        label: string,
        queryId: string,
      }>,
      integrations: {
        [key: string]: {
          code: string,
          color: string,
          id: string,
          statusConfigurations: {
            [key: string]: {
              translations: TranslatedString['_data'],
              color: string,
              backgroundColor: string,
            }
          }
        }
      }
    }>(),
  },
  stock: {
    type: new ObjectType<{
      inTransit?: {
        enabled?: boolean
      },
      stateReport?: {
        validationsMaxLength?: number,
        defaultFilters?: {
          fromArticleNumber?: string,
          toArticleNumber?: string
        }
      }
    }>(),
  },
  print: {
    type: new ObjectType<{
      displayOnScreen?: {
        enabled?: boolean
      }
    }>(),
  },
  referentialDocuments: {
    type: new ObjectType(),
  },
  messages: {
    type: new ObjectType(),
  },
  inventory: {
    type: new ObjectType(),
  },
  reports: {
    type: new ObjectType(),
  },
}) {}
