import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "font-bold pb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({abortWorkflow, workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(workflowStep.step.customerInputInfo), 1),
          _createElementVNode("div", null, [
            (workflowStep.step.generateNewPin)
              ? (_openBlock(), _createBlock(_component_AsyncButton, {
                  key: 0,
                  test: 'generateNewPinButton',
                  class: "btn-basic",
                  onClick: _ctx.generateNewPin
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(workflowStep.step.generateNewPinButton), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_WorkflowTransitions, null, {
          transitionNext: _withCtx(() => [
            _createVNode(_component_AsyncButton, {
              class: "btn-basic py-2.5 px-7",
              onClick: abortWorkflow
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(workflowStep.step.abort), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}