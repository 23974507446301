import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleLabel = _resolveComponent("ArticleLabel")!

  return (_ctx.isDisplayed)
    ? (_openBlock(), _createBlock(_component_ArticleLabel, {
        key: 0,
        theme: _ctx.theme,
        sm: _ctx.sm
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.batchLabel), 1)
        ]),
        _: 1
      }, 8, ["theme", "sm"]))
    : _createCommentVNode("", true)
}