
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';
import IconArrow from '@/Components/Icons/IconArrow.vue';
import {HLInputDateRange} from '@designeo/vue-forms/src/Input/InputDateRange';
import {useInputDate} from '@/Helpers/inputDate';
import {useDateTime} from '@/Helpers/dateTime';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  components: {
    IconArrow,
    PhIcon,
    HLInputDateRange,
  },
  props: {
    modelValue: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: null,
    },
    disabledDates: {
      type: Array as PropType<string[] | string>,
      required: false,
      default: () => [],
    },
    minDate: {
      type: Date,
      required: false,
      default: null,
    },
    maxDate: {
      type: Date,
      required: false,
      default: null,
    },
    maxRangeLength: {
      type: Number,
      default: null,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | '2xs'>,
      required: false,
      default: 'md',
    },
    popupPosition: {
      type: String as PropType<'top' | 'bottom'>,
      required: false,
      default: 'bottom',
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {
      getYesterday,
      getToday,
    } = useInputDate(HLInputDateRange.props.modelFormat.default);
    const {
      toDateTime,
      toJSDate,
      formatDate,
    } = useDateTime(HLInputDateRange.props.systemDateFormat.default);

    const innerModel = computed({
      get: () => {
        return props.modelValue?.map(formatDate) ?? [];
      },
      set: (val) => {
        innerValue.value = val;

        if (isCompleted.value) {
          emit('update:modelValue', val.map(toJSDate));
        }
      },
    });
    const innerValue = ref(innerModel.value);
    const isCompleted = computed(() => innerValue.value.filter(Boolean).length === 2);

    const innerMinDate = computed(() => formatDate(props.minDate));
    const innerMaxDate = computed(() => {
      let maxDate = props.maxDate;

      if (props.maxRangeLength && !isCompleted.value && innerValue.value[0]) {
        const rangeLimitedMaxDate = toDateTime(innerValue.value[0])
          .plus({days: props.maxRangeLength - 1})
          .toJSDate();
        if (!maxDate || rangeLimitedMaxDate < maxDate) {
          maxDate = rangeLimitedMaxDate;
        }
      }

      return formatDate(maxDate);
    });

    return {
      innerValue,
      innerModel,
      getToday,
      getYesterday,
      innerMinDate,
      innerMaxDate,
    };
  },
});
