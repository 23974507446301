import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  isEqual,
  map,
  reject,
  sum,
} from 'lodash-es';
import {WorkflowStepField} from '@/Modules/Workflow/types';
import {Currency, DocumentItemDto} from '@/Model/Entity';
import {useFilters} from '@/Helpers/filters';
import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {fixDecimals} from '@/Helpers/math';
import {createReactiveGetter} from '@/Helpers/reactive';


export const workflowStepMixinNominals = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinNominals extends (superClass as MixinBase<WorkflowStep>) {
    nominalsSettingsCached = createReactiveGetter(function() {
      return this.getFieldValue(WorkflowStepField.nominalsSettings, null);
    });

    /**
     * Remembered settings from other step!
     */
    get nominalsSettings(): {currency: string, enterCoinsTogether: boolean, acceptableNominals: number[]} {
      return this.nominalsSettingsCached();
    }

    currenciesByCurrencySymbolCached = createReactiveGetter(function() {
      return this.configurationStore.currenciesByCurrencySymbol.value;
    });

    get currency(): Currency {
      const currency = this.step.currency || this.nominalsSettings?.currency;

      return this.currenciesByCurrencySymbolCached()?.[currency];
    }

    acceptableNominalsCached = createReactiveGetter(function() {
      if (this.nominalsSettings) {
        return this.nominalsSettings?.acceptableNominals ?? [];
      }

      if (this.step.respectSmallestAcceptedNominal ?? true) {
        return this.currency?.acceptableNominals ?? [];
      }

      return this.currency?.nominals ?? [];
    });

    get acceptableNominals() {
      return this.acceptableNominalsCached();
    }

    acceptableNominalsWithoutCoinsCached = createReactiveGetter(function() {
      return reject(this.acceptableNominals, (nominal) => nominal <= this.currency.coinThreshold);
    });

    get acceptableNominalsWithoutCoins() {
      return this.acceptableNominalsWithoutCoinsCached();
    }

    acceptableNominalsOnlyCoinsCached = createReactiveGetter(function() {
      return reject(this.acceptableNominals, (nominal) => nominal > this.currency.coinThreshold);
    })

    get acceptableNominalsOnlyCoins() {
      return this.acceptableNominalsOnlyCoinsCached();
    }

    get enterCoinsTogether() {
      return this.step.enterCoinsTogether ?? this.nominalsSettings?.enterCoinsTogether ?? false;
    }

    get availableNominals() {
      return this.step.enterCoinsTogether ? this.acceptableNominalsWithoutCoins : this.acceptableNominals;
    }

    createNominalKey(nominal) {
      nominal = nominal.toString().replace(/[,.]/g, '');
      return `${WorkflowStepField.nominal}:${nominal}`;
    }

    get total() {
      return fixDecimals(sum(map(this.availableNominals, (nominal) => {
        const nominalAmount = parseFloat(this.data[this.createNominalKey(nominal)] || '0');
        return fixDecimals(nominalAmount * nominal);
      })) + (parseFloat(this.data[WorkflowStepField.coins] || '0') ?? 0));
    }

    get acceptableNominalsAsItems() {
      return map(this.acceptableNominals, (nominal, index) => {
        const nominalAmount = parseFloat(this.data[this.createNominalKey(nominal)] || '0');
        return {
          position: index + 1,
          description: nominal.toString(),
          quantity: nominalAmount,
          priceNormal: nominalAmount * nominal,
          valueBeforeDiscounts: nominalAmount * nominal,
          valueAfterDiscounts: nominalAmount * nominal,
        };
      });
    }

    get acceptableNominalsWithUnitedCoinsAsItems(): Array<DocumentItemDto['_data']> {
      return [
        ...map(this.acceptableNominalsWithoutCoins, (nominal, index) => {
          const nominalAmount = parseFloat(this.data[this.createNominalKey(nominal)] || '0');
          return {
            position: index + 1,
            description: nominal.toString(),
            quantity: nominalAmount,
            priceNormal: nominalAmount * nominal,
            valueBeforeDiscounts: nominalAmount * nominal,
            valueAfterDiscounts: nominalAmount * nominal,
          };
        }),
        {
          position: this.acceptableNominalsWithoutCoins.length + 1,
          description: WorkflowStepField.coins,
          quantity: parseFloat(this.data[WorkflowStepField.coins] ? '1' : '0'),
          priceNormal: parseFloat(this.data[WorkflowStepField.coins] || '0') ?? 0,
          valueBeforeDiscounts: parseFloat(this.data[WorkflowStepField.coins] || '0') ?? 0,
          valueAfterDiscounts: parseFloat(this.data[WorkflowStepField.coins] || '0') ?? 0,
        },
      ];
    }

    get availableNominalsAsItems() {
      return this.enterCoinsTogether ?
        this.acceptableNominalsWithUnitedCoinsAsItems :
        this.acceptableNominalsAsItems;
    }

    formInputsCached = createReactiveGetter(function() {
      const {currencyFormat} = useFilters();
      return [
        ...this.availableNominals.map((nominal) => ({
          field: this.createNominalKey(nominal),
          label: currencyFormat(nominal, this.step.currency, {
            minimumFractionDigits: 0,
          }),
          amount: nominal,
        })),
        ...(this.enterCoinsTogether ? [
          {
            field: WorkflowStepField.coins,
            label: this.step.coins,
          },
        ] : []),
      ];
    })

    get formInputs() {
      return this.formInputsCached();
    }

    get fields() {
      return [
        ...map(this.availableNominals, (nominal) => this.createNominalKey(nominal)),
        ...(this.enterCoinsTogether ? [WorkflowStepField.coins] : []),
      ];
    }

    get keyboardKeyQwerty() {
      return false;
    }

    setNominalsSettings() {
      this.dataSetter(WorkflowStepField.nominalsSettings, (currentValue) => {
        return {
          currency: currentValue?.currency ?? this.step.currency,
          enterCoinsTogether: currentValue?.enterCoinsTogether ?? this.step.enterCoinsTogether,
          acceptableNominals: currentValue?.acceptableNominals ?? this.acceptableNominals,
        };
      });
    }

    async beforeEnter() {
      if (this.stepInit) return;

      this.setNominalsSettings();

      this.stepInit = true;
    }

    createInvalidNominalsCountMaxQuantityValidation(field) {
      return {
        name: field,
        message: 'validations.string.invalidNominalsCountMaxQuantity',
        test: (value: string, context) => {
          const val = parseFloat(value || '0');
          const limit = Math.pow(10, Math.max(this.step.nominalsCountMaxQuantity, 8));

          if (val < limit) {
            return true;
          } else {
            return context.createError({
              params: {
                limit: this.step.nominalsCountMaxQuantity,
              },
            });
          }
        },
      };
    }
  };

  return Derived as MergeCtor<typeof Derived, TBase>;
};
