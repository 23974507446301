import {
  groupBy,
  map,
  sumBy,
} from 'lodash-es';
import {fixDecimals} from '@/Helpers/math';
import {DocumentItemDto, DocumentLogisticItemDto} from '@/Model/Entity';
import {useFilters} from '@/Helpers/filters';

type Items = Array<
    DocumentItemDto |
    DocumentLogisticItemDto |
    DocumentItemDto['_data'] |
    DocumentLogisticItemDto['_data']
>;

export function unitsByItems(items: Items, unitField, quantityField) {
  const {numberFormat} = useFilters();

  return map(
    groupBy(items, unitField),
    (items) => {
      const value = numberFormat(fixDecimals(sumBy(items, quantityField), 3), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });

      return `${value} ${items[0]?.unitByEnum}`;
    },
  );
}
