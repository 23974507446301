import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-1/2 flex-grow flex flex-col gap-4" }
const _hoisted_2 = { class: "w-1/2 flex flex-col gap-4 xl:max-w-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Display = _resolveComponent("Display")!
  const _component_Instructions = _resolveComponent("Instructions")!
  const _component_Keyboard = _resolveComponent("Keyboard")!
  const _component_Qwerty = _resolveComponent("Qwerty")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Display)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Instructions),
        (_ctx.workflowStore.keyboardNumeric.value)
          ? (_openBlock(), _createBlock(_component_Keyboard, {
              key: 0,
              class: "h-auto",
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('numericKeyboardInput', $event))),
              onLayout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.workflowStore.setKeyboard($event)))
            }))
          : (_ctx.workflowStore.keyboardQwerty.value)
            ? (_openBlock(), _createBlock(_component_Qwerty, {
                key: 1,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('qwertyKeyboardInput', {
          source: _ctx.InputSource.KEYBOARD,
          keys: [$event],
        })))
              }, {
                "layout-toggle": _withCtx(() => [
                  _createElementVNode("button", {
                    class: "qwerty-key",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.workflowStore.setKeyboard(_ctx.WorkflowKeyboards.NUMERIC)))
                  }, " 123 ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}