import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';


export const PermissionDtoPropDef = {
  name: {
    type: new StringType(),
  },
  parameters: {
    type: new ArrayType(new StringType()),
  },
};
export default class GeneratedPermissionDto extends createEntity(PermissionDtoPropDef) {}
