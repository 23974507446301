import {createEntity, NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class PackSellDtoCustom extends createEntity({
  packNumber: {
    type: new StringType(),
  },
  numberOfTickets: {
    type: new NumberType(),
  },
  packPrice: {
    type: new NumberType(),
  },
  issueNumber: {
    type: new StringType(),
  },
  issueName: {
    type: new StringType(),
  },
}) {}
