
import {defineComponent} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {
  WorkflowStepContinueWithoutCustomerCard,
} from '@/Modules/Workflow/Step/StepContinueWithoutCustomerCard/WorkflowStepContinueWithoutCustomerCard';

export default defineComponent({
  name: 'StepContinueWithoutCustomerCard',
  components: {
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.ContinueWithoutCustomerCard;

    const {workflowStep} = useStepTools<WorkflowStepContinueWithoutCustomerCard>(
      WorkflowStepTypes.ContinueWithoutCustomerCard,
    );

    const onReject = () => {
      workflowStep.value.reject();
    };
    const onConfirm = () => {
      workflowStep.value.confirm();
    };

    return {
      stepType,
      WorkflowStepField,
      onReject,
      onConfirm,
    };
  },
});
