import {Constructor} from '@/Model/Entity/patches/patch';
import {ArrayType, EntityType} from '@designeo/apibundle-js';
import {Transaction, TransactionItem} from '@/Model/Entity';

export const TransactionPaymentPatch = <TBase extends Constructor>(superClass: TBase) =>
  class TransactionPaymentPatch extends superClass {
    get transaction(): Transaction {
      // @ts-ignore
      return new EntityType(Transaction).parse(this._data.transaction);
    }
    set transaction(val: Transaction) {
      // @ts-ignore
      this._data.transaction = new EntityType(Transaction).format(val);
    }
  };
