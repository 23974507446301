
import {defineComponent} from 'vue';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepTypes} from '../../types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepKorunkaLotteryDraw',
  components: {
    HLStep,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaDraw;

    return {stepType};
  },
});
