import {
  createEntity,
  EntityType,
  NumberType,
} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import ErrorMessage from '../ErrorMessage';

export default class TicketCanSellDtoCustom extends createEntity({
  canSell: {
    type: new BooleanType(),
  },
  errorMessage: {
    type: new EntityType(ErrorMessage),
  },
  ticketPrice: {
    type: new NumberType(),
  },
  issueNumber: {
    type: new StringType(),
  },
  issueName: {
    type: new StringType(),
  },
}) {}
