export class LRUCache<K, V> {
  private cache = new Map<K, {value: V, timeout: any}>();

  constructor(private maxSize = 5, private ttl = 60_000) {}

  has(key: K) {
    return this.cache.has(key);
  }

  get(key: K) {
    const entry = this.cache.get(key);

    if (entry) {
      clearTimeout(entry.timeout);
      entry.timeout = this.setClearTimeout(key);
      return entry.value;
    }
    return null;
  }

  set(key: K, value: V) {
    if (this.cache.size >= this.maxSize) {
      // TODO: firstKey might not be best choice
      const firstKey = this.cache.keys().next().value;
      this.cache.delete(firstKey);
    }
    clearTimeout(this.cache.get(key)?.timeout);
    this.cache.set(key, {
      value,
      timeout: this.setClearTimeout(key),
    });
  }

  clear() {
    this.cache.forEach((entry) => {
      clearTimeout(entry.timeout);
    });
    this.cache.clear();
  }

  protected setClearTimeout(key: K) {
    return setTimeout(() => {
      this.cache.delete(key);
    }, this.ttl);
  }
}
