import {wait} from '@designeo/js-helpers/src/timing/wait';
import {guid} from './guid';

export const waitAtLeast = async (delay, dateStart: Date, dateEnd: Date = new Date()) => {
  await wait(delay - Math.min((((+dateEnd) - (+dateStart)) / 1000), delay))(null);
};

export const withStopwatch = async (name, fn: ()=> Promise<void> | void) => {
  const stopwatchId = guid();

  try {
    // eslint-disable-next-line no-console
    console.time(`${name} ${stopwatchId}`);
    await fn();
  } finally {
    // eslint-disable-next-line no-console
    console.timeEnd(`${name} ${stopwatchId}`);
  }
};
