
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {useArticleLabel} from '@/Components/ArticleLabels/label';
import {DocumentItemDto, DocumentLogisticItemDto} from '@/Model/Entity';
import ArticleLabel from '@/Components/ArticleLabels/ArticleLabel.vue';

export default defineComponent({
  name: 'ArticleLabelBatch',
  components: {ArticleLabel},
  props: {
    item: {
      type: Object as PropType<DocumentItemDto | DocumentLogisticItemDto>,
      required: true,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    forceVisible: {
      type: Boolean,
      default: null,
    },
  },
  setup(props) {
    const {theme} = useArticleLabel('batch');

    const isDisplayed = computed(() => props.forceVisible ?? props.item.batchLabel);

    return {
      theme,
      isDisplayed,
    };
  },
});
