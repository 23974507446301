import * as yup from 'yup';
import {markRaw} from 'vue';

import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';

import {
  apiCustomersContactsCreate,
} from '@/Model/Action';

import {
  CreateCustomerContactDto,
} from '@/Model/Entity';

import {action} from '@designeo/vue-helpers/src/index';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {CUSTOMER_CONTACT_TYPE_PHONE} from '@/constants/customerContactTypes';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {usePhoneNumberMaskResolver} from '@/Helpers/phoneNumberMaskResolver';

export class WorkflowStepUpdateCustomerAdditionalInformation extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.UpdateCustomerAdditionalInformation;
  }

  get type() {
    return WorkflowStepUpdateCustomerAdditionalInformation.type;
  }

  get component() {
    return markRaw(require('./StepUpdateCustomerAdditionalInformation.vue').default);
  }

  private phoneNumberMaskResolver = usePhoneNumberMaskResolver()
  get resolvedPhoneNumberMask() {
    return this.phoneNumberMaskResolver.resolvePhoneNumberMask(this.data?.value);
  }

  async beforeEnter() {
    this.dataSetter(
      WorkflowStepField.value,
      () => this.phoneNumberMaskResolver.resolveDefaultPhoneNumber(this.data?.value),
    );
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.value]: yup.string()
        .required()
        .test(this.resolvedPhoneNumberMask.validation),
      [WorkflowStepField.label]: yup.string(),
    });
  }

  get customerNumber() {
    return this.data[WorkflowStepField.customerNumber] ?? this.params.customerNumber;
  }

  async finishWorkflow() {
    const registerStore = useRegisterStore();
    if (!await this.validate()) return;

    if (this.stepFinished) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      const contact = await apiCustomersContactsCreate({
        input: new CreateCustomerContactDto({
          type: CUSTOMER_CONTACT_TYPE_PHONE,
          value: this.data.value,
          label: this.data.label,
        }),
        params: {
          customerNumber: this.customerNumber,
        },
      });

      await registerStore.addCustomerContact(contact);
      this.stepFinished = true;
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.USER_UPDATE_CONTACT_FAILED,
          value: e,
        },
      }));
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.value, (fieldActions) => ({
        [WorkflowActions.ADD_PLUS]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_PLUS](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
            detail: {
              field: WorkflowStepField.label,
            },
          }));
        }),
      }), {
        mask: this.resolvedPhoneNumberMask.phoneNumberMask,
      }),
      ...this.withFieldActions(WorkflowStepField.label, (fieldActions) => ({
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(() => this.finishWorkflow()),
      })),
    };
  }
}
