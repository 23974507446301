import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const WinsTableIntervalPropDef = {
  min: {
    type: new NumberType(),
  },
  max: {
    type: new NumberType(),
  },
};
export default class GeneratedWinsTableInterval extends createEntity(WinsTableIntervalPropDef) {}
