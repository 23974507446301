import GeneratedVoucherResultDto from './generated/VoucherResultDto';

export default class VoucherResultDto extends GeneratedVoucherResultDto {
  get documentItem() {
    const documentItem = this.article.documentItem.clone();

    documentItem.serialNo = this.code;

    return documentItem;
  }
}
