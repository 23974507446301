import {KeyPress, InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransformStream,
} from '../streams';

const TRAINING_MODE = false;

const train = !TRAINING_MODE ? ((key: KeyPress) => null) : ((() => {
  const trainSet = {};
  return (key: KeyPress) => {
    if (key.key !== 'Dead') {
      trainSet[KeyPressToStringNotation(key)] = key.key.replace('\'', '\\\'').replace('\\', '\\\\');
      // eslint-disable-next-line
      console.log(
        Object.entries(trainSet)
          .map(([key, value]) => `case '${key}': return '${value}';`)
          .join('\n'),
      );
    }
  };
})());


export function KeyPressToStringNotation(key: KeyPress) {
  const val = [];
  if (key.ctrl) {
    val.push('Ctrl');
  }
  if (key.alt) {
    val.push('Alt');
  }
  if (key.shift) {
    val.push('Shift');
  }
  val.push(key.code);
  return val.join('+');
}


export class LanguageTransformer {
  lookUpTable(key: KeyPress): string {
    const keyRegex = /^Key(.)$/;
    if (keyRegex.test(key.code)) {
      const letter = key.code.match(keyRegex)[1];
      return key.shift ? letter.toUpperCase() : letter.toLowerCase();
    }

    return null;
  }

  lookupTableTransform(key: KeyPress): KeyPress {
    const val = this.lookUpTable(key);
    if (val) {
      return {
        ...key,
        key: val,
      };
    } else {
      return null;
    }
  }

  transform(key: KeyPress): KeyPress {
    train(key);
    if (key.source === InputSource.SCANNER) {
      const result = this.lookupTableTransform(key) ?? key;
      return result;
    } else {
      return key;
    }
  }
}

/**
 * Use when scanner is set to Czech language
 */
export class CsLanguageTransformer extends LanguageTransformer {
  lookUpTable(key: KeyPress): string {
    switch (KeyPressToStringNotation(key)) {
    case 'KeyZ': return 'y';
    case 'Shift+KeyZ': return 'Y';
    case 'KeyY': return 'z';
    case 'Shift+KeyY': return 'Z';
    case 'Backquote': return ';';
    case 'Digit1': return '+';
    case 'Digit2': return 'ě';
    case 'Digit3': return 'š';
    case 'Digit4': return 'č';
    case 'Digit5': return 'ř';
    case 'Digit6': return 'ž';
    case 'Digit7': return 'ý';
    case 'Digit8': return 'á';
    case 'Digit9': return 'í';
    case 'Digit0': return 'é';
    case 'Minus': return '=';
    case 'Shift+Digit1': return '1';
    case 'Shift+Digit2': return '2';
    case 'Shift+Digit3': return '3';
    case 'Shift+Digit4': return '4';
    case 'Shift+Digit5': return '5';
    case 'Shift+Digit6': return '6';
    case 'Shift+Digit7': return '7';
    case 'Shift+Digit8': return '8';
    case 'Shift+Digit9': return '9';
    case 'Shift+Digit0': return '0';
    case 'Shift+Minus': return '%';
    case 'BracketLeft': return 'ú';
    case 'BracketRight': return ')';
    case 'Semicolon': return 'ů';
    case 'Quote': return '§';
    case 'Comma': return ',';
    case 'Period': return '.';
    case 'Slash': return '-';
    case 'Shift+BracketLeft': return '/';
    case 'Shift+BracketRight': return '(';
    case 'Shift+Backslash': return '\'';
    case 'Shift+Semicolon': return '"';
    case 'Shift+Quote': return '!';
    case 'Shift+Comma': return '?';
    case 'Shift+Period': return ':';
    case 'Shift+Slash': return '_';
    default: return super.lookUpTable(key);
    }
  }
}


/**
 * Use when scanner is set to German language
 */
export class DeLanguageTransformer extends LanguageTransformer {
  lookUpTable(key: KeyPress): string {
    switch (KeyPressToStringNotation(key)) {
    case 'KeyZ': return 'y';
    case 'Shift+KeyZ': return 'Y';
    case 'KeyY': return 'z';
    case 'Shift+KeyY': return 'Z';
    case 'Slash': return '-';
    case 'Period': return '.';
    case 'Comma': return ',';
    case 'Quote': return 'ä';
    case 'Semicolon': return 'ö';
    case 'BracketRight': return '+';
    case 'BracketLeft': return 'ü';
    case 'Backslash': return '#';
    case 'Minus': return 'ß';
    case 'Shift+Slash': return '_';
    case 'Shift+Period': return ':';
    case 'Shift+Comma': return ';';
    case 'Shift+Quote': return 'Ä';
    case 'Shift+Semicolon': return 'Ö';
    case 'Shift+Backslash': return '\'';
    case 'Shift+BracketRight': return '*';
    case 'Shift+BracketLeft': return 'Ü';
    case 'Shift+Equal': return 'Dead';
    case 'Shift+Minus': return '?';
    case 'Shift+Backquote': return '°';
    case 'Digit1': return '1';
    case 'Digit2': return '2';
    case 'Digit3': return '3';
    case 'Digit4': return '4';
    case 'Digit5': return '5';
    case 'Digit6': return '6';
    case 'Digit7': return '7';
    case 'Digit8': return '8';
    case 'Digit9': return '9';
    case 'Digit0': return '0';
    case 'Shift+Digit1': return '!';
    case 'Shift+Digit2': return '"';
    case 'Shift+Digit3': return '§';
    case 'Shift+Digit4': return '$';
    case 'Shift+Digit5': return '%';
    case 'Shift+Digit6': return '&';
    case 'Shift+Digit7': return '/';
    case 'Shift+Digit8': return '(';
    case 'Shift+Digit9': return ')';
    case 'Shift+Digit0': return '=';
    default: return super.lookUpTable(key);
    }
  }
}


/**
 * Use when scanner is set to Us English language
 */
export class UsLanguageTransformer extends LanguageTransformer {
  lookUpTable(key: KeyPress): string {
    switch (KeyPressToStringNotation(key)) {
    case 'Backquote': return '`';
    case 'Digit1': return '1';
    case 'Digit2': return '2';
    case 'Digit3': return '3';
    case 'Digit4': return '4';
    case 'Digit5': return '5';
    case 'Digit6': return '6';
    case 'Digit7': return '7';
    case 'Digit8': return '8';
    case 'Digit9': return '9';
    case 'Digit0': return '0';
    case 'Minus': return '-';
    case 'Equal': return '=';
    case 'BracketLeft': return '[';
    case 'BracketRight': return ']';
    case 'Backslash': return '\\';
    case 'Semicolon': return ';';
    case 'Quote': return '\'';
    case 'Comma': return ',';
    case 'Period': return '.';
    case 'Slash': return '/';
    case 'Shift+Backquote': return '~';
    case 'Shift+Digit1': return '!';
    case 'Shift+Digit2': return '@';
    case 'Shift+Digit3': return '#';
    case 'Shift+Digit4': return '$';
    case 'Shift+Digit5': return '%';
    case 'Shift+Digit6': return '^';
    case 'Shift+Digit7': return '&';
    case 'Shift+Digit8': return '*';
    case 'Shift+Digit9': return '(';
    case 'Shift+Digit0': return ')';
    case 'Shift+Minus': return '_';
    case 'Shift+Equal': return '+';
    case 'Shift+BracketLeft': return '{';
    case 'Shift+BracketRight': return '}';
    case 'Shift+Backslash': return '|';
    case 'Shift+Semicolon': return ':';
    case 'Shift+Quote': return '"';
    case 'Shift+Comma': return '<';
    case 'Shift+Period': return '>';
    case 'Shift+Slash': return '?';
    default: return super.lookUpTable(key);
    }
  }
}


/**
 * Use when scanner is set to Slovak language
 */
export class SkLanguageTransformer extends LanguageTransformer {
  lookUpTable(key: KeyPress): string {
    switch (KeyPressToStringNotation(key)) {
    case 'KeyY': return 'z';
    case 'Shift+KeyY': return 'Z';
    case 'KeyZ': return 'y';
    case 'Shift+KeyZ': return 'Y';
    case 'Backquote': return ';';
    case 'Digit1': return '+';
    case 'Digit2': return 'ľ';
    case 'Digit3': return 'š';
    case 'Digit4': return 'č';
    case 'Digit5': return 'ť';
    case 'Digit6': return 'ž';
    case 'Digit7': return 'ý';
    case 'Digit8': return 'á';
    case 'Digit9': return 'í';
    case 'Digit0': return 'é';
    case 'Minus': return '=';
    case 'BracketLeft': return 'ú';
    case 'BracketRight': return 'ä';
    case 'Backslash': return 'ň';
    case 'Semicolon': return 'ô';
    case 'Quote': return '§';
    case 'Comma': return ',';
    case 'Period': return '.';
    case 'Slash': return '-';
    case 'Shift+Digit1': return '1';
    case 'Shift+Digit2': return '2';
    case 'Shift+Digit3': return '3';
    case 'Shift+Digit4': return '4';
    case 'Shift+Digit5': return '5';
    case 'Shift+Digit6': return '6';
    case 'Shift+Digit7': return '7';
    case 'Shift+Digit8': return '8';
    case 'Shift+Digit9': return '9';
    case 'Shift+Digit0': return '0';
    case 'Shift+Minus': return '%';
    case 'Shift+BracketLeft': return '/';
    case 'Shift+BracketRight': return '(';
    case 'Shift+Backslash': return ')';
    case 'Shift+Quote': return '!';
    case 'Shift+Semicolon': return '"';
    case 'Shift+Comma': return '?';
    case 'Shift+Period': return ':';
    case 'Shift+Slash': return '_';
    default: return super.lookUpTable(key);
    }
  }
}

export class NoTransformLanguageTransformer extends LanguageTransformer {
  lookUpTable(key: KeyPress): string {
    return null;
  }
}

function createTransformerInstance(language: string) {
  switch (language) {
  case 'cs': return new CsLanguageTransformer();
  case 'de': return new DeLanguageTransformer();
  case 'us': return new UsLanguageTransformer();
  case 'sk': return new SkLanguageTransformer();
  default: return new NoTransformLanguageTransformer();
  }
}


export function createLanguageTransformStream(language: string) {
  const tranformer = createTransformerInstance(language);
  return new TransformStream<KeyPress, KeyPress>({
    transform(chunk, controller) {
      controller.enqueue(tranformer.transform(chunk));
    },
  });
}
