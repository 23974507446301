import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _directive_test = _resolveDirective("test")!

  return (!_ctx.transitionLeaving)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalDetachedClose, {
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }),
        _createVNode(_component_ModalDetachedBody, { type: _ctx.type }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["type"]),
        _createVNode(_component_ModalDetachedButtons, {
          single: !_ctx.hasRejectBtn
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("button", {
              class: _normalizeClass(["btn-md", {
          'btn-success w-full': _ctx.hasRejectBtn,
          'btn-outline-basic': !_ctx.hasRejectBtn
        }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
            }, [
              _renderSlot(_ctx.$slots, "confirmBtn", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.hasRejectBtn ? _ctx.$t('confirm.buttonConfirm') : _ctx.$t('confirm.buttonContinue')), 1)
              ])
            ], 2), [
              [_directive_test, 'modalConfirm']
            ]),
            (_ctx.hasRejectBtn)
              ? _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-danger btn-md w-full",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('reject')))
                }, [
                  _renderSlot(_ctx.$slots, "rejectBtn", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.$t('confirm.buttonReject')), 1)
                  ])
                ], 512)), [
                  [_directive_test, 'modalReject']
                ])
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["single"])
      ]))
    : _createCommentVNode("", true)
}