
import {
  computed,
  defineComponent,
} from 'vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {WorkflowStepKorunkaLotteryNumbers} from '../WorkflowStepKorunkaLotteryNumbers';
import {WorkflowStepTypes} from '../../../types';
import {useStepTools} from '../../StepTools';
import KorunkaLotterySestkaDtoCustom from '@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom';

export default defineComponent({
  components: {
    AsyncButton,
  },
  setup() {
    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryNumbers>(WorkflowStepTypes.KorunkaNumbers);

    const entity = computed(() => workflowStep.value.entity as KorunkaLotterySestkaDtoCustom);

    const generateRandomNumbersOnEntity = async () => await workflowStep.value.generateRandomNumbersOnEntity();

    return {entity, workflowStep, generateRandomNumbersOnEntity};
  },
});
