import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {RouteLocationRaw} from 'vue-router';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {inventoryStockStoreStatePersist, pdaHandshakeStoreStatePersist} from '@/Helpers/persist';
import {PersistentStore} from '@/Helpers/PersistentStore';

export interface IPdaHandshakeStore {
  callback: (route?: RouteLocationRaw)=> void,
  credentials: {
    shopCode: string,
    posCode: string
  },
}

const createInitState = (data?: Partial<IPdaHandshakeStore>) => ({
  callback: null,
  credentials: null,
});

export class PdaHandshakeStore extends PersistentStore<IPdaHandshakeStore> {
  constructor() {
    super(createInitState(), pdaHandshakeStoreStatePersist);

    if (this.state.credentials) {
      const coreStore = useCoreStore();
      coreStore.setRequestCredentials(this.state.credentials);
    }
  }

  setCallback = action((callback) => {
    this.state.callback = (...args) => {
      callback(...args);
      this.state.callback = null;
    };
  })

  setCredentials = action(async (credentials) => {
    const coreStore = useCoreStore();

    this.state.credentials = credentials;
    await this.persist();
    coreStore.setRequestCredentials(credentials);
  })

  continue = action(async (credentials: {shopCode: string, posCode: string}) => {
    const authStore = useAuthStore();

    await this.setCredentials(credentials);

    const {document: currentDocument} = inventoryStockStoreStatePersist.get() ?? {};

    if (currentDocument && currentDocument.header.shopCode !== credentials.shopCode) {
      inventoryStockStoreStatePersist.reset();
    }


    if (!authStore.activePerson.value) {
      this.state?.callback?.();
      return;
    }

    const {
      shop_code: shopCode,
    } = authStore.activePerson.value.tokenInfo ?? {};

    if (credentials.shopCode !== shopCode) {
      await authStore.logout();
      inventoryStockStoreStatePersist.reset();
      this.state?.callback?.();
      return;
    }

    this.state?.callback?.();
  })

  reset = action(async () => {
    const authStore = useAuthStore();

    await authStore.logout();
    await this.setCredentials(null);
    inventoryStockStoreStatePersist.reset();
  })

  credentials = getter(() => {
    return this.state.credentials;
  })
}

const storeIdentifier = 'PdaHandshakeStore';

export const configurePdaHandshakeStore = createConfigureStore<typeof PdaHandshakeStore>(storeIdentifier);
export const usePdaHandshakeStore = createUseStore(PdaHandshakeStore, storeIdentifier);
