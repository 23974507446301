
import {
  computed,
  defineComponent,
  ref,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useSignalR} from '@/Helpers/signalR';
import {useElectron} from '@/Helpers/electron';
import {ZLayer} from '@/constants/zLayer';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';

export default defineComponent({
  name: 'ModalPosCoreDisconnectState',
  components: {
    ModalDetachedButtons,
    AsyncButton,
    Modal,
  },
  setup() {
    const {closed, reconnecting} = useSignalR();
    const {quit, canQuit} = useElectron();
    const loading = ref(false);
    const {isSecondaryDisplay} = useSecondaryDisplay();
    const updateStore = useUpdateStore();

    const open = computed(() => {
      if (updateStore.updateTriggered.value) {
        return false;
      }

      if (isSecondaryDisplay) {
        return false;
      }

      return closed.value || reconnecting.value;
    });

    const shutdown = () => {
      try {
        loading.value = true;
        quit();
      } finally {
        loading.value = false;
      }
    };

    const canShutdown = computed(() => canQuit.value);

    return {
      open,
      shutdown,
      canShutdown,
      canQuit,
      loading,
      ZLayer,
    };
  },
});
