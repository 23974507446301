import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStepEnterNote} from '@/Modules/Workflow/Step/StepEnterNote/WorkflowStepEnterNote';
import {sumBy, values} from 'lodash-es';
import * as yup from 'yup';
import {
  commonWfFieldValidations,
} from '@/Helpers/validations';

export class WorkflowStepSafeBagDeliveryListOverview extends WorkflowStepEnterNote {
  static get type() {
    return WorkflowStepTypes.SafeBagDeliveryListOverview;
  }

  get type() {
    return WorkflowStepSafeBagDeliveryListOverview.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagDeliveryListOverview.vue').default);
  }

  get safeBagTotal() {
    return sumBy(values(this.data[WorkflowStepField.safeBagDeliveryList] ?? {}), (val: any) => {
      if (!val?.scanned) {
        return 0;
      }

      return val?.safeBag?.amount ?? 0;
    });
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.note]: yup.string()
        .test(commonWfFieldValidations(this.step.validations?.[WorkflowStepField.note])),
    });
  }
}
