import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.PAYMENTS_EXIT]: {
      [RegisterActions.INIT]: action(async () => {
        if (
          this.configuration.value.features.sell.cancelPaymentsWithReturnFromPayments &&
          !this.state.sellDocument.validPayments.length
        ) {
          this.setPayment(null);
        }


        if ((
          !this.configuration.value.features.sell.cancelPaymentsWithReturnFromPayments ||
          !this.state.sellDocument.validPayments.length
        ) && (
          !this.state.sellDocument.hasCustomerInteractions
        )) {
          this.state.sellDocument?.resetCustomerInteractions?.();
          await this.changeState(this.state.previousState);
          await this.fetchPromoEngineResult();
        }
      }),

      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent<boolean>) => {
        if (event.value) {
          await this.cancelSellDocumentCardPayments();
          this.state.sellDocument.cancelCashPayments();
          this.setPayment(null);
          this.state.sellDocument?.resetCustomerInteractions?.();
          await this.fetchPromoEngineResult();
          await this.changeState(this.state.previousState);
          await this.state.saveFlow.reset();
        } else {
          await this.changeState(RegisterState.SELECT_PAYMENT);
        }
      }),
    },
  };
}
