import GeneratedTicketPartnerResource from './generated/TicketPartnerResource';
import {some, values} from 'lodash-es';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';

export default class TicketPartnerResource extends GeneratedTicketPartnerResource {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get isSestka() {
    return this.game.value === KorunkaEntityTypes.Sestka;
  }

  get isDvacetZa20() {
    return this.game.value === KorunkaEntityTypes.DvacetZa20;
  }

  get isHopTrop() {
    return this.game.value === KorunkaEntityTypes.HopTrop;
  }

  get isKombi() {
    return this.game.value === KorunkaEntityTypes.Kombi;
  }

  get isKombiPlus() {
    return this.game.value === KorunkaEntityTypes.KombiPlus;
  }

  get isSupportedGame() {
    return some([
      this.isSestka,
      this.isDvacetZa20,
      this.isHopTrop,
      this.isKombi,
      this.isKombiPlus,
    ]);
  }

  get numbersArray() {
    if (this.isKombi || this.isKombiPlus || this.isDvacetZa20) {
      return values(this.numbers);
    }

    return values(this.numbers).flat();
  }

  get depositValue() {
    if (this.game.value === KorunkaEntityTypes.DvacetZa20) {
      return this.amountPaid / values(this.numbers).length;
    }

    return this.amountPaid;
  }
}
