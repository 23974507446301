import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-grow flex-col" }
const _hoisted_3 = { class: "text-success text-center mb-6" }
const _hoisted_4 = { class: "w-10/12 mx-auto" }
const _hoisted_5 = { class: "font-bold mb-4" }
const _hoisted_6 = { class: "whitespace-pre-line" }
const _hoisted_7 = { class: "flex flex-row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconCheck, {
              rounded: "",
              class: "mx-auto w-12 h-12 mb-4"
            }),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(workflowStep.step.successMessageTitle), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(workflowStep.step.successMessageInfo), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AsyncButton, {
              onClick: () => _ctx.requestNextStep(),
              class: "btn-success py-2.5 px-7"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconCheck, { class: "inline h-3" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('workflow.stepUI.finish')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}