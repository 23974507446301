import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';

export class WorkflowStepKorunkaLotteryEnterCode extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaEnterCode;
  }

  get type() {
    return WorkflowStepKorunkaLotteryEnterCode.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryEnterCode.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.code]: yup.string().required(),
    });
  }

  async beforeEnter() {
    this.ensureCustomerDisplayKorunkaPage();
    await this.syncBasicStepStateToCustomerDisplay();
    await this.syncEntityToCustomerDisplay();
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.code, (fieldActions) => ({
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.SCANNER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ENTER](event.value);
        }),
        [WorkflowActions.ADD_MINUS]: action(() => {
          if (!this.data[WorkflowStepField.code]?.length) {
            fieldActions[WorkflowActions.ADD_MINUS]();
          }
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;

          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
