import {createEntity, NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class POSACheckRequestDtoCustom extends createEntity({
  ean: {
    type: new StringType(),
  },
  pan: {
    type: new StringType(),
  },
  amount: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
