import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentLogisticItemDto from '../DocumentLogisticItemDto';


export const InventoryReportDtoPropDef = {
  logisticItems: {
    type: new ArrayType(new EntityType(DocumentLogisticItemDto)),
  },
};
export default class GeneratedInventoryReportDto extends createEntity(InventoryReportDtoPropDef) {}
