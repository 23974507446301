import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ClubCodeNamePairDto from '../ClubCodeNamePairDto';
import {NumberType} from '@designeo/apibundle-js';


export const LoyaltyPointsDtoPropDef = {
  club: {
    type: new EntityType(ClubCodeNamePairDto),
  },
  pointsBurned: {
    type: new NumberType(),
  },
  pointsCollected: {
    type: new NumberType(),
  },
  pointsLost: {
    type: new NumberType(),
  },
  pointsTotalAfterBon: {
    type: new NumberType(),
  },
  pointsTotalBeforeBon: {
    type: new NumberType(),
  },
};
export default class GeneratedLoyaltyPointsDto extends createEntity(LoyaltyPointsDtoPropDef) {}
