
import {defineComponent} from 'vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import {useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import IconUArrowLeft from '@/Components/Icons/IconUArrowLeft.vue';
import IconCross from '@/Components/Icons/IconCross.vue';

export default defineComponent({
  name: 'WorkflowTransitions',
  components: {
    IconCross,
    IconUArrowLeft,
    IconCheck,
    AsyncButton,
  },
  props: {
    nextDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    nextHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    nextAction: {
      type: Function,
      required: false,
      default: null,
    },
    previousDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    previousHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    previousAction: {
      type: Function,
      required: false,
      default: null,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const workflowStore = useWorkflowStore();

    const {
      currentWorkflow,
      abortWorkflow,
      workflowStep,
    } = useStepTools(workflowStore.currentWorkflow.value.activeWorkflowStep.type);

    const goBack = async () => {
      if (props.previousAction) {
        return await props.previousAction();
      }

      return await currentWorkflow.value.requestPreviousStep();
    };

    const goNext = async () => {
      if (props.validate) {
        if (!await workflowStep.value.validate()) return;
      }

      if (props.nextAction) {
        return await props.nextAction();
      }

      return await currentWorkflow.value.requestNextStep();
    };

    return {
      currentWorkflow,
      abortWorkflow,
      workflowStore,
      goBack,
      goNext,
    };
  },
});
