import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {WorkflowStepField, WorkflowStepUIModes} from '@/Modules/Workflow/types';
import {WarehouseOperations} from '@/constants/warehouseOperations';
import WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom';
import WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom';
import WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom';
import WarehouseOperationReceiptOfPrintedMattersDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReceiptOfPrintedMattersDtoCustom';
import WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom';
import WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom';
import WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom';
import WarehouseOperationReturnOfPrintedMattersDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReturnOfPrintedMattersDtoCustom';
import WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom';
import WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom';
import WarehouseOperationDepreciationOfGoodsTypeDisposalDtoCustom
  from '@/Model/Entity/custom/WarehouseOperationDepreciationOfGoodsTypeDisposalDtoCustom';
import {
  deserializeEntity,
  EntitySerialization,
  serializeEntity,
} from '@/Helpers/entityPersist';
import {has, map} from 'lodash-es';
import {DocumentDto} from '@/Model/Entity';
import {DocumentTypes} from '@/constants/documentTypes';
import {interpolate} from '@/Helpers/filters';

type WarehouseOperationEntities = WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom |
WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom |
WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom |
WarehouseOperationReceiptOfPrintedMattersDtoCustom |
WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom |
WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom |
WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom |
WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom |
WarehouseOperationReturnOfPrintedMattersDtoCustom |
WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom |
WarehouseOperationDepreciationOfGoodsTypeDisposalDtoCustom;

export const workflowStepMixinWarehouseOperation = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinWarehouseOperation extends (superClass as MixinBase<WorkflowStep>) {
    get titleByWarehouseOperation() {
      switch (this.warehouseOperationType) {
      case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
      case WarehouseOperations.receiptOfPrintedMatters:
      case WarehouseOperations.returnOfPrintedMatters:
      case WarehouseOperations.reloadingOfGoodsTypeReceiving:
        return interpolate(this.step.title, {
          documentNumber: this.operationEntityPropLogisticDocument.header.externalReference ??
                this.operationEntityPropLogisticDocument.header.uniqueidentifier,
        });
      default:
        return this.step.title;
      }
    }

    createLogisticDocumentByWarehouseOperation() {
      switch (this.warehouseOperationType) {
      case WarehouseOperations.receiptOfGoodsFromExternalSupplier:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsReceiptFromExternalSupplier);
      case WarehouseOperations.orderOfGoodsFromDistributionCenter:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsOrderFromDC);
      case WarehouseOperations.returnOfGoodsToDistributionCenter:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsReturnToDC);
      case WarehouseOperations.returnOfGoodsToExternalSupplier:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsReturnToExternalSupplier);
      case WarehouseOperations.depreciationOfGoodsTypeConsumption:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsDepreciationConsumption);
      case WarehouseOperations.depreciationOfGoodsTypeDisposal:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsDepreciationDisposal);
      case WarehouseOperations.reloadingOfGoodsTypeDispense:
        return DocumentDto.createWarehouseOperation(DocumentTypes.GoodsReceiptFromPlant);
      default:
        return null; // for other operations shipment notification list is used
      }
    }

    warehouseOperationByStepOperation() {
      const operation = this.step.operation as WarehouseOperations;

      switch (operation) {
      case WarehouseOperations.orderOfGoodsFromDistributionCenter:
        return new WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom({});
      case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
        return new WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom({});
      case WarehouseOperations.receiptOfGoodsFromExternalSupplier:
        return new WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom({});
      case WarehouseOperations.receiptOfPrintedMatters:
        return new WarehouseOperationReceiptOfPrintedMattersDtoCustom({});
      case WarehouseOperations.reloadingOfGoodsTypeDispense:
        return new WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom({});
      case WarehouseOperations.reloadingOfGoodsTypeReceiving:
        return new WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom({});
      case WarehouseOperations.returnOfGoodsToDistributionCenter:
        return new WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom({});
      case WarehouseOperations.returnOfGoodsToExternalSupplier:
        return new WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom({});
      case WarehouseOperations.returnOfPrintedMatters:
        return new WarehouseOperationReturnOfPrintedMattersDtoCustom( {});
      case WarehouseOperations.depreciationOfGoodsTypeConsumption:
        return new WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom({});
      case WarehouseOperations.depreciationOfGoodsTypeDisposal:
        return new WarehouseOperationDepreciationOfGoodsTypeDisposalDtoCustom({});
      default:
        throw new Error(`Unknown operation: ${operation}`);
      }
    }

    serializeOperationEntity(entity: WarehouseOperationEntities) {
      return serializeEntity<WarehouseOperationEntities, WarehouseOperations>(entity)
        .match(
          WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom,
          WarehouseOperations.orderOfGoodsFromDistributionCenter,
        )
        .match(
          WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom,
          WarehouseOperations.receiptOfGoodsFromDistributionCenter,
        )
        .match(
          WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom,
          WarehouseOperations.receiptOfGoodsFromExternalSupplier,
        )
        .match(
          WarehouseOperationReceiptOfPrintedMattersDtoCustom,
          WarehouseOperations.receiptOfPrintedMatters,
        )
        .match(
          WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom,
          WarehouseOperations.reloadingOfGoodsTypeDispense,
        )
        .match(
          WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom,
          WarehouseOperations.reloadingOfGoodsTypeReceiving,
        )
        .match(
          WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom,
          WarehouseOperations.returnOfGoodsToDistributionCenter,
        )
        .match(
          WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom,
          WarehouseOperations.returnOfGoodsToExternalSupplier,
        )
        .match(
          WarehouseOperationReturnOfPrintedMattersDtoCustom,
          WarehouseOperations.returnOfPrintedMatters,
        )
        .match(
          WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom,
          WarehouseOperations.depreciationOfGoodsTypeConsumption,
        )
        .match(
          WarehouseOperationDepreciationOfGoodsTypeDisposalDtoCustom,
          WarehouseOperations.depreciationOfGoodsTypeDisposal,
        )
        .resolve();
    }
    deserializeOperationEntity(entitySerialization: EntitySerialization<WarehouseOperations>) {
      return deserializeEntity(entitySerialization)
        .match(
          WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom,
          WarehouseOperations.orderOfGoodsFromDistributionCenter,
        )
        .match(
          WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom,
          WarehouseOperations.receiptOfGoodsFromDistributionCenter,
        )
        .match(
          WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom,
          WarehouseOperations.receiptOfGoodsFromExternalSupplier,
        )
        .match(
          WarehouseOperationReceiptOfPrintedMattersDtoCustom,
          WarehouseOperations.receiptOfPrintedMatters,
        )
        .match(
          WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom,
          WarehouseOperations.reloadingOfGoodsTypeDispense,
        )
        .match(
          WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom,
          WarehouseOperations.reloadingOfGoodsTypeReceiving,
        )
        .match(
          WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom,
          WarehouseOperations.returnOfGoodsToDistributionCenter,
        )
        .match(
          WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom,
          WarehouseOperations.returnOfGoodsToExternalSupplier,
        )
        .match(
          WarehouseOperationReturnOfPrintedMattersDtoCustom,
          WarehouseOperations.returnOfPrintedMatters,
        )
        .match(
          WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom,
          WarehouseOperations.depreciationOfGoodsTypeConsumption,
        )
        .match(
          WarehouseOperationDepreciationOfGoodsTypeDisposalDtoCustom,
          WarehouseOperations.depreciationOfGoodsTypeDisposal,
        )
        .resolve();
    }

    ensureOperationEntity(): ReturnType<WorkflowStepMixinWarehouseOperation['warehouseOperationByStepOperation']> {
      let serializedEntity: EntitySerialization<WarehouseOperations> = this.getFieldValue(
        WorkflowStepField.entity,
        null,
      );

      if (!serializedEntity) {
        serializedEntity = this.serializeOperationEntity(this.warehouseOperationByStepOperation());

        this.dataSetter(WorkflowStepField.entity, () => serializedEntity);
      }

      return this.deserializeOperationEntity(serializedEntity);
    }

    get warehouseOperationType(): WarehouseOperations {
      const operation = this.step.operation as WarehouseOperations;

      if (operation) {
        return operation;
      }

      const serializedEntity: EntitySerialization<WarehouseOperations> = this.getFieldValue(
        WorkflowStepField.entity,
        null,
      );

      if (!serializedEntity) {
        return null;
      }

      return serializedEntity.entityType;
    }

    get operationEntity() {
      return this.ensureOperationEntity();
    }

    entityPropListGuard(entity): entity is Extract<WarehouseOperationEntities,
        WarehouseOperationReceiptOfGoodsFromDistributionCenterDtoCustom |
        WarehouseOperationReceiptOfPrintedMattersDtoCustom |
        WarehouseOperationReloadingOfGoodsTypeReceivingDtoCustom |
        WarehouseOperationReturnOfPrintedMattersDtoCustom
    > {
      // @ts-ignore
      return has(this.operationEntity.$fields, 'list');
    }
    entityPropLogisticDocumentGuard(entity): entity is WarehouseOperationEntities {
      // @ts-ignore
      return has(this.operationEntity.$fields, 'logisticDocument');
    }

    entityPropSummaryGuard(entity): entity is WarehouseOperationEntities {
      // @ts-ignore
      return has(this.operationEntity.$fields, 'summary');
    }

    entityPropVendorGuard(entity): entity is Extract<WarehouseOperationEntities,
        WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom |
        WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom |
        WarehouseOperationReturnOfGoodsToExternalSupplierDtoCustom
    > {
      // @ts-ignore
      return has(this.operationEntity.$fields, 'vendor');
    }

    entityPropPlantGuard(entity): entity is Extract<
        WarehouseOperationEntities,
        WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom |
        WarehouseOperationReceiptOfGoodsFromExternalSupplierDtoCustom
    > {
      return has(this.operationEntity.$fields, 'plant');
    }
    entityPropSourcePlantGuard(entity): entity is Extract<
        WarehouseOperationEntities,
        WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom
    > {
      return has(this.operationEntity.$fields, 'sourcePlant');
    }
    entityPropTargetPlantGuard(entity): entity is Extract<
        WarehouseOperationEntities,
        WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom
    > {
      return has(this.operationEntity.$fields, 'targetPlant');
    }

    entityPropDistributionCenterGuard(entity): entity is Extract<
        WarehouseOperationEntities,
        WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom |
        WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom
    > {
      return has(this.operationEntity.$fields, 'distributionCenter');
    }

    get operationEntityPropList() {
      if (!this.entityPropListGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'list'`, this.operationEntity);

        return null;
      }

      return this.operationEntity.list;
    }

    set operationEntityPropList(val) {
      if (!this.entityPropListGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'list'`, this.operationEntity);
        return;
      }

      this.operationEntity.list = val;
    }

    get operationEntityPropLogisticDocument() {
      if (!this.entityPropLogisticDocumentGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'logisticDocument'`, this.operationEntity);

        return null;
      }

      if (this.operationEntity.logisticDocument) {
        return this.operationEntity.logisticDocument;
      }

      const logisticDocument = this.createLogisticDocumentByWarehouseOperation();

      if (logisticDocument) {
        this.operationEntity.logisticDocument = logisticDocument;
      }

      return this.operationEntity.logisticDocument;
    }

    set operationEntityPropLogisticDocument(val) {
      if (!this.entityPropLogisticDocumentGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'logisticDocument'`, this.operationEntity);
        return;
      }

      this.operationEntity.logisticDocument = val;
    }

    get operationEntityPropSummary() {
      if (!this.entityPropSummaryGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'summary'`, this.operationEntity);

        return null;
      }

      return this.operationEntity.summary;
    }
    set operationEntityPropSummary(val) {
      if (!this.entityPropSummaryGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'summary'`, this.operationEntity);
        return;
      }

      this.operationEntity.summary = val;
    }

    get operationEntityPropVendor() {
      if (!this.entityPropVendorGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'vendor'`, this.operationEntity);

        return null;
      }

      return this.operationEntity.vendor;
    }
    set operationEntityPropVendor(val) {
      if (!this.entityPropVendorGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'vendor'`, this.operationEntity);
        return;
      }

      this.operationEntity.vendor = val;
    }

    get operationEntityPropPlant() {
      if (!this.entityPropPlantGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'plant'`, this.operationEntity);

        return null;
      }

      return this.operationEntity.plant;
    }
    set operationEntityPropPlant(val) {
      if (!this.entityPropPlantGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'plant'`, this.operationEntity);
        return;
      }

      this.operationEntity.plant = val;
    }

    get operationEntityPropSourcePlant() {
      if (!this.entityPropSourcePlantGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'sourcePlant'`, this.operationEntity);
        return null;
      }


      return this.operationEntity.sourcePlant;
    }
    set operationEntityPropSourcePlant(val) {
      if (!this.entityPropSourcePlantGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'sourcePlant'`, this.operationEntity);
        return;
      }

      this.operationEntity.sourcePlant = val;
    }

    get operationEntityPropTargetPlant() {
      if (!this.entityPropTargetPlantGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'targetPlant'`, this.operationEntity);

        return null;
      }

      return this.operationEntity.targetPlant;
    }
    set operationEntityPropTargetPlant(val) {
      if (!this.entityPropTargetPlantGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'targetPlant'`, this.operationEntity);
        return;
      }

      this.operationEntity.targetPlant = val;
    }

    get operationEntityPropDistributionCenter() {
      if (!this.entityPropDistributionCenterGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'distributionCenter'`, this.operationEntity);

        return null;
      }

      return this.operationEntity.distributionCenter;
    }
    set operationEntityPropDistributionCenter(val) {
      if (!this.entityPropDistributionCenterGuard(this.operationEntity)) {
        console.warn(`Operation does not have property 'distributionCenter'`, this.operationEntity);
        return;
      }

      this.operationEntity.distributionCenter = val;
    }

    get stepUIMode() {
      return WorkflowStepUIModes.atTitle;
    }

    get hasVisiblePrices() {
      switch (this.warehouseOperationType) {
      case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
        return false;
      default:
        return true;
      }
    }

    saveEnumToCustomData(key, data: Array<{id: string, label: string}>) {
      if (!this.operationEntityPropLogisticDocument) {
        return;
      }

      this.operationEntityPropLogisticDocument.expandCustomData({
        workflowData: {
          ...(this.operationEntityPropLogisticDocument?.customData?.workflowData ?? {}),
          [key]: data,
        },
      });
    }

    getEnumValueByStoredEnum(key, id): {id: string, label: string} {
      if (!this.operationEntityPropLogisticDocument) {
        return null;
      }

      const storedEnum = this.operationEntityPropLogisticDocument.customData?.workflowData?.[key];

      if (!storedEnum) {
        return null;
      }

      return storedEnum.find((option) => option.id === id);
    }

    configOptionsToSelectOptions(
      arr: Array<{value: string, label: string}>,
    ): Array<{id: string, label: string}> {
      if (!arr) {
        return null;
      }

      return map(arr, (item) => ({id: item.value, label: item.label}));
    }
  };

  return Derived as MergeCtor<typeof Derived, TBase>;
};
