export const wrapAppRedirectUrl = (val) => {
  const result = new URL('/', window.location.origin);

  result.searchParams.append('landingRoute', encodeURIComponent(val));

  return result.toString();
};

export const unwrapAppRedirectUrl = (url) => {
  const result = new URL(url);

  const landingRoute = result.searchParams.get('landingRoute');

  if (landingRoute) {
    return decodeURIComponent(landingRoute);
  }

  return null;
};
