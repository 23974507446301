import PackCanSellDtoCustom from './custom/PackCanSellDtoCustom';

export default class PackCanSellDto extends PackCanSellDtoCustom {
  get canBeSold() {
    return this.canSell;
  }

  get errorReason() {
    return this.errorMessage?.errorCashierMessage;
  }

  get errorReasonForCustomer() {
    return this.errorMessage?.errorCustomerMessage;
  }
}
