
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';

export default defineComponent({
  name: 'CaretNavigator',
  props: {
    interval: {
      type: Number,
      required: false,
      default: 800,
    },
  },
  setup(props) {
    const visible = ref(true);
    let interval = null;

    onMounted(() => {
      clearInterval(interval);
      interval = setInterval(() => {
        visible.value = !visible.value;
      }, props.interval);
    });

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    return {
      visible,
    };
  },
});
