import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between w-full h-10 px-4" }
const _hoisted_2 = { class: "ml-10 text-2xl text-secondary flex-grow" }
const _hoisted_3 = { class: "self-center flex flex-row items-center gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_Button = _resolveComponent("Button")!
  const _component_HLGridPaginator = _resolveComponent("HLGridPaginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputBuffer, {
        value: $setup.inputBuffer,
        sanitizeDots: false,
        limit: 50
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Button, {
        class: "btn-outline-basic self-start",
        onClick: $setup.toggleShowKeyboard
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(`general.keyboard.${$setup.showKeyboard ? 'hide' : 'show'}`)), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_HLGridPaginator, null, {
        default: _withCtx(({
          nextPage,
          pageSize,
        }) => [
          ($setup.fetchResult.length === pageSize)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "btn-outline-basic",
                loading: $setup.isLoading,
                onClick: nextPage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('action.loadMore')), 1)
                ]),
                _: 2
              }, 1032, ["loading", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}