import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const GeneratedNumbersResourcePropDef = {
  groups: {
    type: new ArrayType(new ArrayType(new NumberType())),
  },
};
export default class GeneratedGeneratedNumbersResource extends createEntity(GeneratedNumbersResourcePropDef) {}
