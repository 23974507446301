import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Currency from '../Currency';
import ExchangeRate from '../ExchangeRate';
import PaymentTypeClass from '../PaymentTypeClass';
import StaticTextPrint from '../StaticTextPrint';
import TemplateClass from '../TemplateClass';
import {ObjectType} from '@designeo/apibundle-js';


export default class GeneratedEnums extends createEntity({
  currencies: {
    type: new ArrayType(new EntityType(Currency)),
  },
  exchangeRates: {
    type: new ArrayType(new EntityType(ExchangeRate)),
  },
  paymentTypes: {
    type: new ArrayType(new EntityType(PaymentTypeClass)),
  },
  staticTextPrints: {
    type: new ArrayType(new EntityType(StaticTextPrint)),
  },
  templates: {
    type: new ArrayType(new EntityType(TemplateClass)),
  },
  transactions: {
    type: new ArrayType(new ObjectType()),
  },
  units: {
    type: new ObjectType(),
  },
  vat: {
    type: new ObjectType(),
  },
}) {}
