import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.ADD_CHAR,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(() => {
        this.state.inputBuffer = this.state.productFlow.product.originalDocumentFiscalReference ?? '';
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.inputBuffer) return;

        this.state.productFlow.product.setOriginalDocumentFiscalReferenceWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          this.state.inputBuffer,
        );

        await this.transitionToNextStep();
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        try {
          const {receipts} = await this.searchSubject(event.value);

          if (!receipts.length) {
            this.dispatchEvent(new Event(RegisterStoreErrors.NO_RECEIPTS_FOUND));
            return;
          }

          const [receipt] = receipts;

          this.state.productFlow.product.setOriginalDocumentFiscalReferenceWithinContext(
            this.state.sellDocument,
            this.state.editOf,
            receipt.documentBarcode,
          );

          await this.transitionToNextStep();
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
    },
  };
}
