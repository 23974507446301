
import {defineComponent} from 'vue';
import {WorkflowActions, WorkflowKeyboards} from '../types';
import Key from '@/Components/KeyboardKey.vue';
import IconBack from '@/Components/Icons/IconUArrowLeft.vue';
import IconBackspace from '@/Components/Icons/IconBackspace.vue';
import IconEnter from '@/Components/Icons/IconEnter.vue';
import IconKeyboard from '@/Components/Icons/IconKeyboard.vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import IconDrawer from '@/Components/Icons/IconDrawer.vue';
import {HLAsyncButton} from '@designeo/vue-helpers';

export default defineComponent({
  components: {
    HLAsyncButton,
    IconDrawer,
    IconBackspace,
    IconEnter,
    IconBack,
    IconKeyboard,
    Key,
  },
  emits: ['input', 'layout'],
  setup() {
    const workflowStore = useWorkflowStore();

    return {
      workflowStore,
      WorkflowActions,
      WorkflowKeyboards,
    };
  },
});
