import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const ShopPointOfSalePropDef = {
  id: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  isActive: {
    type: new BooleanType(),
  },
  ipAddress: {
    type: new StringType(),
  },
  port: {
    type: new NumberType(),
  },
  lastTurnoverSync: {
    type: new DateTimeType(),
  },
};
export default class GeneratedShopPointOfSale extends createEntity(ShopPointOfSalePropDef) {}
