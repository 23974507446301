
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  name: 'StepSafeBagPrintDocument',
  components: {AsyncButton, WorkflowTransitions, HLStep},
  setup() {
    const stepType = WorkflowStepTypes.SafeBagPrintDocument;

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
