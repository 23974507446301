import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {InactivityCondition} from '@/Helpers/inactivity';
import {fixDecimals} from '@/Helpers/math';

export interface IInactivityStore {
  interval: ReturnType<typeof setInterval>,
  now: number,
  limit: number,
  tickBy: number,
  currentCondition: InactivityCondition
}

const createInitState = (data?: Partial<IInactivityStore>): IInactivityStore => ({
  interval: null,
  now: 0,
  limit: null,
  tickBy: null,
  currentCondition: null,
});

export class InactivityStore extends Store<IInactivityStore> {
  constructor() {
    super(createInitState());
  }

  stopInterval = action(() => {
    this.pauseInterval();
    this.state.now = 0;
  })

  pauseInterval = action(() => {
    if (this.state.interval !== null) {
      clearInterval(this.state.interval);
      this.state.interval = null;
    }
  })

  startInterval = action(() => {
    this.pauseInterval();
    this.state.interval = setInterval(async () => {
      this.state.now = Math.min(this.state.now + this.state.tickBy, this.state.limit);
      if (this.state.now >= this.state.limit) {
        this.pauseInterval();
        if (!this.state.currentCondition.isFulfilled()) {
          this.resetNow();
          this.startInterval();
          return;
        }

        this.destroyInterval();
        await this.state.currentCondition.onFulfill();
      }
    }, this.state.tickBy);
  })

  ensureIntervalRun = action(() => {
    if (!this.state.currentCondition?.hasValidTimeout) {
      return;
    }

    if (this.state.interval) {
      return;
    }

    this.startInterval();
  })

  destroyInterval = action(() => {
    this.stopInterval();
    this.state.limit = null;
    this.state.tickBy = null;
  })

  setInterval = action((timeout: number, {tickBy = 1000} = {}) => {
    this.destroyInterval();
    this.state.limit = timeout;
    this.state.tickBy = tickBy;
  })

  resetNow = action(() => {
    this.state.now = 0;
  })

  setCondition = action((condition: InactivityCondition) => {
    this.state.currentCondition = condition;

    if (!this.state.currentCondition) {
      this.destroyInterval();
      return;
    }

    if (!this.state.currentCondition.hasValidTimeout) {
      this.destroyInterval();
      return;
    }


    if (this.state.currentCondition.type !== condition.type) { // special case if we swap conditions while timeout runs
      this.resetNow();
    }

    this.setInterval(condition.timeout);
  })

  now = getter(() => {
    return this.state.now;
  })

  tickBy = getter(() => {
    return this.state.tickBy;
  })

  limit = getter(() => {
    return this.state.limit;
  })

  currentCondition = getter(() => {
    return this.state.currentCondition;
  })

  progress = getter(() => {
    if (!this.limit.value) {
      return 0;
    }

    return 100 - fixDecimals(this.now.value / this.limit.value * 100);
  })

  showProgress = getter(() => {
    if (!this.limit.value) {
      return false;
    }

    if (!this.state.currentCondition?.showProgressAt) {
      return false;
    }

    return this.now.value >= Math.min(this.state.currentCondition?.showProgressAt, this.limit.value);
  })
}

const storeIdentifier = 'InactivityStore';

export const configureInactivityStore = createConfigureStore<typeof InactivityStore>(storeIdentifier);
export const useInactivityStore = createUseStore(InactivityStore, storeIdentifier);
