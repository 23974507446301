import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {
  EshopOrdersActions,
  EshopOrdersInputEvent,
  EshopOrdersOrderStatus,
  EshopOrdersState,
} from '../types';
import {BufferedInput} from '@/Modules/Register/services/KeyboardBuffer';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import OrderNotificationCountDto from '@/Model/Entity/OrderNotificationCountDto';
import {
  every,
  flow,
  isEmpty,
  omit,
} from 'lodash-es';

export interface IEshopOrdersStore {
  state: EshopOrdersState,
  filters: {
    orderNumber?: string,
    customerName?: string,
    customerPhone?: string,
    customerEmail?: string,
    orderDate?: Date[],
    eshop?: string,
    shopCode?: string,
    orderStatus?: EshopOrdersOrderStatus,
  },
  queryId: string,
  orderNotification: OrderNotificationCountDto
}

const createFiltersInitState = (data?: Partial<IEshopOrdersStore['filters']>) => ({
  orderNumber: '',
  customerName: '',
  customerPhone: '',
  customerEmail: '',
  orderDate: [],
  eshop: '',
  shopCode: useConfigurationStore().configuration.value?.general?.pos?.shop?.code,
  orderStatus: null,
  ...data,
});

const createInitState = (data: Partial<IEshopOrdersStore> = {}): IEshopOrdersStore => ({
  state: EshopOrdersState.DEFAULT,
  filters: createFiltersInitState(),
  orderNotification: null,
  queryId: null,
  ...data,
});

export class EshopOrdersStore extends Store<IEshopOrdersStore> {
  constructor() {
    super(createInitState());
  }

  transitions: {[key in EshopOrdersState]?: {[key in EshopOrdersActions]?: any}} = {
    [EshopOrdersState.ENTER_ORDER_NUMBER]: {
      [EshopOrdersActions.ADD_NUMBER]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.orderNumber += event.value;
      }),
      [EshopOrdersActions.ADD_CHAR]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.orderNumber += event.value;
      }),
      [EshopOrdersActions.BACKSPACE]: action(async () => {
        this.state.filters.orderNumber = this.state.filters.orderNumber
          .slice(0, this.state.filters.orderNumber.length - 1);
      }),
    },
    [EshopOrdersState.ENTER_CUSTOMER_NAME]: {
      [EshopOrdersActions.ADD_NUMBER]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.customerName += event.value;
      }),
      [EshopOrdersActions.ADD_CHAR]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.customerName += event.value;
      }),
      [EshopOrdersActions.BACKSPACE]: action(async () => {
        this.state.filters.customerName = this.state.filters.customerName
          .slice(0, this.state.filters.customerName.length - 1);
      }),
    },
    [EshopOrdersState.ENTER_CUSTOMER_PHONE]: {
      [EshopOrdersActions.ADD_NUMBER]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.customerPhone += event.value;
      }),
      [EshopOrdersActions.ADD_CHAR]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.customerPhone += event.value;
      }),
      [EshopOrdersActions.BACKSPACE]: action(async () => {
        this.state.filters.customerPhone = this.state.filters.customerPhone
          .slice(0, this.state.filters.customerPhone.length - 1);
      }),
    },
    [EshopOrdersState.ENTER_CUSTOMER_EMAIL]: {
      [EshopOrdersActions.ADD_NUMBER]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.customerEmail += event.value;
      }),
      [EshopOrdersActions.ADD_CHAR]: action((event: EshopOrdersInputEvent) => {
        this.state.filters.customerEmail += event.value;
      }),
      [EshopOrdersActions.BACKSPACE]: action(async () => {
        this.state.filters.customerEmail = this.state.filters.customerEmail
          .slice(0, this.state.filters.customerEmail.length - 1);
      }),
    },
    [EshopOrdersState.CHOOSE_ORDER_DATE]: {
      [EshopOrdersActions.ENTER]: action((event: EshopOrdersInputEvent) => {
        if (!event.value) return;
        this.state.filters.orderDate = event.value;
      }),
    },
    [EshopOrdersState.SELECT_ESHOP]: {
      [EshopOrdersActions.ENTER]: action((event: EshopOrdersInputEvent) => {
        if (!event.value) return;
        this.state.filters.eshop = event.value;
        this.state.filters.orderStatus = null;
      }),
    },
    [EshopOrdersState.SELECT_ORDER_STATUS]: {
      [EshopOrdersActions.ENTER]: action((event: EshopOrdersInputEvent) => {
        if (!event.value) return;
        this.state.filters.orderStatus = event.value;
      }),
    },
  }

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: EshopOrdersActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: EshopOrdersActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: EshopOrdersActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: EshopOrdersActions.CANCEL,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        await this.onEventInput({
          type: EshopOrdersActions.ADD_CHAR,
          value: key.key,
        });
      }
    }
  });

  changeState = action((state: EshopOrdersState) => {
    this.state.state = state;
    emitTestEvent(TestEvent.REFERENTIAL_DOCUMENTS_STATE_CHANGED);
    emitTestEvent(`${TestEvent.REFERENTIAL_DOCUMENTS_STATE_CHANGED}:${state}`);
  })

  resetFilters = action((state = createFiltersInitState()) => {
    this.state.filters = Object.assign(this.state.filters, state);
  })

  resetState = action(() => {
    this.state = Object.assign(this.state, createInitState({
      /**
       * OrderNotification is persistant between state reset
       */
      orderNotification: this.state.orderNotification,
    }));
  })

  onEventInput = action(async (event: EshopOrdersInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  setFilterValue = action((key: keyof IEshopOrdersStore['filters'], value: any) => {
    this.state.filters[key] = value;
  })

  setQueryId = action((value: string) => {
    this.state.queryId = value;
  })

  setOrderNotification = action((value: OrderNotificationCountDto) => {
    this.state.orderNotification = value;
  })

  filters = getter(() => this.state.filters)

  areFiltersEmpty = getter(() => {
    return flow([
      (filters) => omit(filters, ['shopCode']),
      (filters) => every(filters, isEmpty),
    ])(this.state.filters);
  })

  defaultQueryId = getter(() => {
    const tabs = useConfigurationStore().configuration.value?.features?.eshop?.tabs ?? [];
    const [tab] = tabs;

    if (!tab) {
      return null;
    }

    return tab.queryId;
  })

  queryId = getter(() => this.state.queryId)

  orderNotification = getter(() => this.state.orderNotification)

  isStateDefault = getter(() => this.state.state === EshopOrdersState.DEFAULT)
  isStateEnterOrderNumber = getter(() => this.state.state === EshopOrdersState.ENTER_ORDER_NUMBER)
  isStateEnterCustomerName = getter(() => this.state.state === EshopOrdersState.ENTER_CUSTOMER_NAME)
  isStateEnterCustomerPhone = getter(() => this.state.state === EshopOrdersState.ENTER_CUSTOMER_PHONE)
  isStateEnterCustomerEmail = getter(() => this.state.state === EshopOrdersState.ENTER_CUSTOMER_EMAIL)
  isStateChooseOrderDate = getter(() => this.state.state === EshopOrdersState.CHOOSE_ORDER_DATE)
  isStateSelectEshop = getter(() => this.state.state === EshopOrdersState.SELECT_ESHOP)
  isStateSelectOrderStatus = getter(() => this.state.state === EshopOrdersState.SELECT_ORDER_STATUS)
}

const storeIdentifier = 'EshopOrdersStore';

export const configureEshopOrdersStore = createConfigureStore<typeof EshopOrdersStore>(
  storeIdentifier,
);
export const useEshopOrdersStore = createUseStore(EshopOrdersStore, storeIdentifier);
