import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import KorunkaExternalProgramDto from '../KorunkaExternalProgramDto';


export const ExternalProgramsDtoPropDef = {
  korunka: {
    type: new EntityType(KorunkaExternalProgramDto),
  },
};
export default class GeneratedExternalProgramsDto extends createEntity(ExternalProgramsDtoPropDef) {}
