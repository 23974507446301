import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CurrencyStateDto from '../CurrencyStateDto';


export const SafeStateReportDtoPropDef = {
  safe: {
    type: new EntityType(CurrencyStateDto),
  },
  safebag: {
    type: new EntityType(CurrencyStateDto),
  },
  totalInSafe: {
    type: new EntityType(CurrencyStateDto),
  },
};
export default class GeneratedSafeStateReportDto extends createEntity(SafeStateReportDtoPropDef) {}
