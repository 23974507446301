
import {
  defineComponent,
} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {HLScrollIntoView} from '@/Components/HLScrollIntoView';

export default defineComponent({
  name: 'StepBalanceStockEdit',
  components: {
    HLStep,
    FormInput,
    HLInput,
    HLScrollIntoView,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.BalanceStockEdit;

    const configurationStore = useConfigurationStore();

    const {currencyFormat: originalCurrencyFormat} = useFilters();

    const currencyFormat = (value) => originalCurrencyFormat(value, configurationStore.localCurrency.value.symbol);

    return {
      stepType,
      currencyFormat,
    };
  },
});
