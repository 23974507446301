import IMask from 'imask';
import {
  find,
  map,
  pickBy,
} from 'lodash-es';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {Autocomplete} from '@/constants/autocomplete';

interface GroupedMask {
  mask: IMask.MaskedDynamic,
  prefix?: string,
  minAutocompleteLength: number
}

export const createPhoneNumberMasks = (masks, withPrefix = true): GroupedMask[] => {
  masks = pickBy(masks, (value, key) => (key?.[0] === '+'));
  return map(masks, (patterns, prefix) => {
    if (!Array.isArray(patterns)) {
      patterns = [patterns];
    }

    const iMasks: IMask.MaskedPattern[] = patterns.map((pattern) => {
      const iMaskPattern = withPrefix ?
        `[{+${prefix.substring(1).replace('0', '\\0')}}] ${pattern}` :
        pattern;
      return IMask.createMask({mask: iMaskPattern});
    });

    const masksLengths = map(iMasks, ({mask}) => mask.match(/[+\d]/g).length);
    const minMaskLength = Math.min(...masksLengths);

    let minAutocompleteLength = minMaskLength;
    if (minAutocompleteLength < Autocomplete.minPhoneLength) {
      minAutocompleteLength = Autocomplete.minPhoneLength;
    }

    return {
      mask: IMask.createMask({
        mask: iMasks,
      }),
      prefix,
      minAutocompleteLength,
    };
  });
};

const fallbackPhoneNumberMask: GroupedMask = {
  mask: IMask.createMask({
    mask: [
      IMask.createMask({
        mask: '[{+}]000',
      }),
    ],
  }),
  minAutocompleteLength: 9,
};

const resolveMask = (mask: GroupedMask, value: string, withPrefix: boolean = true) => {
  const phoneNumberMask = mask.mask;
  const maskedPhoneNumber = phoneNumberMask.resolve(value);
  const unmaskedPhoneNumber = phoneNumberMask.unmaskedValue;
  const isPhoneNumberValid = withPrefix ?
    mask.prefix && phoneNumberMask.currentMask.isComplete :
    phoneNumberMask.currentMask.isComplete;
  const validation = {
    name: 'phone',
    message: 'validations.string.phone',
    test: () => isPhoneNumberValid,
  };

  return {
    phoneNumberGroup: mask,
    phoneNumberMask,
    maskedPhoneNumber,
    unmaskedPhoneNumber,
    isPhoneNumberValid,
    validation,
  };
};

export const resolvePhoneNumberMask = (groupedMasks: GroupedMask[]) => (value) => {
  value = value ?? '';
  if (value[0] !== '+') {
    value = `+${value}`;
  }

  const phoneNumberGroup = find(groupedMasks, ({prefix}) => {
    return value.indexOf(prefix) === 0;
  }) ?? fallbackPhoneNumberMask;

  return resolveMask(phoneNumberGroup, value, true);
};

export const resolvePhoneNumberMaskWithOptionalPrefix = (
  groupedMasksWithPrefix: GroupedMask[],
  groupedMasksWithoutPrefix: GroupedMask[],
) => (value: string) => {
  value = value ?? '';

  if (!value) {
    return resolveMask(
      fallbackPhoneNumberMask,
      value,
      false,
    );
  }

  const valueStartsWithPlus = value[0] === '+';

  // Find mask with prefix (has to start with +)
  const phoneNumberGroupMaskWithPrefix = find(
    groupedMasksWithPrefix,
    (groupedMask) => {
      const valueStartsWithPrefix = value.startsWith(groupedMask.prefix);

      return valueStartsWithPrefix;
    },
  );

  const phoneNumberGroupMaskWithoutPrefix: GroupedMask = {
    mask: IMask.createMask({
      mask: groupedMasksWithoutPrefix.map((groupedMask) => groupedMask.mask),
    }),
    minAutocompleteLength: Autocomplete.minPhoneLength,
  };

  const maskToResolve = valueStartsWithPlus ?
    phoneNumberGroupMaskWithPrefix :
    phoneNumberGroupMaskWithoutPrefix;

  return resolveMask(
    maskToResolve ?? fallbackPhoneNumberMask,
    value,
    valueStartsWithPlus,
  );
};

export const usePhoneNumberMaskResolver = () => {
  const {configuration} = useConfigurationStore();
  if (!configuration.value) return;

  const {phoneMasks, defaultPhonePrefix, phoneRechargePrefix} = configuration.value.general;
  // Main phone numbers masks
  const groupedMasksWithPrefix = createPhoneNumberMasks(phoneMasks, true);
  // Used when number has no prefix
  const groupedMasksWithoutPrefix = createPhoneNumberMasks(phoneMasks, false);

  const resolvePhoneRechargeMask = (value) => {
    if (phoneRechargePrefix) {
      if (value.indexOf(phoneRechargePrefix) === -1) {
        value = phoneRechargePrefix;
      }
      const phoneRechargeMask = <GroupedMask> find(groupedMasksWithPrefix, {prefix: phoneRechargePrefix});
      return resolveMask(phoneRechargeMask, value);
    }

    return resolvePhoneNumberMask(groupedMasksWithPrefix)(value);
  };

  const resolveDefaultPhoneNumber = (value = null) => {
    return value || defaultPhonePrefix;
  };

  return {
    resolvePhoneNumberMask: resolvePhoneNumberMaskWithOptionalPrefix(groupedMasksWithPrefix, groupedMasksWithoutPrefix),
    resolvePhoneRechargeMask,
    resolveDefaultPhoneNumber,
  };
};
