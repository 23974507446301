import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import LogisticDocumentDto from '../LogisticDocumentDto';


export const LogisticDocumentsReportDtoPropDef = {
  documents: {
    type: new ArrayType(new EntityType(LogisticDocumentDto)),
  },
};
export default class GeneratedLogisticDocumentsReportDto extends createEntity(LogisticDocumentsReportDtoPropDef) {}
