import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const MessageAddressBookDtoPropDef = {
  id: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
};
export default class GeneratedMessageAddressBookDto extends createEntity(MessageAddressBookDtoPropDef) {}
