// Korunka types separeted in its own file because circular dependencies

export const KORUNKA_PREPARED_TICKET_PREFIX = 'K';

export enum KorunkaCantPayoutReasons {
  NotWinningTicket = 'NOT_WINNING_TICKET',
  TicketAlreadyPaidOut = 'TICKET_ALREADY_PAID_OUT',
  PayoutTimeExpired = 'PAY_OUT_TIME_EXPIRED',
  PayoutAtHeadOffice = 'PAYOUT_AT_HEADOFFICE',
  PayoutAtBranch = 'PAYOUT_AT_BRANCH',
}

export enum KorunkaDrawStatus {
  Planned = 'PLANNED',
  Evaluating = 'EVALUATING',
  Evaluated = 'EVALUATED',
}

export enum KorunkaEntityTypes {
  Sestka = 'SESTKA',
  DvacetZa20 = 'DVACETZA20',
  HopTrop = 'HOPTROP',
  Kombi = 'KOMBI',
  KombiPlus = 'KOMBI_PLUS',
  Ticket = 'TICKET', // Ticket Detail
  ScanTicket = 'SCAN_TICKET',
  PreparedTicket = 'PREPARED_TICKET',
  Card = 'CARD',
  ScanResult = 'SCAN_RESULT',
}

export enum TicketStatus {
  NotPaid = 'NOT_PAID',  // Dont use: Doesnt exist anymore
  NotEvaluated = 'NOT_EVALUATED',  // Payed bet ticket that has not yet been evaluated (not drawn)
  PartlyEvaluated = 'PARTLY_EVALUATED',  // Payed bet ticket that has been partly evaluated (some draws are drawn)
  Evaluated = 'EVALUATED',  // Payed bet ticket that has been fully evaluated (all draws are drawn)
  Evaluating = 'EVALUATING',  // Dont use: "Locked" status, cant cancel anymore (use actions)
  PreEvaluating = 'PRE_EVALUATED',  // Dont use: "Locked" status, cant cancel anymore (use actions)
  Won = 'WON',  // Win bet ticket
  Loss = 'LOSS',  // Lost bet ticket
  PreCanceled = 'PRE_CANCELED',  // Dont use: Doesnt exist anymore
  Canceled = 'CANCELED',  // Canceled bet ticket
}
