import {DateTime} from 'luxon';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {createWarehouseSummary} from '@/Modules/Workflow/Workflow/layout/custom/warehouseOperations/helpers';
import {
  WarehouseOperationListErrors,
  WarehouseOperationListFilters,
  WarehouseOperationListStates,
  WarehouseOperationStepFieldGroups,
} from '@/constants/warehouseOperations';
import {
  find,
  isEmpty,
  isNil,
  isNumber,
  map,
  reduce,
} from 'lodash-es';
import {action} from '@designeo/vue-helpers';
import {
  DocumentDto,
  DocumentLogisticItemDto,
} from '@/Model/Entity';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {apiInStoreManagementArticleSearch} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';

export class WorkflowStepWarehouseOperationList extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationList;
  }

  get type() {
    return WorkflowStepWarehouseOperationList.type;
  }

  get component() {
    return markRaw(require('./StepWarehouseOperationList.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get filters() {
    return [
      WarehouseOperationListFilters.documentDate,
      WarehouseOperationListFilters.documentType,
      WarehouseOperationListFilters.stateOfProcessing,
      // WarehouseOperationListFilters.vendor,
      WarehouseOperationListFilters.reference,
      WarehouseOperationListFilters.sourcePlant,
    ];
  }

  createFilterDataPath(filter: string) {
    return `filters.${filter}`;
  }

  sanitizeDocumentDate(from: Date, to: Date) {
    return [
      DateTime
        .fromJSDate(from)
        .startOf('day')
        .toJSDate(),
      DateTime
        .fromJSDate(to)
        .endOf('day')
        .toJSDate(),
    ];
  }

  get filterTypeDocumentDateDefaultValue() {
    const {defaultValue = null} = this.step.filters?.[WarehouseOperationListFilters.documentDate] ?? {};


    if (isNil(defaultValue) || !isNumber(defaultValue)) {
      return [
        ...this.sanitizeDocumentDate(
          new Date((+new Date()) - 7 * 24 * 60 * 60 * 1000),
          new Date(),
        ),
      ];
    }

    return [
      ...this.sanitizeDocumentDate(
        new Date((+new Date()) - defaultValue * 24 * 60 * 60 * 1000),
        new Date(),
      ),
    ];
  }

  getFilterDefaultValue(filter: WarehouseOperationListFilters) {
    switch (filter) {
    case WarehouseOperationListFilters.documentDate:
      return this.filterTypeDocumentDateDefaultValue;
    default:
      return this.step.filters?.[filter]?.defaultValue ??
          undefined;
    }
  }

  async beforeEnter() {
    if (this.stepInit) {
      return;
    }

    for (const filter of this.filters) {
      this.dataSetter(this.createFilterDataPath(filter), (currentValue) => {
        if (currentValue) {
          return currentValue;
        }

        return this.getFilterDefaultValue(filter);
      });
    }

    this.stepInit = true;
  }

  async beforeContinue() {
    await this.hydrateLogisticItems();
  }

  async hydrateLogisticItem(item: DocumentLogisticItemDto) {
    let documentItems = sanitizeApiSearch(await apiInStoreManagementArticleSearch({
      params: {
        code: item.gtin || item.internalNumberWithBatch,
      },
    })).documentItems;

    if (documentItems.length === 0) {
      throw new Error(WarehouseOperationListErrors.noDocumentItemCounterpart);
    }

    documentItems = map(documentItems, (documentItem) => {
      const expandedDocumentItem = documentItem.expandExpandableSet();
      expandedDocumentItem.sanitize();
      return expandedDocumentItem;
    });

    const documentItemWithInternalNumber = find(
      documentItems,
      (documentItem) => {
        if (!documentItem.internalNumber) {
          return false;
        }

        if (item.batch) {
          return documentItem.batch === item.batch;
        }

        return true;
      },
    );

    if (!documentItemWithInternalNumber) {
      console.error(`No document item counterpart, logistic item: ${item?.gtin || item?.internalNumberWithBatch}`);
      throw new Error(WarehouseOperationListErrors.noDocumentItemCounterpart);
    }

    item.description = documentItemWithInternalNumber.description;
    item.gtin = documentItemWithInternalNumber.gtin;
    item.internalNumber = documentItemWithInternalNumber.internalNumber;
    item.itemType = documentItemWithInternalNumber.itemType;
    item.price = item.price ?? documentItemWithInternalNumber.priceAction;
    item.quantity = item.quantity ?? 1;
    item.saleValidFrom = item.saleValidFrom ?? documentItemWithInternalNumber.saleValidFrom;
    item.saleValidTill = item.saleValidTill ?? documentItemWithInternalNumber.saleValidTill;
    item.unit = documentItemWithInternalNumber.unit;
  }

  async hydrateLogisticItems() {
    const logisticDocument = this.logisticDocument?.clone();

    if (!logisticDocument) {
      return;
    }

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      for (const logisticItem of logisticDocument.logisticItems) {
        await this.hydrateLogisticItem(logisticItem);
      }

      this.logisticDocument = logisticDocument;
    } catch (e) {
      if (e.message === WarehouseOperationListErrors.noDocumentItemCounterpart) {
        this.messageBus.dispatchEvent(new Event(e.message));
      } else {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      }
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get filledFilters() {
    return reduce(this.filters, (acc, filter) => {
      const filterValue = this.getFieldValue(this.createFilterDataPath(filter), null);

      if (isEmpty(filterValue)) {
        return acc;
      }

      return [...acc, {filter, value: filterValue}];
    }, []);
  }

  createFieldDataPath(field: string) {
    return `${WarehouseOperationStepFieldGroups.list}.${field}`;
  }

  get logisticDocument() {
    const logisticDocument = this.getFieldValue(this.createFieldDataPath(WorkflowStepField.document), null);

    if (!logisticDocument) {
      return null;
    }

    return new DocumentDto(logisticDocument);
  }

  set logisticDocument(value: DocumentDto) {
    this.dataSetter(this.createFieldDataPath(WorkflowStepField.document), () => value?.toJson());
    this.operationEntityPropLogisticDocument = value;
  }

  get instructions() {
    if (this.logisticDocument) {
      return createWarehouseSummary({
        attrs: {
          logisticDocument: this.logisticDocument,
        },
      });
    }

    return null;
  }

  get disabledNextStep() {
    return isNil(this.logisticDocument);
  }

  get currentView() {
    return this.getFieldValue(WorkflowStepField.state, WarehouseOperationListStates.listView);
  }

  set currentView(val) {
    this.dataSetter(WorkflowStepField.state, () => val);
  }

  get isFilterView() {
    return this.currentView === WarehouseOperationListStates.filterView;
  }

  set isFilterView(value) {
    this.currentView = value ? WarehouseOperationListStates.filterView : WarehouseOperationListStates.listView;
  }

  get transitions() {
    if (!this.isFilterView) {
      return {};
    }

    return {
      ...reduce(this.filters, (acc, filter) => {
        switch (filter) {
        case WarehouseOperationListFilters.documentDate:
          return {
            ...acc,
            ...this.withFieldActions(this.createFilterDataPath(filter), (fieldActions) => ({
              [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
                fieldActions[WorkflowActions.ENTER](this.sanitizeDocumentDate(event.value[0], event.value[1]));
              }),
            })),
          };
        case WarehouseOperationListFilters.documentType:
        case WarehouseOperationListFilters.stateOfProcessing:
        case WarehouseOperationListFilters.vendor:
        case WarehouseOperationListFilters.sourcePlant:
          return {
            ...acc,
            ...this.withFieldActions(this.createFilterDataPath(filter), (fieldActions) => ({
              [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
                fieldActions[WorkflowActions.ENTER](event.value);
              }),
            })),
          };
        case WarehouseOperationListFilters.reference:
          return {
            ...acc,
            ...this.withFieldActions(this.createFilterDataPath(filter), (fieldActions) => ({
              [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
                fieldActions[WorkflowActions.ADD_NUMBER](event.value);
              }),
              [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent) => {
                fieldActions[WorkflowActions.ADD_NUMBER](event.value);
              }),
              [WorkflowActions.BACKSPACE]: action(() => {
                fieldActions[WorkflowActions.BACKSPACE]();
              }),
            })),
          };
        }
      }, {}),
    };
  }
}
