import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FiscalCommands from '../FiscalCommands';
import {BooleanType} from '@designeo/apibundle-js';
import TransactionDirectionType from '../TransactionDirectionType';
import {ArrayType} from '@designeo/apibundle-js';
import Transaction from '../Transaction';
import TransactionItem from '../TransactionItem';
import TransactionPayment from '../TransactionPayment';
import TransactionDiff from '../TransactionDiff';
import TransactionChangeHistory from '../TransactionChangeHistory';
import TransactionExternalPosSyncItem from '../TransactionExternalPosSyncItem';


export const TransactionPropDef = {
  id: {
    type: new StringType(),
  },
  referentialUniqueidentifier: {
    type: new StringType(),
  },
  documentNumber: {
    type: new StringType(),
  },
  order: {
    type: new NumberType(),
  },
  cashierId: {
    type: new StringType(),
  },
  shopId: {
    type: new StringType(),
  },
  pointOfSaleId: {
    type: new StringType(),
  },
  documentBarcode: {
    type: new StringType(),
  },
  documentDate: {
    type: new DateTimeType(),
  },
  totalPrice: {
    type: new NumberType(),
  },
  amount: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  documentType: {
    type: new EntityType(FiscalCommands),
  },
  documentSubType: {
    type: new EntityType(FiscalCommands),
  },
  operation: {
    type: new StringType(),
  },
  statusCode: {
    type: new NumberType(),
  },
  errorText: {
    type: new StringType(),
  },
  isCanceled: {
    type: new BooleanType(),
  },
  transactionCode: {
    type: new StringType(),
  },
  journalNumber: {
    type: new NumberType(),
  },
  source: {
    type: new EntityType(TransactionDirectionType),
  },
  destination: {
    type: new EntityType(TransactionDirectionType),
  },
  vendor: {
    type: new StringType(),
  },
  envelopeNumber: {
    type: new StringType(),
  },
  customerNumber: {
    type: new StringType(),
  },
  customerCardNumber: {
    type: new StringType(),
  },
  pointsCollected: {
    type: new NumberType(),
  },
  pointsBurned: {
    type: new NumberType(),
  },
  jsonData: {
    type: new StringType(),
  },
  resultJsonData: {
    type: new StringType(),
  },
  rawPrintOutputJson: {
    type: new StringType(),
  },
  disabledSync: {
    type: new BooleanType(),
  },
  isSynced: {
    type: new BooleanType(),
  },
  requireUpdateStamp: {
    type: new DateTimeType(),
  },
  updatedStamp: {
    type: new DateTimeType(),
  },
  financialCloseDayId: {
    type: new StringType(),
  },
  isFinancialDayClosed: {
    type: new BooleanType(),
  },
  paymentIdsAggregate: {
    type: new StringType(),
  },
  // referentialTransactions: {
  //   type: new ArrayType(new EntityType(Transaction)),
  // },
  // original: {
  //   type: new EntityType(Transaction),
  // },
  // transactionItems: {
  //   type: new ArrayType(new EntityType(TransactionItem)),
  // },
  // transactionPayments: {
  //   type: new ArrayType(new EntityType(TransactionPayment)),
  // },
  // transactionDiffs: {
  //   type: new ArrayType(new EntityType(TransactionDiff)),
  // },
  // transactionChangeHistories: {
  //   type: new ArrayType(new EntityType(TransactionChangeHistory)),
  // },
  fromExternalPos: {
    type: new StringType(),
  },
  isSyncToExternalPosRequired: {
    type: new BooleanType(),
  },
  externalReference: {
    type: new StringType(),
  },
  externalPosSyncItemsCollection: {
    type: new ArrayType(new EntityType(TransactionExternalPosSyncItem)),
  },
  externalPosSyncItems: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
  shopName: {
    type: new StringType(),
  },
  posCode: {
    type: new StringType(),
  },
};
export default class GeneratedTransaction extends createEntity(TransactionPropDef) {}
