import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const UpdatePrinterConfigurationDtoPropDef = {
  name: {
    type: new StringType(),
  },
  isDefault: {
    type: new BooleanType(),
  },
};
export default class GeneratedUpdatePrinterConfigurationDto extends createEntity(UpdatePrinterConfigurationDtoPropDef) {}
