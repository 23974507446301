
import {useFilters} from '@/Helpers/filters';
import {OrderDtoOrderEnvelopeDto} from '@/Model/Entity';
import {defineComponent, PropType} from 'vue';
import OrderItem from './OrderItem.vue';
export default defineComponent({
  components: {
    OrderItem,
  },
  props: {
    order: {
      type: Object as PropType<OrderDtoOrderEnvelopeDto>,
      required: true,
    },
  },
  setup() {
    const {currencyFormat} = useFilters();

    return {
      currencyFormat,
    };
  },
});
