
import {
  defineComponent,
  onMounted,
  ref,
} from 'vue';

export default defineComponent({
  name: 'ImageFallback',
  props: {
    src: {
      type: null,
      required: true,
    },
    imageClass: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const hasImageFailed = ref(false);
    const isImageLoaded = ref(false);

    onMounted(() => {
      if (props.src) {
        const image = new Image();
        image.onerror = () => {
          hasImageFailed.value = true;
        };
        image.onload = () => {
          isImageLoaded.value = true;
        };
        image.src = props.src;
      }
    });

    return {hasImageFailed, isImageLoaded};
  },
});
