
import {defineComponent, PropType} from 'vue';
import WarehouseOperationDistributionCenterListItem
// eslint-disable-next-line max-len
  from '@/Modules/Workflow/Step/StepWarehouseOperationDistributionCenter/WarehouseOperationDistributionCenterListItem.vue';
import DistributionCenterDto from '@/Model/Entity/DistributionCenterDto';

export default defineComponent({
  components: {WarehouseOperationDistributionCenterListItem},
  props: {
    list: {
      type: Array as PropType<Array<{isActive: boolean, entity: DistributionCenterDto}>>,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
    return {};
  },
});
