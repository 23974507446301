import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TicketPartnerResourceStatus from '../TicketPartnerResourceStatus';
import TicketPartnerResourceGame from '../TicketPartnerResourceGame';
import {DateTimeType} from '@designeo/apibundle-js';
import DrawPartnerResource from '../DrawPartnerResource';
import {ObjectType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import TicketResourceCombinations from '../TicketResourceCombinations';
import {BooleanType} from '@designeo/apibundle-js';


export const TicketPartnerResourcePropDef = {
  id: {
    type: new NumberType(),
  },
  branchId: {
    type: new NumberType(),
  },
  status: {
    type: new EntityType(TicketPartnerResourceStatus),
  },
  game: {
    type: new EntityType(TicketPartnerResourceGame),
  },
  dateCanceled: {
    type: new DateTimeType(),
  },
  betTime: {
    type: new DateTimeType(),
  },
  datePaidOut: {
    type: new DateTimeType(),
  },
  amountPaid: {
    type: new NumberType(),
  },
  amountWon: {
    type: new NumberType(),
  },
  winTax: {
    type: new NumberType(),
  },
  ticketNumber: {
    type: new NumberType(),
  },
  draw: {
    type: new EntityType(DrawPartnerResource),
  },
  numbers: {
    type: new ObjectType(),
  },
  tutovka: {
    type: new ArrayType(new NumberType()),
  },
  betsCombinations: {
    type: new ArrayType(new EntityType(TicketResourceCombinations)),
  },
  possibleWins: {
    type: new ObjectType(),
  },
  usedRandomBet: {
    type: new BooleanType(),
  },
  booster: {
    type: new BooleanType(),
  },
};
export default class GeneratedTicketPartnerResource extends createEntity(TicketPartnerResourcePropDef) {}
