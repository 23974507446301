
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WarehouseOperationAdditionalInfoFields} from '@/constants/warehouseOperations';
import {
  mapKeys,
  reduce,
} from 'lodash-es';
import {
  WorkflowStepWarehouseOperationAdditionalInfo,
} from '@/Modules/Workflow/Step/StepWarehouseOperationAdditionalInfo/WorkflowStepWarehouseOperationAdditionalInfo';
import FormInput from '@/Components/FormInput.vue';
import InputSelect from '@/Components/InputSelect.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';

export default defineComponent({
  name: 'StepWarehouseOperationAdditionalInfo',
  components: {
    IconCheck,
    AsyncButton,
    WorkflowTransitions,
    HLStep,
    HLInput,
    FormInput,
  },
  setup() {
    const stepType = WorkflowStepTypes.WarehouseOperationAdditionalInfo;
    const {workflowStep} = useStepTools<WorkflowStepWarehouseOperationAdditionalInfo>(stepType);

    const fieldsByKey = computed(() => {
      return mapKeys(fields.value, 'key');
    });

    const getLabelByFieldTypeSelect = ({field, value}, {options = fieldsByKey.value?.[field]?.options} = {}) => {
      return options.find((option) => option.id === value)?.label ?? 'N/A';
    };

    const sanitizeFieldSettings = (key, configSettings) => {
      switch (key as WarehouseOperationAdditionalInfoFields) {
      case WarehouseOperationAdditionalInfoFields.note:
      case WarehouseOperationAdditionalInfoFields.reference:
      case WarehouseOperationAdditionalInfoFields.packages:
        return {
          defaultValue: configSettings.defaultValue ?? null,
          readonly: configSettings.readonly ?? false,
          label: configSettings.label,
          getReadonlyValue() {
            return this.defaultValue;
          },
        };
      case WarehouseOperationAdditionalInfoFields.returnReason:
        return {
          component: InputSelect,
          options: workflowStep.value.configOptionsToSelectOptions(configSettings.options) ?? [],
          defaultValue: configSettings.defaultValue ?? null,
          readonly: configSettings.readonly ?? false,
          label: configSettings.label,
          getReadonlyValue() {
            return getLabelByFieldTypeSelect({
              field: key,
              value: this.defaultValue,
            }, {
              options: this.options,
            });
          },
        };
      }
    };

    /**
     * Field InputDateRange will be transformed to string within persist to storage
     */
    const fields = computed(() => {
      return reduce(
        workflowStep.value.fields,
        (acc, key) => {
          const configSettings = workflowStep.value.step?.fields?.[key];

          return [
            ...acc,
            {
              key: workflowStep.value.createFieldDataPath(key),
              ...sanitizeFieldSettings(key, configSettings),
            },
          ];
        },
        [],
      );
    });

    return {
      stepType,
      WorkflowStepField,
      fields,
    };
  },
});
