import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';
import {
  isNil,
  values,
  some,
} from 'lodash-es';
import {TicketStatus} from '../../Helpers/korunkaTypes';
import GeneratedTicketPartnerResourceV2 from './generated/TicketPartnerResourceV2';

export default class TicketPartnerResourceV2 extends GeneratedTicketPartnerResourceV2 {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get isSestka() {
    return this.game.value === KorunkaEntityTypes.Sestka;
  }

  get isDvacetZa20() {
    return this.game.value === KorunkaEntityTypes.DvacetZa20;
  }

  get isHopTrop() {
    return this.game.value === KorunkaEntityTypes.HopTrop;
  }

  get isKombi() {
    return this.game.value === KorunkaEntityTypes.Kombi;
  }

  get isKombiPlus() {
    return this.game.value === KorunkaEntityTypes.KombiPlus;
  }

  get isSupportedGame() {
    return some([
      this.isSestka,
      this.isDvacetZa20,
      this.isHopTrop,
      this.isKombi,
      this.isKombiPlus,
    ]);
  }

  get numbersArray() {
    if (this.isKombi || this.isKombiPlus || this.isDvacetZa20) {
      return values(this.numbers);
    }

    return values(this.numbers).flat();
  }

  get depositValue() {
    if (this.game.value === KorunkaEntityTypes.DvacetZa20) {
      return this.amountPaid / values(this.numbers).length;
    }

    return this.amountPaid;
  }

  get draw() {
    return this.betOnDraws[0].draw;
  }

  get firstBetOnDraw() {
    return this.betOnDraws[0];
  }

  get lastBetOnDraw() {
    return this.betOnDraws[this.betOnDraws.length - 1];
  }

  /**
   * returns scalar entity!
   */
  get game() {
    return this.gameType;
  }

  get amountWon() {
    return this.amountWonRealNotPaidOutSum;
  }

  get amountWonToPayout() {
    return this.betOnDraws.map((betOnDraw) => {
      const amountWon = betOnDraw.amountWon;
      const isWin = betOnDraw.status.value === TicketStatus.Won;
      const isPaidOut = !isNil(betOnDraw.datePaidOut);

      return isWin && !isPaidOut ? amountWon : 0;
    }).reduce((acc, curr) => acc + curr, 0);
  }

  get drawsRemaining() {
    const draws = this.betOnDraws.filter((betOnDraw) => {
      return betOnDraw.status.value === TicketStatus.NotEvaluated;
    });

    return draws.length;
  }

  get paidOutDrawsCount() {
    const draws = this.betOnDraws.filter((betOnDraw) => {
      return !!betOnDraw.datePaidOut;
    });

    return draws.length;
  }
}
