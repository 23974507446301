import {createEntity} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const ExpirationPredictionDtoPropDef = {
  date: {
    type: new DateTimeType(),
  },
  points: {
    type: new NumberType(),
  },
};
export default class GeneratedExpirationPredictionDto extends createEntity(ExpirationPredictionDtoPropDef) {}
