import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowKeyboards,
  WorkflowCodes,
} from '../types';
import {
  KEYBOARD_KEY_ARROW_DOWN,
  KEYBOARD_KEY_ARROW_LEFT,
  KEYBOARD_KEY_ARROW_RIGHT,
  KEYBOARD_KEY_ARROW_UP,
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_COMMA,
  KEYBOARD_KEY_DELETE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEY_MINUS,
  KEYBOARD_KEY_PERIOD,
  KEYBOARD_KEY_PLUS,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {BufferedInput, InputSource} from '../../Register/services/KeyboardBuffer';
import {workflowStoreStatePersist} from '@/Helpers/persist';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {Workflow} from '@/Modules/Workflow/Workflow/Workflow';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {Context} from '@/Helpers/Context';
import {
  keys,
  map,
  omit,
} from 'lodash-es';
import {submitJournalEventWorkflowFinished, submitJournalEventWorkflowStarted} from '@/Helpers/journal';
import {guid} from '@/Helpers/guid';

export interface IWorkflowStore {
  workflow: string,
  workflowByCode: {[key: string]: Workflow}
  keyboard: WorkflowKeyboards,
  buckets: Map<string, any>
}

export class WorkflowStore extends PersistentStore<IWorkflowStore> {
  constructor() {
    super({
      workflow: null,
      workflowByCode: {},
      keyboard: WorkflowKeyboards.NUMERIC,
      buckets: new Map(),
    }, workflowStoreStatePersist);
  }

  onKeyboardInput = action(async (bufferedInput: BufferedInput) => {
    for (const key of bufferedInput.keys) {
      if (bufferedInput.source === InputSource.SCANNER) {
        await this.onEventInput({
          type: WorkflowActions.SCANNER,
          value: map(bufferedInput.keys, 'key').join(''),
        });
        return; // or sanitize buffered input and fallthrough?
      }

      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        await this.onEventInput({
          type: WorkflowActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        await this.onEventInput({
          type: WorkflowActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        await this.onEventInput({
          type: WorkflowActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_DELETE) {
        await this.onEventInput({
          type: WorkflowActions.CLEAR,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        await this.onEventInput({
          type: WorkflowActions.CANCEL,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_LEFT) {
        await this.onEventInput({
          type: WorkflowActions.PREV,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_RIGHT) {
        await this.onEventInput({
          type: WorkflowActions.NEXT,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_UP) {
        await this.onEventInput({
          type: WorkflowActions.ARROW_UP,
        });
      } else if (key.key === KEYBOARD_KEY_ARROW_DOWN) {
        await this.onEventInput({
          type: WorkflowActions.ARROW_DOWN,
        });
      } else if (key.key === KEYBOARD_KEY_PLUS) {
        await this.onEventInput({
          type: WorkflowActions.ADD_PLUS,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_MINUS) {
        await this.onEventInput({
          type: WorkflowActions.ADD_MINUS,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_COMMA) {
        await this.onEventInput({
          type: WorkflowActions.ADD_COMMA,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_PERIOD) {
        await this.onEventInput({
          type: WorkflowActions.ADD_PERIOD,
          value: key.key,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        await this.onEventInput({
          type: WorkflowActions.ADD_CHAR,
          value: key.key,
        });
      }
    }
  });

  startWorkflow = action((
    workflowCode: WorkflowCodes,
    {overwrite = false, referer = '/', params = {}} = {},
  ) => {
    if (overwrite || !Object.prototype.hasOwnProperty.call(this.state.workflowByCode, workflowCode)) {
      this.state.workflowByCode[workflowCode] = new Workflow(workflowCode, {
        referer,
        context: new Context({params}),
      });
    }

    this.state.workflow = workflowCode;
    submitJournalEventWorkflowStarted(workflowCode);
  })

  leaveWorkflowUnfinished = action(async () => {
    if (this.currentWorkflow.value && this.currentWorkflow.value.canLeaveUnfinishedWF) {
      this.state.workflow = null;
      await this.persist();
    }
  });

  finishWorkflow = action(() => {
    const workflowCode = this.currentWorkflow.value.code;

    this.state.workflowByCode?.[workflowCode]?.stopCacheScope();

    delete this.state.workflowByCode[workflowCode];
    this.state.workflow = null;
    submitJournalEventWorkflowFinished(workflowCode);
  })

  finishAllWorkflows = action(async () => {
    if (!this.currentWorkflow.value) return;

    const workflowCode = this.currentWorkflow.value.code;
    this.state.workflow = null;

    this.state.workflowByCode?.[workflowCode]?.stopCacheScope();

    delete this.state.workflowByCode[workflowCode];

    const workflowList = [
      ...(workflowCode ? [workflowCode] : []),
      ...keys(omit(this.state.workflowByCode, [workflowCode])),
    ];

    for (const workflowListItem of workflowList) {
      submitJournalEventWorkflowFinished(workflowListItem);
    }

    await this.persist();
  })

  onEventInput = action(async (event: WorkflowInputEvent) => {
    try {
      const transitionsByActiveStep = this.currentWorkflow.value?.transitionsByActiveStep;
      const activeFieldTransitions = transitionsByActiveStep?.[this.currentWorkflow.value.activeField] ?? {};
      const hasEmptyTransitions = Object.keys(transitionsByActiveStep).length === 0;

      if (
        (
          hasEmptyTransitions ||
          !Object.prototype.hasOwnProperty.call(activeFieldTransitions, WorkflowActions.CANCEL)
        ) && event.type === WorkflowActions.CANCEL
      ) {
        await this.currentWorkflow.value.requestPreviousStep();
      } else if (
        hasEmptyTransitions &&
        event.type === WorkflowActions.ENTER
      ) {
        await this.currentWorkflow.value.requestNextStep();
      } else {
        await activeFieldTransitions?.[event.type]?.(event);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  })

  setKeyboard = action((keyboard: WorkflowKeyboards) => {
    this.state.keyboard = keyboard;
  })

  saveBucket = action((content, {key = guid()} = {}) => {
    this.state.buckets.set(key, content);

    return key;
  })

  /**
   * Pick is single use, it will be removed from the store
   */
  collectBucket = action((key) => {
    if (!this.state.buckets.has(key)) {
      console.warn(`Bucket with key ${key} does not exist`);
      return null;
    }

    const content = this.state.buckets.get(key);

    this.state.buckets.delete(key);

    return content;
  })

  buckets = getter(() => {
    return this.state.buckets;
  });

  currentWorkflow = getter(() => {
    return this.state.workflowByCode[this.state.workflow];
  })

  configurationStore = getter(() => {
    return useConfigurationStore();
  })

  keyboardNumeric = getter(() => {
    return this.state.keyboard === WorkflowKeyboards.NUMERIC;
  })

  keyboardQwerty = getter(() => {
    return this.state.keyboard === WorkflowKeyboards.QWERTY;
  })
}

const storeIdentifier = 'WorkflowStore';

export const configureWorkflowStore = createConfigureStore<typeof WorkflowStore>(storeIdentifier);
export const useWorkflowStore = createUseStore(WorkflowStore, storeIdentifier);
