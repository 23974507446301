import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const PriceListItemDtoPropDef = {
  internalNumber: {
    type: new StringType(),
  },
  priceConditionCode: {
    type: new StringType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  value: {
    type: new NumberType(),
  },
  vatCode: {
    type: new StringType(),
  },
};
export default class GeneratedPriceListItemDto extends createEntity(PriceListItemDtoPropDef) {}
