import {AbstractPromoFlow, createNewReactive} from '@/Modules/Register/PromoInteractionFlow/AbstractPromoFlow';
import {RegisterState, RegisterStoreErrors} from '@/Modules/Register/types';
import {PromoInteractionFlowTypes} from '@/Modules/Register/PromoInteractionFlow/index';
import {DocumentItemDto} from '@/Model/Entity';
import {
  flow,
  map,
  reject,
  sumBy,
  uniqWith,
} from 'lodash-es';
import {DynamicSetSelection} from '@designeo/pos-promotion-engine/src/blocks/types';
import {
  promotionByTypeThatMatch,
  PromotionMetaType,
} from '@designeo/pos-promotion-engine';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {AppLoaderEvent} from '@/Modules/Core/types';

export type SetItemPool = {
  index: number
  documentItem: DocumentItemDto,
  quantity: number,
  isSelected?: boolean,
}[]

export class DynamicSetSelectionFlow extends AbstractPromoFlow {
  public setItemsPool: SetItemPool = [];
  public setItemsMaxQuantity: number = 0;
  public activeSetItemIndex: number = 0;
  public highlightFlag: boolean = false;

  constructor(
    public dynamicSetSelection: DynamicSetSelection,
    public selection: DynamicSetSelection['selection'][number],
    public nextState?: RegisterState,
  ) {
    super(nextState);
  }

  static new = createNewReactive<DynamicSetSelectionFlow>();

  get setItems() {
    return map(this.setItemsPool, (setItem, index) => {
      return Object.assign(setItem, {
        isSelected: setItem.index === this.activeSetItemIndex,
      });
    });
  }

  get activeSetItem() {
    return this.setItems.find((setItem) => setItem.isSelected);
  }

  get setItemsQuantity() {
    return sumBy(this.setItems, (setItem) => setItem.quantity);
  }

  get addedPromotion() {
    const registerStore = useRegisterStore();

    return promotionByTypeThatMatch(
      registerStore.sellDocument.value,
      PromotionMetaType.DYNAMIC_SET_SELECTED,
      {
        documentItemUniqueIdentifier: this.dynamicSetSelection.documentItemUniqueIdentifier,
        promoCode: this.dynamicSetSelection.promoCode,
      },
    );
  }

  get isNumericInputUsable() {
    return this.selection.selectionStep === 1;
  }

  get isFinished() {
    return this.setItemsQuantity === this.setItemsMaxQuantity;
  }

  async submitDynamicSetSelectedPromotion() {
    const registerStore = useRegisterStore();

    const promotionDynamicSetSelected = {
      type: PromotionMetaType.DYNAMIC_SET_SELECTED,
      documentItemUniqueIdentifier: this.dynamicSetSelection.documentItemUniqueIdentifier,
      promoCode: this.dynamicSetSelection.promoCode,
      selectionKey: this.selection.key,
      ...(this.selection.allowCombination ? {
        items: flow([
          (setItems) => map(setItems, ({documentItem, quantity}) => {
            const item = documentItem.clone();

            item.quantity = quantity;

            return item.toJson();
          }),
          (documentItems) => reject(documentItems, (documentItem) => documentItem.quantity === 0),
        ])(this.setItemsPool),
      } : {}),
    };

    registerStore.sellDocument.value.promotions = [
      ...reject(
        registerStore.sellDocument.value.promotions,
        (promotion) => Object.entries({
          type: promotionDynamicSetSelected.type,
          documentItemUniqueIdentifier: promotionDynamicSetSelected.documentItemUniqueIdentifier,
          promoCode: promotionDynamicSetSelected.promoCode,
        }).every(([key, value]) => promotion[key] === value),
      ),
      promotionDynamicSetSelected,
    ];

    await this.destroy();
  }

  async abort() {
    const registerStore = useRegisterStore();

    registerStore.dispatchEvent(new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ABORTED));

    await this.destroy();
  }

  async destroy() {
    const registerStore = useRegisterStore();
    const addedPromotion = this.addedPromotion;

    await super.destroy();

    if (addedPromotion) {
      await registerStore.transitionToNextStep();
    }
  }

  async findAvailableDynamicSetItems() {
    const registerStore = useRegisterStore();

    try {
      registerStore.dispatchEvent(new Event(AppLoaderEvent.ON));

      const documentItems = flow([
        (documentItems: DocumentItemDto[]) => map(documentItems, (documentItem) => {
          const innerDocumentItem = documentItem.expandExpandableSet();

          innerDocumentItem.quantity = 1;

          return innerDocumentItem;
        }),
        (documentItems: DocumentItemDto[]) => reject(documentItems, (documentItem) => documentItem.isSet),
        (documentItems: DocumentItemDto[]) => uniqWith(documentItems, (itemA, itemB) => {
          if (itemA.gtin !== itemB.gtin) {
            return false;
          }

          if (itemA.internalNumberWithBatch !== itemB.internalNumberWithBatch) {
            return false;
          }

          return true;
        }),
      ])(await registerStore.searchByPromotionList(this.selection.combinationGroup)) as DocumentItemDto[];

      this.setItemsPool = map(documentItems, (documentItem, index) => ({
        index,
        documentItem,
        quantity: 0,
      }));

      this.activeSetItemIndex = this.setItems[0]?.index ?? 0;
      this.setItemsMaxQuantity = this.selection.quantity;


      if (this.setItemsPool.length === 0) {
        await this.abort();
      }
    } catch (e) {
      console.warn(`Trying to fetch combination group ${this.selection.combinationGroup} failed`);
      throw e;
    } finally {
      registerStore.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  canSetItemQuantityBeChangedTo(article: SetItemPool[number], quantity: number) {
    if (quantity < 0) {
      return false;
    }

    const newTotalQuantity = this.setItemsQuantity - article.quantity + quantity;

    return newTotalQuantity <= this.setItemsMaxQuantity && newTotalQuantity >= 0;
  }

  changeActiveSetItemQuantity(quantity: number) {
    this.activeSetItem.quantity = quantity;
  }

  canActiveSetItemQuantityBeChangedTo(quantity: number) {
    return this.canSetItemQuantityBeChangedTo(this.activeSetItem, quantity);
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.DYNAMIC_SET_SELECTION_FLOW,
      nextState: this.nextState,
      setItemsPool: map(this.setItemsPool, (setItem) => ({
        index: setItem.index,
        documentItem: setItem.documentItem.toJson(),
        quantity: setItem.quantity,
      })),
      setItemsMaxQuantity: this.setItemsMaxQuantity,
      activeSetItemIndex: this.activeSetItemIndex,
      highlightFlag: this.highlightFlag,
      dynamicSetSelection: this.dynamicSetSelection,
      selection: this.selection,
    };
  }

  static deserialize(ctx) {
    if (ctx === null) {
      return null;
    }

    const {
      nextState,
      setItemsPool,
      setItemsMaxQuantity,
      activeSetItemIndex,
      highlightFlag,
      dynamicSetSelection,
      selection,
    } = ctx;

    return AbstractPromoFlow.baseDeserialize(DynamicSetSelectionFlow, {
      setItemsPool: map(setItemsPool, (setItem) => ({
        index: setItem.index,
        documentItem: new DocumentItemDto(setItem.documentItem),
        quantity: setItem.quantity,
      })),
      setItemsMaxQuantity,
      activeSetItemIndex,
      highlightFlag,
    }, [
      dynamicSetSelection,
      selection,
      nextState,
    ]);
  }

  toString() {
    return `<DynamicSetSelectionFlow>`;
  }
}
