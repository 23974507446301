import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "w-full md:w-3/4 xl:w-2/5 px-1 md:px-10" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_Teleport, {
    to: $props.attachTo,
    disabled: !$props.attachTo
  }, [
    _createElementVNode("div", _mergeProps({ class: "flex justify-center pt-1 md:pt-2 bg-basic" }, _ctx.$attrs), [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentLayout, (row, rowIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `r-${rowIndex}`,
            class: "flex flex-row pb-0.5 md:pb-2 gap-0.5 md:gap-2.5"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (key, keyIndex) => {
              return _renderSlot(_ctx.$slots, key.slot ?? key.key, {
                key: `k-${keyIndex}-${key.key}`
              }, () => [
                _withDirectives(_createElementVNode("button", {
                  class: _normalizeClass([key.class || '', {
                    'qwerty-key': !key.delimiter,
                    'qwerty-key--delimiter': key.delimiter,
                    'qwerty-key--icon': !!key.icon
                  }]),
                  onClick: ($event: any) => (key.onClick(key))
                }, [
                  (key.icon)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(key.icon), { key: 0 }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(key.key ?? '\xa0'), 1))
                ], 10, _hoisted_2), [
                  [
                    _directive_test,
                    key.key,
                    void 0,
                    { qwertyKeyboardKey: true }
                  ]
                ])
              ])
            }), 128))
          ]))
        }), 128))
      ])
    ], 16)
  ], 8, ["to", "disabled"]))
}