import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import NewOrderPaymentDto from '../NewOrderPaymentDto';
import {DateTimeType} from '@designeo/apibundle-js';
import ChangeOrderStatusHeaderDto from '../ChangeOrderStatusHeaderDto';


export const FulfillOrderDtoPropDef = {
  payments: {
    type: new ArrayType(new EntityType(NewOrderPaymentDto)),
  },
  customerVerificationDate: {
    type: new DateTimeType(),
  },
  header: {
    type: new EntityType(ChangeOrderStatusHeaderDto),
  },
};
export default class GeneratedFulfillOrderDto extends createEntity(FulfillOrderDtoPropDef) {}
