import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';

export interface ISignalRStore {
  reconnecting: boolean,
  reconnected: boolean,
  closed: boolean,
}

const createInitState = (data?: Partial<ISignalRStore>) => ({
  reconnecting: false,
  reconnected: false,
  closed: false,
});

export class SignalRStore extends Store<ISignalRStore> {
  constructor() {
    super(createInitState());
  }

  setReconnecting = action((val) => {
    this.state.reconnecting = val;
  })

  reconnecting = getter(() => {
    return this.state.reconnecting;
  })

  setReconnected = action((val) => {
    this.state.reconnected = val;
  })

  reconnected = getter(() => {
    return this.state.reconnected;
  })

  setClosed = action((val) => {
    this.state.closed = val;
  })

  closed = getter(() => {
    return this.state.closed;
  })
}

const storeIdentifier = 'SignalRStore';

export const configureSignalRStore = createConfigureStore<typeof SignalRStore>(storeIdentifier);
export const useSignalRStore = createUseStore(SignalRStore, storeIdentifier);
