import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import WarehouseType from '../WarehouseType';
import {NumberType} from '@designeo/apibundle-js';


export const StockDtoPropDef = {
  shopCode: {
    type: new StringType(),
  },
  internalNumber: {
    type: new StringType(),
  },
  warehouseCode: {
    type: new StringType(),
  },
  warehouse: {
    type: new EntityType(WarehouseType),
  },
  batch: {
    type: new StringType(),
  },
  batchLabel: {
    type: new StringType(),
  },
  count: {
    type: new NumberType(),
  },
  inTransitCount: {
    type: new NumberType(),
  },
  unitOfQuantityCode: {
    type: new StringType(),
  },
  groupName: {
    type: new StringType(),
  },
  currency: {
    type: new StringType(),
  },
  value: {
    type: new NumberType(),
  },
  description: {
    type: new StringType(),
  },
};
export default class GeneratedStockDto extends createEntity(StockDtoPropDef) {}
