import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TicketPartnerResource from '../TicketPartnerResource';
import Actions from '../Actions';
import TicketPartnerResourceV2 from './TicketPartnerResourceV2';
import {KorunkaVersion} from '@/constants/korunka';
import {EntityTypeKorunka} from '@/Model/Type/korunka';


export const TicketDetailPartnerResourcePropDef = {
  ticket: {
    type: new EntityTypeKorunka({
      [KorunkaVersion.v1]: TicketPartnerResource,
      [KorunkaVersion.v2]: TicketPartnerResourceV2,
    }),
  },
  actions: {
    type: new EntityType(Actions),
  },
};
export default class GeneratedTicketDetailPartnerResource extends createEntity(TicketDetailPartnerResourcePropDef) {}
