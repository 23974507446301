import {DateTime} from 'luxon';
import {
  createEntity,
  StringType,
  DateTimeType,
  ObjectType,
  ArrayType,
} from '@designeo/apibundle-js';
import * as yup from 'yup';
import {reduce} from 'lodash-es';

export default class DelaySellDataDtoCustom extends createEntity({
  delayedSellDate: {
    type: new DateTimeType(),
  },
  delayedSellTime: {
    type: new StringType(),
  },
  delayedSellNumber: {
    type: new StringType(),
  },
  activeField: {
    type: new StringType(),
  },
  errors: {
    type: new ObjectType(),
  },
}) {
  public static new(delayedSellDate, delayedSellNumber): DelaySellDataDtoCustom {
    const dateTime = DateTime.fromJSDate(delayedSellDate ?? new Date());

    return new DelaySellDataDtoCustom({
      delayedSellDate: dateTime.toJSDate().toISOString(),
      delayedSellTime: dateTime.toFormat('HH:mm'),
      delayedSellNumber: delayedSellNumber ?? '',
      activeField: 'delayedSellTime',
    });
  }


  get isValid() {
    return true;
  }

  get isActiveDelayedSellDate() {
    return this.activeField === 'delayedSellDate';
  }

  get isActiveDelayedSellTime() {
    return this.activeField === 'delayedSellTime';
  }

  get isActiveDelayedSellNumber() {
    return this.activeField === 'delayedSellNumber';
  }

  get fieldOrder() {
    return [
      'delayedSellDate',
      'delayedSellTime',
      'delayedSellNumber',
    ];
  }

  getErrorByField(field: string) {
    return this.errors?.[field] ?? null;
  }

  toDocumentDtoAttributes() {
    const [all, hour, minute] = /^([0-9]*?):([0-9]*?)$/.exec(this.delayedSellTime);

    const delayedSellDate = DateTime
      .fromJSDate(this.delayedSellDate)
      .set({
        hour: parseInt(hour ?? '00'),
        minute: parseInt(minute ?? '00'),
      })
      .toJSDate();

    return {
      delayedSellDate: delayedSellDate,
      delayedSellNumber: this.delayedSellNumber,
    };
  }

  validate({required = false} = {}) {
    try {
      yup.object().shape({
        delayedSellDate: yup
          .string()
          .when('isRequired', {
            is: () => required,
            then: yup.string().required(),
          }),
        delayedSellNumber: yup
          .string()
          .when('isRequired', {
            is: () => required,
            then: yup.string().required(),
          })
          .test({
            name: 'delayedSellNumber',
            message: 'validations.number.numericOnly',
            test: (value: string) => {
              if ((value ?? '') === '') return true;

              return /^\d+$/.test(value);
            },
          }),
        delayedSellTime: yup
          .string()
          .when('isRequired', {
            is: () => required,
            then: yup.string().required(),
          })
          .test({
            name: 'timeFormat',
            message: 'validations.string.timeFormat',
            test: (value: string) => {
              if ((value ?? '') === '') return true;

              return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
            },
          }),
      })
        .validateSync(this._data, {abortEarly: false});
      this.errors = null;
      return true;
    } catch (e) {
      this.errors = reduce(e.inner ?? [], (acc, {message, params, path}) => {
        acc[path] = {message, params};

        return acc;
      }, {});
      return false;
    }
  }
}
