import {
  ComponentPublicInstance,
  computed,
  inject,
  nextTick,
  provide,
  ref,
  Ref,
  watch,
} from 'vue';
import {useQueue} from '@/Helpers/promiseQueue';
import {createGridConfig, GridModel} from '@designeo/vue-grid';
import {apiStockNearbyShopGet} from '@/Model/Action';
import {
  DocumentItemDto,
  DocumentLogisticItemDto,
  NearbyShopStockFilterDto,
} from '@/Model/Entity';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {debounce} from 'lodash-es';
import {useModalKeyboard} from '@/Modules/Register/Modal/searchModal';
import NearbyShopStockDto from '../../Model/Entity/NearbyShopStockDto';

export type IModalStockInStore = ComponentPublicInstance<{}, {}, {}, {}, {searchStockInStore(): void}>

export interface StockInStoresApi {
  modalRef: Ref<ComponentPublicInstance>
  inputBuffer: Ref<string>
  isLoading: Ref<boolean>
  fetchResult: Ref<NearbyShopStockDto[]>
  fetchError: Ref<boolean>
  inputBufferMeetLength: Ref<boolean>
  minProductSearchLength: number
  toggleShowKeyboard: ()=> void
  showKeyboard: Ref<boolean>
  product: Ref<DocumentItemDto | DocumentLogisticItemDto | null>
  onlyWithStock: Ref<boolean>
  setOnlyWithStockSwitch: (val: boolean)=> void
  close: ()=> void
}

export const StockInStoresContext = Symbol('StockInStoresContext');

const minProductSearchLength = 0; // Autocomplete.minProductSearchLength;

export function provideStockInStores(
  {
    isOpen,
    inputBuffer,
    product,
    close,
  }: {
    isOpen: Ref<boolean>,
    inputBuffer: Ref<string>,
    product: Ref<DocumentItemDto | DocumentLogisticItemDto | null>,
    close: ()=> void,
  },
) {
  const {showKeyboard, toggleShowKeyboard} = useModalKeyboard();
  const {runPromise, isRunning} = useQueue();

  const modalRef = ref<ComponentPublicInstance>(null);

  const innerIsLoading = ref(false);
  const fetchError = ref(false);
  const onlyWithStock = ref(true);

  const inputBufferMeetLength = computed(() => {
    return inputBuffer.value.length >= minProductSearchLength;
  });

  const gridModel = new GridModel('stockInStores', (async ({filters, sort, paginator}) => {
    if (!isOpen.value || !inputBufferMeetLength.value) {
      return {
        paginator: {
          offset: paginator.offset,
          pageSize: paginator.pageSize,
          total: paginator.total,
        },
        data: [],
      };
    }

    let response = [];

    try {
      await runPromise(async () => {
        if (!product.value) {
          return {
            paginator: {
              offset: 0,
              pageSize: 999,
              total: 9999,
            },
            data: [],
          };
        }

        response = await apiStockNearbyShopGet({
          input: new NearbyShopStockFilterDto({
            internalNumber: product.value.internalNumber,
            ...(product.value.batch ? {
              batch: product.value.batch,
            } : {}),
            onlyNegative: !onlyWithStock.value,
          }),
          params: {
            ...(inputBuffer.value.length ? {
              searchCode: inputBuffer.value,
            } : {}),
          },
        });
      });

      return {
        paginator: {
          offset: 0,
          pageSize: 999,
          total: 9999,
        },
        data: response,
      };
    } finally {
      emitTestEvent(`${TestEvent.SEARCH_STOCK_IN_STORES}:${inputBuffer.value}`);
    }
  }), {
    initialFetch: false,
    defaultPageSize: 4,
  });

  const fetchResult = computed(() => {
    return gridModel.loadedData.value ?? [];
  });

  const gridConfig = computed(() => {
    return createGridConfig({});
  });

  const isLoading = computed(() => {
    return innerIsLoading.value || isRunning.value;
  });

  const searchStockInStore = async () => {
    if (!isOpen.value) {
      return;
    }

    try {
      innerIsLoading.value = true;
      await gridModel.update();
      fetchError.value = false;
    } catch (e) {
      fetchError.value = true;
      console.error(e);
    } finally {
      innerIsLoading.value = false;
    }
  };

  const debouncedSearchStockInStore = debounce(searchStockInStore, 500);

  watch(() => isOpen.value, async (value) => {
    if (!value) { // to hide actual results
      onlyWithStock.value = true;
      await gridModel.update();
      return;
    }

    await nextTick();
    await debouncedSearchStockInStore();
  }, {
    immediate: true,
  });

  watch(() => inputBuffer.value, debouncedSearchStockInStore);

  const setOnlyWithStockSwitch = (val: boolean) => {
    if (isLoading.value) {
      return;
    }

    onlyWithStock.value = val;
    searchStockInStore();
  };

  provide(StockInStoresContext, <StockInStoresApi>{
    modalRef,
    inputBuffer,
    isLoading,
    fetchResult,
    fetchError,
    inputBufferMeetLength,
    minProductSearchLength,
    showKeyboard,
    toggleShowKeyboard,
    product,
    close,
    onlyWithStock,
    setOnlyWithStockSwitch,
  });


  return {
    close,
    modalRef,
    gridModel,
    gridConfig,
    showKeyboard,
    toggleShowKeyboard,
    searchStockInStore,
  };
}

export function useStockInStores() {
  const {
    modalRef,
    inputBuffer,
    isLoading,
    fetchResult,
    fetchError,
    inputBufferMeetLength,
    minProductSearchLength,
    showKeyboard,
    toggleShowKeyboard,
    product,
    close,
    onlyWithStock,
    setOnlyWithStockSwitch,
  } = <StockInStoresApi>inject(StockInStoresContext, null);

  return {
    modalRef,
    inputBuffer,
    isLoading,
    fetchResult,
    fetchError,
    inputBufferMeetLength,
    minProductSearchLength,
    showKeyboard,
    toggleShowKeyboard,
    product,
    close,
    onlyWithStock,
    setOnlyWithStockSwitch,
  };
}
