import {ref} from 'vue';
import {guid} from './guid';

export const useQueue = () => {
  const queue: Array<{promise: Promise<any>, id: string}> = [];
  const isRunning = ref(false);

  const runPromise = async <T = any>(fn: (requestId: string)=> Promise<T>) => {
    const id = guid();
    try {
      isRunning.value = true;
      const promise = fn(id);

      queue.push({id, promise});

      try {
        await promise;
      } catch (e) {
        console.error(e);
        // this is bypassed intentionally, next await will rethrow it if it is last promise in queue
      }

      return await queue[queue.length - 1].promise;
    } finally {
      isRunning.value = false;
      queue.splice(queue.findIndex(({id: itemId}) => itemId === id), 1);
    }
  };

  return {runPromise, isRunning};
};
