
import {
  computed,
  defineComponent,
} from 'vue';
import {useKorunkaSyncTarget} from '../../syncTarget/KorunkaSyncTarget';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import {getLetterFromIndex} from '@/Helpers/alphabetIndex';

export default defineComponent({
  setup() {
    const korunkaSyncTarget = useKorunkaSyncTarget();

    const messages = computed(() => korunkaSyncTarget.messages.value);

    const ticket = computed(() => korunkaSyncTarget.entity.value as KorunkaLotteryTicketDtoCustom);

    const title = computed(() => {
      if (
        !ticket.value.isWon ||
        !ticket.value.isWonWithPayoutAmountExceeded ||
        !ticket.value.isPaidOut ||
        !ticket.value.isLoss ||
        !ticket.value.isCanceled
      ) {
        return messages.value.betNumbers;
      }

      return messages.value.guessedNumbers;
    });

    const isWinningNumber = (number: number) => {
      return ticket.value.ticket.draw.drawNumbers.includes(number);
    };

    const kombiNumberRowGridTemplateColumns = (numbersRow: number[]) => {
      const {length} = numbersRow;
      const cols = length <= 8 ? length : Math.ceil(length / 2);
      return `grid-template-columns: repeat(${cols}, minmax(0, 1fr));`;
    };

    const kombiPlusNumberRowGridTemplateColumns = (numbersRow: number[]) => {
      const {length} = numbersRow;
      const cols = length + 1;
      return `grid-template-columns: 1rem repeat(${cols}, minmax(0, 1fr));`;
    };

    return {
      messages,
      ticket,
      korunkaSyncTarget,
      isWinningNumber,
      getLetterFromIndex,
      kombiNumberRowGridTemplateColumns,
      kombiPlusNumberRowGridTemplateColumns,
      title,
    };
  },
});
