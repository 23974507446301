import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import EshopDto from '../EshopDto';
import {NumberType} from '@designeo/apibundle-js';
import OrderStatus from '../OrderStatus';
import {BooleanType} from '@designeo/apibundle-js';
import OrderCustomerPreviewDto from '../OrderCustomerPreviewDto';
import {DateTimeType} from '@designeo/apibundle-js';


export const OrderPreviewDtoPropDef = {
  id: {
    type: new StringType(),
  },
  eshop: {
    type: new EntityType(EshopDto),
  },
  orderNumber: {
    type: new StringType(),
  },
  totalPrice: {
    type: new NumberType(),
  },
  currencyCode: {
    type: new StringType(),
  },
  statusCode: {
    type: new EntityType(OrderStatus),
  },
  isPaid: {
    type: new BooleanType(),
  },
  customer: {
    type: new EntityType(OrderCustomerPreviewDto),
  },
  orderDate: {
    type: new DateTimeType(),
  },
  orderEditedDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedOrderPreviewDto extends createEntity(OrderPreviewDtoPropDef) {}
