import {
  createEntity,
  StringType,
} from '@designeo/apibundle-js';

export default class VoucherDiscountCheckRequestDtoCustom extends createEntity({
  requestId: {
    type: new StringType(),
  },
  voucherNumber: {
    type: new StringType(),
  },
}) {}
