import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreEvent,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {
  createTransitions,
  getCommonTransitions,
  TransitionDefinition,
} from './helpers';
import {DocumentCustomerDto} from '@/Model/Entity';
import {SearchCustomerBy} from '@/constants/searchCustomerBy';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {computed} from 'vue';
import {usePhoneNumberMaskResolver} from '@/Helpers/phoneNumberMaskResolver';

export const isInputPhoneNumber = (value) => {
  return /^\+\d+$/.test(value);
};

export function useCustomerSearchTransitions(this: RegisterStore) {
  const {resolvePhoneNumberMask, resolveDefaultPhoneNumber} = usePhoneNumberMaskResolver();

  const searchBy = computed(() => {
    return useConfigurationStore().configuration.value?.features?.customer?.search?.searchBy ??
      SearchCustomerBy.DEFAULT;
  });

  const isSearchByPhone = computed(() => {
    return searchBy.value === SearchCustomerBy.PHONE || isInputPhoneNumber(this.state.inputBuffer);
  });

  return {
    ...getCommonTransitions.call(this, [
      RegisterActions.ADD_PLUS,
      RegisterActions.BACKSPACE,
      RegisterActions.CLEAR,
    ]),
    [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
      await this.changeState(RegisterState.ENTER_ARTICLE_NUMBER);
    }),
    [RegisterActions.INIT]: action(async (event: RegisterInputEvent) => {
      if (isSearchByPhone.value) {
        this.state.inputBuffer = resolveDefaultPhoneNumber();
      }
    }),
    [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent) => {
      if (isSearchByPhone.value) {
        const {unmaskedPhoneNumber} = resolvePhoneNumberMask(this.state.inputBuffer + event.value);
        this.state.inputBuffer = unmaskedPhoneNumber;
      } else {
        createTransitions.call(this)[RegisterActions.ADD_NUMBER](event);
      }
    }),
    [RegisterActions.ADD_CHAR]: action(async (event: RegisterInputEvent) => {
      if (!isSearchByPhone.value) {
        createTransitions.call(this)[RegisterActions.ADD_CHAR](event);
      }
    }),
    [RegisterActions.ADD_PERIOD]: action(async (event: RegisterInputEvent) => {
      if (!isSearchByPhone.value) {
        createTransitions.call(this)[RegisterActions.ADD_PERIOD](event);
      }
    }),
  };
}

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.CUSTOMER_SEARCH]: {
      ...useCustomerSearchTransitions.call(this),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (event.value) {
          const added = await this.addCustomer(
            new DocumentCustomerDto({customerNumber: event.value.customerNumber}),
            'customerNumber',
          );

          if (!added) {
            return;
          }

          if (this.state.previousState === RegisterState.FILL_IN_CUSTOMER_CARD) {
            await this.changeState(this.state.previousState);
          } else {
            await this.resetToDefaultStep();
          }
          await this.fetchPromoEngineResult();
        } else {
          this.dispatchEvent(new Event(RegisterStoreEvent.SEARCH_CUSTOMER));
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        if (
          this.state.previousState === RegisterState.ENTER_ARTICLE_NUMBER ||
          this.state.previousState === RegisterState.ENTER_CUSTOMER_CARD
        ) {
          await this.changeState(this.state.previousState);
          await this.onEventInput({
            type: RegisterActions.SCANNER,
            value: event.value,
          });
        }
      }),
    },
  };
}
