import {markRaw} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {
  api3rdPartyKorunkaPreparedTicket,
  api3rdPartyKorunkaTicketByNumber,
  api3rdPartyV2KorunkaTicketByNumber,
  apiTextSearch,
} from '@/Model/Action';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import KorunkaLotteryPreparedTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryPreparedTicketDtoCustom';
import {invert} from 'lodash-es';
import {
  isKorunkaVersionV1,
  isKorunkaVersionV2,
  serializeKorunkaEntity,
  throwUnsupportedKorunkaVersionError,
} from '@/Helpers/korunka';
import {KORUNKA_PREPARED_TICKET_PREFIX} from '@/Helpers/korunkaTypes';
import {sanitizeApiSearch} from '@/Helpers/sanitize';

export class WorkflowStepApiKorunkaLotteryGetTicket extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiKorunkaLotteryGetTicket;
  }

  get type() {
    return WorkflowStepApiKorunkaLotteryGetTicket.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get ticketNumber(): string {
    return this.data[WorkflowStepField.code];
  }

  async flowGetPreparedTicket() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const ticket = await api3rdPartyKorunkaPreparedTicket({params: {
        id: this.ticketNumber.replace(KORUNKA_PREPARED_TICKET_PREFIX, ''),
      }});

      this.entity = new KorunkaLotteryPreparedTicketDtoCustom({
        ...ticket.toJson(),
        id: this.entity.id,
      });

      if (!this.isPreparedTicketEntity(this.entity)) return;

      const gameType = this.entity.gameType.value;

      const workflowCode = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByGameType
        ?.[gameType];

      if (!workflowCode) return;

      const workflowCodesByArticles = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByArticles;

      const articlesByWorkflowCodes = invert(workflowCodesByArticles);

      const articleInternalNumber = articlesByWorkflowCodes?.[workflowCode];

      const [product] = sanitizeApiSearch(await apiTextSearch({params: {text: articleInternalNumber}})).documentItems;

      if (!product) return;

      const articleBucketKey = this.saveBucket(product.toJson());
      const entityBucketKey = this.saveBucket(serializeKorunkaEntity(await this.entity.toGameEntity()));

      this.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {
          detail: {
            workflowCode: workflowCode,
            returnToCurrentWorkflow: false,
            params: {
              articleBucketKey,
              entityBucketKey,
            },
          },
        }),
      );
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async flowGetTicket() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const ticket = await (async () => {
        if (isKorunkaVersionV2()) {
          return await api3rdPartyV2KorunkaTicketByNumber({params: {
            ticketNumber: this.ticketNumber,
          }});
        }

        if (isKorunkaVersionV1()) {
          return await api3rdPartyKorunkaTicketByNumber({params: {
            ticketNumber: this.ticketNumber,
          }});
        }

        throwUnsupportedKorunkaVersionError();
      })();

      this.entity = new KorunkaLotteryTicketDtoCustom({
        ...ticket.toJson(),
        id: this.entity.id,
      });

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeEnter() {
    if (this.ticketNumber.startsWith(KORUNKA_PREPARED_TICKET_PREFIX)) {
      await this.flowGetPreparedTicket();
    } else {
      await this.flowGetTicket();
    }
  }

  get transitions() {
    return {};
  }
}
