import {DOCUMENT_ITEM_SET_TYPE_NORMAL} from '@/constants/documentItemSetTypes';
import DocumentItemDtoCustom from './custom/DocumentItemDtoCustom';
import {
  isNil,
  first,
  sumBy,
  includes,
  has,
  keys,
  filter,
  pick,
  invert,
} from 'lodash-es';
import {SetPriceSources} from '@/constants/setPriceSources';
import {
  DOCUMENT_ITEM_SET_TYPE_SET_ARTICLE,
  DOCUMENT_ITEM_SET_TYPE_SET_COMPONENT,
} from '@/constants/documentItemSetTypes';
import {
  DocumentItemTypes,
} from '@/constants/documentItemTypes';
import DocumentDto from './DocumentDto';
import StockDto from '@/Model/Entity/StockDto';
import {submitJournalEventDocumentItemPriceChange} from '@/Helpers/journal';
import {fixDecimals, toInt} from '@/Helpers/math';
import ArticleType from '@/Model/Entity/ArticleType';
import {SetDisplayTypes} from '@/constants/setDisplayTypes';
import SetDisplayType from '@/Model/Entity/SetDisplayType';
import {CheckAndChargeServiceTypes} from '@/constants/checkAndChargeServiceTypes';
import {CheckAndChargeProviders} from '@/constants/checkAndChargeProviders';
import {Entity} from '@designeo/apibundle-js/src/Entity/base';
import CheckResponseDto from '@/Model/Entity/CheckResponseDto';
import ChargeResponseDto from '@/Model/Entity/ChargeResponseDto';
import RevokeResponseDto from '@/Model/Entity/RevokeResponseDto';
import LotCheckRequestDto from '@/Model/Entity/LotCheckRequestDto';
import LotRevokeRequestDto from '@/Model/Entity/LotRevokeRequestDto';
import LotChargeRequestDto from '@/Model/Entity/LotChargeRequestDto';
import PRCheckRequestDto from '@/Model/Entity/PRCheckRequestDto';
import PRChargeRequestDto from '@/Model/Entity/PRChargeRequestDto';
import PRRevokeRequestDto from '@/Model/Entity/PRRevokeRequestDto';
import PORCheckRequestDto from '@/Model/Entity/PORCheckRequestDto';
import PORChargeRequestDto from '@/Model/Entity/PORChargeRequestDto';
import PORRevokeRequestDto from '@/Model/Entity/PORRevokeRequestDto';
import POSACheckRequestDto from '@/Model/Entity/POSACheckRequestDto';
import POSAChargeRequestDto from '@/Model/Entity/POSAChargeRequestDto';
import POSARevokeRequestDto from '@/Model/Entity/POSARevokeRequestDto';
import {recalculateDocument} from '@designeo/pos-promotion-engine';
import BTCCheckRequestDto from './BTCCheckRequestDto';
import BTCChargeRequestDto from './BTCChargeRequestDto';
import LotCancelRequestDto from '@/Model/Entity/LotCancelRequestDto';
import PORCancelRequestDto from '@/Model/Entity/PORCancelRequestDto';
import POSACancelRequestDto from '@/Model/Entity/POSACancelRequestDto';
import PRCancelRequestDto from '@/Model/Entity/PRCancelRequestDto';
import CancelResponseDto from '@/Model/Entity/CancelResponseDto';
import DocumentLogisticItemDto from '@/Model/Entity/DocumentLogisticItemDto';
import VoucherDiscountChargeRequestDto from '@/Model/Entity/VoucherDiscountChargeRequestDto';
import VoucherDiscountCheckRequestDto from '@/Model/Entity/VoucherDiscountCheckRequestDto';
import VoucherDiscountRevokeRequestDto from '@/Model/Entity/VoucherDiscountRevokeRequestDto';
import VoucherDiscountCancelRequestDto from '@/Model/Entity/VoucherDiscountCancelRequestDto';
import {guid} from '@/Helpers/guid';
import {createBatchKey} from '@/Helpers/batch';
import KorunkaChargeRequestDto from '@/Model/Entity/KorunkaChargeRequestDto';
import KorunkaRevokeRequestDto from '@/Model/Entity/KorunkaRevokeRequestDto';
import KorunkaCancelRequestDto from '@/Model/Entity/KorunkaCancelRequestDto';
import KorunkaCheckRequestDto from '@/Model/Entity/KorunkaCheckRequestDto';
import {isActiveFeatureInTransitStock} from '@/Helpers/features';
import {Korunka} from '../../../types/korunka';
import {PriceSourceConditions} from '@/constants/priceSources';


export default class DocumentItemDto extends DocumentItemDtoCustom {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  set originalEan(val: string) {
    // @ts-ignore
    this._data.originalEan = val;
  }

  // @ts-ignore
  set checkAndChargeShouldCancel(value: boolean) {
    // @ts-ignore
    return this._data.checkAndChargeShouldCancel = value;
  }

  get checkAndChargeShouldCancel(): boolean {
    // @ts-ignore
    return this._data.checkAndChargeShouldCancel ?? false;
  }

  // @ts-ignore
  set dynamicSets(value) {
    // @ts-ignore
    return this._data.dynamicSets = value;
  }

  get dynamicSets() {
    // @ts-ignore
    return this._data.dynamicSets ?? null;
  }

  /**
   * Ean used to add this
   */
  get originalEan(): string {
    // @ts-ignore
    return this._data.originalEan;
  }

  get isPromotionItem(): boolean {
    return !!this.promoCode;
  }

  get isPromotionItemTypePartOfDynamicSet(): boolean {
    if (!this.isPromotionItem) {
      return false;
    }

    return this.isDynamicSet === true;
  }

  get isPromotionItemTypeGift(): boolean {
    if (!this.isPromotionItem) {
      return false;
    }

    return !this.isPromotionItemTypePartOfDynamicSet;
  }

  get isReadOnly(): boolean {
    return this.isPromotionItem;
  }

  get wasExpanded(): boolean {
    return !!this.originalEan;
  }

  get hasIntRounding() {
    return this.itemRounding === 0;
  }

  get itemRoundingValidator(): RegExp {
    return new RegExp(`^([0-9]*)(?:\\.[0-9]{0,${Math.max(0, this.itemRounding - 1)}})?$`);
  }

  /**
   * Base price of the item
   */
  get valueWithoutDiscounts() {
    return this.priceAction * this.quantity;
  }

  /**
   * Base price of the item including item discounts (excluding document discounts)
   * this is value that sums up to sub total of the document
   */
  get valueAfterItemDiscounts() {
    return this.valueBeforeDiscounts + sumBy(
      filter(this.discounts, ({type}) => type.value !== 'DocumentDiscount'),
      'itemValue',
    );
  }

  get unitByEnum() {
    return DocumentItemDto.configurationStore.unitsByCode.value?.[this.unit]?.text ?? this.unit;
  }

  restQuantity(val: number | string = 0) {
    const requestedQuantity = toInt(val) || 0;
    if (this.hasValidMaxQuantity) {
      return Math.min(requestedQuantity, this.maxQuantity - this.quantity);
    } else {
      return requestedQuantity;
    }
  }

  addQuantity(val: number | string) {
    const quantity = toInt(val) || 0;
    this.changeQuantityWithMaxQuantityValidation(this.quantity + quantity);
  }

  changeQuantityWithMaxQuantityValidation(val: number) {
    if (this.hasValidMaxQuantity) {
      this.quantity = Math.min(val, this.maxQuantity);
    } else {
      this.quantity = val;
    }
  }

  get isStrictlyStandaloneItem() {
    return (
      this.isNegative ||
      this.isReturn ||
      this.hasMandatoryFieldSerialNo ||
      this.hasMandatoryFieldPhoneNumber ||
      this.hasMandatoryFieldPriceNormal ||
      this.isTypeService ||
      this.isCheckAndCharge
    );
  }

  get hasMandatoryFieldSerialNo() {
    return (this.isCheckAndCharge && this.isServiceTypePOSA) || this.serialNumber;
  }

  get hasMandatoryFieldInvoiceNo() {
    return this.invoiceNumber;
  }

  get hasMandatoryFieldPriceNormal() {
    if (this.isReturn) {
      return true;
    }

    if (this.isCheckAndCharge && this.isServiceTypeLot) {
      return false;
    }

    return this.hasManualPrice;
  }

  get hasMandatoryFieldReturnReasonCode() {
    return this.isReturn;
  }

  get hasMandatoryFieldOriginalDocumentReference() {
    return this.isReturn &&
      (
        DocumentItemDto.configurationStore.configuration.value
          ?.features
          ?.storno
          ?.requireOriginalDocumentReference ?? true
      );
  }

  get hasMandatoryFieldOriginalDocumentFiscalReference() {
    return this.isReturn &&
      (
        DocumentItemDto.configurationStore.configuration.value
          ?.features
          ?.storno
          ?.requireOriginalDocumentFiscalReference ?? false
      );
  }

  get hasMandatoryFieldPhoneNumber() {
    return (this.isCheckAndCharge && this.isServiceTypePR) || this.needsPhoneNumber;
  }

  get hasMandatoryFieldQuantity() {
    if (this.isReturn && this.isCheckAndCharge && this.isServiceTypeLot) {
      return true;
    }

    return this.isTypeArticle;
  }

  get hasMandatoryFieldLogisticCode() {
    return this.isCheckAndCharge && this.isServiceTypeLot && includes([
      CheckAndChargeProviders.TIPSPORT,
      CheckAndChargeProviders.MOCK_TIPSPORT,
      CheckAndChargeProviders.EUROQUEEN,
      CheckAndChargeProviders.MOCK_EUROQUEEN,
    ], this.provider);
  }

  get hasMandatoryFieldValidationCode() {
    if (this.isReturn) {
      return this.isCheckAndCharge && this.isServiceTypeLot && !this.isNegative;
    }

    return this.isCheckAndCharge && this.isServiceTypeLot && this.isNegative;
  }

  get hasEditableField() {
    return (
      this.hasMandatoryFieldSerialNo ||
      this.hasMandatoryFieldPriceNormal ||
      this.hasMandatoryFieldReturnReasonCode ||
      this.hasMandatoryFieldOriginalDocumentReference ||
      this.hasMandatoryFieldOriginalDocumentFiscalReference ||
      this.hasMandatoryFieldPhoneNumber ||
      this.hasMandatoryFieldQuantity ||
      this.hasMandatoryFieldLogisticCode ||
      this.hasMandatoryFieldValidationCode ||
      this.hasMandatoryFieldQuantity
    );
  }

  get isFilled() {
    if (this.hasMandatoryFieldSerialNo && isNil(this.serialNo)) {
      return false;
    }

    if (this.hasMandatoryFieldPriceNormal && isNil(this.priceNormal)) {
      return false;
    }

    if (this.hasMandatoryFieldReturnReasonCode && isNil(this.returnReasonCode)) {
      return false;
    }

    if (this.hasMandatoryFieldOriginalDocumentReference && isNil(this.originalDocumentReference)) {
      return false;
    }

    if (this.hasMandatoryFieldOriginalDocumentFiscalReference && isNil(this.originalDocumentFiscalReference)) {
      return false;
    }

    if (this.hasMandatoryFieldPhoneNumber && isNil(this.phoneNumber)) {
      return false;
    }

    if (this.hasMandatoryFieldQuantity && isNil(this.quantity)) {
      return false;
    }

    if (this.hasMandatoryFieldLogisticCode && isNil(this.logisticCode)) {
      return false;
    }

    if (this.hasMandatoryFieldValidationCode && isNil(this.validationCode)) {
      return false;
    }

    return true;
  }

  get isAtMaxQuantity() {
    if (!this.hasValidMaxQuantity) return false;
    return this.quantity === this.maxQuantity;
  }

  get isRoundingArticle() {
    const roundingArticleNumber = DocumentItemDto.configurationStore.configuration.value?.general?.roundingArticleId ??
      DocumentItemTypes.numberRounding;

    return this.internalNumber === roundingArticleNumber;
  }

  get quantityIsOverMaxQuantity() {
    if (!this.hasValidMaxQuantity) return false;
    return this.quantity > this.maxQuantity;
  }

  get hasValidMaxQuantity() {
    return !isNil(this.maxQuantity);
  }

  get displayType() {
    return this.setDisplayType?.value;
  }

  get isSet() {
    return this.setType?.value === DOCUMENT_ITEM_SET_TYPE_SET_ARTICLE;
  }

  get isSetComponent() {
    return this.setType?.value === DOCUMENT_ITEM_SET_TYPE_SET_COMPONENT;
  }

  get isPartOfSet() {
    return !!this.setNumber;
  }

  get isSetPriceSourceParts() {
    return this.setPriceSource?.value === SetPriceSources.FromParts;
  }

  get hasPriceSourceConditionVariable() {
    return this.priceSourceConditions === PriceSourceConditions.Variable;
  }

  get isArticleLabelTaxStamp() {
    return !!this.taxStamp;
  }

  get isArticleLabelPackage() {
    return this.isSet;
  }

  get isArticleLabelNetto() {
    return this.netto;
  }

  get isArticleLabelAction() {
    return this.priceAction && this.priceAction !== this.priceNormal;
  }

  get isArticleLabelSellout() {
    return this.sellout;
  }

  get isArticleLabelItemStornoDisabled() {
    return !this.canBeCanceled;
  }

  get isArticleLabelDocumentStornoDisabled() {
    return this.documentStornoDisabled;
  }

  get isArticleLabelItemReturnDisabled() {
    return this.itemReturnDisabled;
  }

  get isArticleLabelSalePackage() {
    return this.salePackage;
  }

  get isTypeArticle() {
    return this.itemType === DocumentItemTypes.article;
  }

  get isTypeService() {
    return this.itemType === DocumentItemTypes.service;
  }

  get isExpandableSet() {
    return typeof this.prep === 'number' && this.prep > 1 && this.setItems.length === 1;
  }

  get isPartOfDocument() {
    return !!this.itemAddedDate;
  }

  get isValid() {
    const now = new Date();
    const validFrom = this.saleValidFrom ?? now;
    const validTill = this.saleValidTill ?? now;

    return validFrom <= now && now <= validTill;
  }

  get discountsValue() {
    return sumBy(this.discounts, 'value') || 0;
  }

  get itemDiscountsWithValue() {
    return (this.discounts ?? [])
      .filter(({itemValue, type}) => itemValue !== 0 && type.value === 'Discount');
  }

  get image() {
    if (
      !DocumentDto.configurationStore.configuration.value.features?.articles?.displayArticleImage ||
      !this.imageId
    ) return null;

    const middlewareUrl = DocumentDto.configurationStore.configuration.value.general.middleware.url;
    return `${middlewareUrl}/sap-content-server/${this.imageId}`;
  }

  get canBeCanceled() {
    return this.isReturn || !this.itemStornoDisabled;
  }

  get canDocumentBeCanceled() {
    return this.isReturn || !this.documentStornoDisabled;
  }

  get canBeReturned() {
    return !this.itemReturnDisabled;
  }

  get internalNumberWithBatch() {
    return this.batch ? createBatchKey({internalNumber: this.internalNumber, batch: this.batch}) : this.internalNumber;
  }

  /**
   * If set is expandable (prep > 1) returns expanded product
   * expandable sets are currently supported only for sets with one item
   * only item inside the set is added to document,
   * info about it's set is in originalEan
   */
  expandExpandableSet() {
    if (this.isExpandableSet) {
      const expandedSet = first(this.setItems);
      expandedSet.originalEan = this.gtin;
      expandedSet.setType = new ArticleType(DOCUMENT_ITEM_SET_TYPE_NORMAL);
      expandedSet.setDisplayType = new SetDisplayType(SetDisplayTypes.Full);
      return expandedSet;
    } else {
      return this;
    }
  }

  sanitize() {
    this.quantity = this.quantity ?? (this.isNegative ? -1 : 1);

    this.setCount();

    this.sanitizePriceAttributes(this);

    for (const setItem of (this.setItems ?? [])) {
      this.sanitizePriceAttributes(setItem);

      setItem.setCount();
    }
  }

  sanitizePriceAttributes(item: DocumentItemDto) {
    item.priceNormal = fixDecimals(item.priceNormal ?? 0);

    if (isNil(item.priceAction)) {
      item.priceAction = item.priceNormal;
    } else {
      item.priceAction = fixDecimals(item.priceAction ?? 0);
    }
  }

  getStockQuantityValueByStock(stock: StockDto) {
    if (!stock || (this.isSet && isNil(this.prep))) return null;

    const isNegative = stock.count < 0;

    let count = stock.count;

    if (this.prep) {
      count = Math.floor(Math.abs(stock.count) / this.prep) * (isNegative ? -1 : 1);
    }

    return count;
  }

  getInTransitQuantityValueByStock(stock: StockDto) {
    if (!stock || (this.isSet && isNil(this.prep))) return null;

    if (isNil(stock.inTransitCount)) {
      return null;
    }

    const isNegative = stock.inTransitCount < 0;

    let inTransitCount = stock.inTransitCount;

    if (this.prep) {
      inTransitCount = Math.floor(Math.abs(stock.inTransitCount) / this.prep) * (isNegative ? -1 : 1);
    }

    return inTransitCount;
  }

  getStockByStockMap(map: {[key: string]: StockDto}) {
    return this.batch ?
      (map?.[createBatchKey({internalNumber: this.internalNumber, batch: this.batch})] ?? null) :
      (map?.[this.internalNumber] ?? null);
  }

  getStockQuantityByStockMap(map: {[key: string]: StockDto}) {
    const stock = this.getStockByStockMap(map);

    return this.getStockQuantityByStock(stock);
  }

  getInTransitQuantityByStockMap(map: {[key: string]: StockDto}) {
    const stock = this.getStockByStockMap(map);

    return this.getInTransitQuantityByStock(stock);
  }

  getInTransitQuantityValueByStockMap(map: {[key: string]: StockDto}) {
    const stock = this.getStockByStockMap(map);

    return this.getInTransitQuantityValueByStock(stock);
  }

  getStockQuantityByStock(stock: StockDto) {
    if (!stock || (this.isSet && isNil(this.prep))) return null;

    const count = this.getStockQuantityValueByStock(stock);
    const unit = this.unitByEnum;

    return [
      count,
      ...(unit ? [unit] : []),
    ].join(' ');
  }

  getInTransitQuantityByStock(stock: StockDto) {
    if (!stock || (this.isSet && isNil(this.prep))) return null;

    const count = this.getInTransitQuantityValueByStock(stock);

    if (!count) {
      return null;
    }

    const unit = this.unitByEnum;

    return [
      count,
      ...(unit ? [unit] : []),
    ].join(' ');
  }

  getStockAndInTransitQuantityByStock(stock: StockDto) {
    if (!stock || (this.isSet && isNil(this.prep))) return null;

    const count = this.getStockQuantityValueByStock(stock);
    const inTransitCount = this.getInTransitQuantityValueByStock(stock);
    const unit = this.unitByEnum;

    if (!inTransitCount || !isActiveFeatureInTransitStock()) {
      return [
        count,
        ...(unit ? [unit] : []),
      ].join(' ');
    }

    return [
      count,
      ...(unit ? [unit] : []),
      `(${inTransitCount > 0 ? '+' : '-'}${inTransitCount})`,
    ].join(' ');
  }


  getStandaloneItemWithinContext(context?: DocumentDto) {
    const document = new DocumentDto({header: {}});
    if (context) {
      document.discounts = context.discounts;
      this.quantity = this.quantity ?? 1; // not sure about this
    } else {
      this.quantity = Math.abs(this.quantity || 1); // not sure about this
    }


    document.addItem(this);

    recalculateDocument({
      document: <import('@designeo/pos-promotion-engine').DocumentDto> document.toJson(),
    });

    const processedItem = document.itemsGroupedBySets[0];

    return processedItem?.mainItem;
  }

  getStandaloneValueAfterDiscountsWithinContext(context?: DocumentDto) {
    return this.getStandaloneItemWithinContext(context)?.valueAfterDiscounts;
  }

  getContextGroup(context: DocumentDto, itemAtIndex: number) {
    return context.itemsGroupedBySets[itemAtIndex];
  }

  setCount() {
    this.count = Math.abs(this.quantity) * (this.isReturn ? -1 : 1);
  }

  setDescriptionWithinContext(context: DocumentDto, itemAtIndex: number, description: string) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.description = description;
    this.description = description;
  }

  setQuantityWithinContext(context: DocumentDto, itemAtIndex: number, quantity: number) {
    const {
      mainItem,
      components,
      mainItemIsEditableSet,
      editableItem,
    } = this.getContextGroup(context, itemAtIndex);

    if (!mainItemIsEditableSet && mainItem.isSet) {
      for (const component of components) {
        component.quantity = quantity * (Math.abs(component.quantity) / Math.abs(mainItem.quantity));

        component.setCount();
      }
    }

    editableItem.quantity = quantity;

    editableItem.setCount();

    context.refreshItemsAttributes();
  }

  setPriceNormalWithinContext(context: DocumentDto, itemAtIndex: number, priceNormal: number) {
    const group = this.getContextGroup(context, itemAtIndex);

    submitJournalEventDocumentItemPriceChange(group, priceNormal);

    group.editableItem.priceAction = priceNormal;
    group.editableItem.priceNormal = priceNormal;
    this.priceAction = priceNormal;
    this.priceNormal = priceNormal;

    context.refreshItemsAttributes();
  }

  setSerialNoWithinContext(context: DocumentDto, itemAtIndex: number, serialNo: string) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.serialNo = serialNo;
    this.serialNo = serialNo;
  }

  setInvoiceNoWithinContext(context: DocumentDto, itemAtIndex: number, invoiceNo: string) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.invoiceNo = invoiceNo;
    this.invoiceNo = invoiceNo;
  }

  setPhoneNumberWithinContext(context: DocumentDto, itemAtIndex: number, phoneNumber: string) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.phoneNumber = phoneNumber;
    this.phoneNumber = phoneNumber;
  }

  setIsReturnWithinContext(context: DocumentDto, itemAtIndex: number, isReturn: boolean) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.isReturn = isReturn;
    this.isReturn = isReturn;

    editableItem.setCount();
    this.setCount();
  }

  setItemReturnDisabledWithinContext(context: DocumentDto, itemAtIndex: number, itemReturnDisabled: boolean) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.itemReturnDisabled = itemReturnDisabled;
    this.itemReturnDisabled = itemReturnDisabled;
  }

  setItemStornoDisabledWithinContext(context: DocumentDto, itemAtIndex: number, itemStornoDisabled: boolean) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.itemStornoDisabled = itemStornoDisabled;
    this.itemStornoDisabled = itemStornoDisabled;
  }

  setDocumentStornoDisabledWithinContext(context: DocumentDto, itemAtIndex: number, documentStornoDisabled: boolean) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.documentStornoDisabled = documentStornoDisabled;
    this.documentStornoDisabled = documentStornoDisabled;
  }

  setStornoReferenceWithinContext(context: DocumentDto, itemAtIndex: number, stornoReference: string) {
    const {editableItem, components} = this.getContextGroup(context, itemAtIndex);

    editableItem.originalDocumentReference = stornoReference;
    this.originalDocumentReference = stornoReference;

    for (const component of components) {
      component.originalDocumentReference = stornoReference;
    }

    for (const component of this.setItems ?? []) {
      component.originalDocumentReference = stornoReference;
    }
  }

  setStornoReasonWithinContext(context: DocumentDto, itemAtIndex: number, stornoReason: string) {
    const {editableItem, components} = this.getContextGroup(context, itemAtIndex);

    editableItem.returnReasonCode = stornoReason;
    this.returnReasonCode = stornoReason;

    for (const component of components) {
      component.returnReasonCode = stornoReason;
    }

    for (const component of this.setItems ?? []) {
      component.returnReasonCode = stornoReason;
    }
  }

  setOriginalDocumentFiscalReferenceWithinContext(
    context: DocumentDto,
    itemAtIndex: number,
    originalDocumentFiscalReference: string,
  ) {
    const {editableItem, components} = this.getContextGroup(context, itemAtIndex);

    editableItem.originalDocumentFiscalReference = originalDocumentFiscalReference;
    this.originalDocumentFiscalReference = originalDocumentFiscalReference;

    for (const component of components) {
      component.originalDocumentFiscalReference = originalDocumentFiscalReference;
    }

    for (const component of this.setItems ?? []) {
      component.originalDocumentFiscalReference = originalDocumentFiscalReference;
    }
  }

  setIsNegativeWithinContext(context: DocumentDto, itemAtIndex: number, isNegative: boolean) {
    const {mainItem, editableItem} = this.getContextGroup(context, itemAtIndex);

    mainItem.isNegative = isNegative;
    this.isNegative = isNegative;

    editableItem.isNegative = isNegative;
    this.isNegative = isNegative;

    const invertedQuantity = Math.abs(mainItem.quantity) * (isNegative ? -1 : 1);

    mainItem.quantity = invertedQuantity; // editable set case

    mainItem.setQuantityWithinContext(context, itemAtIndex, invertedQuantity);

    context.refreshItemsAttributes();
  }

  setLogisticCodeWithinContext(context: DocumentDto, itemAtIndex: number, logisticCode: string) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.logisticCode = logisticCode;
    this.logisticCode = logisticCode;
  }

  setValidationCodeWithinContext(context: DocumentDto, itemAtIndex: number, validationCode: string) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.validationCode = validationCode;
    this.validationCode = validationCode;
  }

  setMaxQuantityWithinContext(context: DocumentDto, itemAtIndex: number, maxQuantity: number) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.maxQuantity = maxQuantity;
    this.maxQuantity = maxQuantity;
  }

  toString() {
    return `${this.description} ${this.quantity}${this.unitByEnum}`;
  }

  sanitizeCustomOriginalDocumentFiscalReferenceWithinContext(context: DocumentDto, itemAtIndex: number) {
    const {editableItem, components} = this.getContextGroup(context, itemAtIndex);

    if (editableItem.isReturn) {
      return;
    }

    if (editableItem.originalDocumentFiscalReference) {
      return;
    }

    if (!context.isModeSale) {
      return;
    }

    const {
      customOriginalDocumentFiscalReference = {},
    } = DocumentItemDto.configurationStore.configuration.value?.features?.sell ?? {};

    if (!has(customOriginalDocumentFiscalReference, editableItem.internalNumber)) {
      return;
    }

    const preset = customOriginalDocumentFiscalReference[editableItem.internalNumber];

    editableItem.setOriginalDocumentFiscalReferenceWithinContext(context, itemAtIndex, preset);
    this.setOriginalDocumentFiscalReferenceWithinContext(context, itemAtIndex, preset);
  }

  /**
   * Check and charge
   */

  get isCheckAndCharge() {
    return !!this.serviceType;
  }

  get isServiceTypeLot() {
    return this.serviceType === CheckAndChargeServiceTypes.LOT;
  }

  get isServiceTypePOR() {
    return this.serviceType === CheckAndChargeServiceTypes.POR;
  }

  get isServiceTypePOSA() {
    return this.serviceType === CheckAndChargeServiceTypes.POSA;
  }

  get isServiceTypePR() {
    return this.serviceType === CheckAndChargeServiceTypes.PR;
  }

  get isServiceTypeVoucherDiscount() {
    return this.serviceType === CheckAndChargeServiceTypes.VOUCHER_DISCOUNT;
  }

  get isServiceTypeKorunka() {
    return this.serviceType === CheckAndChargeServiceTypes.KORUNKA;
  }

  get needExternalConfiguration() {
    if (this.isCheckAndCharge && this.isServiceTypeKorunka && !this.checkRequest) {
      return true;
    }

    return false;
  }

  get externalConfigurationWorkflowCode() {
    if (this.isServiceTypeKorunka) {
      return this.externalConfigurationWorkflowCodeForKorunka;
    }

    return null;
  }

  get externalConfigurationWorkflowCodeForKorunka() {
    const code = DocumentItemDto.configurationStore.configuration.value
      ?.features
      ?.korunka
      ?.workflowCodesByArticles
      ?.[this.internalNumberWithBatch];

    const workflowCodesByGameType = DocumentItemDto.configurationStore.configuration.value
      ?.features
      ?.korunka
      ?.workflowCodesByGameType;

    const gameType = invert(workflowCodesByGameType)?.[code] as Korunka['BetTicketPartnerResource']['gameType'];

    // NOTE: Cannot import KorunkaEntityTypes enum because of circular dependency =(
    // TODO: should be fixed
    if (gameType === 'KOMBI' || gameType === 'KOMBI_PLUS') {
      return null;
    }

    if (!code) {
      return null;
    }

    return code;
  }

  getCommonCheckAndChargeParams(context: DocumentDto) {
    return {
      gtin: this.gtin,
      articleNumber: this.internalNumber,
      receiptGuid: context?.header?.uniqueidentifier,
    };
  }

  getCheckAndChargeCheckRequest(context: DocumentDto, data = null) {
    switch (this.serviceType) {
    case CheckAndChargeServiceTypes.LOT:
      return new LotCheckRequestDto(data ?? {
        logisticCode: this.logisticCode,
        validationCode: this.validationCode,
        salePackage: this.salePackage,
        requestId: guid(),
      });
    case CheckAndChargeServiceTypes.POR:
      return new PORCheckRequestDto(data ?? {
        ean: this.gtin,
        amount: this.priceNormal,
        currency: DocumentItemDto.configurationStore.localCurrency.value.isoNumber,
        requestId: guid(),
      });
    case CheckAndChargeServiceTypes.POSA:
      return new POSACheckRequestDto(data ?? {
        ean: this.gtin,
        pan: this.serialNo,
        amount: this.priceNormal,
        currency: DocumentItemDto.configurationStore.localCurrency.value.isoNumber,
        requestId: guid(),
      });
    case CheckAndChargeServiceTypes.PR:
      return new PRCheckRequestDto(data ?? {
        operatorName: this.serviceParam,
        phoneNumber: this.phoneNumber,
        confirmPhoneNumber: this.phoneNumber,
        amount: this.priceNormal,
        currency: DocumentItemDto.configurationStore.localCurrency.value.isoNumber,
        requestId: guid(),
      });
    case CheckAndChargeServiceTypes.BTC:
      return new BTCCheckRequestDto(data ?? {
        paymentNumber: this.btcPaymentNumber,
        pin: this.btcPin,
        requestId: guid(),
      });
    case CheckAndChargeServiceTypes.VOUCHER_DISCOUNT:
      return new VoucherDiscountCheckRequestDto(data ?? {
        voucherNumber: this.serialNo,
        requestId: guid(),
      });
    case CheckAndChargeServiceTypes.KORUNKA:
      if (data) {
        return new KorunkaCheckRequestDto({
          requestId: data.requestId,
          metadata: data.metadata,
          korunkaRequest: KorunkaCheckRequestDto.processEnvelopeFromContextForCheck(context, this),
        });
      } else {
        throw new Error(
          `Ch&Ch type ${CheckAndChargeServiceTypes.KORUNKA} has to be composed and saved from external source`,
        );
      }
    default:
      return null;
    }
  }

  ensureCheckAndChargeCheckRequest(context: DocumentDto, itemAtIndex, {cache = true} = {}) {
    if (cache && this.checkRequest) {
      return this.getCheckAndChargeCheckRequest(context, this.checkRequest);
    }

    const request = this.getCheckAndChargeCheckRequest(context);
    this.setCheckRequestWithinContext(
      context,
      itemAtIndex,
      request,
    );

    return request;
  }

  getCheckAndChargeChargeRequest(context: DocumentDto, data = null) {
    switch (this.serviceType) {
    case CheckAndChargeServiceTypes.LOT:
      return new LotChargeRequestDto(data ?? {
        logisticCode: this.logisticCode,
        validationCode: this.validationCode,
        salePackage: this.salePackage,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.POR:
      return new PORChargeRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestedReceiptCharsPerLine: 2,
        requestedReceiptLanguage: DocumentItemDto.configurationStore.configuration.value.general.language.value,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.POSA:
      return new POSAChargeRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestedReceiptCharsPerLine: 2,
        requestedReceiptLanguage: DocumentItemDto.configurationStore.configuration.value.general.language.value,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.PR:
      return new PRChargeRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestedReceiptCharsPerLine: 2,
        requestedReceiptLanguage: DocumentItemDto.configurationStore.configuration.value.general.language.value,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.BTC:
      return new BTCChargeRequestDto(data ?? {
        paymentNumber: this.btcPaymentNumber,
        pin: this.btcPin,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.VOUCHER_DISCOUNT:
      return new VoucherDiscountChargeRequestDto(data ?? {
        voucherNumber: this.serialNo,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.KORUNKA:
      return new KorunkaChargeRequestDto(data ?? {
        requestId: this.checkRequest.requestId,
        korunkaRequest: KorunkaChargeRequestDto.processEnvelopeFromContextForCharge(context, this),
      });
    default:
      return null;
    }
  }

  ensureCheckAndChargeChargeRequest(context: DocumentDto, itemAtIndex) {
    if (this.chargeRequest) {
      const request = this.getCheckAndChargeChargeRequest(context, this.chargeRequest);

      const isValid = request.isValid(context, itemAtIndex);

      if (isValid) {
        return request;
      }
    }

    const request = this.getCheckAndChargeChargeRequest(context);

    this.setChargeRequestWithinContext(
      context,
      itemAtIndex,
      request,
    );

    return request;
  }

  getCheckAndChargeRevokeRequest(context: DocumentDto, data = null) {
    switch (this.serviceType) {
    case CheckAndChargeServiceTypes.LOT:
      return new LotRevokeRequestDto(data ?? {
        logisticCode: this.logisticCode,
        salePackage: this.salePackage,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.POR:
      return new PORRevokeRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.POSA:
      return new POSARevokeRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.PR:
      return new PRRevokeRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.VOUCHER_DISCOUNT:
      return new VoucherDiscountRevokeRequestDto(data ?? {
        voucherNumber: this.serialNo,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.KORUNKA:
      return new KorunkaRevokeRequestDto(data ?? {
        requestId: this.checkRequest.requestId,
        korunkaRequest: KorunkaRevokeRequestDto.processEnvelopeFromContextForRevoke(context, this),
      });
    default:
      return null;
    }
  }

  ensureCheckAndChargeRevokeRequest(context: DocumentDto, itemAtIndex) {
    if (this.revokeRequest) {
      return this.getCheckAndChargeRevokeRequest(context, this.revokeRequest);
    }

    const request = this.getCheckAndChargeRevokeRequest(context);

    this.setRevokeRequestWithinContext(
      context,
      itemAtIndex,
      request,
    );

    return request;
  }

  getCheckAndChargeCancelRequest(context: DocumentDto, data = null) {
    switch (this.serviceType) {
    case CheckAndChargeServiceTypes.LOT:
      return new LotCancelRequestDto(data ?? {
        logisticCode: this.logisticCode,
        salePackage: this.salePackage,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.POR:
      return new PORCancelRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.POSA:
      return new POSACancelRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.PR:
      return new PRCancelRequestDto(data ?? {
        authorizationRequestId: this.checkResponse.responseObject.authorizationId,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.VOUCHER_DISCOUNT:
      return new VoucherDiscountCancelRequestDto(data ?? {
        voucherNumber: this.serialNo,
        requestId: this.checkRequest.requestId,
      });
    case CheckAndChargeServiceTypes.KORUNKA:
      return new KorunkaCancelRequestDto(data ?? {
        requestId: this.checkRequest.requestId,
        korunkaRequest: KorunkaCancelRequestDto.processEnvelopeFromContextForCancel(context, this),
      });
    default:
      return null;
    }
  }

  ensureCheckAndChargeCancelRequest(context: DocumentDto, itemAtIndex) {
    if (this.cancelRequest) {
      return this.getCheckAndChargeCancelRequest(context, this.cancelRequest);
    }

    const request = this.getCheckAndChargeCancelRequest(context);

    this.setCancelRequestWithinContext(
      context,
      itemAtIndex,
      request,
    );

    return request;
  }

  get isCheckAndChargeCancellable() {
    if (this.checkAndChargeShouldCancel) {
      return true;
    }

    return this.chargeResponse?.isSuccessful || this.checkResponse?.isSuccessful;
  }

  setCheckRequestWithinContext(context: DocumentDto, itemAtIndex: number, checkRequest: Entity<any, any>) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);
    editableItem.checkRequest = checkRequest.toJson();
    this.checkRequest = checkRequest.toJson();
  }

  setCheckResponseWithinContext(context: DocumentDto, itemAtIndex: number, checkResponse: CheckResponseDto, {
    process = true,
  } = {}) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.checkResponse = checkResponse;
    this.checkResponse = checkResponse;

    if (process) {
      checkResponse.process(context, itemAtIndex, this);
    }
  }

  setChargeRequestWithinContext(context: DocumentDto, itemAtIndex: number, chargeRequest: Entity<any, any>) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.chargeRequest = chargeRequest.toJson();
    this.chargeRequest = chargeRequest.toJson();
  }

  setChargeResponseWithinContext(context: DocumentDto, itemAtIndex: number, chargeResponse: ChargeResponseDto, {
    process = true,
  } = {}) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.chargeResponse = chargeResponse;
    this.chargeResponse = chargeResponse;

    if (process) {
      chargeResponse.process(context, itemAtIndex, this);
    }
  }

  setRevokeRequestWithinContext(context: DocumentDto, itemAtIndex: number, revokeRequest: Entity<any, any>) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.revokeRequest = revokeRequest.toJson();
    this.revokeRequest = revokeRequest.toJson();
  }

  setRevokeResponseWithinContext(context: DocumentDto, itemAtIndex: number, revokeResponse: RevokeResponseDto, {
    process = true,
  } = {}) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.revokeResponse = revokeResponse;
    this.revokeResponse = revokeResponse;

    if (process) {
      revokeResponse.process(context, itemAtIndex, this);
    }
  }

  setCancelRequestWithinContext(context: DocumentDto, itemAtIndex: number, cancelRequest: Entity<any, any>) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.cancelRequest = cancelRequest.toJson();
    this.cancelRequest = cancelRequest.toJson();
  }

  setCancelResponseWithinContext(context: DocumentDto, itemAtIndex: number, cancelResponse: CancelResponseDto, {
    process = true,
  } = {}) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.cancelResponse = cancelResponse;
    this.cancelResponse = cancelResponse;

    if (process) {
      cancelResponse.process(context, itemAtIndex, this);
    }
  }

  setCheckAndChargeShouldCancelWithinContext(context: DocumentDto, itemAtIndex: number, shouldCancel: boolean) {
    const {editableItem} = this.getContextGroup(context, itemAtIndex);

    editableItem.checkAndChargeShouldCancel = shouldCancel;
    this.checkAndChargeShouldCancel = shouldCancel;
  }

  get defaultUniqueProps(): Array<keyof DocumentItemDto['_data']> {
    /**
     * TODO: get default props from item property XY
     */

    // fallback

    if (!this.isCheckAndCharge || !this.isServiceTypeLot) {
      return [];
    }

    return ['logisticCode', 'validationCode'];
  }

  /**
   * This is prepared for another development of unique params
   */
  validateIsUnique(context: DocumentDto, props = this.defaultUniqueProps) {
    if (!props.length) {
      return {
        result: true,
        failedProp: null,
      };
    }

    for (const prop of props) {
      if (!context.itemIsUniqueBy(this, prop)) {
        return {
          result: false,
          failedProp: prop,
        };
      }
    }

    return {
      result: true,
      failedProp: null,
    };
  }

  /**
   * Temporary solution for lot validation, article should have info (from PLU) about unique properties
   */
  validateCheckAndChargeTypeLotWithinContext(context: DocumentDto) {
    const {
      result,
    } = this.validateIsUnique(context);

    return result;
  }

  /**
   * Temporary solution for voucher discount valiadion, article should have info (from PLU) about unique properties
   */
  validateCheckAndChargeTypeVoucherDiscountWithinContext(context: DocumentDto) {
    if (!this.isCheckAndCharge || !this.isServiceTypeVoucherDiscount) {
      return [];
    }

    const {
      result,
    } = this.validateIsUnique(context, ['serialNo']);

    return result;
  }

  validateLotteryCode(lotteryCode) {
    if (!this.lotteryCode) {
      return true;
    }

    return this.lotteryCode === lotteryCode;
  }

  sanitizeCheckAndCharge(context: DocumentDto, itemAtIndex: number) {
    // TODO: Sanitize charge / revoke / cancel requests/responses
    const checkRequest = this.getCheckAndChargeCheckRequest(context, this.checkRequest)?.getSanitizedEntity();

    if (!checkRequest) return;

    this.setCheckRequestWithinContext(context, itemAtIndex, checkRequest);
  }

  toDocumentLogisticItem() {
    const fields = keys(new DocumentLogisticItemDto({}).$fields);
    const data = pick(this.toJson(), fields);

    return new DocumentLogisticItemDto({...data, price: this.priceNormal ?? 0});
  }
}
