import {markRaw} from 'vue';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {apiDiscoveryDiscover, apiLotteryTurnovers} from '@/Model/Action';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export class WorkflowStepApiLotteryTurnovers extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiLotteryTurnovers;
  }

  get type() {
    return WorkflowStepApiLotteryTurnovers.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get wholeShop() {
    return this.step?.wholeShop ?? false;
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      if (this.step.discovery) {
        try {
          await apiDiscoveryDiscover();
        } catch (e) {
          console.error(e);
        }
      }

      const lotteryTurnovers = await apiLotteryTurnovers({
        params: {
          provider: this.step.lotteryProvider,
          wholeShop: this.wholeShop,
        },
      });

      this.dataSetter(WorkflowStepField.lotteryTurnovers, () => lotteryTurnovers.toJson());
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.LOTTERY_TURNOVERS_FETCH_FAILED,
          value: e,
        },
      }));
      throw e;
    } finally {
      emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:turnoversLoaded`);
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
