import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center z-10"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-grow flex flex-col items-center justify-center text-basic-dark"
}
const _hoisted_3 = { class: "text-sm w-56 text-center mt-3.5" }
const _hoisted_4 = { class: "mt-3 text-sm" }
const _hoisted_5 = {
  key: 2,
  class: "flex-grow flex flex-col items-center justify-center text-basic-dark"
}
const _hoisted_6 = { class: "w-56 text-center mt-3.5 font-bold uppercase text-xl" }
const _hoisted_7 = {
  key: 3,
  class: "flex-grow flex items-center justify-center text-basic-dark"
}
const _hoisted_8 = {
  key: 4,
  class: "flex-grow overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLoaderPulse = _resolveComponent("IconLoaderPulse")!
  const _component_IconCube = _resolveComponent("IconCube")!
  const _component_ModalStockInStoresBodyTable = _resolveComponent("ModalStockInStoresBodyTable")!
  const _component_HLPersistScroll = _resolveComponent("HLPersistScroll")!

  return (_openBlock(), _createBlock(_component_HLPersistScroll, {
    active: !$setup.isLoading,
    saveEnabled: !$setup.isLoading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-body overflow-auto modal-body--limited relative flex flex-grow pt-1.5", {'h-screen-73-h': !$setup.showKeyboard}])
      }, [
        ($setup.isLoading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_IconLoaderPulse, { class: "w-16 h-16 text-center self-center stroke-current" })
            ]))
          : (!$setup.inputBufferMeetLength)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_IconCube),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('stockInStores.grid.initInfo')), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('stockInStores.grid.queryMinLength', {length: $setup.minProductSearchLength})), 1)
              ]))
            : ($setup.fetchError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('stockInStores.grid.fetchError')), 1)
                ]))
              : (!$setup.fetchResult.length )
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('general.noResults')), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_ModalStockInStoresBodyTable, { class: "max-h-0" })
                  ]))
      ], 2)
    ]),
    _: 1
  }, 8, ["active", "saveEnabled"]))
}