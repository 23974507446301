import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = {
  key: 0,
  class: "text-danger flex flex-col justify-center items-center"
}
const _hoisted_4 = { class: "font-bold text-center px-10" }
const _hoisted_5 = {
  key: 1,
  class: "text-danger flex flex-col justify-center items-center"
}
const _hoisted_6 = { class: "font-bold text-center px-10" }
const _hoisted_7 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_HLInput, {
              field: _ctx.WorkflowStepField.safeBagDeliveryListCode
            }, {
              default: _withCtx(({isActive, value, setActive, error}) => [
                _createVNode(_component_FormInput, {
                  label: workflowStep.step.safeBagDeliveryList,
                  active: isActive,
                  error: error,
                  layout: "col",
                  errorOutput: 'modal',
                  onClick: setActive
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value.value), 1)
                  ]),
                  _: 2
                }, 1032, ["label", "active", "error", "onClick"])
              ]),
              _: 2
            }, 1032, ["field"])
          ]),
          (_ctx.online && workflowStep.manualMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createVNode(_component_PhIcon, {
                    size: 54,
                    icon: "warning"
                  })
                ]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(workflowStep.step.manualNote), 1)
              ]))
            : (workflowStep.manualMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_PhIcon, {
                      size: 54,
                      icon: "warning"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(workflowStep.step.offlineNote), 1)
                ]))
              : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_WorkflowTransitions, {
            nextDisabled: workflowStep.disabledNextStep,
            nextAction: async () => await workflowStep.processSafeBagDeliveryListCode()
          }, null, 8, ["nextDisabled", "nextAction"])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}