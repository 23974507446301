
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useCrashStore} from '@/Modules/Core/store/CrashStore';
import {ZLayer} from '@/constants/zLayer';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';

export default defineComponent({
  name: 'ModalCrashState',
  components: {
    AsyncButton,
    ModalDetachedButtons,
    Modal,
  },
  setup() {
    const crashStore = useCrashStore();
    const {isSecondaryDisplay} = useSecondaryDisplay();

    const open = computed(() => {
      if (isSecondaryDisplay) {
        return false;
      }

      return crashStore.active.value;
    });

    const refresh = () => {
      window.location.reload();
    };

    return {
      open,
      refresh,
      ZLayer,
    };
  },
});
