import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DialogItemDto from '../DialogItemDto';
import {NumberType} from '@designeo/apibundle-js';


export const DialogDtoPropDef = {
  displayBase64: {
    type: new StringType(),
  },
  dialogItems: {
    type: new ArrayType(new EntityType(DialogItemDto)),
  },
  timeoutInSeconds: {
    type: new NumberType(),
  },
};
export default class GeneratedDialogDto extends createEntity(DialogDtoPropDef) {}
