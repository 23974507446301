import {
  App,
} from 'vue';
import {useCrashStore} from '@/Modules/Core/store/CrashStore';
import {IRestoredErrorRecord} from '@/Helpers/restoreMode';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
  isRestoreMode: boolean,
  restoredErrorRecord: IRestoredErrorRecord
}

export type RegisterTestHelpersContext = {
}

const crashErrors = {
  unexpectedToken: 'Uncaught SyntaxError: Unexpected token \'<\'',
  loadingChunkFailed: /Loading chunk [0-9]+ failed.?/,
};

const isCrash = (event: ErrorEvent) => {
  for (const error of [crashErrors.unexpectedToken, crashErrors.loadingChunkFailed]) {
    if (error instanceof RegExp && error.test(event.message)) {
      return true;
    } else if (error === event.message) {
      return true;
    }
  }

  return false;
};

export function registerCrashDetection(ctx: CoreRequiredContext) {
  if (ctx.isRestoreMode) {
    console.warn('[RestoreMode] crash detection disabled');
    return {};
  }

  window.addEventListener('error', function(event) {
    if (isCrash(event)) {
      useCrashStore().setState(true);
    }
  });


  return {};
}
