import {DateTime} from 'luxon';
import {useDateTime} from '@/Helpers/dateTime';

export const useInputDate = (format) => {
  const {formatDate} = useDateTime(format);

  const getToday = () => {
    return {
      isDisabled: false,
      date: formatDate(DateTime.now()),
    };
  };

  const getYesterday = () => {
    return {
      isDisabled: false,
      date: formatDate(DateTime.now().minus({days: 1})),
    };
  };

  return {
    getToday,
    getYesterday,
  };
};
