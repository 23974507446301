
import {defineComponent} from 'vue';
import {useIconAttrs} from '@/Helpers/iconAttrs';

export default defineComponent({
  name: 'IconCheck',
  setup(props, {attrs}) {
    const {hasActiveAttribute} = useIconAttrs(attrs);

    return {
      hasActiveAttribute,
    };
  },
});
