import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {
  AuthActions,
  AuthInput,
  AuthInputEvent,
  AuthPerson,
  AuthState,
  AuthStoreEvent,
  LoginType,
  VerifyCashierStatus,
} from '../types';
import {
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_DELETE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {
  apiAccountPermissionsGetList,
  apiAccountVerifyCashier,
  apiLogin,
} from '@/Model/Action';
import {
  filter,
  findIndex,
  mapKeys,
  orderBy,
  reject,
} from 'lodash-es';
import {jwtIsExpired, parseJwt} from '@/Helpers/jwt';
import {BufferedInput, InputSource} from '../../Register/services/KeyboardBuffer';
import {useConfigurationStore} from '../../Core/store/ConfigurationStore';
import {authStoreStatePersist} from '@/Helpers/persist';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {MAX_QUICK_LOGIN_PEOPLE} from '@/constants/auth';
import {CashierVerifyResultDto, PermissionDto} from '@/Model/Entity';
import {
  submitJournalEventOperatorLoginFailed,
  submitJournalEventOperatorSuccessfulLogin,
  submitJournalEventOperatorSuccessfulLogout,
} from '@/Helpers/journal';
import {sentryRecordOperatorChange} from '@/Helpers/sentry';
import {PasswordStatuses} from '@/constants/passwordStatuses';
import {preventParallel} from '@/Helpers/promise';
import {useExternalApps} from '@/Helpers/externalApps';
import {isElectron, isVueAppTypeApp} from '@/Helpers/app';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

const createAuthInput = (data: Partial<AuthInput> = {}): AuthInput => ({
  username: '',
  password: '',
  quickLogin: false,
  autoQuickLogin: false,
  timestamp: new Date().getTime(),
  invalid: false,
  verifyStatus: null,
  ...data,
});


export class AuthStore extends PersistentStore<{
  state: AuthState,
  previousState: AuthState,
  authInput: AuthInput,
  accessToken: string,
  people: Array<AuthPerson>,
  lastLoggedPerson: AuthInput['username'],
  isInInventoryApp: boolean,
}> {
  constructor() {
    super({
      state: AuthState.CHOOSE_PERSON,
      previousState: null,
      accessToken: null,
      authInput: createAuthInput(),
      people: [],
      lastLoggedPerson: null,
      isInInventoryApp: false,
    }, authStoreStatePersist);

    if (this.state.state === AuthState.QUICK_LOGIN_CONFIRM && !this.activePerson.value) {
      this.determineLandingState();
    } else if (this.state.state === AuthState.CHOOSE_PERSON && !filter(this.state.people, {quickLogin: true}).length) {
      this.changeState(AuthState.ENTER_PERSONAL_NUMBER);
    }
  }

  onKeyboardInput = action((bufferedInput: BufferedInput) => {
    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        this.onEventInput({
          type: AuthActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        this.onEventInput({
          type: AuthActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        this.onEventInput({
          type: AuthActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_DELETE) {
        this.onEventInput({
          type: AuthActions.CLEAR,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        this.onEventInput({
          type: AuthActions.CANCEL,
        });
      } else if (key.key.length === 1) { // TODO: how to sanitize "rest" chars?
        this.onEventInput({
          type: AuthActions.ADD_CHAR,
          value: key.key,
        });
      }
    }

    if (bufferedInput.source === InputSource.SCANNER) {
      this.onEventInput({
        type: AuthActions.ENTER,
      });
    }
  });

  hydratePeople = action(
    (person: {
      username: string,
      quickLogin: boolean,
      timestamp: number,
      permissions?: PermissionDto[],
      forcePinChange?: boolean,
      remoteAppTimestamp?: string,
    }, accessToken) => {
      const people = this.state.people;

      const userIndex = findIndex(people, {username: person.username});

      people.splice(userIndex >= 0 ? userIndex : people.length, 1, {
        username: person.username,
        quickLogin: person.quickLogin,
        timestamp: accessToken ? +new Date() : person.timestamp,
        accessToken,
        tokenInfo: userIndex >= 0 && !accessToken ?
          people[userIndex].tokenInfo :
          accessToken && parseJwt(accessToken),
        permissions: person.permissions ?? [],
        forcePinChange: person.forcePinChange ?? false,
        remoteAppTimestamp: person.remoteAppTimestamp ?? null,
      });

      this.state.people = people;
    },
  )

  logout = action(async (timeout = false) => {
    if (!this.isLoggedIn.value) return;

    const person = this.activePerson.value;
    this.hydratePeople(this.activePerson.value, null);
    this.state.accessToken = null;
    await this.persist(); // we need to persist it immediately
    await this.onLogout(person, timeout);
  })

  openQuickLoginEditMode = action(() => {
    this.changeState(AuthState.QUICK_LOGIN_EDIT);
  })

  determineLandingState = action(() => {
    this.changeState(
      filter(this.state.people, {quickLogin: true}).length ?
        AuthState.CHOOSE_PERSON :
        AuthState.ENTER_PERSONAL_NUMBER,
    );
  })

  fetchPermissions = action(async () => {
    if (!this.isLoggedIn.value) return;

    try {
      this.hydratePeople(
        {...this.activePerson.value, permissions: await apiAccountPermissionsGetList()},
        this.state.accessToken,
      );
    } catch (e) {
      console.error(e);
    }
  })

  changeState = action((state: AuthState) => {
    this.state.previousState = this.state.state;
    this.state.state = state;
    this.transitions[state]?.[AuthActions.INIT]?.();
  })

  transitions: {[key in AuthState]?: {[key in AuthActions]?: any}} = {
    [AuthState.CHOOSE_PERSON]: {
      [AuthActions.INIT]: action(async () => {
        if (!this.quickLoginPeople.value.length) {
          this.changeState(AuthState.ENTER_PERSONAL_NUMBER);
        }
      }),
      [AuthActions.NEW_USER]: action(async (event: AuthInputEvent) => {
        if (this.isLoggedIn.value) {
          await this.logout();
        }

        this.state.authInput = createAuthInput();
        this.changeState(AuthState.ENTER_PERSONAL_NUMBER);
      }),
      [AuthActions.ENTER]: action(async (event: AuthInputEvent) => {
        if (!event.value) return;

        if (this.activePerson.value && event.value.username !== this.activePerson.value.username) {
          await this.logout();
        }

        try {
          const result = (await apiAccountVerifyCashier({
            params: {cashierCode: event.value.username},
          }));

          this.state.authInput.verifyStatus = result;

          if (result.status.value !== VerifyCashierStatus.VALID) {
            this.changeState(AuthState.ENTER_PERSONAL_NUMBER);
            this.state.authInput.username = event.value.username;
            this.state.authInput.quickLogin = false;
            this.state.authInput.invalid = true;
            emitTestEvent(TestEvent.ERROR, result.status.value);
            return;
          }

          if (this.state.lastLoggedPerson === event.value.username && !jwtIsExpired(event.value.accessToken)) {
            this.state.accessToken = event.value.accessToken;
            this.state.authInput = createAuthInput();

            await this.fetchPermissions();

            this.onLogin(LoginType.JWT);
          } else {
            this.state.authInput = createAuthInput({
              username: event.value.username,
              quickLogin: true,
            });
            this.changeState(AuthState.ENTER_PIN);
          }
        } catch (e) {
          this.state.authInput.verifyStatus = e;
          this.state.authInput.invalid = true;
          console.error(e);
        }
      }),
    },
    [AuthState.ENTER_PERSONAL_NUMBER]: {
      [AuthActions.ADD_NUMBER]: action(async (event: AuthInputEvent) => {
        if (!event.value) return;

        if (this.state.authInput.username.length < this.usernameAutoConfirmLength.value) {
          this.state.authInput.invalid = false;
          this.state.authInput.username += event.value;
        }

        if (this.state.authInput.username.length === this.usernameAutoConfirmLength.value) {
          this.triggerEventInput({
            type: AuthActions.ENTER,
          });
        }
      }),
      [AuthActions.ADD_CHAR]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.username += event.value;
      }),
      [AuthActions.ENTER]: action(async (event: AuthInputEvent) => {
        if (!this.state.authInput.username) {
          this.state.authInput.invalid = true;
          return;
        }

        try {
          const result = (await apiAccountVerifyCashier({
            params: {cashierCode: this.state.authInput.username},
          }));

          this.state.authInput.verifyStatus = result;

          if (result.status.value === VerifyCashierStatus.VALID) {
            this.changeState(AuthState.ENTER_PIN);
          } else {
            submitJournalEventOperatorLoginFailed(this.state.authInput, LoginType.M);
            this.state.authInput.invalid = true;
          }
        } catch (e) {
          console.error(e);
          emitTestEvent(TestEvent.ERROR, e);
          submitJournalEventOperatorLoginFailed(this.state.authInput, LoginType.M);
          this.state.authInput.verifyStatus = e;
          this.state.authInput.invalid = true;
        }
      }),
      [AuthActions.CLEAR]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.username = '';
      }),
      [AuthActions.CANCEL]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.username = '';
        this.changeState(AuthState.CHOOSE_PERSON);
      }),
      [AuthActions.BACKSPACE]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.username = this.state.authInput.username
          .substr(0, this.state.authInput.username.length - 1);
      }),
    },
    [AuthState.ENTER_PIN]: {
      [AuthActions.ADD_NUMBER]: action(async (event: AuthInputEvent) => {
        if (!event.value) return;
        this.state.authInput.invalid = false;

        const oldVal = this.state.authInput.password;

        if (this.state.authInput.password.length < this.pinAutoConfirmLength.value) {
          this.state.authInput.invalid = false;
          this.state.authInput.password += event.value;
        }

        const newVal = this.state.authInput.password;

        if (
          this.state.authInput.password.length === this.pinAutoConfirmLength.value &&
          oldVal !== newVal
        ) {
          await this.triggerEventInput({
            type: AuthActions.ENTER,
          });
        }
      }),
      [AuthActions.CLEAR]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.password = '';
      }),
      [AuthActions.CANCEL]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.password = '';
        this.changeState(this.state.previousState);
      }),
      [AuthActions.BACKSPACE]: action(async (event: AuthInputEvent) => {
        this.state.authInput.invalid = false;
        this.state.authInput.password = this.state.authInput.password
          .substr(0, this.state.authInput.password.length - 1);
      }),
      [AuthActions.ENTER]: action(async (event: AuthInputEvent) => {
        if (!this.state.authInput.password) {
          this.state.authInput.invalid = true;
          return;
        }

        let auth: AuthInput | AuthPerson = this.state.authInput;

        try {
          const response = await apiLogin({
            input: {
              UserName: auth.username,
              Password: auth.password,
            },
          });

          /**
           * if currentPersonState is not null => already saved person with quickLogin: true
           */
          const currentPersonState = this.quickLoginPeopleByUsername.value[auth.username];

          auth = currentPersonState ?? auth;

          this.hydratePeople({
            ...auth,
            forcePinChange: response.password_status === PasswordStatuses.PasswordChangeRequired,
            /**
             * information below should be persisted
             */
            remoteAppTimestamp: this.peopleByUsername.value?.[auth.username]?.remoteAppTimestamp ?? null,
          }, response.access_token);

          this.state.lastLoggedPerson = auth.username;
          this.state.accessToken = response.access_token;
          this.state.authInput = createAuthInput();

          await this.fetchPermissions();

          if (
            (this.hasFreeQuickLoginSlots.value && !auth.quickLogin) &&
            !this.state.isInInventoryApp &&
            !currentPersonState
          ) {
            this.changeState(AuthState.QUICK_LOGIN_CONFIRM);
          } else {
            this.onLogin(LoginType.M);
            this.determineLandingState();
          }
        } catch (e) {
          console.error(e);
          emitTestEvent(TestEvent.ERROR, e);
          submitJournalEventOperatorLoginFailed(this.state.authInput, LoginType.M);
          this.state.authInput.verifyStatus = e;
          this.state.authInput.invalid = true;
          this.state.authInput.password = '';
        }
      }),
    },
    [AuthState.QUICK_LOGIN_CONFIRM]: {
      [AuthActions.ENTER]: action(async ({value = true}: AuthInputEvent) => {
        if (!this.isLoggedIn.value) {
          this.determineLandingState();
          return;
        }

        if (value) {
          this.hydratePeople(
            {...this.activePerson.value, quickLogin: true},
            this.state.accessToken,
          );
        }
        this.onLogin(LoginType.M);
        this.determineLandingState();
      }),
      [AuthActions.CANCEL]: action(async ({value = false}: AuthInputEvent) => {
        if (!this.isLoggedIn.value) {
          this.determineLandingState();
          return;
        }

        if (value) {
          this.hydratePeople(
            {...this.activePerson.value, quickLogin: true},
            this.state.accessToken,
          );
        }
        this.onLogin(LoginType.M);
        this.determineLandingState();
      }),
    },
    [AuthState.QUICK_LOGIN_EDIT]: {
      [AuthActions.CANCEL]: action((event: AuthInputEvent) => {
        this.determineLandingState();
      }),
      [AuthActions.NEW_USER]: action((event: AuthInputEvent) => {
        this.state.authInput.quickLogin = true;
        this.state.authInput.autoQuickLogin = true;
        this.changeState(AuthState.ENTER_PERSONAL_NUMBER);
      }),
    },
  }

  ensurePostLoginExternalApp = action(() => {
    if (!isElectron() || !isVueAppTypeApp()) {
      return;
    }

    const remoteApp = this.configuration.value?.features?.login?.remoteAppAfterLogin ?? null;

    if (!remoteApp) {
      return;
    }

    const remoteAppThrottle = this.configuration.value?.features?.login?.remoteAppAfterLoginThrottle ?? null;
    const activePersonRemoteAppLaunch = this.peopleByUsername.value
      ?.[this.activePerson.value?.username]
      ?.remoteAppTimestamp;

    const validateThrottle = () => {
      if (!remoteAppThrottle) {
        return true;
      }

      if (!activePersonRemoteAppLaunch) {
        return true;
      }

      const launchDate = new Date(activePersonRemoteAppLaunch);

      return ((+launchDate) + (remoteAppThrottle * 1000)) <= +new Date();
    };

    if (validateThrottle()) {
      this.hydratePeople(
        {...this.activePerson.value, remoteAppTimestamp: new Date().toISOString()},
        this.state.accessToken,
      );
      useExternalApps().open(remoteApp);
    }
  })

  onLogin = action((loginType: LoginType) => {
    this.dispatchEvent(new Event(AuthStoreEvent.LOGIN));
    sentryRecordOperatorChange(this.activePerson.value, true, {loginType});
    submitJournalEventOperatorSuccessfulLogin(this.activePerson.value, loginType);
    emitTestEvent(TestEvent.LOGIN);
    this.ensurePostLoginExternalApp();
  })

  onLogout = action(async (person, byTimeout) => {
    this.dispatchEvent(new Event(AuthStoreEvent.LOGOUT));
    sentryRecordOperatorChange(person, false);
    await submitJournalEventOperatorSuccessfulLogout(byTimeout);
  })

  storedPerson = getter(() => {
    const haveStoredPerson = Object.prototype.hasOwnProperty.call(
      this.quickLoginPeopleByUsername.value,
      this.authInput.value.username,
    );

    if (!haveStoredPerson) {
      return null;
    }

    return this.quickLoginPeopleByUsername.value[this.authInput.value.username];
  })

  authTitle = getter(() => {
    const storedPerson = this.storedPerson.value;

    if (storedPerson) {
      return `${storedPerson.tokenInfo?.first_name} ${storedPerson.tokenInfo?.last_name}`;
    }

    if (this.authInput.value.verifyStatus instanceof CashierVerifyResultDto) {
      const cashier = this.authInput.value.verifyStatus.cashier;
      return `${cashier.firstName} ${cashier.lastName}`;
    }

    return null;
  })

  activePeopleByAccessToken = getter(() => {
    return mapKeys(reject(this.state.people, {accessToken: null}), 'accessToken');
  })

  quickLoginPeopleByUsername = getter(() => {
    return mapKeys(filter(this.state.people, {quickLogin: true}), 'username');
  })

  peopleByUsername = getter(() => {
    return mapKeys(this.state.people, 'username');
  })

  hasFreeQuickLoginSlots = getter(() => {
    return filter(this.state.people, {quickLogin: true}).length < MAX_QUICK_LOGIN_PEOPLE;
  })

  activePerson = getter(() => {
    if (!this.state.accessToken || jwtIsExpired(this.state.accessToken)) return null;

    return this.activePeopleByAccessToken.value[this.state.accessToken];
  })

  lastLoggedPerson = getter(() => {
    return this.state.lastLoggedPerson;
  })

  isLoggedIn = getter(() => {
    return !!this.activePerson.value;
  });

  authorizationHeader = getter(() => {
    if (this.state.accessToken) {
      return this.createAuthorizationHeader(this.state.accessToken);
    }
    return undefined;
  });

  authInput = getter(() => this.state.authInput)

  password = getter(() => this.state.authInput.password)

  people = getter(() => this.state.people)

  quickLoginPeople = getter(() => {
    return orderBy(filter(this.state.people, {quickLogin: true}), 'timestamp', 'desc');
  })

  configuration = getter(() => {
    return useConfigurationStore().configuration.value;
  })

  usernameAutoConfirmLength = getter(() => {
    return this.configuration.value.features.login.usernameAutoConfirmLength ?? Infinity;
  })

  pinAutoConfirmLength = getter(() => {
    return this.configuration.value.features.login.pinAutoConfirmLength ?? Infinity;
  })

  isAuthStateChoosePerson = getter(
    () => this.state.state === AuthState.CHOOSE_PERSON,
  )

  isStateTypeInputPersonalNumber = getter(
    () => this.state.state === AuthState.ENTER_PERSONAL_NUMBER,
  )

  isStateTypeInputPin = getter(
    () => this.state.state === AuthState.ENTER_PIN,
  )

  isStateTypeQuickLoginConfirm = getter(
    () => this.state.state === AuthState.QUICK_LOGIN_CONFIRM,
  )

  isAuthStateQuickLoginEdit = getter(
    () => this.state.state === AuthState.QUICK_LOGIN_EDIT,
  )

  hasAuthStateAutoQuickLogin = getter(() => this.state.authInput.autoQuickLogin)

  createAuthorizationHeader = action((accessToken) => {
    return `Bearer ${accessToken}`;
  })

  setIsInInventoryApp = action((value: boolean) => {
    this.state.isInInventoryApp = value;
  })

  deletePersonFromQuickLogin = action((person: AuthPerson) => {
    this.hydratePeople(
      {...person, quickLogin: false},
      this.quickLoginPeopleByUsername.value[person.username].accessToken,
    );
  })

  triggerEventInput = (event: AuthInputEvent) => {
    setTimeout(() => this.onEventInput(event), 0);
  };

  onEventInput = preventParallel(action(async (event: AuthInputEvent) => {
    await this.transitions?.[this.state.state]?.[event.type]?.(event);
  }))
}

const storeIdentifier = 'AuthStore';

export const configureAuthStore = createConfigureStore<typeof AuthStore>(storeIdentifier);
export const useAuthStore = createUseStore(AuthStore, storeIdentifier);
