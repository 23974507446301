
export enum EshopOrdersActions {
  ADD_NUMBER = 'addNumber',
  ADD_CHAR = 'addNumber',
  BACKSPACE = 'backspace',
  ENTER = 'enter',
  CANCEL = 'cancel',
}

export type EshopOrdersInputEvent<V = any> = {
  type: EshopOrdersActions,
  value?: V
}

export enum EshopOrdersState {
  DEFAULT = 'default', // NOTE: Filters are closed
  ENTER_ORDER_NUMBER = 'enterOrderNumber',
  ENTER_CUSTOMER_NAME = 'enterCustomerName',
  ENTER_CUSTOMER_PHONE = 'enterCustomerPhone',
  ENTER_CUSTOMER_EMAIL = 'enterCustomerEmail',
  CHOOSE_ORDER_DATE = 'enterOrderDate',
  SELECT_ESHOP = 'enterEshop',
  SELECT_ORDER_STATUS = 'enterOrderStatus',
}

export enum EshopOrdersOrderStatus {
  NEW = 'New',
  IN_PREPARATION = 'InPreparation',
  READY_FOR_CUSTOMER = 'ReadyForCustomer',
  FULFILLED = 'Fulfilled',
  CANCELLED = 'Cancelled'
}

export enum EshopOrdersWSEvents {
  ORDER_NOTIFICATION_COUNT_CHANGED = 'OrderNotificationCountChanged'
}

// TODO: Needs EP
export enum EshopOrdersEshop {
  DESIGNEO = 'designeo',
}

export enum EshopOrdersChangeStateAction {
  FULFILL = 'fulfill',
  CANCEL = 'cancel',
}

export interface EshopOrdersStornoReasonOption {
  id: string,
  label: string,
}

export interface EshopOrdersTabAttrInterface {
  icon?: string;
  title: string;
  key: string;
  onClick?: ()=> void;
}
