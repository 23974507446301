import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FacadeFinishPartnerSessionRequestV2 from '../FacadeFinishPartnerSessionRequestV2';


export const ValidateSessionV2CommandPropDef = {
  request: {
    type: new EntityType(FacadeFinishPartnerSessionRequestV2),
  },
};
export default class GeneratedValidateSessionV2Command extends createEntity(ValidateSessionV2CommandPropDef) {}
