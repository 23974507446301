import {markRaw, toRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  DocumentDto,
  DocumentFinItemDto,
  DocumentPaymentDto,
} from '@/Model/Entity';
import {SignalRErrors} from '@/Helpers/signalR';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {useCashAmountValidatorStore} from '@/Modules/Core/store/CashAmountValidatorStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFilters} from '@/Helpers/filters';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import {workflowStepMixinSaveFinDocument} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinSaveFinDocument';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {workflowStepMixinPayment} from '../StepMixins/WorkflowStepMixinPayment';
import {
  flow,
  map,
} from 'lodash-es';

// eslint-disable-next-line max-len
export class WorkflowStepApiCreateTransaction extends flow(
  (ctor) => workflowStepMixinNominals(ctor),
  (ctor) => workflowStepMixinSaveFinDocument(ctor),
  (ctor) => workflowStepMixinPayment(ctor),
)(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiCreateTransaction;
  }

  get type() {
    return WorkflowStepApiCreateTransaction.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get allowNegativeAmount() {
    return this.step?.allowNegativeAmount ?? false;
  }

  get documentPayments() {
    /**
     * Payments from optional step
     */
    const documentPayments = this.getFieldValue(WorkflowStepField.payments, []);

    if (documentPayments.length) {
      return map(documentPayments, (documentPayment) => new DocumentPaymentDto(documentPayment));
    } else {
      const payment = this.configurationStore.createPayment(this.transaction.paymentId);
      payment.setValue(this.transactionAmount);

      return [payment];
    }
  }

  get transaction() {
    const transactionCode = this.step.transactionCode;

    return this.configurationStore.financialTransactionsByCode.value?.[transactionCode];
  }

  get transactionAmount() {
    return this.data[WorkflowStepField.amount] ? parseFloat(this.data[WorkflowStepField.amount]) : this.total;
  }

  async beforeEnter() {
    const {currencyFormat} = useFilters();
    const {configuration} = useConfigurationStore();
    const cashAmountValidatorStore = useCashAmountValidatorStore();

    if (
      this.allowNegativeAmount ? this.data[WorkflowStepField.amount] === 0 : this.data[WorkflowStepField.amount] <= 0
    ) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.stepInit) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    const document = DocumentDto.createFinancialDocument({
      header: {
        reference: this.getFieldValue(WorkflowStepField.paymentFinDocument, undefined),
        finDocumentCode: this.transaction.code,
        finDocumentName: this.transaction.name,
        finDocumentTransactionNumber: this.transaction.number,
        total: this.transactionAmount,
        currency: this.transaction.currency,
        source: this.transaction.transactionSource,
        destination: this.transaction.transactionDestination,
        sapTransactionCode: this.transaction.sapTransactionCode,
        note: this.data[WorkflowStepField.note],
      },
      items: this.availableNominalsAsItems,
    });

    for (let documentPayment of this.documentPayments) {
      documentPayment = documentPayment.clone();

      if (!documentPayment.type.hasConfirmationMethodTerminal) {
        document.addPayment(documentPayment, {refreshTotalPrice: false});
        continue;
      }

      const payment = this.paymentById(documentPayment.verifyDocumentId);

      if (!payment.isResolved) {
        continue;
      }

      const {
        card,
        verifyDocumentId,
      } = await payment.process(); // Returns result object immediately

      // NOTE: results are already attached to different fin document
      // document.processPaymentResults(results);

      if (verifyDocumentId) {
        documentPayment.verifyDocumentId = verifyDocumentId;
      }

      if (card) {
        documentPayment.cardNumber = card.cardNumber;
        documentPayment.cardType = card.cardType;
      }

      document.addPayment(documentPayment, {refreshTotalPrice: false});
    }


    const maxFinDocumentAmount = configuration.value?.features?.transactionLimits?.maxFinDocumentAmount;

    if (maxFinDocumentAmount && document.totalPrice > maxFinDocumentAmount) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.TRANSACTION_LIMIT_EXCEEDED,
          args: {
            limit: currencyFormat(maxFinDocumentAmount),
            value: currencyFormat(document.totalPrice),
          },
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
      return;
    }

    if (this.transactionAmount) {
      document.finItems = [
        new DocumentFinItemDto({
          valueBeforeDiscounts: this.transactionAmount,
          quantity: 1,
        }),
      ];
    }

    let finDocumentResult: Awaited<ReturnType<WorkflowStepApiCreateTransaction['saveFinDocument']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      finDocumentResult = await this.saveFinDocument(document);

      finDocumentResult.result = new PrinterResult(finDocumentResult.result ?? {});

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      if (!finDocumentResult?.result?.hasError) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));
      }
    } finally {
      await (async () => {
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));

        if (!finDocumentResult?.created) {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          return;
        }

        if (isActiveFeaturePrintDisplayOnScreen() && finDocumentResult?.result?.hasValidPrintContent) {
          await this.printContentStore.open(finDocumentResult.result.printContent);
        }

        this.stepInit = true;

        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));

        if (this.code !== WorkflowCodes.financialReport) {
          cashAmountValidatorStore.validate();
        }
      })();
    }
  }

  get transitions() {
    return {};
  }
}
