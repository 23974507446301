import {
  RegisterActions,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {QuestionFlow} from '@/Modules/Register/PromoInteractionFlow/QuestionFlow';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ANSWER_QUESTION]: {
      [RegisterActions.INIT]: action(async ({previousState, questionId}) => {
        const question = questionId ? this.pendingQuestions.value.find(({id}) => id === questionId) :
          this.pendingQuestions.value[0];
        if (question) {
          const nextStep = previousState;
          await this.setPromoFlow(await QuestionFlow.new(question.id, nextStep));
          await this.persist();
        } else {
          await this.changeState(previousState);
        }
      }),
    },
  };
}
