import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerCardPreviewDtoPropDef = {
  cardNumber: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerCardPreviewDto extends createEntity(CustomerCardPreviewDtoPropDef) {}
