import {Store} from '@designeo/vue-helpers';
import {BroadcastIO, broadcastIO} from './broadcastIO';
import {watch, Ref} from 'vue';
import {guid} from '@/Helpers/guid';


type SyncTargets = import('../Modules/CustomerExternal/syncTarget/PromotionSyncTarget').PromotionSyncTarget
  | import('../Modules/CustomerExternal/syncTarget/KorunkaSyncTarget').KorunkaSyncTarget
  | import('../Modules/CustomerExternal/syncTarget/CustomerRegistrationSyncTarget').CustomerRegistrationSyncTarget
  | import('../Modules/AnalogDisplay/syncTarget/AnalogDisplaySyncTarget').AnalogDisplaySyncTarget
// | MoreTargets;

/**
 * Acts like store, but expects that state is
 *  replicated from getters of other store
 */
export class SyncTarget<Name extends string, T extends object> extends Store<T> {
  protected readonly state: T;
  protected readonly transmitter: BroadcastIO;

  constructor(public name: Name, state: T, {transmitter = broadcastIO} = {}) {
    super(state);
    this.transmitter = transmitter;

    this.transmitter.addEventListener(
      `SyncTarget:${this.name}:Update`,
      // @ts-ignore
      ({detail: {key, value}}) => {
        this.state[key] = value;
      },
    );

    this.transmitter.postMessage(
      // @ts-ignore
      `SyncTarget:${this.name}:RequestUpdate`, {},
    );
  }
}


type JsonSerializablePrimitive = boolean | string | number | null | undefined;
type JsonSerializableObject = {[key: string]: JsonSerializable};
type JsonSerializableArray = JsonSerializable[];
type JsonSerializable = JsonSerializableArray | JsonSerializableObject | JsonSerializablePrimitive;

type Expand<T extends SyncTarget<any, any>> = T extends SyncTarget<infer N, infer S> ? {name: N, state: S}: never
type Visitor<T extends {name: string, state: any}> = {
  [n in T['name']]: Extract<T, { name: n }>['state']
}
type SyncTargetsByName = Visitor<Expand<SyncTargets>>

const activeSyncToTargetMap = new Map<string, string>();
const targetWeakMap = new WeakMap<Ref<any>, any>();
const syncToTargetCleanup = new FinalizationRegistry<()=> void>((fn) => fn());

export function syncToTarget<
  N extends Expand<SyncTargets>['name'],
  K extends keyof SyncTargetsByName[N],
  V extends SyncTargetsByName[N][K]
>(
  name: N,
  key: keyof SyncTargetsByName[N],
  ref: Ref<V>,
  {transmitter = broadcastIO} = {},
) {
  const id = guid();
  const activeSyncKey = `${name}:${key}`;

  activeSyncToTargetMap.set(activeSyncKey, id);

  const weakRef = new WeakRef(ref);

  targetWeakMap.set(ref, () => {
    if (id !== activeSyncToTargetMap.get(activeSyncKey)) {
      return;
    }

    try {
      const ref = weakRef.deref();
      if (ref) {
        // @ts-ignore
        transmitter.postMessage(`SyncTarget:${name}:Update`, {
          key,
          value: ref.value,
        });
      }
    } catch (err) {
      console.error(`Failed to sync syncToTarget(${name}, ${key})`, err);
    }
  });

  const weakSync = (...args) => {
    targetWeakMap.get(weakRef.deref())?.(...args);
  };

  transmitter.addEventListener(`SyncTarget:${name}:RequestUpdate`, weakSync);

  syncToTargetCleanup.register(ref, () => {
    transmitter.removeEventListener(`SyncTarget:${name}:RequestUpdate`, weakSync);
  });

  try {
    watch(ref, weakSync, {immediate: true, deep: true});
  } catch (err) {
    console.error(`Failed to setup syncToTarget(${name}, ${key})`, err);
  }
  return ref;
}

export function emitToTarget<
  N extends Expand<SyncTargets>['name'],
  K extends keyof SyncTargetsByName[N],
  V extends SyncTargetsByName[N][K]
>(
  name: N,
  key: keyof SyncTargetsByName[N],
  value: V,
  {transmitter = broadcastIO} = {},
) {
  // @ts-ignore
  transmitter.postMessage(`SyncTarget:${name}:Update`, {
    key,
    value,
  });
}
