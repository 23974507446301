
import {defineComponent, computed} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export default defineComponent({
  name: 'StepBTCCharge',
  components: {HLStep, WorkflowTransitions},
  setup() {
    const stepType = WorkflowStepTypes.BTCCharge;

    const configurationStore = useConfigurationStore();

    const orderState = computed(() => configurationStore.configuration.value.features.btc.orderStates);

    const localCurrency = computed(() => configurationStore.configuration.value.general.localCurrency);

    const {interpolate, currencyFormat} = useFilters();

    return {
      stepType,
      interpolate,
      currencyFormat,
      configurationStore,
      localCurrency,
      orderState,
    };
  },
});
