import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import KorunkaCheckRequestDto from '@/Model/Entity/KorunkaCheckRequestDto';
import {DocumentItemDto} from '@/Model/Entity';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {guid} from '@/Helpers/guid';
import {
  KorunkaGameEntities,
  serializeKorunkaEntity,
} from '@/Helpers/korunka';
import {StornoReasonCodes} from '@/constants/stornoReasonCodes';

export class WorkflowStepKorunkaCheck extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaCheck;
  }

  get type() {
    return WorkflowStepKorunkaCheck.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  processGame(entity: KorunkaGameEntities, product: DocumentItemDto) {
    product.priceNormal = entity.total;

    if (entity.isCancel) {
      // product.isReturn = true; // Check and charge not called when true
      product.isNegative = true;
      product.originalDocumentReference = entity.ticketNumber.toString();
      product.returnReasonCode = this.configurationStore
        .configuration
        .value
        .getStornoReasonByCode(StornoReasonCodes.complaint)?.reasonId;
    }
  }

  processScanResult(
    entity: import('@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom').default,
    product: DocumentItemDto,
  ) {
    product.priceNormal = entity.total;
  }

  processTicket(
    entity: import('@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom').default,
    product: DocumentItemDto,
  ) {
    product.priceNormal = entity.total;
    product.isNegative = entity.isWon || entity.betsDrawState.isWin;
  }

  async beforeContinue() {
    const product: DocumentItemDto = this.article.clone();
    const entity: import('@/Helpers/korunka').KorunkaEntities = this.entity.clone();

    const checkRequest: KorunkaCheckRequestDto = new KorunkaCheckRequestDto({
      requestId: guid(),
      metadata: serializeKorunkaEntity(entity),
    });

    product.checkRequest = checkRequest.toJson();

    if (this.isGameSestkaEntity(entity)) {
      this.processGame(entity, product);
    } else if (this.isGameDvacetZa20Entity(entity)) {
      this.processGame(entity, product);
    } else if (this.isGameHopTropEntity(entity)) {
      this.processGame(entity, product);
    } else if (this.isGameKombiEntity(entity)) {
      this.processGame(entity, product);
    } else if (this.isGameKombiPlusEntity(entity)) {
      this.processGame(entity, product);
    } else if (this.isTicketEntity(entity)) {
      this.processTicket(entity, product);
    } else if (this.isScanResultEntity(entity)) {
      this.processScanResult(entity, product);
    }

    const bucketKey = this.saveBucket(product.toJson());

    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.SET_REFERER_QUERY_ARGS, {
      detail: {
        externalConfiguration: bucketKey,
      },
    }));
  }

  get transitions() {
    return {};
  }
}

