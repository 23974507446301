import {JournalEventTypes} from '@/constants/journalEventTypes';
import AddJournalRecordDto from '@/Model/Entity/AddJournalRecordDto';
import JournalDto from '../Model/Entity/JournalDto';
import JournalEventType from '@/Model/Entity/JournalEventType';
import {AxiosInstance, AxiosRequestConfig} from 'axios';
import {
  DocumentCustomerDto,
  DocumentDto,
  DocumentPaymentDto,
} from '@/Model/Entity';
import {GroupBySets} from '@/Model/Entity/DocumentDto';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {AuthPerson} from '@/Modules/Auth/types';
import {formatPosition} from '@/Helpers/journalFormatters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {WorkflowCodes} from '@/Modules/Workflow/types';

const getTimestamp = () => new Date().toISOString();

type ArgsAsTuple<
  ARGS extends Array<string> = [],
  ARGS_OPTIONAL extends Array<string> = []
> = {[key in (ARGS[number])]: {toString(): string}} & {[key in (ARGS_OPTIONAL[number])]?: {toString(): string}}

export type JournalEventTemplateDataMap = {
  [JournalEventTypes.StartPosBe]: ArgsAsTuple<['posBeVersion'], ['timeStamp']>,
  [JournalEventTypes.StartPosFe]: ArgsAsTuple<['timeStampPrevious'], ['timeStamp']>,
  [JournalEventTypes.EndPosFe]: ArgsAsTuple<['timeStampPrevious'], ['timeStamp']>,
  [JournalEventTypes.MoPortalOpen]: ArgsAsTuple<[], ['timeStamp']>,
  [JournalEventTypes.OperatorSuccessfulLogin]: ArgsAsTuple<[
    'username',
    'firstName',
    'lastName',
    'loginType',
  ], [
    'timeStamp',
  ]>,
  [JournalEventTypes.OperatorSuccessfulLogout]: ArgsAsTuple<['timeout'], ['timeStamp']>,
  [JournalEventTypes.OperatorLoginFailed]: ArgsAsTuple<[
    'username',
    'firstName',
    'lastName',
    'loginType',
  ], [
    'timeStamp',
  ]>,
  [JournalEventTypes.SellDocumentCreate]: ArgsAsTuple<[], [
    'shopId',
    'posId',
    'operatorUsername',
    'sellerUsername',
    'timeStamp',
    'refShopId',
    'refPosId',
    'refOperatorUsername',
    'refTimeStamp',
  ]>,
  [JournalEventTypes.SellDocumentCancel]: ArgsAsTuple<['transactionId', 'documentType']>,
  [JournalEventTypes.DocumentItemCancel]: ArgsAsTuple<['position']>,
  [JournalEventTypes.CustomerAdd]: ArgsAsTuple<[
    'action',
    'inputType',
    'customerNumber',
    'cardNumber',
  ]>,
  [JournalEventTypes.SelectPaymentEnter]: ArgsAsTuple<['currency', 'value']>,
  [JournalEventTypes.SelectPaymentLeaveToEnterArticle]: ArgsAsTuple,
  [JournalEventTypes.PaymentInLocalCurrency]: ArgsAsTuple,
  [JournalEventTypes.PaymentInForeignCurrency]: ArgsAsTuple<[
    'paymentType',
    'currency',
    'value',
    'exchangeRate',
  ], [
    'paymentDetail'
  ]>,
  [JournalEventTypes.FourEyesAuthorization]: ArgsAsTuple<['username', 'authorizationType']>,
  [JournalEventTypes.DocumentItemChange]: ArgsAsTuple<[
    'position',
    'action',
    'inputType',
    'netto',
    'vatIndex',
    'setPointer',
    'quantity',
    'price',
    'internalNumber',
  ], [
    'additionalInfo'
  ]>,
  [JournalEventTypes.DocumentItemPriceChange]: ArgsAsTuple<['position', 'price', 'newPrice']>,
  [JournalEventTypes.CashManipulation]: ArgsAsTuple<[
    'source',
    'target',
    'paymentType',
    'currency',
    'value',
  ], [
    'operatorUsername',
    'sellerUsername',
    'timeStamp',
  ]>,
  [JournalEventTypes.Promotion]: ArgsAsTuple<['position', 'description', 'price']>,
  [JournalEventTypes.WorkflowFinished]: ArgsAsTuple<['workflowCode', 'workflowTitle']>
  [JournalEventTypes.CardPaymentStart]: ArgsAsTuple
  [JournalEventTypes.PrintStart]: ArgsAsTuple
  [JournalEventTypes.ErrorDocumentCreate]: ArgsAsTuple
  [JournalEventTypes.LocalStorageClear]: ArgsAsTuple<['storage']>
}

const axiosLikeBeacon = (config: AxiosRequestConfig) => {
  const headers = {
    type: 'application/json',
  };
  navigator.sendBeacon(config.url, new Blob([JSON.stringify(config.data)], headers));

  return {data: '', status: 200};
};

export const journalOptions = {
  silenced: false,
};

export const submitJournalEvent = async <TYPE extends JournalEventTypes>(
  type: TYPE,
  {
    data,
    beacon = false,
    skipLines = null,
    clearJournal = false,
    includeCurrentReceipt = false,
  }: {
    data?: TYPE extends keyof JournalEventTemplateDataMap
      ? JournalEventTemplateDataMap[TYPE]
      : {},
    beacon?: boolean,
    skipLines?: Array<number>
    clearJournal?: boolean
    includeCurrentReceipt?: boolean
  } = {},
) => {
  if (journalOptions.silenced) return;

  const authStore = (await import('@/Modules/Auth/store/AuthStore'))
    .useAuthStore();
  const configurationStore = (await import('@/Modules/Core/store/ConfigurationStore'))
    .useConfigurationStore();
  const registerStore = (await import('@/Modules/Register/store/RegisterStore'))
    .useRegisterStore();

  try {
    await (await import('@/Model/Action')).apiJournalV2Add({
      input: new AddJournalRecordDto({
        journal: new JournalDto({
          timeStamp: new Date().toISOString(),
          ...(authStore.activePerson.value ? {
            cashierId: authStore.activePerson.value?.username,
          } : {
            cashierId: null,
          }),
          journalEventType: new JournalEventType(type).toJson(),
          jsonData: {
            shopId: configurationStore.configuration.value?.general.pos?.shop?.id,
            shopCode: configurationStore.configuration.value?.general.pos?.shop?.code,
            posId: configurationStore.configuration.value?.general.pos?.id,
            posCode: configurationStore.configuration.value?.general.pos?.code,
            operatorUsername: authStore.activePerson.value?.username,
            sellerUsername: authStore.activePerson.value?.username,
            timeStamp: getTimestamp(),
            ...(includeCurrentReceipt ? {
              transactionId: registerStore.sellDocument.value?.header?.uniqueidentifier,
              documentType: registerStore.sellDocument.value?.documentType.value,
            } : {}),
            ...(data ?? {}),
          },
          clearJournal,
        }).toJson(),
      }),
      ...(beacon ? {
        axios: axiosLikeBeacon as unknown as AxiosInstance,
      } : {}),
    });
  } catch (e) {
    console.error(e);
  }
};

export const submitJournalEventMoPortalOpen = () => {
  submitJournalEvent(JournalEventTypes.MoPortalOpen);
};

export const submitJournalEventPayment = async (payment: DocumentPaymentDto) => {
  const configurationStore = (await import('@/Modules/Core/store/ConfigurationStore'))
    .useConfigurationStore();

  const journalEventType = payment.isInLocalCurrency ?
    JournalEventTypes.PaymentInLocalCurrency :
    JournalEventTypes.PaymentInForeignCurrency;

  const params = payment.isInLocalCurrency ? {
    value: payment.value,
  } : {
    value: payment.valueinFCurrency,
    valueInLocalCurrency: payment.value,
    localCurrency: configurationStore.localCurrency.value.symbol,
    exchangeRate: payment.exchangeRate,
  };

  await submitJournalEvent(journalEventType, {
    data: {
      paymentType: payment?.type?.description,
      currency: payment.currency,
      ...params,
    },
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventDocumentItemCancel = (position: number) => {
  submitJournalEvent(JournalEventTypes.DocumentItemCancel, {
    data: {
      position,
    },
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventCustomerAdd = async (customer: DocumentCustomerDto, {
  action,
  productFlow,
}) => {
  submitJournalEvent(JournalEventTypes.CustomerAdd, {
    data: {
      customerNumber: customer.customerNumber ?? 'N/a',
      cardNumber: customer.cardNumber ?? 'N/a',
      action: action === null ? 'Modify' : action ? 'ADD' : 'Remove',
      inputType: productFlow.inputMode === InputSource.SCANNER ? 'B' : 'M',
    },
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventDocumentItemChange = async (group: GroupBySets, {
  productFlow,
}) => {
  const item = group.editableItem.clone();

  submitJournalEvent(JournalEventTypes.DocumentItemChange, {
    data: {
      position: formatPosition(group.index),
      action: productFlow.isNew ? 'A' : 'M',
      inputType: productFlow.inputMode === InputSource.SCANNER ? 'B' : 'M',
      netto: item.netto ? 'N' : ' ',
      vatIndex: item.vatid ? item.vatid : ' ',
      setPointer: item.isSet ? 'S' : item.isSetComponent ? 'I' : ' ',
      internalNumber: item.internalNumber,
      quantity: item.quantity,
      price: item.valueAfterDiscounts,
      additionalInfo: [
        ...(item.hasMandatoryFieldSerialNo ? [item.serialNo] : []),
        ...(item.hasMandatoryFieldPhoneNumber ? [item.phoneNumber] : []),
      ].join('; '),
    },
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventDocumentItemPriceChange = (group: GroupBySets, newPrice) => {
  submitJournalEvent(JournalEventTypes.DocumentItemPriceChange, {
    data: {
      position: formatPosition(group.index),
      price: group.editableItem.priceNormal,
      newPrice: newPrice,
    },
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventStartPosFe = (previousTimeStamp) => {
  submitJournalEvent(JournalEventTypes.StartPosFe, {
    data: {
      timeStampPrevious: previousTimeStamp,
    },
  });
};

export const submitJournalEventEndPosFe = (previousTimeStamp) => {
  submitJournalEvent(JournalEventTypes.EndPosFe, {
    data: {
      timeStampPrevious: previousTimeStamp,
    },
    beacon: true,
  });
};

export const submitJournalEventOperatorSuccessfulLogin = (authPerson: AuthPerson, loginType) => {
  submitJournalEvent(JournalEventTypes.OperatorSuccessfulLogin, {
    data: {
      username: authPerson.username,
      firstName: authPerson.tokenInfo?.first_name ?? 'N/a',
      lastName: authPerson.tokenInfo?.last_name ?? 'N/a',
      loginType,
    },
  });
};

export const submitJournalEventOperatorLoginFailed = (authInput, loginType) => {
  submitJournalEvent(JournalEventTypes.OperatorLoginFailed, {
    data: {
      username: authInput.username,
      firstName: 'N/a',
      lastName: 'N/a',
      loginType,
    },
  });
};

export const submitJournalEventOperatorSuccessfulLogout = async (byTimeout) => {
  const configurationStore = (await import('@/Modules/Core/store/ConfigurationStore'))
    .useConfigurationStore();

  await submitJournalEvent(JournalEventTypes.OperatorSuccessfulLogout, {
    data: {
      ...(byTimeout ? {
        timeout: configurationStore.configuration.value.features.login.automaticLogoutTimeout,
      } : {
        timeout: '',
      }),
    },
  });
};

export const submitJournalEventFourEyesAuthorization = (username, authorizationType) => {
  submitJournalEvent(JournalEventTypes.FourEyesAuthorization, {
    data: {
      username,
      authorizationType,
    },
  });
};

export const submitJournalEventSellDocumentCreate = (doc: DocumentDto) => {
  submitJournalEvent(JournalEventTypes.SellDocumentCreate, {
    includeCurrentReceipt: true,
    skipLines: [2],
  });
};

export const submitJournalEventSellDocumentCancel = (doc: DocumentDto) => {
  submitJournalEvent(JournalEventTypes.SellDocumentCancel, {
    data: {
      transactionId: doc?.header?.uniqueidentifier,
      documentType: doc?.documentType.value,
    },
  });
};

export const submitJournalEventSelectPaymentLeaveToEnterArticle = () => {
  submitJournalEvent(JournalEventTypes.SelectPaymentLeaveToEnterArticle, {
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventSelectPaymentEnter = (value, payment: DocumentPaymentDto) => {
  submitJournalEvent(JournalEventTypes.SelectPaymentEnter, {
    data: {
      value: value,
      currency: payment.currency,
    },
    includeCurrentReceipt: true,
  });
};

export const submitJournalEventStornoDocumentCreate = (doc: DocumentDto) => {
  submitJournalEvent(JournalEventTypes.StornoDocumentCreate, {
    data: {
      refShopId: doc?.header?.shopId,
      refShopCode: doc?.header?.shopCode,
      refPosId: doc?.header?.posId,
      refPosCode: doc?.header?.posCode,
      refOperatorUsername: doc?.header?.cashierPersonalNumber,
      refTimeStamp: doc?.header?.documentDate,
    },
  });
};

const getWorkflowConfigurationByCode = (code: string) => useConfigurationStore().configuration.value.workflow[code];

export const submitJournalEventWorkflowStarted = (workflowCode: string) => {
  submitJournalEvent(JournalEventTypes.WorkflowStarted, {
    data: {
      workflowCode: workflowCode,
      workflowTitle: getWorkflowConfigurationByCode(workflowCode)?.title,
    },
  });
};

export const submitJournalEventWorkflowFinished = (workflowCode: string) => {
  /**
   * This condition is here, because otherwise it will record finished financialReport to new file
   */
  if (workflowCode === WorkflowCodes.financialReport) return;

  submitJournalEvent(JournalEventTypes.WorkflowFinished, {
    data: {
      workflowCode,
      workflowTitle: getWorkflowConfigurationByCode(workflowCode)?.title,
    },
  });
};

export const submitJournalEventWorkflowCancelled = (workflowCode: string) => {
  submitJournalEvent(JournalEventTypes.WorkflowCancelled, {
    data: {
      workflowCode,
      workflowTitle: getWorkflowConfigurationByCode(workflowCode)?.title,
    },
  });
};

export const submitJournalEventFinancialCloseDay = () => {
  submitJournalEvent(JournalEventTypes.FinancialCloseDay, {
    clearJournal: true,
  });
};

export const submitJournalEventPosStateReport = () => {
  submitJournalEvent(JournalEventTypes.PosStateReport);
};

export const submitJournalEventTrainingModeStarted = () => {
  submitJournalEvent(JournalEventTypes.TrainingModeStarted);
};

export const submitJournalEventTrainingModeEnded = () => {
  submitJournalEvent(JournalEventTypes.TrainingModeEnded);
};
export const submitJournalEventCardPaymentStart = (doc: DocumentDto) => {
  submitJournalEvent(JournalEventTypes.CardPaymentStart, {
    includeCurrentReceipt: true,
  });
};
export const submitJournalEventPrintStart = () => {
  submitJournalEvent(JournalEventTypes.PrintStart, {
    includeCurrentReceipt: true,
  });
};
export const submitJournalEventErrorDocumentCreate = () => {
  submitJournalEvent(JournalEventTypes.ErrorDocumentCreate, {
    includeCurrentReceipt: true,
  });
};
export const submitJournalEventLocalStorageClear = ({storage}) => {
  submitJournalEvent(JournalEventTypes.LocalStorageClear, {
    data: {
      storage,
    },
  });
};
