import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';
import {QuickCallStorno} from '../../QuickCall/Classes/QuickCall';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.RETURN_REASON_CODE]: {
      ...getCommonTransitions.call(this, [RegisterActions.CANCEL]),
      [RegisterActions.INIT]: action(async () => {
        this.quickCallActiveMap.value.clear();

        if (this.state.productFlow.product.returnReasonCode) {
          this.quickCallActiveMap.value.set(this.state.productFlow.product.returnReasonCode, {
            code: this.state.productFlow.product.returnReasonCode,
          });
        }

        await this.persist();
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.productFlow.product.returnReasonCode) return;

        await this.transitionToNextStep();
      }),
    },
  };
}
