import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {
  WarehouseOperationStepFieldGroups,
  WarehouseOperationAdditionalInfoFields,
} from '@/constants/warehouseOperations';
import {
  filter,
  flow,
  has,
  isNil,
  map,
  reduce,
} from 'lodash-es';
import {action} from '@designeo/vue-helpers';

export class WorkflowStepWarehouseOperationAdditionalInfo extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationAdditionalInfo;
  }

  get type() {
    return WorkflowStepWarehouseOperationAdditionalInfo.type;
  }

  get component() {
    return markRaw(require('./StepWarehouseOperationAdditionalInfo.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get fields() {
    return filter([
      WarehouseOperationAdditionalInfoFields.note,
      WarehouseOperationAdditionalInfoFields.reference,
      WarehouseOperationAdditionalInfoFields.packages,
      WarehouseOperationAdditionalInfoFields.returnReason,
    ], (field) => {
      const configSettings = this.step?.fields?.[field];

      return !!configSettings;
    });
  }

  get title() {
    return this.titleByWarehouseOperation;
  }

  createFieldDataPath(field: string) {
    return `${WarehouseOperationStepFieldGroups.additionalInfo}.${field}`;
  }

  getFieldDefaultValue(field: WarehouseOperationAdditionalInfoFields) {
    switch (field) {
    default:
      return this.step.fields?.[field]?.defaultValue ?? undefined;
    }
  }

  async beforeEnter() {
    if (this.stepInit) {
      return;
    }

    for (const field of this.fields) {
      this.dataSetter(this.createFieldDataPath(field), (currentValue) => {
        if (currentValue) {
          return currentValue;
        }

        return this.getFieldDefaultValue(field);
      });
    }

    this.stepInit = true;
  }

  async beforeContinue() {
    if (this.stepFinished) {
      return;
    }

    const returnReasonEnum = this.step?.fields?.[WarehouseOperationAdditionalInfoFields.returnReason]?.options;

    if (returnReasonEnum) {
      this.saveEnumToCustomData(
        WarehouseOperationAdditionalInfoFields.returnReason,
        this.configOptionsToSelectOptions(returnReasonEnum),
      );
    }

    this.stepFinished = true;
  }

  get transitions() {
    return {
      ...reduce(this.fields, (acc, field, index, arr) => {
        return {
          ...acc,
          ...this.withFieldActions(this.createFieldDataPath(field), (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
            }),
            [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
              if (!isNil(event.value)) {
                fieldActions[WorkflowActions.ENTER](event.value);
                return;
              }

              if (arr.indexOf(field) === arr.length - 1) {
                this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
                return;
              }
            }),
            ...this.createArrowMovementTransitions(map(arr, this.createFieldDataPath), index),
          })),
        };
      }, {}),
    };
  }
}
