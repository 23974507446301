
import {computed, defineComponent} from 'vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';

export default defineComponent({
  name: 'ContentWorkflow',

  setup() {
    const workflowStore = useWorkflowStore();

    const workflowTitle = computed(() => {
      return workflowStore.currentWorkflow.value.workflowTitle;
    });

    return {
      workflowTitle,
    };
  },
});
