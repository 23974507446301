import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.amount
          }, {
            default: _withCtx(({isActive, value, setActive, error}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.amount,
                error: error,
                active: isActive,
                errorOutput: 'modal',
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputBuffer, {
                    value: value.value,
                    disableCaretNavigator: ""
                  }, null, 8, ["value"])
                ]),
                _: 2
              }, 1032, ["label", "error", "active", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"])
        ]),
        _createVNode(_component_WorkflowTransitions, { validate: true })
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}