import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col gap-2 mt-2 bg-white rounded-md p-4" }
const _hoisted_3 = { class: "font-bold text-xl" }
const _hoisted_4 = { class: "text-danger font-bold mt-4 text-center" }
const _hoisted_5 = { class: "flex flex-col items-center justify-center gap-4 p-4" }
const _hoisted_6 = { class: "font-bold m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalNumbers = _resolveComponent("ModalNumbers")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.drawsDates) + ": " + _toDisplayString(_ctx.drawsDates), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.drawsDates,
          void 0,
          { drawsDates: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.drawsCount) + ": " + _toDisplayString(_ctx.entity?.drawsCount), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.drawsCount ??0,
          void 0,
          { drawsCount: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.maxPrizePerTicket) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.maxPrizePerTicket ?? 0 )), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.maxPrizePerTicket ??0,
          void 0,
          { maxPrizePerTicket: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.total) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.total ?? 0)), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.total ?? 0,
          void 0,
          { total: true }
        ]
      ])
    ]),
    _createElementVNode("button", {
      class: "btn btn-basic btn-outline-basic mt-4 mx-auto w-4/12",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNumbersModal && _ctx.openNumbersModal(...args)))
    }, _toDisplayString(_ctx.workflowStep.messages.showNumbers), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.workflowStep.messages.waitingForCustomerConfirm), 1),
    _createVNode(_component_ModalNumbers, {
      title: _ctx.workflowStep.messages.modalNumbersTitle,
      isOpen: _ctx.workflowStep.numbersModalIsOpen,
      onClose: _ctx.closeNumbersModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity?.randomNumbers, (randomNumbersRow, rowIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: rowIndex,
              class: "grid gap-4",
              style: {"grid-template-columns":"1rem repeat(6, minmax(0, 1fr))"}
            }, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(rowIndex + 1) + ". ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(randomNumbersRow, (number, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "korunka-circle-number"
                }, _toDisplayString(number), 1))
              }), 128))
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["title", "isOpen", "onClose"])
  ]))
}