import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const SendPinDtoPropDef = {
  smsTemplate: {
    type: new StringType(),
  },
};
export default class GeneratedSendPinDto extends createEntity(SendPinDtoPropDef) {}
