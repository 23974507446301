import {onBeforeMount, onUnmounted} from 'vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {AppVersion} from '@/constants/appVersion';

export const useAppVersion = (appVersion: AppVersion) => {
  const coreStore = useCoreStore();
  let currentAppVersion = null;

  onBeforeMount(() => {
    currentAppVersion = coreStore.appVersion.value ?? null;
    coreStore.setAppVersion(appVersion);
  });

  onUnmounted(() => {
    coreStore.setAppVersion(currentAppVersion);
  });
};
