import {TranslatedString} from '@/Model/Entity';
import {
  createEntity,
  StringType,
  EntityType,
  NumberType,
  ObjectType,
} from '@designeo/apibundle-js';

export default class PromoPointsBurningButton extends createEntity({
  type: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  icon: {
    type: new StringType(),
  },
  label: {
    type: new EntityType(TranslatedString),
  },
  value: {
    type: new NumberType(),
  },
  style: {
    type: new ObjectType(),
  },
}) {}
