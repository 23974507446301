import {RegisterRoutesOptions} from '@/ModuleSystem';

export default [
  {
    path: '/analog-display',
    name: 'analog-display',
    component: () => import('./AnalogDisplay/AnalogDisplayPage.vue'),
    meta: {authorization: false},
  },
] as RegisterRoutesOptions;
