import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const OrderItemDtoPropDef = {
  id: {
    type: new StringType(),
  },
  title: {
    type: new StringType(),
  },
  gtin: {
    type: new StringType(),
  },
  internalNumber: {
    type: new StringType(),
  },
  batch: {
    type: new StringType(),
  },
  unitPrice: {
    type: new NumberType(),
  },
  unitPriceWithoutVat: {
    type: new NumberType(),
  },
  price: {
    type: new NumberType(),
  },
  priceWithoutVat: {
    type: new NumberType(),
  },
  vatRate: {
    type: new NumberType(),
  },
  unit: {
    type: new StringType(),
  },
  quantity: {
    type: new NumberType(),
  },
};
export default class GeneratedOrderItemDto extends createEntity(OrderItemDtoPropDef) {}
