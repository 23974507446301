import {DocumentItemDto} from '../../Model/Entity';

export enum DisplayState {
  ENTER_CUSTOMER_CARD,
  ENTER_PRODUCT,
  ENTER_PRODUCT_QUANTITY,
  PRODUCT_OVERVIEW,
  ENTER_PRODUCT_PRICE,
  ENTER_PRODUCT_SERIAL_NUMBER,
  ENTER_PRODUCT_INVOICE_NUMBER,
  ENTER_PRODUCT_PHONE_NUMBER,
  PAYMENT,
  CANCEL,
  ENTER_STORNO_REFERENCE,
  ENTER_STORNO_REASON,
  VALIDATE_AGE_RESTRICTION,
  ENTER_LOGISTIC_CODE,
  ENTER_VALIDATION_CODE,
  PRODUCT_PHONE_NUMBER_CONFIRM,
  VOID,
  DISPLAY_READ_ONLY_PRODUCT,
  ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE,
}

export interface DisplayEnterCustomerCard {
  type: DisplayState.ENTER_CUSTOMER_CARD,
  returnMode: boolean,
  inputValue: string,
}

export interface DisplayEnterProduct {
  type: DisplayState.ENTER_PRODUCT,
  returnMode: boolean,
  inputValue: string,
  error?: RegisterStoreErrors
  quantityPreset: string
  hasCustomer: boolean,
}

export interface DisplayEnterProductQuantity {
  type: DisplayState.ENTER_PRODUCT_QUANTITY,
  insertMode: boolean,
  inputValue: string,
  product: DocumentItemDto,
}

export interface DisplayProductOverview {
  type: DisplayState.PRODUCT_OVERVIEW,
  product: DocumentItemDto,
}

export interface DisplayEnterProductPrice {
  type: DisplayState.ENTER_PRODUCT_PRICE,
  insertMode: boolean,
  inputValue: string,
  product: DocumentItemDto,
}

export interface DisplayEnterProductSerialNumber {
  type: DisplayState.ENTER_PRODUCT_SERIAL_NUMBER,
  insertMode: boolean,
  inputValue: string,
  product: DocumentItemDto,
}

export interface DisplayEnterProductInvoiceNumber {
  type: DisplayState.ENTER_PRODUCT_INVOICE_NUMBER,
  insertMode: boolean,
  inputValue: string,
  product: DocumentItemDto,
}

export interface DisplayEnterProductPhoneNumber {
  type: DisplayState.ENTER_PRODUCT_PHONE_NUMBER,
  insertMode: boolean,
  inputValue: string,
  error?: RegisterStoreErrors,
  product: DocumentItemDto,
}

export interface DisplayEnterStornoReference {
  type: DisplayState.ENTER_STORNO_REFERENCE,
  insertMode: boolean,
  inputValue: string,
}

export interface DisplayEnterOriginalDocumentFiscalReference {
  type: DisplayState.ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE,
  insertMode: boolean,
  inputValue: string,
}

export interface DisplayEnterStornoReason {
  type: DisplayState.ENTER_STORNO_REASON,
  product: DocumentItemDto,
}

export interface DisplayPayment {
  type: DisplayState.PAYMENT,
  insertMode: boolean,
  inputValue: string,
}

export interface DisplayCancel {
  type: DisplayState.CANCEL,
  inputValue: string,
}

export interface DisplayAgeValidation {
  type: DisplayState.VALIDATE_AGE_RESTRICTION,
  product: DocumentItemDto,
}

export interface DisplayEnterLogisticCode {
  type: DisplayState.ENTER_LOGISTIC_CODE,
  insertMode: boolean,
  inputValue: string,
  product: DocumentItemDto,
}

export interface DisplayEnterValidationCode {
  type: DisplayState.ENTER_VALIDATION_CODE,
  insertMode: boolean,
  inputValue: string,
  product: DocumentItemDto,
}

export interface DisplayProductPhoneNumberConfirm {
  type: DisplayState.PRODUCT_PHONE_NUMBER_CONFIRM,
  product: DocumentItemDto,
}

export interface DisplayVoid {
  type: DisplayState.VOID,
}

export interface DisplayReadonlyProduct {
  type: DisplayState.DISPLAY_READ_ONLY_PRODUCT,
  product: DocumentItemDto,
}

export type DisplayVariants = DisplayPayment
  | DisplayEnterProductSerialNumber
  | DisplayEnterProductInvoiceNumber
  | DisplayEnterProductPrice
  | DisplayEnterProductQuantity
  | DisplayEnterProduct
  | DisplayEnterCustomerCard
  | DisplayEnterProductPhoneNumber
  | DisplayCancel
  | DisplayEnterStornoReference
  | DisplayEnterStornoReason
  | DisplayAgeValidation
  | DisplayEnterLogisticCode
  | DisplayEnterValidationCode
  | DisplayProductPhoneNumberConfirm
  | DisplayVoid
  | DisplayReadonlyProduct
  | DisplayEnterOriginalDocumentFiscalReference;

export enum RegisterState {
  ENTER_CUSTOMER_CARD='enterCustomerCard',
  FILL_IN_CUSTOMER_CARD='fillInCustomerCard',
  FILL_IN_CUSTOMER_CARD_SEARCH='fillInCustomerCardSearch',
  ENTER_ARTICLE_NUMBER='enterArticleNumber',
  ENTER_ARTICLE_QUANTITY='enterArticleQuantity',
  ENTER_ARTICLE_PRICE='enterArticlePrice',
  ENTER_SERIAL_NUMBER='enterSerialNumber',
  ENTER_ORIGINAL_DOCUMENT_FISCAL_REFERENCE='enterOriginalDocumentFiscalReference',
  SELECT_PRODUCT_VARIATION='selectProductVariation',
  SELECT_PAYMENT='selectPayment',
  PAYMENTS_EXIT='paymentsExit',
  SELECT_GIFT='selectGift',
  DYNAMIC_SET_SELECTION='dynamicSetSelection',
  DYNAMIC_SET_LEVELS_SELECTION='dynamicSetLevelsSelection',
  CANCEL_MODE='cancelMode',
  PRODUCT_SEARCH='productSearch',
  CUSTOMER_SEARCH='customerSearch',
  ENTER_PHONE_NUMBER='enterPhoneNumber',
  PHONE_NUMBER_CONFIRM='phoneNumberConfirm',
  ORIGINAL_DOCUMENT_REFERENCE='originalDocumentReference',
  RETURN_REASON_CODE='returnReasonCode',
  VALIDATE_AGE_RESTRICTION='validateAgeRestriction',
  SELECT_CHECK_AND_CHARGE_LOT_VARIATION='selectCheckAndChargeLotVariation',
  ENTER_LOGISTIC_CODE='enterLogisticCode',
  ENTER_VALIDATION_CODE='enterValidationCode',
  BURN_POINTS='burnPoints',
  ANSWER_QUESTION='answerQuestion',
  GECO_GAME='gecoGame',
  ENTER_CUSTOM_DATA='enterCustomData',
  ENTER_CUSTOM_DATA_QUESTION='enterCustomDataQuestion',
  UNASSIGNED_CARD='unassignedCard',
  CONNECT_CARD_TO_CUSTOMER='connectCardToCustomer',
  READONLY_ARTICLE_SELECTED='readOnlyArticleSelected',
  ENTER_INVOICE_NUMBER='enterInvoiceNumber',
  ENTER_DELAYED_SELL_DATA='delayedSellData',
  STOCK_IN_STORES='stockInStores',
  CONTEXTUAL_PRODUCT_RECOMMENDATION_SELECTION='contextualProductRecommendationSelection',
}

export enum RegisterActions {
  INIT='init',
  BEFORE_ENTER='beforeEnter',
  BEFORE_LEAVE='beforeLeave',
  ADD_NUMBER='addNumber',
  ADD_PERIOD='addPeriod',
  ADD_COMMA='addComma',
  ADD_PLUS='addPlus',
  ADD_MINUS='addMinus',
  ADD_CHAR='addChar',
  ENTER='enter',
  BACKSPACE='backspace',
  CLEAR='clear',
  CANCEL='cancel',
  NEXT='next',
  PREV='prev',
  SCANNER='scanner',
  SKIP='skip',
  QUICK_CALL='quickCall',
  POINTS_BURNING='pointsBurning',
  VERIFY_CUSTOMER_BY_PIN='verifyCustomerByPin',
  VERIFY_CUSTOMER_PIN='verifyCustomerPin',
  VERIFY_CUSTOMER_CARD='verifyCustomerCard',
  CUSTOMER_VERIFIED='customerVerified',
}

export type RegisterInputEvent<V = any> = {
  type: RegisterActions,
  value?: V
}

export enum RegisterStoreEvent {
  CREATE_NEW_CUSTOMER_FOR_UNASSIGNED_CARD = 'createNewCustomerForUnassignedCard',
  CUSTOMER_ADD_CARD = 'customerAddCard',
  CUSTOMER_CHANGED = 'customerChanged',
  FOLLOW_UP_DOCUMENT_INJECTED = 'followUpDocumentInjected',
  CUSTOMER_VERIFICATION_FAILED = 'customerVerificationFailed',
  CUSTOMER_VERIFICATION_STARTED = 'customerVerificationStarted',
  CUSTOMER_VERIFICATION_SUCCEEDED = 'customerVerificationSucceeded',
  INIT_CUSTOMER_DISPLAY = 'initCustomerDisplay',
  ITEMS_CHANGE = 'itemsChanged',
  LEAVE = 'leave',
  PAYMENTS_CHANGED = 'paymentsChanged',
  REDIRECT_TO_RECEIPT = 'redirectToReceipt',
  REQUEST_CUSTOMER_CARD = 'requestCustomerCard',
  REQUEST_CUSTOMER_PHONE_NUMBER = 'requestCustomerPhoneNumber',
  REQUEST_PAYMENT_TERMINAL_PRINTOUT = 'requestPaymentTerminalPrintout',
  SEARCH_CUSTOMER = 'searchCustomer',
  SEARCH_PRODUCT = 'searchProduct',
  SEARCH_STOCK_IN_STORE = 'searchStockInStore',
  SELL_DOCUMENT_CREATED = 'sellDocumentCreated',
  UNVERIFIED_CUSTOMER_ADDED = 'unverifiedCustomerAdded',
  CONFIRM_CUSTOMER_REPLACEMENT = 'confirmCustomerReplacement',
  OPEN_WORKFLOW_FROM_QUICK_CALL = 'openWorkflowFromQuickCall',
  START_EXTERNAL_CONFIGURATION = 'startExternalConfiguration'
}

export enum RegisterStoreErrors {
  API_ERROR = 'apiError',
  ARTICLE_IN_TRAINING_MODE = 'articleInTrainingMode',
  CHECK_AND_CHARGE_FAILED = 'changeAndChargeFailed',
  CUSTOMER_ERROR = 'customerError',
  DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_LOT = 'duplicityErrorCheckAndChargeTypeLot',
  DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_VOUCHER_DISCOUNT = 'duplicityErrorCheckAndChargeTypeVoucherDiscount',
  DYNAMIC_SET_SELECTION_ABORTED = 'dynamicSetSelectionAborted',
  DYNAMIC_SET_SELECTION_ARTICLE_NOT_FOUND = 'dynamicSetSelectionArticleNotFound',
  DYNAMIC_SET_SELECTION_ARTICLE_QUANTITY_CAN_NOT_BE_CHANGED = 'dynamicSetSelectionArticleQuantityCanNotBeChanged',
  EDIT_DISABLED = 'editDisabled',
  FOUR_EYES = 'fourEyes',
  INVALID_UI_OPERATION = 'invalidUiOperation',
  INVALID_LOGISTIC_CODE = 'invalidLogisticCode',
  INVALID_NOMINAL = 'invalidNominal',
  INVALID_PHONE_NUMBER = 'invalidPhoneNumber',
  INVALID_ROUNDING_ARTICLE = 'invalidRoundingArticle',
  INVALID_PAYMENT_VALUE = 'invalidPaymentValue',
  MAX_PRICE_EXCEEDED = 'maxPriceExceeded',
  MAX_QUANTITY_EXCEEDED = 'maxQuantityExceeded',
  MIN_PRICE_EXCEEDED = 'minPriceExceeded',
  NON_RETURNABLE = 'nonReturnable',
  NO_CUSTOMERS_FOUND = 'noCustomersFound',
  NO_PRODUCTS_FOUND = 'noProductsFound',
  NO_RECEIPTS_FOUND = 'noReceiptsFound',
  OFFLINE = 'offline',
  PAYMENT_ERROR = 'paymentError',
  PAYMENT_OVERPAY = 'paymentOverpay',
  PAYMENT_OVERPAY_MAX = 'paymentOverpayMax',
  PAYMENT_OVERPAY_MIN = 'paymentOverpayMin',
  PAYMENT_UNDERPAY = 'paymentUnderpay',
  PIN_VERIFICATION_FAILED = 'pinVerificationFailed',
  PRICE_CHANGE_NOT_ALLOWED = 'priceChangeNotAllowed',
  TRANSACTION_LIMIT_EXCEEDED = 'transactionLimitExceeded',
  ZERO_PRICE = 'zeroPrice',
  FROZEN_RECEIPT = 'frozenReceipt',

  INVALID_INVOICE_NUMBER = 'invalidInvoiceNumber',
}

export enum CustomerAuthenticationState {
  VERIFICATION_CHOICE = 'verificationChoice',
  VERIFY_BY_PIN = 'verifyByPin',
  VERIFY_BY_CARD = 'verifyByCard',
}

export enum ProductFlowEvent {
  STARTED='started',
  FINISHED='finised',
  canceled='canceled',
}

export enum ContextualProductRecommendationPriceType {
  notRegistered = 'NotRegistered',
  registered = 'Registered',
  all = 'All',
}
