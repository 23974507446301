import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "rounded-2xl bg-basic flex flex-row justify-between relative overflow-hidden" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCheck = _resolveComponent("IconCheck")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "bg-success absolute h-full left-4 top-0",
      style: _normalizeStyle(_ctx.successBarStyles)
    }, null, 4),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.points, (point, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["h-8 w-8 rounded-full flex items-center justify-center text-basic-lightest font-bold text-lg relative", point.classList])
      }, [
        (point.isFinished)
          ? (_openBlock(), _createBlock(_component_IconCheck, {
              key: 0,
              class: "h-7 w-7",
              rounded: ""
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(index + 1), 1))
      ], 2))
    }), 128))
  ]))
}