import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const ArticleFilePreviewDtoPropDef = {
  name: {
    type: new StringType(),
  },
  fileDate: {
    type: new DateTimeType(),
  },
  exportName: {
    type: new StringType(),
  },
  isCurrentlyInUse: {
    type: new BooleanType(),
  },
  absolutePath: {
    type: new StringType(),
  },
};
export default class GeneratedArticleFilePreviewDto extends createEntity(ArticleFilePreviewDtoPropDef) {}
