import {
  UploadFunction,
  StoredData,
  Scheduler,
} from '@designeo/sync-manager/src/types';
import {SyncRunner} from '@designeo/sync-manager/src/runners';
import IndexedDBStorage from '@designeo/sync-manager/src/storage/IndexedDBStorage';
import PeriodicOnlineScheduler from '@designeo/sync-manager/src/schedulers/PeriodicOnlineScheduler';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  Ref,
  ref,
} from 'vue';
import {Entity} from '@designeo/apibundle-js/src/Entity/base';

export const storages = {};
export const syncRunners = {};

export const createRunner = <T extends StoredData<any, any>>(name, storage, uploadFunction, scheduler) => {
  return new SyncRunner<T['id'], T>(
    name,
    storage,
    uploadFunction, // function that takes and returns StoredData type (like wrapped axios)
    scheduler,
  );
};

export type SyncRunnerWithStorage<T extends StoredData<any, any>> = {
  storage: IndexedDBStorage<T['id'], T>,
  syncRunner: SyncRunner<T['id'], T['data']>,
}

export type EntityStorageRecord<E extends Entity<any, any>> = {
  // @ts-ignore
  entityData: E['_data'],
  error?: string,
}

export const registerSyncRunnerWithStorage = <T extends StoredData<any, any>>(
  name,
  uploadFunction: UploadFunction<T>,
  {
    scheduler = new PeriodicOnlineScheduler(10 * 1000),
  }: {
    scheduler?: Scheduler<any, any>,
  } = {},
): SyncRunnerWithStorage<T> => {
  storages[name] = new IndexedDBStorage<T['id'], T>(name);
  syncRunners[name] = createRunner<T>(name, storages[name], uploadFunction, scheduler);

  return {
    storage: storages[name],
    syncRunner: syncRunners[name],
  };
};

export const useSyncRunnerItem = <
  DATA extends StoredData<any, any> = StoredData<any, any>,
  DB extends IndexedDBStorage<any, any> = IndexedDBStorage<any, any>,
  RUNNER extends SyncRunner<any, any> = SyncRunner<any, any>,
>(name, query) => {
  const item = ref(null) as Ref<DATA>;
  const storage: DB = storages[name] ?? null;
  const syncRunner: RUNNER = syncRunners[name] ?? null;

  const checkSynced = async () => {
    if (!storage) return;

    const [record] = await storage.list(query);

    item.value = record;
  };

  const synced = computed(() => {
    return item.value?.synced ?? true;
  });

  const retrySync = async () => {
    await syncRunner._uploadSingle(item.value);
  };

  onBeforeMount(async () => {
    if (storage) {
      storage.addEventListener('afterUpsert', checkSynced);
    }
    await checkSynced();
  });

  onBeforeUnmount(() => {
    if (storage) {
      storage.removeEventListener('afterUpsert', checkSynced);
    }
  });

  return {
    item,
    synced,
    retrySync,
  };
};
