import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DownloadProgressDto from '../DownloadProgressDto';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import UpdateConditionDto from '../UpdateConditionDto';
import {DateTimeType} from '@designeo/apibundle-js';
import PosVersionTypeEnum from '../PosVersionTypeEnum';
import {BooleanType} from '@designeo/apibundle-js';


export const UpdateStatusDtoPropDef = {
  downloadProgress: {
    type: new EntityType(DownloadProgressDto),
  },
  targetVersion: {
    type: new StringType(),
  },
  conditions: {
    type: new ArrayType(new EntityType(UpdateConditionDto)),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  updateType: {
    type: new EntityType(PosVersionTypeEnum),
  },
  isReady: {
    type: new BooleanType(),
  },
};
export default class GeneratedUpdateStatusDto extends createEntity(UpdateStatusDtoPropDef) {}
