import {guid} from '@/Helpers/guid';
import {RegisterStoreErrors} from '@/Modules/Register/types';
import {StornoReasonCodes} from '@/constants/stornoReasonCodes';
import {
  DocumentDto,
  DocumentItemDto,
} from '@/Model/Entity';
import {
  map,
  some,
  filter,
  reject,
  find,
  flatMap,
  sumBy,
  reduce,
} from 'lodash-es';
import {fixDecimals, recalculateDocument} from '@designeo/pos-promotion-engine';
import {PromotionMetaType, ItemDiscountType} from '@designeo/pos-promotion-engine/src/index';
import {GroupBySets} from '@/Model/Entity/DocumentDto';
import OutputType from '@/Model/Entity/OutputType';
import DocumentItemGiftDto from '@/Model/Entity/DocumentItemGiftDto';


export async function changeSaleDocumentToStorno(doc: DocumentDto, {
  api3rdPartyCancel,
  getStornoReasonByCode,
  getStornoPrintTemplate,
  getPartialStornoAllowed,
  getOriginalDocumentFiscalReference,
  referentialDocument,
}) {
  doc.originalReferentialDocument = referentialDocument;

  removeActionPromotions(doc);
  removeFollowUpDocumentPromotions(doc);
  recalculateDocument({document: doc.toJson()});

  await Promise.all(
    map(doc.validCheckAndChargeChargedCancellableGroups, async (group) => {
      const request = group.editableItem.ensureCheckAndChargeCancelRequest(doc, group.index);

      try {
        // const response = await (require('@/Model/Action/index.ts').api3rdPartyCancel({
        const response = await api3rdPartyCancel({
          input: request.toJson(),
          params: {
            provider: group.editableItem.provider,
            serviceType: group.editableItem.serviceType,
            ...group.editableItem.getCommonCheckAndChargeParams(doc),
          },
        });

        group.editableItem.setCancelResponseWithinContext(
          doc,
          group.index,
          response,
        );

        if (response.canBeCanceled && !response.isSuccessful) {
          throw new Error('isNotSuccessful');
        }

        return null;
      } catch (e) {
        console.error(e);
      }
    }),
  );

  /**
     * By this point, check and charge items that have cancel response in state:
     * !doc.isSuccessful && doc.cannotBeCanceled
     *
     * are marked as not returnable
     */


  if (some(doc.itemsGroupedBySets, (group) => {
    return (
      group.editableItem.isCheckAndCharge &&
        group.editableItem.canBeCanceled &&
        group.editableItem.canBeReturned &&
        !group.editableItem.cancelResponse?.isSuccessful
    );
  })) {
    throw new Error(RegisterStoreErrors.CHECK_AND_CHARGE_FAILED);
  }

  removeUnreturnableItems(doc);

  doc.payments = [];
  doc.canceledPayments = [];
  doc.header.rounding = 0;
  doc.header.total = 0;
  doc.header.subtotal = 0;
  doc.header.returnReasonCode = getStornoReasonByCode(StornoReasonCodes.cashierError)?.reasonId;
  doc.printTemplateType = new OutputType(getStornoPrintTemplate());
  // doc.header.returnReasonCode = DocumentDto.configurationStore.configuration.value
  //   .getStornoReasonByCode(StornoReasonCodes.cashierError)?.reasonId;

  invertLoyaltyPoints(doc);

  invertItemsToStorno(doc, {getStornoReasonByCode, getOriginalDocumentFiscalReference});

  invertDiscounts(doc);

  regenerateItemsUniqueIdentifiers(doc);

  if (getPartialStornoAllowed()) {
    setItemsQuantityAndMaxQuantity(doc);
  }

  doc.paymentResults = null;
  doc.contents = [];
  doc.header.documentNumber = null;

  recalculateDocument({
    document: <import('@designeo/pos-promotion-engine').DocumentDto> doc.toJson(),
  });
}

export function existItemWithGift(items: DocumentItemDto[], giftUniqueIdentifier: string) {
  return some(items, (item) => {
    return some(item.gifts ?? [], (gift) => gift.giftUniqueIdentifier === giftUniqueIdentifier);
  });
}

export function removeUnreturnableGroup(doc: DocumentDto, group: GroupBySets) {
  doc.unreturnableGroups = [...(doc.unreturnableGroups ?? []), group];
  // @ts-ignore
  const items = new Set([group.mainItem._data, ...group.components.map(({_data}) => _data)]);
  doc.items = reject(doc.items, (item) => items.has(item.toJson()));
  const groups = doc.itemsGroupedBySets;
  for (const item of items) {
    for (const gift of (<DocumentItemGiftDto[]>item.gifts) ?? []) {
      if (!existItemWithGift(doc.items, gift.giftUniqueIdentifier)) {
        const group = find(groups, (group) => group.editableItem.uniqueIdentifier === gift.giftUniqueIdentifier);
        if (group) {
          removeUnreturnableGroup(doc, group);
        }
      }
    }
  }
}

export function removeUnreturnableItems(doc: DocumentDto) {
  // remove rounding
  doc.items = reject(doc.items, (item) => item.isBonRounding);

  for (const group of doc.itemsGroupedBySets) {
    if (!group.editableItem.canBeReturned || !group.editableItem.canBeCanceled) {
      removeUnreturnableGroup(doc, group);
    }
  }
}

export function invertLoyaltyPoints(doc: DocumentDto) {
  if (doc.hasCustomer) {
    doc.unreturnableGroups.forEach((group) => {
      group.editableItem.points.forEach((pointsTransaction) => {
        const transaction = find(doc.loyalty.transactions, {promoCode: pointsTransaction.promoCode});
        transaction.points += Math.round(pointsTransaction.points * -1);
      });
    });

    doc.discounts = reject(doc.discounts, {value: 0});
    doc.loyalty.transactions = reject(doc.loyalty.transactions, {points: 0});

    const txIdMapping = new Map();

    doc.loyalty.transactions = map(doc.loyalty?.transactions ?? [], (transaction) => {
      transaction.points *= -1;
      const oldId = transaction.id;
      transaction.id = guid();
      txIdMapping.set(oldId, transaction.id);
      return transaction;
    });

    for (const item of doc.items) {
      for (const point of item.points) {
        point.transactionId = txIdMapping.get(point.transactionId);
        point.points = fixDecimals(-1 * point.points);
      }
    }

    // this represents changeset of customer attributes
    // we do not know how to revert it (it might depend on other sellDocuments)
    // so we just remove the changeset and leave the customer attributes as they are
    doc.loyalty.attributes = [];
  }
}

export function removeActionPromotions(doc: DocumentDto) {
  doc.promotions = doc.promotions.filter(({type}) => {
    return type !== PromotionMetaType.SEND_SMS;
  });
}

export function removeFollowUpDocumentPromotions(doc: DocumentDto) {
  doc.promotions = doc.promotions.filter(({type}) => {
    return type !== PromotionMetaType.FOLLOW_UP_DOCUMENT;
  });
}

export function invertDiscounts(doc: DocumentDto) {
  const removedDocumentDiscounts = flatMap(
    doc.unreturnableGroups,
    ({mainItem, components}) => filter(
      [].concat(map(components, ({discounts}) => discounts), mainItem.discounts ?? []),
      ({type}) => type.value === ItemDiscountType.DocumentDiscount,
    ),
  );

  // flip item discounts
  for (const {discounts} of doc.items) {
    for (const discount of discounts ?? []) {
      discount.quantity *= -1;
      discount.itemValue *= -1;
      discount.value *= -1;
    }
  }

  // flip document discounts and substract value of it's distribution into unreturnable items
  for (const discount of doc.discounts ?? []) {
    discount.value *= -1;
    discount.value += sumBy(
      filter(removedDocumentDiscounts, ({promoCode}) => promoCode === discount.promoCode),
      ({itemValue}) => itemValue,
    );
  }
}


export function regenerateItemsUniqueIdentifiers(doc: DocumentDto) {
  for (const item of doc.items) {
    item.originalUniqueIdentifier = item.uniqueIdentifier;
    item.uniqueIdentifier = guid();
  }
}

export function invertItemsToStorno(doc: DocumentDto, {getStornoReasonByCode, getOriginalDocumentFiscalReference}) {
  for (const {editableItem, index} of doc.itemsGroupedBySets) {
    editableItem.setIsNegativeWithinContext(
      doc,
      index,
      !editableItem.isNegative,
    );

    editableItem.setIsReturnWithinContext(
      doc,
      index,
      true,
    );

    editableItem.setItemReturnDisabledWithinContext(
      doc,
      index,
      true,
    );

    if (editableItem.hasMandatoryFieldReturnReasonCode) {
      editableItem.setStornoReasonWithinContext(
        doc,
        index,
        getStornoReasonByCode(StornoReasonCodes.cashierError)?.reasonId,
      );
    }

    if (editableItem.hasMandatoryFieldOriginalDocumentReference) {
      editableItem.setStornoReferenceWithinContext(
        doc,
        index,
        doc.header.documentNumber,
      );
    }


    if (editableItem.hasMandatoryFieldOriginalDocumentFiscalReference) {
      editableItem.setOriginalDocumentFiscalReferenceWithinContext(
        doc,
        index,
        getOriginalDocumentFiscalReference(),
      );
    }
  }
}


export function setItemsQuantityAndMaxQuantity(doc: DocumentDto) {
  doc.itemsGroupedBySets = reduce(doc.itemsGroupedBySets, (acc, group) => {
    const {editableItem, index} = group;

    const editableItemRestStornoQuantity = doc
      .originalReferentialDocument
      .getEditableItemRestStornoQuantity(editableItem);

    if (!editableItemRestStornoQuantity) {
      return acc;
    }

    editableItem.setQuantityWithinContext(
      doc,
      index,
      editableItemRestStornoQuantity,
    );

    editableItem.setMaxQuantityWithinContext(
      doc,
      index,
      editableItemRestStornoQuantity * -1,
    );

    acc.push(group);

    return acc;
  }, []);
}
