
import PhIcon from '@/Components/PhIcon.vue';
import {
  defineComponent,
  computed,
  Ref,
  PropType,
} from 'vue';
export default defineComponent({
  components: {PhIcon},
  props: {
    payment: {
      type: Object,
      required: true,
    },
    value: {
      type: Object as PropType<Ref<number>>,
      required: true,
    },
  },
  setup(props) {
    const isActive = computed(() => {
      return props.value.value === props.payment.paymentId;
    });
    return {
      isActive,
    };
  },
});
