
import {defineComponent} from 'vue';
import Html from '@/Components/Html.vue';
// import IconQuestionMark from '@/Components/Icons/IconQuestionMark.vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';

export default defineComponent({
  name: 'Instructions',
  components: {
    // IconQuestionMark,
    Html,
  },
  setup() {
    const workflowStore = useWorkflowStore();

    return {
      workflowStore,
    };
  },
});
