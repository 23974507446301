import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import DrawPartnerResource from '@/Model/Entity/DrawPartnerResource';
import {
  isEmpty,
  map,
  sortBy,
} from 'lodash-es';
import resolveDrawComponent from './Components/resolve';
import {AppLoaderEvent} from '@/Modules/Core/types';

export class WorkflowStepKorunkaLotteryDraw extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaDraw;
  }

  fetchError = null;

  get type() {
    return WorkflowStepKorunkaLotteryDraw.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryDraw.vue').default);
  }

  get gameComponent() {
    return resolveDrawComponent(this.korunkaType);
  }

  get canBeReturnedTo() {
    return !this.isRepeatBetTicket && !this.isTicketFromScan;
  }

  get disabledNextStep() {
    return this.isTicketFromScan ? false : !this.hasSelectedDraws;
  }

  get draws(): DrawPartnerResource[] {
    return map(this.getFieldValue('draws', []), (entityData) => new DrawPartnerResource(entityData));
  }

  set draws(entities: DrawPartnerResource[]) {
    this.dataSetter('draws', () => map(entities, (entity) => entity.toJson()));
  }

  get hasSelectedDraws() {
    if (
      this.isTicketEntity(this.entity) ||
      this.isCardEntity(this.entity) ||
      this.isScanTicketEntity(this.entity) ||
      this.isPreparedTicketEntity(this.entity) ||
      this.isScanResultEntity(this.entity)
    ) return false;

    return !isEmpty(this.entity.draws);
  }

  async fetchDraws() {
    this.ensureCustomerDisplayKorunkaPage();

    if (this.isTicketFromScan) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    await this.syncBasicStepStateToCustomerDisplay();

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      if (!this.stepInit) {
        if (
          this.isTicketEntity(this.entity) ||
          this.isCardEntity(this.entity) ||
          this.isScanTicketEntity(this.entity) ||
          this.isPreparedTicketEntity(this.entity) ||
          this.isScanResultEntity(this.entity)
        ) return;

        const draws = await this.entity.fetchDraws();
        this.draws = sortBy(draws, 'deadlineForBets');
      }

      await this.syncEntityToCustomerDisplay();
      this.stepInit = true;
      this.fetchError = false;
    } catch (e) {
      console.error(e);

      this.fetchError = true;

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  preselectFirstDrawIfEmpty() {
    if (
      this.isTicketEntity(this.entity) ||
      this.isCardEntity(this.entity) ||
      this.isScanTicketEntity(this.entity) ||
      this.isPreparedTicketEntity(this.entity) ||
      this.isScanResultEntity(this.entity)
    ) return;

    const firstDrawOption = this.draws?.[0];

    if (this.entity.draws.length === 0 && firstDrawOption) {
      this.entity.draws = [firstDrawOption];
    }
  }

  async beforeEnter() {
    await this.fetchDraws();
    this.preselectFirstDrawIfEmpty();
  }

  get transitions() {
    return {};
  }
}
