import {createEntity, NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class PORChargeRequestDtoCustom extends createEntity({
  authorizationRequestId: {
    type: new StringType(),
  },
  requestedReceiptLanguage: {
    type: new StringType(),
  },
  requestedReceiptCharsPerLine: {
    type: new NumberType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
