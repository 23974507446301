import GeneratedPaymentTypeClass from './generated/PaymentTypeClass';
import {PaymentConfirmationMethods} from '@/constants/paymentConfirmationMethods';
import {toRaw} from 'vue';
import {PaymentTypeMethods} from '@/constants/paymentTypeMethods';

export default class PaymentTypeClass extends GeneratedPaymentTypeClass {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get payTerminalVirtualIdWithFallback() {
    return this.payTerminalVirtualId ??
    this.configurationStore.configuration.value?.general?.printAndPayment?.payTerminalVirtualId ??
    null;
  }

  get hasConfirmationMethodTerminal() {
    return this.confirmation?.method === PaymentConfirmationMethods.terminal;
  }

  get isCardMethod() {
    return this.type.value === PaymentTypeMethods.card;
  }

  get isCashMethod() {
    return this.type.value === PaymentTypeMethods.cash;
  }
}
