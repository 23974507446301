
import {
  computed,
  defineComponent,
} from 'vue';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '../../types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepKorunkaLotteryCardOverview} from './WorkflowStepKorunkaLotteryCardOverview';
import KorunkaLotteryCardDtoCustom from '@/Model/Entity/custom/KorunkaLotteryCardDtoCustom';
import {useFilters} from '@/Helpers/filters';
import {Languages} from '@/constants/languages';

export default defineComponent({
  name: 'StepKorunkaLotteryScanCard',
  components: {
    HLStep,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaScanCard;

    const {interpolate, numberFormat: originalNumberFormat} = useFilters();

    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryCardOverview>(stepType);

    const entity = computed(() => workflowStep.value.entity as KorunkaLotteryCardDtoCustom);

    const numberFormat = (val) => originalNumberFormat(
      val,
      {useGrouping: true, minimumFractionDigits: 0},
      Languages.CS,
    );

    return {
      stepType,
      workflowStep,
      WorkflowStepField,
      entity,
      interpolate,
      numberFormat,
    };
  },
});
