import {runWithFallback} from '@designeo/js-helpers/src/persistent/helpers';
import {AsyncStorage} from './asyncStore';

export const createPersistentAsyncVariable = ({asyncStorage}: {asyncStorage: AsyncStorage}) =>
  (name: string, fallback: any = null) => ({
    get: () => runWithFallback(() => JSON.parse(asyncStorage.getItem(name)), fallback),
    set: async (val: any) => {
      await asyncStorage.setItem(name, JSON.stringify(val));
      return val;
    },
    reset: async () => await asyncStorage.removeItem(name),
  });
