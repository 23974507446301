import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  apiDocumentGet,
  apiOrdersChangeStatusCancel,
  apiOrdersChangeStatusFulfill,
} from '@/Model/Action';
import {workflowStepMixinEshop} from '../StepMixins/WorkflowStepMixinEshop';
import {
  CancelOrderDto,
  FulfillOrderDto,
  NewOrderPaymentDto,
} from '@/Model/Entity';
import {flow, map} from 'lodash-es';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {guid} from '@/Helpers/guid';
import {workflowStepMixinPayment} from '../StepMixins/WorkflowStepMixinPayment';
import {EshopOrdersChangeStateAction} from '@/Modules/Apps/EshopOrders/types';

export class WorkflowStepEshopOrderStateChange extends flow(
  (ctor) => workflowStepMixinPayment(ctor),
  (ctor) => workflowStepMixinEshop(ctor),
)(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.EshopOrderStateChange;
  }

  get type() {
    return WorkflowStepEshopOrderStateChange.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get action(): EshopOrdersChangeStateAction {
    return this.step.action;
  }

  get orderPayments() {
    if (!this.payments.length) {
      return Promise.resolve([]);
    }

    return Promise
      .resolve(apiDocumentGet({
        params: {
          id: this.financialDocumentReferenceId,
        },
      }))
      .then((financialDocument) => {
        return map(this.payments, (payment) => {
          if (payment.type.hasConfirmationMethodTerminal) {
            const paymentVerificationTriggerDocument = this.paymentTriggerDocumentById(payment.verifyDocumentId);

            return new NewOrderPaymentDto({
              id: paymentVerificationTriggerDocument.header.uniqueidentifier,
              total: payment.value,
              currency: payment.currency,
              terminalVirtualId: payment.payTerminalVirtualId,
              paymentReferenceId: financialDocument.document.header.uniqueidentifier,
              paymentReferenceNumber: financialDocument.document.header.documentNumber,
              paymentType: payment.paymentType.value,
              paymentDate: paymentVerificationTriggerDocument.header.documentDate?.toISOString() ??
              new Date().toISOString(),
            });
          }

          return new NewOrderPaymentDto({
            id: guid(),
            total: payment.value,
            currency: payment.currency,
            terminalVirtualId: payment.payTerminalVirtualId,
            paymentReferenceId: financialDocument.document.header.uniqueidentifier,
            paymentReferenceNumber: financialDocument.document.header.documentNumber,
            paymentType: payment.paymentType.value,
            paymentDate: new Date().toISOString(),
          });
        });
      });
  }

  async fulfillOrder() {
    const authStore = useAuthStore();

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      await apiOrdersChangeStatusFulfill({
        input: new FulfillOrderDto({
          header: {
            cashierPersonalNumber: authStore.activePerson.value?.username,
            cashierName: [
              authStore.activePerson.value?.tokenInfo?.first_name ?? '-',
              authStore.activePerson.value?.tokenInfo?.last_name ?? '-',
            ].join(' '),
            shopCode: this.configurationStore.configuration.value?.general?.pos?.shop?.code,
            posCode: this.configurationStore.configuration.value?.general?.pos?.code,
          },
          customerVerificationDate: this.getFieldValue(WorkflowStepField.customerVerificationDate, undefined),
          payments: map(await this.orderPayments, (payment) => payment.toJson()),
        }),
        params: {
          id: this.order.data.id,
        },
      });

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async cancelOrder() {
    const authStore = useAuthStore();

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      await apiOrdersChangeStatusCancel({
        input: new CancelOrderDto({
          header: {
            cashierPersonalNumber: authStore.activePerson.value?.username,
            cashierName: [
              authStore.activePerson.value?.tokenInfo?.first_name ?? '-',
              authStore.activePerson.value?.tokenInfo?.last_name ?? '-',
            ].join(' '),
            shopCode: this.configurationStore.configuration.value?.general?.pos?.shop?.code,
            posCode: this.configurationStore.configuration.value?.general?.pos?.code,
          },
          cancelReason: this.orderStornoReason.id,
          cancelReasonText: this.orderStornoReason.label,
          cancelReasonNote: this.getFieldValue(WorkflowStepField.note),
        }),
        params: {
          id: this.order.data.id,
        },
      });

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeEnter() {
    if (this.stepInit) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    try {
      await this.ensureOrder();
    } catch (e) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    }

    try {
      switch (this.action) {
      case EshopOrdersChangeStateAction.FULFILL:
        await this.fulfillOrder();
        break;
      case EshopOrdersChangeStateAction.CANCEL:
        await this.cancelOrder();
        break;
      default:
        throw new Error('Unknown action');
      }
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    }
  }

  get transitions() {
    return {};
  }
}
