import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const VerifyOrderPinRequestDtoPropDef = {
  pin: {
    type: new StringType(),
  },
};
export default class GeneratedVerifyOrderPinRequestDto extends createEntity(VerifyOrderPinRequestDtoPropDef) {}
