import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';


export const ErrorPropDef = {
  code: {
    type: new StringType(),
  },
  key: {
    type: new StringType(),
  },
  title: {
    type: new StringType(),
  },
  instructions: {
    type: new StringType(),
  },
  property: {
    type: new StringType(),
  },
  parameters: {
    type: new ObjectType(),
  },
};
export default class GeneratedError extends createEntity(ErrorPropDef) {}
