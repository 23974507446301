import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreEvent,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {getCommonTransitions, TransitionDefinition} from './helpers';
import {UnassignedCardOptions} from '@/constants/unassignedCardOptions';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.UNASSIGNED_CARD]: {
      ...getCommonTransitions.call(this, [RegisterActions.CANCEL]),
      [RegisterActions.BEFORE_LEAVE]: action(async ({to}) => {
        this.state.unassignedCard = null;
      }),
      [RegisterActions.INIT]: action(async ({cardNumber}) => {
        this.state.unassignedCard = cardNumber;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (event.value === UnassignedCardOptions.addToExistCustomer) {
          await this.changeState(RegisterState.CONNECT_CARD_TO_CUSTOMER, {
            initArg: {
              cardNumber: this.state.unassignedCard,
            },
          });
        } else if (event.value === UnassignedCardOptions.createNewCustomer) {
          this.dispatchEvent(new CustomEvent(RegisterStoreEvent.CREATE_NEW_CUSTOMER_FOR_UNASSIGNED_CARD, {
            detail: {
              cardNumber: this.state.unassignedCard,
            },
          }));
        }
      }),
    },
  };
}
