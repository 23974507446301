
import {
  computed,
  defineComponent,
} from 'vue';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import InputBuffer from '@/Components/InputBuffer.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import FormInput from '@/Components/FormInput.vue';
import {HLScrollIntoView} from '@/Components/HLScrollIntoView';
import {useFilters} from '@/Helpers/filters';
import {WorkflowStepEnterLotteryCategories} from './WorkflowStepEnterLotteryCategories';
import {map, some} from 'lodash-es';

export default defineComponent({
  name: 'StepEnterLotteryCategories',
  components: {
    FormInput,
    HLStep,
    HLInput,
    WorkflowTransitions,
    InputBuffer,
    HLScrollIntoView,
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterLotteryCategories;

    const {workflowStep, isActiveField} = useStepTools<WorkflowStepEnterLotteryCategories>(stepType);

    const {interpolate} = useFilters();

    const inputDisplayFilter = (value: any) => {
      const str = String(value);
      return value ? str.replace(/-/g, '') : value;
    };

    const resultMessage = computed(() => {
      if (workflowStep.value.categoriesCorrectionSum < 0) {
        return interpolate(
          workflowStep.value.step.resultMessageNegative,
          {amount: Math.abs(workflowStep.value.categoriesCorrectionSum)},
        );
      }

      if (workflowStep.value.categoriesCorrectionSum > 0) {
        return interpolate(
          workflowStep.value.step.resultMessagePositive,
          {amount: Math.abs(workflowStep.value.categoriesCorrectionSum)},
        );
      }

      if (workflowStep.value.categoriesCorrectionSum === 0) {
        return interpolate(
          workflowStep.value.step.resultMessageEquals,
          {amount: Math.abs(workflowStep.value.categoriesCorrectionSum)},
        );
      }

      return null;
    });

    const isActiveCategoryFieldTitle = (internalNumber) => {
      return some(
        map(workflowStep.value.getCategoryFieldsByInternalNumber(internalNumber), (field) => isActiveField(field)),
      );
    };

    const isLastRowCategoryField = (internalNumber, field) => {
      const {length, [length - 1]: last} = workflowStep.value.getCategoryFieldsByInternalNumber(internalNumber);

      return last === field;
    };

    return {
      stepType,
      WorkflowStepField,
      inputDisplayFilter,
      interpolate,
      resultMessage,
      isActiveCategoryFieldTitle,
      isLastRowCategoryField,
    };
  },
});
