import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col gap-8 flex-grow" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
              return (_openBlock(), _createBlock(_component_HLInput, {
                key: field.key,
                field: field.key
              }, {
                default: _withCtx(({value, error, isActive, setActive}) => [
                  _createVNode(_component_FormInput, {
                    error: error,
                    errorOutput: 'modal',
                    layout: 'col',
                    size: 'sm',
                    active: isActive,
                    class: _normalizeClass({
                'text-basic-dark': field.readonly,
              }),
                    marginBottom: false,
                    onClick: ($event: any) => (field.readonly ? null : setActive())
                  }, _createSlots({
                    label: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(field.label), 1)
                    ]),
                    _: 2
                  }, [
                    (field.component && !field.readonly)
                      ? {
                          name: "form-control",
                          fn: _withCtx(({active}) => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), _mergeProps({
                              modelValue: value.value,
                              "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                              active: active
                            }, {
                    ...(!field.options ? {} : {
                      options: field.options,
                    }),
                  }, { size: 'sm' }), null, 16, ["modelValue", "onUpdate:modelValue", "active"]))
                          ])
                        }
                      : {
                          name: "default",
                          fn: _withCtx(() => [
                            _createTextVNode(_toDisplayString((field.readonly ? field.getReadonlyValue() : value.value) ?? '\xa0'), 1)
                          ])
                        }
                  ]), 1032, ["error", "active", "class", "onClick"])
                ]),
                _: 2
              }, 1032, ["field"]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_WorkflowTransitions, null, {
            transitionNext: _withCtx(({goNext}) => [
              _createVNode(_component_AsyncButton, {
                test: 'wfTransitionsNext',
                onClick: goNext,
                class: "py-2.5 px-7 btn-success"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconCheck, { class: "inline h-3" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('action.setUp')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}