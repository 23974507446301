
import Button from '@/Components/Button.vue';
import InputBuffer from '@/Components/InputBuffer.vue';
import {useStockInStores} from '@/Components/ModalStockInStores/stockInStores';
import {HLGridPaginator} from '@designeo/vue-grid';

export default {
  name: 'StockInStoresButtons',
  components: {
    HLGridPaginator,
    Button,
    InputBuffer,
  },
  setup() {
    const {
      isLoading,
      inputBuffer,
      fetchResult,
      showKeyboard,
      toggleShowKeyboard,
    } = useStockInStores();

    return {
      isLoading,
      inputBuffer,
      fetchResult,
      showKeyboard,
      toggleShowKeyboard,
    };
  },
};
