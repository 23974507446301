import {
  computed,
  onBeforeUnmount,
  ref,
  Ref,
  watch,

} from 'vue';
import {
  every,
  map,
} from 'lodash-es';
import {CreateMessageDto} from '@/Model/Entity';
import {useMessageStore} from '@/Modules/Apps/PosMessage/store/MessageStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useRouter} from 'vue-router';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import MessageDetailDto from '@/Model/Entity/MessageDetailDto';

export const useMessage = (message: Ref<MessageDetailDto|CreateMessageDto>) => {
  const domParsedBody = computed(() => {
    return new DOMParser().parseFromString(`<body>${message.value?.body ?? ''}</body>`, 'text/html');
  });

  const isPlainText = computed(() => every(domParsedBody.value.body.childNodes, (node) => node instanceof Text));

  const sanitizedMessageBody = computed(() => {
    if (!message.value?.body) {
      return '';
    }

    for (const anchor of map(domParsedBody.value.querySelectorAll('a'))) {
      const hrefAttr = anchor.attributes.getNamedItem('href');

      if (!hrefAttr) {
        continue;
      }

      anchor.setAttribute('target', '_blank');

      if (!/^https?:\/\//i.test(hrefAttr.value)) {
        hrefAttr.value = `http://${hrefAttr.value}`;
      }
    }

    for (const paragraph of map(domParsedBody.value.querySelectorAll('p'))) {
      if (paragraph.innerHTML === '') {
        paragraph.innerHTML = '&nbsp;';
      }
    }

    return domParsedBody.value.body.innerHTML;
  });

  const messageBodyInHtml = computed(() => {
    const body = message.value?.body;
    if (body && isPlainText.value) {
      return body.replace(/\n/g, '<br>');
    }

    return body;
  });

  return {
    isPlainText,
    messageBodyInHtml,
    sanitizedMessageBody,
  };
};

export const useMessageReminder = () => {
  const authStore = useAuthStore();
  const messageStore = useMessageStore();
  const configurationStore = useConfigurationStore();
  const router = useRouter();
  const currentTime = ref(0);
  const timeout = ref(null);

  const throttle = computed(() => {
    return configurationStore.configuration.value?.features?.messages?.importOrSensitiveMessageRemindThrottle ?? null;
  });

  const startTimeout = () => {
    if (!throttle.value) {
      return;
    }

    if (!messageStore.unreadImportantOrSensitiveMessages.value.length) {
      destroyTimeout();
      return;
    }


    timeout.value = setInterval(() => {
      currentTime.value += 1;
      if (currentTime.value >= throttle.value) {
        destroyTimeout();
        messageStore.setModalImportantUnreadMessage(null);
      }
    }, 1000);
  };

  const pauseTimeout = () => {
    clearInterval(timeout.value);
  };

  const destroyTimeout = () => {
    clearInterval(timeout.value);
    currentTime.value = 0;
  };

  watch(() => messageStore.modalImportantUnreadMessageIsOpened.value, (newValue, oldValue) => {
    if (!authStore.isLoggedIn.value) {
      destroyTimeout();
      return;
    }

    if (oldValue && !newValue && router.currentRoute.value?.name !== 'apps-messages') {
      startTimeout();
    }
  });

  watch(() => router.currentRoute.value, (newValue, oldValue) => {
    if (newValue?.name === 'apps-messages') {
      destroyTimeout();
    } else if (oldValue?.name === 'apps-messages') {
      startTimeout();
    }
  });

  onBeforeUnmount(() => {
    destroyTimeout();
  });
};

export const attachReceivedMessageToReply = (message, reply) => {
  return `${message}<br><br>${reply}`;
};
