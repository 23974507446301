import {
  BooleanType,
  createEntity,
  NumberType,
  ObjectType,
} from '@designeo/apibundle-js';
import {DocumentHeaderPointsInfo} from '@/constants/documentHeaderPointsInfo';


export default class GeneratedSell extends createEntity({
  maxQuantity: {
    type: new NumberType(),
  },
  numberOfDigitsRecognizedAsQuantity: {
    type: new NumberType(),
  },
  inputs: {
    type: new ObjectType(),
  },
  qwertyForSearch: {
    type: new BooleanType(),
  },
  qwertyForDelayedSell: {
    type: new BooleanType(),
  },
  cancelPaymentsWithReturnFromPayments: {
    type: new BooleanType(),
  },
  serviceArticlePriceOnNominal: {
    type: new BooleanType(),
  },
  customerSearchEnabled: {
    type: new BooleanType(),
  },
  customOriginalDocumentFiscalReference: {
    type: new ObjectType(),
  },
  additionalData: {
    type: new ObjectType(),
  },
  allowZeroPriceArticle: {
    type: new BooleanType(),
  },
  document: {
    type: new ObjectType<{
      header: {
        pointsInfo: DocumentHeaderPointsInfo
      }
    }>(),
  },
}) {}
