import {OutputTypes} from '@/constants/outputTypes';

export enum InventoryActions {
  BEFORE_LEAVE = 'beforeLeave',
  BEFORE_ENTER = 'beforeEnter',
  INIT = 'init',
  ADD_NUMBER = 'addNumber',
  ADD_PERIOD = 'addPeriod',
  ADD_COMMA = 'addComma',
  ADD_PLUS = 'addPlus',
  ADD_MINUS = 'addMinus',
  ADD_CHAR = 'addChar',
  ENTER = 'enter',
  SUM = 'sum',
  BACKSPACE = 'backspace',
  CLEAR = 'clear',
  CANCEL = 'cancel',
  NEXT = 'next',
  PREV = 'prev',
  SCANNER = 'scanner',
  SKIP = 'skip',
}

export enum InventoryEvent {
  UPDATE_GRID_MODEL = 'updateGridModel',
  CANCEL_INVENTORY = 'cancelInventory',
  SEARCH_PRODUCT = 'searchProduct',
  API_ERROR = 'apiError',
  ERROR = 'error',
  EXIT = 'exit',
  SCROLL_TO = 'scrollTo',
  SKIPPED_ARTICLES = 'skippedArticles',
  MISSING_STOCK_ITEMS = 'missingStockItems',
  SEARCH_STOCK_IN_STORE = 'searchStockInStore',
}

export type InventoryInputEvent<V = any> = {
  type: InventoryActions,
  value?: V
}

export enum InventoryState {
  DEFAULT = 'default',
  SEARCH = 'search',
  SHOW_SUMMARY = 'showSummary',
  SHOW_IMAGE = 'showImage',
  EDIT_QUANTITY = 'editQuantity',
  SEARCH_PRODUCTS = 'searchProducts',
  SELECT_MODE = 'selectMode',
  SELECT_PRINT_TEMPLATE = 'selectPrintTemplate',
  SEARCH_ITEM_GROUP_RANGE = 'searchItemGroupRange',
  SEARCH_ITEM_GROUP_LIST = 'searchItemGroupList',
  ITEM_DETAIL = 'itemDetail',
  NON_EMPTY_IN_TRANSIT = 'nonEmptyInTransit',
  STOCK_IN_STORES = 'stockInStores',
}

export enum InventoryDisplayState {
  SEARCH_ARTICLE = 'searchArticle',
  EDIT_QUANTITY = 'editQuantity',
  CANCEL = 'cancel',
}

export enum InventoryCalculationType {
  FULL = 'Full',
  PARTIAL = 'Partial'
}

export enum InventoryPartialMode {
  ITEM_GROUP = 'itemGroup',
  ITEM_GROUP_LIST = 'itemGroupList',
  ITEM_GROUP_RANGE = 'itemGroupRange',
  SCANNED_ARTICLES = 'scannedArticles',
  CUSTOM = 'custom'
}

export enum InventoryQuantityDifferenceFilter {
  ALL = 'all',
  ONLY_DIFFERENCES = 'onlyDifferences'
}

export enum InventoryWarehouseCode {
  OrdinaryWarehouse = '0001',
  ExchangeWarehouse = '0003'
}

export enum InventoryWarehouseType {
  OrdinaryWarehouse = 'OrdinaryWarehouse',
  ExchangeWarehouse = 'ExchangeWarehouse'
}

export enum InventoryErrors {
  INVENTORY_INVALID_GROUP = 'inventoryInvalidGroup',
  INVENTORY_INVALID_GROUP_FROM_TO = 'inventoryGroupInvalidFromTo',
  ARTICLE_IS_TYPE_SET = 'articleIsTypeSet',
  ARTICLE_IS_NOT_PART_OF_ITEM_GROUP = 'articleIsNotPartOfItemGroup',
  GROUP_FETCH_ERROR = 'groupFetchError',
  INVENTORY_NEGATIVE_QUANTITY_ARTICLES = 'inventoryNegativeQuantityArticles',
}

export enum InventoryPrintTemplate {
  ALL = 'all',
  ONLY_DIFFERENCES = 'onlyDifferences',
  FIND_DIFFERENCES = 'findDifferences'
}

export enum InventoryGroup {
  UNKNOWN = 'unknown'
}

export interface InventoryGroupInput {
  active: boolean,
  value: string,
}

export enum InventoryGroupRangeIndex {
  FROM = 0,
  TO = 1
}

export enum InventoryGroupInputType {
  LIST = 'List',
  RANGE = 'Range'
}

export type InputTypeGroupList = {
  type: InventoryGroupInputType.LIST
  inputs: [InventoryGroupInput]
}

export type InputTypeGroupRange = {
  type: InventoryGroupInputType.RANGE
  inputs: [InventoryGroupInput, InventoryGroupInput]
}

export type InventoryGroupInputs = Array<InputTypeGroupList | InputTypeGroupRange>

export type InventoryCustomType = {
  code: string,
}

