
import {defineComponent, PropType} from 'vue';
import WarehouseOperationPlantListItem
  from '@/Modules/Workflow/Step/StepWarehouseOperationPlant/WarehouseOperationPlantListItem.vue';
import PlantDto from '@/Model/Entity/PlantDto';

export default defineComponent({
  components: {WarehouseOperationPlantListItem},
  props: {
    list: {
      type: Array as PropType<Array<{isActive: boolean, entity: PlantDto}>>,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
    return {};
  },
});
