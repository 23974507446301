
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import Modal from '../../../Components/Modal.vue';
import FormInputFloating from '@/Components/FormInputFloating.vue';
import Keyboard from '@/Modules/Core/FourEyes/Keyboard.vue';
import {
  FourEyesStoreErrors,
  FourEyesStoreEvents,
  useFourEyesStore,
  UsernameValidations,
} from '@/Modules/Core/store/FourEyesStore';
import {
  AppLoaderEvent,
  FourEyesActions,
  FourEyesOperations,
} from '@/Modules/Core/types';
import {useMaskString} from '@/Helpers/mask';
import {useI18n} from 'vue-i18n';
import {KeyboardBuffer} from '@/Modules/Register/services/KeyboardBuffer';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import * as yup from 'yup';
import {useHelpTranslations} from '@/Helpers/translations';
import {useFilters} from '@/Helpers/filters';
import {VerifyCashierStatus} from '../../Auth/types';
import {seekApiErrors, useErrorParser} from '@/Helpers/errors';
import IconPerson from '@/Components/Icons/IconPerson.vue';
import {ZLayer} from '@/constants/zLayer';

export default defineComponent({
  name: 'ModalFourEyes',
  components: {
    IconPerson,
    Keyboard,
    FormInputFloating,
    Modal,
  },
  setup() {
    const {dateFormat} = useFilters();
    const coreStore = useCoreStore();
    const fourEyesStore = useFourEyesStore();
    const {maskedValue: password} = useMaskString(fourEyesStore.password);
    const username = computed(() => fourEyesStore.username.value);
    const i18n = useI18n();
    const {getErrorName} = useHelpTranslations();
    const getInitErrorsState = () => ({username: null, password: null});
    const errors = ref(getInitErrorsState());
    const parseError = useErrorParser();

    const keyboardBuffer = new KeyboardBuffer((data) => {
      for (const buffer of data) {
        fourEyesStore.onKeyboardInput(buffer);
      }
    }, {muteResolver: () => false});

    const onKeyboardInput = async (event) => {
      if (keyboardBuffer.isMuted) {
        return;
      }

      await fourEyesStore.onEventInput(event);
    };

    const setLoaderOn = () => {
      coreStore.setLoader(true);
    };

    const setLoaderOff = () => {
      coreStore.setLoader(false);
    };

    const submit = () => {
      fourEyesStore.onEventInput({
        type: FourEyesActions.ENTER,
      });
    };

    const setUsernameState = (event) => {
      event.target.blur();
      fourEyesStore.setUsernameState();
    };
    const setPasswordState = (event) => {
      event.target.blur();
      fourEyesStore.setPasswordState();
    };

    const close = () => {
      fourEyesStore.closeFourEyesConfirm();
    };

    const operation = computed(() => {
      const operationKeys = {
        default: fourEyesStore.operation.value,
        [FourEyesOperations.RECEIPT_STORNO_BEFORE_TIMEOUT]: 'referentialReceiptCancel',
        [FourEyesOperations.RECEIPT_STORNO_AFTER_TIMEOUT]: 'referentialReceiptCancel',
      };
      const operationKey = operationKeys[fourEyesStore.operation.value] ?? operationKeys.default;
      const translationKey = `fourEyes.operations.${operationKey}`;

      if (!i18n.te(translationKey)) return '';

      const operationArgs = ({
        referentialReceiptCancel: () => {
          if (!fourEyesStore.subject.value) {
            return {
              number: null,
              date: null,
            };
          }

          return {
            number: fourEyesStore.subject.value.header.documentNumber,
            date: dateFormat(fourEyesStore.subject.value.header.documentDate),
          };
        },
        [FourEyesOperations.PRODUCT_CANCEL]: () => ({
          subject: fourEyesStore.subject.value,
        }),
        [FourEyesOperations.PRODUCT_RETURN]: () => ({
          subject: fourEyesStore.subject.value,
        }),
      })[operationKey]?.() ?? {};

      return i18n.t(translationKey, operationArgs);
    });

    const getInvalidUsernameErrorMessage = (event: CustomEvent<
        {type: UsernameValidations.SIZE, [key: string]: any} |
        {type: UsernameValidations.STATUS, status: VerifyCashierStatus} |
        {type: UsernameValidations.API, error: any}
      >) => {
      if (event.detail.type === UsernameValidations.SIZE) {
        return i18n.t('validations.string.fourEyesUsernameLength', event.detail);
      }

      if (event.detail.type === UsernameValidations.STATUS) {
        return i18n.t(
          event.detail.status === VerifyCashierStatus.INVALID ?
            'validations.string.fourEyesUsernameInvalid' :
            'validations.string.fourEyesUsernameNotFound',
          event.detail,
        );
      }

      return parseError(seekApiErrors(event.detail.error)).join(', ');
    };

    const onInvalidUsername = async (event: CustomEvent) => {
      errors.value.username = new yup.ValidationError(getInvalidUsernameErrorMessage(event));
    };

    const onOneTimeCodeFailed = () => {
      errors.value.username = new yup.ValidationError(getErrorName(FourEyesStoreErrors.ONE_TIME_CODE_FAILED));
    };

    const onLoginFailed = (event: CustomEvent) => {
      errors.value.password = new yup.ValidationError(parseError(seekApiErrors(event.detail.error)).join(', '));
    };

    const onUsernameValid = () => {
      errors.value.username = null;
    };

    onMounted(() => {
      fourEyesStore.addEventListener(AppLoaderEvent.ON, setLoaderOn);
      fourEyesStore.addEventListener(AppLoaderEvent.OFF, setLoaderOff);
      fourEyesStore.addEventListener(FourEyesStoreErrors.ONE_TIME_CODE_FAILED, onOneTimeCodeFailed);
      fourEyesStore.addEventListener(FourEyesStoreErrors.LOGIN_FAILED, onLoginFailed);
      fourEyesStore.addEventListener(FourEyesStoreErrors.INVALID_USERNAME, onInvalidUsername);
      fourEyesStore.addEventListener(FourEyesStoreEvents.USERNAME_VALID, onUsernameValid);
    });

    onBeforeUnmount(() => {
      fourEyesStore.removeEventListener(AppLoaderEvent.ON, setLoaderOn);
      fourEyesStore.removeEventListener(AppLoaderEvent.OFF, setLoaderOff);
      fourEyesStore.removeEventListener(FourEyesStoreErrors.ONE_TIME_CODE_FAILED, onOneTimeCodeFailed);
      fourEyesStore.removeEventListener(FourEyesStoreErrors.LOGIN_FAILED, onLoginFailed);
      fourEyesStore.removeEventListener(FourEyesStoreErrors.INVALID_USERNAME, onInvalidUsername);
      fourEyesStore.removeEventListener(FourEyesStoreEvents.USERNAME_VALID, onUsernameValid);
    });

    watch(() => fourEyesStore.isActive.value, async (newValue, oldValue) => {
      if (newValue && !oldValue) {
        keyboardBuffer.attach();
      } else if (oldValue && !newValue) {
        keyboardBuffer.detach();
        errors.value = getInitErrorsState();
      }
    }, {immediate: true});

    return {
      fourEyesStore,
      close,
      username,
      password,
      operation,
      setUsernameState,
      setPasswordState,
      submit,
      errors,
      ZLayer,
      onKeyboardInput,
    };
  },
});
