import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {
  commonWfFieldValidations,
  wfMaxLengthValidation,
} from '@/Helpers/validations';
import {isNil, reduce} from 'lodash-es';
import {SexOptions} from '@/constants/sexOptions';

export class WorkflowStepWorkflowValidations extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.WorkflowValidations;
  }

  get type() {
    return WorkflowStepWorkflowValidations.type;
  }

  get component() {
    return markRaw(require('./StepWorkflowValidations.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.amount]: yup.string()
        .required()
        .test(wfMaxLengthValidation()),
      [WorkflowStepField.note]: yup.string()
        .test(commonWfFieldValidations(this.step.validations?.[WorkflowStepField.note])),
      [WorkflowStepField.label]: yup.string()
        .test(commonWfFieldValidations(this.step.validations?.[WorkflowStepField.label])),
      [WorkflowStepField.sex]: yup.string()
        .required(),
    });
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.sex, (fieldActions) => ({
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.PREV]: action(() => {
          fieldActions[WorkflowActions.ENTER](SexOptions.MALE);
        }),
        [WorkflowActions.NEXT]: action(() => {
          fieldActions[WorkflowActions.ENTER](SexOptions.FEMALE);
        }),
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<boolean>) => {
          if (!isNil(event.value)) {
            fieldActions[WorkflowActions.ENTER](event.value);
          } else {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
              detail: {
                field: WorkflowStepField.amount,
              },
            }));
          }
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.amount, (fieldActions) => ({
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
          if (
            this.data[WorkflowStepField.amount].includes('.') ||
            this.data[WorkflowStepField.amount][this.data[WorkflowStepField.amount].length - 1] === '-'
          ) {
            return;
          }
          fieldActions[WorkflowActions.ADD_COMMA](event.value);
        }),
        [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
          if (this.data[WorkflowStepField.amount].includes('.')) return;
          fieldActions[WorkflowActions.ADD_PERIOD](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ADD_MINUS]: action(() => {
          if (!this.data[WorkflowStepField.amount]?.length) {
            fieldActions[WorkflowActions.ADD_MINUS]();
          }
        }),
        [WorkflowActions.ENTER]: action(async () => {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
            detail: {
              field: WorkflowStepField.note,
            },
          }));
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.note, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_CHAR,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
            detail: {
              field: WorkflowStepField.label,
            },
          }));
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.label, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_CHAR,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
