import GeneratedDocumentLoyaltyDto from './generated/DocumentLoyaltyDto';
import ExternalProgramsDto from '@/Model/Entity/ExternalProgramsDto';
import KorunkaExternalProgramDto from './KorunkaExternalProgramDto';

export default class DocumentLoyaltyDto extends GeneratedDocumentLoyaltyDto {
  setKorunkaCard(cardNumber: string) {
    this.externalPrograms = new ExternalProgramsDto({
      ...(this.externalPrograms ? this.externalPrograms.toJson() : {}),
      korunka: new KorunkaExternalProgramDto({cardNumber}).toJson(),
    });
  }
}
