import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const TransactionItemPropDef = {
  id: {
    type: new StringType(),
  },
  transactionId: {
    type: new StringType(),
  },
  internalNumber: {
    type: new StringType(),
  },
  gtin: {
    type: new StringType(),
  },
  quantity: {
    type: new NumberType(),
  },
  count: {
    type: new NumberType(),
  },
  valueAfterDiscounts: {
    type: new NumberType(),
  },
  vatValue: {
    type: new NumberType(),
  },
  priceNormal: {
    type: new NumberType(),
  },
  priceAfterItemDiscounts: {
    type: new NumberType(),
  },
  priceAction: {
    type: new NumberType(),
  },
  valueBeforeDiscounts: {
    type: new NumberType(),
  },
  isCanceled: {
    type: new BooleanType(),
  },
  // transaction: {
  //   type: new EntityType(Transaction),
  // },
};
export default class GeneratedTransactionItem extends createEntity(TransactionItemPropDef) {}
