
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import {useFilters} from '@/Helpers/filters';
import {WorkflowStepKorunkaLotteryOverview} from '../WorkflowStepKorunkaLotteryOverview';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ModalNumbers from './ModalNumbers.vue';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useWorkflowStore} from '../../../store/WorkflowStore';
import {WorkflowStepTypes, WorkflowActions} from '../../../types';
import {useStepTools} from '../../StepTools';
import KorunkaLotteryKombiPlusDtoCustom from '@/Model/Entity/custom/KorunkaLotteryKombiPlusDtoCustom';
import {getLetterFromIndex} from '@/Helpers/alphabetIndex';

export default defineComponent({
  components: {
    ModalNumbers,
  },
  setup() {
    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryOverview>(WorkflowStepTypes.KorunkaOverview);

    const workflowStore = useWorkflowStore();
    const configurationStore = useConfigurationStore();
    const {dateFormat, currencyFormat: baseCurrencyFormat} = useFilters();

    const entity = computed(() => workflowStep.value.entity as KorunkaLotteryKombiPlusDtoCustom);

    const drawsDates = computed(() => {
      if (!entity.value?.firstAndLastDraw) {
        return null;
      }

      return entity.value.firstAndLastDraw
        .map((value) => dateFormat(value.deadlineForBets, {format: 'dd. MM.'}))
        .join(' - ');
    });

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    const openNumbersModal = () => {
      workflowStep.value.setNumbersModalIsOpen(true);
    };

    const closeNumbersModal = () => {
      workflowStep.value.setNumbersModalIsOpen(false);
    };

    const onCustomerConfirmResult = async (event: CustomEvent<boolean>) => {
      const result = event.detail;
      workflowStep.value.setCustomerConfirmResult(result);

      if (result) {
        if (workflowStep.value.numbersModalIsOpen) {
          closeNumbersModal();
        }

        await workflowStore.onEventInput({
          type: WorkflowActions.ENTER,
        });

        broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      } else {
        await workflowStore.onEventInput({
          type: WorkflowActions.CANCEL,
        });
      }
    };

    onMounted(() => {
      broadcastIO.addEventListener(BroadcastIOChannels.KORUNKA_LOTTERY_CONFIRM_RESULT, onCustomerConfirmResult);
    });

    onBeforeUnmount(() => {
      broadcastIO.removeEventListener(BroadcastIOChannels.KORUNKA_LOTTERY_CONFIRM_RESULT, onCustomerConfirmResult);
    });

    return {
      workflowStep,
      entity,
      currencyFormat,
      drawsDates,
      openNumbersModal,
      closeNumbersModal,
      getLetterFromIndex,
    };
  },
});
