import PermissionTargetDto from '@/Model/Entity/PermissionTargetDto';
import {RegisterRoutesOptions} from '@/ModuleSystem';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {RouteLocation} from 'vue-router';
import * as aclKeys from '@/constants/aclKeys';

export default [
  // query.referer // return url
  // query.fresh // restart even if workflow exists
  {
    path: '/workflow/:workflowCode',
    name: 'workflow',
    component: () => import('./Workflow/WorkflowPage.vue'),
    meta: {
      acl: (to: RouteLocation) =>
        PermissionTargetDto.create(aclKeys.PERMISSION_WORKFLOW_ACCESS_CUSTOM)
          .withParameters([].concat(to.params.workflowCode)),
    },
    props: true,
    async beforeEnter(to, from, next) {
      const configurationStore = useConfigurationStore();

      if (
        !Object.prototype.hasOwnProperty.call(configurationStore.configuration.value.workflow, to.params.workflowCode)
      ) {
        next('/404');
      } else {
        next();
      }
    },
  },
] as RegisterRoutesOptions;
