
import {
  computed,
  defineComponent,
  nextTick,
  ref,
  watch,

} from 'vue';
import {useInactivityStore} from '@/Modules/Core/store/InactiveStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';

export default defineComponent({
  name: 'InactivityBar',
  setup() {
    const inactivityStore = useInactivityStore();
    const authStore = useAuthStore();
    let queueLength = 0;
    let animationQueue = Promise.resolve();
    const progressQueued = ref(100);
    const isActiveQueued = ref(false);

    const hasActivePerson = computed(() => {
      return !!authStore.activePerson.value;
    });

    const hasValidTimeout = computed(() => {
      return inactivityStore.currentCondition.value?.hasValidTimeout;
    });

    const isActive = computed(() => {
      // if (!hasActivePerson.value) {
      //   return false;
      // }

      return !!inactivityStore.currentCondition.value;
    });

    const progress = computed(() => {
      return inactivityStore.progress.value;
    });


    watch(() => ({progress: progress.value, isActive: isActive.value}), ({progress, isActive}) => {
      queueLength += 1;

      animationQueue = animationQueue.then(() => new Promise((resolve) => {
        progressQueued.value = progress;
        isActiveQueued.value = isActive;
        nextTick(() => {
          setTimeout(() => {
            queueLength -= 1;

            if (!queueLength) {
              animationQueue = Promise.resolve();
            }

            resolve();
          }, 200);
        });
      }));
    }, {immediate: true});

    return {
      hasActivePerson,
      progressQueued,
      isActiveQueued,
      hasValidTimeout,
    };
  },
});
