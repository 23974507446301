import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerSex from '../CustomerSex';
import {DateTimeType} from '@designeo/apibundle-js';
import CustomerState from '../CustomerState';


export const CustomerDtoPropDef = {
  id: {
    type: new StringType(),
  },
  customerNumber: {
    type: new StringType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  cardType: {
    type: new StringType(),
  },
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
  yearOfBirth: {
    type: new NumberType(),
  },
  dayOfBirth: {
    type: new NumberType(),
  },
  monthOfBirth: {
    type: new NumberType(),
  },
  sex: {
    type: new EntityType(CustomerSex),
  },
  gdprConsentDate: {
    type: new DateTimeType(),
  },
  gdprConsentPlace: {
    type: new StringType(),
  },
  phoneVerificationDate: {
    type: new DateTimeType(),
  },
  emailVerificationDate: {
    type: new DateTimeType(),
  },
  state: {
    type: new EntityType(CustomerState),
  },
  createdDate: {
    type: new DateTimeType(),
  },
  editedDate: {
    type: new DateTimeType(),
  },
  lastTransactionDate: {
    type: new DateTimeType(),
  },
  deletedDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedCustomerDto extends createEntity(CustomerDtoPropDef) {}
