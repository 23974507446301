import GeneratedCustomerDto from './generated/CustomerDto';

export default class CustomerDto extends GeneratedCustomerDto {
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  toString() {
    this.fullName;
  }

  toStringWithCustomerNumber() {
    return `${this.fullName} (${this.customerNumber})`;
  }
}
