
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {map} from 'lodash-es';
import {
  defineComponent,
  nextTick,
  ref,
  watch,
} from 'vue';

export default defineComponent({
  name: 'Theme',
  setup() {
    const configurationStore = useConfigurationStore();
    const styleRef = ref<HTMLStyleElement>(null);


    watch(
      () => configurationStore.configuration.value?.ui?.style?.variables ?? {},
      async (newVal, oldVal) => {
        await nextTick();
        styleRef.value.innerHTML = `:root {${map(newVal ?? {}, (val, key) => `${key}: ${val};`).join('')}}`;
      }, {immediate: true});

    return {
      styleRef,
    };
  },
});
