import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {
  WarehouseOperations,
  WarehouseOperationStepFieldGroups,
} from '@/constants/warehouseOperations';
import {
  flow,
} from 'lodash-es';
import {SignalRErrors} from '@/Helpers/signalR';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {workflowStepMixinSaveFinDocument} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinSaveFinDocument';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {apiV1PosInStoreManagementShipmentNotificationPutProcessed} from '@/Model/Action';
import {
  DocumentDto,
  TransactionDirectionType,
} from '@/Model/Entity';
import FiscalCommands from '../../../../Model/Entity/FiscalCommands';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowStepApiCreateWarehouseOperation extends flow(
  (ctor) => workflowStepMixinWarehouseOperation(ctor),
  (ctor) => workflowStepMixinSaveFinDocument(ctor),
)(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiCreateWarehouseOperation;
  }

  get type() {
    return WorkflowStepApiCreateWarehouseOperation.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get logisticOperation(): DocumentDto {
    const logisticOperation = new DocumentDto(
      this.getFieldValue(this.createAdditionalInfoFieldDataPath(WorkflowStepField.document)),
    );

    const transaction = this.transaction;

    if (!transaction) {
      return logisticOperation;
    }

    if (transaction.code) {
      logisticOperation.header.finDocumentCode = transaction.code;
    }

    if (transaction.name) {
      logisticOperation.header.finDocumentName = transaction.name;
    }

    if (transaction.sapTransactionCode) {
      logisticOperation.header.sapTransactionCode = transaction.sapTransactionCode;
    }

    if (transaction.source) {
      logisticOperation.header.source = new TransactionDirectionType(transaction.source);
    }

    if (transaction.destination) {
      logisticOperation.header.destination = new TransactionDirectionType(transaction.destination);
    }

    if (transaction.transactionSubType) {
      logisticOperation.documentSubType = new FiscalCommands(transaction.transactionSubType);
    }

    return logisticOperation;
  }

  get transaction() {
    return this.configurationStore
      .logisticTransactionsByCode
      .value?.[this.step.transactionCode];
  }

  createAdditionalInfoFieldDataPath(field: string) {
    return `${WarehouseOperationStepFieldGroups.additionalInfo}.${field}`;
  }

  hasLogisticOperationBeMarked(logisticOperation: DocumentDto) {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
    case WarehouseOperations.receiptOfPrintedMatters:
    case WarehouseOperations.returnOfPrintedMatters:
    case WarehouseOperations.reloadingOfGoodsTypeReceiving:
      return !!logisticOperation.header.externalReference;
    default:
      return false;
    }
  }

  async createLogisticDocument() {
    if (this.stepInit) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    let finDocumentResult: Awaited<ReturnType<WorkflowStepApiCreateWarehouseOperation['saveFinDocument']>> = null;
    const logisticOperation = this.logisticOperation;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));


      finDocumentResult = await this.saveFinDocument(logisticOperation);

      finDocumentResult.result = new PrinterResult(finDocumentResult.result ?? {});

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      if (!finDocumentResult?.result?.hasError) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));
      }
    } finally {
      await (async () => {
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));

        if (!finDocumentResult?.created) {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          return;
        }

        if (this.hasLogisticOperationBeMarked(logisticOperation)) {
          try {
            this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
            await apiV1PosInStoreManagementShipmentNotificationPutProcessed({
              params: {
                id: logisticOperation.header.uniqueidentifier,
              },
            });
          } catch (e) {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
            return;
          } finally {
            this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
          }
        }

        if (isActiveFeaturePrintDisplayOnScreen() && finDocumentResult?.result?.hasValidPrintContent) {
          await this.printContentStore.open(finDocumentResult?.result?.printContent);
        }

        this.stepInit = true;

        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      })();
    }
  }

  get title() {
    return this.titleByWarehouseOperation;
  }

  async beforeEnter() {
    await this.createLogisticDocument();
  }

  get transitions() {
    return {};
  }
}
