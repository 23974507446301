import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {trainingStoreStatePersist} from '@/Helpers/persist';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {submitJournalEventTrainingModeEnded, submitJournalEventTrainingModeStarted} from '@/Helpers/journal';

export interface ITrainingStore {
  training: boolean,
}

export class TrainingStore extends PersistentStore<ITrainingStore> {
  constructor() {
    super({
      training: false,
    }, trainingStoreStatePersist);
  }

  startTraining = action(() => {
    this.state.training = true;
    submitJournalEventTrainingModeStarted();
  })

  finishTraining = action(() => {
    this.state.training = false;
    submitJournalEventTrainingModeEnded();
  })

  trainingIsActive = getter(() => {
    return !!this.state.training;
  })
}

const storeIdentifier = 'TrainingStore';

export const configureTrainingStore = createConfigureStore<typeof TrainingStore>(storeIdentifier);
export const useTrainingStore = createUseStore(TrainingStore, storeIdentifier);
