import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.value
          }, {
            default: _withCtx(({isActive, value, setActive, error}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.value,
                active: isActive,
                error: error,
                errorOutput: 'modal',
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.phoneFormat(value.value)), 1)
                ]),
                _: 2
              }, 1032, ["label", "active", "error", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"]),
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.label
          }, {
            default: _withCtx(({isActive, value, setActive, error}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.label,
                active: isActive,
                error: error,
                errorOutput: 'modal',
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value.value), 1)
                ]),
                _: 2
              }, 1032, ["label", "active", "error", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"])
        ]),
        _createVNode(_component_WorkflowTransitions, {
          nextAction: () => workflowStep.finishWorkflow()
        }, null, 8, ["nextAction"])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}