import {KeyPress} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransformStream,
} from '../streams';

export function createDebouncedKeypressLogger(ms: number) {
  let buffer: KeyPress[] = [];
  let timeout = null;
  return new TransformStream<KeyPress, KeyPress>({
    transform(key, controller) {
      if (typeof window !== 'undefined' && window['KEYLOGGER']) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log('Keys:', JSON.stringify(buffer, null, 2));
          buffer = [];
        }, ms);

        buffer.push(key);
      }
      controller.enqueue(key);
    },
  });
}
