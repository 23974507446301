import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ChangeOrderStatusHeaderDtoPropDef = {
  cashierPersonalNumber: {
    type: new StringType(),
  },
  cashierName: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
  posCode: {
    type: new StringType(),
  },
};
export default class GeneratedChangeOrderStatusHeaderDto extends createEntity(ChangeOrderStatusHeaderDtoPropDef) {}
