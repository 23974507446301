
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepSafeBagDeliveryDriver',
  components: {WorkflowTransitions, FormInput, HLInput, HLStep},
  setup() {
    const stepType = WorkflowStepTypes.SafeBagDeliveryDriver;

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
