import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "text-center flex-grow" }
const _hoisted_3 = { class: "pb-4" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "uppercase font-bold" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "uppercase font-bold" }
const _hoisted_8 = { class: "mt-5" }
const _hoisted_9 = { class: "pt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPerson = _resolveComponent("IconPerson")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({abortWorkflow, workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconPerson, {
              class: "inline",
              rounded: ""
            })
          ]),
          (workflowStep && workflowStep.stepFinished)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(workflowStep.step.stepFinishedInfo), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(workflowStep.step.customerInputInfo), 1),
                _createElementVNode("div", null, _toDisplayString(workflowStep.step.customerInputRequest), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_AsyncButton, {
                    class: "btn-basic",
                    onClick: abortWorkflow
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(workflowStep.step.abort), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ])),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.repeatRequestIsVisible)
              ? (_openBlock(), _createBlock(_component_AsyncButton, {
                  key: 0,
                  class: "btn-warning",
                  onClick: _ctx.repeatRequest
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(workflowStep.step.repeatRequest), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_WorkflowTransitions, {
          nextDisabled: !workflowStep.stepFinished
        }, null, 8, ["nextDisabled"])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}