import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerContactDtoPropDef = {
  id: {
    type: new StringType(),
  },
  customerId: {
    type: new StringType(),
  },
  type: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
  label: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerContactDto extends createEntity(CustomerContactDtoPropDef) {}
