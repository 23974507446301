import {isNil} from 'lodash-es';
export * from './GiftSelectionFlow';
import {PointsBurningFlow} from './PointsBurningFlow';
import {GiftSelectionFlow} from './GiftSelectionFlow';
import {QuestionFlow} from './QuestionFlow';
import {GecoGameFlow} from './GecoGameFlow';
import {DynamicSetSelectionFlow} from './DynamicSetSelectionFlow';
import {DynamicSetLevelsSelectionFlow} from './DynamicSetLevelsSelectionFlow';
import {ContextualProductRecommendationsSelectionFlow} from './ContextualProductRecommendationsSelectionFlow';

export type PromoFlow = DynamicSetSelectionFlow
  | DynamicSetLevelsSelectionFlow
  | GiftSelectionFlow
  | PointsBurningFlow
  | QuestionFlow
  | GecoGameFlow
  | ContextualProductRecommendationsSelectionFlow

export enum PromoInteractionFlowTypes {
  GIFT_SELECTION_FLOW='GiftSelectionFlow',
  POINTS_BURNING_FLOW='PointsBurningFlow',
  QUESTION_FLOW='QuestionFlow',
  GECO_GAME_FLOW='GecoGameFlow',
  DYNAMIC_SET_SELECTION_FLOW='DynamicSetSelectionFlow',
  DYNAMIC_SET_LEVELS_SELECTION_FLOW='DynamicSetLevelsSelectionFlow',
  CONTEXTUAL_PRODUCT_RECOMMENDATIONS_SELECTION_FLOW='ContextualProductRecommendationsSelectionFlow',
}

export function deserializePromoInteractionFlow(flow): PromoFlow {
  if (isNil(flow?.type)) {
    return null;
  }
  switch (flow.type) {
  case PromoInteractionFlowTypes.GIFT_SELECTION_FLOW:
    return GiftSelectionFlow.deserialize(flow);
  case PromoInteractionFlowTypes.POINTS_BURNING_FLOW:
    return PointsBurningFlow.deserialize(flow);
  case PromoInteractionFlowTypes.QUESTION_FLOW:
    return QuestionFlow.deserialize(flow);
  case PromoInteractionFlowTypes.GECO_GAME_FLOW:
    return GecoGameFlow.deserialize(flow);
  case PromoInteractionFlowTypes.DYNAMIC_SET_SELECTION_FLOW:
    return DynamicSetSelectionFlow.deserialize(flow);
  case PromoInteractionFlowTypes.DYNAMIC_SET_LEVELS_SELECTION_FLOW:
    return DynamicSetLevelsSelectionFlow.deserialize(flow);
  case PromoInteractionFlowTypes.CONTEXTUAL_PRODUCT_RECOMMENDATIONS_SELECTION_FLOW:
    return ContextualProductRecommendationsSelectionFlow.deserialize(flow);
  default: return null;
  }
}
