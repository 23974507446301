import {RegisterRoutesOptions} from '@/ModuleSystem';

export default [
  {
    path: '/customer-external',
    name: 'customer-default',
    meta: {authorization: false},
    component: () => import('./Idle/IdlePage.vue'),
  },
  {
    path: '/customer-external/pin-verification',
    name: 'customer-pin-verification',
    meta: {authorization: false},
    component: () => import('./PinVerification/PinVerificationPage.vue'),
  },
  {
    path: '/customer-external/gdpr',
    name: 'customer-gdpr',
    meta: {authorization: false},
    component: () => import('./CustomerRegistration/GDPRPage.vue'),
  },
  {
    path: '/customer-external/customer-information-confirm',
    name: 'customer-customer-information-confirm',
    meta: {authorization: false},
    component: () => import('./CustomerRegistration/InformationConfirmPage.vue'),
  },
  {
    path: '/customer-external/checkout',
    name: 'customer-checkout',
    meta: {authorization: false},
    component: () => import('./Checkout/CheckoutPage.vue'),
  },
  {
    path: '/customer-external/points-burning',
    name: 'customer-points-burning',
    meta: {authorization: false},
    component: () => import('./PointsBurning/PointsBurningPage.vue'),
  },
  {
    path: '/customer-external/phone-charge',
    name: 'customer-phone-charge',
    meta: {authorization: false},
    component: () => import('./PhoneCharge/PhoneChargePage.vue'),
  },
  {
    path: '/customer-external/phone-charge-confirm',
    name: 'customer-phone-charge-confirm',
    meta: {authorization: false},
    component: () => import('./PhoneCharge/PhoneChargeConfirmPage.vue'),
  },
  {
    path: '/customer-external/question',
    name: 'customer-question',
    meta: {authorization: false},
    component: () => import('./Promotions/PromotionQuestionPage.vue'),
  },
  {
    path: '/customer-external/gift-choice',
    name: 'customer-gift-choice',
    meta: {authorization: false},
    component: () => import('./Promotions/PromotionGiftChoice.vue'),
  },
  {
    path: '/customer-external/geco-game',
    name: 'customer-geco-game',
    meta: {authorization: false},
    component: () => import('./GecoGame/GecoGamePage.vue'),
  },
  {
    path: '/customer-external/btc-confirm',
    name: 'customer-btc-confirm',
    meta: {authorization: false},
    component: () => import('./BTC/BTCConfirmPage.vue'),
  },
  {
    path: '/customer-external/btc-enter-pin',
    name: 'customer-btc-enter-pin',
    meta: {authorization: false},
    component: () => import('./BTC/BTCEnterPinPage.vue'),
  },
  {
    path: '/customer-external/korunka',
    name: 'korunka-default',
    meta: {authorization: false},
    component: () => import('./KorunkaLottery/KorunkaPage.vue'),
  },
  {
    path: '/customer-external/contextual-product-recommendations',
    name: 'customer-contextual-product-recommendations',
    meta: {authorization: false},
    component: () => import('./ContextualProductRecommendations/ContextualProductRecommendationsPage.vue'),
  },
] as RegisterRoutesOptions;
