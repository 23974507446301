import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: !!_ctx.coreStore.confirmContent.value,
    zIndex: _ctx.zIndex,
    name: "confirm",
    onClose: _cache[0] || (_cache[0] = (event) => event ? _ctx.onConfirm() : _ctx.onReject())
  }, {
    body: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component)))
    ]),
    _: 1
  }, 8, ["open", "zIndex"]))
}