export enum ModalTypes {
 'sm' = 'sm',
 'md' = 'md',
 'lg' = 'lg',
 'xl' = 'xl',
 'full' = 'full',
 'pda' = 'pda',
 'pda-sm' = 'pda-sm',
}

export type ModalType = ModalTypes | (string & {});
