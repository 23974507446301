import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  computed,
} from 'vue';
import {ArticleLabels} from '@/Model/Entity';

export const useArticleLabel = (key: keyof ArticleLabels['$fields']) => {
  const {translatedString} = useFilters();
  const configurationStore = useConfigurationStore();

  const configuration = computed(() => configurationStore.configuration.value?.articleLabels?.[key]);
  const theme = computed(() => configuration.value?.style);
  const label = computed(() => {
    if (!configuration.value?.value) {
      return key;
    }

    return translatedString(configuration.value.value);
  });

  return {
    theme,
    label,
  };
};
