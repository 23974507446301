import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  DocumentDto,
  DocumentFinItemDto,
  SafeBagDto,
} from '@/Model/Entity';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {useCashAmountValidatorStore} from '@/Modules/Core/store/CashAmountValidatorStore';
import {
  filter,
  map,
  sumBy,
} from 'lodash-es';
import {workflowStepMixinSaveFinDocument} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinSaveFinDocument';
import {SignalRErrors} from '@/Helpers/signalR';
import {fixDecimals} from '@/Helpers/math';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {DocumentTypes} from '@/constants/documentTypes';

export class WorkflowStepApiSafeBagSend extends workflowStepMixinSaveFinDocument(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiSafeBagSend;
  }

  get type() {
    return WorkflowStepApiSafeBagSend.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get deliverySafeBags(): Array<{safeBag: SafeBagDto, scanned: boolean}> {
    return map(this.getFieldValue(WorkflowStepField.safeBagDeliveryList, []), (listItem) => ({
      safeBag: new SafeBagDto(listItem.safeBag),
      scanned: listItem.scanned,
    }));
  }

  get scannedDeliverySafeBags() {
    return filter(this.deliverySafeBags, {scanned: true});
  }

  async beforeEnter() {
    const cashAmountValidatorStore = useCashAmountValidatorStore();

    if (this.stepInit) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    const transaction = this.configurationStore.financialTransactionsByCode.value[this.step.transactionCode];

    const payment = this.configurationStore.createPayment(transaction.paymentId);
    const amount = fixDecimals(sumBy(this.scannedDeliverySafeBags, 'safeBag.amount'));

    payment.setValue(amount);

    const document = DocumentDto.createFinancialDocument({
      header: {
        finDocumentCode: transaction.code,
        finDocumentName: transaction.name,
        finDocumentTransactionNumber: transaction.number,
        total: payment.value,
        source: transaction.transactionSource,
        destination: transaction.transactionDestination,
        sapTransactionCode: transaction.sapTransactionCode,
        note: this.data[WorkflowStepField.note],
        username: this.data[WorkflowStepField.name],
      },
      documentSubType: DocumentTypes.SafeBagSend,
    });

    document.addPayment(payment, {refreshTotalPrice: false});

    if (amount) {
      document.finItems = map(this.scannedDeliverySafeBags, (item) => new DocumentFinItemDto({
        valueBeforeDiscounts: item.safeBag.amount,
        quantity: 1,
        currency: item.safeBag.currency,
        envelopeNumber: item.safeBag.envelopeNumber,
      }));
    }

    let finDocumentResult: Awaited<ReturnType<WorkflowStepApiSafeBagSend['saveFinDocument']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      finDocumentResult = await this.saveFinDocument(document);

      finDocumentResult.result = new PrinterResult(finDocumentResult.result ?? {});

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      if (!finDocumentResult?.result?.hasError) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));
      }
    } finally {
      await (async () => {
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));

        if (!finDocumentResult?.created) {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          return;
        }

        if (isActiveFeaturePrintDisplayOnScreen() && finDocumentResult?.result?.hasValidPrintContent) {
          await this.printContentStore.open(finDocumentResult.result.printContent);
        }

        this.stepInit = true;

        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));

        if (this.code !== WorkflowCodes.financialReport) {
          cashAmountValidatorStore.validate();
        }
      })();
    }
  }

  get transitions() {
    return {};
  }
}
