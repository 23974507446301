
import {
  computed,
  defineComponent,
} from 'vue';
import {useFilters} from '@/Helpers/filters';
import KorunkaLotteryCardDtoCustom from '../../../../Model/Entity/custom/KorunkaLotteryCardDtoCustom';
import {useKorunkaSyncTarget} from '../../syncTarget/KorunkaSyncTarget';
import {WorkflowStepTypes} from '../../../Workflow/types';
import {Languages} from '@/constants/languages';

export default defineComponent({
  setup() {
    const {interpolate, numberFormat: originalNumberFormat} = useFilters();
    const korunkaSyncTarget = useKorunkaSyncTarget();

    const currentStepType = computed(() => korunkaSyncTarget.stepType.value);

    const isStepOverview = computed(() => currentStepType.value === WorkflowStepTypes.KorunkaOverview);

    const entity = computed(() => korunkaSyncTarget.entity.value as KorunkaLotteryCardDtoCustom);

    const messages = computed(() => korunkaSyncTarget.messages.value);

    const numberFormat = (val) => originalNumberFormat(
      val,
      {useGrouping: true, minimumFractionDigits: 0},
      Languages.CS,
    );

    return {
      currentStepType,
      entity,
      messages,
      interpolate,
      numberFormat,
      isStepOverview,
      korunkaSyncTarget,
    };
  },
});
