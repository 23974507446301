import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class TicketSellDtoCustom extends createEntity({
  ticketPrice: {
    type: new StringType(),
  },
  issueNumber: {
    type: new StringType(),
  },
  issueName: {
    type: new StringType(),
  },
}) {}
