import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const UpdateCustomerContactDtoPropDef = {
  type: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
  label: {
    type: new StringType(),
  },
};
export default class GeneratedUpdateCustomerContactDto extends createEntity(UpdateCustomerContactDtoPropDef) {}
