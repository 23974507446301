import {markRaw, nextTick} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {broadcastIO, BroadcastIOChannels} from '../../../../Helpers/broadcastIO';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';

export class WorkflowStepKorunkaLotterySetup extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaSetup;
  }

  get type() {
    return WorkflowStepKorunkaLotterySetup.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    this.ensureWorkflowEntity();

    broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'korunka-default'});

    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {};
  }
}
