
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useFilters} from '@/Helpers/filters';
import {useEshopOrders} from '@/Modules/Apps/EshopOrders/eshopOrders';

export default defineComponent({
  name: 'StepEnterNote',
  props: {
    order: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterNote;
    const {dateTimeFormat, phoneFormat} = useFilters();
    const {
      translateOrderStatus,
      orderStatusColor,
    } = useEshopOrders();

    return {
      stepType,
      WorkflowStepField,
      phoneFormat,
      dateTimeFormat,
      translateOrderStatus,
      orderStatusColor,
    };
  },
});
