import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export default class GeneratedShop extends createEntity({
  city: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  country: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  id: {
    type: new StringType(),
  },
  lat: {
    type: new NumberType(),
  },
  lon: {
    type: new NumberType(),
  },
  name: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
  street: {
    type: new StringType(),
  },
  zip: {
    type: new StringType(),
  },
}) {}
