import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ClubAccountTransactionsDto from '../ClubAccountTransactionsDto';


export const AccountTransactionClubGroupsDtoPropDef = {
  data: {
    type: new ArrayType(new EntityType(ClubAccountTransactionsDto)),
  },
};
export default class GeneratedAccountTransactionClubGroupsDto extends createEntity(AccountTransactionClubGroupsDtoPropDef) {}
