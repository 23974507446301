export const useVibrate = () => {
  const isSupported = typeof window.navigator !== 'undefined' && 'vibrate' in window.navigator;

  // Vibrate for 50ms
  const vibrate = (pattern: VibratePattern = 50) => {
    if (isSupported) {
      navigator!.vibrate(pattern);
    }
  };

  return {isSupported, vibrate};
};
