

import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import {useRouter} from 'vue-router';

import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import ModalAlert from '@/Modules/CustomerExternal/Alert/ModalAlert.vue';

export default defineComponent({
  name: 'LayoutCustomerExternal',
  components: {ModalAlert},
  setup() {
    const router = useRouter();
    const coreStore = useCoreStore();

    const onRouteChange = (event: CustomEvent) => {
      router.push(event.detail);
    };

    const onRouteGet = (event: CustomEvent) => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_GET, router.currentRoute.value);
    };

    const onAppLoadingChange = (event: CustomEvent<boolean>) => {
      coreStore.setLoader(event.detail);
    };

    onMounted(() => {
      broadcastIO.addEventListener(BroadcastIOChannels.ROUTE_CHANGE, onRouteChange);
      broadcastIO.addEventListener(BroadcastIOChannels.APP_LOADING, onAppLoadingChange);
      broadcastIO.addEventListener(BroadcastIOChannels.ROUTE_GET, onRouteGet);
      broadcastIO.postMessage(BroadcastIOChannels.INIT, router.currentRoute.value);
    });

    onBeforeUnmount(() => {
      broadcastIO.removeEventListener(BroadcastIOChannels.ROUTE_CHANGE, onRouteChange);
      broadcastIO.removeEventListener(BroadcastIOChannels.APP_LOADING, onAppLoadingChange);
      broadcastIO.removeEventListener(BroadcastIOChannels.ROUTE_GET, onRouteGet);
    });
  },
});
