import {createEntity, StringType} from '@designeo/apibundle-js';
import {ClientDataPartnerResourcePropDef} from '../generated/ClientDataPartnerResource';

export default class KorunkaLotteryCardDtoCustom extends createEntity({
  ...ClientDataPartnerResourcePropDef,
  id: {
    type: new StringType(),
  },
}) {
  get fullName() {
    return [this.firstname, this.lastname].join(' ');
  }

  get kbMonthPlace() {
    return this.korunkarMesicePlace;
  }

  get kbMonthAmount() {
    return this.korunkarMesiceAmountKb;
  }

  get kbYearPlace() {
    return this.korunkarRokuPlace;
  }

  get kbYearAmount() {
    return this.korunkarRokuAmountKb;
  }
}
