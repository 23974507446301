
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {HLInputSelect} from '@designeo/vue-forms/src/Input/InputSelect';

export default defineComponent({
  components: {
    HLInputSelect,
  },
  props: {
    options: {
      type: Array as PropType<Array<{ id: any, label: string }>>,
      required: true,
    },
    modelValue: {
      type: null,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const innerModel = computed({
      get: () => {
        return props.modelValue;
      },
      set: (val) => {
        emit('update:modelValue', val);
      },
    });

    return {
      innerModel,
    };
  },
});
