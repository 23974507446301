
import IconCube from '@/Components/Icons/IconCube.vue';
import IconLoaderPulse from '@/Components/Icons/IconLoaderPulse.vue';
import {HLPersistScroll} from '@/Components/HLPersistScroll';
import {useStockInStores} from '@/Components/ModalStockInStores/stockInStores';
import ModalStockInStoresBodyTable from '@/Components/ModalStockInStores/ModalStockInStoresBodyTable.vue';

export default {
  name: 'StockInStoresBody',
  components: {
    ModalStockInStoresBodyTable,
    HLPersistScroll,
    IconCube,
    IconLoaderPulse,
  },
  setup() {
    const {
      modalRef,
      isLoading,
      showKeyboard,
      fetchResult,
      fetchError,
      inputBufferMeetLength,
      minProductSearchLength,
    } = useStockInStores();

    return {
      modalRef,
      isLoading,
      showKeyboard,
      fetchResult,
      fetchError,
      inputBufferMeetLength,
      minProductSearchLength,
    };
  },
};
