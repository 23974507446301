export const useIconAttrs = (attrs) => {
  return {
    hasActiveAttribute: (attr) => {
      const hasAttr = Object.prototype.hasOwnProperty.call(attrs, attr);

      if (!hasAttr) return false;

      if (attrs[attr] === '') return true;

      return attrs[attr];
    },
  };
};
