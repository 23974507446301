
import {
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['close'],
  setup(props, {emit}) {
    const onClose = () => emit('close');

    return {
      onClose,
    };
  },
});
