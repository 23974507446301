
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import Html from '@/Components/Html.vue';
import {useFilters} from '@/Helpers/filters';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepManualCheckTotalAmount',
  components: {WorkflowTransitions,
    Html,
    HLStep},
  setup() {
    const {currencyFormat} = useFilters();
    const stepType = WorkflowStepTypes.ManualCheckTotalAmount;

    return {
      stepType,
      WorkflowStepField,
      currencyFormat,
    };
  },
});
