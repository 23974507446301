import {Currencies} from '@/constants/currencies';

export enum PaymentCodes {
  cashCzk = 'CASH_CZK',
  cashEur = 'CASH_EUR',
  creditCardCzk = 'CARD_CZK',
  creditCardEur = 'CARD_EUR',
  coupon = 'COUPON',
  check = 'CHECK',
}

export const defaultPaymentsByLocalCurrency = {
  [Currencies.CZK]: {
    card: PaymentCodes.creditCardCzk,
    cash: PaymentCodes.cashCzk,
  },
  [Currencies.EUR]: {
    card: PaymentCodes.creditCardEur,
    cash: PaymentCodes.cashEur,
  },
};
