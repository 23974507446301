
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';
import PhIcon from '@/Components/PhIcon.vue';
import Button from '@/Components/Button.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {
  WorkflowStepSafeBagScanSafeBags,
} from '@/Modules/Workflow/Step/StepSafeBagScanSafeBags/WorkflowStepSafeBagScanSafeBags';


export default defineComponent({
  name: 'StepSafeBagScanSafeBags',
  components: {
    AsyncButton,
    Button,
    PhIcon,
    WorkflowTransitions,
    FormInput,
    HLInput,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.SafeBagScanSafeBags;
    const coreStore = useCoreStore();
    const {workflowStep} = useStepTools<WorkflowStepSafeBagScanSafeBags>(stepType);
    const {currencyFormat} = useFilters();

    const skipRestSafeBags = async () => {
      if (await coreStore.confirm(workflowStep.value.step.skipScanningSafeBagsConfirm)) {
        await workflowStep.value.skipRestSafeBags();
      }
    };

    const canBeSkipped = computed(() => {
      if (workflowStep.value.manualMode) {
        return false;
      }

      if (workflowStep.value.everySafeBagScanned) {
        return false;
      }

      return workflowStep.value.scannedTotal >= 1;
    });

    const canBeConfirmed = computed(() => {
      if (workflowStep.value.manualMode) {
        return !!workflowStep.value.deliverySafeBags.length;
      }

      return !workflowStep.value.disabledNextStep;
    });

    return {
      stepType,
      WorkflowStepField,
      currencyFormat,
      skipRestSafeBags,
      canBeSkipped,
      canBeConfirmed,
    };
  },
});
