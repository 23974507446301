import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {
  CashAmountValidatorActions,
  CashAmountValidatorEvents,
  CashAmountValidatorInputEvent,
  CashAmountValidatorState,
} from '../types';
import CashStatesDto from '@/Model/Entity/CashStatesDto';
import {apiStockGetCashState} from '@/Model/Action';

export interface ICashAmountValidatorStore {
  state: CashAmountValidatorState
  validation: {
    cashStates: CashStatesDto,
    resolve: ()=> void,
    reject?: (reason?: any)=> void,
  },
}

const createInitState = (data?: Partial<ICashAmountValidatorStore>) => ({
  state: CashAmountValidatorState.CLOSED,
  validation: null,
});

export class CashAmountValidatorStore extends Store<ICashAmountValidatorStore> {
  constructor() {
    super(createInitState());
  }

  transitions: {[key in CashAmountValidatorState]?: {[key in CashAmountValidatorActions]?: any}} = {
    [CashAmountValidatorState.CLOSED]: {
      [CashAmountValidatorActions.OPEN]: action(
        async (event: CashAmountValidatorInputEvent<ICashAmountValidatorStore['validation']>) => {
          this.state.validation = event.value;
          this.state.state = CashAmountValidatorState.OPENED;
        },
      ),
    },
    [CashAmountValidatorState.OPENED]: {
      [CashAmountValidatorActions.CLOSE]: action(async () => {
        this.state.validation?.resolve?.();
        this.state.state = CashAmountValidatorState.CLOSED;
        this.dispatchEvent(new Event(CashAmountValidatorEvents.CLOSED));
      }),
    },
  }

  validate = action(async () => {
    let resolve = null;

    await this.ensureClosedState();

    const cashStates = await apiStockGetCashState({});

    if (!cashStates.hasExceededCashAmount) return Promise.resolve();

    const promise = (new Promise<void>((onFulfilled) => {
      resolve = () => onFulfilled();
    }))
      .then((result) => {
        this.state.validation = null;
        return result;
      })
      .catch((err) => {
        this.state.validation = null;
        throw err;
      });


    await this.onEventInput({
      type: CashAmountValidatorActions.OPEN,
      value: {
        cashStates,
        resolve,
      },
    });

    return Promise.resolve(promise);
  })

  ensureClosedState = action(async () => {
    if (this.isActive.value) {
      await this.onEventInput({
        type: CashAmountValidatorActions.CLOSE,
      });
    }
  })

  terminate = action(async () => {
    await this.ensureClosedState();
  })

  onEventInput = action(async (event: CashAmountValidatorInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  isActive = getter(() => {
    return this.state.state === CashAmountValidatorState.OPENED;
  })

  validation = getter(() => {
    return this.state.validation;
  })
}

const storeIdentifier = 'CashAmountValidatorStore';

export const configureCashAmountValidatorStore = createConfigureStore<typeof CashAmountValidatorStore>(storeIdentifier);
export const useCashAmountValidatorStore = createUseStore(CashAmountValidatorStore, storeIdentifier);
