import * as yup from 'yup';
import {Currency} from '@/Model/Entity';
import {nominals} from './nominals';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export const validationBirthdate: yup.TestConfig = {
  name: 'birthdate',
  message: 'validations.string.birthdate',
  test: (value: string, context) => {
    if ((value ?? '') === '') return true;

    const min = 1900;
    const max = new Date().getUTCFullYear() - 18;
    const year = parseInt(value, 10);

    if (year >= min && year <= max) {
      return true;
    } else {
      return context.createError({
        params: {
          min,
          max,
        },
      });
    }
  },
};

export const basicPhoneValidationRegExp = /^\+\d{9,}$/;

export const validationPhone = (phoneRegExp: RegExp = basicPhoneValidationRegExp): yup.TestConfig => {
  return {
    name: 'phone',
    message: 'validations.string.phone',
    test: (value: string) => {
      if ((value ?? '') === '' || !phoneRegExp) return true;

      return phoneRegExp.test(value);
    },
  };
};

export const validationInvalidNominalsMinValue = (
  currency: Currency,
  {
    acceptZero = false,
    acceptNegative = false,
    acceptableNominals = currency.acceptableNominals,
    smallestAcceptedNominal = currency.smallestAcceptedNominal,
  } = {},
): yup.TestConfig => {
  smallestAcceptedNominal = smallestAcceptedNominal ?? 0.01;
  acceptableNominals = acceptableNominals ?? [smallestAcceptedNominal];
  return {
    name: 'invalidNominalsMinValue',
    message: 'validations.string.invalidNominalsMinValue',
    test: (value: string, context) => {
      const val = acceptNegative ? Math.abs(parseFloat(value || '0')) : parseFloat(value || '0');

      if (!currency) return true;

      const nominalsDecomposition = nominals(val, acceptableNominals);

      if (val >= smallestAcceptedNominal && nominalsDecomposition.reminder === 0) {
        return true;
      } else if (val < smallestAcceptedNominal) {
        return acceptZero && val === 0 ? true : context.createError({
          message: 'validations.string.invalidNominalsMinValue',
          params: {
            limit: smallestAcceptedNominal,
          },
        });
      } else if (nominalsDecomposition.reminder !== 0) {
        return context.createError({
          message: 'validations.string.invalidNominalsDecomposition',
          params: {
            reminder: nominalsDecomposition.reminder,
          },
        });
      }
    },
  };
};

export const createMaxFinDocumentAmountValidation = (total, {required = false} = {}) => {
  return (value, context) => {
    if (!value && !required) return true;

    const {currencyFormat} = useFilters();
    const {configuration} = useConfigurationStore();

    const maxFinDocumentAmount = configuration.value?.features?.transactionLimits?.maxFinDocumentAmount;

    if (maxFinDocumentAmount && total > maxFinDocumentAmount) {
      return context.createError({
        message: 'validations.string.transactionLimitExceeded',
        params: {
          limit: currencyFormat(maxFinDocumentAmount),
          value: currencyFormat(total),
        },
      });
    }

    return true;
  };
};

export const commonWfFieldValidations = ({
  regExp = null,
  maxLength = null,
  minLength = null,
  length = null,
  required = null,
} = {}): yup.TestConfig => {
  return {
    test: async (value: string, context) => {
      if (required) {
        try {
          await yup.string()
            .required()
            .validate(value);
        } catch (e) {
          return context.createError({
            message: e.message,
          });
        }
      }

      if (!value) return true;

      if (regExp && !new RegExp(regExp.pattern, regExp.flags).test(value)) {
        return context.createError({
          message: regExp.errorMessage,
        });
      }

      if (length) {
        try {
          await yup.string()
            .length(length)
            .validate(value);
        } catch (e) {
          return context.createError({
            message: e.message,
            params: {length},
          });
        }
      }

      if (minLength) {
        try {
          await yup.string()
            .min(minLength)
            .validate(value);
        } catch (e) {
          return context.createError({
            message: e.message,
            params: {min: minLength},
          });
        }
      }

      if (maxLength) {
        try {
          await yup.string()
            .max(maxLength)
            .validate(value);
        } catch (e) {
          return context.createError({
            message: e.message,
            params: {max: maxLength},
          });
        }
      }

      return true;
    },
  };
};

export const wfMaxLengthValidation = () => commonWfFieldValidations({maxLength: 10});

export const toYupSchema = (pojo = {}) => {
  return yup.object(Object.entries(pojo).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && !('__isYupSchema__' in value)) {
      acc[key] = toYupSchema(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {}));
};
