import {AbstractPromoFlow, createNewReactive} from './AbstractPromoFlow';
import {PromoInteractionFlowTypes} from '@/Modules/Register/PromoInteractionFlow/index';
import {AppLoaderEvent} from './../../Core/types';
import {RegisterState} from './../types';
import {useRegisterStore} from './../store/RegisterStore';
import {DocumentDto, DocumentItemDto} from '@/Model/Entity';

import {v4 as uuidv4} from 'uuid';
import {
  PromotionMetaType,
  DocumentGiftPool,
  DocumentGiftSelected,
  GiftPoolArticleIdentifier,
  PromotionQuestionQuestionee,
  promotionByType,
  promotionByTypeThatMatch,

} from '@designeo/pos-promotion-engine';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {
  map,
  flatten,
  filter,
  find,
  isNil,
  every,
  reject,
  remove,
} from 'lodash-es';
import {PromoRemoved} from '@designeo/pos-promotion-engine/src/blocks/types';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {first} from '@designeo/pos-promotion-engine/src/utils';

export type GiftPoolWithAvailableGifts = DocumentGiftPool & {
  availableGifts: {
    gift: DocumentGiftPool['articles'][0],
    documentItem: DocumentItemDto,
  }[]
}

export type GiftPoolWithAvailableGiftsSerialized = DocumentGiftPool & {
  giftAdditionalInfo: GiftAdditionalInfoMap,
  availableGifts: {
    gift: DocumentGiftPool['articles'][0],
    documentItem: ReturnType<DocumentItemDto['toJson']>,
  }[]
}

export type GiftConsequences = {
  [key: DocumentItemDto['_data']['internalNumber']]: PromoRemoved['items']
}


export type GiftAdditionalInfo = {
  unitPrice: number,
  bulkPointsPrice: number,
  quantitySelected: number,
}

export type GiftAdditionalInfoMap = {
  [key: DocumentItemDto['_data']['internalNumber']]: GiftAdditionalInfo
}

export class GiftSelectionFlow extends AbstractPromoFlow {
  public availableGifts: GiftPoolWithAvailableGifts['availableGifts'] = null;
  public giftConsequences: GiftConsequences = {};
  public giftAdditionalInfo: GiftAdditionalInfoMap = {};
  private articleCache = new Map();

  constructor(
    public gifts: DocumentGiftPool,
    public nextState: RegisterState,
  ) {
    super(nextState);
  }

  static new = createNewReactive<GiftSelectionFlow>();

  get isForCustomer() {
    return this.gifts?.questionee === PromotionQuestionQuestionee.CUSTOMER ||
      this.gifts?.questionee === PromotionQuestionQuestionee.CUSTOMER_AND_CASHIER;
  }

  get isForCashier() {
    return this.gifts?.questionee === PromotionQuestionQuestionee.CASHIER ||
      this.gifts?.questionee === PromotionQuestionQuestionee.CUSTOMER_AND_CASHIER;
  }

  async init() {
    if (!this.isForCustomer) return super.init();
    await super.init({
      broadcastIO: {
        [BroadcastIOChannels.GIFT_SELECTION_REQUEST]: () => {
          this.broadcastGiftSelection();
        },
        [BroadcastIOChannels.GIFT_SELECTED]: ({detail: gift}) => {
          if (gift === null) {
            this.reactive.selectGift(null);
          } else {
            this.reactive.selectGift(new DocumentItemDto(gift));
          }
        },
        [BroadcastIOChannels.GIFT_UNSELECTED]: ({detail: gift}) => {
          if (gift) {
            this.reactive.unselectGift(new DocumentItemDto(gift));
          }
        },
      },
    });
    this.broadcastGiftSelection();
  }

  broadcastGiftSelection() {
    const msg: GiftPoolWithAvailableGiftsSerialized = {
      ...this.gifts,
      giftAdditionalInfo: this.giftAdditionalInfo,
      availableGifts: (this.availableGifts ?? []).map(({gift, documentItem}) => ({
        gift,
        documentItem: documentItem.toJson(),
      })),
    };

    broadcastIO.postMessage(BroadcastIOChannels.GIFT_OPTIONS_CHANGED, msg);
    emitTestEvent(`${TestEvent.GIFT_OPTIONS_CHANGED}:update`);
  }

  async destroy() {
    useRegisterStore().onPromoRemovedSeen();
    await super.destroy();
    emitTestEvent(`${TestEvent.GIFT_OPTIONS_CHANGED}:destroy`);
  }

  get text() {
    return this.gifts?.text;
  }

  get pointsRemaining() {
    return this.gifts?.poolPointsRemaining ?? 0;
  }

  get poolPoints() {
    return this.gifts?.poolPoints ?? 0;
  }

  getGiftSelectedPromotion(sellDocument): DocumentGiftSelected {
    return promotionByTypeThatMatch(sellDocument, PromotionMetaType.GIFT_SELECTED, {giftPool: this.gifts.giftPool});
  }

  async fetchGiftConsequences() {
    const store = useRegisterStore();
    const giftConsequences = {};
    const giftAdditionalInfo = {};

    for (const gift of this.availableGifts) {
      const localSellDocument = store.sellDocument.value.clone();
      const giftSelectedPromotion = await this.ensureGiftSelectedPromotion(localSellDocument);
      const existingQuantity = this.getGiftSelectionItem(giftSelectedPromotion, gift.documentItem)?.quantity ?? 0;
      const inserted = await this.upsertGift(giftSelectedPromotion, gift.documentItem);
      const {document} = await DocumentDto.promoEngine.processDocument(localSellDocument, null);
      const promoRemoved = first(promotionByType(document, PromotionMetaType.PROMO_REMOVED));
      if (promoRemoved) {
        giftConsequences[gift.documentItem.internalNumber] = promoRemoved.items;
      }

      // item might not be there if you don't have enough points
      const item = document.items.find(({uniqueIdentifier}) => uniqueIdentifier === inserted.uniqueIdentifier);
      giftAdditionalInfo[gift.documentItem.internalNumber] = {
        unitPrice: item?.priceAfterItemDiscounts,
        bulkPointsPrice: gift.gift.bulkPointPrice,
        quantitySelected: existingQuantity,
      };
    }

    this.giftAdditionalInfo = giftAdditionalInfo;
    this.giftConsequences = giftConsequences;
  }

  async confirmGiftConsequences(gift: DocumentItemDto) {
    const store = useRegisterStore();

    store.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      let giftSelectedPromotion = await this.ensureGiftSelectedPromotion(store.sellDocument.value);
      this.upsertGift(giftSelectedPromotion, gift);
      await store.fetchPromoEngineResult({triggerImmediatePromotions: false, ignorePromoRemovals: true});

      giftSelectedPromotion = await this.ensureGiftSelectedPromotion(store.sellDocument.value);
      gift.quantity *= -1;
      this.upsertGift(giftSelectedPromotion, gift);

      await this.updateGifts();
    } catch (err) {
      console.error('Gift consequence confirm failed', err);
    } finally {
      store.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async updateGifts() {
    const store = useRegisterStore();
    const document = await store.fetchPromoEngineResult({triggerImmediatePromotions: false, ignorePromoRemovals: true});
    const gifts = <DocumentGiftPool>document.promotions.find((promo) =>
      promo.type === PromotionMetaType.GIFT_POOL && promo.giftPool === this.gifts.giftPool,
    );
    if (!gifts || (this.getGiftSelectedPromotion(store.sellDocument.value)?.selectionFinished ?? false)) {
      await this.destroy();
    } else {
      this.gifts = gifts;
      await this.findAvailableGifts();
      this.broadcastGiftSelection();
    }
  }

  getGiftSelectionItem(giftSelectedPromotion: DocumentGiftSelected, gift: DocumentItemDto) {
    return find(
      giftSelectedPromotion.selected,
      ({internalNumber, gtin}) => gift.internalNumber === internalNumber && gift.gtin === gtin,
    );
  }

  async upsertGift(giftSelectedPromotion: DocumentGiftSelected, gift: DocumentItemDto) {
    const existingGift = this.getGiftSelectionItem(giftSelectedPromotion, gift);
    if (existingGift) {
      existingGift.quantity += gift.quantity;
      return existingGift;
    } else {
      const selectedItem: typeof giftSelectedPromotion.selected[0] = {
        uniqueIdentifier: uuidv4(),
        ...gift.clone().toJson(),
      };
      // @ts-ignore
      giftSelectedPromotion.selected.push(selectedItem);
      return selectedItem;
    }
  }

  async ensureGiftSelectedPromotion(sellDocument) {
    let giftSelectedPromotion: DocumentGiftSelected = this.getGiftSelectedPromotion(sellDocument);

    if (!giftSelectedPromotion) {
      giftSelectedPromotion = {
        type: PromotionMetaType.GIFT_SELECTED,
        giftPool: this.gifts?.giftPool,
        selectionFinished: false,
        pointsRemaining: this.gifts.poolPointsRemaining,
        selected: [],
      };
      sellDocument.promotions.push(giftSelectedPromotion);
    }
    return this.getGiftSelectedPromotion(sellDocument);
  }

  async changeGiftQuantity(document: DocumentDto, gift: DocumentItemDto, diff: number) {
    const giftSelectedPromotion = await this.ensureGiftSelectedPromotion(document);
    let existingGift: typeof giftSelectedPromotion.selected[0] = find(
      giftSelectedPromotion.selected,
      ({internalNumber, gtin}) => gift.internalNumber === internalNumber && gift.gtin === gtin,
    );
    if (!existingGift) {
      existingGift = {
        uniqueIdentifier: uuidv4(),
        ...gift.clone().toJson(),
        quantity: 0,
      };
      // @ts-ignore
      giftSelectedPromotion.selected.push(existingGift);
    }
    existingGift.quantity += diff;
    if (existingGift.quantity <= 0) {
      remove(giftSelectedPromotion.selected, existingGift);
    }
  }

  async unselectGift(gift: DocumentItemDto) {
    const store = useRegisterStore();
    store.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      await this.changeGiftQuantity(store.sellDocument.value, gift, -gift.quantity);
      await this.updateGifts();
    } catch (err) {
      console.error('Gift selection failed', err);
      await this.destroy();
    } finally {
      store.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async selectGift(gift: DocumentItemDto|null, selectionFinished = false) {
    const store = useRegisterStore();
    store.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      const giftSelectedPromotion = await this.ensureGiftSelectedPromotion(store.sellDocument.value);
      if (gift) {
        await this.changeGiftQuantity(store.sellDocument.value, gift, gift.quantity);
        if (selectionFinished) {
          giftSelectedPromotion.selectionFinished = true;
        }
      } else {
        giftSelectedPromotion.selectionFinished = true;
      }
      await this.updateGifts();
    } catch (err) {
      console.error('Gift selection failed', err);
      await this.destroy();
    } finally {
      store.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async rejectGift() {
    return this.selectGift(null);
  }

  async findArticle(article: DocumentGiftPool['articles'][0]) {
    const store = useRegisterStore();
    const cacheKey = ({identifierType, identifier}) => `${identifierType}-${identifier}`;
    if (this.articleCache.has(cacheKey(article))) {
      return this.articleCache.get(cacheKey(article));
    }
    if (article.identifierType === GiftPoolArticleIdentifier.INTERNAL_NUMBER_PREFIX) {
      try {
        const results = (await store.searchSubject(article.identifier)).documentItems
          .filter(({internalNumber}) => (internalNumber ?? '').startsWith(article.identifier));
        this.articleCache.set(cacheKey(article), results);
        return results;
      } catch (err) {
        console.warn('Trying to gift an unknown article: internalNumber ==', article.identifier);
      }
    } else if (article.identifierType === GiftPoolArticleIdentifier.PROMOTION_LIST) {
      try {
        const results = (await store.searchByPromotionList(article.identifier));
        this.articleCache.set(cacheKey(article), results);
        return results;
      } catch (err) {
        console.warn('Trying to gift an unknown article: promotionList ==', article.identifier);
      }
    }
    return [];
  }

  async findAvailableGifts() {
    const results = flatten(await Promise.all(map(this.gifts?.articles ?? [], async (article) => {
      const results = await this.findArticle(article);
      if (results.length === 0) {
        return null;
      }
      return map(results, (foundItem) => {
        const item = foundItem ?? new DocumentItemDto({});
        item.quantity = article.bulkQuantity;
        item['_data']['promoCode'] = article.promoCode;
        item.sanitize();
        return {gift: article, documentItem: item};
      });
    })));
    this.availableGifts = filter(results, (availableGift) => {
      if (isNil(availableGift)) {
        return false;
      }
      if (isNil(availableGift.documentItem)) {
        return false;
      }
      // #35620
      if (
        availableGift.documentItem.netto &&
        !isNil(availableGift.gift.unitPrice) &&
        availableGift.documentItem.priceAction !== availableGift.gift.unitPrice
      ) {
        return false;
      }
      // #35927
      if (availableGift.documentItem.prep) {
        return false;
      }
      return true;
    });

    if (this.gifts.allowWithoutSelection && this.availableGifts.length === 1) {
      const {documentItem, gift} = this.availableGifts[0];
      const item = documentItem.clone();
      item.quantity = Math.floor(gift.bulkQuantity * (this.gifts.poolPointsRemaining / gift.bulkPointPrice));
      this.selectGift(item, true);
    } else if (
      this.availableGifts.length === 0 ||
      every(this.availableGifts, (gift) => gift.gift.bulkPointPrice > this.gifts.poolPointsRemaining)
    ) {
      this.selectGift(null, true);
    } else {
      await this.fetchGiftConsequences();
      this.broadcastGiftSelection();
    }
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.GIFT_SELECTION_FLOW,
      nextState: this.nextState,
      gifts: this.gifts,
      giftAdditionalInfo: this.giftAdditionalInfo,
      availableGifts: this.availableGifts ? this.availableGifts.map(({documentItem, gift}) => ({
        gift,
        documentItem: documentItem.toJson(),
      })) : null,
    };
  }

  static deserialize(ctx) {
    if (ctx === null) {
      return null;
    }
    const {
      gifts,
      availableGifts,
      nextState,
      giftAdditionalInfo,
    } = ctx;

    return AbstractPromoFlow.baseDeserialize(GiftSelectionFlow, {
      giftAdditionalInfo: giftAdditionalInfo,
      availableGifts: availableGifts ? availableGifts.map(({documentItem, gift}) => ({
        documentItem: new DocumentItemDto(documentItem),
        gift,
      })) : null,
    }, [
      gifts,
      nextState,
    ]);
  }

  toString() {
    return `<GiftSelectionFlow(${this.gifts?.giftPool})>`;
  }
}
