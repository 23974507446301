import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import WarehouseType from '../WarehouseType';


export const StockFilterOnlyLoadedArticlesDtoPropDef = {
  onlyNegative: {
    type: new BooleanType(),
  },
  onlySales: {
    type: new BooleanType(),
  },
  warehouseTypes: {
    type: new ArrayType(new EntityType(WarehouseType)),
  },
};
export default class GeneratedStockFilterOnlyLoadedArticlesDto extends createEntity(StockFilterOnlyLoadedArticlesDtoPropDef) {}
