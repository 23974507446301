import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow py-0" }
const _hoisted_2 = {
  class: "flex-grow overflow-y-scroll pr-2",
  style: {"max-height":"495px"}
}
const _hoisted_3 = { class: "text-lg font-normal" }
const _hoisted_4 = {
  key: 0,
  class: "text-basic-light"
}
const _hoisted_5 = { class: "grid grid-cols-5 text-lg gap-y-2" }
const _hoisted_6 = { class: "col-span-3" }
const _hoisted_7 = { class: "col-span-2 text-right" }
const _hoisted_8 = {
  key: 0,
  class: "text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_FormInputNominal = _resolveComponent("FormInputNominal")!
  const _component_HLScrollIntoView = _resolveComponent("HLScrollIntoView")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_HLStep = _resolveComponent("HLStep")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workflowStep.formInputs, ({field, label, amount}, idx) => {
            return (_openBlock(), _createElementBlock("div", { key: field }, [
              _createVNode(_component_HLInput, { field: field }, {
                default: _withCtx(({isActive, value, setActive, error}) => [
                  _createVNode(_component_HLScrollIntoView, { active: isActive }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_FormInputNominal, {
                        active: isActive,
                        error: error,
                        errorOutput: 'modal',
                        size: "xs",
                        class: "my-0.5",
                        onClick: setActive
                      }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(label), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_InputBuffer, {
                            value: value.value,
                            disableCaretNavigator: ""
                          }, null, 8, ["value"])
                        ]),
                        _: 2
                      }, 1032, ["active", "error", "onClick"]), [
                        [
                          _directive_test,
                          amount,
                          void 0,
                          { amount: true }
                        ]
                      ])
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 2
              }, 1032, ["field"]),
              (idx !== workflowStep.formInputs.length - 1)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex-grow pt-4 grid", {
          'grid-cols-2': _ctx.canPrint
        }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({
            'text-danger': workflowStep.totalDifference < 0,
            'text-success': workflowStep.totalDifference === 0,
            'text-warning': workflowStep.totalDifference > 0,
          })
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(workflowStep.step.difference) + ": ", 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.currencyFormat(workflowStep.totalDifference, workflowStep.step.currency)), 1)
            ])
          ], 2),
          (_ctx.canPrint)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_AsyncButton, {
                  class: "btn-basic",
                  onClick: () => _ctx.print()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(workflowStep.step.print), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}