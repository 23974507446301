import {Constructor} from '@/Model/Entity/patches/patch';
import {DocumentDto, DocumentItemDto} from '@/Model/Entity';
import {
  flatten,
  flow,
  map,
  reduce,
} from 'lodash-es';
import {Korunka} from '../../../../types/korunka';
import {KorunkaGameEntities} from '@/Helpers/korunka';
import KorunkaChargeDto from '@/Model/Entity/KorunkaChargeDto';
import {guid} from '@/Helpers/guid';

type SessionRequest = Korunka['FacadeFinishPartnerSessionRequest'];

type BetTicket = IKorunkaEnvelope['betTickets'][number];
type RepeatBetTicket = IKorunkaEnvelope['repeatBetTickets'][number];
type PreparedTicket = IKorunkaEnvelope['preparedTickets'][number];
type CancelTicketNumber = IKorunkaEnvelope['cancelTicketNumbers'][number];
type PayOutTicket = IKorunkaEnvelope['payOutTickets'][number];

export interface IBetTicket {
  value: BetTicket
}
export interface IRepeatBetTicket {
  value: RepeatBetTicket
}
export interface IPreparedTicket {
  value: PreparedTicket
}
export interface IPayOutTicket {
  value: PayOutTicket
}
export interface ICancelTicketNumber {
  value: CancelTicketNumber
}

export interface IKorunkaEnvelope extends SessionRequest {
  bondsToEntities: {
    /**
     * [entityId]: Array<operationsIds>
     */
    [key: string]: string[]
  }
}

class ActionBase<T> {
  constructor(public value: T) {}
  toData() {
    return this.value;
  }
}


export class ActionBetTicket extends ActionBase<IBetTicket['value']> implements IBetTicket {}
export class ActionRepeatBetTicket extends ActionBase<IRepeatBetTicket['value']> implements IRepeatBetTicket {}
export class ActionPreparedTicket extends ActionBase<IPreparedTicket['value']> implements IPreparedTicket {}
export class ActionCancelTicketNumber extends ActionBase<ICancelTicketNumber['value']> implements ICancelTicketNumber {}
export class ActionPayOutTicket extends ActionBase<IPayOutTicket['value']> implements IPayOutTicket {}

export type AnyAction = ActionBetTicket |
  ActionRepeatBetTicket |
  ActionPreparedTicket |
  ActionCancelTicketNumber |
  ActionPayOutTicket

export const KorunkaPatch = <TBase extends Constructor>(superClass: TBase) =>
  class KorunkaPatch extends superClass {
    public static isTicketEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom').default;
    }

    public static isGameEntity(entity): entity is KorunkaGameEntities {
      return this.isGameSestkaEntity(entity) ||
        this.isGameDvacetZa20Entity(entity) ||
        this.isGameHopTropEntity(entity) ||
        this.isGameKombiEntity(entity) ||
        this.isGameKombiPlusEntity(entity);
    }

    public static isScanResultEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom').default;
    }

    public static isCardEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryCardDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryCardDtoCustom').default;
    }

    public static isGameSestkaEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom').default;
    }

    public static isGameDvacetZa20Entity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom').default;
    }

    public static isGameHopTropEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom').default;
    }

    public static isGameKombiEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryKombiDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryKombiDtoCustom').default;
    }

    public static isGameKombiPlusEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryKombiPlusDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryKombiPlusDtoCustom').default;
    }

    public static recordEntityBonds(envelope: IKorunkaEnvelope, entityId, operationIds: string[]) {
      envelope.bondsToEntities[entityId] = [
        ...(envelope.bondsToEntities[entityId] ?? []),
        ...operationIds,
      ];
    }

    public static processGame(envelope: IKorunkaEnvelope, game: KorunkaGameEntities) {
      if (game.isCancel) {
        envelope.finallyClearedCash += game.total;
        const actions = map(game.ticketsToCancel, (ticket) => {
          return game.toActionCancelTicket(ticket.ticketNumber).toData();
        });

        envelope.cancelTicketNumbers.push(...actions);

        KorunkaPatch.recordEntityBonds(envelope, game.id, map(actions, 'operationId'));
      } else if (game.isRepeatBetTicket) {
        envelope.finallyClearedCash -= game.total;
        const action = game.toActionRepeatBetTicket().toData();
        envelope.repeatBetTickets.push(action);

        KorunkaPatch.recordEntityBonds(envelope, game.id, [action.operationId]);
      } else if (game.isFromScan) {
        envelope.finallyClearedCash -= game.total;
        const action = game.toActionBetTicket().toData();
        envelope.betTickets.push(action);

        KorunkaPatch.recordEntityBonds(envelope, game.id, [action.operationId]);
      } else {
        envelope.finallyClearedCash -= game.total;
        const action = game.toActionBetTicket().toData();
        envelope.betTickets.push(action);

        KorunkaPatch.recordEntityBonds(envelope, game.id, [action.operationId]);
      }
    }

    public static processTicket(
      envelope: IKorunkaEnvelope,
      ticket: import('@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom').default,
    ) {
      envelope.finallyClearedCash += ticket.total * ((ticket.isWon || ticket.betsDrawState.isWin) ? 1 : -1);
      envelope.payOutTickets.push(ticket.toActionPayOutTicket().toData());
    }

    public static processScanResult(
      envelope: IKorunkaEnvelope,
      scanResult: import('@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom').default,
    ) {
      scanResult.ticketGameEntities.forEach((game) => {
        this.processGame(envelope, game);
      });
    }

    public static processEnvelopeFromContextForCheck(context: DocumentDto, item: DocumentItemDto): IKorunkaEnvelope {
      return KorunkaPatch.ensureEnvelopeFromContext(context);
    }

    public static processEnvelopeFromContextForCharge(context: DocumentDto, item: DocumentItemDto): IKorunkaEnvelope {
      return KorunkaPatch.ensureEnvelopeFromContext(context);
    }

    public static processEnvelopeFromContextForRevoke(context: DocumentDto, item: DocumentItemDto): IKorunkaEnvelope {
      if (!item.checkRequest.metadata) {
        throw new Error(`Article ${item.description} does not have korunka ch&ch metadata!`);
      }

      const itemKorunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(item.checkRequest.metadata);

      return reduce(
        context.itemsGroupedBySets,
        (envelope, group) => {
          if (!group.editableItem.isServiceTypeKorunka) {
            return envelope;
          }

          if (!group.editableItem.checkRequest.metadata) {
            throw new Error(`Article ${group.editableItem.description} does not have korunka ch&ch metadata!`);
          }

          const groupKorunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
            .deserializeKorunkaEntity(group.editableItem.checkRequest.metadata);


          if (groupKorunkaEntity.id === itemKorunkaEntity.id) {
            return envelope;
          }

          KorunkaPatch.processKorunkaEntityForEnvelope(envelope, groupKorunkaEntity);

          return envelope;
        },
        KorunkaPatch.getEmptyEnvelope(context),
      );
    }

    /**
     * Item have to be already charged!
     */
    public static resolveDocumentItemFinishedBetTickets(item): Korunka['BetTicketPartnerResponse'][] {
      const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(item.checkRequest.metadata);

      if (!KorunkaPatch.isGameEntity(korunkaEntity)) {
        return [];
      }

      return flow([
        (bonds) => bonds[korunkaEntity.id] ?? [],
        (operationIds) => map(operationIds, (operationId) => {
          return (item.chargeResponse.entity as KorunkaChargeDto)
            .korunkaResponse
            .betTicketsByOperationId
            ?.[operationId] ?? [];
        }),
        (tickets) => flatten(tickets),
      ])(
        (item.chargeRequest.korunkaRequest as IKorunkaEnvelope).bondsToEntities,
      );
    }

    public static processEnvelopeFromContextForCancel(context: DocumentDto, item: DocumentItemDto): IKorunkaEnvelope {
      if (!item.checkRequest.metadata) {
        throw new Error(`Article ${item.description} does not have korunka ch&ch metadata!`);
      }

      const envelope = KorunkaPatch.getEmptyEnvelope(context);

      envelope.requestId = guid();

      const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(item.checkRequest.metadata);

      if (KorunkaPatch.isTicketEntity(korunkaEntity)) {
        return envelope;
      } else if (KorunkaPatch.isGameEntity(korunkaEntity) && korunkaEntity.isCancel) {
        korunkaEntity.ticketsToCancel = [
          {
            ticketNumber: korunkaEntity.ticketNumber,
          },
        ];
      } else if (KorunkaPatch.isGameEntity(korunkaEntity)) {
        korunkaEntity.isCancel = true;
        korunkaEntity.ticketsToCancel = KorunkaPatch.resolveDocumentItemFinishedBetTickets(item);
      } else {
        throw new Error('Unknown entity!');
      }

      KorunkaPatch.processKorunkaEntityForEnvelope(envelope, korunkaEntity);

      return envelope;
    }

    public static ensureEnvelopeFromContext(context: DocumentDto): IKorunkaEnvelope {
      return reduce(
        context.itemsGroupedBySets,
        (envelope, group) => {
          if (!group.editableItem.isServiceTypeKorunka) {
            return envelope;
          }

          if (group.editableItem.isCanceled) {
            return envelope;
          }

          if (!group.editableItem.checkRequest.metadata) {
            throw new Error(`Article ${group.editableItem.description} does not have korunka ch&ch metadata!`);
          }

          const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
            .deserializeKorunkaEntity(group.editableItem.checkRequest.metadata);

          KorunkaPatch.processKorunkaEntityForEnvelope(envelope, korunkaEntity);

          return envelope;
        },
        KorunkaPatch.getEmptyEnvelope(context),
      );
    }

    public static getEmptyEnvelope(context: DocumentDto): IKorunkaEnvelope {
      return {
        requestId: context.header.uniqueidentifier,
        cardNumber: context?.loyalty?.externalPrograms?.korunka?.cardNumber ?? null,
        betTickets: [],
        repeatBetTickets: [],
        preparedTickets: [],
        cancelTicketNumbers: [],
        payOutTickets: [],
        finallyClearedCash: 0,
        bondsToEntities: {},
      } as IKorunkaEnvelope;
    }

    public static processKorunkaEntityForEnvelope(
      envelope: IKorunkaEnvelope,
      korunkaEntity: import('@/Helpers/korunka').KorunkaEntities,
    ) {
      if (KorunkaPatch.isGameEntity(korunkaEntity)) {
        KorunkaPatch.processGame(envelope, korunkaEntity);
      } else if (KorunkaPatch.isTicketEntity(korunkaEntity)) {
        KorunkaPatch.processTicket(envelope, korunkaEntity);
      } else if (KorunkaPatch.isScanResultEntity(korunkaEntity)) {
        KorunkaPatch.processScanResult(envelope, korunkaEntity);
      }
    }
  };
