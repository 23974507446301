import GeneratedNearbyShopStockDto from './generated/NearbyShopStockDto';

export default class NearbyShopStockDto extends GeneratedNearbyShopStockDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get shopAddress() {
    return [
      ...(this.shopStreet ? [this.shopStreet] : []),
      ...(this.shopCity ? [this.shopCity] : []),
    ].join(', ');
  }

  get unitByEnum() {
    return NearbyShopStockDto.configurationStore.unitsByCode.value
      ?.[this.unitOfQuantityCode]
      ?.text ?? this.unitOfQuantityCode;
  }
}
