import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretNavigator = _resolveComponent("CaretNavigator")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    ref: "inputText",
    class: _normalizeClass(["inline h-full", {
      [_ctx.highlightedClass]: _ctx.insertMode,
      'whitespace-pre': _ctx.multiline
    }])
  }, [
    _createTextVNode(_toDisplayString(_ctx.isOverLimit ? '...' : null) + _toDisplayString(_ctx.cursorPos ? _ctx.sanitizedValue.slice(0, _ctx.cursorPos) : null), 1),
    (_ctx.isCaret)
      ? (_openBlock(), _createBlock(_component_CaretNavigator, {
          key: 0,
          ref: "caret"
        }, null, 512))
      : _createCommentVNode("", true),
    _createTextVNode(_toDisplayString(_ctx.sanitizedValue.slice(_ctx.cursorPos)), 1)
  ], 10, _hoisted_1))
}