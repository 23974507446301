
import {
  defineComponent,
} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import IconWarning from '@/Components/Icons/IconWarning.vue';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {WorkflowStepSafeTransfer} from '@/Modules/Workflow/Step/StepSafeTransfer/WorkflowStepSafeTransfer';
import InputBuffer from '@/Components/InputBuffer.vue';

export default defineComponent({
  name: 'StepSafeTransfer',
  components: {
    InputBuffer,
    HLStep,
    HLInput,
    FormInput,
    IconCheck,
    WorkflowTransitions,
    IconWarning,
  },
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.SafeTransfer;

    const {workflowStep} = useStepTools<WorkflowStepSafeTransfer>(stepType);

    const {interpolate} = useFilters();

    const onDrawerCashStateValidation = () => {
      workflowStore.currentWorkflow.value.activeWorkflowStep.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {detail: {
          workflowCode: workflowStep.value.drawerCashStateValidationCode,
        }}),
      );
    };

    const onNext = () => workflowStep.value.finish();

    return {
      stepType,
      WorkflowStepField,
      interpolate,
      onDrawerCashStateValidation,
      onNext,
    };
  },
});
