import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-4" }
const _hoisted_2 = { class: "text-base font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalConfirmContent = _resolveComponent("ModalConfirmContent")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.isOpen,
    name: "modalPaymentConfirm",
    onClose: _ctx.onReject
  }, {
    body: _withCtx(() => [
      _createVNode(_component_ModalConfirmContent, {
        onClose: _ctx.onReject,
        onConfirm: _ctx.onConfirm,
        onReject: _ctx.onReject
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('workflow.steps.eshopPayment.confirm.title')), 1),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('workflow.steps.eshopPayment.confirm.question')), 1)
          ])
        ]),
        confirmBtn: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.eshopPayment.confirm.confirm')), 1)
        ]),
        rejectBtn: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.eshopPayment.confirm.reject')), 1)
        ]),
        _: 1
      }, 8, ["onClose", "onConfirm", "onReject"])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}