import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {
  WarehouseOperationAdditionalInfoFields,
  WarehouseOperationConfirmInfo,
  WarehouseOperations,
  WarehouseOperationStepFieldGroups,
} from '@/constants/warehouseOperations';
import {has, reduce} from 'lodash-es';
import {DocumentDto, DocumentHeaderDto} from '@/Model/Entity';

export class WorkflowStepWarehouseOperationConfirm extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationConfirm;
  }

  get type() {
    return WorkflowStepWarehouseOperationConfirm.type;
  }

  get component() {
    return markRaw(require('./StepWarehouseOperationConfirm.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get info() {
    return [
      WarehouseOperationConfirmInfo.documentSubType,
      WarehouseOperationConfirmInfo.logisticsSource,
      WarehouseOperationConfirmInfo.logisticsDestination,
      WarehouseOperationConfirmInfo.numberOfItems,
      WarehouseOperationConfirmInfo.totalAmount,
      ...(this.hasVisiblePrices ? [WarehouseOperationConfirmInfo.totalPrice] : []),
      WarehouseOperationConfirmInfo.packages,
      WarehouseOperationConfirmInfo.returnReason,
      WarehouseOperationConfirmInfo.reference,
      WarehouseOperationConfirmInfo.note,
    ];
  }

  getInfoValueByField(field: string) {
    switch (field as WarehouseOperationConfirmInfo) {
    case WarehouseOperationConfirmInfo.documentSubType:
      return this.operationEntityPropLogisticDocument.documentSubType.value;
    case WarehouseOperationConfirmInfo.logisticsSource:
      return this.logisticsSource?.name;
    case WarehouseOperationConfirmInfo.logisticsDestination:
      return this.logisticsDestination?.name;
    case WarehouseOperationConfirmInfo.numberOfItems:
      return this.operationEntityPropLogisticDocument.logisticItems.length;
    case WarehouseOperationConfirmInfo.totalAmount:
      return this.operationEntityPropLogisticDocument.logisticItems;
    case WarehouseOperationConfirmInfo.totalPrice:
      return this.operationEntity.totalPrice;
    case WarehouseOperationConfirmInfo.returnReason:
      return this.returnReasonName;
    case WarehouseOperationConfirmInfo.packages:
      return this.packages;
    case WarehouseOperationConfirmInfo.reference:
      return this.externalReference;
    case WarehouseOperationConfirmInfo.note:
      return this.note;
    }
  }

  createAdditionalInfoFieldDataPath(field: string) {
    return `${WarehouseOperationStepFieldGroups.additionalInfo}.${field}`;
  }

  get logisticsSource() {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
    case WarehouseOperations.receiptOfPrintedMatters:
    case WarehouseOperations.returnOfPrintedMatters:
    case WarehouseOperations.reloadingOfGoodsTypeReceiving:
      return this.operationEntityPropLogisticDocument.header.logisticsSource?.toJson();
    case WarehouseOperations.depreciationOfGoodsTypeConsumption:
    case WarehouseOperations.depreciationOfGoodsTypeDisposal:
      return {
        id: this.configurationStore.configuration.value?.general?.pos?.shop?.code,
        name: this.configurationStore.configuration.value?.general?.pos?.shop?.name,
        warehouse: null,
      };
    case WarehouseOperations.returnOfGoodsToDistributionCenter:
    case WarehouseOperations.returnOfGoodsToExternalSupplier:
      return {
        id: this.configurationStore.configuration.value?.general?.pos?.shop?.code,
        name: this.configurationStore.configuration.value?.general?.pos?.shop?.name,
        warehouse: null,
      };
    case WarehouseOperations.orderOfGoodsFromDistributionCenter:
      return {
        id: this.operationEntityPropDistributionCenter?.dc ??
            this.operationEntityPropVendor?.vendor,
        name: this.operationEntityPropDistributionCenter?.name ??
            this.operationEntityPropVendor?.name,
        warehouse: null,
      };
    case WarehouseOperations.receiptOfGoodsFromExternalSupplier:
      return {
        id: this.operationEntityPropVendor.vendor,
        name: this.operationEntityPropVendor.name,
        warehouse: null,
      };
    case WarehouseOperations.reloadingOfGoodsTypeDispense:
      return {
        id: this.operationEntityPropSourcePlant?.plant ??
          this.configurationStore.configuration.value?.general?.pos?.shop?.code,
        name: this.operationEntityPropSourcePlant?.plant ??
          this.configurationStore.configuration.value?.general?.pos?.shop?.name,
        warehouse: null,
      };
    }
  }

  get logisticsDestination() {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
    case WarehouseOperations.receiptOfPrintedMatters:
    case WarehouseOperations.returnOfPrintedMatters:
    case WarehouseOperations.reloadingOfGoodsTypeReceiving:
      return this.operationEntityPropLogisticDocument.header.logisticsDestination?.toJson();
    case WarehouseOperations.returnOfGoodsToDistributionCenter:
      return {
        id: this.operationEntityPropDistributionCenter.dc,
        name: this.operationEntityPropDistributionCenter.name,
        warehouse: null,
      };
    case WarehouseOperations.returnOfGoodsToExternalSupplier:
      return {
        id: this.operationEntityPropVendor.vendor,
        name: this.operationEntityPropVendor.name,
        warehouse: null,
      };
    case WarehouseOperations.depreciationOfGoodsTypeConsumption:
    case WarehouseOperations.depreciationOfGoodsTypeDisposal:
      return null;
    case WarehouseOperations.receiptOfGoodsFromExternalSupplier:
    case WarehouseOperations.orderOfGoodsFromDistributionCenter:
      return {
        id: this.operationEntityPropPlant?.plant ??
            this.configurationStore.configuration.value?.general?.pos?.shop?.code,
        name: this.operationEntityPropPlant?.name ??
            this.configurationStore.configuration.value?.general?.pos?.shop?.name,
        warehouse: null,
      };
    case WarehouseOperations.reloadingOfGoodsTypeDispense:
      return {
        id: this.operationEntityPropTargetPlant.plant,
        name: this.operationEntityPropTargetPlant.name,
        warehouse: null,
      };
    }
  }

  get externalReference() {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.receiptOfGoodsFromDistributionCenter:
    case WarehouseOperations.receiptOfPrintedMatters:
    case WarehouseOperations.returnOfPrintedMatters:
    case WarehouseOperations.reloadingOfGoodsTypeReceiving:
      return this.operationEntityPropLogisticDocument.header.externalReference;
    default:
      return this.getFieldValue(
        this.createAdditionalInfoFieldDataPath(WarehouseOperationAdditionalInfoFields.reference),
      );
    }
  }

  get returnReasonCode() {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.returnOfGoodsToDistributionCenter:
    case WarehouseOperations.returnOfGoodsToExternalSupplier:
      return this.getFieldValue(
        this.createAdditionalInfoFieldDataPath(WarehouseOperationAdditionalInfoFields.returnReason),
      );
    default:
      return null;
    }
  }

  get returnReasonName() {
    if (!this.returnReasonCode) {
      return null;
    }

    const selectedReturnReason = this.getEnumValueByStoredEnum(
      WarehouseOperationAdditionalInfoFields.returnReason,
      this.returnReasonCode,
    );

    return selectedReturnReason?.label ?? 'N/A';
  }

  get note() {
    return this.getFieldValue(this.createAdditionalInfoFieldDataPath(WarehouseOperationAdditionalInfoFields.note));
  }

  get packages() {
    return this.getFieldValue(this.createAdditionalInfoFieldDataPath(WarehouseOperationAdditionalInfoFields.packages));
  }

  get logisticOperation(): DocumentDto {
    const logisticDocument: DocumentDto = this.operationEntityPropLogisticDocument.clone();

    logisticDocument.header = new DocumentHeaderDto({
      ...(this.operationEntityPropLogisticDocument.header?.toJson() ?? {}),
      externalReference: this.externalReference,
      logisticsSource: this.logisticsSource,
      logisticsDestination: this.logisticsDestination,
      returnReasonCode: this.returnReasonCode,
      returnReasonName: this.returnReasonName,
      total: this.operationEntity.totalPrice,
      note: this.note,
      envelopeNumber: this.packages,
    });

    return logisticDocument;
  }

  async beforeContinue() {
    this.dataSetter(
      this.createAdditionalInfoFieldDataPath(WorkflowStepField.document),
      () => this.logisticOperation.toJson(),
    );
  }

  get summary() {
    const config = this.step.summary ?? {};

    return reduce(this.info, (acc, field) => {
      if (!has(config, field)) {
        return acc;
      }

      return [
        ...acc,
        {
          field,
          label: config?.[field]?.label,
          value: this.getInfoValueByField(field),
        },
      ];
    }, []);
  }

  get title() {
    return this.titleByWarehouseOperation;
  }

  get transitions() {
    return {};
  }
}
