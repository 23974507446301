import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerReceiptPointsDtoPropDef = {
  pointsBurned: {
    type: new NumberType(),
  },
  pointsCollected: {
    type: new NumberType(),
  },
  clubCode: {
    type: new StringType(),
  },
  clubName: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerReceiptPointsDto extends createEntity(CustomerReceiptPointsDtoPropDef) {}
