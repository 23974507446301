

import {useKeyboard} from '@/Helpers/keyboard';
import {useRouter} from 'vue-router';

export default {
  name: 'LayoutWithTitle',
  setup() {
    const {registerEscapeKey} = useKeyboard();
    const router = useRouter();

    if (!(router.currentRoute.value?.meta?.disableEscapeListener ?? false)) {
      registerEscapeKey(() => {
        router.go(-1);
      });
    }
  },
};
