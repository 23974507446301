import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const LogisticsLocationDtoPropDef = {
  id: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  warehouse: {
    type: new StringType(),
  },
};
export default class GeneratedLogisticsLocationDto extends createEntity(LogisticsLocationDtoPropDef) {}
