
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,

} from 'vue';
// @ts-ignore
import mainLogo from '@/assets/images/logo.svg';
import IconThreeDots from '@/Components/Icons/IconThreeDots.vue';
import {quitApp, reloadApp} from '@/Helpers/app';
import ModalCrashState from '@/Modules/Core/CrashState/ModalCrashState.vue';
import ModalPosCoreNotAvailable from '@/Modules/Core/PosCoreNotAvailable/ModalPosCoreNotAvailable.vue';
import {usePosCoreNotAvailableStore} from '@/Modules/Core/store/PosCoreNotAvailableStore';
import {AppVersion} from '@/constants/appVersion';
import {useAppVersion} from '@/Helpers/appVersion';

export default defineComponent({
  name: 'StartupPage',
  components: {
    ModalCrashState,
    ModalPosCoreNotAvailable,
    IconThreeDots,
  },
  props: {
    appVersion: {
      type: String as PropType<AppVersion>,
      required: true,
    },
    isElectron: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const posCoreNotAvailableStore = usePosCoreNotAvailableStore();
    const interval = ref(null);
    const time = ref(0);

    useAppVersion(props.appVersion);

    onMounted(() => {
      interval.value = setInterval(() => {
        time.value += 1000;
        if (time.value > 60000 * 2) {
          if (posCoreNotAvailableStore.active.value === null) {
            posCoreNotAvailableStore.setState(true);
          }
        }
      }, 1000);
    });

    onBeforeUnmount(() => {
      time.value = 0;
      posCoreNotAvailableStore.setState(null);
      clearInterval(interval.value);
    });

    const quit = () => new Promise(() => {
      quitApp();
    });

    const restart = () => new Promise(() => {
      reloadApp();
    });


    return {
      mainLogo,
      quit,
      restart,
    };
  },
});
