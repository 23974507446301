import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const TurnoverByVatDtoPropDef = {
  vatId: {
    type: new NumberType(),
  },
  sellerVatId: {
    type: new StringType(),
  },
  vatValue: {
    type: new NumberType(),
  },
  turnoverWovat: {
    type: new NumberType(),
  },
  turnoverVat: {
    type: new NumberType(),
  },
  turnover: {
    type: new NumberType(),
  },
  fiscalIndex: {
    type: new NumberType(),
  },
  sapFiscalIndex: {
    type: new StringType(),
  },
};
export default class GeneratedTurnoverByVatDto extends createEntity(TurnoverByVatDtoPropDef) {}
