import {AbstractPromoFlow, createNewReactive} from '@/Modules/Register/PromoInteractionFlow/AbstractPromoFlow';
import {RegisterState} from '@/Modules/Register/types';
import {PromoInteractionFlowTypes} from '@/Modules/Register/PromoInteractionFlow/index';
import {DynamicSetLevelsSelection, DynamicSetSelection} from '@designeo/pos-promotion-engine/src/blocks/types';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {action} from '@designeo/vue-helpers';
import {reactive} from 'vue';
import {useRegisterStore} from '../store/RegisterStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export class ContextualProductRecommendationsSelectionFlow extends AbstractPromoFlow {
  state: {
    highlightedRecommendation: string,
    displayPricePerUnit: boolean,
  } = reactive({
    highlightedRecommendation: null,
    displayPricePerUnit: false,
  });

  static new = createNewReactive<ContextualProductRecommendationsSelectionFlow>();

  static createRecommendationKey(
    recommendation: DynamicSetSelection | DynamicSetLevelsSelection,
    selection: DynamicSetSelection['selection'][number] | DynamicSetLevelsSelection['selection'][number],
  ) {
    return `${recommendation.promoCode}-${selection.key}`;
  }

  constructor(
    public recommendations: Array<DynamicSetSelection | DynamicSetLevelsSelection>,
    public nextState?: RegisterState,
  ) {
    super(nextState);
  }

  get highlightedRecommendation() {
    return this.state.highlightedRecommendation;
  }

  set highlightedRecommendation(val: string) {
    this.state.highlightedRecommendation = val;
  }

  get displayPricePerUnit() {
    return this.state.displayPricePerUnit;
  }

  set displayPricePerUnit(val: boolean) {
    this.state.displayPricePerUnit = val;
  }

  onProductRecommendationsShowPricePerUnit = action(async () => {
    this.displayPricePerUnit = true;

    this.updateProductRecommendationsState();

    await useRegisterStore().persist();
  });

  onProductRecommendationsRequest = action(() => {
    this.updateProductRecommendationsState();
  });

  onProductRecommendationHighlighted = action((
    event: CustomEvent<{
      recommendation: DynamicSetSelection | DynamicSetLevelsSelection,
      selection: DynamicSetSelection['selection'][number] | DynamicSetLevelsSelection['selection'][number],
    }>,
  ) => {
    this.highlightRecommendation(event.detail.recommendation, event.detail.selection);
  });

  highlightRecommendation = action(async (
    recommendation: DynamicSetSelection | DynamicSetLevelsSelection,
    selection: DynamicSetSelection['selection'][number] | DynamicSetLevelsSelection['selection'][number],
  ) => {
    const registerStore = useRegisterStore();

    this.highlightedRecommendation = ContextualProductRecommendationsSelectionFlow
      .createRecommendationKey(recommendation, selection);

    this.updateProductRecommendationsState();

    await registerStore.persist();
  })

  isHighlightedRecommendation = action((
    recommendation: DynamicSetSelection | DynamicSetLevelsSelection,
    selection: DynamicSetSelection['selection'][number] | DynamicSetLevelsSelection['selection'][number],
  ) => {
    return this.highlightedRecommendation === ContextualProductRecommendationsSelectionFlow
      .createRecommendationKey(recommendation, selection);
  })

  updateProductRecommendationsState() {
    const configurationStore = useConfigurationStore();
    const registerStore = useRegisterStore();

    const isUsableDisplayPricePerUnit = configurationStore.configuration.value
      ?.promoEngine
      ?.contextualProductRecommendations
      ?.customerSelect
      ?.pricePerUnitToggle ?? false;

    broadcastIO.postMessage(BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_OPTIONS, {
      recommendations: this.recommendations,
      highlightedRecommendation: this.highlightedRecommendation,
      product: registerStore.productFlow.value.product.toJson(),
      displayPricePerUnit: {
        enabled: isUsableDisplayPricePerUnit,
        active: this.displayPricePerUnit,
      },
    });
  }

  async init() {
    broadcastIO.addEventListener(
      BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_REQUEST,
      this.onProductRecommendationsRequest,
    );

    broadcastIO.addEventListener(
      BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_SHOW_PRICE_PER_UNIT,
      this.onProductRecommendationsShowPricePerUnit,
    );

    broadcastIO.addEventListener(
      BroadcastIOChannels.PRODUCT_RECOMMENDATION_HIGHLIGHTED,
      this.onProductRecommendationHighlighted,
    );

    this.updateProductRecommendationsState();

    await super.init();
  }

  async abort() {
    await this.destroy();
  }

  async destroy() {
    broadcastIO.removeEventListener(
      BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_REQUEST,
      this.onProductRecommendationsRequest,
    );

    broadcastIO.removeEventListener(
      BroadcastIOChannels.PRODUCT_RECOMMENDATIONS_SHOW_PRICE_PER_UNIT,
      this.onProductRecommendationsShowPricePerUnit,
    );

    broadcastIO.removeEventListener(
      BroadcastIOChannels.PRODUCT_RECOMMENDATION_HIGHLIGHTED,
      this.onProductRecommendationHighlighted,
    );

    await super.destroy();
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.CONTEXTUAL_PRODUCT_RECOMMENDATIONS_SELECTION_FLOW,
      nextState: this.nextState,
      recommendations: this.recommendations,
      highlightedRecommendation: this.highlightedRecommendation,
      displayPricePerUnit: this.displayPricePerUnit,
    };
  }

  static deserialize(ctx) {
    if (ctx === null) {
      return null;
    }

    const {
      recommendations,
      nextState,
      highlightedRecommendation,
      displayPricePerUnit,
    } = ctx;

    return AbstractPromoFlow.baseDeserialize(ContextualProductRecommendationsSelectionFlow, {
      highlightedRecommendation,
      displayPricePerUnit,
    }, [recommendations, nextState]);
  }

  toString() {
    return `<ProductRecommendationsSelectionFlow>`;
  }
}
