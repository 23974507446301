import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FiscalCommands from '../FiscalCommands';
import {NumberType} from '@designeo/apibundle-js';


export const DocumentNumberCounterDtoPropDef = {
  documentType: {
    type: new EntityType(FiscalCommands),
  },
  lastNumber: {
    type: new NumberType(),
  },
  overriddenNumber: {
    type: new NumberType(),
  },
};
export default class GeneratedDocumentNumberCounterDto extends createEntity(DocumentNumberCounterDtoPropDef) {}
