import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ClubCodeNamePairDtoPropDef = {
  code: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
};
export default class GeneratedClubCodeNamePairDto extends createEntity(ClubCodeNamePairDtoPropDef) {}
