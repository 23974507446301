import {
  createEntity, StringType,
} from '@designeo/apibundle-js';
import {
  mapKeys,
  filter,
  map,
  values,
  flow,
} from 'lodash-es';
import {Korunka} from '../../../../types/korunka';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';
import {api3rdPartyKorunkaGenerateDraws} from '../../Action';
import DrawPartnerResource from '../DrawPartnerResource';
import {BetTicketPartnerResourcePropDef} from '../generated/BetTicketPartnerResource';
import KorunkaLotteryDvacetZa20DtoCustom from './KorunkaLotteryDvacetZa20DtoCustom';
import KorunkaLotteryHopTropDtoCustom from './KorunkaLotteryHopTropDtoCustom';
import KorunkaLotteryKombiDtoCustom from './KorunkaLotteryKombiDtoCustom';
import KorunkaLotteryKombiPlusDtoCustom from './KorunkaLotteryKombiPlusDtoCustom';
import KorunkaLotterySestkaDtoCustom from './KorunkaLotterySestkaDtoCustom';

export default class KorunkaLotteryScanTicketDtoCustom extends createEntity(
  {
    ...BetTicketPartnerResourcePropDef,
    id: {
      type: new StringType(),
    },
  },
) {
  async getDrawsByIds(ids: number[]) {
    const result = await api3rdPartyKorunkaGenerateDraws();

    const drawsById = mapKeys(result, 'id');

    return flow(
      (ids: number[]) => map(ids, (id) => drawsById?.[id]),
      (draws: DrawPartnerResource[]) => filter(draws, Boolean),
      (draws: DrawPartnerResource[]) => map(draws, (draw) => draw.toJson()),
    )(ids);
  }

  isSupportedGame() {
    switch (this.gameType.value as Korunka['BetTicketPartnerResource']['gameType']) {
    case KorunkaEntityTypes.Sestka:
    case KorunkaEntityTypes.DvacetZa20:
    case KorunkaEntityTypes.HopTrop:
    case KorunkaEntityTypes.Kombi:
    case KorunkaEntityTypes.KombiPlus:
      return true;
    default:
      return false;
    }
  }

  async toGameEntity() {
    const draws = await this.getDrawsByIds(this.drawIds);

    switch (this.gameType.value as Korunka['BetTicketPartnerResource']['gameType']) {
    case KorunkaEntityTypes.Sestka:
      return new KorunkaLotterySestkaDtoCustom({
        deposit: {
          value: this.amountPaid,
        },
        draws,
        randomNumbers: values(this.numbers)[0],
        amountPaid: this.amountPaid,
        isFromScan: true,
        id: this.id,
        tutovka: this.tutovka,
      });
    case KorunkaEntityTypes.DvacetZa20:
      return new KorunkaLotteryDvacetZa20DtoCustom({
        deposit: {
          // NOTE: We need to calculate real deposit from the ticket
          // Reverse calculation of total when assembling game bet ticket
          value: this.amountPaidAll / this.drawIds.length / values(this.numbers).length,
        },
        booster: this.booster,
        draws,
        randomNumbers: values(this.numbers),
        amountPaid: this.amountPaid,
        isFromScan: true,
        id: this.id,
        tutovka: this.tutovka,
      });
    case KorunkaEntityTypes.HopTrop:
      return new KorunkaLotteryHopTropDtoCustom({
        deposit: {
          value: this.amountPaid,
        },
        draws,
        randomNumbers: values(this.numbers)[0],
        amountPaid: this.amountPaid,
        isFromScan: true,
        id: this.id,
        tutovka: this.tutovka,
      });
    case KorunkaEntityTypes.Kombi:
      return new KorunkaLotteryKombiDtoCustom({
        deposit: {
          value: this.amountPaid,
        },
        draws,
        randomNumbers: values(this.numbers),
        amountPaid: this.amountPaid,
        isFromScan: true,
        id: this.id,
        betsCombinations: this.betsCombinations.map((combination) => combination.toJson()),
        tutovka: this.tutovka,
      });
    case KorunkaEntityTypes.KombiPlus:
      return new KorunkaLotteryKombiPlusDtoCustom({
        deposit: {
          value: this.amountPaid,
        },
        draws,
        randomNumbers: values(this.numbers),
        amountPaid: this.amountPaid,
        isFromScan: true,
        id: this.id,
        betsCombinations: this.betsCombinations.map((combination) => combination.toJson()),
        tutovka: this.tutovka,
      });
    default:
      throw new Error(`Unknown ticket gameType ${this.gameType}`);
    }
  }
}
