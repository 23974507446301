import GeneratedTransactionDto from './generated/TransactionDto';
import {TransactionDtoPatch} from '@/Model/Entity/patches/TransactionDto';
import {has, isEmpty} from 'lodash-es';

export default class TransactionDto extends TransactionDtoPatch(GeneratedTransactionDto) {
  get key() {
    return `${this.document.header.uniqueidentifier}_${this.document.header.referentialUniqueidentifier}`;
  }

  get hasResult() {
    if (!has(this._data, 'result')) {
      return false;
    }

    if (isEmpty(this._data.result)) {
      return false;
    }

    return true;
  }
}
