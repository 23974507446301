import {GroupBySets} from '@/Model/Entity/DocumentDto';
import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.CANCEL_MODE]: {
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent<GroupBySets>) => {
        if (event.value) {
          await this.cancelGroup(event.value);

          await this.toggleCancelMode();
        } else if (!this.sellDocument.value.canBeCanceled) {
          await this.toggleCancelMode();
        } else {
          await this.cancelSellDocument();
        }
      }),
      [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
        await this.toggleCancelMode();
      }),
    },
  };
}
