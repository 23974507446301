import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PaymentReportDto from '../PaymentReportDto';


export const PaymentsReportDtoPropDef = {
  payments: {
    type: new ArrayType(new EntityType(PaymentReportDto)),
  },
};
export default class GeneratedPaymentsReportDto extends createEntity(PaymentsReportDtoPropDef) {}
