import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedTranslatedString extends createEntity({
  cs: {
    type: new StringType(),
  },
  de: {
    type: new StringType(),
  },
  en: {
    type: new StringType(),
  },
  sk: {
    type: new StringType(),
  },
}) {}
