import {markRaw} from 'vue';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';

export default function resolveOverviewComponent(gameType: KorunkaEntityTypes, isMultipleTickets: boolean) {
  const components = {
    [KorunkaEntityTypes.Sestka]: () => {
      return isMultipleTickets ?
        markRaw(require('./SestkaMultipleOverview.vue').default) :
        markRaw(require('./SestkaOverview.vue').default);
    },
    [KorunkaEntityTypes.DvacetZa20]: () => {
      return isMultipleTickets ?
        markRaw(require('./DvacetZa20MultipleOverview.vue').default) :
        markRaw(require('./DvacetZa20Overview.vue').default);
    },
    [KorunkaEntityTypes.HopTrop]: () => {
      return isMultipleTickets ?
        markRaw(require('./HopTropMultipleOverview.vue').default) :
        markRaw(require('./HopTropOverview.vue').default);
    },
    [KorunkaEntityTypes.Kombi]: () => {
      return isMultipleTickets ?
        markRaw(require('./KombiMultipleOverview.vue').default) :
        markRaw(require('./KombiOverview.vue').default);
    },
    [KorunkaEntityTypes.KombiPlus]: () => {
      return isMultipleTickets ?
        markRaw(require('./KombiPlusMultipleOverview.vue').default) :
        markRaw(require('./KombiPlusOverview.vue').default);
    },
  };

  return components?.[gameType]?.();
}
