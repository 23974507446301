import GeneratedExchangeRate from './generated/ExchangeRate';
import {fixDecimals} from '@/Helpers/math';

export default class ExchangeRate extends GeneratedExchangeRate {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get isInLocalCurrency() {
    return this.baseCurrency === this.configurationStore.localCurrency.value.symbol;
  }

  get exchangeRateToLocalCurrency(): ExchangeRate {
    const exchangeRateKey = `${this.baseCurrency}:${this.configurationStore.localCurrency.value.symbol}`;
    return this.configurationStore.exchangeRatesByCurrencies.value[exchangeRateKey];
  }

  exchangeToLocalCurrency(value) {
    if (this.isInLocalCurrency) {
      return value;
    }

    const {baseAmount, quoteAmount} = this.exchangeRateToLocalCurrency;

    return fixDecimals(value * quoteAmount / baseAmount);
  }

  exchangeFromLocalCurrency(value) {
    if (this.isInLocalCurrency) {
      return value;
    }

    const exchangeRateKey = `${this.baseCurrency}:${this.configurationStore.localCurrency.value.symbol}`;
    const {baseAmount, quoteAmount} = this.configurationStore.exchangeRatesByCurrencies.value[exchangeRateKey];

    return fixDecimals(value * baseAmount / quoteAmount);
  }
}
