import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-center px-2 py-4 font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarehouseOperationListItem = _resolveComponent("WarehouseOperationListItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col flex-grow divide-y divide-basic-light -my-2", {
      'justify-center': !_ctx.list.length,
    }])
  }, [
    (_ctx.list.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.list, (listItem, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "py-1.5"
          }, [
            _createVNode(_component_WarehouseOperationListItem, {
              item: listItem,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event)))
            }, null, 8, ["item"])
          ]))
        }), 128))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationList.noItems')), 1))
  ], 2))
}