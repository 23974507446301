import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const DocumentLogisticItemDtoPropDef = {
  internalNumber: {
    type: new StringType(),
  },
  description: {
    type: new StringType(),
  },
  descriptionShort: {
    type: new StringType(),
  },
  gtin: {
    type: new StringType(),
  },
  position: {
    type: new NumberType(),
  },
  imageId: {
    type: new StringType(),
  },
  taxStamp: {
    type: new StringType(),
  },
  batch: {
    type: new StringType(),
  },
  itemType: {
    type: new StringType(),
  },
  batchLabel: {
    type: new StringType(),
  },
  quantity: {
    type: new NumberType(),
  },
  unit: {
    type: new StringType(),
  },
  price: {
    type: new NumberType(),
  },
  scannedDateTime: {
    type: new DateTimeType(),
  },
  inventoryGroup: {
    type: new StringType(),
  },
  saleValidFrom: {
    type: new DateTimeType(),
  },
  saleValidTill: {
    type: new DateTimeType(),
  },
  quantityReal: {
    type: new NumberType(),
  },
  quantityAccounting: {
    type: new NumberType(),
  },
  quantityCorrection: {
    type: new NumberType(),
  },
  quantityDifference: {
    type: new NumberType(),
  },
  quantityToFind: {
    type: new NumberType(),
  },
  valueDifference: {
    type: new NumberType(),
  },
  pricePurchase: {
    type: new NumberType(),
  },
  priceStock: {
    type: new NumberType(),
  },
  priceSelling: {
    type: new NumberType(),
  },
  serialNo: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentLogisticItemDto extends createEntity(DocumentLogisticItemDtoPropDef) {}
