import {Constructor} from '@/Model/Entity/patches/patch';
import {ArrayType, EntityType} from '@designeo/apibundle-js';
import Transaction from '../Transaction';
import TransactionItem from '../TransactionItem';
import TransactionDiff from '../TransactionDiff';
import TransactionPayment from '../TransactionPayment';

export const TransactionPatch = <TBase extends Constructor>(superClass: TBase) =>
  class TransactionPatch extends superClass {
    get original(): Transaction {
      // @ts-ignore
      return new EntityType(Transaction).parse(this._data.original);
    }
    set original(val: Transaction) {
      // @ts-ignore
      this._data.original = new EntityType(Transaction).format(val);
    }

    get referentialTransactions() {
      // @ts-ignore
      return new ArrayType(new EntityType(Transaction)).parse(this._data.referentialTransactions);
    }
    set referentialTransactions(val) {
      // @ts-ignore
      this._data.referentialTransactions = new ArrayType(new EntityType(Transaction)).format(val);
    }

    get transactionItems() {
      // @ts-ignore
      return new ArrayType(new EntityType(TransactionItem)).parse(this._data.transactionItems);
    }
    set transactionItems(val) {
      // @ts-ignore
      this._data.transactionItems = new ArrayType(new EntityType(TransactionItem)).format(val);
    }

    get transactionPayments() {
      // @ts-ignore
      return new ArrayType(new EntityType(TransactionPayment)).parse(this._data.transactionPayments);
    }
    set transactionPayments(val) {
      // @ts-ignore
      this._data.transactionPayments = new ArrayType(new EntityType(TransactionPayment)).format(val);
    }

    get transactionDiffs() {
      // @ts-ignore
      return new ArrayType(new EntityType(TransactionDiff)).parse(this._data.transactionDiffs);
    }
    set transactionDiffs(val) {
      // @ts-ignore
      this._data.transactionDiffs = new ArrayType(new EntityType(TransactionDiff)).format(val);
    }
  };
