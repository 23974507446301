import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const CreateCounterDtoPropDef = {
  receiptId: {
    type: new StringType(),
  },
  transactionDate: {
    type: new DateTimeType(),
  },
  shopCode: {
    type: new StringType(),
  },
};
export default class GeneratedCreateCounterDto extends createEntity(CreateCounterDtoPropDef) {}
