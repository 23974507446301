import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col flex-grow m-4 px-4 gap-3"
}
const _hoisted_2 = { class: "text-2xl font-bold text-center uppercase" }
const _hoisted_3 = { class: "mx-auto pt-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col text-lg font-bold mx-auto"
}
const _hoisted_6 = { class: "flex flex-col mx-auto" }
const _hoisted_7 = { class: "mx-auto font-bold" }
const _hoisted_8 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_9 = {
  key: 1,
  class: "font-bold"
}
const _hoisted_10 = {
  key: 0,
  class: "mx-auto font-bold"
}
const _hoisted_11 = {
  key: 1,
  class: "mx-auto font-bold"
}
const _hoisted_12 = {
  key: 2,
  class: "mx-auto font-bold"
}
const _hoisted_13 = { class: "mx-auto pt-4" }
const _hoisted_14 = {
  key: 0,
  class: "font-bold text-center whitespace-pre"
}
const _hoisted_15 = {
  key: 1,
  class: "font-bold text-center whitespace-pre"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ticket?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.interpolate(_ctx.messages.ticket, {ticketCode: _ctx.ticket.ticketNumber})), 1),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.badge.class, "w-1/2 text-center font-bold py-4 px-6 mx-auto uppercase rounded-full"])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badge.labels, (label, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass({
          'text-xs': _ctx.badge.labels.length > 1,
          'text-2xs': _ctx.badge.labels.length > 2,
        })
            }, _toDisplayString(label), 3))
          }), 128))
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.resolvedIconByTicketState,
            alt: "korunka-state-icon"
          }, null, 8, _hoisted_4)
        ]),
        (_ctx.ticket.paidOutDrawsCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.messages.ticketIsPartlyPaidOut), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createTextVNode(_toDisplayString(_ctx.messages.draw) + ": ", 1),
            (_ctx.hasMultipleBetOnDraws)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.dateFormat(_ctx.ticket.firstBetOnDraw.draw.deadlineForBets)) + " - " + _toDisplayString(_ctx.dateFormat(_ctx.ticket.lastBetOnDraw.draw.deadlineForBets)), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.dateFormat(_ctx.ticket.draw.deadlineForBets)), 1))
          ]),
          (!_ctx.entity.isCanceled && !_ctx.entity.isNotEvaluated && !!_ctx.ticket.amountWon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.messages.win) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.ticket.amountWon)), 1))
            : _createCommentVNode("", true),
          (_ctx.ticket.amountWonToPayout)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.messages.amountWonToPayout) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.ticket.amountWonToPayout)), 1))
            : _createCommentVNode("", true),
          (_ctx.ticket.drawsRemaining)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.messages.drawsRemaining) + ": " + _toDisplayString(_ctx.ticket.drawsRemaining), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.entity.payoutAmountExceeded.branch && !_ctx.entity.isPaidOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.messages.winAmountTooLargeBranch), 1))
            : _createCommentVNode("", true),
          (_ctx.entity.payoutAmountExceeded.headOffice && !_ctx.entity.isPaidOut)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.messages.winAmountTooLargeHeadOffice), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}