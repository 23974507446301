import {
  BooleanType,
  createEntity,
  ObjectType,
  StringType,
} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DefaultPaymentMethod from '../DefaultPaymentMethod';


export default class GeneratedPayment extends createEntity({
  defaultPaymentMethod: {
    type: new EntityType(DefaultPaymentMethod),
  },
  customRefundConditionMethod: {
    type: new StringType(),
  },
  primaryPrintoutAskForPaymentTerminalPrintout: {
    type: new BooleanType(),
  },
  secondaryPrintoutAskForPaymentTerminalPrintout: {
    type: new BooleanType(),
  },
  cashQuickPick: {
    type: new ObjectType(),
  },
}) {}
