import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Role from '../Role';


export default class GeneratedAcl extends createEntity({
  roles: {
    type: new ArrayType(new EntityType(Role)),
  },
}) {}
