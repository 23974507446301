import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ConfigurationInfoDto from '../ConfigurationInfoDto';
import SyncStatusDto from '../SyncStatusDto';


export const StatusDtoPropDef = {
  installerVersion: {
    type: new StringType(),
  },
  coreVersion: {
    type: new StringType(),
  },
  printerVersion: {
    type: new StringType(),
  },
  posId: {
    type: new StringType(),
  },
  middlewareUri: {
    type: new StringType(),
  },
  isOnline: {
    type: new BooleanType(),
  },
  priceListCreationDate: {
    type: new DateTimeType(),
  },
  priceListVersion: {
    type: new StringType(),
  },
  isOnlineForced: {
    type: new BooleanType(),
  },
  loadedArticles: {
    type: new NumberType(),
  },
  loadedConfigurations: {
    type: new ArrayType(new EntityType(ConfigurationInfoDto)),
  },
  syncStatus: {
    type: new EntityType(SyncStatusDto),
  },
};
export default class GeneratedStatusDto extends createEntity(StatusDtoPropDef) {}
