import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "pb-4 text-center" }
const _hoisted_4 = { class: "pb-8 uppercase font-bold text-center" }
const _hoisted_5 = { class: "flex justify-center" }
const _hoisted_6 = { class: "flex-grow" }
const _hoisted_7 = { class: "flex flex-row gap-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "pt-4 flex flex-row justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPerson = _resolveComponent("IconPerson")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({abortWorkflow, workflowStep}) => [
        (workflowStep.activeCustomerConfirmation)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_IconPerson, {
                    class: "inline",
                    rounded: ""
                  })
                ]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(workflowStep.step.confirmRequest), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AsyncButton, {
                    class: "btn-basic",
                    onClick: () => workflowStep.onAnswer(true)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(workflowStep.step.confirmBypass), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]),
              _createVNode(_component_WorkflowTransitions, {
                previousAction: () => workflowStep.abortCustomerConfirmation(),
                nextHidden: true
              }, null, 8, ["previousAction"])
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_HLInput, {
                  field: _ctx.WorkflowStepField.yearOfBirth
                }, {
                  default: _withCtx(({isActive, value, setActive, error}) => [
                    _createVNode(_component_FormInput, {
                      label: workflowStep.step.yearOfBirth,
                      active: isActive,
                      error: error,
                      errorOutput: 'modal',
                      onClick: setActive
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(value.value), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "active", "error", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["field"]),
                _createVNode(_component_HLInput, {
                  field: _ctx.WorkflowStepField.sex
                }, {
                  default: _withCtx(({isActive, value, setActive, setActiveWithValue, error}) => [
                    _createVNode(_component_FormInput, {
                      label: workflowStep.step.sex,
                      active: isActive,
                      error: error,
                      errorOutput: 'modal',
                      onClick: setActive
                    }, {
                      "form-control": _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", {
                            class: _normalizeClass(['form-control text-center', {
                      'bg-secondary text-basic-lightest': value.value === _ctx.SexOptions.MALE
                    }]),
                            onClick: ($event: any) => (setActiveWithValue(_ctx.SexOptions.MALE))
                          }, _toDisplayString(_ctx.$t('general.sex.male')), 11, _hoisted_8),
                          _createElementVNode("div", {
                            class: _normalizeClass(['form-control text-center', {
                      'bg-secondary text-basic-lightest': value.value === _ctx.SexOptions.FEMALE
                    }]),
                            onClick: ($event: any) => (setActiveWithValue(_ctx.SexOptions.FEMALE))
                          }, _toDisplayString(_ctx.$t('general.sex.female')), 11, _hoisted_9)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["label", "active", "error", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["field"]),
                _createVNode(_component_HLInput, {
                  field: _ctx.WorkflowStepField.phone
                }, {
                  default: _withCtx(({isActive, value, setActive, error}) => [
                    _createVNode(_component_FormInput, {
                      label: workflowStep.step.phone,
                      active: isActive,
                      error: error,
                      errorOutput: 'modal',
                      onClick: setActive
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.phoneFormat(value.value)), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "active", "error", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["field"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_AsyncButton, {
                  class: "btn-basic",
                  onClick: abortWorkflow
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(workflowStep.step.abort), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _createVNode(_component_WorkflowTransitions, {
                class: "pt-14",
                nextAction: () => workflowStep.submit()
              }, null, 8, ["nextAction"])
            ], 64))
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}