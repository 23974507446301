import {markRaw} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  DocumentItemDto,
  LotteryTurnoversDto,
  SearchResultDto,
} from '@/Model/Entity';
import {apiSearch} from '@/Model/Action';
import {
  filter,
  flatMap,
  flow,
  forEach,
  fromPairs,
  groupBy,
  map,
  without,
} from 'lodash-es';
import {fieldsWithIndex, prefixField} from '../../../../Helpers/lotteryFields';

export class WorkflowStepApiLotteryArticleCalculations extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiLotteryArticleCalculations;
  }

  get type() {
    return WorkflowStepApiLotteryArticleCalculations.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get lotteryTurnovers() {
    return new LotteryTurnoversDto(this.getFieldValue(WorkflowStepField.lotteryTurnovers, {}));
  }

  get terminalCount() {
    return 1;
  }

  get lotteryArticlesFromInaccurateCategories() {
    return map(this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []), (article) => {
      return new DocumentItemDto(article ?? {});
    });
  }

  get articleFields() {
    return flatMap(this.lotteryArticlesFromInaccurateCategories, (article) => {
      return fieldsWithIndex(this.getFieldKeyByInternalNumber(article.internalNumber), this.terminalCount);
    });
  }

  getFieldKeyByInternalNumber(internalNumber) {
    return [
      WorkflowStepField.lotteryArticles,
      internalNumber,
      WorkflowStepField.amount,
    ].join('.');
  }

  async beforeEnter(): Promise<void> {
    const internalNumbers = flow(
      (turnoverByArticles) => filter(turnoverByArticles, (turnover) => turnover.totalAmount !== 0),
      (turnoverByArticles) => map(turnoverByArticles, 'internalNumber'),
      (internalNumbers) => without(internalNumbers, undefined),
    )(this.lotteryTurnovers.turnoverByArticles);

    const searchResults = await Promise.all(
      map(internalNumbers, (internalNumber) => {
        return apiSearch({params: {code: internalNumber}});
      }),
    );

    const articles = flatMap(
      searchResults,
      (results) => map(results, (result) => result?.documentItem?.toJson() ?? null).filter(Boolean),
    );

    this.dataSetter(
      WorkflowStepField.lotteryArticlesFromInaccurateCategories,
      () => articles,
    );

    this.dataSetter(WorkflowStepField.lotteryArticles, (value) => {
      return {
        ...value,
        ...fromPairs(
          map(
            internalNumbers, (id) => (
              [
                id,
                {
                  amount: fromPairs(
                    Array.from({length: this.terminalCount}, (v, index) => [prefixField(index, 'f'), '0']),
                  ),
                },
              ]
            ),
          ),
        ),
      };
    });

    const articlesByParentId = groupBy(articles, 'parentId');

    forEach(articlesByParentId, (articles, index) => {
      this.dataSetter(WorkflowStepField.inaccurateArticlesByCategory, (currentValue = {}) => {
        return {
          ...currentValue,
          ...(articles.length ? {[index]: articles} : {}),
        };
      });
    });

    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {};
  }
}
