import {
  createApp,
  Plugin,
} from 'vue';
import {uniqueId} from 'lodash-es';
import LayoutIframeWrapper from '@/Layout/LayoutIframeWrapper.vue';
import {submitJournalEventMoPortalOpen} from '@/Helpers/journal';
import {FramePersistenceCodes} from '@/constants/framePersistenceCodes';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {interpolate} from '@/Helpers/filters';
import {
  getVueApp,
  setTouchEmulation,
  systemExec,
} from '@/Helpers/app';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {RemoteAppButtonTypes} from '@/constants/remoteAppButtonTypes';
import {useTrainingStore} from '@/Modules/Training/store/TrainingStore';
import {wait} from '@designeo/js-helpers';
import {AppVersion} from '@/constants/appVersion';

const frames = new Map<string, {
  app: any,
  url: URL,
  close: Function,
  open: Function
}>();

export const useExternalApps = () => {
  const i18nInstall: Plugin = getVueApp()?.config.globalProperties.i18nInstall;
  const coreStore = useCoreStore();
  const authStore = useAuthStore();
  const registerStore = useRegisterStore();
  const configurationStore = useConfigurationStore();

  const interpolateLink = (link) => {
    return interpolate(link, {
      username: authStore.activePerson.value?.username ?? 'null',
      posCode: configurationStore.configuration.value?.general.pos.code ?? 'null',
      posId: configurationStore.configuration.value?.general.pos.id ?? 'null',
      shopCode: configurationStore.configuration.value?.general.pos.shop.code ?? 'null',
      shopId: configurationStore.configuration.value?.general.pos.shop.id ?? 'null',
      customerId: registerStore.customer.value?.customerNumber ?? 'null',
      articleId: registerStore.productFlow.value?.product?.internalNumber ?? 'null',
      articleImageId: registerStore.productFlow.value?.product?.imageId ?? 'null',
    });
  };

  const openFrame = async ({name, appType, link, persistenceCode}) => {
    if (useTrainingStore().trainingIsActive.value) return;
    try {
      const root = document.getElementById('iframe-wrapper');
      let url: URL;

      try {
        link = interpolateLink(link);
        url = new URL(link);
      } catch (e) {
        console.error(e);
        return;
      }

      const key = `${persistenceCode ?? `${url.origin}${url.pathname}`}:${authStore.activePerson.value?.username}`;

      if (!frames.has(key)) {
        const id = uniqueId('view_');

        const frame = document.createElement('div');
        let closed = false;

        frame.id = id;

        root.appendChild(frame);

        const close = async () => {
          closed = true;
          root.classList.add('hidden');
          frame.classList.add('hidden');
          await wait(100)(null); // event propagation!
          coreStore.setKeyboardBufferMute(false);
          if (!persistenceCode) {
            frames.delete(key);
            frame.remove();
          }
          setTouchEmulation(true);
        };

        const open = async () => {
          closed = false;
          coreStore.setKeyboardBufferMute(true);
          frame.classList.remove('hidden');
          root.classList.remove('hidden');
          setTouchEmulation(false);
        };

        const amIClosed = () => {
          return closed;
        };

        const app = createApp(LayoutIframeWrapper, {
          appVersion: AppVersion.IframeWrapper,
          name,
          url,
          close,
          open,
          amIClosed,
        });

        frames.set(key, {
          app,
          url,
          close,
          open,
        });

        app.use(i18nInstall);

        app.mount(document.getElementById(id));
      }

      const win = frames.get(key);

      win.open();

      if (persistenceCode === FramePersistenceCodes.moPortal) {
        submitJournalEventMoPortalOpen();
      }

      return win;
    } catch (e) {
      console.error(e);
    }
  };

  const openExternalApp = ({link}) => {
    if (useTrainingStore().trainingIsActive.value) return;
    return systemExec({
      cmd: interpolateLink(link),
      token: authStore.activePerson.value?.accessToken ?? null,
    });
  };

  const open = async (appCode: string) => {
    const app = configurationStore.configuration.value?.buttons?.remoteAppButtons?.[appCode];

    if (!app) {
      console.warn(`App ${appCode} is not configured`);
      return;
    }

    if (app?.appType === RemoteAppButtonTypes.iframe) {
      await openFrame(app);
    } else if (app?.appType === RemoteAppButtonTypes.externalApp) {
      await openExternalApp(app);
    }
  };

  return {
    frames,
    open,
    openFrame,
    openExternalApp,
  };
};
