import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {
  findIndex,
  flow,
  map,
  reduce,
} from 'lodash-es';
import {workflowStepMixinPayment} from '../StepMixins/WorkflowStepMixinPayment';
import {Payment} from '@/Modules/Payment/payment';
import {DocumentPaymentDto} from '@/Model/Entity';
import {nominals} from '@/Helpers/nominals';
import {PaymentTypeMethods} from '@/constants/paymentTypeMethods';
import {fixDecimals} from '@/Helpers/math';

export class WorkflowStepPaymentSelect extends flow(
  (ctor) => workflowStepMixinPayment(ctor),
)(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.PaymentSelect;
  }

  get type() {
    return WorkflowStepPaymentSelect.type;
  }

  get component() {
    return markRaw(require('./StepPaymentSelect.vue').default);
  }

  get preselectedPaymentIndex() {
    return this.paymentButtons.findIndex(({preselect}) => preselect);
  }

  get paymentButtons() {
    return this.step.paymentButtons as Array<{
      paymentId: string;
      icon: string;
      label: string,
      preselect: boolean,
    }>;
  }

  get canContinue() {
    return !!this.paymentMethod;
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.paymentMethod, (currentValue) => {
      if (currentValue) {
        return currentValue;
      }

      if (this.preselectedPaymentIndex === -1) {
        return null;
      }

      return this.paymentButtons[this.preselectedPaymentIndex]?.paymentId ?? null;
    });

    this.dataSetter(WorkflowStepField.paymentValue, () => this.transactionTotalValueByPaymentMethod);
  }

  get paymentMethod() {
    return this.getFieldValue(
      WorkflowStepField.paymentMethod,
      this.paymentButtons?.[this.preselectedPaymentIndex]?.paymentId ?? null,
    );
  }

  set paymentMethod(value) {
    this.dataSetter(WorkflowStepField.paymentMethod, () => value);
    this.dataSetter(WorkflowStepField.paymentValue, () => this.transactionTotalValueByPaymentMethod);
  }

  get transactionTotalValue() {
    return this.paymentTransitionEnvelope.value;
  }

  get transactionCurrency() {
    return this.paymentTransitionEnvelope.currency;
  }

  get transactionTotalValueByPaymentMethod() {
    if (this.activePaymentType?.type.value === PaymentTypeMethods.cash) {
      const currency = this.configurationStore.currenciesByCurrencySymbol.value[this.activePaymentType.currency];
      return nominals(this.transactionTotalValue, currency.acceptableNominals).decomposableRounded;
    } else {
      return this.transactionTotalValue;
    }
  }

  get isPaid() {
    if (this.hasFinishedPaymentRequests) {
      return true;
    }

    return false;
  }

  requestEshopPayment() {
    if (this.isPaid) {
      return;
    }

    const paymentId = this.getFieldValue(WorkflowStepField.paymentMethod);
    const paymentValueToPay = this.getFieldValue(WorkflowStepField.paymentValue);

    const payment = this.configurationStore.createPayment(paymentId);
    payment.setValue(paymentValueToPay);

    let payments: DocumentPaymentDto[] = [];

    if (this.returnAmount > 0) {
      const returnPayment = this.configurationStore.createPayment(paymentId);
      returnPayment.isMoneyBack = true;
      returnPayment.setValue(this.returnAmount * -1);

      payments = [payment, returnPayment];
    } else {
      payments = [payment];
    }

    for (const payment of payments) {
      if (!payment.type.hasConfirmationMethodTerminal) {
        continue;
      }

      payment.verifyDocumentId = this.createPaymentRequest(Payment.create({
        paymentId: payment.paymentID,
        value: payment.isMoneyBack ? paymentValueToPay * -1 : paymentValueToPay,
      }));
    }

    this.payments = payments;
  }

  set payments(value) {
    this.dataSetter(WorkflowStepField.payments, () => map(value, (payment) => payment.toJson()));
  }

  get activePaymentType() {
    return this.paymentTypesByPaymentId[this.paymentMethod];
  }

  get paymentTypesByPaymentId() {
    return this.configurationStore.paymentTypesByPaymentId.value;
  }

  get activePaymentTypeWithOverpay() {
    return this.activePaymentType?.type?.value === PaymentTypeMethods.cash;
  }

  get returnAmount() {
    if (!this.activePaymentType) {
      return 0;
    }

    const valueToPay = this.getFieldValue(WorkflowStepField.paymentValue);

    if (this.activePaymentType.currency === this.activePaymentType.changeCurrency) {
      return Math.max(valueToPay - this.transactionTotalValueByPaymentMethod, 0);
    }

    const exchangeRateKey = `${this.activePaymentType.currency}:${this.activePaymentType.changeCurrency}`;
    const exchangeRate = this.configurationStore.exchangeRatesByCurrencies.value[exchangeRateKey];

    if (!exchangeRate) {
      return 0;
    }

    const valueToPayInFCurrency = exchangeRate
      .exchangeToLocalCurrency(valueToPay);
    const transactionTotalValueByPaymentMethodInFCurrency = exchangeRate
      .exchangeToLocalCurrency(this.transactionTotalValueByPaymentMethod);

    return Math.max(fixDecimals(valueToPayInFCurrency - transactionTotalValueByPaymentMethodInFCurrency), 0);
  }

  get isPaymentRequestValid() {
    const valueToPay = this.getFieldValue(WorkflowStepField.paymentValue);
    return valueToPay >= this.transactionTotalValueByPaymentMethod;
  }

  currencyLocalSymbol(currencySymbol: string) {
    return this.configurationStore.currenciesByCurrencySymbol.value[currencySymbol].localSymbol;
  }

  async finish() {
    if (!this.canContinue) {
      return;
    }

    if (this.hasUnfinishedPaymentRequests || this.hasFinishedPaymentRequests) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.transactionTotalValue === 0) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (!this.isPaymentRequestValid) {
      return;
    }

    this.requestEshopPayment();
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.paymentMethod, (fieldActions) => ({
        [WorkflowActions.PREV]: action(() => {
          const currentPaymentId = this.getFieldValue(WorkflowStepField.paymentMethod);
          const index = findIndex(this.paymentButtons, ({paymentId}) => paymentId == currentPaymentId);
          const nextId = this.paymentButtons[index - 1]?.paymentId ?? currentPaymentId;
          this.paymentMethod = nextId;
        }),
        [WorkflowActions.NEXT]: action(() => {
          const currentPaymentId = this.getFieldValue(WorkflowStepField.paymentMethod);
          const index = findIndex(this.paymentButtons, ({paymentId}) => paymentId == currentPaymentId);
          const nextId = this.paymentButtons[index + 1]?.paymentId ?? currentPaymentId;
          this.paymentMethod = nextId;
        }),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent) => {
          if (event.value) {
            this.paymentMethod = event.value;
            return;
          }

          if (this.activePaymentTypeWithOverpay) {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
              detail: {
                field: WorkflowStepField.paymentValue,
              },
            }));
          } else {
            await this.finish();
          }
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.paymentValue, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_NUMBER,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.finish();
        }),
      })),
    };
  }
}
