import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const PromotionListDtoPropDef = {
  id: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  createdDate: {
    type: new DateTimeType(),
  },
  createdById: {
    type: new StringType(),
  },
  createdByName: {
    type: new StringType(),
  },
  editedDate: {
    type: new DateTimeType(),
  },
  editedById: {
    type: new StringType(),
  },
  editedByName: {
    type: new StringType(),
  },
  listDefinition: {
    type: new StringType(),
  },
};
export default class GeneratedPromotionListDto extends createEntity(PromotionListDtoPropDef) {}
