import {KeyPress} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransformStream,
} from '../streams';


const KeyboardSymbols = {
  Minus: '-',
  Enter: '\n',
};

export function createTranslateKeysTransform() {
  function keyPressToKey(keypress: KeyPress) {
    if (KeyboardSymbols[keypress.code]) {
      return KeyboardSymbols[keypress.code];
    } else if (keypress.code.startsWith('Digit')) {
      return keypress.code.replace('Digit', '');
    } else {
      return keypress.key;
    }
  }
  return new TransformStream<KeyPress, KeyPress>({
    transform(key, controller) {
      if (key.source === 'scanner') {
        controller.enqueue({
          ...key,
          key: keyPressToKey(key),
        });
      } else {
        controller.enqueue(key);
      }
    },
  });
}
