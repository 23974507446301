import {StockDto} from '@/Model/Entity';
import {mapKeys} from 'lodash-es';

export const createBatchKey = ({internalNumber, batch}) => `${internalNumber}:${batch}`;

export const mapStockList = (list: StockDto[]) => {
  return mapKeys(list, (item) => {
    if (item.batch) {
      return createBatchKey({internalNumber: item.internalNumber, batch: item.batch});
    }

    return item.internalNumber;
  });
};
