import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import InventoryStockDto from '../InventoryStockDto';
import {DateTimeType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const InventoryDtoPropDef = {
  stock: {
    type: new EntityType(InventoryStockDto),
  },
  startedDate: {
    type: new DateTimeType(),
  },
  finishedDate: {
    type: new DateTimeType(),
  },
  printOnlyDifferences: {
    type: new BooleanType(),
  },
  lastInventoryNumber: {
    type: new StringType(),
  },
  priceDifferenceTotal: {
    type: new NumberType(),
  },
  lastInventoryDate: {
    type: new DateTimeType(),
  },
  customInventoryCode: {
    type: new StringType(),
  },
  customInventoryName: {
    type: new StringType(),
  },
};
export default class GeneratedInventoryDto extends createEntity(InventoryDtoPropDef) {}
