import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import StornoReasonClass from '../StornoReasonClass';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedStorno extends createEntity({
  allowAddingStornoOnlyToEmptyBon: {
    type: new BooleanType(),
  },
  allowEntireDocumentStorno: {
    type: new BooleanType(),
  },
  allowPartialDocumentStorno: {
    type: new BooleanType(),
  },
  autoUntoggleStornoAfterAddingArticle: {
    type: new BooleanType(),
  },
  requireOriginalDocumentFiscalReference: {
    type: new BooleanType(),
  },
  requireOriginalDocumentReference: {
    type: new BooleanType(),
  },
  stornoReasons: {
    type: new ArrayType(new EntityType(StornoReasonClass)),
  },
  stornoTimeout: {
    type: new NumberType(),
  },
  forcedSellDocumentPrintTemplateForStorno: {
    type: new StringType(),
  },
  referentialDocumentPartialDocumentStorno: {
    type: new BooleanType(),
  },
}) {}
