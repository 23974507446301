import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {
  PrintContentActions,
  PrintContentEvents,
  PrintContentInputEvent,
  PrintContentState,
} from '../types';
import {ModalType, ModalTypes} from '@/constants/modal';

export interface IPrintContentStore {
  state: PrintContentState
  printContent: {
    content: string,
    modalType: ModalType,
    resolve: (value?: boolean)=> void,
    reject?: (reason?: any)=> void,
  },
}

const createInitState = (data?: Partial<IPrintContentStore>) => ({
  state: PrintContentState.CLOSED,
  printContent: null,
});

export class PrintContentStore extends Store<IPrintContentStore> {
  constructor() {
    super(createInitState());
  }

  transitions: {[key in PrintContentState]?: {[key in PrintContentActions]?: any}} = {
    [PrintContentState.CLOSED]: {
      [PrintContentActions.OPEN]: action(async (event: PrintContentInputEvent<IPrintContentStore['printContent']>) => {
        this.state.printContent = event.value;
        this.state.state = PrintContentState.OPENED;
      }),
    },
    [PrintContentState.OPENED]: {
      [PrintContentActions.CLOSE]: action(async (event: PrintContentInputEvent) => {
        this.state.printContent?.resolve?.();
        this.state.state = PrintContentState.CLOSED;
        this.dispatchEvent(new Event(PrintContentEvents.CLOSED));
      }),
      [PrintContentActions.PRINT]: action(async (event: PrintContentInputEvent) => {
        // TODO print!
        this.dispatchEvent(new Event(PrintContentEvents.PRINTED));
      }),
    },
  }

  open = action(async (
    printContent,
    {
      modalType = ModalTypes.lg,
    }: {
      modalType?: ModalType
    } = {},
  ) => {
    let resolve = null;

    const promise = (new Promise<{canceled: boolean}>((onFulfilled, onRejected) => {
      resolve = (canceled = false) => onFulfilled({canceled});
    }))
      .then((result) => {
        this.state.printContent = null;
        return result;
      })
      .catch((err) => {
        this.state.printContent = null;
        throw err;
      });


    await this.onEventInput({
      type: PrintContentActions.OPEN,
      value: {
        content: printContent,
        modalType,
        resolve,
      },
    });

    return promise;
  })

  ensureClosedState = action(async () => {
    if (this.isActive.value) {
      await this.onEventInput({
        type: PrintContentActions.CLOSE,
      });
    }
  })

  close = action(() => {
    this.ensureClosedState();
  })

  onEventInput = action(async (event: PrintContentInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  isActive = getter(() => {
    return this.state.state === PrintContentState.OPENED;
  })

  printContent = getter(() => {
    return this.state.printContent;
  })
}

const storeIdentifier = 'PrintContentStore';

export const configurePrintContentStore = createConfigureStore<typeof PrintContentStore>(storeIdentifier);
export const usePrintContentStore = createUseStore(PrintContentStore, storeIdentifier);
