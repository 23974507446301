import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleErrorEnum from '../ArticleErrorEnum';
import {StringType} from '@designeo/apibundle-js';


export const ArticleDataErrorPropDef = {
  errorTypes: {
    type: new EntityType(ArticleErrorEnum),
  },
  errorCode: {
    type: new StringType(),
  },
};
export default class GeneratedArticleDataError extends createEntity(ArticleDataErrorPropDef) {}
