import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Function from '../Function';
import {StringType} from '@designeo/apibundle-js';
import TranslatedString from '../TranslatedString';


export default class GeneratedFunctionButton extends createEntity({
  function: {
    type: new EntityType(Function),
  },
  icon: {
    type: new StringType(),
  },
  keyboardShortcut: {
    type: new StringType(),
  },
  label: {
    type: new EntityType(TranslatedString),
  },
}) {}
