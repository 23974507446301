import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';

export interface ISynchronizationStore {
  resolve: ()=> void,
  reject: (err: Error)=> void,
  promise: Promise<void>
}

const createInitState = (): ISynchronizationStore => ({
  resolve: () => {},
  reject: () => {},
  promise: Promise.resolve(),
});

export class SynchronizationStore extends Store<ISynchronizationStore> {
  constructor() {
    super(createInitState());
  }

  createSynchronizationProcess = action(() => {
    this.state.promise = new Promise<void>((...args) => {
      [this.state.resolve, this.state.reject] = args;
    });

    return this.state.promise;
  })

  finishSynchronizationProcess = action((err = null) => {
    if (err) {
      this.state.reject(err);
    } else {
      this.state.resolve();
    }

    this.state = Object.assign(this.state, createInitState());
  })

  synchronization = getter(() => {
    return this.state.promise;
  })
}

const storeIdentifier = 'SynchronizationStore';

export const configureSynchronizationStore = createConfigureStore<typeof SynchronizationStore>(storeIdentifier);
export const useSynchronizationStore = createUseStore(SynchronizationStore, storeIdentifier);
