
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue';
import FormInput from '@/Components/FormInput.vue';
import {
  HLInput,
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {SexOptions} from '@/constants/sexOptions';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useFilters} from '@/Helpers/filters';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {
  WorkflowStepEnterCustomerInformation,
} from '@/Modules/Workflow/Step/StepEnterCustomerInformation/WorkflowStepEnterCustomerInformation';
import IconPerson from '@/Components/Icons/IconPerson.vue';

export default defineComponent({
  name: 'StepEnterCustomerInformation',
  components: {
    IconPerson,
    WorkflowTransitions,
    AsyncButton,
    HLInput,
    HLStep,
    FormInput,
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterCustomerInformation;
    const {workflowStep} = useStepTools<WorkflowStepEnterCustomerInformation>(stepType);

    const onCustomerInformationConfirmResponse = async (event: CustomEvent) => {
      await workflowStep.value.onAnswer(event.detail);
    };


    onMounted(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-customer-information-confirm'});
      broadcastIO.addEventListener(
        BroadcastIOChannels.CUSTOMER_INFORMATION_CONFIRM,
        onCustomerInformationConfirmResponse,
      );
    });

    onBeforeUnmount(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      broadcastIO.removeEventListener(
        BroadcastIOChannels.CUSTOMER_INFORMATION_CONFIRM,
        onCustomerInformationConfirmResponse,
      );
    });

    const {phoneFormat} = useFilters();

    return {
      stepType,
      WorkflowStepField,
      SexOptions,
      phoneFormat,
    };
  },
});
