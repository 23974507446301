import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "flex flex-row justify-between pt-4 gap-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              onClick: _ctx.onReject,
              class: "btn-danger py-2.5 px-7"
            }, _toDisplayString(workflowStep.step.rejectLabel), 9, _hoisted_4),
            _withDirectives(_createElementVNode("button", {
              onClick: _ctx.onConfirm,
              class: "btn-success py-2.5 px-7"
            }, [
              _createTextVNode(_toDisplayString(workflowStep.step.confirmLabel), 1)
            ], 8, _hoisted_5), [
              [_directive_test, 'withCard']
            ])
          ])
        ]),
        _createVNode(_component_WorkflowTransitions, { nextHidden: "" })
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}