import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const ShopDataDtoPropDef = {
  id: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  street: {
    type: new StringType(),
  },
  city: {
    type: new StringType(),
  },
  zip: {
    type: new StringType(),
  },
  country: {
    type: new StringType(),
  },
  lat: {
    type: new NumberType(),
  },
  lon: {
    type: new NumberType(),
  },
  email: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
  isActive: {
    type: new BooleanType(),
  },
};
export default class GeneratedShopDataDto extends createEntity(ShopDataDtoPropDef) {}
