import {useMessageStore} from '@/Modules/Apps/PosMessage/store/MessageStore';
import {useIncorrectRestartStore} from '@/Modules/Core/store/IncorrectRestartStore';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {estimateNavigatorStorage, getStorageSize} from '@/Plugins/storage';
import {isElectron} from './app';
import {broadcastIO, BroadcastIOChannels} from './broadcastIO';
import {devToolsPermissionStorage} from './devTools';
import {e2eScenarioIdSessionStorage} from './persist';
import {sentryRecordException} from './sentry';

/**
 * this method resets application parts, that could be broken because of configuration update!
 */

export const configurationPostProcess = async () => {
  const messageStore = useMessageStore();
  const registerStore = useRegisterStore();

  await messageStore.setMessageDetail(null); // acl
  await registerStore.ensureInitState(); // default init could be changed
};

export const logoutPostProcess = async () => {
  const messageStore = useMessageStore();
  const workflowStore = useWorkflowStore();
  const incorrectRestartStore = useIncorrectRestartStore();

  broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
  await messageStore.setMessageDetail(null);
  messageStore.setModalImportantUnreadMessage(null);
  devToolsPermissionStorage.reset();

  if (
    !e2eScenarioIdSessionStorage.get() &&
    !incorrectRestartStore.canCancelWorkflow.value &&
    !incorrectRestartStore.canCancelSellDocument.value
  ) {
    workflowStore.buckets.value.clear();
  }

  const localStorageStatus = getStorageSize(window.localStorage);
  const localStorageMaxSize = isElectron() ? 10 * 1024 * 1024 : 10 * 1024 * 1024;
  const sessionStorageStatus = getStorageSize(window.sessionStorage);
  const sessionStorageMaxSize = isElectron() ? 10 * 1024 * 1024 : 5 * 1024 * 1024;

  if (localStorageStatus.sizeTotal > (localStorageMaxSize * 0.85)) {
    const message = [
      `LocalStorage size ${localStorageStatus.sizeTotalHumanReadable} is reaching its limit`,
      JSON.stringify(localStorageStatus),
    ].join(', ');

    sentryRecordException(new Error(message));
    console.warn(message);
  }

  if (sessionStorageStatus.sizeTotal > (sessionStorageMaxSize * 0.85)) {
    const message = [
      `SessionStorage size ${sessionStorageStatus.sizeTotalHumanReadable} is reaching its limit`,
      JSON.stringify(localStorageStatus),
    ].join(', ');

    sentryRecordException(new Error(message));
    console.warn(message);
  }

  const navigatorStorage = await estimateNavigatorStorage();

  if (navigatorStorage && navigatorStorage.percentageUsed > 50) {
    const message = [
      `NavigatorStorage usage is ${navigatorStorage.percentageUsed.toFixed(5)}%`,
      `You can write up to ${navigatorStorage.remaining} more bytes.`,
    ].join(', ');

    sentryRecordException(new Error(message));
    console.warn(message);
  }
};
