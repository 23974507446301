import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedPosAttribute extends createEntity({
  name: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
}) {}
