import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {RouteLocationRaw} from 'vue-router';
import {clearStorage, resetToShop} from '@/Helpers/app';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {apiAdminShop} from '@/Model/Action';
import SwitchShopDto from '@/Model/Entity/SwitchShopDto';
import {authStoreStatePersist} from '@/Helpers/persist';

export interface IResetToShopStore {
  callback: (route?: RouteLocationRaw)=> void
}

const createInitState = (data?: Partial<IResetToShopStore>) => ({
  callback: null,
});

export class ResetToShopStore extends Store<IResetToShopStore> {
  constructor() {
    super(createInitState());
  }

  setCallback = action((callback) => {
    this.state.callback = (...args) => {
      callback(...args);
      this.state.callback = null;
    };
  })

  continue = action(() => {
    this.state.callback?.();
  })

  resetToShopId = getter(() => {
    return resetToShop();
  })

  currentShopId = getter(() => {
    return useConfigurationStore().configuration.value?.general?.pos?.shop?.code ?? null;
  })

  isActive = getter(() => {
    if (!this.currentShopId.value) {
      return false;
    }

    return this.resetToShopId.value && this.resetToShopId.value !== this.currentShopId.value;
  })

  execute = action(async () => {
    await apiAdminShop({
      input: new SwitchShopDto({
        shopCode: this.resetToShopId.value,
      }),
    });

    const authStoreData = authStoreStatePersist.get();

    await clearStorage();

    authStoreStatePersist.set(authStoreData);
  })
}

const storeIdentifier = 'ResetToShopStore';

export const configureResetToShopStore = createConfigureStore<typeof ResetToShopStore>(storeIdentifier);
export const useResetToShopStore = createUseStore(ResetToShopStore, storeIdentifier);
