import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow bg-basic-lightest m-4 px-4" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "font-bold text-3xl pt-2" }
const _hoisted_4 = { class: "flex flex-col flex-grow gap-4 mt-6" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.messages.subTitle), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.interpolate(_ctx.messages.cardNumber, {cardNumber: _ctx.numberFormat(_ctx.entity.cardNumber)})), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.entity.username), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createTextVNode(_toDisplayString(_ctx.messages.balance) + ": ", 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.numberFormat(_ctx.entity.accountBalanceVirtual)) + " " + _toDisplayString(_ctx.messages.accountBalanceVirtual), 1)
          ]),
          _createElementVNode("div", null, [
            _createTextVNode(_toDisplayString(_ctx.messages.kbMonth) + ": ", 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.numberFormat(_ctx.entity.kbMonthAmount)) + " / " + _toDisplayString(_ctx.numberFormat(_ctx.entity.kbMonthPlace)), 1)
          ]),
          _createElementVNode("div", null, [
            _createTextVNode(_toDisplayString(_ctx.messages.kbYear) + ": ", 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.numberFormat(_ctx.entity.kbYearAmount)) + " / " + _toDisplayString(_ctx.numberFormat(_ctx.entity.kbYearPlace)), 1)
          ])
        ])
      ])
    ])
  ]))
}