import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!

  return (_openBlock(), _createBlock(_component_AsyncButton, {
    class: "btn btn-basic btn-md",
    onClick: _ctx.onPrint
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.eshopOrderOverview.print')), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}