import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {DynamicSetSelectionFlow} from '@/Modules/Register/PromoInteractionFlow/DynamicSetSelectionFlow';


export default function(this: RegisterStore): TransitionDefinition {
  const isValidPromoFlow = (promoFlow): promoFlow is DynamicSetSelectionFlow => {
    if (!promoFlow) {
      return false;
    }

    return promoFlow instanceof DynamicSetSelectionFlow;
  };

  return {
    [RegisterState.DYNAMIC_SET_SELECTION]: {
      [RegisterActions.INIT]: action(async ({
        previousState,
        dynamicSetSelection,
        selection,
      }) => {
        const nextStep = previousState;

        await this.withAppLoader(async () => {
          const promoFlow = await DynamicSetSelectionFlow.new(
            dynamicSetSelection,
            selection,
            nextStep,
          );

          try {
            await this.setPromoFlow(promoFlow);
            await promoFlow.findAvailableDynamicSetItems();
            await this.persist();
          } catch (e) {
            console.error(e);
            await promoFlow.abort();
          }
        });
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        if (this.promoFlow.value.isFinished) {
          await this.promoFlow.value.submitDynamicSetSelectedPromotion();
        }
      }),
      [RegisterActions.NEXT]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        if (this.promoFlow.value.activeSetItemIndex === this.promoFlow.value.setItems.length - 1) {
          this.promoFlow.value.activeSetItemIndex = 0;
        } else {
          this.promoFlow.value.activeSetItemIndex += 1;
        }
      }),
      [RegisterActions.PREV]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        if (this.promoFlow.value.activeSetItemIndex === 0) {
          this.promoFlow.value.activeSetItemIndex = this.promoFlow.value.setItems.length - 1;
        } else {
          this.promoFlow.value.activeSetItemIndex -= 1;
        }
      }),
      [RegisterActions.ADD_PLUS]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        const currentQuantity = this.promoFlow.value.activeSetItem.quantity;
        const prep = this.promoFlow.value.selection.selectionStep;

        const newQuantity = currentQuantity + prep;

        if (!this.promoFlow.value.canActiveSetItemQuantityBeChangedTo(newQuantity)) {
          this.dispatchEvent(
            new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ARTICLE_QUANTITY_CAN_NOT_BE_CHANGED, {
              detail: {
                article: this.promoFlow.value.activeSetItem.documentItem.description,
                quantity: newQuantity,
              },
            }),
          );
          return false;
        }

        this.promoFlow.value.changeActiveSetItemQuantity(newQuantity);
      }),
      [RegisterActions.ADD_MINUS]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        const currentQuantity = this.promoFlow.value.activeSetItem.quantity;
        const prep = this.promoFlow.value.selection.selectionStep;

        const newQuantity = currentQuantity - prep;

        if (!this.promoFlow.value.canActiveSetItemQuantityBeChangedTo(newQuantity)) {
          this.dispatchEvent(
            new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ARTICLE_QUANTITY_CAN_NOT_BE_CHANGED, {
              detail: {
                article: this.promoFlow.value.activeSetItem.documentItem.description,
                quantity: newQuantity,
              },
            }),
          );
          return;
        }

        this.promoFlow.value.changeActiveSetItemQuantity(newQuantity);
      }),
      [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        if (!this.promoFlow.value.isNumericInputUsable) {
          return;
        }

        const currentQuantity = this.promoFlow.value.activeSetItem.quantity;

        const newQuantity = parseInt(`${currentQuantity}${event.value}`, 10);

        if (!this.promoFlow.value.canActiveSetItemQuantityBeChangedTo(newQuantity)) {
          this.dispatchEvent(
            new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ARTICLE_QUANTITY_CAN_NOT_BE_CHANGED, {
              detail: {
                article: this.promoFlow.value.activeSetItem.documentItem.description,
                quantity: newQuantity,
              },
            }),
          );
          return;
        }


        this.promoFlow.value.changeActiveSetItemQuantity(newQuantity);
      }),
      [RegisterActions.BACKSPACE]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        if (!this.promoFlow.value.isNumericInputUsable) {
          return;
        }

        const currentQuantityAsString = `${this.promoFlow.value.activeSetItem.quantity}`;

        if (currentQuantityAsString.length === 1) {
          this.promoFlow.value.changeActiveSetItemQuantity(0);
        } else {
          const newQuantity = parseInt(
            `${currentQuantityAsString}`.substring(0, currentQuantityAsString.length - 1),
            10,
          );

          this.promoFlow.value.changeActiveSetItemQuantity(newQuantity);
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        if (!isValidPromoFlow(this.promoFlow.value)) {
          return;
        }

        const {documentItems} = await this.searchSubject(event.value);

        if (!documentItems.length) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ARTICLE_NOT_FOUND, {
            detail: {
              article: event.value,
            },
          }));
          return;
        }

        const documentItem = documentItems[0].expandExpandableSet();

        documentItem.sanitize();

        const promoFlowMatchingArticle = this.promoFlow.value.setItems.find((setItem) => {
          if (setItem.documentItem.internalNumber === documentItem.internalNumber) {
            return true;
          }

          if (setItem.documentItem.gtin === documentItem.gtin) {
            return true;
          }

          return false;
        });

        if (!promoFlowMatchingArticle) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ARTICLE_NOT_FOUND, {
            detail: {
              article: documentItem.description,
            },
          }));
          return;
        }

        this.promoFlow.value.activeSetItemIndex = promoFlowMatchingArticle.index;

        const currentQuantity = this.promoFlow.value.activeSetItem.quantity;

        const prep = (() => {
          if (this.promoFlow.value.selection.selectionStep !== 1) {
            return this.promoFlow.value.selection.selectionStep;
          }

          if (documentItem.quantity !== 1) {
            return Math.abs(documentItem.quantity);
          }

          return this.promoFlow.value.selection.selectionStep;
        })();

        const newQuantity = currentQuantity + prep;

        if (this.promoFlow.value.canActiveSetItemQuantityBeChangedTo(newQuantity)) {
          this.promoFlow.value.changeActiveSetItemQuantity(newQuantity);
          this.promoFlow.value.highlightFlag = true;
        } else {
          this.dispatchEvent(
            new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ARTICLE_QUANTITY_CAN_NOT_BE_CHANGED, {
              detail: {
                article: promoFlowMatchingArticle.documentItem.description,
                quantity: newQuantity,
              },
            }),
          );
        }
      }),
    },
  };
}
