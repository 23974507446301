import {v4 as uuidv4} from 'uuid';

const staticGuid = uuidv4(); // For test purpose

const generateGuid = () => uuidv4();

Object.defineProperty(generateGuid, 'staticGuid', {
  value: () => staticGuid,
});

Object.defineProperty(generateGuid, 'mock', {
  value: (guid) => guid,
});

export const guid = generateGuid as (typeof generateGuid & {staticGuid: ()=> any} & {mock: (guid: string)=> any});
