import {
  OutputType,
  PaymentButton,
  PaymentTypeClass,
  TranslatedString,
} from '@/Model/Entity';
import {map} from 'lodash-es';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useRegisterStore} from '../../store/RegisterStore';
import {
  RegisterActions,
  RegisterState,
  RegisterStoreEvent,
} from '../../types';
import {QuickCallTypes} from '../types';
import {PaymentTypeMethods} from '@/constants/paymentTypeMethods';
import {OutputTypes} from '@/constants/outputTypes';
import {PointsBurningFlow} from '../../PromoInteractionFlow/PointsBurningFlow';
import {InputSource} from '../../services/KeyboardBuffer';
import {useTrainingStore} from '@/Modules/Training/store/TrainingStore';
import {PhIcons} from '@/Helpers/phIcons';
import {SaveFlowQueueItemType} from '@/Modules/Register/SaveFlow/SaveFlow';

export interface QuickCallData {
  label?: TranslatedString
  icon?: PhIcons
  code?: string
  type?: string
  style?: {
    textColor?: string
    backgroundColor?: string
    fontSize?: string
  };
  value?: any
  disabled?: boolean
  children?: QuickCallData[]
  state?: string
  isCustomButton?: boolean
  itemsPerPage?: number
}

export interface QuickCallArticleSubmenuData extends QuickCallData {
  showItemImages?: boolean
}

export interface QuickCallArticleData extends QuickCallData {
  articleId?: string
  showItemPrices?: boolean
  showItemImages?: boolean
}

export interface QuickCallStornoData extends QuickCallData {
  reasonId?: string
}

export interface QuickCallCustomerLoyaltyPointsData extends QuickCallData {
  pointsBurningClub?: string
}

export interface QuickCallPaymentData extends QuickCallData {
  paymentId?: number
  paymentType?: PaymentTypeClass
}

export interface QuickCallOpenWorkflowData extends QuickCallData {
  workflowCode?: string
}

export type QuickCallClasses = QuickCall
  | QuickCallStorno
  | QuickCallPayment
  | QuickCallArticle
  | QuickCallCustomerLoyaltyPoints
  | QuickCallPrintTemplateCodeSecondary
  | QuickCallEnterCustomData
  | QuickCallEnterDelayedSellData
  | QuickCallEnterBurningPoints
  | QuickCallOpenWorkflow
  | QuickCallArticleSubmenu
  | QuickCallFinishReceipt;

export class QuickCall<D extends QuickCallData = QuickCallData> {
  protected data: D
  public parent: QuickCallArticleSubmenu
  private paginatorOffset: number

  constructor(data: D = null, {parent = null} = {}) {
    this.data = data;
    this.parent = parent;
    this.paginatorOffset = 0;
    // Keep in commit - usefull for debugging :smile:
    // console.groupCollapsed(this.constructor.name);
    // console.log(data?.type);
    // console.log(data);
    // console.groupEnd();
  }

  get label() {
    return this.data?.label ?? {};
  }

  get code() {
    return this.data?.code;
  }

  get type() {
    return this.data?.type;
  }

  get style() {
    return this.data?.style ?? {};
  }

  get state() {
    return this.data?.state;
  }

  get value() {
    return this.data?.value;
  }

  get disabled() {
    return this.data?.disabled ?? false;
  }

  get isCustomButton() {
    return this.data?.isCustomButton ?? false;
  }

  get itemsPerPage() {
    return this.data?.itemsPerPage ?? 9;
  }

  get children() {
    return this.createChildren(this.data?.children ?? []);
  }

  get paginator() {
    const entity = this;
    return {
      get offset() {
        return entity.paginatorOffset;
      },
      set offset(val) {
        entity.paginatorOffset = val;
      },
      pageSize: this.itemsPerPage,
      total: this.children.length,
    };
  }

  get registerStore() {
    return useRegisterStore();
  }

  get configurationStore() {
    return useConfigurationStore();
  }

  get trainingStore() {
    return useTrainingStore();
  }

  async onExecute() {
    await this.registerStore.onEventInput({
      type: RegisterActions.QUICK_CALL,
      value: this,
    });
  }

  getDataFromPaymentButtons = (code) => {
    return this.configurationStore.paymentButtonsByCode.value?.[code];
  }

  createChildren = (children?: QuickCallData[]) => {
    return map(
      children,
      (child) => {
        if (!child) {
          return null;
        }

        if (child?.type === QuickCallTypes.Submenu) {
          return new QuickCallArticleSubmenu(child);
        } else if (child?.type === QuickCallTypes.Article) {
          return new QuickCallArticle(child, {parent: this});
        } else if (child?.type === QuickCallTypes.StornoReason) {
          return new QuickCallStorno(child);
        } else if (child?.type === QuickCallTypes.Payment) {
          const availablePaymentButtonsCodes = this.configurationStore
            .configuration.value
            .getPaymentButtonsBySellDocument(this.registerStore.sellDocument.value)
            .map((paymentButton) => paymentButton.code);

          if (availablePaymentButtonsCodes.includes(child.code)) {
            return new QuickCallPayment({
              ...child,
              ...this.getDataFromPaymentButtons(child?.code)?.toJson() ?? {},
            });
          }

          return;
        } else if (child?.type === QuickCallTypes.CustomerLoyaltyPoints) {
          if (this.registerStore.sellDocument.value.isModeStorno) {
            return null;
          }

          if (this.registerStore.sellDocument.value.balanceIsZero) {
            return null;
          }

          if (this.trainingStore.trainingIsActive.value) {
            return null;
          }

          return new QuickCallCustomerLoyaltyPoints({
            ...child,
            ...this.getDataFromPaymentButtons(child?.code)?.toJson() ?? {},
          });
        } else if (child?.type === QuickCallTypes.PrintTemplateCodeSecondary) {
          if (this.registerStore.sellDocument.value.balanceIsZero) {
            return null;
          }

          if (
            this.configurationStore.configuration.value
              .features?.storno?.forcedSellDocumentPrintTemplateForStorno &&
            this.registerStore.sellDocument.value.isModeStorno
          ) {
            return null;
          }

          return new QuickCallPrintTemplateCodeSecondary({
            ...child,
            ...this.getDataFromPaymentButtons(child?.code)?.toJson() ?? {},
          });
        } else if (child?.type === QuickCallTypes.EnterCustomData) {
          if (this.registerStore.sellDocument.value.isModeStorno) {
            return null;
          }

          if (this.registerStore.sellDocument.value.balanceIsZero) {
            return null;
          }

          if (this.trainingStore.trainingIsActive.value) {
            return null;
          }

          if (!this.registerStore.customDataFields.value.length) {
            return null;
          }

          return new QuickCallEnterCustomData({
            ...child,
            ...this.getDataFromPaymentButtons(child?.code)?.toJson() ?? {},
          });
        } else if (child?.type === QuickCallTypes.EnterDelayedSellData) {
          if (this.registerStore.sellDocument.value.isModeStorno) {
            return null;
          }

          return new QuickCallEnterDelayedSellData({
            ...child,
            ...this.getDataFromPaymentButtons(child?.code)?.toJson() ?? {},
          });
        } else if (child?.type === QuickCallTypes.BurningPoints) {
          if (this.registerStore.sellDocument.value.isModeStorno) {
            return null;
          }

          if (this.registerStore.sellDocument.value.balanceIsZero) {
            return null;
          }

          if (this.trainingStore.trainingIsActive.value) {
            return null;
          }

          return new QuickCallEnterBurningPoints(child);
        } else if (child?.type === QuickCallTypes.CashQuickPick) {
          if (this.registerStore.sellDocument.value?.balanceIsZero) {
            return null;
          }

          if (!this.registerStore.sellDocument.value?.partialPaymentsAreAllowed) {
            return null;
          }

          if (!this.registerStore.payment.value?.type?.isCashMethod) {
            return null;
          }

          return new QuickCallCashQuickPick(child);
        } else if (child?.type === QuickCallTypes.OpenWorkflow) {
          return new QuickCallOpenWorkflow(child);
        } else if (child?.type === QuickCallTypes.FinishReceipt) {
          if (!this.registerStore.sellDocument.value) {
            return null;
          }

          if (!this.registerStore.sellDocument.value.balanceIsZero) {
            return null;
          }

          const [saveAttempt = null] = this.registerStore.saveFlow.value
            .queueItemsByType(SaveFlowQueueItemType.saveSellDocument);


          if (!saveAttempt) {
            return null;
          }

          /**
           * Queue item is created but not executed yet => we can not offer to finish receipt
           */
          if (!saveAttempt.result) {
            return null;
          }

          /**
           * Print have passed => we can not offer to finish receipt
           */
          if (saveAttempt.result.printed) {
            return null;
          }

          /**
           * Http request failed => we can not offer to finish receipt
           */
          if (!saveAttempt.result.result && saveAttempt.result.error) {
            return null;
          }

          /**
           * We have created document that failed somewhere on printer => we can offer to finish receipt
           */
          return new QuickCallFinishReceipt(child);
        }

        return new QuickCall(child);
      },
    ).filter(Boolean);
  };
}

export class QuickCallStorno extends QuickCall<QuickCallStornoData> {
  get reasonId() {
    return this.data?.reasonId;
  }

  async onExecute(): Promise<void> {
    this.registerStore.productFlow.value.product.setStornoReasonWithinContext(
      this.registerStore.sellDocument.value,
      this.registerStore.editOf.value,
      this.reasonId,
    );
  }
}

export class QuickCallPayment extends QuickCall<QuickCallPaymentData> {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  get paymentType() {
    return this.configurationStore.paymentTypesByPaymentId.value[this.paymentId];
  }

  get paymentId() {
    return this.data.paymentId;
  }

  async onExecute(): Promise<void> {
    if (!this.isCustomButton && this.registerStore.inputBuffer.value !== '' && !this.registerStore.payment) {
      const payment = this.configurationStore.getPaymentByPaymentCode(this.code);
      const isDefaultPayment = payment.paymentID === this.configurationStore.defaultPaymentId.value;
      return this.registerStore.changePayment(payment, {
        ...(payment.type.type.value === PaymentTypeMethods.cash && isDefaultPayment ? {
          value: this.registerStore.inputBuffer.value,
        } : {}),
      });
    }

    if (!this.isCustomButton) {
      return this.registerStore.changePayment(this.configurationStore.getPaymentByPaymentCode(this.code));
    }
  }
}

export class QuickCallArticle extends QuickCall<QuickCallArticleData> {
  get articleId() {
    return this.data?.articleId;
  }

  get showItemPrices() {
    return this.data?.showItemPrices ?? null;
  }

  get showItemImages() {
    return this.data?.showItemImages ?? null;
  }

  async onExecute(): Promise<void> {
    if (this.registerStore.isRegisterStateEnterArticleNumber.value) {
      this.registerStore.productFlow.value.inputMode = InputSource.QUICK_CALL;
      await this.registerStore.search(this.articleId);
    } else if (this.registerStore.isRegisterStateEnterArticleQuantity.value) {
      if (!(this.registerStore.productFlow.value?.product?.isFilled ?? true)) {
        return;
      }

      this.registerStore.productFlow.value.inputMode = InputSource.QUICK_CALL;
      await this.registerStore.search(this.articleId);
    } else if (this.registerStore.isRegisterStateEnterCustomerCard.value) {
      this.registerStore.productFlow.value.inputMode = InputSource.QUICK_CALL;
      await this.registerStore.search(this.articleId);
    } else if (this.registerStore.isRegisterStateReadonlyArticleSelected.value) {
      this.registerStore.productFlow.value.inputMode = InputSource.QUICK_CALL;
      await this.registerStore.search(this.articleId);
    }
  }
}

// Used when selecting payment with loyalty points
export class QuickCallCustomerLoyaltyPoints extends QuickCall<QuickCallCustomerLoyaltyPointsData> {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  get pointsBurningClub() {
    return this.data.pointsBurningClub;
  }

  get disabled(): boolean {
    if (this.pointsBurningClub) {
      return (this.registerStore.getAvailablePointsBurning(this.pointsBurningClub)?.maxValue ?? 0) <= 0;
    }

    return false;
  }

  async onExecute(): Promise<void> {
    if (this.pointsBurningClub) {
      return this.registerStore.startBurningPoints(this.pointsBurningClub);
    }
  }
}

export class QuickCallPrintTemplateCodeSecondary extends QuickCall {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  async onExecute(): Promise<void> {
    if (!this.registerStore.sellDocument.value.hasSecondaryPrintTemplateType) {
      this.registerStore.quickCallActiveMap.value.set(
        this.code,
        {code: this?.code, isCustomButton: this?.isCustomButton},
      );
    } else {
      this.registerStore.quickCallActiveMap.value.delete(this.code);
    }

    this.registerStore.sellDocument.value.printTemplateType = new OutputType(
      this.registerStore.sellDocument.value.hasSecondaryPrintTemplateType ? OutputTypes.Primary : OutputTypes.Secondary,
    );
  }
}

export class QuickCallEnterCustomData extends QuickCall {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  async onExecute(): Promise<void> {
    return this.registerStore.changeState(RegisterState.ENTER_CUSTOM_DATA, {resetBuffer: false});
  }
}

export class QuickCallEnterDelayedSellData extends QuickCall {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  async onExecute(): Promise<void> {
    return this.registerStore.changeState(RegisterState.ENTER_DELAYED_SELL_DATA);
  }
}

// Used when selecting burning points value
export class QuickCallEnterBurningPoints extends QuickCall {
  protected data: QuickCallData;

  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  async onExecute(): Promise<void> {
    if (this.registerStore.promoFlow.value instanceof PointsBurningFlow) {
      this.registerStore.promoFlow.value.burnPoints(this.value);
    }
  }
}

export class QuickCallCashQuickPick extends QuickCall {
  protected data: QuickCallData;

  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  async onExecute(): Promise<void> {
    await this.registerStore.onEventInput({
      type: RegisterActions.ENTER,
      value: this.value,
    });
  }
}

export class QuickCallArticleSubmenu extends QuickCall<QuickCallArticleSubmenuData> {
  get showItemImages() {
    return this.data?.showItemImages ?? null;
  }
}

export class QuickCallOpenWorkflow extends QuickCall<QuickCallOpenWorkflowData> {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  get isCustomButton() {
    return true;
  }

  async onExecute(): Promise<void> {
    if (this.data?.workflowCode) {
      this.registerStore.dispatchEvent(new CustomEvent(RegisterStoreEvent.OPEN_WORKFLOW_FROM_QUICK_CALL, {
        detail: {
          workflowCode: this.data.workflowCode,
        },
      }));
    }
  }
}

export class QuickCallFinishReceipt extends QuickCall {
  get icon(): PhIcons {
    return this.data?.icon ?? null;
  }

  get isCustomButton() {
    return true;
  }

  async onExecute(): Promise<void> {
    this.registerStore.sellDocument.value.disabledMandatoryPhysicalPrint = true;
    await this.registerStore.persist();
    this.registerStore.saveSellDocument();
  }
}
