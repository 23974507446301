import {TransitionDefinition} from './helpers';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import cancelMode from './cancelMode';
import customerSearch from './customerSearch';
import enterArticleNumber from './enterArticleNumber';
import enterArticlePrice from './enterArticlePrice';
import enterArticleQuantity from './enterArticleQuantity';
import enterCustomerCard from './enterCustomerCard';
import enterLogisticCode from './enterLogisticCode';
import enterPhoneNumber from './enterPhoneNumber';
import enterSerialNumber from './enterSerialNumber';
import returnReasonCode from './returnReasonCode';
import enterValidationCode from './enterValidationCode';
import phoneNumberConfirm from './phoneNumberConfirm';
import productSearch from './productSearch';
import selectGift from './selectGift';
import selectCheckAndChargeLotVariation from './selectCheckAndChargeLotVariation';
import selectPayment from './selectPayment';
import validateAgeRestriction from './validateAgeRestriction';
import originalDocumentReference from './originalDocumentReference';
import burnPoints from './burnPoints';
import answerQuestion from './answerQuestion';
import gecoGame from './gecoGame';
import enterCustomData from './enterCustomData';
import fillInCustomerCard from './fillInCustomerCard';
import fillInCustomerCardSearch from './fillInCustomerCardSearch';
import unassignedCard from './unassignedCard';
import connectCardToCustomer from './connectCardToCustomer';
import paymentsExit from '@/Modules/Register/store/transitions/paymentsExit';
import readonlyArticleSelected from './readonlyArticleSelected';
import enterOriginalDocumentFiscalReference
  from '@/Modules/Register/store/transitions/enterOriginalDocumentFiscalReference';
import enterDelaySellData from '@/Modules/Register/store/transitions/enterDelaySellData';
import enterCustomDataQuestion from '@/Modules/Register/store/transitions/enterCustomDataQuestion';
import enterInvoiceNumber from '@/Modules/Register/store/transitions/enterInvoiceNumber';
import stockInStores from '@/Modules/Register/store/transitions/stockInStores';
import selectDynamicSetSelection from '@/Modules/Register/store/transitions/dynamicSetSelection';
import selectDynamicSetLevelsSelection from '@/Modules/Register/store/transitions/dynamicSetLevelsSelection';
import contextualProductRecommendationSelection from './contextualProductRecommendationSelection';

export const getTransitions = function(this: RegisterStore): TransitionDefinition {
  return {
    ...burnPoints.call(this),
    ...answerQuestion.call(this),
    ...gecoGame.call(this),
    ...cancelMode.call(this),
    ...connectCardToCustomer.call(this),
    ...customerSearch.call(this),
    ...enterArticleNumber.call(this),
    ...enterArticlePrice.call(this),
    ...enterArticleQuantity.call(this),
    ...enterCustomData.call(this),
    ...enterCustomDataQuestion.call(this),
    ...enterDelaySellData.call(this),
    ...enterCustomerCard.call(this),
    ...enterLogisticCode.call(this),
    ...enterPhoneNumber.call(this),
    ...enterSerialNumber.call(this),
    ...enterInvoiceNumber.call(this),
    ...fillInCustomerCard.call(this),
    ...fillInCustomerCardSearch.call(this),
    ...returnReasonCode.call(this),
    ...originalDocumentReference.call(this),
    ...enterValidationCode.call(this),
    ...phoneNumberConfirm.call(this),
    ...contextualProductRecommendationSelection.call(this),
    ...paymentsExit.call(this),
    ...productSearch.call(this),
    ...selectCheckAndChargeLotVariation.call(this),
    ...selectGift.call(this),
    ...selectDynamicSetSelection.call(this),
    ...selectDynamicSetLevelsSelection.call(this),
    ...selectPayment.call(this),
    ...stockInStores.call(this),
    ...unassignedCard.call(this),
    ...validateAgeRestriction.call(this),
    ...readonlyArticleSelected.call(this),
    ...enterOriginalDocumentFiscalReference.call(this),
  };
};
