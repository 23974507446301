
import {useStockInStores} from '@/Components/ModalStockInStores/stockInStores';
import PhIcon from '@/Components/PhIcon.vue';
import {computed} from 'vue';

export default {
  name: 'StockInStoresHeader',
  components: {PhIcon},
  setup() {
    const {
      product,
      close,
      onlyWithStock,
      setOnlyWithStockSwitch,
    } = useStockInStores();

    return {
      product,
      close,
      onlyWithStock: computed({
        get: () => onlyWithStock.value,
        set: (value) => setOnlyWithStockSwitch(value),
      }),
    };
  },
};
