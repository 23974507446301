import {useRouter} from 'vue-router';
import {WorkflowCodes} from '@/Modules/Workflow/types';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {DocumentItemDto} from '@/Model/Entity';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';

export const useWorkflowEntries = () => {
  const registerStore = useRegisterStore();
  const workflowStore = useWorkflowStore();
  const router = useRouter();

  const openCustomerRegistration = async (cardNumber = null) => {
    if (!await registerStore.amIOnline()) return;
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.customerRegistration,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        ...(cardNumber ? {
          params: JSON.stringify({cardNumber: cardNumber}),
        } : {}),
      },
    });
  };

  const openCustomerAddCard = async (customerNumber: string, {cardNumber = null}: {cardNumber?: string} = {}) => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.customerAddCard,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({customerNumber, cardNumber}),
      },
    });
  };

  const openCustomerUpdate = async (customerNumber: string) => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.customerUpdate,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({customerNumber}),
      },
    });
  };

  const openCustomerPhoneChange = async (customerNumber: string) => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.customerPhoneChange,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({customerNumber}),
      },
    });
  };

  const openCustomerUpdateAdditionalInformation = async (customerNumber: string) => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.customerUpdateAdditionalInformation,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({customerNumber}),
      },
    });
  };

  const openCustomerVerify = async (customerNumber: string) => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.customerVerification,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({customerNumber}),
      },
    });
  };

  const openGenericWorkflow = async (
    workflowCode,
    {
      referer = router.currentRoute.value.fullPath,
      fresh = 'true',
      params = JSON.stringify({}),
    } = {},
  ) => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode,
      },
      query: {
        referer,
        fresh,
        params,
      },
    });
  };

  const openProductExternalConfiguration = async (workflowCode, product: DocumentItemDto) => {
    const articleBucketKey = workflowStore.saveBucket(product.toJson());

    await router.replace({
      name: 'workflow',
      params: {
        workflowCode,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({articleBucketKey}),
      },
    });
  };

  /**
   * TODO: Use generic workflow instead
   */
  const openKorunkaCheckTicket = async () => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.korunkaLotteryCheckTicket,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({}),
      },
    });
  };

  /**
   * TODO: Use generic workflow instead
   */
  const openKorunkaScanTicket = async () => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.korunkaLotteryScanTicket,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({}),
      },
    });
  };

  /**
   * TODO: Use generic workflow instead
   */
  const openKorunkaScanCard = async () => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.korunkaLotteryScanCard,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({}),
      },
    });
  };

  /**
   * TODO: Use generic workflow instead
   */
  const openStockBalancing = async () => {
    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: WorkflowCodes.stockBalancing,
      },
      query: {
        referer: router.currentRoute.value.fullPath,
        fresh: 'true',
        params: JSON.stringify({}),
      },
    });
  };


  return {
    openGenericWorkflow,
    openCustomerAddCard,
    openCustomerPhoneChange,
    openCustomerRegistration,
    openCustomerUpdate,
    openCustomerUpdateAdditionalInformation,
    openCustomerVerify,
    openProductExternalConfiguration,
    openKorunkaCheckTicket,
    openKorunkaScanTicket,
    openKorunkaScanCard,
    openStockBalancing,
  };
};
