import {
  createEntity,
  EntityType,
  ObjectType,

} from '@designeo/apibundle-js';
import {
  WarehouseOperationLogisticDocumentAttributes,
  WarehouseOperationBaseAttributes,
  WarehouseOperationFilterAttributes,
  WarehouseOperationPatch,
  WarehouseOperationSummaryAttributes,

} from '@/Model/Entity/patches/WarehouseOperationMixin';
import DistributionCenterDto from '@/Model/Entity/DistributionCenterDto';

export default class WarehouseOperationReturnOfGoodsToDistributionCenterDtoCustom extends WarehouseOperationPatch(
  createEntity({
    ...WarehouseOperationBaseAttributes,
    ...WarehouseOperationLogisticDocumentAttributes,
    ...WarehouseOperationSummaryAttributes,
    distributionCenter: {
      type: new EntityType(DistributionCenterDto),
    },
  }),
) {}
