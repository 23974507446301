import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const VATDtoPropDef = {
  percentage: {
    type: new NumberType(),
  },
  totalWithVAT: {
    type: new NumberType(),
  },
  totalWithoutVAT: {
    type: new NumberType(),
  },
  vatValue: {
    type: new NumberType(),
  },
  fiscalIndex: {
    type: new NumberType(),
  },
  sapFiscalIndex: {
    type: new StringType(),
  },
};
export default class GeneratedVATDto extends createEntity(VATDtoPropDef) {}
