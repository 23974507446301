import {
  createConfigureStore,
  createUseStore,
  getter,
  Store,
  action,
} from '@designeo/vue-helpers';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export enum KeyboardShortcutsEvents {
  shortcutHit = 'shortcutHit',
  registerShortcut = 'registerShortcut',
  unregisterShortcut = 'unregisterShortcut'
}

export interface IKeyboardShortcutsStore {
  visible?: boolean
  active?: boolean
}

const createInitState = (data?: Partial<IKeyboardShortcutsStore>): IKeyboardShortcutsStore => ({
  visible: true,
  active: true,
});

export class KeyboardShortcutsStore extends Store<IKeyboardShortcutsStore> {
  constructor() {
    super(createInitState());
  }

  configuration = getter(() => {
    return useConfigurationStore().configuration.value;
  })

  setVisible = action((state: boolean) => {
    this.state.visible = state;
  })

  visible = getter(() => {
    return this.state.visible;
  })

  active = getter(() => {
    return this.state.active;
  })

  shortcutsByGroup = getter(() => {
    /**
     * NYI
     */
    return {

    };
  })
}

const storeIdentifier = 'KeyboardShortcutsStore';

export const configureKeyboardShortcutsStore = createConfigureStore<typeof KeyboardShortcutsStore>(storeIdentifier);
export const useKeyboardShortcutsStore = createUseStore(KeyboardShortcutsStore, storeIdentifier);
