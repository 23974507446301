import './style/main.css';
import './Helpers/preventScanningKeyShortcuts';
import createPosContext from './contexts/pos';
import {createApp} from 'vue';
import {registerDevTools} from './Helpers/devTools';
import {emitTestEvent} from '@/Helpers/testEvent';

(async function() {
  registerDevTools(); // TODO: dev condition!

  const ctx = await createPosContext({
    createApp,
    ssrState: window['__SSR_STATE__'],
  });

  if (ctx.isSplashScreen) return;

  const ssrRendered = !!window['__SSR_STATE__'];

  ctx.app.use(ctx.router);

  await ctx.router.isReady();

  ctx.ensureStartupClose();

  ctx.app.mount('#app', ssrRendered);

  emitTestEvent('ready', '*');
})();


