import {RegisterRoutesOptions} from '@/ModuleSystem';

export default [
  {
    path: '/apps/inventory/login',
    name: 'apps-inventory-login',
    meta: {authorization: false},
    component: () => import('./Login/LoginPage.vue'),
  },
  {
    path: '/apps/inventory/bar-code',
    name: 'apps-inventory-bar-code',
    component: () => import('./BarCode/BarCodePage.vue'),
  },
  {
    path: '/apps/inventory/choose-stock-type',
    name: 'apps-inventory-choose-stock-type',
    component: () => import('./ChooseStockType/ChooseStockTypePageWrapper.vue'),
  },
  {
    path: '/apps/inventory/stock/:stockType',
    name: 'apps-inventory-stock',
    meta: {
      disableEscapeListener: true,
    },
    component: () => import('./Stock/StockPage.vue'),
  },
] as RegisterRoutesOptions;
