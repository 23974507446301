import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ContentPrintType from '../ContentPrintType';
import DocumentContentFormat from '../DocumentContentFormat';
import {BooleanType} from '@designeo/apibundle-js';


export const DocumentContentDtoPropDef = {
  id: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(ContentPrintType),
  },
  source: {
    type: new StringType(),
  },
  contentBase64: {
    type: new StringType(),
  },
  ownerId: {
    type: new StringType(),
  },
  format: {
    type: new EntityType(DocumentContentFormat),
  },
  isSensitive: {
    type: new BooleanType(),
  },
};
export default class GeneratedDocumentContentDto extends createEntity(DocumentContentDtoPropDef) {}
