import {createApp} from 'vue';
import StartupPage from '@/Modules/Startup/Startup/StartupPage.vue';
import {registerVueI18n} from '@/Plugins/i18n';
import {registerModuleSystem} from '@/ModuleSystem';
import {createContext} from '@designeo/js-helpers/src/index';
import {isElectron} from '@/Helpers/app';
import {registerTestHelpers} from '@/Plugins/testHelpers';
import {AppVersion} from '@/constants/appVersion';
import {RouterTree} from '@/ModuleSystem/routerTree';
import {registerFeatures} from '@/Plugins/features';

export async function createStartup(routerTree: RouterTree) {
  const app = createApp(StartupPage, {
    appVersion: AppVersion.Startup,
    isElectron: isElectron(),
  });

  const ctx = createContext({
    app,
    appId: {},
    routerTree,
  })
    .register(registerFeatures)
    .register(registerTestHelpers)
    .register(registerModuleSystem())
    .register((ctx) => {
      ctx.locale = (navigator.language || 'en').substring(0, 2);
      ctx.registerTranslations({});
    })
    .register(registerVueI18n);

  const resolvedCtx = await ctx.resolve();

  return resolvedCtx.app;
}
