import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import InStoreSearchDto from '../InStoreSearchDto';


export const InStoreSearchFilterDtoPropDef = {
  filters: {
    type: new ArrayType(new EntityType(InStoreSearchDto)),
  },
};
export default class GeneratedInStoreSearchFilterDto extends createEntity(InStoreSearchFilterDtoPropDef) {}
