import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {apiCustomersCreate} from '@/Model/Action';
import {CreateCustomerDto} from '@/Model/Entity';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export class WorkflowStepApiRegisterCustomer extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiRegisterCustomer;
  }

  get type() {
    return WorkflowStepApiRegisterCustomer.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    if (!this.stepFinished) {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      try {
        const customer = await apiCustomersCreate({
          input: new CreateCustomerDto({
            gdprConsentDate: new Date().toISOString(),
            gdprConsentPlace: this.configurationStore.configuration.value.general.pos.shop.code,
            ...this.data,
          }),
        });

        if (customer) {
          emitTestEvent(TestEvent.CUSTOMER_CREATED, JSON.parse(JSON.stringify(customer.toJson())));
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.SET_REFERER_QUERY_ARGS, {
            detail: {customerNumber: customer.customerNumber},
          }));

          this.dataSetter(WorkflowStepField.customerNumber, () => customer.customerNumber);
          this.stepFinished = true;
        }

        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      } catch (e) {
        console.error(e);
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.USER_CREATE_FAILED,
            value: e,
          },
        }));
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.SET_ACTIVE_STEP_BY_CODE, {
          detail: {
            code: WorkflowStepTypes.EnterCustomerInformation,
          },
        }));
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
        throw e;
      }
    } else {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    }
  }

  get transitions() {
    return {};
  }
}
