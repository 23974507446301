import {GiftSelectionFlow} from '@/Modules/Register/PromoInteractionFlow';
import {
  first,
  filter,
  some,
} from 'lodash-es';
import {DocumentGiftPool, PromotionMetaType} from '@designeo/pos-promotion-engine';
import {
  RegisterActions,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.SELECT_GIFT]: {
      [RegisterActions.INIT]: action(async ({
        previousState,
        giftPool: selectedGiftPool,
      }: {
        previousState: RegisterState,
        giftPool?: DocumentGiftPool
      }) => {
        const nextStep = previousState;

        if (selectedGiftPool && !some(
          this.sellDocument.value.promotions,
          (promo) => promo.type === PromotionMetaType.GIFT_POOL && promo.giftPool === selectedGiftPool.giftPool,
        )) {
          await this.changeState(previousState);
          return;
        }

        await this.withAppLoader(async () => {
          const giftPool = <DocumentGiftPool>(selectedGiftPool ?? first(filter(
            this.pendingPromoInteractions.value,
            (promo) => promo.type === 'GiftPool',
          )));
          if (giftPool) {
            const promoFlow = await GiftSelectionFlow.new(giftPool, nextStep);
            await promoFlow.findAvailableGifts();
            await this.setPromoFlow(promoFlow);
            await this.persist();
          } else {
            await this.changeState(nextStep);
          }
        });
      }),
    },
  };
}
