
import {computed, defineComponent} from 'vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import IconCheck from '@/Components/Icons/IconCheck.vue';

export default defineComponent({
  name: 'ProgressBar',
  components: {IconCheck},
  setup() {
    const workflowStore = useWorkflowStore();

    const points = computed(() => {
      return Array.from({length: workflowStore.currentWorkflow.value.visibleSteps.of}, (val, key) => {
        const isFinished = (key + 1) < workflowStore.currentWorkflow.value.visibleSteps.step;
        const isActive = (key + 1) === workflowStore.currentWorkflow.value.visibleSteps.step;

        return {
          isFinished,
          isActive,
          classList: isFinished ? 'bg-success' : isActive ? 'bg-secondary' : 'bg-basic-darkest',
        };
      });
    });

    const successBarStyles = computed(() => {
      const steps = workflowStore.currentWorkflow.value.visibleSteps.of;
      const activeStepIndex = workflowStore.currentWorkflow.value.visibleSteps.step - 1;

      return {
        width: `calc(${(activeStepIndex / (steps - 1)) * 100}% - (2rem * ${activeStepIndex / (steps - 1)}))`,
      };
    });

    return {
      workflowStore,
      points,
      successBarStyles,
    };
  },
});
