import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen max-h-screen overflow-hidden bg-app-bg text-basic" }
const _hoisted_2 = { class: "grid grid-cols-3 app-header font-bold uppercase shadow px-5 py-3 text-sm" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { class: "self-center" }
const _hoisted_5 = { class: "text-right" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: "inline-flex flex-row items-center gap-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reloadIframe && _ctx.reloadIframe(...args)))
        }, [
          _createVNode(_component_PhIcon, {
            icon: "arrowClockwise",
            size: 22
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('action.reload')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "inline-flex flex-row items-center gap-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
        }, [
          _createVNode(_component_PhIcon, {
            icon: 'x',
            size: 22
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('action.close')), 1)
        ])
      ])
    ]),
    _createElementVNode("webview", {
      ref: "view",
      class: "w-screen h-screen",
      src: _ctx.url.toString(),
      allowpopups: "",
      frameborder: "0"
    }, null, 8, _hoisted_6)
  ]))
}