import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleFilePreviewDto from '../ArticleFilePreviewDto';


export const ArticleFileListingResultDtoPropDef = {
  files: {
    type: new ArrayType(new EntityType(ArticleFilePreviewDto)),
  },
};
export default class GeneratedArticleFileListingResultDto extends createEntity(ArticleFileListingResultDtoPropDef) {}
