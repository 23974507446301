
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import NearbyShopStockDto from '../../Model/Entity/NearbyShopStockDto';
import {isNil} from 'lodash-es';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<NearbyShopStockDto>,
      required: true,
    },
  },
  setup(props) {
    const {numberWithPolarityFormat} = useFilters();

    const stock = computed(() => {
      return [
        ...(!isNil(props.item.count) ? [`${props.item.count} ${props.item.unitByEnum}`] : []),
        ...(!isNil(props.item.inTransitCount) ? [`(${numberWithPolarityFormat(props.item.inTransitCount)})`] : []),
      ].join(' ');
    });

    const cells = computed(() => {
      return [
        props.item.shopCode,
        props.item.shopStreet,
        props.item.shopAddress,
        stock.value,
      ];
    });

    return {
      cells,
    };
  },
});
