
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'StepSafeBagDeliveryListOverview',
  components: {WorkflowTransitions, FormInput, HLInput, HLStep},
  setup() {
    const stepType = WorkflowStepTypes.SafeBagDeliveryListOverview;

    const {currencyFormat} = useFilters();

    return {
      currencyFormat,
      stepType,
      WorkflowStepField,
    };
  },
});
