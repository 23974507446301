
import {defineComponent} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalConfirmContent from '@/Components/ModalConfirmContent.vue';

export default defineComponent({
  name: 'ModalCustomDataQuestion',
  components: {
    ModalConfirmContent,
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['reject', 'confirm'],
  setup(props, {emit}) {
    const onReject = async () => {
      emit('reject');
    };

    const onConfirm = async () => {
      emit('confirm');
    };

    return {
      onReject,
      onConfirm,
    };
  },
});
