import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_test = _resolveDirective("test")!

  return (_ctx.isVisible)
    ? _withDirectives((_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        class: "mr-1",
        to: {name: _ctx.isMessagesPage ? 'register' : 'apps-messages'}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PhIcon, {
              icon: "envelope",
              size: 32
            }),
            (_ctx.unreadImportantMessages.length || _ctx.unreadSensitiveMessages.length)
              ? (_openBlock(), _createBlock(_component_PhIcon, {
                  key: 0,
                  icon: "warning-circle",
                  size: 22,
                  class: _normalizeClass([_ctx.unreadImportantMessages.length ? 'bg-danger' : _ctx.unreadSensitiveMessages.length ? 'bg-warning' : '', "text-white rounded-full absolute -bottom-1.5 -left-2.5 text-sm text-center"])
                }, null, 8, ["class"]))
              : _createCommentVNode("", true),
            (_ctx.unreadMessages.length > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["rounded-full bg-danger absolute -top-2 -right-2.5 h-5 text-white flex justify-center items-center", {
          'w-7 text-xs': _ctx.unreadMessages.length > 99,
          'w-5 text-sm': _ctx.unreadMessages.length <= 99,
        }])
                }, _toDisplayString(_ctx.unreadMessages.length > 99 ? '99+' : _ctx.unreadMessages.length), 3))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["to"])), [
        [_directive_test, 'messagesLink']
      ])
    : _createCommentVNode("", true)
}