import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {first, isEmpty} from 'lodash-es';
import CustomerAdditionalDataDto from '@/Model/Entity/CustomerAdditionalDataDto';
import {CustomDataDto} from '@/Model/Entity';

export default function(this: RegisterStore): TransitionDefinition {
  const addCharToActiveField = (value) => {
    if (isEmpty(this.state.customData.formEntity[this.state.customData.activeField])) {
      this.state.customData.formEntity[this.state.customData.activeField] = value;
    } else {
      this.state.customData.formEntity[this.state.customData.activeField] += value;
    }
  };

  const removeCharFromActiveField = () => {
    if (!isEmpty(this.state.customData.formEntity[this.state.customData.activeField])) {
      this.state.customData.formEntity[this.state.customData.activeField] = (
        this.state.customData.formEntity[this.state.customData.activeField]
          .slice(0, this.state.customData.formEntity[this.state.customData.activeField].length - 1)
      );
    }
  };

  const moveNext = () => {
    const indexOfActiveField = this.customDataFields.value.indexOf(this.state.customData?.activeField);
    if (indexOfActiveField !== this.customDataFields.value.length - 1) {
      this.state.customData.activeField = this.customDataFields.value[indexOfActiveField + 1];
      return true;
    }

    return false;
  };

  const movePrev = () => {
    const indexOfActiveField = this.customDataFields.value.indexOf(this.state.customData?.activeField);
    if (indexOfActiveField !== 0) {
      this.state.customData.activeField = this.customDataFields.value[indexOfActiveField - 1];
      return true;
    }

    return false;
  };

  return {
    [RegisterState.ENTER_CUSTOM_DATA]: {
      [RegisterActions.BEFORE_LEAVE]: action(() => {
        this.state.customData = null;
      }),
      [RegisterActions.INIT]: action(() => {
        this.state.customData = {
          activeField: this.state.customData?.activeField ??
            first(this.customDataFields.value),
          formEntity: this.state.customData?.formEntity ??
            this.state.sellDocument.customData?.customerData?.clone() ?? new CustomerAdditionalDataDto({}),
        };
      }),
      [RegisterActions.ADD_NUMBER]: action(async (event: RegisterInputEvent<string>) => {
        addCharToActiveField(event.value);
      }),
      [RegisterActions.ADD_CHAR]: action(async (event: RegisterInputEvent<string>) => {
        addCharToActiveField(event.value);
      }),
      [RegisterActions.BACKSPACE]: action(async () => {
        removeCharFromActiveField();
      }),
      [RegisterActions.CANCEL]: action(async () => {
        this.state.customData = null;
        await this.changeState(RegisterState.SELECT_PAYMENT, {resetBuffer: false});
      }),
      [RegisterActions.PREV]: action(() => {
        movePrev();
      }),
      [RegisterActions.NEXT]: action((event: RegisterInputEvent) => {
        if (event.value) {
          this.state.customData.activeField = event.value;
          return;
        }

        moveNext();
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!event.value && moveNext()) {
          return;
        }

        this.state.sellDocument.expandCustomData({
          customerData: this.state.customData.formEntity,
        });

        this.state.customData = null;

        const validationResult = this.state.sellDocument.customData.customerData
          .validate({required: this.areCustomDataMandatory.value});

        if (
          validationResult.valid &&
          this.state.sellDocument.balanceIsZero
        ) {
          await this.saveSellDocument();
        } else {
          await this.changeState(RegisterState.SELECT_PAYMENT, {resetBuffer: false});
        }
      }),
    },
  };
}
