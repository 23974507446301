import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';

export class WorkflowStepBTCTransactionCheck extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.BTCTransactionCheck;
  }

  get type() {
    return WorkflowStepBTCTransactionCheck.type;
  }

  get component() {
    return markRaw(require('./StepBTCTransactionCheck.vue').default);
  }

  get transitions() {
    return {};
  }
}
