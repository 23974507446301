
import {
  defineComponent,
} from 'vue';
import FormInput from '@/Components/FormInput.vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import InputBuffer from '@/Components/InputBuffer.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepKorunkaEnterTicketCode',
  components: {
    WorkflowTransitions,
    InputBuffer,
    HLInput,
    HLStep,
    FormInput,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaEnterCode;

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
