
import useLayouts from './layouts';
import {useI18n} from 'vue-i18n';
import {computed} from 'vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export default {
  name: 'Qwerty',
  inheritAttrs: false,
  props: {
    attachTo: {
      type: String,
      required: false,
      default: '#qwerty',
    },
    animate: {
      type: Boolean,
      required: false,
      default: true,
    },
    layout: {
      type: String,
      required: false,
      default: () => 'base',
    },
  },
  emits: ['click', 'hide'],
  setup(props, {emit}) {
    const i18n = useI18n();
    const coreStore = useCoreStore();

    const isPdaApp = computed(() => {
      return coreStore.isAppVersionPda.value;
    });

    const {currentLayout} = useLayouts(props.layout, i18n.locale.value, emit, {isPda: isPdaApp.value});

    return {
      currentLayout,
    };
  },
};
