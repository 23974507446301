import {createEntity, NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class EasyCoinOrderDtoCustom extends createEntity({
  fiat_amount: {
    type: new NumberType(),
  },
  fiat_code: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  direction: {
    type: new StringType(),
  },
  id: {
    type: new NumberType(),
  },
  payment_num: {
    type: new StringType(),
  },
  status: {
    type: new StringType(),
  },
}) {}
