import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import NewDocumentNumberCounterDto from '../NewDocumentNumberCounterDto';


export const SetDocumentCounterDtoPropDef = {
  counters: {
    type: new ArrayType(new EntityType(NewDocumentNumberCounterDto)),
  },
};
export default class GeneratedSetDocumentCounterDto extends createEntity(SetDocumentCounterDtoPropDef) {}
