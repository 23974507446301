
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WarehouseOperationConfirmInfo} from '@/constants/warehouseOperations';
import {
  map,
} from 'lodash-es';
import {
  WorkflowStepWarehouseOperationConfirm,
} from '@/Modules/Workflow/Step/StepWarehouseOperationConfirm/WorkflowStepWarehouseOperationConfirm';
import {unitsByItems} from '@/Helpers/unit';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFilters} from '@/Helpers/filters';
import {useI18n} from 'vue-i18n';
import AsyncButton from '@/Components/AsyncButton.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';

export default defineComponent({
  name: 'StepWarehouseOperationConfirm',
  components: {
    IconCheck,
    AsyncButton,
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const i18n = useI18n();
    const stepType = WorkflowStepTypes.WarehouseOperationConfirm;
    const {workflowStep} = useStepTools<WorkflowStepWarehouseOperationConfirm>(stepType);
    const configurationStore = useConfigurationStore();
    const {currencyFormat} = useFilters();

    const formatSummaryValueByField = (field, value) => {
      switch (field as WarehouseOperationConfirmInfo) {
      case WarehouseOperationConfirmInfo.documentSubType:
        return i18n.t(`apps.documents.types.${value ?? 'unknown'}`);
      case WarehouseOperationConfirmInfo.totalPrice:
        return currencyFormat(
          value,
          configurationStore.localCurrency.value.symbol,
        );
      case WarehouseOperationConfirmInfo.totalAmount:
        return unitsByItems(value, 'unit', 'quantity').join('; ');
      default:
        return value;
      }
    };

    const summary = computed(() => {
      return map(workflowStep.value.summary, ({value, label, field}) => {
        return {
          field,
          label,
          value: formatSummaryValueByField(field, value),
        };
      });
    });

    return {
      stepType,
      WorkflowStepField,
      summary,
    };
  },
});
