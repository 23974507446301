import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {apiSearchByAttribute} from '@/Model/Action';
import {
  groupBy,
  find,
  map,
  mapValues,
} from 'lodash-es';
import {DocumentItemDto, LotteryTurnoversDto} from '@/Model/Entity';
import {AppLoaderEvent} from '@/Modules/Core/types';

export class WorkflowStepEnterLotteryArticles extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterLotteryArticles;
  }

  get type() {
    return WorkflowStepEnterLotteryArticles.type;
  }

  get component() {
    return markRaw(require('./StepEnterLotteryArticles.vue').default);
  }

  get stepCategory() {
    return this.step.categoryId;
  }

  get isResolvedByCategory() {
    return this.configurationStore.configuration.value
      .features
      .lottery
      .articleCategories[this.stepCategory]
      .resolveByCategory;
  }

  get categories() {
    return mapValues(this.configurationStore.configuration.value.features.lottery.articleCategories, 'title');
  }

  get lotteryArticlesFromInaccurateCategories() {
    return map(this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []), (article) => {
      return new DocumentItemDto(article ?? {});
    });
  }

  get lotteryTurnovers() {
    return new LotteryTurnoversDto(this.getFieldValue(WorkflowStepField.lotteryTurnovers, {}));
  }

  get articlesByInaccurateCategoryId() {
    return groupBy(this.lotteryArticlesFromInaccurateCategories, 'parentId')[this.stepCategory];
  }

  get lotteryAmountEquals() {
    return this.getFieldValue(WorkflowStepField.lotteryAmountEquals, false);
  }

  get canBeReturnedTo() {
    return this.lotteryAmountEquals === false;
  }

  getFieldKeyByInternalNumber(internalNumber) {
    return [
      WorkflowStepField.lotteryArticles,
      internalNumber,
      WorkflowStepField.amount,
    ].join('.');
  }

  async beforeEnter() {
    if (this.lotteryAmountEquals) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const categoryAmountKey = [
        WorkflowStepField.lotteryCategories,
        this.stepCategory,
        WorkflowStepField.amount,
      ].join('.');

      const enteredAmount: number = parseFloat(this.getFieldValue(categoryAmountKey, '0'));

      const actualAmount: number = this.lotteryTurnovers
        .turnoverCategoriesByCategoryId
        ?.[this.stepCategory]
        ?.totalAmount ?? 0;

      const isCategoryInnacurate = enteredAmount !== actualAmount;

      if (isCategoryInnacurate) {
        const searchResults = await apiSearchByAttribute({params: {attribute: 'parentId', value: this.stepCategory}});
        const articles = map(searchResults, ({documentItem}) => documentItem.toJson()) ?? [];

        this.dataSetter(
          WorkflowStepField.lotteryArticlesFromInaccurateCategories,
          () => !this.isResolvedByCategory ?
            articles :
            [find(articles, (article) => article.internalNumber === this.stepCategory)],
        );
      }
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeContinue() {
    if (this.lotteryAmountEquals) return;
    if (!this.isResolvedByCategory) {
      const inaccurateArticles = [];

      const articles = groupBy(
        this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []),
        'parentId',
      )[this.stepCategory] ?? [];

      for (const article of articles) {
        const field = this.getFieldKeyByInternalNumber(article.internalNumber);
        const enteredAmount: number = parseFloat(this.getFieldValue(field, '0'));
        const valueBeforeDiscounts: number = this.lotteryTurnovers.getArticleTotalAmount(article);

        if (enteredAmount !== valueBeforeDiscounts) {
          inaccurateArticles.push(article);
        }
      }

      this.dataSetter(WorkflowStepField.inaccurateArticlesByCategory, (currentValue = {}) => {
        return {
          ...currentValue,
          ...(inaccurateArticles.length ? {[this.stepCategory]: inaccurateArticles} : {}),
        };
      });
    } else {
      try {
        const article = find(
          this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []),
          (article: DocumentItemDto) => article.internalNumber === this.stepCategory,
        ) ?? null;

        if (article) {
          const field = `${WorkflowStepField.lotteryCategories}.${this.stepCategory}.${WorkflowStepField.amount}`;
          const enteredAmount: number = parseFloat(this.getFieldValue(field, '0'));
          const valueBeforeDiscounts: number = this.lotteryTurnovers.getArticleTotalAmount(article);

          if (enteredAmount !== valueBeforeDiscounts) {
            // treat category as if it was article - used for diffing and correction
            this.dataSetter(
              this.getFieldKeyByInternalNumber(article.internalNumber),
              () => enteredAmount,
            );
            // set default category article
            this.dataSetter(WorkflowStepField.inaccurateArticlesByCategory, (currentValue = {}) => {
              return {
                ...currentValue,
                ...(article ? {[this.stepCategory]: article} : {}),
              };
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  get transitions() {
    const fields = map(this.lotteryArticlesFromInaccurateCategories, (article) => {
      return this.getFieldKeyByInternalNumber(article.internalNumber);
    });
    return {
      ...map(fields, (field, index: number) => {
        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
            }),
            [WorkflowActions.ADD_MINUS]: action(() => {
              fieldActions[WorkflowActions.ADD_MINUS]();
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_COMMA](event.value);
            }),
            [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_PERIOD](event.value);
            }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = fields[index + 1] ?? fields[index];

              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                detail: {
                  field: nextField,
                },
              }));
            }),
            ...this.createArrowMovementTransitions(fields, index),
          })),
        };
      }).reduce((acc, val, index) => ({...acc, ...val}), {}),
    };
  }
}
