import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow flex flex-col" }
const _hoisted_3 = { class: "flex-grow flex flex-col overflow-y-auto" }
const _hoisted_4 = { class: "border-b border-basic-light py-2" }
const _hoisted_5 = { class: "flex flex-row justify-between" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "flex flex-row gap-0.5 font-bold" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarehouseOperationGoodsList = _resolveComponent("WarehouseOperationGoodsList")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_ModalSearchProducts = _resolveComponent("ModalSearchProducts")!
  const _component_ModalStockInStores = _resolveComponent("ModalStockInStores")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_WarehouseOperationGoodsList, {
              class: "max-h-0",
              list: _ctx.list,
              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.articleDetail = $event))
            }, null, 8, ["list"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.summary.items')) + " " + _toDisplayString(_ctx.list.length), 1),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.totalUnits)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('workflow.steps.warehouseOperationGoodsList.summary.total')) + " " + _toDisplayString(_ctx.totalUnits), 1))
                  : _createCommentVNode("", true),
                (_ctx.totalPrice && _ctx.totalUnits)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, " / "))
                  : _createCommentVNode("", true),
                (_ctx.totalPrice)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.totalPrice), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_WorkflowTransitions, { nextDisabled: _ctx.nextDisabled }, null, 8, ["nextDisabled"])
        ]),
        _createVNode(_component_ModalSearchProducts),
        _createVNode(_component_ModalStockInStores)
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}