import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import JournalDto from '../JournalDto';


export const AddJournalRecordDtoPropDef = {
  journal: {
    type: new EntityType(JournalDto),
  },
};
export default class GeneratedAddJournalRecordDto extends createEntity(AddJournalRecordDtoPropDef) {}
