import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const FacadePayOutTicketRequestResourcePropDef = {
  ticketId: {
    type: new NumberType(),
  },
  verificationCode: {
    type: new StringType(),
  },
  operationId: {
    type: new StringType(),
  },
};
export default class GeneratedFacadePayOutTicketRequestResource extends createEntity(FacadePayOutTicketRequestResourcePropDef) {}
