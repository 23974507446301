
import {defineComponent} from 'vue';

import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import LayoutBase from '@/Modules/Workflow/Workflow/layout/LayoutBase.vue';
import IconCross from '@/Components/Icons/IconCross.vue';
import {useStepTools} from '@/Modules/Workflow/Step/StepTools';
import Step from '@/Modules/Workflow/Step/Step.vue';

export default defineComponent({
  components: {
    Step,
    IconCross,
    LayoutBase,
  },
  setup() {
    const workflowStore = useWorkflowStore();
    const {abortWorkflow} = useStepTools(workflowStore.currentWorkflow.value.activeStepType);

    return {
      workflowStore,
      abortWorkflow,
    };
  },
});
