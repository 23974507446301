import {groupBy} from 'lodash-es';
import GeneratedOrderDto from './generated/OrderDto';

export default class OrderDto extends GeneratedOrderDto {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get pickUpWorkflowCode() {
    return this.configurationStore.configuration.value
      ?.features
      ?.eshop
      ?.integrations
      ?.[this.eshop.code]
      ?.workflow
      ?.pickUp.code;
  }

  get stornoWorkflowCode() {
    return this.configurationStore.configuration.value
      ?.features
      ?.eshop
      ?.integrations
      ?.[this.eshop.code]
      ?.workflow
      ?.storno.code;
  }

  get eventsByType() {
    return groupBy(this.events, (event) => event.type.value);
  }
}
