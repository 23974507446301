
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep, useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import ModalSearchProducts from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/ModalSearchProducts.vue';
import WarehouseOperationGoodsList
  from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WarehouseOperationGoodsList.vue';
import {
  WorkflowStepWarehouseOperationGoodsList,
} from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WorkflowStepWarehouseOperationGoodsList';
import {map} from 'lodash-es';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {unitsByItems} from '@/Helpers/unit';
import ModalStockInStores from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/ModalStockInStores.vue';

export default defineComponent({
  name: 'StepWarehouseOperationGoodsList',
  components: {
    ModalStockInStores,
    WarehouseOperationGoodsList,
    ModalSearchProducts,
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.WarehouseOperationGoodsList;
    const configurationStore = useConfigurationStore();
    const {currencyFormat} = useFilters();

    const {workflowStep} = useStepTools<WorkflowStepWarehouseOperationGoodsList>(stepType);

    const articleDetail = computed<any>({
      get() {
        return workflowStep.value.updateArticle;
      },
      set(value) {
        workflowStep.value.openArticleUpdate(value);
      },
    });

    const list = computed(() => {
      return map(
        workflowStep.value.operationEntityPropLogisticDocument.logisticItems,
        (item) => ({
          entity: item,
          isActive: articleDetail.value?.gtin === item.gtin,
          hasVisiblePrices: workflowStep.value.hasVisiblePrices,
        }),
      );
    });

    const totalUnits = computed(() => {
      return unitsByItems(
        workflowStep.value.operationEntityPropLogisticDocument.logisticItems,
        'unit',
        'quantity',
      )
        .join('; ');
    });

    const totalPrice = computed(() => {
      if (!workflowStep.value.hasVisiblePrices) {
        return null;
      }

      return currencyFormat(
        workflowStep.value.operationEntity.totalPrice,
        configurationStore.localCurrency.value.symbol,
      );
    });

    const nextDisabled = computed(() => {
      return !workflowStep.value.canContinue;
    });

    return {
      stepType,
      WorkflowStepField,
      articleDetail,
      list,
      totalUnits,
      totalPrice,
      nextDisabled,
    };
  },
});
