import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _directive_test = _resolveDirective("test")!

  return (!_ctx.transitionLeaving)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalDetachedClose, { onClose: _ctx.onReject }, null, 8, ["onClose"]),
        _createVNode(_component_ModalDetachedBody, {
          class: "pb-5",
          type: _ctx.type
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('posConfiguration.dataConfirm.message')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createVNode(_component_ModalDetachedButtons, null, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("button", {
              class: "btn-success btn-md w-full",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('posConfiguration.dataConfirm.confirm')), 1)
            ], 512), [
              [_directive_test, 'modalConfirm']
            ]),
            _withDirectives(_createElementVNode("button", {
              class: "btn-danger btn-md w-full",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onReject && _ctx.onReject(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('posConfiguration.dataConfirm.reject')), 1)
            ], 512), [
              [_directive_test, 'modalReject']
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}