import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-xl text-center mb-10" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "w-7/12 mx-auto pb-2 flex gap-2 justify-center items-center font-bold" }
const _hoisted_4 = { class: "w-7/12 mx-auto flex flex-col gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPerson = _resolveComponent("IconPerson")!
  const _component_FormInputFloating = _resolveComponent("FormInputFloating")!
  const _component_Keyboard = _resolveComponent("Keyboard")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    type: "lg",
    zIndex: _ctx.ZLayer.aboveSpinner,
    open: _ctx.fourEyesStore.isActive.value,
    silenceCloseListeners: true,
    height: "unlimited",
    bgVariant: "bg-basic-lightest",
    bodyClass: "pt-10 pb-14",
    onClose: _ctx.close
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('fourEyes.title')), 1)
    ]),
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.fourEyesStore.resolveByActivePerson.value ? _ctx.$t('fourEyes.hint') : _ctx.$t('fourEyes.hint2')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.operation), 1)
      ], 512), [
        [_directive_test, 'four-eyes']
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IconPerson, { class: "w-8 inline" }),
        _createTextVNode(" " + _toDisplayString(_ctx.fourEyesStore.cashier.value), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.fourEyesStore.isActiveStateEnterOperatorPersonalNumber.value)
          ? (_openBlock(), _createBlock(_component_FormInputFloating, {
              key: 0,
              label: _ctx.$t('fourEyes.username'),
              active: _ctx.fourEyesStore.isActiveStateEnterOperatorPersonalNumber.value,
              value: _ctx.username,
              error: _ctx.errors.username
            }, null, 8, ["label", "active", "value", "error"]))
          : (_ctx.fourEyesStore.isActiveStateEnterOperatorPersonalPin.value)
            ? (_openBlock(), _createBlock(_component_FormInputFloating, {
                key: 1,
                label: _ctx.$t('fourEyes.password'),
                active: _ctx.fourEyesStore.isActiveStateEnterOperatorPersonalPin.value,
                value: _ctx.password,
                error: _ctx.errors.password
              }, null, 8, ["label", "active", "value", "error"]))
            : _createCommentVNode("", true),
        _createVNode(_component_Keyboard, { onInput: _ctx.onKeyboardInput }, null, 8, ["onInput"])
      ])
    ]),
    _: 1
  }, 8, ["zIndex", "open", "onClose"]))
}