import {App} from 'vue';
import {Router} from 'vue-router';
import {initSentry} from '@/Helpers/sentry';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {IRestoredErrorRecord} from '@/Helpers/restoreMode';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
  router: Router,
  isRestoreMode: boolean,
  restoredErrorRecord: IRestoredErrorRecord
}

export type RegisterTestHelpersContext = {
}

export function registerSentry(ctx: CoreRequiredContext) {
  if (ctx.isRestoreMode) {
    console.warn('[RestoreMode] sentry init bypassed');
    return {};
  }

  const configurationStore = useConfigurationStore();

  initSentry({
    app: ctx.app,
    router: ctx.router,
    config: configurationStore.configuration.value?.secrets?.sentry?.posUi,
  });

  return {};
}
