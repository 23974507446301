import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-grow flex-col" }
const _hoisted_3 = { class: "font-bold uppercase" }
const _hoisted_4 = { class: "list-disc pl-6" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row justify-center pt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(workflowStep.step.conditionsTitle), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workflowStep.step.conditions, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
            }), 128))
          ]),
          (_ctx.repeatRequestIsVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_AsyncButton, {
                  class: "btn-warning",
                  onClick: _ctx.repeatRequest
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(workflowStep.step.repeatRequest), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_WorkflowTransitions, { nextHidden: "" })
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}