import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const CashDataDtoPropDef = {
  sellDocuments: {
    type: new NumberType(),
  },
  finDocuments: {
    type: new NumberType(),
  },
  total: {
    type: new NumberType(),
  },
};
export default class GeneratedCashDataDto extends createEntity(CashDataDtoPropDef) {}
