import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const SwitchShopDtoPropDef = {
  shopCode: {
    type: new StringType(),
  },
};
export default class GeneratedSwitchShopDto extends createEntity(SwitchShopDtoPropDef) {}
