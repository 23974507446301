import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {UpdateTypes} from '@/constants/updateTypes';
import {Configuration} from '@/Model/Entity';
import {apiPosUpdate, apiPosUpdateStatus} from '@/Model/Action';
import {wait} from '@designeo/js-helpers/src/timing/wait';
import {UpdateStatus} from '@/constants/updateStatus';
import {useIncorrectRestartStore} from '@/Modules/Core/store/IncorrectRestartStore';
import {useRecoveryStore} from '@/Modules/Core/store/RecoveryStore';
import {ConfirmTypes, RecoveryTypes} from '@/Modules/Core/types';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import UpdateStatusDto from '@/Model/Entity/UpdateStatusDto';
import {every} from 'lodash-es';
import {seekApiErrors, useErrorParser} from '@/Helpers/errors';
import {sentryRecordException} from '@/Helpers/sentry';

export interface IUpdateStore {
  appVersion: {
    current: string,
    updateAvailable: string,
    updateVersionType: UpdateTypes,
    updateValidFrom: string,
    updateStatus: UpdateStatus,
  },
  startUpdateIn: number,
  timeRemaining: number,
  timeExpired: boolean,
  forcedWarning: boolean
  updateStatus: UpdateStatusDto
  postponedUpdateValidFrom: string
}

const createInitState = (data?: Partial<IUpdateStore>): IUpdateStore => ({
  appVersion: null,
  startUpdateIn: null,
  timeRemaining: null,
  timeExpired: false,
  forcedWarning: false,
  updateStatus: null,
  postponedUpdateValidFrom: null,
});

export class UpdateStore extends Store<IUpdateStore> {
  private timeRemainingTimeout: ReturnType<typeof setTimeout>
  constructor() {
    super(createInitState());
  }

  get millisecondsToUpdate() {
    if (!this.updateValidFrom.value) {
      return null;
    }

    const scheduleDateAsMs = +this.updateValidFrom.value;
    const now = +new Date();


    return Math.max(scheduleDateAsMs - now, 0);
  }

  fetchUpdateStatus = action(async () => {
    try {
      this.state.updateStatus = ((await apiPosUpdateStatus({})) ?? null) as UpdateStatusDto | null;
    } catch (e) {
      console.error(e);
      this.state.updateStatus = null;
    }
  })

  processConfiguration = action((configuration: Configuration) => {
    this.state.appVersion = configuration.general.appVersion;

    this.state.timeRemaining = null;
    this.state.timeExpired = false;

    if (!this.state.appVersion || this.versionAreSame.value) return;

    this.createTimeRemainingTimeout();
  })

  createTimeRemainingTimeout = action(() => {
    const millisecondsToUpdate = this.millisecondsToUpdate;

    if (this.state.timeRemaining === millisecondsToUpdate) {
      this.destroyTimeRemainingTimeout();
      return;
    }


    clearTimeout(this.timeRemainingTimeout);
    this.timeRemainingTimeout = setTimeout(() => {
      this.state.timeRemaining = millisecondsToUpdate;
      this.state.timeExpired = millisecondsToUpdate === 0;
      this.createTimeRemainingTimeout();
    }, 500);
  })

  destroyTimeRemainingTimeout = action(() => {
    clearTimeout(this.timeRemainingTimeout);
  })

  postponeUpdate = action(
    (toDate = (+new Date()) + (30 * 60 * 1000)) => {
      if (!this.state.appVersion) return;

      this.state.postponedUpdateValidFrom = new Date(toDate).toISOString();

      this.state.timeRemaining = null;
      this.state.timeExpired = false;
      this.createTimeRemainingTimeout();
    },
  )

  ensureAppCleanup = action(async () => {
    const incorrectRestartStore = useIncorrectRestartStore();
    const recoveryStore = useRecoveryStore();
    const authStore = useAuthStore();

    if (!authStore.isLoggedIn.value) { // Note we cannot create recovery with no signed user
      return;
    }

    if (!incorrectRestartStore.isActive.value) {
      return;
    }

    if (incorrectRestartStore.canCancelSellDocument.value) {
      return await recoveryStore.recovery(RecoveryTypes.register, {
        error: new Error('update within incorrect restart'),
        withAppReload: false,
        fourEyes: false,
      });
    }

    if (incorrectRestartStore.canCancelWorkflow.value) {
      return await recoveryStore.recovery(RecoveryTypes.workflow, {
        error: new Error('update within incorrect restart'),
        withAppReload: false,
        fourEyes: false,
      });
    }
  })

  startUpdate = action(async () => {
    const coreStore = useCoreStore();
    const parseError = useErrorParser();

    try {
      await this.ensureAppCleanup();

      this.state.startUpdateIn = 60000;
      await wait(this.state.startUpdateIn)(null);

      try {
        coreStore.setLoader(true);
        await apiPosUpdate({});
        await wait(5000)(null);
      } finally {
        coreStore.setLoader(false);
      }
    } catch (e) {
      this.state.startUpdateIn = null;
      console.error(e);
      await coreStore.alert(parseError(seekApiErrors(e)).join(', '), {type: ConfirmTypes.error});
      sentryRecordException(e, {message: 'UpdateFailed'});
    }
  })

  closeDetail = action(() => {
    this.state.forcedWarning = false;
  })

  openDetail = action(() => {
    this.state.forcedWarning = true;
  })

  appVersion = getter(() => {
    return this.state.appVersion;
  })

  forcedWarning = getter(() => {
    return this.state.forcedWarning;
  })

  startUpdateIn = getter(() => {
    return this.state.startUpdateIn;
  })

  updateTriggered = getter(() => {
    return !!this.state.startUpdateIn;
  })

  versionAreSame = getter(() => {
    return this.state.appVersion && this.state.appVersion.current === this.state.appVersion.updateAvailable;
  })

  isUpdateTypeOnDemand = getter(() => {
    return this.state.appVersion?.updateVersionType === UpdateTypes.OnDemand;
  })

  isUpdateTypeImmediate = getter(() => {
    return this.state.appVersion?.updateVersionType === UpdateTypes.Immediate;
  })

  apiUpdateValidFrom = getter(() => {
    if (!this.state.appVersion?.updateValidFrom) return new Date();

    return new Date(this.state.appVersion.updateValidFrom);
  })

  updateValidFrom = getter(() => {
    if (!this.state.appVersion?.updateValidFrom) return new Date();

    if (this.state.postponedUpdateValidFrom) return new Date(this.state.postponedUpdateValidFrom);

    return new Date(this.state.appVersion.updateValidFrom);
  })

  postponedUpdateValidFrom = getter(() => {
    if (!this.state.postponedUpdateValidFrom) {
      return null;
    }

    return new Date(this.state.postponedUpdateValidFrom);
  })

  hasActiveCountdown = getter(() => {
    if (!this.isActive.value) return false;

    /**
     * show countdown if time remaining is lower than 1 hour
     */

    return this.state.timeRemaining < (60 * 60 * 1000);
  })

  hasActiveWarning = getter(() => {
    if (this.updateTriggered.value) {
      return true;
    }

    if (this.versionAreSame.value) {
      return false;
    }

    if (this.isUpdateTypeImmediate.value) {
      return false;
    }

    return this.state.timeRemaining === 0;
  })

  timeRemaining = getter(() => {
    return this.state.timeRemaining;
  })

  allUpdateConditionsAreMet = getter(() => {
    return every(this.updateStatus.value?.conditions ?? [], ({isFulfilled}) => isFulfilled);
  })

  isScheduled = getter(() => {
    return this.state.timeExpired;
  })

  isActive = getter(() => {
    if (this.versionAreSame.value) return false;

    return this.timeRemaining.value !== null;
  })

  updateStatus = getter(() => {
    return this.state.updateStatus;
  })
}

const storeIdentifier = 'UpdateStore';

export const configureUpdateStore = createConfigureStore<typeof UpdateStore>(storeIdentifier);
export const useUpdateStore = createUseStore(UpdateStore, storeIdentifier);
