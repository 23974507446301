/* eslint-disable prefer-const */
import {changeSaleDocumentToStorno} from '@/Helpers/document/storno';
import {DateTime} from 'luxon';
import {
  DocumentGiftPool,
  promotionByType,
  PromotionMeta,
  PromotionMetaType,
  recalculateDocument,
  recalculateDocumentTotalPrice,
} from '@designeo/pos-promotion-engine';
import GeneratedDocumentDto from './generated/DocumentDto';

import DocumentItemDto from './DocumentItemDto';
import {
  every,
  filter,
  find,
  findIndex,
  flatten,
  flow,
  forEach,
  groupBy,
  has,
  includes,
  isNil,
  last,
  map,
  omit,
  reject,
  some,
  sortBy,
  sumBy,
  toPairs,
  values,
} from 'lodash-es';
import {PAYMENT_TYPE_CASH, PAYMENT_TYPE_CREDIT_CARD} from '@/constants/paymentTypes';
import {cashNominalRoundingByStrategy, nominals} from '@/Helpers/nominals';
import {fixDecimals} from '@/Helpers/math';
import {
  DocumentCustomerDto,
  DocumentHeaderDto,
  DocumentLogisticItemDto,
  DocumentModeType,
  DocumentPaymentDto,
  DocumentValidation,
  DocumentValidationCashier,
  InventoryMetadataDto,
  InventoryStockDto,
  ResultDto,
  StockType,
} from '@/Model/Entity/exports';
import {guid} from '@/Helpers/guid';
import {DocumentTypes} from '@/constants/documentTypes';
import {DocumentModeTypes} from '@/constants/documentModeTypes';
import {submitJournalEventDocumentItemCancel, submitJournalEventPayment} from '@/Helpers/journal';
import {PrintoutTypes} from '@/constants/printoutTypes';
import {DocumentDtoPatch} from '@/Model/Entity/patches/DocumentDto';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {OutputTypes} from '@/constants/outputTypes';
import {getResponseCodeConfiguration, ResponseCodes} from '@/Helpers/printerServiceResponseCodes';
import {InventoryStockType} from '@/Modules/Apps/Inventory/types';
import {ContentBase64Sources} from '@/constants/contentBase64Sources';
import DocumentContentDto from '@/Model/Entity/DocumentContentDto';
import {parseJwt} from '@/Helpers/jwt';
import ReferentialDocumentsDto from '@/Model/Entity/ReferentialDocumentsDto';
import Pos from '@/Model/Entity/Pos';
import {
  b64DecodeUnicode,
  printerHtmlBase64ToDataHtmlContent,
  printerPdfBase64ToDataPdfContent,
} from '@/Helpers/base64';
import DocumentLoyaltyDto from './DocumentLoyaltyDto';
import CustomDataDto from '@/Model/Entity/CustomDataDto';
import {AnalogDisplayLayout} from '@/Modules/AnalogDisplay/types';
import DocumentDiscountDto from '@/Model/Entity/DocumentDiscountDto';
import {DocumentContentTypes} from '@/constants/documentContentTypes';
import {Entity} from '@designeo/apibundle-js/src/Entity/base';
import {recordCustomEventLogEntry} from '@/Helpers/logger';

export type GroupBySets = {
  index: number,
  mainItem: DocumentItemDto,
  components: DocumentItemDto[],
  mainItemIsEditableSet: boolean,
  editableItem: DocumentItemDto,
  editableItemIsEditableSet: boolean,
  referentialDocumentEditableItem: DocumentItemDto,
  lotteryValues?: {
    categoryTurnoverValue: number
    categoryEnteredValue: number
  }
}

export enum ExtraInfoItemsInReceiptType {
  GECO_GAME='gecoGame',
}

export type ExtraInfoItemsInReceipt = {
  type: ExtraInfoItemsInReceiptType,
  description: string,
  id: string,
}

export default class DocumentDto extends DocumentDtoPatch(GeneratedDocumentDto) {
  public static get configurationStore(): ReturnType<typeof useConfigurationStore> {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  public static get authStore() {
    return (require('@/Modules/Auth/store/AuthStore')).useAuthStore();
  }

  public static get promoEngine() {
    return (require('@/Modules/Register/PromoEngine/PromoEngine')).usePromoEngine() as
      import('@/Modules/Register/PromoEngine/PromoEngine').PromoEngine;
  }

  public static get variableSymbolByPosCodeAndNowDateTime() {
    return [
      '0',
      DocumentDto.configurationStore.configuration.value.general.pos.shop.code,
      DateTime.now().toFormat('yyMMdd'),
    ].join('');
  }

  public static createEmptySellDocument() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.SellDocument,
      header: <DocumentHeaderDto['_data']>{
        uniqueidentifier: guid(),
        currency: DocumentDto.configurationStore.localCurrency.value.symbol,
        posCode: DocumentDto.configurationStore.configuration.value.general.pos.code,
        posId: DocumentDto.configurationStore.configuration.value.general.pos.id,
        shopCode: DocumentDto.configurationStore.configuration.value.general.pos.shop.code,
        shopId: DocumentDto.configurationStore.configuration.value.general.pos.shop.id,
        rounding: 0,
        total: 0,
        subtotal: 0,
        mode: DocumentModeTypes.SALE,
      },
      items: [],
      payments: [],
      printTemplateType: OutputTypes.Primary,
    });
  }

  public static createEmptyPrintDocument() {
    return new DocumentDto(<DocumentDto['_data']>{
      isCanceled: false,
      documentType: DocumentTypes.PrintDocument,
      header: {
        uniqueidentifier: guid(),
      },
      items: [],
      contents: [],
    });
  }

  public static createEmptyStockBalancingDocument() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.SellDocument,
      documentSubType: DocumentTypes.StockBalancing,
      header: <DocumentHeaderDto['_data']>{
        uniqueidentifier: guid(),
        currency: DocumentDto.configurationStore.localCurrency.value.symbol,
        posCode: DocumentDto.configurationStore.configuration.value.general.pos.code,
        posId: DocumentDto.configurationStore.configuration.value.general.pos.id,
        shopCode: DocumentDto.configurationStore.configuration.value.general.pos.shop.code,
        shopId: DocumentDto.configurationStore.configuration.value.general.pos.shop.id,
        rounding: 0,
        total: 0,
        subtotal: 0,
      },
      items: [],
      payments: [],
      printTemplateType: OutputTypes.Secondary,
    });
  }

  public static createCloseDayDocument() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.CloseDay,
      header: {
        posId: DocumentDto.configurationStore.configuration.value.general.pos.id,
        shopId: DocumentDto.configurationStore.configuration.value.general.pos.shop.id,
        uniqueidentifier: guid(),
      },
    });
  }

  public static createInventoryDocument({stockType}: {stockType?: InventoryStockType}) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.InventoryDocument,
      disablePrintout: true,
      header: {
        uniqueidentifier: guid(),
        posName: DocumentDto.configurationStore.configuration.value.general.pos.name,
        posCode: DocumentDto.configurationStore.configuration.value.general.pos.code,
        posId: DocumentDto.configurationStore.configuration.value.general.pos.id,
        shopName: DocumentDto.configurationStore.configuration.value.general.pos.shop.name,
        shopCode: DocumentDto.configurationStore.configuration.value.general.pos.shop.code,
        shopId: DocumentDto.configurationStore.configuration.value.general.pos.shop.id,
        stockType,
      },
      logisticItems: [],
    });
  }

  public static createInventorySummaryDocument() {
    const document = new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.InventorySummaryDocument,
      header: {
        currency: DocumentDto.configurationStore.localCurrency.value.symbol,
        posId: DocumentDto.configurationStore.configuration.value.general.pos.id,
        shopId: DocumentDto.configurationStore.configuration.value.general.pos.shop.id,
        shopName: DocumentDto.configurationStore.configuration.value.general.pos.shop.name,
        uniqueidentifier: guid(),
        previewOnly: false,
        inventoryType: 'full',
      },
      inventory: {
        startedDate: new Date(),
      },
      logisticItems: [],
      inventoryGroups: [],
    });

    document.setBasicInfo();

    return document;
  }

  public static createNominalsCountDocument(
    {
      currency,
      documentBarcode,
      envelopeNumber,
      externalReference = null,
      variableSymbol = DocumentDto.variableSymbolByPosCodeAndNowDateTime,
      items,
      isPreview,
    }: {
      currency: string,
      documentBarcode: string
      envelopeNumber: string
      externalReference?: string,
      variableSymbol?: string
      items: Array<DocumentDto['_data']>
      isPreview: boolean,
    },
  ) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.SafeBag,
      printoutType: PrintoutTypes.A4Printer,
      ...(isPreview ? {} : {
        disablePrintout: true,
      }),
      header: {
        uniqueidentifier: guid(),
        currency,
        documentBarcode,
        copies: 1,
        envelopeNumber,
        externalReference,
        variableSymbol,
        ...(!isPreview ? {} : {
          previewOnly: true,
        }),
        // ??
        // transferFrom: 'Loomis Czech republic a.s.',
        // ShopAddressLine1: 'GECO a.s.',
        // ShopAddressLine2: '181 00 Praha 8',
        // ShopAddressLine3: 'MOP 247',
      },
      items,
    });
  }

  public static createSafeBag(
    {
      documentBarcode,
      envelopeNumber,
      variableSymbol = DocumentDto.variableSymbolByPosCodeAndNowDateTime,
      items,
      header,
      documentType = DocumentTypes.FinDocument,
      documentSubType = DocumentTypes.SafeBag,
    }: {
      documentBarcode: string
      envelopeNumber: string
      variableSymbol?: string
      items: Array<DocumentItemDto['_data']>
      header,
      documentType?: DocumentTypes,
      documentSubType?: DocumentTypes,
    },
  ) {
    const safeBag = new DocumentDto(<DocumentDto['_data']>{
      documentType,
      documentSubType,
      attachments: [
        {
          type: 'Safebag',
          data: '',
          name: '',
        },
      ],
      header: {
        source: 'CashDrawer',
        destination: 'Safebag',
        currency: DocumentDto.configurationStore.localCurrency.value.symbol,
        uniqueidentifier: guid(),
        copies: 1,
        documentBarcode,
        envelopeNumber,
        variableSymbol,
        ...header,
        // ??
        // transferFrom: 'Loomis Czech republic a.s.',
        // shopAddressLine1: 'GECO a.s.',
        // shopAddressLine2: '181 00 Praha 8',
        // shopAddressLine3: 'MOP 247',
      },
      items,
    });
    safeBag.header.total = safeBag.balance;

    return safeBag;
  }

  public static createCashDrawerState(
    {
      currency,
      items,
    }: {
      currency: string,
      items: Array<DocumentDto['_data']>
    },
  ) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.CashDrawerState,
      header: {
        uniqueidentifier: guid(),
        currency,
        copies: 1,
      },
      items,
    });
  }

  public static createFinancialDocument({
    header = {
      reference: undefined,
      finDocumentCode: null,
      finDocumentName: null,
      finDocumentTransactionNumber: null,
      currency: null,
      total: 0,
      source: null,
      destination: null,
      sapTransactionCode: null,
      note: null,
      username: null,
    },
    items = [],
    documentType = DocumentTypes.FinDocument,
    documentSubType = null,
  }: {
    documentType?,
    documentSubType?,
    header?,
    items?: DocumentDto['_data']
  } = {}) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: documentType ?? DocumentTypes.FinDocument,
      ...(documentSubType ? {
        documentSubType,
      } : {}),
      header: <DocumentHeaderDto['_data']>{
        reference: header.reference,
        uniqueidentifier: guid(),
        finDocumentCode: header.finDocumentCode,
        finDocumentName: header.finDocumentName,
        finDocumentTransactionNumber: header.finDocumentTransactionNumber,
        currency: header.currency ?? DocumentDto.configurationStore.localCurrency.value.symbol,
        total: header.total,
        source: header.source,
        destination: header.destination,
        sapTransactionCode: header.sapTransactionCode,
        note: header.note,
        username: header.username,
      },
      items,
      finItems: [],
      payments: [],
    });
  }

  public static createNonFinancialDocument(contents) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.NonFiscalDocument,
      header: <DocumentHeaderDto['_data']>{
        uniqueidentifier: guid(),
        copies: 1,
      },
      contents: map(contents, ({outputBase64}) => (<DocumentContentDto['_data']>({
        contentBase64: outputBase64,
        source: ContentBase64Sources.PaymentTerminalResult,
      }))),
    });
  }

  public static createCashDrawerDocument() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.OpenCashDrawer,
      header: {
        uniqueidentifier: guid(),
      },
    });
  }

  public static createCustomerExpiredPointsPrint(customer: DocumentCustomerDto) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.CustomerDetail,
      header: {
        uniqueidentifier: guid(),
      },
      customer: customer.clone().toJson(),
    });
  }

  public static createPosPayment(payment: DocumentPaymentDto) {
    payment = payment.clone();
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.PosPayment,
      header: <DocumentHeaderDto['_data']>{
        currency: payment.currency,
        uniqueidentifier: guid(),
        total: payment.value,
      },
      payments: [payment.toJson()],
    });
  }

  public static createPosPaymentRefund(payment: DocumentPaymentDto) {
    payment = payment.clone();

    payment.value = Math.abs(payment.value);

    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.PosPaymentRefund,
      header: <DocumentHeaderDto['_data']>{
        currency: payment.currency,
        uniqueidentifier: guid(),
        total: payment.value,
      },
      payments: [payment.toJson()],
    });
  }

  public static createPosPaymentCancel(payment: DocumentPaymentDto) {
    payment = payment.clone();

    payment.value = Math.abs(payment.value);

    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.PosPaymentCancel,
      // terminalID: paymentResult.terminalID,
      // authorizationCode: paymentResult.authorizationCode,
      header: <DocumentHeaderDto['_data']>{
        currency: payment.currency,
        uniqueidentifier: guid(),
        total: payment.value,
      },
      payments: [payment.toJson()],
    });
  }

  public static createDialogResult(dialogResult, originalDocument: DocumentDto) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.DialogResult,
      header: <DocumentHeaderDto['_data']>{
        uniqueidentifier: originalDocument.header.uniqueidentifier,
      },
      dialogResult,
      originalOperation: originalDocument.documentType.value,
    });
  }

  public static createLotteryCloseDay() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.LotteryCloseDay,
      header: {
        uniqueidentifier: guid(),
        documentDate: new Date().toISOString(),
      },
    });
  }

  public static createPosPaymentZReport() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.PosPaymentZReport,
      header: <DocumentHeaderDto['_data']>{
        uniqueidentifier: guid(),
        currency: DocumentDto.configurationStore.localCurrency.value.symbol,
      },
    });
  }

  public static createPrinterInit() {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.InitPrinter,
      header: {
        uniqueidentifier: guid(),
      },
    });
  }

  public static createAnalogDisplayMessage(layout: AnalogDisplayLayout) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.Display,
      header: {
        uniqueidentifier: guid(),
      },
      customData: {
        display: {
          displayLine1: layout[0]?.join('') ?? '',
          displayLine2: layout[1]?.join('') ?? '',
        },
      },
    });
  }

  public static createWarehouseOperation(subType: string) {
    return new DocumentDto(<DocumentDto['_data']>{
      documentType: DocumentTypes.LogisticDocument,
      documentSubType: subType,
      header: {
        uniqueidentifier: guid(),
      },
      logisticItems: [],
      payments: [],
    });
  }

  // @ts-ignore
  set promotions(value: PromotionMeta[]) {
    // @ts-ignore
    return this._data.promotions = value;
  }

  get promotions(): PromotionMeta[] {
    // @ts-ignore
    return this._data.promotions ?? [];
  }

  get extraInfoItemsInReceipt(): ExtraInfoItemsInReceipt[] {
    // @ts-ignore
    const gecoGameResults = filter(promotionByType(this, PromotionMetaType.GECO_GAME), (game) => {
      return game.played;
    });

    return [
      ...gecoGameResults.map(({text, promoCode}) => ({
        id: `GECO_GAME_${promoCode}`,
        type: ExtraInfoItemsInReceiptType.GECO_GAME,
        description: text,
      })),
    ];
  }

  get availablePromotionGiftPools(): DocumentGiftPool[] {
    return <DocumentGiftPool[]>filter(this.promotions, ({type}) => type === PromotionMetaType.GIFT_POOL);
  }

  get balance() {
    return fixDecimals(this.itemsNettoTotalValue - this.paidTotal);
  }

  get balanceIsNegative() {
    return this.balance < 0;
  }

  get balanceIsPositive() {
    return this.balance > 0;
  }

  get balanceIsZero() {
    return this.balance === 0;
  }

  get partialPaymentsAreAllowed() {
    return !this.isModeStorno;
  }

  get balanceDecomposition() {
    return nominals(
      this.balance,
      DocumentDto.configurationStore.localCurrency.value.nominals,
    );
  }

  get balanceDecompositionByAcceptableNominals() {
    return nominals(
      this.balance,
      DocumentDto.configurationStore.localCurrency.value.acceptableNominals,
    );
  }

  get createdInCurrentShop() {
    return this.createdOn(DocumentDto.configurationStore.configuration.value.general.pos);
  }

  get hasPayoutItem() {
    return some(this.itemsGroupedBySets, ({editableItem}) => {
      return editableItem.isNegative && !editableItem.isReturn;
    });
  }

  get validPaymentsWithCard(): DocumentPaymentDto[] {
    return filter(this.validPayments, (payment: DocumentPaymentDto) => {
      return payment.type.isCardMethod;
    });
  }

  get validPaymentsWithCardInLocalCurrency(): DocumentPaymentDto[] {
    return filter(this.validPayments, (payment: DocumentPaymentDto) => {
      return payment.type.isCardMethod && payment.isInLocalCurrency;
    });
  }

  get validPaymentsWithCardInForeignCurrency(): DocumentPaymentDto[] {
    return filter(this.validPayments, (payment: DocumentPaymentDto) => {
      return payment.type.isCardMethod && !payment.isInLocalCurrency;
    });
  }

  get validPaymentsWithCardAndPayTerminalVirtualId(): DocumentPaymentDto[] {
    return filter(this.validPayments, (payment: DocumentPaymentDto) => {
      return payment.type.isCardMethod && !!payment.payTerminalVirtualId;
    });
  }

  get hasPrimaryPrintTemplateType() {
    return this.printTemplateType.value === OutputTypes.Primary;
  }

  get hasSecondaryPrintTemplateType() {
    return this.printTemplateType.value === OutputTypes.Secondary;
  }

  get hasValidPaymentWithCard() {
    return this.validPaymentsWithCard.length;
  }

  get hasValidPaymentWithCardInForeignCurrency() {
    return this.validPaymentsWithCardInForeignCurrency.length;
  }

  get hasValidPaymentsWithCardAndPayTerminalVirtualId() {
    return this.validPaymentsWithCardAndPayTerminalVirtualId.length;
  }

  get hasValidPaymentWithCardInLocalCurrency() {
    return this.validPaymentsWithCardInLocalCurrency.length;
  }

  get hasCustomer() {
    return this.customer?.customerNumber || this.customer?.cardNumber;
  }

  get isInProgress() {
    if (this.validItems.length) {
      return true;
    }

    if (this.unreturnableGroups.length) {
      return true;
    }

    return false;
  }

  get isUntouched() {
    if (this.items.length > 0) {
      return false;
    }

    if (this.payments.length > 0) {
      return false;
    }

    if (this.hasCustomer) {
      return false;
    }

    if (this.unreturnableGroups.length > 0) {
      return false;
    }

    if (this.header.delayedSellNumber) {
      return false;
    }

    if (this.isDocumentSubTypeAutomatedFollowUpDocument) {
      return false;
    }

    return true;
  }

  get isTouched() {
    return !this.isUntouched;
  }

  get canBeDiscarded() {
    if (this.items.length > 0) {
      return false;
    }

    if (this.payments.length > 0) {
      return false;
    }

    if (this.unreturnableGroups.length > 0) {
      return false;
    }

    if (this.hasCustomer) {
      return false;
    }

    return true;
  }

  get canBeCanceledWithoutConfirmation() {
    if (this.validItems.length > 0) {
      return false;
    }

    return true;
  }

  get canBeCanceled() {
    return every(this.itemsGroupedBySets, ({editableItem}) => {
      return editableItem.canBeCanceled && editableItem.canDocumentBeCanceled;
    });
  }

  get itemsAreCancelable() {
    return every(this.itemsGroupedBySets, ({editableItem}) => {
      return editableItem.canBeCanceled;
    });
  }

  get canFinishedDocumentBeCanceled() {
    return every(this.itemsGroupedBySets, ({editableItem}) => {
      return editableItem.canDocumentBeCanceled;
    });
  }

  get isModeSale() {
    return this.header.mode.value === DocumentModeTypes.SALE;
  }

  get isModeFullStorno() {
    return this.header.mode.value === DocumentModeTypes.STORNO;
  }

  get isModePartialStorno() {
    return this.header.mode.value === DocumentModeTypes.PARTIAL_STORNO;
  }

  get isModeStorno() {
    return this.isModeFullStorno || this.isModePartialStorno;
  }

  get isDocumentTypeFinDocument() {
    return this.documentType?.value === DocumentTypes.FinDocument;
  }

  get isDocumentTypeLogisticDocument() {
    return this.documentType?.value === DocumentTypes.LogisticDocument;
  }

  get isDocumentTypeCloseDay() {
    return this.documentType?.value === DocumentTypes.CloseDay;
  }

  get isDocumentTypeSellDocument() {
    return this.documentType?.value === DocumentTypes.SellDocument;
  }

  get isDocumentTypeFinancialCloseDay() {
    return this.documentType?.value === DocumentTypes.FinancialCloseDay;
  }

  get isDocumentTypeSafeBag() {
    return this.documentType?.value === DocumentTypes.SafeBag;
  }

  get isDocumentTypeInventorySummaryDocument() {
    return this.documentType?.value === DocumentTypes.InventorySummaryDocument;
  }

  get isDocumentTypePosPayment() {
    return this.documentType?.value === DocumentTypes.PosPayment;
  }

  get isDocumentTypePosPaymentRefund() {
    return this.documentType?.value === DocumentTypes.PosPaymentRefund;
  }

  get isDocumentTypePrintDocument() {
    return this.documentType?.value === DocumentTypes.PrintDocument;
  }

  get isDocumentTypeCustomerDetail() {
    return this.documentType?.value === DocumentTypes.CustomerDetail;
  }

  get isDocumentSubTypeLotteryCloseDay() {
    return this.documentSubType?.value === DocumentTypes.LotteryCloseDay;
  }

  get isDocumentSubTypeAutomatedFollowUpDocument() {
    return this.documentSubType?.value === DocumentTypes.AutomatedFollowUpDocument;
  }

  get isPayroll() {
    return this.itemsNettoTotalValue < 0;
  }

  get isDelayedSell() {
    return this.header.isDelayedSell ?? false;
  }

  createGroups(items: DocumentItemDto[]) {
    const doc = this;
    return flow([
      toPairs,
      (val) => groupBy(val, ([i, documentItem]) => {
        return documentItem.setNumber ? `set-${documentItem.setNumber}` : `pos-${i}`;
      }),
      (val) => map(val), // need number index
      (val) => map(val, (pairs, index) => {
        const groupFromPair = map(pairs, ([key, items]) => items);
        const group = {
          index,
          mainItem: groupFromPair[0],
          components: [],
          get mainItemIsEditableSet() {
            return !!(this.components.length && some(this.components, {isEditableSet: true}));
          },
          get editableItem() {
            if (this.mainItemIsEditableSet) {
              return find(this.components, {isEditableSet: true}); // TODO how to determine next editable item?
            }

            return this.mainItem;
          },
          get editableItemIsEditableSet() {
            return this.editableItem.isEditableSet && this.editableItem !== this.mainItem;
          },
          /**
           * this is the spot, where u want to change view of:
           *
           * sell => storno
           * storno => sell
           */
          get referentialDocumentEditableItem() {
            if (!doc.originalReferentialDocument) {
              return null;
            }

            return doc.originalReferentialDocument.getOriginalEditableItemByEditableItem(this.editableItem);
          },
        };


        if (groupFromPair.length !== 1) {
          group.mainItem = find(groupFromPair, {isSet: true});
          group.components = filter(groupFromPair, {isSetComponent: true});
        }

        return group;
      }),
      (val) => reject(val, ({mainItem}) => isNil(mainItem)),
    ])(items);
  }

  get itemsGroupedBySets(): Array<GroupBySets> {
    return this.createGroups(this.validItems);
  }

  get canceledItemsGroupedBySets(): Array<GroupBySets> {
    return this.createGroups(this.canceledItems);
  }

  set itemsGroupedBySets(groups: Array<GroupBySets>) {
    this.items = flatten(map(groups, ({mainItem, components}) => [mainItem, ...components]));
  }

  get lastValidPayment() {
    return last(this.validPayments);
  }

  get lastPaymentInCreditCard() {
    return this.lastValidPayment?.paymentID === PAYMENT_TYPE_CREDIT_CARD;
  }

  get lastPaymentInCash() {
    return this.lastValidPayment?.paymentID === PAYMENT_TYPE_CASH;
  }

  get needPaymentPrintoutConfirmation() {
    if (!this.isDocumentTypeSellDocument) return false;

    // @ts-ignore the question has been already asked
    if (!isNil(this._data.disablePrintPaymentTerminalResult)) return false;

    const needPrimaryPaymentPrintoutConfirmation = DocumentDto.configurationStore.configuration.value
      .features
      .payment
      .primaryPrintoutAskForPaymentTerminalPrintout;

    const needSecondaryPaymentPrintoutConfirmation = DocumentDto.configurationStore.configuration.value
      .features
      .payment
      .secondaryPrintoutAskForPaymentTerminalPrintout;

    if (this.hasPrimaryPrintTemplateType && !needPrimaryPaymentPrintoutConfirmation) return false;

    if (this.hasSecondaryPrintTemplateType && !needSecondaryPaymentPrintoutConfirmation) return false;

    return some(this.validPayments, (payment) => {
      return payment.type.isCardMethod;
    });
  }

  get paidTotal() {
    return sumBy(this.validPayments, 'value') || 0;
  }

  get totalPrice() {
    return this.header.total || 0;
  }

  get totalDiscountValue() {
    /**
     * Valid items are non set items or components of set without parent
     */
    const items = filter(this.items, (item) => {
      if (item.isCanceled) {
        return false;
      }

      if (item.isSet) { // is set parent
        return false;
      }

      return true;
    });

    const itemsTotalDiscountValue = flow([
      (items: DocumentItemDto[]) => {
        return sumBy(items, (item) => {
          return sumBy((item.discounts ?? []), ({itemValue, type}) => {
            if (type.value === 'Discount' || type.value === 'SetDiscount') {
              return itemValue;
            }

            return 0;
          });
        });
      },
      (val) => fixDecimals(val),
    ])(items);

    const documentTotalDiscountValue = flow([
      (discounts: DocumentDiscountDto[]) => sumBy(discounts, ({value}) => value),
      (val) => fixDecimals(val),
    ])(this.discounts ?? []);


    return fixDecimals(itemsTotalDiscountValue + documentTotalDiscountValue);
  }

  get valueToReturn() {
    if (this.isPayroll) {
      return 0;
    }

    if (!this.lastPaymentInCreditCard) {
      return Math.abs(Math.min(last(this.validPayments)?.value || 0, 0));
    }

    return Math.abs(Math.min(this.balance, 0));
  }

  get validItems() {
    return filter(this.items, ({isCanceled, isRoundingArticle}) => {
      return !isCanceled && !isRoundingArticle;
    });
  }

  get validPayments(): DocumentPaymentDto[] {
    return filter(this.payments, ({isCanceled, isRefunded, isValidated}) => {
      return !isCanceled && !isRefunded && isValidated;
    });
  }

  get validPaymentsWithAnyState(): DocumentPaymentDto[] {
    return filter(this.payments, ({isCanceled, isRefunded, isValidated}) => {
      return !isCanceled && !isRefunded;
    });
  }

  get validUnvalidatedPayments() {
    return filter(this.payments, ({isCanceled, isRefunded, isValidated}) => {
      return !isCanceled && !isRefunded && !isValidated;
    });
  }

  get validCheckAndChargeGroups() {
    return filter(this.itemsGroupedBySets, (group) => {
      return group.editableItem.isCheckAndCharge;
    });
  }

  get validCheckAndChargeUnchargedGroups() {
    return filter(this.validCheckAndChargeGroups, (group) => {
      return !group.editableItem.chargeResponse?.isSuccessful;
    });
  }

  get validCheckAndChargeChargedCancellableGroups() {
    return filter(this.validCheckAndChargeGroups, (group) => {
      return (
        group.editableItem.chargeResponse?.isSuccessful &&
        group.editableItem.canBeCanceled &&
        group.editableItem.canBeReturned
      );
    });
  }

  get validCheckAndChargeCancellableGroups() {
    return filter(this.validCheckAndChargeGroups, (group) => {
      return group.editableItem.isCheckAndChargeCancellable;
    });
  }

  get paymentsGroupedById() {
    return groupBy(this.validPayments, 'paymentID');
  }

  get itemsNettoTotalValue() {
    return fixDecimals(sumBy(
      reject(this.items, ({isCanceled, isSetComponent}) => {
        return isSetComponent || isCanceled;
      }),
      'valueAfterDiscounts',
    ) || 0);
  }

  get itemsNettoTotalValueWithoutExternal() {
    return fixDecimals(sumBy(
      reject(this.items, ({isCanceled, isSetComponent, sellerVATID}) => {
        return isSetComponent || isCanceled || !!sellerVATID;
      }),
      'valueAfterDiscounts',
    ) || 0);
  }

  get canceledItems() {
    return filter(this.items, {isCanceled: true});
  }

  get hasCanceledItems() {
    return this.canceledItems.length > 0;
  }

  get hasItems() {
    return this.items.length > 0;
  }

  _addItem(documentItem: DocumentItemDto) {
    if (documentItem.isSet) {
      this.addDocumentItemTypeSet(documentItem);
    } else {
      this.addDocumentItemTypeNonSet(documentItem);
    }
    this.recalculateItemPositions();
  }

  addCustomer(documentCustomer: DocumentCustomerDto) {
    this.customer = documentCustomer;
  }

  removeCustomer() {
    this.customer = null;
  }

  addPayment(documentPayment: DocumentPaymentDto, {refreshTotalPrice = true} = {}) {
    documentPayment = documentPayment.clone();

    if (documentPayment.value < 0) {
      documentPayment.isMoneyBack = true;
    }

    if (this.isDocumentTypeFinDocument) {
      documentPayment.sapTransactionCode = undefined;
    }

    this.payments = [...(this.payments || []), documentPayment];

    if (refreshTotalPrice) {
      this.refreshTotalPrice();
    }

    submitJournalEventPayment(documentPayment);
  }

  /**
   * TODO remove normalize to Result['_data'] after refactoring payments in register
   */
  processPaymentResults(results: Array<ResultDto | ResultDto['_data']>) {
    for (let result of [].concat(results)) {
      result = result instanceof ResultDto ? result.clone().toJson() : result;

      const currentResults: {[key: string]: any} = this.paymentResults ?? {};
      const resultConfiguration = getResponseCodeConfiguration(result);
      const resultsForCurrentPosPaymentId = currentResults[result.uniqueIdentifier] ?? [];

      this.paymentResults = {
        ...currentResults,
        [result.uniqueIdentifier]: sortBy([...resultsForCurrentPosPaymentId, result], (result) => {
          return result.processedAt ?? result.date;
        }),
      };

      if (resultConfiguration.code === ResponseCodes.Ok && includes([
        DocumentTypes.PosPayment,
        DocumentTypes.PosPaymentRefund,
        DocumentTypes.PosPaymentCancel,
      ], resultConfiguration.operation)) {
        this.processPaymentOutput(result?.output ?? []);
      }
    }
  }

  clearPaymentResultsByPayment(documentPayment: DocumentPaymentDto) {
    if (!this.paymentResults) {
      return;
    }

    this.paymentResults = omit(this.paymentResults, [documentPayment.verifyDocumentId]);
  }

  paymentResultsByPayment(documentPayment: DocumentPaymentDto): ResultDto['_data'][] {
    return this.paymentResults?.[documentPayment.verifyDocumentId] ?? [];
  }

  findSuccessPaymentResultByPayment(documentPayment: DocumentPaymentDto): ResultDto['_data'] {
    if (!documentPayment.verifyDocumentId) {
      return null;
    }

    if (!this.paymentResults?.[documentPayment.verifyDocumentId]) {
      return null;
    }

    return find(this.paymentResults[documentPayment.verifyDocumentId], (result) => {
      const {code, operation} = getResponseCodeConfiguration(result);
      return code === ResponseCodes.Ok && includes([
        DocumentTypes.PosPayment,
        DocumentTypes.PosPaymentRefund,
        DocumentTypes.PosPaymentCancel,
      ], operation);
    });
  }

  get nonFiscalPrintableDocuments(): string[] {
    return flow([
      (val) => values(val),
      (val) => flatten(val),
      (val) => filter(val, (result) => {
        const {operation, code} = getResponseCodeConfiguration(result);
        return operation === DocumentTypes.NonFiscalDocument &&
          code === ResponseCodes.Ok &&
          !!(result.printout?.pdfBase64 ?? result.printout?.htmlBase64);
      }),
      (val) => map(val, ({printout}) => {
        return (
          printerPdfBase64ToDataPdfContent(printout.pdfBase64) ??
          printerHtmlBase64ToDataHtmlContent(printout.htmlBase64)
        );
      }),
    ])(<{[key: string]: ResultDto[]}>(this.paymentResults));
  }

  async addReturnPayment() {
    const value = this.middleValueIsRoundedDown(this.balanceDecompositionByAcceptableNominals) ?
      this.balanceDecompositionByAcceptableNominals.decomposableRoundedDown :
      this.balanceDecompositionByAcceptableNominals.decomposableRounded;

    if (!value) {
      return;
    }

    const returnPayment = DocumentDto.configurationStore.createPayment(PAYMENT_TYPE_CASH);
    returnPayment.setValue(value);

    this.addPayment(returnPayment);
  }

  async addRounding(value = null, {decomposableValueValidation = true} = {}) {
    this.removeRounding();
    const roundingArticle = await DocumentDto.configurationStore.getRoundingArticle();

    if (!roundingArticle) {
      throw new Error('roundingArticle missing!'); // we should not reach this point, never!
    }

    let rounding = value ?? this.balanceDecompositionByAcceptableNominals.reminder;

    if (rounding === 0) {
      return;
    }

    if (decomposableValueValidation) {
      const decomposableValue = this.middleValueIsRoundedDown(this.balanceDecompositionByAcceptableNominals) ?
        this.balanceDecompositionByAcceptableNominals.decomposableRoundedDown :
        this.balanceDecompositionByAcceptableNominals.decomposableRounded;

      if (decomposableValue !== 0) {
        return;
      }
    }

    rounding = fixDecimals(
      Math.abs(rounding) < DocumentDto.configurationStore.localCurrency.value.smallestAcceptedNominal ?
        rounding * -1 :
        DocumentDto.configurationStore.localCurrency.value.smallestAcceptedNominal - rounding,
    );

    this.header.rounding = rounding;

    roundingArticle.isBonRounding = true;
    roundingArticle.quantity = rounding < 0 ? -1 : 1;
    roundingArticle.priceNormal = Math.abs(rounding);
    roundingArticle.priceAction = Math.abs(rounding);
    roundingArticle.priceAfterItemDiscounts = Math.abs(rounding);
    roundingArticle.valueBeforeDiscounts = rounding;
    roundingArticle.valueAfterDiscounts = rounding;

    this._addItem(roundingArticle);

    this.refreshTotalPrice();
  }

  removeRounding() {
    this.header.rounding = 0;

    this.items = <Array<DocumentItemDto>>reject(this.items, (item) => {
      return item.isRoundingArticle;
    });

    this.refreshTotalPrice();
  }

  addDocumentItemTypeSet(documentItem: DocumentItemDto) {
    const lastParentItemByInternalNumber = this.lastParentItemByInternalNumber(documentItem);
    const lastParentIsSameItemByInternalNumber = !!lastParentItemByInternalNumber;
    const hasValidParentWithSpareQuantity = lastParentIsSameItemByInternalNumber &&
      !lastParentItemByInternalNumber.isAtMaxQuantity &&
      lastParentItemByInternalNumber.originalEan === documentItem.originalEan;

    if (hasValidParentWithSpareQuantity) {
      const lostQuantity = lastParentItemByInternalNumber.restQuantity(documentItem.quantity);
      this.processDocumentItemAddQuantity(
        lastParentItemByInternalNumber,
        lostQuantity,
      );

      for (const setItem of documentItem.setItems) {
        setItem.referenceItem = documentItem.internalNumber;
        setItem.setNumber = lastParentItemByInternalNumber.setNumber;

        setItem.quantity = lostQuantity * setItem.quantity;
        this._addItem(setItem);
      }

      documentItem.quantity = documentItem.quantity - lostQuantity;
    }

    if (documentItem.quantity) {
      const setItems = documentItem.setItems;
      documentItem.setNumber = Math.max(...this.items.map(({setNumber}) => setNumber ?? 0), 0) + 1;
      documentItem.setPosition = 0;

      documentItem.setItems = [];
      this.processDocumentItemAddition(documentItem);

      let i = 1;
      for (const setItem of setItems) {
        setItem.referenceItem = documentItem.internalNumber;
        setItem.setNumber = documentItem.setNumber;
        setItem.setPosition = i++;
        setItem.quantity = documentItem.quantity * setItem.quantity;
        this._addItem(setItem);
      }
    }
  }

  addDocumentItemTypeNonSet(documentItem: DocumentItemDto) {
    const lastItemByInternalNumber = documentItem.setNumber ?
      this.findComponentByInternalNumberAndSetNumber(documentItem) :
      this.lastParentItemByInternalNumber(documentItem);
    const hasSameItemByInternalNumber = !!lastItemByInternalNumber;
    const hasValidSiblingWithSpareQuantity = hasSameItemByInternalNumber &&
      !lastItemByInternalNumber.isAtMaxQuantity &&
      lastItemByInternalNumber.originalEan === documentItem.originalEan;

    if (hasValidSiblingWithSpareQuantity) {
      const lostQuantity = lastItemByInternalNumber.restQuantity(documentItem.quantity);
      this.processDocumentItemAddQuantity(
        lastItemByInternalNumber,
        lostQuantity,
      );

      documentItem.quantity = documentItem.quantity - lostQuantity;
    }

    if (documentItem.quantity) {
      this.processDocumentItemAddition(documentItem);
    }
  }

  addContent(contents: DocumentContentDto | DocumentContentDto[]) {
    this.contents = [
      ...(this.contents ?? []),
      ...([].concat(contents)),
    ];
  }

  removeContent(contents: DocumentContentDto | DocumentContentDto[]) {
    this.contents = reject(this.contents ?? [], (content) => {
      return some([].concat(contents), (contentToRemove) => {
        return contentToRemove.id === content.id;
      });
    });
  }

  sanitizeContentOfOwner(ownerId) {
    this.contents = reject(this.contents ?? [], {ownerId});
  }

  get contentsByOwnerId() {
    return groupBy(this.contents ?? [], 'ownerId');
  }

  get contentsTypeStandalone() {
    return filter(this.contents ?? [], ({type}) => {
      return type?.value === DocumentContentTypes.StandalonePrintout;
    });
  }

  addPrintedContent(contents: DocumentContentDto | DocumentContentDto[]) {
    this._data.printedContents = [
      ...(this._data.printedContents ?? []),
      ...(map([].concat(contents), (content) => content.toJson())),
    ];
  }

  get printedContents() {
    return map(this._data.printedContents ?? [], (content) => new DocumentContentDto(content));
  }

  set printedContents(val: DocumentContentDto[]) {
    this._data.printedContents = map(val, (content) => content.toJson());
  }

  get printedContentsByOwnerId() {
    return groupBy(this.printedContents, 'ownerId');
  }

  isContentPrinted(content: DocumentContentDto) {
    return some(this.printedContents, (printedContent) => {
      return printedContent.id === content.id;
    });
  }

  addItem(documentItem: DocumentItemDto) {
    documentItem.sanitize();

    this._addItem(documentItem);

    this.refreshItemsAttributes();
  }

  middleValueIsRoundedDown(decomposition: ReturnType<typeof nominals>) {
    let by: 'payer' | 'payee' | 'odd' = 'payer';

    // @ts-ignore
    if (by === 'odd') {
      by = Math.floor(Math.abs(this.itemsNettoTotalValue)) % 2 ? 'payer' : 'payee';
    }


    const isMiddleValueRoundedUp = ((by) => {
      switch (by) {
      case 'payer': return this.itemsNettoTotalValue > 0;
      case 'payee': return this.itemsNettoTotalValue < 0;
      }
    })(by as typeof by);

    const isNegativeReminder = decomposition.reminder < 0;

    return decomposition.infiniteReminder && (
      isMiddleValueRoundedUp === isNegativeReminder
    );
  }

  async balanceByActiveDocumentPayment(documentPayment: DocumentPaymentDto) {
    let balanceBySmallestNominals = this.balance;
    const roundingArticle = await DocumentDto.configurationStore.getRoundingArticle();

    if (documentPayment.type.rounding && roundingArticle && documentPayment.type.isCashMethod) {
      balanceBySmallestNominals = cashNominalRoundingByStrategy(balanceBySmallestNominals, this.itemsNettoTotalValue);
    }

    if (documentPayment.type.rounding && roundingArticle) {
      const nominalDecomposition = nominals(
        balanceBySmallestNominals,
        DocumentDto.configurationStore.localCurrency.value.acceptableNominals,
      );

      balanceBySmallestNominals = this.middleValueIsRoundedDown(nominalDecomposition) ?
        nominalDecomposition.decomposableRoundedDown :
        nominalDecomposition.decomposableRounded;
    }

    let balance = documentPayment.exchangeFromLocalCurrency(balanceBySmallestNominals);

    if (documentPayment.type.rounding && roundingArticle) {
      balance = nominals(balance, documentPayment.acceptableNominals).decomposableRoundedUp;
    }

    return balance;
  }

  cancel() {
    this.isCanceled = true;
    this.canceledByID = DocumentDto.authStore.activePerson.value?.tokenInfo?.sub;
    this.canceledAt = new Date();
  }

  cancelItem(group: GroupBySets, fourEyes: {id: string}) {
    submitJournalEventDocumentItemCancel(group.index + 1);

    for (const item of [group.mainItem, ...group.components]) {
      item.isCanceled = true;
      item.canceledByID = fourEyes.id;
      item.canceledAt = new Date();
    }

    this.refreshItemsAttributes();
  }

  cancelCashPayments() {
    for (const payment of <DocumentPaymentDto[]> this.validPayments) {
      if (payment.type.isCashMethod) {
        payment.isCanceled = true;
      }
    }
    this.removeRounding();
  }

  confirmItemAgeRestriction(item: DocumentItemDto) {
    if (item.ageSaleRestriction) {
      this.header.confirmedAgeRestrictions = [
        ...(this.header.confirmedAgeRestrictions ?? []),
        parseInt(item.ageSaleRestriction, 10),
      ];
    }
  }

  createdOn(pos: Pos) {
    return this.header.shopId === pos.shop.id;
  }

  findComponentByInternalNumberAndSetNumber(itemToFind: DocumentItemDto) {
    return find(flatten(map(this.itemsGroupedBySets, 'components')), {
      internalNumber: itemToFind.internalNumber,
      setNumber: itemToFind.setNumber,
    });
  }

  itemHasConfirmedAgeRestriction(item: DocumentItemDto) {
    if (!item) return false;

    if (!item.ageSaleRestriction) return true;

    const ageRestriction = parseInt(item.ageSaleRestriction, 10);

    return findIndex(this.header.confirmedAgeRestrictions ?? [], (confirmedAge) => {
      return confirmedAge >= ageRestriction;
    }) >= 0;
  }

  itemIsUniqueBy(item, prop) {
    const invalidMatch = find(this.itemsGroupedBySets, ({editableItem}) => {
      if (editableItem.uniqueIdentifier === item.uniqueIdentifier) {
        return false;
      }

      if (isNil(editableItem?.[prop])) {
        return false;
      }

      if (isNil(item?.[prop])) {
        return false;
      }

      return editableItem[prop] === item[prop];
    });

    return !invalidMatch;
  }

  isLastItemEditable(documentItem: DocumentItemDto) {
    const lastParentItemByInternalNumber = this.lastParentItemByInternalNumber(documentItem);
    const lastParentIsSameItemByInternalNumber = !!lastParentItemByInternalNumber;
    return lastParentIsSameItemByInternalNumber &&
      !lastParentItemByInternalNumber.isAtMaxQuantity &&
      lastParentItemByInternalNumber.originalEan === documentItem.originalEan;
  }

  async invertToStornoState(referentialDocument: ReferentialDocumentsDto) {
    await changeSaleDocumentToStorno(this, {
      api3rdPartyCancel: require('@/Model/Action/index.ts').api3rdPartyCancel,
      getStornoReasonByCode: (
        ...args: Parameters<typeof DocumentDto.configurationStore.configuration.value.getStornoReasonByCode>
      ) => {
        return DocumentDto.configurationStore.configuration.value.getStornoReasonByCode(...args);
      },
      getOriginalDocumentFiscalReference: () => {
        return referentialDocument.transaction?.result?.documentBarcode ?? null;
      },
      getStornoPrintTemplate: () => {
        return DocumentDto.configurationStore.configuration.value
          ?.features
          ?.storno
          ?.forcedSellDocumentPrintTemplateForStorno ?? this.printTemplateType.value;
      },
      getPartialStornoAllowed: () => {
        return DocumentDto.configurationStore.configuration.value
          ?.features
          ?.storno
          ?.referentialDocumentPartialDocumentStorno ?? false;
      },
      referentialDocument,
    });
  }

  async hasAlreadyBeenSaved() {
    try {
      await (require('@/Model/Action/index.ts').apiDocumentGet)({
        params: {
          id: this.header.uniqueidentifier,
        },
      });

      return true;
    } catch (e) {
      if (e?.response?.status === 404) {
        return false;
      }

      throw e;
    }
  }

  markAsStorno(mode: DocumentModeTypes) {
    this.header.mode = new DocumentModeType(mode);
    this.header.referentialUniqueidentifier = this.header.uniqueidentifier;
    this.header.uniqueidentifier = guid();
  }

  set disabledMandatoryPhysicalPrint(val) {
    this._data.disabledMandatoryPhysicalPrint = val;
  }

  get disabledMandatoryPhysicalPrint() {
    return this._data.disabledMandatoryPhysicalPrint ?? false;
  }

  get nonPromotionsItems() {
    return reject(this.itemsGroupedBySets, ({editableItem}) => editableItem.isPromotionItem);
  }

  lastParentItemByInternalNumber(itemToFind: DocumentItemDto) {
    const lastParentItem = last(this.nonPromotionsItems)?.mainItem;

    if (
      !itemToFind.isStrictlyStandaloneItem &&
      lastParentItem?.internalNumber === itemToFind.internalNumber &&
      lastParentItem?.unit === itemToFind.unit &&
      lastParentItem?.batch === itemToFind.batch &&
      lastParentItem?.isNegative === itemToFind.isNegative &&
      lastParentItem?.promoCode === itemToFind.promoCode
    ) {
      return lastParentItem;
    }

    return null;
  }

  setBasicInfo() {
    this.header.documentDate = new Date();
    this.header.cashierId = DocumentDto.authStore.activePerson.value?.tokenInfo?.sub;
    this.header.cashier = [
      DocumentDto.authStore.activePerson.value?.tokenInfo?.first_name,
      DocumentDto.authStore.activePerson.value?.tokenInfo?.last_name,
    ].join(' ');
    this.header.cashierPersonalNumber = DocumentDto.authStore.activePerson.value?.username;
    this.header.shopCode = DocumentDto.configurationStore.configuration.value?.general?.pos?.shop?.code;
    this.header.shopId = DocumentDto.configurationStore.configuration.value?.general?.pos?.shop?.id;
    this.header.posCode = DocumentDto.configurationStore.configuration.value?.general?.pos?.code;
    this.header.posId = DocumentDto.configurationStore.configuration.value?.general?.pos?.id;
  }

  /**
   * Removing fields and data that are not needed for backend and business processes!!
   */
  prepareForApi() {
    /**
     * Temporary data properties used only for UI flows, backend does not accept these fields
     */
    delete this._data.originalDocument;
    delete this._data.originalReferentialDocument;
    delete this._data.uncancellableItems;

    /**
     * Data for GECO PLU dynamic sets, backend does not need these fields and they are too large
     */
    for (const item of this.items) {
      // @ts-ignore
      delete item._data.dynamicSets;
    }

    /**
     * Check and charge request/response data, sanitization of sensitive and large data
     */
    for (const group of this.itemsGroupedBySets) {
      group.editableItem.sanitizeCheckAndCharge(this, group.index);
    }

    /**
     * Customer detail data, sanitization of large data
     */
    if (!this.isDocumentTypeCustomerDetail && this.hasCustomer) { // Type customer detail need all customer data
      const customerInnerData = this.customer.clone().toJson();

      this.customer = new DocumentCustomerDto({
        ...omit(customerInnerData, [
          'latestTransactions',
          'pointsExpirationPredictions',
          'pointsHistory',
          'previousMonthPointsExpirations',
        ]),
      });
    }

    /**
     * Follow up document promotion, remove document data from promotion because it is too large
     */
    for (const followUpDocument of promotionByType(this, PromotionMetaType.FOLLOW_UP_DOCUMENT)) {
      delete followUpDocument.document;
    }
  }

  preflightSetupTypeCommon() {
    this.setBasicInfo();

    /**
     * Specific field sanitization
     */

    this.canceledPayments = [
      ...(this.canceledPayments ?? []),
      ...filter(this.payments, ({isCanceled, isRefunded, isValidated}) => {
        return isCanceled || isRefunded || !isValidated;
      }),
    ];

    this.contents = map(this.contents ?? [], (content) => {
      if (content?.isSensitive) {
        content.contentBase64 = '';
      }

      return content;
    });

    this.printedContents = map(this.printedContents, (content) => {
      if (content?.isSensitive) {
        content.contentBase64 = '';
      }

      return content;
    });

    this.payments = this.validPayments;
  }

  preflightSetupTypePrintDocument() {
    this.setBasicInfo();
  }

  preflightSetupTypePosPayment() {
    this.setBasicInfo();

    /**
    * Specific field sanitization
    */

    this.canceledPayments = [
      ...(this.canceledPayments ?? []),
      ...filter(this.payments, ({isCanceled, isRefunded}) => {
        return isCanceled || isRefunded;
      }),
    ];

    this.payments = this.validPayments;
  }

  toApiClone() {
    const clone = this.clone();

    clone.prepareForApi();

    return clone;
  }

  preflightSetup() {
    // TODO: Remove
    if (this.isDocumentTypePosPayment || this.isDocumentTypePosPaymentRefund) {
      this.preflightSetupTypePosPayment();
    } else if (this.isDocumentTypePrintDocument) {
      this.preflightSetupTypePrintDocument();
    } else {
      this.preflightSetupTypeCommon();
    }
  }

  processDocumentItemAddQuantity(documentItem: DocumentItemDto, quantity: number) {
    documentItem.addQuantity(quantity);
  }

  processDocumentItemAddition(documentItem: DocumentItemDto) {
    documentItem.itemAddedDate = new Date();
    documentItem.position = 0;
    documentItem.uniqueIdentifier = guid();
    this.items = [].concat(this.items, documentItem);
  }

  processDocumentLogisticItemAddition(item: DocumentLogisticItemDto) {
    item.scannedDateTime = new Date();
    this.logisticItems = [].concat(this.logisticItems, item);
  }

  processPaymentOutput(output: Array<{outputBase64: string}>) {
    this.addContent(
      flow([
        // #45991
        (output) => filter(output, ({outputBase64}) => {
          try {
            b64DecodeUnicode(outputBase64);
            return true;
          } catch (e) {
            console.error(e);
            recordCustomEventLogEntry('DocumentDto processPaymentOutput', 'invalid outputBase64');
            return false;
          }
        }),
        (output) => map(output, ({outputBase64}) => new DocumentContentDto({
          contentBase64: outputBase64,
          source: ContentBase64Sources.PaymentTerminalResult,
          id: guid(),
        })),
      ])(output),
    );
  }

  recalculateItemPositions() {
    forEach(this.items, (item, index) => {
      item.position = index + 1;
    });
  }

  recordFourEyes({operation, data = {}, accessToken, oneTimeCodeAuth}) {
    let cashier = null;
    if (accessToken) {
      const cashierTokenData = parseJwt(accessToken);
      cashier = new DocumentValidationCashier({
        id: cashierTokenData.oi_tkn_id,
        name: `${cashierTokenData.first_name} ${cashierTokenData.last_name}`,
        personalNumber: cashierTokenData.name,
      }).toJson();
    }

    this.documentValidations = [
      ...this.documentValidations ?? [],
      new DocumentValidation({
        operation,
        oneTimeCodeAuth,
        cashier,
        date: new Date().toISOString(),
        data,
      }),
    ];
  }

  refreshTotalPrice() {
    // @ts-ignore promoengine types has bit different enums
    recalculateDocumentTotalPrice({document: this._data});
  }

  refreshItemsAttributes() {
    recalculateDocument({
      document: <import('@designeo/pos-promotion-engine').DocumentDto> this._data,
    });
    this.refreshTotalPrice();
  }

  setStockType(stockType: InventoryStockType) {
    this.header.stockType = new StockType(stockType);
  }

  removeLogisticItemByGtin(
    gtin: DocumentLogisticItemDto['gtin'] | DocumentItemDto['gtin'],
  ) {
    this.logisticItems = filter(this.logisticItems, (item) => item.gtin !== gtin);
  }

  removeLogisticItemByInternalNumberWithBatch(
    internalNumberWithBatch: DocumentLogisticItemDto['internalNumberWithBatch'] | DocumentItemDto['internalNumberWithBatch'],
  ) {
    this.logisticItems = filter(this.logisticItems, (item) => item.internalNumberWithBatch !== internalNumberWithBatch);
  }

  setInventoryInfo(metadata: InventoryMetadataDto) {
    this.inventory.lastInventoryNumber = metadata.lastInventoryNumber;
    this.inventory.lastInventoryDate = metadata.lastInventoryDate;
    this.header.order = metadata.nextInventoryNumberOrder;


    this.inventory.finishedDate = new Date();
    this.calculateInventoryPriceDifferenceTotal();
  }

  calculateInventoryStock(logisticItems: DocumentLogisticItemDto[]) {
    this.inventory.stock = new InventoryStockDto({
      valueBefore: fixDecimals(sumBy(logisticItems, (logisticItem) => {
        return (logisticItem?.quantityStockOrdinaryAndExchange ?? 0) * (logisticItem?.price ?? 0);
      })),
      valueAfter: fixDecimals(sumBy(logisticItems, (logisticItem) => {
        return (logisticItem?.quantityReal ?? 0 + logisticItem?.quantityStockExchange ?? 0) * (logisticItem?.price ?? 0);
      })),
    });
  }

  calculateInventoryPriceDifferenceTotal() {
    this.inventory.priceDifferenceTotal = fixDecimals(sumBy(this.logisticItems, 'valueDifference'));
  }

  findInvalidLogisticItems() {
    const invalidItems = this.logisticItems.filter((logisticItem) => {
      return !Number.isInteger(logisticItem.quantity) || isNaN(logisticItem.quantity);
    });

    return invalidItems;
  }

  get hasCustomerInteractions() {
    return (this.promotions ?? []).some((promo) => {
      return (promo.type === PromotionMetaType.POINTS_BURN) ||
      (promo.type === PromotionMetaType.GIFT_SELECTED) ||
      (promo.type === PromotionMetaType.QUESTION && has(promo, 'answer'));
    });
  }

  resetCustomerInteractions() {
    const giftPoolsWithoutSelection = new Set((this.promotions ?? [])
      .filter((promo) => promo.type === PromotionMetaType.GIFT_POOL && promo.allowWithoutSelection)
      .map((promo: DocumentGiftPool) => promo.giftPool));

    this.promotions = (this.promotions ?? []).filter((promo) => {
      if (promo.type === PromotionMetaType.POINTS_BURN) {
        return false;
      }
      // selected gifts that are not automatically added
      if (promo.type === PromotionMetaType.GIFT_SELECTED && !giftPoolsWithoutSelection.has(promo.giftPool)) {
        return false;
      }
      return true;
    });
    for (const promo of this.promotions) {
      if (promo.type === PromotionMetaType.QUESTION) {
        delete promo.answer;
      }
    }
  }

  isOverMaxAcceptedCashAmount(newPayment: DocumentPaymentDto) {
    const maxAcceptedCashAmount = DocumentDto.configurationStore.localCurrency.value.maxAcceptedCashAmount;
    const paymentsValueSum = sumBy([...this.payments, newPayment], 'value');

    return paymentsValueSum > maxAcceptedCashAmount;
  }

  setKorunkaCard(cardNumber: string) {
    if (this.loyalty) {
      this.loyalty.setKorunkaCard(cardNumber);
    } else {
      this.loyalty = new DocumentLoyaltyDto({});
      this.loyalty.setKorunkaCard(cardNumber);
    }
  }

  expandCustomData(
    data: (
      Partial<CustomDataDto['_data']> |
      {[K in keyof CustomDataDto['$fields']]: ReturnType<CustomDataDto['$fields'][K]['type']['parse']>}
    ),
  ) {
    type D = typeof data;

    const isEntity = <T extends keyof D>(val: D[T]): val is Entity<any, any> => {
      return !!has(val, '$fields');
    };

    for (const [key, value] of <Array<[keyof D, D[keyof D]]>>Object.entries(data)) {
      const innerData = isEntity(value) ? value?.clone()?.toJson() : value;

      this.customData = new CustomDataDto({
        ...(this.customData?.toJson() ?? {}),
        [key]: innerData,
      });
    }
  }
}
