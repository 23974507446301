import routes from './routes';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerWorkflow(ctx: RequiredContext) {
  await ctx.registerRoutes(routes);
  return {};
}
