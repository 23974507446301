import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentItemPointsType from '../DocumentItemPointsType';
import TransactionType from '../TransactionType';
import {NumberType} from '@designeo/apibundle-js';


export const DocumentItemPointsDtoPropDef = {
  transactionId: {
    type: new StringType(),
  },
  clubCode: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(DocumentItemPointsType),
  },
  transactionType: {
    type: new EntityType(TransactionType),
  },
  points: {
    type: new NumberType(),
  },
  promoCode: {
    type: new StringType(),
  },
  priceConditionSap: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentItemPointsDto extends createEntity(DocumentItemPointsDtoPropDef) {}
