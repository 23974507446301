import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PosAttributeDataDto from '../PosAttributeDataDto';
import ShopDataDto from '../ShopDataDto';


export const PointOfSaleDataDtoPropDef = {
  id: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  isActive: {
    type: new BooleanType(),
  },
  isTest: {
    type: new BooleanType(),
  },
  posVersion: {
    type: new StringType(),
  },
  attributes: {
    type: new ArrayType(new EntityType(PosAttributeDataDto)),
  },
  eetIpAddress: {
    type: new StringType(),
  },
  eetTaxNumber: {
    type: new StringType(),
  },
  ipAddress: {
    type: new StringType(),
  },
  shop: {
    type: new EntityType(ShopDataDto),
  },
};
export default class GeneratedPointOfSaleDataDto extends createEntity(PointOfSaleDataDtoPropDef) {}
