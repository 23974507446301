import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ChangePasswordDtoPropDef = {
  oldPassword: {
    type: new StringType(),
  },
  newPassword: {
    type: new StringType(),
  },
};
export default class GeneratedChangePasswordDto extends createEntity(ChangePasswordDtoPropDef) {}
