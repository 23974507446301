
import {
  defineComponent,
} from 'vue';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepKorunkaTicketResult',
  components: {
    HLStep,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaTicketResult;

    return {
      stepType,
    };
  },
});
