export * from './AltTranform';
export * from './KeyPressStream';
export * from './KeyPressTransform';
export * from './KeypressToTextTransform';
export * from './DebouncedKeypressLogger';
export * from './TranslateKeysTransform';
import {createAltTransform} from './AltTranform';
import {createKeyPressTransform} from './KeyPressTransform';
import {createTranslateKeysTransform} from './TranslateKeysTransform';
import {createScannerTransformStream} from './ScannerTransform';
import {createKeyPressToBufferedInput} from './KeyPressToBufferedInput';
import {createLanguageTransformStream} from './LanguageTransform';
import {TransformStream} from '../streams';
import {KeyPressWithTimestamp} from '@/Modules/Register/services/KeyboardBuffer';
import {createAndroidPdaFixTransform} from './AndroidPdaFixTransform';


export const createFinalReaderTransform = ({scannerLanguage}) => {
  const stream = new TransformStream<KeyPressWithTimestamp, KeyPressWithTimestamp>();
  return {
    writable: stream.writable,
    readable: stream.readable
      .pipeThrough(createAndroidPdaFixTransform())
      .pipeThrough(createAltTransform())
      .pipeThrough(createScannerTransformStream())
      .pipeThrough(createKeyPressTransform())
      .pipeThrough(createLanguageTransformStream(scannerLanguage))
      .pipeThrough(createTranslateKeysTransform())
      .pipeThrough(createKeyPressToBufferedInput()),
  };
};
