
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepSafeBagSaveToSap',
  components: {WorkflowTransitions, HLStep},
  setup() {
    const stepType = WorkflowStepTypes.SafeBagSaveToSap;

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
