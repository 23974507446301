import {KorunkaVersion} from '@/constants/korunka';
import {
  EntityClass,
  BaseType,
  Type,
  ExtractEntityData,
  TypeOptions,
  EntityOptions,
} from '@designeo/apibundle-js';

export class EntityTypeKorunka<T extends {[V in KorunkaVersion]: EntityClass<any>}>
  extends BaseType
  implements Type<T[KorunkaVersion], ExtractEntityData<InstanceType<T[KorunkaVersion]>>> {
  public readonly entityByVersion: T

  constructor(entityByVersion: T) {
    super();
    this.entityByVersion = entityByVersion as T;
  }

  parse(
    data: ExtractEntityData<InstanceType<T[KorunkaVersion]>>,
    options?: TypeOptions<EntityOptions>,
  ): InstanceType<T[KorunkaVersion]> {
    if (!data) {
      return null;
    } else {
      const korunkaCurrentVersion = require('@/Helpers/korunka').getCurrentKorunkaVersion();
      const Entity = this.entityByVersion[korunkaCurrentVersion];
      return new Entity(data, options?.additionalOptions);
    }
  }
  format(
    entity: InstanceType<T[KorunkaVersion]>,
    options?: TypeOptions<EntityOptions>,
  ): ExtractEntityData<InstanceType<T[KorunkaVersion]>> {
    if (!entity) {
      return null;
    } else {
      return entity.toJson();
    }
  }
}
