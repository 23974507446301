import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col flex-grow" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_5 = {
  class: "overflow-auto pr-2",
  style: {"height":"380px"}
}
const _hoisted_6 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLScrollIntoView = _resolveComponent("HLScrollIntoView")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (workflowStep.step.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(workflowStep.step.description), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workflowStep.categories.entries(), ([category, description]) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: category,
                  class: _normalizeClass(["grid gap-1 w-full", [`grid-cols-${workflowStep.terminalCount + 1}`]]),
                  style: _normalizeStyle([
                workflowStep.terminalCount === 1
                  ? 'grid-template-columns: auto 140px'
                  : 'grid-template-columns: auto 100px 140px;'
              ])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex break-words w-full uppercase font-bold text-xs", [
                  _ctx.isActiveCategoryFieldTitle(category) ? 'text-secondary' : 'text-basic',
                ]])
                  }, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(description || category), 1)
                  ], 2),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workflowStep.getCategoryFieldsByInternalNumber(category), (field, index) => {
                    return (_openBlock(), _createBlock(_component_HLInput, {
                      key: index,
                      field: field
                    }, {
                      default: _withCtx(({isActive, value, setActive, insertMode, error}) => [
                        _createVNode(_component_HLScrollIntoView, { active: isActive }, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_FormInput, {
                              active: isActive,
                              padding: false,
                              labelUppercase: false,
                              labelFontBold: false,
                              showLabel: false,
                              label: description || category,
                              marginBottom: false,
                              layout: "col",
                              error: error,
                              class: "text-xs w-full",
                              errorOutput: 'modal',
                              onClick: setActive
                            }, {
                              "form-control": _withCtx(() => [
                                _createElementVNode("div", {
                                  class: "grid items-center mb-3",
                                  style: _normalizeStyle([
                          _ctx.isLastRowCategoryField(category, field)
                            ? 'grid-template-columns: 70% 30%'
                            : 'grid-template-columns: 100% 0%'
                        ])
                                }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(['form-control form-control--xs', {
                            ['form-control--active']: !error && isActive,
                            ['form-control--error']: error
                          }])
                                  }, [
                                    _createVNode(_component_InputBuffer, {
                                      value: _ctx.inputDisplayFilter(value.value),
                                      disableCaretNavigator: !isActive,
                                      insertMode: insertMode && isActive
                                    }, null, 8, ["value", "disableCaretNavigator", "insertMode"])
                                  ], 2),
                                  (_ctx.isLastRowCategoryField(category, field))
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: _normalizeClass(["text-right font-bold pl-1", {
                            'text-danger': workflowStep
                              .lotteryTurnovers
                              .getCategoryCorrection(
                                category,
                                String(workflowStep.sumCategoryFieldValues(category))
                              ) > 0,
                            'text-warning': workflowStep
                              .lotteryTurnovers
                              .getCategoryCorrection(
                                category,
                                String(workflowStep.sumCategoryFieldValues(category))
                              ) < 0,
                            'text-success': workflowStep
                              .lotteryTurnovers
                              .getCategoryCorrection(
                                category,
                                String(workflowStep.sumCategoryFieldValues(category))
                              ) === 0
                          }])
                                      }, _toDisplayString(workflowStep.lotteryTurnovers.getCategoryCorrection(
                              category,
                              String(workflowStep.sumCategoryFieldValues(category))
                            )), 3))
                                    : _createCommentVNode("", true)
                                ], 4)
                              ]),
                              _: 2
                            }, 1032, ["active", "label", "error", "onClick"]), [
                              [_directive_test, 'lotteryArticleInput']
                            ])
                          ]),
                          _: 2
                        }, 1032, ["active"])
                      ]),
                      _: 2
                    }, 1032, ["field"]))
                  }), 128))
                ], 6))
              }), 128))
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["text-lg text-right font-bold mt-2", {
              'text-danger': workflowStep.categoriesCorrectionSum > 0,
              'text-warning': workflowStep.categoriesCorrectionSum < 0,
              'text-success': workflowStep.categoriesCorrectionSum === 0
            }])
            }, _toDisplayString(_ctx.resultMessage), 3)
          ]),
          _createVNode(_component_WorkflowTransitions, { class: "my-0" })
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}