import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {apiSearchByAttribute} from '@/Model/Action';
import {DocumentItemDto, LotteryTurnoversDto} from '@/Model/Entity';
import * as yup from 'yup';
import {
  concat,
  every,
  find,
  flatMap,
  flow,
  fromPairs,
  groupBy,
  isNil,
  map,
  mapKeys,
  mapValues,
  omit,
  reduce,
  setWith,
  some,
  sum,
  uniqBy,
} from 'lodash-es';
import {sortByArray} from '@/Helpers/array';
import {toYupSchema, validationInvalidNominalsMinValue} from '@/Helpers/validations';
import {
  fieldsWithIndex,
  getOriginalField,
  prefixField,
  sanitizeField,
  sanitizeFields,
  sumLotteryFields,
} from '@/Helpers/lotteryFields';
import IMask from 'imask';
import {fixDecimals} from '@/Helpers/math';

export class WorkflowStepEnterLotteryCategoryWithArticles extends WorkflowStep {
  private _articleFields: string[]

  static get type() {
    return WorkflowStepTypes.EnterLotteryCategoryWithArticles;
  }

  get type() {
    return WorkflowStepEnterLotteryCategoryWithArticles.type;
  }

  get component() {
    return markRaw(require('./StepEnterLotteryCategoryWithArticles.vue').default);
  }

  get stepInit() {
    return this.context.get([this.dataKey, this.index].join('.'), {section: this.type})?.init ?? false;
  }

  set stepInit(val: boolean) {
    this.context.modifyAttribute([this.dataKey, this.index].join('.'), 'init', () => val, {section: this.type});
  }

  get currency() {
    return this.configurationStore
      .localCurrency
      .value;
  }

  get category() {
    return {
      id: this.step.categoryId,
      title: this.step.categoryTitle,
      isNegative: this.configurationStore
        .configuration
        ?.value
        ?.features
        ?.lottery
        ?.articleCategories
        ?.[this.step.categoryId]
        ?.isNegative ??
      this.step?.categoryIsNegative ?? false,
    };
  }

  get isResolvedByCategory() {
    return this.configurationStore
      .configuration
      ?.value
      ?.features
      ?.lottery
      ?.articleCategories
      ?.[this.step.categoryId]
      ?.resolveByCategory ??
    this.step?.resolveByCategory ?? false;
  }

  get skipCategory() {
    return this.step?.skipCategory ?? false;
  }

  get lotteryAmountEquals() {
    return this.getFieldValue(WorkflowStepField.lotteryAmountEquals, false);
  }

  get canBeReturnedTo() {
    return this.lotteryAmountEquals === false;
  }

  get terminalCount() {
    return this.configurationStore.configuration.value?.features?.lottery?.terminalCount ?? 1;
  }

  get inputCharsMaxLen() {
    return this.configurationStore.configuration.value?.features?.lottery?.inputCharsMaxLen ?? 6;
  }

  get articleFields() {
    return this._articleFields;
  }

  get categoryFields() {
    return sanitizeFields(
      fieldsWithIndex(
        `${WorkflowStepField.lotteryCategories}.${this.category.id}.${WorkflowStepField.amount}`,
        this.terminalCount,
      ),
    );
  }

  get lotteryTurnovers() {
    return new LotteryTurnoversDto(this.getFieldValue(WorkflowStepField.lotteryTurnovers, {}));
  }

  get lotteryArticlesFromInaccurateCategories() {
    return map(this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []), (article) => {
      return new DocumentItemDto(article ?? {});
    });
  }

  get lotteryArticlesFromInaccurateCategoriesByInternalNumber() {
    return mapKeys(this.lotteryArticlesFromInaccurateCategories, 'internalNumber');
  }

  get articlesByInaccurateCategoryId() {
    return groupBy(this.lotteryArticlesFromInaccurateCategories, 'parentId')?.[this.category.id] ?? [];
  }

  get hasUnresolvedArticles() {
    return this.articlesByInaccurateCategoryId.length > 0 && !this.isResolvedByCategory;
  }

  get categoryEnteredAmount() {
    const fieldValue = this.getFieldValue(
      `${WorkflowStepField.lotteryCategories}.${prefixField(this.category.id)}.${WorkflowStepField.amount}`,
    );
    const sum = sumLotteryFields(fieldValue);
    return !isNil(sum) ? String(fixDecimals(sum)) : null;
  }

  get categoryCorrection() {
    return this.lotteryTurnovers.getCategoryCorrection(this.category.id, this.categoryEnteredAmount);
  }

  get hasFilledArticles() {
    const articles = this.articlesByInaccurateCategoryId;

    return some(map(articles, (article) => {
      const field = this.getFieldKeyByInternalNumber(prefixField(article.internalNumber));
      const amount = this.getFieldValue(field);

      return !!amount;
    }));
  }

  get hasFilledAllCategoryFields() {
    return every(map(this.categoryFields, (field) => {
      const amount = this.getFieldValue(field);

      return !!amount;
    }));
  }

  get articleValuesSum() {
    const articles = this.articlesByInaccurateCategoryId;

    const valuesSum = sum(map(articles, (article) => {
      const field = this.getFieldKeyByInternalNumber(prefixField(article.internalNumber));
      const amount = String(sumLotteryFields(this.getFieldValue(field)));

      return this.lotteryTurnovers.getArticleCorrection(article, amount);
    }));

    return fixDecimals(valuesSum);
  }

  get categoryArticles() {
    return this.getFieldValue('lotteryArticles', []);
  }

  getFieldKeyByInternalNumber(internalNumber, sanitize = false) {
    const field = [
      WorkflowStepField.lotteryArticles,
      ...(sanitize ? [prefixField(internalNumber)] : [internalNumber]),
      WorkflowStepField.amount,
    ].join('.');

    return field;
  }

  get articleFieldsGroupedByInternalNumber() {
    return groupBy(this.articleFields, (field) => {
      const [stepField, internalNumber, amount, index] = getOriginalField(field).split('.');
      return internalNumber;
    });
  }

  getArticleFieldsByInternalNumber(internalNumber) {
    const fields = this.articleFieldsGroupedByInternalNumber;

    return fields[internalNumber];
  }

  resetCategoryArticlesAmount() {
    const categoryCorrection = this.lotteryTurnovers.getCategoryCorrection(
      this.category.id, this.categoryEnteredAmount,
    );

    if (categoryCorrection === 0) {
      const articleIds = map(this.articlesByInaccurateCategoryId, 'internalNumber');

      this.dataSetter(WorkflowStepField.lotteryArticles, (value) => {
        if (value) {
          return {
            ...value,
            ...fromPairs(
              map(
                articleIds, (internalNumber) => ([
                  prefixField(internalNumber),
                  {
                    amount: fromPairs(
                      Array.from({length: this.terminalCount}, (v, index) => [prefixField(index, 'f'), '0']),
                    ),
                  },
                ]),
              ),
            ),
          };
        }
      });
      this.dataSetter(WorkflowStepField.inaccurateArticlesByCategory, (value) => {
        if (value) {
          return omit(value, this.category.id);
        }
      });
    }
  }

  sumArticleFieldValues(internalNumber): number {
    const fields = this.getArticleFieldsByInternalNumber(internalNumber);
    const fieldData = map(fields, (field) => this.getFieldValue(field));
    const sum = sumLotteryFields(fieldData);
    return !isNil(sum) ? fixDecimals(sum) : null;
  }

  setEnteredValues() {
    const lotteryCategoriesTurnoversByInternalNumber = mapValues(
      this.lotteryTurnovers.turnoverCategoriesByCategoryId,
      (v) => v.totalAmount,
    );

    const lotteryArticlesTurnoversByInternalNumber = mapValues(
      this.lotteryTurnovers.turnoverArticlesByInternalNumber,
      (v) => v.totalAmount,
    );

    this.dataSetter('enteredLotteryValues', (currentValue = []) => {
      const categoryInternalNumber = this.category.id;
      const categoryDescription = this.step.title;
      const categoryEnteredValue = this.categoryEnteredAmount ? parseInt(this.categoryEnteredAmount) : 0;
      const categoryTurnoverValue = lotteryCategoriesTurnoversByInternalNumber?.[categoryInternalNumber] ?? 0;
      const categoryCorrection = !this.isResolvedByCategory ? null : this.lotteryTurnovers.getCategoryCorrection(
        categoryInternalNumber,
        categoryEnteredValue.toString(),
      );

      const articles = map(this.articlesByInaccurateCategoryId, (article) => {
        const articleEnteredValue = this.sumArticleFieldValues(article.internalNumber) ?? 0;
        const articleTurnoverValue = lotteryArticlesTurnoversByInternalNumber?.[article.internalNumber] ?? 0;
        const articleCorrection = this.lotteryTurnovers.getArticleCorrection(
          article,
          articleEnteredValue.toString(),
        );

        return {
          internalNumber: article.internalNumber,
          description: article.description,
          articleEnteredValue,
          articleTurnoverValue,
          articleCorrection,
        };
      });

      return [
        ...currentValue,
        {
          categoryDescription,
          categoryInternalNumber,
          categoryTurnoverValue,
          categoryEnteredValue,
          categoryCorrection,
          isResolvedByCategory: this.isResolvedByCategory,
          articles,
        },
      ];
    });
  }

  async searchAndCheckArticles() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const searchResults = await apiSearchByAttribute({params: {attribute: 'parentId', value: this.category.id}});
      const articles = sortByArray(
        map(searchResults, ({documentItem}) => documentItem.toJson()) ?? [],
        this.step.articlesOrder,
        'internalNumber',
      );

      this.dataSetter(
        WorkflowStepField.lotteryArticlesFromInaccurateCategories,
        () => !this.isResolvedByCategory ?
          articles :
          [find(articles, (article) => article.internalNumber === this.category.id)], // set category main article
      );
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeEnter() {
    try {
      // reset validation errors
      this.validationError = {};
      // always recalculate articles when they are set
      const articles: any[] = this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []);
      if (articles.length) {
        this.dataSetter(WorkflowStepField.lotteryArticlesFromInaccurateCategories, () => {
          return [];
        });
      }

      if (this.lotteryAmountEquals) {
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        return;
      }

      await this.searchAndCheckArticles();

      this._articleFields = flatMap(this.lotteryArticlesFromInaccurateCategories, (article) => {
        return sanitizeFields(
          fieldsWithIndex(this.getFieldKeyByInternalNumber(article.internalNumber), this.terminalCount),
        );
      });

      if (!this.stepInit) {
        const articleIds = map(this.articlesByInaccurateCategoryId, 'internalNumber');

        this.dataSetter(WorkflowStepField.lotteryArticles, (value) => {
          return {
            ...value,
            ...fromPairs(
              map(
                articleIds, (internalNumber) => ([
                  prefixField(internalNumber),
                  {
                    amount: fromPairs(
                      Array.from({length: this.terminalCount}, (v, index) => [prefixField(index, 'f'), '0']),
                    ),
                  },
                ]),
              ),
            ),
          };
        });
      }

      this.stepInit = true;
    } catch (e) {
      console.error(e);
    }
  }

  async beforeContinue() {
    this.setEnteredValues();

    if (this.lotteryAmountEquals) return;

    if (!this.isResolvedByCategory) {
      const inaccurateArticles = [];

      const categoryCorrection = this.lotteryTurnovers.getCategoryCorrection(
        this.category.id, this.categoryEnteredAmount,
      );

      const articles: DocumentItemDto[] = groupBy(
        this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []),
        'parentId',
      )?.[this.category.id] ?? [];

      for (const article of articles) {
        const field = this.getFieldKeyByInternalNumber(article.internalNumber, true);
        const fieldValue = sumLotteryFields(this.getFieldValue(field, '0'));
        const enteredAmount = !article.hasManualPrice ? (fieldValue * article.priceNormal) : fieldValue;
        const valueBeforeDiscounts: number = this.lotteryTurnovers.getArticleTotalAmount(article);

        if (
          enteredAmount !== valueBeforeDiscounts &&
          (this.skipCategory ? true : categoryCorrection !== 0)
        ) {
          inaccurateArticles.push(article);
        }
      }

      this.dataSetter(WorkflowStepField.inaccurateArticlesByCategory, (currentValue = {}) => {
        return {
          ...currentValue,
          ...(inaccurateArticles.length ? {[this.category.id]: inaccurateArticles} : {}),
        };
      });
    } else {
      try {
        const article = find(
          this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []),
          (article: DocumentItemDto) => article.internalNumber === this.category.id,
        ) ?? null;

        if (article) {
          /* eslint-disable-next-line */
          const field = `${WorkflowStepField.lotteryCategories}.${prefixField(this.category.id)}.${WorkflowStepField.amount}`;
          const enteredAmount = sumLotteryFields(this.getFieldValue(field, '0'));
          const valueBeforeDiscounts: number = this.lotteryTurnovers.getCategoryTotalAmount(this.category.id);

          if (enteredAmount !== valueBeforeDiscounts) {
            // treat category as if it was article - used for diffing and correction
            this.dataSetter(
              this.getFieldKeyByInternalNumber(article.internalNumber, true),
              () => ({'f:0': String(enteredAmount)}),
            );

            // sum all articles into the main one resolved by the category
            // (needed when there is more than one article resolved by the category)
            this.dataSetter(WorkflowStepField.lotteryTurnovers, (currentValue: LotteryTurnoversDto) => {
              const indexOfMainArticle = currentValue.turnoverByArticles.findIndex(
                (article) => article.internalNumber === this.category.id,
              );

              if (indexOfMainArticle !== -1) {
                currentValue.turnoverByArticles[indexOfMainArticle].totalAmount = valueBeforeDiscounts;
              }

              return currentValue;
            });

            // set default category article
            this.dataSetter(WorkflowStepField.inaccurateArticlesByCategory, (currentValue = {}) => {
              return {
                ...currentValue,
                ...(article ? {[this.category.id]: article} : {}),
              };
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  get validator(): yup.AnyObjectSchema {
    const fields = [
      ...(this.skipCategory ? [] : this.categoryFields),
      ...(this.isResolvedByCategory ? [] : this.articleFields),
    ];

    const objectSchema = reduce(fields, (result, path) => {
      const [/* skip */, internalNumber] = getOriginalField(path).split('.');
      const article = mapKeys(this.lotteryArticlesFromInaccurateCategories, 'internalNumber')?.[internalNumber];
      const fieldIsArticle = this.articleFields.includes(path);

      const isNegative = fieldIsArticle ? article.isNegative : this.category.isNegative;

      const validation = yup.string()
        .test(validationInvalidNominalsMinValue(this.currency, {acceptZero: true, acceptNegative: true}))
        .max(this.inputCharsMaxLen + (isNegative ? 1 : 0))
        .typeError('validations.string.max')
        .required();

      return setWith(result, path, validation, Object);
    }, {});

    return toYupSchema(objectSchema);
  }

  activateInsertMode(field: string): boolean {
    const value = this.getFieldValue(field);
    return value === '0';
  }

  get transitions() {
    const lotteryArticlesFromInaccurateCategoriesByInternalNumber = this
      .lotteryArticlesFromInaccurateCategoriesByInternalNumber;

    return {
      ...map(this.categoryFields, (field, index: number, array) => {
        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
              this.resetCategoryArticlesAmount();
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
              this.resetCategoryArticlesAmount();
            }),
            [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_COMMA](event.value);
              this.resetCategoryArticlesAmount();
            }),
            [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_PERIOD](event.value);
              this.resetCategoryArticlesAmount();
            }),
            [WorkflowActions.ENTER]: action(async () => {
              try {
                const isLastCategoryField = index === array.length - 1;

                if ((this.isResolvedByCategory || this.categoryCorrection === 0) && isLastCategoryField) {
                  if (!await this.validate()) return;
                  this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
                  return;
                }

                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                  detail: {
                    field: isLastCategoryField ? this.articleFields[0] : this.categoryFields[index + 1],
                  },
                }));
              } catch (e) {
                console.error(e);
              }
            }),
            ...this.createArrowMovementTransitions(this.categoryFields, index),
          }), {
            mask: IMask.createMask({
              mask: Array.from({length: this.inputCharsMaxLen + (this.category.isNegative ? 1 : 0)}, () => '*').join(),
            }),
            transformValue: (value) => {
              if (parseInt(value) === 0) return value;

              if (this.category.isNegative && parseInt(value) > 0) {
                return String(Math.abs(parseFloat(value)) * -1);
              }

              return isNaN(value) ? '' : value;
            },
          }),
        };
      }).reduce((acc, val, index) => ({...acc, ...val}), {}),
      ...map(this.articleFields, (field, index: number) => {
        const [/* skip */, internalNumber] = getOriginalField(field).split('.');
        const article = lotteryArticlesFromInaccurateCategoriesByInternalNumber?.[internalNumber];

        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
              if (!article.hasManualPrice || this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_COMMA](event.value);
            }),
            [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
              if (!article.hasManualPrice || this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_PERIOD](event.value);
            }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = this.articleFields?.[index + 1];

              if (nextField) {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                  detail: {
                    field: nextField,
                  },
                }));
              } else {
                if (!await this.validate()) return;
                this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
              }
            }),
            ...this.createArrowMovementTransitions(this.articleFields, index),
          }), {
            mask: IMask.createMask({
              mask: Array.from({length: this.inputCharsMaxLen + (article.isNegative ? 1 : 0)}, () => '*').join(),
            }),
            transformValue: (value) => {
              if (parseInt(value) === 0) return value;

              if (article.isNegative && parseInt(value) > 0) {
                return String(Math.abs(parseFloat(value)) * -1);
              }

              return isNaN(value) ? '' : value;
            },
          }),
        };
      }).reduce((acc, val, index) => ({...acc, ...val}), {}),
    };
  }
}
