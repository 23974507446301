import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const PrinterConfigurationUpdateResultDtoPropDef = {
  updated: {
    type: new BooleanType(),
  },
};
export default class GeneratedPrinterConfigurationUpdateResultDto extends createEntity(PrinterConfigurationUpdateResultDtoPropDef) {}
