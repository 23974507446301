import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {RouteLocationRaw} from 'vue-router';
import {useAcl} from '@/Helpers/acl';
import {PERMISSION_CANCEL} from '@/constants/aclKeys';
import {useRestoreModeStore} from '@/Modules/Core/store/RestoreModeStore';

export interface IIncorrectRestartStore {
  callback: (route?: RouteLocationRaw)=> void
}

const createInitState = (data?: Partial<IIncorrectRestartStore>) => ({
  callback: null,
});

export class IncorrectRestartStore extends Store<IIncorrectRestartStore> {
  constructor() {
    super(createInitState());
  }

  setCallback = action((callback) => {
    this.state.callback = (...args) => {
      callback(...args);
      this.state.callback = null;
    };
  })

  ensureWorkflowReset = action(async () => {
    const workflowStore = useWorkflowStore();

    if (workflowStore.currentWorkflow.value) {
      await workflowStore.finishAllWorkflows();
    }
  })

  ensureRegisterReset = action(async () => {
    const registerStore = useRegisterStore();
    const sellDocument = registerStore.sellDocument.value;

    if (sellDocument?.isUntouched) {
      return;
    }

    if (sellDocument?.canBeDiscarded ?? true) {
      await registerStore.resetReceipt();
      return;
    }

    if (registerStore.isEditModeActive.value) {
      await registerStore.ensurePurchaseFlowReset();
    }

    if (!registerStore.isRegisterStateCancelMode.value && registerStore.cancelModeIsUsable.value) {
      await registerStore.toggleCancelMode();
    }

    if (sellDocument?.validPaymentsWithAnyState.length) {
      return; // we cannot solve this case here!
    }

    if (sellDocument?.canBeCanceledWithoutConfirmation) {
      try {
        await registerStore.cancelSellDocument({fourEyes: false});
      } catch (e) {
        console.error(e);
      }
      return;
    }

    if (sellDocument.canBeCanceled && useAcl()(PERMISSION_CANCEL)) {
      try {
        await registerStore.cancelSellDocument();
      } catch (e) {
        console.error(e);
      }
      return;
    }
  })

  continue = action(async () => {
    if (this.canCancelSellDocument.value) {
      this.state?.callback?.({name: 'register'});
    } else if (this.canCancelWorkflow.value) {
      this.state?.callback?.({name: 'workflow', params: {workflowCode: useWorkflowStore().currentWorkflow.value.code}});
    } else {
      this.state?.callback?.();
    }
  })

  cancel = action(() => {
    if (this.canCancelSellDocument.value) {
      this.cancelSellDocument();
    } else if (this.canCancelWorkflow.value) {
      this.cancelWorkflow();
    } else {
      this.state?.callback?.();
    }
  })

  cancelWorkflow = action(async () => {
    await this.ensureWorkflowReset();
    this.state?.callback?.({name: 'login'});
  })

  cancelSellDocument = action(async () => {
    await this.ensureRegisterReset();
    await this.ensureWorkflowReset();
    this.state?.callback?.({name: 'register'});
  })

  isActive = getter(() => {
    if (useRestoreModeStore().active.value) {
      return false;
    }

    return this.canCancelWorkflow.value || this.canCancelSellDocument.value;
  })

  isVisible = getter(() => {
    const authStore = useAuthStore();

    if (!this.isActive.value) {
      return false;
    }

    if (!this.state?.callback) {
      return false;
    }

    if (!authStore.activePerson.value) {
      return false;
    }


    if (Object.keys(authStore.quickLoginPeopleByUsername.value).length) {
      return authStore.isAuthStateChoosePerson.value;
    }

    return authStore.isStateTypeInputPersonalNumber.value;
  })

  canContinue = getter(() => {
    return true;
  })

  canCancelWorkflow = getter(() => {
    if (this.canCancelSellDocument.value) {
      return false;
    }

    const workflowStore = useWorkflowStore();


    return !!workflowStore.currentWorkflow.value;
  })

  canCancelSellDocument = getter(() => {
    const registerStore = useRegisterStore();
    const sellDocument = registerStore.sellDocument.value;

    if (sellDocument?.isUntouched ?? true) {
      return false;
    }

    return true;
  })
}

const storeIdentifier = 'IncorrectRestartStore';

export const configureIncorrectRestartStore = createConfigureStore<typeof IncorrectRestartStore>(storeIdentifier);
export const useIncorrectRestartStore = createUseStore(IncorrectRestartStore, storeIdentifier);
