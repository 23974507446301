
import {
  defineComponent,
} from 'vue';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import InputBuffer from '@/Components/InputBuffer.vue';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '../../types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepKorunkaLotteryScanCard} from './WorkflowStepKorunkaLotteryScanCard';

export default defineComponent({
  name: 'StepKorunkaLotteryScanCard',
  components: {
    HLStep,
    HLInput,
    FormInput,
    InputBuffer,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaScanCard;

    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryScanCard>(stepType);

    return {
      stepType,
      workflowStep,
      WorkflowStepField,
    };
  },
});
