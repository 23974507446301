import * as Sentry from '@sentry/vue';
import {Offline} from '@sentry/integrations';
import {SeverityLevel} from '@sentry/types';
import {BrowserTracing} from '@sentry/tracing';
import {Configuration, DocumentDto} from '@/Model/Entity';
import {AuthPerson, LoginType} from '@/Modules/Auth/types';
import {Workflow} from '@/Modules/Workflow/Workflow/Workflow';
import {guid} from '@/Helpers/guid';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useSignalR} from '@/Helpers/signalR';
import {CoreWSEvents} from '@/Modules/Core/types';
import {App} from 'vue';
import {Router} from 'vue-router';
import {isElectron} from '@/Helpers/app';
import {getCurrentBuildHash, e2eScenarioIdSessionStorage} from '@/Helpers/persist';
import {mapValues} from 'lodash-es';
import {recordCustomEventLogEntry} from './logger';

let pendingRequests = 0;

export enum SentryTags {
  isElectron = 'isElectron',
  buildHash = 'buildHash',
  posId = 'posId',
  posCode = 'posCode',
  shopId = 'shopId',
  shopCode = 'shopCode',
  shopCodeAndPosCode = 'shopCodeAndPosCode',
  language = 'language',
  configured = 'configured',
  tag = 'tag',
}

export enum SentryTransactionTypes {
  SellDocument = 'SellDocument',
  Workflow = 'Workflow',
  StandaloneAxiosRequest = 'StandaloneAxiosRequest',
}

export enum SentryBreadcrumbsCategories {
  auth = 'auth',
  document = 'document'
}

const sanitizeGuid = (guid: string) => guid.replace(/-/g, '');


export function sentrySetIsElectron() {
  Sentry.setTag(SentryTags.isElectron, isElectron());
}

export function sentrySetBuildHash() {
  Sentry.setTag(SentryTags.buildHash, getCurrentBuildHash());
}

export function sentrySetConfiguration(configuration: Configuration) {
  if (configuration.isPosNotConfigured) {
    Sentry.setTag(SentryTags.posId, 'n/a');
    Sentry.setTag(SentryTags.posCode, 'n/a');
    Sentry.setTag(SentryTags.shopId, 'n/a');
    Sentry.setTag(SentryTags.shopCode, 'n/a');
    Sentry.setTag(SentryTags.shopCodeAndPosCode, [
      'n/a',
      'n/a',
    ].join('/'));
  } else {
    Sentry.setTag(SentryTags.posId, configuration.general.pos.id);
    Sentry.setTag(SentryTags.posCode, configuration.general.pos.code);
    Sentry.setTag(SentryTags.shopId, configuration.general.pos.shop.id);
    Sentry.setTag(SentryTags.shopCode, configuration.general.pos.shop.code);
    Sentry.setTag(SentryTags.shopCodeAndPosCode, [
      configuration.general.pos.shop.code,
      configuration.general.pos.code,
    ].join('/'));
  }

  Sentry.setTag(SentryTags.configured, configuration.general.configured.value);
  Sentry.setTag(SentryTags.language, configuration.general.language.value);

  if (process.env.APP_VERSION) {
    Sentry.setTag(SentryTags.tag, process.env.APP_VERSION);
  }
}

export function sentryCurrentUnclosedContext() {
  const scope = Sentry.getCurrentHub().getScope();
  const transaction = scope.getTransaction();

  if (transaction && !transaction?.endTimestamp) {
    return {
      scope,
      transaction,
    };
  }

  return null;
}

/**
 * Transaction logic is:
 * - I can have only 1 parent transaction
 * - If I have active sellDocumentTransaction, workflows are recorded as events
 */

export function sentryEnsureSellDocumentTransaction(doc: DocumentDto) {
  const unclosedContext = sentryCurrentUnclosedContext();

  if (unclosedContext && unclosedContext.transaction.name !== SentryTransactionTypes.SellDocument) {
    unclosedContext.transaction.finish();
    unclosedContext.scope.clearBreadcrumbs();
  }

  if (sentryCurrentUnclosedContext()) {
    return;
  }

  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(Sentry.startTransaction({
    name: SentryTransactionTypes.SellDocument,
    traceId: sanitizeGuid(doc.header.uniqueidentifier),
    op: e2eScenarioIdSessionStorage.get(),
  })));
}

export function sentryFinishCurrentSellDocumentTransaction() {
  const unclosedContext = sentryCurrentUnclosedContext();

  if (!unclosedContext) {
    return;
  }

  if (unclosedContext.transaction.name === SentryTransactionTypes.SellDocument) {
    unclosedContext.transaction.finish();
    unclosedContext.scope.clearBreadcrumbs();
  }
}

export function sentryEnsureWorkflowTransaction(workflow: Workflow) {
  const unclosedContext = sentryCurrentUnclosedContext();

  if (unclosedContext) {
    setTransactionEvent(`Workflow start ${workflow.code} ${workflow.guid}`);
    return;
  }

  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(Sentry.startTransaction({
    name: SentryTransactionTypes.Workflow,
    description: workflow.code,
    data: {
      workflowGuid: workflow.guid,
    },
    traceId: sanitizeGuid(workflow.guid),
    op: e2eScenarioIdSessionStorage.get(),
  })));
}

export function sentryFinishCurrentWorkflowTransaction(workflow: Workflow) {
  const unclosedContext = sentryCurrentUnclosedContext();

  if (!unclosedContext) {
    return;
  }

  if (
    unclosedContext.transaction.name === SentryTransactionTypes.Workflow &&
    unclosedContext.transaction?.data?.workflowGuid === workflow.guid
  ) {
    unclosedContext.transaction.finish();
    unclosedContext.scope.clearBreadcrumbs();
  } else {
    setTransactionEvent(`Workflow end ${workflow.code} ${workflow.guid}`);
  }
}

export function sentryEnsureStandaloneAjaxRequestTransaction() {
  const unclosedContext = sentryCurrentUnclosedContext();
  pendingRequests++;

  if (!unclosedContext) {
    Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(Sentry.startTransaction({
      name: SentryTransactionTypes.StandaloneAxiosRequest,
      traceId: sanitizeGuid(guid()),
      op: e2eScenarioIdSessionStorage.get(),
    })));
  }
}

export function sentryFinishCurrentStandaloneAjaxRequestTransaction() {
  pendingRequests--;
  const unclosedContext = sentryCurrentUnclosedContext();

  if (
    unclosedContext &&
    unclosedContext.transaction.name === SentryTransactionTypes.StandaloneAxiosRequest &&
    !pendingRequests
  ) {
    unclosedContext.transaction.finish();
    unclosedContext.scope.clearBreadcrumbs();
  }
}

export function sentryFinishCurrentTransaction() {
  const unclosedContext = sentryCurrentUnclosedContext();

  if (!unclosedContext) {
    return;
  }

  unclosedContext.transaction.finish();
  unclosedContext.scope.clearBreadcrumbs();
}

export function setTransactionEvent(
  event: string,
  {
    type = 'event',
    start = new Date(),
    end = start,
    status = 'ok',
    transaction = Sentry.getCurrentHub().getScope()
      .getTransaction(),
  } = {},
) {
  if (!transaction) {
    return;
  }

  const span = transaction.startChild({
    op: type,
    startTimestamp: (+start / 1000),
    endTimestamp: (+end / 1000),
    description: event,
  });

  span.setStatus(status);
  span.finish();
}

export function sentryRecordOperatorChange(
  person: Pick<AuthPerson, 'username'>,
  login: boolean,
  {
    loginType = LoginType.M,
  }: {
    loginType?: LoginType,
  } = {},
) {
  if (login) {
    Sentry.setUser({id: person.username, loginType});
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbsCategories.auth,
      message: `Person ${person.username} login`,
      level: 'info' as SeverityLevel,
    });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
    Sentry.addBreadcrumb({
      category: SentryBreadcrumbsCategories.auth,
      message: `Person ${person.username} logout`,
      level: 'info' as SeverityLevel,
    });
  }
}

export function sentryRecordSellDocumentInit(doc: DocumentDto) {
  Sentry.addBreadcrumb({
    category: SentryBreadcrumbsCategories.document,
    message: `SellDocument ${doc.header.uniqueidentifier} inited`,
    level: 'info' as SeverityLevel,
  });
}

export function sentryRecordSellDocumentCreate(doc: DocumentDto) {
  Sentry.addBreadcrumb({
    category: SentryBreadcrumbsCategories.document,
    message: `SellDocument ${doc.header.uniqueidentifier} created`,
    level: 'info' as SeverityLevel,
  });
}

export function sentryRecordSellDocumentCancel(doc: DocumentDto) {
  Sentry.addBreadcrumb({
    category: SentryBreadcrumbsCategories.document,
    message: `SellDocument ${doc.header.uniqueidentifier} canceled`,
    level: 'info' as SeverityLevel,
  });
}


export function sentryRecordException(exception: Error, {message = null} = {}) {
  if (message) {
    exception.message = `${message} - ${exception.message}`;
  }

  Sentry.captureException(exception);
}

export function sentryCaptureEvent(message) {
  Sentry.captureMessage(message, 'log' as SeverityLevel);
}

type SentryConfiguration = {
  dsn?: string,
  environment?: string,
  maxBreadcrumbs?: number,
  tracesSampleRate?: number,
  attachStackTrace?: boolean,
  sections?: {
    [key in SentryTransactionTypes]: {
      tracesSampleRate?: number,
    }
  }
}

/**
 * Note: we use || for some options because backend can return it as empty string
 */

const sanitizeSentryConfiguration = (config: SentryConfiguration): SentryConfiguration => {
  // eslint-disable-next-line max-len
  config.dsn = config.dsn || process.env.SENTRY_FALLBACK_DSN || 'https://976449e87db44e30b8db0faae7bd0095@sentry.designeo.cz/9';
  config.environment = config.environment || 'NotAssigned';
  config.maxBreadcrumbs = config.maxBreadcrumbs ?? 100;
  config.tracesSampleRate = config.tracesSampleRate ?? 1;
  config.attachStackTrace = config.attachStackTrace ?? false;
  config.sections = {...mapValues(SentryTransactionTypes, () => ({})), ...(config.sections ?? {})};
  config.sections = mapValues(config.sections, (section) => {
    section.tracesSampleRate = section.tracesSampleRate ?? config.tracesSampleRate ?? 1;
    return section;
  });

  return config;
};

const configurationChangesProcessedListener = async ({configuration = null} = {}) => {
  if (configuration) {
    recordCustomEventLogEntry('Sentry event', CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED);
    sentrySetConfiguration(new Configuration(configuration));
  }
};

const ensureConfigurationChangesProcessedListener = async () => {
  const {notificationsConnection} = useSignalR();

  await notificationsConnection.removeEventListener(
    CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED,
    configurationChangesProcessedListener,
  );

  await notificationsConnection.addEventListener<[{configuration?: Configuration['_data']}]>(
    CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED,
    configurationChangesProcessedListener,
  );
};

let currentConfig = null;

const configurationsAreEqual = (newConfiguration) => {
  return JSON.stringify(newConfiguration) === JSON.stringify(currentConfig);
};

export function initSentry(ctx: {
  app: App<any>
  router: Router,
  config?: SentryConfiguration,
}) {
  if (process.env.NODE_ENV === 'development') return;

  ctx.config = sanitizeSentryConfiguration(ctx.config ?? {});

  const currentDsn = Sentry.getCurrentHub().getClient()
    ?.getOptions().dsn ?? null;

  if (currentDsn && configurationsAreEqual(ctx.config)) {
    // eslint-disable-next-line no-console
    console.log('[Sentry] configs are equal, initialize skipped');
    return;
  }

  const configurationStore = useConfigurationStore();

  Sentry.init({
    // debug: true,
    app: ctx.app,
    release: process.env.SENTRY_RELEASE,
    dsn: ctx.config.dsn,
    environment: ctx.config.environment,
    maxBreadcrumbs: ctx.config.maxBreadcrumbs,
    tracesSampleRate: ctx.config.tracesSampleRate,
    attachStacktrace: ctx.config.attachStackTrace,
    attachProps: true,
    integrations: [
      new Offline({maxStoredEvents: 100}),
      new BrowserTracing({
        tracingOrigins: ['localhost', /^\/api/],
        traceFetch: true,
        traceXHR: true,

        idleTimeout: null,
        finalTimeout: null,
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: false,
        markBackgroundTransactions: false,
      }),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      return breadcrumb;
    },
    beforeSend(event) {
      return event;
    },
    tracesSampler: (samplingContext) => {
      if (process.env.NODE_ENV === 'development') {
        return true;
      }

      return ctx.config
        .sections
        ?.[samplingContext?.transactionContext?.name]
        ?.tracesSampleRate ?? ctx.config.tracesSampleRate;
    },
  });

  currentConfig = ctx.config;

  // eslint-disable-next-line no-console
  console.log('[Sentry] initialized');


  sentrySetBuildHash();
  sentrySetIsElectron();
  sentrySetConfiguration(configurationStore.configuration.value);
  ensureConfigurationChangesProcessedListener();
  return {};
}
