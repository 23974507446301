import {createEntity, StringType} from '@designeo/apibundle-js';

export default class BTCCheckRequestDtoCustom extends createEntity({
  paymentNumber: {
    type: new StringType(),
  },
  pin: {
    type: new StringType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
