import {KeyPress, BufferedInput} from '@/Modules/Register/services/KeyboardBuffer';
import {
  ReadableStream,
} from '../streams';

export enum SequenceMatched {
  YES='yes',
  NO='no',
  NOT_FINISHED='not finished',
}

export const key = <T extends KeyPress>(keypress: Partial<T>): KeyPress & Partial<T> => {
  return {
    code: 'UNKNOWN',
    alt: false,
    shift: false,
    ctrl: false,
    key: '0',
    ...keypress,
  };
};

export const keypress = (keypress: Partial<KeyPress>): KeyPress[] => {
  const k = key(keypress);
  return [{...k, dir: 'down'}, {...k, dir: 'up'}];
};

export const isSequence = (sequence: ((key: KeyPress)=> boolean)[]) => (capturedSequence: KeyPress[]) => {
  for (let i = 0; i < capturedSequence.length; i++) {
    if (!sequence[i](capturedSequence[i])) {
      return SequenceMatched.NO;
    }
  }
  return capturedSequence.length == sequence.length ? SequenceMatched.YES : SequenceMatched.NOT_FINISHED;
};

export const someSequenceMatched = (...matches: SequenceMatched[]) => {
  if (matches.includes(SequenceMatched.YES)) {
    return SequenceMatched.YES;
  }
  if (matches.includes(SequenceMatched.NOT_FINISHED)) {
    return SequenceMatched.NOT_FINISHED;
  }
  return SequenceMatched.NO;
};


export async function* streamToAsync(stream) {
  const reader = stream.getReader();
  try {
    while (true) {
      const {done, value} = await reader.read();
      if (done) {
        return;
      }
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

export async function streamToBufferedInput(stream: ReadableStream<BufferedInput>) {
  const chunks: BufferedInput[] = [];
  for await (const chunk of streamToAsync(stream)) {
    chunks.push(chunk);
  }
  return chunks;
}
