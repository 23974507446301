import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {apiDocumentCreate} from '@/Model/Action';
import {
  DocumentDto,
  DocumentItemDto,
  DocumentPrintoutDto,
  LotteryTurnoversDto,
} from '@/Model/Entity';
import {
  flatten,
  flow,
  groupBy,
  isInteger,
  map,
  mapKeys,
  mapValues,
  reduce,
  values,
} from 'lodash-es';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import {useSignalR} from '@/Helpers/signalR';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {
  prefixField,
  sumLotteryFields,
  getFieldsFromData,
  unprefixField,
  getOriginalField,
  getOriginalFields,
} from '@/Helpers/lotteryFields';
import {OutputTypes} from '@/constants/outputTypes';
import OutputType from '@/Model/Entity/OutputType';
import {DocumentTypes} from '@/constants/documentTypes';
import FiscalCommands from '@/Model/Entity/FiscalCommands';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import DocumentSave from '@/Helpers/document/save';
import {printerPdfBase64ToDataPdfContent, printerHtmlBase64ToDataHtmlContent} from '../../../../Helpers/base64';

const DOCUMENT_REF_FIELD = 'lotteryDocumentReference';

export class WorkflowStepEnterLotteryOverview extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterLotteryOverview;
  }

  get type() {
    return WorkflowStepEnterLotteryOverview.type;
  }

  get component() {
    return markRaw(require('./StepEnterLotteryOverview.vue').default);
  }

  get lotteryArticlesFromInaccurateCategories() {
    const articles = flow(
      (obj) => values(obj),
      (arr) => flatten(arr),
    )(this.getFieldValue(WorkflowStepField.inaccurateArticlesByCategory, []));

    return map(articles, (article) => {
      return new DocumentItemDto(article ?? {});
    });
  }

  get lotteryArticlesFromInaccurateCategoriesByInternalNumber() {
    return mapKeys(this.lotteryArticlesFromInaccurateCategories, 'internalNumber');
  }

  get lotteryTurnovers() {
    return new LotteryTurnoversDto(this.getFieldValue(WorkflowStepField.lotteryTurnovers, {}));
  }

  get articlesByInaccurateCategoryId() {
    return groupBy(this.lotteryArticlesFromInaccurateCategories, 'parentId');
  }

  getFieldKeyByInternalNumber(internalNumber, sanitize = false) {
    const field = [
      WorkflowStepField.lotteryArticles,
      ...(sanitize ? [prefixField(internalNumber)] : [internalNumber]),
      WorkflowStepField.amount,
    ].join('.');

    return field;
  }

  get lotteryAmountEquals() {
    return this.getFieldValue(WorkflowStepField.lotteryAmountEquals, false);
  }

  get canBeReturnedTo() {
    return !this.stepFinished;
  }

  async beforeEnter() {
    this.createSellDocumentOnce();

    if (this.lotteryAmountEquals) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }
  }

  get printTemplateTypeOverride(): OutputTypes {
    return this.step.printTemplateType ?? null;
  }

  get sellDocument() {
    const doc = this.getFieldValue(WorkflowStepField.document, null);
    return doc ? new DocumentDto(doc) : null;
  }

  set sellDocument(value: DocumentDto) {
    this.dataSetter(WorkflowStepField.document, () => value.toJson());
  }

  get lotteryTotalSellAmount() {
    return sumLotteryFields(this.getFieldValue(WorkflowStepField.lotteryTotalSellAmount, '0'));
  }

  createSellDocumentOnce() {
    if (!this.sellDocument) {
      this.sellDocument = DocumentDto.createEmptySellDocument();
    }
  }

  async beforeContinue() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      if (this.stepFinished) return;

      const {notificationsConnection} = useSignalR();

      const inaccurateArticles: DocumentItemDto[] = this.lotteryArticlesFromInaccurateCategories;

      let articlesAreWhole = true;

      const sellDocument: DocumentDto = this.sellDocument.clone();

      sellDocument.documentSubType = new FiscalCommands(DocumentTypes.LotteryCloseDay);

      for (const [index, article] of inaccurateArticles.entries()) {
        const articleClone: DocumentItemDto = article.clone();
        const field = this.getFieldKeyByInternalNumber(articleClone.internalNumber, true);

        const enteredAmount = String(sumLotteryFields(this.getFieldValue(field, '0')));

        const correctionPrice = this.lotteryTurnovers.getArticleCorrection(articleClone, enteredAmount);

        const correctionPriceIsNegative = correctionPrice < 0;

        const articleQuantity = this.lotteryTurnovers.getArticleQuantity(articleClone, enteredAmount);

        const quantity = correctionPriceIsNegative ? (Math.abs(articleQuantity) * -1) : articleQuantity;

        if (articlesAreWhole) {
          articlesAreWhole = isInteger(quantity);
        }

        const isQuantityNegative = quantity < 0;

        sellDocument.addItem(articleClone);

        articleClone.setIsNegativeWithinContext(sellDocument, index, false);
        if (articleClone.isTypeService) {
          articleClone.setPriceNormalWithinContext(sellDocument, index, Math.abs(correctionPrice));
        }
        articleClone.setQuantityWithinContext(sellDocument, index, Math.abs(articleQuantity));
        articleClone.setIsReturnWithinContext(sellDocument, index, isQuantityNegative);
        articleClone.setIsNegativeWithinContext(sellDocument, index, isQuantityNegative);
        articleClone.setCount();

        if (articleClone.valueAfterDiscounts < 0 && this.step.customOriginalDocumentFiscalReference) {
          articleClone.setOriginalDocumentFiscalReferenceWithinContext(
            sellDocument,
            index,
            this.step.customOriginalDocumentFiscalReference,
          );
        }
      }

      // TODO: Maybe delete this because we have validations now
      // so its impossible to put float as article quantity
      // but could still be possible in some weird magical way :shrug:
      if (!articlesAreWhole) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.LOTTERY_INVALID_QUANTITY,
          },
        }));
        throw new Error(WorkflowStepErrors.LOTTERY_INVALID_QUANTITY);
      }

      if (this.printTemplateTypeOverride) {
        sellDocument.printTemplateType = new OutputType(this.printTemplateTypeOverride);
      }

      if (inaccurateArticles.length) {
        const payment = this.configurationStore.createPayment(this.step?.paymentId);
        payment.setValue(sellDocument.header.total);
        sellDocument.addPayment(payment);
      }

      sellDocument.expandCustomData({
        workflowData: {
          enteredLotteryValues: this.getFieldValue('enteredLotteryValues', []),
          lotteryTotalSellAmount: this.lotteryTotalSellAmount,
          lotteryTurnovers: this.lotteryTurnovers.toJson(),
        },
      });

      sellDocument.preflightSetup();

      if (!inaccurateArticles.length) {
        /**
         * NOTE: printer service ignore this, and it is handled lower
         */
        sellDocument.disablePrintout = true;
      }

      const result = await DocumentSave.run(() => {
        this.dataSetter(WorkflowStepField.lotteryDocumentTotalAmount, () => {
          return sellDocument.header.total;
        });

        return sellDocument.toApiClone();
      }, {
        reference: {
          get: () => {
            return this.getFieldValue(DOCUMENT_REF_FIELD, null);
          },
          set: async (documentId: string) => {
            this.dataSetter(DOCUMENT_REF_FIELD, () => documentId);
            await this.workflowStore.persist();
          },
        },
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      /**
       * NOTE: `&& inaccurateArticles.length` is follow up from upper note
       */
      if (isActiveFeaturePrintDisplayOnScreen() && result.printed && inaccurateArticles.length) {
        const printout: DocumentPrintoutDto['_data'] = result.result.printout;
        const printContent = printerPdfBase64ToDataPdfContent(printout.pdfBase64) ??
        printerHtmlBase64ToDataHtmlContent(printout.htmlBase64);
        await this.printContentStore.open(printContent);
      }

      emitTestEvent(TestEvent.SELL_DOCUMENT_SAVED, sellDocument.header.uniqueidentifier);

      this.stepFinished = true;
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
          value: e,
        },
      }));

      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}

