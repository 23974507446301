import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import WarehouseType from '../WarehouseType';
import ArticleNumberRangeDto from '../ArticleNumberRangeDto';
import {BooleanType} from '@designeo/apibundle-js';


export const StockFilterDtoPropDef = {
  internalNumbers: {
    type: new ArrayType(new StringType()),
  },
  warehouseTypes: {
    type: new ArrayType(new EntityType(WarehouseType)),
  },
  articleNumberRange: {
    type: new EntityType(ArticleNumberRangeDto),
  },
  onlyNegative: {
    type: new BooleanType(),
  },
  onlySales: {
    type: new BooleanType(),
  },
};
export default class GeneratedStockFilterDto extends createEntity(StockFilterDtoPropDef) {}
