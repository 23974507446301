import {createEntity, ObjectType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import QuickCallArticle from '../QuickCallArticle';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import TranslatedString from '../TranslatedString';
import {BooleanType} from '@designeo/apibundle-js';
import State from '../State';
import ArticlesStyle from '../ArticlesStyle';
import ArticlesType from '../ArticlesType';


export default class GeneratedQuickCallSubmenu extends createEntity({
  children: {
    // type: new ArrayType(new EntityType(QuickCallArticle)),
    type: new ArrayType(new ObjectType()),
  },
  code: {
    type: new StringType(),
  },
  image: {
    type: new StringType(),
  },
  itemsPerPage: {
    type: new NumberType(),
  },
  label: {
    type: new EntityType(TranslatedString),
  },
  showItemImages: {
    type: new BooleanType(),
  },
  showItemPrices: {
    type: new BooleanType(),
  },
  state: {
    type: new EntityType(State),
  },
  style: {
    type: new EntityType(ArticlesStyle),
  },
  type: {
    type: new EntityType(ArticlesType),
  },
}) {}
