
import {defineComponent, PropType} from 'vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import {useTransitions} from '@/Helpers/transitions';
import {ConfirmTypes} from '@/Modules/Core/types';

export default defineComponent({
  name: 'ModalConfirmContent',
  components: {ModalDetachedButtons, ModalDetachedClose, ModalDetachedBody},
  props: {
    hasRejectBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String as PropType<ConfirmTypes>,
      required: false,
      default: ConfirmTypes.default,
    },
  },
  emits: ['close', 'confirm', 'reject'],
  setup() {
    const {transitionLeaving} = useTransitions();

    return {
      transitionLeaving,
    };
  },
});
