import {computed} from 'vue';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';

export const useContextMixin = () => {
  const authStore = useAuthStore();

  const lastLoggedUser = computed(() => {
    const user = authStore.peopleByUsername.value?.[authStore.lastLoggedPerson.value];

    if (!user) {
      return null;
    }

    return `${user.tokenInfo?.first_name} ${user.tokenInfo?.last_name}`;
  });

  return {
    lastLoggedUser,
  };
};
