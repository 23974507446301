import TicketCanSellDtoCustom from './custom/TicketCanSellDtoCustom';

export default class TicketCanSellDto extends TicketCanSellDtoCustom {
  get canBeSold() {
    return this.canSell;
  }

  get errorReason() {
    return this.errorMessage?.errorCashierMessage;
  }

  get errorReasonForCustomer() {
    return this.errorMessage?.errorCustomerMessage;
  }
}
