export enum AclParameters {
  switchTheme = 'switchTheme',
  switchLanguage = 'switchLanguage',
  switchOffline = 'switchOffline',
  switchTranslationKeys = 'switchTranslationKeys',
  switchTrainingMode = 'switchTrainingMode',
  printerInit = 'printerInit',
  posReRegistration = 'posReRegistration',
  iconsDetail = 'iconsDetail',
  numberSeries = 'numberSeries'
}
