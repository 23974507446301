export enum CheckAndChargeProviders {
  SAZKA = 'SAZKA',
  MOCK_SAZKA = 'MOCK_SAZKA',
  TIPSPORT = 'TIPSPORT',
  MOCK_TIPSPORT = 'MOCK_TIPSPORT',
  EUROQUEEN = 'EUROQUEEN',
  MOCK_EUROQUEEN = 'MOCK_EUROQUEEN',
  NIKE = 'NIKE',
  MOCK_NIKE = 'MOCK_NIKE',
  GIP = 'GIP',
  MOCK_GIP = 'MOCK_GIP',
  EASYCOIN = 'EASYCOIN',
  MOCK_EASYCOIN = 'MOCK_EASYCOIN',
}
