import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BetOnDrawStatus from '../BetOnDrawStatus';
import {DateTimeType} from '@designeo/apibundle-js';
import DrawPartnerResource from '../DrawPartnerResource';


export const BetOnDrawPropDef = {
  id: {
    type: new NumberType(),
  },
  status: {
    type: new EntityType(BetOnDrawStatus),
  },
  dateCanceled: {
    type: new DateTimeType(),
  },
  datePaidOut: {
    type: new DateTimeType(),
  },
  amountWon: {
    type: new NumberType(),
  },
  draw: {
    type: new EntityType(DrawPartnerResource),
  },
};
export default class GeneratedBetOnDraw extends createEntity(BetOnDrawPropDef) {}
