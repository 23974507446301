/* eslint-disable array-bracket-newline */
import {useAnalogDisplayStore} from '@/Modules/AnalogDisplay/store/AnalogDisplayStore';
import {
  computed,
  onBeforeUnmount,
  watch,
} from 'vue';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {AnalogDisplayLayout} from '@/Modules/AnalogDisplay/types';
import {useI18n} from 'vue-i18n';
import {interpolate, useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  debounce,
  flow,
  last,
  map,

} from 'lodash-es';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {
  BroadcastIO,
  createBroadcastChannel,
} from '@/Helpers/broadcastIO';

export enum AnalogDisplayContentType {
  afterPurchase = 'afterPurchase',
  logoff = 'logoff',
  purchase = 'purchase',
  outOfPurchase = 'outOfPurchase',
}

export function useAnalogDisplayTools() {
  const analogDisplayStore = useAnalogDisplayStore();
  const ellipsis = '...';

  const columns = computed(() => {
    return analogDisplayStore.displayDimensions.value.columns;
  });

  const padToFitRow = (str: string, spacing = true): string => {
    const spacingLength = spacing ? 2 : 0;

    if (str.length > columns.value) {
      return str.slice(0, columns.value - ellipsis.length) + ellipsis;
    }

    if (str.length === columns.value) {
      return str;
    }

    if ((str.length + spacingLength) > columns.value) {
      return str;
    }

    const strWithSpacing = ` ${str} `.slice(0, columns.value);

    if ((str.length + spacingLength) === columns.value) {
      return strWithSpacing;
    }

    const lengthDiff = columns.value - strWithSpacing.length;

    const leftPadding = Math.ceil(lengthDiff / 2);
    const rightPadding = Math.floor(lengthDiff / 2);

    return `${'*'.repeat(leftPadding)}${strWithSpacing}${'*'.repeat(rightPadding)}`;
  };

  const padOverflow = (str: string) => {
    if (str.length > columns.value) {
      return str.slice(0, columns.value - ellipsis.length) + ellipsis;
    }

    return str;
  };

  const padJustifyBetween = ([strLeft, strRight]: [string, string]) => {
    if (strLeft.length + 1 + strRight.length > columns.value) {
      return strRight.padStart(columns.value, ' ');
    }

    const delimiterSpace = columns.value - (strLeft.length + strRight.length);

    return [
      strLeft.padEnd(Math.floor(delimiterSpace / 2) - 1 + strLeft.length, ' '),
      ' ',
      strRight.padStart(Math.ceil(delimiterSpace / 2) + strRight.length, ' '),
    ].join('');
  };

  const uppercaseLayout = (layout: AnalogDisplayLayout): AnalogDisplayLayout => {
    return map(layout, (row) => {
      return map(row, (cell) => {
        return cell.toUpperCase();
      });
    });
  };

  const toRow = (str: string): string[] => str.padEnd(columns.value, ' ').split('');


  return {
    uppercaseLayout,
    padToFitRow,
    padOverflow,
    padJustifyBetween,
    toRow,
  };
}

export function useAnalogDisplay() {
  const {isSecondaryDisplay} = useSecondaryDisplay();

  if (isSecondaryDisplay) {
    return;
  }

  const i18n = useI18n();
  const authStore = useAuthStore();
  const analogDisplayStore = useAnalogDisplayStore();
  const registerStore = useRegisterStore();
  const configurationStore = useConfigurationStore();
  const {
    currencyFormat: baseCurrencyFormat,
    numberFormat: baseNumberFormat,
  } = useFilters();

  const configuration = computed(() => {
    return configurationStore.configuration.value?.analogDisplay ?? null;
  });

  const enabled = computed(() => {
    return configuration.value?.enabled ?? false;
  });

  if (!enabled.value) {
    return;
  }

  const currencyFormat = (...args: Parameters<typeof baseCurrencyFormat>) => {
    return baseCurrencyFormat(...args).replace(/\s/g, '');
  };

  const numberFormat = (...args: Parameters<typeof baseNumberFormat>) => {
    return baseNumberFormat(...args).replace(/\s/g, '');
  };

  const {
    uppercaseLayout,
    padToFitRow,
    padOverflow,
    padJustifyBetween,
    toRow,
  } = useAnalogDisplayTools();

  const getLogoffContent = (): AnalogDisplayLayout => {
    return [
      flow([
        (config) => {
          if (config?.messages?.logoffContent?.noOperatorSignedIn) {
            return interpolate(config.messages.logoffContent.noOperatorSignedIn, {});
          }

          return i18n.t('analogDisplay.logoffContent.noOperatorSignedIn');
        },
        (str) => padToFitRow(str),
        (str) => toRow(str),
      ])(configuration.value),
    ];
  };
  const getOutOfPurchaseContent = (): AnalogDisplayLayout => {
    return [
      toRow(padToFitRow(i18n.t('analogDisplay.outOfPurchaseContent.greetings'))),
      flow([
        (config) => {
          if (config?.messages?.outOfPurchaseContent?.welcome) {
            return interpolate(config.messages.outOfPurchaseContent.welcome, {});
          }

          return i18n.t('analogDisplay.outOfPurchaseContent.welcome');
        },
        (str) => padToFitRow(str),
        (str) => toRow(str),
      ])(configuration.value),
    ];
  };
  const getPurchaseContent = async (): Promise<AnalogDisplayLayout> => {
    if (registerStore.isActivePaymentInputRestrictionState.value) {
      if (registerStore.payment.value && registerStore.payment.value.isInLocalCurrency) {
        return uppercaseLayout([
          toRow(padJustifyBetween([
            registerStore.sellDocument.value.validPayments.length ?
              i18n.t('analogDisplay.purchaseContent.toPay') :
              i18n.t('analogDisplay.purchaseContent.total'),
            numberFormat(
              await registerStore.sellDocument.value.balanceByActiveDocumentPayment(registerStore.payment.value),
            ),
          ])),
          ...(registerStore.payment.value ? [
            toRow(padJustifyBetween([
              registerStore.payment.value.type.description,
              numberFormat(registerStore.payment.value.value),
            ])),
          ] : []),
        ]);
      }

      if (registerStore.payment.value) {
        const exchangeRate = registerStore.payment.value.exchangeRateToLocalCurrency;

        return uppercaseLayout([
          toRow(padOverflow([
            currencyFormat(registerStore.payment.value.valueinFCurrency, registerStore.payment.value.currency, {
              currencyDisplay: 'code',
            }),
            numberFormat(exchangeRate.quoteAmount),
          ].join('x'))),
          toRow(padJustifyBetween([
            i18n.t('analogDisplay.purchaseContent.foreignCurrency'),
            numberFormat(registerStore.payment.value.value),
          ])),
        ]);
      }

      if (configuration.value?.messages?.purchaseContent?.showDiscounts ?? true) {
        return uppercaseLayout([
          toRow(padJustifyBetween([
            i18n.t('analogDisplay.purchaseContent.discounts'),
            numberFormat(registerStore.sellDocument.value.totalDiscountValue),
          ])),
          toRow(padJustifyBetween([
            i18n.t('analogDisplay.purchaseContent.toPay'),
            numberFormat(registerStore.sellDocument.value.balance),
          ])),
        ]);
      }

      return uppercaseLayout([
        toRow(padJustifyBetween([
          i18n.t('analogDisplay.purchaseContent.toPay'),
          numberFormat(registerStore.sellDocument.value.balance),
        ])),
      ]);
    }

    if (registerStore.isActiveCustomerInputRestrictionState.value) {
      return uppercaseLayout([]);
    }

    if (!registerStore.productFlow.value.isInProgress && registerStore.sellDocument.value.itemsGroupedBySets.length) {
      const product = last(registerStore.sellDocument.value.itemsGroupedBySets).editableItem;
      const quantityFormatted = numberFormat(product.quantity, {
        minimumFractionDigits: 0,
        maximumFractionDigits: product.itemRounding,
      });


      return uppercaseLayout([
        toRow(padOverflow(product.description)),
        toRow(padJustifyBetween([
          product.quantity > 1 ?
            `${quantityFormatted}x ${numberFormat(product.priceAfterItemDiscounts)}` :
            '',
          product.isTypeService ?
            '' :
            `${numberFormat(product.valueAfterItemDiscounts)}`,
        ])),
      ]);
    }

    if (!registerStore.productFlow.value.isInProgress) {
      return uppercaseLayout([]);
    }

    const product = registerStore.productFlow.value.product;

    if (!product) {
      return uppercaseLayout([]);
    }

    const quantityFormatted = numberFormat(product.quantity, {
      minimumFractionDigits: 0,
      maximumFractionDigits: product.itemRounding,
    });

    return [
      toRow(padOverflow(product.description)),
      toRow(padJustifyBetween([
        product.quantity > 1 ?
          `${quantityFormatted}x ${numberFormat(product.priceAction)}` :
          '',
        product.isTypeService ?
          '' :
          `${numberFormat(product.valueWithoutDiscounts)}`,
      ])),
    ];
  };
  const getAfterPurchaseContent = (): AnalogDisplayLayout => {
    return uppercaseLayout([
      toRow(padJustifyBetween([
        i18n.t('analogDisplay.afterPurchaseContent.paid'),
        numberFormat(registerStore.lastSellDocument.value.paidTotal),
      ])),
      toRow(padJustifyBetween([
        i18n.t('analogDisplay.afterPurchaseContent.toReturn'),
        numberFormat(registerStore.lastSellDocument.value.valueToReturn),
      ])),
    ]);
  };


  const layout = computed(() => {
    if (registerStore.lastSellDocument.value && !registerStore.sellDocument.value?.isTouched) {
      return {
        content: getAfterPurchaseContent(),
        type: AnalogDisplayContentType.afterPurchase,
      };
    }

    if (!authStore.isLoggedIn.value) {
      return {
        content: getLogoffContent(),
        type: AnalogDisplayContentType.logoff,
      };
    }

    if (registerStore.sellDocument.value?.isTouched) {
      return {
        content: getPurchaseContent(),
        type: AnalogDisplayContentType.purchase,
      };
    }

    return {
      content: getOutOfPurchaseContent(),
      type: AnalogDisplayContentType.outOfPurchase,
    };
  });

  let displayTimeout = null;

  const ensureContent = debounce(
    async ({content, type}: typeof layout.value) => {
      clearTimeout(displayTimeout);

      if (!content) {
        return;
      }

      await analogDisplayStore.ensureContent({layout: await content});

      if (type !== AnalogDisplayContentType.afterPurchase) {
        return;
      }

      displayTimeout = setTimeout(async () => {
        if (!authStore.isLoggedIn.value) {
          await analogDisplayStore.ensureContent({layout: getLogoffContent()});
          return;
        }

        await analogDisplayStore.ensureContent({layout: getOutOfPurchaseContent()});
      }, 10000);
    },
    100,
  );

  watch(layout, (layout) => {
    ensureContent(layout);
  }, {immediate: true});

  onBeforeUnmount(() => {
    clearTimeout(displayTimeout);
  });
}

export const analogDisplayIO = new BroadcastIO(createBroadcastChannel('analogDisplayIO'));
