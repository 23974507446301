import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "uppercase" }
const _hoisted_2 = {
  key: 1,
  class: "w-full",
  style: {"min-height":"540px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrintContent = _resolveComponent("PrintContent")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.printContentStore.isActive.value,
    invalidateRoute: false,
    type: _ctx.modalType,
    height: "unlimited",
    zIndex: _ctx.ZLayer.aboveSpinner,
    onClose: _ctx.close
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('printContent.title')), 1)
    ]),
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        (_ctx.content)
          ? (_openBlock(), _createBlock(_component_PrintContent, {
              key: 0,
              content: _ctx.content,
              class: "w-full",
              style: {"min-height":"540px"}
            }, null, 8, ["content"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2))
      ], 512), [
        [_directive_test, 'print-content']
      ])
    ]),
    buttons: _withCtx(() => [
      _withDirectives(_createElementVNode("button", {
        class: "btn-outline-basic",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t('action.close')), 1)
      ], 512), [
        [_directive_test, 'closePrintModal']
      ])
    ]),
    _: 1
  }, 8, ["open", "type", "zIndex", "onClose"]))
}