import {DateTime} from 'luxon';
import {
  isEmpty,
  toUpper,
  toLower,
  template,
} from 'lodash-es';
import {usePhoneNumberMaskResolver} from '@/Helpers/phoneNumberMaskResolver';
import {useI18nLocale} from '@/Helpers/locale';

export const quantityFormat = (val, options: Partial<Intl.NumberFormatOptions> = {}) => Intl.NumberFormat('en', {
  useGrouping: false,
  ...options,
}).format(val);

export const interpolate = (string, data) => {
  try {
    return template(string, {interpolate: /{([\s\S]+?)}/g})(data);
  } catch (e) {
    console.error('Interpolation template is missing some variables:');
    console.error(e);
    return string;
  }
};

export const useFilters = () => {
  const {i18nLocale} = useI18nLocale();

  const numberFormat = (
    val,
    options: Partial<Intl.NumberFormatOptions> = {},
    locale: any = i18nLocale.value,
  ) => Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(val);

  const numberAbsolute = (val) => {
    if (typeof val === 'number') {
      return Math.abs(val);
    }

    if (typeof val === 'string') {
      const number = parseFloat(val);

      if (Number.isNaN(number)) {
        return val;
      }

      return Math.abs(number);
    }

    return val;
  };

  const numberWithPolarityFormat = (val) => {
    return val > 0 ? `+${val}` : `${val}`;
  };

  const pointsFormat = (val: number) => {
    const points = Math.floor(val ?? 0);
    return numberWithPolarityFormat(points);
  };

  const currencyFormat = (val, currency = ',-', options: Intl.NumberFormatOptions = {}) => {
    if (currency === ',-') {
      const number = Intl.NumberFormat(i18nLocale.value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      }).format(val);

      return isEmpty(number) ? '' : `${number}${currency}`;
    }

    return Intl.NumberFormat(i18nLocale.value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency,
      ...options,
    }).format(val);
  };

  const dateFormat = (date, {format = 'dd. MM. y', locale = i18nLocale.value} = {}) => {
    return DateTime
      .fromJSDate(date instanceof Date ? date : new Date(date))
      .setLocale(locale)
      .toFormat(format);
  };

  const dateTimeFormat = (date, {format = 'dd. MM. y HH:mm', locale = i18nLocale.value} = {}) => {
    return dateFormat(date instanceof Date ? date : new Date(date), {format, locale});
  };

  const timeFormat = (date, {format = 'HH:mm', locale = i18nLocale.value} = {}) => {
    return dateFormat(date instanceof Date ? date : new Date(date), {format, locale});
  };

  const upperCase = (val) => toUpper(val ?? '');
  const lowerCase = (val) => toLower(val ?? '');

  const translatedString = (data) => {
    return data[i18nLocale.value];
  };

  let phoneNumberMaskResolver = null;
  const phoneFormat = (phone) => {
    const {maskedPhoneNumber} = (phoneNumberMaskResolver = phoneNumberMaskResolver ?? usePhoneNumberMaskResolver())
      .resolvePhoneNumberMask(phone);
    return maskedPhoneNumber;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return {
    upperCase,
    lowerCase,
    numberFormat,
    numberWithPolarityFormat,
    numberAbsolute,
    pointsFormat,
    currencyFormat,
    dateFormat,
    dateTimeFormat,
    timeFormat,
    translatedString,
    phoneFormat,
    interpolate,
    formatBytes,
  };
};
