
import {
  defineComponent,
  onBeforeUnmount,
  PropType,
  ref,
  Ref,
  watch,
  computed,
} from 'vue';
import TransitionWrapper from './TransitionWrapper.vue';
import {useRouter} from 'vue-router';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {emitTestEvent} from '@/Helpers/testEvent';
import PhIcon from '@/Components/PhIcon.vue';
import {Modal} from '@/Modules/Core/types';
import {ZLayer} from '@/constants/zLayer';
import {ModalType} from '@/constants/modal';

export default defineComponent({
  name: 'Modal',
  components: {
    PhIcon,
    TransitionWrapper,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String as PropType<ModalType>,
      required: false,
      default: 'md',
    },
    bgVariant: {
      type: String,
      default: 'bg-white',
    },
    invalidateRoute: {
      type: Boolean,
      required: false,
      default: true,
    },
    height: {
      type: String as PropType<'limited' | 'unlimited'>,
      required: false,
      default: 'limited',
    },
    contentClass: {
      type: String,
      default: null,
    },
    innerContentClass: {
      type: String,
      default: null,
    },
    bodyClass: {
      type: null,
      default: null,
    },
    buttonClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    zIndex: {
      type: Number,
      default: ZLayer.modal,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    /**
     * this props setup behaviour that sets modal state => is commonly used in keyboard buffer to silence it
     */
    silenceKeyboardBuffer: {
      type: Boolean,
      required: false,
      default: true,
    },
    silenceCloseListeners: {
      type: Boolean,
      required: false,
      default: false,
    },
    offsetModalContentX: {
      type: String,
      required: false,
      default: null,
    },
    offsetModalContentY: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['close'],
  setup(props, {emit}) {
    const router = useRouter();
    const modalContainerRef = ref(null);
    const coreStore = useCoreStore();
    const enterTime = ref(300);
    const leaveTime = ref(200);
    const isOpened = ref(props.open);

    const modal: Ref<Modal> = ref(null);
    const body = ref(null);

    const onClose = (state) => {
      if (modal.value) {
        emit('close', state);
      }
    };

    const onOpen = () => {
      modal.value = coreStore.requestModal(onClose, {
        zIndexBase: props.zIndex,
        silenceKeyboardBuffer: props.silenceKeyboardBuffer,
        spawnedAtRoute: router?.currentRoute.value?.name,
        silenceCloseListeners: props.silenceCloseListeners,
      });
      // @ts-ignore
      document.activeElement.blur();
    };

    watch(() => props.open, (newVal, oldVal) => {
      if (props.open && isOpened.value) {
        emitTestEvent(TestEvent.MODAL_CLOSED);
      } else if (!props.open && !isOpened.value) {
        emitTestEvent(TestEvent.MODAL_OPENED);
      }

      if (newVal && !modal.value) {
        onOpen();
      } else if (!newVal && modal.value) {
        coreStore.removeModal(modal.value.id);
        modal.value = null;
      }
    }, {immediate: true});

    watch(() => props.silenceKeyboardBuffer, (newVal) => {
      if (modal.value) {
        coreStore.updateModal(modal.value.id, {
          silenceKeyboardBuffer: newVal,
        });
      }
    });

    watch(
      () => router?.currentRoute.value?.name,
      (routeName) => {
        try {
          if (!modal.value || !props.invalidateRoute || !modalContainerRef.value) {
            return;
          }

          if (modal.value.spawnedAtRoute !== routeName) {
            modalContainerRef.value.classList.add('hidden');
          } else {
            modalContainerRef.value.classList.remove('hidden');
          }
        } catch (err) {
          console.error(err);
        }
      },
    );

    function modalOpened() {
      isOpened.value = props.open;
      emitTestEvent(TestEvent.MODAL_OPENED);
    }

    function modalClosed() {
      isOpened.value = props.open;
      emitTestEvent(TestEvent.MODAL_CLOSED);
    }

    onBeforeUnmount(() => {
      try {
        if (modal.value) {
          coreStore.removeModal(modal.value.id);
        }
      } catch (err) {
        console.error(err);
      }
    });

    const totalZIndex = computed(() => {
      return modal.value?.zIndexBase + modal.value?.zIndexOffset ?? 0;
    });

    return {
      modalContainerRef,
      enterTime,
      leaveTime,
      modalOpened,
      modalClosed,
      onClose,
      totalZIndex,
      body,
    };
  },
});
