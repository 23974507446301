import {KEYBOARD_KEY_ESCAPE} from '@/constants/keyboardKeys';
import {onMounted, onUnmounted} from 'vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export const useKeyboard = () => {
  const coreStore = useCoreStore();

  const registerEscapeKey = (fn, {
    muted = () => coreStore.keyboardBufferIsMuted.value,
    keyUp = false,
  } = {}) => {
    const method = (ev: KeyboardEvent) => {
      if (muted()) return;

      if (ev.key === KEYBOARD_KEY_ESCAPE) {
        if (keyUp) {
          window.addEventListener('keyup', (ev) => {
            if (ev.key === KEYBOARD_KEY_ESCAPE) {
              fn(ev);
            }
          }, {once: true});
        } else {
          fn(ev);
        }
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', method);
    });
    onUnmounted(() => {
      window.removeEventListener('keydown', method);
    });
  };

  return {
    registerEscapeKey,
  };
};
