/* eslint-disable max-len */
import GeneratedArticleResultDto from './generated/ArticleResultDto';
import {
  first,
  groupBy,
  isEmpty,
  isNil,
  map,
  last,
  flow,
  filter,
} from 'lodash-es';
import {
  DocumentItemTypes,
} from '@/constants/documentItemTypes';
import {SetPriceSources} from '@/constants/setPriceSources';
import {
  DOCUMENT_ITEM_SET_TYPE_NORMAL,
  DOCUMENT_ITEM_SET_TYPE_SET_ARTICLE,
  DOCUMENT_ITEM_SET_TYPE_SET_COMPONENT,
} from '@/constants/documentItemSetTypes';
import {DocumentItemUnits} from '@/constants/documentItemUnits';
import {SetDisplayTypes} from '@/constants/setDisplayTypes';
import {DateTimeType} from '@designeo/apibundle-js/src/Types/primitive';
import {DateTime} from 'luxon';
import {PriceSourceConditions} from '@/constants/priceSources';

/**
 * We can have different price conditions for the same article, but this enum contains only default conditions with 0
 */
enum SapPriceConditionCodes {
  priceNormal = 'VKP0',
  priceAction = 'VKA0',
  priceRecyclingFee = 'ZRPO'
}

enum SapItemTypes {
  article = 'HAWA',
  service = 'DIEN'
}

type PriceListItem = {
  priceConditionCode: string,
  validFrom: string,
  validTill?: string,
  value: string,
  vatCode: string
}

const sapValueToNumber = (str: string) => isNil(str) ? null : parseFloat(str);
const sapValueToBoolean = (str: string) => isNil(str) ? null : (!(str === '' || str === 'false'));
const sapValueToDateFrom = (str: string) => {
  const date = new DateTimeType().parse(str);

  if (isNil(date)) {
    return null;
  }

  if (str.indexOf('T') !== -1) {
    return date.toISOString();
  }

  return DateTime.fromJSDate(date).startOf('day')
    .toJSDate()
    .toISOString();
};

const sapValueToDateTill = (str: string) => {
  const date = new DateTimeType().parse(str);

  if (isNil(date)) {
    return null;
  }

  if (str.indexOf('T') !== -1) {
    return date.toISOString();
  }

  return DateTime.fromJSDate(date).endOf('day')
    .toJSDate()
    .toISOString();
};

const sapPluCodeToItemType = (itemType: SapItemTypes) => {
  switch (itemType) {
  case SapItemTypes.article:
    return DocumentItemTypes.article;
  case SapItemTypes.service:
    return DocumentItemTypes.service;
  default: return '';
  }
};
class CustomAttributes {
  private readonly data: Array<{
    key: string,
    value: boolean,
    [key: string]: any,
  }>;

  constructor(customAttributes: Array<{key: string, value: string, [key: string]: any}>) {
    this.data = map(customAttributes, (customAttribute) => {
      return {
        ...customAttribute,
        value: sapValueToBoolean(customAttribute.value),
      };
    });
  }

  dataGroupedByKeys() {
    return groupBy(this.data, 'key');
  }

  hasActive(key: string) {
    const customAttributes = this.dataGroupedByKeys();

    if (!customAttributes[key]) {
      return false;
    }

    if (!customAttributes[key].length) {
      return false;
    }

    return last(customAttributes[key])?.value ?? false;
  }
}


export default class ArticleResultDto extends GeneratedArticleResultDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  public static get priceNormalConditionByConfiguration() {
    const {defaultConditionNormalPrice = null} = ArticleResultDto.configurationStore.configuration.value?.general ?? {};

    if (!defaultConditionNormalPrice) {
      return SapPriceConditionCodes.priceNormal;
    }

    return defaultConditionNormalPrice as SapPriceConditionCodes;
  }

  public static get priceActionConditionByConfiguration() {
    const {defaultConditionActionPrice = null} = ArticleResultDto.configurationStore.configuration.value?.general ?? {};

    if (!defaultConditionActionPrice) {
      return SapPriceConditionCodes.priceAction;
    }

    return defaultConditionActionPrice as SapPriceConditionCodes;
  }

  public static get priceRecyclingFeeConditionByConfiguration() {
    /**
     * TODO: config?
     */
    return SapPriceConditionCodes.priceRecyclingFee;
  }


  createDocumentItemData(
    articleData: any, {
      isChild = false,
      setDisplayType = null,
      setPrintType = null,
    } = {}) {
    const DocumentItemDto = (require('@/Model/Entity/DocumentItemDto')).default;

    const priceListItems = articleData.sapData.priceListItems ?? [];

    const priceListItemsByPriceConditionCode = flow([
      (priceListItems: PriceListItem[]) => filter(priceListItems, ({validFrom, validTill}) => {
        const validFromFromSapValue = sapValueToDateFrom(validFrom);
        const validTillFromSapValue = sapValueToDateTill(validTill);
        const now = new Date();

        if (!isNil(validFromFromSapValue) && !isNil(validTillFromSapValue)) {
          const validFromAsDate = new Date(validFromFromSapValue);
          const validTillAsDate = new Date(validTillFromSapValue);

          return validFromAsDate <= now && validTillAsDate >= now;
        }

        if (!isNil(validFromFromSapValue)) {
          const validFromAsDate = new Date(validFromFromSapValue);

          return validFromAsDate <= now;
        }

        if (!isNil(validTillFromSapValue)) {
          const validTillAsDate = new Date(validTillFromSapValue);

          return validTillAsDate >= now;
        }

        return true;
      }),
      (priceListItems: PriceListItem[]) => groupBy(
        priceListItems,
        ({priceConditionCode = ArticleResultDto.priceNormalConditionByConfiguration}) => {
          return priceConditionCode;
        },
      ),
    ])(priceListItems as PriceListItem[]) as {[priceConditionCode: string]: PriceListItem[]};

    const getPriceListItemPriceBySapPriceConditionCode = (code: SapPriceConditionCodes) => {
      const value = first(priceListItemsByPriceConditionCode[code])?.value;

      if (isNil(value)) {
        return null;
      }

      return sapValueToNumber(value);
    };

    const getPriceListItemVatCodeBySapPriceConditionCode = (code: SapPriceConditionCodes) => {
      const vatCode = first(priceListItemsByPriceConditionCode[code])?.vatCode;

      if (isNil(vatCode)) {
        return null;
      }

      return vatCode;
    };

    const data: Partial<typeof DocumentItemDto['_data']> = {};

    const priceListVatCode = getPriceListItemVatCodeBySapPriceConditionCode(ArticleResultDto.priceNormalConditionByConfiguration);

    data.assortmentInfo = articleData.assortmentInfo;

    data.systemAttributes = articleData.sapData?.systemAttributes ?? {};
    data.customAttributes = articleData.sapData?.customAttributes ?? [];

    data.setDisplayType = setDisplayType ?? articleData.sapData?.systemAttributes?.setDisplayType ?? SetDisplayTypes.Full;
    data.setPrintType = setPrintType ?? articleData.sapData?.systemAttributes?.setPrintType;
    data.serialNumber = sapValueToBoolean(articleData.sapData?.systemAttributes?.serialNumber) ?? false;
    data.invoiceNumber = sapValueToBoolean(articleData.sapData?.systemAttributes?.invoiceNumber) ?? false;

    data.priceRecyclingFee = getPriceListItemPriceBySapPriceConditionCode(ArticleResultDto.priceRecyclingFeeConditionByConfiguration);

    // @ts-ignore
    if (articleData.setInfo) {
      if (sapValueToNumber(articleData.setInfo.price) === 0) {
        if (isChild) {
          data.setPriceSource = SetPriceSources.Weight;
          data.priceAction = getPriceListItemPriceBySapPriceConditionCode(ArticleResultDto.priceActionConditionByConfiguration);
          data.priceNormal = getPriceListItemPriceBySapPriceConditionCode(ArticleResultDto.priceNormalConditionByConfiguration);
        } else {
          data.setPriceSource = SetPriceSources.FromParts;
          data.priceAction = null;
          data.priceNormal = null;
        }
      } else {
        data.priceAction = articleData.setInfo.price;
        data.priceNormal = articleData.setInfo.price;
      }
    } else {
      data.priceAction = getPriceListItemPriceBySapPriceConditionCode(ArticleResultDto.priceActionConditionByConfiguration);
      data.priceNormal = articleData.barcodeDecomposition?.priceNormal ?? getPriceListItemPriceBySapPriceConditionCode(ArticleResultDto.priceNormalConditionByConfiguration);
      if (data.priceNormal === 0 || data.priceNormal === null) {
        data.setPriceSource = SetPriceSources.FromParts;
      }
    }

    data.customAttributes = [
      ...data.customAttributes,
      {
        key: 'priceNormalCurrentPLU',
        value: data.priceNormal,
      },
      {
        key: 'priceActionCurrentPLU',
        value: data.priceAction ?? data.priceNormal,
      },
    ];

    for (const [key, value] of Object.entries(articleData.barcodeDecomposition ?? {})) {
      if (key.startsWith('customAttributes.')) {
        data.customAttributes.push({
          key: key.replace('customAttributes.', ''),
          value,
        });
      }
    }

    const sapCustomAttributesToCustomAttributes = new CustomAttributes(data.customAttributes);

    if (sapCustomAttributesToCustomAttributes.hasActive('FloatingPrice')) {
      data.priceSourceConditions = PriceSourceConditions.Variable;
    }

    //
    data.isNegative = (articleData.barcodeDecomposition?.isNegative ?? sapValueToBoolean(articleData.sapData.isNegative) ?? false);
    data.unit = articleData.sapData.unitOfQuantityCode;
    data.quantity = sapValueToNumber(articleData.setInfo?.quantity) ?? null;
    // @ts-ignore not internally described as field but as a getter
    data.setItems = map(articleData.setItems ?? [], (item) => {
      return this.createDocumentItemData(item, {
        isChild: true,
        setDisplayType: data.setDisplayType,
        setPrintType: data.setPrintType,
      });
    });

    //
    data.ageSaleRestriction = articleData.barcodeDecomposition?.ageSaleRestriction ?? articleData.sapData?.systemAttributes?.ageSaleRestriction;
    data.internalNumber = articleData.sapData.internalNumber;
    data.imageId = articleData.sapData.artsid;
    data.batch = articleData.sapData.variant?.batch.id;
    data.batchLabel = articleData.sapData.variant?.batch.text;
    data.cardType = articleData.barcodeDecomposition?.cardType;
    data.description = articleData.sapData.text;
    data.descriptionShort = articleData.sapData.shortText;
    data.disableExportToSap = sapValueToBoolean(articleData.sapData?.systemAttributes?.disableExportToSap) ?? false;
    data.documentStornoDisabled = sapValueToBoolean(articleData.sapData?.systemAttributes?.documentStornoDisabled) ?? false;
    data.dynamicSets = articleData.sapData?.dynamicSets ?? null;
    data.gtin = articleData.sapData.gtin;
    data.hasManualPrice = data.priceNormal === 0 && articleData.sapData.typeOfPLUCode === SapItemTypes.service;
    data.isCanceled = false;
    data.isEditableSet = sapValueToBoolean(articleData.setInfo?.isEditableSet) ?? false;
    data.itemReturnDisabled = sapValueToBoolean(articleData.sapData?.systemAttributes?.itemReturnDisabled) ?? false;
    data.itemRounding = articleData.sapData.unitOfQuantityCode === DocumentItemUnits.KG ? 3 : 0;
    data.itemStornoDisabled = sapValueToBoolean(articleData.sapData?.systemAttributes?.itemStornoDisabled) ?? false;
    data.itemType = sapPluCodeToItemType(articleData.sapData.typeOfPLUCode);
    data.logisticCode = articleData.barcodeDecomposition?.logisticCode;
    data.lotteryCode = articleData.sapData?.systemAttributes?.lotteryCode;
    data.maxPrice = sapValueToNumber(articleData.sapData?.systemAttributes?.maxPrice);
    data.maxQuantity = data.serialNumber ? 1 : sapValueToNumber(articleData.sapData?.systemAttributes?.maxQuantity);
    data.minPrice = sapValueToNumber(articleData.sapData?.systemAttributes?.minPrice);
    data.netto = sapValueToBoolean(articleData.sapData.netto) ?? false;
    data.parentId = articleData.sapData?.systemAttributes?.parentId;
    data.position = 0;
    data.prep = sapValueToNumber(articleData.sapData?.systemAttributes?.prep);
    data.prodh = articleData.sapData.prodh;
    data.provider = articleData.sapData?.systemAttributes?.provider;
    data.salePackage = sapValueToBoolean(articleData.sapData?.systemAttributes?.salePackage) ?? false;
    data.saleValidFrom = sapValueToDateFrom(articleData.sapData?.variant?.batch?.saleValidFrom ?? articleData?.sapData?.saleValidFrom);
    data.saleValidTill = sapValueToDateTill(articleData.sapData?.variant?.batch?.saleValidTill ?? articleData?.sapData?.saleValidTill);
    data.sellerVATID = articleData.sapData?.systemAttributes?.sellerVATID;
    data.sellout = sapValueToBoolean(articleData.sapData?.systemAttributes?.sellout) ?? false;
    data.serviceParam = articleData.sapData?.systemAttributes?.serviceParam;
    data.serviceType = articleData.sapData?.systemAttributes?.serviceType;
    data.setPosition = 0;
    // @ts-ignore
    data.setType = isChild ? DOCUMENT_ITEM_SET_TYPE_SET_COMPONENT : data.setItems.length ? DOCUMENT_ITEM_SET_TYPE_SET_ARTICLE : DOCUMENT_ITEM_SET_TYPE_NORMAL;
    data.taxStamp = articleData.sapData?.systemAttributes?.taxStamp;
    data.vatShort = ArticleResultDto.configurationStore.vatByCode.value[priceListVatCode]?.code;
    data.vatValue = ArticleResultDto.configurationStore.vatByCode.value[priceListVatCode]?.amount;
    data.vatid = ArticleResultDto.configurationStore.vatByCode.value[priceListVatCode]?.fiscalIndex;
    data.weight = articleData.barcodeDecomposition?.weight;
    data.systemAttributes.productGroup = articleData.sapData.wgr;
    data.inventoryGroup = articleData?.sapData?.systemAttributes?.inventoryGroup;

    /**
     * testing overrides
     *
     * it should always be in comment in commit!
     */
    // data.itemStornoDisabled = false;
    // data.itemReturnDisabled = true;
    // data.documentStornoDisabled = false;

    data.dataErrors = articleData?.dataErrors ?? [];

    return data;
  }


  get documentItem() {
    const DocumentItemDto = (require('@/Model/Entity/DocumentItemDto')).default;

    if (isEmpty(this._data)) {
      return new DocumentItemDto({});
    }

    if ((this._data?.dataErrors ?? []).length) {
      return null;
    }


    return new DocumentItemDto(this.createDocumentItemData(this._data));
  }
}
