import {createContext} from '@designeo/js-helpers';
import AppLayout from '../Layout/AppPos.vue';
import {registerAuthPos} from '@/Modules/Auth/index.pos';
import {registerCoreModule} from '@/Modules/Core';
import {registerModuleSystem} from '@/ModuleSystem';
import {registerVueI18n} from '@/Plugins/i18n';
import {registerRouter} from '@/Plugins/router';
import {registerSSRState} from '@/Plugins/ssrState';
import {registerRegister} from '@/Modules/Register';
import {registerPosConfiguration} from '@/Modules/PosConfiguration';
import {registerTestHelpers} from '@/Plugins/testHelpers';
import {registerApps} from '@/Modules/Apps';
import {registerAcl} from '@/Plugins/acl';
import {registerCustomerExternal} from '@/Modules/CustomerExternal';
import {registerWorkflow} from '@/Modules/Workflow';
import {registerSentry} from '@/Plugins/sentry';
import {registerInventory} from '@/Modules/Inventory';
import {isElectron} from '@/Helpers/app';
import {registerStartup} from '@/Plugins/startup';
import {registerIncorrectRestart} from '@/Plugins/incorectRestart';
import {registerCrashDetection} from '@/Plugins/crash';
import {registerRestoreMode} from '@/Plugins/restoreMode';
import {registerResetToShop} from '@/Plugins/resetToShop';
import {registerAsyncStorage, registerStorageInterceptor} from '@/Plugins/storage';
import {registerFeatures} from '@/Plugins/features';
import {registerAnalogDisplay} from '@/Modules/AnalogDisplay';
import {AppVersion} from '@/constants/appVersion';
import {unwrapAppRedirectUrl} from '@/Helpers/url';
import {getPosRouterTree} from '@/ModuleSystem/routerTree.pos';


interface createContextOptions {
  createApp: import('vue').CreateAppFunction<any>,
  ssrState: any,
}

export default async ({createApp, ssrState}: createContextOptions) => {
  const isSplashScreen = JSON.parse(document.body.dataset?.splashScreen ?? 'false');

  if (isSplashScreen) {
    return {
      app: null,
      router: null,
      ensureStartupClose: null,
      isSplashScreen: true,
    };
  }

  const landingRoute = unwrapAppRedirectUrl(window.location.href);

  if (landingRoute) {
    window.history.replaceState(null, '', `/#${landingRoute}`);
  }


  const appId = {};

  const app = createApp(AppLayout, {
    appVersion: AppVersion.Pos,
    appId,
  });

  const ctx = createContext({
    app,
    appId,
    isSplashScreen: false,
    isElectron: isElectron(),
    routerTree: getPosRouterTree(),
    replicateRouterTree: () => getPosRouterTree(),
  })
    .register(registerAsyncStorage)
    .register(registerFeatures)
    .register(registerStorageInterceptor)
    .register(registerRestoreMode)
    .register(registerCrashDetection)
    .register(registerStartup)
    .register(registerAcl)
    .register(registerTestHelpers)
    .register(registerModuleSystem())
    .register(registerCoreModule())
    .register(registerAnalogDisplay)
    .register(registerApps)
    .register(registerAuthPos)
    .register(registerCustomerExternal)
    .register(registerPosConfiguration)
    .register(registerRegister)
    .register(registerInventory)
    .register(registerWorkflow)
    .register(registerVueI18n)
    .register(registerRouter)
    .register(registerSSRState(ssrState))
    .register(registerSentry)
    .register(registerResetToShop)
    .register(registerIncorrectRestart)
  ;

  const resolvedContext = await ctx.resolve();
  app.config.globalProperties.$context = resolvedContext;

  return resolvedContext;
};
