import {markRaw} from 'vue';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import * as yup from 'yup';

export class WorkflowStepBTCEnterOrderNumber extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.BTCEnterOrderNumber;
  }

  get type() {
    return WorkflowStepBTCEnterOrderNumber.type;
  }

  get component() {
    return markRaw(require('./StepBTCEnterOrderNumber.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.orderNumber]: yup.string()
        .required(),
    });
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.orderNumber, () => '');
  }

  get disabledNextStep() {
    return !this.getFieldValue(WorkflowStepField.orderNumber, '');
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.orderNumber, (fieldActions) => ({
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.SCANNER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ENTER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
