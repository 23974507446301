import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex relative items-center justify-center mb-0.5" }
const _hoisted_2 = {
  key: 0,
  class: "rounded-full bg-danger absolute -top-2 -right-2.5 h-5 text-white flex justify-center items-center w-5 text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _directive_test = _resolveDirective("test")!

  return (_ctx.isVisible)
    ? _withDirectives((_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        to: {name: _ctx.isEshopOrdersPage ? 'register' : 'apps-eshop-orders'}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PhIcon, {
              icon: "tote-simple",
              size: 32
            }),
            (_ctx.unfulfilledOrders)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createTextVNode(_toDisplayString(_ctx.unfulfilledOrders), 1)
                ], 512)), [
                  [
                    _directive_test,
                    _ctx.unfulfilledOrders,
                    void 0,
                    { unfulfilledOrders: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["to"])), [
        [_directive_test, 'eshopOrdersLink']
      ])
    : _createCommentVNode("", true)
}