import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';

export interface IPrinterStatusStore {
  warningCode: number | string,
}

const createInitState = (data?: Partial<IPrinterStatusStore>) => ({
  warningCode: null,
});

export class PrinterStatusStore extends Store<IPrinterStatusStore> {
  constructor() {
    super(createInitState());
  }

  setWarningCode = action((val) => {
    this.state.warningCode = val;
  })

  warningCode = getter(() => {
    return this.state.warningCode;
  })

  reset = action(() => {
    Object.assign(this.state, createInitState());
  })
}

const storeIdentifier = 'PrinterStatusStore';

export const configurePrinterStatusStore = createConfigureStore<typeof PrinterStatusStore>(
  storeIdentifier,
);
export const usePrinterStatusStore = createUseStore(PrinterStatusStore, storeIdentifier);
