import {createApp, toRaw} from 'vue';
import IncorrectRestartPage from '@/Modules/IncorrectRestart/IncorrectRestart/IncorrectRestartPage.vue';
import {registerVueI18n} from '@/Plugins/i18n';
import {registerModuleSystem} from '@/ModuleSystem';
import {createContext} from '@designeo/js-helpers/src/index';
import {isElectron} from '@/Helpers/app';
import {registerTestHelpers} from '@/Plugins/testHelpers';
import {registerAuthPos} from '@/Modules/Auth/index.pos';
import {registerRouter} from '@/Plugins/router';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {InactivityCondition} from '@/Helpers/inactivity';
import {AppVersion} from '@/constants/appVersion';
import {RouterTree} from '@/ModuleSystem/routerTree';
import {registerFeatures} from '@/Plugins/features';

export async function createIncorrectRestart(routerTree: RouterTree) {
  const app = createApp(IncorrectRestartPage, {
    appVersion: AppVersion.IncorrectRestart,
    isElectron: isElectron(),
  });

  const ctx = (
    createContext({
      app,
      appId: {},
      isRestoreMode: false,
      restoredErrorRecord: null,
      routerTree,
    })
      .register(registerFeatures)
      .register(registerTestHelpers)
      .register(registerModuleSystem())
      .register(registerAuthPos)
      .register(async (ctx) => {
        const configurationStore = useConfigurationStore();
        ctx.locale = configurationStore.configuration.value.general.language.value?.toLowerCase();
        await ctx.registerTranslations({});
        await ctx.registerRoutes([
          {
            path: '/register',
            name: 'register',
            redirect: () => ({name: 'login'}),
          },
        ]);
        await ctx.registerHookAfterEach(InactivityCondition.inactivityRouterHook);
      })
      .register(registerVueI18n)
      .register(registerRouter)
  );

  const resolvedCtx = await ctx.resolve();

  resolvedCtx.app.use(resolvedCtx.router);

  await resolvedCtx.router.isReady();

  return resolvedCtx.app;
}
