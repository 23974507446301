
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
});
