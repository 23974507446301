import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {createDebouncedKeypressLogger, createFinalReaderTransform} from './KeyboardStreams';
import {TransformStream, WritableStream} from './streams';
import {map} from 'lodash-es';
import {KEYBOARD_KEY_ESCAPE} from '@/constants/keyboardKeys';
import {indexOfKeyboardEvent} from '@/Components/KeyboardShortcuts';

const bind = (fn) => fn;


export interface KeyPress {
  source?: InputSource,
  dir?: 'up' | 'down' | 'press',
  alt: boolean,
  ctrl: boolean,
  shift: boolean,
  key: string,
  code: string,
}

export interface KeyPressWithTimestamp extends KeyPress {
  timeStamp: number,
}

export enum InputSource {
  KEYBOARD = 'keyboard',
  PASTE = 'paste',
  SCANNER = 'scanner',
  QUICK_CALL = 'quickCall',
}

/**
 * Make it module agnostic
 */
export interface BufferedInput {
  source: InputSource
  keys: KeyPress[]
}

type KeyboardBufferCallback = (input: BufferedInput[])=> void

export type ScannerStxEtxOptions = 'default'

export class KeyboardBuffer {
  private _attachedElement: HTMLElement | Window
  private keyPressSource: TransformStream<KeyPressWithTimestamp, KeyPress>
  private fn: KeyboardBufferCallback
  private muteResolver: ()=> boolean

  constructor(fn: KeyboardBufferCallback, {
    muteResolver = () => require('@/Modules/Core/store/CoreStore').useCoreStore().keyboardBufferIsMuted.value,
  } = {}) {
    this._attachedElement = null;
    this.keyPressSource = new TransformStream();
    this.fn = fn;
    this.muteResolver = muteResolver;
    this.keyPressSource.readable
      .pipeThrough(createDebouncedKeypressLogger(100))
      .pipeThrough(createFinalReaderTransform({scannerLanguage: useConfigurationStore().scannerLanguage.value}))
      .pipeTo(new WritableStream({
        write(inputBuffer) {
          fn([inputBuffer]);
        },
      }));
  }

  writeKeyPress(key: KeyPress, source: KeyPress['source'], dir: KeyPress['dir'], timeStamp: number) {
    const writer = this.keyPressSource.writable.getWriter();
    writer.write({
      ...key,
      source,
      dir,
      timeStamp,
    });
    writer.releaseLock();
  }

  static set scannerMode(val: ScannerStxEtxOptions) {
    // not much to set right now
  }

  get isMuted() {
    return this.muteResolver();
  }

  isInterceptedByShortcut(event: KeyboardEvent) {
    return indexOfKeyboardEvent(event) !== -1;
  }

  isInvalidKeyboardEvent(event: KeyboardEvent) {
    // Prevent escape because of modals

    if (this.isMuted) {
      return true;
    }

    if (this.isInterceptedByShortcut(event)) {
      return true;
    }

    return event.defaultPrevented && event.key === KEYBOARD_KEY_ESCAPE;
  }

  onKeyDown = bind((event: KeyboardEvent) => {
    if (this.isInvalidKeyboardEvent(event)) {
      return;
    }

    this.writeKeyPress({
      code: event.code,
      alt: event.altKey,
      ctrl: event.ctrlKey,
      shift: event.shiftKey,
      key: event.key,
    }, InputSource.KEYBOARD, 'down', event.timeStamp);
  })

  onKeyUp = bind((event: KeyboardEvent) => {
    if (this.isInvalidKeyboardEvent(event)) {
      return;
    }

    this.writeKeyPress({
      code: event.code,
      alt: event.altKey,
      ctrl: event.ctrlKey,
      shift: event.shiftKey,
      key: event.key,
    }, InputSource.KEYBOARD, 'up', event.timeStamp);
  })

  onKeyPress = bind((event: KeyboardEvent) => {
    this.writeKeyPress({
      code: event.code,
      alt: event.altKey,
      ctrl: event.ctrlKey,
      shift: event.shiftKey,
      key: event.key,
    }, InputSource.KEYBOARD, 'press', event.timeStamp);
  });

  onPaste = bind(async (event: ClipboardEvent) => {
    if (this.isMuted) return;

    const emit = (data: string) => {
      this.fn([
        {
          keys: map(data.split(''), (key) => ({
            alt: false,
            ctrl: false,
            shift: false,
            key: key,
            code: key,
          })),
          source: InputSource.PASTE,
        },
      ]);
    };

    const data = event.clipboardData.getData('Text');
    if (data) {
      return emit(data);
    }

    for (const item of map(event.clipboardData.items)) {
      if (item.kind === 'string') {
        const data: string = await new Promise((resolve) => item.getAsString((data) => resolve(data)));
        return emit(data);
      }
    }
  })

  attach(el: HTMLElement | Window = window) {
    if (this._attachedElement) {
      this.detach();
    }
    this._attachedElement = el;
    this._attachedElement.addEventListener('keydown', this.onKeyDown, {passive: true});
    this._attachedElement.addEventListener('keypress', this.onKeyPress, {passive: true});
    this._attachedElement.addEventListener('keyup', this.onKeyUp, {passive: true});
    this._attachedElement.addEventListener('paste', this.onPaste, {passive: true});
  }

  detach() {
    if (this._attachedElement) {
      this._attachedElement.removeEventListener('keydown', this.onKeyDown);
      this._attachedElement.removeEventListener('keypress', this.onKeyPress);
      this._attachedElement.removeEventListener('keyup', this.onKeyUp);
      this._attachedElement.removeEventListener('paste', this.onPaste);
    }
  }
}
