import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {DocumentDto} from '@/Model/Entity';

export class WorkflowStepSafeBagOverview extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagOverview;
  }

  get type() {
    return WorkflowStepSafeBagOverview.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagOverview.vue').default);
  }

  get safeBagEnvelopeCode() {
    return this.getFieldValue(WorkflowStepField.safeBagEnvelopeCode);
  }

  get safeBagWasEdited() {
    return !!this.getFieldValue(WorkflowStepField.safeBagDocumentEdited);
  }

  get safeBag() {
    const value = this.getFieldValue(
      this.safeBagWasEdited ?
        WorkflowStepField.safeBagDocumentEdited :
        WorkflowStepField.safeBagDocument,
      {},
    );

    return new DocumentDto(value);
  }

  get transitions() {
    return {};
  }
}
