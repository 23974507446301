import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block pt-5 font-bold text-basic overflow-hidden overflow-ellipsis w-full" }
const _hoisted_2 = { class: "w-full flex-wrap text-left text-danger pt-2 text-sm" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretNavigator = _resolveComponent("CaretNavigator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'relative flex items-center h-14 px-3.5 w-full',
        'rounded-md border bg-white uppercase',
        {'border-secondary' : _ctx.active},
        {'bg-danger-light': !!_ctx.error},
        _ctx.inputClass
      ])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass([
          'absolute top-0 left-0 w-full h-full',
          'px-3.5 flex items-center',
          'text-sm text-basic-dark font-medium',
          'transform transition-transform origin-top-left',
          {'scale-75 -translate-y-0.5 translate-x-0.5' : _ctx.active || _ctx.value}
        ])
      }, _toDisplayString(_ctx.label), 3),
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.value), 1),
        (_ctx.active)
          ? (_openBlock(), _createBlock(_component_CaretNavigator, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.hasTextErrorOutput && _ctx.hasActiveError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, " "))
    ])
  ]))
}