import {
  flatMap,
  isEmpty,
  isObject,
  map,
  sumBy,
  values,
} from 'lodash-es';

export const prefixField = (field, prefix = 'in') => {
  return `${prefix}:${field}`;
};

export const unprefixField = (field, prefix = 'in') => {
  return field.replace(`${prefix}:`, '');
};

export const sanitizeField = (field: string) => {
  const [stepField, internalNumber, amount, index] = field.split('.');
  return [stepField, prefixField(internalNumber), amount, index].join('.');
};

export const sanitizeFields = (fields: string[]) => map(fields, sanitizeField);

export const getOriginalField = (field: string) => {
  const [stepField, sanitizedInternalNumber, amount, index] = field.split('.');
  return [stepField, unprefixField(sanitizedInternalNumber), amount, index].join('.');
};

export const getOriginalFields = (fields: string[]) => map(fields, getOriginalField);

export const fieldsWithIndex = (field: string, terminalCount: number = 1) => {
  return Array.from({length: terminalCount}, (item, index) => [field, prefixField(index, 'f')].join('.'));
};

export const sumLotteryFields = (fieldData: object) => {
  return sumBy(values(fieldData), (v) => {
    return !isEmpty(v) ? parseFloat(v) : null;
  });
};

export const getFieldsFromData = (val, keys = []) => isObject(val) ?
  flatMap(val, (v, k) => getFieldsFromData(v, [...keys, k])) :
  keys.join('.');
