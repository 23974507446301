import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TransactionType from '../TransactionType';


export const AccountTransactionDtoPropDef = {
  id: {
    type: new StringType(),
  },
  customerId: {
    type: new StringType(),
  },
  shopId: {
    type: new StringType(),
  },
  receiptId: {
    type: new StringType(),
  },
  clubCode: {
    type: new StringType(),
  },
  transactionDate: {
    type: new DateTimeType(),
  },
  points: {
    type: new NumberType(),
  },
  type: {
    type: new EntityType(TransactionType),
  },
  place: {
    type: new StringType(),
  },
  pointsValidTill: {
    type: new DateTimeType(),
  },
  data: {
    type: new StringType(),
  },
  note: {
    type: new StringType(),
  },
};
export default class GeneratedAccountTransactionDto extends createEntity(AccountTransactionDtoPropDef) {}
