
import {computed, defineComponent} from 'vue';
import ModalDetachedBody from '@/Components/ModalDetachedBody.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import {useTransitions} from '@/Helpers/transitions';
import {useConfirmContent} from '@/Modules/Core/Confirm/contents/content';
import {useRecoveryStore} from '@/Modules/Core/store/RecoveryStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import {useAcl} from '@/Helpers/acl';
import * as aclKeys from '@/constants/aclKeys';

export default defineComponent({
  name: 'ModalConfirmContentTypeRecovery',
  components: {ModalDetachedButtons, ModalDetachedClose, ModalDetachedBody},
  setup() {
    const i18n = useI18n();
    const coreStore = useCoreStore();
    const recoveryStore = useRecoveryStore();
    const router = useRouter();
    const {transitionLeaving} = useTransitions();
    const {
      onConfirm,
      onReject,
      hasRejectFn,
      type,
    } = useConfirmContent();
    const acl = useAcl();

    const onRecovery = async () => {
      if (await coreStore.confirm(i18n.t('recovery.confirm'))) {
        await recoveryStore.recovery(recoveryStore.getRecoveryTypeByRoute(router.currentRoute.value));
      }
    };

    const canUseRecovery = computed(() => {
      return acl(aclKeys.PERMISSION_RECOVERY_REQUEST_FOUR_EYES);
    });

    return {
      transitionLeaving,
      type,
      onRecovery,
      onConfirm,
      onReject,
      hasRejectFn,
      coreStore,
      canUseRecovery,
    };
  },
});
