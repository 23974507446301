import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {isNil} from 'lodash-es';
import {SexOptions} from '@/constants/sexOptions';
import {markRaw} from 'vue';
import {validationBirthdate} from '@/Helpers/validations';
import {usePhoneNumberMaskResolver} from '@/Helpers/phoneNumberMaskResolver';
import {syncToTarget} from '@/Helpers/syncedStore';
import {
  getter,
} from '@designeo/vue-helpers';
import {CreateCustomerDto} from '@/Model/Entity';

export class WorkflowStepEnterCustomerInformation extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterCustomerInformation;
  }

  private phoneNumberMaskResolver = usePhoneNumberMaskResolver()

  get resolvedPhoneNumberMask() {
    return this.phoneNumberMaskResolver.resolvePhoneNumberMask(this.phone);
  }

  ensureCustomerPreset() {
    if (this.params.customerPreset) {
      const customerPresetData = this.getBucket(this.params.customerPreset);
      const customer = new CreateCustomerDto(customerPresetData);

      if (customer.yearOfBirth) {
        this.yearOfBirth = customer.yearOfBirth;
      }

      if (customer.sex) {
        this.sex = customer.sex.value;
      }

      if (customer.phone) {
        this.phone = customer.phone;
      }

      if (customer.attributes) {
        this.attributes = customer.attributes.map((attribute) => attribute.toJson());
      }
    }
  }

  async beforeEnter() {
    this.ensureCustomerPreset();

    this.phone = this.phoneNumberMaskResolver.resolveDefaultPhoneNumber(this.phone);
    this.activeCustomerConfirmation = false;
  }

  get type() {
    return WorkflowStepEnterCustomerInformation.type;
  }

  get component() {
    return markRaw(require('./StepEnterCustomerInformation.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.yearOfBirth]: yup.string()
        .required()
        .test(validationBirthdate),
      [WorkflowStepField.sex]: yup.string()
        .required(),
      [WorkflowStepField.phone]: yup.string()
        .required()
        .test(this.resolvedPhoneNumberMask.validation)
        .default(() => this.phoneNumberMaskResolver.resolveDefaultPhoneNumber()),
    });
  }

  async submit() {
    if (!await this.validate()) return;
    this.activeCustomerConfirmation = true;
  }

  get yearOfBirth() {
    return this.getFieldValue(WorkflowStepField.yearOfBirth, null);
  }

  set yearOfBirth(val) {
    this.dataSetter(WorkflowStepField.yearOfBirth, () => val);
  }

  get sex() {
    return this.getFieldValue(WorkflowStepField.sex, null);
  }

  set sex(val) {
    this.dataSetter(WorkflowStepField.sex, () => val);
  }

  get phone() {
    return this.getFieldValue(WorkflowStepField.phone, null);
  }

  set phone(val) {
    this.dataSetter(WorkflowStepField.phone, () => val);
  }

  get attributes() {
    return this.getFieldValue(WorkflowStepField.attributes, null);
  }

  set attributes(val) {
    this.dataSetter(WorkflowStepField.attributes, () => val);
  }

  get activeCustomerConfirmation() {
    return this.getFieldValue('activeCustomerConfirmation', false);
  }

  set activeCustomerConfirmation(val) {
    this.dataSetter('activeCustomerConfirmation', () => val);
  }

  customerInformationSync = syncToTarget('CustomerRegistration', 'customerInformation', getter(() => {
    return {
      active: this.activeCustomerConfirmation,
      texts: {
        header: this.step.customerDisplayHeader,
        request: this.step.customerDisplayRequest,
        [WorkflowStepField.yearOfBirth]: this.step.yearOfBirth,
        [WorkflowStepField.sex]: this.step.sex,
        [WorkflowStepField.phone]: this.step.phone,
      },
      fields: {
        [WorkflowStepField.yearOfBirth]: this.yearOfBirth,
        [WorkflowStepField.sex]: this.sex,
        [WorkflowStepField.phone]: this.phone,
      },
    };
  }));

  abortCustomerConfirmation() {
    this.activeCustomerConfirmation = false;
  }

  onAnswer(confirmed: boolean) {
    this.activeCustomerConfirmation = false;

    if (confirmed) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } else {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.CUSTOMER_INFORMATION_CONFIRMATION_FAILED,
        },
      }));
    }
  }

  get disabledNextStep(): any {
    if (this.activeCustomerConfirmation) {
      return true;
    }

    return false;
  }

  get transitions() {
    if (this.activeCustomerConfirmation) {
      return {};
    }

    const fields = [
      WorkflowStepField.yearOfBirth,
      WorkflowStepField.sex,
      WorkflowStepField.phone,
    ];
    return {
      ...this.withFieldActions(WorkflowStepField.yearOfBirth, (fieldActions) => ({
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(() => {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
            detail: {
              field: WorkflowStepField.sex,
            },
          }));
        }),
        ...this.createArrowMovementTransitions(fields, fields.indexOf(WorkflowStepField.yearOfBirth)),
      })),
      ...this.withFieldActions(WorkflowStepField.sex, (fieldActions) => ({
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.PREV]: action(() => {
          fieldActions[WorkflowActions.ENTER](SexOptions.MALE);
        }),
        [WorkflowActions.NEXT]: action(() => {
          fieldActions[WorkflowActions.ENTER](SexOptions.FEMALE);
        }),
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<boolean>) => {
          if (!isNil(event.value)) {
            fieldActions[WorkflowActions.ENTER](event.value);
          } else {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
              detail: {
                field: WorkflowStepField.phone,
              },
            }));
          }
        }),
        ...this.createArrowMovementTransitions(fields, fields.indexOf(WorkflowStepField.sex)),
      })),
      ...this.withFieldActions(WorkflowStepField.phone, (fieldActions) => ({
        [WorkflowActions.ADD_PLUS]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_PLUS](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.submit();
        }),
        ...this.createArrowMovementTransitions(fields, fields.indexOf(WorkflowStepField.phone)),
      }), {
        mask: this.resolvedPhoneNumberMask.phoneNumberMask,
      }),
    };
  }
}
