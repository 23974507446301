import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TransactionType from '../TransactionType';
import {BooleanType} from '@designeo/apibundle-js';


export const LoyaltyPointsTransactionDtoPropDef = {
  id: {
    type: new StringType(),
  },
  clubCode: {
    type: new StringType(),
  },
  points: {
    type: new NumberType(),
  },
  type: {
    type: new EntityType(TransactionType),
  },
  promoCode: {
    type: new StringType(),
  },
  note: {
    type: new StringType(),
  },
  exportToFib: {
    type: new BooleanType(),
  },
};
export default class GeneratedLoyaltyPointsTransactionDto extends createEntity(LoyaltyPointsTransactionDtoPropDef) {}
