
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {HLInputSelectMultiple} from '@designeo/vue-forms/src/Input/InputSelectMultiple';
import {ensureFieldInterface} from '@designeo/vue-forms/src/Input/Input';

export default defineComponent({
  components: {
    HLInputSelectMultiple,
  },
  inheritAttrs: false,
  props: {
    strictVModel: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      type: Array,
      default: ()=>[],
    },
    options: {
      type: Array as PropType<Array<string | {id: any, label: string, [key: string]: any}>>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const {
      getModelValue,
      setModelValue,
    } = ensureFieldInterface(props, {
      emit,
    });

    const model = computed({
      get: () => getModelValue(),
      set: (val) => setModelValue(val),
    });


    return {
      open,
      model,
    };
  },
});
