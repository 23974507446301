export enum CustomerDataField {
    customerName = 'customerName',
    vat = 'vat',
    vatId = 'vatId',
    vatAmount = 'vatAmount',
    street = 'street',
    city = 'city',
    zip = 'zip',
    note = 'note',
}
