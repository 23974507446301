import {
  createEntity,
  ObjectType,
} from '@designeo/apibundle-js';
import {
  WarehouseOperationLogisticDocumentAttributes,
  WarehouseOperationBaseAttributes,
  WarehouseOperationFilterAttributes,
  WarehouseOperationPatch,
  WarehouseOperationSummaryAttributes,

} from '@/Model/Entity/patches/WarehouseOperationMixin';

export default class WarehouseOperationDepreciationOfGoodsTypeConsumptionDtoCustom extends WarehouseOperationPatch(
  createEntity({
    ...WarehouseOperationBaseAttributes,
    ...WarehouseOperationLogisticDocumentAttributes,
    ...WarehouseOperationSummaryAttributes,
  }),
) {}
