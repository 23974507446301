import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HLAsyncButton = _resolveComponent("HLAsyncButton")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_HLAsyncButton, { onClick: _ctx.onClick }, {
    default: _withCtx(({invokeOnClick, isInternallyWaiting}) => [
      _withDirectives(_createVNode(_component_Button, _mergeProps(_ctx.$attrs, {
        loading: _ctx.loading ?? isInternallyWaiting,
        disabled: _ctx.disabled,
        onClick: invokeOnClick
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 2
      }, 1040, ["loading", "disabled", "onClick"]), [
        [_directive_test, _ctx.test]
      ])
    ]),
    _: 3
  }, 8, ["onClick"]))
}