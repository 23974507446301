import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {
  PosConfiguration,
  PosConfigurationActions,
  PosConfigurationEvent,
  PosConfigurationInputEvent,
  PosConfigurationState,
} from '../types';
import {
  KEYBOARD_KEY_BACKSPACE,
  KEYBOARD_KEY_DELETE,
  KEYBOARD_KEY_ENTER,
  KEYBOARD_KEY_ESCAPE,
  KEYBOARD_KEYS_NUMBERS,
} from '@/constants/keyboardKeys';
import {BufferedInput} from '@/Modules/Register/services/KeyboardBuffer';
import {
  apiAdminRegisterPos,
  apiAdminUnregisterPos,
  apiConfigurationGet,
  apiDocumentCreate,
  apiV1PosPromotionsBulk,

} from '@/Model/Action';
import {
  DocumentDto, PointOfSaleActivatedDto,
} from '@/Model/Entity';
import {seekApiErrors} from '@/Helpers/errors';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {CoreWSEvents} from '@/Modules/Core/types';
import {useSignalR} from '@/Helpers/signalR';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  clearStorage,
} from '@/Helpers/app';

export const createPosConfiguration = (data: Partial<PosConfiguration> = {}): PosConfiguration => ({
  code: '',
  language: 'en',
});

export interface IPosConfigurationStore {
    state: PosConfigurationState,
    posConfiguration: PosConfiguration,
    registrationResult: PointOfSaleActivatedDto,
    errors: any[]
  }

export class PosConfigurationStore extends PersistentStore<IPosConfigurationStore> {
  constructor() {
    super({
      state: PosConfigurationState.SELECT_LANGUAGE,
      posConfiguration: createPosConfiguration(),
      registrationResult: null,
      errors: [],
    }, require('@/Helpers/persist').registrationStoreStatePersist);
  }

  transitions: {[key in PosConfigurationState]?: {[key in PosConfigurationActions]?: Function}} = {
    [PosConfigurationState.SELECT_LANGUAGE]: {
      [PosConfigurationActions.ENTER]: action(async (event: PosConfigurationInputEvent) => {
        if (!event.value) return;

        this.state.posConfiguration.language = event.value;
        this.dispatchEvent(new Event(PosConfigurationEvent.LANGUAGE_SELECTED));
        this.state.state = PosConfigurationState.ENTER_CODE;
      }),
      [PosConfigurationActions.CANCEL]: action(async (event: PosConfigurationInputEvent) => {
        this.ensureExit();
      }),
    },
    [PosConfigurationState.ENTER_CODE]: {
      [PosConfigurationActions.ADD_NUMBER]: action(async (event: PosConfigurationInputEvent) => {
        this.state.posConfiguration.code += event.value;
      }),
      [PosConfigurationActions.BACKSPACE]: action(async () => {
        this.state.posConfiguration.code = this.state.posConfiguration.code.slice(
          0,
          this.state.posConfiguration.code.length - 1,
        );
      }),
      [PosConfigurationActions.ENTER]: action(async (event: PosConfigurationInputEvent) => {
        await this.registerPos();
      }),
      [PosConfigurationActions.CANCEL]: action(async (event: PosConfigurationInputEvent) => {
        this.ensureExit();
      }),
    },
  }

  onKeyboardInput = action((bufferedInput: BufferedInput) => {
    for (const key of bufferedInput.keys) {
      if (KEYBOARD_KEYS_NUMBERS.includes(key.key)) {
        this.onEventInput({
          type: PosConfigurationActions.ADD_NUMBER,
          value: key.key,
        });
      } else if (key.key === KEYBOARD_KEY_ENTER) {
        this.onEventInput({
          type: PosConfigurationActions.ENTER,
        });
      } else if (key.key === KEYBOARD_KEY_BACKSPACE) {
        this.onEventInput({
          type: PosConfigurationActions.BACKSPACE,
        });
      } else if (key.key === KEYBOARD_KEY_DELETE) {
        this.onEventInput({
          type: PosConfigurationActions.CLEAR,
        });
      } else if (key.key === KEYBOARD_KEY_ESCAPE) {
        this.onEventInput({
          type: PosConfigurationActions.CANCEL,
        });
      }
    }
  });

  canBeExited = getter(() => {
    if (!useConfigurationStore().configuration.value?.isPosConfigured) {
      return false;
    }

    return this.isPosConfigurationStateEnterCode.value || this.isPosConfigurationStateSelectLanguage.value;
  })

  ensureExit = action(() => {
    if (!this.canBeExited.value) {
      return;
    }

    this.dispatchEvent(new Event(PosConfigurationEvent.EXIT));
  })

  restart = action(() => {
    this.state.posConfiguration = createPosConfiguration();
    this.state.errors = [];
    this.state.state = PosConfigurationState.SELECT_LANGUAGE;
  })

  registerPos = action(async () => {
    try {
      this.state.state = PosConfigurationState.INSTALL_IN_PROGRESS;
      const response = await apiAdminRegisterPos({
        params: {
          singleUseCode: this.state.posConfiguration.code,
        },
      });

      window.postMessage(JSON.parse(JSON.stringify({
        name: CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED,
        payload: {
          configuration: await apiConfigurationGet(),
          promotions: (await apiV1PosPromotionsBulk()).toJson(),
        },
      })), window.location.origin);

      if (response) {
        this.state.registrationResult = response;
      }

      this.state.state = PosConfigurationState.SUCCESS;
      await clearStorage();
    } catch (e) {
      console.error(e);
      this.state.errors = seekApiErrors(e);
      this.state.state = PosConfigurationState.FAILED;
    }

    this.state.posConfiguration = createPosConfiguration();
  })

  setCloseDay = action(async () => {
    await Promise.all([
      apiDocumentCreate({
        input: DocumentDto.createCloseDayDocument().toApiClone(),
      }),
    ]);
  })

  errors = getter(() => this.state.errors)

  posConfiguration = getter(() => this.state.posConfiguration)

  isPosConfigurationStateSelectLanguage = getter(
    () => this.state.state === PosConfigurationState.SELECT_LANGUAGE,
  )

  isPosConfigurationStateEnterCode = getter(
    () => this.state.state === PosConfigurationState.ENTER_CODE,
  )

  isPosConfigurationStateInstallInProgress = getter(
    () => this.state.state === PosConfigurationState.INSTALL_IN_PROGRESS,
  )

  isPosConfigurationStateSuccess = getter(
    () => this.state.state === PosConfigurationState.SUCCESS,
  )

  isPosConfigurationStateFailed = getter(
    () => this.state.state === PosConfigurationState.FAILED,
  )

  registrationResult = getter(
    () => this.state.registrationResult,
  )


  onEventInput = action(async (event: PosConfigurationInputEvent) => {
    this.transitions?.[this.state.state]?.[event.type]?.(event);
  })
}

const storeIdentifier = 'PosConfigurationStore';

export const configurePosConfigurationStore = createConfigureStore<typeof PosConfigurationStore>(storeIdentifier);
export const usePosConfigurationStore = createUseStore(PosConfigurationStore, storeIdentifier);
