import GeneratedCurrency from './generated/Currency';
import {filter} from 'lodash-es';
import ExchangeRate from '@/Model/Entity/ExchangeRate';

export default class Currency extends GeneratedCurrency {
  get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get acceptableNominals() {
    return filter(this.nominals, (nominal) => nominal >= this.smallestAcceptedNominal);
  }

  get symbolLowerCase() {
    return this.symbol?.toLowerCase?.();
  }

  get isLocalCurrency() {
    return this.symbol === this.configurationStore.localCurrency.value.symbol;
  }

  get exchangeRate() {
    if (this.isLocalCurrency) {
      return new ExchangeRate({
        baseAmount: 1,
        baseCurrency: this.symbol,
        quoteAmount: 1,
        quoteCurrency: this.symbol,
      });
    }

    const exchangeRateKey = `${this.symbol}:${this.configurationStore.localCurrency.value.symbol}`;
    return this.configurationStore.exchangeRatesByCurrencies.value[exchangeRateKey];
  }
}
