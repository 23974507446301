import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';

export class WorkflowStepSafeBagPickup extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagPickup;
  }

  get type() {
    return WorkflowStepSafeBagPickup.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagPickup.vue').default);
  }

  get transitions() {
    return {};
  }
}
