
import {defineComponent, PropType} from 'vue';
import {DocumentItemDto} from '@/Model/Entity';

export default defineComponent({
  name: 'ProductName',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    item: {
      type: Object as PropType<{documentItem: DocumentItemDto}>,
      required: false,
      default: null,
    },
  },
});
