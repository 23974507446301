import {axios} from '../axios';
import {createAction} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {UrlEncodedParamsType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ProblemDetails from '../../Entity/ProblemDetails';
import ErrorResponse from '../../Entity/ErrorResponse';
import {VoidType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import PermissionDto from '../../Entity/PermissionDto';
import CashierVerifyResultDto from '../../Entity/CashierVerifyResultDto';
import ChangePasswordDto from '../../Entity/ChangePasswordDto';
import DocumentDto from '../../Entity/DocumentDto';
import PointOfSaleActivatedDto from '../../Entity/PointOfSaleActivatedDto';
import SwitchShopDto from '../../Entity/SwitchShopDto';
import ArticlesDto from '../../Entity/ArticlesDto';
import ArticleFileListingResultDto from '../../Entity/ArticleFileListingResultDto';
import CreateCounterDto from '../../Entity/CreateCounterDto';
import CounterDto from '../../Entity/CounterDto';
import CreateCustomerContactDto from '../../Entity/CreateCustomerContactDto';
import CustomerContactDto from '../../Entity/CustomerContactDto';
import UpdateCustomerContactDto from '../../Entity/UpdateCustomerContactDto';
import CustomerReceiptDto from '../../Entity/CustomerReceiptDto';
import CreateCustomerDto from '../../Entity/CreateCustomerDto';
import CustomerDto from '../../Entity/CustomerDto';
import DocumentCustomerDto from '../../Entity/DocumentCustomerDto';
import UpdateCustomerDto from '../../Entity/UpdateCustomerDto';
import CustomerPhoneDto from '../../Entity/CustomerPhoneDto';
import CustomerCardPreviewDto from '../../Entity/CustomerCardPreviewDto';
import CustomerCardDto from '../../Entity/CustomerCardDto';
import CustomersDto from '../../Entity/CustomersDto';
import SendPinDto from '../../Entity/SendPinDto';
import PinDto from '../../Entity/PinDto';
import AccountTransactionClubGroupsDto from '../../Entity/AccountTransactionClubGroupsDto';
import ReferentialDocumentsDto from '../../Entity/ReferentialDocumentsDto';
import RepeatablePrintingCommand from '../../Entity/RepeatablePrintingCommand';
import TransactionDto from '../../Entity/TransactionDto';
import DocumentNumberCounterDto from '../../Entity/DocumentNumberCounterDto';
import SetDocumentCounterDto from '../../Entity/SetDocumentCounterDto';
import CreateErrorDocumentDto from '../../Entity/CreateErrorDocumentDto';
import DocumentReportInfoDto from '../../Entity/DocumentReportInfoDto';
import StringStringValueTuple from '../../Entity/StringStringValueTuple';
import {BooleanType} from '@designeo/apibundle-js';
import PlantDto from '../../Entity/PlantDto';
import DistributionCenterDto from '../../Entity/DistributionCenterDto';
import VendorDto from '../../Entity/VendorDto';
import ShipmentNotificationListDto from '../../Entity/ShipmentNotificationListDto';
import ShipmentNotificationDetailDto from '../../Entity/ShipmentNotificationDetailDto';
import SearchResultDto from '../../Entity/SearchResultDto';
import InStoreSearchFilterDto from '../../Entity/InStoreSearchFilterDto';
import InventoryReportDto from '../../Entity/InventoryReportDto';
import InventoryGroupDto from '../../Entity/InventoryGroupDto';
import InventoryMetadataDto from '../../Entity/InventoryMetadataDto';
import {StringType} from '@designeo/apibundle-js';
import AddJournalCommand from '../../Entity/AddJournalCommand';
import AddJournalRecordDto from '../../Entity/AddJournalRecordDto';
import GeneratedNumbersResource from '../../Entity/GeneratedNumbersResource';
import DrawPartnerResource from '../../Entity/DrawPartnerResource';
import ClientDataPartnerResource from '../../Entity/ClientDataPartnerResource';
import PreparedTicketPartnerResource from '../../Entity/PreparedTicketPartnerResource';
import TicketDetailPartnerResource from '../../Entity/TicketDetailPartnerResource';
import GeneratePossibleWinsCommand from '../../Entity/GeneratePossibleWinsCommand';
import AmountsToWinResource from '../../Entity/AmountsToWinResource';
import ValidateSessionCommand from '../../Entity/ValidateSessionCommand';
import ValidatePartnerSessionResponse from '../../Entity/ValidatePartnerSessionResponse';
import BetTicketPartnerResource from '../../Entity/BetTicketPartnerResource';
import TicketDetailPartnerResourceV2 from '../../Entity/TicketDetailPartnerResourceV2';
import ValidateSessionV2Command from '../../Entity/ValidateSessionV2Command';
import LotteryTurnoversDto from '../../Entity/LotteryTurnoversDto';
import LotteryTurnoverErrorsDto from '../../Entity/LotteryTurnoverErrorsDto';
import MessageDtoIPagingObject from '../../Entity/MessageDtoIPagingObject';
import CreateMessageDto from '../../Entity/CreateMessageDto';
import MessageAddressBookDto from '../../Entity/MessageAddressBookDto';
import ArchiveMessageDto from '../../Entity/ArchiveMessageDto';
import MessageAttachmentDto from '../../Entity/MessageAttachmentDto';
import MessageListDtoIPagingObject from '../../Entity/MessageListDtoIPagingObject';
import MessageDetailDto from '../../Entity/MessageDetailDto';
import OrderListDto from '../../Entity/OrderListDto';
import OrderDtoOrderEnvelopeDto from '../../Entity/OrderDtoOrderEnvelopeDto';
import VerifyOrderPinRequestDto from '../../Entity/VerifyOrderPinRequestDto';
import VerifyOrderPinResultDto from '../../Entity/VerifyOrderPinResultDto';
import FulfillOrderDto from '../../Entity/FulfillOrderDto';
import OrderFulfillResultDto from '../../Entity/OrderFulfillResultDto';
import CancelOrderDto from '../../Entity/CancelOrderDto';
import OrderCancelResultDto from '../../Entity/OrderCancelResultDto';
import OrderNotificationCountDto from '../../Entity/OrderNotificationCountDto';
import CashStatesDto from '../../Entity/CashStatesDto';
import SafeStateDto from '../../Entity/SafeStateDto';
import UpdatePrinterConfigurationDto from '../../Entity/UpdatePrinterConfigurationDto';
import PrinterConfigurationUpdateResultDto from '../../Entity/PrinterConfigurationUpdateResultDto';
import PromotionBulkDto from '../../Entity/PromotionBulkDto';
import ReceiptCounterDto from '../../Entity/ReceiptCounterDto';
import SubmitPosStateReportDto from '../../Entity/SubmitPosStateReportDto';
import SafeBagDto from '../../Entity/SafeBagDto';
import ArticleListDto from '../../Entity/ArticleListDto';
import ArticleListFilterDto from '../../Entity/ArticleListFilterDto';
import GroupRangeSearchDto from '../../Entity/GroupRangeSearchDto';
import StockFilterDto from '../../Entity/StockFilterDto';
import StockDto from '../../Entity/StockDto';
import NearbyShopStockFilterDto from '../../Entity/NearbyShopStockFilterDto';
import NearbyShopStockDto from '../../Entity/NearbyShopStockDto';
import StockFilterOnlyLoadedArticlesDto from '../../Entity/StockFilterOnlyLoadedArticlesDto';
import StatusDto from '../../Entity/StatusDto';
import LocalIPDto from '../../Entity/LocalIPDto';
import PrinterServiceStateDto from '../../Entity/PrinterServiceStateDto';
import CheckResponseDto from '../../Entity/CheckResponseDto';
import ChargeResponseDto from '../../Entity/ChargeResponseDto';
import RevokeResponseDto from '../../Entity/RevokeResponseDto';
import CancelResponseDto from '../../Entity/CancelResponseDto';
import Transaction from '../../Entity/Transaction';
import UpdateStatusDto from '../../Entity/UpdateStatusDto';
import SendSmsDto from '../../Entity/SendSmsDto'; // Do not remove

export const apiLogin = createAction({
  name: 'api.login',
  method: 'POST',
  path: '/api/login',
  inputType: new UrlEncodedParamsType(new ObjectType()),
  outputTypes: {
    200: new ObjectType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiLoginRefresh = createAction({
  name: 'api.login.refresh',
  method: 'POST',
  path: '/api/login/refresh',
  inputType: new UrlEncodedParamsType(new ObjectType()),
  outputTypes: {
    200: new ObjectType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAccountPermissionsGetList = createAction({
  name: 'api.account.permissions.get.list',
  method: 'GET',
  path: '/api/account/permissions',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(PermissionDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAccountVerifyCashier = createAction({
  name: 'api.account.verify.cashier',
  method: 'GET',
  path: '/api/account/verify/{cashierCode}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CashierVerifyResultDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAccountPasswordChange = createAction({
  name: 'api.account.password.change',
  method: 'PUT',
  path: '/api/account/password/change',
  inputType: new EntityType(ChangePasswordDto),
  outputTypes: {
    202: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAccountPasswordChangeCashier = createAction({
  name: 'api.account.password.change.cashier',
  method: 'PUT',
  path: '/api/account/{cashierCode}/password/change',
  inputType: new EntityType(ChangePasswordDto),
  outputTypes: {
    202: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAccountTokenValidity = createAction({
  name: 'api.account.token.validity',
  method: 'GET',
  path: '/api/account/token-validity',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    401: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2AccountVerifyCashier = createAction({
  name: 'api.v2.account.verify.cashier',
  method: 'GET',
  path: '/api/v2/account/verify/{cashierCode}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CashierVerifyResultDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2Login = createAction({
  name: 'api.v2.login',
  method: 'POST',
  path: '/api/v2/login',
  inputType: new UrlEncodedParamsType(new ObjectType()),
  outputTypes: {
    200: new ObjectType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminPosStateCreate = createAction({
  name: 'api.admin.pos.state.create',
  method: 'POST',
  path: '/api/admin/pos-state',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminPosSalesReportCreate = createAction({
  name: 'api.admin.pos.sales.report.create',
  method: 'POST',
  path: '/api/admin/pos-sales-report',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminRegisterPos = createAction({
  name: 'api.admin.register.pos',
  method: 'PUT',
  path: '/api/admin/register-pos/{singleUseCode}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(PointOfSaleActivatedDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminUnregisterPos = createAction({
  name: 'api.admin.unregister.pos',
  method: 'PUT',
  path: '/api/admin/unregister-pos',
  inputType: new VoidType(),
  outputTypes: {
    202: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminShop = createAction({
  name: 'api.admin.shop',
  method: 'PUT',
  path: '/api/admin/switch-shop',
  inputType: new EntityType(SwitchShopDto),
  outputTypes: {
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2ArticlesByTypeGet = createAction({
  name: 'api.v2.articles.by.type.get',
  method: 'GET',
  path: '/api/v2/articles/{type}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ArticlesDto),
  },
  errorTypes: {
    304: new VoidType(),
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminArticlesManagementList = createAction({
  name: 'api.admin.articles.management.list',
  method: 'GET',
  path: '/api/articles-management',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ArticleFileListingResultDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiAdminArticlesManagementSwitch = createAction({
  name: 'api.admin.articles.management.switch',
  method: 'POST',
  path: '/api/articles-management/switch/{fileName}',
  inputType: new VoidType(),
  outputTypes: {
    202: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiColdBoot = createAction({
  name: 'api.cold.boot',
  method: 'POST',
  path: '/api/cold-boot',
  inputType: new VoidType(),
  outputTypes: {

  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiConfigurationGet = createAction({
  name: 'api.configuration.get',
  method: 'GET',
  path: '/api/configuration',
  inputType: new VoidType(),
  outputTypes: {
    200: new ObjectType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    423: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2ConfigurationWithParamsGet = createAction({
  name: 'api.v2.configuration.with.params.get',
  method: 'GET',
  path: '/api/v2/configuration/{shopCode}/{posCode}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ObjectType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2ConfigurationGet = createAction({
  name: 'api.v2.configuration.get',
  method: 'GET',
  path: '/api/v2/configuration',
  inputType: new VoidType(),
  outputTypes: {
    200: new ObjectType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCountersCreate = createAction({
  name: 'api.counters.create',
  method: 'POST',
  path: '/api/counters/{counterCode}',
  inputType: new EntityType(CreateCounterDto),
  outputTypes: {
    200: new EntityType(CounterDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiToDoList = createAction({
  name: 'api.to.do.list',
  method: 'GET',
  path: '/to-do-list/{cashierCode}',
  inputType: new VoidType(),
  outputTypes: {

  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersContactsCreate = createAction({
  name: 'api.customers.contacts.create',
  method: 'POST',
  path: '/api/customers/{customerNumber}/contacts',
  inputType: new EntityType(CreateCustomerContactDto),
  outputTypes: {
    201: new EntityType(CustomerContactDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersContactsGetList = createAction({
  name: 'api.customers.contacts.get.list',
  method: 'GET',
  path: '/api/customers/{customerNumber}/contacts',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(CustomerContactDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersContactsGet = createAction({
  name: 'api.customers.contacts.get',
  method: 'GET',
  path: '/api/customers/{customerNumber}/contacts/{contactId}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CustomerContactDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersContactsUpdate = createAction({
  name: 'api.customers.contacts.update',
  method: 'PUT',
  path: '/api/customers/{customerNumber}/contacts/{contactId}',
  inputType: new EntityType(UpdateCustomerContactDto),
  outputTypes: {
    200: new EntityType(CustomerContactDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersContactsDelete = createAction({
  name: 'api.customers.contacts.delete',
  method: 'DELETE',
  path: '/api/customers/{customerNumber}/contacts/{contactId}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CustomerContactDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersReceiptsGetList = createAction({
  name: 'api.customers.receipts.get.list',
  method: 'GET',
  path: '/api/customers/{customerNumber}/receipts',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(CustomerReceiptDto)),
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersCreate = createAction({
  name: 'api.customers.create',
  method: 'POST',
  path: '/api/customers',
  inputType: new EntityType(CreateCustomerDto),
  outputTypes: {
    200: new EntityType(CustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersGet = createAction({
  name: 'api.customers.get',
  method: 'GET',
  path: '/api/customers/{customerNumber}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentCustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersUpdate = createAction({
  name: 'api.customers.update',
  method: 'PUT',
  path: '/api/customers/{customerNumber}',
  inputType: new EntityType(UpdateCustomerDto),
  outputTypes: {
    200: new EntityType(CustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosCustomersUpdatePhone = createAction({
  name: 'api.v1.pos.customers.update.phone',
  method: 'PUT',
  path: '/api/customers/{customerNumber}/update-phone',
  inputType: new EntityType(CustomerPhoneDto),
  outputTypes: {
    200: new EntityType(CustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosCustomersAddCard = createAction({
  name: 'api.v1.pos.customers.add.card',
  method: 'POST',
  path: '/api/customers/{customerNumber}/add-card',
  inputType: new EntityType(CustomerCardPreviewDto),
  outputTypes: {
    200: new EntityType(CustomerCardDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosCustomersDeactivateCard = createAction({
  name: 'api.v1.pos.customers.deactivate.card',
  method: 'POST',
  path: '/api/customers/{customerNumber}/deactivate-card',
  inputType: new EntityType(CustomerCardPreviewDto),
  outputTypes: {
    200: new EntityType(CustomerCardDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersByCard = createAction({
  name: 'api.customers.by.card',
  method: 'GET',
  path: '/api/customers/by-card/{cardNumber}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentCustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersSearch = createAction({
  name: 'api.customers.search',
  method: 'GET',
  path: '/api/customers/search',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CustomersDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersGenerateAndSendPin = createAction({
  name: 'api.customers.generate.and.send.pin',
  method: 'POST',
  path: '/api/customers/{customerNumber}/generate-and-send-pin',
  inputType: new EntityType(SendPinDto),
  outputTypes: {
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersVerifyPhoneByPin = createAction({
  name: 'api.customers.verify.phone.by.pin',
  method: 'POST',
  path: '/api/customers/{customerNumber}/verify-phone-by-pin',
  inputType: new EntityType(PinDto),
  outputTypes: {
    200: new EntityType(CustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersVerifyPin = createAction({
  name: 'api.customers.verify.pin',
  method: 'POST',
  path: '/api/customers/{customerNumber}/verify-pin',
  inputType: new EntityType(PinDto),
  outputTypes: {
    204: new EntityType(CustomerDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersGdprConsent = createAction({
  name: 'api.customers.gdpr.consent',
  method: 'POST',
  path: '/api/customers/{customerNumber}/gdpr-consent',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CustomerDto),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersCardAvailable = createAction({
  name: 'api.customers.card.available',
  method: 'POST',
  path: '/api/customers/customer-card/{customerCardNumber}/available',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersGetByPhone = createAction({
  name: 'api.customers.get.by.phone',
  method: 'GET',
  path: '/api/customers/by-phone/{phoneNumberPrefix}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(DocumentCustomerDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersGetByPhonePaginated = createAction({
  name: 'api.customers.get.by.phone.paginated',
  method: 'GET',
  path: '/api/customers/by-phone-paginated/{phoneNumberPrefix}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CustomersDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiCustomersTransactionsGetList = createAction({
  name: 'api.customers.transactions.get.list',
  method: 'GET',
  path: '/api/customers/{customerNumber}/transactions',
  inputType: new VoidType(),
  outputTypes: {
    201: new EntityType(AccountTransactionClubGroupsDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDiscoveryDiscover = createAction({
  name: 'api.discovery.discover',
  method: 'POST',
  path: '/api/discovery',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentCreate = createAction({
  name: 'api.document.create',
  method: 'POST',
  path: '/api/documents',
  inputType: new EntityType(DocumentDto),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGetList = createAction({
  name: 'api.document.get.list',
  method: 'GET',
  path: '/api/documents',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(ReferentialDocumentsDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentFinishWithPdfPrint = createAction({
  name: 'api.document.finish.with.pdf.print',
  method: 'POST',
  path: '/api/documents/finish-with-pdf-print',
  inputType: new VoidType(),
  outputTypes: {
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentCreateDisplay = createAction({
  name: 'api.document.create.display',
  method: 'POST',
  path: '/api/documents/display',
  inputType: new EntityType(DocumentDto),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentRepeatablePrinting = createAction({
  name: 'api.document.repeatable.printing',
  method: 'POST',
  path: '/api/documents/repeatable-printing',
  inputType: new EntityType(RepeatablePrintingCommand),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGetByDocumentBarcode = createAction({
  name: 'api.document.get.by.document.barcode',
  method: 'GET',
  path: '/api/documents/barcode/{barcode}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ReferentialDocumentsDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGet = createAction({
  name: 'api.document.get',
  method: 'GET',
  path: '/api/documents/{id}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(TransactionDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGetCounters = createAction({
  name: 'api.document.get.counters',
  method: 'GET',
  path: '/api/documents/counters',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(DocumentNumberCounterDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentUpdateCounters = createAction({
  name: 'api.document.update.counters',
  method: 'PUT',
  path: '/api/documents/counters',
  inputType: new EntityType(SetDocumentCounterDto),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentStaticTextPrints = createAction({
  name: 'api.document.staticTextPrints',
  method: 'POST',
  path: '/api/documents/static-text-prints/{code}',
  inputType: new VoidType(),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentErrorCreate = createAction({
  name: 'api.document.error.create',
  method: 'POST',
  path: '/api/documents/error',
  inputType: new EntityType(CreateErrorDocumentDto),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGetChangeHistory = createAction({
  name: 'api.document.get.change.history',
  method: 'GET',
  path: '/api/documents/{id}/change-history',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(TransactionDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGetReportInfo = createAction({
  name: 'api.document.get.report.info',
  method: 'GET',
  path: '/api/documents/report/info',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentReportInfoDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentGetReportPrint = createAction({
  name: 'api.document.get.report.print',
  method: 'GET',
  path: '/api/documents/report/print/{previewOnly}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2DocumentCreate = createAction({
  name: 'api.v2.document.create',
  method: 'POST',
  path: '/api/v2/documents',
  inputType: new EntityType(DocumentDto),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiFinancialCloseDayGetFibAndBon = createAction({
  name: 'api.financial.close.day.get.fib.and.bon',
  method: 'GET',
  path: '/api/financial-close-day/fibAndBon',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(StringStringValueTuple),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiFinancialCloseDayGetFib = createAction({
  name: 'api.financial.close.day.get.fib',
  method: 'GET',
  path: '/api/financial-close-day/fib',
  inputType: new VoidType(),
  outputTypes: {

  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiFinancialCloseDayGetBon = createAction({
  name: 'api.financial.close.day.get.bon',
  method: 'GET',
  path: '/api/financial-close-day/bon',
  inputType: new VoidType(),
  outputTypes: {

  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiFinancialCloseDayCanStart = createAction({
  name: 'api.financial.close.day.can.start',
  method: 'GET',
  path: '/api/financial-close-day/canStart',
  inputType: new VoidType(),
  outputTypes: {
    200: new BooleanType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiFinancialCloseDayCreate = createAction({
  name: 'api.financial.close.day.create',
  method: 'POST',
  path: '/api/financial-close-day',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInStoreManagementPlantList = createAction({
  name: 'api.in.store.management.plant.list',
  method: 'GET',
  path: '/api/in-store-management/plants',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(PlantDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInStoreManagementDistributionCenterList = createAction({
  name: 'api.in.store.management.distribution.center.list',
  method: 'GET',
  path: '/api/in-store-management/distribution-centers',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(DistributionCenterDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInStoreManagementVendorList = createAction({
  name: 'api.in.store.management.vendor.list',
  method: 'GET',
  path: '/api/in-store-management/vendors',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(VendorDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosInStoreManagementShipmentNotificationList = createAction({
  name: 'api.v1.pos.in.store.management.shipment.notification.list',
  method: 'GET',
  path: '/api/in-store-management/shipment-notifications',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(ShipmentNotificationListDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosInStoreManagementShipmentNotificationGet = createAction({
  name: 'api.v1.pos.in.store.management.shipment.notification.get',
  method: 'GET',
  path: '/api/in-store-management/shipment-notification/{id}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ShipmentNotificationDetailDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosInStoreManagementShipmentNotificationPutProcessed = createAction({
  name: 'api.v1.pos.in.store.management.shipment.notification.put.processed',
  method: 'PUT',
  path: '/api/in-store-management/shipment-notification/{id}/processed',
  inputType: new VoidType(),
  outputTypes: {
    202: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInStoreManagementArticleSearch = createAction({
  name: 'api.in.store.management.article.search',
  method: 'GET',
  path: '/api/in-store-management/search',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInStoreManagementArticleTextSearch = createAction({
  name: 'api.in.store.management.article.text.search',
  method: 'GET',
  path: '/api/in-store-management/text-search',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInStoreManagementArticleMultiSearch = createAction({
  name: 'api.in.store.management.article.multi.search',
  method: 'POST',
  path: '/api/in-store-management/multi-search',
  inputType: new EntityType(InStoreSearchFilterDto),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInventoryCreate = createAction({
  name: 'api.inventory.create',
  method: 'POST',
  path: '/api/inventory',
  inputType: new EntityType(DocumentDto),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInventoryDataForReport = createAction({
  name: 'api.inventory.data.for.report',
  method: 'GET',
  path: '/api/inventory/data-for-report',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(InventoryReportDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInventoryCustomArticlesGet = createAction({
  name: 'api.inventory.custom.articles.get',
  method: 'GET',
  path: '/api/inventory/custom/articles/{code}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInventoryGroupsList = createAction({
  name: 'api.inventoryGroups.list',
  method: 'GET',
  path: '/api/inventoryGroups',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(InventoryGroupDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiInventoryMetadata = createAction({
  name: 'api.inventory.metadata',
  method: 'GET',
  path: '/api/inventory-metadata',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(InventoryMetadataDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiJournalTxtGet = createAction({
  name: 'api.journal.txt.get',
  method: 'GET',
  path: '/api/journal/txt',
  inputType: new VoidType(),
  outputTypes: {
    200: new StringType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiJournalLinesGet = createAction({
  name: 'api.journal.lines.get',
  method: 'GET',
  path: '/api/journal/lines',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new StringType()),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiJournalPrint = createAction({
  name: 'api.journal.print',
  method: 'POST',
  path: '/api/journal/print',
  inputType: new VoidType(),
  outputTypes: {
    201: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    409: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiJournalAdd = createAction({
  name: 'api.journal.add',
  method: 'POST',
  path: '/api/journal/add',
  inputType: new EntityType(AddJournalCommand),
  outputTypes: {
    200: new StringType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiJournalV2Add = createAction({
  name: 'api.journal.v2.add',
  method: 'POST',
  path: '/api/v2/journal/add',
  inputType: new EntityType(AddJournalRecordDto),
  outputTypes: {
    200: new StringType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaGenerateNumbers = createAction({
  name: 'api.3rd-party.korunka.generate-numbers',
  method: 'POST',
  path: '/api/3rd-party/korunka/generate-numbers',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(GeneratedNumbersResource)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaGenerateDraws = createAction({
  name: 'api.3rd-party.korunka.generate-draws',
  method: 'GET',
  path: '/api/3rd-party/korunka/draws',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(DrawPartnerResource)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaClientsByBranchCard = createAction({
  name: 'api.3rd-party.korunka.clients.by-branch-card',
  method: 'GET',
  path: '/api/3rd-party/korunka/clients/by-branch-card/{cardNumber}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ClientDataPartnerResource),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaPreparedTicket = createAction({
  name: 'api.3rd-party.korunka.prepared.ticket',
  method: 'GET',
  path: '/api/3rd-party/korunka/prepared-tickets/{id}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(PreparedTicketPartnerResource),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaTicketByNumber = createAction({
  name: 'api.3rd-party.korunka.ticket.by-number',
  method: 'GET',
  path: '/api/3rd-party/korunka/tickets/by-number/{ticketNumber}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(TicketDetailPartnerResource),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaTicketPossibleWins = createAction({
  name: 'api.3rd-party.korunka.ticket.possible-wins',
  method: 'POST',
  path: '/api/3rd-party/korunka/tickets/possible-wins',
  inputType: new EntityType(GeneratePossibleWinsCommand),
  outputTypes: {
    200: new EntityType(AmountsToWinResource),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaValidate = createAction({
  name: 'api.3rd-party.korunka.validate',
  method: 'POST',
  path: '/api/3rd-party/korunka/validate',
  inputType: new EntityType(ValidateSessionCommand),
  outputTypes: {
    200: new EntityType(ValidatePartnerSessionResponse),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaRecognizeTicket = createAction({
  name: 'api.3rd-party.korunka.recognize-ticket',
  method: 'GET',
  path: '/api/3rd-party/korunka/recognize-ticket',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(BetTicketPartnerResource)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyKorunkaDebugTickets = createAction({
  name: 'api.3rd-party.korunka.debug-tickets',
  method: 'GET',
  path: '/api/3rd-party/korunka/debug-tickets',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(DrawPartnerResource)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyV2KorunkaTicketByNumber = createAction({
  name: 'api.3rd-party.v2.korunka.ticket.by-number',
  method: 'GET',
  path: '/api/v2/3rd-party/korunka/tickets/by-number/{ticketNumber}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(TicketDetailPartnerResourceV2),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyV2KorunkaValidate = createAction({
  name: 'api.3rd-party.v2.korunka.validate',
  method: 'POST',
  path: '/api/v2/3rd-party/korunka/validate',
  inputType: new EntityType(ValidateSessionV2Command),
  outputTypes: {
    200: new EntityType(ValidatePartnerSessionResponse),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiLoggingIntegrationEvents = createAction({
  name: 'api.logging.integration.events',
  method: 'GET',
  path: '/api/logging/integration-events',
  inputType: new VoidType(),
  outputTypes: {

  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiLotteryLastLotteryStatement = createAction({
  name: 'api.lottery.last.lottery.statement',
  method: 'GET',
  path: '/api/lottery/last-lottery-statement',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiLotteryTurnovers = createAction({
  name: 'api.lottery.turnovers',
  method: 'GET',
  path: '/api/lottery/turnovers/{provider}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(LotteryTurnoversDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiLotteryCurrentPosOnlyTurnovers = createAction({
  name: 'api.lottery.current.pos.only.turnovers',
  method: 'GET',
  path: '/api/lottery/turnovers/current-pos-only/{provider}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(LotteryTurnoversDto),
  },
  errorTypes: {
    400: new EntityType(LotteryTurnoverErrorsDto),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageList = createAction({
  name: 'api.message.list',
  method: 'GET',
  path: '/api/messages',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(MessageDtoIPagingObject),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageCreate = createAction({
  name: 'api.message.create',
  method: 'POST',
  path: '/api/messages',
  inputType: new EntityType(CreateMessageDto),
  outputTypes: {
    201: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageAttachment = createAction({
  name: 'api.message.attachment',
  method: 'GET',
  path: '/api/messages/attachment/{attachmentId}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ObjectType(),  // TODO: keep here ObjectType
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageAddressBook = createAction({
  name: 'api.message.address.book',
  method: 'GET',
  path: '/api/messages/address-book',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(MessageAddressBookDto)),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageArchive = createAction({
  name: 'api.message.archive',
  method: 'PUT',
  path: '/api/messages/archive',
  inputType: new EntityType(ArchiveMessageDto),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageUploadAttachment = createAction({
  name: 'api.message.upload.attachment',
  method: 'POST',
  path: '/api/messages/upload',
  inputType: new ObjectType(),  // TODO: keep here ObjectType
  outputTypes: {
    200: new EntityType(MessageAttachmentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiMessageMarkAsRead = createAction({
  name: 'api.message.mark.as.read',
  method: 'PUT',
  path: '/api/messages/{id}/mark-as-read',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2MessageList = createAction({
  name: 'api.v2.message.list',
  method: 'GET',
  path: '/api/v2/messages',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(MessageListDtoIPagingObject),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV2MessageDetail = createAction({
  name: 'api.v2.message.detail',
  method: 'GET',
  path: '/api/v2/messages/detail/{messageId}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(MessageDetailDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersGetList = createAction({
  name: 'api.orders.get.list',
  method: 'GET',
  path: '/api/orders',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(OrderListDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersGetDetail = createAction({
  name: 'api.orders.get.detail',
  method: 'GET',
  path: '/api/orders/{id}',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(OrderDtoOrderEnvelopeDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersVerifyPin = createAction({
  name: 'api.orders.verify.pin',
  method: 'POST',
  path: '/api/orders/{id}/verify-pin',
  inputType: new EntityType(VerifyOrderPinRequestDto),
  outputTypes: {
    200: new EntityType(VerifyOrderPinResultDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersChangeStatusFulfill = createAction({
  name: 'api.orders.change.status.fulfill',
  method: 'POST',
  path: '/api/orders/{id}/change-status/fulfill',
  inputType: new EntityType(FulfillOrderDto),
  outputTypes: {
    200: new EntityType(OrderFulfillResultDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersChangeStatusCancel = createAction({
  name: 'api.orders.change.status.cancel',
  method: 'POST',
  path: '/api/orders/{id}/change-status/cancel',
  inputType: new EntityType(CancelOrderDto),
  outputTypes: {
    200: new EntityType(OrderCancelResultDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersPrintDeliveryNote = createAction({
  name: 'api.orders.print.delivery.note',
  method: 'POST',
  path: '/api/orders/print/delivery-note',
  inputType: new EntityType(OrderDtoOrderEnvelopeDto),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersNotificationCount = createAction({
  name: 'api.orders.notification.count',
  method: 'GET',
  path: '/api/orders/notification/count',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(OrderNotificationCountDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiOrdersTryUpdateCustomerByOrderId = createAction({
  name: 'api.orders.try.update.customer.by.order.id',
  method: 'PUT',
  path: '/api/orders/{id}/try-update-customer',
  inputType: new VoidType(),
  outputTypes: {
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiStockGetCashState = createAction({
  name: 'api.stock.get.cash.state',
  method: 'GET',
  path: '/api/pos/cash-state',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(CashStatesDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiPosGetSafeState = createAction({
  name: 'api.pos.get.safe.state',
  method: 'GET',
  path: '/api/pos/safe-state',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(SafeStateDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiPrinterUpdateConfiguration = createAction({
  name: 'api.printer.update.configuration',
  method: 'PUT',
  path: '/api/printer',
  inputType: new ArrayType(new EntityType(UpdatePrinterConfigurationDto)),
  outputTypes: {
    200: new EntityType(PrinterConfigurationUpdateResultDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosPromotionsBulk = createAction({
  name: 'api.v1.pos.promotions.bulk',
  method: 'GET',
  path: '/api/v1/pos/promotions/bulk',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(PromotionBulkDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosReceiptCountersCountAndIncrement = createAction({
  name: 'api.v1.pos.receipt.counters.count.and.increment',
  method: 'PUT',
  path: '/api/v1/pos/receipt-counters/count-and-increment',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ReceiptCounterDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiReportPosStateGet = createAction({
  name: 'api.report.pos.state.get',
  method: 'GET',
  path: '/api/report/pos-state',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiReportPosStateSubmit = createAction({
  name: 'api.report.pos.state.submit',
  method: 'POST',
  path: '/api/report/pos-state',
  inputType: new EntityType(SubmitPosStateReportDto),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosSafeBagsGetByTransport = createAction({
  name: 'api.v1.pos.safe.bags.get.by.transport',
  method: 'GET',
  path: '/api/safe-bags/by-transport/{transportId}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SafeBagDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiV1PosSafeBagVerify = createAction({
  name: 'api.v1.pos.safe.bag.verify',
  method: 'GET',
  path: '/api/safe-bags/verify/{code}',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearch = createAction({
  name: 'api.search',
  method: 'GET',
  path: '/api/search',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchMiddleware = createAction({
  name: 'api.search.middleware',
  method: 'GET',
  path: '/api/search-middleware',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchList = createAction({
  name: 'api.search.list',
  method: 'GET',
  path: '/api/search/list',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(ArticleListDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchListFiltered = createAction({
  name: 'api.search.list.filtered',
  method: 'POST',
  path: '/api/search/list',
  inputType: new EntityType(ArticleListFilterDto),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchByAttribute = createAction({
  name: 'api.search.by.attribute',
  method: 'GET',
  path: '/api/search/by-attribute/{attribute}/{value}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchByGroup = createAction({
  name: 'api.search.by.group',
  method: 'GET',
  path: '/api/search/by-group/{group}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchByGroupRange = createAction({
  name: 'api.search.by.group.range',
  method: 'POST',
  path: '/api/search/by-group-range',
  inputType: new EntityType(GroupRangeSearchDto),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchPromotions = createAction({
  name: 'api.search.promotions',
  method: 'GET',
  path: '/api/search/promotion/{promotionCode}',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiTextSearch = createAction({
  name: 'api.text.search',
  method: 'GET',
  path: '/api/text-search',
  inputType: new VoidType(),
  outputTypes: {
    200: new ArrayType(new EntityType(SearchResultDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchIsready = createAction({
  name: 'api.search.isready',
  method: 'GET',
  path: '/api/search/is-ready',
  inputType: new VoidType(),
  outputTypes: {
    200: new BooleanType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSearchLoadNewTree = createAction({
  name: 'api.search.load.new.tree',
  method: 'POST',
  path: '/api/search/load-new-tree',
  inputType: new VoidType(),
  outputTypes: {
    200: new BooleanType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiStockGet = createAction({
  name: 'api.stock.get',
  method: 'POST',
  path: '/api/stock',
  inputType: new EntityType(StockFilterDto),
  outputTypes: {
    200: new ArrayType(new EntityType(StockDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiStockNearbyShopGet = createAction({
  name: 'api.stock.nearby.shop.get',
  method: 'POST',
  path: '/api/stock/nearby-shop',
  inputType: new EntityType(NearbyShopStockFilterDto),
  outputTypes: {
    200: new ArrayType(new EntityType(NearbyShopStockDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiStockGetOnlyLoadedArticles = createAction({
  name: 'api.stock.get.only.loaded.articles',
  method: 'POST',
  path: '/api/stock/only-loaded-articles',
  inputType: new EntityType(StockFilterOnlyLoadedArticlesDto),
  outputTypes: {
    200: new ArrayType(new EntityType(StockDto)),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiStockPrint = createAction({
  name: 'api.stock.print',
  method: 'POST',
  path: '/api/stock/print',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(DocumentDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiTechnicalStatus = createAction({
  name: 'api.technical-status',
  method: 'GET',
  path: '/api/technical-status',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(StatusDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiTechnicalStatusLocalIp = createAction({
  name: 'api.technical-status.local.ip',
  method: 'GET',
  path: '/api/technical-status/local-ip',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(LocalIPDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiTechnicalStatusPrinterService = createAction({
  name: 'api.technical-status.printer.service',
  method: 'GET',
  path: '/api/technical-status/printer-service',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(PrinterServiceStateDto),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiTestResetDb = createAction({
  name: 'api.test.reset.db',
  method: 'POST',
  path: '/api/test/reset-db',
  inputType: new VoidType(),
  outputTypes: {
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyCheck = createAction({
  name: 'api.3rd-party.check',
  method: 'POST',
  path: '/api/3rd-party/check',
  inputType: new ObjectType(),
  outputTypes: {
    200: new EntityType(CheckResponseDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    403: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyCharge = createAction({
  name: 'api.3rd-party.charge',
  method: 'POST',
  path: '/api/3rd-party/charge',
  inputType: new ObjectType(),
  outputTypes: {
    200: new EntityType(ChargeResponseDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    403: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyRevoke = createAction({
  name: 'api.3rd-party.revoke',
  method: 'POST',
  path: '/api/3rd-party/revoke',
  inputType: new ObjectType(),
  outputTypes: {
    200: new EntityType(RevokeResponseDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    403: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const api3rdPartyCancel = createAction({
  name: 'api.3rd-party.cancel',
  method: 'POST',
  path: '/api/3rd-party/cancel',
  inputType: new ObjectType(),
  outputTypes: {
    200: new EntityType(CancelResponseDto),
  },
  errorTypes: {
    400: new EntityType(ProblemDetails),
    403: new EntityType(ProblemDetails),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiDocumentCreateExternalPos = createAction({
  name: 'api.document.create.external.pos',
  method: 'POST',
  path: '/api/transactions/from-external-pos/{posId}/{transactionDate}',
  inputType: new EntityType(Transaction),
  outputTypes: {
    201: new VoidType(),
    202: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiPosUpdate = createAction({
  name: 'api.pos.update',
  method: 'POST',
  path: '/api/pos/update/start',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiPosDownload = createAction({
  name: 'api.pos.download',
  method: 'POST',
  path: '/api/pos/update/download',
  inputType: new VoidType(),
  outputTypes: {
    200: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiSms = createAction({ // do not remove!!
  name: 'api.sms',
  method: 'POST',
  path: '/api/sms',
  inputType: new EntityType(SendSmsDto),
  outputTypes: {
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    404: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});

export const apiPosUpdateStatus = createAction({
  name: 'api.pos.update.status',
  method: 'GET',
  path: '/api/pos/update/status',
  inputType: new VoidType(),
  outputTypes: {
    200: new EntityType(UpdateStatusDto),
    204: new VoidType(),
  },
  errorTypes: {
    400: new EntityType(ErrorResponse),
    500: new EntityType(ErrorResponse),
  },
  paramsType: new ObjectType(),
  axios,
});
