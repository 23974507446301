import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col flex-grow" }
const _hoisted_3 = { class: "list-decimal pl-4" }
const _hoisted_4 = { class: "flex-grow mt-4" }
const _hoisted_5 = { class: "mt-auto mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputBuffer = _resolveComponent("InputBuffer")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("ul", _hoisted_3, [
              _createElementVNode("li", null, _toDisplayString(_ctx.workflowStep.messages.stepOne), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.workflowStep.messages.stepTwo), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.workflowStep.messages.stepThree), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.workflowStep.messages.stepFour), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_HLInput, {
              field: _ctx.WorkflowStepField.cardNumber
            }, {
              default: _withCtx(({isActive, value, setActive, error}) => [
                _createVNode(_component_FormInput, {
                  layout: 'col',
                  label: _ctx.workflowStep.messages.cardNumber,
                  error: error,
                  active: isActive,
                  errorOutput: 'modal',
                  onClick: setActive
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InputBuffer, {
                      value: value.value,
                      disableCaretNavigator: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 2
                }, 1032, ["label", "error", "active", "onClick"])
              ]),
              _: 1
            }, 8, ["field"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_WorkflowTransitions, {
              nextDisabled: _ctx.workflowStep.disabledNextStep
            }, null, 8, ["nextDisabled"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}