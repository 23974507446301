import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_CUSTOM_DATA_QUESTION]: {
      [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
        await this.changeState(RegisterState.SELECT_PAYMENT);
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent<boolean>) => {
        if (event.value) {
          await this.changeState(RegisterState.ENTER_CUSTOM_DATA);
        } else {
          await this.changeState(RegisterState.SELECT_PAYMENT);
        }
      }),
    },
  };
}
