import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.workflowStore.currentWorkflow.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["flex items-stretch justify-center gap-5 px-2 py-2 xl:container", {
      'overflow-hidden': !_ctx.workflowStore.keyboardQwerty.value,
      'overflow-y-auto': _ctx.workflowStore.keyboardQwerty.value,
      'max-h-screen-55-h xl:max-h-screen-65-h': _ctx.workflowStore.keyboardQwerty.value,
    }])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
    : _createCommentVNode("", true)
}