import {
  createEntity,
  EntityType,
} from '@designeo/apibundle-js';
import {
  WarehouseOperationLogisticDocumentAttributes,
  WarehouseOperationBaseAttributes,
  WarehouseOperationPatch,
  WarehouseOperationSummaryAttributes,

} from '@/Model/Entity/patches/WarehouseOperationMixin';
import VendorDto from '@/Model/Entity/VendorDto';
import PlantDto from '@/Model/Entity/PlantDto';
import DistributionCenterDto from '@/Model/Entity/DistributionCenterDto';

export default class WarehouseOperationOrderOfGoodsFromDistributionCenterDtoCustom extends WarehouseOperationPatch(
  createEntity({
    ...WarehouseOperationBaseAttributes,
    ...WarehouseOperationLogisticDocumentAttributes,
    ...WarehouseOperationSummaryAttributes,
    vendor: {
      type: new EntityType(VendorDto),
    },
    distributionCenter: {
      type: new EntityType(DistributionCenterDto),
    },
    plant: {
      type: new EntityType(PlantDto),
    },
  }),
) {}
