import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowCodes,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {
  isKorunkaVersionV1,
  isKorunkaVersionV2,
  serializeKorunkaEntity,
  throwUnsupportedKorunkaVersionError,
} from '@/Helpers/korunka';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {apiTextSearch} from '@/Model/Action';
import {invert} from 'lodash-es';
import {interpolate} from '@/Helpers/filters';
import {useRegisterStore} from '../../../Register/store/RegisterStore';

export class WorkflowStepKorunkaLotteryTicketResult extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaTicketResult;
  }

  get title() {
    if (!this.isTicketEntity(this.entity)) return;

    return interpolate(this.step.title, {ticketCode: this.entity.ticket.ticketNumber});
  }

  get type() {
    return WorkflowStepKorunkaLotteryTicketResult.type;
  }

  get component() {
    if (this.isTicketEntity(this.entity) && !this.entity.isSupportedGame) {
      return markRaw(require('./StepKorunkaLotteryTicketResultUnsupported.vue').default);
    }

    if (isKorunkaVersionV2()) {
      return markRaw(require('./StepKorunkaLotteryTicketResultV2.vue').default);
    }

    if (isKorunkaVersionV1()) {
      return markRaw(require('./StepKorunkaLotteryTicketResultV1.vue').default);
    }

    throwUnsupportedKorunkaVersionError();
  }

  get registerStore() {
    return useRegisterStore();
  }

  onPayout() {
    if (!this.isTicketEntity(this.entity)) return;

    if (!this.validateTicket(this.entity.ticket.ticketNumber)) return;

    const entityBucketKey = this.saveBucket(serializeKorunkaEntity(this.entity));

    this.messageBus.dispatchEvent(
      new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {
        detail: {
          workflowCode: WorkflowCodes.korunkaLotteryPayoutTicket,
          params: {entityBucketKey},
          returnToCurrentWorkflow: false,
        },
      }),
    );
  }

  async onCancelTicket() {
    if (!this.isTicketEntity(this.entity)) return;

    if (!this.validateTicket(this.entity.ticket.ticketNumber)) return;

    try {
      const gameType = this.entity.ticket.game.value;

      const workflowCode = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByGameType
        ?.[gameType];

      if (!workflowCode) return;

      const workflowCodesByArticles = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByArticles;

      const articlesByWorkflowCodes = invert(workflowCodesByArticles);

      const articleInternalNumber = articlesByWorkflowCodes?.[workflowCode];

      const [product] = sanitizeApiSearch(await apiTextSearch({params: {text: articleInternalNumber}})).documentItems;

      if (!product) return;

      const articleBucketKey = this.saveBucket(product.toJson());
      const entityBucketKey = this.saveBucket(serializeKorunkaEntity(this.entity.toGameCancel()));

      this.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {
          detail: {
            workflowCode: WorkflowCodes.korunkaLotteryCancelTicket,
            returnToCurrentWorkflow: false,
            params: {
              entityBucketKey,
              articleBucketKey,
              korunkaType: gameType,
            },
          },
        }),
      );
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    }
  }

  async onRepeatBetTicket() {
    if (!this.isTicketEntity(this.entity)) return;

    try {
      const gameType = this.entity.ticket.game.value;

      const workflowCode = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByGameType
        ?.[gameType];

      if (!workflowCode) return;

      const workflowCodesByArticles = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByArticles;

      const articlesByWorkflowCodes = invert(workflowCodesByArticles);

      const articleInternalNumber = articlesByWorkflowCodes?.[workflowCode];

      const [product] = sanitizeApiSearch(await apiTextSearch({params: {text: articleInternalNumber}})).documentItems;

      if (!product) return;

      const articleBucketKey = this.saveBucket(product.toJson());
      const entityBucketKey = this.saveBucket(serializeKorunkaEntity(this.entity.toGameRepeat()));

      this.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {
          detail: {
            workflowCode: workflowCode,
            returnToCurrentWorkflow: false,
            params: {
              articleBucketKey,
              entityBucketKey,
            },
          },
        }),
      );
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    }
  }

  async beforeEnter() {
    this.ensureCustomerDisplayKorunkaPage();
    await this.syncBasicStepStateToCustomerDisplay();
    await this.syncEntityToCustomerDisplay();
  }

  sellDocumentHasTicketToCancel(ticketNumber: number) {
    const sellDocument = this.registerStore.sellDocument.value;

    const ticketsToCancel = sellDocument.items
      .filter((item) => item.isServiceTypeKorunka)
      .map((article) => {
        const ticketsToCancel = article?.checkRequest?.metadata?.entity?.ticketsToCancel;
        return ticketsToCancel;
      })
      .flat()
      .filter(Boolean)
      .map(({ticketNumber}) => ticketNumber);

    return ticketsToCancel.includes(ticketNumber);
  }

  sellDocumentHasTicketToPayout(ticketNumber: number) {
    const sellDocument = this.registerStore.sellDocument.value;

    const ticketsToPayout = sellDocument.items
      .filter((item) => item.isServiceTypeKorunka)
      .map((article) => {
        const ticketNumber = article?.checkRequest?.metadata?.entity?.ticket?.ticketNumber;
        return ticketNumber;
      })
      .filter(Boolean);

    return ticketsToPayout.includes(ticketNumber);
  }

  validateTicket(ticketNumber: number) {
    if (this.sellDocumentHasTicketToCancel(ticketNumber)) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.KORUNKA_CANNOT_CANCEL_TICKET,
        },
      }));

      return false;
    }

    if (this.sellDocumentHasTicketToPayout(ticketNumber)) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.KORUNKA_CANNOT_PAYOUT_TICKET,
        },
      }));

      return false;
    }

    return true;
  }

  get transitions() {
    return {};
  }
}
