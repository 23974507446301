import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn bg-primary-lightest shadow-md p-2", {
      'btn-outline-success': _ctx.modelValue,
      'btn-outline-basic': !_ctx.modelValue && !_ctx.active,
      'btn-outline-primary': !_ctx.modelValue && _ctx.active,
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createVNode(_component_PhIcon, {
      icon: "PhCheck",
      class: _normalizeClass({'invisible': !_ctx.modelValue}),
      size: _ctx.sizeAsNumber
    }, null, 8, ["class", "size"])
  ], 2))
}