import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-danger flex justify-center pb-5" }
const _hoisted_3 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _directive_test = _resolveDirective("test")!

  return (!_ctx.transitionLeaving)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalDetachedClose, { onClose: _ctx.onReject }, null, 8, ["onClose"]),
        _createVNode(_component_ModalDetachedBody, {
          class: "pb-5",
          type: _ctx.type
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_PhIcon, {
                icon: "warning",
                size: 50
              })
            ]),
            _createElementVNode("div", null, _toDisplayString(_ctx.coreStore.confirmContent.value && _ctx.coreStore.confirmContent.value.message), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('apps.documents.pointOfNoReturnWarning')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createVNode(_component_ModalDetachedButtons, {
          single: !_ctx.hasRejectFn
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("button", {
              class: _normalizeClass(["btn-md w-full", {
          'btn-success': _ctx.hasRejectFn,
          'btn-outline-basic': !_ctx.hasRejectFn
        }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.hasRejectFn ? _ctx.$t('confirm.buttonConfirm') : _ctx.$t('confirm.buttonContinue')), 1)
            ], 2), [
              [_directive_test, 'modalConfirm']
            ]),
            (_ctx.hasRejectFn)
              ? _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-danger btn-md w-full",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onReject && _ctx.onReject(...args)))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('confirm.buttonReject')), 1)
                ], 512)), [
                  [_directive_test, 'modalReject']
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["single"])
      ]))
    : _createCommentVNode("", true)
}