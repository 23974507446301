import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {computed} from 'vue';

export const useConfirmContent = () => {
  const coreStore = useCoreStore();

  const hasRejectFn = computed(() => !!coreStore.confirmContent.value?.reject);

  const onReject = () => {
    if (hasRejectFn.value) {
      coreStore.confirmContent.value.reject();
    } else {
      coreStore.confirmContent.value.resolve();
    }
  };

  const type = computed(() => {
    return coreStore.confirmContent.value?.type;
  });

  const onConfirm = () => coreStore.confirmContent.value.resolve();

  return {
    type,
    onReject,
    onConfirm,
    hasRejectFn,
  };
};
