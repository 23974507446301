
import {defineComponent} from 'vue';

import Display from '../../../Display/Display.vue';
import Keyboard from '../../../Keyboard/Keyboard.vue';
import Instructions from '@/Modules/Workflow/Instructions/Instructions.vue';
import Qwerty from '@/Components/Qwerty/Qwerty.vue';
import {useWorkflowStore} from '../../../store/WorkflowStore';

import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {WorkflowKeyboards} from '../../../types';
import LayoutBase from '@/Modules/Workflow/Workflow/layout/LayoutBase.vue';

export default defineComponent({
  components: {
    LayoutBase,
    Qwerty,
    Instructions,
    Keyboard,
    Display,
  },
  emits: ['numericKeyboardInput', 'qwertyKeyboardInput'],
  setup() {
    const workflowStore = useWorkflowStore();

    return {
      workflowStore,
      InputSource,
      WorkflowKeyboards,
    };
  },
});
