import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {getResponseCodeConfiguration, ResponseCodes} from '@/Helpers/printerServiceResponseCodes';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {apiDocumentCreate, apiDocumentFinishWithPdfPrint} from '@/Model/Action';
import {DocumentDto} from '@/Model/Entity';
import {useSignalR} from '@/Helpers/signalR';
import {toRaw} from 'vue';
import {
  every,
  filter,
  findLast,
  includes,
  map,
  uniq,
} from 'lodash-es';
import {DateTime} from 'luxon';
import {guid} from '@/Helpers/guid';
import {DocumentTypes} from '@/constants/documentTypes';
import {b64DecodeUnicode} from '@/Helpers/base64';
import {recordCustomEventLogEntry} from '@/Helpers/logger';

const parseResultDate = (date) => {
  return DateTime
    .fromJSDate(new Date(date))
    .toFormat('dd. MM. y HH:mm:ss:SSS');
};

export const setResultProcessedAt = (result) => {
  result.processedAt = new Date().toISOString();
  return result;
};

export class DocumentStatusStore extends Store<{
  queue: Array<{
    guid: ReturnType<typeof guid>,
    dialog: {
      resolve: (results?: any[])=> void,
      reject?: (reason?: any)=> void,
      configuration: ReturnType<typeof getResponseCodeConfiguration>
    },
    result,
    document,
    promise,
    resolveIds: string[],
    isInner: boolean,
    isResolved: boolean,
  }>
}> {
  constructor() {
    super({
      queue: [],
    });
  }


  currentTask = getter(() => this.state.queue[0] ?? null);

  dialog = getter(() => this.currentTask.value?.dialog);

  retry = action(async () => {
    const currentTask = this.currentTask.value;
    const currentTaskId = currentTask.document.header.uniqueidentifier;

    try {
      const doc = new DocumentDto(currentTask.document).clone();

      const resolveIds = currentTask?.resolveIds ?? [];

      const {notificationsConnection} = useSignalR();
      await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== doc.header.uniqueidentifier) return false;

          if (!currentTask.isResolved) {
            const innerResults = await this.solve(result, document, {
              resolveIds: [...resolveIds, currentTaskId],
              isInner: true,
            });

            const solvingResult = innerResults.pop();

            Object.assign(result, solvingResult ?? result);

            return !!solvingResult;
          }

          return true;
        },
        {timeout: null},
      )(async () => {
        return await apiDocumentCreate({
          input: doc.toApiClone(),
        });
      });
    } catch (e) {
      console.error(e);
    }
  })

  resolveToPDF = action(async () => {
    const currentTask = this.currentTask.value;
    const currentTaskId = currentTask.document.header.uniqueidentifier;

    try {
      const doc = new DocumentDto(currentTask.document).clone();

      const resolveIds = currentTask?.resolveIds ?? [];

      const {notificationsConnection} = useSignalR();
      await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== doc.header.uniqueidentifier) return false;

          if (!currentTask.isResolved) {
            const innerResults = await this.solve(result, document, {
              resolveIds: [...resolveIds, currentTaskId],
              isInner: true,
            });

            const solvingResult = innerResults.pop();

            Object.assign(result, solvingResult ?? result);

            return !!solvingResult;
          }

          return true;
        },
        {timeout: null},
      )(async () => {
        return await apiDocumentFinishWithPdfPrint({
          params: {
            id: doc.header.uniqueidentifier,
          },
        });
      });
    } catch (e) {
      console.error(e);
    }
  })

  terminate = action(() => {
    if (this.currentTask.value) {
      this.solve(
        {
          ...this.currentTask.value.result,
          statusCode: parseInt(ResponseCodes.ActionInterrupted),
        },
        this.currentTask.value.document,
        {
          resolveIds: this.currentTask.value.resolveIds,
          isInner: true,
        },
      );

      return;
    }

    this.solve(
      {
        statusCode: parseInt(ResponseCodes.ActionInterrupted),
      },
      null,
      {
        resolveIds: [],
        isInner: true,
      },
    );
  })

  sendDialogResult = action(async (dialogResult) => {
    const results = [];

    try {
      const doc = DocumentDto.createDialogResult(
        dialogResult,
        new DocumentDto(this.currentTask.value.document).clone(),
      );

      const {notificationsConnection} = useSignalR();

      await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== doc.header.uniqueidentifier) return false;

          results.push(result);

          return true;
        },
        {timeout: null},
      )(async () => {
        return await apiDocumentCreate({
          input: doc.toApiClone(),
        });
      });

      return results;
    } catch (e) {
      console.error(e);
      throw e;
    }
  })

  sendNonFiscalDocument = action(async (output: {outputBase64: string}[]) => {
    const results = [];

    try {
      const doc = DocumentDto.createNonFinancialDocument(output);

      const {notificationsConnection} = useSignalR();

      await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== doc.header.uniqueidentifier) return false;

          const innerResults = await this.solve(result, document);

          const solvingResult = innerResults.pop();

          Object.assign(result, solvingResult ?? result);

          results.push(...innerResults);

          return !!solvingResult;
        },
        {timeout: null},
      )(async () => {
        return await apiDocumentCreate({
          input: doc.toApiClone(),
        });
      });

      return results;
    } catch (e) {
      console.error(e);
      return [];
    }
  })

  isValidResultWithNonFiscalPrint = action((result, document) => {
    const configuration = getResponseCodeConfiguration(result);

    if (configuration.code === ResponseCodes.ActionInterrupted) {
      return false;
    }

    if (configuration.finished) {
      return false;
    }

    if (!result.output?.length) {
      return false;
    }

    // #45991
    const hasValidOutput = every(result.output, (output) => {
      try {
        b64DecodeUnicode(output.outputBase64);
        return true;
      } catch (e) {
        console.error(e);
        recordCustomEventLogEntry('DocumentStatusStore isValidResultWithNonFiscalPrint', 'invalid outputBase64');
        return false;
      }
    });

    if (!hasValidOutput) {
      return false;
    }


    if (
      configuration.operation === DocumentTypes.PosPayment ||
      configuration.operation === DocumentTypes.PosPaymentCancel ||
      configuration.operation === DocumentTypes.PosPaymentRefund
    ) {
      return true;
    }

    return false;
  })

  solve = action(async (result, document, {resolveIds = [], isInner = false} = {}) => {
    setResultProcessedAt(result);
    const configuration = getResponseCodeConfiguration(result);

    // Do not delete, usable during debug
    // console.log(
    //   parseResultDate(result.processedAt),
    //   // parseResultDate(new Date()),
    //   result.statusCode,
    //   configuration.operation,
    // );

    const ensureNonFiscalPrint = async () => {
      if (this.isValidResultWithNonFiscalPrint(result, document)) {
        return await this.sendNonFiscalDocument(result.output);
      }

      return [];
    };

    // Current result told me, that task is solved or terminated
    if (configuration.finished || configuration.isTerminated) {
      const idsToResolve = uniq([result.uniqueIdentifier, ...resolveIds]);
      const taskQueue = filter(this.state.queue, (queueItem) => {
        return includes(idsToResolve, queueItem.result.uniqueIdentifier);
      });

      if (!taskQueue.length) {
        /** Current queue is single result */
        return Promise.resolve([
          ...await ensureNonFiscalPrint(),
          result,
        ]);
      }

      const createResolveResult = async () => {
        return [...(map(taskQueue, 'result')), ...await ensureNonFiscalPrint(), setResultProcessedAt(result)];
      };

      if (isInner) { // isInner is a pointer on inner results
        const lastOuterTask = findLast(taskQueue, (task) => !task.isInner);

        for (const [, task] of taskQueue.entries()) {
          task.dialog.resolve(task === lastOuterTask ? await createResolveResult() : []);
          await task.promise;
        }

        if (!lastOuterTask) {
          return Promise.resolve(await createResolveResult());
        } else {
          return Promise.resolve([]);
        }
      } else {
        for (const [, task] of taskQueue.entries()) {
          task.dialog.resolve([]);
          await task.promise;
        }

        return Promise.resolve(await createResolveResult());
      }
    }

    // Current result told me, this result does not need dialog
    if (!configuration.hasDialog) {
      return Promise.resolve([...await ensureNonFiscalPrint(), result]);
    }

    let resolve = (...args) => {};

    let reject = (...args) => {};

    const nonFiscalPrintPromise = ensureNonFiscalPrint();

    const promise = new Promise<any>((onFulfilled, onRejected) => {
      resolve = async (results) => {
        onFulfilled([...await nonFiscalPrintPromise, ...results]);
      };
      reject = (err: Error) => {
        onRejected(err);
      };
    });


    const task = {
      guid: guid(),
      document,
      result,
      dialog: {
        resolve,
        reject,
        configuration,
      },
      promise,
      resolveIds,
      isInner,
      isResolved: false,
    };

    try {
      return promise
        .then((results: any[]) => {
          task.isResolved = true;

          const taskIndex = toRaw(this.state.queue).findIndex((value) => value === task);

          if (taskIndex >= 0) {
            this.state.queue.splice(taskIndex, 1);
          }

          return results;
        });
    } finally {
      this.state.queue.unshift(task);
    }
  })
}

const storeIdentifier = 'DocumentStatusStore';

export const configureDocumentStatusStore = createConfigureStore<typeof DocumentStatusStore>(storeIdentifier);
export const useDocumentStatusStore = createUseStore(DocumentStatusStore, storeIdentifier);
