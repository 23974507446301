import {WorkflowStepEvents} from './../../types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {
  AppLoaderEvent,
} from '@/Modules/Core/types';
import {DocumentDto} from '@/Model/Entity';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import DocumentSave from '@/Helpers/document/save';

export class WorkflowStepSafeBagPrintDocument extends workflowStepMixinNominals(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.SafeBagPrintDocument;
  }

  get type() {
    return WorkflowStepSafeBagPrintDocument.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagPrintDocument.vue').default);
  }

  async printSaveBagNominals(forced = false) {
    if (!forced && this.stepFinished) return;

    let nominalsDocumentResult: Awaited<ReturnType<DocumentSave['run']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      nominalsDocumentResult = await DocumentSave.run(() => {
        const nominals = DocumentDto.createNominalsCountDocument({
          currency: this.data.nominalsSettings.currency,
          envelopeNumber: this.data.safeBagEnvelopeCode,
          documentBarcode: this.data.safeBagEnvelopeCode,
          externalReference: this.data.safeBagEnvelopeCode,
          items: this.availableNominalsAsItems,
          isPreview: true,
        });

        nominals.refreshTotalPrice();

        return nominals;
      }, {
        reference: {
          get: () => {
            return this.getFieldValue(`nominalsDocument:${this.index}`, null);
          },
          set: async (documentId: string) => {
            this.dataSetter(`nominalsDocument:${this.index}`, () => documentId);
            await this.workflowStore.persist();
          },
        },
      });

      nominalsDocumentResult.result = new PrinterResult(nominalsDocumentResult.result ?? {});

      if (nominalsDocumentResult.error) {
        throw nominalsDocumentResult.error;
      }

      if (nominalsDocumentResult.result.hasError) {
        throw new Error(nominalsDocumentResult.result.errorMessage);
      }

      this.stepFinished = true;

      if (isActiveFeaturePrintDisplayOnScreen() && nominalsDocumentResult.result.hasValidPrintContent) {
        await this.printContentStore.open(nominalsDocumentResult.result.printContent);
      }
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));

      if (!nominalsDocumentResult?.created) {
        throw e;
      }
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeEnter() {
    await this.printSaveBagNominals();
  }

  get transitions() {
    return {};
  }
}
