import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {SexOptions} from '@/constants/sexOptions';
import {isNil} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  apiCustomersGet,
} from '@/Model/Action';
import {
  validationBirthdate,
} from '@/Helpers/validations';

export class WorkflowStepUpdateCustomerInformation extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.UpdateCustomerInformation;
  }

  get type() {
    return WorkflowStepUpdateCustomerInformation.type;
  }

  get component() {
    return markRaw(require('../StepUpdateCustomerInformation/StepUpdateCustomerInformation.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.yearOfBirth]: yup.string()
        .required()
        .test(validationBirthdate),
      [WorkflowStepField.sex]: yup.string()
        .required(),
    });
  }

  get customerNumber() {
    return this.data[WorkflowStepField.customerNumber] ?? this.params.customerNumber;
  }

  async beforeEnter() {
    if (this.stepInit) return;

    this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      const customer = await apiCustomersGet({
        params: {
          customerNumber: this.customerNumber,
        },
      });

      this.dataSetter(null, () => customer.toJson());
      this.stepInit = true;
    } catch (e) {
      console.error(e);
    }
    this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.yearOfBirth, (fieldActions) => ({
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(() => {
          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
            detail: {
              field: WorkflowStepField.sex,
            },
          }));
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.sex, (fieldActions) => ({
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.PREV]: action(() => {
          fieldActions[WorkflowActions.ENTER](SexOptions.MALE);
        }),
        [WorkflowActions.NEXT]: action(() => {
          fieldActions[WorkflowActions.ENTER](SexOptions.FEMALE);
        }),
        [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent<boolean>) => {
          if (!isNil(event.value)) {
            fieldActions[WorkflowActions.ENTER](event.value);
            return;
          }

          if (!await this.validate()) return;

          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
