import {useRouter} from 'vue-router';
import {useTimeBasedEvents} from '@/Helpers/event';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';

export const useReferentialDocumentsTimeBasedEvents = () => {
  const {isSecondaryDisplay} = useSecondaryDisplay();

  if (isSecondaryDisplay) {
    return;
  }

  const router = useRouter();

  useTimeBasedEvents([
    {
      unit: 'day',
      callback: async () => {
        const referentialDocumentsStore = require('@/Modules/Apps/ReferentialDocuments/store/ReferentialDocumentsStore')
          .useReferentialDocumentsStore();

        referentialDocumentsStore.resetFilters();

        if (router.currentRoute.value?.name === 'apps-documents') {
          try {
            await router.replace({name: 'apps'});
            await router.replace({name: 'apps-documents'});
          } catch (e) {
            console.error(e);
          }
        }
      },
    },
  ]);
};
