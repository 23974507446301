import {markRaw} from 'vue';
import {DateTime, Interval} from 'luxon';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {apiLotteryLastLotteryStatement} from '@/Model/Action';
import {DocumentDto} from '@/Model/Entity';
import {AppLoaderEvent} from '@/Modules/Core/types';

export class WorkflowStepCheckLotteryFinancialReport extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.CheckLotteryFinancialReport;
  }

  get type() {
    return WorkflowStepCheckLotteryFinancialReport.type;
  }

  get component() {
    return markRaw(require('./StepCheckLotteryFinancialReport.vue').default);
  }

  get lotteryCloseDayRequired() {
    return this.step?.lotteryCloseDayRequired ?? true;
  }

  async beforeEnter() {
    try {
      this.stepInit = false;
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const lastLotteryReportDate = (
        await apiLotteryLastLotteryStatement() as DocumentDto
      )?.header?.documentDate ?? null;

      if (!lastLotteryReportDate) {
        this.dataSetter('lotteryCanContinue', () => false);
        this.disabledNextStep = this.lotteryCloseDayRequired ? true : false;
        return;
      }

      const lastReportMinutesAgo = Interval.fromDateTimes(
        DateTime.fromJSDate(lastLotteryReportDate),
        DateTime.now(),
      ).length('minutes');

      const diff = lastReportMinutesAgo < this.step.lotteryLastReportInterval;
      this.dataSetter('lotteryCanContinue', () => diff);
      this.disabledNextStep = this.lotteryCloseDayRequired ? !diff : false;
    } catch (e) {
      console.error(e);
    } finally {
      this.stepInit = true;
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
