
import AppHeader from '@/Modules/Core/AppHeader/AppHeader.vue';
export default {
  name: 'LayoutDefault',
  components: {
    AppHeader,
  },
  props: {
    view: {
      type: String,
      required: false,
      default: 'default',
      enum: [
        'default',
        'apps',
      ],
    },
  },
};
