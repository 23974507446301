import {ref} from 'vue';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export const useModalKeyboard = ({
  initValue = useConfigurationStore().configuration.value?.features?.sell?.qwertyForSearch ?? true,
} = {}) => {
  const showKeyboard = ref(initValue);

  const toggleShowKeyboard = () => {
    showKeyboard.value = !showKeyboard.value;
  };
  const setShowKeyboard = (val) => {
    showKeyboard.value = val;
  };

  return {
    showKeyboard,
    toggleShowKeyboard,
    setShowKeyboard,
  };
};
