import {useI18n} from 'vue-i18n';

export const useArticleHelpers = () => {
  const i18n = useI18n();
  const getArticleId = (article) => [
    ...(article.internalNumber ? [article.internalNumber] : []),
    ...(article.gtin ? [`${i18n.t('general.gtin')} ${article.gtin}`] : []),
  ].join(' / ');

  const getArticleGtin = (article) => {
    if (!article.gtin) {
      return null;
    }

    return `${i18n.t('general.gtin')} ${article.gtin}`;
  };

  return {
    getArticleId,
    getArticleGtin,
  };
};
