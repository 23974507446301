import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {action} from '@designeo/vue-helpers';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {api3rdPartyKorunkaClientsByBranchCard} from '../../../../Model/Action';
import KorunkaLotteryCardDtoCustom from '../../../../Model/Entity/custom/KorunkaLotteryCardDtoCustom';

export class WorkflowStepKorunkaLotteryScanCard extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaScanCard;
  }

  get type() {
    return WorkflowStepKorunkaLotteryScanCard.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryScanCard.vue').default);
  }

  get disabledNextStep() {
    return !this.cardNumber;
  }

  get cardNumber(): string {
    return this.getFieldValue(WorkflowStepField.cardNumber, false);
  }

  async beforeContinue() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const result = await api3rdPartyKorunkaClientsByBranchCard({params: {cardNumber: this.cardNumber}});

      this.entity = new KorunkaLotteryCardDtoCustom({...result.toJson()});
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.cardNumber, (fieldActions) => ({
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.SCANNER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ENTER](event.value);
        }),
        [WorkflowActions.ADD_MINUS]: action(() => {
          if (!this.data[WorkflowStepField.cardNumber]?.length) {
            fieldActions[WorkflowActions.ADD_MINUS]();
          }
        }),
        [WorkflowActions.ENTER]: action(async () => {
          if (!await this.validate()) return;

          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        }),
      })),
    };
  }
}
