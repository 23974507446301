import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const EshopDtoPropDef = {
  id: {
    type: new StringType(),
  },
  code: {
    type: new StringType(),
  },
};
export default class GeneratedEshopDto extends createEntity(EshopDtoPropDef) {}
