import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const PosAttributeDataDtoPropDef = {
  name: {
    type: new StringType(),
  },
  value: {
    type: new StringType(),
  },
};
export default class GeneratedPosAttributeDataDto extends createEntity(PosAttributeDataDtoPropDef) {}
