
import {
  defineComponent,
  PropType,
} from 'vue';
import WarehouseOperationGoodsListItem
  from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WarehouseOperationGoodsListItem.vue';
import {DocumentLogisticItemDto} from '@/Model/Entity';

export default defineComponent( {
  components: {WarehouseOperationGoodsListItem},
  props: {
    list: {
      type: Array as PropType<Array<{
        entity: DocumentLogisticItemDto
        isActive: boolean,
        hasVisiblePrices: boolean,
      }>>,
      required: true,
    },
  },
  emits: ['select'],
});
