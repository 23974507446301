import {DocumentCreateMode} from '@/constants/documentModeTypes';
import {OutputTypes} from '@/constants/outputTypes';
import {PrintoutTypes} from '@/constants/printoutTypes';
import {
  apiDocumentGet,
  apiDocumentRepeatablePrinting,
  apiDocumentCreate,
} from '@/Model/Action';
import {
  DocumentDto,
  OutputDto,
  RepeatablePrintingCommand,
  ResultDto,
  TransactionDto,
} from '@/Model/Entity';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {getResponseCodeConfiguration} from '../printerServiceResponseCodes';
import {SignalRErrors, useSignalR} from '../signalR';

export interface Result {
  result: ResultDto['_data'],
  error: Error,
  created: boolean,
  printed: boolean,
  mandatory: boolean,
}


export default class DocumentSave {
  private reference: {get(): Promise<string> | string; set(val: string): Promise<void> | void};
  transaction: TransactionDto = null;
  mandatoryReceiptPrint: boolean;

  public static async run(...args: ConstructorParameters<typeof DocumentSave>) {
    const instance = new DocumentSave(...args);
    return await instance.run();
  }

  constructor(
    public documentFactory: ()=> DocumentDto | Promise<DocumentDto>,
    {
      mandatoryReceiptPrint = useConfigurationStore().configuration.value
        ?.general
        ?.printAndPayment
        ?.mandatoryReceiptPrint ?? true,
      reference = {
        get: () => {
          return null;
        },
        set: (val: string) => {},
      },
    } = {},
  ) {
    this.reference = reference;
    this.mandatoryReceiptPrint = mandatoryReceiptPrint;
  }

  async ensureTransaction() {
    if (this.transaction) {
      return this.transaction;
    }

    if (await this.reference.get()) {
      const transaction = await apiDocumentGet({
        params: {uniqueidentifier: await this.reference.get()},
      });

      this.transaction = transaction;
      return this.transaction;
    }
  }

  public async create(document, mode: DocumentCreateMode, {
    printoutType,
    outputType,
    templateCode = undefined,
  } = {
    printoutType: document.printoutType?.value ?? PrintoutTypes.ReceiptPrinter,
    outputType: document.printTemplateType?.value ?? OutputTypes.Primary,
  }) {
    const {notificationsConnection} = useSignalR();
    const documentStatusStore = useDocumentStatusStore();
    const coreStore = useCoreStore();
    document = document.clone();

    const sellDocumentHeaderGUID = document.header.uniqueidentifier;

    try {
      const [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== sellDocumentHeaderGUID) return false;

          const solvingResult = (await documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
        {timeout: null},
      )(async () => {
        if (mode === DocumentCreateMode.print) {
          return await apiDocumentRepeatablePrinting({
            input: new RepeatablePrintingCommand({
              printoutType: printoutType,
              outputType: outputType,
              templateCode: templateCode,
              uniqueidentifier: sellDocumentHeaderGUID,
              copies: 1,
            }),
          });
        }

        if (mode === DocumentCreateMode.create) {
          document.disablePrintout = true;
        }

        document.preflightSetup();

        const result = await apiDocumentCreate({
          input: document.toApiClone(),
        });

        await this.reference.set(document.header.uniqueidentifier);

        return result;
      });

      return result;
    } catch (e) {
      if (e.message === SignalRErrors.timeout) {
        documentStatusStore.terminate();
      }

      throw e;
    }
  }

  public async print(document: DocumentDto, {
    printoutType,
    outputType,
    templateCode,
  }): Promise<Result> {
    let result;

    try {
      result = await this.create(document, DocumentCreateMode.print, {
        printoutType,
        outputType,
        templateCode,
      });
    } catch (error) {
      return {
        result,
        created: this.mandatoryReceiptPrint ? false : true,
        printed: false,
        error,
        mandatory: this.mandatoryReceiptPrint,
      };
    }

    const responseResult = getResponseCodeConfiguration(result);

    return {
      result,
      created: this.mandatoryReceiptPrint ? responseResult.finished : true,
      printed: responseResult.finished,
      error: null,
      mandatory: this.mandatoryReceiptPrint,
    };
  }

  async save(document: DocumentDto): Promise<{
    result: ResultDto['_data'],
    error: Error,
    created: boolean,
    printed: boolean,
    mandatory: boolean,
  }> {
    let result;

    if (this.mandatoryReceiptPrint) {
      try {
        result = await this.create(document, DocumentCreateMode.createAndPrint);

        if (!getResponseCodeConfiguration(result).finished) {
          return {
            result,
            created: false,
            printed: false,
            error: null,
            mandatory: true,
          };
        }
      } catch (error) {
        return {
          result,
          created: false,
          printed: false,
          error,
          mandatory: true,
        };
      }
    } else {
      try {
        result = await this.create(document, DocumentCreateMode.create);

        if (!getResponseCodeConfiguration(result).finished) {
          return {
            result,
            created: false,
            printed: false,
            error: null,
            mandatory: false,
          };
        }
      } catch (error) {
        return {
          result,
          created: false,
          printed: false,
          error,
          mandatory: false,
        };
      }

      try {
        result = await this.create(document, DocumentCreateMode.print);
      } catch (error) {
        return {
          result,
          created: true,
          printed: false,
          error,
          mandatory: false,
        };
      }
    }

    return {
      result,
      created: true,
      printed: getResponseCodeConfiguration(result).finished,
      error: null,
      mandatory: this.mandatoryReceiptPrint,
    };
  }

  public async run(): Promise<Result> {
    const reference = await this.reference.get();

    if (!reference) {
      return await this.save(await this.documentFactory());
    }

    try {
      const currentFinTransacation = await apiDocumentGet({
        params: {
          id: reference,
        },
      });

      const responseResult = getResponseCodeConfiguration(currentFinTransacation.result);

      if (responseResult.finished) {
        if (responseResult.originalResult.output?.length) {
          /**
           * We are currently support only single print output
           */
          const [
            {
              target: printoutType,
              outputType,
              templateCode,
            },
          ] = responseResult.originalResult.output as Array<OutputDto['_data']>;

          return await this.print(
            new DocumentDto({
              header: {
                uniqueidentifier: reference,
              },
            }),
            {
              printoutType,
              outputType,
              templateCode,
            },
          );
        } else {
          return {
            result: currentFinTransacation.result.toJson(),
            error: null,
            created: true, // ?
            printed: false, // ?
            mandatory: this.mandatoryReceiptPrint,
          };
        }
      }

      return await this.save(await this.documentFactory());
    } catch (e) {
      if (e?.response?.status === 404) {
        return await this.save(await this.documentFactory());
      }

      console.error(e);

      return {
        result: null,
        error: e,
        created: false,
        printed: false,
        mandatory: this.mandatoryReceiptPrint,
      };
    }
  }
}
