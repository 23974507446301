export function b64DecodeUnicode(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode(parseInt(p1, 16));
  }));
}

export function printerHtmlBase64ToDataHtmlContent(base64) {
  if (!base64) return null;

  const content = encodeURIComponent(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
      </head>
      <body style="display: flex; justify-content: center;">
          ${b64DecodeUnicode(base64)}
      </body>
    </html>
  `);

  return `data:text/html,${content}`;
}

export function printerPdfBase64ToDataPdfContent(base64) {
  if (!base64) return null;
  return `data:application/pdf;base64,${base64}`;
}
