
import {
  defineComponent,
  watch,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useOnlineDetection} from '@/Helpers/onlineDetection';
import {
  WorkflowStepSafeBagScanDeliveryList,
} from '@/Modules/Workflow/Step/StepSafeBagScanDeliveryList/WorkflowStepSafeBagScanDeliveryList';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  name: 'StepSafeBagScanDeliveryList',
  components: {PhIcon, WorkflowTransitions, FormInput, HLInput, HLStep},
  setup() {
    const stepType = WorkflowStepTypes.SafeBagScanDeliveryList;

    const {online} = useOnlineDetection();
    const {workflowStep} = useStepTools<WorkflowStepSafeBagScanDeliveryList>(stepType);

    watch(() => online.value, (newVal, oldVal) => {
      if (!newVal && !workflowStep.value.manualMode) {
        workflowStep.value.manualMode = true;
      }
    }, {immediate: true});

    return {
      online,
      stepType,
      WorkflowStepField,
    };
  },
});
