import {markRaw} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {
  api3rdPartyKorunkaValidate,
  api3rdPartyV2KorunkaValidate,
  apiSearch,
} from '@/Model/Action';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {DocumentItemDto} from '@/Model/Entity';
import ValidateSessionCommand from '@/Model/Entity/ValidateSessionCommand';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import KorunkaCheckRequestDto from '@/Model/Entity/KorunkaCheckRequestDto';
import {
  isKorunkaVersionV1,
  isKorunkaVersionV2,
  throwUnsupportedKorunkaVersionError,
} from '../../../../Helpers/korunka';

export class WorkflowStepApiKorunkaLotteryGetPayoutArticle extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiKorunkaLotteryGetPayoutArticle;
  }

  get type() {
    return WorkflowStepApiKorunkaLotteryGetPayoutArticle.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get verificationCode() {
    return this.data[WorkflowStepField.code];
  }

  async getPayoutArticle(): Promise<DocumentItemDto> {
    const entity = this.entity as KorunkaLotteryTicketDtoCustom;

    const code = this.korunkaConfiguration
      ?.payoutArticlesByGameType
      ?.[entity.ticket.game.value] ?? null;

    if (!code) {
      return null;
    }

    const {documentItems} = sanitizeApiSearch(
      await apiSearch({params: {code}}),
    );

    if (documentItems.length === 0) {
      return null;
    }

    return documentItems[0];
  }

  async beforeEnter() {
    if (!this.isTicketEntity(this.entity)) {
      return;
    }

    this.entity.verificationCode = this.verificationCode;

    const registerStore = useRegisterStore();

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const envelopeFromContext = KorunkaCheckRequestDto.ensureEnvelopeFromContext(registerStore.sellDocument.value);

      const validateSessionCommand = new ValidateSessionCommand({
        request: envelopeFromContext,
      });

      KorunkaCheckRequestDto.processTicket(envelopeFromContext, this.entity);

      if (isKorunkaVersionV1()) {
        await api3rdPartyKorunkaValidate({
          input: validateSessionCommand,
        });
      } else if (isKorunkaVersionV2()) {
        await api3rdPartyV2KorunkaValidate({
          input: validateSessionCommand,
        });
      } else {
        throwUnsupportedKorunkaVersionError();
      }


      const payoutArticle = await this.getPayoutArticle();

      if (!payoutArticle) {
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        return;
      }

      const articleBucketKey = this.saveBucket(payoutArticle.toJson());

      this.dataSetter(WorkflowStepField.articleBucketKey, () => articleBucketKey);

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
