
import {defineComponent} from 'vue';
import CaretNavigator from './CaretNavigator.vue';
import FormInputMixin from '@/Components/FormInputMixin.vue';

export default defineComponent({
  name: 'FormInputFloating',
  components: {
    CaretNavigator,
  },
  mixins: [FormInputMixin],
  props: {
    inputClass: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
});
