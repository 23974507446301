import {nextTick, ref} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepUIModes,
} from '../../types';
import {emitToTarget} from '@/Helpers/syncedStore';
import {
  deserializeKorunkaEntity,
  KorunkaEntities,
  serializeKorunkaEntity,
} from '@/Helpers/korunka';
import {EntitySerialization} from '@/Helpers/entityPersist';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {DocumentItemDto} from '@/Model/Entity';
import {guid} from '@/Helpers/guid';
import {createKorunkaStepReadyMessage} from '@/tests/e2e/helpers/korunka';
import {emitTestEvent} from '@/Helpers/testEvent';
import {isEmpty} from 'lodash-es';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';

const STEP_MODEL = 'model';

export const workflowStepMixinKorunka = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinKorunka extends (superClass as MixinBase<WorkflowStep>) {
    get stepUIMode() {
      return WorkflowStepUIModes.none;
    }

    get gameComponent() {
      throw new Error('Implement in inherited class');
    }

    get korunkaType(): KorunkaEntityTypes {
      return this.getFieldValue(WorkflowStepField.korunkaType, this.params.korunkaType ?? this.step.korunkaType);
    }

    get messages(): Record<string, string> {
      return this?.step?.messages;
    }

    get articleBucketKey() {
      return this.getFieldValue(WorkflowStepField.articleBucketKey, this.params.articleBucketKey);
    }

    get article() {
      const articleData = this.getBucket(this.articleBucketKey);

      if (articleData) {
        return new DocumentItemDto(articleData);
      }

      return null;
    }

    get entityBucketKey() {
      const value = this.getFieldValue(WorkflowStepField.entityBucketKey, this.params.entityBucketKey);
      return value;
    }

    get entity(): KorunkaEntities {
      const model: EntitySerialization<KorunkaEntityTypes> = this.getFieldValue(STEP_MODEL, null);

      if (!model) {
        return null;
      }

      return deserializeKorunkaEntity(model);
    }

    set entity(value: KorunkaEntities) {
      this.dataSetter(STEP_MODEL, () => serializeKorunkaEntity(value));
      this.syncEntityToCustomerDisplay();
    }

    get numbersModalIsOpen() {
      return this.getFieldValue('numbersModalOpen', false);
    }

    setNumbersModalIsOpen(value) {
      this.dataSetter('numbersModalOpen', () => value);
      emitToTarget('Korunka', 'numbersModalOpen', value);
    }

    get customerDisplayMessages() {
      return this.getFieldValue(WorkflowStepField.customerDisplayMessages, this.step?.customerDisplayMessages);
    }

    ensureWorkflowEntity(
      options: EntitySerialization<KorunkaEntityTypes> = this.getBucket(this.entityBucketKey) ?? {
        entityType: this.korunkaType,
        entity: {
          id: guid(),
        },
      },
    ) {
      if (this.entity) {
        return;
      }

      if (!options?.entityType) {
        throw new Error(`No flow!`);
      }

      this.entity = deserializeKorunkaEntity(options);
      this.dataSetter(WorkflowStepField.korunkaType, () => this.korunkaType);
      this.dataSetter(WorkflowStepField.customerDisplayMessages, () => this.customerDisplayMessages);
    }

    ensureCustomerDisplayKorunkaPage() {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'korunka-default'});
    }

    async syncEntityToCustomerDisplay() {
      emitToTarget('Korunka', 'entity', this.entity.toJson());
    }

    async syncBasicStepStateToCustomerDisplay() {
      emitToTarget('Korunka', 'isSynced', false);
      await nextTick();

      emitToTarget('Korunka', 'stepType', this.type);
      emitToTarget('Korunka', 'korunkaType', this.korunkaType);
      emitToTarget('Korunka', 'messages', this.customerDisplayMessages);
      emitToTarget('Korunka', 'isSynced', true);
      emitToTarget('Korunka', 'isMultipleTickets', this.isScanResultEntity(this.entity));
    }

    async calculateEntityPossibleWin() {
      try {
        if (
          this.isTicketEntity(this.entity) ||
          this.isCardEntity(this.entity) ||
          this.isScanTicketEntity(this.entity) ||
          this.isPreparedTicketEntity(this.entity)
        ) return false;

        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

        return await this.entity.calculateMaxPossibleWin();
      } catch (e) {
        console.error(e);
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
        throw e;
      } finally {
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    }

    async afterEnter(): Promise<void> {
      // @ts-expect-error
      // this.type can be any workflow type, we could narrow down the type to only korunka steps
      emitTestEvent(createKorunkaStepReadyMessage(this.type, this.korunkaType));
    }

    get isRepeatBetTicket() {
      if (
        this.isTicketEntity(this.entity) ||
        this.isCardEntity(this.entity) ||
        this.isScanTicketEntity(this.entity) ||
        this.isPreparedTicketEntity(this.entity) ||
        this.isScanResultEntity(this.entity)
      ) return false;

      return this.entity.isRepeatBetTicket && !isEmpty(this.entity.repeatTicketIds);
    }

    get isTicketFromScan() {
      if (
        this.isTicketEntity(this.entity) ||
        this.isCardEntity(this.entity) ||
        this.isScanTicketEntity(this.entity) ||
        this.isPreparedTicketEntity(this.entity)
      ) return false;

      return this.entity.isFromScan;
    }

    get isPrepared() {
      if (
        this.isTicketEntity(this.entity) ||
        this.isCardEntity(this.entity) ||
        this.isScanTicketEntity(this.entity) ||
        this.isPreparedTicketEntity(this.entity) ||
        this.isScanResultEntity(this.entity)
      ) return false;

      return this.entity.isPrepared;
    }

    get korunkaConfiguration() {
      const configurationStore = useConfigurationStore();

      return configurationStore.configuration.value?.features?.korunka ?? {};
    }

    isTicketEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom').default;
    }

    isCardEntity(entity): entity is import('@/Model/Entity/custom/KorunkaLotteryCardDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryCardDtoCustom').default;
    }

    isScanTicketEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryScanTicketDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryScanTicketDtoCustom').default;
    }

    isPreparedTicketEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryPreparedTicketDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryPreparedTicketDtoCustom').default;
    }

    isScanResultEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom').default;
    }

    isGameSestkaEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom').default;
    }

    isGameDvacetZa20Entity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryDvacetZa20DtoCustom').default;
    }

    isGameHopTropEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom').default;
    }

    isGameKombiEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryKombiDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryKombiDtoCustom').default;
    }

    isGameKombiPlusEntity(entity):
      entity is import('@/Model/Entity/custom/KorunkaLotteryKombiPlusDtoCustom').default {
      return entity instanceof require('@/Model/Entity/custom/KorunkaLotteryKombiPlusDtoCustom').default;
    }
  };


  return Derived as MergeCtor<typeof Derived, TBase>;
};
