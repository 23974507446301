
import {
  computed,
  defineComponent,
} from 'vue';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useStepTools} from '../StepTools';
import {WorkflowStepKorunkaLotteryTicketResult} from './WorkflowStepKorunkaLotteryTicketResult';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ModalNumbers from './Components/ModalNumbers.vue';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import {getLetterFromIndex} from '@/Helpers/alphabetIndex';

export default defineComponent({
  name: 'StepKorunkaTicketResult',
  components: {
    ModalNumbers,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaTicketResult;

    const configurationStore = useConfigurationStore();
    const {currencyFormat: baseCurrencyFormat, dateFormat} = useFilters();

    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryTicketResult>(stepType);

    const entity = computed(() => workflowStep.value.entity as KorunkaLotteryTicketDtoCustom);

    const hasWinningNumbers = computed(() => (entity.value?.ticket?.draw?.drawNumbers ?? []).length > 0);

    const winningNumbers = computed(() => entity.value.ticket.draw.drawNumbers.join(', '));

    const canBetAgain = computed(() => !entity.value.actions.canCancel || !entity.value.actions.canPayOut);

    const badge = computed(() => {
      if (entity.value.isWon || entity.value.isWonWithPayoutAmountExceeded) {
        return {
          class: 'bg-success text-basic-lightest',
          label: workflowStep.value.messages.ticketStatusWon,
        };
      } else if (entity.value.isPaidOut) {
        return {
          class: 'bg-primary text-basic-lightest',
          label: workflowStep.value.messages.ticketStatusIsPaidOut,
        };
      } else if (entity.value.isLoss) {
        return {
          class: 'bg-danger text-basic-lightest',
          label: workflowStep.value.messages.ticketStatusLoss,
        };
      } else if (entity.value.isCanceled || entity.value.isPreCanceled) {
        return {
          class: 'bg-basic-darkest text-white',
          label: workflowStep.value.messages.ticketStatusCanceled,
        };
      }


      return {
        class: 'bg-warning text-basic-lightest',
        label: workflowStep.value.messages.ticketStatusNotEvaluated,
      };
    });

    const modalNumbersTitle = computed(() => {
      if (
        !entity.value.isWon ||
        !entity.value.isPaidOut ||
        !entity.value.isLoss ||
        !entity.value.isCanceled
      ) {
        return workflowStep.value.messages.modalBetNumbersTitle;
      }

      return workflowStep.value.messages.modalNumbersTitle;
    });

    const openNumbersModal = () => {
      workflowStep.value.setNumbersModalIsOpen(true);
    };

    const closeNumbersModal = () => {
      workflowStep.value.setNumbersModalIsOpen(false);
    };

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    const isWinningNumber = (number: number) => {
      return entity.value.ticket.draw.drawNumbers.includes(number);
    };

    const onPayout = () => {
      workflowStep.value.onPayout();
    };

    const onCancelTicket = () => {
      workflowStep.value.onCancelTicket();
    };

    const onRepeatBetTicket = () => {
      workflowStep.value.onRepeatBetTicket();
    };

    const kombiNumberRowGridTemplateColumns = (numbersRow: number[]) => {
      const {length} = numbersRow;
      const cols = length <= 8 ? length : Math.ceil(length / 2);
      return `grid-template-columns: repeat(${cols}, minmax(0, 1fr));`;
    };

    const kombiPlusNumberRowGridTemplateColumns = (numbersRow: number[]) => {
      const {length} = numbersRow;
      const cols = length + 1;
      return `grid-template-columns: 1rem repeat(${cols}, minmax(0, 1fr));`;
    };

    return {
      stepType,
      workflowStep,
      ticket: entity,
      canBetAgain,
      badge,
      winningNumbers,
      isWinningNumber,
      dateFormat,
      currencyFormat,
      openNumbersModal,
      closeNumbersModal,
      onPayout,
      onCancelTicket,
      onRepeatBetTicket,
      hasWinningNumbers,
      getLetterFromIndex,
      kombiNumberRowGridTemplateColumns,
      kombiPlusNumberRowGridTemplateColumns,
      modalNumbersTitle,
    };
  },
});
