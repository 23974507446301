export enum QuickCallDisplayWhen {
  Receipt = 'Receipt',
  Payment = 'Payment',
}

export enum QuickCallTypes {
  Submenu = 'Submenu',
  Article = 'Article',
  Payment = 'Payment',
  BurningPoints = 'BurningPoints',
  StornoReason = 'StornoReason',
  CustomerLoyaltyPoints = 'CustomerLoyaltyPoints',
  PrintTemplateCodeSecondary = 'PrintTemplateCodeSecondary',
  EnterCustomData = 'EnterCustomData',
  EnterDelayedSellData = 'EnterDelayedSellData',
  CashQuickPick = 'CashQuickPick',
  OpenWorkflow = 'OpenWorkflow',
  FinishReceipt = 'FinishReceipt',
}

export enum QuickCallPanelPriority {
  PendingPayment = 'PendingPayment',
  QuickCall = 'QuickCall',
  InfoMessage = 'InfoMessage',
  GuidedSelling = 'GuidedSelling',
  CustomerFeaturedAttributes = 'CustomerFeaturedAttributes',
  ContextualProductRecommendations = 'ContextualProductRecommendations',
}
