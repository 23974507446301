import {
  isNil,
  map,
  reject,
} from 'lodash-es';
import {AxiosError} from 'axios';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFilters} from '@/Helpers/filters';

export type ErrorLikeString = string;
export type ErrorLikeObject = {
  code: string
  title: string
  instructions: string
};

export type ErrorLike = ErrorLikeString | Array<ErrorLikeString> | ErrorLikeObject | Array<ErrorLikeObject>

export const useErrorParser = () => {
  const configurationStore = useConfigurationStore();
  const {translatedString} = useFilters();

  return (errorLike: ErrorLike) => {
    return reject(
      map([].concat(errorLike ?? []), (error: ErrorLikeString | ErrorLikeObject) => {
        if (typeof error === 'string') {
          const name = configurationStore.configuration.value?.help?.[error]?.name;
          return {
            message: name ? translatedString(name) : error,
            toString() {
              return this.message;
            },
          };
        }

        const name = configurationStore.configuration.value?.help?.[error.code]?.name;

        return {
          ...error,
          message: name ? translatedString(name) : [error.title, error.instructions].filter(Boolean).join(' - '),
          toString() {
            return this.message;
          },
        };
      }),
      ({message}) => isNil(message),
    );
  };
};

export const isAxiosErrorGuard = (e): e is AxiosError => {
  return !!e?.isAxiosError;
};

export const seekApiErrors = (e: any, {messageAsFallback = false} = {}): Array<ErrorLikeObject> => {
  if (e?.__CANCEL__) {
    return [e.message];
  }

  if (!isAxiosErrorGuard(e)) return [];

  if (messageAsFallback) {
    return e?.response?.data?.errors ?? e?.response?.data?.value?.errors ?? [e.message] ?? [];
  }

  return e?.response?.data?.errors ?? e?.response?.data?.value?.errors ?? [];
};
