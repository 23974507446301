import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Transaction from '../Transaction';
import {DateTimeType} from '@designeo/apibundle-js';


export const TransactionChangeHistoryPropDef = {
  id: {
    type: new StringType(),
  },
  transaction: {
    type: new EntityType(Transaction),
  },
  transactionId: {
    type: new StringType(),
  },
  resultJsonData: {
    type: new StringType(),
  },
  createdAt: {
    type: new DateTimeType(),
  },
};
export default class GeneratedTransactionChangeHistory extends createEntity(TransactionChangeHistoryPropDef) {}
