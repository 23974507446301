import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "grid text-sm",
  style: {"grid-template-columns":"70% 10% 20%"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-bold text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_test = _resolveDirective("test")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.article.description), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["text-right", {'text-danger': !Number.isInteger(_ctx.quantity)}])
    }, [
      (_ctx.correctionPrice !== 0 && !_ctx.article.isTypeService)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.quantity) + " " + _toDisplayString(_ctx.workflowStep.step.quantityLabel), 1))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currencyFormat(_ctx.correctionPrice, _ctx.configurationStore.configuration.value.general.localCurrency)), 1)
  ], 512)), [
    [
      _directive_test,
      _ctx.article.internalNumber,
      void 0,
      { articleInternalNumber: true }
    ],
    [
      _directive_test,
      _ctx.correctionPrice,
      void 0,
      { articleCorrectionPrice: true }
    ],
    [
      _directive_test,
      _ctx.quantity,
      void 0,
      { articleQuantity: true }
    ]
  ])
}