import { resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_test = _resolveDirective("test")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentByStepType), {
    key: `${_ctx.workflowCode}_${_ctx.stepType}`,
    stepType: _ctx.stepType
  }, null, 8, ["stepType"])), [
    [
      _directive_test,
      _ctx.stepType,
      void 0,
      { stepType: true }
    ]
  ])
}