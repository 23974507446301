import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';

export class WorkflowStepKorunkaLotteryCardOverview extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaCardOverview;
  }

  get type() {
    return WorkflowStepKorunkaLotteryCardOverview.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryCardOverview.vue').default);
  }

  get cardNumber(): string {
    return this.getFieldValue(WorkflowStepField.cardNumber, false);
  }

  async beforeContinue() {
    const registerStore = useRegisterStore();

    if (this.isCardEntity(this.entity)) {
      registerStore.sellDocument.value.setKorunkaCard(this.entity.cardNumber);
    }
  }

  async beforeEnter() {
    await this.syncBasicStepStateToCustomerDisplay();
    await this.syncEntityToCustomerDisplay();
  }

  get transitions() {
    return {};
  }
}
