
import {
  computed,
  defineComponent,
} from 'vue';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import InputBuffer from '@/Components/InputBuffer.vue';
import {HLScrollIntoView} from '@/Components/HLScrollIntoView';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepEnterLotteryCategoryWithArticles} from './WorkflowStepEnterLotteryCategoryWithArticles';
import {useFilters} from '@/Helpers/filters';
import {fixDecimals} from '@/Helpers/math';
import {map, some} from 'lodash-es';

export default defineComponent({
  name: 'StepEnterLotteryCategoryWithArticles',
  components: {
    FormInput,
    HLStep,
    WorkflowTransitions,
    InputBuffer,
    HLInput,
    HLScrollIntoView,
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterLotteryCategoryWithArticles;

    const {interpolate} = useFilters();

    const {workflowStep, isActiveField} = useStepTools<WorkflowStepEnterLotteryCategoryWithArticles>(stepType);

    const category = computed(() => workflowStep.value.category);

    const hasUnresolvedArticles = computed(() => workflowStep.value.hasUnresolvedArticles);

    const categoryEnteredAmount = computed(() => workflowStep.value.categoryEnteredAmount);

    const articleValuesSum = computed(() => workflowStep.value.articleValuesSum);

    const isResolvedByCategory = computed(() => workflowStep.value.isResolvedByCategory);

    const skipCategory = computed(() => workflowStep.value.skipCategory);

    const categoryCorrection = computed(() => workflowStep.value.categoryCorrection);

    const hasFilledArticles = computed(() => workflowStep.value.hasFilledArticles);

    const hasFilledAllCategoryFields = computed(() => workflowStep.value.hasFilledAllCategoryFields);

    const isActiveCategoryFieldTitle = computed(() => {
      return some(map(workflowStep.value.categoryFields, (field) => isActiveField(field)));
    });

    const transitionCanGoNext = computed(() => {
      return skipCategory.value ? true : hasFilledAllCategoryFields.value && !workflowStep.value.hasValidationError;
    });

    const articleFieldsGroupedByInternalNumber = computed(() => {
      return workflowStep.value.articleFieldsGroupedByInternalNumber;
    });

    const isActiveArticleFieldTitle = (internalNumber) => {
      const articleFields = articleFieldsGroupedByInternalNumber.value[internalNumber];

      return some(map(articleFields, (field) => isActiveField(field)));
    };

    const isLastRowArticleField = (internalNumber, field) => {
      const {length, [length - 1]: last} = articleFieldsGroupedByInternalNumber.value[internalNumber];

      return last === field;
    };

    const showArticles = computed(() => {
      return skipCategory.value ?
        true :
        categoryEnteredAmount.value &&
        hasFilledAllCategoryFields.value &&
        categoryCorrection.value !== 0 &&
        !isResolvedByCategory.value;
    });

    const inputDisplayFilter = (value: any) => {
      const str = String(value);
      return value ? str.replace(/-/g, '') : value;
    };

    return {
      stepType,
      category,
      interpolate,
      hasUnresolvedArticles,
      categoryEnteredAmount,
      articleValuesSum,
      isResolvedByCategory,
      categoryCorrection,
      hasFilledArticles,
      inputDisplayFilter,
      skipCategory,
      showArticles,
      hasFilledAllCategoryFields,
      isActiveCategoryFieldTitle,
      isActiveArticleFieldTitle,
      isLastRowArticleField,
      transitionCanGoNext,
      fixDecimals,
    };
  },
});
