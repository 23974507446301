import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ClubType from '../ClubType';
import {NumberType} from '@designeo/apibundle-js';


export const CustomerClubDtoPropDef = {
  code: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(ClubType),
  },
  priority: {
    type: new NumberType(),
  },
};
export default class GeneratedCustomerClubDto extends createEntity(CustomerClubDtoPropDef) {}
