import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {WorkflowStepField} from '@/Modules/Workflow/types';
import {Payment} from '@/Modules/Payment/payment';
import {guid} from '@/Helpers/guid';
import {DocumentDto} from '@/Model/Entity';
import PaymentTransitionEnvelope from '@/Model/Entity/custom/PaymentTransitionEnvelope';

export interface IPaymentRequest {
  id: string,
  triggerDocument: DocumentDto['_data'],
  paymentData: ReturnType<(typeof Payment)['serialize']>
}

export const workflowStepMixinPayment = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinPayment extends (superClass as MixinBase<WorkflowStep>) {
    set paymentTransitionEnvelope(val: PaymentTransitionEnvelope) {
      this.dataSetter(WorkflowStepField.paymentTransitionEnvelope, () => val.toJson());
    }

    get paymentTransitionEnvelope(): PaymentTransitionEnvelope {
      return new PaymentTransitionEnvelope(this.getFieldValue(WorkflowStepField.paymentTransitionEnvelope, {}));
    }

    createPaymentRequest(paymentResolver: Payment): IPaymentRequest['id'] {
      const id = guid();

      this.dataSetter(
        WorkflowStepField.paymentRequests,
        () => [
          ...this.paymentRequests,
          <IPaymentRequest>{
            id,
            triggerDocument: null,
            paymentData: Payment.serialize(paymentResolver),
          },
        ],
      );

      return id;
    }

    get paymentRequests(): IPaymentRequest[] {
      return this.getFieldValue(
        WorkflowStepField.paymentRequests,
        [],
      );
    }

    get nextUnfinishedPaymentRequest(): IPaymentRequest {
      return this.paymentRequests.find((paymentRequest) => {
        return !Payment.deserialize(paymentRequest.paymentData).isResolved;
      });
    }

    get hasUnfinishedPaymentRequests(): boolean {
      return !!this.nextUnfinishedPaymentRequest;
    }

    get finishedPaymentRequests(): IPaymentRequest[] {
      return this.paymentRequests.filter((paymentRequest) => {
        const payment = Payment.deserialize(paymentRequest.paymentData);

        return payment.isResolved && payment.isSuccessful;
      });
    }

    get hasFinishedPaymentRequests(): boolean {
      return !!this.finishedPaymentRequests.length;
    }

    async modifyPaymentRequest(id, modifier: (currentPaymentRequestState: IPaymentRequest)=> IPaymentRequest) {
      const paymentRequestIndex = this.paymentRequests.findIndex(
        (paymentRequest) => paymentRequest.id === id,
      );

      if (paymentRequestIndex === -1) {
        throw new Error(`Payment request with id ${id} not found`);
      }

      this.dataSetter(
        WorkflowStepField.paymentRequests,
        (currentValue) => {
          const newPaymentRequests = [...currentValue];
          const currentPaymentRequest = newPaymentRequests[paymentRequestIndex];

          newPaymentRequests[paymentRequestIndex] = modifier(currentPaymentRequest);

          return newPaymentRequests;
        },
      );

      /**
       * Immediately persist the workflow store to prevent data loss
       */
      await this.workflowStore.persist();
    }

    paymentRequestById(id: string): IPaymentRequest {
      return this.paymentRequests.find((paymentRequest) => paymentRequest.id === id);
    }

    paymentById(id: string): Payment {
      const paymentRequest = this.paymentRequestById(id);

      if (!paymentRequest) {
        return null;
      }

      return Payment.deserialize(paymentRequest.paymentData);
    }

    paymentTriggerDocumentById(id: string): DocumentDto {
      const paymentRequest = this.paymentRequestById(id);

      if (!paymentRequest) {
        return null;
      }

      return paymentRequest.triggerDocument ? new DocumentDto(paymentRequest.triggerDocument) : null;
    }
  };

  return Derived as MergeCtor<typeof Derived, TBase>;
};
