
import {OrderDtoOrderEnvelopeDto} from '@/Model/Entity';
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<OrderDtoOrderEnvelopeDto>,
      required: true,
    },
  },
  setup(props) {
    const isVisible = computed(() => {
      if (!props.order) {
        return false;
      }

      return !props.order.data.isPaid;
    });

    return {
      isVisible,
    };
  },
});
