
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import {DocumentItemDto} from '../../../Model/Entity';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'ValueAfterDiscounts',
  props: {
    value: {
      type: Object as PropType<DocumentItemDto>,
      required: true,
    },
  },
  setup(props) {
    const {numberFormat} = useFilters();

    const valueAfterDiscounts = computed(() => {
      if (props.value.isPartOfDocument) {
        return numberFormat(props.value.valueWithoutDiscounts);
      } else {
        return numberFormat(props.value.clone().getStandaloneValueAfterDiscountsWithinContext());
      }
    });

    return {
      valueAfterDiscounts,
    };
  },
});
