
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import {WorkflowActions, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {useWorkflowStore} from '../../store/WorkflowStore';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import IconWarning from '@/Components/Icons/IconWarning.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepBTCEnterOrderPin',
  components: {WorkflowTransitions, IconWarning, HLStep},
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.BTCEnterOrderPin;

    const onCustomerBTCEnterPin = async (event: CustomEvent) => {
      try {
        await workflowStore.onEventInput({
          type: WorkflowActions.ENTER,
          value: event.detail,
        });

        broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(() => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-btc-enter-pin'});
      broadcastIO.addEventListener(BroadcastIOChannels.CUSTOMER_PIN_RESULT, onCustomerBTCEnterPin);
    });

    onBeforeUnmount(() => {
      broadcastIO.removeEventListener(BroadcastIOChannels.CUSTOMER_PIN_RESULT, onCustomerBTCEnterPin);
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
    });

    return {
      stepType,
    };
  },
});
