import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CustomerAdditionalDataDtoPropDef = {
  customerName: {
    type: new StringType(),
  },
  vat: {
    type: new StringType(),
  },
  vatId: {
    type: new StringType(),
  },
  vatAmount: {
    type: new StringType(),
  },
  street: {
    type: new StringType(),
  },
  city: {
    type: new StringType(),
  },
  zip: {
    type: new StringType(),
  },
  note: {
    type: new StringType(),
  },
};
export default class GeneratedCustomerAdditionalDataDto extends createEntity(CustomerAdditionalDataDtoPropDef) {}
