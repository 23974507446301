import {RegisterRoutesOptions} from '@/ModuleSystem';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';

export default [
  {
    path: '/login',
    name: 'login',
    meta: {authorization: false},
    component: () => import('./Login/LoginPage.vue'),
    beforeEnter: async (to, from, next) => {
      const authStore = useAuthStore();
      if (
        authStore.activePerson.value &&
        !authStore.activePerson.value.quickLogin &&
        from.path !== '/'
      ) {
        await authStore.logout();
      }

      next();
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    meta: {authorization: false},
    beforeEnter: (to, from, next) => {
      const route = (to?.redirectedFrom ?? from);
      const name = route.fullPath.startsWith('/apps/inventory') ?
        'apps-inventory-login' :
        'login';

      if (route.name === name) {
        const query = {};
        if (to.query?.fullPath) {
          query['redirect'] = encodeURIComponent(to.query.fullPath as string);
        }
        next({
          replace: true,
          name,
          query,
        });
      } else {
        next({
          replace: true,
          name,
          query: {
            redirect: encodeURIComponent(route.fullPath),
          },
        });
      }
    },
  },
  {
    path: '/quick-login-edit',
    name: 'quick-login-edit',
    component: () => import('./PeopleEdit/PeopleEditPage.vue'),
  },
  {
    path: '/pin-change',
    name: 'pin-change',
    meta: {
      onlineMandatory: true,
    },
    component: () => import('./PinChange/PinChangePage.vue'),
  },
] as RegisterRoutesOptions;
