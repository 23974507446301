import TicketValidationDtoCustom from './custom/TicketValidationDtoCustom';

export default class TicketValidationDto extends TicketValidationDtoCustom {
  get canBeSold() {
    return this.canPayOut;
  }

  get errorReason() {
    return this.errorMessage?.errorCashierMessage;
  }

  get errorReasonForCustomer() {
    return this.errorMessage?.errorCustomerMessage;
  }
}
