
import {DocumentItemDto, StockDto} from '@/Model/Entity';
import {
  defineComponent,
  PropType,
} from 'vue';
import ProductVariation from '@/Modules/Register/Modal/ProductVariation.vue';


export default defineComponent({
  name: 'WarehouseOperationGoodsVariations',
  components: {
    ProductVariation,
  },
  props: {
    products: {
      type: Array as PropType<Array<{
        item: DocumentItemDto,
        stock?: StockDto,
      }>>,
      required: true,
    },
  },
  emits: ['select', 'detail'],
});
