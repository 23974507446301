import {
  defineComponent,
  h,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue';
import {map} from 'lodash-es';
import {draf} from '@designeo/js-helpers';

export const HLPersistScroll = defineComponent({
  name: 'HLPersistScroll',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    saveEnabled: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, {emit}) {
    const element = ref(null);
    const scrollPosition = ref(null);

    watch(() => props.active, async (value) =>
      nextTick(async () => {
        await draf(null);
        if (value && element.value) {
          element.value.scrollTop = scrollPosition.value;
        }
      }), {immediate: true});

    const onScroll = (event) => {
      if (props.saveEnabled) {
        scrollPosition.value = event.target.scrollTop;
      }
    };

    onMounted(() => {
      if (element.value) {
        element.value.addEventListener('scroll', onScroll);
      }
    });

    return {
      element,
    };
  },
  render() {
    const slotData = {};
    const content = (this.$slots?.default?.(slotData) ?? []);

    if (content.length > 1) throw new Error('HLPersistScroll is build as a single child abstract component');

    return map(content, (slot) => h(slot, {ref: 'element'}));
  },
});
