import {
  createEntity,
  ObjectType,
} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import AskForCustomerCard from '../AskForCustomerCard';
import {ArrayType} from '@designeo/apibundle-js';
import {VoidType} from '@designeo/apibundle-js';
import EditableField from '../EditableField';
import Search from '../Search';
import {
  MainAttributes,
  TabContents,
} from '@/Modules/Register/Modal/ModalCustomerDetail/customerDetail';
import {CustomerFeaturedAttribute} from '@/Modules/Register/CustomerFeaturedAttributes/customerFeaturedAttribute';


export default class GeneratedCustomer extends createEntity({
  askForCustomerCard: {
    type: new EntityType(AskForCustomerCard),
  },
  contactInformationTypes: {
    type: new ArrayType(new VoidType()),
  },
  editableFields: {
    type: new ArrayType(new EntityType(EditableField)),
  },
  search: {
    type: new EntityType(Search),
  },
  detail: {
    type: new ObjectType<{
      unauthenticatedCustomerCanBeUpdated: boolean,
      mainAttributes: Array<
        {
          attribute: MainAttributes
          label?: string
        } | {
          attribute: MainAttributes.points,
          label?: string
          clubCode: string
        }
      >,
      tabs: Array<TabContents>
    }>(),
  },
  featuredAttributes: {
    type: new ArrayType(new ObjectType<CustomerFeaturedAttribute>()),
  },
}) {}
