import {emitTestEvent} from '@/Helpers/testEvent';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {markRaw} from 'vue';
import {apiSearchListFiltered, apiStockGet} from '@/Model/Action';
import {
  ArticleFilterDescriptionDto,
  ArticleListFilterDto,
  StockDto,
  StockFilterDto,
} from '@/Model/Entity';
import {workflowStepMixinBalanceStock} from '../StepMixins/WorkflowStepMixinBalanceStock';
import {
  difference,
  flow,
  map,
  reject,
  filter,
  isEmpty,
} from 'lodash-es';
import {sanitizeApiSearch} from '@/Helpers/sanitize';

export class WorkflowStepApiFetchStock extends workflowStepMixinBalanceStock(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiFetchStock;
  }

  get type() {
    return WorkflowStepApiFetchStock.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get internalNumbers(): string[] {
    // NOTE: Should never be empty or this step will fetch all articles stock
    return this.step?.internalNumbers ?? [];
  }

  async fetchStockByInternalNumbers() {
    const stockList = await apiStockGet(
      {
        input: new StockFilterDto({
          internalNumbers: this.internalNumbers,
        }),
      },
    );

    return stockList;
  }

  async searchDocumentItems(items: {internalNumber: string, batch?: string}[]) {
    const {documentItems} = sanitizeApiSearch((await apiSearchListFiltered({
      input: new ArticleListFilterDto({
        articles: map(
          items,
          (item) => new ArticleFilterDescriptionDto({
            internalNumber: item.internalNumber,
            batch: item?.batch || null,
          }).toJson(),
        ),
      }),
    })));

    return documentItems;
  }

  async fetchDocumentItems(stockItems: StockDto[]) {
    const items = stockItems.map((stockItem) => ({internalNumber: stockItem.internalNumber, batch: stockItem?.batch}));

    const searchList = await this.searchDocumentItems(items);

    const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

    return articleList;
  }

  async fetchMissingStockItems(internalNumbers: string[]) {
    const items = internalNumbers.map((internalNumber) => ({internalNumber}));

    const searchList = await this.searchDocumentItems(items);

    const articleList = reject(map(searchList, (documentItem) => documentItem.expandExpandableSet()), 'isSet');

    const stockList = articleList.map((item) => new StockDto({
      internalNumber: item.internalNumber,
      description: item?.description,
      count: 0,
      unitOfQuantityCode: item.unit,
    }));

    return stockList;
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const stockList = await this.fetchStockByInternalNumbers();

      const missingStockListInternalNumbers = flow(
        (stockItems: StockDto[]) => map(stockItems, (stockItem) => stockItem?.internalNumber),
        (stockInternalNumbers: string[]) => filter(stockInternalNumbers, Boolean),
        (stockInternalNumbers: string[]) => difference(this.internalNumbers, stockInternalNumbers),
      )(stockList);

      const missingStockItems = await this.fetchMissingStockItems(missingStockListInternalNumbers);

      this.stockList = [...stockList, ...missingStockItems];

      this.articleList = await this.fetchDocumentItems(this.stockList);

      const missingArticleListInternalNumbersWithBatch = difference(
        map(this.stockList, (stockItem) => stockItem.internalNumberWithBatch),
        map(this.articleList, (articleItem) => articleItem.internalNumberWithBatch),
      );

      if (!isEmpty(missingArticleListInternalNumbersWithBatch)) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.STOCK_FETCH_FAILED_MISSING_ITEMS,
            value: missingArticleListInternalNumbersWithBatch.join(', '),
          },
        }));
      }

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.STOCK_FETCH_FAILED,
          value: e,
        },
      }));
    } finally {
      emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:stockLoaded`);
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
