
import {defineComponent} from 'vue';
import ModalConfirmContent from '@/Components/ModalConfirmContent.vue';
import {useConfirmContent} from '@/Modules/Core/Confirm/contents/content';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export default defineComponent({
  name: 'ModalConfirmContentDefault',
  components: {ModalConfirmContent},
  setup() {
    const coreStore = useCoreStore();
    const {
      type,
      hasRejectFn,
      onReject,
      onConfirm,
    } = useConfirmContent();

    return {
      type,
      hasRejectFn,
      onReject,
      onConfirm,
      coreStore,
    };
  },
});
