import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {
  CustomerInteractionActions,
  CustomerInteractionEvents,
  CustomerInteractionInputEvent,
  CustomerInteractionState,
} from '../types';

export interface ICustomerInteractionStore {
  state: CustomerInteractionState
  interaction: {
    message: string,
    resolve: (value?: boolean)=> void,
    reject?: (reason?: any)=> void,
  },
}

const createInitState = (data?: Partial<ICustomerInteractionStore>) => ({
  state: CustomerInteractionState.CLOSED,
  interaction: null,
});

export class CustomerInteractionStore extends Store<ICustomerInteractionStore> {
  constructor() {
    super(createInitState());
  }

  transitions: {[key in CustomerInteractionState]?: {[key in CustomerInteractionActions]?: any}} = {
    [CustomerInteractionState.CLOSED]: {
      [CustomerInteractionActions.OPEN]: action(
        async (event: CustomerInteractionInputEvent<ICustomerInteractionStore['interaction']>) => {
          this.state.interaction = event.value;
          this.state.state = CustomerInteractionState.OPENED;
        },
      ),
    },
    [CustomerInteractionState.OPENED]: {
      [CustomerInteractionActions.CLOSE]: action(async (event: CustomerInteractionInputEvent<boolean>) => {
        this.state.interaction?.resolve?.(event.value ?? false);
        this.state.state = CustomerInteractionState.CLOSED;
        this.dispatchEvent(new Event(CustomerInteractionEvents.CLOSED));
      }),
    },
  }

  open = action(async (message) => {
    let resolve = null;

    await this.ensureClosedState();

    const promise = (new Promise<{canceled: boolean}>((onFulfilled, onRejected) => {
      resolve = (canceled = false) => onFulfilled({canceled});
    }))
      .then((result) => {
        this.state.interaction = null;
        return result;
      })
      .catch((err) => {
        this.state.interaction = null;
        throw err;
      });


    await this.onEventInput({
      type: CustomerInteractionActions.OPEN,
      value: {
        message,
        resolve,
      },
    });

    return Promise.resolve(promise);
  })

  ensureClosedState = action(async (canceled = false) => {
    if (this.isActive.value) {
      await this.onEventInput({
        type: CustomerInteractionActions.CLOSE,
        value: canceled,
      });
    }
  })

  close = action((canceled = false) => {
    this.ensureClosedState(canceled);
  })

  onEventInput = action(async (event: CustomerInteractionInputEvent) => {
    return await this.transitions?.[this.state.state]?.[event.type]?.(event);
  })

  isActive = getter(() => {
    return this.state.state === CustomerInteractionState.OPENED;
  })

  interaction = getter(() => {
    return this.state.interaction;
  })
}

const storeIdentifier = 'CustomerInteractionStore';

export const configureCustomerInteractionStore = createConfigureStore<typeof CustomerInteractionStore>(storeIdentifier);
export const useCustomerInteractionStore = createUseStore(CustomerInteractionStore, storeIdentifier);
