import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import InventoryGroupMetadataDto from '../InventoryGroupMetadataDto';


export const InventoryMetadataDtoPropDef = {
  shopCode: {
    type: new StringType(),
  },
  lastInventoryDate: {
    type: new DateTimeType(),
  },
  lastInventoryNumber: {
    type: new StringType(),
  },
  nextInventoryNumberOrder: {
    type: new NumberType(),
  },
  inventoryGroups: {
    type: new ArrayType(new EntityType(InventoryGroupMetadataDto)),
  },
};
export default class GeneratedInventoryMetadataDto extends createEntity(InventoryMetadataDtoPropDef) {}
