import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const CreateMessageDtoPropDef = {
  to: {
    type: new ArrayType(new StringType()),
  },
  subject: {
    type: new StringType(),
  },
  body: {
    type: new StringType(),
  },
  isSensitive: {
    type: new BooleanType(),
  },
  attachmentIds: {
    type: new ArrayType(new StringType()),
  },
};
export default class GeneratedCreateMessageDto extends createEntity(CreateMessageDtoPropDef) {}
