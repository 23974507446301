import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const BatchDtoPropDef = {
  id: {
    type: new StringType(),
  },
  text: {
    type: new StringType(),
  },
  saleValidFrom: {
    type: new DateTimeType(),
  },
  saleValidTill: {
    type: new DateTimeType(),
  },
};
export default class GeneratedBatchDto extends createEntity(BatchDtoPropDef) {}
