import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentDto from '../DocumentDto';


export const SubmitPosStateReportDtoPropDef = {
  previewOnly: {
    type: new BooleanType(),
  },
  templateCode: {
    type: new StringType(),
  },
  document: {
    type: new EntityType(DocumentDto),
  },
};
export default class GeneratedSubmitPosStateReportDto extends createEntity(SubmitPosStateReportDtoPropDef) {}
