
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';

import {useMessageStore} from '@/Modules/Apps/PosMessage/store/MessageStore';
import {useRouter} from 'vue-router';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import PhIcon from '@/Components/PhIcon.vue';

export default defineComponent({
  components: {
    PhIcon,
  },
  setup() {
    const authStore = useAuthStore();
    const messageStore = useMessageStore();
    const timeout = ref(null);
    const router = useRouter();

    const isMessagesPage = computed(() => router.currentRoute.value.name === 'apps-messages');

    const unreadMessages = computed(() => messageStore.unreadMessages.value);
    const unreadImportantMessages = computed(() => messageStore.unreadImportantMessages.value);
    const unreadSensitiveMessages = computed(() => messageStore.unreadSensitiveMessages.value);

    const refreshMessageCount = async () => {
      if (authStore.activePerson.value) {
        await messageStore.fetchUnreadMessages();
      }
    };


    const createRefreshTimeout = () => {
      clearTimeout(timeout.value);
      timeout.value = setTimeout(async () => {
        await refreshMessageCount();
        createRefreshTimeout();
      }, 60000);
    };

    onMounted(async () => {
      await refreshMessageCount();
      createRefreshTimeout();
    });

    onBeforeUnmount(() => {
      clearTimeout(timeout.value);
    });

    watch(() => authStore.activePerson.value, async (newValue, oldValue) => {
      if ((!oldValue && newValue) || (newValue?.username !== oldValue?.username)) {
        clearTimeout(timeout.value);
        await refreshMessageCount();
        createRefreshTimeout();
      }
    });

    const isVisible = computed(() => {
      if (!router.hasRoute('apps-messages')) {
        return false;
      }

      if (!authStore.isLoggedIn.value) {
        return false;
      }

      if (authStore.isStateTypeQuickLoginConfirm.value) {
        return false;
      }

      return true;
    });


    return {
      isMessagesPage,
      unreadMessages,
      unreadImportantMessages,
      unreadSensitiveMessages,
      isVisible,
    };
  },
});
