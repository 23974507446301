import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {api3rdPartyKorunkaRecognizeTicket, apiTextSearch} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {serializeKorunkaEntity} from '@/Helpers/korunka';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {invert} from 'lodash-es';
import {guid} from '@/Helpers/guid';
import KorunkaLotteryScanResultDtoCustom from '@/Model/Entity/custom/KorunkaLotteryScanResultDtoCustom';

export class WorkflowStepKorunkaLotteryScanTicket extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaScanTicket;
  }

  get type() {
    return WorkflowStepKorunkaLotteryScanTicket.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryScanTicket.vue').default);
  }

  get disabledNextStep() {
    return !this.scanFinished;
  }

  get scanFinished() {
    return this.getFieldValue(WorkflowStepField.scanFinished, false);
  }

  set scanFinished(value: boolean) {
    this.dataSetter(WorkflowStepField.scanFinished, () => value);
  }

  async onScan() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const tickets = await api3rdPartyKorunkaRecognizeTicket();

      this.entity = new KorunkaLotteryScanResultDtoCustom({
        tickets: tickets.map((ticket) => ticket.toJson()),
        id: guid(),
      });

      if (!(await this.entity.validate())) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepErrors.KORUNKA_UNUPPORTED_GAME_TYPE));
        return;
      }

      await this.entity.setup();

      const gameType = this.entity.gameType;

      const workflowCode = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByGameType
        ?.[gameType];

      if (!workflowCode) return;

      const workflowCodesByArticles = this.configurationStore.configuration
        ?.value
        ?.features
        ?.korunka
        ?.workflowCodesByArticles;

      const articlesByWorkflowCodes = invert(workflowCodesByArticles);

      const articleInternalNumber = articlesByWorkflowCodes?.[workflowCode];

      const [product] = sanitizeApiSearch(await apiTextSearch({params: {text: articleInternalNumber}})).documentItems;

      if (!product) return;

      const articleBucketKey = this.saveBucket(product.toJson());
      const entityBucketKey = this.saveBucket(serializeKorunkaEntity(this.entity));

      this.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {
          detail: {
            workflowCode: workflowCode,
            returnToCurrentWorkflow: false,
            params: {
              korunkaType: gameType,
              entityBucketKey,
              articleBucketKey,
            },
          },
        }),
      );

      this.scanFinished = true;
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
