import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerSex from '../CustomerSex';


export const UpdateCustomerDtoPropDef = {
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  yearOfBirth: {
    type: new NumberType(),
  },
  dayOfBirth: {
    type: new NumberType(),
  },
  monthOfBirth: {
    type: new NumberType(),
  },
  sex: {
    type: new EntityType(CustomerSex),
  },
};
export default class GeneratedUpdateCustomerDto extends createEntity(UpdateCustomerDtoPropDef) {}
