/* eslint-disable max-len */
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {ResultDto, TranslatedString} from '@/Model/Entity';
import {b64DecodeUnicode} from '@/Helpers/base64';
import {map} from 'lodash-es';
import DialogDto from '../Model/Entity/DialogDto';

type ResponseCode = {
  code: string,
  notPrinted: boolean,
  callSupport: boolean,
  noop: boolean,
  /**
   * if some response has isTerminated: true => it quits the process mostly without notification!
   * !!!special behave for payments!!!
   */
  isTerminated: boolean,
  isUnknown: boolean,
  magneticCardSignDialog: boolean,
  finished: boolean,
}

const responseCode = (
  code,
  {
    notPrinted = false,
    callSupport = false,
    noop = false,
    isTerminated = false,
    isUnknown = false,
    magneticCardSignDialog = false,
    finished = false,
  } = {},
) => ({
  [code]: <ResponseCode>{
    code,
    notPrinted,
    callSupport,
    noop,
    isUnknown,
    isTerminated,
    magneticCardSignDialog,
    finished,
  },
});

export enum ResponseCodes {
  ResponseCodeUnknown = '-999',
  ActionInterrupted = '-1',
  Ok = '0',
  NoConnection = '1000',
  CommunicationError = '1029',
  NoValidRequest = '2000',
  DocumentAlreadyPrinted = '2002',
  NotFoundInQueue = '2003',
  PrinterDenied = '2007',
  PrinterBusy = '2006',
  VATValuesError = '2009',
  PrinterNotDefined = '2017',
  PrinterPaperOut = '2018',
  PrinterCutterError = '2019',
  PrinterCoverOpen = '2020',
  PrinterOtherError = '2021',
  ApplicationException = '2031',
  PrinterQueueUnfinishedPrints = '2033',
  PrinterQueueStopped = '2034',
  NoValidPrinter = '2052',
  DocumentCrossCheckError = '2058',
  NoValidTransaction = '2060',
  VATCalculationError = '2062',
  PaymentPreceding = '2100',
  PaymentNotAccepted = '2101',
  PaymentInterrupted = '2102',
  NotAbleToCheckResult = '2103',
  PrinterInWrongState = '2104',
  PaymentCardLimited = '2105',
  PaymentProceedAction = '2107',
  PaymentWrongPIN = '2108',
  PaymentWrongCurrency = '2109',
  TerminalCommunicationError = '2110',
  PaymentTransactionDeclined = '2111',
  PaymentLimitExceeded = '2112',
  PaymentHoldBackCard = '2113',
  PaymentLackOfMoneyOnAccount = '2117',
  PaymentInvalidTransaction = '2118',
  TerminalDisconnected = '2142',
  PrinterDisconnected = '2143',
  PrintoutProblem = '2300',
  CloseDayNeeded = '2301',
  PressButtonNeeded = '2302',
  PrinterError = '3000',
  PrinterOecNotConnected = '3001',
  EetOfflineOrError = '2305',
  EetContentWithoutCertificate = '2144',
  ChduError = '2400',
  CertificateError = '2402',
  IdentificationDataError = '2403',
  CertificateDataError = '2404',
  EetBlocked = '2405',
  PrinterIsStoppingSending = '2406',
  EkasaStateError = '2407',
}

/**
 * Transfer this map to configuration
 */
export const responseCodesSettings = {
  ...responseCode(ResponseCodes.ResponseCodeUnknown, {noop: true, isUnknown: true}),
  ...responseCode(ResponseCodes.ActionInterrupted, {isTerminated: true}),
  ...responseCode(ResponseCodes.Ok, {finished: true}),
  ...responseCode(ResponseCodes.NoConnection, {notPrinted: true}),
  ...responseCode(ResponseCodes.CommunicationError, {callSupport: true}),
  ...responseCode(ResponseCodes.NoValidRequest, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.DocumentAlreadyPrinted, {noop: true}),
  ...responseCode(ResponseCodes.NotFoundInQueue, {callSupport: true}),
  ...responseCode(ResponseCodes.PrinterDenied, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.PrinterBusy, {callSupport: true}),
  ...responseCode(ResponseCodes.VATValuesError, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.PrinterNotDefined, {notPrinted: true}),
  ...responseCode(ResponseCodes.PrinterPaperOut, {notPrinted: true}),
  ...responseCode(ResponseCodes.PrinterCutterError, {callSupport: true}),
  ...responseCode(ResponseCodes.PrinterCoverOpen, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.PrinterOtherError, {callSupport: true}),
  ...responseCode(ResponseCodes.ApplicationException, {callSupport: true}),
  ...responseCode(ResponseCodes.NoValidPrinter, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.DocumentCrossCheckError, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.NoValidTransaction),
  ...responseCode(ResponseCodes.VATCalculationError, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.PaymentPreceding, {noop: true}),
  ...responseCode(ResponseCodes.PaymentNotAccepted),
  ...responseCode(ResponseCodes.PaymentInterrupted),
  ...responseCode(ResponseCodes.NotAbleToCheckResult, {callSupport: true}),
  ...responseCode(ResponseCodes.PrinterInWrongState, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.PaymentCardLimited),
  ...responseCode(ResponseCodes.PaymentProceedAction, {magneticCardSignDialog: true}),
  ...responseCode(ResponseCodes.PaymentWrongPIN),
  ...responseCode(ResponseCodes.PaymentWrongCurrency),
  ...responseCode(ResponseCodes.TerminalCommunicationError),
  ...responseCode(ResponseCodes.PaymentTransactionDeclined),
  ...responseCode(ResponseCodes.PaymentLimitExceeded),
  ...responseCode(ResponseCodes.PaymentHoldBackCard),
  ...responseCode(ResponseCodes.PaymentLackOfMoneyOnAccount),
  ...responseCode(ResponseCodes.PaymentInvalidTransaction),
  ...responseCode(ResponseCodes.TerminalDisconnected),
  ...responseCode(ResponseCodes.PrinterDisconnected),
  ...responseCode(ResponseCodes.PrintoutProblem, {callSupport: true}),
  ...responseCode(ResponseCodes.CloseDayNeeded, {notPrinted: true}),
  ...responseCode(ResponseCodes.PressButtonNeeded, {notPrinted: true}),
  ...responseCode(ResponseCodes.PrinterError, {callSupport: true}),
  ...responseCode(ResponseCodes.PrinterOecNotConnected, {callSupport: true}),
  ...responseCode(ResponseCodes.EetOfflineOrError, {callSupport: true}),
  ...responseCode(ResponseCodes.EetContentWithoutCertificate, {notPrinted: true, callSupport: true}),
  ...responseCode(ResponseCodes.ChduError, {notPrinted: true}),
  ...responseCode(ResponseCodes.CertificateError, {notPrinted: true}),
  ...responseCode(ResponseCodes.IdentificationDataError, {notPrinted: true}),
  ...responseCode(ResponseCodes.CertificateDataError, {notPrinted: true}),
  ...responseCode(ResponseCodes.EetBlocked, {notPrinted: true}),
  ...responseCode(ResponseCodes.PrinterIsStoppingSending, {notPrinted: true}),
  ...responseCode(ResponseCodes.EkasaStateError, {notPrinted: true}),
  ...responseCode(ResponseCodes.PrinterQueueStopped, {callSupport: true}),
  ...responseCode(ResponseCodes.PrinterQueueUnfinishedPrints, {callSupport: true}),

};

export type ResponseCodeSettings = {[key: string]: ResponseCode}[string];

/**
 * TODO remove normalize to Result['_data'] after refactoring payments in register
 */
export const getResponseCodeConfiguration = (result: ResultDto | ResultDto['_data']): ResponseCodeSettings & {
  icon: string,
  instructions: TranslatedString['_data'],
  isCloseable: boolean,
  isRepeatable: boolean,
  isRecoverable: boolean,
  isResolvableToPdf: boolean,
  title: TranslatedString['_data'],
  content: Array<{
    dialogItems: Array<{code: string, text: string}>
    displayBase64: string
    timeoutInSeconds: number
  }>,
  displayBase64: string,
  operation: string,
  hasDialog: boolean,
  originalResult?: ResultDto['_data']
} => {
  const configurationStore = useConfigurationStore();

  result = (result instanceof ResultDto ? result.clone().toJson() : result) as ResultDto['_data'];

  const errors = configurationStore.configuration.value?.errors;

  const responseCodeSettings = responseCodesSettings[result.statusCode] ??
    responseCodesSettings[ResponseCodes.ResponseCodeUnknown];

  if (responseCodeSettings.isUnknown) {
    return {
      content: [],
      displayBase64: '',
      operation: result.operation,
      hasDialog: true,
      ...responseCodeSettings,
      isCloseable: false,
      isRepeatable: false,
      isRecoverable: true,
      isResolvableToPdf: false,
      icon: 'warning',
      instructions: errors?.[ResponseCodes.ResponseCodeUnknown]?.instructions ?? null,
      title: errors?.[ResponseCodes.ResponseCodeUnknown]?.title ?? null,
      originalResult: result,
    };
  }

  return {
    content: map(result.dialog ?? [], (dialog: DialogDto['_data']) => ({
      ...dialog,
      displayBase64: b64DecodeUnicode(dialog.displayBase64 ?? ''),
    })),
    displayBase64: b64DecodeUnicode(result.displayBase64 ?? ''),
    operation: result.operation,
    hasDialog: !responseCodeSettings.noop && !responseCodeSettings.finished,
    ...responseCodeSettings,
    isCloseable: errors?.[result.statusCode]?.isCloseable ?? true,
    isRepeatable: errors?.[result.statusCode]?.isRepeatable ?? false,
    isRecoverable: errors?.[result.statusCode]?.isRecoverable ?? false,
    isResolvableToPdf: errors?.[result.statusCode]?.isResolvableToPdf ?? false,
    icon: errors?.[result.statusCode]?.icon ?? null,
    instructions: errors?.[result.statusCode]?.instructions ?? null,
    title: errors?.[result.statusCode]?.title ?? null,
    originalResult: result,
  };
};
