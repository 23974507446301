import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CashDataDto from '../CashDataDto';


export const CashStateDtoPropDef = {
  czk: {
    type: new EntityType(CashDataDto),
  },
  eur: {
    type: new EntityType(CashDataDto),
  },
};
export default class GeneratedCashStateDto extends createEntity(CashStateDtoPropDef) {}
