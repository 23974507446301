import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ActionStyle from '../ActionStyle';
import TranslatedString from '../TranslatedString';


export default class GeneratedArticleLabel extends createEntity({
  style: {
    type: new EntityType(ActionStyle),
  },
  value: {
    type: new EntityType(TranslatedString),
  },
}) {}
