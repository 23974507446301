import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TicketPartnerResourceV2 from '../TicketPartnerResourceV2';
import Actions from '../Actions';


export const TicketDetailPartnerResourceV2PropDef = {
  ticket: {
    type: new EntityType(TicketPartnerResourceV2),
  },
  actions: {
    type: new EntityType(Actions),
  },
};
export default class GeneratedTicketDetailPartnerResourceV2 extends createEntity(TicketDetailPartnerResourceV2PropDef) {}
