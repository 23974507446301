import {AbstractPromoFlow, createNewReactive} from './AbstractPromoFlow';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {RegisterState} from '@/Modules/Register/types';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {PromoInteractionFlowTypes} from '@/Modules/Register/PromoInteractionFlow/index';
import {action} from '@designeo/vue-helpers/src/index';
import {PromotionMetaType, promotionByType} from '@designeo/pos-promotion-engine';
import {apiCountersCreate} from '@/Model/Action';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import CreateCounterDto from '@/Model/Entity/CreateCounterDto';
import {GecoGame, GecoGameAvailable} from '@designeo/pos-promotion-engine/src/blocks/types';
import HelpTypes from '@/constants/helpTypes';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';
import {reactive} from 'vue';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';


export interface GecoGameState {
  state: GameState,
  game: GecoGameAvailable,
  result?: GecoGame,
}

export enum GameState {
  QUESTION = 'Question',
  WAITING_FOR_CONFIRMATION = 'WaitingForConfirmation',
  LOADING_RESULT = 'LoadingResult',
  SHOWING_RESULT = 'ShowingResult',
  FAILED = 'Failed',
  FINISHED = 'Finished',
}

export class GecoGameFlow extends AbstractPromoFlow {
  public gameState: GecoGameState;

  constructor(
    public nextState: RegisterState,
    private promoCode: string,
    private counterName: string,
  ) {
    super(nextState);
    this.gameState = reactive({
      state: GameState.QUESTION,
      game: null,
      result: null,
    });
  }

  static new = createNewReactive<GecoGameFlow>();

  get isConfirmed() {
    return this.gameState.state !== GameState.QUESTION;
  }

  get availableGecoGame() {
    const {sellDocument} = useRegisterStore();

    // @ts-ignore
    const availableGecoGames = promotionByType(sellDocument.value, PromotionMetaType.GECO_GAME_AVAILABLE);
    return availableGecoGames.find(({counterName, promoCode}) => {
      return counterName === this.counterName && promoCode === this.promoCode;
    });
  }

  get gecoGame() {
    const {sellDocument} = useRegisterStore();

    // @ts-ignore
    const gecoGames = promotionByType(sellDocument.value, PromotionMetaType.GECO_GAME);
    return gecoGames.find(({counterName, promoCode}) => {
      return counterName === this.counterName && promoCode === this.promoCode;
    });
  }

  async init() {
    useRegisterStore().ensureCustomerDisplayState();
    this.gameState.game = this.availableGecoGame;
    this.syncState();
    await super.init({
      broadcastIO: {
        [BroadcastIOChannels.GECO_GAME_CUSTOMER_CONFIRM]: ({detail: confirm}) => {
          if (confirm) {
            this.confirm();
          } else {
            this.reject();
          }
        },
        [BroadcastIOChannels.PLAY_GECO_GAME]: async () => {
          const configurationStore = useConfigurationStore();
          const helpStore = useHelpStore();
          const {sellDocument} = useRegisterStore();

          this.syncState();

          if (!this.gecoGame?.counterValue) {
            try {
              this.gameState.state = GameState.LOADING_RESULT;
              this.syncState();
              const {count} = await apiCountersCreate({
                params: {
                  counterCode: this.availableGecoGame.counterName,
                },
                input: new CreateCounterDto({
                  receiptId: sellDocument.value.header.uniqueidentifier,
                  transactionDate: new Date().toISOString(),
                  shopCode: configurationStore.configuration.value?.general?.pos?.shop?.code,
                }),
              });
              this.gameState.state = GameState.SHOWING_RESULT,
              await this.updateGecoGame({counter: count});
            } catch (e) {
              console.error(e);
              await helpStore.show(HelpTypes.UNKNOWN);
              return this.reject();
            }
          } else {
            this.gameState.state = GameState.SHOWING_RESULT,
            await this.updateGecoGame({played: true});
          }

          broadcastIO.postMessage(BroadcastIOChannels.GECO_GAME_RESULT, this.gecoGame);
        },
        [BroadcastIOChannels.GECO_GAME_FINISHED]: () => {
          this.gameState.state = GameState.FINISHED;
          this.syncState();
        },
        [BroadcastIOChannels.GECO_GAME_REQUEST_GAME_STATE]: () => {
          this.syncState();
        },
      },
    });
  }

  syncState() {
    broadcastIO.postMessage(BroadcastIOChannels.GECO_GAME_GAME_STATE, this.gameState);
    emitTestEvent(`${TestEvent.GECO_GAME_STATE_CHANGED}:${this.gameState.state}`);
  }

  async destroy() {
    await super.destroy();
  }

  async updateGecoGame({counter = null, played = true} = {}) {
    const registerStore = useRegisterStore();

    if (this.gecoGame) {
      this.gecoGame.counterValue = counter ?? this.gecoGame.counterValue;
      this.gecoGame.played = played ?? this.gecoGame.played;
    } else {
      registerStore.sellDocument.value.promotions.push(<GecoGame>{
        type: PromotionMetaType.GECO_GAME,
        promoCode: this.availableGecoGame.promoCode,
        counterName: this.availableGecoGame.counterName,
        counterValue: counter,
        played,
      });
    }

    await registerStore.fetchPromoEngineResult();

    this.gameState.result = this.gecoGame;
    this.syncState();
  }

  confirm() {
    this.gameState.state = GameState.WAITING_FOR_CONFIRMATION,
    this.syncState();
  }
  async reject() {
    await this.updateGecoGame({played: false});
    await this.destroy();
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.GECO_GAME_FLOW,
      nextState: this.nextState,
      promoCode: this.promoCode,
      counterName: this.counterName,
      gameState: this.gameState,
    };
  }

  static deserialize(ctx): GecoGameFlow {
    if (ctx === null) {
      return null;
    }
    const {
      nextState,
      promoCode,
      counterName,
      gameState,
    } = ctx;
    return AbstractPromoFlow.baseDeserialize(
      GecoGameFlow,
      {
        gameState: reactive(gameState),
      }, [
        nextState,
        promoCode,
        counterName,
      ]);
  }
}
