import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col flex-grow"
}
const _hoisted_3 = { class: "flex flex-row gap-4 mx-auto" }
const _hoisted_4 = { class: "flex flex-row mx-auto mt-4" }
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col flex-grow"
}
const _hoisted_6 = { class: "text-danger" }
const _hoisted_7 = { class: "flex flex-row mx-auto mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.workflowStep.fetchError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.randomNumbers, (number, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: index,
                class: "korunka-circle-number"
              }, [
                _createTextVNode(_toDisplayString(number), 1)
              ], 512)), [
                [_directive_test, 'korunka-number']
              ])
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AsyncButton, {
              class: "btn-primary w-full shadow-md",
              onClick: () => _ctx.generateRandomNumbersOnEntity()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.workflowStep.messages?.generateNumbers), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('workflow.steps.korunka.workflowCannotContinue')), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AsyncButton, {
              class: "btn-primary w-full shadow-md",
              onClick: () => _ctx.workflowStep.fetchNumbers()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('workflow.steps.korunka.repeat')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
  ]))
}