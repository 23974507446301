
import {
  computed,
  defineComponent,
} from 'vue';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useStepTools} from '../StepTools';
import {WorkflowStepKorunkaLotteryTicketResult} from './WorkflowStepKorunkaLotteryTicketResult';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ModalNumbers from './Components/ModalNumbers.vue';
import KorunkaLotteryTicketDtoCustom from '@/Model/Entity/custom/KorunkaLotteryTicketDtoCustom';
import {getLetterFromIndex} from '@/Helpers/alphabetIndex';
import TicketPartnerResourceV2 from '@/Model/Entity/TicketPartnerResourceV2';

export default defineComponent({
  name: 'StepKorunkaTicketResult',
  components: {
    ModalNumbers,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaTicketResult;

    const configurationStore = useConfigurationStore();
    const {currencyFormat: baseCurrencyFormat, dateFormat} = useFilters();

    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryTicketResult>(stepType);

    const entity = computed(() => workflowStep.value.entity as KorunkaLotteryTicketDtoCustom);

    const ticket = computed(() => entity.value.ticket as TicketPartnerResourceV2);

    const hasWinningNumbers = computed(() => (ticket.value?.draw?.drawNumbers ?? []).length > 0);

    const hasMultipleBetOnDraws = computed(() => {
      return ticket.value?.betOnDraws.length > 1;
    });

    const winningNumbers = computed(() => ticket.value?.draw.drawNumbers.join(', '));

    const canBetAgain = computed(() => !entity.value.actions.canCancel || !entity.value.actions.canPayOut);

    const badge = computed(() => {
      if (entity.value.betsDrawState.isWin && !entity.value.betsDrawState.isPaidOut) {
        return {
          class: 'bg-success text-basic-lightest',
          labels: [
            ...(entity.value.betsDrawState.isPartlyPaidOut ?
              [workflowStep.value.messages.ticketStatusIsPartlyPaidOut] :
              []
            ),
            workflowStep.value.messages.ticketStatusWon,
            ...(entity.value.betsDrawState.isWaitingForNextDraw ?
              [workflowStep.value.messages.ticketStatusWaitingForNextDraw] :
              []
            ),
          ],
        };
      }

      if (entity.value.betsDrawState.isLoss) {
        return {
          class: 'bg-danger text-basic-lightest',
          labels: [
            ...(entity.value.betsDrawState.isPartlyPaidOut ?
              [workflowStep.value.messages.ticketStatusIsPartlyPaidOut] :
              []
            ),
            workflowStep.value.messages.ticketStatusLoss,
            ...(entity.value.betsDrawState.isWaitingForNextDraw ?
              [workflowStep.value.messages.ticketStatusWaitingForNextDraw] :
              []
            ),
          ],
        };
      }

      if (entity.value.betsDrawState.isPaidOut) {
        return {
          class: 'bg-primary text-basic-lightest',
          labels: [workflowStep.value.messages.ticketStatusIsPaidOut],
        };
      }

      if (
        entity.value.betsDrawState.isCanceled ||
        entity.value.betsDrawState.isPartlyCanceled ||
        entity.value.betsDrawState.isPreCanceled ||
        entity.value.betsDrawState.isPartlyPreCanceled
      ) {
        return {
          class: 'bg-basic-darkest text-white',
          labels: [workflowStep.value.messages.ticketStatusCanceled],
        };
      }

      if (entity.value.betsDrawState.noWinsYet) {
        return {
          class: 'bg-warning text-basic-lightest',
          labels: [
            workflowStep.value.messages.ticketStatusNoWinsYet,
            ...(entity.value.betsDrawState.isWaitingForNextDraw ?
              [workflowStep.value.messages.ticketStatusWaitingForNextDraw] :
              []
            ),
          ],
        };
      }

      return {
        class: 'bg-warning text-basic-lightest',
        labels: [
          workflowStep.value.messages.ticketStatusNotEvaluated,
          ...(entity.value.betsDrawState.isWaitingForNextDraw ?
            [workflowStep.value.messages.ticketStatusWaitingForNextDraw] :
            []
          ),
        ],
      };
    });

    const modalNumbersTitle = computed(() => {
      if (
        !entity.value.isWon ||
        !entity.value.isPaidOut ||
        !entity.value.isLoss ||
        !entity.value.isCanceled
      ) {
        return workflowStep.value.messages.modalBetNumbersTitle;
      }

      return workflowStep.value.messages.modalNumbersTitle;
    });

    const openNumbersModal = () => {
      workflowStep.value.setNumbersModalIsOpen(true);
    };

    const closeNumbersModal = () => {
      workflowStep.value.setNumbersModalIsOpen(false);
    };

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    const isWinningNumber = (number: number) => {
      return ticket.value?.draw.drawNumbers.includes(number);
    };

    const onPayout = () => {
      workflowStep.value.onPayout();
    };

    const onCancelTicket = () => {
      workflowStep.value.onCancelTicket();
    };

    const onRepeatBetTicket = () => {
      workflowStep.value.onRepeatBetTicket();
    };

    const kombiNumberRowGridTemplateColumns = (numbersRow: number[]) => {
      const {length} = numbersRow;
      const cols = length <= 8 ? length : Math.ceil(length / 2);
      return `grid-template-columns: repeat(${cols}, minmax(0, 1fr));`;
    };

    const kombiPlusNumberRowGridTemplateColumns = (numbersRow: number[]) => {
      const {length} = numbersRow;
      const cols = length + 1;
      return `grid-template-columns: 1rem repeat(${cols}, minmax(0, 1fr));`;
    };

    return {
      stepType,
      workflowStep,
      entity,
      ticket,
      canBetAgain,
      badge,
      hasMultipleBetOnDraws,
      winningNumbers,
      isWinningNumber,
      dateFormat,
      currencyFormat,
      openNumbersModal,
      closeNumbersModal,
      onPayout,
      onCancelTicket,
      onRepeatBetTicket,
      hasWinningNumbers,
      getLetterFromIndex,
      kombiNumberRowGridTemplateColumns,
      kombiPlusNumberRowGridTemplateColumns,
      modalNumbersTitle,
    };
  },
});
