import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderActionsDto from '../OrderActionsDto';
import OrderDto from '../OrderDto';


export const OrderDtoOrderEnvelopeDtoPropDef = {
  actions: {
    type: new EntityType(OrderActionsDto),
  },
  data: {
    type: new EntityType(OrderDto),
  },
};
export default class GeneratedOrderDtoOrderEnvelopeDto extends createEntity(OrderDtoOrderEnvelopeDtoPropDef) {}
