import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-basic-lightest shadow-sm rounded-2xl flex flex-col flex-grow h-0 px-6 py-5" }
const _hoisted_2 = { class: "h-full overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Html = _resolveComponent("Html")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Html, {
        value: _ctx.workflowStore.currentWorkflow.value.instructions,
        class: "instructions-html"
      }, null, 8, ["value"])
    ])
  ]))
}