import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {
  map,
  mapKeys,
  mapValues,
  min,
} from 'lodash-es';
import {action} from '@designeo/vue-helpers/src/index';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import {validationInvalidNominalsMinValue} from '@/Helpers/validations';
import {CashStatesDto, DocumentDto} from '@/Model/Entity';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import {apiDocumentCreate} from '@/Model/Action';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowDrawerCashStateResult extends workflowStepMixinNominals(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.DrawerCashStateResult;
  }

  get type() {
    return WorkflowDrawerCashStateResult.type;
  }

  get component() {
    return markRaw(require('./StepDrawerCashStateResult').default);
  }

  async beforeContinue() {
    if (!await this.validate()) {
      throw new Error('invalid');
    }
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      ...mapValues(mapKeys(this.fields), (val, field) => {
        if (this.step.enterCoinsTogether && field === WorkflowStepField.coins) {
          return yup.string()
            .test(this.createInvalidNominalsCountMaxQuantityValidation(field))
            .test(validationInvalidNominalsMinValue(this.currency, {
              acceptableNominals: this.currency.nominals,
              smallestAcceptedNominal: min(this.currency.nominals),
              acceptZero: true,
            }));
        }

        return yup.string()
          .test(this.createInvalidNominalsCountMaxQuantityValidation(field));
      }),
    });
  }

  get cashState() {
    return new CashStatesDto(this.getFieldValue(WorkflowStepField.cashState, {}));
  }

  get totalNominals() {
    return this.total;
  }

  get totalApi() {
    return this.cashState.cash?.[this.currency.symbolLowerCase]?.total;
  }

  get totalDifference() {
    return this.totalNominals - this.totalApi;
  }

  get canPrint() {
    return this.step.enablePrint ?? false;
  }

  async print() {
    const {notificationsConnection} = useSignalR();

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      const items = this.availableNominalsAsItems;

      const doc = DocumentDto.createCashDrawerState({
        currency: this.currency.symbol,
        items,
      });

      doc.refreshTotalPrice();

      doc.header.total = this.totalApi;

      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== doc.header.uniqueidentifier) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        doc.preflightSetup();
        return await apiDocumentCreate({
          input: doc.toApiClone(),
        });
      });

      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      this.stepFinished = true;

      if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
        await this.printContentStore.open(result.printContent);
      }
    } catch (e) {
      console.error(e);
      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    const fields = this.fields;

    return {
      ...map(fields, (field, index) => {
        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_CHAR](event.value);
            }),
            ...(field === WorkflowStepField.coins ? {
              [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
                if (this.getFieldValue(field, '').includes('.')) return;
                fieldActions[WorkflowActions.ADD_COMMA](event.value);
              }),
              [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
                if (this.getFieldValue(field, '').includes('.')) return;
                fieldActions[WorkflowActions.ADD_PERIOD](event.value);
              }),
            } : {}),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ADD_MINUS]: action(() => {
              if (!this.getFieldValue(field, '').length) {
                fieldActions[WorkflowActions.ADD_MINUS]();
              }
            }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = fields[index + 1] ?? fields[index];

              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                detail: {
                  field: index === fields.length - 1 ? fields[0] : nextField,
                },
              }));
            }),
            ...this.createArrowMovementTransitions(fields, index),
          })),
        };
      }).reduce((acc, val) => ({...acc, ...val}), {}),
    };
  }
}
