import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (false && _ctx.hasValidTimeout && _ctx.isActiveQueued)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["fixed w-full top-0 left-0 transition-all", {
      'h-1.5': _ctx.hasActivePerson,
      'h-0.5': !_ctx.hasActivePerson,
    }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["transition-all h-full duration-200", {
        'bg-secondary': _ctx.hasActivePerson && _ctx.progressQueued > 35,
        'bg-warning': _ctx.hasActivePerson && _ctx.progressQueued <= 35,
        'bg-basic-dark': !_ctx.hasActivePerson
      }]),
          style: _normalizeStyle({width: `${_ctx.hasActivePerson ? _ctx.progressQueued : 100}%`})
        }, null, 6)
      ], 2))
    : _createCommentVNode("", true)
}