import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {apiFinancialCloseDayCanStart, apiLotteryLastLotteryStatement} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {workflowStepMixinBalanceStock} from '../StepMixins/WorkflowStepMixinBalanceStock';
import {DocumentDto} from '@/Model/Entity';
import {DateTime, Interval} from 'luxon';

export class WorkflowStepCheckBalanceStockAvailable extends workflowStepMixinBalanceStock(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.CheckBalanceStockAvailable;
  }

  get type() {
    return WorkflowStepCheckBalanceStockAvailable.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  async beforeContinue() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const lastLotteryReportDate = (
        await apiLotteryLastLotteryStatement() as DocumentDto
      )?.header?.documentDate ?? null;

      const lastReportMinutesAgo = Interval.fromDateTimes(
        DateTime.fromJSDate(lastLotteryReportDate),
        DateTime.now(),
      ).length('minutes');

      const lotteryReportDone = lastReportMinutesAgo < (this.step?.lotteryLastReportInterval ?? 30);

      const financialCloseDayCanStart = await apiFinancialCloseDayCanStart();

      const isAvailable = lotteryReportDone && financialCloseDayCanStart;

      if (!isAvailable) {
        this.disabledNextStep = true;
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.BALANCE_STOCK_NOT_AVAILABLE,
          },
        }));
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
      }
    } catch (e) {
      console.error({e});
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
