import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const SafebagResultDtoPropDef = {
  envelopeNumber: {
    type: new StringType(),
  },
};
export default class GeneratedSafebagResultDto extends createEntity(SafebagResultDtoPropDef) {}
