/* eslint-disable max-len */
import {kebabCase} from 'lodash-es';

const sanitizeKey = <S extends string>(str: S) => kebabCase(str).replace(/^ph-/, '') as S;

export const phIcons = {
  [sanitizeKey('PhActivity')]: () => import('phosphor-vue/dist/esm/components/PhActivity.vue.js'),
  [sanitizeKey('PhAddressBook')]: () => import('phosphor-vue/dist/esm/components/PhAddressBook.vue.js'),
  [sanitizeKey('PhAlarm')]: () => import('phosphor-vue/dist/esm/components/PhAlarm.vue.js'),
  [sanitizeKey('PhArchive')]: () => import('phosphor-vue/dist/esm/components/PhArchive.vue.js'),
  [sanitizeKey('PhArchiveBox')]: () => import('phosphor-vue/dist/esm/components/PhArchiveBox.vue.js'),
  [sanitizeKey('PhArchiveTray')]: () => import('phosphor-vue/dist/esm/components/PhArchiveTray.vue.js'),
  [sanitizeKey('PhArrowBendDownLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowBendDownLeft.vue.js'),
  [sanitizeKey('PhArrowCircleLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowCircleLeft.vue.js'),
  [sanitizeKey('PhArrowCircleRight')]: () => import('phosphor-vue/dist/esm/components/PhArrowCircleRight.vue.js'),
  [sanitizeKey('PhArrowClockwise')]: () => import('phosphor-vue/dist/esm/components/PhArrowClockwise.vue.js'),
  [sanitizeKey('PhArrowElbowDownLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowElbowDownLeft.vue.js'),
  [sanitizeKey('PhArrowFatUp')]: () => import('phosphor-vue/dist/esm/components/PhArrowFatUp.vue.js'),
  [sanitizeKey('PhArrowLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowLeft.vue.js'),
  [sanitizeKey('PhArrowRight')]: () => import('phosphor-vue/dist/esm/components/PhArrowRight.vue.js'),
  [sanitizeKey('PhArrowSquareLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowSquareLeft.vue.js'),
  [sanitizeKey('PhArrowSquareRight')]: () => import('phosphor-vue/dist/esm/components/PhArrowSquareRight.vue.js'),
  [sanitizeKey('PhArrowURightDown')]: () => import('phosphor-vue/dist/esm/components/PhArrowURightDown.vue.js'),
  [sanitizeKey('PhArrowUUpLeft')]: () => import('phosphor-vue/dist/esm/components/PhArrowUUpLeft.vue.js'),
  [sanitizeKey('PhArrowsCounterClockwise')]: () => import('phosphor-vue/dist/esm/components/PhArrowsCounterClockwise.vue.js'),
  [sanitizeKey('PhArrowsInSimple')]: () => import('phosphor-vue/dist/esm/components/PhArrowsInSimple.vue.js'),
  [sanitizeKey('PhArrowsOutSimple')]: () => import('phosphor-vue/dist/esm/components/PhArrowsOutSimple.vue.js'),
  [sanitizeKey('PhArticle')]: () => import('phosphor-vue/dist/esm/components/PhArticle.vue.js'),
  [sanitizeKey('PhAsterisk')]: () => import('phosphor-vue/dist/esm/components/PhAsterisk.vue.js'),
  [sanitizeKey('PhBackspace')]: () => import('phosphor-vue/dist/esm/components/PhBackspace.vue.js'),
  [sanitizeKey('PhBagSimple')]: () => import('phosphor-vue/dist/esm/components/PhBagSimple.vue.js'),
  [sanitizeKey('PhBank')]: () => import('phosphor-vue/dist/esm/components/PhBank.vue.js'),
  [sanitizeKey('PhBarcode')]: () => import('phosphor-vue/dist/esm/components/PhBarcode.vue.js'),
  [sanitizeKey('PhBell')]: () => import('phosphor-vue/dist/esm/components/PhBell.vue.js'),
  [sanitizeKey('PhBellRinging')]: () => import('phosphor-vue/dist/esm/components/PhBellRinging.vue.js'),
  [sanitizeKey('PhBinoculars')]: () => import('phosphor-vue/dist/esm/components/PhBinoculars.vue.js'),
  [sanitizeKey('PhBook')]: () => import('phosphor-vue/dist/esm/components/PhBook.vue.js'),
  [sanitizeKey('PhBookBookmark')]: () => import('phosphor-vue/dist/esm/components/PhBookBookmark.vue.js'),
  [sanitizeKey('PhBookOpen')]: () => import('phosphor-vue/dist/esm/components/PhBookOpen.vue.js'),
  [sanitizeKey('PhBookmarkSimple')]: () => import('phosphor-vue/dist/esm/components/PhBookmarkSimple.vue.js'),
  [sanitizeKey('PhBooks')]: () => import('phosphor-vue/dist/esm/components/PhBooks.vue.js'),
  [sanitizeKey('PhBriefcase')]: () => import('phosphor-vue/dist/esm/components/PhBriefcase.vue.js'),
  [sanitizeKey('PhCalculator')]: () => import('phosphor-vue/dist/esm/components/PhCalculator.vue.js'),
  [sanitizeKey('PhCalendar')]: () => import('phosphor-vue/dist/esm/components/PhCalendar.vue.js'),
  [sanitizeKey('PhCalendarBlank')]: () => import('phosphor-vue/dist/esm/components/PhCalendarBlank.vue.js'),
  [sanitizeKey('PhCamera')]: () => import('phosphor-vue/dist/esm/components/PhCamera.vue.js'),
  [sanitizeKey('PhCar')]: () => import('phosphor-vue/dist/esm/components/PhCar.vue.js'),
  [sanitizeKey('PhCardholder')]: () => import('phosphor-vue/dist/esm/components/PhCardholder.vue.js'),
  [sanitizeKey('PhCards')]: () => import('phosphor-vue/dist/esm/components/PhCards.vue.js'),
  [sanitizeKey('PhCaretDown')]: () => import('phosphor-vue/dist/esm/components/PhCaretDown.vue.js'),
  [sanitizeKey('PhCaretLeft')]: () => import('phosphor-vue/dist/esm/components/PhCaretLeft.vue.js'),
  [sanitizeKey('PhCaretRight')]: () => import('phosphor-vue/dist/esm/components/PhCaretRight.vue.js'),
  [sanitizeKey('PhChalkboardTeacher')]: () => import('phosphor-vue/dist/esm/components/PhChalkboardTeacher.vue.js'),
  [sanitizeKey('PhChartBar')]: () => import('phosphor-vue/dist/esm/components/PhChartBar.vue.js'),
  [sanitizeKey('PhChartPieSlice')]: () => import('phosphor-vue/dist/esm/components/PhChartPieSlice.vue.js'),
  [sanitizeKey('PhChatCircleDots')]: () => import('phosphor-vue/dist/esm/components/PhChatCircleDots.vue.js'),
  [sanitizeKey('PhChatDots')]: () => import('phosphor-vue/dist/esm/components/PhChatDots.vue.js'),
  [sanitizeKey('PhChatText')]: () => import('phosphor-vue/dist/esm/components/PhChatText.vue.js'),
  [sanitizeKey('PhChatsCircle')]: () => import('phosphor-vue/dist/esm/components/PhChatsCircle.vue.js'),
  [sanitizeKey('PhCheck')]: () => import('phosphor-vue/dist/esm/components/PhCheck.vue.js'),
  [sanitizeKey('PhCheckCircle')]: () => import('phosphor-vue/dist/esm/components/PhCheckCircle.vue.js'),
  [sanitizeKey('PhCheckSquare')]: () => import('phosphor-vue/dist/esm/components/PhCheckSquare.vue.js'),
  [sanitizeKey('PhChecks')]: () => import('phosphor-vue/dist/esm/components/PhChecks.vue.js'),
  [sanitizeKey('PhClipboardText')]: () => import('phosphor-vue/dist/esm/components/PhClipboardText.vue.js'),
  [sanitizeKey('PhClock')]: () => import('phosphor-vue/dist/esm/components/PhClock.vue.js'),
  [sanitizeKey('PhClockClockwise')]: () => import('phosphor-vue/dist/esm/components/PhClockClockwise.vue.js'),
  [sanitizeKey('PhClockCounterClockwise')]: () => import('phosphor-vue/dist/esm/components/PhClockCounterClockwise.vue.js'),
  [sanitizeKey('PhClub')]: () => import('phosphor-vue/dist/esm/components/PhClub.vue.js'),
  [sanitizeKey('PhCoins')]: () => import('phosphor-vue/dist/esm/components/PhCoins.vue.js'),
  [sanitizeKey('PhCompass')]: () => import('phosphor-vue/dist/esm/components/PhCompass.vue.js'),
  [sanitizeKey('PhCopySimple')]: () => import('phosphor-vue/dist/esm/components/PhCopySimple.vue.js'),
  [sanitizeKey('PhCreditCard')]: () => import('phosphor-vue/dist/esm/components/PhCreditCard.vue.js'),
  [sanitizeKey('PhCube')]: () => import('phosphor-vue/dist/esm/components/PhCube.vue.js'),
  [sanitizeKey('PhCurrencyEur')]: () => import('phosphor-vue/dist/esm/components/PhCurrencyEur.vue.js'),
  [sanitizeKey('PhDatabase')]: () => import('phosphor-vue/dist/esm/components/PhDatabase.vue.js'),
  [sanitizeKey('PhDesktop')]: () => import('phosphor-vue/dist/esm/components/PhDesktop.vue.js'),
  [sanitizeKey('PhDownloadSimple')]: () => import('phosphor-vue/dist/esm/components/PhDownloadSimple.vue.js'),
  [sanitizeKey('PhEnvelope')]: () => import('phosphor-vue/dist/esm/components/PhEnvelope.vue.js'),
  [sanitizeKey('PhEnvelopeOpen')]: () => import('phosphor-vue/dist/esm/components/PhEnvelopeOpen.vue.js'),
  [sanitizeKey('PhEye')]: () => import('phosphor-vue/dist/esm/components/PhEye.vue.js'),
  [sanitizeKey('PhEyeSlash')]: () => import('phosphor-vue/dist/esm/components/PhEyeSlash.vue.js'),
  [sanitizeKey('PhFastForward')]: () => import('phosphor-vue/dist/esm/components/PhFastForward.vue.js'),
  [sanitizeKey('PhFile')]: () => import('phosphor-vue/dist/esm/components/PhFile.vue.js'),
  [sanitizeKey('PhFileText')]: () => import('phosphor-vue/dist/esm/components/PhFileText.vue.js'),
  [sanitizeKey('PhFingerprint')]: () => import('phosphor-vue/dist/esm/components/PhFingerprint.vue.js'),
  [sanitizeKey('PhFirstAidKit')]: () => import('phosphor-vue/dist/esm/components/PhFirstAidKit.vue.js'),
  [sanitizeKey('PhFlag')]: () => import('phosphor-vue/dist/esm/components/PhFlag.vue.js'),
  [sanitizeKey('PhFlagBanner')]: () => import('phosphor-vue/dist/esm/components/PhFlagBanner.vue.js'),
  [sanitizeKey('PhFolder')]: () => import('phosphor-vue/dist/esm/components/PhFolder.vue.js'),
  [sanitizeKey('PhFolderOpen')]: () => import('phosphor-vue/dist/esm/components/PhFolderOpen.vue.js'),
  [sanitizeKey('PhFunnel')]: () => import('phosphor-vue/dist/esm/components/PhFunnel.vue.js'),
  [sanitizeKey('PhGear')]: () => import('phosphor-vue/dist/esm/components/PhGear.vue.js'),
  [sanitizeKey('PhGift')]: () => import('phosphor-vue/dist/esm/components/PhGift.vue.js'),
  [sanitizeKey('PhGlobe')]: () => import('phosphor-vue/dist/esm/components/PhGlobe.vue.js'),
  [sanitizeKey('PhHand')]: () => import('phosphor-vue/dist/esm/components/PhHand.vue.js'),
  [sanitizeKey('PhHandPointing')]: () => import('phosphor-vue/dist/esm/components/PhHandPointing.vue.js'),
  [sanitizeKey('PhHourglass')]: () => import('phosphor-vue/dist/esm/components/PhHourglass.vue.js'),
  [sanitizeKey('PhHouse')]: () => import('phosphor-vue/dist/esm/components/PhHouse.vue.js'),
  [sanitizeKey('PhIdentificationCard')]: () => import('phosphor-vue/dist/esm/components/PhIdentificationCard.vue.js'),
  [sanitizeKey('PhImage')]: () => import('phosphor-vue/dist/esm/components/PhImage.vue.js'),
  [sanitizeKey('PhInfinity')]: () => import('phosphor-vue/dist/esm/components/PhInfinity.vue.js'),
  [sanitizeKey('PhInfo')]: () => import('phosphor-vue/dist/esm/components/PhInfo.vue.js'),
  [sanitizeKey('PhKey')]: () => import('phosphor-vue/dist/esm/components/PhKey.vue.js'),
  [sanitizeKey('PhKeyboard')]: () => import('phosphor-vue/dist/esm/components/PhKeyboard.vue.js'),
  [sanitizeKey('PhLink')]: () => import('phosphor-vue/dist/esm/components/PhLink.vue.js'),
  [sanitizeKey('PhListBullets')]: () => import('phosphor-vue/dist/esm/components/PhListBullets.vue.js'),
  [sanitizeKey('PhLockSimple')]: () => import('phosphor-vue/dist/esm/components/PhLockSimple.vue.js'),
  [sanitizeKey('PhMagnifyingGlass')]: () => import('phosphor-vue/dist/esm/components/PhMagnifyingGlass.vue.js'),
  [sanitizeKey('PhMinusCircle')]: () => import('phosphor-vue/dist/esm/components/PhMinusCircle.vue.js'),
  [sanitizeKey('PhMoney')]: () => import('phosphor-vue/dist/esm/components/PhMoney.vue.js'),
  [sanitizeKey('PhMoonStars')]: () => import('phosphor-vue/dist/esm/components/PhMoonStars.vue.js'),
  [sanitizeKey('PhMouse')]: () => import('phosphor-vue/dist/esm/components/PhMouse.vue.js'),
  [sanitizeKey('PhNote')]: () => import('phosphor-vue/dist/esm/components/PhNote.vue.js'),
  [sanitizeKey('PhNewspaper')]: () => import('phosphor-vue/dist/esm/components/PhNewspaper.vue.js'),
  [sanitizeKey('PhNotePencil')]: () => import('phosphor-vue/dist/esm/components/PhNotePencil.vue.js'),
  [sanitizeKey('PhNotebook')]: () => import('phosphor-vue/dist/esm/components/PhNotebook.vue.js'),
  [sanitizeKey('PhNotepad')]: () => import('phosphor-vue/dist/esm/components/PhNotepad.vue.js'),
  [sanitizeKey('PhPackage')]: () => import('phosphor-vue/dist/esm/components/PhPackage.vue.js'),
  [sanitizeKey('PhPaperclip')]: () => import('phosphor-vue/dist/esm/components/PhPaperclip.vue.js'),
  [sanitizeKey('PhPencil')]: () => import('phosphor-vue/dist/esm/components/PhPencil.vue.js'),
  [sanitizeKey('PhPencilSimple')]: () => import('phosphor-vue/dist/esm/components/PhPencilSimple.vue.js'),
  [sanitizeKey('PhPencilSimpleLine')]: () => import('phosphor-vue/dist/esm/components/PhPencilSimpleLine.vue.js'),
  [sanitizeKey('PhPercent')]: () => import('phosphor-vue/dist/esm/components/PhPercent.vue.js'),
  [sanitizeKey('PhPerson')]: () => import('phosphor-vue/dist/esm/components/PhPerson.vue.js'),
  [sanitizeKey('PhPhone')]: () => import('phosphor-vue/dist/esm/components/PhPhone.vue.js'),
  [sanitizeKey('PhPhoneCall')]: () => import('phosphor-vue/dist/esm/components/PhPhoneCall.vue.js'),
  [sanitizeKey('PhPlus')]: () => import('phosphor-vue/dist/esm/components/PhPlus.vue.js'),
  [sanitizeKey('PhPlusCircle')]: () => import('phosphor-vue/dist/esm/components/PhPlusCircle.vue.js'),
  [sanitizeKey('PhPower')]: () => import('phosphor-vue/dist/esm/components/PhPower.vue.js'),
  [sanitizeKey('PhPrinter')]: () => import('phosphor-vue/dist/esm/components/PhPrinter.vue.js'),
  [sanitizeKey('PhProhibit')]: () => import('phosphor-vue/dist/esm/components/PhProhibit.vue.js'),
  [sanitizeKey('PhPushPin')]: () => import('phosphor-vue/dist/esm/components/PhPushPin.vue.js'),
  [sanitizeKey('PhPuzzlePiece')]: () => import('phosphor-vue/dist/esm/components/PhPuzzlePiece.vue.js'),
  [sanitizeKey('PhQrCode')]: () => import('phosphor-vue/dist/esm/components/PhQrCode.vue.js'),
  [sanitizeKey('PhQuestion')]: () => import('phosphor-vue/dist/esm/components/PhQuestion.vue.js'),
  [sanitizeKey('PhRainbowCloud')]: () => import('phosphor-vue/dist/esm/components/PhRainbowCloud.vue.js'),
  [sanitizeKey('PhReceipt')]: () => import('phosphor-vue/dist/esm/components/PhReceipt.vue.js'),
  [sanitizeKey('PhRecycle')]: () => import('phosphor-vue/dist/esm/components/PhRecycle.vue.js'),
  [sanitizeKey('PhRuler')]: () => import('phosphor-vue/dist/esm/components/PhRuler.vue.js'),
  [sanitizeKey('PhShoppingCartSimple')]: () => import('phosphor-vue/dist/esm/components/PhShoppingCartSimple.vue.js'),
  [sanitizeKey('PhSignIn')]: () => import('phosphor-vue/dist/esm/components/PhSignIn.vue.js'),
  [sanitizeKey('PhSignOut')]: () => import('phosphor-vue/dist/esm/components/PhSignOut.vue.js'),
  [sanitizeKey('PhSignpost')]: () => import('phosphor-vue/dist/esm/components/PhSignpost.vue.js'),
  [sanitizeKey('PhSmiley')]: () => import('phosphor-vue/dist/esm/components/PhSmiley.vue.js'),
  [sanitizeKey('PhSmileySad')]: () => import('phosphor-vue/dist/esm/components/PhSmileySad.vue.js'),
  [sanitizeKey('PhSnowflake')]: () => import('phosphor-vue/dist/esm/components/PhSnowflake.vue.js'),
  [sanitizeKey('PhSpiral')]: () => import('phosphor-vue/dist/esm/components/PhSpiral.vue.js'),
  [sanitizeKey('PhSquare')]: () => import('phosphor-vue/dist/esm/components/PhSquare.vue.js'),
  [sanitizeKey('PhSquaresFour')]: () => import('phosphor-vue/dist/esm/components/PhSquaresFour.vue.js'),
  [sanitizeKey('PhStack')]: () => import('phosphor-vue/dist/esm/components/PhStack.vue.js'),
  [sanitizeKey('PhStorefront')]: () => import('phosphor-vue/dist/esm/components/PhStorefront.vue.js'),
  [sanitizeKey('PhTag')]: () => import('phosphor-vue/dist/esm/components/PhTag.vue.js'),
  [sanitizeKey('PhTextAa')]: () => import('phosphor-vue/dist/esm/components/PhTextAa.vue.js'),
  [sanitizeKey('PhTextAlignJustify')]: () => import('phosphor-vue/dist/esm/components/PhTextAlignJustify.vue.js'),
  [sanitizeKey('PhThermometerSimple')]: () => import('phosphor-vue/dist/esm/components/PhThermometerSimple.vue.js'),
  [sanitizeKey('PhThumbsDown')]: () => import('phosphor-vue/dist/esm/components/PhThumbsDown.vue.js'),
  [sanitizeKey('PhThumbsUp')]: () => import('phosphor-vue/dist/esm/components/PhThumbsUp.vue.js'),
  [sanitizeKey('PhTicket')]: () => import('phosphor-vue/dist/esm/components/PhTicket.vue.js'),
  [sanitizeKey('PhTimer')]: () => import('phosphor-vue/dist/esm/components/PhTimer.vue.js'),
  [sanitizeKey('PhTrash')]: () => import('phosphor-vue/dist/esm/components/PhTrash.vue.js'),
  [sanitizeKey('PhUmbrellaSimple')]: () => import('phosphor-vue/dist/esm/components/PhUmbrellaSimple.vue.js'),
  [sanitizeKey('PhUploadSimple')]: () => import('phosphor-vue/dist/esm/components/PhUploadSimple.vue.js'),
  [sanitizeKey('PhUserCircle')]: () => import('phosphor-vue/dist/esm/components/PhUserCircle.vue.js'),
  [sanitizeKey('PhUserList')]: () => import('phosphor-vue/dist/esm/components/PhUserList.vue.js'),
  [sanitizeKey('PhUserRectangle')]: () => import('phosphor-vue/dist/esm/components/PhUserRectangle.vue.js'),
  [sanitizeKey('PhVideoCamera')]: () => import('phosphor-vue/dist/esm/components/PhVideoCamera.vue.js'),
  [sanitizeKey('PhWallet')]: () => import('phosphor-vue/dist/esm/components/PhWallet.vue.js'),
  [sanitizeKey('PhWarning')]: () => import('phosphor-vue/dist/esm/components/PhWarning.vue.js'),
  [sanitizeKey('PhWarningCircle')]: () => import('phosphor-vue/dist/esm/components/PhWarningCircle.vue.js'),
  [sanitizeKey('PhWarningOctagon')]: () => import('phosphor-vue/dist/esm/components/PhWarningOctagon.vue.js'),
  [sanitizeKey('PhWrench')]: () => import('phosphor-vue/dist/esm/components/PhWrench.vue.js'),
  [sanitizeKey('PhX')]: () => import('phosphor-vue/dist/esm/components/PhX.vue.js'),
  [sanitizeKey('PhXCircle')]: () => import('phosphor-vue/dist/esm/components/PhXCircle.vue.js'),
  [sanitizeKey('PhXSquare')]: () => import('phosphor-vue/dist/esm/components/PhXSquare.vue.js'),
  [sanitizeKey('PhWifiHigh')]: () => import('phosphor-vue/dist/esm/components/PhWifiHigh.vue.js'),
  [sanitizeKey('PhWifiX')]: () => import('phosphor-vue/dist/esm/components/PhWifiX.vue.js'),
  [sanitizeKey('PhToteSimple')]: () => import('phosphor-vue/dist/esm/components/PhToteSimple.vue.js'),
};

export type PhIcons = keyof typeof phIcons;

export const getPhIcon = async (icon: string) => {
  const sanitizedIcon = sanitizeKey(icon);
  if (!Object.prototype.hasOwnProperty.call(phIcons, sanitizedIcon)) {
    console.warn(`Icon '${icon}' is not added!`);
    return null;
  }

  try {
    return (await phIcons[sanitizedIcon]()).default;
  } catch (e) {
    console.error(e);
    return null;
  }
};
