import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TicketCalcWinsRequestResourceGameType from '../TicketCalcWinsRequestResourceGameType';
import {NumberType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import TicketResourceCombinations from '../TicketResourceCombinations';
import {BooleanType} from '@designeo/apibundle-js';


export const TicketCalcWinsRequestResourcePropDef = {
  gameType: {
    type: new EntityType(TicketCalcWinsRequestResourceGameType),
  },
  bet: {
    type: new NumberType(),
  },
  numbers: {
    type: new ObjectType(),
  },
  combinations: {
    type: new ArrayType(new EntityType(TicketResourceCombinations)),
  },
  tutovka: {
    type: new ArrayType(new NumberType()),
  },
  booster: {
    type: new BooleanType(),
  },
};
export default class GeneratedTicketCalcWinsRequestResource extends createEntity(TicketCalcWinsRequestResourcePropDef) {}
