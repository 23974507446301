import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,

} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_INVOICE_NUMBER]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(async () => {
        this.state.inputBuffer = this.state.productFlow.product.invoiceNo ?? '';
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        try {
          if (!this.state.inputBuffer) return;

          const {invoices} = await this.searchSubject(this.state.inputBuffer);

          if (!invoices.length) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_INVOICE_NUMBER, {
              detail: {
                invoiceNumber: this.state.inputBuffer,
              },
            }));

            return;
          }

          this.state.productFlow.product.setInvoiceNoWithinContext(
            this.state.sellDocument,
            this.state.editOf,
            this.state.inputBuffer,
          );

          await this.transitionToNextStep();
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        try {
          const {invoices} = await this.searchSubject(event.value);

          if (!invoices.length) {
            this.dispatchEvent(new CustomEvent(RegisterStoreErrors.INVALID_INVOICE_NUMBER, {
              detail: {
                invoiceNumber: event.value,
              },
            }));

            return;
          }

          this.state.productFlow.product.setInvoiceNoWithinContext(
            this.state.sellDocument,
            this.state.editOf,
            event.value,
          );

          await this.transitionToNextStep();
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
    },
  };
}
