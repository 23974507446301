import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const SendSmsDtoPropDef = {
  phoneNumber: {
    type: new StringType(),
  },
  message: {
    type: new StringType(),
  },
};
export default class GeneratedSendSmsDto extends createEntity(SendSmsDtoPropDef) {}
