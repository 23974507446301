import GIPCheckDtoCustom from './custom/GIPCheckDtoCustom';

export default class GIPCheckDto extends GIPCheckDtoCustom {
  get canBeSold() {
    return !!this.authorizationId;
  }

  get errorReason() {
    return null;
  }

  get errorReasonForCustomer() {
    return null;
  }
}
