import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {LotteryTurnoversDto} from '@/Model/Entity';
import {fieldsWithIndex, sumLotteryFields} from '@/Helpers/lotteryFields';
import {
  every,
  map,
  mapKeys,
  reduce,
} from 'lodash-es';
import IMask from 'imask';
import {validationInvalidNominalsMinValue} from '../../../../Helpers/validations';
import * as yup from 'yup';


export class WorkflowStepEnterLotterySum extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterLotterySum;
  }

  get type() {
    return WorkflowStepEnterLotterySum.type;
  }

  get component() {
    return markRaw(require('./StepEnterLotterySum.vue').default);
  }

  get terminalCount() {
    return this.configurationStore.configuration.value?.features?.lottery?.terminalCount ?? 1;
  }

  get showResultMessage() {
    return this.step?.showResultMessage ?? false;
  }

  get fields() {
    return fieldsWithIndex(WorkflowStepField.lotteryTotalSellAmount, this.terminalCount);
  }

  get lotteryTurnovers() {
    return new LotteryTurnoversDto(this.getFieldValue(WorkflowStepField.lotteryTurnovers, {}));
  }

  get inputCharsMaxLen() {
    return this.configurationStore.configuration.value?.features?.lottery?.inputCharsMaxLen ?? 6;
  }

  get currency() {
    return this.configurationStore
      .localCurrency
      .value;
  }

  get hasFilledAllSumFields() {
    return every(map(this.fields, (field) => {
      const amount = this.getFieldValue(field);

      return !!amount;
    }));
  }

  get fieldsValueSum() {
    return sumLotteryFields(this.getFieldValue(WorkflowStepField.lotteryTotalSellAmount, '0'));
  }

  get skipOtherStepsOnLotteryTotalEquals() {
    return this.step?.skipOtherStepsOnLotteryTotalEquals ?? true;
  }

  async beforeContinue() {
    const totalAmount = this.lotteryTurnovers.totalAmount;

    if (this.fieldsValueSum === totalAmount && this.skipOtherStepsOnLotteryTotalEquals) {
      this.dataSetter(WorkflowStepField.lotteryAmountEquals, () => true);
    } else {
      this.dataSetter(WorkflowStepField.lotteryAmountEquals, () => false);
    }
  }

  get validator(): yup.AnyObjectSchema {
    const schema = reduce(
      this.fields,
      (acc, field) => {
        const schema = reduce((field.split(/\./gi) ?? []).reverse(), (acc, pathItem, index: number) => {
          const isFirst = index === 0;

          if (isFirst) {
            return {
              [pathItem]: yup.string()
                .test(validationInvalidNominalsMinValue(this.currency, {acceptZero: true, acceptNegative: true}))
                .max(this.inputCharsMaxLen)
                .typeError('validations.string.max')
                .required(),
            };
          } else {
            return {
              [pathItem]: yup.object(acc),
            };
          }
        }, {});

        return yup.object()
          .concat(acc)
          .concat(yup.object().shape(schema));
      }, yup.object(),
    );

    return schema;
  }

  get transitions() {
    return {
      ...map(this.fields, (field, index: number, array) => {
        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.CANCEL]: action(() => {
              this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
            }),
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
            }),
            [WorkflowActions.ADD_MINUS]: action(() => {
              fieldActions[WorkflowActions.ADD_MINUS]();
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_COMMA](event.value);
            }),
            [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_PERIOD](event.value);
            }),
            [WorkflowActions.ENTER]: action(async () => {
              const isLastField = index === array.length - 1;
              if (isLastField) {
                if (!await this.validate()) return;
                this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
              } else {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                  detail: {
                    field: array[index + 1],
                  },
                }));
                return;
              }
            }),
            ...this.createArrowMovementTransitions(this.fields, index),
          }),
          {
            mask: IMask.createMask({
              mask: Array.from({length: this.inputCharsMaxLen}, () => '*').join(),
            }),
          }),
        };
      }).reduce((acc, val, index) => ({...acc, ...val}), {}),
    };
  }
}
