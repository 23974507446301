import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PointOfSaleDataDto from '../PointOfSaleDataDto';
import MiddlewareDataDto from '../MiddlewareDataDto';


export const PointOfSaleActivatedDtoPropDef = {
  privateKey: {
    type: new StringType(),
  },
  pointOfSale: {
    type: new EntityType(PointOfSaleDataDto),
  },
  middleware: {
    type: new EntityType(MiddlewareDataDto),
  },
  registrationId: {
    type: new StringType(),
  },
};
export default class GeneratedPointOfSaleActivatedDto extends createEntity(PointOfSaleActivatedDtoPropDef) {}
