
import {
  computed,
  defineComponent,
} from 'vue';
import ToggleListMultiple from '@/Components/ToggleListMultiple.vue';
import {WorkflowStepKorunkaLotteryDraw} from '../WorkflowStepKorunkaLotteryDraw';
import KorunkaLotterySestkaDtoCustom from '@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom';
import {
  filter,
  flatten,
  flow,
  groupBy,
  has,
  isEmpty,
  map,
  mapKeys,
  mapValues,
  values,
} from 'lodash-es';
import {useFilters} from '@/Helpers/filters';
import DrawPartnerResource from '@/Model/Entity/DrawPartnerResource';
import {DateTime} from 'luxon';
import {WorkflowStepTypes} from '../../../types';
import {useStepTools} from '../../StepTools';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  components: {
    ToggleListMultiple,
    AsyncButton,
  },
  setup() {
    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryDraw>(WorkflowStepTypes.KorunkaDraw);

    const {dateFormat} = useFilters();

    const entityDrawIdsGroupedByDate = computed(() => {
      const entityDrawsGroupedByDate = groupBy(
        entity.value.draws,
        (draw) => draw.deadlineForBetsNoTime.toISOString(),
      );

      return mapValues(entityDrawsGroupedByDate, (draws) => map(draws, 'id'));
    });

    const entity = computed(() => workflowStep.value.entity as KorunkaLotterySestkaDtoCustom);

    const drawsByDate = computed(() => groupBy(
      workflowStep.value.draws,
      (draw) => draw.deadlineForBetsNoTime.toISOString(),
    ));

    const drawsById = computed(() => mapKeys(workflowStep.value.draws, 'id'));

    const updateModel = (value, dateAsISOString) => {
      let currentValue = entityDrawIdsGroupedByDate.value;

      if (isEmpty(value)) {
        currentValue = {
          ...currentValue,
          [dateAsISOString]: [],
        };
      } else if (has(currentValue, dateAsISOString)) {
        currentValue[dateAsISOString] = value;
      } else {
        currentValue = {
          ...currentValue,
          [dateAsISOString]: value,
        };
      }

      entity.value.draws = flow(
        (val) => values(val),
        (val) => flatten(val),
        (ids) => map(ids, (id) => drawsById.value?.[id]),
      )(currentValue);
    };

    const stringToDate = (date: string) => new Date(date);

    const entitiesToOptions = (entities: DrawPartnerResource[]) => {
      return map(entities, (entity) => ({
        id: entity.id,
        label: workflowStep.value.messages?.[entity.drawTypeAsMessage] ?? entity.type.value,
      }));
    };

    const selectDrawRange = (days: number) => {
      const today = DateTime.now();
      const nextDays = today.plus({days});

      entity.value.draws = filter(workflowStep.value.draws, (draw) => {
        const drawDate = DateTime.fromJSDate(draw.deadlineForBetsNoTime);

        return drawDate <= nextDays;
      });
    };

    const selectWeek = () => selectDrawRange(6);

    const selectMonth = () => selectDrawRange(29);

    const gridTemplateColumnsStyle = (entities: DrawPartnerResource[]) => {
      if (entities.length === 3) {
        return 'grid-template-columns: 100px 1fr 1fr 1fr';
      }

      if (entities.length === 2 || entities.length === 1) {
        return 'grid-template-columns: 100px 1fr 1fr';
      }

      return 'grid-template-columns: auto';
    };

    return {
      entity,
      dateFormat,
      stringToDate,
      entitiesToOptions,
      drawsByDate,
      entityDrawIdsGroupedByDate,
      updateModel,
      selectWeek,
      selectMonth,
      workflowStep,
      isEmpty,
      gridTemplateColumnsStyle,
    };
  },
});
