export enum DocumentTypes {
  CashDrawerState = 'CashDrawerState',
  CloseDay = 'CloseDay',
  CustomerDetail = 'CustomerDetail',
  DialogResult = 'DialogResult',
  Display = 'Display',
  FinDocument = 'FinDocument',
  FinancialCloseDay = 'FinancialCloseDay',
  GoodsDepreciationConsumption = 'GoodsDepreciationConsumption',
  GoodsDepreciationDisposal = 'GoodsDepreciationDisposal',
  GoodsOrderFromDC = 'GoodsOrderFromDC',
  GoodsOrderFromExternalSupplier = 'GoodsOrderFromExternalSupplier',
  GoodsReceiptFromDC = 'GoodsReceiptFromDC',
  GoodsReceiptFromExternalSupplier = 'GoodsReceiptFromExternalSupplier',
  GoodsReceiptFromPlant = 'GoodsReceiptFromPlant',
  GoodsReceiptFromPress = 'GoodsReceiptFromPress',
  GoodsReturnToDC = 'GoodsReturnToDC',
  GoodsReturnToExternalSupplier = 'GoodsReturnToExternalSupplier',
  GoodsReturnToPress = 'GoodsReturnToPress',
  GoodsTransferToPlant = 'GoodsTransferToPlant',
  InitPrinter = 'InitPrinter',
  InventoryDocument = 'InventoryDocument',
  InventoryFindDiffsDocument = 'InventoryFindDiffsDocument',
  InventorySummaryDocument = 'InventorySummaryDocument',
  LogisticDocument = 'LogisticDocument',
  LotteryCloseDay = 'LotteryCloseDay',
  NonFiscalDocument = 'NonFiscalDocument',
  OpenCashDrawer = 'OpenCashDrawer',
  PosPayment = 'POSPayment',
  PosPaymentCancel = 'POSPaymentCancel',
  PosPaymentRefund = 'POSPaymentRefund',
  PosPaymentZReport = 'POSPaymentZReport',
  PosSalesDocument = 'POSSalesDocument',
  PosStateDocument = 'POSStateDocument',
  PrintDocument = 'PrintDocument',
  SafeBag = 'Safebag',
  SafeBagSend = 'SafebagSend',
  SellDocument = 'SellDocument',
  StockBalancing = 'StockBalancing',
  StockReport = 'StockReport',
  TransferToNextDay = 'TransferToNextDay',
  InventoryPartial = 'InventoryPartial',
  InventoryFull = 'InventoryFull',
  AutomatedFollowUpDocument = 'AutomatedFollowUpDocument',
}
