import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value || _ctx.$slots.default)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["inline-flex items-center justify-center bg-warning text-basic-lightest rounded-full text-xs uppercase", {
      'py-0.5 px-2': !_ctx.sm,
      'px-1.5': _ctx.sm,
    }]),
        style: _normalizeStyle({
      ..._ctx.themeStyle,
      fontSize: _ctx.sm ? '0.55rem' : ''
    })
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ])
      ], 6))
    : _createCommentVNode("", true)
}