import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';

export default class BTCChargeRequestDtoCustom extends createEntity({
  paymentNumber: {
    type: new StringType(),
  },
  pin: {
    type: new StringType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
