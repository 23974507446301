import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.value || _ctx.$slots.default)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["inline-flex flex-col items-center justify-center bg-warning text-basic-lightest font-bold rounded-full", _ctx.size])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ])
      ], 2))
    : _createCommentVNode("", true)
}