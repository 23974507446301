import {Entity, Class} from '@designeo/apibundle-js/src/Entity/base';

type AnyEntity = Class<Entity<any, any>, any[]>

export type EntitySerialization<EntityType = string> = {
  entity: Entity<any, any>['_data'],
  entityType: EntityType,
}

export const deserializeEntity = <E extends Entity<any, any> = any>(val: EntitySerialization) => {
  let result;

  const resolve = (): E => {
    if (result) {
      return result;
    }

    return null;
  };

  const match = <GC extends AnyEntity>(Ctor: GC, entityType: EntitySerialization['entityType']) => {
    if (val?.entityType === entityType) {
      result = new (Ctor)(val?.entity ?? {});
    }

    return {
      match,
      resolve,
    };
  };

  return {
    match,
  };
};


export const serializeEntity = <
  E extends Entity<any, any> = any,
  T = any,
  ES extends EntitySerialization<T> = EntitySerialization<T>,
>(entity: E) => {
  let result;

  const resolve = (): ES => {
    if (result) {
      return {
        entity: result.entity.toJson(),
        entityType: result.entityType,
      } as ES;
    }

    return null;
  };

  const match = <GC extends AnyEntity>(Ctor: GC, entityType: EntitySerialization['entityType']) => {
    if (entity instanceof Ctor) {
      result = {
        entity,
        entityType,
      };
    }

    return {
      match,
      resolve,
    };
  };

  return {
    match,
  };
};
