import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinBalanceStock} from '../StepMixins/WorkflowStepMixinBalanceStock';

export class WorkflowStepBalanceStockOverview extends workflowStepMixinBalanceStock(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.BalanceStockOverview;
  }

  get type() {
    return WorkflowStepBalanceStockOverview.type;
  }

  get component() {
    return markRaw(require('./StepBalanceStockOverview.vue').default);
  }

  async beforeEnter() {
    if (!this.sellDocument.hasItems) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }
  }

  get transitions() {
    return {};
  }
}
