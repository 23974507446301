import {useRouter} from 'vue-router';

/**
 * TODO: rename file to display.ts and useSecondaryDisplay to useDisplay
 * refactor some conditions from isSecondaryDisplay to !isPrimaryDisplay or !isMainDisplay
 */


const isSecondaryDisplayPath = (path: string) => {
  return path.startsWith('/customer-external') || path.startsWith('/analog-display');
};

export const useSecondaryDisplay = ({router = useRouter()} = {}) => {
  if (!router) {
    const url = new URL(window.location.href);
    const path = url.hash.slice(1);
    return {
      currentRouterPath: {path},
      isSecondaryDisplay: isSecondaryDisplayPath(path),
    };
  }

  const currentRouterPath = router.resolve(window.location.hash.split('#')[1] ?? '/');

  return {
    router,
    currentRouterPath,
    isSecondaryDisplay: isSecondaryDisplayPath(currentRouterPath.path),
  };
};
