import PermissionTargetDtoCustom from '@/Model/Entity/custom/PermissionTargetDtoCustom';
import PermissionDto from '@/Model/Entity/PermissionDto';
import {intersection} from 'lodash-es';
import * as aclKeys from '@/constants/aclKeys';

export default class PermissionTargetDto extends PermissionTargetDtoCustom {
  public static create(name: keyof typeof aclKeys) {
    return new PermissionTargetDto({name});
  }

  withParameters(parameters: string[]) {
    this.parametersStrategy = 'some';
    this.parameters = parameters;
    return this;
  }

  withEveryStrategy() {
    this.parametersStrategy = 'every';
    return this;
  }

  withSomeStrategy() {
    this.parametersStrategy = 'some';
    return this;
  }

  isValidTargetOf(permission: PermissionDto) {
    if (this.name !== permission.name) {
      return false;
    }

    const targetParameters = this.parameters ?? [];
    const permissionParameters = permission.parameters ?? [];

    if (!targetParameters.length || !permissionParameters.length) {
      return true;
    }

    if (this.parametersStrategy === 'every') {
      return intersection(targetParameters, permissionParameters).length === targetParameters.length;
    }


    return !!intersection(targetParameters, permissionParameters).length;
  }
}
