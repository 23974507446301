import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {
  map,
  mapKeys,
  mapValues,
  min,
  reject,
  sumBy,
  isNil,
} from 'lodash-es';
import {action} from '@designeo/vue-helpers/src/index';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';
import {nominals} from '@/Helpers/nominals';
import {validationInvalidNominalsMinValue} from '@/Helpers/validations';

export class WorkflowStepNominalsCount extends workflowStepMixinNominals(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.NominalsCount;
  }

  get type() {
    return WorkflowStepNominalsCount.type;
  }

  get component() {
    return markRaw(require('./StepNominalsCount.vue').default);
  }

  get minValueValidationValue() {
    return this.step.minValue;
  }

  async beforeContinue() {
    if (!this.validateMinValue()) {
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.MIN_VALUE,
          args: {
            minValue: this.minValueValidationValue,
          },
        },
      }));

      throw new Error('invalid');
    }

    if (!await this.validate()) {
      throw new Error('invalid');
    }

    if (this.automaticMode) {
      this.setNominalFields();
    }
  }

  setNominalFields(value = this.total) {
    for (const nominal of this.availableNominals) {
      const decomposition = nominals(value, [nominal]);
      value -= decomposition.decomposableTotal;

      if (decomposition.decomposableTotal) {
        this.dataSetter(this.createNominalKey(nominal), () => decomposition.decomposableTotal / nominal);
      } else {
        this.dataSetter(this.createNominalKey(nominal), () => '');
      }
    }

    if (this.step.enterCoinsTogether) {
      this.dataSetter(WorkflowStepField.coins, () => value || '');
    }
  }

  get manualMode() {
    return this.getFieldValue(WorkflowStepField.manualMode, this.step.defaultMode === 'Manual');
  }

  get automaticMode() {
    return !this.manualMode;
  }

  set manualMode(val: boolean) {
    this.validate().then((result) => {
      if (!result) {
        return;
      }

      if (val) {
        this.setNominalFields();
      } else {
        this.dataSetter(WorkflowStepField.value, () => this.total);
      }

      this.dataSetter(WorkflowStepField.manualMode, () => val);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
        detail: {
          field: val ? this.fields[0] : WorkflowStepField.value,
        },
      }));
    });
  }

  get valueAsNominals() {
    let value = parseFloat(this.getFieldValue(WorkflowStepField.value, '0') || '0') ?? 0;
    return [
      ...reject(map(this.availableNominals, (nominal) => {
        const decomposition = nominals(value, [nominal]);
        value -= decomposition.decomposableTotal;
        return {
          nominal,
          amount: decomposition.decomposableTotal / nominal,
          coins: false,
        };
      }), {amount: 0}),
      ...(this.step.enterCoinsTogether && value ? [
        ...reject(map(this.acceptableNominalsOnlyCoins, (nominal) => {
          const decomposition = nominals(value, [nominal]);
          value -= decomposition.decomposableTotal;
          return {
            nominal,
            amount: decomposition.decomposableTotal / nominal,
            coins: false,
          };
        }), {amount: 0}),
      ] : []),
    ];
  }

  get total() {
    if (this.automaticMode) {
      return sumBy(this.valueAsNominals, ({nominal, amount}) => amount * nominal);
    }

    return super.total;
  }

  get validator(): yup.AnyObjectSchema {
    if (this.automaticMode) {
      return yup.object().shape({
        [WorkflowStepField.value]: yup.string()
          .test(this.createInvalidNominalsCountMaxQuantityValidation(WorkflowStepField.value))
          .test(validationInvalidNominalsMinValue(this.currency, {acceptZero: true})),
      });
    }

    return yup.object().shape({
      ...mapValues(mapKeys(this.fields), (val, field) => {
        if (this.step.enterCoinsTogether && field === WorkflowStepField.coins) {
          return yup.string()
            .test(this.createInvalidNominalsCountMaxQuantityValidation(field))
            .test(validationInvalidNominalsMinValue(this.currency, {
              acceptableNominals: this.currency.nominals,
              smallestAcceptedNominal: min(this.currency.nominals),
              acceptZero: true,
            }));
        }

        return yup.string()
          .test(this.createInvalidNominalsCountMaxQuantityValidation(field));
      }),
    });
  }

  validateMinValue() {
    if (isNil(this.minValueValidationValue)) {
      return true;
    }

    return this.total >= this.minValueValidationValue;
  }

  get transitions() {
    if (this.automaticMode) {
      return {
        ...this.withFieldActions(WorkflowStepField.value, (fieldActions) => ({
          [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          }),
          [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
            if (this.getFieldValue(WorkflowStepField.value, '').includes('.')) return;
            fieldActions[WorkflowActions.ADD_COMMA](event.value);
          }),
          [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
            if (this.getFieldValue(WorkflowStepField.value, '').includes('.')) return;
            fieldActions[WorkflowActions.ADD_PERIOD](event.value);
          }),
          [WorkflowActions.BACKSPACE]: action(() => {
            fieldActions[WorkflowActions.BACKSPACE]();
          }),
          /**
           * TODO configurable?
           */
          // [WorkflowActions.ADD_MINUS]: action(() => {
          //   if (!this.getFieldValue(WorkflowStepField.value, '').length) {
          //     fieldActions[WorkflowActions.ADD_MINUS]();
          //   }
          // }),
          [WorkflowActions.ENTER]: action(async () => {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
          }),
        })),
      };
    }

    const fields = this.fields;

    return {
      ...map(fields, (field, index) => {
        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_CHAR](event.value);
            }),
            ...(field === WorkflowStepField.coins ? {
              [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
                if (this.getFieldValue(field, '').includes('.')) return;
                fieldActions[WorkflowActions.ADD_COMMA](event.value);
              }),
              [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
                if (this.getFieldValue(field, '').includes('.')) return;
                fieldActions[WorkflowActions.ADD_PERIOD](event.value);
              }),
            } : {}),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            /**
             * TODO configurable?
             */
            // [WorkflowActions.ADD_MINUS]: action(() => {
            //   if (!this.getFieldValue(field, '').length) {
            //     fieldActions[WorkflowActions.ADD_MINUS]();
            //   }
            // }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = fields[index + 1] ?? fields[index];

              if (fields.length - 1 === index) {
                this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
              } else {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                  detail: {
                    field: nextField,
                  },
                }));
              }
            }),
            ...this.createArrowMovementTransitions(fields, index),
          })),
        };
      }).reduce((acc, val) => ({...acc, ...val}), {}),
    };
  }
}
