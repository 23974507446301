
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import {
  WorkflowActions,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {
  WorkflowStepWarehouseOperationVendor,
} from '@/Modules/Workflow/Step/StepWarehouseOperationVendor/WorkflowStepWarehouseOperationVendor';
import {apiInStoreManagementVendorList} from '@/Model/Action';
import VendorDto from '@/Model/Entity/VendorDto';
import WarehouseOperationVendorList
  from '@/Modules/Workflow/Step/StepWarehouseOperationVendor/WarehouseOperationVendorList.vue';
import {debounce, map} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import FormInput from '@/Components/FormInput.vue';

export default defineComponent({
  name: 'StepWarehouseOperationVendor',
  components: {
    FormInput,
    HLInput,
    WarehouseOperationVendorList,
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.WarehouseOperationVendor;
    const {workflowStep, currentWorkflow} = useStepTools<WorkflowStepWarehouseOperationVendor>(stepType);
    const managementVendorList = ref<VendorDto[]>([]);

    const fetchVendorList = async () => {
      try {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

        managementVendorList.value = await apiInStoreManagementVendorList({
          params: {
            ...(workflowStep.value.searchString.length < 2 ? {} : {
              name: workflowStep.value.searchString,
            }),
          },
        });
      } catch (e) {
        managementVendorList.value = [];
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      } finally {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    };

    const debouncedFetchVendorList = debounce(fetchVendorList, 400);

    const vendors = computed(() => {
      return map(managementVendorList.value, (listItem: VendorDto) => {
        return {
          entity: listItem,
          isActive: workflowStep.value.operationEntityPropVendor?.vendor === listItem.vendor,
        };
      });
    });

    const onVendorSelect = (vendor: VendorDto) => {
      workflowStep.value.operationEntityPropVendor = vendor;
    };

    onMounted(async () => {
      workflowStep.value.messageBus.addEventListener(WorkflowActions.ENTER, debouncedFetchVendorList);
      await fetchVendorList();
    });

    onBeforeUnmount(() => {
      if (!currentWorkflow.value) {
        return;
      }

      workflowStep.value.messageBus.removeEventListener(WorkflowActions.ENTER, debouncedFetchVendorList);
    });

    const searchField = computed(() => {
      return workflowStep.value.createFieldDataPath(WorkflowStepField.searchString);
    });

    watch(() => workflowStep.value.searchString, () => {
      debouncedFetchVendorList();
    }, {flush: 'post'});

    return {
      stepType,
      vendors,
      onVendorSelect,
      searchField,
    };
  },
});
