import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({abortWorkflow, workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.cardNumber
          }, {
            default: _withCtx(({isActive, value, setActive}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.cardNumber,
                active: isActive,
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value.value), 1)
                ]),
                _: 2
              }, 1032, ["label", "active", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AsyncButton, {
              class: "btn-basic",
              onClick: abortWorkflow
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(workflowStep.step.abort), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _createVNode(_component_WorkflowTransitions, {
          nextDisabled: workflowStep.disabledNextStep
        }, null, 8, ["nextDisabled"])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}