export enum WorkflowCodes {
  'btcBuy' = 'btcBuy',
  'btcSell' = 'btcSell',
  'drawerCashStateValidationCzk' = 'drawerCashStateValidationCzk',
  'drawerCashStateValidationEur' = 'drawerCashStateValidationEur',
  'customerAddCard' = 'customerAddCard',
  'customerPhoneChange' = 'customerPhoneChange',
  'customerRegistration' = 'customerRegistration',
  'customerUpdate' = 'customerUpdate',
  'customerUpdateAdditionalInformation' = 'customerUpdateAdditionalInformation',
  'customerVerification' = 'customerVerification',
  'financialReport' = 'financialReport',
  'financialStatement' = 'financialStatement',
  'lotteryTerminalClosure' = 'lotteryTerminalClosure',
  'korunkaLottery' = 'korunkaLottery',
  'safeBagCreateCzk' = 'safeBagCreateCzk',
  'safeBagCreateEur' = 'safeBagCreateEur',
  'transaction_FIN_IN_SAFE_TO_DRAWER_CZK' = 'transaction_FIN_IN_SAFE_TO_DRAWER_CZK',
  'transaction_FIN_OUT_BUY_MATERIAL_CZK_VAT21' = 'transaction_FIN_OUT_BUY_MATERIAL_CZK_VAT21',
  'transaction_FIN_OUT_DRAWER_TO_SAFE_CZK' = 'transaction_FIN_OUT_DRAWER_TO_SAFE_CZK',
  'stockBalancing' = 'stockBalancing',
  'eshopOrderPickUp' = 'eshopOrderPickUp',
  'eshopOrderStorno' = 'eshopOrderStorno',

  // Korunka Workflows
  'korunkaLotteryScanTicket' = 'korunkaLotteryScanTicket',
  'korunkaLotteryScanCard' = 'korunkaLotteryScanCard',
  'korunkaLotteryCheckTicket' = 'korunkaLotteryCheckTicket',
  'korunkaLotteryPayoutTicket' = 'korunkaLotteryPayoutTicket',
  'korunkaLotteryCancelTicket' = 'korunkaLotteryCancelTicket',

  'korunkaLotterySestka' = 'korunkaLotterySestka',
  'korunkaLotteryDvacetZa20' = 'korunkaLotteryDvacetZa20',
  'korunkaLotteryHopTrop' = 'korunkaLotteryHopTrop',
  'korunkaLotteryKombi' = 'korunkaLotteryKombi',
  'korunkaLotteryKombiPlus' = 'korunkaLotteryKombiPlus',
}


export enum WorkflowStepTypes {
  ApiAddCustomerCard = 'ApiAddCustomerCard',
  ApiBTCCheck = 'ApiBTCCheck',
  ApiCreateTransaction = 'ApiCreateTransaction',
  ApiCreateWarehouseOperation = 'ApiCreateWarehouseOperation',
  ApiGenerateAndSendPin = 'ApiGenerateAndSendPin',
  ApiLogLotteryCloseDayFinish = 'ApiLogLotteryCloseDayFinish',
  ApiLotteryArticleCalculations = 'ApiLotteryArticleCalculations',
  ApiLotteryTurnovers = 'ApiLotteryTurnovers',
  ApiPosPaymentZReport = 'ApiPosPaymentZReport',
  ApiRegisterCustomer = 'ApiRegisterCustomer',
  ApiRequestCashState = 'ApiRequestCashState',
  ApiSafeBagSend = 'ApiSafeBagSend',
  ApiUpdateCustomer = 'ApiUpdateCustomer',
  ApiUpdateCustomerPhone = 'ApiUpdateCustomerPhone',
  ApiSafeBagCreateFinDocument = 'ApiSafeBagCreateFinDocument',
  ApiSafeBagCreateDiffDocument = 'ApiSafeBagCreateDiffDocument',
  ApiFetchStock = 'ApiFetchStock',
  ApiCreateBalanceStockSellDocument = 'ApiCreateBalanceStockSellDocument',

  EshopOrderCreateFinDocument = 'EshopOrderCreateFinDocument',

  WarehouseOperationAdditionalInfo = 'WarehouseOperationAdditionalInfo',
  WarehouseOperationConfirm = 'WarehouseOperationConfirm',
  WarehouseOperationDistributionCenter = 'WarehouseOperationDistributionCenter',
  WarehouseOperationGoodsList = 'WarehouseOperationGoodsList',
  WarehouseOperationList = 'WarehouseOperationList',
  WarehouseOperationPlant = 'WarehouseOperationPlant',
  WarehouseOperationPrint = 'WarehouseOperationPrint',
  WarehouseOperationSetup = 'WarehouseOperationSetup',
  WarehouseOperationVendor = 'WarehouseOperationVendor',
  BTCConditionsAgreement = 'BTCConditionsAgreement',
  BTCEnterOrderNumber = 'BTCEnterOrderNumber',
  BTCEnterOrderPin = 'BTCEnterOrderPin',
  BTCCharge = 'BTCCharge',
  BTCCheck = 'BTCCheck',
  BTCTransactionCheck = 'BTCTransactionCheck',
  BTCTransactionOverview = 'BTCTransactionOverview',
  ConfirmGdprConditions = 'ConfirmGdprConditions',
  ContinueWithoutCustomerCard = 'ContinueWithoutCustomerCard',
  CreateFinancialReport = 'CreateFinancialReport',
  DrawerCashStateResult = 'DrawerCashStateResult',
  EnterAmount = 'EnterAmount',
  EnterCustomerCard = 'EnterCustomerCard',
  EnterCustomerInformation = 'EnterCustomerInformation',
  EnterCustomerPin = 'EnterCustomerPin',
  EnterLotteryArticles = 'EnterLotteryArticles',
  EnterLotteryCategories = 'EnterLotteryCategories',
  EnterLotteryCategoryWithArticles = 'EnterLotteryCategoryWithArticles',
  EnterLotteryOverview = 'EnterLotteryOverview',
  EnterLotterySum = 'EnterLotterySum',
  EnterNote = 'EnterNote',
  EnterTransactionAmount = 'EnterTransactionAmount',
  EshopOrderOverview = 'EshopOrderOverview',
  EshopPayment = 'EshopPayment',
  EshopPinVerification = 'EshopPinVerification',
  GenericConfirm = 'GenericConfirm',
  GenericSuccess = 'GenericSuccess',
  CheckFinancialReportAvailable = 'CheckFinancialReportAvailable',
  CheckLotteryFinancialReport = 'CheckLotteryFinancialReport',
  InvalidConfiguration = 'InvalidConfiguration',
  KorunkaCheck = 'KorunkaCheck',
  KorunkaLottery = 'KorunkaLottery',
  LotterySuccess = 'LotterySuccess',
  ManualCheck = 'ManualCheck',
  ManualCheckTotalAmount = 'ManualCheckTotalAmount',
  NominalsCount = 'NominalsCount',
  SafeBagDeliveryDriver = 'SafeBagDeliveryDriver',
  SafeBagDeliveryListOverview = 'SafeBagDeliveryListOverview',
  SafeBagEnterNominalsCount = 'SafeBagEnterNominalsCount',
  SafeBagEditNominalsCount = 'SafeBagEditNominalsCount',
  SafeBagPickup = 'SafeBagPickup',
  SafeBagPrintDocument = 'SafeBagPrintDocument',
  SafeBagSaveToSap = 'SafeBagSaveToSap',
  SafeBagCreate = 'SafeBagCreate',
  SafeBagScanBarcode = 'SafeBagScanBarcode',
  SafeBagEnterBarcode = 'SafeBagEnterBarcode',
  SafeBagScanDeliveryList = 'SafeBagScanDeliveryList',
  SafeBagScanSafeBags = 'SafeBagScanSafeBags',
  SafebagTransfer = 'SafebagTransfer',
  SafeBagValidate = 'SafeBagValidate',
  SafeBagOverview = 'SafeBagOverview',
  SafeTransfer = 'SafeTransfer',
  StockCheck = 'StockCheck',
  UpdateCustomerAdditionalInformation = 'UpdateCustomerAdditionalInformation',
  UpdateCustomerInformation = 'UpdateCustomerInformation',
  UpdateCustomerPhone = 'UpdateCustomerPhone',
  WorkflowValidations = 'WorkflowValidations',
  CheckBalanceStockAvailable = 'CheckBalanceStockAvailable',
  BalanceStockEdit = 'BalanceStockEdit',
  BalanceStockOverview = 'BalanceStockOverview',
  BalanceStockSuccess = 'BalanceStockSuccess',
  PaymentSelect = 'PaymentSelect',
  Payment = 'Payment',
  PaymentCreateFinDocument = 'PaymentCreateFinDocument',
  EshopOrderStateChange = 'EshopOrderStateChange',
  EshopOrderStornoReason = 'EshopOrderStornoReason',
  EshopOrderCustomerRegistration = 'EshopOrderCustomerRegistration',
  EshopCustomerVerification = 'EshopCustomerVerification',
  TransactionToPayment = 'TransactionToPayment',

  // Korunka StepTypes
  KorunkaScanTicket = 'KorunkaLotteryScanTicket',
  KorunkaScanCard = 'KorunkaLotteryScanCard',
  KorunkaCardOverview = 'KorunkaLotteryCardOverview',
  KorunkaSetup = 'KorunkaLotterySetup',
  KorunkaNumbers = 'KorunkaLotteryNumbers',
  KorunkaDeposit = 'KorunkaLotteryDeposit',
  KorunkaDraw = 'KorunkaLotteryDraw',
  KorunkaOverview = 'KorunkaLotteryOverview',
  KorunkaEnterCode = 'KorunkaLotteryEnterCode',
  KorunkaTicketResult = 'KorunkaLotteryTicketResult',

  // Korunka API StepTypes
  ApiKorunkaLotteryGetTicket = 'ApiKorunkaLotteryGetTicket',

  ApiKorunkaLotteryGetPayoutArticle = 'ApiKorunkaLotteryGetPayoutArticle',
}

export enum WorkflowStepField {
  // common reusable fields
  amount = 'amount',
  attributes = 'attributes',
  cardNumber = 'cardNumber',
  coins = 'coins',
  conditions = 'conditions',
  currency = 'currency',
  customerNumber = 'customerNumber',
  detail = 'detail',
  document = 'document',
  email = 'email',
  entity = 'entity',
  firstName = 'firstName',
  gdpr = 'gdpr',
  label = 'label',
  lastName = 'lastName',
  nominal = 'nominal',
  note = 'note',
  name = 'name',
  orderNumber = 'orderNumber',
  orderPin = 'orderPin',
  quantity = 'quantity',
  phone = 'phone',
  searchString = 'searchString',
  sex = 'sex',
  state = 'state',
  previousState = 'previousState',
  type = 'type',
  value = 'value',
  yearOfBirth = 'yearOfBirth',
  // checkResponse = 'checkResponse',
  articleBucketKey = 'articleBucketKey',
  entityBucketKey = 'entityBucketKey',
  safeBagDocument = 'safeBagDocument',
  safeBagDocumentEdited = 'safeBagDocumentEdited',
  stock = 'stock',
  balanceStockItem = 'balanceStockItem',
  payments = 'payments',

  skip = 'skip',
  manualMode = 'manualMode',
  stepPositions = 'stepPositions',

  // payment fields
  paymentMethod = 'paymentMethod',
  paymentValue = 'paymentValue',
  paymentTransitionEnvelope = 'paymentTransitionEnvelope',
  paymentRequests = 'paymentRequests',
  paymentFinDocument = 'paymentFinDocument',

  // specific fields
  btcDisplayError = 'btcDisplayError',
  bypassCardNumber = 'bypassCardNumber',
  safeAmount = 'safeAmount',
  safeBagEnvelopeCode = 'safeBagEnvelopeCode',
  safeBagDeliveryList = 'safeBagDeliveryList',
  safeBagDeliveryListCode = 'safeBagDeliveryListCode',
  lotteryTotalSellAmount = 'lotteryTotalSellAmount',
  lotteryAmountEquals = 'lotteryAmountEquals',
  lotteryTurnovers = 'lotteryTurnovers',
  lotteryCategories = 'lotteryCategories',
  lotteryArticlesFromInaccurateCategories = 'lotteryArticlesFromInaccurateCategories',
  lotteryArticles = 'lotteryArticles',
  lotteryCorrectionDocumentId = 'lotteryCorrectionDocumentId',
  lotteryDocumentTotalAmount = 'lotteryDocumentTotalAmount',
  inaccurateArticlesByCategory = 'inaccurateArticlesByCategory',
  cashState = 'cashState',
  nominalsSettings = 'nominalsSettings',
  orderStornoReasonId = 'orderStornoReasonId',
  orderStornoReason = 'orderStornoReason',

  // Korunka fields
  korunkaType = 'korunkaType',
  customerDisplayMessages = 'customerDisplayMessages',
  code = 'code',
  ticket = 'ticket',
  customDepositToggle = 'customDepositToggle',
  customDepositValue = 'customDepositValue',
  scanFinished = 'scanFinished',

  // Eshop fields
  pinVerificationDate = 'pinVerificationDate',
  customerVerificationDate = 'customerVerificationDate',
  financialDocumentReferenceId = 'financialDocumentReferenceId',
}

export enum WorkflowActions {
  SCANNER='scanner',
  ADD_CHAR='addChar',
  ADD_NUMBER='addNumber',
  ADD_COMMA='addComma',
  ADD_PERIOD='addPeriod',
  ADD_PLUS='addPlus',
  ADD_MINUS='addMinus',
  PREV='prev',
  NEXT='next',
  ENTER='enter',
  BACKSPACE='backspace',
  CLEAR='clear',
  CANCEL='cancel',
  REPEAT='repeat',
  ARROW_DOWN='arrowDown',
  ARROW_UP='arrowUp',
}

export type WorkflowInputEvent<ValueType = any> = {
  type: WorkflowActions,
  value?: ValueType
}

export enum WorkflowEvents {
  EXIT='exit',
}

export enum WorkflowKeyboards {
  NUMERIC = 'numeric',
  QWERTY = 'qwerty',
}

export enum WorkflowStepEvents {
  CHANGE_ACTIVE_FIELD = 'changeActiveField',
  NEXT = 'next',
  PREV = 'prev',
  SET_ACTIVE_STEP_BY_CODE = 'setActiveStepByCode',
  SET_ACTIVE_STEP_BY_INDEX = 'setActiveStepByIndex',
  SET_REFERER_QUERY_ARGS = 'setRefererQueryArg',
  ERROR = 'error',
  ABORT_WORKFLOW = 'abortWorkflow',
  CHANGE_WORKFLOW = 'changeWorkflow',
  EXIT = 'exit',
  POINT_OF_NO_RETURN = 'pointOfNoReturn',
  METHOD_EXECUTE = 'methodExecute',
}

export enum WorkflowStepErrors {
  GDPR_DECLINED = 'gdprDeclined',
  CUSTOMER_INFORMATION_CONFIRMATION_FAILED = 'customerInformationConfirmationFailed',
  USER_CREATE_FAILED = 'userCreateFailed',
  USER_UPDATE_FAILED = 'userUpdateFailed',
  PIN_VERIFICATION_FAILED = 'pinVerificationFailed',
  USER_UPDATE_CONTACT_FAILED = 'userUpdateContactFailed',
  DOCUMENT_CREATE_FAILED = 'documentCreateFailed',
  LOTTERY_INVALID_QUANTITY = 'lotteryInvalidQuantity',
  PRINT_FAILED = 'printFailed',
  TRANSACTION_LIMIT_EXCEEDED = 'transactionLimitExceeded',
  INVALID_SAFE_BAG_CODE = 'invalidSafeBagCode',
  FINANCIAL_REPORT_NOT_AVAILABLE = 'financialReportNotAvailable',
  INVALID_CARD_NUMBER = 'invalidCardNumber',
  CONDITIONS_DECLINED = 'conditionsDeclined',
  LOTTERY_TURNOVERS_FETCH_FAILED = 'lotteryTurnoversFetchFailed',
  INVALID_SAFEBAG_ENVELOPE_CODE = 'invalidSafeBagEnvelopeCode',
  TRANSPORT_LIST_IS_EMPTY = 'transportListIsEmpty',
  MIN_VALUE = 'minValue',
  MAX_VALUE = 'maxValue',
  SET_CAN_NOT_BE_ADDED = 'setCanNotBeAdded',
  SAFEBAG_ALREADY_SENT = 'safeBagAlreadySent',
  SAFEBAG_DOESNT_EXIST = 'safeBagDoesntExist',
  BALANCE_STOCK_NOT_AVAILABLE = 'balanceStockNotAvailable',
  STOCK_FETCH_FAILED = 'stockFetchFailed',
  STOCK_FETCH_FAILED_MISSING_ITEMS = 'stockFetchFailedMissingItems',
  KORUNKA_CANNOT_CANCEL_TICKET = 'korunkaCannotCancelTicket',
  KORUNKA_CANNOT_PAYOUT_TICKET = 'korunkaCannotPayoutTicket',
  INVALID_ESHOP_ORDER_FIN_DOCUMENT_STATE = 'invalidEshopOrderFinDocumentState',
  KORUNKA_UNUPPORTED_GAME_TYPE = 'korunkaUnsupportedGameType',
}

export enum WorkflowStepUIModes {
  everywhere = 'everywhere',
  atTitle = 'atTitle',
  atFooter = 'atFooter',
  none = 'none',
}

export enum WorkflowStepBTCDirection {
  BUY = 'buy',
  SELL = 'sell'
}
