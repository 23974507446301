import {
  createEntity,
  EntityType,
} from '@designeo/apibundle-js';
import {
  WarehouseOperationLogisticDocumentAttributes,
  WarehouseOperationBaseAttributes,
  WarehouseOperationFilterAttributes,
  WarehouseOperationPatch,
  WarehouseOperationSummaryAttributes,
} from '@/Model/Entity/patches/WarehouseOperationMixin';
import {PlantDto} from '@/Model/Entity';

export default class WarehouseOperationReloadingOfGoodsTypeDispenseDtoCustom extends WarehouseOperationPatch(
  createEntity({
    ...WarehouseOperationBaseAttributes,
    ...WarehouseOperationLogisticDocumentAttributes,
    ...WarehouseOperationSummaryAttributes,
    sourcePlant: {
      type: new EntityType(PlantDto),
    },
    targetPlant: {
      type: new EntityType(PlantDto),
    },
  }),
) {}
