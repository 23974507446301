import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleDataError from '../ArticleDataError';
import AssortmentInfoDto from '../AssortmentInfoDto';


export const ArticleResultDtoPropDef = {
  dataErrors: {
    type: new ArrayType(new EntityType(ArticleDataError)),
  },
  assortmentInfo: {
    type: new EntityType(AssortmentInfoDto),
  },
};
export default class GeneratedArticleResultDto extends createEntity(ArticleResultDtoPropDef) {}
