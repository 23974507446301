import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import MessageDto from '../MessageDto';
import {NumberType} from '@designeo/apibundle-js';


export const MessageDtoIPagingObjectPropDef = {
  data: {
    type: new ArrayType(new EntityType(MessageDto)),
  },
  limit: {
    type: new NumberType(),
  },
  offset: {
    type: new NumberType(),
  },
  total: {
    type: new NumberType(),
  },
};
export default class GeneratedMessageDtoIPagingObject extends createEntity(MessageDtoIPagingObjectPropDef) {}
