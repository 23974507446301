import GeneratedArticlePreviewDto from './generated/ArticlePreviewDto';
import {createBatchKey} from '@/Helpers/batch';
import {
  first,
  groupBy,
  isNil,
} from 'lodash-es';

enum SapPriceConditionCodes {
  priceAction = 'VKA0',
  priceNormal = 'VKP0',
  priceRecyclingFee = 'ZRPO'
}

export default class ArticlePreviewDto extends GeneratedArticlePreviewDto {
  get internalNumberWithBatch() {
    return this.batch ? createBatchKey({internalNumber: this.internalNumber, batch: this.batch.id}) : this.internalNumber;
  }

  get priceNormalListItem() {
    return this.getPriceListItemPriceBySapPriceConditionCode(SapPriceConditionCodes.priceNormal);
  }

  get priceListItemsByPriceConditionCode() {
    return groupBy(this.priceListItems, 'priceConditionCode');
  }

  getPriceListItemPriceBySapPriceConditionCode(code: SapPriceConditionCodes) {
    const value = first(this.priceListItemsByPriceConditionCode[code]);

    if (isNil(value)) {
      return null;
    }

    return value;
  }
}
