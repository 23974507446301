import {
  Configuration,
  CreateErrorDocumentDto,
  DocumentDto,
} from '@/Model/Entity';
import PromotionBulkDto from '../Model/Entity/PromotionBulkDto';
import {RouteRecordRaw} from 'vue-router';
import {clearStorage} from '@/Helpers/app';
import {flatten, map} from 'lodash-es';
import {ConsoleCollector} from '@/Modules/Core/store/RestoreModeStore';
import {uploadFile} from '@designeo/js-helpers/src/file/upload';
import {asyncStorage} from './asyncStore';

export function isRestoreMode() {
  try {
    return new URL(window.location.href).pathname.startsWith('/restore-mode');
  } catch (e) {
    return false;
  }
}

export interface IRestoredErrorRecord {
  id: string
  document: DocumentDto['_data']
  error: CreateErrorDocumentDto['_data']
  // eslint-disable-next-line camelcase
  created_at: string
  service: string
  state: {
    promotions: PromotionBulkDto['_data']
    configuration: Configuration['_data']
    currentRoute: RouteRecordRaw
    indexedDB: {[key: string]: any}
    localStorage: {[key: string]: any}
    technicalStatus: {[key: string]: any}
    console: {[key: string]: any}
  }
}

function restoreStorage(storage: Storage, data) {
  for (const storageRecordKey of Object.keys(data)) {
    storage.setItem(storageRecordKey, data[storageRecordKey]);
  }
}

export function restoreModeStart() {
  window.location.href = '/restore-mode';
}

export async function restoreModeExit() {
  const savedLocalStorage = localStorage.getItem('savedLocalStorage');
  const savedSessionStorage = sessionStorage.getItem('savedSessionStorage');
  const savedAsyncStorage = asyncStorage.getItem('savedAsyncStorage');

  await clearStorage();

  if (savedLocalStorage) {
    restoreStorage(window.localStorage, JSON.parse(savedLocalStorage));
  }

  if (savedSessionStorage) {
    restoreStorage(window.sessionStorage, JSON.parse(savedSessionStorage));
  }

  if (savedAsyncStorage) {
    await asyncStorage.deserialize(JSON.parse(savedAsyncStorage));
  }

  window.location.href = '/';
}

export function requestRestoreData(): Promise<IRestoredErrorRecord> {
  const restoreRecord = async (record: IRestoredErrorRecord) => {
    // @ts-ignore
    window?.clear?.();
    console.warn('[RestoreMode] error record submitted');
    /**
     * {promotions, configuration, currentRoute} are going to be registered down the pipeline
     */

    const savedLocalStorage = window.localStorage.getItem('savedLocalStorage') ||
      JSON.stringify(localStorage);
    const savedSessionStorage = window.sessionStorage.getItem('savedSessionStorage') ||
      JSON.stringify(sessionStorage);
    const savedAsyncStorage = asyncStorage.getItem('savedAsyncStorage') ||
      JSON.stringify(asyncStorage.serialize());

    await clearStorage();

    restoreStorage(window.localStorage, record.state.localStorage);
    await asyncStorage.deserialize(record.state.indexedDB);


    window.localStorage.setItem('savedLocalStorage', savedLocalStorage);
    window.sessionStorage.setItem('savedSessionStorage', savedSessionStorage);
    await asyncStorage.setItem('savedAsyncStorage', savedAsyncStorage);

    console.warn('[RestoreMode] localStorage restored');

    // eslint-disable-next-line no-console
    console.groupCollapsed(`[RestoreMode] console.{error, warn}`);

    for (const item of record.state.console.history) {
      // eslint-disable-next-line no-console
      console.log(`[RestoreMode] ${item.type} record from ${item.date}`);
      // eslint-disable-next-line no-console
      console[item.type](...flatten(map(item.args, (arg) => {
        const deserializedArg = ConsoleCollector.deserializeArg(arg);

        if (deserializedArg instanceof Error) {
          return [deserializedArg, {error: deserializedArg}];
        }

        return deserializedArg;
      })));
    }

    // eslint-disable-next-line no-console
    console.groupEnd();

    return record;
  };

  return new Promise((resolve) => {
    console.warn(`For continue insert error document record as 'window.restoreErrorRecord(record)'`);
    Object.defineProperties(window, {
      restoreErrorRecord: {
        value: async (record) => {
          if (record) {
            resolve(await restoreRecord(record));
            return;
          }

          const [file] = await uploadFile({
            accept: 'application/json',
            multiple: false,
            resolveCanceled: false,
          });

          const fileAsText = await new Promise<string>((resolve) => {
            const reader = new FileReader();

            reader.addEventListener(
              'load',
              () => {
                resolve(reader.result as string);
              },
              false,
            );

            if (file) {
              reader.readAsText(file);
            }
          });

          resolve(await restoreRecord(JSON.parse(fileAsText)));
        },
      },
    });
  });
}
