import {Context} from '@/Helpers/Context';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {submitJournalEventPrintStart} from '@/Helpers/journal';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {RegisterStoreErrors, RegisterStoreEvent} from '@/Modules/Register/types';
import {
  filter,
  first,
  flatten,
  flow,
  map,
  reject,
  some,
} from 'lodash-es';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {wait} from '@designeo/js-helpers';
import {sentryRecordSellDocumentCreate} from '@/Helpers/sentry';
import {useRestoreModeStore} from '@/Modules/Core/store/RestoreModeStore';
import {DocumentCreateMode} from '@/constants/documentModeTypes';
import {
  DocumentContentDto,
  DocumentDto,
  PrintCheck,
} from '@/Model/Entity';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  apiDocumentCreate,
  apiDocumentFinishWithPdfPrint,
  apiDocumentRepeatablePrinting,
} from '@/Model/Action';
import RepeatablePrintingCommand from '../../../Model/Entity/RepeatablePrintingCommand';
import {PrintoutTypes} from '@/constants/printoutTypes';
import {OutputTypes} from '@/constants/outputTypes';
import {PromotionMetaType} from '@designeo/pos-promotion-engine';
import {usePrinterStatusStore} from '@/Modules/Core/store/PrinterStatusStore';
import {PrintCheckTypes} from '@/constants/printCheckTypes';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';
import {broadcastIO} from '@/Helpers/broadcastIO';

export type ExecutorArgs = [SaveFlowQueueItem, number, SaveFlow]

export type DocumentSubmitProcessResult<D extends ('S' | 'D') = 'S'> = {
  created: boolean,
  printed: boolean,
} & (D extends 'S' ? {
  result: PrinterResult,
  error: Error,
} : {
  result: PrinterResult['_data'],
  error: ReturnType<typeof serializeError>,
})

const getInitState = () => ({
  queue: null,
  stepByStep: false,
});

export enum SaveFlowQueueItemType {
  ensureCheckAndCharge = 'ensureCheckAndCharge',
  saveSellDocument = 'saveSellDocument',
  printStandaloneDocument = 'printStandaloneDocument',
  postPurchaseProcesses = 'postPurchaseProcesses',
}

export enum SaveFlowQueueItemCallbackType {
  standalonePrints = 'standalonePrints',
}

function serializeError(error: Error) {
  return {
    name: error.name,
    message: error.message,
    stack: error.stack,
  };
}

function deserializeError(errorLike: {
  name: string,
  message: string,
  stack: string,
}) {
  const err = new Error(errorLike.message);
  err.name = errorLike.name;
  err.stack = errorLike.stack;

  return err;
}

type SaveFlowQueueItemSerialized<RR extends any = any, M extends any = any> = {
  type: SaveFlowQueueItemType,
  finished: boolean
  error: {
    name: string,
    message: string,
    stack: string,
  },
  result: RR,
  metadata: M,
}

export class SaveFlowQueueItem<R extends any = any, M extends any = any> {
  public type: SaveFlowQueueItemType
  public finished: boolean
  public error: Error
  public result: R
  public metadata: M
  public executorCallbacks: Array<{metadata?: any, type: SaveFlowQueueItemCallbackType}> = []
  private readonly executor: (...args: ExecutorArgs)=> Promise<any>;
  constructor(
    type: SaveFlowQueueItemType,
    {
      metadata = null,
    }: {
      metadata?: M
    } = {},
  ) {
    this.type = type;
    this.executor = SaveFlowQueueItem.getExecutorByType(type).bind(this);
    this.metadata = metadata;
  }

  public static queueItemFromCommonData(queueItemData) {
    const queueItem = new SaveFlowQueueItem(queueItemData.type);

    queueItem.finished = queueItemData.finished;
    queueItem.error = queueItemData.error ? deserializeError(queueItemData.error) : null;
    queueItem.executorCallbacks = queueItemData.executorCallbacks;

    return queueItem;
  }
  public static queueItemToCommonData(queueItem: SaveFlowQueueItem) {
    return {
      type: queueItem.type,
      finished: queueItem.finished,
      executorCallbacks: queueItem.executorCallbacks,
      error: queueItem.error ? serializeError(queueItem.error) : null,
    };
  }

  public static deserializeQueueItem(queueItemData: SaveFlowQueueItemSerialized<any>): SaveFlowQueueItem {
    const queueItem = SaveFlowQueueItem.queueItemFromCommonData(queueItemData);

    queueItem.metadata = queueItemData.metadata;
    queueItem.result = queueItemData.result;

    return queueItem;
  }

  public static serializeQueueItem(queueItem: SaveFlowQueueItem): SaveFlowQueueItemSerialized<any> {
    return {
      ...SaveFlowQueueItem.queueItemToCommonData(queueItem),
      metadata: queueItem.metadata,
      result: queueItem.result,
    };
  }

  public static deserializeQueueItemTypeSaveSellDocument(
    queueItemData: SaveFlowQueueItemSerialized<DocumentSubmitProcessResult<'D'>>,
  ): SaveFlowQueueItem {
    const queueItem = SaveFlowQueueItem.queueItemFromCommonData(queueItemData);

    queueItem.metadata = queueItemData.metadata;
    queueItem.result = !queueItemData.result ? null : {
      ...queueItemData.result,
      result: queueItemData.result.result ? new PrinterResult(queueItemData.result.result) : null,
      error: queueItemData.result.error ? deserializeError(queueItemData.result.error) : null,
    };

    return queueItem;
  }

  public static serializeQueueItemTypeSaveSellDocument(
    queueItem: SaveFlowQueueItem,
  ): SaveFlowQueueItemSerialized<DocumentSubmitProcessResult> {
    return {
      ...SaveFlowQueueItem.queueItemToCommonData(queueItem),
      metadata: queueItem.metadata,
      result: !queueItem.result ? null : {
        ...queueItem.result,
        result: queueItem.result.result ? queueItem.result.result.toJson() : null,
        error: queueItem.result.error ? serializeError(queueItem.result.error) : null,
      },
    };
  }

  public static deserializeQueueItemTypePrintStandaloneDocument(
    queueItemData: SaveFlowQueueItemSerialized<DocumentSubmitProcessResult<'D'>>,
  ): SaveFlowQueueItem {
    const queueItem = SaveFlowQueueItem.queueItemFromCommonData(queueItemData);

    queueItem.metadata = queueItemData.metadata;
    queueItem.result = !queueItemData.result ? null : {
      ...queueItemData.result,
      result: queueItemData.result.result ? new PrinterResult(queueItemData.result.result) : null,
      error: queueItemData.result.error ? deserializeError(queueItemData.result.error) : null,
    };

    return queueItem;
  }

  public static serializeQueueItemTypePrintStandaloneDocument(
    queueItem: SaveFlowQueueItem,
  ): SaveFlowQueueItemSerialized<DocumentSubmitProcessResult> {
    return {
      ...SaveFlowQueueItem.queueItemToCommonData(queueItem),
      metadata: queueItem.metadata,
      result: !queueItem.result ? null : {
        ...queueItem.result,
        result: queueItem.result.result ? queueItem.result.result.toJson() : null,
        error: queueItem.result.error ? serializeError(queueItem.result.error) : null,
      },
    };
  }

  public static serialize(queueItem: SaveFlowQueueItem): SaveFlowQueueItemSerialized {
    switch (queueItem.type) {
    case SaveFlowQueueItemType.saveSellDocument:
      return SaveFlowQueueItem.serializeQueueItemTypeSaveSellDocument(queueItem);
    case SaveFlowQueueItemType.printStandaloneDocument:
      return SaveFlowQueueItem.serializeQueueItemTypePrintStandaloneDocument(queueItem);
    default:
      return SaveFlowQueueItem.serializeQueueItem(queueItem);
    }
  }

  public static deserialize(queueItemSerialized: SaveFlowQueueItemSerialized): SaveFlowQueueItem {
    switch (queueItemSerialized.type) {
    case SaveFlowQueueItemType.saveSellDocument:
      return SaveFlowQueueItem.deserializeQueueItemTypeSaveSellDocument(
        queueItemSerialized as SaveFlowQueueItemSerialized<DocumentSubmitProcessResult<'D'>>,
      );
    case SaveFlowQueueItemType.printStandaloneDocument:
      return SaveFlowQueueItem.deserializeQueueItemTypePrintStandaloneDocument(
        queueItemSerialized as SaveFlowQueueItemSerialized<DocumentSubmitProcessResult<'D'>>,
      );
    default:
      return SaveFlowQueueItem.deserializeQueueItem(
        queueItemSerialized as SaveFlowQueueItemSerialized<void>,
      );
    }
  }

  public static getExecutorByType(type: SaveFlowQueueItemType) {
    switch (type) {
    case SaveFlowQueueItemType.ensureCheckAndCharge: return SaveFlowQueueItem.executorForTypeEnsureCheckAndCharge;
    case SaveFlowQueueItemType.saveSellDocument: return SaveFlowQueueItem.executorForTypeSaveSellDocument;
    case SaveFlowQueueItemType.printStandaloneDocument: return SaveFlowQueueItem.executorForTypePrintStandaloneDocument;
    case SaveFlowQueueItemType.postPurchaseProcesses: return SaveFlowQueueItem.executorForTypePostPurchaseProcesses;
    default:
      throw new Error(`Unknown queue item type: ${type}`);
    }
  }

  public static get executorForTypeEnsureCheckAndCharge() {
    return async function(this: SaveFlowQueueItem, ...args: ExecutorArgs) {
      const registerStore = useRegisterStore();

      try {
        await registerStore.ensureCheckAndChargeCharge();
        this.markFinished();
      } catch (e) {
        this.markFailed(e);
      }
    };
  }

  public static get executorForTypePrintStandaloneDocument() {
    return async function(this: SaveFlowQueueItem, ...args: ExecutorArgs) {
      const registerStore = useRegisterStore();
      const printDocument = new DocumentDto(this.metadata.document);

      /**
       * TODO: fraud protection!
       */
      // try {
      //   const changeHistory = await apiDocumentGetChangeHistory({
      //     params: {
      //       id: printDocument.header.uniqueidentifier,
      //     },
      //   });
      //
      //   if (changeHistory.length !== 0) {
      //     const {
      //       result: historyResult,
      //     } = last(changeHistory);
      //
      //     const result = new PrinterResult(historyResult.toJson());
      //
      //     if (getResponseCodeConfiguration(historyResult).finished) {
      //       this.markFinished();
      //       return <DocumentSubmitProcessResult>{
      //         result: result,
      //         created: true,
      //         printed: true,
      //         error: null,
      //       };
      //     }
      //   }
      // } catch (e) {
      //   // Probably not created at all
      //   console.error(e);
      // }

      try {
        // if (await printDocument.hasAlreadyBeenSaved()) {
        //   for (const content of printDocument.contents) {
        //     registerStore.sellDocument.value.addPrintedContent(content.clone());
        //   }
        //
        //   this.markFinished();
        //
        //   return <DocumentSubmitProcessResult>{
        //     result: null,
        //     created: true,
        //     printed: true,
        //     error: null,
        //   };
        // }

        /**
         * ??
         */
        const mandatoryReceiptPrint = true;

        const result = await registerStore.createDocumentSubmitProcess(
          printDocument.header.uniqueidentifier,
          async (mode) => {
            if (mode === DocumentCreateMode.print) {
              return await apiDocumentRepeatablePrinting({
                input: new RepeatablePrintingCommand({
                  printoutType: printDocument.printoutType?.value ?? PrintoutTypes.ReceiptPrinter,
                  outputType: printDocument.printTemplateType?.value ?? OutputTypes.Primary,
                  uniqueidentifier: printDocument.header.uniqueidentifier,
                  copies: 1,
                }),
              });
            }

            printDocument.preflightSetup();

            if (mode === DocumentCreateMode.create) {
              printDocument.disablePrintout = true;
            }

            return await apiDocumentCreate({
              input: printDocument.toApiClone(),
            });
          },
          {mandatoryPrint: mandatoryReceiptPrint},
        );

        if (!result.result && result.error) {
          registerStore.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: result.error,
          }));

          this.markFailed(result.error);
          return result;
        }

        if (!mandatoryReceiptPrint && result.created) {
          this.markFinished();

          return result;
        }

        if (result.error) { // Create error
          this.markFailed(result.error);
          return result;
        }

        if (result.result.hasError) { // Create error
          this.markFailed(new Error(result.result.errorMessage));
          return result;
        }

        for (const content of printDocument.contents) {
          registerStore.sellDocument.value.addPrintedContent(content.clone());
        }

        this.markFinished();

        return result;
      } catch (e) {
        this.markFailed(e);
      }
    };
  }

  public static get executorForTypeSaveSellDocument() {
    return async function(this: SaveFlowQueueItem, ...args: ExecutorArgs) {
      const registerStore = useRegisterStore();
      const configurationStore = useConfigurationStore();


      try {
        submitJournalEventPrintStart();
        const mandatoryReceiptPrint = configurationStore.configuration.value
          ?.general
          ?.printAndPayment
          ?.mandatoryReceiptPrint ?? true;

        const sellDocument = registerStore.sellDocument.value;

        const result = await registerStore.createDocumentSubmitProcess(
          sellDocument.header.uniqueidentifier,
          async (mode) => {
            if (mode === DocumentCreateMode.print) {
              return await apiDocumentRepeatablePrinting({
                input: new RepeatablePrintingCommand({
                  printoutType: sellDocument.printoutType?.value ?? PrintoutTypes.ReceiptPrinter,
                  outputType: sellDocument.printTemplateType?.value ?? OutputTypes.Primary,
                  uniqueidentifier: sellDocument.header.uniqueidentifier,
                  copies: 1,
                }),
              });
            }

            sellDocument.preflightSetup();

            if (mode === DocumentCreateMode.create) {
              sellDocument.disablePrintout = true;
            }

            // TODO: remove this when backend implements sms.sent flag handling
            sellDocument.promotions = reject(sellDocument.promotions, (promo) => {
              return promo.type === PromotionMetaType.SEND_SMS && promo.sent;
            });

            if (
              !sellDocument.disablePrintout &&
              sellDocument.disabledMandatoryPhysicalPrint &&
              await sellDocument.hasAlreadyBeenSaved()
            ) {
              return await apiDocumentFinishWithPdfPrint({
                params: {
                  id: sellDocument.header.uniqueidentifier,
                },
              });
            }

            return await apiDocumentCreate({
              input: sellDocument.toApiClone(),
            });
          },
          {mandatoryPrint: mandatoryReceiptPrint},
        );

        if (!result.result && result.error) {
          registerStore.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: result.error,
          }));

          this.markFailed(result.error);
          return result;
        }

        if (!mandatoryReceiptPrint && result.created) {
          this.markFinished();

          return result;
        }

        if (result.error) { // Create error
          this.markFailed(result.error);
          return result;
        }

        if (result.result.hasError) { // Create error
          this.markFailed(new Error(result.result.errorMessage));
          return result;
        }

        this.markFinished();

        return result;
      } catch (e) {
        this.markFailed(e);
      }
    };
  }

  public static get executorForTypePostPurchaseProcesses() {
    return async function(this: SaveFlowQueueItem, ...args: ExecutorArgs) {
      const registerStore = useRegisterStore();
      const restoreModeStore = useRestoreModeStore();
      const printerStatusStore = usePrinterStatusStore();
      const configurationStore = useConfigurationStore();

      const onPurchaseFinish = async () => {
        if (
          !registerStore.sellDocument.value.isDocumentSubTypeAutomatedFollowUpDocument &&
          configurationStore.configuration.value?.features?.login?.redirectToLoginPageAfterCheckout
        ) {
          broadcastIO.ensureMuteAndPauseQueue();
        }

        await registerStore.resetReceipt();
        registerStore.dispatchEvent(new Event(RegisterStoreEvent.SELL_DOCUMENT_CREATED));
      };

      try {
        const [queueItem, queueItemIndex, saveFlow] = args;

        const [firstQueueItemTypeSaveSellDocument = null] = saveFlow
          .queueItemsByType(SaveFlowQueueItemType.saveSellDocument);
        const queueItemsTypePrintStandaloneDocument = saveFlow
          .queueItemsByType(SaveFlowQueueItemType.printStandaloneDocument);

        if (!firstQueueItemTypeSaveSellDocument?.result?.created) {
          /**
           * Sell document was not created!
           */
          return;
        }

        if (some(queueItemsTypePrintStandaloneDocument, (queueItem) => !queueItem.result?.created)) {
          /**
           * Some print documents were not created!
           */
          return;
        }

        try {
          emitTestEvent(TestEvent.SELL_DOCUMENT_SAVED, registerStore.sellDocument.value.header.uniqueidentifier);
          sentryRecordSellDocumentCreate(registerStore.sellDocument.value);

          const hasFollowUpDocuments = registerStore.hasAvailableFollowUpDocuments.value;

          /**
           * Do not await, these messages should be displayed outside of this flow
           * It has effect on postpone switch of secondary display!
           */
          const postPurchaseSteps = (async () => {
            const printContents = !isActiveFeaturePrintDisplayOnScreen() ? [] : [
              ...(firstQueueItemTypeSaveSellDocument.result.result.hasValidPrintContent ? [
                ...registerStore.sellDocument.value.nonFiscalPrintableDocuments,
                firstQueueItemTypeSaveSellDocument.result.result.printContent,
              ] : []),
              ...flow([
                (queueItems) => filter(queueItems, (queueItem) => queueItem.result?.result?.hasValidPrintContent),
                (queueItems) => map(queueItems, (queueItem) => queueItem.result.result.printContent),
              ])(queueItemsTypePrintStandaloneDocument),
            ];

            for (const [index, printContent] of printContents.entries()) {
              if (index !== 0) {
                await wait(300)(null);
              }

              await registerStore.printContentStore.value.open(printContent);
            }

            if (firstQueueItemTypeSaveSellDocument.result.result.hasWarning) {
              printerStatusStore.setWarningCode(firstQueueItemTypeSaveSellDocument.result.result.warningCode);
            }

            if (!hasFollowUpDocuments) {
              registerStore.cashAmountValidatorStore.value.validate();
            }
          })();

          /**
           * Automated follow-up document should not be saved as last sell document
           * => we need info about proper last sell document to be able to return cash to customer
           */
          if (!registerStore.sellDocument.value.isDocumentSubTypeAutomatedFollowUpDocument) {
            registerStore.setLastSellDocument(registerStore.sellDocument.value.clone());
          }

          restoreModeStore.consoleCollector.value.flush();

          if (hasFollowUpDocuments) {
            const pendingFollowUpDocument = first(registerStore.pendingFollowUpDocuments.value);

            await registerStore.resetReceipt({
              onInitFinish: async () => {
                try {
                  await postPurchaseSteps;
                } catch (e) {
                  console.error(e);
                }

                await registerStore.startFollowUpDocumentFlow(pendingFollowUpDocument);
              },
            });
          } else {
            await onPurchaseFinish();
          }
        } catch (e) {
          console.error(e);
          /**
           * This step has not mandatory steps to be completed, if it fails, we can continue
           */

          await onPurchaseFinish();
        }
      } catch (e) {
        /**
         * This step has to be repeated, even if it fails
         */
        // this.markFailed(e);
      }
    };
  }

  get queueItemsByCallbacks() {
    const registerStore = useRegisterStore();

    try {
      return flow([
        (callbacks) => map(callbacks, ({type, metadata = {}}) => {
          switch (type) {
          case SaveFlowQueueItemCallbackType.standalonePrints:
            return flow([
              (contents: Array<[number, DocumentContentDto]>) => {
                return reject(contents, ([index, content]) => {
                  return registerStore.sellDocument.value.isContentPrinted(content);
                });
              },
              (contents: Array<[number, DocumentContentDto]>) => {
                return map(contents, ([index, content]) => {
                  const printDocument = DocumentDto.createEmptyPrintDocument();

                  /**
                   * Note: id should be unique for each template and will be used as fraud detection
                   */
                  printDocument.header.uniqueidentifier = content.id;

                  printDocument.isSensitive = content.isSensitive ?? false;

                  /**
                   * TODO: Refactor this, let PS take it from configuration, this is stage 1 solution
                   */
                  printDocument.printCheck = new PrintCheck(PrintCheckTypes.PrintEnd);

                  printDocument.addContent(content.clone());

                  return new SaveFlowQueueItem(SaveFlowQueueItemType.printStandaloneDocument, {
                    metadata: {
                      ...metadata,
                      document: printDocument.toJson(),
                    },
                  });
                });
              },
            ])([...registerStore.sellDocument.value.contentsTypeStandalone.entries()]);
          default:
            return new SaveFlowQueueItem(type, {metadata});
          }
        }),
        (queueItems) => flatten(queueItems),
      ])(this.executorCallbacks);
    } finally {
      this.flushCallbacks();
    }
  }

  get isFinished() {
    return !!this.finished;
  }

  get hasError() {
    return !!this.error;
  }

  markFinished() {
    this.finished = true;
    this.error = null;
  }

  markFailed(error: Error) {
    console.error(error);
    this.error = error;
  }

  registerCallback(args: SaveFlowQueueItem['executorCallbacks'][number]) {
    this.executorCallbacks.push(args);
  }

  flushCallbacks() {
    this.executorCallbacks = [];
  }

  async execute(...args: ExecutorArgs) {
    try {
      this.result = await this.executor(...args);
    } catch (e) {
      this.markFailed(e);
    }
  }
}


export class SaveFlow {
  public context: Context

  public static deserialize(data: any): SaveFlow {
    return new SaveFlow(new Context({
      queue: !data.queue ? null :
      map(data.queue, (item: SaveFlowQueueItemSerialized) => SaveFlowQueueItem.deserialize(item)),
    }));
  }

  public static serialize(saveFlow: SaveFlow): any {
    return {
      queue: !saveFlow.context.state.queue ? null :
      map(saveFlow.context.state.queue, (item: SaveFlowQueueItem) => SaveFlowQueueItem.serialize(item)),
    };
  }

  constructor(context = new Context(getInitState())) {
    Object.assign(context.state, {
      ...getInitState(),
      ...context.state,
    });

    this.context = context;
  }

  get needCleanup() {
    return !!this.queue?.length;
  }

  get hasValidQueue() {
    if (!this.queue) {
      return false;
    }

    const [firstQueueItemTypeSaveSellDocument = null] = this.queueItemsByType(SaveFlowQueueItemType.saveSellDocument);

    if (!firstQueueItemTypeSaveSellDocument) {
      return false;
    }

    if (firstQueueItemTypeSaveSellDocument.hasError) {
      return false;
    }

    return true;
  }

  async createQueue() {
    if (this.hasValidQueue) {
      return;
    }

    if (this.needCleanup) {
      this.reset();
    }

    const registerStore = useRegisterStore();

    const queue = [];

    /**
     * Ensure check and charge articles
     */
    const queueItemTypeEnsureCheckAndCharge = new SaveFlowQueueItem(SaveFlowQueueItemType.ensureCheckAndCharge);

    /**
     * Print standalone print documents
     */
    queueItemTypeEnsureCheckAndCharge.registerCallback({type: SaveFlowQueueItemCallbackType.standalonePrints});
    queue.push(queueItemTypeEnsureCheckAndCharge);

    /**
     * Print main receipt
     */
    queue.push(new SaveFlowQueueItem(SaveFlowQueueItemType.saveSellDocument));

    /**
     * Post print actions and validations
     */
    queue.push(new SaveFlowQueueItem(SaveFlowQueueItemType.postPurchaseProcesses));

    this.context.state.queue = queue;

    await registerStore.persist();
  }

  async processQueue() {
    const registerStore = useRegisterStore();

    let currentQueueItemIndex = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (currentQueueItemIndex === this.queue.length) {
        break;
      }

      const queueItem = this.queue[currentQueueItemIndex];

      if (queueItem.isFinished) {
        currentQueueItemIndex++;
        continue;
      }

      await queueItem.execute(queueItem, currentQueueItemIndex, this);

      if (queueItem.hasError) {
        await registerStore.persist();
        break;
      }

      this.queue.splice(currentQueueItemIndex + 1, 0, ...queueItem.queueItemsByCallbacks);

      await registerStore.persist();

      currentQueueItemIndex++;
    }
  }

  get queue(): SaveFlowQueueItem[] {
    return this.context.state.queue;
  }

  queueItemsByType<T extends SaveFlowQueueItemType>(type: T) {
    return filter(this.queue ?? [], (item) => {
      return item.type === type;
    }) as T extends SaveFlowQueueItemType.saveSellDocument ? Array<SaveFlowQueueItem<DocumentSubmitProcessResult>>
      : T extends SaveFlowQueueItemType.printStandaloneDocument ? Array<SaveFlowQueueItem<DocumentSubmitProcessResult>>
      : T extends SaveFlowQueueItemType.postPurchaseProcesses ? Array<SaveFlowQueueItem<void>>
      : T extends SaveFlowQueueItemType.ensureCheckAndCharge ? Array<SaveFlowQueueItem<void>>
      : never;
  }

  async process() {
    await this.createQueue();
    await this.processQueue();
  }

  reset() {
    Object.assign(this.context.state, getInitState());
  }
}
