import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Acl from '../Acl';
import ArticleLabels from '../ArticleLabels';
import {ArrayType} from '@designeo/apibundle-js';
import BarcodeTemplate from '../BarcodeTemplate';
import Buttons from '../Buttons';
import CustomerDisplay from '../CustomerDisplay';
import Enums from '../Enums';
import {ObjectType} from '@designeo/apibundle-js';
import Features from '../Features';
import ConfigurationGeneral from '../ConfigurationGeneral';
import Hardware from '../Hardware';
import QuickCall from '../QuickCall';
import Ui from '../Ui';
import {TreeSearchOptions} from '@designeo/pos-search-engine/src/common/helpers/Tree';
import {InventoryKeyboardMode} from '@/Modules/Apps/Inventory/types';

export default class ConfigurationCustom extends createEntity({
  acl: {
    type: new EntityType(Acl),
  },
  analogDisplay: {
    type: new ObjectType<{
      enabled: boolean,
      messages: {
        logoffContent: {
          noOperatorSignedIn: string
        },
        outOfPurchaseContent: {
          welcome: string,
        },
        purchaseContent: {
          showDiscounts: boolean,
        }
      }
    }>(),
  },
  articleLabels: {
    type: new EntityType(ArticleLabels),
  },
  assets: {
    type: new ArrayType(new ObjectType()),
  },
  barcodes: {
    type: new ArrayType(new EntityType(BarcodeTemplate)),
  },
  buttons: {
    type: new EntityType(Buttons),
  },
  customerDisplay: {
    type: new EntityType(CustomerDisplay),
  },
  enums: {
    type: new EntityType(Enums),
  },
  errors: {
    type: new ObjectType(),
  },
  features: {
    type: new EntityType(Features),
  },
  general: {
    type: new EntityType(ConfigurationGeneral),
  },
  hardware: {
    type: new EntityType(Hardware),
  },
  help: {
    type: new ObjectType(),
  },
  printTemplates: {
    type: new ObjectType(),
  },
  returnCodes: {
    type: new ObjectType(),
  },
  homeMenu: {
    type: new ObjectType(),
  },
  quickCall: {
    type: new EntityType(QuickCall),
  },
  secrets: {
    type: new ObjectType(),
  },
  translations: {
    type: new ObjectType(),
  },
  ui: {
    type: new EntityType(Ui),
  },
  workflow: {
    type: new ObjectType(),
  },
  promoEngine: {
    type: new ObjectType(),
  },
  pda: {
    type: new ObjectType<{keyboard: {mode: InventoryKeyboardMode}, barcodeParser: TreeSearchOptions['barcode']}>(),
  },
}) {}
