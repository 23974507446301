import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "flex flex-row gap-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.yearOfBirth
          }, {
            default: _withCtx(({isActive, value, setActive, error}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.yearOfBirth,
                active: isActive,
                error: error,
                errorOutput: 'modal',
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value.value), 1)
                ]),
                _: 2
              }, 1032, ["label", "active", "error", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"]),
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.sex
          }, {
            default: _withCtx(({isActive, value, setActive, setActiveWithValue, error}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.sex,
                active: isActive,
                error: error,
                errorOutput: 'modal',
                onClick: setActive
              }, {
                "form-control": _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: _normalizeClass(['form-control text-center', {
                    'bg-secondary text-basic-lightest': value.value === _ctx.SexOptions.MALE
                  }]),
                      onClick: ($event: any) => (setActiveWithValue(_ctx.SexOptions.MALE))
                    }, _toDisplayString(_ctx.$t('general.sex.male')), 11, _hoisted_4),
                    _createElementVNode("div", {
                      class: _normalizeClass(['form-control text-center', {
                    'bg-secondary text-basic-lightest': value.value === _ctx.SexOptions.FEMALE
                  }]),
                      onClick: ($event: any) => (setActiveWithValue(_ctx.SexOptions.FEMALE))
                    }, _toDisplayString(_ctx.$t('general.sex.female')), 11, _hoisted_5)
                  ])
                ]),
                _: 2
              }, 1032, ["label", "active", "error", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"])
        ]),
        _createVNode(_component_WorkflowTransitions, { validate: "" })
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}