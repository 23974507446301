import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const KorunkaExternalProgramDtoPropDef = {
  cardNumber: {
    type: new StringType(),
  },
};
export default class GeneratedKorunkaExternalProgramDto extends createEntity(KorunkaExternalProgramDtoPropDef) {}
