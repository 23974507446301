import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BarcodeFormat from '../BarcodeFormat';
import {StringType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import BarcodeType from '../BarcodeType';


export default class GeneratedBarcodeTemplate extends createEntity({
  format: {
    type: new EntityType(BarcodeFormat),
  },
  name: {
    type: new StringType(),
  },
  properties: {
    type: new ObjectType(),
  },
  searchBy: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(BarcodeType),
  },
}) {}
