
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,

} from 'vue';
import {
  WorkflowActions,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,

} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {
  WorkflowStepWarehouseOperationPlant,
} from '@/Modules/Workflow/Step/StepWarehouseOperationPlant/WorkflowStepWarehouseOperationPlant';
import {apiInStoreManagementPlantList} from '@/Model/Action';
import PlantDto from '@/Model/Entity/PlantDto';
import WarehouseOperationPlantList
  from '@/Modules/Workflow/Step/StepWarehouseOperationPlant/WarehouseOperationPlantList.vue';
import {
  debounce,
  map,
} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import FormInput from '@/Components/FormInput.vue';

export default defineComponent({
  name: 'StepWarehouseOperationSupplier',
  components: {
    FormInput,
    HLInput,
    WarehouseOperationPlantList,
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.WarehouseOperationPlant;
    const {workflowStep, currentWorkflow} = useStepTools<WorkflowStepWarehouseOperationPlant>(stepType);
    const managementPlantList = ref<PlantDto[]>([]);

    const fetchVendorList = async () => {
      try {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

        managementPlantList.value = await apiInStoreManagementPlantList({
          params: {
            ...(workflowStep.value.searchString.length < 2 ? {} : {
              name: workflowStep.value.searchString,
            }),
          },
        });
      } catch (e) {
        managementPlantList.value = [];
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      } finally {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    };

    const debouncedFetchVendorList = debounce(fetchVendorList, 400);

    const plants = computed(() => {
      return map(managementPlantList.value, (listItem: PlantDto) => {
        return {
          entity: listItem,
          isActive: workflowStep.value.plant?.plant === listItem.plant,
        };
      });
    });

    const onPlantSelect = (plant: PlantDto) => {
      workflowStep.value.plant = plant;
    };

    onMounted(async () => {
      workflowStep.value.messageBus.addEventListener(WorkflowActions.ENTER, debouncedFetchVendorList);
      await fetchVendorList();
    });

    onBeforeUnmount(() => {
      if (!currentWorkflow.value) {
        return;
      }

      workflowStep.value.messageBus.removeEventListener(WorkflowActions.ENTER, debouncedFetchVendorList);
    });

    const searchField = computed(() => {
      return workflowStep.value.createFieldDataPath(WorkflowStepField.searchString);
    });

    watch(() => workflowStep.value.searchString, () => {
      debouncedFetchVendorList();
    }, {flush: 'post'});

    return {
      stepType,
      plants,
      onPlantSelect,
      searchField,
    };
  },
});
