import {Store} from '@designeo/vue-helpers';
import {toRaw} from 'vue';
import {IPersist} from './persist';
import {
  watch,
  EffectScope,
} from 'vue';
import {debounce} from 'lodash-es';
import {canPersistentStoreUsePersist} from '@/Helpers/app';
// import {withStopwatch} from './time';

const persistentStoreEffectScope = new EffectScope(true);

export class PersistentStore<T extends object> extends Store<T> {
  public persist: ()=> Promise<void>
  public persistWithDebounce: ReturnType<typeof debounce>
  constructor(state: T, persist: IPersist<T>, {autoPersist = true} = {}) {
    super(persist.get() || state);

    this.persist = async () => {
      this.persistWithDebounce.cancel();

      if (!canPersistentStoreUsePersist()) {
        return;
      }
      // await withStopwatch(`${this.constructor.name} persist`, async () => {
      //   await persist.set(toRaw(this.state));
      // });
      await persist.set(toRaw(this.state));
    };

    this.persistWithDebounce = debounce(async () => {
      this.persist();
    }, 400);

    if (autoPersist) {
      persistentStoreEffectScope.run(() => {
        watch(
          () => this.state,
          () => {
            this.persistWithDebounce();
          },
          {
            flush: 'sync', // https://docs.w3cub.com/vue~3/api/instance-methods#watch
            deep: true,
          },
        );
      });
    }
  }
}
