import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["p-2 uppercase", {
      'toggle': !_ctx.isActive,
      'toggle-active': _ctx.isActive,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', _ctx.payment.paymentId)))
  }, [
    _createVNode(_component_PhIcon, {
      icon: _ctx.payment.icon,
      weight: "bold",
      size: 32
    }, null, 8, ["icon"]),
    _createTextVNode(" " + _toDisplayString(_ctx.payment.label), 1)
  ], 2))
}