import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';

export default class PRCheckRequestDtoCustom extends createEntity({
  operatorName: {
    type: new StringType(),
  },
  phoneNumber: {
    type: new StringType(),
  },
  confirmPhoneNumber: {
    type: new StringType(),
  },
  amount: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  requestId: {
    type: new StringType(),
  },
}) {}
