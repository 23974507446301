import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ValidationError from '../ValidationError';


export const ActionsPropDef = {
  canPayOut: {
    type: new BooleanType(),
  },
  cantPayoutReasons: {
    type: new ArrayType(new EntityType(ValidationError)),
  },
  canCancel: {
    type: new BooleanType(),
  },
};
export default class GeneratedActions extends createEntity(ActionsPropDef) {}
