import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers';
import {workflowStepMixinEshop} from '../StepMixins/WorkflowStepMixinEshop';
import {mapKeys} from 'lodash-es';
import {EshopOrdersStornoReasonOption} from '@/Modules/Apps/EshopOrders/types';
import {commonWfFieldValidations} from '@/Helpers/validations';

export class WorkflowStepEshopOrderStornoReason extends workflowStepMixinEshop(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.EshopOrderStornoReason;
  }

  get type() {
    return WorkflowStepEshopOrderStornoReason.type;
  }

  get component() {
    return markRaw(require('./StepEshopOrderStornoReason.vue').default);
  }

  get orderStornoReasonOptions(): EshopOrdersStornoReasonOption[] {
    return this.step.orderStornoReasonOptions;
  }

  get orderStornoReasonOptionsById() {
    return mapKeys(this.orderStornoReasonOptions, 'id');
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.note]: yup.string()
        .test(commonWfFieldValidations(this.step.validations?.[WorkflowStepField.note])),
    });
  }

  ensureFirstSelectedReason() {
    if (!this.orderStornoReasonOptions.length) {
      return;
    }

    if (this.orderStornoReason) {
      return;
    }

    this.dataSetter(WorkflowStepField.orderStornoReasonId, () => this.orderStornoReasonOptions[0].id);
    this.orderStornoReason = this.orderStornoReasonOptions[0];
    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: WorkflowStepField.note,
      },
    }));
  }

  async beforeEnter() {
    if (this.stepInit) {
      return;
    }

    try {
      await this.ensureOrder();
      this.stepInit = true;
      this.ensureFirstSelectedReason();
    } catch (e) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
    }
  }

  async finish() {
    if (!await this.validate()) return;

    if (this.isFetchingOrder) {
      return;
    }

    this.stepFinished = true;
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.orderStornoReasonId, (fieldActions) => ({
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
          if (!event.value) {
            return;
          }

          fieldActions[WorkflowActions.ENTER](event.value);

          this.orderStornoReason = this.orderStornoReasonOptionsById?.[event.value];

          this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
            detail: {
              field: WorkflowStepField.note,
            },
          }));
        }),
      })),
      ...this.withFieldActions(WorkflowStepField.note, (fieldActions) => ({
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_CHAR](event.value);
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.finish();
        }),
      })),
    };
  }
}
