
import {
  computed,
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep, useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import {useI18n} from 'vue-i18n';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  name: 'StepWrongConfiguration',
  components: {
    AsyncButton,
    HLStep,
  },
  setup() {
    const i18n = useI18n();
    const stepType = WorkflowStepTypes.InvalidConfiguration;
    const {currentWorkflow, abortWorkflow} = useStepTools(stepType);

    const description = computed(() => {
      return i18n.t('workflow.steps.invalidConfiguration.description', {
        workflow: currentWorkflow.value.workflowTitle,
      });
    });

    const exit = () => abortWorkflow();

    return {
      stepType,
      WorkflowStepField,
      description,
      exit,
    };
  },
});
