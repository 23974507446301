
import {
  defineComponent, PropType,
} from 'vue';
import CaretNavigator from './CaretNavigator.vue';
import FormInputMixin from '@/Components/FormInputMixin.vue';
import IconMoney from '@/Components/Icons/IconMoney.vue';
import FormInputErrorModal from '@/Components/FormInputErrorModal.vue';

export default defineComponent({
  name: 'FormInputNominal',
  components: {
    FormInputErrorModal,
    IconMoney,
    CaretNavigator,
  },
  mixins: [FormInputMixin],
  props: {
    size: {
      type: String as PropType<'xs' | 'sm' | 'md'>,
      required: false,
      default: 'sm',
    },
  },
});
