import {KorunkaPatch} from '@/Model/Entity/patches/KorunkaMixin';
import KorunkaErrorDtoCustom from '@/Model/Entity/custom/KorunkaErrorDtoCustom';
import {
  flow,
  isEmpty,
  map,
  reject,
} from 'lodash-es';

export default class KorunkaErrorDto extends KorunkaPatch(KorunkaErrorDtoCustom) {
  get canBeSold() {
    return false;
  }

  get formattedValidationErrors() {
    if (!this.validationErrors) {
      return [];
    }

    if (!this.validationErrors.length) {
      return [];
    }

    return flow([
      (errors) => map(errors, ({message}) => message),
      (errors) => reject(errors, isEmpty),
    ])(this.validationErrors);
  }

  get formattedErrors() {
    if (!this.errors) {
      return [];
    }

    if (!this.errors.length) {
      return [];
    }

    return flow([
      (errors) => map(errors, ({code, field, errorMessage}) => {
        if (!errorMessage) {
          return [
            code,
            field,
          ].join(' - ');
        }

        return [
          ...(errorMessage.title ? [errorMessage.title] : []),
          ...(errorMessage.message ? [errorMessage.message] : []),
        ].join(' - ');
      }),
      (errors) => reject(errors, isEmpty),
    ])(this.errors);
  }

  get errorReason() {
    if (this.formattedValidationErrors.length) {
      return this.formattedValidationErrors.join('; ');
    }

    if (this.formattedErrors.length) {
      return this.formattedErrors.join('; ');
    }

    return null;
  }

  get errorReasonForCustomer() {
    return null;
  }
}
