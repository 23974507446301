import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,

} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {apiStockGetCashState} from '@/Model/Action';
import {
  isEmpty,
  isNil,
  toLower,
} from 'lodash-es';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {action} from '@designeo/vue-helpers';
import * as yup from 'yup';
import {
  commonWfFieldValidations,
  createMaxFinDocumentAmountValidation,
  validationInvalidNominalsMinValue,
  wfMaxLengthValidation,
} from '@/Helpers/validations';

export class WorkflowStepSafeTransfer extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeTransfer;
  }

  get type() {
    return WorkflowStepSafeTransfer.type;
  }

  get component() {
    return markRaw(require('./StepSafeTransfer.vue').default);
  }

  get drawerCashStateValidationCode() {
    return this.step.drawerCashStateValidationCode;
  }

  get amountKeepInDrawer() {
    return this.step.amountKeepInDrawer ?? null;
  }

  get canChangeAmount() {
    return this.step.canChangeAmount ?? false;
  }

  set safeCanContinue(val) {
    this.dataSetter('safeCanContinue', () => val);
  }

  get safeCanContinue() {
    return this.getFieldValue('safeCanContinue', false);
  }

  set safeAmount(val: string) {
    this.dataSetter(WorkflowStepField.safeAmount, () => val);

    this.amount = this.safeAmountAsNumber;
  }

  get safeAmount() {
    return this.getFieldValue(WorkflowStepField.safeAmount, '0');
  }

  get safeAmountAsNumber() {
    return parseFloat(this.safeAmount);
  }

  set amount(val) {
    this.dataSetter(WorkflowStepField.amount, () => val);
  }

  safeAmountByTotalCashState(total) {
    if (isNil(this.amountKeepInDrawer)) {
      return total;
    }

    if (total >= this.amountKeepInDrawer) {
      return total - this.amountKeepInDrawer;
    }

    if (total > 0) {
      return 0;
    }

    return total;
  }

  async beforeEnter() {
    this.stepInit = false;


    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const currencySymbol = this.step.currency;

      const totalMoneyInPOS = (await apiStockGetCashState())?.cash?.[toLower(currencySymbol)]?.total;

      this.safeAmount = this.safeAmountByTotalCashState(totalMoneyInPOS).toString(10);

      this.safeCanContinue = !this.safeAmountAsNumber && !this.canChangeAmount;

      this.stepInit = true;
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get currency() {
    return this.configurationStore.currenciesByCurrencySymbol.value[this.step.currency];
  }

  get validator(): yup.AnyObjectSchema {
    if (!this.canChangeAmount) {
      return super.validator;
    }

    return yup.object().shape({
      [WorkflowStepField.safeAmount]: yup.string()
        .test(commonWfFieldValidations({required: true}))
        .test(wfMaxLengthValidation())
        .test(validationInvalidNominalsMinValue(this.currency, {acceptZero: true}))
        .test(createMaxFinDocumentAmountValidation(
          this.currency.exchangeRate.exchangeToLocalCurrency(this.safeAmountAsNumber),
          {required: true},
        )),
    });
  }

  async finish() {
    if (!await this.validate()) {
      return;
    }

    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  activateInsertMode(field: string): boolean {
    return this.canChangeAmount;
  }

  get transitions() {
    if (!this.canChangeAmount) {
      return {};
    }

    return {
      ...this.withFieldActions(WorkflowStepField.safeAmount, (fieldActions) => ({
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.CANCEL]: action(() => {
          if (isEmpty(this.safeAmount)) {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          }

          this.safeAmount = '';
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.finish();
        }),
      })),
    };
  }
}
