import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const PrinterServiceStateDtoPropDef = {
  isRunning: {
    type: new BooleanType(),
  },
};
export default class GeneratedPrinterServiceStateDto extends createEntity(PrinterServiceStateDtoPropDef) {}
