import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransitionDefinition,
  getCommonTransitions,
  createTransitions,
} from './helpers';
import {QuickCallArticle} from '../../QuickCall/Classes/QuickCall';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.READONLY_ARTICLE_SELECTED]: {
      ...getCommonTransitions.call(this, [RegisterActions.CANCEL]),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        await this.transitionToNextStep();
      }),
    },
  };
}
