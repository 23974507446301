import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CurrencyStateDto from '../CurrencyStateDto';


export const SafeStateDtoPropDef = {
  czk: {
    type: new NumberType(),
  },
  eur: {
    type: new NumberType(),
  },
  safe: {
    type: new EntityType(CurrencyStateDto),
  },
  safebag: {
    type: new EntityType(CurrencyStateDto),
  },
  totalInSafe: {
    type: new EntityType(CurrencyStateDto),
  },
};
export default class GeneratedSafeStateDto extends createEntity(SafeStateDtoPropDef) {}
