import GeneratedOrderCustomerAddressDto from './generated/OrderCustomerAddressDto';

export default class OrderCustomerAddressDto extends GeneratedOrderCustomerAddressDto {
  toString() {
    return [
      [this.street, this.city].filter(Boolean).join(' '),
      this.zipCode,
    ].filter(Boolean).join(', ');
  }
}
