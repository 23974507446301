import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ReceiptResultDtoPropDef = {
  documentBarcode: {
    type: new StringType(),
  },
};
export default class GeneratedReceiptResultDto extends createEntity(ReceiptResultDtoPropDef) {}
