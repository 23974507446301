import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TranslatedString from '../TranslatedString';


export default class GeneratedStaticTextPrint extends createEntity({
  code: {
    type: new StringType(),
  },
  content: {
    type: new StringType(),
  },
  name: {
    type: new EntityType(TranslatedString),
  },
  templateCode: {
    type: new StringType(),
  },
}) {}
