import PORCheckRequestDtoCustom from './custom/PORCheckRequestDtoCustom';

export default class PORCheckRequestDto extends PORCheckRequestDtoCustom {
  get canBeSold() {
    return true;
  }

  get errorReason() {
    return null;
  }

  getSanitizedEntity() {
    return this.clone();
  }
}
