import {AbstractPromoFlow, createNewReactive} from '@/Modules/Register/PromoInteractionFlow/AbstractPromoFlow';
import {RegisterState, RegisterStoreErrors} from '@/Modules/Register/types';
import {PromoInteractionFlowTypes} from '@/Modules/Register/PromoInteractionFlow/index';
import {DocumentItemDto} from '@/Model/Entity';
import {
  every,
  flow,
  map,
  reject,
  sumBy,
  uniqWith,
} from 'lodash-es';
import {DynamicSetLevelsSelection} from '@designeo/pos-promotion-engine/src/blocks/types';
import {
  promotionByTypeThatMatch,
  PromotionMetaType,
} from '@designeo/pos-promotion-engine';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {AppLoaderEvent} from '@/Modules/Core/types';

export type SetItemPool = {
  index: number
  documentItem: DocumentItemDto,
  quantity: number,
  isSelected?: boolean,
}[]

export type SetLevelPool = {
  index: number
  name: string
  setItemsPool: SetItemPool,
  isActive?: boolean,
  isFinished?: boolean,
  setItemsMaxQuantity: number,
  selectionStep: number,
}[]

export class DynamicSetLevelsSelectionFlow extends AbstractPromoFlow {
  public setLevelsPool: SetLevelPool = [];
  public activeSetLevelIndex: number = 0;
  public activeSetItemIndex: number = 0;
  public highlightFlag: boolean = false;

  constructor(
    public dynamicSetLevelsSelection: DynamicSetLevelsSelection,
    public selection: DynamicSetLevelsSelection['selection'][number],
    public nextState?: RegisterState,
  ) {
    super(nextState);
  }

  static new = createNewReactive<DynamicSetLevelsSelectionFlow>();

  get setLevels() {
    return map(this.setLevelsPool, (setLevel, index) => {
      const setItemsQuantity = sumBy(setLevel.setItemsPool, (setItem) => setItem.quantity);
      return Object.assign(setLevel, {
        isActive: setLevel.index === this.activeSetLevelIndex,
        isFinished: setItemsQuantity === setLevel.setItemsMaxQuantity,
      });
    });
  }

  get activeSetLevelItems() {
    return map(this.setLevels[this.activeSetLevelIndex].setItemsPool, (setItem, index) => {
      return Object.assign(setItem, {
        isSelected: setItem.index === this.activeSetItemIndex,
      });
    });
  }

  get activeSetLevel() {
    return this.setLevels.find((setItem) => setItem.isActive);
  }

  get activeSetItem() {
    return this.activeSetLevelItems.find((setItem) => setItem.isSelected);
  }

  get activeSetLevelItemsQuantity() {
    return sumBy(this.activeSetLevelItems, (setItem) => setItem.quantity);
  }

  get addedPromotion() {
    const registerStore = useRegisterStore();

    return promotionByTypeThatMatch(
      registerStore.sellDocument.value,
      PromotionMetaType.DYNAMIC_SET_LEVELS_SELECTED,
      {
        documentItemUniqueIdentifier: this.dynamicSetLevelsSelection.documentItemUniqueIdentifier,
        promoCode: this.dynamicSetLevelsSelection.promoCode,
      },
    );
  }

  get isNumericInputUsable() {
    return this.activeSetLevel.selectionStep === 1;
  }

  get isFinished() {
    return every(this.setLevels, (setLevel) => setLevel.isFinished);
  }

  get isFirstLevel() {
    return this.activeSetLevelIndex === 0;
  }

  get isLastLevel() {
    return this.activeSetLevelIndex === this.setLevels.length - 1;
  }

  async submitDynamicSetSelectedPromotion() {
    const registerStore = useRegisterStore();

    const promotionDynamicSetSelected = {
      type: PromotionMetaType.DYNAMIC_SET_LEVELS_SELECTED,
      documentItemUniqueIdentifier: this.dynamicSetLevelsSelection.documentItemUniqueIdentifier,
      promoCode: this.dynamicSetLevelsSelection.promoCode,
      selectionKey: this.selection.key,
      itemsByLevel: map(this.setLevelsPool, (setLevel) => {
        const setItemsPoolOfFilledItems = reject(setLevel.setItemsPool, (setItem) => setItem.quantity === 0);

        return map(setItemsPoolOfFilledItems, ({documentItem, quantity}) => {
          const item = documentItem.clone();

          item.quantity = quantity;

          return item.toJson();
        });
      }),
    };

    registerStore.sellDocument.value.promotions = [
      ...reject(
        registerStore.sellDocument.value.promotions,
        (promotion) => Object.entries({
          type: promotionDynamicSetSelected.type,
          documentItemUniqueIdentifier: promotionDynamicSetSelected.documentItemUniqueIdentifier,
          promoCode: promotionDynamicSetSelected.promoCode,
        }).every(([key, value]) => promotion[key] === value),
      ),
      promotionDynamicSetSelected,
    ];

    await this.destroy();
  }

  async abort() {
    const registerStore = useRegisterStore();

    registerStore.dispatchEvent(new CustomEvent(RegisterStoreErrors.DYNAMIC_SET_SELECTION_ABORTED));

    await this.destroy();
  }

  async destroy() {
    const registerStore = useRegisterStore();
    const addedPromotion = this.addedPromotion;

    await super.destroy();

    if (addedPromotion) {
      await registerStore.transitionToNextStep();
    }
  }

  async fetchSelectionGroupItems(selectionGroup: string) {
    const registerStore = useRegisterStore();

    return flow([
      (documentItems: DocumentItemDto[]) => map(documentItems, (documentItem) => {
        const innerDocumentItem = documentItem.expandExpandableSet();

        innerDocumentItem.quantity = 1;

        return innerDocumentItem;
      }),
      (documentItems: DocumentItemDto[]) => reject(documentItems, (documentItem) => documentItem.isSet),
      (documentItems: DocumentItemDto[]) => uniqWith(documentItems, (itemA, itemB) => {
        if (itemA.gtin !== itemB.gtin) {
          return false;
        }

        if (itemA.internalNumberWithBatch !== itemB.internalNumberWithBatch) {
          return false;
        }

        return true;
      }),
    ])(await registerStore.searchByPromotionList(selectionGroup)) as DocumentItemDto[];
  }

  async findAvailableDynamicSetLevels() {
    const registerStore = useRegisterStore();

    try {
      registerStore.dispatchEvent(new Event(AppLoaderEvent.ON));

      const setLevelsPool: SetLevelPool = [];

      for (const [index, level] of this.selection.levels.entries()) {
        const items = await this.fetchSelectionGroupItems(level.selectionGroup);

        setLevelsPool.push({
          index: index,
          name: level.name,
          setItemsPool: map(items, (documentItem, index) => ({
            index,
            documentItem,
            quantity: 0,
          })),
          setItemsMaxQuantity: level.quantity,
          selectionStep: level.selectionStep,
        });
      }

      this.setLevelsPool = setLevelsPool;
      this.activeSetItemIndex = 0;
      this.activeSetLevelIndex = 0;

      if (this.setLevelsPool.length === 0) {
        await this.abort();
      }
    } catch (e) {
      console.warn(`Trying to fetch combination groups failed`);
      throw e;
    } finally {
      registerStore.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  changeActiveSetLevelIndex(index: number) {
    this.activeSetItemIndex = 0;
    this.activeSetLevelIndex = index;
  }

  canSetItemQuantityBeChangedTo(article: SetItemPool[number], quantity: number) {
    if (quantity < 0) {
      return false;
    }

    const newTotalQuantity = this.activeSetLevelItemsQuantity - article.quantity + quantity;

    return newTotalQuantity <= this.activeSetLevel.setItemsMaxQuantity && newTotalQuantity >= 0;
  }

  changeActiveSetItemQuantity(quantity: number) {
    this.activeSetItem.quantity = quantity;
  }

  canActiveSetItemQuantityBeChangedTo(quantity: number) {
    return this.canSetItemQuantityBeChangedTo(this.activeSetItem, quantity);
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.DYNAMIC_SET_LEVELS_SELECTION_FLOW,
      nextState: this.nextState,
      dynamicSetLevelsSelection: this.dynamicSetLevelsSelection,
      selection: this.selection,
      setLevelsPool: map(this.setLevelsPool, (setLevel) => ({
        ...setLevel,
        setItemsPool: map(setLevel.setItemsPool, (setItem) => ({
          ...setItem,
          documentItem: setItem.documentItem.toJson(),
        })),
      })),
      activeSetLevelIndex: this.activeSetLevelIndex,
      activeSetItemIndex: this.activeSetItemIndex,
      highlightFlag: this.highlightFlag,
    };
  }

  static deserialize(ctx) {
    if (ctx === null) {
      return null;
    }

    const {
      nextState,
      dynamicSetLevelsSelection,
      selection,
      setLevelsPool,
      activeSetLevelIndex,
      activeSetItemIndex,
      highlightFlag,
    } = ctx;

    return AbstractPromoFlow.baseDeserialize(DynamicSetLevelsSelectionFlow, {
      setLevelsPool: map(setLevelsPool, (setLevel) => ({
        ...setLevel,
        setItemsPool: map(setLevel.setItemsPool, (setItem) => ({
          ...setItem,
          documentItem: new DocumentItemDto(setItem.documentItem),
        })),
      })),
      activeSetLevelIndex,
      activeSetItemIndex,
      highlightFlag,
    }, [
      dynamicSetLevelsSelection,
      selection,
      nextState,
    ]);
  }

  toString() {
    return `<DynamicSetLevelsSelectionFlow>`;
  }
}
