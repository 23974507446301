import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerSex from '../CustomerSex';
import {ArrayType} from '@designeo/apibundle-js';
import CreateCustomerAttributeDto from '../CreateCustomerAttributeDto';


export const CreateCustomerDtoPropDef = {
  cardNumber: {
    type: new StringType(),
  },
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
  gdprConsentPlace: {
    type: new StringType(),
  },
  gdprConsentDate: {
    type: new DateTimeType(),
  },
  yearOfBirth: {
    type: new NumberType(),
  },
  dayOfBirth: {
    type: new NumberType(),
  },
  monthOfBirth: {
    type: new NumberType(),
  },
  sex: {
    type: new EntityType(CustomerSex),
  },
  attributes: {
    type: new ArrayType(new EntityType(CreateCustomerAttributeDto)),
  },
};
export default class GeneratedCreateCustomerDto extends createEntity(CreateCustomerDtoPropDef) {}
