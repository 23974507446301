const fileUrlGarbageCollectorCallback = new FinalizationRegistry((heldValue: string) => {
  URL.revokeObjectURL(heldValue);
});

export class FileUrl {
  public readonly objectUrl: string;

  public static base64ToBlob(base64, type, {sliceSize = 512} = {}) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: type});
  }

  public static fromDataString(dataString: string) {
    const [all, type, isBase64, content] = dataString.match(/^data:(.*?)(;base64)?,(.*)$/);

    switch (type) {
    case 'application/pdf':
      return isBase64 ?
        FileUrl.fromBlob(FileUrl.base64ToBlob(content, type)) :
        FileUrl.fromBlob(new Blob([content], {type: type}));
    case 'text/html':
      return FileUrl.fromBlob(new Blob([decodeURIComponent(content)], {type: type}));
    default:
      throw new Error(`Unsupported type ${type}!`);
    }
  }

  public static fromBlob(blob: Blob) {
    return new FileUrl(new File([blob], 'unknown', {type: blob.type}));
  }

  public static fromFile(file: File) {
    return new FileUrl(file);
  }

  constructor(public readonly file: File) {
    if (this.isMimeTypeApplicationPdf) {
      this.objectUrl = `${URL.createObjectURL(file)}#toolbar=0&view=FitH`;
    } else {
      this.objectUrl = URL.createObjectURL(file);
    }
    fileUrlGarbageCollectorCallback.register(this, this.objectUrl);
  }

  get mimeType() {
    return this.file.type;
  }

  get isMimeTypeApplicationPdf() {
    return this.file.type === 'application/pdf';
  }
}
