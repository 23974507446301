import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ErrorMessage from '../ErrorMessage';


export const CancelResponseDtoPropDef = {
  responseObject: {
    type: new ObjectType(),
  },
  responseObjectType: {
    type: new StringType(),
  },
  success: {
    type: new BooleanType(),
  },
  errorName: {
    type: new StringType(),
  },
  errorMessage: {
    type: new EntityType(ErrorMessage),
  },
  cannotBeCanceled: {
    type: new BooleanType(),
  },
};
export default class GeneratedCancelResponseDto extends createEntity(CancelResponseDtoPropDef) {}
