
import {
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import {FileUrl} from '@/Helpers/file';
import {guid} from '@/Helpers/guid';

export default defineComponent({
  name: 'PrintContent',
  props: {
    content: {
      type: Object as PropType<FileUrl>,
      required: true,
    },
    test: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const key = ref(null);

    watch(() => props.content, () => {
      key.value = guid();
    }, {immediate: true});

    return {
      key,
    };
  },
});
