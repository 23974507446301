import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleResultDto from '../ArticleResultDto';


export const VoucherResultDtoPropDef = {
  code: {
    type: new StringType(),
  },
  article: {
    type: new EntityType(ArticleResultDto),
  },
};
export default class GeneratedVoucherResultDto extends createEntity(VoucherResultDtoPropDef) {}
