import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-header gap-4" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "flex flex-row gap-8" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "font-bold text-secondary" }
const _hoisted_6 = { class: "flex-grow flex justify-end" }
const _hoisted_7 = { class: "flex flex-row gap-px border border-basic-light rounded-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('stockInStores.title')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString($setup.product?.description), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["py-1 px-3 m-px rounded-md text-sm", {'text-white bg-primary': $setup.onlyWithStock}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onlyWithStock = true))
            }, _toDisplayString(_ctx.$t('stockInStores.grid.switch.withStock')), 3),
            _createElementVNode("div", {
              class: _normalizeClass(["py-1 px-3 m-px rounded-md text-sm", {'text-white bg-primary': !$setup.onlyWithStock}]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onlyWithStock = false))
            }, _toDisplayString(_ctx.$t('stockInStores.grid.switch.all')), 3)
          ])
        ])
      ])
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "font-bold cursor-pointer px-3",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
    }, [
      _createVNode(_component_PhIcon, {
        icon: "x",
        size: 25
      })
    ], 512), [
      [_directive_test, 'closeModal']
    ])
  ]))
}