import {submitJournalEventSelectPaymentLeaveToEnterArticle} from './../../../../Helpers/journal';
import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
  RegisterStoreEvent,

} from '@/Modules/Register/types';
import {markRaw, toRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_ARTICLE_NUMBER]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.ADD_CHAR,
        RegisterActions.ADD_PERIOD,
        RegisterActions.ADD_COMMA,
        RegisterActions.BACKSPACE,
      ]),
      [RegisterActions.INIT]: action(async ({previousState}) => {
        if (
          previousState === RegisterState.PAYMENTS_EXIT &&
          this.state.sellDocument.isDocumentSubTypeAutomatedFollowUpDocument
        ) {
          await this.fetchPromoEngineResult();
          await this.changeState(RegisterState.SELECT_PAYMENT);
          return;
        }

        if (previousState === RegisterState.PAYMENTS_EXIT) {
          submitJournalEventSelectPaymentLeaveToEnterArticle();
          await this.triggerImmediatePromotions();
        }
      }),
      [RegisterActions.CANCEL]: action(async () => {
        if (this.clearIsUsable.value) {
          this.state.productFlow.presetQuantity = null;
          this.resetInputBuffer();
          this.state.insertMode = false;
        } else if (this.state.sellDocument.canBeCanceledWithoutConfirmation) {
          await this.resetToDefaultStep();
          await this.ensureInitState();
          this.dispatchEvent(new Event(RegisterStoreEvent.LEAVE));
        } else {
          await this.resetToDefaultStep();
          this.toggleCancelMode();
        }
      }),
      [RegisterActions.CLEAR]: action(async (event: RegisterInputEvent) => {
        if (this.state.inputBuffer) {
          this.resetInputBuffer();
          this.state.insertMode = false;
        } else {
          this.state.productFlow.presetQuantity = null;
        }
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        const isActivePresetFunction = this.checkSellFunctionPresetQuantity();

        if (isActivePresetFunction) {
          this.changeState(RegisterState.ENTER_ARTICLE_NUMBER);
        } else if (this.state.inputBuffer === '' && this.sumIsUsable.value) {
          this.changeState(RegisterState.SELECT_PAYMENT);
        } else if (this.state.inputBuffer !== '') {
          await this.search(this.state.inputBuffer);
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        this.state.productFlow.inputMode = InputSource.SCANNER;
        await this.search(event.value);
      }),
    },
    [RegisterState.SELECT_PRODUCT_VARIATION]: {
      [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
        await this.resetToDefaultStep();
      }),
      [RegisterActions.NEXT]: action(async (event: RegisterInputEvent<{ offset: number, limit: number }>) => {
        try {
          const {documentItems} = await this.searchSubject(this.state.lastSearchedSubject, event.value);

          this.state.loadedProducts = markRaw(documentItems);
        } catch (e) {
          this.state.loadedProducts = markRaw([]);
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!event.value) return;

        await this.startProductFlow(markRaw(event.value));

        if (!this.isRegisterStateSelectProductVariation.value) {
          this.state.loadedProducts = markRaw([]);
        }
      }),
    },
  };
}
