
/* eslint-disable array-bracket-newline,max-len */
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
  watch,
  PropType,
} from 'vue';
import {useI18n} from 'vue-i18n';
import {CountdownTicks} from '@/constants/countdown';

export default defineComponent({
  name: 'Countdown',
  props: {
    milliseconds: {
      type: null,
      required: true,
    },
    withLead: {
      type: Boolean,
      required: false,
      default: true,
    },
    xs: {
      type: Boolean,
      required: false,
      default: false,
    },
    ticks: {
      type: String as PropType<CountdownTicks>,
      required: false,
      default: CountdownTicks.internal,
      enum: [
        CountdownTicks.internal,
        CountdownTicks.external,
      ],
    },
  },
  setup(props) {
    const i18n = useI18n();
    const milliseconds = ref(props.milliseconds);
    let timer = null;

    const label = computed(() => {
      const secondAsMs = 1000;
      const minuteAsMs = secondAsMs * 60;
      const hourAsMs = minuteAsMs * 60;
      const dayAsMs = hourAsMs * 24;

      const days = Math.floor(
        milliseconds.value / dayAsMs,
      );
      const hours = Math.floor(
        (milliseconds.value - (days * dayAsMs)) / hourAsMs,
      );
      const minutes = Math.floor(
        (milliseconds.value - ((days * dayAsMs) + (hours * hourAsMs))) / minuteAsMs,
      );
      const seconds = Math.floor(
        (milliseconds.value - ((days * dayAsMs) + (hours * hourAsMs) + (minutes * minuteAsMs))) / secondAsMs,
      );

      return [
        ...(!days ? [] : [
          i18n.t(props.withLead ? 'countdown.daysLead' : props.xs ? 'countdown.daysXs' : 'countdown.days', days),
        ]),
        ...(!hours && !days ? [] : [
          i18n.t(!days && props.withLead ? 'countdown.hoursLead' : props.xs ? 'countdown.hoursXs' : 'countdown.hours', hours),
        ]),
        ...(!minutes && !hours && !days ? [] : [
          i18n.t(!days && !hours && props.withLead ? 'countdown.minutesLead' : props.xs ? 'countdown.minutesXs' : 'countdown.minutes', minutes),
        ]),
        i18n.t(!days && !hours && !minutes && props.withLead ? 'countdown.secondsLead' : props.xs ? 'countdown.secondsXs' : 'countdown.seconds', seconds),
      ].join(' ');
    });

    if (props.ticks !== CountdownTicks.internal) {
      watch(() => props.milliseconds, (newVal) => {
        milliseconds.value = newVal;
      });
    }

    const stopTimer = () => {
      clearInterval(timer);
    };

    const startTimer = () => {
      stopTimer();
      timer = setInterval(() => {
        milliseconds.value = Math.max(milliseconds.value - 1000, 0);
      }, 1000);
    };

    onMounted(() => {
      if (props.ticks !== CountdownTicks.internal) return;
      startTimer();
    });

    onBeforeUnmount(() => {
      if (props.ticks !== CountdownTicks.internal) return;
      stopTimer();
    });

    return {
      label,
    };
  },
});
