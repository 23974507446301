import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('incorrectRestart.types.sellDocument.text1', {documentType: _ctx.documentType})), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('incorrectRestart.types.sellDocument.text2', {cashier: _ctx.cashier})), 1),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('incorrectRestart.types.sellDocument.text3', {date: _ctx.date})), 1),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('incorrectRestart.types.sellDocument.question')), 1)
  ]))
}