import { resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative overflow-hidden" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.content)
      ? _withDirectives((_openBlock(), _createElementBlock("iframe", {
          key: _ctx.key,
          src: _ctx.content.objectUrl,
          frameborder: "0",
          class: "absolute top-0 left-0 w-full h-full"
        }, null, 8, _hoisted_2)), [
          [_directive_test, _ctx.test]
        ])
      : _createCommentVNode("", true)
  ]))
}