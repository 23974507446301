
import {computed, defineComponent} from 'vue';
import {useStockInStores} from '@/Components/ModalStockInStores/stockInStores';
import ModalStockInStoresBodyTableItem from '@/Components/ModalStockInStores/ModalStockInStoresBodyTableItem.vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  components: {ModalStockInStoresBodyTableItem},
  setup() {
    const i18n = useI18n();
    const {
      fetchResult,
    } = useStockInStores();

    const cells = computed(() => {
      return [
        i18n.t('stockInStores.grid.columns.shopCode'),
        i18n.t('stockInStores.grid.columns.shopName'),
        i18n.t('stockInStores.grid.columns.shopAddress'),
        i18n.t('stockInStores.grid.columns.stock'),
      ];
    });

    return {
      cells,
      fetchResult,
    };
  },
});
