import GeneratedCustomerAdditionalDataDto from './generated/CustomerAdditionalDataDto';
import * as yup from 'yup';
import {CustomerDataField} from '@/constants/additionalData';
import {isNil, reduce} from 'lodash-es';

export default class CustomerAdditionalDataDto extends GeneratedCustomerAdditionalDataDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  validate({required = true} = {}) {
    const requiredFields = CustomerAdditionalDataDto.configurationStore
      .configuration
      .value
      .features
      ?.sell
      ?.additionalData
      ?.requiredFields ?? [CustomerDataField.vat];

    const validationFields = reduce(requiredFields, (acc, field) => {
      acc[field] = yup.string().nullable()
        .required();
      return acc;
    }, {});

    try {
      yup.object().shape({...(required ? validationFields : {})})
        .validateSync(this._data, {abortEarly: false});

      return {
        valid: true,
        error: null,
      };
    } catch (e) {
      return {
        valid: false,
        error: e,
      };
    }
  }
}
