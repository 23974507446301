import {Constructor} from '@/Model/Entity/patches/patch';
import {
  BooleanType,
  StringType,
} from '@designeo/apibundle-js';

export const DocumentPaymentDtoPatch = <TBase extends Constructor>(superClass: TBase) =>
  class DocumentPaymentDtoPatch extends superClass {
    get isCanceled() {
      // @ts-ignore
      return new BooleanType().parse(this._data.isCanceled);
    }
    set isCanceled(val) {
      // @ts-ignore
      this._data.isCanceled = new BooleanType().format(val);
    }

    get isRefunded() {
      // @ts-ignore
      return new BooleanType().parse(this._data.isRefunded);
    }
    set isRefunded(val) {
      // @ts-ignore
      this._data.isRefunded = new BooleanType().format(val);
    }

    get isValidated() {
      // @ts-ignore
      return new BooleanType().parse(this._data.isValidated ?? true);
    }
    set isValidated(val) {
      // @ts-ignore
      this._data.isValidated = new BooleanType().format(val);
    }
  };
