import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const BetPreparedTicketPartnerResourcePropDef = {
  preparedTicketIds: {
    type: new ArrayType(new NumberType()),
  },
  drawIds: {
    type: new ArrayType(new NumberType()),
  },
  operationId: {
    type: new StringType(),
  },
};
export default class GeneratedBetPreparedTicketPartnerResource extends createEntity(BetPreparedTicketPartnerResourcePropDef) {}
