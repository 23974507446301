import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ValidationErrorPropDef = {
  error: {
    type: new StringType(),
  },
  field: {
    type: new StringType(),
  },
  errorMessage: {
    type: new StringType(),
  },
  message: {
    type: new StringType(),
  },
  title: {
    type: new StringType(),
  },
  operationId: {
    type: new StringType(),
  },
};
export default class GeneratedValidationError extends createEntity(ValidationErrorPropDef) {}
