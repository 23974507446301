import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
  RegisterStoreEvent,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition} from './helpers';
import {CustomerDto, DocumentCustomerDto} from '@/Model/Entity';
import {apiV1PosCustomersAddCard} from '@/Model/Action';
import CustomerCardPreviewDto from '../../../../Model/Entity/CustomerCardPreviewDto';
import {useCustomerSearchTransitions} from '@/Modules/Register/store/transitions/customerSearch';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.CONNECT_CARD_TO_CUSTOMER]: {
      ...useCustomerSearchTransitions.call(this),
      [RegisterActions.BEFORE_LEAVE]: action(async ({to}) => {
        this.state.unassignedCard = null;
      }),
      [RegisterActions.INIT]: action(async ({cardNumber}) => {
        useCustomerSearchTransitions.call(this)[RegisterActions.INIT]();
        this.state.unassignedCard = cardNumber;
      }),
      [RegisterActions.CANCEL]: action(async (event: RegisterInputEvent) => {
        await this.changeState(this.state.previousState, {
          initArg: {
            cardNumber: this.state.unassignedCard,
          },
        });
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent<CustomerDto>) => {
        if (event.value) {
          this.dispatchEvent(new CustomEvent(
            RegisterStoreEvent.CUSTOMER_ADD_CARD,
            {detail: {customerNumber: event.value.customerNumber, cardNumber: this.state.unassignedCard}},
          ));
        } else {
          this.dispatchEvent(new Event(RegisterStoreEvent.SEARCH_CUSTOMER));
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        try {
          const {documentCustomers} = await this.searchSubject(event.value);

          if (!documentCustomers.length) return;

          this.state.productFlow.inputMode = InputSource.SCANNER;
          await this.onEventInput({
            type: RegisterActions.ENTER,
            value: await this.fetchCustomer(documentCustomers[0], 'cardNumber', {
              unassignedCardProcess: false,
            }),
          });
        } catch (e) {
          this.dispatchEvent(new CustomEvent(RegisterStoreErrors.API_ERROR, {
            detail: e,
          }));
        }
      }),
    },
  };
}
