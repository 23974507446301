import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full h-full flex items-center justify-center p-3 bg-basic-lightest text-sm text-basic-dark text-center font-bold" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!!_ctx.src)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.hasImageFailed)
          ? _renderSlot(_ctx.$slots, "fallback", { key: 0 }, () => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('general.imageFallback')), 1)
            ])
          : (_ctx.isImageLoaded)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _ctx.src,
                class: _normalizeClass(_ctx.imageClass),
                alt: ""
              }, null, 10, _hoisted_3))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}