import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const DocumentReportInfoDtoPropDef = {
  count: {
    type: new NumberType(),
  },
  exceededPrintSoftLimit: {
    type: new BooleanType(),
  },
};
export default class GeneratedDocumentReportInfoDto extends createEntity(DocumentReportInfoDtoPropDef) {}
