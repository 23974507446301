import {asyncStorage} from '@/Helpers/asyncStore';
import {submitJournalEventLocalStorageClear} from '@/Helpers/journal';
import {recordCustomEventLogEntry} from '@/Helpers/logger';
import {App} from 'vue';

export function getStorageSize(storage: Storage) {
  let total = 0;
  let storageKey;
  const result = {
    sizeTotal: 0,
    sizeTotalHumanReadable: '0 KB',
    storage: {},
  };

  for (let i = 0; i < storage.length; i++) {
    storageKey = storage.key(i);

    const size = ((storage[storageKey].length + storageKey.length) * 2);

    total += size;

    result.storage[storageKey] = {
      size,
      sizeHumanReadable: (size / 1024).toFixed(2) + ' KB',
    };
  }

  result.sizeTotal = total;
  result.sizeTotalHumanReadable = (total / 1024).toFixed(2) + ' KB';

  return result;
}

export async function estimateNavigatorStorage() {
  if (navigator.storage && navigator.storage.estimate) {
    const quota = await navigator.storage.estimate();
    const percentageUsed = (quota.usage / quota.quota) * 100;
    const remaining = quota.quota - quota.usage;

    return {
      percentageUsed,
      remaining,
    };
  }
}

export async function registerStorageInterceptor() {
  const originalLocalStorageClear = window.localStorage.clear.bind(window.localStorage);

  window.localStorage.clear = () => {
    recordCustomEventLogEntry('Storage registerStorageInterceptor', 'localStorage.clear');
    submitJournalEventLocalStorageClear({storage: 'localStorage'});
    console.error('localStorage.clear() is not recommended, use S.O.S. from console instead!');
    originalLocalStorageClear();
  };

  const originalSessionStorageClear = window.sessionStorage.clear.bind(window.sessionStorage);

  window.sessionStorage.clear = () => {
    recordCustomEventLogEntry('Storage registerStorageInterceptor', 'sessionStorage.clear');
    submitJournalEventLocalStorageClear({storage: 'sessionStorage'});
    console.error('sessionStorage.clear() is not recommended, use S.O.S. from console instead!');
    originalSessionStorageClear();
  };
}


type CoreRequiredContext = {
  app: App<any>
}


export async function registerAsyncStorage(ctx: CoreRequiredContext) {
  await asyncStorage.hydrate();
}
