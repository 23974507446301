
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {
  WorkflowStepWarehouseOperationPrint,
} from '@/Modules/Workflow/Step/StepWarehouseOperationPrint/WorkflowStepWarehouseOperationPrint';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  name: 'StepWarehouseOperationPrint',
  components: {
    AsyncButton,
    WorkflowTransitions,
    HLStep,
  },
  setup() {
    const stepType = WorkflowStepTypes.WarehouseOperationPrint;
    const {workflowStep} = useStepTools<WorkflowStepWarehouseOperationPrint>(stepType);

    const print = async () => {
      await workflowStep.value.print();
    };

    return {
      stepType,
      WorkflowStepField,
      print,
    };
  },
});
