
import {
  defineComponent,
} from 'vue';
import {WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'StepBalanceStockSuccess',
  components: {
    HLStep,
    IconCheck,
    AsyncButton,
  },
  setup() {
    const stepType = WorkflowStepTypes.BalanceStockSuccess;
    const {interpolate} = useFilters();
    return {
      stepType,
      interpolate,
    };
  },
});
