
import {
  computed,
  defineComponent,
  ref,
  watch,

} from 'vue';
import {isNil} from 'lodash-es';
import CaretNavigator from '@/Components/CaretNavigator.vue';
import {scrollIntoView} from '@/Helpers/scroll';

export default defineComponent({
  name: 'InputBuffer',
  components: {CaretNavigator},
  props: {
    value: {
      type: null,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: () => `InputBuffer_${Math.floor(Math.random() * 1000)}`,
    },
    highlightedClass: {
      type: String,
      required: false,
      default: 'bg-secondary-light',
    },
    insertMode: {
      type: Boolean,
      require: false,
      default: false,
    },
    disableCaretNavigator: {
      type: Boolean,
      require: false,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: null,
    },
    cursorPosition: {
      type: Number,
      required: false,
      default: null,
    },
    sanitizeDots: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const caret = ref<HTMLDivElement>(null);
    const inputText = ref<HTMLDivElement>(null);

    const sanitizeDots = (str) => {
      if (props.sanitizeDots) {
        return str.replace(/\./gi, ',');
      }

      return str;
    };

    const isCaret = computed(() => {
      return !props.insertMode && !props.disableCaretNavigator;
    });

    const cursorPos = computed(() => {
      return props.cursorPosition ?? sanitizedValue.value.length;
    });

    const sanitizedValue = computed(() => {
      const value = isNil(props.value) ? '\xa0' : props.value;
      let str = value.toString();
      str = str.length ? str : '\xa0';

      if (inputText.value && props.multiline) {
        document.getElementById(inputText.value.id).scrollIntoView({behavior: 'smooth', block: 'end'});
      }
      if (isNil(props.limit)) {
        return sanitizeDots(str);
      }

      return sanitizeDots(str.length > props.limit ? str.slice((props.limit - 3) * -1) : str);
    });

    const isOverLimit = computed(() => {
      const str = (props.value || '\xa0').toString();

      if (isNil(props.limit)) {
        return false;
      }

      return str.length > props.limit;
    });

    watch([() => props.value, cursorPos], () => {
      const el = caret.value?.['$el'];
      if (el) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            scrollIntoView(el);
          });
        });
      }
    });

    return {
      caret,
      isCaret,
      cursorPos,
      inputText,
      isOverLimit,
      sanitizedValue,
    };
  },
});
