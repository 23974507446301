import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import UiGeneral from '../UiGeneral';
import UiStyle from '../UiStyle';


export default class GeneratedUi extends createEntity({
  general: {
    type: new EntityType(UiGeneral),
  },
  style: {
    type: new EntityType(UiStyle),
  },
}) {}
