import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  DocumentPaymentDto,
  OrderDtoOrderEnvelopeDto,
} from '@/Model/Entity';
import {apiOrdersGetDetail, apiOrdersGetList} from '@/Model/Action';
import {WorkflowStepEvents, WorkflowStepField} from '../../types';
import {isActiveFeatureEshop} from '@/Helpers/features';
import {EshopOrdersStornoReasonOption} from '@/Modules/Apps/EshopOrders/types';

const STEP_ORDER_MODEL = 'order';


export const workflowStepMixinEshop = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinEshop extends (superClass as MixinBase<WorkflowStep>) {
    isFetchingOrder = false;

    get payments() {
      const payments: DocumentPaymentDto['_data'][] = this.getFieldValue(WorkflowStepField.payments, []);
      return payments.map((paymentDto) => new DocumentPaymentDto(paymentDto));
    }

    set payments(paymentDtos: DocumentPaymentDto[]) {
      this.dataSetter(WorkflowStepField.payments, () => paymentDtos.map((paymentDto) => paymentDto.toJson()));
    }

    get financialDocumentReferenceId() {
      return this.getFieldValue(WorkflowStepField.financialDocumentReferenceId, null);
    }

    set financialDocumentReferenceId(value: string) {
      this.dataSetter(WorkflowStepField.financialDocumentReferenceId, () => value);
    }

    get orderStornoReason(): EshopOrdersStornoReasonOption {
      return this.getFieldValue(WorkflowStepField.orderStornoReason, null);
    }

    set orderStornoReason(value: EshopOrdersStornoReasonOption) {
      this.dataSetter(WorkflowStepField.orderStornoReason, () => value);
    }

    get order(): OrderDtoOrderEnvelopeDto {
      const model = this.getFieldValue(STEP_ORDER_MODEL, null);

      if (!model) return null;

      return new OrderDtoOrderEnvelopeDto(model);
    }

    get disabledNextStep() {
      if (super.disabledNextStep) {
        return true;
      }

      if (!this.order) {
        return true;
      }

      return false;
    }

    set disabledNextStep(value: boolean) {
      super.disabledNextStep = value;
    }

    set order(value: OrderDtoOrderEnvelopeDto) {
      this.dataSetter(STEP_ORDER_MODEL, () => value.toJson());
    }

    currencyLocalSymbol(currency: string): string {
      return this.configurationStore.currenciesByCurrencySymbol.value?.[currency]?.localSymbol ?? ',-';
    }

    get orderCurrencySymbol(): string {
      return this.currencyLocalSymbol(this.order?.data.currencyCode);
    }

    get customer() {
      return this.order?.data.customer;
    }

    get eshop() {
      return this.order?.data.eshop;
    }

    get isPaid() {
      return this.order?.data.isPaid ?? false;
    }

    async ensureOrder() {
      if (!isActiveFeatureEshop()) {
        return;
      }

      if (this.order) {
        return;
      }

      if (!this.params.orderId) {
        return;
      }

      await this.fetchOrder(this.params.orderId);
    }

    async fetchOrder(id: string) {
      this.isFetchingOrder = true;
      try {
        this.order = await apiOrdersGetDetail({params: {id}});
      } catch (e) {
        console.error(e);
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
        throw e;
      } finally {
        this.isFetchingOrder = false;
      }
    }
  };

  return Derived as MergeCtor<typeof Derived, TBase>;
};
