import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {wait} from '@designeo/js-helpers/src/timing/wait';
import {wrapAppRedirectUrl} from '@/Helpers/url';
import {asyncStorage} from './asyncStore';

export type PrinterInfo = {
  name: string,
  displayName: string,
  description: string,
  status: number,
  isDefault: boolean,
  options: object,
}


export function isElectron() {
  // @ts-ignore
  return !!window.isElectron;
}

export function quitApp() {
  // @ts-ignore
  window.electronAPI?.quit?.();
}

export function resetToShop(): string {
  // @ts-ignore
  const shop = JSON.parse(window.electronAPI?.resetToShop?.() ?? null);

  if (!shop) {
    return null;
  }

  return shop.toString();
}

export function logger(): {record: (entry)=> void, isActive: boolean, toggleOff: ()=> void, toggleOn: ()=> void} {
  if (typeof window === 'undefined') {
    return {
      record: (entry) => {},
      isActive: false,
      toggleOff: () => {},
      toggleOn: () => {},
    };
  }

  // @ts-ignore
  return window.electronAPI?.logger?.() ?? {
    record: (entry) => {},
    isActive: false,
    toggleOff: () => {},
    toggleOn: () => {},
  };
}

export function getPrinters(): Array<PrinterInfo> {
  // @ts-ignore
  return window.electronAPI?.getPrinters?.() ?? [];
}

export async function electronPrint(blob) {
  const url = URL.createObjectURL(blob);
  try {
    // @ts-ignore
    return await (window.electronAPI?.print?.(url) ?? undefined);
  } finally {
    URL.revokeObjectURL(url);
  }
}

export async function electronDownload(
  {
    file,
    token,
    open = false,
  }: {
    file: File,
    token: string,
    open?: boolean,
  },
) {
  const url = URL.createObjectURL(file);
  try {
    // @ts-ignore
    return await (window.electronAPI?.download?.({url, token, fileName: file.name, open}) ?? undefined);
  } finally {
    URL.revokeObjectURL(url);
  }
}

export function systemExec({cmd, token}) {
  // @ts-ignore
  window.electronAPI?.systemExec?.({cmd, token});
}

export async function clearStorage() {
  window.localStorage.clear();
  const e2eScenarioId = window.sessionStorage.getItem('e2eScenarioId');
  window.sessionStorage.clear();
  if (e2eScenarioId) {
    window.sessionStorage.setItem('e2eScenarioId', e2eScenarioId);
  }
  await asyncStorage.clear();
}

export function disablePersistentStores() {
  window['persistentStoresDisabled'] = true;
}

export function enablePersistentStores() {
  window['persistentStoresDisabled'] = false;
}

export function canPersistentStoreUsePersist() {
  if (typeof window === 'undefined') {
    return false;
  }
  // @ts-ignore
  return !(window?.persistentStoresDisabled ?? false);
}

export function reloadApp() {
  const coreStore = useCoreStore();
  try {
    coreStore.setLoader(true);
    window.location.reload();
  } catch (e) {
    console.error(e);
  } finally {
    wait(5000)(null).then(() => {
      coreStore.setLoader(false);
    });
  }
}

export function reloadAppFromRoot() {
  const coreStore = useCoreStore();
  try {
    coreStore.setLoader(true);
    window.location.href = wrapAppRedirectUrl('/');
  } catch (e) {
    console.error(e);
  } finally {
    wait(5000)(null).then(() => {
      coreStore.setLoader(false);
    });
  }
}

export function reloadInventoryPdaFromRoot() {
  const coreStore = useCoreStore();
  try {
    coreStore.setLoader(true);
    window.location.href = wrapAppRedirectUrl('/apps/inventory/login');
  } catch (e) {
    console.error(e);
  } finally {
    wait(5000)(null).then(() => {
      coreStore.setLoader(false);
    });
  }
}

export function isVueAppTypeApp() {
  // @ts-ignore
  return !!document.getElementById('app')?.__vue_app__;
}

export function isVueAppTypeIncorrectRestart() {
  // @ts-ignore
  return !!document.getElementById('incorrectRestart')?.__vue_app__;
}

export function isVueAppTypeStartup() {
  // @ts-ignore
  return !!document.getElementById('startup')?.__vue_app__;
}

export function setTouchEmulation(enabled: boolean) {
  if (typeof window['electronAPI'] !== 'undefined') {
    window['electronAPI'].setTouchEmulation(enabled);
  }
}


// @ts-ignore // NOTE: how we should access this?!
export const getVueApp = () => document.getElementById('app').__vue_app__;
