import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PointHistoryDto from '../PointHistoryDto';


export const CustomerAccountPointsHistoryDtoPropDef = {
  clubCode: {
    type: new StringType(),
  },
  history: {
    type: new ArrayType(new EntityType(PointHistoryDto)),
  },
};
export default class GeneratedCustomerAccountPointsHistoryDto extends createEntity(CustomerAccountPointsHistoryDtoPropDef) {}
