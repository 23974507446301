
import Modal from '@/Components/Modal.vue';
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import Html from '@/Components/Html.vue';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {useFilters} from '@/Helpers/filters';
import AsyncButton from '@/Components/AsyncButton.vue';
import Countdown from '@/Components/Countdown.vue';
import ModalDetachedClose from '@/Components/ModalDetachedClose.vue';
import {ZLayer} from '@/constants/zLayer';
import {useRecoveryStore} from '@/Modules/Core/store/RecoveryStore';
import {useRouter} from 'vue-router';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useI18n} from 'vue-i18n';
import {waitAtLeast} from '@/Helpers/time';

export default defineComponent({
  name: 'ModalDocumentStatus',
  components: {
    ModalDetachedClose,
    Countdown,
    AsyncButton,
    Html,
    Modal,
  },
  setup() {
    const documentStatusStore = useDocumentStatusStore();
    const {translatedString} = useFilters();
    const coreStore = useCoreStore();
    const i18n = useI18n();
    const router = useRouter();
    const disableUserInteractions = ref(false);

    const key = computed(() => {
      return documentStatusStore.currentTask.value?.guid;
    });

    watch(key, () => {
      disableUserInteractions.value = false;
    });

    const statusConfiguration = computed(() => {
      return documentStatusStore.dialog.value?.configuration ?? null;
    });

    const hasSensitiveDocument = computed(() => {
      return documentStatusStore.currentTask.value?.document?.isSensitive ?? false;
    });

    const hasValidStatusConfiguration = computed(() => {
      return !!statusConfiguration.value;
    });

    const canBeClosed = computed(() => {
      if (!hasValidStatusConfiguration.value) {
        return false;
      }

      if (hasSensitiveDocument.value) {
        return true;
      }

      return statusConfiguration.value.isCloseable ?? false;
    });

    const canBeRepeated = computed(() => {
      if (!hasValidStatusConfiguration.value) {
        return false;
      }

      return statusConfiguration.value.isRepeatable ?? false;
    });

    const canBeRecovered = computed(() => {
      if (!hasValidStatusConfiguration.value) {
        return false;
      }

      return statusConfiguration.value.isRecoverable ?? false;
    });

    const canBeResolvedToPdf = computed(() => {
      if (!hasValidStatusConfiguration.value) {
        return false;
      }

      return statusConfiguration.value.isResolvableToPdf ?? false;
    });

    const withDisabledUserInteractions = async (fn) => {
      const dateStart = new Date();
      const delayedKey = key.value;
      let delay = 0;

      try {
        disableUserInteractions.value = true;
        const result = fn();

        if (!(result instanceof Promise)) {
          delay = 10000;
        }

        return await result;
      } finally {
        (async () => {
          await waitAtLeast(delay, dateStart);

          if (delayedKey === key.value) {
            disableUserInteractions.value = false;
          }
        })();
      }
    };

    const onClose = async () => {
      if (!canBeClosed.value) {
        return;
      }

      await withDisabledUserInteractions(() => {
        documentStatusStore.terminate();
      });
    };

    const onRetry = async () => {
      if (!canBeRepeated.value) {
        return;
      }

      await withDisabledUserInteractions(async () => {
        await documentStatusStore.retry();
      });
    };

    const onRecovery = async () => {
      if (!canBeRecovered.value) {
        return;
      }

      const recoveryStore = useRecoveryStore();

      if (await coreStore.confirm(i18n.t('recovery.confirm'))) {
        await withDisabledUserInteractions(async () => {
          await recoveryStore.recovery(recoveryStore.getRecoveryTypeByRoute(router.currentRoute.value));
        });
      }
    };

    const onResolveByPDF = async () => {
      if (!canBeResolvedToPdf.value) {
        return;
      }

      await withDisabledUserInteractions(async () => {
        await documentStatusStore.resolveToPDF();
      });
    };

    const onDialogItemClick = async (result) => {
      await withDisabledUserInteractions(async () => {
        await documentStatusStore.sendDialogResult(result);
      });
    };

    const opened = computed(() => {
      if (documentStatusStore.dialog.value) {
        return true;
      }

      return false;
    });

    const title = computed(() => {
      const title = statusConfiguration.value.title;

      if (title) {
        return translatedString(title) ?? statusConfiguration.value.code;
      }

      return statusConfiguration.value.code;
    });

    const instructions = computed(() => {
      const instructions = statusConfiguration.value.instructions;

      if (instructions) {
        return translatedString(instructions) ?? statusConfiguration.value.code;
      }
      return statusConfiguration.value.code;
    });

    const icon = computed(() => {
      return statusConfiguration.value.icon;
    });

    const content = computed(() => {
      return statusConfiguration.value.content ?? [];
    });

    return {
      key,
      icon,
      title,
      instructions,
      opened,
      statusConfiguration,
      content,
      onClose,
      onRecovery,
      onResolveByPDF,
      onRetry,
      onDialogItemClick,
      ZLayer,
      disableUserInteractions,
      hasValidStatusConfiguration,
      hasSensitiveDocument,
      canBeClosed,
      canBeRepeated,
      canBeRecovered,
      canBeResolvedToPdf,
    };
  },
});
