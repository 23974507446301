export const messageFields = ['to', 'subject', 'body'] as const;
export type MessageTypeFields = typeof messageFields[number];

export enum PosMessageState {
  BASE_STATE,
  NEW_MESSAGE_STATE,
}

export enum PosMessageActions {
  NEW_MESSAGE_MODAL_STATE_ACTION,
  ATTACHMENTS_LIST_MODAL_STATE_ACTION,
  SHOW_ATTACHMENT_MODAL_STATE_ACTION,
  FOCUS_STATE_ACTION,
  MESSAGE_SET_VALUE_ACTION,
  MESSAGE_SET_CURSOR,
}

export type PosMessageInputEvent<V = any> = {
  type: PosMessageActions,
  value?: V
}

export enum MessageTypes {
  sent = 'Sent',
  received = 'Received',
}

export enum TypesEnum {
  inbox = 'inbox',
  outbox = 'outbox',
  archive = 'archive',
}

export enum SupportedMimeTypesEnum {
  pdf = 'application/pdf',
  img = 'image/',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export interface IPaginator {
  limit: number;
  offset: number;
  total: number;
}
