import {App} from 'vue';
import * as features from '@/Helpers/features';

/**
 * Defines what this module needs
 */
type FeaturesRequiredContext = {
    app: App<any>,
}

export type RegisterTestHelpersContext = {
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $features: typeof features,
    }
}

export function registerFeatures(ctx: FeaturesRequiredContext) {
  ctx.app.use({
    install: (app) => {
      Object.defineProperty(app.config.globalProperties, '$features', {
        get() {
          return features;
        },
      });
    },
  });

  return {};
}
