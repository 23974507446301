export enum WarehouseOperations {
    orderOfGoodsFromDistributionCenter = 'orderOfGoodsFromDistributionCenter',
    receiptOfGoodsFromDistributionCenter = 'receiptOfGoodsFromDistributionCenter',
    receiptOfGoodsFromExternalSupplier = 'receiptOfGoodsFromExternalSupplier',
    receiptOfPrintedMatters = 'receiptOfPrintedMatters',
    reloadingOfGoodsTypeDispense = 'reloadingOfGoodsTypeDispense',
    reloadingOfGoodsTypeReceiving = 'reloadingOfGoodsTypeReceiving',
    returnOfGoodsToDistributionCenter = 'returnOfGoodsToDistributionCenter',
    returnOfPrintedMatters = 'returnOfPrintedMatters',
    returnOfGoodsToExternalSupplier = 'returnOfGoodsToExternalSupplier',
    depreciationOfGoodsTypeConsumption = 'depreciationOfGoodsTypeConsumption',
    depreciationOfGoodsTypeDisposal = 'depreciationOfGoodsTypeDisposal',
}

export enum WarehouseOperationPlantDirections {
    source = 'source',
    destination = 'destination',
}

export enum WarehouseOperationListFilters {
    documentDate = 'documentDate',
    documentType = 'documentType',
    stateOfProcessing = 'stateOfProcessing',
    vendor = 'vendor',
    reference = 'reference',
    sourcePlant = 'sourcePlant',
}

export enum WarehouseOperationListStates {
    listView = 'listView',
    filterView = 'filterView',
}

export enum WarehouseOperationListErrors {
    noDocumentItemCounterpart = 'noDocumentItemCounterpart',
}

export enum WarehouseOperationGoodsListStates {
    listView = 'listView',
    articleSearch = 'articleSearch',
    articleUpdate = 'articleUpdate',
    stockInStores = 'stockInStores',
}

export enum WarehouseOperationAdditionalInfoFields {
    note = 'note',
    reference = 'reference',
    packages = 'packages',
    returnReason = 'returnReason',
}

export enum WarehouseOperationConfirmInfo {
    documentSubType = 'documentSubType',
    logisticsSource = 'logisticsSource',
    logisticsDestination = 'logisticsDestination',
    numberOfItems = 'numberOfItems',
    totalAmount = 'totalAmount',
    totalPrice = 'totalPrice',
    reference = 'reference',
    note = 'note',
    packages = 'packages',
    returnReason = 'returnReason',
}

export enum WarehouseOperationStepFieldGroups {
    additionalInfo = 'additionalInfo',
    distributionCenter = 'distributionCenter',
    goodsList = 'goodsList',
    list = 'list',
    plant = 'plant',
    plantDestination = 'plantDestination',
    plantSource = 'plantSource',
    vendor = 'vendor',
}

export enum WarehouseOperationShipmentNotificationStatuses {
    open = 'Open',
    processed = 'Processed',
}
