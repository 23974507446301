import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import {apiDocumentCreate} from '@/Model/Action';
import {DocumentDto} from '@/Model/Entity';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowStepApiPosPaymentZReport extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiPosPaymentZReport;
  }

  get type() {
    return WorkflowStepApiPosPaymentZReport.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    const {notificationsConnection} = useSignalR();

    if (this.stepFinished) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      const doc = DocumentDto.createPosPaymentZReport();

      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== doc.header.uniqueidentifier) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        doc.preflightSetup();
        return await apiDocumentCreate({
          input: doc.toApiClone(),
          params: {
            disablePrintout: true,
          },
        });
      });

      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      this.stepFinished = true;

      if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
        await this.printContentStore.open(result.printContent);
      }

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      /**
       * PosPaymentZReport is optional
       */
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
