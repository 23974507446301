import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  markRaw,
} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import {AppLoaderEvent} from '@/Modules/Core/types';
import resolveNumbersComponent from './Components/resolve';
import {isEmpty} from 'lodash-es';

export class WorkflowStepKorunkaLotteryNumbers extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaNumbers;
  }

  fetchError = null;

  get type() {
    return WorkflowStepKorunkaLotteryNumbers.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryNumbers.vue').default);
  }

  get gameComponent() {
    return resolveNumbersComponent(this.korunkaType);
  }

  get disabledNextStep() {
    if (
      this.isTicketEntity(this.entity) ||
      this.isCardEntity(this.entity) ||
      this.isScanTicketEntity(this.entity) ||
      this.isPreparedTicketEntity(this.entity) ||
      this.isScanResultEntity(this.entity)
    ) return false;

    return isEmpty(this.entity.randomNumbers);
  }

  get canBeReturnedTo() {
    return !this.isRepeatBetTicket && !this.isTicketFromScan;
  }

  async generateRandomNumbersOnEntity() {
    if (
      this.isTicketEntity(this.entity) ||
      this.isCardEntity(this.entity) ||
      this.isScanTicketEntity(this.entity) ||
      this.isPreparedTicketEntity(this.entity) ||
      this.isGameKombiEntity(this.entity) ||
      this.isGameKombiPlusEntity(this.entity) ||
      this.isScanResultEntity(this.entity)
    ) return;

    try {
      const numbers = await this.entity.generateRandomNumbers();
      await this.syncEntityToCustomerDisplay();
      return numbers;
    } catch (e) {
      console.error(e);

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));

      throw e;
    }
  }

  async generateCustomRandomNumbers({groupsCount = 1, numbersCount = 6} = {}) {
    this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
    try {
      if (this.isGameDvacetZa20Entity(this.entity)) {
        const numbers = await this.entity.generateRandomNumbersWithGroupsCount(groupsCount);
        await this.syncEntityToCustomerDisplay();
        return numbers;
      }

      if (this.isGameHopTropEntity(this.entity)) {
        const numbers = await this.entity.generateRandomNumbers({numbersCount});
        await this.syncEntityToCustomerDisplay();
        return numbers;
      }

      throw new Error('Entity doesn\'t implement method: generateCustomRandomNumbers');
    } catch (e) {
      console.error(e);

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async addRandomNumbersRow() {
    try {
      if (this.isGameDvacetZa20Entity(this.entity)) {
        await this.entity.addRandomNumbersRow();
        await this.syncEntityToCustomerDisplay();
        return;
      }

      throw new Error('Entity doesn\'t implement method: addRandomNumbersRow');
    } catch (e) {
      console.error(e);

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    }
  }

  async removeRandomNumbersRow(index) {
    try {
      if (this.isGameDvacetZa20Entity(this.entity)) {
        this.entity.removeRandomNumbersRow(index);
        await this.syncEntityToCustomerDisplay();
        return;
      }

      throw new Error('Entity doesn\'t implement method: removeRandomNumbersRow');
    } catch (e) {
      console.error(e);

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    }
  }

  async fetchNumbers() {
    this.ensureCustomerDisplayKorunkaPage();

    if (this.isRepeatBetTicket) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.isTicketFromScan) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      await this.syncBasicStepStateToCustomerDisplay();

      if (!this.stepInit) {
        await this.generateRandomNumbersOnEntity();
        await this.syncEntityToCustomerDisplay();
        this.stepInit = true;
      }

      this.fetchError = false;
    } catch (e) {
      console.error(e);

      this.fetchError = true;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  async beforeEnter() {
    await this.fetchNumbers();
  }

  get transitions() {
    return {};
  }
}
