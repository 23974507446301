import {
  createEntity,
  StringType,
} from '@designeo/apibundle-js';

export default class VoucherDiscountChargeRequestDtoCustom extends createEntity({
  requestId: {
    type: new StringType(),
  },
  voucherNumber: {
    type: new StringType(),
  },
}) {}
