import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinEshop} from '../StepMixins/WorkflowStepMixinEshop';
import {apiOrdersTryUpdateCustomerByOrderId} from '@/Model/Action';
import {CreateCustomerDto} from '@/Model/Entity';
import {DateTime} from 'luxon';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {CustomerAttributes} from '@/constants/customerAttributes';

export class WorkflowStepEshopOrderCustomerRegistration extends workflowStepMixinEshop(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.EshopOrderCustomerRegistration;
  }

  get type() {
    return WorkflowStepEshopOrderCustomerRegistration.type;
  }

  get component() {
    return markRaw(require('./StepEshopOrderCustomerRegistration.vue').default);
  }

  get createQuestion() {
    return this.getFieldValue('createQuestion', false);
  }

  set createQuestion(value) {
    this.dataSetter('createQuestion', () => value);
  }

  get createCustomerTriggered() {
    return this.getFieldValue('createCustomerTriggered', false);
  }

  set createCustomerTriggered(value) {
    this.dataSetter('createCustomerTriggered', () => value);
  }

  async updateOrCreateCustomer() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      await apiOrdersTryUpdateCustomerByOrderId({
        params: {
          id: this.order.data.id,
        },
      });

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      if (e.response && e.response.status === 409) {
        this.createQuestion = true;
        return;
      }

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          value: e,
        },
      }));
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  createCustomer() {
    this.createCustomerTriggered = true;

    this.messageBus.dispatchEvent(
      new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {detail: {
        workflowCode: this.step.registrationWorkflowCode,
        params: {
          customerPreset: this.saveBucket(new CreateCustomerDto({
            ...(this.order.data.customer.birthDate ? {
              yearOfBirth: DateTime.fromJSDate(this.order.data.customer.birthDate).toFormat('yyyy'),
            } : {}),
            ...(this.order.data.customer.sex.value ? {
              sex: this.order.data.customer.sex.value,
            } : {}),
            ...(this.order.data.customer.phoneNumber ? {
              phone: this.order.data.customer.phoneNumber,
            } : {}),
            attributes: [
              {
                key: CustomerAttributes.dataRefreshedFromEshop,
                value: this.order.data.eshop.code,
              },
              {
                key: CustomerAttributes.dataRefreshedFromEshopDate,
                value: new Date().toISOString(),
              },
            ],
          }).toJson()),
        },
      }}),
    );
  }

  async beforeEnter() {
    if (!this.stepInit) {
      try {
        await this.ensureOrder();
        this.stepInit = true;
      } catch (err) {
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.EXIT));
      }
    }

    /**
     * we are coming back from workflow
     */
    if (this.createCustomerTriggered) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    await this.updateOrCreateCustomer();
  }

  get transitions() {
    return {};
  }
}
