import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "grid",
  style: {"grid-template-columns":"min-content auto 30% 30%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalStockInStoresBodyTableItem = _resolveComponent("ModalStockInStoresBodyTableItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "px-6 pt-4 pb-2 uppercase font-bold"
      }, _toDisplayString(cell), 1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fetchResult, (item, index) => {
      return (_openBlock(), _createBlock(_component_ModalStockInStoresBodyTableItem, {
        key: index,
        item: item
      }, null, 8, ["item"]))
    }), 128))
  ]))
}