
import {
  computed,
  defineComponent,
} from 'vue';
import {useFilters} from '@/Helpers/filters';
import KorunkaLotteryHopTropDtoCustom from '@/Model/Entity/custom/KorunkaLotteryHopTropDtoCustom';
import {useKorunkaSyncTarget} from '../../syncTarget/KorunkaSyncTarget';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {WorkflowStepTypes} from '../../../Workflow/types';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';

export default defineComponent({
  setup() {
    const {dateFormat, currencyFormat: baseCurrencyFormat} = useFilters();
    const korunkaSyncTarget = useKorunkaSyncTarget();
    const configurationStore = useConfigurationStore();

    const currentStepType = computed(() => korunkaSyncTarget.stepType.value);

    const isStepOverview = computed(() => currentStepType.value === WorkflowStepTypes.KorunkaOverview);

    const entity = computed(() => korunkaSyncTarget.entity.value as KorunkaLotteryHopTropDtoCustom);

    const messages = computed(() => korunkaSyncTarget.messages.value);

    const drawsDates = computed(() => {
      if (!entity.value?.firstAndLastDraw) {
        return null;
      }

      return entity.value.firstAndLastDraw
        .map((value) => dateFormat(value.deadlineForBets, {format: 'dd. MM.'}))
        .join(' - ');
    });

    const canBeDeclined = computed(() => {
      if (!entity.value) {
        return false;
      }

      if (entity.value.isFromScan) {
        return false;
      }


      return true;
    });

    const currencyFormat = (value) => {
      return baseCurrencyFormat(value, configurationStore.localCurrency.value.symbol);
    };

    const onAccept = () => {
      broadcastIO.postMessage(BroadcastIOChannels.KORUNKA_LOTTERY_CONFIRM_RESULT, true);
    };

    const onDecline = () => {
      broadcastIO.postMessage(BroadcastIOChannels.KORUNKA_LOTTERY_CONFIRM_RESULT, false);
    };

    return {
      currentStepType,
      currencyFormat,
      drawsDates,
      entity,
      messages,
      isStepOverview,
      onAccept,
      onDecline,
      korunkaSyncTarget,
      canBeDeclined,
    };
  },
});
