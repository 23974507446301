import * as yup from 'yup';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {action} from '@designeo/vue-helpers/src/index';
import {SafeBagDto} from '@/Model/Entity';
import {
  every,
  map,
  mapKeys,
  omit,
  sumBy,
  values,
} from 'lodash-es';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {apiSearch} from '@/Model/Action';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {wfMaxLengthValidation} from '@/Helpers/validations';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {interpolate} from '@/Helpers/filters';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

export class WorkflowStepSafeBagScanSafeBags extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.SafeBagScanSafeBags;
  }

  get type() {
    return WorkflowStepSafeBagScanSafeBags.type;
  }

  get component() {
    return markRaw(require('./StepSafeBagScanSafeBags.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      ...(this.manualMode ? {
        [WorkflowStepField.safeBagEnvelopeCode]: yup
          .string()
          .required()
          .test({
            name: 'safeBagEnvelopeCode',
            message: 'validations.string.safeBagAlreadyScanned',
            test: async (value: string, context) => {
              if (!value) return true;

              const deliveryList = this.getFieldValue(WorkflowStepField.safeBagDeliveryList, {});

              if (Object.prototype.hasOwnProperty.call(deliveryList, value)) {
                return false;
              }

              try {
                this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
                const {safeBags} = sanitizeApiSearch(await apiSearch({params: {code: value ?? ''}}));

                if (safeBags.length) {
                  return true;
                } else {
                  return context.createError({
                    message: 'validations.string.incorrectSafeBagEnvelopeCode',
                  });
                }
              } catch (e) {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                  detail: {
                    value: e,
                  },
                }));
                return false;
              } finally {
                this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
              }
            },
          }),
        [WorkflowStepField.amount]: yup.string()
          .required()
          .test(wfMaxLengthValidation()),
      } : {
        [WorkflowStepField.safeBagEnvelopeCode]: yup
          .string()
          .test({
            name: 'safeBagEnvelopeCode',
            message: 'validations.string.safeBagAlreadyScanned',
            test: async (value: string, context) => {
              if ((value ?? '') === '') return true;

              try {
                this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
                const {safeBags} = sanitizeApiSearch(await apiSearch({params: {code: value ?? ''}}));

                if (this.deliverySafeBagsByEnvelopeNumber?.[value]?.scanned) {
                  return false;
                }

                if (safeBags.length) {
                  const deliveryListHasEnvelopeNumber = !!Object.prototype.hasOwnProperty.call(
                    this.deliverySafeBagsByEnvelopeNumber, value,
                  );

                  if (!deliveryListHasEnvelopeNumber) {
                    return context.createError({
                      message: 'validations.string.invalidSafeBagEnvelopeCode',
                    });
                  }

                  return true;
                } else {
                  return context.createError({
                    message: 'validations.string.incorrectSafeBagEnvelopeCode',
                  });
                }
              } catch (e) {
                this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                  detail: {
                    value: e,
                  },
                }));
                return false;
              } finally {
                this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
              }
            },
          }),
      }),
    });
  }

  get deliverySafeBags(): Array<{safeBag: SafeBagDto, scanned: boolean}> {
    return map(this.getFieldValue(WorkflowStepField.safeBagDeliveryList, []), (listItem) => ({
      safeBag: new SafeBagDto(listItem.safeBag),
      scanned: listItem.scanned,
    }));
  }

  get scannedTotal() {
    return sumBy(values(this.data[WorkflowStepField.safeBagDeliveryList] ?? {}), (val: any) => {
      if (!val.scanned) return 0;

      return val?.safeBag?.amount ?? 0;
    });
  }

  get everySafeBagScanned() {
    return every(this.deliverySafeBags, {scanned: true});
  }

  get deliverySafeBagsByEnvelopeNumber() {
    return mapKeys(this.deliverySafeBags, 'safeBag.envelopeNumber');
  }

  get manualMode() {
    return this.data[WorkflowStepField.manualMode] ?? false;
  }

  verifySafeBag(safeBag: SafeBagDto['_data']['envelopeNumber']) {
    this.dataSetter(WorkflowStepField.safeBagDeliveryList, (currentValue) => {
      currentValue[safeBag].scanned = true;
      return currentValue;
    });
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.skip, () => false);
  }

  async skipRestSafeBags() {
    this.dataSetter(WorkflowStepField.skip, () => true);
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get skip() {
    return this.getFieldValue(WorkflowStepField.skip, false);
  }

  async removeSafeBag(envelopeNumber) {
    if (await useCoreStore().confirm(interpolate(this.step.confirmEnvelopeDelete, {envelopeNumber}))) {
      this.dataSetter(WorkflowStepField.safeBagDeliveryList, (currentValue) => {
        return omit(currentValue, [envelopeNumber]);
      });
    }
  }

  get disabledNextStep() {
    if (this.manualMode || this.skip) return false;

    return !this.everySafeBagScanned;
  }

  async submit() {
    const safeBagEnvelopeCode = this.getFieldValue(WorkflowStepField.safeBagEnvelopeCode, '');
    const amount = this.getFieldValue(WorkflowStepField.amount, 0);

    if (!await this.validate()) return;

    this.dataSetter(WorkflowStepField.safeBagDeliveryList, (currentValue) => {
      return {
        ...currentValue,
        [safeBagEnvelopeCode]: {
          safeBag: new SafeBagDto({
            envelopeNumber: safeBagEnvelopeCode,
            amount: parseFloat(amount),
            currency: this.step.currency,
          }).toJson(),
          scanned: true,
        },
      };
    });

    this.dataSetter(WorkflowStepField.safeBagEnvelopeCode, () => '');
    this.dataSetter(WorkflowStepField.amount, () => '');

    this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
      detail: {
        field: WorkflowStepField.safeBagEnvelopeCode,
        validate: false,
      },
    }));
  }

  get transitions() {
    return {
      ...(this.manualMode ? {
        ...this.withFieldActions(WorkflowStepField.safeBagEnvelopeCode, (fieldActions) => ({
          [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<string>) => {
            this.dataSetter(WorkflowStepField.safeBagEnvelopeCode, () => event.value);

            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
              detail: {
                field: WorkflowStepField.amount,
              },
            }));
          }),
          [WorkflowActions.ENTER]: action(async () => {
            this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
              detail: {
                field: WorkflowStepField.amount,
              },
            }));
          }),
        })),
        ...this.withFieldActions(WorkflowStepField.amount, (fieldActions) => ({
          [WorkflowActions.ADD_NUMBER]: action(async (event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_NUMBER](event.value);
          }),
          [WorkflowActions.BACKSPACE]: action(() => {
            fieldActions[WorkflowActions.BACKSPACE]();
          }),
          [WorkflowActions.ENTER]: action(async () => {
            await this.submit();
          }),
        })),
      } : {
        ...this.withFieldActions(WorkflowStepField.safeBagEnvelopeCode, (fieldActions) => ({
          [WorkflowActions.SCANNER]: action(async (event: WorkflowInputEvent<string>) => {
            this.dataSetter(WorkflowStepField.safeBagEnvelopeCode, () => event.value);

            try {
              if (!await this.validate()) return;
              emitTestEvent(TestEvent.SAFE_BAG_SCANNED);
            } finally {
              this.dataSetter(WorkflowStepField.safeBagEnvelopeCode, () => '');
            }

            this.verifySafeBag(event.value);
          }),
          [WorkflowActions.ENTER]: action(async () => {
            if (this.everySafeBagScanned) {
              this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
            }
          }),
        })),
      }),
    };
  }
}
