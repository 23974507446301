import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PosUpdateVersionConditionEnum from '../PosUpdateVersionConditionEnum';
import {BooleanType} from '@designeo/apibundle-js';


export const UpdateConditionDtoPropDef = {
  type: {
    type: new EntityType(PosUpdateVersionConditionEnum),
  },
  isFulfilled: {
    type: new BooleanType(),
  },
};
export default class GeneratedUpdateConditionDto extends createEntity(UpdateConditionDtoPropDef) {}
