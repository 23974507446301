import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid grid-cols-2 items-end gap-20 px-2.5 py-1", {
      'bg-basic-light': _ctx.item.isActive,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.item.entity)))
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.item.entity.name), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.item.entity.dc), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, _toDisplayString(_ctx.item.entity.streetAndHouseNo), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.item.entity.city), 1)
    ])
  ], 2))
}