import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalStockInStoresHeader = _resolveComponent("ModalStockInStoresHeader")!
  const _component_ModalStockInStoresBody = _resolveComponent("ModalStockInStoresBody")!
  const _component_ModalStockInStoresButtons = _resolveComponent("ModalStockInStoresButtons")!
  const _component_Qwerty = _resolveComponent("Qwerty")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_HLGrid = _resolveComponent("HLGrid")!

  return (_openBlock(), _createBlock(_component_HLGrid, {
    config: _ctx.gridConfig,
    model: _ctx.gridModel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Modal, {
        ref: "modalRef",
        open: _ctx.open,
        type: "full",
        height: "limited",
        contentClass: "py-2 px-3",
        innerContentClass: "flex flex-col flex-grow",
        silenceKeyboardBuffer: false,
        onClose: _ctx.close
      }, _createSlots({
        headerWrapper: _withCtx(() => [
          _createVNode(_component_ModalStockInStoresHeader)
        ]),
        bodyWrapper: _withCtx(() => [
          _createVNode(_component_ModalStockInStoresBody)
        ]),
        buttons: _withCtx(() => [
          _createVNode(_component_ModalStockInStoresButtons)
        ]),
        _: 2
      }, [
        (_ctx.showKeyboard)
          ? {
              name: "keyboard",
              fn: _withCtx(() => [
                _createVNode(_component_Qwerty, {
                  attachTo: null,
                  onClick: _ctx.onKeyboardInput,
                  onHide: _ctx.toggleShowKeyboard
                }, null, 8, ["onClick", "onHide"])
              ])
            }
          : undefined
      ]), 1032, ["open", "onClose"])
    ]),
    _: 1
  }, 8, ["config", "model"]))
}