import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const VendorDtoPropDef = {
  vendor: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  city: {
    type: new StringType(),
  },
  postalCode: {
    type: new StringType(),
  },
  street: {
    type: new StringType(),
  },
  houseNo: {
    type: new StringType(),
  },
  mail: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
  hasDirectShipment: {
    type: new BooleanType(),
  },
};
export default class GeneratedVendorDto extends createEntity(VendorDtoPropDef) {}
