
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Html',
  props: {
    value: {
      type: null,
      required: false,
      default: null,
    },
  },
});
