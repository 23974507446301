import {isFunction} from 'lodash-es';

export const sortByArray = <T = Record<string, any>>(array: T[], sortOrder: any[], property: keyof T) => {
  const orderMap = new Map(sortOrder.map((key, index) => [key, index + 1]));

  const sorted = array.sort((a, b) => {
    const valueOfA = a?.[property];
    const valueOfB = b?.[property];

    const orderOfA = orderMap.get(valueOfA);
    const orderOfB = orderMap.get(valueOfB);

    return (orderOfA || Number.MAX_VALUE) - (orderOfB || Number.MAX_VALUE);
  });

  return sorted;
};

export const groupByToMap = (array: any[], groupByKey: string | Function) => {
  const map = new Map();

  array.forEach((item) => {
    const keys = isFunction(groupByKey) ? groupByKey(item) : item?.[groupByKey as string];

    if (!keys) return;

    for (const key of keys) {
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    }
  });

  return map;
};
