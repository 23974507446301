
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue';
import {
  WorkflowActions,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import IconPerson from '@/Components/Icons/IconPerson.vue';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {HLStep, useStepTools} from '../StepTools';
import AsyncButton from '@/Components/AsyncButton.vue';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepConfirmGdprConditions',
  components: {WorkflowTransitions,
    AsyncButton,
    HLStep,
    IconPerson},
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.ConfirmGdprConditions;
    const {workflowStep} = useStepTools(stepType);
    const repeatRequestIsVisible = ref(false);

    const onCustomerGdprRequestResponse = async (event: CustomEvent) => {
      try {
        repeatRequestIsVisible.value = !event.detail;
        await workflowStore.onEventInput({
          type: WorkflowActions.ENTER,
          value: event.detail,
        });

        broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-default'});
      } catch (e) {
        console.error(e);
      }
    };

    const repeatRequest = async () => {
      broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-gdpr'});
      repeatRequestIsVisible.value = false;
    };

    onMounted(() => {
      if (!workflowStep.value.stepFinished) {
        broadcastIO.postMessage(BroadcastIOChannels.ROUTE_CHANGE, {name: 'customer-gdpr'});
      }

      broadcastIO.addEventListener(BroadcastIOChannels.GDPR_REQUEST_RESULT, onCustomerGdprRequestResponse);
    });

    onBeforeUnmount(() => {
      broadcastIO.removeEventListener(BroadcastIOChannels.GDPR_REQUEST_RESULT, onCustomerGdprRequestResponse);
    });

    return {
      stepType,
      WorkflowStepField,
      repeatRequest,
      repeatRequestIsVisible,
    };
  },
});
