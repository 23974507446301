
import {defineComponent, PropType} from 'vue';
import DistributionCenterDto from '@/Model/Entity/DistributionCenterDto';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<{isActive: boolean, entity: DistributionCenterDto}>,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
  },
});
