import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {DocumentDto, ResultDto} from '@/Model/Entity';
import {useSignalR} from '@/Helpers/signalR';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {
  apiDocumentCreate,
  apiDocumentGet,
  apiDocumentRepeatablePrinting,
} from '@/Model/Action';
import RepeatablePrintingCommand from '@/Model/Entity/RepeatablePrintingCommand';
import {PrintoutTypes} from '@/constants/printoutTypes';
import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {getResponseCodeConfiguration} from '@/Helpers/printerServiceResponseCodes';
import {OutputTypes} from '@/constants/outputTypes';
import {DocumentCreateMode} from '@/constants/documentModeTypes';

export const workflowStepMixinSaveFinDocument = <TBase extends MixinBase<WorkflowStep>>(superClass: TBase) => {
  const Derived = class WorkflowStepMixinSaveFinDocument extends (superClass as MixinBase<WorkflowStep>) {
    private async apiFinDocumentCreate(document: DocumentDto, mode: DocumentCreateMode): Promise<ResultDto['_data']> {
      const {notificationsConnection} = useSignalR();
      document = document.clone();

      const sellDocumentHeaderGUID = document.header.uniqueidentifier;

      const [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== sellDocumentHeaderGUID) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
        {timeout: null},
      )(async () => {
        if (mode === DocumentCreateMode.print) {
          return await apiDocumentRepeatablePrinting({
            input: new RepeatablePrintingCommand({
              printoutType: document.printoutType?.value ?? PrintoutTypes.ReceiptPrinter,
              outputType: document.printTemplateType?.value ?? OutputTypes.Primary,
              uniqueidentifier: sellDocumentHeaderGUID,
              copies: 1,
            }),
          });
        }

        if (mode === DocumentCreateMode.create) {
          document.disablePrintout = true;
        }

        document.preflightSetup();

        const result = await apiDocumentCreate({
          input: document.toApiClone(),
        });

        return result;
      });

      return result;
    }

    get mandatoryReceiptPrint() {
      return this.configurationStore.configuration.value
        ?.general
        ?.printAndPayment
        ?.mandatoryReceiptPrint ?? true;
    }

    async saveFinDocument(document: DocumentDto): Promise<{
      result: ResultDto['_data'],
      error: Error,
      created: boolean,
      printed: boolean,
      mandatory: boolean,
    }> {
      let result;

      if (this.mandatoryReceiptPrint) {
        try {
          result = await this.apiFinDocumentCreate(document, DocumentCreateMode.createAndPrint);

          if (!getResponseCodeConfiguration(result).finished) {
            return {
              result,
              created: false,
              printed: false,
              error: null,
              mandatory: true,
            };
          }
        } catch (error) {
          return {
            result,
            created: false,
            printed: false,
            error,
            mandatory: true,
          };
        }
      } else {
        try {
          result = await this.apiFinDocumentCreate(document, DocumentCreateMode.create);

          if (!getResponseCodeConfiguration(result).finished) {
            return {
              result,
              created: false,
              printed: false,
              error: null,
              mandatory: false,
            };
          }
        } catch (error) {
          return {
            result,
            created: false,
            printed: false,
            error,
            mandatory: false,
          };
        }

        try {
          result = await this.apiFinDocumentCreate(document, DocumentCreateMode.print);
        } catch (error) {
          return {
            result,
            created: true,
            printed: false,
            error,
            mandatory: false,
          };
        }
      }

      return {
        result,
        created: true,
        printed: getResponseCodeConfiguration(result).finished,
        error: null,
        mandatory: this.mandatoryReceiptPrint,
      };
    }
  };

  return Derived as MergeCtor<typeof Derived, TBase>;
};
