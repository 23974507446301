import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TicketResourceCombinationsCombinationType from '../TicketResourceCombinationsCombinationType';
import {NumberType} from '@designeo/apibundle-js';


export const TicketResourceCombinationsPropDef = {
  combinationType: {
    type: new EntityType(TicketResourceCombinationsCombinationType),
  },
  count: {
    type: new NumberType(),
  },
  amount: {
    type: new NumberType(),
  },
};
export default class GeneratedTicketResourceCombinations extends createEntity(TicketResourceCombinationsPropDef) {}
