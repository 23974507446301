import * as yup from 'yup';
import {
  markRaw,
  toRaw,
} from 'vue';
import {
  WorkflowStepTypes,
  WorkflowActions,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepUIModes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {Context} from '@/Helpers/Context';
import {EventTarget} from 'event-target-shim';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  filter,
  get,
  has,
  identity,
  isFunction,
  isNil,
  last,
  mapKeys,
  omit,
  uniq,
} from 'lodash-es';
import IMask from 'imask';
import {usePrintContentStore} from '@/Modules/Core/store/PrintContentStore';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {action} from '@designeo/vue-helpers/src/index';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import {DocumentDto} from '@/Model/Entity';
import {apiDocumentCreate} from '@/Model/Action';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {emitTestEvent} from '../../../Helpers/testEvent';
import {TestEvent} from '../../../tests/e2e/helpers/testEvents';

type WorkflowActionMap = Partial<{[key in WorkflowActions]: any}>

export class WorkflowStep<STEP extends {[key: string]: any} = {[key: string]: any}> {
  get type(): WorkflowStepTypes {
    throw new Error('Implement in inherited class');
  }

  get transitions(): Partial<{[key in WorkflowStepField]: WorkflowActionMap} | WorkflowActionMap> {
    throw new Error('Implement in inherited class');
  }

  get component(): any {
    throw new Error('Implement in inherited class');
  }

  get isKorunkaStep(): Boolean {
    return false;
  }

  public validationError: {[key: string]: yup.ValidationError} = {}
  public messageBus: EventTarget<Record<WorkflowStepEvents, Event>, 'standard'>
  public cachedScopes: Array<{stop: ()=> void}> = markRaw([]);

  constructor(
    public step: STEP,
    public context: Context,
    public code: WorkflowCodes,
    public index: number,
  ) {
    this.messageBus = new EventTarget();
  }

  stopCachedScopes() {
    for (const scope of this.cachedScopes) {
      scope.stop();
    }
  }

  async beforeEnter() { // step init
    this.stepInit = true;
  }

  async afterEnter() {
    emitTestEvent(`${TestEvent.WORKFLOW_STEP_AFTER_ENTER}:${this.type}`);
  }

  async beforeContinue() {
    this.stepFinished = true;
  }

  async beforeReturn() {}

  get layout() {
    return markRaw(require('./layout/common/Columns.vue').default);
  }

  get stepUIMode() {
    return WorkflowStepUIModes.everywhere;
  }

  get showWorkflowProgress() {
    return true;
  }

  get dataKey() {
    return this.step.dataKey ?? this.code;
  }

  get configurationStore() {
    return useConfigurationStore();
  }

  get printContentStore() {
    return usePrintContentStore();
  }

  get documentStatusStore() {
    return useDocumentStatusStore();
  }

  get workflowStore() {
    return useWorkflowStore();
  }

  getBucket(key) {
    const bucket = this.getFieldValue(`buckets.${key}`);

    if (!isNil(bucket)) {
      return bucket;
    }

    this.dataSetter(`buckets.${key}`, () => this.workflowStore.collectBucket(key));

    return this.getFieldValue(`buckets.${key}`);
  }

  saveBucket(content, {key = undefined} = {}) {
    return this.workflowStore.saveBucket(content, {key});
  }

  get params() {
    return this.context.state?.params ?? {};
  }

  get data() {
    return this.context.get(this.dataKey);
  }

  getFieldValue(
    field: WorkflowStepField | string | string[],
    fallback = undefined,
  ) {
    return get(this.data, field, fallback);
  }

  get canBeReturnedTo(): Promise<boolean> | boolean {
    return Promise.resolve(!this.step.isTechnicalStep && !this.isPointOfNoReturn);
  }

  get isAfterPointOfNoReturn(): boolean {
    if (this.index === 0) {
      return false;
    }

    return this.workflowStore.currentWorkflow.value?.steps?.[this.index - 1]?.isPointOfNoReturn ?? false;
  }

  get isPointOfNoReturn(): Promise<boolean> | boolean {
    return this.step.isPointOfNoReturn ?? false;
  }

  get disabledNextStep() {
    return this.context.get(this.dataKey, {section: this.type})?.disabledNextStep ?? false;
  }

  set disabledNextStep(val: boolean) {
    this.context.modifyAttribute(this.dataKey, 'disabledNextStep', () => val, {section: this.type});
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({});
  }

  validatorHasField(field) {
    try {
      yup.reach(this.validator, field);
      return true;
    } catch (e) {
      return false;
    }
  }

  getFieldValidator(field): yup.AnySchema {
    try {
      return yup.reach(this.validator, field);
    } catch (e) {
      return null;
    }
  }

  resetInvalidField(field) {
    this.dataSetter(
      field,
      () => {
        const fieldValidator = this.getFieldValidator(field);
        const hasDefault = fieldValidator && has(fieldValidator.spec, 'default');

        if (hasDefault && isFunction(fieldValidator.spec.default)) {
          return fieldValidator.spec.default();
        } else if (hasDefault) {
          return fieldValidator.spec.default;
        }

        return fieldValidator?.type === 'string' ? '' : null;
      },
    );
  }

  async validate({data = this.data, abortEarly = false} = {}) {
    try {
      await this.validator.validate(data, {abortEarly});
      this.validationError = {};
      return true;
    } catch (e) {
      this.validationError = {
        ...this.validationError,
        ...mapKeys(e.inner, 'path'),
      };

      // Clear invalid fields
      for (const field of Object.keys(this.validationError)) {
        this.resetInvalidField(field);
      }

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
        detail: {
          field: Object.keys(this.validationError)[0] ?? null,
          validate: false,
        },
      }));

      return false;
    }
  }

  async validateAt(field, {data = this.data, clearOnFail = false} = {}) {
    if (!this.validatorHasField(field)) return true;

    try {
      await this.validator.validateAt(field, data);
      this.validationError = omit(this.validationError, [field]);
      return true;
    // @ts-ignore
    } catch (e: yup.ValidationError) {
      this.validationError = {
        ...this.validationError,
        [e.path]: e,
      };
      if (clearOnFail) {
        this.resetInvalidField(field);
      }
      return false;
    }
  }

  validationErrorAt(field) {
    if (!this.validationError || !this.validationError[field]) return null;

    return this.validationError[field];
  }

  get hasValidationError() {
    return !!Object.keys(this.validationError).length;
  }

  dataSetter(
    field: WorkflowStepField | string,
    modifier: (currentValue: any)=> any,
  ) {
    if (field) {
      this.context.modifyAttribute(this.dataKey, field, modifier);
    } else {
      this.context.modifySection(this.dataKey, modifier);
    }
  }

  get state() {
    return this.context.get(this.dataKey, {section: 'state'});
  }

  stateSetter(state: string, modifier: (currentValue: any)=> any) {
    this.context.modifyAttribute(this.dataKey, state, modifier, {section: 'state'});
  }

  get errors() {
    return this.context.get(this.dataKey, {section: 'errors'});
  }

  errorsSetter(field: string, modifier: (currentValue: any)=> any) {
    this.context.modifyAttribute(
      this.dataKey,
      field,
      (currentValue) => uniq([].concat(modifier(currentValue ?? []) ?? [])),
      {section: 'errors'},
    );
  }

  get stepFinished() {
    return this.context.get(this.dataKey, {section: this.type})?.finished ?? false;
  }

  set stepFinished(val: boolean) {
    this.context.modifyAttribute(this.dataKey, 'finished', () => val, {section: this.type});
  }

  get stepInit() {
    return this.context.get(this.dataKey, {section: this.type})?.init ?? false;
  }

  set stepInit(val: boolean) {
    this.context.modifyAttribute(this.dataKey, 'init', () => val, {section: this.type});
  }

  recordStep() {
    this.context.modifyAttribute(
      this.code,
      WorkflowStepField.stepPositions,
      (currentValue: Array<WorkflowStepTypes>) => {
        currentValue = currentValue ?? [];

        if (last(currentValue) === this.type) {
          return currentValue;
        }

        return [...currentValue].concat(this.type);
      },
      {section: 'history'},
    );
  }

  hasVisited(step: WorkflowStepTypes, {count = 1} = {}) {
    const history = toRaw(this.context.get(this.code, {section: 'history'})?.[WorkflowStepField.stepPositions] ?? []);

    return filter(history, (historyItem) => historyItem === step)?.length >= count;
  }

  get insertMode() {
    return this.context.get(this.dataKey, {section: this.type})?.insertMode ?? false;
  }

  set insertMode(val: boolean) {
    this.context.modifyAttribute(this.dataKey, 'insertMode', () => val, {section: this.type});
  }

  get title() {
    return this.step.title;
  }

  get instructions() {
    return this.step.instructions;
  }

  /**
   * This fn setups default behaviour for insert mode of workflow step
   * Override it within step if needed
   */
  activateInsertMode(field: string) {
    return this.insertMode;
  }

  valToString(val) { // TODO make transformation map with right types, not just string :thinkingface:
    return (val ?? '').toString();
  }

  withFieldActions(
    field: WorkflowStepField | string,
    fn: (actions: WorkflowActionMap)=> WorkflowActionMap,
    {
      mask = null,
      transformValue = identity,
    }: {
      mask?: IMask.Masked<any>,
      transformValue?: Function
    } = {},
  ) {
    const transformFunction = transformValue;
    const withMask = (fn, {isComplete = true} = {}) => {
      return (currentVal) => {
        currentVal = this.valToString(currentVal);

        if (isNil(mask)) {
          return fn(currentVal);
        }

        mask.resolve(fn(currentVal));
        return mask.unmaskedValue;
      };
    };

    const withTransformation = (fn) => {
      return (currentVal) => {
        return transformFunction(fn(currentVal));
      };
    };


    return {
      [field]: fn({
        [WorkflowActions.ADD_CHAR]: async (value) => {
          if (this.insertMode) {
            this.dataSetter(field, withMask(withTransformation((oldVal) => value)));
            this.insertMode = false;
          } else {
            this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal + value)));
          }
          await this.validateAt(field);
        },
        [WorkflowActions.ADD_NUMBER]: async (value) => {
          if (this.insertMode) {
            this.dataSetter(field, withMask(withTransformation((oldVal) => value)));
            this.insertMode = false;
          } else {
            this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal + value)));
          }
          await this.validateAt(field);
        },
        [WorkflowActions.ADD_COMMA]: async (value) => {
          if (this.insertMode) {
            this.dataSetter(field, withMask(withTransformation((oldVal) => '.')));
            this.insertMode = false;
          } else {
            this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal + '.')));
          }
          await this.validateAt(field);
        },
        [WorkflowActions.ADD_PERIOD]: async (value) => {
          if (this.insertMode) {
            this.dataSetter(field, withMask(withTransformation((oldVal) => '.')));
            this.insertMode = false;
          } else {
            this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal + '.')));
          }
          await this.validateAt(field);
        },
        [WorkflowActions.ADD_PLUS]: async (value) => {
          if (this.insertMode) {
            this.dataSetter(field, withMask(withTransformation((oldVal) => value)));
            this.insertMode = false;
          } else {
            this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal + value)));
          }
          await this.validateAt(field);
        },
        [WorkflowActions.ADD_MINUS]: async () => {
          if (this.insertMode) {
            this.dataSetter(field, withMask(withTransformation((oldVal) => '-')));
            this.insertMode = false;
          } else {
            this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal + '-')));
          }
          await this.validateAt(field);
        },
        [WorkflowActions.BACKSPACE]: async () => {
          this.dataSetter(field, withMask(withTransformation((oldVal) => oldVal.slice(0, oldVal.length - 1))));
          await this.validateAt(field);
        },
        [WorkflowActions.ENTER]: async (value) => {
          this.dataSetter(field, withMask(withTransformation(() => value)));
          await this.validateAt(field);
        },
      }),
    };
  }

  createArrowMovementTransitions(fields, index) {
    return {
      [WorkflowActions.ARROW_UP]: action(() => {
        const previousField = fields[index - 1] ?? fields[index];

        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
          detail: {
            field: previousField,
          },
        }));
      }),
      [WorkflowActions.ARROW_DOWN]: action(() => {
        const nextField = fields[index + 1] ?? fields[index];

        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
          detail: {
            field: nextField,
          },
        }));
      }),
    };
  }

  get keyboardKeyQwerty() {
    return this.step.keyboard?.keyQwerty ?? true;
  }

  get keyboardKeyCashDrawer() {
    return this.step.keyboard?.keyCashDrawer ?? false;
  }

  async keyboardKeyCashDrawerFn() {
    const {notificationsConnection} = useSignalR();

    try {
      this.messageBus.dispatchEvent(new CustomEvent(AppLoaderEvent.ON));

      const cashDrawer = DocumentDto.createCashDrawerDocument();

      const sellDocumentHeaderGUID = cashDrawer.header.uniqueidentifier;

      await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== sellDocumentHeaderGUID) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        cashDrawer.preflightSetup();

        return await apiDocumentCreate({
          input: cashDrawer.toApiClone(),
        });
      });
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }
    } finally {
      this.messageBus.dispatchEvent(new CustomEvent(AppLoaderEvent.OFF));
    }
  }
}
