import {
  createConfigureStore,
  createUseStore,
  getter,
  Store,
  action,
} from '@designeo/vue-helpers';

export interface IPosCoreNotAvailableStore {
  active?: boolean
}

const createInitState = (data?: Partial<IPosCoreNotAvailableStore>): IPosCoreNotAvailableStore => ({
  active: null,
});

export class PosCoreNotAvailableStore extends Store<IPosCoreNotAvailableStore> {
  constructor() {
    super(createInitState());
  }

  setState = action((state: boolean) => {
    this.state.active = state;
  })

  active = getter(() => {
    return this.state.active;
  })
}

const storeIdentifier = 'PosCoreNotAvailableStore';

export const configurePosCoreNotAvailableStore = createConfigureStore<typeof PosCoreNotAvailableStore>(storeIdentifier);
export const usePosCoreNotAvailableStore = createUseStore(PosCoreNotAvailableStore, storeIdentifier);
