import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFilters} from '@/Helpers/filters';

export const useHelpTranslations = () => {
  const {translatedString, interpolate} = useFilters();
  const configurationStore = useConfigurationStore();

  const getErrorName = (errorId, params = {}) => {
    const value = configurationStore.configuration.value?.help?.[errorId]?.name;
    if (!errorId || !value) return errorId;

    return interpolate(translatedString(value), params);
  };

  const getErrorHelp = (errorId, params = {}) => {
    const value = configurationStore.configuration.value?.help?.[errorId]?.help;
    if (!errorId || !value) return null;

    return interpolate(translatedString(value), params);
  };

  const getError = (errorId, params = {}) => {
    return {
      id: errorId,
      name: getErrorName(errorId, params),
      help: getErrorHelp(errorId, params),
    };
  };

  return {
    getErrorName,
    getErrorHelp,
    getError,
  };
};
