import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TransactionType from '../TransactionType';


export const AccountTransactionPreviewDtoPropDef = {
  id: {
    type: new StringType(),
  },
  receiptId: {
    type: new StringType(),
  },
  transactionDate: {
    type: new DateTimeType(),
  },
  shopId: {
    type: new StringType(),
  },
  clubCode: {
    type: new StringType(),
  },
  place: {
    type: new StringType(),
  },
  points: {
    type: new NumberType(),
  },
  type: {
    type: new EntityType(TransactionType),
  },
  pointsValidTill: {
    type: new DateTimeType(),
  },
  note: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
};
export default class GeneratedAccountTransactionPreviewDto extends createEntity(AccountTransactionPreviewDtoPropDef) {}
