import {markRaw} from 'vue';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers';
import {isNil} from 'lodash-es';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import * as yup from 'yup';

export class WorkflowStepBTCEnterOrderPin extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.BTCEnterOrderPin;
  }

  get type() {
    return WorkflowStepBTCEnterOrderPin.type;
  }

  get component() {
    return markRaw(require('./StepBTCEnterOrderPin.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.orderPin]: yup.string()
        .required(),
    });
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.orderPin, () => '');
  }

  get disabledNextStep() {
    return !this.getFieldValue(WorkflowStepField.orderPin, '');
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.orderPin, (fieldActions) => ({
        [WorkflowActions.ENTER]: action(async (event: WorkflowInputEvent<boolean>) => {
          if (!isNil(event.value)) {
            if (event.value) {
              fieldActions[WorkflowActions.ENTER](event.value);
              this.dataSetter(WorkflowStepField.btcDisplayError, () => null);
              this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
            } else {
              this.errorsSetter(
                WorkflowStepField.orderPin, (errors) => errors.concat(WorkflowStepErrors.PIN_VERIFICATION_FAILED),
              );
              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
                detail: {
                  type: WorkflowStepErrors.PIN_VERIFICATION_FAILED,
                },
              }));
            }
          }
        }),
      })),
    };
  }
}
