import GeneratedDrawPartnerResource from './generated/DrawPartnerResource';
import {DateTime} from 'luxon';
export default class DrawPartnerResource extends GeneratedDrawPartnerResource {
  get deadlineForBetsNoTime() {
    return DateTime.fromJSDate(this.deadlineForBets)
      .set({hour: 0, minute: 0, second: 0})
      .toJSDate();
  }

  get drawTypeAsMessage() {
    switch (this.type.value) {
    case 'AFTERNOON':
      return 'afternoonDraw';
    case 'EVENING':
      return 'eveningDraw';
    case 'SPECIAL':
      return 'specialDraw';
    default: return null;
    }
  }
}
