import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "text-2xl text-danger font-bold" }
const _hoisted_3 = { class: "pb-3" }
const _hoisted_4 = { class: "text-lg" }
const _hoisted_5 = { class: "mt-4 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_Html = _resolveComponent("Html")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    type: "lg",
    open: _ctx.helpStore.isActive.value,
    zIndex: _ctx.zIndex,
    onClose: _ctx.close
  }, {
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_ModalDetachedClose, { onClose: _ctx.close }, null, 8, ["onClose"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Html, {
                value: _ctx.error.name
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", null, [
              (_ctx.description)
                ? (_openBlock(), _createBlock(_component_Html, {
                    key: 0,
                    class: "block whitespace-pre-line mt-3",
                    value: _ctx.description
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Html, {
            value: _ctx.error.help
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("button", {
            type: "button",
            class: "btn-outline-basic py-3 px-9 font-normal text-uppercase",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('action.close')), 1)
          ], 512), [
            [_directive_test, 'closeModal']
          ])
        ])
      ], 512), [
        [
          _directive_test,
          _ctx.error.id,
          void 0,
          { helpModal: true }
        ]
      ])
    ]),
    _: 1
  }, 8, ["open", "zIndex", "onClose"]))
}