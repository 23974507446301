
import {
  defineComponent,
} from 'vue';
import PhIcon from '@/Components/PhIcon.vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import OrderItemList from './OrderItemList.vue';
import OrderDetail from './OrderDetail.vue';
import {
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {useFilters} from '@/Helpers/filters';
import IconLoaderPulse from '@/Components/Icons/IconLoaderPulse.vue';
import OrderPaymentState from './OrderPaymentState.vue';
import OrderPrint from './OrderPrint.vue';

export default defineComponent({
  name: 'StepEshopOrderOverview',
  components: {
    OrderItemList,
    OrderDetail,
    WorkflowTransitions,
    HLStep,
    IconLoaderPulse,
    PhIcon,
    OrderPaymentState,
    OrderPrint,
  },
  setup() {
    const stepType = WorkflowStepTypes.EshopOrderOverview;
    const {dateFormat, phoneFormat} = useFilters();

    return {
      stepType,
      WorkflowStepField,
      phoneFormat,
      dateFormat,
    };
  },
});
