import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {
  flatMap,
  flow,
  groupBy,
  isNil,
  keys,
  map,
  mapValues,
  orderBy,
  sum,
} from 'lodash-es';
import {LotteryTurnoversDto} from '@/Model/Entity';
import IMask from 'imask';
import {
  sanitizeFields,
  fieldsWithIndex,
  getOriginalField,
  sumLotteryFields,
} from '@/Helpers/lotteryFields';
import {fixDecimals} from '@/Helpers/math';

export class WorkflowStepEnterLotteryCategories extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterLotteryCategories;
  }

  get type() {
    return WorkflowStepEnterLotteryCategories.type;
  }

  get component() {
    return markRaw(require('./StepEnterLotteryCategories.vue').default);
  }

  get terminalCount() {
    return this.configurationStore.configuration.value?.features?.lottery?.terminalCount ?? 1;
  }

  get inputCharsMaxLen() {
    return this.configurationStore.configuration.value?.features?.lottery?.inputCharsMaxLen ?? 6;
  }

  get categories() {
    const categories = this.configurationStore.configuration.value.features.lottery.articleCategories;
    const entries = Object.entries(categories);
    const sortedEntries = orderBy(entries, ([key, value]: [string, any]) => value?.order ?? null);

    // Put sorted entries back to Map
    // we use new Map because it guarantees the order of keys
    // objects can automatically sort "number" keys
    return sortedEntries.reduce((acc, [k, v]: [string, any]) => {
      acc.set(k, v.title);
      return acc;
    }, new Map());
  }

  get categoriesCorrectionSum() {
    return flow(
      (categories: Map<string, any>) => ([...categories.keys()]),
      (internalNumbers: string[]) => map(internalNumbers, (internalNumber) => {
        const value = String(this.sumCategoryFieldValues(internalNumber));
        return this.lotteryTurnovers.getCategoryCorrection(internalNumber, value);
      }),
      (values: number[]) => sum(values),
    )(this.categories);
  }

  get lotteryAmountEquals() {
    if (this.step.canSkipOnLotteryTotalEquals) {
      return this.getFieldValue(WorkflowStepField.lotteryAmountEquals, false);
    } else {
      return false;
    }
  }

  get canBeReturnedTo() {
    return this.lotteryAmountEquals === false;
  }

  get fields() {
    return sanitizeFields(
      flatMap([...this.categories.keys()], (categoryId) => {
        return fieldsWithIndex(
          `${WorkflowStepField.lotteryCategories}.${categoryId}.${WorkflowStepField.amount}`,
          this.terminalCount,
        );
      }),
    );
  }

  get lotteryTurnovers() {
    return new LotteryTurnoversDto(this.getFieldValue(WorkflowStepField.lotteryTurnovers, {}));
  }

  getCategoryFieldsByInternalNumber(internalNumber) {
    const fields = groupBy(this.fields, (field) => {
      const [stepField, internalNumber, amount, index] = getOriginalField(field).split('.');
      return internalNumber;
    });

    return fields[internalNumber];
  }

  sumCategoryFieldValues(internalNumber): number {
    const fields = this.getCategoryFieldsByInternalNumber(internalNumber);
    const fieldData = map(fields, (field) => this.getFieldValue(field, '0') || '0');
    const sum = sumLotteryFields(fieldData);

    return !isNil(sum) ? fixDecimals(sum) : null;
  }

  async beforeEnter() {
    // always recalculate articles when they are set
    const articles: any[] = this.getFieldValue(WorkflowStepField.lotteryArticlesFromInaccurateCategories, []);
    if (articles.length) {
      this.dataSetter(WorkflowStepField.lotteryArticlesFromInaccurateCategories, () => {
        return [];
      });
    }

    if (this.lotteryAmountEquals) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }
  }

  async beforeContinue() {
    if (this.lotteryAmountEquals) return;

    try {
      this.fields.forEach((field) => {
        const amount = this.getFieldValue(field, '0');
        this.dataSetter(field, () => amount);
      });

      this.stepFinished = true;
    } catch (err) {
      console.error(err);
    }
  }

  get transitions() {
    return {
      ...map(this.fields, (field, index: number) => {
        const [
          /* skip*/,
          categoryId,
          /* skip*/
        ] = getOriginalField(field).split('.');

        const categoryIsNegative = this.configurationStore
          .configuration
          ?.value
          ?.features
          ?.lottery
          ?.articleCategories
          ?.[categoryId]
          ?.isNegative ?? false;

        return {
          ...this.withFieldActions(field, (fieldActions) => ({
            [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
              fieldActions[WorkflowActions.ADD_NUMBER](event.value);
            }),
            [WorkflowActions.BACKSPACE]: action(() => {
              fieldActions[WorkflowActions.BACKSPACE]();
            }),
            [WorkflowActions.ADD_COMMA]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_COMMA](event.value);
            }),
            [WorkflowActions.ADD_PERIOD]: action((event: WorkflowInputEvent<string>) => {
              if (this.getFieldValue(field, '').includes('.')) return;
              fieldActions[WorkflowActions.ADD_PERIOD](event.value);
            }),
            [WorkflowActions.ENTER]: action(async () => {
              const nextField = this.fields[index + 1] ?? this.fields[index];

              this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, {
                detail: {
                  field: nextField,
                },
              }));
            }),
            ...this.createArrowMovementTransitions(this.fields, index),
          }), {
            mask: IMask.createMask({
              mask: Array.from({length: this.inputCharsMaxLen + (categoryIsNegative ? 1 : 0)}, () => '*').join(),
            }),
            transformValue: (value) => {
              if (parseInt(value) === 0) return value;

              if (categoryIsNegative && parseInt(value) > 0) {
                return String(Math.abs(parseFloat(value)) * -1);
              }

              return isNaN(value) ? '' : value;
            },
          }),
        };
      }).reduce((acc, val, index) => ({...acc, ...val}), {}),
    };
  }
}
