import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import {isElectron, quitApp} from '@/Helpers/app';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export const useElectron = () => {
  const i18n = useI18n();
  const coreStore = useCoreStore();

  const canQuit = computed(() => {
    return isElectron();
  });

  const quit = async () => {
    if (await coreStore.confirm(i18n.t('apps.home.quit.confirm'))) {
      quitApp();
    }
  };

  return {
    canQuit,
    quit,
  };
};
