import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {DocumentDto} from '@/Model/Entity';
import {api3rdPartyCharge} from '@/Model/Action';
import {CheckAndChargeProviders} from '@/constants/checkAndChargeProviders';
import {CheckAndChargeServiceTypes} from '@/constants/checkAndChargeServiceTypes';
import {AppLoaderEvent} from '@/Modules/Core/types';
import EasyCoinOrderDto from '@/Model/Entity/EasyCoinOrderDto';

export class WorkflowStepBTCCharge extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.BTCCharge;
  }

  get type() {
    return WorkflowStepBTCCharge.type;
  }

  get component() {
    return markRaw(require('./StepBTCCharge.vue').default);
  }

  get document() {
    return new DocumentDto(this.data?.[WorkflowStepField.document] ?? {});
  }

  get checkResponse() {
    return this.document.itemsGroupedBySets?.[0].editableItem?.checkResponse;
  }

  async beforeEnter() {
    this.dataSetter(WorkflowStepField.amount, () => {
      return (<EasyCoinOrderDto> this.checkResponse?.entity)?.fiat_amount ?? 0;
    });

    this.dataSetter(WorkflowStepField.note, () => {
      return (<EasyCoinOrderDto> this.checkResponse?.entity)?.payment_num;
    });
  }

  async beforeContinue() {
    const documentItem = this.document.itemsGroupedBySets?.[0].editableItem;
    documentItem.btcPin = this.data[WorkflowStepField.orderPin];
    const request = documentItem?.ensureCheckAndChargeChargeRequest(this.document, 0);

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      const response = await api3rdPartyCharge({
        input: request?.toJson(),
        params: {
          provider: this.step.isMock ? CheckAndChargeProviders.MOCK_EASYCOIN : CheckAndChargeProviders.EASYCOIN,
          serviceType: CheckAndChargeServiceTypes.BTC,
          ...documentItem.getCommonCheckAndChargeParams(this.document),
        },
      });

      documentItem.setChargeResponseWithinContext(
        this.document,
        0,
        response,
      );

      if (!response.success) {
        throw new Error('requestFailed');
      }
    } catch (e) {
      console.error(e);

      this.dataSetter(WorkflowStepField.btcDisplayError, () => true);

      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
          value: e,
        },
      }));

      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
