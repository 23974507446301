import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const CancelTicketNumberResourcePropDef = {
  ticketNumber: {
    type: new NumberType(),
  },
  operationId: {
    type: new StringType(),
  },
};
export default class GeneratedCancelTicketNumberResource extends createEntity(CancelTicketNumberResourcePropDef) {}
