import {
  App,
} from 'vue';
import * as aclKeys from '@/constants/aclKeys';
import {useAcl} from '@/Helpers/acl';

/**
 * Defines what this module needs
 */
type CoreRequiredContext = {
  app: App<any>
}

export type RegisterTestHelpersContext = {
}

export function registerAcl(ctx: CoreRequiredContext) {
  ctx.app.mixin({
    data() {
      return {
        $aclKeys: aclKeys,
      };
    },
    computed: {
      $acl() {
        return useAcl();
      },
    },
  });

  return {};
}
