
import {
  computed,
  defineComponent,
} from 'vue';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import InputBuffer from '@/Components/InputBuffer.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import {HLScrollIntoView} from '@/Components/HLScrollIntoView';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {map, sum} from 'lodash-es';

export default defineComponent({
  name: 'StepEnterLotteryArticles',
  components: {
    HLStep,
    HLInput,
    FormInput,
    WorkflowTransitions,
    InputBuffer,
    IconCheck,
    HLScrollIntoView,
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterLotteryArticles;

    const {workflowStep}: {workflowStep} = useStepTools(stepType);

    const stepCategory = computed(() => {
      return workflowStep.value.stepCategory;
    });

    const hasUnresolvedArticles = computed(() => {
      return workflowStep.value.articlesByInaccurateCategoryId?.length > 0 && !workflowStep.value.isResolvedByCategory;
    });

    const categoryEnteredAmount = computed(() => {
      /* eslint-disable-next-line */
      const field = `${WorkflowStepField.lotteryCategories}.${stepCategory.value}.${WorkflowStepField.amount}`;
      const value = workflowStep.value.getFieldValue(field);

      return value;
    });

    const articleValuesSum = computed(() => {
      const articles = workflowStep.value.articlesByInaccurateCategoryId;

      return sum(map(articles, (article) => {
        const field = workflowStep.value.getFieldKeyByInternalNumber(article.internalNumber);
        const amount = workflowStep.value.getFieldValue(field);
        return workflowStep.value.lotteryTurnovers.getArticleCorrection(article, amount);
      }));
    });

    return {
      stepType,
      stepCategory,
      categoryEnteredAmount,
      articleValuesSum,
      hasUnresolvedArticles,
    };
  },
});
