import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const ReceiptCounterDtoPropDef = {
  count: {
    type: new NumberType(),
  },
};
export default class GeneratedReceiptCounterDto extends createEntity(ReceiptCounterDtoPropDef) {}
