import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const CurrencyStateDtoPropDef = {
  czk: {
    type: new NumberType(),
  },
  eur: {
    type: new NumberType(),
  },
};
export default class GeneratedCurrencyStateDto extends createEntity(CurrencyStateDtoPropDef) {}
