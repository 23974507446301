
import {defineComponent, PropType} from 'vue';
import WarehouseOperationListItem
  from '@/Modules/Workflow/Step/StepWarehouseOperationList/WarehouseOperationListItem.vue';
import ShipmentNotificationListDto from '@/Model/Entity/ShipmentNotificationListDto';

export default defineComponent({
  components: {WarehouseOperationListItem},
  props: {
    list: {
      type: Array as PropType<
        Array<{
          isActive: boolean,
          entity: ShipmentNotificationListDto,
        }>
      >,
      required: true,
    },
  },
  emits: ['select'],
  setup() {
    return {};
  },
});
