import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OutputType from '../OutputType';
import Target from '../Target';
import TemplateOrientation from '../TemplateOrientation';
import {BooleanType} from '@designeo/apibundle-js';


export const OutputDtoPropDef = {
  codePage: {
    type: new NumberType(),
  },
  outputBase64: {
    type: new StringType(),
  },
  outputType: {
    type: new EntityType(OutputType),
  },
  templateCode: {
    type: new StringType(),
  },
  target: {
    type: new EntityType(Target),
  },
  templateOrientation: {
    type: new EntityType(TemplateOrientation),
  },
  forceA4RotatedSize: {
    type: new BooleanType(),
  },
  htmlBase64: {
    type: new StringType(),
  },
  pdfBase64: {
    type: new StringType(),
  },
};
export default class GeneratedOutputDto extends createEntity(OutputDtoPropDef) {}
