import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col divide-y divide-basic-light -my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarehouseOperationPlantListItem = _resolveComponent("WarehouseOperationPlantListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (listItem, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "py-1.5"
      }, [
        _createVNode(_component_WarehouseOperationPlantListItem, {
          item: listItem,
          onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', $event)))
        }, null, 8, ["item"])
      ]))
    }), 128))
  ]))
}