import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {usePromoEngine} from './../Register/PromoEngine/PromoEngine';
import routes from '../Core/routes';
import {App} from 'vue';
import {useSignalR} from '@/Helpers/signalR';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {
  apiColdBoot,
  apiConfigurationGet,
  apiPrinterUpdateConfiguration,
  apiTechnicalStatusPrinterService,
  apiV1PosPromotionsBulk,
} from '@/Model/Action';
import {switchTheme} from '@/Helpers/theme';
import {
  Configuration,
  PromotionBulkDto,
  PromotionDto,
} from '@/Model/Entity';
import {CoreWSEvents} from '@/Modules/Core/types';
import {createTbtTranslations} from '@/Plugins/i18n';
import {wait} from '@designeo/js-helpers/src/timing/wait';
import {configurationPostProcess} from '@/Helpers/reset';
import {initSentry, sentryCaptureEvent} from '@/Helpers/sentry';
import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';
import {Theme} from '@/constants/theme';
import {getPrinters, isElectron} from '@/Helpers/app';
import {map} from 'lodash-es';
import UpdatePrinterConfigurationDto from '../../Model/Entity/UpdatePrinterConfigurationDto';
import {coldStartSessionsStorage} from '@/Helpers/persist';
import {IRestoredErrorRecord} from '@/Helpers/restoreMode';
import {createPromoEngineWorker} from '@/Modules/Register/PromoEngine/createPromoEngineWorker';
import {useSynchronizationStore} from '@/Modules/Core/store/SynchronizationStore';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {usePrinterServiceStatusStore} from '@/Modules/Core/store/PrinterServiceStatusStore';
import {recordCustomEventLogEntry} from '@/Helpers/logger';

/**
 * Defines what this module needs
 */
export type CoreRequiredContext = import('../../ModuleSystem').ModuleSystem & {
  app: App<any>,
  isPdaApp?: boolean,
  isElectron: boolean,
  isRestoreMode: boolean,
  restoredErrorRecord: IRestoredErrorRecord
}

/**
 * Define what this module provides thru context
 */
export type CoreContext = CoreRequiredContext & {
}

async function ensureColdStartSync() {
  try {
    if (coldStartSessionsStorage.get()?.value) {
      coldStartSessionsStorage.set({
        startedAt: new Date().toISOString(),
        value: false,
      });
      if (isElectron()) {
        await apiColdBoot();
      }
    }
  } catch (e) {
    await wait(3000)(null);
    return await ensureColdStartSync();
  }
}

async function ensurePrinterSync() {
  try {
    if (!isElectron()) {
      return;
    }

    await apiPrinterUpdateConfiguration({
      input: map(getPrinters() ?? [], (printer) => new UpdatePrinterConfigurationDto(printer)),
    });
  } catch (e) {
    await wait(3000)(null);
    return await ensurePrinterSync();
  }
}

async function ensurePrimaryDisplayPing() {
  try {
    await broadcastIO.postMessageWithCallback(
      BroadcastIOChannels.PRIMARY_WINDOW_PING,
      null,
      BroadcastIOChannels.PRIMARY_WINDOW_PONG,
      {timeout: 3000},
    );
  } catch (e) {
    console.error(e);
    return await ensurePrimaryDisplayPing();
  }
}

async function ensurePrimaryDisplayPong() {
  broadcastIO.addEventListener(BroadcastIOChannels.PRIMARY_WINDOW_PING, () => {
    broadcastIO.postMessage(BroadcastIOChannels.PRIMARY_WINDOW_PONG);
  });
}

async function ensureConfiguration() {
  try {
    return await apiConfigurationGet();
  } catch (e) {
    await wait(3000)(null);
    return await ensureConfiguration();
  }
}

async function ensurePrinterServiceStatus(tries = 10) {
  for (let i = 0; i < tries; i++) {
    try {
      return await apiTechnicalStatusPrinterService();
    } catch (e) {
      if (i === tries - 1) {
        throw e;
      } else {
        await wait(3000)(null);
      }
    }
  }
}


export function registerCoreModule() {
  return async (ctx: CoreRequiredContext) => {
    await ctx.registerRoutes(routes);

    const {notificationsConnection} = useSignalR();
    const configurationStore = useConfigurationStore();
    const authStore = useAuthStore();
    const updateStore = useUpdateStore();
    const synchronizationStore = useSynchronizationStore();
    const printerServiceStatusStore = usePrinterServiceStatusStore();
    const secondaryDisplay = useSecondaryDisplay();

    const processEventConfigurationChanges = async ({configuration = null, promotions = null} = {}) => {
      if (configuration) {
        // eslint-disable-next-line no-console
        console.log('processEventConfigurationChanges', 'configuration');
        recordCustomEventLogEntry('Configuration event', CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED);
        await configurationStore.setConfiguration(new Configuration(configuration));

        if (!ctx.isRestoreMode) {
          initSentry({
            app: ctx.app,
            // @ts-ignore we have router by now
            router: ctx.router,
            config: configurationStore.configuration.value?.secrets?.sentry?.posUi,
          });
        } else {
          console.warn('[RestoreMode] sentry init bypassed');
        }


        switchTheme(configurationStore.configuration.value?.ui?.style?.theme.value as Theme);

        ctx.locale = configurationStore.configuration.value.general.language.value?.toLowerCase();

        updateStore.processConfiguration(configurationStore.configuration.value);

        await ctx.registerTranslations(configurationStore.configuration.value.translations);

        // @ts-ignore
        if (ctx.i18n) {
          // @ts-ignore we have ctx.i18n by this point
          ctx.i18n.global.locale = ctx.locale;

          const newTranslations = createTbtTranslations(ctx.getTranslations());

          for (const locale of Object.keys(newTranslations)) {
            // @ts-ignore we have ctx.i18n by this point
            ctx.i18n.global.setLocaleMessage(locale, newTranslations[locale]);
          }
        }

        await authStore.fetchPermissions();

        await configurationPostProcess();
      }

      if (promotions) {
        // eslint-disable-next-line no-console
        console.log('processEventConfigurationChanges', 'promotions');
        recordCustomEventLogEntry('Promotions event', CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED);
        await usePromoEngine().updateConfiguration(new PromotionBulkDto(promotions));
      }

      await updateStore.fetchUpdateStatus();

      synchronizationStore.finishSynchronizationProcess();

      emitTestEvent(TestEvent.CONFIGURATION_CHANGES_PROCESSED);
    };
    const processUpdateConditionsMightChanges = async () => {
      await updateStore.fetchUpdateStatus();
    };

    const processEventPrinterServiceStatusChanges = (isRunning: boolean) => {
      printerServiceStatusStore.setIsRunning(isRunning);
    };

    const stepConfigurationReady = () => {
      if (ctx.isRestoreMode) {
        return Promise.resolve();
      }

      if (ctx.isPdaApp) {
        return Promise.resolve(); // configuration will be fetched during handshake!
      }

      return ensureConfiguration();
    };
    const stepColdStartSync = () => {
      if (ctx.isRestoreMode) {
        console.warn('[RestoreMode] cold start sync bypassed');
        return Promise.resolve();
      }

      if (secondaryDisplay.isSecondaryDisplay) {
        return Promise.resolve();
      }

      return ensureColdStartSync();
    };
    const stepPrinterSync = () => {
      if (ctx.isRestoreMode) {
        console.warn('[RestoreMode] printer sync bypassed');
        return Promise.resolve();
      }

      if (secondaryDisplay.isSecondaryDisplay) {
        return Promise.resolve();
      }

      return ensurePrinterSync();
    };
    const stepPrimaryDisplayPingPong = () => {
      if (secondaryDisplay.isSecondaryDisplay) {
        return ensurePrimaryDisplayPing();
      }

      return ensurePrimaryDisplayPong();
    };
    const stepConfigurationLoad = () => {
      if (ctx.isRestoreMode) {
        console.warn('[RestoreMode] configuration restored');
        return Promise.resolve(ctx.restoredErrorRecord.state.configuration)
          .then((config: Configuration['_data']) => {
            return Promise.resolve(configurationStore.setConfiguration(new Configuration(config)));
          });
      }

      if (ctx.isPdaApp) {
        return Promise.resolve(); // configuration will be fetched during handshake!
      }

      return Promise.resolve(ensureConfiguration())
        .then((config: Configuration['_data']) => {
          return Promise.resolve(configurationStore.setConfiguration(new Configuration(config)));
        });
    };
    const stepUpdateStatusSync = () => {
      return Promise.resolve(updateStore.fetchUpdateStatus());
    };
    const stepPromoEngineSetup = () => {
      if (ctx.isRestoreMode) {
        console.warn('[RestoreMode] promotion restored');
        usePromoEngine().promotionEngine = createPromoEngineWorker();
        return usePromoEngine()
          .updateConfiguration(new PromotionBulkDto(ctx.restoredErrorRecord.state.promotions));
      }

      return apiV1PosPromotionsBulk()
        .then((promotions) => {
          return Promise.resolve(usePromoEngine().updateConfiguration(promotions));
        });
    };
    const stepTranslatorAndThemeSetup = () => {
      switchTheme(configurationStore.configuration.value?.ui?.style?.theme.value as Theme);

      updateStore.processConfiguration(configurationStore.configuration.value);

      // TODO: remove to LC
      ctx.locale = configurationStore.configuration.value.general.language.value?.toLowerCase();
      return Promise.resolve(ctx.registerTranslations(configurationStore.configuration.value.translations));
    };
    const stepCheckPrinterServiceStatus = async () => {
      try {
        const status = await ensurePrinterServiceStatus();
        printerServiceStatusStore.setIsRunning(status.isRunning);
      } catch (e) {
        printerServiceStatusStore.setIsRunning(false);
      }
    };
    const stepConfigurationPostProcess = () => {
      return Promise.resolve(configurationPostProcess());
    };
    const stepListenersSetup = () => {
      if (ctx.isRestoreMode) {
        console.warn('[RestoreMode] configuration listeners disabled');
        return Promise.resolve();
      }

      return Promise.all([
        notificationsConnection.addEventListener<[
          {
            configuration?: Configuration['_data'],
            promotions?: PromotionBulkDto['_data']
          }
        ]>(
          CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED,
          processEventConfigurationChanges,
        ),
        notificationsConnection.addEventListener<[]>(
          CoreWSEvents.UPDATE_CONDITIONS_MIGHT_CHANGED,
          processUpdateConditionsMightChanges,
        ),
        notificationsConnection.addEventListener<[
          Configuration['_data']
        ]>(
          CoreWSEvents.NEW_SYNC_AVAILABLE,
          () => {
            recordCustomEventLogEntry('ConfigurationOrPromotions event', CoreWSEvents.NEW_SYNC_AVAILABLE);
            configurationStore.setSyncAvailable();
            // @ts-ignore
            if (ctx.router.currentRoute.value.name === 'login') {
              configurationStore.ensureApplySync();
            }
          },
        ),
        notificationsConnection.addEventListener<[boolean]>(
          CoreWSEvents.PRINTER_SERVICE_RUNNING_STATE_CHANGED,
          processEventPrinterServiceStatusChanges,
        ),
      ]);
    };

    await Promise.all([
      stepConfigurationReady()
        .then(stepColdStartSync)
        .then(stepPrinterSync)
        .then(stepPrimaryDisplayPingPong)
        .then(stepConfigurationLoad)
        .then(stepUpdateStatusSync)
        .then(stepPromoEngineSetup)
        .then(stepTranslatorAndThemeSetup)
        .then(stepConfigurationPostProcess)
        .then(stepCheckPrinterServiceStatus),
      stepListenersSetup(),
    ]);

    window.addEventListener('message', async ({data}: MessageEvent<{
      name: string,
      payload: {
        configuration?: Configuration['_data'],
        promotions?: PromotionBulkDto['_data']
      }
    }>) => {
      if (data?.name === CoreWSEvents.CONFIGURATION_CHANGES_PROCESSED) {
        processEventConfigurationChanges(data.payload);
      }

      if (data?.name === 'EvalTestPromotion') {
        const promoEngine = usePromoEngine();
        const newPromotion: PromotionDto['_data'] = data.payload;

        const promotionBulk: PromotionBulkDto['_data'] = promoEngine.promotionBulk?.clone()?.toJson() ??
          new PromotionBulkDto({})?.toJson();

        const oldPromotionIndex = promotionBulk.promotions
          .findIndex((promotion: PromotionDto['_data']) => promotion.id === newPromotion.id);

        if (oldPromotionIndex !== -1) {
          promotionBulk.promotions.splice(
            oldPromotionIndex,
            1,
            newPromotion,
          );
        } else {
          promotionBulk.promotions.push(newPromotion);
        }

        promoEngine.enableLogs = true;

        await promoEngine.updateConfiguration(new PromotionBulkDto(promotionBulk));

        // @ts-ignore
        if (ctx.router.currentRoute.value.name === 'register') {
          const registerStore = require('@/Modules/Register/store/RegisterStore').useRegisterStore();
          await registerStore.fetchPromoEngineResult();

          if (registerStore.currentEditGroup.value) {
            await registerStore.promotionHookActiveArticleChanged();
          }
        }
      }
    });

    await ctx.registerHookBeforeEach(async (to, from, next) => {
      if (to.name === 'login' && from.path === '/') {
        await configurationStore.applySync();
      } else if (to.name === 'login') {
        await configurationStore.ensureApplySync();
      }

      next();
    });

    await ctx.registerHookBeforeEach(async (to, from, next) => {
      if (from.name === 'login') {
        await synchronizationStore.synchronization.value;
      }

      next();
    });

    await ctx.registerHookAfterEach((to) => {
      emitTestEvent(`${TestEvent.ROUTE_NAVIGATION}:${to.name as string ?? to.path}`);
    });

    return {};
  };
}
