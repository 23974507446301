import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const OrderCustomerAddressDtoPropDef = {
  city: {
    type: new StringType(),
  },
  zipCode: {
    type: new StringType(),
  },
  street: {
    type: new StringType(),
  },
  countryCode: {
    type: new StringType(),
  },
};
export default class GeneratedOrderCustomerAddressDto extends createEntity(OrderCustomerAddressDtoPropDef) {}
