import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import * as yup from 'yup';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {action} from '@designeo/vue-helpers/src/index';
import {markRaw} from 'vue';
import {reduce} from 'lodash-es';
import {commonWfFieldValidations} from '@/Helpers/validations';

export class WorkflowStepEnterNote extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.EnterNote;
  }

  get type() {
    return WorkflowStepEnterNote.type;
  }

  get component() {
    return markRaw(require('./StepEnterNote.vue').default);
  }

  get validator(): yup.AnyObjectSchema {
    return yup.object().shape({
      [WorkflowStepField.note]: yup.string()
        .test(commonWfFieldValidations(this.step.validations?.[WorkflowStepField.note])),
    });
  }

  async finish() {
    if (!await this.validate()) return;

    this.stepFinished = true;
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.note, (fieldActions) => ({
        ...reduce([
          WorkflowActions.ADD_CHAR,
          WorkflowActions.ADD_COMMA,
          WorkflowActions.ADD_PERIOD,
          WorkflowActions.ADD_PLUS,
          WorkflowActions.ADD_MINUS,
        ], (acc, val) => {
          acc[val] = action((event: WorkflowInputEvent<string>) => {
            fieldActions[WorkflowActions.ADD_CHAR](event.value);
          });

          return acc;
        }, {}),
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.finish();
        }),
      })),
    };
  }
}
