import {
  createEntity, StringType,
} from '@designeo/apibundle-js';
import {
  mapKeys,
  filter,
  map,
  values,
  flow,
} from 'lodash-es';
import {Korunka} from '../../../../types/korunka';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';
import {api3rdPartyKorunkaGenerateDraws} from '../../Action';
import DrawPartnerResource from '../DrawPartnerResource';
import {PreparedTicketPartnerResourcePropDef} from '../generated/PreparedTicketPartnerResource';
import KorunkaLotteryDvacetZa20DtoCustom from './KorunkaLotteryDvacetZa20DtoCustom';
import KorunkaLotteryHopTropDtoCustom from './KorunkaLotteryHopTropDtoCustom';
import KorunkaLotteryKombiDtoCustom from './KorunkaLotteryKombiDtoCustom';
import KorunkaLotteryKombiPlusDtoCustom from './KorunkaLotteryKombiPlusDtoCustom';
import KorunkaLotterySestkaDtoCustom from './KorunkaLotterySestkaDtoCustom';

export default class KorunkaLotteryPreparedTicketDtoCustom extends createEntity(
  {
    ...PreparedTicketPartnerResourcePropDef,
    id: {
      type: new StringType(),
    },
  },
) {
  async getDrawsByIds(ids: number[]) {
    const result = await api3rdPartyKorunkaGenerateDraws();

    const drawsById = mapKeys(result, 'id');

    return flow(
      (ids: number[]) => map(ids, (id) => drawsById?.[id]),
      (draws: DrawPartnerResource[]) => filter(draws, Boolean),
      (draws: DrawPartnerResource[]) => map(draws, (draw) => draw.toJson()),
    )(ids);
  }

  async toGameEntity() {
    const draws = await this.getDrawsByIds(this.drawIds);

    switch (this.gameType.value as Korunka['BetTicketPartnerResource']['gameType']) {
    case KorunkaEntityTypes.Sestka:
      return new KorunkaLotterySestkaDtoCustom({
        deposit: {
          value: this.amountToPayKc,
        },
        draws,
        randomNumbers: values(this.numbers)[0],
        isPrepared: true,
        id: this.id,
      });
    case KorunkaEntityTypes.DvacetZa20:
      return new KorunkaLotteryDvacetZa20DtoCustom({
        deposit: {
          value: this.amountToPayKc,
        },
        draws,
        randomNumbers: values(this.numbers),
        isPrepared: true,
        id: this.id,
      });
    case KorunkaEntityTypes.HopTrop:
      return new KorunkaLotteryHopTropDtoCustom({
        deposit: {
          value: this.amountToPayKc,
        },
        draws,
        randomNumbers: values(this.numbers)[0],
        isPrepared: true,
        id: this.id,
      });
    case KorunkaEntityTypes.Kombi:
      return new KorunkaLotteryKombiDtoCustom({
        deposit: {
          value: this.amountToPayKc,
        },
        draws,
        randomNumbers: values(this.numbers),
        isPrepared: true,
        id: this.id,
      });
    case KorunkaEntityTypes.KombiPlus:
      return new KorunkaLotteryKombiPlusDtoCustom({
        deposit: {
          value: this.amountToPayKc,
        },
        draws,
        randomNumbers: values(this.numbers),
        isPrepared: true,
        id: this.id,
      });
    default:
      throw new Error(`Unknown ticket gameType ${this.gameType}`);
    }
  }
}
