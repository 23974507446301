import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import QuickCallSubmenu from '../QuickCallSubmenu';


export default class GeneratedQuickCall extends createEntity({
  Receipt: {
    type: new EntityType(QuickCallSubmenu),
  },
  Payment: {
    type: new EntityType(QuickCallSubmenu),
  },
}) {}
