import {PermissionDto} from '@/Model/Entity';
import CashierVerifyResultDto from '@/Model/Entity/CashierVerifyResultDto';
import {AxiosError} from 'axios';

export enum AuthState {
  CHOOSE_PERSON,
  ENTER_PERSONAL_NUMBER,
  ENTER_PIN,
  QUICK_LOGIN_CONFIRM,
  QUICK_LOGIN_EDIT,
}

export enum AuthActions {
  ADD_CHAR='addChar',
  ADD_NUMBER='addNumber',
  ENTER='enter',
  BACKSPACE='backspace',
  CLEAR='clear',
  NEW_USER='newUser',
  CANCEL='cancel',
  INIT='init',
}

export type AuthInputEvent<ValueType = any> = {
  type: AuthActions,
  value?: ValueType
}

// TODO: change according to core data structure
export interface AuthInput {
  username: string,
  password: string,
  quickLogin: boolean,
  autoQuickLogin: boolean,
  timestamp: number,
  invalid: boolean,
  verifyStatus: CashierVerifyResultDto | AxiosError,
}

export enum LoginType {
  JWT = 'JWT',
  M = 'M'
}

export type AuthPerson = Pick<AuthInput, 'username' | 'quickLogin' | 'timestamp'>
  & {accessToken: string}
  & {tokenInfo: {[key: string]: any}}
  & {permissions: PermissionDto[]}
  & {forcePinChange: boolean}
  & {remoteAppTimestamp: string}

export enum AuthStoreEvent {
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export enum VerifyCashierStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
  NOT_FOUND = 'NotFound'
}


export enum PinChangeState {
  ENTER_OLD_PIN = 'ENTER_OLD_PIN',
  ENTER_NEW_PIN = 'ENTER_NEW_PIN',
  ENTER_NEW_PIN_REPEAT = 'ENTER_NEW_PIN_REPEAT',
}

export enum PinChangeActions {
  ADD_NUMBER='addNumber',
  ENTER='enter',
  BACKSPACE='backspace',
  CLEAR='clear',
  CANCEL='cancel',
  PREV='prev',
  NEXT='next',
}

export type PinChangeInputEvent<ValueType = any> = {
  type: PinChangeActions,
  value?: ValueType
}

export enum PinChangeEvents {
  CHANGE_SUCCESSFUL = 'changeSuccessful',
}

export enum PinChangeErrors {
  API_ERROR = 'apiError',
  NEW_PINS_NOT_SAME = 'newPinsNotSame',
  FIELDS_NOT_FILLED = 'fieldsNotFilled',
}
