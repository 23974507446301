import {RegisterRoutesOptions} from '@/ModuleSystem';
import {ConfirmTemplates, FourEyesOperations} from '@/Modules/Core/types';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFourEyesStore} from '@/Modules/Core/store/FourEyesStore';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export default [
  {
    path: '/pos-configuration',
    name: 'pos-configuration',
    meta: {authorization: false},
    component: () => import('./Steps/StepsPage.vue'),
    async beforeEnter(to, from, next) {
      const configurationStore = useConfigurationStore();
      const fourEyesStore = useFourEyesStore();
      const helpStore = useHelpStore();
      const coreStore = useCoreStore();

      if (configurationStore.configuration.value?.isPosConfigured ?? false) {
        try {
          if (from.fullPath === '/') {
            next({name: 'login', query: {redirect: encodeURIComponent(to.fullPath as string)}});
            return;
          } else if (!await coreStore.confirm(null, {template: ConfirmTemplates.reRegistration})) {
            next(false);
            return;
          }

          const result = await fourEyesStore
            .openFourEyesConfirm(FourEyesOperations.POS_RE_REGISTRATION_MANAGE);
          next(result.isConfirmed);
        } catch (e) {
          await helpStore.show(helpStore.getErrorId(e.message));
          next(false);
        }
      } else {
        next();
      }
    },
  },
] as RegisterRoutesOptions;
