import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HLInputSelectMultiple = _resolveComponent("HLInputSelectMultiple")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_HLInputSelectMultiple, {
    ref: "InputSelectMultiple",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    options: _ctx.options,
    strictVModel: _ctx.strictVModel
  }, {
    default: _withCtx(({ sortedOptions, model: innerModel }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedOptions, (option, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([option.selected ? 'toggle-active' : 'toggle-default']),
          onClick: ($event: any) => (innerModel.value = option.id)
        }, [
          _createTextVNode(_toDisplayString(option.label), 1)
        ], 10, _hoisted_1)), [
          [
            _directive_test,
            option.id,
            void 0,
            { toggleMultiple: true }
          ]
        ])
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "options", "strictVModel"]))
}