
import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'ArticleLabel',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    theme: {
      type: Object,
      default: null,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const themeStyle = computed(() => ({
      color: props.theme?.textColor ?? '#FFFFFF',
      backgroundColor: props.theme?.backgroundColor ?? '#D19700',
    }));
    return {
      themeStyle,
    };
  },
});
