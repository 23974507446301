
const locked = new Set();
const lockRetrigger = new Set();

export const PromoFlowLock = Symbol('PromoFlowLock');

export async function withLock(lock: Symbol, fn: (...args: any[])=> any, retrigger = () => {}) {
  if (locked.has(lock)) {
    lockRetrigger.add(lock);
    return;
  }
  locked.add(lock);
  try {
    return await fn();
  } finally {
    locked.delete(lock);

    const wasRequested = lockRetrigger.has(lock);
    lockRetrigger.delete(lock);

    if (wasRequested) {
      await retrigger();
    }
  }
}
