import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticlePreviewDto from '../ArticlePreviewDto';


export const ArticleListDtoPropDef = {
  items: {
    type: new ArrayType(new EntityType(ArticlePreviewDto)),
  },
};
export default class GeneratedArticleListDto extends createEntity(ArticleListDtoPropDef) {}
