import GeneratedLotteryTurnoversDto from './generated/LotteryTurnoversDto';
import {keys, mapKeys} from 'lodash-es';
import {fixDecimals} from '@/Helpers/math';
import DocumentItemDto from '@/Model/Entity/DocumentItemDto';

export default class LotteryTurnoversDto extends GeneratedLotteryTurnoversDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get turnoverCategoriesByCategoryId() {
    return mapKeys(this.turnoverByCategories, 'parentId');
  }

  get turnoverArticlesByInternalNumber() {
    return mapKeys(this.turnoverByArticles, 'internalNumber');
  }

  getIsResolvedByCategory(article: DocumentItemDto) {
    return LotteryTurnoversDto.configurationStore.configuration.value
      ?.features
      ?.lottery
      ?.articleCategories?.[article.parentId]
      ?.resolveByCategory ?? false;
  }

  getTurnoverArticlesByInternalNumberHasArticle(article: DocumentItemDto) {
    return keys(this.turnoverArticlesByInternalNumber).includes(article.internalNumber);
  }

  getCategoryTotalAmount(categoryId) {
    return this.turnoverCategoriesByCategoryId?.[categoryId]?.totalAmount ?? 0;
  }

  getArticleTotalAmount(article: DocumentItemDto) {
    return article.parentId && this.getIsResolvedByCategory(article) && !this.getTurnoverArticlesByInternalNumberHasArticle(article) ?
      this.turnoverCategoriesByCategoryId?.[article.parentId]?.totalAmount ?? 0 :
      this.turnoverArticlesByInternalNumber?.[article.internalNumber]?.totalAmount ?? 0;
  }

  isArticleValueSatisfied(article: DocumentItemDto, value: string) {
    const amount = article.hasManualPrice ? parseFloat(value ?? '0') : parseFloat(value ?? '0') * article.priceNormal;
    return this.getArticleTotalAmount(article) - amount === 0;
  }

  isCategoryValueSatisfied(categoryId, value: string) {
    return this.getCategoryCorrection(categoryId, value) === 0;
  }

  getCategoryCorrection(categoryId, value: string) {
    return fixDecimals(parseFloat(value || '0') - this.getCategoryTotalAmount(categoryId));
  }

  getArticleCorrection(article: DocumentItemDto, value: string) {
    if (article.hasManualPrice) {
      const correction = fixDecimals(parseFloat(value ?? '0') - this.getArticleTotalAmount(article));

      if (Number.isNaN(correction)) return this.getArticleTotalAmount(article) * -1;

      return fixDecimals(correction);
    } else {
      const amount = parseFloat(value ?? '0') * article.priceNormal;

      const correction = fixDecimals(amount - this.getArticleTotalAmount(article));

      if (Number.isNaN(correction)) return this.getArticleTotalAmount(article) * -1;

      return fixDecimals(correction);
    }
  }

  getArticleQuantity(article: DocumentItemDto, value: string) {
    if (article.isNegative) return 1;

    const valueAfterDiscounts = article.getStandaloneValueAfterDiscountsWithinContext();

    return article.isTypeService ? 1 : (this.getArticleCorrection(article, value) / valueAfterDiscounts);
  }
}
