import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import CustomerDto from '../CustomerDto';


export const CustomersDtoPropDef = {
  total: {
    type: new NumberType(),
  },
  data: {
    type: new ArrayType(new EntityType(CustomerDto)),
  },
};
export default class GeneratedCustomersDto extends createEntity(CustomersDtoPropDef) {}
