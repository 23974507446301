import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const CashierDtoPropDef = {
  isDisabled: {
    type: new BooleanType(),
  },
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  validFrom: {
    type: new DateTimeType(),
  },
  validTill: {
    type: new DateTimeType(),
  },
  userName: {
    type: new StringType(),
  },
};
export default class GeneratedCashierDto extends createEntity(CashierDtoPropDef) {}
