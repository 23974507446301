import {Context} from '@/Helpers/Context';
import {DocumentItemDto} from '@/Model/Entity';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {isNil, toString} from 'lodash-es';
import {DynamicSetSelection, DynamicSetLevelsSelection} from '@designeo/pos-promotion-engine/src/blocks/types';

export type ProductFlowState = {
  product: DocumentItemDto['_data'],
  isNew: boolean,
  inputMode: InputSource,
  presetQuantity: number,
  promotions?: {
    recommendations: Array<DynamicSetSelection | DynamicSetLevelsSelection>,
  },
}

const getInitState = () => ({
  product: null,
  isNew: true,
  inputMode: InputSource.KEYBOARD,
  presetQuantity: null,
  promotions: {
    recommendations: [],
  },
});

export class ProductFlow {
  public context: Context
  public isSaving: boolean

  public static deserialize(data: any): ProductFlow {
    return new ProductFlow(new Context(data));
  }

  public static serialize(productFlow: ProductFlow): any {
    return productFlow.context.state;
  }

  constructor(context = new Context(getInitState())) {
    this.isSaving = false;

    Object.assign(context.state, {
      ...getInitState(),
      ...context.state,
    });

    this.context = context;
  }

  get promotions(): ProductFlowState['promotions'] {
    return this.context.state.promotions;
  }

  set promotions(val: ProductFlowState['promotions']) {
    this.context.state.promotions = val;
  }

  get isNew() {
    return this.context.state.isNew;
  }

  set isNew(val: boolean) {
    this.context.state.isNew = val;
  }

  get inputMode(): InputSource {
    return this.context.state.inputMode;
  }

  set inputMode(val: InputSource) {
    this.context.state.inputMode = val;
  }

  get presetQuantityIsSet() {
    return !isNil(this.presetQuantity);
  }

  get presetQuantityAsString() {
    if (this.context.state.presetQuantity === null) return null;
    return toString(this.context.state.presetQuantity);
  }

  set presetQuantityAsString(val: string) {
    this.context.state.presetQuantity = parseFloat(val);
  }

  get presetQuantity() {
    if (this.context.state.presetQuantity === null) return null;
    return this.context.state.presetQuantity;
  }

  set presetQuantity(val: number) {
    this.context.state.presetQuantity = val;
  }

  get product() {
    return this.context.state.product ? new DocumentItemDto(this.context.state.product) : null;
  }

  set product(val: DocumentItemDto | null) {
    if (this.isSaving) {
      throw new Error('Can\'t set productflow.product during saving');
    }
    if (val) {
      this.context.state.product = val.toJson();
    } else {
      this.context.state.product = null;
    }
  }

  get isInProgress() {
    return this.product !== null;
  }

  get canBeSaved() {
    if (this.isSaving) {
      return false;
    }

    if (this.isInProgress && !this.product.isFilled) {
      return false;
    }

    return true;
  }

  reset() {
    this.isSaving = false;
    Object.assign(this.context.state, getInitState());
  }
}
