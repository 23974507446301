import {
  watch,
  onBeforeUnmount,
  onBeforeMount,
} from 'vue';
import {
  debounce,
  map,
} from 'lodash-es';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {RouteLocationNormalized} from 'vue-router';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useInactivityStore} from '@/Modules/Core/store/InactiveStore';
import {waitAtLeast} from '@/Helpers/time';
import {useCrashStore} from '@/Modules/Core/store/CrashStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {reloadAppFromRoot, reloadInventoryPdaFromRoot} from '@/Helpers/app';

const INACTIVITY_PING_EVENT = 'inactivityPing';

export const inactivityPing = () => {
  document.body.dispatchEvent(new Event(INACTIVITY_PING_EVENT));
};

export const useInactivityDetection = () => {
  const authStore = useAuthStore();
  const inactivityStore = useInactivityStore();

  const debouncedRefresh = debounce((lastUpdater) => {
    inactivityStore.resetNow();
  }, 300, {
    'leading': true,
    'trailing': false,
  });

  const events = ['keyup', 'mousemove', 'mouseup', INACTIVITY_PING_EVENT];
  const listeners: Array<[string, ()=> void]> = map(events, (event) => [event, () => debouncedRefresh(event)]);

  const registerListeners = () => {
    for (const [event, fn] of listeners) {
      document.body.addEventListener(event, fn);
    }
  };


  const registerRemoveListeners = () => {
    for (const [event, fn] of listeners) {
      document.body.removeEventListener(event, fn);
    }
  };


  watch(() => authStore.activePerson.value, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      inactivityStore.resetNow();
    }
  });

  onBeforeMount(() => {
    registerListeners();
  });

  onBeforeUnmount(() => {
    registerRemoveListeners();
  });
};

type LoginRedirect = (()=> Promise<void>) | (()=> void)

export class InactivityCondition {
  static get type() {
    return 'InactivityCondition';
  }

  static get inactivityRouterHook() {
    return (to, from, failure) => {
      const inactivityStore = useInactivityStore();
      const authStore = useAuthStore();

      inactivityStore.setCondition(InactivityCondition.getConditionByRoute(to));

      if (authStore.activePerson.value) {
        inactivityStore.ensureIntervalRun();
      }
    };
  }

  static getConditionByRoute(route: RouteLocationNormalized) {
    switch (route.name) {
    // case 'pos-configuration': return new DisabledInactivityCondition(); // should we do it??
    case 'register': return new RegisterInactivityCondition();
    case 'apps-inventory-login':
    case 'apps-inventory-choose-stock-type':
    case 'apps-inventory-stock': return new InactivityCondition({
      loginRedirect: () => reloadInventoryPdaFromRoot(),
    });
    default: return new InactivityCondition();
    }
  }

  public loginRedirect: LoginRedirect

  constructor({loginRedirect = () => reloadAppFromRoot()}: {loginRedirect?: LoginRedirect} = {}) {
    this.loginRedirect = loginRedirect;
  }

  get type() {
    return InactivityCondition.type;
  }

  get timeout(): number {
    if (process.env.NODE_ENV === 'development') {
      return 30 * 60 * 1000;
      // return 30 * 1000;
    }

    const timeout = useConfigurationStore().configuration.value?.features?.login?.automaticLogoutTimeout;

    if (!timeout) {
      return null;
    }

    return timeout * 1000;
  }

  get hasValidTimeout(): boolean {
    return this.timeout !== null;
  }

  get showProgressAt(): number {
    if (process.env.NODE_ENV === 'development') {
      return (30 * 60 * 1000) - (30 * 1000);
      // return 20 * 1000;
    }

    const timeout = useConfigurationStore().configuration.value?.features?.login?.automaticLogoutInfoTimeout;

    if (!timeout) {
      return null;
    }

    return timeout * 1000;
  }

  isFulfilled() {
    const coreStore = useCoreStore();
    const crashStore = useCrashStore();

    if (coreStore.loaderActive.value) {
      return false;
    }

    if (crashStore.active.value) {
      return false;
    }

    return true;
  }

  async logoutAndRedirect() {
    const authStore = useAuthStore();
    const coreStore = useCoreStore();

    try {
      coreStore.setLoader(true);

      const dateFnLogin = new Date();

      await authStore.logout(true);

      await waitAtLeast(350, dateFnLogin, new Date()); // waiting for loading render

      await this.loginRedirect();
    } catch (e) {
      console.error(e);
    } finally {
      coreStore.setLoader(false);
    }
  }

  async onFulfill() {
    await this.logoutAndRedirect();
  }
}

export class RegisterInactivityCondition extends InactivityCondition {
  static get type() {
    return 'RegisterInactivityCondition';
  }

  get type() {
    return RegisterInactivityCondition.type;
  }

  async ensureCleanup() {
    try {
      const registerStore = useRegisterStore();

      if (registerStore.sellDocument.value?.isUntouched) {
        return;
      }

      if (registerStore.sellDocument.value?.canBeDiscarded ?? true) {
        await registerStore.resetReceipt();
        return;
      }

      if (registerStore.isEditModeActive.value) {
        await registerStore.ensurePurchaseFlowReset();
      }

      if (!registerStore.sellDocument.value?.canBeCanceledWithoutConfirmation) {
        return;
      }

      if (registerStore.sellDocument.value?.validPayments.length) {
        return; // we cannot solve this case here!
      }

      await registerStore.cancelSellDocument({fourEyes: false});
    } catch (e) {
      console.error(e);
    }
  }


  async onFulfill() {
    await this.ensureCleanup();

    await this.logoutAndRedirect();
  }
}

export class DisabledInactivityCondition extends InactivityCondition {
  static get type() {
    return 'DisabledInactivityCondition';
  }

  get type() {
    return DisabledInactivityCondition.type;
  }

  get hasValidTimeout() {
    return false; // this will disable redirect condition
  }
}
