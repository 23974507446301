import {
  BooleanType,
  createEntity,
  StringType,
} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export default class GeneratedSearch extends createEntity({
  numberOfResults: {
    type: new NumberType(),
  },
  searchBy: {
    type: new StringType(),
  },
  manualTrigger: {
    type: new BooleanType(),
  },
}) {}
