
import {
  map,
  sum,
} from 'lodash-es';
import {
  computed,
  defineComponent,
} from 'vue';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {useFilters} from '@/Helpers/filters';
import Article from './Article.vue';
import {sumLotteryFields} from '@/Helpers/lotteryFields';

export default defineComponent({
  name: 'StepEnterLotteryOverview',
  components: {
    HLStep,
    WorkflowTransitions,
    Article,
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterLotteryOverview;

    const {workflowStep}: {workflowStep} = useStepTools(stepType);

    const {currencyFormat} = useFilters();

    const configurationStore = useConfigurationStore();

    const total = computed(() => {
      const articles = workflowStep.value.lotteryArticlesFromInaccurateCategories ?? [];

      const diffs = map(articles, (article) => {
        return workflowStep.value.lotteryTurnovers.getArticleCorrection(
          article,
          sumLotteryFields(
            workflowStep.value.getFieldValue(
              workflowStep.value.getFieldKeyByInternalNumber(article.internalNumber, true),
            ),
          ),
        );
      });

      return sum(diffs);
    });

    return {
      stepType,
      total,
      currencyFormat,
      configurationStore,
    };
  },
});
