import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const PointHistoryDtoPropDef = {
  collectedPoints: {
    type: new NumberType(),
  },
  burnedPoints: {
    type: new NumberType(),
  },
  expiredPoints: {
    type: new NumberType(),
  },
  date: {
    type: new DateTimeType(),
  },
};
export default class GeneratedPointHistoryDto extends createEntity(PointHistoryDtoPropDef) {}
