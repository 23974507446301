import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import AutomaticLogout from '../AutomaticLogout';
import {BooleanType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedLogin extends createEntity({
  usernameAutoConfirmLength: {
    type: new NumberType(),
  },
  pinAutoConfirmLength: {
    type: new NumberType(),
  },
  automaticLogout: {
    type: new EntityType(AutomaticLogout),
  },
  automaticLogoutTimeout: {
    type: new NumberType(),
  },
  automaticLogoutInfoTimeout: {
    type: new NumberType(),
  },
  redirectToLoginPageAfterCheckout: {
    type: new BooleanType(),
  },
  usernameMinLength: {
    type: new NumberType(),
  },
  usernameMaxLength: {
    type: new NumberType(),
  },
  remoteAppAfterLogin: {
    type: new StringType(),
  },
  remoteAppAfterLoginThrottle: {
    type: new NumberType(),
  },
}) {}
