import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderStatus from '../OrderStatus';
import OrderEventOrigin from '../OrderEventOrigin';
import OrderEventType from '../OrderEventType';
import {DateTimeType} from '@designeo/apibundle-js';


export const OrderEventDtoPropDef = {
  id: {
    type: new StringType(),
  },
  statusCode: {
    type: new EntityType(OrderStatus),
  },
  statusReason: {
    type: new StringType(),
  },
  statusReasonText: {
    type: new StringType(),
  },
  statusReasonNote: {
    type: new StringType(),
  },
  shopCode: {
    type: new StringType(),
  },
  posCode: {
    type: new StringType(),
  },
  cashierPersonalNumber: {
    type: new StringType(),
  },
  cashierName: {
    type: new StringType(),
  },
  origin: {
    type: new EntityType(OrderEventOrigin),
  },
  type: {
    type: new EntityType(OrderEventType),
  },
  eventDate: {
    type: new DateTimeType(),
  },
};
export default class GeneratedOrderEventDto extends createEntity(OrderEventDtoPropDef) {}
