import {MixinBase} from '@/Helpers/mixins';

export function classPropertyGuard<
    CI extends InstanceType<MixinBase>,
    G extends string
>(instance: CI, property: G): instance is (CI & Record<G, any>) {
  if (!instance) {
    return false;
  }

  const proto = Object.getPrototypeOf(instance);
  return Object.prototype.hasOwnProperty.call(proto, property);
}
