
import {computed, defineComponent} from 'vue';

import FormInput from '@/Components/FormInput.vue';
import {
  HLInput,
  HLStep,
} from '@/Modules/Workflow/Step/StepTools';
import {
  WorkflowActions,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {useWorkflowStore} from '../../store/WorkflowStore';
import {useFilters} from '@/Helpers/filters';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepUpdateCustomerAdditionalInformation',
  components: {WorkflowTransitions,
    HLInput,
    HLStep,
    FormInput},
  setup() {
    const {phoneFormat} = useFilters();
    const stepType = WorkflowStepTypes.UpdateCustomerAdditionalInformation;
    const workflowStore = useWorkflowStore();
    const currentWorkflow = computed(() => workflowStore.currentWorkflow.value);

    return {
      stepType,
      WorkflowStepField,
      currentWorkflow,
      phoneFormat,
      workflowStore,
      WorkflowActions,
    };
  },
});
