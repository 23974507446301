import KorunkaChargeRequestDtoCustom from '@/Model/Entity/custom/KorunkaChargeRequestDtoCustom';
import {KorunkaPatch} from '@/Model/Entity/patches/KorunkaMixin';
import DocumentDto from './DocumentDto';
import KorunkaErrorDto from './KorunkaErrorDto';

export default class KorunkaChargeRequestDto extends KorunkaPatch(KorunkaChargeRequestDtoCustom) {
  isValid(context: DocumentDto, itemAtIndex: number) {
    const {editableItem} = context.itemsGroupedBySets[itemAtIndex];

    const isKorunkaErrorDto = editableItem.chargeResponse?.entity instanceof KorunkaErrorDto;

    return !isKorunkaErrorDto;
  }
}
