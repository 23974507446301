import {Context} from '@/Helpers/Context';
import {
  DocumentPaymentDto,
  DocumentDto,
  ResultDto,
  TransactionDto,
} from '@/Model/Entity';
import {SignalRErrors, useSignalR} from '@/Helpers/signalR';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {setResultProcessedAt, useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {
  filter,
  includes,
  last,
  map,
  some,
  sortBy,

} from 'lodash-es';
import {DocumentTypes} from '@/constants/documentTypes';
import {getResponseCodeConfiguration, ResponseCodes} from '@/Helpers/printerServiceResponseCodes';
import {
  apiDocumentCreate,
  apiDocumentGet,
  apiDocumentGetChangeHistory,
} from '@/Model/Action';
import {preventParallel} from '@/Helpers/promise';
import {recordCustomEventLogEntry} from '@/Helpers/logger';
import {
  IResolver,
  IResolverConstructor,
  ResolverTypes,
} from '@/Modules/Payment/resolvers/IResolver';
import {submitJournalEventCardPaymentStart} from '@/Helpers/journal';
import {
  IPaymentEvent,
  PaymentModes,
  PaymentEventEmitArgs,
  PaymentEventRegisterArgs,
  PaymentEvents,
  IPaymentContextState,
  PaymentContext,
} from '@/Modules/Payment/payment';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';

interface ICommunicationResult {
  isValidated: boolean,
  isCanceled: boolean,
}

export interface IResolverByTwoWayCommunication extends IResolver {
  fetchChangeHistory(): Promise<Array<TransactionDto>>
  savePaymentDocument(paymentDocument: DocumentDto): Promise<void>
  fetchPaymentDocumentTransaction(): Promise<TransactionDto>
  onPaymentStart(paymentDocument: DocumentDto): void
  notificationsConnection: ReturnType<typeof useSignalR>['notificationsConnection']
  withLoader<T, R extends(Promise<T> | T)>(fn: ()=> R): Promise<R>
  withInterceptor<T, R extends(Promise<T> | T)>(
    paymentId: string,
    fn: (registerInterceptor: (fn: (err: Error)=> void)=> void)=> R,
  ): Promise<R>
  createDocumentPaymentEntity(): DocumentPaymentDto
  evaluateIncomingPayload(result: ResultDto['_data'], document: DocumentDto['_data']): Promise<ResultDto['_data'][]>
}

const getInitState = (): IPaymentContextState => ({
  /**
   * Params from request
   */
  value: null,
  paymentId: null,
  payTerminalVirtualId: null,
  referentialUniqueId: null,
  referenceNumber: null,
  mode: null,

  /**
   * Internal state
   */
  resultBuffer: [],

  /**
   * Link to document which is used for validation
   */
  verifyDocumentId: null,
});

// eslint-disable-next-line max-len
const ResolverByTwoWayCommunication: IResolverConstructor<IResolverByTwoWayCommunication> = class ResolverByTwoWayCommunication implements IResolverByTwoWayCommunication {
  public context: PaymentContext
  private eventListeners: Array<IPaymentEvent> = []

  public static deserialize(data: any): ResolverByTwoWayCommunication {
    return new ResolverByTwoWayCommunication(new Context({
      ...getInitState(),
      ...data,
      resultBuffer: data.resultBuffer.map((result) => new ResultDto(result)),
    }));
  }

  public static serialize(resolver: ResolverByTwoWayCommunication): any {
    return {
      ...resolver.context.state,
      resultBuffer: resolver.context.state.resultBuffer.map((result) => result.toJson()),
    };
  }

  constructor(context = new Context(getInitState())) {
    Object.assign(context.state, {
      ...getInitState(),
      ...context.state,
    });

    this.context = context as PaymentContext;
  }

  get type() {
    return ResolverTypes.ByTwoWayCommunication;
  }

  get validPaymentResults() {
    return filter(
      this.context.state.resultBuffer,
      (result) => this.isValidPaymentResult(result),
    );
  }

  get lastValidPaymentResultWithConfiguration() {
    if (!this.validPaymentResults.length) {
      return null;
    }


    return getResponseCodeConfiguration(last(this.validPaymentResults));
  }

  get isTerminated() {
    if (!this.validPaymentResults.length) {
      return false;
    }

    const {
      isTerminated,
    } = this.lastValidPaymentResultWithConfiguration;

    return isTerminated;
  }

  get isSuccessful() {
    if (!this.validPaymentResults.length) {
      return false;
    }

    const {
      code,
    } = this.lastValidPaymentResultWithConfiguration;

    return code === ResponseCodes.Ok;
  }

  get isResolved() {
    return this.isTerminated || this.isSuccessful;
  }

  get verifyDocumentId() {
    return this.context.state.verifyDocumentId;
  }

  on(...args: PaymentEventRegisterArgs) {
    const [event, callback] = args;

    this.eventListeners.push({
      event,
      callback,
    });

    return this;
  }

  emit(...args: PaymentEventEmitArgs) {
    const [emitEvent, ...emitCallbackArgs] = args;

    for (const {event: listenerEvent, callback: listenerCallback} of this.eventListeners) {
      if (listenerEvent === emitEvent) {
        listenerCallback(...emitCallbackArgs);
      }
    }
  }

  isValidPaymentResult(result: ResultDto) {
    return includes([
      DocumentTypes.PosPayment,
      DocumentTypes.PosPaymentRefund,
      DocumentTypes.PosPaymentCancel,
    ], result.operation);
  }

  processResults(results: ResultDto[], {replace = false} = {}) {
    this.context.state.resultBuffer = sortBy([
      ...(replace ? [] : this.context.state.resultBuffer),
      ...results,
    ], (result: ResultDto) => result.processedAt);

    this.emit(PaymentEvents.resultsReceived, sortBy(results, (result) => result.processedAt));
  }

  async withInterceptor<T, R extends(Promise<T> | T)>(
    paymentId: string,
    fn: (registerInterceptor: (fn: (err: Error)=> void)=> void)=> R,
  ): Promise<R> {
    try {
      return await fn((emitError) => {
        // @ts-ignore
        window.signalR?.payment.registerPayment(paymentId, emitError);
      });
    } finally {
      // @ts-ignore
      window.signalR?.payment.unregisterPayment(paymentId);
    }
  }

  async withLoader<T, R extends(Promise<T> | T)>(fn: ()=> R): Promise<R> {
    const coreStore = useCoreStore();

    try {
      coreStore.setLoader(true, {
        timeout: 1 * 60 * 1000,
      });

      return await fn();
    } finally {
      coreStore.setLoader(false);
    }
  }

  async fetchPaymentDocumentTransaction() {
    return await this.withLoader(async () => {
      return await apiDocumentGet({
        params: {
          id: this.context.state.verifyDocumentId,
        },
      });
    });
  }

  async fetchChangeHistory() {
    return this.withLoader(async () => {
      const history = await apiDocumentGetChangeHistory({
        params: {
          id: this.context.state.verifyDocumentId,
        },
      });

      return filter(history, (transaction) => transaction.hasResult);
    });
  }

  async savePaymentDocument(paymentDocument: DocumentDto) {
    return this.withLoader(async () => {
      paymentDocument.preflightSetup();

      this.emit(PaymentEvents.triggerDocumentCreated, paymentDocument);

      return await apiDocumentCreate({
        input: paymentDocument.toApiClone(),
      });
    });
  }

  get notificationsConnection() {
    const {notificationsConnection} = useSignalR();

    return notificationsConnection;
  }

  async evaluateIncomingPayload(result: ResultDto['_data'], document: DocumentDto['_data']) {
    const documentStatusStore = useDocumentStatusStore();

    return await documentStatusStore.solve(result, document);
  }

  async establishTwoWayCommunicationAndProcess(
    paymentId: string,
    trigger = (cancel: (err?: Error)=> void) => {},
    callback: (...args: any[])=> boolean | Promise<boolean> = null,
  ): Promise<ICommunicationResult> {
    const documentStatusStore = useDocumentStatusStore();

    try {
      await this.withInterceptor(paymentId, async (registerInterceptor) => {
        await this.withLoader(async () => {
          await this.notificationsConnection.addEventListenerWithTrigger(
            PrinterWSEvents.PROCESSED_DOC_MESSAGE,
            callback ?? (async (...args) => {
              const [{result, document} = {result: null, document: null}, sellDocumentUniqueId] = args;

              if (sellDocumentUniqueId !== paymentId) return false;

              emitTestEvent(`${TestEvent.PAYMENT_VERIFICATION_EVENT}:${result.statusCode}`, result);

              const results = await this.evaluateIncomingPayload(result, document);

              this.processResults(results.map((result) => new ResultDto(result)));

              if (this.isTerminated) {
                throw new Error(this.lastValidPaymentResultWithConfiguration.code);
              }

              return this.isSuccessful;
            }),
            {timeout: 10 * 60 * 1000},
          )((cancel) => {
            registerInterceptor(cancel);
            return trigger(cancel);
          });
        });
      });
    } catch (e) {
      console.error(e);
      recordCustomEventLogEntry('ResolverByTwoWayCommunication establishTwoWayCommunicationAndProcess', e.message);

      /**
       * Connection might be lost
       */
      if (e.message === SignalRErrors.timeout) {
        documentStatusStore.terminate();

        return {
          isValidated: false,
          isCanceled: false,
        };
      }

      if (this.isSuccessful) {
        return {
          isValidated: true,
          isCanceled: false,
        };
      }

      if (this.isTerminated) {
        return {
          isValidated: true,
          isCanceled: true,
        };
      }

      return {
        isValidated: false,
        isCanceled: false,
      };
    }

    return {
      isValidated: true,
      isCanceled: false,
    };
  }

  onPaymentStart(paymentDocument: DocumentDto) {
    submitJournalEventCardPaymentStart(paymentDocument);
  }

  createDocumentPaymentEntity() {
    const configurationStore = useConfigurationStore();

    const documentPayment = configurationStore.createPayment(this.context.state.paymentId);

    if (this.context.state.payTerminalVirtualId) {
      documentPayment.payTerminalVirtualId = this.context.state.payTerminalVirtualId;
    }

    documentPayment.setValue(this.context.state.value);

    return documentPayment;
  }

  createPaymentDocumentTypeCreate() {
    const payment = this.createDocumentPaymentEntity();

    const paymentDocument = this.context.state.value < 0 ?
      DocumentDto.createPosPaymentRefund(payment) :
      DocumentDto.createPosPayment(payment);

    if (this.context.state.referentialUniqueId) {
      paymentDocument.header.referentialUniqueidentifier = this.context.state.referentialUniqueId;
    }

    this.context.state.verifyDocumentId = paymentDocument.header.uniqueidentifier;

    return paymentDocument;
  }

  createPaymentDocumentTypeCancel() {
    const payment = this.createDocumentPaymentEntity();

    const paymentDocument = DocumentDto.createPosPaymentCancel(payment);

    if (this.context.state.referenceNumber) {
      paymentDocument.referenceNumber = this.context.state.referenceNumber;
    }

    this.context.state.verifyDocumentId = paymentDocument.header.uniqueidentifier;

    return paymentDocument;
  }

  createPaymentDocumentTypeRefund() {
    const payment = this.createDocumentPaymentEntity();

    const paymentDocument = this.context.state.value > 0 ?
      DocumentDto.createPosPaymentRefund(payment) :
      DocumentDto.createPosPayment(payment);

    if (this.context.state.referenceNumber) {
      paymentDocument.referenceNumber = this.context.state.referenceNumber;
    }

    this.context.state.verifyDocumentId = paymentDocument.header.uniqueidentifier;

    return paymentDocument;
  }

  createPaymentDocument() {
    switch (this.context.state.mode) {
    case PaymentModes.Create:
      return this.createPaymentDocumentTypeCreate();
    case PaymentModes.Cancel:
      return this.createPaymentDocumentTypeCancel();
    case PaymentModes.Refund:
      return this.createPaymentDocumentTypeRefund();
    default:
      throw new Error(`Unknown payment mode: ${this.context.state.mode}`);
    }
  }

  async startTwoWayCommunicationAndProcess() {
    const paymentDocument = this.createPaymentDocument();

    this.onPaymentStart(paymentDocument);

    const result = await this.establishTwoWayCommunicationAndProcess(
      paymentDocument.header.uniqueidentifier,
      async () => await this.savePaymentDocument(paymentDocument),
    );

    return result;
  }

  async resumeTwoWayCommunicationAndProcess(): Promise<ICommunicationResult> {
    let changeHistory: Array<TransactionDto> = [];

    try {
      changeHistory = await this.fetchChangeHistory();
    } catch (e) {
      console.error(e);
      recordCustomEventLogEntry('ResolverByTwoWayCommunication fetchChangeHistory', e.message);
      /**
       * We have verifyDocumentId but api for history failed => document is not in history
       */
      return {
        isValidated: true,
        isCanceled: true,
      };
    }

    if (changeHistory.length === 0) {
      /**
       * We do not have any history, check if document is older thant T - threshold
       * if it is older than threshold and if we do not have any results, terminate validation process
       */
      try {
        const transaction = await this.fetchPaymentDocumentTransaction();

        const threshold = 2 * 60 * 1000;
        const timeDiff = (+new Date()) - (+transaction.document.header.documentDate);
        const isOlderThanThreshold = timeDiff > threshold;

        if (isOlderThanThreshold) {
          return {
            isValidated: true,
            isCanceled: true,
          };
        }
      } catch (e) {
        console.error(e);
        recordCustomEventLogEntry('ResolverByTwoWayCommunication fetchPaymentDocumentTransaction', e.message);
        /**
         * Api call failed, but history does not => api issue
         */
        return {
          isValidated: false,
          isCanceled: false,
        };
      }
    }

    const resultsWithProcessedTimeStamp: Array<ResultDto> = map(changeHistory, ({result}) => {
      return new ResultDto(setResultProcessedAt(result.toJson()));
    });

    this.processResults(resultsWithProcessedTimeStamp, {replace: true});

    /**
     * Payment failed on itself on POSPrinter<>PosCore
     * => we have validated payment
     */
    if (this.isTerminated) {
      return {
        isValidated: true,
        isCanceled: true,
      };
    }

    /**
     * Payment has been finished on POSPrinter<>PosCore
     * => we have validated payment
     */
    if (this.isSuccessful) {
      return {
        isValidated: true,
        isCanceled: false,
      };
    }

    /**
     * Payment has been somewhere in the middle of process on POSPrinter<>PosCore
     * => we will try to reestablish connection and continue
     */

    const callback = (() => {
      const solvePrinterStatusWithPreventedParallel = preventParallel(
        (result, doc) => this.evaluateIncomingPayload(result, doc),
      );

      return async (...args) => {
        const [{result, document} = {result: null, document: null}, sellDocumentUniqueId, optional] = args;

        if (sellDocumentUniqueId !== this.context.state.verifyDocumentId) return false;

        const isRestoredEvent = optional?.isRestoredEvent ?? false;

        /**
         * Events from restored event branch has to prevented parallel!
         */

        const results = map(
          isRestoredEvent ?
            await solvePrinterStatusWithPreventedParallel(result, document) :
            await this.evaluateIncomingPayload(result, document),
          (result) => new ResultDto(result),
        );

        if (isRestoredEvent) {
          /**
           * this run has been triggered by last restored event, so we have to strip it from results
           */
          results.shift();
        }

        this.processResults(results);

        if (this.isTerminated) {
          throw new Error(this.lastValidPaymentResultWithConfiguration.code);
        }

        return this.isSuccessful;
      };
    })();

    try {
      const result = await this.establishTwoWayCommunicationAndProcess(
        this.context.state.verifyDocumentId,
        async (cancel) => {
          if (!changeHistory.length) {
            return;
          }

          const {
            result: unverifiedPaymentResult,
            document: unvalidatedPaymentDocument,
          } = last(changeHistory);

          (async () => {
            try {
              const isSuccessful = await callback(
                {
                  result: unverifiedPaymentResult.clone().toJson(),
                  document: unvalidatedPaymentDocument.clone().toJson(),
                },
                unvalidatedPaymentDocument.header.uniqueidentifier,
                {isRestoredEvent: true},
              );

              if (isSuccessful) {
                cancel(); // this throws SignalRErrors.canceled
              }
            } catch (e) {
              cancel(e); // this throws Error
            }
          })();
        },
        callback,
      );

      return result;
    } catch (e) {
      console.error(e);
      recordCustomEventLogEntry('ResolverByTwoWayCommunication resumeTwoWayCommunicationAndProcess', e.message);

      if (e.message === SignalRErrors.canceled) {
        /**
         * TwoWayCommunication has been canceled because of successful validation
         */
        return {
          isValidated: true,
          isCanceled: false,
        };
      }

      console.error(e);

      return {
        isValidated: true,
        isCanceled: true,
      };
    }
  }

  async ensureTwoWayCommunicationAndProcess() {
    if (this.context.state.verifyDocumentId) {
      return await this.resumeTwoWayCommunicationAndProcess();
    } else {
      return await this.startTwoWayCommunicationAndProcess();
    }
  }

  get processResult() {
    return {
      isTerminated: this.isTerminated,
      isSuccessful: this.isSuccessful,
      results: this.context.state.resultBuffer,
      verifyDocumentId: this.context.state.verifyDocumentId,
      handled: some(this.context.state.resultBuffer, (result) => {
        const {
          hasDialog,
        } = getResponseCodeConfiguration(result);

        return hasDialog;
      }),
      ...(this.isSuccessful ? {
        card: {
          cardNumber: this.lastValidPaymentResultWithConfiguration.originalResult?.cardNumber,
          cardType: this.lastValidPaymentResultWithConfiguration.originalResult?.cardType,
        },
      } : {}),
    };
  }

  async process() {
    if (this.isResolved) {
      return this.processResult;
    }

    const {
      isCanceled,
      isValidated,
    } = await this.ensureTwoWayCommunicationAndProcess();

    if (!isValidated) {
      return await this.process();
    }

    if (isCanceled) {
      return this.processResult;
    }

    return this.processResult;
  }
};


export default ResolverByTwoWayCommunication;
