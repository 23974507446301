import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';


export const AmountsToWinResourcePropDef = {
  amountsToWin: {
    type: new ObjectType(),
  },
};
export default class GeneratedAmountsToWinResource extends createEntity(AmountsToWinResourcePropDef) {}
