
import {
  computed,
  defineComponent,
} from 'vue';
import {useRouter} from 'vue-router';
import {useConfigurationStore} from '../store/ConfigurationStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useOnlineDetection} from '@/Helpers/onlineDetection';
import {useTrainingStore} from '@/Modules/Training/store/TrainingStore';
import MessageNotifier from '@/Modules/Apps/PosMessage/MessageNotifier.vue';
import EshopOrdersNotifier from '@/Modules/Apps/EshopOrders/EshopOrdersNotifier/EshopOrdersNotifier.vue';
import IconPerson from '@/Components/Icons/IconPerson.vue';
import IconHome from '@/Components/Icons/IconHome.vue';
import Now from '@/Components/Now.vue';
import PhIcon from '@/Components/PhIcon.vue';
import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';
import Countdown from '@/Components/Countdown.vue';
import {useFilters} from '@/Helpers/filters';
import Logo from '@/Components/Logo.vue';
import {useRestoreModeStore} from '@/Modules/Core/store/RestoreModeStore';
import {useResetToShopStore} from '@/Modules/Core/store/ResetToShopStore';
import {CountdownTicks} from '@/constants/countdown';

export default defineComponent({
  name: 'AppHeader',
  components: {
    Logo,
    Countdown,
    PhIcon,
    MessageNotifier,
    EshopOrdersNotifier,
    IconHome,
    IconPerson,
    Now,
  },
  props: {
    view: {
      type: String,
      required: false,
      default: 'default',
      enum: [
        'default',
        'apps',
      ],
    },
  },
  setup(props) {
    const {
      online,
      isForcedOnline,
      resetForcedOnline,
    } = useOnlineDetection();
    const {dateTimeFormat} = useFilters();

    const configurationStore = useConfigurationStore();
    const authStore = useAuthStore();
    const trainingStore = useTrainingStore();
    const updateStore = useUpdateStore();
    const restoreModeStore = useRestoreModeStore();
    const resetToShopStore = useResetToShopStore();

    const isDefaultView = computed(() => props.view === 'default');
    const isAppsView = computed(() => props.view === 'apps');
    const shopLabel = computed(() => [
      configurationStore.configuration.value?.general?.pos?.shop?.code ?? 'n/a',
      configurationStore.configuration.value?.general?.pos?.code ?? 'n/a',
    ].join('/'));

    const resetToShopLabel = computed(() => {
      if (!resetToShopStore.isActive.value) {
        return false;
      }

      return resetToShopStore.resetToShopId.value;
    });

    const router = useRouter();
    const isLoginPage = computed(() => router.currentRoute.value.name === 'login');

    const appsRouteExists = computed(() => {
      return router.hasRoute('apps');
    });

    return {
      authStore,
      shopLabel,
      resetToShopLabel,
      trainingStore,
      isDefaultView,
      isAppsView,
      isLoginPage,
      online,
      isForcedOnline,
      resetForcedOnline,
      updateStore,
      dateTimeFormat,
      appsRouteExists,
      restoreModeStore,
      CountdownTicks,
    };
  },
});
