
import {computed, defineComponent} from 'vue';
import {useI18n} from 'vue-i18n';
import {DocumentDto} from '@/Model/Entity';
import {unitsByItems} from '@/Helpers/unit';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export default defineComponent({
  setup(props, {attrs}) {
    const i18n = useI18n();
    const {dateFormat, currencyFormat} = useFilters();

    const configurationStore = useConfigurationStore();

    const logisticDocument = computed(() => {
      return attrs.logisticDocument as DocumentDto;
    });

    const summary = computed(() => {
      return [
        ...(!logisticDocument.value.header.reference ? [] : [
          {
            value: logisticDocument.value.header.reference,
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.reference'),
          },
        ]),

        ...(!logisticDocument.value.header.logisticsSource.name ? [] : [
          {
            value: logisticDocument.value.header.logisticsSource.name,
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.logisticsSource'),
          },
        ]),

        ...(!logisticDocument.value.header.documentDate ? [] : [
          {
            value: dateFormat(logisticDocument.value.header.documentDate),
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.documentDate'),
          },
        ]),

        ...(!logisticDocument.value.header.logisticsDestination.name ? [] : [
          {
            value: logisticDocument.value.header.logisticsDestination.name,
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.logisticsDestination'),
          },
        ]),

        ...(!logisticDocument.value.documentSubType.value ? [] : [
          {
            value: i18n.t(`apps.documents.types.${logisticDocument.value.documentSubType.value}`),
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.documentType'),
          },
        ]),

        ...(!logisticDocument.value.logisticItems ? [] : [
          {
            value: unitsByItems(logisticDocument.value.logisticItems, 'unit', 'quantity').join('; '),
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.totalAmount'),
          },
        ]),

        ...(!logisticDocument.value.logisticItems ? [] : [
          {
            value: currencyFormat(
              logisticDocument.value.logisticItems.reduce((acc, item) => acc + ((item.price ?? 0) * item.quantity), 0),
              configurationStore.localCurrency.value.symbol,
            ),
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.totalPrice'),
          },
        ]),

        ...(!logisticDocument.value.logisticItems.length ? [] : [
          {
            value: logisticDocument.value.logisticItems.length,
            label: i18n.t('workflow.steps.warehouseOperations.summary.fields.quantity'),
          },
        ]),

      ];
    });

    return {
      summary,
    };
  },
});
