import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const InventoryGroupDtoPropDef = {
  shopCode: {
    type: new StringType(),
  },
  inventoryGroup: {
    type: new StringType(),
  },
  inventoryGroupText: {
    type: new StringType(),
  },
  allowedLostValue: {
    type: new NumberType(),
  },
  currency: {
    type: new StringType(),
  },
  lastInventoryDate: {
    type: new DateTimeType(),
  },
  lastInventoryNumber: {
    type: new StringType(),
  },
  nextInventoryNumberOrder: {
    type: new NumberType(),
  },
};
export default class GeneratedInventoryGroupDto extends createEntity(InventoryGroupDtoPropDef) {}
