import {isSequence, SequenceMatched} from './helpers';
import {KeyPress, InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransformStream,
} from '../streams';

const enter: KeyPress = {
  'code': 'Enter',
  'alt': false,
  'ctrl': false,
  'shift': false,
  'key': 'Enter',
};

const etxKey: KeyPress = {
  'code': 'ETX',
  'alt': false,
  'ctrl': false,
  'shift': false,
  'key': '',
};

const stxKey: KeyPress = {
  'code': 'STX',
  'alt': false,
  'ctrl': false,
  'shift': false,
  'key': '',
};

const stxPatterns = [
  {
    matcher: isSequence([(key: KeyPress) => key.code === 'ALT' && key.key === '\x02']),
    drop: 1,
  },
  {
    matcher: isSequence([(key: KeyPress) => key.code === 'STX']),
    drop: 1,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Control' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyB' && key.dir === 'down',
      (key: KeyPress) => key.key === 'Control' && key.dir === 'up',
      (key: KeyPress) => key.code === 'KeyB' && key.dir === 'up',
    ]),
    drop: 4,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Control' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyB' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyB' && key.dir === 'up',
      (key: KeyPress) => key.key === 'Control' && key.dir === 'up',
    ]),
    drop: 4,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Control' && key.dir === 'down',
      (key: KeyPress) => key.key === 'Shift' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyB' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyB' && key.dir === 'up',
      (key: KeyPress) => key.key === 'Control' && key.dir === 'up',
      (key: KeyPress) => key.key === 'Shift' && key.dir === 'up',
    ]),
    drop: 4,
  },
  {
    matcher: isSequence([(key: KeyPress) => key.key === 'Unidentified' && key.dir === 'down']),
    drop: 1,
  },
];


const etxPatterns = [
  {
    matcher: isSequence([(key: KeyPress) => key.code === 'ALT' && key.key === '\x03']),
    drop: 1,
  },
  {
    matcher: isSequence([(key: KeyPress) => key.code === 'ETX']),
    drop: 1,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Control' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyC' && key.dir === 'down',
      (key: KeyPress) => key.key === 'Control' && key.dir === 'up',
      (key: KeyPress) => key.code === 'KeyC' && key.dir === 'up',
    ]),
    drop: 4,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Control' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyC' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyC' && key.dir === 'up',
      (key: KeyPress) => key.key === 'Control' && key.dir === 'up',
    ]),
    drop: 4,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Enter' && key.dir === 'down',
      (key: KeyPress) => key.key === 'Enter' && key.dir === 'up',
    ]),
    drop: 4,
    requireSTX: true,
    appendEnter: true,
  },
  {
    matcher: isSequence([
      (key: KeyPress) => key.key === 'Control' && key.dir === 'down',
      (key: KeyPress) => key.code === 'KeyJ' && key.dir === 'down',
      (key: KeyPress) => key.key === 'Control' && key.dir === 'up',
      (key: KeyPress) => key.code === 'KeyJ' && key.dir === 'up',
    ]),
    drop: 4,
  },
  {
    matcher: isSequence([(key: KeyPress) => key.key === 'Unidentified' && key.dir === 'up']),
    drop: 1,
  },
];


export function createScannerTransformStream() {
  const buffer: KeyPress[] = [];
  let isScanner = false;

  function testStxPatterns(controller) {
    let allFailed = true;
    for (const stxPattern of stxPatterns) {
      const sequencedMatched = stxPattern.matcher(buffer);
      if (sequencedMatched === SequenceMatched.YES) {
        isScanner = true;
        buffer.splice(0, stxPattern.drop);
        controller.enqueue({
          ...stxKey,
          source: 'scanner',
          dir: 'down',
        });
        return SequenceMatched.YES;
      } else if (sequencedMatched === SequenceMatched.NOT_FINISHED) {
        allFailed = false;
      }
    }

    if (allFailed) {
      return SequenceMatched.NO;
    }
    return SequenceMatched.NOT_FINISHED;
  }

  function testEtxPatterns(controller) {
    let allFailed = true;
    for (const etxPattern of etxPatterns) {
      const sequencedMatched = etxPattern.matcher(buffer);
      if (etxPattern.requireSTX && !isScanner) {
        // Don't convert enter to etx if we're not in scanner mode
        continue;
      }
      if (sequencedMatched === SequenceMatched.YES) {
        isScanner = false;
        buffer.splice(0, etxPattern.drop);
        controller.enqueue({
          ...etxKey,
          source: 'scanner',
          dir: 'down',
        });
        return SequenceMatched.YES;
      } else if (sequencedMatched === SequenceMatched.NOT_FINISHED) {
        allFailed = false;
      }
    }

    if (allFailed) {
      return SequenceMatched.NO;
    }
    return SequenceMatched.NOT_FINISHED;
  }

  return new TransformStream<KeyPress, KeyPress>({
    transform(chunk, controller) {
      buffer.push(chunk);
      // eslint-disable-next-line
      while (buffer.length > 0) {
        const etxResult = testEtxPatterns(controller);
        const stxResult = testStxPatterns(controller);
        // const etxResult = isScanner ? testEtxPatterns(controller) : SequenceMatched.NO;
        // const stxResult = isScanner ? SequenceMatched.NO : testStxPatterns(controller);

        if (stxResult === SequenceMatched.NO && etxResult === SequenceMatched.NO) {
          const key = buffer.shift();
          controller.enqueue({
            ...key,
            source: isScanner ? InputSource.SCANNER : key.source,
          });
        } else if (stxResult === SequenceMatched.NOT_FINISHED || etxResult === SequenceMatched.NOT_FINISHED) {
          break;
        }
      }
    },
  });
}
