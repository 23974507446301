import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {
  DocumentDto,
  DocumentFinItemDto,
  ReferentialDocumentsDto,
  SafeBagDto,
} from '@/Model/Entity';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {useCashAmountValidatorStore} from '@/Modules/Core/store/CashAmountValidatorStore';
import {
  filter,
  map,
  sumBy,
} from 'lodash-es';
import {workflowStepMixinSaveFinDocument} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinSaveFinDocument';
import {SignalRErrors} from '@/Helpers/signalR';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowStepApiSafeBagCreateFinDocument extends workflowStepMixinSaveFinDocument(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiSafeBagCreateFinDocument;
  }

  get type() {
    return WorkflowStepApiSafeBagCreateFinDocument.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get safeBagEnvelopeCode() {
    return this.getFieldValue(WorkflowStepField.safeBagEnvelopeCode);
  }

  get safeBagWasEdited() {
    return !!this.getFieldValue(WorkflowStepField.safeBagDocumentEdited);
  }

  get safeBag() {
    const value = this.getFieldValue(
      this.safeBagWasEdited ?
        WorkflowStepField.safeBagDocumentEdited :
        WorkflowStepField.safeBagDocument,
      {},
    );

    return new DocumentDto(value);
  }

  async beforeEnter() {
    const cashAmountValidatorStore = useCashAmountValidatorStore();

    if (this.stepInit) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    const transaction = this.configurationStore.financialTransactionsByCode.value[this.step.transactionCode];

    const payment = this.configurationStore.createPayment(transaction.paymentId);
    const amount = this.safeBag.header.total;

    payment.setValue(amount);

    const document = DocumentDto.createFinancialDocument({
      header: {
        finDocumentCode: transaction.code,
        finDocumentName: transaction.name,
        finDocumentTransactionNumber: transaction.number,
        total: payment.value,
        source: transaction.transactionSource,
        destination: transaction.transactionDestination,
        sapTransactionCode: transaction.sapTransactionCode,
      },
    });

    document.header.referentialUniqueidentifier = this.safeBag.header.uniqueidentifier;

    document.addPayment(payment, {refreshTotalPrice: false});

    if (amount) {
      document.finItems = [
        new DocumentFinItemDto({
          valueBeforeDiscounts: this.safeBag.header.total,
          quantity: 1,
          envelopeNumber: this.safeBagEnvelopeCode,
        }),
      ];
    }

    let finDocumentResult: Awaited<ReturnType<WorkflowStepApiSafeBagCreateFinDocument['saveFinDocument']>> = null;

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      finDocumentResult = await this.saveFinDocument(document);

      finDocumentResult.result = new PrinterResult(finDocumentResult.result ?? {});

      if (finDocumentResult.error) {
        throw finDocumentResult.error;
      }

      if (finDocumentResult.result.hasError) {
        throw new Error(finDocumentResult.result.errorMessage);
      }
    } catch (e) {
      console.error(e);

      if (e.message === SignalRErrors.timeout) {
        this.documentStatusStore.terminate();
      }

      if (!finDocumentResult?.result?.hasError) {
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));
      }
    } finally {
      await (async () => {
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));

        if (!finDocumentResult?.created) {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
          return;
        }

        if (isActiveFeaturePrintDisplayOnScreen() && finDocumentResult?.result?.hasValidPrintContent) {
          await this.printContentStore.open(finDocumentResult.result.printContent);
        }

        this.stepInit = true;

        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));

        if (this.code !== WorkflowCodes.financialReport) {
          cashAmountValidatorStore.validate();
        }
      })();
    }
  }

  get transitions() {
    return {};
  }
}
