import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import OrderSex from '../OrderSex';
import {DateTimeType} from '@designeo/apibundle-js';
import OrderCustomerAddressDto from '../OrderCustomerAddressDto';
import {BooleanType} from '@designeo/apibundle-js';


export const OrderCustomerDtoPropDef = {
  firstName: {
    type: new StringType(),
  },
  lastName: {
    type: new StringType(),
  },
  sex: {
    type: new EntityType(OrderSex),
  },
  birthDate: {
    type: new DateTimeType(),
  },
  address: {
    type: new EntityType(OrderCustomerAddressDto),
  },
  phoneNumber: {
    type: new StringType(),
  },
  email: {
    type: new StringType(),
  },
  ageConfirmationRequired: {
    type: new BooleanType(),
  },
};
export default class GeneratedOrderCustomerDto extends createEntity(OrderCustomerDtoPropDef) {}
