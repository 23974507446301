import PrinterResultCustom from './custom/PrinterResultCustom';
import {
  ResponseCodes,
} from '@/Helpers/printerServiceResponseCodes';
import {
  isEmpty,
  isNil,
  mapKeys,
} from 'lodash-es';
import {OutputTypes} from '@/constants/outputTypes';
import {printerHtmlBase64ToDataHtmlContent, printerPdfBase64ToDataPdfContent} from '@/Helpers/base64';

export default class PrinterResult extends PrinterResultCustom {
  get hasValidResponse() {
    return !isEmpty(this._data);
  }

  get hasError() {
    if (isNil(this.statusCode)) {
      return false;
    }

    return this.statusCode.toString() !== ResponseCodes.Ok;
  }

  get hasWarning() {
    if (isNil(this.warningCode)) {
      return false;
    }

    return this.warningCode.toString() !== ResponseCodes.Ok;
  }

  get isSuccessful() {
    return !this.hasError;
  }

  get errorMessage() {
    return this.description || this.errorText;
  }

  get hasValidPrintContent() {
    if (!this.hasValidResponse) return false;

    return !!(this.printout?.pdfBase64 ?? this.printout?.htmlBase64 ?? false);
  }

  /**
   * returns base64 content
   */
  get printContent() {
    if (!this.hasValidPrintContent) return null;

    return (
      printerPdfBase64ToDataPdfContent(this.printout.pdfBase64) ??
      printerHtmlBase64ToDataHtmlContent(this.printout.htmlBase64)
    );
  }

  get outputsByOutputType() {
    return mapKeys(this.output ?? [], 'outputType');
  }

  /**
   * returns base64 content
   */
  getPrintContentByOutputType(outputType: OutputTypes) {
    return this.outputsByOutputType[outputType];
  }
}
