import {
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import {isNil} from 'lodash-es';
import {wait} from '@designeo/js-helpers/src/timing/wait';
import {useSignalRStore} from '@/Modules/Core/store/SignalRStore';
import {getSignalRInterceptor} from '@/Helpers/interceptors';
import {recordCustomEventLogEntry, recordSignalRLogEntry} from '@/Helpers/logger';

export const connections = {};

export enum SignalRErrors {
  timeout = 'Request timeout',
  canceled = 'Request canceled',
}

type Logger = (...args)=> void

const signalRLoggersMap = new Map<string, {logger: Logger, referenceCounter: number}>();

export const signalRHandlersMap = new Map<(...args: any[])=> any, {event: string, trace: string}>();

const getInterceptedConnector = async () => {
  return getSignalRInterceptor();
};

const getOnlineConnector = ({retry = 1000} = {}) => async (url, connectionKey) => {
  const sentryCaptureEvent = (message) => {
    require('@/Helpers/sentry').sentryCaptureEvent(message);
  };

  try {
    const signalRStore = useSignalRStore();

    const connection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => retry,
      })
      .build();

    await connection.start();

    connection.onreconnecting((error) => {
      sentryCaptureEvent('SignalR reconnecting');
      recordCustomEventLogEntry('SignalR reconnecting', error?.message ?? 'Unknown error');
      signalRStore.setReconnecting(true);
      signalRStore.setReconnected(false);
    });

    connection.onreconnected((error) => {
      sentryCaptureEvent('SignalR reconnected');
      recordCustomEventLogEntry('SignalR reconnected', error);
      signalRStore.setReconnecting(false);
      signalRStore.setReconnected(true);
    });

    connection.onclose(async (error) => {
      sentryCaptureEvent('SignalR closed');
      recordCustomEventLogEntry('SignalR reconnected', error?.message ?? 'Unknown error');
      signalRStore.setClosed(true);
      delete connections[connectionKey];
    });

    signalRStore.setClosed(false);

    return connection;
  } catch (e) {
    console.error(e);
    if (retry) {
      await wait(retry)(null);
      return getOnlineConnector({retry})(url, connectionKey);
    } else {
      throw e;
    }
  }
};

const createConnectionWithInterceptor = async (url, connectionKey) => {
  const interceptor = await getInterceptedConnector();
  if (interceptor) {
    return interceptor;
  }

  return await getOnlineConnector()(url, connectionKey);
};

const createSignalRListenerLogger = (event) => {
  return (...data) => {
    recordSignalRLogEntry(event, 'server => client', ...data);
  };
};

const addConnectionEventListener = (connection: HubConnection, event, handler) => {
  connection.on(event, handler);

  signalRHandlersMap.set(handler, {event, trace: new Error().stack});

  if (signalRLoggersMap.has(event)) {
    const {logger, referenceCounter} = signalRLoggersMap.get(event);
    signalRLoggersMap.set(event, {logger, referenceCounter: referenceCounter + 1});
    return;
  }

  const logger = createSignalRListenerLogger(event);
  signalRLoggersMap.set(event, {logger, referenceCounter: 1});
  connection.on(event, logger);
};

const removeConnectionEventListener = (connection: HubConnection, event, handler) => {
  connection.off(event, handler);

  signalRHandlersMap.delete(handler);

  if (!signalRLoggersMap.has(event)) {
    return;
  }

  const {logger, referenceCounter} = signalRLoggersMap.get(event);

  if (referenceCounter > 1) {
    signalRLoggersMap.set(event, {logger, referenceCounter: referenceCounter - 1});
    return;
  }

  connection.off(event, logger);
  signalRLoggersMap.delete(event);
};

const invokeConnectionEvent = (connection, event, ...data) => {
  connection.invoke(event, ...data);

  recordSignalRLogEntry(event, 'client => server', ...data);
};

const createConnection = (id, url, {connector = createConnectionWithInterceptor} = {}) => {
  const connectionKey = `${id}:${url}`;

  const ensureConnection = async (): Promise<HubConnection> => {
    if (!connections[connectionKey]) {
      connections[connectionKey] = connector(url, connectionKey);
    }

    try {
      await connections[connectionKey];
    } catch (e) {
      delete connections[connectionKey];
    }

    return connections[connectionKey];
  };

  const setTransactionEvent = (event, data?) => {
    require('@/Helpers/sentry').setTransactionEvent(event, data);
  };

  return {
    addEventListener: async <T extends any[]>(event: string, callback: (...args: T)=> void) => {
      addConnectionEventListener(await ensureConnection(), event, callback);
    },
    addEventListenerWithTrigger: <T extends any[]>(
      event: string,
      callback: (...args: T)=> boolean | Promise<boolean> = () => true,
      {timeout = 30000} = {},
    ) => {
      let fulfilled = false;
      return (trigger: (cancel: ()=> void)=> any) => Promise
        .resolve(ensureConnection())
        .then(async (connection) => {
          let resolve;
          let reject;
          let responseTimeout;
          let startDate;
          let callbackPromiseLock;

          const communicationPromise = new Promise<T>((resolveInner, rejectInner) => {
            resolve = resolveInner;
            reject = rejectInner;
          })
            .then((data) => {
              fulfilled = true;
              setTransactionEvent(`addEventListenerWithTrigger:${event}`, {
                type: 'measurement',
                start: startDate,
                status: 'ok',
              });
              return Promise.resolve(data);
            })
            .catch((e) => {
              fulfilled = true;
              setTransactionEvent(`addEventListenerWithTrigger:${event}`, {
                type: 'measurement',
                start: startDate,
                status: 'failed',
              });
              return Promise.reject(e);
            });

          const innerCallback = async (...args: T) => {
            setTransactionEvent(`webSocketEvent:${event}`);
            try {
              const currentChainLevel = (async () => {
                await callbackPromiseLock;

                const callbackResultPromise = callback(...args);
                await new Promise((resolveInner) => {
                  setTimeout(resolveInner, 1);
                });
                return () => callbackResultPromise;
              })();

              callbackPromiseLock = currentChainLevel;

              const resultPromiseProxy = await currentChainLevel;

              if (await resultPromiseProxy()) {
                clearTimeout(responseTimeout);
                removeConnectionEventListener(connection, event, innerCallback);
                resolve(args);
              }
            } catch (e) {
              removeConnectionEventListener(connection, event, innerCallback);
              reject(e);
            }
          };

          addConnectionEventListener(connection, event, innerCallback);

          try {
            startDate = new Date();
            callbackPromiseLock = trigger((err = new Error(SignalRErrors.canceled)) => {
              if (fulfilled) return;
              clearTimeout(responseTimeout);
              removeConnectionEventListener(connection, event, innerCallback);
              reject(err);
            });

            await callbackPromiseLock;
          } catch (e) {
            removeConnectionEventListener(connection, event, innerCallback);
            throw e;
          }

          if (!isNil(timeout)) {
            responseTimeout = setTimeout(() => {
              reject(new Error(SignalRErrors.timeout));
            }, timeout);
          }

          return await communicationPromise;
        });
    },
    dispatchEvent: async (event, data?) => {
      invokeConnectionEvent(
        await ensureConnection(),
        event, ...(data !== undefined ? [data] : []),
      );
    },
    removeEventListener: async (event, callback?) => {
      removeConnectionEventListener(await ensureConnection(), event, callback);
    },
    waitForEvent: async (event) => {
      return new Promise((resolve, reject) => {
        Promise.resolve(ensureConnection())
          .then((connection) => {
            const handler = (...args) => {
              removeConnectionEventListener(connection, event, handler);
              resolve(args);
            };
            addConnectionEventListener(connection, event, handler);
          });
      });
    },
    get connection(): Promise<HubConnection> {
      return ensureConnection();
    },
  };
};

export const useSignalR = () => {
  const signalRStore = useSignalRStore();
  return {
    reconnecting: signalRStore.reconnecting,
    reconnected: signalRStore.reconnected,
    closed: signalRStore.closed,
    createConnection,
    get notificationsConnection() {
      return createConnection(
        'default',
        '/api/hubs/notifications',
      );
    },
    get notificationsOnlineOnly() {
      return createConnection(
        'onlineOnly',
        '/api/hubs/notifications',
        {connector: getOnlineConnector()},
      );
    },
    get notificationsOnlineOnlyWithSingleTry() {
      return createConnection(
        'onlineOnlyWithSingleTry',
        '/api/hubs/notifications',
        {connector: getOnlineConnector({retry: null})},
      );
    },
  };
};
