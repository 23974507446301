import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedBarcode extends createEntity({
  mode: {
    type: new StringType(),
  },
  scannerLanguage: {
    type: new StringType(),
  },
}) {}
