
import {
  computed,
  defineComponent,
  ref,
} from 'vue';
import {mapKeys} from 'lodash-es';
import ToggleList from '@/Components/ToggleList.vue';
import {WorkflowStepKorunkaLotteryDeposit} from '../WorkflowStepKorunkaLotteryDeposit';
import KorunkaLotterySestkaDtoCustom from '@/Model/Entity/custom/KorunkaLotterySestkaDtoCustom';
import {useStepTools} from '../../StepTools';
import {WorkflowStepField, WorkflowStepTypes} from '../../../types';
import {HLInput} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import InputBuffer from '@/Components/InputBuffer.vue';

export default defineComponent({
  components: {
    ToggleList,
    HLInput,
    FormInput,
    InputBuffer,
  },
  setup() {
    const {workflowStep} = useStepTools<WorkflowStepKorunkaLotteryDeposit>(WorkflowStepTypes.KorunkaDeposit);

    const innerValue = ref(null);

    const customDeposit = computed(() => workflowStep.value.customDeposit);

    const entity = computed(() => workflowStep.value.entity as KorunkaLotterySestkaDtoCustom);

    const innerModel = computed(() => {
      return customDeposit.value ?
        null :
        entity.value?.deposit?.id ?? innerValue.value;
    });

    const depositOptions = computed(() => workflowStep.value.depositOptions);

    const depositOptionsById = computed(() => mapKeys(depositOptions.value, 'id'));

    const updateModel = (id) => {
      workflowStep.value.customDeposit = false;

      innerValue.value = id;

      entity.value.deposit = depositOptionsById.value?.[id];
    };

    const toggleCustomDeposit = () => {
      workflowStep.value.customDeposit = !workflowStep.value.customDeposit;
    };

    return {
      entity,
      innerModel,
      updateModel,
      customDeposit,
      depositOptions,
      toggleCustomDeposit,
      workflowStep,
      WorkflowStepField,
    };
  },
});
