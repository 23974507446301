
import {defineComponent} from 'vue';
import {HLAsyncButton} from '@designeo/vue-helpers';
import Button from '@/Components/Button.vue';

export default defineComponent({
  name: 'AsyncButton',
  components: {
    HLAsyncButton,
    Button,
  },
  inheritAttrs: false,
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    test: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
});
