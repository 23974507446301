import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-screen max-h-screen overflow-hidden bg-app-bg text-basic" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, { class: "flex-grow lg:py-4 xl:container" })
  ]))
}