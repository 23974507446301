import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Transaction from '../Transaction';
import {BooleanType} from '@designeo/apibundle-js';
import PaymentTypeType from '../PaymentTypeType';
import {NumberType} from '@designeo/apibundle-js';


export const TransactionPaymentPropDef = {
  id: {
    type: new StringType(),
  },
  transactionId: {
    type: new StringType(),
  },
  // transaction: {
  //   type: new EntityType(Transaction),
  // },
  isMoneyBack: {
    type: new BooleanType(),
  },
  type: {
    type: new EntityType(PaymentTypeType),
  },
  currency: {
    type: new StringType(),
  },
  value: {
    type: new NumberType(),
  },
  valueInFCurrency: {
    type: new NumberType(),
  },
  exchangeRate: {
    type: new NumberType(),
  },
};
export default class GeneratedTransactionPayment extends createEntity(TransactionPaymentPropDef) {}
