import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const InventoryGroupResultsByVatDtoPropDef = {
  vatAmount: {
    type: new NumberType(),
  },
  totalSum: {
    type: new NumberType(),
  },
};
export default class GeneratedInventoryGroupResultsByVatDto extends createEntity(InventoryGroupResultsByVatDtoPropDef) {}
