import {
  createConfigureStore,
  createUseStore,
  Store,
} from '@designeo/vue-helpers';
import {useFourEyesStore} from '@/Modules/Core/store/FourEyesStore';
import {
  FourEyesOperations,
  RecoveryEvents,
  RecoveryTypes,
} from '@/Modules/Core/types';
import {apiDocumentErrorCreate, apiTechnicalStatus} from '@/Model/Action';
import CreateErrorDocumentDto from '../../../Model/Entity/CreateErrorDocumentDto';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {
  clearStorage,
  getVueApp,
  reloadAppFromRoot,
} from '@/Helpers/app';
import {waitAtLeast} from '@/Helpers/time';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {usePromoEngine} from '@/Modules/Register/PromoEngine/PromoEngine';
import {pick} from 'lodash-es';
import {useRestoreModeStore} from '@/Modules/Core/store/RestoreModeStore';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {submitJournalEventErrorDocumentCreate} from '@/Helpers/journal';
import {asyncStorage} from '@/Helpers/asyncStore';

export interface IRecoveryStore {
}

const createInitState = (data?: Partial<IRecoveryStore>) => ({});

export class RecoveryStore extends Store<IRecoveryStore> {
  constructor() {
    super(createInitState());
  }

  async cleanup(recoveryType: RecoveryTypes) {
    const registerStore = useRegisterStore();
    const workflowStore = useWorkflowStore();

    if (registerStore.sellDocument.value?.isTouched) {
      try {
        await registerStore.resetReceipt();
      } catch (e) {
        console.error(e);
        await clearStorage();
      }
    }

    if (recoveryType === RecoveryTypes.workflow) {
      try {
        await workflowStore.finishAllWorkflows();
      } catch (e) {
        console.error(e);
        await clearStorage();
      }
    } else if (recoveryType === RecoveryTypes.unknown) {
      await clearStorage();
    }
  }

  getRecoveryTypeByRoute(route: any) {
    route = typeof route === 'string' ? {name: route} : route;
    switch (route.name) {
    case 'register': return RecoveryTypes.register;
    case 'workflow': return RecoveryTypes.workflow;
    default: return RecoveryTypes.unknown;
    }
  }

  async getRecoveryContextByType(
    recoveryType: RecoveryTypes,
    {error}: {error?: Error} = {},
  ): Promise<CreateErrorDocumentDto['_data']> {
    const registerStore = useRegisterStore();
    const configurationStore = useConfigurationStore();
    const promoEngine = usePromoEngine();

    const state = {
      localStorage: JSON.parse(JSON.stringify(localStorage)),
      indexedDB: asyncStorage.serialize(),
      configuration: configurationStore.configuration.value?.toJson?.() ?? {},
      promotions: promoEngine.promotionBulk?.toJson?.() ?? promoEngine.promotionBulk ?? {},
      technicalStatus: await (async () => {
        try {
          return (await apiTechnicalStatus())?.toJson() ?? {};
        } catch (e) {
          console.error(e);
          return {};
        }
      })(),
      currentRoute: pick(getVueApp()?.config?.globalProperties?.$route ?? {}, [
        'fullPath',
        'hash',
        'href',
        'meta',
        'name',
        'params',
        'path',
        'query',
      ]),
      customerExternal: await (async () => {
        try {
          return pick((await broadcastIO.postMessageWithCallback(
            BroadcastIOChannels.ROUTE_GET,
            null,
            BroadcastIOChannels.ROUTE_GET,
            {timeout: 2000},
          )), [
            'fullPath',
            'hash',
            'href',
            'meta',
            'name',
            'params',
            'path',
            'query',
          ]);
        } catch (e) {
          return null;
        }
      })(),
      console: useRestoreModeStore().consoleCollector.value.ejectAndFlush(),
    };

    const service = 'pos-ui';

    switch (recoveryType) {
    case RecoveryTypes.workflow:
      return {
        document: {
          ...(registerStore.sellDocument.value?.isTouched ? {
            ...registerStore.sellDocument.value.toJson(),
          } : {}),
        },
        error: {
          trace: error?.stack ?? null,
          message: error?.message ?? 'manual - workflow',
          description: null,
          createAt: new Date().toISOString(),
        },
        state,
        service,
      };
    case RecoveryTypes.register:
      return {
        document: {
          ...(registerStore.sellDocument.value?.isTouched ? {
            ...registerStore.sellDocument.value.toJson(),
          } : {}),
        },
        error: {
          trace: error?.stack ?? null,
          message: error?.message ?? 'manual - register',
          description: null,
          createAt: new Date().toISOString(),
        },
        state,
        service,
      };
    case RecoveryTypes.unknown:
      return {
        document: {
          ...(registerStore.sellDocument.value?.isTouched ? {
            ...registerStore.sellDocument.value.toJson(),
          } : {}),
        },
        error: {
          trace: error?.stack ?? null,
          message: error?.message ?? 'manual - unknown',
          description: null,
          createAt: new Date().toISOString(),
        },
        state,
        service,
      };
    }
  }

  async recoveryCurrentState(
    recoveryType: RecoveryTypes,
    {error, withAppReload = true}: {error?: Error, withAppReload?: boolean} = {},
  ) {
    /**
     * @todo - request user description of problem
     */

    const coreStore = useCoreStore();

    try {
      coreStore.setLoader(true);

      const requestStart = new Date();

      submitJournalEventErrorDocumentCreate();

      await apiDocumentErrorCreate({
        input: new CreateErrorDocumentDto(await this.getRecoveryContextByType(recoveryType, {error})),
      });

      await waitAtLeast(2000, requestStart, new Date());

      await this.cleanup(recoveryType);

      if (withAppReload) {
        await reloadAppFromRoot();
      }
    } catch (e) {
      console.error(e);
    } finally {
      coreStore.setLoader(false);
    }
  }

  async recovery(
    recoveryType: RecoveryTypes,
    {
      error = null,
      withAppReload = true,
      fourEyes = true,
    }: {
      error?: Error,
      withAppReload?: boolean,
      fourEyes?: boolean
    } = {},
  ) {
    if (useRestoreModeStore().active.value) {
      console.error('[RestoreMode] you can not recovery in restore mode!');
      return;
    }

    const fourEyesStore = useFourEyesStore();

    try {
      const result = fourEyes && await fourEyesStore.openFourEyesConfirm(FourEyesOperations.RECOVERY);

      if (!fourEyes || result.isConfirmed) {
        await this.recoveryCurrentState(recoveryType, {error, withAppReload});
      }
    } catch (e) {
      this.dispatchEvent(new CustomEvent(RecoveryEvents.FOUR_EYES_ERROR, {
        detail: e.message,
      }));
    }
  }
}

const storeIdentifier = 'RecoveryStore';

export const configureRecoveryStore = createConfigureStore<typeof RecoveryStore>(storeIdentifier);
export const useRecoveryStore = createUseStore(RecoveryStore, storeIdentifier);
