
import {useFilters} from '@/Helpers/filters';
import {OrderDtoOrderEnvelopeDto, OrderItemDto} from '@/Model/Entity';
import {defineComponent, PropType} from 'vue';
export default defineComponent({
  props: {
    item: {
      type: Object as PropType<OrderItemDto>,
      required: true,
    },
    order: {
      type: Object as PropType<OrderDtoOrderEnvelopeDto>,
      required: true,
    },
  },
  setup() {
    const {currencyFormat, numberFormat} = useFilters();

    return {
      numberFormat,
      currencyFormat,
    };
  },
});
