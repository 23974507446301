
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from 'vue';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';

export default defineComponent({
  name: 'Now',
  setup() {
    const configurationStore = useConfigurationStore();
    const {dateFormat} = useFilters();
    const date = ref(new Date());
    let timer = null;

    const fallbackFormat = 'ccc HH:mm';

    const now = computed(() => {
      try {
        return dateFormat(date.value, {
          format: configurationStore.configuration.value?.ui?.general?.headerCurrentDateTimeFormat ?? fallbackFormat,
        });
      } catch (e) {
        console.error(e);
        return dateFormat(date.value, {format: fallbackFormat});
      }
    });

    const stopTimer = () => {
      clearInterval(timer);
    };

    const startTimer = () => {
      stopTimer();
      timer = setInterval(() => {
        date.value = new Date();
      }, 1000);
    };

    onMounted(() => {
      startTimer();
    });

    onBeforeUnmount(() => {
      stopTimer();
    });

    return {
      now,
    };
  },
});
