import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {
  every,
  findIndex,
  isObject,
  map,
  some,
} from 'lodash-es';
import * as aclKeys from '@/constants/aclKeys';
import {PermissionDto} from '@/Model/Entity';
import {
  RouteLocation,
  RouteLocationRaw,
  useRouter,
} from 'vue-router';
import PermissionTargetDto from '@/Model/Entity/PermissionTargetDto';

export type AclKeys = keyof typeof aclKeys |
  Array<keyof typeof aclKeys> |
  PermissionTargetDto |
  PermissionTargetDto[] |
  PermissionTargetDto['_data'] |
  PermissionTargetDto['_data'][];

export const aclMetaToKeys = (to: RouteLocation) => {
  if (typeof to.meta?.acl === 'function') {
    return <AclKeys>to.meta.acl(to);
  } else {
    return <AclKeys>to.meta?.acl ?? null;
  }
};

export const useRouteAccess = () => {
  const router = useRouter();
  const acl = useAcl();

  return (route: RouteLocationRaw) => {
    const to = router.resolve(route);

    const keys = aclMetaToKeys(to);
    const strategy = <'every' | 'some'>(to.meta?.aclStrategy ?? 'every');

    return !keys || acl(keys, {method: strategy});
  };
};

export const useAcl = () => {
  const authStore = useAuthStore();

  const resolveAcl = (keys: AclKeys) => (permissions: PermissionDto[]) => map([].concat(keys), (aclKey) => {
    if (aclKey instanceof PermissionTargetDto) {
      return findIndex(permissions, (permission) => aclKey.isValidTargetOf(permission)) !== -1;
    }

    if (isObject(aclKey)) {
      return findIndex(permissions, (permission) => {
        return (new PermissionTargetDto(aclKey)).isValidTargetOf(permission);
      }) !== -1;
    }

    return findIndex(permissions, {name: aclKey}) !== -1;
  });

  const $acl = (
    keys: AclKeys,
    {
      method = 'every',
      source = authStore.activePerson.value?.permissions ?? [],
    }: {
      method?: 'some' | 'every',
      source?: PermissionDto[]
    } = {},
  ) => {
    if (!source.length) return false;

    if (method === 'every') {
      return every(resolveAcl([].concat(keys))(source));
    } else {
      return some(resolveAcl([].concat(keys))(source));
    }
  };

  $acl.some = (keys) => $acl(keys, {method: 'some'});
  $acl.every = (keys) => $acl(keys, {method: 'every'});

  return $acl;
};
