import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const StatisticsReportDtoPropDef = {
  journalStartNumber: {
    type: new NumberType(),
  },
  journalEndNumber: {
    type: new NumberType(),
  },
  servicesTotalAmount: {
    type: new NumberType(),
  },
  documentCount: {
    type: new NumberType(),
  },
  averageDocumentTurnover: {
    type: new NumberType(),
  },
  sellDocumentCount: {
    type: new NumberType(),
  },
  averageSellDocumentTurnover: {
    type: new NumberType(),
  },
  documentItemCount: {
    type: new NumberType(),
  },
  averageDocumentItemTurnover: {
    type: new NumberType(),
  },
  cashDrawerOpenedCount: {
    type: new NumberType(),
  },
  canceledSellDocumentCount: {
    type: new NumberType(),
  },
  canceledSellDocumentValue: {
    type: new NumberType(),
  },
  returnedDocumentItemCount: {
    type: new NumberType(),
  },
  returnedDocumentItemQuantity: {
    type: new NumberType(),
  },
  returnedDocumentItemValue: {
    type: new NumberType(),
  },
  canceledDocumentItemCount: {
    type: new NumberType(),
  },
  canceledDocumentItemQuantity: {
    type: new NumberType(),
  },
  canceledDocumentItemValue: {
    type: new NumberType(),
  },
};
export default class GeneratedStatisticsReportDto extends createEntity(StatisticsReportDtoPropDef) {}
