import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow -mt-4" }
const _hoisted_2 = { class: "flex flex-col gap-2 flex-grow" }
const _hoisted_3 = { class: "flex flex-row flex-wrap gap-3 form-control overflow-hidden overflow-ellipsis form-control--sm" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "flex-shrink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WarehouseOperationList = _resolveComponent("WarehouseOperationList")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_FormInput, {
              showLabel: false,
              layout: 'col',
              size: 'sm',
              marginBottom: false,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFilterView = !_ctx.isFilterView))
            }, _createSlots({ _: 2 }, [
              (_ctx.filtersSummary.length)
                ? {
                    name: "form-control",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersSummary, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "px-1 py-0.5 bg-warning rounded text-white text-sm font-normal whitespace-nowrap"
                          }, _toDisplayString(value), 1))
                        }), 128))
                      ])
                    ])
                  }
                : {
                    name: "default",
                    fn: _withCtx(() => [
                      _createTextVNode(_toDisplayString('\xa0'))
                    ])
                  }
            ]), 1024)
          ]),
          (_ctx.isFilterView)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filters, (filter) => {
                return (_openBlock(), _createBlock(_component_HLInput, {
                  key: filter.key,
                  field: filter.key
                }, {
                  default: _withCtx(({value, error, isActive, setActive}) => [
                    _createVNode(_component_FormInput, {
                      marginBottom: false,
                      error: error,
                      errorOutput: 'modal',
                      layout: 'col',
                      size: 'sm',
                      active: isActive,
                      class: _normalizeClass({
                'text-basic-dark': filter.readonly,
              }),
                      labelUppercase: false,
                      onClick: ($event: any) => (filter.readonly ? null : setActive())
                    }, _createSlots({
                      label: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(filter.label), 1)
                      ]),
                      _: 2
                    }, [
                      (filter.component && !filter.readonly)
                        ? {
                            name: "form-control",
                            fn: _withCtx(({active}) => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(filter.component), _mergeProps({
                                modelValue: value.value,
                                "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                                active: active
                              }, {
                    ...(!filter.options ? {} : {
                      options: filter.options,
                    }),
                    ...(!filter.direction ? {} : {
                      direction: filter.direction,
                    }),
                  }, { size: 'sm' }), null, 16, ["modelValue", "onUpdate:modelValue", "active"]))
                            ])
                          }
                        : {
                            name: "default",
                            fn: _withCtx(() => [
                              _createTextVNode(_toDisplayString((filter.readonly ? filter.getReadonlyValue() : value.value) ?? '\xa0'), 1)
                            ])
                          }
                    ]), 1032, ["error", "active", "class", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["field"]))
              }), 128))
            : (_openBlock(), _createBlock(_component_WarehouseOperationList, {
                key: 1,
                list: _ctx.list,
                onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onListSelect($event)))
              }, null, 8, ["list"])),
          (_ctx.isFilterView)
            ? (_openBlock(), _createBlock(_component_AsyncButton, {
                key: 2,
                onClick: () => _ctx.isFilterView = !_ctx.isFilterView,
                class: "py-2.5 mt-4 btn-secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('action.search')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_WorkflowTransitions, {
            nextDisabled: workflowStep.disabledNextStep
          }, null, 8, ["nextDisabled"])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}