import {
  ObjectType,
  ArrayType,
  EntityType,
  NumberType,
  BooleanType,
  StringType,
} from '@designeo/apibundle-js';
import {Korunka} from '../../../../types/korunka';
import {Currencies} from '@/constants/currencies';
import DrawPartnerResource from '../DrawPartnerResource';

export const KorunkaLotteryGameCommonProperties = {
  repeatTicketIds: {
    type: new ArrayType(new NumberType()),
  },
  preparedTicketIds: {
    type: new ArrayType(new NumberType()),
  },
  deposit: {
    type: new ObjectType<{id?: number, value?: number, currency?: Currencies}>(),
  },
  draws: {
    type: new ArrayType(new EntityType(DrawPartnerResource)),
  },
  maxPrizePerTicket: {
    type: new NumberType(),
  },
  amountPaid: {
    type: new NumberType(),
  },
  amountPaidSum: {
    type: new NumberType(),
  },
  isCancel: {
    type: new BooleanType(),
  },
  ticketsToCancel: {
    type: new ArrayType(new ObjectType()),
  },
  isRepeatBet: {
    type: new BooleanType(),
  },
  isFromScan: {
    type: new BooleanType(),
  },
  isPrepared: {
    type: new BooleanType(),
  },
  ticketNumber: {
    type: new NumberType(),
  },
  betsCombinations: {
    type: new ArrayType(new ObjectType<Korunka['TicketResourceCombinations']>()),
  },
  tutovka: {
    type: new ArrayType(new NumberType()),
  },
  amountsToWin: {
    type: new ObjectType(),
  },
  id: {
    type: new StringType(),
  },
};
