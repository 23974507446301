import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
  Store,
} from '@designeo/vue-helpers';
import {syncToTarget} from '@/Helpers/syncedStore';
import {AnalogDisplayLayout} from '@/Modules/AnalogDisplay/types';
import {apiDocumentCreateDisplay} from '@/Model/Action';
import {DocumentDto} from '@/Model/Entity';
import {isEqual} from 'lodash-es';
import {analogDisplayIO} from '@/Helpers/analogDisplay';

export type IAnalogDisplayStore = {
  content: {
    layout: AnalogDisplayLayout,
    mode?: 'byState' | 'manual'
  }
};

const createInitState = (): IAnalogDisplayStore => ({
  content: null,
});

export class AnalogDisplayStore extends Store<IAnalogDisplayStore> {
  constructor() {
    super(createInitState());
  }

  content= syncToTarget('AnalogDisplay', 'content', getter(() => {
    return this.state.content;
  }), {transmitter: analogDisplayIO})

  displayDimensions= syncToTarget('AnalogDisplay', 'displayDimensions', getter(() => {
    return {
      rows: 2,
      columns: 20,
    };
  }), {transmitter: analogDisplayIO})

  ensureContent = action(async ({layout, mode = 'byState'}: IAnalogDisplayStore['content']) => {
    if (!this.state.content) {
      return await this.setContent({layout, mode});
    }

    if (this.state.content.mode !== mode) {
      return await this.setContent({layout, mode});
    }

    if (!isEqual(this.state.content.layout, layout)) {
      return await this.setContent({layout, mode});
    }

    return null;
  });


  setContent = action(async ({layout, mode = 'byState'}: IAnalogDisplayStore['content']) => {
    this.state.content = {
      layout,
      mode,
    };

    const messageDocument = DocumentDto.createAnalogDisplayMessage(layout);

    messageDocument.preflightSetup();

    await apiDocumentCreateDisplay({
      input: messageDocument.toApiClone(),
    });
  });
}

const storeIdentifier = 'AnalogDisplayStore';

export const configureAnalogDisplayStore = createConfigureStore<typeof AnalogDisplayStore>(storeIdentifier);
export const useAnalogDisplayStore = createUseStore(AnalogDisplayStore, storeIdentifier);
