import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import resolveOverviewComponent from './Components/resolve';

export class WorkflowStepKorunkaLotteryOverview extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaOverview;
  }

  get type() {
    return WorkflowStepKorunkaLotteryOverview.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryOverview.vue').default);
  }

  get gameComponent() {
    return resolveOverviewComponent(this.korunkaType, this.isScanResultEntity(this.entity));
  }

  get disabledNextStep() {
    return !this.customerConfirmResult;
  }

  get customerConfirmResult() {
    return this.getFieldValue('customerConfirmResult', false);
  }

  setCustomerConfirmResult(value) {
    this.dataSetter('customerConfirmResult', () => value);
  }

  previousTicket() {
    if (this.isScanResultEntity(this.entity)) {
      this.entity.previousTicket();
      this.syncEntityToCustomerDisplay();
    }
  }

  nextTicket() {
    if (this.isScanResultEntity(this.entity)) {
      this.entity.nextTicket();
      this.syncEntityToCustomerDisplay();
    }
  }

  async beforeEnter() {
    await this.syncBasicStepStateToCustomerDisplay();
    await this.calculateEntityPossibleWin();
    await this.syncEntityToCustomerDisplay();
  }

  get transitions() {
    return {};
  }
}
