
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {
  HLStep, useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import {WorkflowStepEshopOrderCustomerRegistration} from './WorkflowStepEshopOrderCustomerRegistration';
import OrderDetail from '../StepEshopOrderOverview/OrderDetail.vue';
import AsyncButton from '@/Components/AsyncButton.vue';

export default defineComponent({
  name: 'StepEshopOrderCustomerRegistration',
  components: {
    WorkflowTransitions,
    HLStep,
    OrderDetail,
    AsyncButton,
  },
  setup() {
    const stepType = WorkflowStepTypes.EshopOrderCustomerRegistration;
    const {
      workflowStep,
    } = useStepTools<WorkflowStepEshopOrderCustomerRegistration>(stepType);

    const createCustomer = async () => {
      workflowStep.value.createCustomer();
    };

    return {
      stepType,
      WorkflowStepField,
      createCustomer,
    };
  },
});
