import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ClientDataPartnerResourcePropDef = {
  clientId: {
    type: new NumberType(),
  },
  cardNumber: {
    type: new StringType(),
  },
  username: {
    type: new StringType(),
  },
  firstname: {
    type: new StringType(),
  },
  lastname: {
    type: new StringType(),
  },
  accountBalanceVirtual: {
    type: new NumberType(),
  },
  korunkarMesiceAmountKb: {
    type: new NumberType(),
  },
  korunkarMesicePlace: {
    type: new NumberType(),
  },
  korunkarRokuAmountKb: {
    type: new NumberType(),
  },
  korunkarRokuPlace: {
    type: new NumberType(),
  },
};
export default class GeneratedClientDataPartnerResource extends createEntity(ClientDataPartnerResourcePropDef) {}
