
import {
  computed,
  defineComponent,
  ref,
  watch,

} from 'vue';
import Modal from '@/Components/Modal.vue';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {useSynchronizationStore} from '@/Modules/Core/store/SynchronizationStore';
import {debounce} from 'lodash-es';

export default defineComponent({
  name: 'ModalSynchronizationState',
  components: {
    Modal,
  },
  setup() {
    const synchronizationStore = useSynchronizationStore();
    const {isSecondaryDisplay} = useSecondaryDisplay();
    const resolving = ref(false);

    const debouncedResolving = debounce((val) => {
      if (val !== resolving.value) {
        resolving.value = val;
      }
    }, 1000);

    const open = computed(() => {
      if (isSecondaryDisplay) {
        return false;
      }

      return resolving.value;
    });

    watch(
      () => synchronizationStore.synchronization.value,
      async (promise) => {
        try {
          debouncedResolving(true);
          await promise;
        } finally {
          debouncedResolving(false);
        }
      },
      {immediate: true},
    );

    return {
      open,
    };
  },
});
