import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.PHONE_NUMBER_CONFIRM]: {
      ...getCommonTransitions.call(this, [RegisterActions.CANCEL]),
      [RegisterActions.INIT]: action(async () => {
        this.ensureCustomerDisplayState();
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (event.value) {
          await this.transitionToNextStep();
        } else {
          this.state.productFlow.product.setPhoneNumberWithinContext(
            this.state.sellDocument,
            this.state.editOf,
            null,
          );

          await this.changeState(RegisterState.ENTER_PHONE_NUMBER);
        }
      }),
    },
  };
}
