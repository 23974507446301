import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import Confirmation from '../Confirmation';
import Overpay from '../Overpay';
import {NumberType} from '@designeo/apibundle-js';
import PaymentVerification from '../PaymentVerification';
import {BooleanType} from '@designeo/apibundle-js';
import PaymentTypeType from '../PaymentTypeType';
import Underpay from '../Underpay';
import Refund from '../Refund';


export default class GeneratedPaymentTypeClass extends createEntity({
  automaticallyOpenDrawer: {
    type: new StringType(),
  },
  changeCurrency: {
    type: new StringType(),
  },
  confirmation: {
    type: new EntityType(Confirmation),
  },
  currency: {
    type: new StringType(),
  },
  description: {
    type: new StringType(),
  },
  overpay: {
    type: new EntityType(Overpay),
  },
  paymentCode: {
    type: new StringType(),
  },
  paymentId: {
    type: new NumberType(),
  },
  payTerminalVirtualId: {
    type: new StringType(),
  },
  paymentVerification: {
    type: new EntityType(PaymentVerification),
  },
  refund: {
    type: new EntityType(Refund),
  },
  rounding: {
    type: new BooleanType(),
  },
  sapTransactionCode: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(PaymentTypeType),
  },
  underpay: {
    type: new EntityType(Underpay),
  },
}) {}
