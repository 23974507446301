import {ICustomerSectionAttribute} from '@/Modules/CustomerExternal/types';
import {createEntity, ObjectType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import AdClass from '../AdClass';


export default class GeneratedCustomerDisplay extends createEntity({
  ads: {
    type: new ArrayType(new EntityType(AdClass)),
  },
  checkout: {
    type: new ObjectType<{
      panel: {
        customer: {
          sections: Array<ICustomerSectionAttribute>
        }
      }
    }>(),
  },
}) {}
