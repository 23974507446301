import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import InventoryGroupResultsByVatDto from '../InventoryGroupResultsByVatDto';


export const DocumentInventoryGroupPropDef = {
  inventoryGroup: {
    type: new StringType(),
  },
  inventoryGroupText: {
    type: new StringType(),
  },
  allowedLostValue: {
    type: new NumberType(),
  },
  lostValue: {
    type: new NumberType(),
  },
  lostValueToPay: {
    type: new NumberType(),
  },
  lastInventoryDate: {
    type: new DateTimeType(),
  },
  resultsByVat: {
    type: new ArrayType(new EntityType(InventoryGroupResultsByVatDto)),
  },
  originalAllowedLostValue: {
    type: new NumberType(),
  },
  valueDifference: {
    type: new NumberType(),
  },
  quantityDifference: {
    type: new NumberType(),
  },
};
export default class GeneratedDocumentInventoryGroup extends createEntity(DocumentInventoryGroupPropDef) {}
