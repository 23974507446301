import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalStockInStores = _resolveComponent("ModalStockInStores", true)!

  return (_openBlock(), _createBlock(_component_ModalStockInStores, {
    ref: "stockInStoresRef",
    product: _ctx.product,
    open: _ctx.open,
    inputBuffer: _ctx.inputBuffer,
    onClose: _ctx.onClose,
    onKeyboardInput: _ctx.onKeyboardInput
  }, null, 8, ["product", "open", "inputBuffer", "onClose", "onKeyboardInput"]))
}