import {createEntity} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export default class GeneratedOverpay extends createEntity({
  allow: {
    type: new BooleanType(),
  },
  max: {
    type: new NumberType(),
  },
  min: {
    type: new NumberType(),
  },
}) {}
