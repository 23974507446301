import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.VALIDATE_AGE_RESTRICTION]: {
      [RegisterActions.ENTER]: action(async () => {
        this.state.sellDocument.confirmItemAgeRestriction(this.state.productFlow.product);
        await this.transitionToNextStep();
      }),
      [RegisterActions.CANCEL]: action(async () => {
        await this.restoreSellDocumentBackup();
        await this.resetToDefaultStep();
      }),
    },
  };
}
