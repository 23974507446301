import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col gap-2 mt-2 bg-white rounded-md p-4" }
const _hoisted_3 = { class: "font-bold text-xl" }
const _hoisted_4 = { class: "text-danger font-bold mt-4 text-center" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = {
  class: "grid items-center justify-start gap-2",
  style: {"grid-template-rows":"repeat(8, 1fr)","grid-auto-flow":"column"}
}
const _hoisted_7 = { class: "font-bold text-sm m-auto" }
const _hoisted_8 = {
  key: 0,
  class: "grid gap-1 pt-2",
  style: {"grid-template-columns":"50px repeat(5, 24px)"}
}
const _hoisted_9 = { class: "text-xs my-auto" }
const _hoisted_10 = { class: "flex flex-row justify-between items-center" }
const _hoisted_11 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!
  const _component_ModalNumbers = _resolveComponent("ModalNumbers")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.drawsDates) + ": " + _toDisplayString(_ctx.drawsDates), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.drawsDates,
          void 0,
          { drawsDates: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.ticketCount) + ": " + _toDisplayString(_ctx.entity?.ticketCount), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.ticketCount,
          void 0,
          { ticketCount: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.drawsCount) + ": " + _toDisplayString(_ctx.entity?.drawsCount), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.drawsCount,
          void 0,
          { drawsCount: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.maxPrizePerTicket) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.maxPrizePerTicket ?? 0 )), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.maxPrizePerTicket,
          void 0,
          { maxPrizePerTicket: true }
        ]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.total) + ": " + _toDisplayString(_ctx.currencyFormat(_ctx.entity?.total ?? 0)), 1)
      ], 512), [
        [
          _directive_test,
          _ctx.entity?.total ?? 0,
          void 0,
          { total: true }
        ]
      ])
    ]),
    _createElementVNode("button", {
      class: "btn btn-basic btn-outline-basic mt-4 mx-auto w-4/12",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openNumbersModal && _ctx.openNumbersModal(...args)))
    }, _toDisplayString(_ctx.workflowStep.messages.showNumbers), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.workflowStep.messages.waitingForCustomerConfirm), 1),
    _createVNode(_component_ModalNumbers, {
      title: _ctx.workflowStep.messages.modalNumbersTitle,
      isOpen: _ctx.workflowStep.numbersModalIsOpen,
      onClose: _ctx.closeNumbersModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTicketEntity?.randomNumbers, (randomNumbersRow, rowIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: rowIndex,
                class: "grid gap-1 items-center",
                style: {"grid-template-columns":"1rem repeat(5, minmax(0, 1fr))"}
              }, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLetterFromIndex(rowIndex)) + ". ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(randomNumbersRow, (number, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "korunka-circle-number korunka-circle-number--xs"
                  }, _toDisplayString(number), 1))
                }), 128))
              ]))
            }), 128))
          ]),
          (_ctx.activeTicketEntity.tutovka.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.workflowStep.messages.tutovka) + ": ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTicketEntity.tutovka, (number, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "korunka-circle-number korunka-circle-number--xs"
                  }, _toDisplayString(number), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      paginator: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            class: "btn shadow-md",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPreviousTicket && _ctx.onPreviousTicket(...args)))
          }, [
            _createVNode(_component_PhIcon, { icon: "PhCaretLeft" })
          ]),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.interpolate(_ctx.workflowStep.messages.ticketNumber, {number: _ctx.entity.activeTicketIndex + 1})), 1),
          _createElementVNode("button", {
            class: "btn shadow-md",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onNextTicket && _ctx.onNextTicket(...args)))
          }, [
            _createVNode(_component_PhIcon, { icon: "PhCaretRight" })
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isOpen", "onClose"])
  ]))
}