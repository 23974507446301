import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';


export const LogisticDocumentDtoPropDef = {
  year: {
    type: new NumberType(),
  },
  documentNumber: {
    type: new StringType(),
  },
  documentType: {
    type: new StringType(),
  },
  postingDate: {
    type: new DateTimeType(),
  },
  text: {
    type: new StringType(),
  },
};
export default class GeneratedLogisticDocumentDto extends createEntity(LogisticDocumentDtoPropDef) {}
