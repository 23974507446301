import {
  computed,
  ComputedRef,
  defineComponent,
  h,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  watch,

} from 'vue';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {useI18n} from 'vue-i18n';
import {
  WorkflowActions,
  WorkflowEvents,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  camelCase,
  get,
  map,
  some,
} from 'lodash-es';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {Workflow} from '@/Modules/Workflow/Workflow/Workflow';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useRouter} from 'vue-router';
import {seekApiErrors, useErrorParser} from '@/Helpers/errors';
import {submitJournalEventWorkflowCancelled} from '@/Helpers/journal';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {useHelpStore} from '@/Modules/Core/store/HelpStore';
import {emitTestEvent} from '@/Helpers/testEvent';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {wait} from '@designeo/js-helpers';

export const useMessageBus = (workflowStep: WorkflowStep) => {
  const workflowStore = useWorkflowStore();
  const coreStore = useCoreStore();
  const helpStore = useHelpStore();
  const router = useRouter();
  const parseError = useErrorParser();
  const {abortWorkflow, pointOfNoReturn} = useStepTools(workflowStep.type);

  const transitionLock = () => new Promise((resolve) => {
    if (!coreStore.loaderActive.value) {
      resolve(null);
      return;
    }

    const unwatch = watch(() => coreStore.loaderActive.value, (value) => {
      if (!value) {
        unwatch();
        resolve(null);
      }
    });
  });

  const next = async () => {
    await wait(0, null);
    await transitionLock();
    await workflowStore.currentWorkflow.value.requestNextStep();
  };

  const prev = async () => {
    await transitionLock();
    await workflowStore.currentWorkflow.value.requestPreviousStep();
  };

  const setActiveStepByCode = async (event: CustomEvent) => {
    await transitionLock();
    await workflowStore.currentWorkflow.value.setActiveStepByCode(event.detail.code);
  };

  const setActiveStepByIndex = async (event: CustomEvent) => {
    await transitionLock();
    await workflowStore.currentWorkflow.value.setActiveStepByIndex(event.detail.index);
  };

  const onExit = async () => {
    await transitionLock();
    workflowStore.currentWorkflow.value?.messageBus.dispatchEvent(new Event(WorkflowEvents.EXIT));
  };

  const changeActiveField = (event: CustomEvent) => {
    workflowStore.currentWorkflow.value.changeActiveField(event.detail.field, {
      validate: event.detail.validate ?? undefined,
    });
  };

  const setRefererQueryArgs = (event: CustomEvent) => {
    const refererRoute = router.resolve(workflowStore.currentWorkflow.value.referer);

    refererRoute.query = {
      ...refererRoute.query,
      ...event.detail,
    };

    workflowStore.currentWorkflow.value.referer = router.resolve(refererRoute).fullPath;
  };

  const onError = async (event: CustomEvent) => {
    let description = event?.detail?.value || null;
    if (event.detail.value && event.detail.value instanceof Error) {
      const apiErrors = parseError(seekApiErrors(event.detail.value));
      if (apiErrors.length) {
        description = apiErrors.join('\n');
      } else {
        description = event.detail.value.message;
      }
    }

    emitTestEvent(TestEvent.ERROR, event.detail.type);
    await helpStore.show(event.detail.type, {params: event.detail.args || {}, description});
  };

  const setLoaderOn = (event: CustomEvent<Parameters<typeof coreStore['setLoader']>[1]>) => {
    coreStore.setLoader(true, {
      opacity: event.detail?.opacity,
      timeout: event.detail?.timeout,
    });
  };

  const setLoaderOff = () => {
    coreStore.setLoader(false);
  };

  const onChangeWorkflow = async (
    event: CustomEvent<{workflowCode: string, params: Record<string, any>, returnToCurrentWorkflow?: boolean}>,
  ) => {
    const currentWorkflowReferer = workflowStore.currentWorkflow.value.referer.toString();

    await router.replace({
      name: 'workflow',
      params: {
        workflowCode: event.detail.workflowCode,
      },
      query: {
        referer: (event.detail?.returnToCurrentWorkflow ?? true) ?
          router.currentRoute.value.fullPath :
          currentWorkflowReferer,
        fresh: 'true',
        params: JSON.stringify(event.detail.params || {}),
      },
    });
  };

  onMounted(() => {
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.NEXT, next);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.PREV, prev);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.SET_ACTIVE_STEP_BY_CODE, setActiveStepByCode);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.SET_ACTIVE_STEP_BY_INDEX, setActiveStepByIndex);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, changeActiveField);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.SET_REFERER_QUERY_ARGS, setRefererQueryArgs);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.ERROR, onError);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.ABORT_WORKFLOW, abortWorkflow);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.POINT_OF_NO_RETURN, pointOfNoReturn);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.CHANGE_WORKFLOW, onChangeWorkflow);
    workflowStep.messageBus.addEventListener(WorkflowStepEvents.EXIT, onExit);

    workflowStep.messageBus.addEventListener(AppLoaderEvent.ON, setLoaderOn);
    workflowStep.messageBus.addEventListener(AppLoaderEvent.OFF, setLoaderOff);
  });

  onBeforeUnmount(() => {
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.NEXT, next);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.PREV, prev);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.SET_ACTIVE_STEP_BY_CODE, setActiveStepByCode);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.SET_ACTIVE_STEP_BY_INDEX, setActiveStepByIndex);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.CHANGE_ACTIVE_FIELD, changeActiveField);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.SET_REFERER_QUERY_ARGS, setRefererQueryArgs);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.ERROR, onError);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.ABORT_WORKFLOW, abortWorkflow);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.POINT_OF_NO_RETURN, pointOfNoReturn);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.CHANGE_WORKFLOW, onChangeWorkflow);
    workflowStep.messageBus.removeEventListener(WorkflowStepEvents.EXIT, onExit);

    workflowStep.messageBus.removeEventListener(AppLoaderEvent.ON, setLoaderOn);
    workflowStep.messageBus.removeEventListener(AppLoaderEvent.OFF, setLoaderOff);
  });
};


export const useStepTools = <WS extends WorkflowStep = WorkflowStep>
  (stepType: WorkflowStepTypes) => {
  const workflowStore = useWorkflowStore();
  const coreStore = useCoreStore();
  const i18n = useI18n();

  const currentWorkflow = computed(() => workflowStore.currentWorkflow.value);

  const workflowStep = computed(() => {
    if (!Object.prototype.hasOwnProperty.call(currentWorkflow.value.workflowStepByType, stepType)) {
      console.warn(
        `List of workflows ${Object.keys(currentWorkflow.value.workflowStepByType)} does not contain ${stepType}`,
      );
    }

    return currentWorkflow.value.activeWorkflowStep as WS;
  });

  const isActiveField = (field) => {
    return workflowStore.currentWorkflow.value.activeField === field;
  };

  const getFieldLabel = (field) => {
    const key = `workflow.${camelCase(currentWorkflow.value.activeStepType)}.${field}`;
    if (!i18n.te(key)) return null;

    return i18n.t(key);
  };

  const insertMode = computed(
    () => workflowStore.currentWorkflow.value.activeWorkflowStep.insertMode,
  );

  const abortWorkflow = async () => {
    if (!currentWorkflow.value) return;
    if (await coreStore.confirm(i18n.t('workflow.abortWorkflow.confirm'))) {
      currentWorkflow.value.exit();
      submitJournalEventWorkflowCancelled(currentWorkflow.value.code);
    }
  };

  const pointOfNoReturn = async () => {
    if (!currentWorkflow.value) return;
    if (await coreStore.confirm(i18n.t('workflow.pointOfNoReturn.confirm'))) {
      currentWorkflow.value.exit();
      submitJournalEventWorkflowCancelled(currentWorkflow.value.code);
    }
  };

  return {
    currentWorkflow,
    workflowStep,
    isActiveField,
    getFieldLabel,
    insertMode,
    abortWorkflow,
    pointOfNoReturn,
  };
};

const StepContext = Symbol('StepContext');

interface StepApi {
  workflowStep: ComputedRef<WorkflowStep>
  currentWorkflow: ComputedRef<Workflow>
  isActiveField: (field: any)=> boolean
  getFieldLabel: (field: any)=> string
  insertMode: ComputedRef<boolean>
}

/* eslint-disable vue/one-component-per-file */
export const HLStep = defineComponent({
  name: 'HLStep',
  props: {
    stepType: {
      type: String as PropType<WorkflowStepTypes>,
      required: true,
    },
  },
  setup(props, {emit}) {
    const {
      workflowStep,
      currentWorkflow,
      isActiveField,
      insertMode,
      abortWorkflow,
      getFieldLabel,
    } = useStepTools(props.stepType);

    const router = useRouter();

    const workflowCode = computed(() => router.currentRoute.value.params.workflowCode);

    const onGetWorkflowStep = () => {
      const currentStep = workflowStep.value?.step;

      broadcastIO.postMessage(
        BroadcastIOChannels.GET_WORKFLOW_STEP,
        currentStep,
      );
    };

    useMessageBus(workflowStep.value);

    provide(StepContext, <StepApi>{
      workflowStep,
      currentWorkflow,
      isActiveField,
      insertMode,
      getFieldLabel,
    });

    onMounted(async () => {
      // check current workflow step code against code from router because
      // workflowStep.value.code can be old unfinished workflow sometimes
      if (workflowStep.value.code === workflowCode.value) {
        broadcastIO.addEventListener(BroadcastIOChannels.GET_WORKFLOW_STEP, onGetWorkflowStep);
        broadcastIO.postMessage(BroadcastIOChannels.GET_WORKFLOW_STEP);
        await workflowStep.value.recordStep();
        await workflowStep.value.beforeEnter();
        await workflowStep.value.afterEnter();
      }
    });

    onBeforeUnmount(() => {
      broadcastIO.removeEventListener(BroadcastIOChannels.GET_WORKFLOW_STEP, onGetWorkflowStep);
    });


    return {
      abortWorkflow,
      workflowStep,
      currentWorkflow,
      isActiveField,
      insertMode,
      getFieldLabel,
    };
  },
  render() {
    const slotData = {
      abortWorkflow: this.abortWorkflow,
      workflowStep: this.workflowStep,
      currentWorkflow: this.currentWorkflow,
      isActiveField: this.isActiveField,
      insertMode: this.insertMode,
      getFieldLabel: this.getFieldLabel,
    };
    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});

export const injectStepContext = () => <StepApi>inject(StepContext, null);

export const HLInput = defineComponent({
  name: 'HLInput',
  props: {
    field: {
      type: String,
      required: true,
    },
  },
  setup(props, {emit}) {
    const i18n = useI18n();
    const workflowStore = useWorkflowStore();
    const {
      workflowStep,
      currentWorkflow,
      isActiveField,
      insertMode,
      getFieldLabel,
    } = injectStepContext();

    const error = computed(() => workflowStep.value.validationErrorAt(props.field));

    const label = computed(() => getFieldLabel(props.field));

    const isActive = computed(() => {
      return isActiveField(props.field);
    });

    const value = computed({
      get: () => {
        return get(workflowStep.value.data, props.field);
      },
      set: (val) => {
        workflowStore.onEventInput({
          type: WorkflowActions.ENTER,
          value: val,
        });
      },
    });

    const setActive = async () => {
      if (isActive.value) {
        return;
      }

      await currentWorkflow.value.changeActiveField(props.field);
    };

    const setActiveWithValue = async (val) => {
      await setActive();
      value.value = val;
    };

    return {
      label,
      isActive,
      value,
      setActive,
      setActiveWithValue,
      error,
      insertMode,
    };
  },
  render() {
    const slotData = {
      label: this.label,
      isActive: this.isActive,
      value: computed({
        get: () => {
          return this.value;
        },
        set: (val) => {
          this.value = val;
        },
      }),
      setActive: this.setActive,
      setActiveWithValue: this.setActiveWithValue,
      error: this.error,
      insertMode: this.insertMode,
    };

    return map((this.$slots?.default?.(slotData) ?? []), (slot) => h(slot));
  },
});
