
import {computed, defineComponent} from 'vue';
import {useCoreStore} from '../store/CoreStore';
import TransitionWrapper from '../../../Components/TransitionWrapper.vue';
import IconLoaderPulse from '../../../Components/Icons/IconLoaderPulse.vue';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {ZLayer} from '@/constants/zLayer';
import {LoaderControls} from '@/constants/loader';
import {includes} from 'lodash-es';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'AppLoader',
  components: {
    IconLoaderPulse,
    TransitionWrapper,
  },
  setup() {
    const i18n = useI18n();
    const coreStore = useCoreStore();
    const documentStatusStore = useDocumentStatusStore();
    const isVisible = computed(() => {
      if (documentStatusStore.dialog.value) {
        return false;
      }

      return coreStore.loaderTimeoutExpired.value;
    });

    const wait = () => {
      coreStore.processExpirationQuestionWait();
    };

    const reload = () => {
      coreStore.processExpirationQuestionReload();
    };

    const cancelRequests = () => {
      coreStore.processExpirationQuestionCancelRequests(i18n.t('loader.cancelRequestsReason'));
    };

    const hasCancelRequests = computed(() => {
      if (!includes(coreStore.loaderControls.value, LoaderControls.cancelRequests)) {
        return false;
      }

      return coreStore.apiRequestCancelers.value.length;
    });

    const hasReload = computed(() => {
      if (!includes(coreStore.loaderControls.value, LoaderControls.reload)) {
        return false;
      }

      return true;
    });

    const hasWait = computed(() => {
      if (!includes(coreStore.loaderControls.value, LoaderControls.wait)) {
        return false;
      }

      return true;
    });

    const loaderActive = computed(() => {
      return coreStore.loaderActive.value;
    });

    const loaderOpacity = computed(() => {
      return coreStore.loaderOpacity.value;
    });

    return {
      ZLayer,
      loaderActive,
      loaderOpacity,
      isVisible,
      wait,
      reload,
      cancelRequests,
      hasCancelRequests,
      hasReload,
      hasWait,
    };
  },
});
