
import {
  HLGrid,
} from '@designeo/vue-grid';
import {
  defineComponent,
  PropType,
  toRef,

} from 'vue';
import Modal from '@/Components/Modal.vue';
import Qwerty from '@/Components/Qwerty/Qwerty.vue';
import ModalStockInStoresButtons from '@/Components/ModalStockInStores/ModalStockInStoresButtons.vue';
import ModalStockInStoresHeader from '@/Components/ModalStockInStores/ModalStockInStoresHeader.vue';
import ModalStockInStoresBody from '@/Components/ModalStockInStores/ModalStockInStoresBody.vue';
import {provideStockInStores} from '@/Components/ModalStockInStores/stockInStores';
import {DocumentItemDto, DocumentLogisticItemDto} from '@/Model/Entity';

export default defineComponent({
  name: 'ModalStockInStores',
  components: {
    ModalStockInStoresBody,
    ModalStockInStoresHeader,
    ModalStockInStoresButtons,
    Qwerty,
    Modal,
    HLGrid,
  },
  props: {
    product: {
      type: null as PropType<DocumentItemDto | DocumentLogisticItemDto | null>,
      required: true,
    },
    inputBuffer: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'keyboardInput'],
  setup(props, {emit}) {
    const close = () => {
      emit('close');
    };

    const onKeyboardInput = (key) => {
      emit('keyboardInput', key);
    };

    const {
      gridModel,
      gridConfig,
      modalRef,
      showKeyboard,
      toggleShowKeyboard,
      searchStockInStore,
    } = provideStockInStores({
      isOpen: toRef(props, 'open'),
      inputBuffer: toRef(props, 'inputBuffer'),
      product: toRef(props, 'product'),
      close,
    });

    return {
      close,
      modalRef,
      gridModel,
      gridConfig,
      showKeyboard,
      toggleShowKeyboard,
      onKeyboardInput,
      searchStockInStore,
    };
  },
});
