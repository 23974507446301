export enum AppLoaderEvent {
  ON = 'appLoaderOn',
  OFF = 'appLoaderOff',
}

export enum CoreWSEvents {
  CONFIGURATION_CHANGES_PROCESSED = 'ConfigurationChangesProcessed',
  UPDATE_CONDITIONS_MIGHT_CHANGED = 'UpdateConditionsMightChanged',
  NEW_SYNC_AVAILABLE = 'NewSyncAvailable',
  APPLY_SYNC = 'ApplySync',
  PRINTER_SERVICE_RUNNING_STATE_CHANGED = 'PrinterServiceRunningStateChanged',
}


export enum PrinterWSEvents {
  ESTABLISH_NOTIFICATION_CONNECTION = 'ESTABLISH_NOTIFICATION_CONNECTION',
  NEW_NOTIFICATION = 'NewNotification',
  PROCESSED_DOC_MESSAGE = 'ProcessedDocMessage',
  PROCESSED_PDF_DOC_MESSAGE = 'ProcessedPdfDocMessage',
}

export enum FourEyesState {
  CLOSED,
  ENTER_OPERATOR_PERSONAL_NUMBER,
  ENTER_OPERATOR_PERSONAL_PIN,
}

export enum FourEyesActions {
  ADD_NUMBER='addNumber',
  ENTER='enter',
  BACKSPACE='backspace',
  BACK='back',
  CANCEL='cancel',
  SCANNER='scanner',
}

export enum FourEyesErrors {
  REQUEST = 'request',
  PROCESS = 'process',
  SAME_OPERATOR = 'sameOperator',
  UNAUTHORIZED = 'unauthorized',
}

export enum FourEyesOperations {
  PRODUCT_CANCEL = 'productCancel',
  RECEIPT_CANCEL = 'receiptCancel',
  PRODUCT_RETURN = 'productReturn',
  RECEIPT_STORNO_BEFORE_TIMEOUT = 'receiptStornoBeforeTimeout',
  RECEIPT_STORNO_AFTER_TIMEOUT = 'receiptStornoAfterTimeout',
  CANCEL_OF_RECEIPT_STORNO = 'cancelOfReceiptStorno',
  QUICK_LOGIN_MANAGE = 'quickLoginManage',
  POS_BLOCKED_STATE_MANAGE = 'posBlockedStateManage',
  POS_RE_REGISTRATION_MANAGE = 'posBlockedStateManage',
  RECOVERY = 'recovery',
  DEV_TOOLS = 'devTools',
}

export type FourEyesInputEvent<V = any> = {
  type: FourEyesActions,
  value?: V
}


export enum PrintContentState {
  CLOSED,
  OPENED,
}

export enum PrintContentActions {
  PRINT='enter',
  CLOSE='close',
  OPEN='close',
}

export enum PrintContentEvents {
  PRINTED='printed',
  CLOSED='closed',
}

export type PrintContentInputEvent<V = any> = {
  type: PrintContentActions,
  value?: V
}

export enum CustomerInteractionState {
  CLOSED,
  OPENED,
}

export enum CustomerInteractionActions {
  CLOSE='close',
  OPEN='close',
}

export enum CustomerInteractionEvents {
  PRINTED='printed',
  CLOSED='closed',
}

export type CustomerInteractionInputEvent<V = any> = {
  type: CustomerInteractionActions,
  value?: V
}


export enum CashAmountValidatorState {
  CLOSED,
  OPENED,
}

export enum CashAmountValidatorActions {
  CLOSE='close',
  OPEN='close',
}

export enum CashAmountValidatorEvents {
  CLOSED='closed',
}

export type CashAmountValidatorInputEvent<V = any> = {
  type: CashAmountValidatorActions,
  value?: V
}

export enum RecoveryEvents {
  FOUR_EYES_ERROR='fourEyesError',
}

export enum RecoveryTypes {
  workflow = 'workflow',
  register = 'register',
  unknown = 'unknown'
}


export enum CoreSounds {
  ARTICLE_NOT_FOUND = 'articleNotFound',
  ARTICLE_ALREADY_IN_DOCUMENT = 'articleAlreadyInDocument',
  ARTICLE_IS_TYPE_SERVICE = 'articleIsTypeService',
  ARTICLE_IS_NOT_EXPANDABLE_SET = 'articleIsNotExpandableSet',
  ARTICLE_VARIATIONS = 'articleVariations',
}
export enum ConfirmTypes {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
  default = 'default'
}

export enum ConfirmTemplates {
  default = 'error',
  defaultWithRecovery = 'defaultWithRecovery',
  defaultWithSellDocumentCheckAndChargeCancel = 'defaultWithSellDocumentCheckAndChargeCancel',
  reRegistration = 'reRegistration',
}

export interface Modal {
  id: string,
  zIndexBase: number,
  zIndexOffset: number,
  silenceKeyboardBuffer: boolean
  silenceCloseListeners: boolean
  spawnedAtRoute: any,
  closeCallback: (positive: boolean)=> void,
}
