import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const ArticleFilterDescriptionDtoPropDef = {
  internalNumber: {
    type: new StringType(),
  },
  batch: {
    type: new StringType(),
  },
};
export default class GeneratedArticleFilterDescriptionDto extends createEntity(ArticleFilterDescriptionDtoPropDef) {}
