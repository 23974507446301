import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export default class GeneratedTemplateClass extends createEntity({
  code: {
    type: new StringType(),
  },
  content: {
    type: new StringType(),
  },
  template: {
    type: new StringType(),
  },
}) {}
