
import {computed, defineComponent} from 'vue';

import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';

import ProgressBar from '@/Modules/Workflow/ProgressBar/ProgressBar.vue';
import Html from '@/Components/Html.vue';
import Step from '../Step/Step.vue';
import IconCross from '@/Components/Icons/IconCross.vue';
import {useStepTools} from '../Step/StepTools';
import {WorkflowStepUIModes} from '@/Modules/Workflow/types';

export default defineComponent({
  name: 'Display',
  components: {
    Step,
    Html,
    ProgressBar,
    IconCross,
  },
  setup() {
    const workflowStore = useWorkflowStore();
    const {abortWorkflow} = useStepTools(workflowStore.currentWorkflow.value.activeStepType);

    const showStepUi = computed(() => {
      return workflowStore.currentWorkflow.value.activeWorkflowStep?.showWorkflowProgress ?? true;
    });

    const hasStepUi = computed(() => {
      return showStepUi.value && workflowStore.currentWorkflow.value.visibleSteps.of > 1;
    });

    const hasTitleStepUi = computed(() => {
      if (workflowStore.currentWorkflow.value.visibleSteps.of < 2) {
        return false;
      }

      if (workflowStore.currentWorkflow.value.activeWorkflowStep.stepUIMode === WorkflowStepUIModes.everywhere) {
        return true;
      }

      if (workflowStore.currentWorkflow.value.activeWorkflowStep.stepUIMode === WorkflowStepUIModes.atTitle) {
        return true;
      }

      return false;
    });

    const hasFooterStepUi = computed(() => {
      if (workflowStore.currentWorkflow.value.visibleSteps.of < 2) {
        return false;
      }

      if (workflowStore.currentWorkflow.value.activeWorkflowStep.stepUIMode === WorkflowStepUIModes.everywhere) {
        return true;
      }

      if (workflowStore.currentWorkflow.value.activeWorkflowStep.stepUIMode === WorkflowStepUIModes.atFooter) {
        return true;
      }

      return false;
    });

    return {
      hasStepUi,
      workflowStore,
      abortWorkflow,
      hasTitleStepUi,
      hasFooterStepUi,
    };
  },
});
