
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalConfirmContent from '@/Components/ModalConfirmContent.vue';
import ModalConfirmContentDefault from '@/Modules/Core/Confirm/contents/ModalConfirmContentDefault.vue';
import ModalConfirmContentTypeRecovery from '@/Modules/Core/Confirm/contents/ModalConfirmContentTypeRecovery.vue';
import ModalConfirmContentTypeSellDocumentCancel
  from '@/Modules/Core/Confirm/contents/ModalConfirmContentTypeSellDocumentCancel.vue';
import {ConfirmTemplates} from '@/Modules/Core/types';
import {useConfirmContent} from '@/Modules/Core/Confirm/contents/content';
import {useCoreStore} from '../store/CoreStore';
import {ZLayer} from '@/constants/zLayer';
import ModalConfirmContentTypeReRegistration
  from '@/Modules/Core/Confirm/contents/ModalConfirmContentTypeReRegistration.vue';

export default defineComponent({
  name: 'ModalConfirm',
  components: {
    ModalConfirmContent,
    Modal,
  },
  setup() {
    const coreStore = useCoreStore();
    const {onReject, onConfirm} = useConfirmContent();

    const zIndex = computed(() => {
      return ZLayer.critical;
    });

    const component = computed(() => {
      switch (coreStore.confirmContent.value?.template) {
      case ConfirmTemplates.defaultWithSellDocumentCheckAndChargeCancel:
        return ModalConfirmContentTypeSellDocumentCancel;
      case ConfirmTemplates.defaultWithRecovery:
        return ModalConfirmContentTypeRecovery;
      case ConfirmTemplates.reRegistration:
        return ModalConfirmContentTypeReRegistration;
      default:
        return ModalConfirmContentDefault;
      }
    });

    return {
      onReject,
      onConfirm,
      coreStore,
      component,
      zIndex,
    };
  },
});
