import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_4 = { class: "flex flex-col w-3/4 mx-auto mb-6" }
const _hoisted_5 = { class: "mx-auto text-center text-danger" }
const _hoisted_6 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconWarning = _resolveComponent("IconWarning")!
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_HLInput = _resolveComponent("HLInput")!
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep}) => [
        _createElementVNode("div", _hoisted_2, [
          (workflowStep.data.btcDisplayError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_IconWarning, { class: "inline h-12 w-12 mb-4" }),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(workflowStep.step.orderNumberError), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_HLInput, {
            field: _ctx.WorkflowStepField.orderNumber
          }, {
            default: _withCtx(({isActive, error, value, setActive}) => [
              _createVNode(_component_FormInput, {
                label: workflowStep.step.labelOrderNumber,
                error: error,
                errorOutput: 'modal',
                active: isActive,
                layout: "col",
                onClick: setActive
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value.value), 1)
                ]),
                _: 2
              }, 1032, ["label", "error", "active", "onClick"])
            ]),
            _: 2
          }, 1032, ["field"])
        ]),
        _createVNode(_component_WorkflowTransitions, {
          nextDisabled: workflowStep.disabledNextStep
        }, null, 8, ["nextDisabled"])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}