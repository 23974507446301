
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {ZLayer} from '@/constants/zLayer';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';
import {usePrinterStatusStore} from '@/Modules/Core/store/PrinterStatusStore';
import {getResponseCodeConfiguration} from '@/Helpers/printerServiceResponseCodes';
import {useFilters} from '@/Helpers/filters';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'ModalPrinterStatus',
  components: {
    ModalDetachedButtons,
    AsyncButton,
    Modal,
  },
  setup() {
    const i18n = useI18n();
    const printerStatusStore = usePrinterStatusStore();
    const {isSecondaryDisplay} = useSecondaryDisplay();
    const updateStore = useUpdateStore();
    const {translatedString} = useFilters();

    const warningCode = computed(() => {
      return printerStatusStore.warningCode.value;
    });

    const warning = computed(() => {
      if (!warningCode.value) {
        return null;
      }

      return getResponseCodeConfiguration({
        statusCode: warningCode.value,
      });
    });

    const title = computed(() => {
      if (!warning.value?.title) {
        return i18n.t('printerStatus.fallbackTitle');
      }

      return translatedString(warning.value.title ?? {}) ?? i18n.t('printerStatus.fallbackTitle');
    });

    const message = computed(() => {
      if (!warning.value?.instructions) {
        return i18n.t('printerStatus.fallbackMessage');
      }

      return translatedString(warning.value.instructions ?? {}) ?? i18n.t('printerStatus.fallbackMessage');
    });

    const open = computed(() => {
      if (updateStore.updateTriggered.value) {
        return false;
      }

      if (isSecondaryDisplay) {
        return false;
      }

      return !!warningCode.value;
    });

    const onClose = () => {
      printerStatusStore.reset();
    };

    return {
      open,
      ZLayer,
      title,
      message,
      onClose,
    };
  },
});
