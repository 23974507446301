import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col flex-grow m-4 px-4 gap-4"
}
const _hoisted_2 = { class: "text-2xl font-bold text-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row items-center justify-center gap-4 p-4"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col items-center justify-center gap-2 p-4"
}
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = {
  key: 2,
  class: "flex flex-row items-center justify-center gap-4 p-4"
}
const _hoisted_7 = {
  key: 3,
  class: "flex flex-col items-center justify-center gap-4 p-4"
}
const _hoisted_8 = {
  class: "grid items-center justify-center gap-4 p-4",
  style: {"grid-template-rows":"repeat(8, 1fr)","grid-auto-flow":"column"}
}
const _hoisted_9 = { class: "font-bold m-auto" }
const _hoisted_10 = {
  key: 0,
  class: "grid gap-2 border-t pt-2 pb-0 px-4 justify-center",
  style: {"grid-template-columns":"50px repeat(10, 24px)"}
}
const _hoisted_11 = { class: "text-xs my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ticket?.ticket?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.ticket.ticket.isSestka)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.ticket.numbersArray, (number, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["korunka-circle-number", {'korunka-circle-number--win': _ctx.isWinningNumber(number)}])
                }, _toDisplayString(number), 3))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.ticket.ticket.isDvacetZa20)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.ticket.numbersArray, (numbersRow, rowIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: rowIndex,
                  class: "grid grid-cols-7 gap-4"
                }, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(rowIndex + 1) + ". ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numbersRow, (number, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["korunka-circle-number korunka-circle-number--sm", {'korunka-circle-number--win': _ctx.isWinningNumber(number)}])
                    }, _toDisplayString(number), 3))
                  }), 128))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.ticket.ticket.isHopTrop)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.ticket.numbersArray, (number, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["korunka-circle-number", {'korunka-circle-number--win': _ctx.isWinningNumber(number)}])
                }, _toDisplayString(number), 3))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.ticket.ticket.isKombi)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.ticket.numbersArray, (numbersRow, rowIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: rowIndex,
                  class: "grid gap-4",
                  style: _normalizeStyle(_ctx.kombiNumberRowGridTemplateColumns(numbersRow))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numbersRow, (number, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["korunka-circle-number", {'korunka-circle-number--win': _ctx.isWinningNumber(number)}])
                    }, _toDisplayString(number), 3))
                  }), 128))
                ], 4))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.ticket.ticket.isKombiPlus)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.ticket.numbersArray, (numbersRow, rowIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: rowIndex,
                    class: "grid gap-4",
                    style: _normalizeStyle(_ctx.kombiPlusNumberRowGridTemplateColumns(numbersRow))
                  }, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getLetterFromIndex(rowIndex)) + ". ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numbersRow, (number, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(["korunka-circle-number korunka-circle-number--xs", {'korunka-circle-number--win': _ctx.isWinningNumber(number)}])
                      }, _toDisplayString(number), 3))
                    }), 128))
                  ], 4))
                }), 128))
              ]),
              (_ctx.ticket.ticket.tutovka.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.messages.tutovka) + ": ", 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticket.ticket.tutovka, (number, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(["korunka-circle-number korunka-circle-number--xs", {'korunka-circle-number--win': _ctx.isWinningNumber(number)}])
                      }, _toDisplayString(number), 3))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}