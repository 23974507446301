import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BetTicketPartnerResourceGameType from '../BetTicketPartnerResourceGameType';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import TicketResourceCombinations from '../TicketResourceCombinations';
import {StringType} from '@designeo/apibundle-js';
import {BooleanType} from '@designeo/apibundle-js';


export const BetTicketPartnerResourcePropDef = {
  gameType: {
    type: new EntityType(BetTicketPartnerResourceGameType),
  },
  amountPaid: {
    type: new NumberType(),
  },
  amountPaidAll: {
    type: new NumberType(),
  },
  drawIds: {
    type: new ArrayType(new NumberType()),
  },
  numbers: {
    type: new ObjectType(),
  },
  tutovka: {
    type: new ArrayType(new NumberType()),
  },
  betsCombinations: {
    type: new ArrayType(new EntityType(TicketResourceCombinations)),
  },
  operationId: {
    type: new StringType(),
  },
  booster: {
    type: new BooleanType(),
  },
};
export default class GeneratedBetTicketPartnerResource extends createEntity(BetTicketPartnerResourcePropDef) {}
