import {createEntity} from '@designeo/apibundle-js';
import {DateTimeType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import JournalEventType from '../JournalEventType';
import {BooleanType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import FiscalCommands from '../FiscalCommands';


export const JournalDtoPropDef = {
  timeStamp: {
    type: new DateTimeType(),
  },
  cashierId: {
    type: new StringType(),
  },
  journalEventType: {
    type: new EntityType(JournalEventType),
  },
  clearJournal: {
    type: new BooleanType(),
  },
  jsonData: {
    type: new ObjectType(),
  },
  index: {
    type: new ArrayType(new NumberType()),
  },
  transactionId: {
    type: new StringType(),
  },
  documentType: {
    type: new EntityType(FiscalCommands),
  },
  shopCode: {
    type: new StringType(),
  },
  posCode: {
    type: new StringType(),
  },
};
export default class GeneratedJournalDto extends createEntity(JournalDtoPropDef) {}
