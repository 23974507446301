import {
  ArrayType,
  createEntity,
  DateTimeType,
  EntityType,
  NumberType,
  ObjectType,
  StringType,
} from '@designeo/apibundle-js';
import {
  groupBy,
  map,
  mapKeys,
} from 'lodash-es';

class BetTicket extends createEntity({
  betTime: {
    type: new DateTimeType(),
  },
  ticketNumber: {
    type: new NumberType(),
  },
  verificationCode: {
    type: new StringType(),
  },
  operationId: {
    type: new StringType(),
  },
}) { }

class KorunkaResponse extends createEntity({
  requestId: {
    type: new StringType(),
  },
  betTickets: {
    type: new ArrayType(new EntityType(BetTicket)),
  },
  printerData: {
    type: new ArrayType(new ObjectType<{ data: string, ticketNumber: number }>()),
  },
}) {
  sanitize() {
    this.printerData = map(this.printerData ?? [], (printerData) => {
      return {
        ...printerData,
        data: '',
      };
    });
  }

  get betTicketsByTicketNumber() {
    return mapKeys(this.betTickets ?? [], 'ticketNumber');
  }

  get betTicketsByOperationId() {
    return groupBy(this.betTickets ?? [], 'operationId');
  }

  get printerDataWithOperationId() {
    return map(this.printerData ?? [], (printerData) => {
      return {
        ...printerData,
        operationId: this.betTicketsByTicketNumber[printerData.ticketNumber]?.operationId ?? null,
      };
    });
  }
}

export default class KorunkaChargeDtoCustom extends createEntity({
  korunkaResponse: {
    type: new EntityType(KorunkaResponse),
  },
}) { }
