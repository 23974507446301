import {Constructor} from '@/Model/Entity/patches/patch';
import {ArrayType, ObjectType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentDto, {GroupBySets} from '../DocumentDto';
import DocumentItemDto from '../DocumentItemDto';
import ReferentialDocumentsDto from '../ReferentialDocumentsDto';
import {map} from 'lodash-es';

export const DocumentDtoPatch = <TBase extends Constructor>(superClass: TBase) =>
  class DocumentDtoPatch extends superClass {
    get items() {
      // @ts-ignore
      return new ArrayType(new EntityType(DocumentItemDto)).parse(this._data.items);
    }
    set items(val) {
      // @ts-ignore
      this._data.items = new ArrayType(new EntityType(DocumentItemDto)).format(val);
    }

    get originalDocument(): DocumentDto {
      // @ts-ignore
      return this.originalReferentialDocument?.transaction?.document ?? null;
    }

    get originalReferentialDocument(): ReferentialDocumentsDto {
      // @ts-ignore
      return new EntityType(ReferentialDocumentsDto).parse(this._data.originalReferentialDocument);
    }
    set originalReferentialDocument(val: ReferentialDocumentsDto) {
      // @ts-ignore
      this._data.originalReferentialDocument = new EntityType(ReferentialDocumentsDto).format(val);
    }

    get unreturnableGroups(): GroupBySets[] {
      // @ts-ignore
      return new ArrayType(new ObjectType()).parse(map(this._data.unreturnableGroups ?? [], (group) => <GroupBySets>{
        index: group.index,
        mainItem: new DocumentItemDto(group.mainItem),
        components: map(group.components ?? [], (component) => new DocumentItemDto(component)),
        mainItemIsEditableSet: group.mainItemIsEditableSet,
        editableItem: new DocumentItemDto(group.mainItem),
        editableItemIsEditableSet: group.editableItemIsEditableSet,
      }));
    }

    set unreturnableGroups(val: GroupBySets[]) {
      // @ts-ignore
      this._data.unreturnableGroups = new ArrayType(new ObjectType()).format(map(val, (group) => <GroupBySets>{
        index: group.index,
        mainItem: group.mainItem.toJson(),
        components: map(group.components ?? [], (component) => component.toJson()),
        mainItemIsEditableSet: group.mainItemIsEditableSet,
        editableItem: group.mainItem.toJson(),
        editableItemIsEditableSet: group.editableItemIsEditableSet,
      }));
    }
  };
