
import {
  createGridConfig,
  GridModel,
  HLGrid,
  HLGridPaginator,
} from '@designeo/vue-grid';
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import Qwerty from '@/Components/Qwerty/Qwerty.vue';
import {apiStockGet} from '@/Model/Action';
import InputBuffer from '@/Components/InputBuffer.vue';
import IconCube from '@/Components/Icons/IconCube.vue';
import Button from '@/Components/Button.vue';
import {useOnlineDetection} from '@/Helpers/onlineDetection';
import {
  map,
  debounce,
} from 'lodash-es';
import AsyncButton from '@/Components/AsyncButton.vue';
import IconStock from '@/Components/Icons/IconStock.vue';
import IconLoaderPulse from '@/Components/Icons/IconLoaderPulse.vue';
import StockFilterDto from '@/Model/Entity/StockFilterDto';
import {useModalKeyboard} from '@/Modules/Register/Modal/searchModal';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {emitTestEvent} from '@/Helpers/testEvent';
import {Autocomplete} from '@/constants/autocomplete';
import {mapStockList} from '@/Helpers/batch';
import {useQueue} from '@/Helpers/promiseQueue';
import {useStepTools} from '@/Modules/Workflow/Step/StepTools';
import {
  WorkflowStepWarehouseOperationGoodsList,
} from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WorkflowStepWarehouseOperationGoodsList';
import {WorkflowActions, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import WarehouseOperationGoodsVariations
  from '@/Modules/Workflow/Step/StepWarehouseOperationGoodsList/WarehouseOperationGoodsVariations.vue';
import {DocumentItemDto} from '@/Model/Entity';

export default defineComponent({
  name: 'ModalSearchProducts',
  components: {
    WarehouseOperationGoodsVariations,
    IconStock,
    AsyncButton,
    Button,
    IconCube,
    InputBuffer,
    Qwerty,
    Modal,
    HLGrid,
    HLGridPaginator,
    IconLoaderPulse,
  },
  setup() {
    const {online} = useOnlineDetection();
    const {showKeyboard, toggleShowKeyboard} = useModalKeyboard();
    const {runPromise, isRunning} = useQueue();
    const workflowStore = useWorkflowStore();
    const {workflowStep, currentWorkflow} = useStepTools<WorkflowStepWarehouseOperationGoodsList>(
      WorkflowStepTypes.WarehouseOperationGoodsList,
    );

    const modal = ref<ComponentPublicInstance | null>(null);

    const stockMap = ref({});
    const stockActive = ref(false);
    const innerIsLoading = ref(false);
    const loadFailed = ref(false);

    const close = async () => {
      await workflowStore.onEventInput({
        type: WorkflowActions.CANCEL,
      });
    };

    const gridModel = new GridModel('searchProducts', (async ({filters, sort, paginator}) => {
      if (!hasMeetMinLength.value) {
        try {
          return {
            paginator: {
              offset: paginator.offset,
              pageSize: paginator.pageSize,
              total: paginator.total,
            },
            data: [],
          };
        } finally {
          hideStock();
        }
      }

      let response = [];
      const text = inputBuffer.value;

      try {
        await runPromise(async () => {
          response = await workflowStep.value.searchArticleByText(text, {
            paginator: {offset: paginator.offset ?? 0},
            sort,
          });
        });

        return {
          paginator: {
            offset: 0,
            pageSize: paginator.offset + 50,
            total: 9999,
          },
          data: response,
        };
      } finally {
        if (stockActive.value && online.value && gridModel.loadedData.value.length) {
          fetchStock(response);
        } else {
          hideStock();
        }

        emitTestEvent(`${TestEvent.SEARCH_PRODUCTS}:${text}`);
      }
    }), {
      initialFetch: false,
      defaultPageSize: 4,
      defaultSort: [
        {
          key: 'documentItem.description',
          direction: 'ASC',
        },
      ],
    });

    const onProductSearch = async () => {
      try {
        innerIsLoading.value = true;
        await gridModel.update();
        loadFailed.value = false;
      } catch (e) {
        loadFailed.value = true;
        console.error(e);
      } finally {
        innerIsLoading.value = false;
      }
    };

    const inputBuffer = computed(() => {
      return workflowStep.value.searchString ?? '';
    });

    // eslint-disable-next-line no-unused-vars
    const debouncedProductSearch = debounce(onProductSearch, 500);

    onMounted(async () => {
      workflowStep.value.messageBus.addEventListener(WorkflowActions.ENTER, onProductSearch);
      if (workflowStep.value.isCurrentStateArticleSearch && inputBuffer.value) {
        await nextTick();
        onProductSearch();
      }
    });

    onBeforeUnmount(() => {
      if (!currentWorkflow.value) {
        return;
      }

      workflowStep.value.messageBus.removeEventListener(WorkflowActions.ENTER, onProductSearch);
    });

    watch(() => inputBuffer.value, () => {
      if (!workflowStep.value.isCurrentStateArticleSearch) {
        return;
      }

      if (hasMeetMinLength.value) {
        innerIsLoading.value = true;
      }

      debouncedProductSearch();
    }, {
      flush: 'post',
    });

    const selectItem = (documentItem: DocumentItemDto) => {
      workflowStep.value.processDocumentItemAdd(documentItem);
    };

    const onKeyboardInput = (key) => {
      workflowStore.onKeyboardInput({
        source: InputSource.KEYBOARD,
        keys: [key],
      });
    };

    const gridConfig = computed(() => {
      return createGridConfig({});
    });

    const fetchStock = async (data) => {
      if (!data.length) return;

      try {
        const stockList = await apiStockGet({
          input: new StockFilterDto({
            internalNumbers: map(data, 'internalNumber'),
          }),
        });

        stockMap.value = mapStockList(stockList);
        stockActive.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    const hideStock = () => {
      stockMap.value = {};
      stockActive.value = false;
    };

    const mapItems = (items) => {
      return map(items, (documentItem) => ({
        item: documentItem,
        ...(stockActive.value ? {
          stock: documentItem.getStockByStockMap(stockMap.value),
        } : {}),
      }));
    };

    const isOpen = computed(() => {
      return workflowStep.value.isCurrentStateArticleSearch;
    });

    const hasMeetMinLength = computed(() => {
      return inputBuffer.value.length >= Autocomplete.minProductSearchLength;
    });


    return {
      loadFailed,
      close,
      gridModel,
      gridConfig,
      selectItem,
      onKeyboardInput,
      online,
      fetchStock,
      hideStock,
      stockActive,
      mapItems,
      innerIsLoading,
      showKeyboard,
      toggleShowKeyboard,
      minProductSearchLength: Autocomplete.minProductSearchLength,
      isRunning,
      modal,
      isOpen,
      hasMeetMinLength,
      inputBuffer,
    };
  },
});
