import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {flow} from 'lodash-es';
import {workflowStepMixinPayment} from '../StepMixins/WorkflowStepMixinPayment';
import {workflowStepMixinSaveFinDocument} from '../StepMixins/WorkflowStepMixinSaveFinDocument';
import {workflowStepMixinNominals} from '../StepMixins/WorkflowStepMixinNominals';
import PaymentTransitionEnvelope from '@/Model/Entity/custom/PaymentTransitionEnvelope';

export class WorkflowStepTransactionToPayment extends flow(
  (ctor) => workflowStepMixinSaveFinDocument(ctor),
  (ctor) => workflowStepMixinNominals(ctor),
  (ctor) => workflowStepMixinPayment(ctor),
)(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.TransactionToPayment;
  }

  get type() {
    return WorkflowStepTransactionToPayment.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  createPaymentTransitionEnvelopeFromTransaction() {
    const amount = this.getFieldValue(WorkflowStepField.amount);

    if (amount) {
      this.paymentTransitionEnvelope = new PaymentTransitionEnvelope({
        currency: this.currency.symbol,
        value: parseFloat(amount),
      });
    } else {
      this.paymentTransitionEnvelope = new PaymentTransitionEnvelope({
        currency: this.currency.symbol,
        value: this.total,
      });
    }
  }

  async beforeEnter() {
    this.createPaymentTransitionEnvelopeFromTransaction();
    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {};
  }
}
