import {
  ComponentPublicInstance,
  nextTick,
  ref,
} from 'vue';
import {draf} from '@designeo/js-helpers/src/index';

export function isInView(el, intersectionRatio = 0.5) {
  return new Promise((resolve) => {
    const observer = new IntersectionObserver(function([entry]) {
      observer.disconnect();
      resolve(entry.intersectionRatio > intersectionRatio);
    });

    observer.observe(el);
  });
}

export const scrollToTop = (el: HTMLElement) => {
  nextTick(async () => {
    await draf(null);
    if (el) {
      el.scrollTop = 0;
    }
  });
};

export const scrollToBottom = (el: HTMLElement) => {
  nextTick(async () => {
    await draf(null);
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  });
};

export const scrollIntoView = async (el: Element | ComponentPublicInstance) => {
  await nextTick();
  await draf(null);

  if (!el) {
    return;
  }

  const element: Element = el instanceof Element ? el : el.$el;

  if (!element.scrollIntoView) {
    return;
  }

  if (await isInView(element, 0.8)) {
    return;
  }

  element.scrollIntoView(false);
};
