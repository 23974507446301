import GeneratedRevokeResponseDto from './generated/RevokeResponseDto';
import {
  CheckAndChargeRevokeResponseObjectTypes,
} from '@/constants/checkAndChargeResponseObjectTypes';
import PackReturnDto from '@/Model/Entity/PackReturnDto';
import TicketReturnDto from '@/Model/Entity/TicketReturnDto';
import DocumentDto from '@/Model/Entity/DocumentDto';
import DocumentItemDto from '@/Model/Entity/DocumentItemDto';
import GIPRevokeDto from '@/Model/Entity/GIPRevokeDto';
import VoucherRevokeDto from '@/Model/Entity/VoucherRevokeDto';
import KorunkaRevokeDto from '@/Model/Entity/KorunkaRevokeDto';
import KorunkaErrorDto from '@/Model/Entity/KorunkaErrorDto';
import {map, uniqBy} from 'lodash-es';

export default class RevokeResponseDto extends GeneratedRevokeResponseDto {
  get entity() {
    switch (<CheckAndChargeRevokeResponseObjectTypes> this.responseObjectType) {
    case CheckAndChargeRevokeResponseObjectTypes.PackReturnDto:
      return new PackReturnDto(this.responseObject);
    case CheckAndChargeRevokeResponseObjectTypes.TicketReturnDto:
      return new TicketReturnDto(this.responseObject);

    case CheckAndChargeRevokeResponseObjectTypes.GIPRevokeDto:
      return new GIPRevokeDto(this.responseObject);

    case CheckAndChargeRevokeResponseObjectTypes.VoucherRevokeDto:
      return new VoucherRevokeDto(this.responseObject);

    case CheckAndChargeRevokeResponseObjectTypes.KorunkaRevokeDto:
      return new KorunkaRevokeDto(this.responseObject);

    case CheckAndChargeRevokeResponseObjectTypes.KorunkaErrorDto:
      return new KorunkaErrorDto(this.responseObject);
    }
  }

  get isSuccessful() {
    return this.success;
  }

  get errorReason() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCashierMessage ??
        this.errorMessage?.errorCashierMessage ??
        null;
    }

    if (this.entity instanceof KorunkaErrorDto) {
      return this.entity.errorReason ?? null;
    }

    return null;
  }

  get errorReasonForCustomer() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCustomerMessage ??
        this.errorMessage?.errorCustomerMessage ??
        null;
    }

    if (this.entity instanceof KorunkaErrorDto) {
      return this.entity.errorReasonForCustomer ?? null;
    }

    return null;
  }

  process(document: DocumentDto, itemAtIndex: number, displayItem: DocumentItemDto) {
    const documentItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (!this.isSuccessful && this.cannotBeRevoked) {
      documentItem.setItemStornoDisabledWithinContext(
        document,
        itemAtIndex,
        true,
      );
    }

    if (!this.isSuccessful) {
      return;
    }

    document.sanitizeContentOfOwner(documentItem.uniqueIdentifier);
  }

  hasErrorFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(editableItem.checkRequest.metadata);
      const entityOperationIds: string[] = editableItem?.chargeRequest?.korunkaRequest?.bondsToEntities?.[korunkaEntity.id] ?? [];
      const errorOperationIds = map(this.entity.errors ?? [], ({operationId}) => operationId);
      const hasError = entityOperationIds.some((operationId) => errorOperationIds.includes(operationId));

      return hasError;
    }

    return false;
  }

  getErrorMessageDetailFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      return `${this.errorReason}`;
    }

    return !this.errorReason ?
      `${editableItem.description}` :
      `${editableItem.description}, ${this.errorReason}`;
  }
}
