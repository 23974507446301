import GeneratedReferentialDocumentsDto from './generated/ReferentialDocumentsDto';
import {
  filter,
  find,
  reduce,
  some,
  sumBy,
} from 'lodash-es';
import DocumentItemDto from '@/Model/Entity/DocumentItemDto';
import DocumentDto from '@/Model/Entity/DocumentDto';

export default class ReferentialDocumentsDto extends GeneratedReferentialDocumentsDto {
  get isCanceledSellByFullStorno() {
    if (this.transaction.document.isModeStorno) {
      return false;
    }

    return some(this.referentialDocuments, (transaction) => {
      return transaction.document.isModeFullStorno;
    });
  }

  get isCanceledSell() {
    if (this.transaction.document.isModeStorno) {
      return false;
    }

    // TODO: how to identify partially canceled receipt, that can be cancelled further

    return some(this.referentialDocuments, (transaction) => {
      return transaction.document.isModeStorno;
    });
  }

  get sellCancelReferentialDocuments() {
    if (!this.isCanceledSell) {
      return [];
    }


    return filter(this.referentialDocuments, (transaction) => {
      return transaction.document.isModeStorno;
    });
  }

  get cancelSellReferentialDocument() {
    if (!this.transaction.document.isModeStorno) {
      return null;
    }

    return this.originalDocument;
  }

  get transactionDocumentWithCanceledItems() {
    if (!this.referentialDocuments.length) {
      return this.transaction.document;
    }

    const originalEntity: DocumentDto = this.transaction.document.clone();
    const artificialEntity: DocumentDto = this.transaction.document.clone();

    for (const item of originalEntity.items) {
      item.originalUniqueIdentifier = item.uniqueIdentifier;
    }

    for (const item of artificialEntity.items) {
      item.originalUniqueIdentifier = item.uniqueIdentifier;
    }

    for (const {editableItem, index} of artificialEntity.itemsGroupedBySets) {
      editableItem.setQuantityWithinContext(
        artificialEntity,
        index,
        editableItem.quantity + this.getEditableItemRestStornoQuantity(editableItem),
      );
    }

    return new DocumentDto({
      ...originalEntity.toJson(),
      originalReferentialDocument: new ReferentialDocumentsDto({
        referentialDocuments: [],
        originalDocument: null,
        transaction: {
          document: artificialEntity.toJson(),
        },
      }).toJson(),
    });
  }

  get key() {
    return this.transaction.key;
  }

  isSameReferentialDocument(referentialDocument: ReferentialDocumentsDto) {
    const referentialDocumentId = referentialDocument?.transaction?.document?.header?.uniqueidentifier;

    return referentialDocumentId === this.transaction.document.header.uniqueidentifier;
  }

  findOriginalItem(arr, item: DocumentItemDto, {key = 'uniqueIdentifier'} = {}): DocumentItemDto {
    return find(arr, {
      [key]: item.originalUniqueIdentifier,
    });
  }

  getOriginalEditableItemByEditableItem(item: DocumentItemDto): DocumentItemDto {
    if (!item) return null;

    return this.findOriginalItem(this.transaction.document.items, item) ?? null;
  }

  getEditableItemsFromReferentialDocumentsByEditableItem(item: DocumentItemDto): Array<DocumentItemDto> {
    return reduce(this.referentialDocuments, (acc, referentialDocument) => {
      const originalItem = this.findOriginalItem(
        referentialDocument.document.items,
        item,
        {key: 'originalUniqueIdentifier'},
      );

      if (originalItem) {
        acc.push(originalItem.clone());
      }

      return acc;
    }, []);
  }

  getEditableItemRestStornoQuantity(item: DocumentItemDto) {
    const editableItem = this.getOriginalEditableItemByEditableItem(item);

    if (!editableItem) {
      return null;
    }

    if (!this.getEditableItemsFromReferentialDocumentsByEditableItem(item).length) {
      return editableItem.quantity * -1;
    }

    return editableItem.quantity * -1 - sumBy(this.getEditableItemsFromReferentialDocumentsByEditableItem(item), (item) => {
      return item.quantity;
    });
  }
}
