import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const InStoreSearchDtoPropDef = {
  internalNumber: {
    type: new StringType(),
  },
  batch: {
    type: new StringType(),
  },
};
export default class GeneratedInStoreSearchDto extends createEntity(InStoreSearchDtoPropDef) {}
