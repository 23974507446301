import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {CheckAndChargeProviders} from '@/constants/checkAndChargeProviders';
import {CheckAndChargeServiceTypes} from '@/constants/checkAndChargeServiceTypes';
import {api3rdPartyCheck} from '@/Model/Action';
import {DocumentDto, DocumentItemDto} from '@/Model/Entity';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {markRaw} from 'vue';
import {BitcoinDirections} from '@/constants/bitcoinDirections';
import EasyCoinOrderDto from '@/Model/Entity/EasyCoinOrderDto';

export class WorkflowStepApiBTCCheck extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiBTCCheck;
  }

  get type() {
    return WorkflowStepApiBTCCheck.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get directionByWorkflowCode(): BitcoinDirections {
    return {
      [WorkflowCodes.btcBuy]: BitcoinDirections.Buy,
      [WorkflowCodes.btcSell]: BitcoinDirections.Sell,
    }[this.code];
  }

  async beforeEnter() {
    const doc = DocumentDto.createEmptySellDocument();

    const documentItem = new DocumentItemDto(
      {
        serviceType: CheckAndChargeServiceTypes.BTC,
        btcPaymentNumber: this.data?.[WorkflowStepField.orderNumber],
        btcPin: this.data?.[WorkflowStepField.orderPin],
      },
    );

    doc.addItem(documentItem);

    const request = documentItem.ensureCheckAndChargeCheckRequest(doc, 0);

    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      this.dataSetter(WorkflowStepField.btcDisplayError, () => false);

      const response = await api3rdPartyCheck({
        input: request.toJson(),
        params: {
          provider: this.step.isMock ? CheckAndChargeProviders.MOCK_EASYCOIN : CheckAndChargeProviders.EASYCOIN,
          serviceType: CheckAndChargeServiceTypes.BTC,
          ...documentItem.getCommonCheckAndChargeParams(doc),
        },
      });

      documentItem.setCheckResponseWithinContext(
        doc,
        0,
        response,
      );

      this.dataSetter(WorkflowStepField.document, () => doc.toJson());

      if (!response.success) {
        throw new Error('requestFailed');
      }

      if ((<EasyCoinOrderDto>response.entity).direction !== this.directionByWorkflowCode) {
        throw new Error('wrongDirection');
      }

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.dataSetter(WorkflowStepField.btcDisplayError, () => true);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.SET_ACTIVE_STEP_BY_CODE, {
        detail: {
          code: WorkflowStepTypes.BTCEnterOrderNumber,
        },
      }));
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
