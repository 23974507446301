import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "px-4",
  style: {"width":"350px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_Countdown = _resolveComponent("Countdown")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modalRef",
    open: _ctx.modal.open,
    name: 'modal-alert',
    type: "lg"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ModalDetachedClose, { onClose: _ctx.close }, null, 8, ["onClose"]),
        _createVNode(_component_ModalDetachedBody, {
          class: "pt-2",
          type: _ctx.ConfirmTypes.error
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.modal.message), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createVNode(_component_ModalDetachedButtons, {
          class: "gap-2",
          single: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Countdown, {
              class: "text-sm",
              milliseconds: _ctx.modal.autoClose,
              withLead: false,
              xs: ""
            }, null, 8, ["milliseconds"]),
            _createVNode(_component_AsyncButton, {
              test: 'modalConfirm',
              class: "btn-md btn-outline-basic",
              onClick: () => _ctx.close()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('action.close')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}