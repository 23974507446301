export enum DocumentModeTypes {
  SALE = 'Sale',
  STORNO = 'Storno',
  PARTIAL_STORNO = 'PartialStorno',
  TRAINING = 'Training'
}

export enum DocumentCreateMode {
  print = 'print',
  createAndPrint = 'createAndPrint',
  create = 'create',
}
