import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "flex flex-col flex-grow" }
const _hoisted_4 = { class: "text-success text-center mb-4" }
const _hoisted_5 = { class: "w-3/4 mx-auto" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = {
  key: 1,
  class: "font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_HLStep = _resolveComponent("HLStep")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({workflowStep, currentWorkflow}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_IconCheck, {
                rounded: "",
                class: "mx-auto w-12 h-12 mb-4"
              }),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(workflowStep.step.successMessage), 1),
                (workflowStep.amount < 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.interpolate(workflowStep.step.negativeAmountMessage, {amount: Math.abs(workflowStep.amount)})), 1))
                  : _createCommentVNode("", true),
                (workflowStep.amount > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.interpolate(workflowStep.step.positiveAmountMessage, {amount: Math.abs(workflowStep.amount)})), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_AsyncButton, {
              test: 'wfTransitionsNext',
              onClick: () => currentWorkflow.requestNextStep(),
              "data-test": "wfTransitionsNext",
              class: "btn-success w-1/2 mx-auto mt-4 py-2.5 px-7"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconCheck, { class: "inline h-3" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('workflow.stepUI.finish')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}