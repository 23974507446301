import {ArticleGroups, ArticleGroupTypes} from '@designeo/pos-promotion-engine';
import {PromoBlock} from '@designeo/pos-promotion-engine/src/blocks/Promo';
import GeneratedPromotionBulkDto from './generated/PromotionBulkDto';
import {
  map,
  fromPairs,
  flatMap,
} from 'lodash-es';

export default class PromotionBulkDto extends GeneratedPromotionBulkDto {
  get definition(): Array<PromoBlock['Definition'] & {validFrom: Date, validTill: Date}> {
    return flatMap(this.promotions, (promo) => {
      try {
        return map(JSON.parse(promo.promotionScript) ?? [], (script) => ({
          ...script,
          validFrom: promo.validFrom,
          validTill: promo.validTill,
        }));
      } catch (err) {
        console.warn(`Could not parse promo "${promo.name}"`);
        return [];
      }
    });
  }

  get articleGroups(): ArticleGroups {
    return fromPairs(map(this.promotionsList, (list) => {
      try {
        const definition = JSON.parse(list.listDefinition);
        return list.code ? [list.code, definition] : null;
      } catch (err) {
        console.warn(`Could not parse articleGroups "${list.name}"`);
        return null;
      }
    }));
  }
}
