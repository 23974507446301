
import {
  computed,
  defineComponent,
} from 'vue';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLStep,
  HLInput,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import FormInput from '@/Components/FormInput.vue';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';
import InputBuffer from '@/Components/InputBuffer.vue';
import {some, map} from 'lodash-es';
import {WorkflowStepEnterLotterySum} from '../steps';

export default defineComponent({
  name: 'StepEnterLotterySum',
  components: {
    InputBuffer,
    HLStep,
    HLInput,
    FormInput,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.EnterLotterySum;

    const {workflowStep, isActiveField} = useStepTools<WorkflowStepEnterLotterySum>(stepType);

    const isActiveSumFieldTitle = computed(() => {
      return some(map(workflowStep.value.fields, (field) => isActiveField(field)));
    });

    const hasFilledAllSumFields = computed(() => {
      return workflowStep.value.hasFilledAllSumFields;
    });

    const transitionCanGoNext = computed(() => {
      return hasFilledAllSumFields.value && !workflowStep.value.hasValidationError;
    });

    const showResultMessage = computed(() => {
      return workflowStep.value.showResultMessage;
    });

    const lotterySumSatisfied = computed(() => {
      return workflowStep.value.fieldsValueSum === workflowStep.value.lotteryTurnovers.totalAmount;
    });

    return {
      stepType,
      hasFilledAllSumFields,
      isActiveSumFieldTitle,
      transitionCanGoNext,
      lotterySumSatisfied,
      showResultMessage,
    };
  },
});
