import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleFilterDescriptionDto from '../ArticleFilterDescriptionDto';


export const ArticleListFilterDtoPropDef = {
  articles: {
    type: new ArrayType(new EntityType(ArticleFilterDescriptionDto)),
  },
};
export default class GeneratedArticleListFilterDto extends createEntity(ArticleListFilterDtoPropDef) {}
