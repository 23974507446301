import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import SendPinDto from '@/Model/Entity/SendPinDto';
import {apiCustomersGenerateAndSendPin} from '@/Model/Action';

export class WorkflowStepApiGenerateAndSendPin extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiGenerateAndSendPin;
  }

  get type() {
    return WorkflowStepApiGenerateAndSendPin.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get customerNumber() {
    return this.data[WorkflowStepField.customerNumber] ?? this.params.customerNumber;
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      if (this.customerNumber) {
        await apiCustomersGenerateAndSendPin({
          input: new SendPinDto({smsTemplate: this.step.smsTemplate}),
          params: {
            customerNumber: this.customerNumber,
          },
        });
      }
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.USER_CREATE_FAILED,
          value: e,
        },
      }));
      throw e;
    } finally {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    }
  }

  get transitions() {
    return {};
  }
}
