
import {
  computed,
  defineComponent,
  ref,
} from 'vue';
import Modal from '@/Components/Modal.vue';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import {ZLayer} from '@/constants/zLayer';
import {useSecondaryDisplay} from '@/Helpers/secondaryDisplay';
import {usePosCoreNotAvailableStore} from '@/Modules/Core/store/PosCoreNotAvailableStore';
import {quitApp, reloadApp} from '@/Helpers/app';

export default defineComponent({
  name: 'ModalPosCoreNotAvailable',
  components: {
    ModalDetachedButtons,
    AsyncButton,
    Modal,
  },
  props: {
    isElectron: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const posCoreNotAvailableStore = usePosCoreNotAvailableStore();
    const loading = ref(false);
    const {isSecondaryDisplay} = useSecondaryDisplay();

    const open = computed(() => {
      if (isSecondaryDisplay) {
        return false;
      }

      return posCoreNotAvailableStore.active.value;
    });

    const quit = () => new Promise(() => {
      quitApp();
    });

    const restart = () => new Promise(() => {
      reloadApp();
    });

    return {
      open,
      loading,
      ZLayer,
      quit,
      restart,
    };
  },
});
