import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DrawPartnerResourceType from '../DrawPartnerResourceType';
import DrawPartnerResourceStatus from '../DrawPartnerResourceStatus';
import {DateTimeType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';


export const DrawPartnerResourcePropDef = {
  id: {
    type: new NumberType(),
  },
  type: {
    type: new EntityType(DrawPartnerResourceType),
  },
  status: {
    type: new EntityType(DrawPartnerResourceStatus),
  },
  deadlineForBets: {
    type: new DateTimeType(),
  },
  drawNumbers: {
    type: new ArrayType(new NumberType()),
  },
};
export default class GeneratedDrawPartnerResource extends createEntity(DrawPartnerResourcePropDef) {}
