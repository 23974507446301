import {useAuthStore} from './store/AuthStore';
import routes from './routes.pos';
import {isNil} from 'lodash-es';
import {amIOnline} from '@/Helpers/onlineDetection';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {
  aclMetaToKeys,
  useAcl,
} from '@/Helpers/acl';
import {InactivityCondition} from '@/Helpers/inactivity';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem & {
  appId: any,
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerAuthPos(ctx: RequiredContext) {
  const acl = useAcl();

  await ctx.registerRoutes(routes, {
    layout: 'login',
  });

  await ctx.registerHookBeforeEach((to, from, next) => {
    if (isNil(to.meta.authorization) || to.meta.authorization === true) {
      if (!useAuthStore().isLoggedIn.value) {
        next({name: 'unauthorized', query: {fullPath: to.fullPath}});
      } else {
        next();
      }
    } else {
      next();
    }
  });

  await ctx.registerHookBeforeEach(async (to, from, next) => {
    if ((to.meta.onlineMandatory ?? false) && !(await amIOnline())) {
      // @ts-ignore
      next(from.path === '/' ? {name: 'login'} : false);
      // @ts-ignore
      useCoreStore().alert(ctx.i18n.global.t('general.onlineMandatory'));
    } else {
      next();
    }
  });

  await ctx.registerHookBeforeEach(async (to, from, next) => {
    const keys = aclMetaToKeys(to);
    const strategy = <'every' | 'some'>(to.meta?.aclStrategy ?? 'every');

    if (keys && !acl(keys, {method: strategy})) {
      // @ts-ignore
      next(from.path === '/' ? {name: 'login'} : false);
      // @ts-ignore
      useCoreStore().alert(ctx.i18n.global.t('general.accessDenied'));
    } else {
      next();
    }
  });

  await ctx.registerHookBeforeEach(async (to, from, next) => {
    if (
      to.name === 'login' ||
      to.name === 'pin-change' ||
      (to.name === 'register' && to.redirectedFrom?.name === 'register')
    ) {
      next();
    } else if ((useAuthStore().activePerson.value?.forcePinChange ?? false) && await amIOnline()) {
      next({name: 'pin-change'});
    } else {
      next();
    }
  });

  await ctx.registerHookAfterEach(InactivityCondition.inactivityRouterHook);

  return {};
}
