export enum PosConfigurationState {
  SELECT_LANGUAGE,
  ENTER_CODE,
  INSTALL_IN_PROGRESS,
  SUCCESS,
  FAILED,
}

export enum PosConfigurationActions {
  ADD_NUMBER='addNumber',
  ENTER='enter',
  BACKSPACE='backspace',
  CLEAR='clear',
  CANCEL='cancel',
}

export type PosConfigurationInputEvent = {
  type: PosConfigurationActions,
  value?: any
}

export interface PosConfiguration {
  language: string,
  code: string,
}

export enum PosConfigurationEvent {
  LANGUAGE_SELECTED = 'setLanguage',
  EXIT = 'exit',
}
