import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import AccountTransactionDto from '../AccountTransactionDto';


export const ClubAccountTransactionsDtoPropDef = {
  clubCode: {
    type: new StringType(),
  },
  dtos: {
    type: new ArrayType(new EntityType(AccountTransactionDto)),
  },
};
export default class GeneratedClubAccountTransactionsDto extends createEntity(ClubAccountTransactionsDtoPropDef) {}
