
import {
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
} from 'vue';
import {useI18n} from 'vue-i18n';
import {TranslatedString} from '@/Model/Entity';
import PhIcon from '@/Components/PhIcon.vue';
import {useKeyboard} from '@/Helpers/keyboard';
import {AppVersion} from '@/constants/appVersion';
import {useAppVersion} from '@/Helpers/appVersion';

export default defineComponent({
  name: 'LayoutIframeWrapper',
  components: {PhIcon},
  props: {
    appVersion: {
      type: String as PropType<AppVersion>,
      required: true,
    },
    name: {
      type: Object as PropType<TranslatedString>,
      required: false,
      default: null,
    },
    url: {
      type: Object as PropType<URL>,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    amIClosed: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const {registerEscapeKey} = useKeyboard();
    const view = ref<HTMLIFrameElement>(null);
    const title = props.name?.[i18n.locale.value] ?? props.url.host;

    useAppVersion(props.appVersion);

    onMounted(async () => {
      await nextTick();
      view.value.focus();
    });

    const reloadIframe = () => {
      const src = view.value.src;
      view.value.src = '';
      view.value.src = src;
    };

    registerEscapeKey((ev: Event) => {
      ev.preventDefault();
      props.close();
    }, {
      keyUp: true,
      muted: () => props.amIClosed(),
    });

    return {
      title,
      view,
      reloadIframe,
    };
  },
});
