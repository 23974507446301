import GeneratedOrderItemDto from './generated/OrderItemDto';

export default class OrderItemDto extends GeneratedOrderItemDto {
  public static get configurationStore() {
    return (require('@/Modules/Core/store/ConfigurationStore')).useConfigurationStore();
  }

  get unitByEnum() {
    return OrderItemDto.configurationStore.unitsByCode.value?.[this.unit]?.text ?? this.unit;
  }
}
