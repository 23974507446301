import {TestEvent} from './testEvents';

export enum KorunkaTicketNumbers {
  SESTKA_WIN = 104,
  HOPTROP_LOSS = 115,
  KOMBI_CANCELED = 127,
  KOMBI_PLUS_WIN = 134,
  DVACETZA20_EVALUATING = 172,
}

export const KorunkaVerificationCode = '123456';

// NOTE: import from WorkflowStepTypes breaks e2e ts compilation
export enum KorunkaStepTypes {
  KorunkaScanTicket = 'KorunkaLotteryScanTicket',
  KorunkaScanCard = 'KorunkaLotteryScanCard',
  KorunkaCardOverview = 'KorunkaLotteryCardOverview',
  KorunkaSetup = 'KorunkaLotterySetup',
  KorunkaNumbers = 'KorunkaLotteryNumbers',
  KorunkaDeposit = 'KorunkaLotteryDeposit',
  KorunkaDraw = 'KorunkaLotteryDraw',
  KorunkaOverview = 'KorunkaLotteryOverview',
  KorunkaEnterCode = 'KorunkaLotteryEnterCode',
  KorunkaTicketResult = 'KorunkaLotteryTicketResult',
  ApiKorunkaLotteryGetTicket = 'ApiKorunkaLotteryGetTicket',
  ApiKorunkaLotteryGetPayoutArticle = 'ApiKorunkaLotteryGetPayoutArticle',
}

// NOTE: import from Helpers breaks e2e ts compilation
export enum KorunkaEntityTypes {
  Sestka = 'SESTKA',
  DvacetZa20 = 'DVACETZA20',
  HopTrop = 'HOPTROP',
  Kombi = 'KOMBI',
  KombiPlus = 'KOMBI_PLUS',
  Ticket = 'TICKET',
  ScanTicket = 'SCAN_TICKET',
  PreparedTicket = 'PREPARED_TICKET',
  Card = 'CARD'
}

export const createKorunkaStepReadyMessage = (stepType: KorunkaStepTypes, korunkaType: KorunkaEntityTypes = null) => {
  return [
    TestEvent.WORKFLOW_STEP_AFTER_ENTER,
    stepType,
    korunkaType,
  ].filter(Boolean).join(':');
};

export const scanTicketMockBodyByGame = (game: KorunkaEntityTypes) => {
  switch (game) {
  case KorunkaEntityTypes.Sestka:
    return [
      {
        'gameType': 'SESTKA',
        'amountPaid': 35,
        'amountPaidAll': 105,
        'drawIds': [
          1,
          2,
          3,
        ],
        'numbers': {
          'A': [
            1,
            19,
            32,
            35,
            37,
            48,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'SESTKA',
        'amountPaid': 35,
        'amountPaidAll': 105,
        'drawIds': [
          1,
          2,
          3,
        ],
        'numbers': {
          'A': [
            6,
            19,
            29,
            41,
            45,
            46,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'SESTKA',
        'amountPaid': 35,
        'amountPaidAll': 105,
        'drawIds': [
          1,
          2,
          3,
        ],
        'numbers': {
          'A': [
            7,
            23,
            26,
            30,
            34,
            42,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'SESTKA',
        'amountPaid': 35,
        'amountPaidAll': 105,
        'drawIds': [
          1,
          2,
          3,
        ],
        'numbers': {
          'A': [
            2,
            10,
            12,
            36,
            43,
            44,
          ],
        },
        'booster': false,
      },
    ];
  case KorunkaEntityTypes.DvacetZa20:
    return [
      {
        'gameType': 'DVACETZA20',
        'amountPaid': 80,
        'amountPaidAll': 160,
        'drawIds': [
          1,
          2,
        ],
        'numbers': {
          'A': [
            8,
            9,
            16,
            28,
            34,
            47,
          ],
          'B': [
            14,
            15,
            26,
            32,
            34,
            49,
          ],
          'C': [
            5,
            14,
            22,
            37,
            42,
            46,
          ],
          'D': [
            12,
            15,
            21,
            26,
            33,
            47,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'DVACETZA20',
        'amountPaid': 60,
        'amountPaidAll': 240,
        'drawIds': [
          1,
          2,
        ],
        'numbers': {
          'A': [
            8,
            9,
            16,
            28,
            34,
            47,
          ],
          'B': [
            14,
            15,
            26,
            32,
            34,
            49,
          ],
          'C': [
            5,
            14,
            22,
            37,
            42,
            46,
          ],
          'D': [
            12,
            15,
            21,
            26,
            33,
            47,
          ],
        },
        'booster': true,
      },
    ];
  case KorunkaEntityTypes.HopTrop:
    return [
      {
        'gameType': 'HOPTROP',
        'amountPaid': 50,
        'amountPaidAll': 50,
        'drawIds': [1],
        'numbers': {
          'A': [
            14,
            26,
            29,
            37,
            46,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'HOPTROP',
        'amountPaid': 50,
        'amountPaidAll': 50,
        'drawIds': [1],
        'numbers': {
          'A': [
            5,
            19,
            32,
            44,
            49,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'HOPTROP',
        'amountPaid': 50,
        'amountPaidAll': 50,
        'drawIds': [1],
        'numbers': {
          'A': [
            8,
            11,
            26,
            32,
            38,
          ],
        },
        'booster': false,
      },
      {
        'gameType': 'HOPTROP',
        'amountPaid': 50,
        'amountPaidAll': 50,
        'drawIds': [1],
        'numbers': {
          'A': [
            3,
            4,
            19,
            42,
            47,
          ],
        },
        'booster': false,
      },
    ];
  case KorunkaEntityTypes.Kombi:
    return [
      {
        'gameType': 'KOMBI',
        'amountPaid': 13,
        'amountPaidAll': 13,
        'drawIds': [1],
        'numbers': {
          'A': [
            1,
            17,
            33,
          ],
        },
        'betsCombinations': [
          {
            'combinationType': 'SINGLE',
            'count': 3,
            'amount': 1,
          },
          {
            'combinationType': 'QUADRUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'DOUBLE',
            'count': 3,
            'amount': 0,
          },
          {
            'combinationType': 'QUINTUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'TRIPLE',
            'count': 1,
            'amount': 10,
          },
        ],
        'booster': false,
      },
      {
        'gameType': 'KOMBI',
        'amountPaid': 13,
        'amountPaidAll': 13,
        'drawIds': [1],
        'numbers': {
          'A': [
            2,
            20,
            42,
          ],
        },
        'betsCombinations': [
          {
            'combinationType': 'SINGLE',
            'count': 3,
            'amount': 0,
          },
          {
            'combinationType': 'QUADRUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'DOUBLE',
            'count': 3,
            'amount': 1,
          },
          {
            'combinationType': 'QUINTUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'TRIPLE',
            'count': 1,
            'amount': 10,
          },
        ],
        'booster': false,
      },
    ];
  case KorunkaEntityTypes.KombiPlus:
    return [
      {
        'gameType': 'KOMBI_PLUS',
        'amountPaid': 40,
        'amountPaidAll': 40,
        'drawIds': [1],
        'numbers': {
          'A': [13],
          'B': [
            26,
            36,
          ],
          'C': [
            25,
            35,
          ],
          'D': [8],
        },
        'betsCombinations': [
          {
            'combinationType': 'SINGLE',
            'count': 4,
            'amount': 4,
          },
          {
            'combinationType': 'QUADRUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'DOUBLE',
            'count': 6,
            'amount': 4,
          },
          {
            'combinationType': 'QUINTUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'TRIPLE',
            'count': 4,
            'amount': 0,
          },
        ],
        'booster': false,
      },
      {
        'gameType': 'KOMBI_PLUS',
        'amountPaid': 36,
        'amountPaidAll': 36,
        'drawIds': [1],
        'numbers': {
          'A': [11],
          'B': [
            11,
            15,
          ],
          'C': [
            20,
            38,
          ],
          'D': [47],
        },
        'betsCombinations': [
          {
            'combinationType': 'SINGLE',
            'count': 4,
            'amount': 4,
          },
          {
            'combinationType': 'QUADRUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'DOUBLE',
            'count': 5,
            'amount': 4,
          },
          {
            'combinationType': 'QUINTUPLE',
            'count': 0,
            'amount': 0,
          },
          {
            'combinationType': 'TRIPLE',
            'count': 2,
            'amount': 0,
          },
        ],
        'booster': false,
      },
    ];
  default:
    return;
  }
};
