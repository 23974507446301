import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';


export const NearbyShopStockDtoPropDef = {
  shopCode: {
    type: new StringType(),
  },
  shopName: {
    type: new StringType(),
  },
  shopCity: {
    type: new StringType(),
  },
  shopStreet: {
    type: new StringType(),
  },
  count: {
    type: new NumberType(),
  },
  inTransitCount: {
    type: new NumberType(),
  },
  unitOfQuantityCode: {
    type: new StringType(),
  },
};
export default class GeneratedNearbyShopStockDto extends createEntity(NearbyShopStockDtoPropDef) {}
