import {AbstractPromoFlow, createNewReactive} from './AbstractPromoFlow';
import {PromoInteractionFlowTypes} from './index';
import {useConfigurationStore} from './../../Core/store/ConfigurationStore';
import {RegisterState} from './../types';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import PromoPointsBurningButton from '@/Model/Entity/custom/PromoPointsBurningButton';
import {
  PromotionAvailablePointsBurning,
  PromotionMetaType,
} from '@designeo/pos-promotion-engine';
import {action, getter} from '@designeo/vue-helpers';
import {
  filter,
  take,
} from 'lodash-es';
import {broadcastIO, BroadcastIOChannels} from '@/Helpers/broadcastIO';
import {QuickCallTypes} from '../QuickCall/types';

export class PointsBurningFlow extends AbstractPromoFlow {
  constructor(
    public availablePointsBurning: PromotionAvailablePointsBurning,
    public nextState: RegisterState,
  ) {
    super(nextState);
  }

  async init() {
    broadcastIO.addEventListener(BroadcastIOChannels.POINTS_BURNING_POINTS_REQUEST, this.onPointsRequest);
    broadcastIO.addEventListener(BroadcastIOChannels.POINTS_BURNING_SELECTED, this.onPointsSelected, {once: true});
    this.updatePointsOptions();
    await super.init();
  }

  static new = createNewReactive<PointsBurningFlow>();

  onPointsRequest = action(() => {
    this.updatePointsOptions();
  });

  onPointsSelected = action((event: CustomEvent<number>) => {
    this.burnPoints(event.detail);
  });

  updatePointsOptions() {
    broadcastIO.postMessage(BroadcastIOChannels.POINTS_BURNING_POINTS_OPTIONS, this.pointsBurningOptions.value);
  }

  pointsBurningOptions = getter(() => {
    const localCurrency = useConfigurationStore().localCurrency.value;
    const balance = useRegisterStore().sellDocument.value.balance;
    const acceptableNominals = filter(localCurrency.nominals, (val) => val >= localCurrency.smallestAcceptedNominal);

    const maxValue = Math.min(this.availablePointsBurning.maxValue, balance);
    return [maxValue, ...take(filter(acceptableNominals, (val) => val < maxValue), 5)];
  })

  quickCallButtons = getter(() => {
    const currency = useConfigurationStore().localCurrency.value;
    return this.pointsBurningOptions.value.map((value) => new PromoPointsBurningButton({
      type: QuickCallTypes.BurningPoints,
      code: `${value}`,
      value: value,
      label: {
        cs: `${value} ${currency.localSymbol}`,
        en: `${value} ${currency.localSymbol}`,
        de: `${value} ${currency.localSymbol}`,
        sk: `${value} ${currency.localSymbol}`,
      },
      // isCustomButton: true,
      style: {},
    }));
  })

  async destroy() {
    const store = useRegisterStore();
    store.setPayment(null);
    broadcastIO.removeEventListener(BroadcastIOChannels.POINTS_BURNING_POINTS_REQUEST, this.onPointsRequest);
    broadcastIO.removeEventListener(BroadcastIOChannels.POINTS_BURNING_SELECTED, this.onPointsSelected);
    await super.destroy();
  }

  async burnPoints(value: number) {
    const store = useRegisterStore();
    await store.withAppLoader(async () => {
      store.sellDocument.value.promotions.push({
        type: PromotionMetaType.POINTS_BURN,
        clubCode: this.availablePointsBurning.clubCode,
        value,
      });
      await store.fetchPromoEngineResult({triggerImmediatePromotions: false});
      await this.destroy();
    });
  }

  serialize() {
    return {
      type: PromoInteractionFlowTypes.POINTS_BURNING_FLOW,
      availablePointsBurning: this.availablePointsBurning,
      nextState: this.nextState,
    };
  }

  static deserialize(ctx): PointsBurningFlow {
    if (ctx === null) {
      return null;
    }
    const {
      availablePointsBurning,
      nextState,
    } = ctx;
    return AbstractPromoFlow.baseDeserialize(
      PointsBurningFlow,
      {},
      [availablePointsBurning, nextState],
    );
  }
}
