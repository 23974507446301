import {ConfigurationStates} from '@/constants/configurationStates';
import ConfigurationCustom from '@/Model/Entity/custom/ConfigurationCustom';
import DocumentDto from '@/Model/Entity/DocumentDto';
import PaymentButton from '@/Model/Entity/PaymentButton';
import {filter, find} from 'lodash-es';
import {RefundConditions} from '@/constants/refundConditions';
import {defaultPaymentsByLocalCurrency, PaymentCodes} from '@/constants/paymentCodes';
import {StornoReasonCodes} from '@/constants/stornoReasonCodes';

export default class Configuration extends ConfigurationCustom {
  get isPosNotConfigured() {
    return this.general.configured.value === ConfigurationStates.NotConfigured;
  }

  get isPosConfigured() {
    return this.general.configured.value === ConfigurationStates.Configured;
  }

  get isPosBlocked() {
    return this.general.configured.value === ConfigurationStates.MiddlewareDisconnected;
  }

  getPaymentButtonsBySellDocument(sellDocument: DocumentDto): PaymentButton[] {
    const trainingStore = (require('@/Modules/Training/store/TrainingStore')).useTrainingStore();

    if (sellDocument.balanceIsZero) {
      return [];
    }

    const localCashPayment = defaultPaymentsByLocalCurrency
      ?.[this?.general?.localCurrency]?.cash ?? PaymentCodes.cashCzk;

    const localCardPayment = defaultPaymentsByLocalCurrency
      ?.[this?.general?.localCurrency]?.card ?? PaymentCodes.creditCardCzk;

    if (this.features?.payment?.customRefundConditionMethod === RefundConditions.GECO) {
      return filter(this.buttons.paymentButtons, (button) => {
        if (button.isCustomButton) {
          return false;
        }

        if (trainingStore.trainingIsActive.value && button.paymentType?.isCardMethod) {
          return false;
        }

        if (sellDocument.isModeStorno && sellDocument.originalDocument?.hasValidPaymentWithCardInForeignCurrency) {
          return !!find(sellDocument.originalDocument?.validPaymentsWithCardInForeignCurrency ?? [], ({currency}) => {
            return button.paymentType?.isCardMethod && button.paymentType?.currency === currency;
          });
        }

        if (sellDocument.isModeStorno && sellDocument.originalDocument?.hasValidPaymentWithCardInLocalCurrency) {
          return button.paymentType?.paymentCode === localCardPayment;
        }

        if (sellDocument.isModeStorno) {
          return button.paymentType?.paymentCode === localCashPayment;
        }

        if (sellDocument.balanceIsNegative) {
          return !!button.paymentType?.refund.allow;
        }

        return true;
      });
    }


    return filter(this.buttons.paymentButtons, (button) => {
      if (button.isCustomButton) {
        return false;
      }

      if (trainingStore.trainingIsActive.value && button.paymentType?.isCardMethod) {
        return false;
      }

      const refundRestriction = (
        (!sellDocument.isModeStorno && sellDocument.balanceIsNegative) ||
        (sellDocument.isModeStorno)
      );

      if (refundRestriction) {
        return !!button.paymentType?.refund.allow;
      }

      return true;
    });
  }

  getStornoReasonByCode(code: StornoReasonCodes) {
    return find(this.features.storno.stornoReasons, {code: code}) ?? null;
  }
}
