import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const DistributionCenterDtoPropDef = {
  dc: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  city: {
    type: new StringType(),
  },
  postalCode: {
    type: new StringType(),
  },
  street: {
    type: new StringType(),
  },
  houseNo: {
    type: new StringType(),
  },
  mail: {
    type: new StringType(),
  },
  phone: {
    type: new StringType(),
  },
};
export default class GeneratedDistributionCenterDto extends createEntity(DistributionCenterDtoPropDef) {}
