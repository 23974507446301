import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const SetItemDtoPropDef = {
  code: {
    type: new StringType(),
  },
  articleCode: {
    type: new StringType(),
  },
  price: {
    type: new StringType(),
  },
  quantity: {
    type: new StringType(),
  },
  editableSet: {
    type: new StringType(),
  },
};
export default class GeneratedSetItemDto extends createEntity(SetItemDtoPropDef) {}
