import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PreparedTicketPartnerResourceGameType from '../PreparedTicketPartnerResourceGameType';
import {ArrayType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import TicketResourceCombinations from '../TicketResourceCombinations';
import {BooleanType} from '@designeo/apibundle-js';


export const PreparedTicketPartnerResourcePropDef = {
  id: {
    type: new NumberType(),
  },
  code: {
    type: new StringType(),
  },
  clientId: {
    type: new NumberType(),
  },
  gameType: {
    type: new EntityType(PreparedTicketPartnerResourceGameType),
  },
  drawIds: {
    type: new ArrayType(new NumberType()),
  },
  amountToPayKc: {
    type: new NumberType(),
  },
  numbers: {
    type: new ObjectType(),
  },
  tutovka: {
    type: new ArrayType(new NumberType()),
  },
  combinations: {
    type: new ArrayType(new EntityType(TicketResourceCombinations)),
  },
  possibleWins: {
    type: new ObjectType(),
  },
  usedRandomBet: {
    type: new BooleanType(),
  },
  booster: {
    type: new BooleanType(),
  },
};
export default class GeneratedPreparedTicketPartnerResource extends createEntity(PreparedTicketPartnerResourcePropDef) {}
