
import {
  defineComponent,
} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
  WorkflowCodes,
} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import IconWarning from '@/Components/Icons/IconWarning.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';
import {useWorkflowStore} from '../../store/WorkflowStore';
import {useFilters} from '@/Helpers/filters';

export default defineComponent({
  name: 'StepCheckLotteryFinancialReport',
  components: {HLStep, IconWarning, IconCheck, WorkflowTransitions},
  setup() {
    const workflowStore = useWorkflowStore();
    const stepType = WorkflowStepTypes.CheckLotteryFinancialReport;

    const {interpolate} = useFilters();

    const onLotteryClosure = () => {
      workflowStore.currentWorkflow.value.activeWorkflowStep.messageBus.dispatchEvent(
        new CustomEvent(WorkflowStepEvents.CHANGE_WORKFLOW, {detail: {
          workflowCode: WorkflowCodes.lotteryTerminalClosure,
        }}),
      );
    };

    return {
      stepType,
      WorkflowStepField,
      onLotteryClosure,
      interpolate,
    };
  },
});
