import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {AppLoaderEvent} from '@/Modules/Core/types';
import {apiDocumentCreate} from '@/Model/Action';
import {DocumentDto} from '@/Model/Entity';

export class WorkflowStepApiLogLotteryCloseDayFinish extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.ApiLogLotteryCloseDayFinish;
  }

  get type() {
    return WorkflowStepApiLogLotteryCloseDayFinish.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  async beforeEnter() {
    if (!this.stepFinished) {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
      try {
        const document = DocumentDto.createLotteryCloseDay();
        document.preflightSetup();

        document.header.referentialUniqueidentifier = this.getFieldValue(
          WorkflowStepField.lotteryCorrectionDocumentId,
          null,
        );

        await apiDocumentCreate({
          input: document.toApiClone(),
        });
        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      } catch (e) {
        console.error(e);
        this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
            value: e,
          },
        }));

        this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));

        throw e;
      }
    } else {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    }
  }

  get transitions() {
    return {};
  }
}
