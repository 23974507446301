import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row flex-grow gap-1" }
const _hoisted_2 = { class: "self-center" }
const _hoisted_3 = { class: "font-bold flex flex-row items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhIcon = _resolveComponent("PhIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row items-end gap-8 px-4 py-1", {
      'bg-basic-light': _ctx.item.isActive,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.item.entity)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PhIcon, {
          icon: _ctx.icon,
          weight: "bold",
          size: 26
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(" #" + _toDisplayString(_ctx.item.entity.reference) + " ", 1),
          (_ctx.item.entity.isStatusProcessed)
            ? (_openBlock(), _createBlock(_component_PhIcon, {
                key: 0,
                class: "text-success",
                icon: 'PhCheckCircle',
                weight: "bold",
                size: 20
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, _toDisplayString(_ctx.item.entity.supplierName), 1)
      ])
    ]),
    _createElementVNode("div", null, _toDisplayString(_ctx.dateFormat(_ctx.item.entity.documentDate)), 1)
  ], 2))
}