import {createEntity, NumberType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';


export default class GeneratedLottery extends createEntity({
  terminalCount: {
    type: new NumberType(),
  },
  inputCharsMaxLen: {
    type: new NumberType(),
  },
  articleCategories: {
    type: new ObjectType(),
  },
}) {}
