export enum ReferentialDocumentsState {
  ENTER_ARTICLE_IDENTIFIER = 'enterArticleIdentifier',
  ENTER_CUSTOMER_IDENTIFIER = 'enterCustomerIdentifier',
  CHOSE_DATE = 'choseDate',
  LIST_DATA = 'listData',
  SELECT_CURRENCY = 'selectCurrency',
  SELECT_DOCUMENT_STATUS = 'selectDocumentStatus',
  SELECT_POS = 'selectPos',
  SELECT_SHOP = 'selectShop',
  SELECT_DOCUMENT_TYPE = 'selectDocumentType',
  SELECT_SUB_DOCUMENT_TYPE = 'selectSubDocumentType',
  SELECT_DOCUMENT_TYPES = 'selectDocumentTypes',
  SELECT_VENDOR = 'selectVendor',
}

export enum ReferentialDocumentsActions {
  ADD_NUMBER = 'addNumber',
  ADD_CHAR = 'addNumber',
  BACKSPACE = 'backspace',
  ENTER = 'enter',
  CANCEL = 'cancel',
}

export type ReferentialDocumentsInputEvent<V = any> = {
  type: ReferentialDocumentsActions,
  value?: V
}

export enum ReferentialDocumentsStateCommonFilterOptions {
  ALL_OPTIONS = 'allOptions',
  CONFIG_DEFAULT = 'configDefault'
}

export enum UpdateWSEvents {
  DOWNLOAD_PROGRESS = 'ProcessedDownloadProgress'
}

export enum ReferentialDocumentsDocumentStatusFilterOptions {
  CANCELED = 'Canceled',
  NOT_CANCELED = 'NotCanceled',
  ALL = 'All'
}
