import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreEvent,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {InputSource} from '@/Modules/Register/services/KeyboardBuffer';
import {
  TransitionDefinition,
  getCommonTransitions,
  createTransitions,
} from './helpers';
import {QuickCallArticle} from '../../QuickCall/Classes/QuickCall';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_CUSTOMER_CARD]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_CHAR,
        RegisterActions.ADD_NUMBER,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.SKIP,
      ]),
      [RegisterActions.CANCEL]: action(async () => {
        createTransitions.call(this)[RegisterActions.SKIP]();
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.inputBuffer) return;

        const isActivePresetFunction = this.checkSellFunctionPresetQuantity();

        if (isActivePresetFunction) {
          await this.changeState(RegisterState.ENTER_ARTICLE_NUMBER);
        } else {
          await this.search(this.state.inputBuffer, 'customer');
        }
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        this.state.productFlow.inputMode = InputSource.SCANNER;
        await this.search(event.value, 'customer');
      }),
    },
  };
}
