import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ArticleFileType from '../ArticleFileType';


export const ArticlesDtoPropDef = {
  data: {
    type: new ObjectType(),
  },
  fileName: {
    type: new StringType(),
  },
  type: {
    type: new EntityType(ArticleFileType),
  },
  eTag: {
    type: new StringType(),
  },
};
export default class GeneratedArticlesDto extends createEntity(ArticlesDtoPropDef) {}
