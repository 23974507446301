import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const LotteryTurnoverByArticlePropDef = {
  totalAmount: {
    type: new NumberType(),
  },
  internalNumber: {
    type: new StringType(),
  },
};
export default class GeneratedLotteryTurnoverByArticle extends createEntity(LotteryTurnoverByArticlePropDef) {}
