
import {
  computed,
  defineComponent,
} from 'vue';
import {isNil} from 'lodash-es';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {HLStep, useStepTools} from '@/Modules/Workflow/Step/StepTools';
import IconPrinter from '@/Components/Icons/IconPrinter.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import AsyncButton from '@/Components/AsyncButton.vue';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';
import IconReload from '@/Components/Icons/IconReload.vue';
import IconError from '@/Components/Icons/IconError.vue';
import {useOnlineDetection} from '@/Helpers/onlineDetection';
import {useWorkflowStore} from '../../store/WorkflowStore';

export default defineComponent({
  name: 'StepStockCheck',
  components: {
    IconError,
    IconReload,
    HLStep,
    IconPrinter,
    IconCheck,
    WorkflowTransitions,
    AsyncButton,
  },
  setup() {
    const {online} = useOnlineDetection();
    const stepType = WorkflowStepTypes.StockCheck;
    const workflowStore = useWorkflowStore();

    const {workflowStep} = useStepTools(workflowStore.currentWorkflow.value.activeWorkflowStep.type);

    const stock = computed(() => workflowStep.value.data?.stock);

    const stockHasItems = computed(() => stock.value?.length > 0);

    const stockIsEmpty = computed(() => stock.value?.length === 0);

    const stockIsNil = computed(() => isNil(stock.value));

    const errorLoadingStock = computed(() => workflowStep.value.data?.errorLoadingStock);

    const displayPrint = computed(() => online.value && !stockIsNil.value);

    const displayRetry = computed(() => !stockIsNil.value);

    return {
      online,
      stepType,
      WorkflowStepField,
      stock,
      stockHasItems,
      stockIsEmpty,
      errorLoadingStock,
      stockIsNil,
      displayPrint,
      displayRetry,
    };
  },
});
