
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import {
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {
  HLInput,
  HLStep,
  useStepTools,
} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';
import FormInput from '@/Components/FormInput.vue';
import InputSelect from '@/Components/InputSelect.vue';
import {
  WorkflowStepWarehouseOperationList,
} from '@/Modules/Workflow/Step/StepWarehouseOperationList/WorkflowStepWarehouseOperationList';
import {
  filter,
  flow,
  map,
  mapKeys,
  reduce,
} from 'lodash-es';
import {
  WarehouseOperationListErrors,
  WarehouseOperationListFilters,
  WarehouseOperationShipmentNotificationStatuses,
} from '@/constants/warehouseOperations';
import InputDateRange from '@/Components/InputDateRange.vue';
import {useFilters} from '@/Helpers/filters';
import WarehouseOperationList from '@/Modules/Workflow/Step/StepWarehouseOperationList/WarehouseOperationList.vue';
import {DocumentDto} from '@/Model/Entity';
import {
  apiInStoreManagementPlantList,
  apiInStoreManagementVendorList,
  apiV1PosInStoreManagementShipmentNotificationGet,
  apiV1PosInStoreManagementShipmentNotificationList,
} from '@/Model/Action';
import {AppLoaderEvent, ConfirmTypes} from '@/Modules/Core/types';
import {DocumentTypes} from '@/constants/documentTypes';
import {useI18n} from 'vue-i18n';
import ShipmentNotificationListDto from '@/Model/Entity/ShipmentNotificationListDto';
import AsyncButton from '@/Components/AsyncButton.vue';
import IconCheck from '@/Components/Icons/IconCheck.vue';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';

export default defineComponent({
  name: 'StepWarehouseOperationList',
  components: {
    IconCheck,
    AsyncButton,
    WarehouseOperationList,
    WorkflowTransitions,
    HLStep,
    HLInput,
    FormInput,
  },
  setup() {
    const i18n = useI18n();
    const stepType = WorkflowStepTypes.WarehouseOperationList;
    const {workflowStep, currentWorkflow} = useStepTools<WorkflowStepWarehouseOperationList>(stepType);
    const coreStore = useCoreStore();

    const {dateFormat} = useFilters();

    const isFilterView = computed<boolean>({
      get() {
        return workflowStep.value.isFilterView;
      },
      set(value) {
        workflowStep.value.isFilterView = value;
      },
    });

    const filtersOptions = ref({}) as Ref<
      Partial<Record<WarehouseOperationListFilters, Array<{id: string, label: string}> | null>>
    >;

    const sanitizeFilterSettings = (key, configSettings) => {
      switch (key as WarehouseOperationListFilters) {
      case WarehouseOperationListFilters.documentDate:
        return {
          component: InputDateRange,
          active: false,
          readonly: configSettings.readonly ?? false,
          label: configSettings.label ?? null,
          defaultValue: configSettings.defaultValue ?? [],
          getReadonlyValue() {
            return `${dateFormat(this.defaultValue[0])} - ${dateFormat(this.defaultValue[1])}`;
          },
        };
      case WarehouseOperationListFilters.documentType:
      case WarehouseOperationListFilters.stateOfProcessing:
      case WarehouseOperationListFilters.vendor:
        return {
          component: InputSelect,
          active: false,
          options: workflowStep.value.configOptionsToSelectOptions(configSettings.options) ??
              filtersOptions.value[key] ??
              [],
          readonly: configSettings.readonly ?? false,
          label: configSettings.label ?? null,
          defaultValue: configSettings.defaultValue ?? null,
          getReadonlyValue() {
            return getLabelByFilterTypeSelect({
              filter: key,
              value: this.defaultValue,
            }, {
              options: this.options,
            });
          },
        };
      case WarehouseOperationListFilters.sourcePlant:
        return {
          component: InputSelect,
          active: false,
          options: workflowStep.value.configOptionsToSelectOptions(configSettings.options) ??
              filtersOptions.value[key] ??
              [],
          readonly: configSettings.readonly ?? false,
          label: configSettings.label ?? null,
          direction: 'up',
          defaultValue: configSettings.defaultValue ?? null,
          getReadonlyValue() {
            return getLabelByFilterTypeSelect({
              filter: key,
              value: this.defaultValue,
            }, {
              options: this.options,
            });
          },
        };
      case WarehouseOperationListFilters.reference:
        return {
          active: false,
          readonly: configSettings.readonly ?? false,
          label: configSettings.label ?? null,
          defaultValue: configSettings.defaultValue ?? null,
          getReadonlyValue() {
            return this.defaultValue;
          },
        };
      }
    };

    const filters = computed(() => {
      return reduce(
        workflowStep.value.filters,
        (acc, key) => {
          const configSettings = workflowStep.value.step?.filters?.[key];

          if (!configSettings) {
            return acc;
          }

          return [
            ...acc,
            {
              key: workflowStep.value.createFilterDataPath(key),
              ...sanitizeFilterSettings(key, configSettings),
            },
          ];
        },
        [],
      );
    });

    const filtersByKey = computed(() => {
      return mapKeys(filters.value, 'key');
    });

    const getLabelByFilterTypeSelect = (
      {filter, value},
      {
        options = filtersOptions.value[filter] ?? filtersByKey.value?.[filter]?.options ?? [],
      } = {},
    ) => {
      return options.find((option) => option.id === value)?.label ?? 'N/A';
    };

    const filtersSummary = computed(() => {
      return flow([
        (arr) => filter(arr, ({filter}) => !filtersByKey.value?.[filter]?.readonly),
        (arr) => map(arr, ({filter, value}) => {
          switch (filter) {
          case WarehouseOperationListFilters.documentDate:
            return `${dateFormat(value[0])} - ${dateFormat(value[1])}`;
          case WarehouseOperationListFilters.reference:
            return value;
          case WarehouseOperationListFilters.sourcePlant:
          case WarehouseOperationListFilters.documentType:
          case WarehouseOperationListFilters.vendor:
          case WarehouseOperationListFilters.stateOfProcessing:
            return getLabelByFilterTypeSelect({filter, value});
          }
        }),
      ])(workflowStep.value.filledFilters);
    });

    const prepareFiltersOptions = async () => {
      const [documentTypes, vendors, statesOfProcessing, plants] = await Promise.all([
        new Promise<Array<{ id: string, label: string }>>((resolve, reject) => {
          if (workflowStep.value?.step?.filters?.[WarehouseOperationListFilters.documentType]?.options) {
            resolve(workflowStep.value.step.filters[WarehouseOperationListFilters.documentType].options);
            return;
          }

          resolve(map([
            DocumentTypes.GoodsReceiptFromDC,
            DocumentTypes.GoodsReturnToDC,
            DocumentTypes.GoodsOrderFromDC,
            DocumentTypes.GoodsReceiptFromPress,
            DocumentTypes.GoodsReceiptFromExternalSupplier,
            DocumentTypes.GoodsReturnToExternalSupplier,
            DocumentTypes.GoodsOrderFromExternalSupplier,
            DocumentTypes.GoodsReturnToPress,
            DocumentTypes.GoodsDepreciationConsumption,
            DocumentTypes.GoodsDepreciationDisposal,
            DocumentTypes.GoodsReceiptFromPlant,
            DocumentTypes.GoodsTransferToPlant,
          ], (type) => {
            return {
              id: type,
              label: i18n.t(`apps.documents.types.${type}`),
            };
          }));
        }),
        new Promise<Array<{ id: string, label: string }>>((resolve, reject) => {
          if (workflowStep.value?.step?.filters?.[WarehouseOperationListFilters.vendor]?.options) {
            resolve(workflowStep.value.step.filters[WarehouseOperationListFilters.vendor].options);
            return;
          }

          apiInStoreManagementVendorList({})
            .then((vendors) => {
              resolve(map(vendors, (vendor) => ({
                id: vendor.vendor,
                label: vendor.name,
              })));
            })
            .catch(reject);
        }),
        new Promise<Array<{ id: string, label: string }>>((resolve, reject) => {
          if (workflowStep.value?.step?.filters?.[WarehouseOperationListFilters.stateOfProcessing]?.options) {
            resolve(workflowStep.value.step.filters[WarehouseOperationListFilters.stateOfProcessing].options);
            return;
          }

          resolve(map(Object.values(WarehouseOperationShipmentNotificationStatuses), (status) => ({
            id: status,
            label: i18n.t(`apps.documents.statesOfProcessing.${status}`),
          })));
        }),
        new Promise<Array<{ id: string, label: string }>>((resolve, reject) => {
          if (workflowStep.value?.step?.filters?.[WarehouseOperationListFilters.sourcePlant]?.options) {
            resolve(workflowStep.value.step.filters[WarehouseOperationListFilters.sourcePlant].options);
            return;
          }

          apiInStoreManagementPlantList({})
            .then((plants) => {
              resolve(map(plants, (plant) => ({
                id: plant.plant,
                label: plant.name,
              })));
            })
            .catch(reject);
        }),
      ]);
      filtersOptions.value = {
        [WarehouseOperationListFilters.documentType]: documentTypes,
        [WarehouseOperationListFilters.stateOfProcessing]: statesOfProcessing,
        [WarehouseOperationListFilters.vendor]: vendors,
        [WarehouseOperationListFilters.sourcePlant]: plants,
      };
    };

    const getShipmentNotificationParams = () => {
      return reduce(workflowStep.value.filledFilters, (acc, {filter, value}) => {
        switch (filter) {
        case WarehouseOperationListFilters.documentDate:
          return {
            ...acc,
            ...(!value?.[0] ? {} : {'from': new Date(value[0]).toISOString()}),
            ...(!value?.[1] ? {} : {'to': new Date(value[1]).toISOString()}),
          };
        case WarehouseOperationListFilters.documentType:
          return {
            ...acc,
            'documentSubType': value,
          };
        case WarehouseOperationListFilters.reference:
          return {
            ...acc,
            'reference': value,
          };
        case WarehouseOperationListFilters.stateOfProcessing:
          return {
            ...acc,
            'status': value,
          };
        case WarehouseOperationListFilters.vendor:
          return {
            ...acc,
            'vendor': value,
          };
        case WarehouseOperationListFilters.sourcePlant:
          throw new Error(`Filter ${filter} is not implemented!`);
        }
      }, {});
    };

    const fetchList = async () => {
      workflowStep.value.operationEntityPropList = await apiV1PosInStoreManagementShipmentNotificationList({
        params: getShipmentNotificationParams(),
      });
    };

    const fetchContent = async ({list, filters}) => {
      try {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
        if (filters) {
          await prepareFiltersOptions();
        }

        if (list) {
          await fetchList();
        }
      } catch (e) {
        console.error(e);
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      } finally {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    };

    const list = computed({
      get() {
        return map(workflowStep.value.operationEntityPropList, (item: ShipmentNotificationListDto) => {
          return {
            entity: item,
            isActive: logisticDocument.value?.header.uniqueidentifier === item.id,
          };
        });
      },
      set(value: Array<any>) {
        workflowStep.value.operationEntityPropList = value;
      },
    });


    const logisticDocument = computed<DocumentDto>(() => {
      return workflowStep.value.logisticDocument;
    });

    const onListSelect = async (item: ShipmentNotificationListDto) => {
      if (item.isStatusProcessed) {
        await coreStore.alert(i18n.t('workflow.steps.warehouseOperationList.itemAlreadyProcessed'));
        return;
      }


      try {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));
        const {documentJson, reference} = await apiV1PosInStoreManagementShipmentNotificationGet({
          params: {
            id: item.id,
          },
        });

        const logisticDocument = documentJson?.clone();

        logisticDocument.header.externalReference = reference;

        workflowStep.value.logisticDocument = logisticDocument;
        await workflowStep.value.hydrateLogisticItems();
      } catch (e) {
        console.error(e);
        workflowStep.value.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
          detail: {
            value: e,
          },
        }));
      } finally {
        workflowStep.value.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
      }
    };

    const onNoDocumentItemCounterpart = async (event: Event) => {
      await coreStore.alert(
        i18n.t('workflow.steps.warehouseOperationList.noDocumentItemCounterpart', {
          reference: workflowStep.value.logisticDocument.header.externalReference,
        }),
        {
          type: ConfirmTypes.error,
        },
      );
    };

    onMounted(async () => {
      await fetchContent({list: true, filters: true});
      workflowStep.value.messageBus.addEventListener(
        WarehouseOperationListErrors.noDocumentItemCounterpart,
        onNoDocumentItemCounterpart,
      );
    });

    onBeforeUnmount(() => {
      if (!currentWorkflow.value) {
        return;
      }

      workflowStep.value.messageBus.removeEventListener(
        WarehouseOperationListErrors.noDocumentItemCounterpart,
        onNoDocumentItemCounterpart,
      );
    });

    watch(() => isFilterView.value, async (isFilterView) => {
      await fetchContent({list: !isFilterView, filters: false});
    }, {flush: 'post'});

    return {
      stepType,
      WorkflowStepField,
      isFilterView,
      filters,
      filtersSummary,
      list,
      onListSelect,
      logisticDocument,
    };
  },
});
