import {throttle} from 'lodash-es';
import {CoreSounds} from '../types';

const soundEffectImportMap = {
  [CoreSounds.ARTICLE_ALREADY_IN_DOCUMENT]: async () => (await import('@/assets/sounds/article-warning.wav')).default,
  [CoreSounds.ARTICLE_IS_TYPE_SERVICE]: async () => (await import('@/assets/sounds/article-error.wav')).default,
  [CoreSounds.ARTICLE_IS_NOT_EXPANDABLE_SET]: async () => (await import('@/assets/sounds/article-error.wav')).default,
  [CoreSounds.ARTICLE_NOT_FOUND]: async () => (await import('@/assets/sounds/article-not-found.wav')).default,
  [CoreSounds.ARTICLE_VARIATIONS]: async () => (await import('@/assets/sounds/article-variations.wav')).default,
};

export default () => {
  const preloadSoundEffect = async (type: keyof typeof soundEffectImportMap) => {
    if (!Object.prototype.hasOwnProperty.call(soundEffectImportMap, type)) {
      return null;
    }

    return new Audio(await soundEffectImportMap[type]());
  };

  const playSoundEffect = throttle(async (type: keyof typeof soundEffectImportMap) => {
    try {
      const soundEffect = await preloadSoundEffect(type);

      if (soundEffect) {
        await soundEffect.play();
      }
    } catch (e) {
      console.warn(e);
    }
  }, 500);

  return {
    preloadSoundEffect,
    playSoundEffect,
  };
};

