import routes from './routes';
// import {useInventoryStore} from '@/Modules/Inventory/store/InventoryStore';
// import {useCoreStore} from '@/Modules/Core/store/CoreStore';
// import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
// import {includes, some} from 'lodash-es';
// import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
// import customerExternalRoutes from '../CustomerExternal/routes';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerInventory(ctx: RequiredContext) {
  await ctx.registerRoutes(routes);

  // ctx.registerHookBeforeEach((to, from, next) => {
  //   const inventoryStore = useInventoryStore();
  //   const coreStore = useCoreStore();
  //   const configurationStore = useConfigurationStore();
  //   const authStore = useAuthStore();

  //   const purchaseInProgressRoutes = [
  //     'workflow',
  //     'customer-detail',
  //     'register',
  //   ];

  //   if (
  //     configurationStore.configuration.value.isPosNotConfigured ||
  //     !authStore.activePerson.value ||
  //     !registerStore.isInProgress.value ||
  //     some([].concat(customerExternalRoutes), {name: to.name}) ||
  //     includes(purchaseInProgressRoutes, to.name)
  //   ) {
  //     next();
  //   } else {
  //     next({name: 'register'});
  //     // @ts-ignore we already has i18n in ctx by this time
  //     coreStore.alert(ctx.i18n.global.t('register.exitInProgressError'));
  //   }
  // });
  return {};
}
