
import {
  defineComponent,
} from 'vue';
import {
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepCreateFinancialReport',
  components: {HLStep, WorkflowTransitions},
  setup() {
    const stepType = WorkflowStepTypes.CreateFinancialReport;
    return {
      stepType,
      WorkflowStepField,
    };
  },
});
