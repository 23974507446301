import {usePromoEngine} from '@/Modules/Register/PromoEngine/PromoEngine';
import {
  SignalRErrors,
  signalRHandlersMap,
  useSignalR,
} from '@/Helpers/signalR';
import {useRouter} from 'vue-router';
import {
  Composer,
  useI18n,
} from 'vue-i18n';
import {KeyboardBuffer} from '@/Modules/Register/services/KeyboardBuffer';
import {
  isArray,
  isEmpty,
  isFunction,
  mergeWith,
} from 'lodash-es';
import {onBeforeUnmount, onMounted} from 'vue';
import {usePosConfigurationStore} from '@/Modules/PosConfiguration/store/PosConfigurationStore';
import {useAuthStore} from '@/Modules/Auth/store/AuthStore';
import {useRegisterStore} from '@/Modules/Register/store/RegisterStore';
import {useWorkflowStore} from '@/Modules/Workflow/store/WorkflowStore';
import defaultTranslations from '@/Plugins/translations/defaultTranslations.json';
import {jsonSchemaToJson} from '@/Plugins/translations/jsonSchemaToJson';
import {createTbtTranslations} from '@/Plugins/i18n';
import {jsonToJsonSchema} from '@/Plugins/translations/jsonToJsonSchema';
import {getAppStorage} from '@designeo/vue-helpers';
import {useDocumentStatusStore} from '@/Modules/Core/store/DocumentStatusStore';
import {OnlineDetectionEvents} from '@/Helpers/onlineDetection';
import {useFourEyesStore} from '@/Modules/Core/store/FourEyesStore';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  clearStorage,
  getVueApp,
  logger,
} from '@/Helpers/app';
import {Configuration} from '@/Model/Entity';
import {useUpdateStore} from '@/Modules/Core/store/UpdateStore';
import {useCoreStore} from '@/Modules/Core/store/CoreStore';
import {useRecoveryStore} from '@/Modules/Core/store/RecoveryStore';
import {useInventoryStore} from '@/Modules/Inventory/store/InventoryStore';
import {useInventoryStockStore} from '@/Modules/Apps/Inventory/Stock/store/InventoryStockStore';
import {restoreModeExit, restoreModeStart} from '@/Helpers/restoreMode';
import {useMessageStore} from '@/Modules/Apps/PosMessage/store/MessageStore';
import {e2eScenarioIdSessionStorage, sessionStoragePersistentVariable} from '@/Helpers/persist';
import {sentryFinishCurrentTransaction} from '@/Helpers/sentry';
import {wrapAppRedirectUrl} from '@/Helpers/url';
import {usePdaHandshakeStore} from '../Modules/Core/store/PdaHandshakeStore';
import {getStorageSize} from '@/Plugins/storage';
import {guid} from '@/Helpers/guid';
import {FourEyesOperations, PrinterWSEvents} from '@/Modules/Core/types';
import {jwtIsExpired} from './jwt';

export const devToolsPermissionStorage = sessionStoragePersistentVariable('devTools', null);

const currentInstanceHasDevToolsPermission = () => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  // @ts-ignore
  if (window.e2eScenarioId) { // running under test runner
    return true;
  }

  return !jwtIsExpired(devToolsPermissionStorage.get());
};

const createPermissionError = () => {
  return new Error('[DevTools] Permission denied, use `devTools=true` for authorize yourself for current session');
};

const withPermissionTrap = (wrapped) => {
  if (isFunction(wrapped)) {
    return (...args) => {
      if (currentInstanceHasDevToolsPermission()) {
        return wrapped(...args);
      }

      throw createPermissionError();
    };
  }

  if (currentInstanceHasDevToolsPermission()) {
    return wrapped;
  }

  throw createPermissionError();
};

export const fakeScanner = (str: string, {el = window, mode = 'default', EventConstructor = KeyboardEvent} = {}) => {
  const prefix = [
    /* eslint-disable */
    {key: 'Alt', event: 'keydown'},
    {code: 'Numpad0', key: '0', event: 'keydown'},
    {code: 'Numpad0', key: '0', event: 'keyup'},
    {code: 'Numpad0', key: '0', event: 'keydown'},
    {code: 'Numpad0', key: '0', event: 'keyup'},
    {code: 'Numpad2', key: '2', event: 'keydown'},
    {code: 'Numpad2', key: '2', event: 'keyup'},
    {key: 'Alt', event: 'keyup'},
    /* eslint-enable */
  ];
  const suffix = [
    /* eslint-disable */
    {key: 'Alt', event: 'keydown'},
    {code: 'Numpad0', key: '0', event: 'keydown'},
    {code: 'Numpad0', key: '0', event: 'keyup'},
    {code: 'Numpad0', key: '0', event: 'keydown'},
    {code: 'Numpad0', key: '0', event: 'keyup'},
    {code: 'Numpad3', key: '3', event: 'keydown'},
    {code: 'Numpad3', key: '3', event: 'keyup'},
    {key: 'Alt', event: 'keyup'},
    /* eslint-enable */
  ];
  for (const prefixChar of prefix) {
    el.dispatchEvent(new (EventConstructor)(prefixChar.event, {
      // @ts-ignore
      altKey: prefixChar.alt || false,
      key: prefixChar.key,
      code: prefixChar.code,
    }));
  }
  for (const char of str) {
    el.dispatchEvent(new (EventConstructor)('keydown', {
      key: char,
    }));
    el.dispatchEvent(new (EventConstructor)('keypress', {
      key: char,
    }));
    el.dispatchEvent(new (EventConstructor)('keyup', {
      key: char,
    }));
  }
  for (const suffixChar of suffix) {
    el.dispatchEvent(new (EventConstructor)(suffixChar.event, {
      // @ts-ignore
      altKey: suffixChar.alt || false,
      key: suffixChar.key,
      code: suffixChar.code,
    }));
  }
};

export const goOnline = async () => {
  const {notificationsConnection} = useSignalR();
  await notificationsConnection.addEventListenerWithTrigger(OnlineDetectionEvents.GET_STATE)(async () => {
    await notificationsConnection.dispatchEvent(OnlineDetectionEvents.CHECK_STATE, true);
  });
};

export const goOffline = async () => {
  const {notificationsConnection} = useSignalR();
  await notificationsConnection.addEventListenerWithTrigger(OnlineDetectionEvents.GET_STATE)(async () => {
    await notificationsConnection.dispatchEvent(OnlineDetectionEvents.CHECK_STATE, false);
  });
};

export const resetForcedOfflineState = async () => {
  const {notificationsConnection} = useSignalR();
  await notificationsConnection.addEventListenerWithTrigger(OnlineDetectionEvents.GET_STATE)(async () => {
    await notificationsConnection.dispatchEvent(OnlineDetectionEvents.RESET_FORCE);
  });
};

export const loggedUser = () => {
  const authStore = useAuthStore();
  return authStore.activePerson.value;
};

export const sellDocument = () => {
  const registerStore = useRegisterStore();
  return registerStore.sellDocument.value;
};

export const currentWorkflow = () => {
  const workflowStore = useWorkflowStore();
  return workflowStore.currentWorkflow.value;
};

export const vm = () => {
  return getVueApp();
};

export const createTree = (paths, separator = '.') => {
  return paths.reduce((obj, path) => {
    path.split(separator).reduce((acc, component) => {
      return acc[component] = acc[component] || {};
    }, obj);
    return obj;
  }, {});
};

export const createDefaultTranslations = (val) => {
  function customizer(oldValue, newValue) {
    if (isArray(oldValue)) {
      return newValue;
    }
  }

  return JSON.stringify(
    mergeWith(
      {},
      defaultTranslations,
      jsonToJsonSchema(createTbtTranslations(val)),
      customizer,
    ),
    null,
    2, // same as project space
  );
};

export const ejectTranslations = () => {
  /**
   * Eject default translations as json
   */

  return jsonSchemaToJson(defaultTranslations);
};

export const openRestoreMode = () => {
  restoreModeStart();
};

export const closeRestoreMode = () => {
  restoreModeExit();
};

export const simulateUpdate = (updateVersionTypeImmediate = false, {data = {}, delay = 5000} = {}) => {
  useUpdateStore().processConfiguration(new Configuration({
    ...data,
    general: {
      appVersion: {
        current: '0.0.0',
        updateAvailable: '0.0.1',
        updateVersionType: updateVersionTypeImmediate ? 'Immediate' : 'OnDemand',
        updateValidFrom: new Date((+new Date()) + delay).toISOString(),
      },
    },
  }));
};

let messages = {};
// todo: get rid of i18n - (useI18n() can be called only at root of setup function)
export const toggleTranslationKeys = (i18n: Composer) => {
  if (!isEmpty(messages)) {
    for (const locale of i18n.availableLocales) {
      i18n.setLocaleMessage(locale, messages[locale]);
    }
    messages = {};
  } else {
    for (const locale of i18n.availableLocales) {
      const localeTranslations = i18n.getLocaleMessage(locale) ?? {};

      if (!isEmpty(localeTranslations)) {
        messages = Object.assign(messages, {[locale]: localeTranslations});
      }

      i18n.setLocaleMessage(locale, {});
    }
  }
};

export const registerDevTools = () => {
  Object.defineProperty(window, 'devTools', {
    get() {
      return currentInstanceHasDevToolsPermission();
    },
    set(val: boolean | string) {
      if (typeof val === 'string') {
        if (!jwtIsExpired(val)) {
          devToolsPermissionStorage.set(val);
          return;
        } else {
          throw new Error('[DevTools] Invalid JWT token');
        }
      }

      if (!val) {
        devToolsPermissionStorage.reset();
      } else if (val && !currentInstanceHasDevToolsPermission()) {
        (async () => {
          const fourEyesStore = useFourEyesStore();
          const result = await fourEyesStore.openFourEyesConfirm(FourEyesOperations.DEV_TOOLS);
          devToolsPermissionStorage.set(result.accessToken);
        })();
      }
    },
  });

  Object.defineProperty(window, 'scanner', {
    value: fakeScanner,
  });

  Object.defineProperty(window, 'guid', {
    value: () => guid(),
  });

  Object.defineProperty(window, 'e2eScenarioId', {
    get() {
      return e2eScenarioIdSessionStorage.get();
    },
    set(v: any) {
      return e2eScenarioIdSessionStorage.set(v);
    },
  });

  Object.defineProperty(window, 'recoveryStore', {
    get() {
      return useRecoveryStore();
    },
  });

  Object.defineProperty(window, 'promoEngineDebug', {
    get() {
      return localStorage.getItem('PROMO_ENGINE_LOGS_ENABLED') === 'true';
    },
    set(val) {
      localStorage.setItem('PROMO_ENGINE_LOGS_ENABLED', val ? 'true' : 'false');
    },
  });

  Object.defineProperty(window, 'clearStorage', {
    value: clearStorage,
  });

  /** Here starts helpers with permissions */

  Object.defineProperty(window, 'finishCurrentSentryTransaction', {
    value: withPermissionTrap(sentryFinishCurrentTransaction),
  });


  Object.defineProperty(window, 'goOnline', {
    value: withPermissionTrap(goOnline),
  });

  Object.defineProperty(window, 'goOffline', {
    value: withPermissionTrap(goOffline),
  });

  Object.defineProperty(window, 'resetForcedOfflineState', {
    value: withPermissionTrap(resetForcedOfflineState),
  });

  Object.defineProperty(window, 'ctx', {
    get() {
      return withPermissionTrap(vm)()?._context?.config?.globalProperties?.$context;
    },
  });

  Object.defineProperty(window, 'wrapAppRedirectUrl', {
    value: withPermissionTrap((val) => wrapAppRedirectUrl(val)),
  });

  Object.defineProperty(window, 'createTree', {
    value: withPermissionTrap(createTree),
  });

  Object.defineProperty(window, 'loggedUser', {
    get() {
      return withPermissionTrap(loggedUser)();
    },
  });

  Object.defineProperty(window, 'sellDocument', {
    get() {
      return withPermissionTrap(sellDocument)();
    },
  });

  Object.defineProperty(window, 'vm', {
    get() {
      return withPermissionTrap(vm)();
    },
  });

  Object.defineProperty(window, 'wf', {
    get() {
      return withPermissionTrap(currentWorkflow)();
    },
  });

  Object.defineProperty(window, 'appStorage', {
    get() {
      return withPermissionTrap(getAppStorage);
    },
  });

  Object.defineProperty(window, 'documentStatusStore', {
    get() {
      return withPermissionTrap(useDocumentStatusStore)();
    },
  });

  Object.defineProperty(window, 'registerStore', {
    get() {
      return withPermissionTrap(useRegisterStore)();
    },
  });

  Object.defineProperty(window, 'coreStore', {
    get() {
      return withPermissionTrap(useCoreStore)();
    },
  });

  Object.defineProperty(window, 'configurationStore', {
    get() {
      return withPermissionTrap(useConfigurationStore)();
    },
  });

  Object.defineProperty(window, 'fourEyesStore', {
    get() {
      return withPermissionTrap(useFourEyesStore)();
    },
  });

  Object.defineProperty(window, 'authStore', {
    get() {
      return withPermissionTrap(useAuthStore)();
    },
  });

  Object.defineProperty(window, 'workflowStore', {
    get() {
      return withPermissionTrap(useWorkflowStore)();
    },
  });

  Object.defineProperty(window, 'inventoryStore', {
    get() {
      return withPermissionTrap(useInventoryStore)();
    },
  });

  Object.defineProperty(window, 'inventoryStockStore', {
    get() {
      return withPermissionTrap(useInventoryStockStore)();
    },
  });

  Object.defineProperty(window, 'messageStore', {
    get() {
      return withPermissionTrap(useMessageStore)();
    },
  });

  Object.defineProperty(window, 'updateStore', {
    get() {
      return withPermissionTrap(useUpdateStore)();
    },
  });

  Object.defineProperty(window, 'pdaHandshakeStore', {
    get() {
      return withPermissionTrap(usePdaHandshakeStore)();
    },
  });

  Object.defineProperty(window, 'simulateUpdate', {
    value: withPermissionTrap(simulateUpdate),
  });

  Object.defineProperty(window, 'copySellDocument', {
    value: withPermissionTrap(() => {
      // @ts-ignore
      copy(JSON.stringify(sellDocument().toJson(), null, 2));
    }),
  });


  Object.defineProperty(window, 'copyPromotions', {
    value: withPermissionTrap(() => {
      const promotionBulk = usePromoEngine().promotionBulk;
      // @ts-ignore
      copy({
        promotions: [...promotionBulk.definition],
        promotionLists: {...promotionBulk.articleGroups},
      });
    }),
  });

  /**
   * U can use it like this in console:
   *
   * @example
   *
   copy(createDefaultTranslations({
    "cs": {
      "errorCodes": {
        "userUpdateFailed": {
         "name": "Při ukládání editace došlo k chybě",
         "help": ""
        }
      }
    },
    "sk": {

    },
    "en": {

    },
    "de": {

    }
   }))
   */

  Object.defineProperty(window, 'createDefaultTranslations', {
    value: withPermissionTrap(createDefaultTranslations),
  });

  Object.defineProperty(window, 'ejectTranslations', {
    value: withPermissionTrap(ejectTranslations),
  });

  Object.defineProperty(window, 'openRestoreMode', {
    value: withPermissionTrap(openRestoreMode),
  });

  Object.defineProperty(window, 'closeRestoreMode', {
    value: withPermissionTrap(closeRestoreMode),
  });

  Object.defineProperty(window, 'storageStatus', {
    get() {
      return withPermissionTrap(() => {
        // eslint-disable-next-line no-console
        console.log({
          localStorage: getStorageSize(window.localStorage),
          sessionStorage: getStorageSize(window.sessionStorage),
        });
      })();
    },
  });

  Object.defineProperty(window, 'logger', {
    get() {
      return withPermissionTrap(logger)().isActive;
    },
    set(isActive: boolean) {
      if (isActive) {
        withPermissionTrap(logger)().toggleOn();
      } else {
        withPermissionTrap(logger)().toggleOff();
      }
    },
  });

  Object.defineProperty(window, 'signalR', {
    value: {
      emitToAllHandlers: withPermissionTrap((args, {events = null}: {events?: string[]} = {}) => {
        for (const [handler, {event: sourceEvent, trace}] of signalRHandlersMap.entries()) {
          if (!events || events.includes(sourceEvent)) {
            handler(...args);
          }
        }
      }),
      get payment() {
        const ensurePaymentMap = () => {
          const paymentMap = (
            window['paymentMap'] = window['paymentMap'] ?? new Map()
          ) as Map<string, {emitError: (err: Error)=> void}>;

          return paymentMap;
        };

        return {
          registerPayment: (paymentId, emitError) => {
            const paymentMap = ensurePaymentMap();

            paymentMap.set(paymentId, {emitError});
          },
          unregisterPayment: (paymentId) => {
            const paymentMap = ensurePaymentMap();

            paymentMap.delete(paymentId);
          },
          emitPaymentResult: (
            {
              statusCode,
              cardType,
              cardNumber,
              output,
              doc,
            }: {
              statusCode: string | number
              cardType?,
              cardNumber?,
              output?,
              doc?,
            },
            {
              guid = null,

            } = {},
          ) => {
            const paymentMap = ensurePaymentMap();
            const payments = [].concat(guid ?? [...paymentMap.keys()]);

            if (!payments.length) {
              // eslint-disable-next-line no-console
              console.log('[DevTools] No payments to emit');
              return;
            }

            for (const guidItem of payments) {
              this.emitToAllHandlers([
                {
                  result: {
                    'operation': 'POSPayment',
                    'dialog': [],
                    'uniqueIdentifier': guidItem,
                    'date': new Date().toISOString(),

                    'statusCode': typeof statusCode === 'number' ? statusCode : parseInt(statusCode, 10),
                    'cardType': cardType,
                    'cardNumber': cardNumber,
                    'output': output,
                  },
                  document: doc ?? {
                    header: {
                      uniqueidentifier: guidItem,
                    },
                  },
                },
                guidItem,
              ], {events: [PrinterWSEvents.PROCESSED_DOC_MESSAGE]});
            }
          },
          get timeout() {
            const paymentMap = ensurePaymentMap();

            for (const [guid, {emitError}] of paymentMap.entries()) {
              emitError(new Error(SignalRErrors.timeout));
            }

            return undefined;
          },
          get finish() {
            return this.emitPaymentResult({
              statusCode: 0,
              cardType: 'MASTERCARD',
              cardNumber: '************1234',
            });
          },
          get errorLimit() {
            return this.emitPaymentResult({
              statusCode: 2112,
              cardType: 'MASTERCARD',
              cardNumber: '************1234',
              // eslint-disable-next-line max-len
              output: [{outputBase64: 'LS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQogICAgICAgICAgICAgICAgR2VjbyBTSwogICAgICAgICAgICAgICAgICBURVNUCiAgICAgICAgICAgIEVsZWt0csOhcmVuc2vDoSA0CiAgICAgICAgICAgIEJyYXRpc2xhdmEgODMxMDQKICAgICAgICAgICAgICAgQWRkcmVzcyAgNQotLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tCiAgICAgICAgICBUUkFOU0FDVElPTiBERUNMSU5FRAotLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tCjI5LjAxLjIwMjQgIDEyOjQ0OjAzICAwMDA0ODAgLyAwOTMgLyBDQTEKICAgICAgICAgICAgQ0FSREhPTERFUiBDT1BZCi0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0KVElEOiBHRUNPMTEgICAgICAgICAgICAgIChHRUNPMTEvR0VDTzExKQogICAgICAgICAgICAgICAgICBDaGlwCkNhcmQ6IFZJU0EgICAgICAgICAgIHh4eHggeHh4eCB4eHh4IDc1NTUKQUlEOiBBMDAwMDAwMDAzMTAxMCAgICAgICAgICAgICAgUmV2b2x1dAoKICAgICAgICAgICAgICAgICAgU2FsZQoKIFRvdGFsOiBFVVIgICAgICAgICAgICAgICAgICAgICAgICAwLDgyCgogICAgICAgICAgICBQcmVrcm9jZW4gbGltaXQKLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQogICAgICAgICAgVFJBTlNBQ1RJT04gREVDTElORUQKLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLQ=='}],
            });
          },
          get wait() {
            return this.emitPaymentResult({statusCode: 2100});
          },
          get processing() {
            return this.emitPaymentResult({statusCode: 2107});
          },
        };
      },
    },
  });
};

export const mountDevToolsKeyboardListener = () => {
  const router = useRouter();
  const i18n = useI18n();
  const posConfigurationStore = usePosConfigurationStore();

  const keyboardBuffer = new KeyboardBuffer((data) => {
    try {
      for (const buffer of data) {
        if (buffer.keys.length === 1 && buffer.keys[0].key === 'F12' && buffer.keys[0].shift && buffer.keys[0].alt) {
          withPermissionTrap(posConfigurationStore.setCloseDay)();
        } else if (buffer.keys.length === 1 && buffer.keys[0].key === 'F12' && buffer.keys[0].shift) {
          withPermissionTrap(router.push)({
            name: 'signpost',
          });
        } else if (buffer.keys.length === 1 && buffer.keys[0].key === 'F11' && buffer.keys[0].shift) {
          withPermissionTrap(toggleTranslationKeys)(i18n);
        } else if (buffer.keys.length === 1 && buffer.keys[0].key === 'F7' && buffer.keys[0].shift) {
          withPermissionTrap(goOnline)();
        } else if (buffer.keys.length === 1 && buffer.keys[0].key === 'F8' && buffer.keys[0].shift) {
          withPermissionTrap(goOffline)();
        }
      }
    } catch (e) {
      console.error(e);
    }
  });

  onMounted(() => {
    keyboardBuffer.attach();
  });

  onBeforeUnmount(() => {
    keyboardBuffer.detach();
  });
};
