import {SyncTarget} from '@/Helpers/syncedStore';
import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';

export enum MediaType {
  imageCode='imageCode',
  imageUrl='imageUrl',
}

export type PromotionSyncTargetState = {
  advertismentPromotions: {
    type: MediaType,
    code: string,
  }[],
}

export class PromotionSyncTarget extends SyncTarget<'Promotion', PromotionSyncTargetState> {
  constructor() {
    super('Promotion', {
      advertismentPromotions: [],
    });
  }

  advertismentPromotions = getter(() => {
    return this.state.advertismentPromotions;
  });
}

const storeIdentifier = 'PromotionSyncTarget';

export const configurePromotionSyncTarget = createConfigureStore<typeof PromotionSyncTarget>(storeIdentifier);
export const usePromotionSyncTarget = createUseStore(PromotionSyncTarget, storeIdentifier);
