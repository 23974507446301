import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-grow" }
const _hoisted_2 = { class: "flex flex-col flex-grow" }
const _hoisted_3 = { class: "list-disc pl-4" }
const _hoisted_4 = { class: "mt-auto mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowTransitions = _resolveComponent("WorkflowTransitions")!
  const _component_HLStep = _resolveComponent("HLStep")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HLStep, { stepType: _ctx.stepType }, {
      default: _withCtx(({}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("ul", _hoisted_3, [
              _createElementVNode("li", null, _toDisplayString(_ctx.workflowStep.messages.stepOne), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.workflowStep.messages.stepTwo), 1)
            ]),
            _withDirectives(_createElementVNode("div", {
              class: "btn btn-primary mt-4",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScan && _ctx.onScan(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.workflowStep.messages.scan), 1)
            ], 512), [
              [_directive_test, 'scan-ticket']
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_WorkflowTransitions, {
              nextDisabled: _ctx.workflowStep.disabledNextStep
            }, null, 8, ["nextDisabled"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["stepType"])
  ]))
}