import {markRaw} from 'vue';
import {KorunkaEntityTypes} from '@/Helpers/korunkaTypes';

export default function resolveDepositComponent(gameType: KorunkaEntityTypes) {
  const components = {
    [KorunkaEntityTypes.Sestka]: () => {
      return markRaw(require('./BasicDeposit.vue').default);
    },
    [KorunkaEntityTypes.DvacetZa20]: () => {
      return markRaw(require('./BasicDeposit.vue').default);
    },
    [KorunkaEntityTypes.HopTrop]: () => {
      return markRaw(require('./BasicDeposit.vue').default);
    },
  };

  return components?.[gameType]?.();
}
