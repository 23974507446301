
function isProblematicKey(event: KeyboardEvent) {
  return (event.ctrlKey && event.shiftKey && event.code === 'KeyC') || // Select element in page
    (event.ctrlKey && event.code === 'KeyJ') || // Open downloads
    (event.ctrlKey && event.shiftKey && event.code === 'KeyB') || // Toggle bookmark bar
    (event.altKey && event.code.startsWith('Digit')) || // change tab
    (event.altKey && event.code.startsWith('Numpad')); // change tab
}

function preventProblematicKeys(event: KeyboardEvent) {
  if (isProblematicKey(event)) {
    event.preventDefault();
  }
}

if (typeof window !== 'undefined') {
  window.addEventListener('keydown', (event: KeyboardEvent) => {
    preventProblematicKeys(event);
  });
}
