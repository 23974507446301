export enum SetDisplayTypes {
  // Main article and components are shown with quantity and price
  Full='Full',
  // Main article only with quantity and price
  SetArticleOnly = 'SetArticleOnly',
  // Main article  with quantity and price and components with quantity
  SetArticleWithPrice = 'SetArticleWithPrice',
  // Main article with quantity and components with quantity and price
  SetArticleWithAmount = 'SetArticleWithAmount',
  // Only components with quantity and price
  ComponentsOnly = 'ComponentsOnly',
  // Main article without price, pricePerItem and quantity
  Voucher = 'Voucher',
}
