import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinNominals} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinNominals';

export class WorkflowStepManualCheckTotalAmount extends workflowStepMixinNominals(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ManualCheckTotalAmount;
  }

  get type() {
    return WorkflowStepManualCheckTotalAmount.type;
  }

  get component() {
    return markRaw(require('./StepManualCheckTotalAmount.vue').default);
  }

  get transitions() {
    return {};
  }
}
