import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import BatchDto from '../BatchDto';
import {ArrayType} from '@designeo/apibundle-js';
import PriceListItemDto from '../PriceListItemDto';
import SetItemDto from '../SetItemDto';


export const ArticlePreviewDtoPropDef = {
  gtin: {
    type: new StringType(),
  },
  batch: {
    type: new EntityType(BatchDto),
  },
  internalNumber: {
    type: new StringType(),
  },
  taxStamp: {
    type: new StringType(),
  },
  priceListItems: {
    type: new ArrayType(new EntityType(PriceListItemDto)),
  },
  setItems: {
    type: new ArrayType(new EntityType(SetItemDto)),
  },
};
export default class GeneratedArticlePreviewDto extends createEntity(ArticlePreviewDtoPropDef) {}
