import {
  RegisterActions,
  RegisterInputEvent,
  RegisterState,
  RegisterStoreErrors,
} from '@/Modules/Register/types';
import {action} from '@designeo/vue-helpers/src/index';
import {RegisterStore} from '@/Modules/Register/store/RegisterStore';
import {TransitionDefinition, getCommonTransitions} from './helpers';

export default function(this: RegisterStore): TransitionDefinition {
  return {
    [RegisterState.ENTER_SERIAL_NUMBER]: {
      ...getCommonTransitions.call(this, [
        RegisterActions.ADD_NUMBER,
        RegisterActions.ADD_CHAR,
        RegisterActions.BACKSPACE,
        RegisterActions.CLEAR,
        RegisterActions.CANCEL,
      ]),
      [RegisterActions.INIT]: action(async () => {
        this.state.inputBuffer = this.state.productFlow.product.serialNo ?? '';
        this.state.insertMode = true;
      }),
      [RegisterActions.ENTER]: action(async (event: RegisterInputEvent) => {
        if (!this.state.inputBuffer) return;

        this.state.productFlow.product.setSerialNoWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          this.state.inputBuffer,
        );

        const isCheckAndChargeTypeVoucherDiscountAndUnique = this.state.productFlow.product
          .validateCheckAndChargeTypeVoucherDiscountWithinContext(this.state.sellDocument);

        if (!isCheckAndChargeTypeVoucherDiscountAndUnique) {
          this.dispatchEvent(
            new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_VOUCHER_DISCOUNT, {
              detail: this.state.productFlow.product.serialNo,
            }),
          );
          return;
        }

        await this.transitionToNextStep();
      }),
      [RegisterActions.SCANNER]: action(async (event: RegisterInputEvent) => {
        this.state.productFlow.product.setSerialNoWithinContext(
          this.state.sellDocument,
          this.state.editOf,
          event.value,
        );

        const isCheckAndChargeTypeVoucherDiscountAndUnique = this.state.productFlow.product
          .validateCheckAndChargeTypeVoucherDiscountWithinContext(this.state.sellDocument);

        if (!isCheckAndChargeTypeVoucherDiscountAndUnique) {
          this.dispatchEvent(
            new CustomEvent(RegisterStoreErrors.DUPLICITY_ERROR_CHECK_AND_CHARGE_TYPE_VOUCHER_DISCOUNT, {
              detail: this.state.productFlow.product.serialNo,
            }),
          );
          return;
        }

        await this.transitionToNextStep();
      }),
    },
  };
}
