import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PromotionDto from '../PromotionDto';
import PromotionListDto from '../PromotionListDto';


export const PromotionBulkDtoPropDef = {
  promotions: {
    type: new ArrayType(new EntityType(PromotionDto)),
  },
  promotionsList: {
    type: new ArrayType(new EntityType(PromotionListDto)),
  },
};
export default class GeneratedPromotionBulkDto extends createEntity(PromotionBulkDtoPropDef) {}
