import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';


export const PrinterVersionDtoPropDef = {
  version: {
    type: new StringType(),
  },
};
export default class GeneratedPrinterVersionDto extends createEntity(PrinterVersionDtoPropDef) {}
