import DocumentDto from '@/Model/Entity/DocumentDto';
import DocumentItemDto from '@/Model/Entity/DocumentItemDto';
import GeneratedCheckResponseDto from './generated/CheckResponseDto';
import {CheckAndChargeCheckResponseObjectTypes} from '@/constants/checkAndChargeResponseObjectTypes';
import PackCanSellDto from '@/Model/Entity/PackCanSellDto';
import TicketCanSellDto from '@/Model/Entity/TicketCanSellDto';
import TicketValidateDto from '@/Model/Entity/TicketValidationDto';
import EasyCoinOrderDto from '@/Model/Entity/EasyCoinOrderDto';
import GIPCheckDto from '@/Model/Entity/GIPCheckDto';
import VoucherCheckDto from '@/Model/Entity/VoucherCheckDto';
import KorunkaCheckDto from '@/Model/Entity/KorunkaCheckDto';
import KorunkaCheckRequestDto from '@/Model/Entity/KorunkaCheckRequestDto';
import KorunkaErrorDto from '@/Model/Entity/KorunkaErrorDto';
import {map} from 'lodash-es';

export default class CheckResponseDto extends GeneratedCheckResponseDto {
  get entity() {
    switch (<CheckAndChargeCheckResponseObjectTypes> this.responseObjectType) {
    case CheckAndChargeCheckResponseObjectTypes.PackCanSellDto:
      return new PackCanSellDto(this.responseObject);
    case CheckAndChargeCheckResponseObjectTypes.TicketCanSellDto:
      return new TicketCanSellDto(this.responseObject);
    case CheckAndChargeCheckResponseObjectTypes.TicketValidationDto:
      return new TicketValidateDto(this.responseObject);

    case CheckAndChargeCheckResponseObjectTypes.EasyCoinOrderDto:
      return new EasyCoinOrderDto(this.responseObject);

    case CheckAndChargeCheckResponseObjectTypes.GIPCheckDto:
      return new GIPCheckDto(this.responseObject);

    case CheckAndChargeCheckResponseObjectTypes.VoucherCheckDto:
      return new VoucherCheckDto(this.responseObject);

    case CheckAndChargeCheckResponseObjectTypes.KorunkaCheckDto:
      return new KorunkaCheckDto(this.responseObject);

    case CheckAndChargeCheckResponseObjectTypes.KorunkaErrorDto:
      return new KorunkaErrorDto(this.responseObject);
    }
  }

  get isSuccessful() {
    if (this.entity) {
      return this.success && this.entity.canBeSold;
    }

    return this.success;
  }

  get errorReason() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCashierMessage ??
        this.errorMessage?.errorCashierMessage ??
        null;
    }

    return this.entity.errorReason ?? null;
  }

  get errorReasonForCustomer() {
    if (this.isSuccessful) {
      return null;
    }

    if (!this.entity) {
      return this.responseObject?.errorMessage?.errorCustomerMessage ??
        this.errorMessage?.errorCustomerMessage ??
        null;
    }

    return this.entity.errorReasonForCustomer ?? null;
  }

  get canBeSold() {
    if (!this.success) {
      return false;
    }

    return this.entity?.canBeSold;
  }

  process(document: DocumentDto, itemAtIndex: number, displayItem: DocumentItemDto) {
    if (this.isSuccessful) {
      displayItem.setCheckAndChargeShouldCancelWithinContext(
        document,
        itemAtIndex,
        false,
      );
    }

    if (this.isSuccessful && this.entity instanceof PackCanSellDto) {
      displayItem.setQuantityWithinContext(
        document,
        itemAtIndex,
        this.entity.numberOfTickets,
      );

      displayItem.setPriceNormalWithinContext(
        document,
        itemAtIndex,
        this.entity.packPrice / this.entity.numberOfTickets,
      );
    } else if (this.isSuccessful && this.entity instanceof TicketCanSellDto) {
      displayItem.setPriceNormalWithinContext(
        document,
        itemAtIndex,
        this.entity.ticketPrice,
      );
    } else if (this.isSuccessful && this.entity instanceof TicketValidateDto) {
      displayItem.setPriceNormalWithinContext(
        document,
        itemAtIndex,
        this.entity.winnings,
      );
    } else if (this.isSuccessful && this.entity instanceof VoucherCheckDto) {
      displayItem.setDescriptionWithinContext(
        document,
        itemAtIndex,
        `${displayItem.description} (${this.entity.name})`,
      );
    } else if (this.isSuccessful && this.entity instanceof KorunkaCheckDto) {
      for (const group of document.itemsGroupedBySets) {
        if (!group.mainItem.isServiceTypeKorunka) {
          continue;
        }

        if (itemAtIndex === group.index) {
          continue;
        }

        /**
         * Check from this article is used to validate other articles
         */

        group.editableItem.setCheckRequestWithinContext(
          document,
          group.index,
          new KorunkaCheckRequestDto({
            ...displayItem.checkRequest,
            metadata: group.editableItem.checkRequest.metadata,
          }),
        );

        group.editableItem.setCheckResponseWithinContext(
          document,
          group.index,
          this,
          {process: false},
        );
      }
    } else if (!this.isSuccessful && this.entity instanceof KorunkaErrorDto) {
      for (const group of document.itemsGroupedBySets) {
        if (!group.mainItem.isServiceTypeKorunka) {
          continue;
        }

        if (itemAtIndex === group.index) {
          continue;
        }

        /**
         * Check from this article is used to validate other articles
         */

        group.editableItem.setCheckRequestWithinContext(
          document,
          group.index,
          new KorunkaCheckRequestDto({
            ...displayItem.checkRequest,
            metadata: group.editableItem.checkRequest.metadata,
          }),
        );

        group.editableItem.setCheckResponseWithinContext(
          document,
          group.index,
          this,
          {process: false},
        );
      }
    }
  }

  hasErrorFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      const korunkaEntity: import('@/Helpers/korunka').KorunkaEntities = require('@/Helpers/korunka')
        .deserializeKorunkaEntity(editableItem.checkRequest.metadata);
      const entityOperationIds: string[] = editableItem?.chargeRequest?.korunkaRequest?.bondsToEntities?.[korunkaEntity.id] ?? [];
      const errorOperationIds = map(this.entity.errors ?? [], ({operationId}) => operationId);
      const hasError = entityOperationIds.some((operationId) => errorOperationIds.includes(operationId));

      return hasError;
    }

    return false;
  }

  getErrorMessageDetailFromGroup(document: DocumentDto, itemAtIndex: number) {
    const editableItem = document.itemsGroupedBySets[itemAtIndex].editableItem;

    if (this.entity instanceof KorunkaErrorDto) {
      return `${this.errorReason}`;
    }

    return !this.errorReason ?
      `${editableItem.description}` :
      `${editableItem.description}, ${this.errorReason}`;
  }
}
