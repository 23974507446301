import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-row gap-2 items-center" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleLabelBatch = _resolveComponent("ArticleLabelBatch")!
  const _component_TaxStamp = _resolveComponent("TaxStamp")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "rootRef",
    class: _normalizeClass(["flex flex-row items-center justify-between px-2 py-1", {
      'bg-basic-light': _ctx.item.isActive,
    }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.item.entity)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.entity.description), 1),
        _createVNode(_component_ArticleLabelBatch, {
          item: _ctx.item.entity,
          sm: true
        }, null, 8, ["item"]),
        (_ctx.item.entity.taxStamp)
          ? (_openBlock(), _createBlock(_component_TaxStamp, {
              key: 0,
              value: _ctx.item.entity.taxStamp,
              size: "w-4 h-4 text-xs"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.item.entity.internalNumber) + " / " + _toDisplayString(_ctx.item.entity.gtin) + " ", 1),
        (_ctx.item.hasVisiblePrices)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" / " + _toDisplayString(_ctx.currencyFormat(_ctx.item.entity.price)), 1)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.entity.quantity) + " " + _toDisplayString(_ctx.item.entity.unitByEnum), 1)
  ], 2))
}