import {
  AxiosAdapter,
  AxiosRequestConfig,
} from 'axios';
import {useTrainingStore} from '@/Modules/Training/store/TrainingStore';
import {connections} from '@/Helpers/signalR';
import {PrinterWSEvents} from '@/Modules/Core/types';
import {DocumentDto} from '@/Model/Entity';
import {wait} from '@designeo/js-helpers';
import {DocumentTypes} from '@/constants/documentTypes';
const settle = require('axios/lib/core/settle');

const invokeNotificationConnectionMessage = async (
  event,
  args,
  {connectionKey = 'default:/api/hubs/notifications'} = {},
) => {
  const handlers = (await connections[connectionKey])?._methods?.[event.toLowerCase()] ?? [];
  for (const handler of handlers) {
    await handler(...args);
  }
};

const createDefaultResponse = (config) => ({
  status: 200,
  statusText: '',
  headers: {},
  config,
  request: {},
});

const mockByActionName = {
  // 'api.3rd-party.check': async (config: AxiosRequestConfig) => ({
  //   ...createDefaultResponse(config),
  //   data: {
  //     ...config.data,
  //     checkAndChargeRequestId: guid(),
  //   },
  // }),
  // 'api.3rd-party.charge': async (config: AxiosRequestConfig) => ({
  //   ...createDefaultResponse(config),
  //   data: null,
  // }),
  // 'api.3rd-party.revoke': async (config: AxiosRequestConfig) => ({
  //   ...createDefaultResponse(config),
  //   data: null,
  // }),
  // 'api.customers.verify.phone.by.pin': async (config: AxiosRequestConfig) => ({
  //   ...createDefaultResponse(config),
  //   status: 204,
  //   data: null,
  // }),
  // 'api.customers.send.pin': async (config: AxiosRequestConfig) => ({
  //   ...createDefaultResponse(config),
  //   status: 204,
  //   data: {},
  // }),
  'api.document.create': async (config: AxiosRequestConfig) => {
    try {
      return {
        ...createDefaultResponse(config),
        status: 201,
        data: null,
      };
    } finally {
      const documentDto = <DocumentDto['_data']>JSON.parse(config.data);

      if (!documentDto.isCanceled && documentDto.documentType === DocumentTypes.SellDocument) {
        await wait(2000, null);
        await invokeNotificationConnectionMessage(PrinterWSEvents.PROCESSED_DOC_MESSAGE, [
          {
            result: {
              statusCode: 0,
              printout: {
                // eslint-disable-next-line max-len
                htmlBase64: 'PHByZT4NCjxzcGFuIHN0eWxlPSJtYXJnaW4tbGVmdDoyMHB4O2ZvbnQtc2l6ZToxLjBlbTsiPi0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLTwvc3Bhbj4NCjxzcGFuIHN0eWxlPSJtYXJnaW4tbGVmdDoyMHB4O2ZvbnQtc2l6ZToxLjBlbTsiPlXEjXRlbmthIMSNLjogVGVzdG92YWPDrSDDusSNdGVua2E8L3NwYW4+DQo8c3BhbiBzdHlsZT0ibWFyZ2luLWxlZnQ6MjBweDtmb250LXNpemU6MS4wZW07Ij4tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS0tLS08L3NwYW4+DQo8c3BhbiBzdHlsZT0ibWFyZ2luLWxlZnQ6MjBweDtmb250LXNpemU6MS4wZW07Ij4gICAgPC9zcGFuPg0KPHNwYW4gc3R5bGU9Im1hcmdpbi1sZWZ0OjIwcHg7Zm9udC1zaXplOjEuMGVtOyI+ICAgICAgICAgIETEm2t1amVtZSBWw6FtIHphIG7DoWt1cDwvc3Bhbj4NCjxzcGFuIHN0eWxlPSJtYXJnaW4tbGVmdDoyMHB4O2ZvbnQtc2l6ZToxLjBlbTsiPjwvc3Bhbj4NCjwvcHJlPg0K',
              },
            },
          },
          // @ts-ignore
          documentDto.header.uniqueidentifier,
        ]);
      }
    }
  },
  // ...(fromPairs(map(['api.customers.update', 'api.customers.create'], (key) => {
  //   return [
  //     key,
  //     async (config: AxiosRequestConfig) => ({
  //       ...createDefaultResponse(config),
  //       data: {
  //         createdDate: '2021-07-08T06:41:56.235071Z',
  //         customerNumber: '44441',
  //         dayOfBirth: 10,
  //         editedDate: '2021-07-08T06:41:56.242014Z',
  //         email: 'karel.srna@seznam.cz',
  //         firstName: 'Karel',
  //         gdprConsentDate: '2021-04-21T14:30:00Z',
  //         gdprConsentPlace: 'Krakov',
  //         id: '3ff4d018-6703-4659-8e8b-6af7cbf7616f',
  //         lastName: 'Srna',
  //         monthOfBirth: 5,
  //         phone: '+420607555666',
  //         sex: 'Male',
  //         state: 'Registered',
  //         yearOfBirth: 1980,
  //       },
  //     }),
  //   ];
  // }))),
};

export const withTrainingMockAdapter = (axiosAdapter: AxiosAdapter): AxiosAdapter => (config: AxiosRequestConfig) => {
  const trainingStore = useTrainingStore();
  if (
    trainingStore.trainingIsActive.value &&
    Object.prototype.hasOwnProperty.call(mockByActionName, config.headers.ActionName)
  ) {
    return new Promise((resolve, reject) => {
      mockByActionName[config.headers.ActionName](config)
        .then((data) => {
          settle(resolve, reject, data);
        })
        .catch(reject);
    });
  }

  return new Promise((resolve, reject) => {
    axiosAdapter(config).then((response) => {
      settle(resolve, reject, response);
    })
      .catch(reject);
  });
};
