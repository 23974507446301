import {createEntity} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import ShopPointOfSale from '../ShopPointOfSale';


export const LotteryTurnoverErrorsDtoPropDef = {
  errorPos: {
    type: new ArrayType(new EntityType(ShopPointOfSale)),
  },
};
export default class GeneratedLotteryTurnoverErrorsDto extends createEntity(LotteryTurnoverErrorsDtoPropDef) {}
