import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import UserStatus from '../UserStatus';
import CashierDto from '../CashierDto';


export const CashierVerifyResultDtoPropDef = {
  status: {
    type: new EntityType(UserStatus),
  },
  cashier: {
    type: new EntityType(CashierDto),
  },
};
export default class GeneratedCashierVerifyResultDto extends createEntity(CashierVerifyResultDtoPropDef) {}
