
import {
  computed,
  defineComponent,
} from 'vue';
import Modal from '../../../Components/Modal.vue';
import {useIncorrectRestartStore} from '@/Modules/Core/store/IncorrectRestartStore';
import AsyncButton from '@/Components/AsyncButton.vue';
import {
  ContentSellDocument,
  ContentUnknown,
  ContentWorkflow,
} from '@/Modules/IncorrectRestart/IncorrectRestart/Contents';
import ModalDetachedButtons from '@/Components/ModalDetachedButtons.vue';

export default defineComponent({
  name: 'ModalIncorrectRestart',
  components: {
    ModalDetachedButtons,
    AsyncButton,
    Modal,
  },
  setup() {
    const incorrectRestartStore = useIncorrectRestartStore();

    const subject = computed(() => {
      if (incorrectRestartStore.canCancelSellDocument.value) {
        return ContentSellDocument;
      }

      if (incorrectRestartStore.canCancelWorkflow.value) {
        return ContentWorkflow;
      }

      return ContentUnknown;
    });

    return {
      incorrectRestartStore,
      subject,
    };
  },
});
