import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "pt-4 text-2xl pb-3 text-danger font-bold" }
const _hoisted_2 = { class: "pb-4 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    invalidateRoute: false,
    buttonClose: false,
    zIndex: _ctx.ZLayer.critical
  }, {
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('posDeactivatedState.title')), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('posDeactivatedState.body')), 1),
        _createVNode(_component_ModalDetachedButtons, null, {
          default: _withCtx(() => [
            (_ctx.canShutdown)
              ? (_openBlock(), _createBlock(_component_AsyncButton, {
                  key: 0,
                  test: 'posDeactivatedStateButton',
                  type: "button",
                  class: "btn-basic bg-basic-light btn-md",
                  loading: _ctx.loading,
                  onClick: _ctx.shutdown
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('posDeactivatedState.shutdown')), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 512), [
        [_directive_test, 'posDeactivatedStateModal']
      ])
    ]),
    _: 1
  }, 8, ["open", "zIndex"]))
}