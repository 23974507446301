import {
  action,
  createConfigureStore,
  createUseStore,
  getter,
} from '@designeo/vue-helpers';
import {
  Configuration,
  DocumentPaymentDto,
  PaymentButton,
} from '../../../Model/Entity';
import {KeyboardBuffer, ScannerStxEtxOptions} from '../../Register/services/KeyboardBuffer';
import {
  filter,
  find,
  isNil,
  mapKeys,
} from 'lodash-es';
import {DocumentTypes} from '@/constants/documentTypes';
import {useSignalR} from '@/Helpers/signalR';
import {CoreWSEvents} from '@/Modules/Core/types';
import {PersistentStore} from '@/Helpers/PersistentStore';
import {sanitizeApiSearch} from '@/Helpers/sanitize';
import {DocumentItemTypes} from '@/constants/documentItemTypes';
import {DocumentItemDto} from '@/Model/Entity';
import {useSynchronizationStore} from '@/Modules/Core/store/SynchronizationStore';
import {loggerTags} from '@/Helpers/logger';

export interface IConfigurationStore {
  configuration: Configuration,
  syncAvailable: boolean,
}

export class ConfigurationStore extends PersistentStore<IConfigurationStore> {
  constructor() {
    super({
      configuration: null,
      syncAvailable: false,
    }, require('@/Helpers/persist').configurationStoreStatePersist);
  }

  setConfiguration = action(async (configuration: Configuration) => {
    this.state.configuration = configuration;

    loggerTags.set('RegistrationId', configuration.general?.pos?.registrationId);
    loggerTags.set('Middleware', configuration.general?.middleware?.name);
    loggerTags.set('PosVersion', configuration.general?.appVersion?.current);
    loggerTags.set('ShopCode', configuration.general?.pos?.shop?.code);
    loggerTags.set('ShopId', configuration.general?.pos?.shop?.id);
    loggerTags.set('PosCode', configuration.general?.pos?.code);
    loggerTags.set('PosId', configuration.general?.pos?.id);
    loggerTags.set('Configured', configuration.general?.configured.value);

    KeyboardBuffer.scannerMode = (
      this.state.configuration.hardware?.barcode?.mode ?? 'default'
    ) as ScannerStxEtxOptions;
  })

  getRoundingArticle = action(async () => {
    if (!this.state.configuration?.general?.roundingArticleId) {
      return null;
    }

    if (this.state.configuration.general.roundingArticleId === DocumentItemTypes.numberRounding) { // test case
      return new DocumentItemDto({
        internalNumber: DocumentItemTypes.numberRounding,
        netto: true,
      });
    }

    const {documentItems} = sanitizeApiSearch(await (require('@/Model/Action')).apiSearch({
      params: {
        code: this.state.configuration.general.roundingArticleId,
      },
    }));

    if (!documentItems.length) {
      return null;
    }

    return documentItems[0];
  })

  createPayment = action((paymentId = this.defaultPaymentId.value) => {
    if (isNil(paymentId)) return;

    const paymentType = this.paymentTypesByPaymentId.value[paymentId];

    return new DocumentPaymentDto({
      paymentID: paymentId,
      ...(paymentType ? {
        currency: paymentType.currency,
        description: paymentType.description,
        paymentType: paymentType.type?.value,
      } : {}),
      ...(paymentType && paymentType.isCardMethod && paymentType.hasConfirmationMethodTerminal ? {
        payTerminalVirtualId: paymentType.payTerminalVirtualIdWithFallback,
      } : {}),
      ...(paymentType && paymentType.sapTransactionCode ? {
        sapTransactionCode: paymentType.sapTransactionCode,
      } : {}),
    });
  })

  cashRegisterConfigurationErrors = getter(() => {
    const errors = [];
    if (!this.configuration.value?.['_data'] || Object.keys(this.configuration.value?.['_data']).length === 0) {
      errors.push({
        msg: 'emergency.invalidConfiguration',
        invalidSection: 'configuration',
        value: `${JSON.stringify(this.configuration.value?.['_data'])}`,
      });
      return errors;
    }
    if (!this.configuration.value?.general?.localCurrency) {
      errors.push({
        msg: 'emergency.invalidConfiguration',
        invalidSection: 'general.localCurrency',
        value: `${JSON.stringify(this.configuration.value?.general?.localCurrency)}`,
      });
    }
    if (!this.configuration.value?.enums?.vat) {
      errors.push({
        msg: 'emergency.invalidConfiguration',
        invalidSection: 'enums.vat',
        value: `${JSON.stringify(this.configuration.value?.enums?.vat)}`,
      });
    }
    if (!this.configuration.value?.enums?.currencies?.length) {
      errors.push({
        msg: 'emergency.invalidConfiguration',
        invalidSection: 'enums.currencies',
        value: `${JSON.stringify(this.configuration.value?.enums?.currencies)}`,
      });
    }
    return errors;
  })

  defaultPaymentId = getter(() => {
    return this.state.configuration?.features?.payment?.defaultPaymentMethod?.paymentId ?? 1;
  })

  getPaymentByPaymentCode = action((code) => {
    const paymentByCode = mapKeys(this.state.configuration.buttons.paymentButtons, 'code');

    return this.createPayment(paymentByCode[code].paymentId);
  })

  setSyncAvailable = action(() => {
    this.state.syncAvailable = true;
  })

  ensureApplySync = action(async () => {
    if (this.state.syncAvailable) {
      await this.applySync();
    }
  })

  applySync = action(async () => {
    const {notificationsConnection} = useSignalR();
    useSynchronizationStore().createSynchronizationProcess();
    await notificationsConnection.dispatchEvent(CoreWSEvents.APPLY_SYNC);
    this.state.syncAvailable = false;
  })

  getMediaUrl = action((code) => {
    return find(this.state.configuration.assets, {code})?.['mediaUrl'];
  })

  configuration = getter(() => this.state.configuration)

  paymentButtonsByPaymentId = getter(
    () => mapKeys(this.state.configuration.buttons.paymentButtons, 'paymentId'),
  )

  paymentButtonsByCode = getter(
    () => mapKeys(this.state.configuration.buttons.paymentButtons, 'code'),
  )

  exchangeRatesByCurrencies = getter(() => mapKeys(
    this.state.configuration.enums.exchangeRates,
    ({baseCurrency, quoteCurrency}) => `${baseCurrency}:${quoteCurrency}`,
  ))

  currenciesByCurrencySymbol = getter(() => mapKeys(
    this.state.configuration.enums.currencies,
    'symbol',
  ))

  paymentTypesByPaymentId = getter(() => mapKeys(this.state.configuration.enums.paymentTypes, 'paymentId'))

  unitsByCode = getter(() => mapKeys(this.state.configuration.enums.units, 'code'))

  stornoReasonsByReasonId = getter(() => mapKeys(this.state.configuration.features.storno.stornoReasons, 'reasonId'))

  localCurrency = getter(
    () => this.currenciesByCurrencySymbol.value[this.state.configuration.general.localCurrency],
  )

  financialTransactionsByCode = getter((): {[key: string]: {[key: string]: any}} => (
    mapKeys(
      filter(this.state.configuration.enums.transactions, {transactionType: DocumentTypes.FinDocument}),
      'code',
    )
  ))

  logisticTransactionsByCode = getter((): {[key: string]: {[key: string]: any}} => (
    mapKeys(
      filter(this.state.configuration.enums.transactions, {transactionType: DocumentTypes.LogisticDocument}),
      'code',
    )
  ))

  vatByCode = getter(() => this.state.configuration.enums.vat)

  mainClubCode = getter(() => this.state.configuration?.features?.loyalty?.mainClubCode)

  scannerLanguage = getter(() => this.state.configuration?.hardware?.barcode?.scannerLanguage)
}

const storeIdentifier = 'ConfigurationStore';

export const configureConfigurationStore = createConfigureStore<typeof ConfigurationStore>(storeIdentifier);
export const useConfigurationStore = createUseStore(ConfigurationStore, storeIdentifier);
