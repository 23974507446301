import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_ModalDetachedBody = _resolveComponent("ModalDetachedBody")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.debouncedOpen,
    name: "importantUnreadMessage",
    invalidateRoute: false,
    onClose: _ctx.onClose
  }, {
    body: _withCtx(() => [
      _createVNode(_component_ModalDetachedClose, { onClose: _ctx.onClose }, null, 8, ["onClose"]),
      _createVNode(_component_ModalDetachedBody, { type: _ctx.type }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.message), 1)
        ]),
        _: 1
      }, 8, ["type"]),
      _createVNode(_component_ModalDetachedButtons, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("button", {
            class: "btn-md",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('action.close')), 1)
          ], 512), [
            [_directive_test, 'modalConfirm']
          ]),
          _withDirectives(_createElementVNode("button", {
            class: _normalizeClass(["btn-md", {
            'btn-outline-danger': _ctx.type === _ctx.ConfirmTypes.error,
            'btn-outline-warning': _ctx.type === _ctx.ConfirmTypes.warning,
          }]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.redirectToMessages && _ctx.redirectToMessages(...args)))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('apps.messages.unreadMessages.button')), 1)
          ], 2), [
            [_directive_test, 'modalConfirm']
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}