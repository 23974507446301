import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-4 text-2xl pb-3 text-warning font-bold" }
const _hoisted_2 = { class: "pb-4 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!
  const _component_Modal = _resolveComponent("Modal")!
  const _directive_test = _resolveDirective("test")!

  return (_openBlock(), _createBlock(_component_Modal, {
    open: _ctx.open,
    invalidateRoute: false,
    buttonClose: false,
    type: "xl",
    zIndex: _ctx.ZLayer.priorityModal
  }, {
    body: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_ctx.message), 1)
        ]),
        _createVNode(_component_ModalDetachedButtons, { single: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_AsyncButton, {
              test: 'printerStatusChecked',
              type: "button",
              class: "btn-basic bg-basic-light btn-md",
              onClick: () => _ctx.onClose()
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('action.continue')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ], 512), [
        [_directive_test, 'printerStatusModal']
      ])
    ]),
    _: 1
  }, 8, ["open", "zIndex"]))
}