import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FiscalCommands from '../FiscalCommands';
import {NumberType} from '@designeo/apibundle-js';


export const NewDocumentNumberCounterDtoPropDef = {
  documentType: {
    type: new EntityType(FiscalCommands),
  },
  number: {
    type: new NumberType(),
  },
};
export default class GeneratedNewDocumentNumberCounterDto extends createEntity(NewDocumentNumberCounterDtoPropDef) {}
