import {createEntity} from '@designeo/apibundle-js';
import {NumberType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import TurnoverByVatDto from '../TurnoverByVatDto';


export const TurnoverReportDtoPropDef = {
  turnoverBrutto: {
    type: new NumberType(),
  },
  turnoverNetto: {
    type: new NumberType(),
  },
  discounts: {
    type: new ArrayType(new NumberType()),
  },
  turnoverByVats: {
    type: new ArrayType(new EntityType(TurnoverByVatDto)),
  },
};
export default class GeneratedTurnoverReportDto extends createEntity(TurnoverReportDtoPropDef) {}
