import {RegisterRoutesOptions} from '@/ModuleSystem';
import {PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS, PERMISSION_MESSAGES_APP_ACCESS} from '@/constants/aclKeys';
import {useExternalApps} from '@/Helpers/externalApps';
import {isElectron} from '@/Helpers/app';
import PermissionTargetDto from '@/Model/Entity/PermissionTargetDto';
import {useTrainingStore} from '@/Modules/Training/store/TrainingStore';
import * as aclKeys from '@/constants/aclKeys';
import {AclParameters} from '@/constants/aclParameters';
import {default as inventoryRoutes} from './Inventory/routes';
import {isActiveFeatureEshop, isActiveFeatureMessages} from '@/Helpers/features';

export default [
  /**
   * Menus
   */
  {
    path: '/apps/:sub*',
    name: 'apps',
    component: () => import('./Home/MenuPage.vue'),
    props: true,
  },

  /**
   * Detail pages
   */
  {
    path: '/apps/technical-status',
    name: 'apps-technical-status',
    component: () => import('./PosTechnicalStatus/PosTechnicalStatusPage.vue'),
  },
  {
    path: '/apps/technical-status/configuration',
    name: 'apps-technical-status-configuration',
    component: () => import('./PosTechnicalStatus/PosConfigurationPage.vue'),
  },
  {
    path: '/apps/technical-status/promotions',
    name: 'apps-technical-status-promotions',
    component: () => import('./PosTechnicalStatus/PosPromotionsPage.vue'),
  },
  {
    path: '/apps/additional-settings',
    name: 'apps-additional-settings',
    meta: {
      acl: PermissionTargetDto
        .create(PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS),
    },
    component: () => import('./AdditionalSettings/AdditionalSettingsPage.vue'),
  },
  {
    path: '/apps/additional-settings/icons',
    meta: {
      acl: PermissionTargetDto
        .create(aclKeys.PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS)
        .withParameters([AclParameters.iconsDetail]),
    },
    name: 'apps-icons',
    component: () => import('./Icons/IconsPage.vue'),
  },
  {
    path: '/apps/number-series',
    meta: {
      acl: PermissionTargetDto
        .create(aclKeys.PERMISSION_ADDITIONAL_SETTINGS_APP_ACCESS)
        .withParameters([AclParameters.numberSeries]),
      onlineMandatory: true,
    },
    name: 'apps-number-series',
    component: () => import('./NumberSeries/NumberSeriesPage.vue'),
  },
  {
    path: '/apps/technical-status/plu',
    name: 'apps-technical-status-plu',
    component: () => import('@/Modules/Apps/ArticleManagement/ArticleManagementPage.vue'),
  },
] as RegisterRoutesOptions;

export const routesWithTitle = [
  /**
   * Detail pages with different layout
   */
  {
    path: '/apps/documents/:receipt*',
    name: 'apps-documents',
    component: () => import('./ReferentialDocuments/ReferentialDocumentsPage.vue'),
    props: true,
  },
  {
    path: '/apps/eshop/orders/:order*',
    name: 'apps-eshop-orders',
    component: () => import('@/Modules/Apps/EshopOrders/EshopOrdersPage.vue'),
    meta: {
      disableEscapeListener: true,
    },
    beforeEnter(to, from, next) {
      if (!isActiveFeatureEshop()) {
        next('/404');
        return;
      }

      next();
    },
    props: true,
  },
  {
    path: '/apps/messages',
    name: 'apps-messages',
    meta: {
      acl: PERMISSION_MESSAGES_APP_ACCESS,
      disableEscapeListener: true,
    },
    component: () => import('./PosMessage/MessagesPage.vue'),
    beforeEnter(to, from, next) {
      if (!isActiveFeatureMessages()) {
        next('/404');
        return;
      }

      next();
    },
  },
  {
    path: '/apps/journal',
    name: 'apps-journal',
    component: () => import('./Journal/JournalPage.vue'),
  },
  {
    path: '/apps/static-text-prints',
    name: 'apps-static-text-prints',
    component: () => import('./StaticTextPrints/StaticTextPrintsPage.vue'),
  },
  {
    path: '/apps/pos-state-report',
    name: 'apps-pos-state-report',
    component: () => import('@/Modules/Apps/PosStateReport/PosStateReportPage.vue'),
  },
  {
    path: '/apps/pos-article-sales-report',
    name: 'apps-pos-article-sales-report',
    component: () => import('@/Modules/Apps/PosArticleSalesReport/PosArticleSalesReportPage.vue'),
  },
  {
    path: '/apps/remote-app/:code',
    name: 'remote-app',
    async beforeEnter(to, from, next) {
      if (!isElectron() || useTrainingStore().trainingIsActive.value) {
        next('/404');
        return;
      } else {
        await useExternalApps().open(to.params.code as string);
      }

      next(false);
    },
  },
  {
    path: '/apps/pos-stock-state-report',
    name: 'apps-pos-stock-state-report',
    meta: {
      disableEscapeListener: true,
    },
    component: () => import('./PosStockState/PosStockStateReportPage.vue'),
  },
  ...inventoryRoutes,
] as RegisterRoutesOptions;

export const routesWithoutTitle = [] as RegisterRoutesOptions;
