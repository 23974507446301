import {
  onBeforeUnmount, onMounted,
} from 'vue';
import {DateTime} from 'luxon';
import {groupBy, has} from 'lodash-es';

type Units = 'second' | 'minute' | 'hour' | 'day';
type Callback = ()=> (Promise<void>) | void;

export const useTimeBasedEvents = (listeners: Array<{unit: Units, callback: Callback}> = []) => {
  let timestamp = new Date();
  let interval = null;

  const executeListenersByUnit = (unit: Units) => {
    for (const listener of listeners) {
      if (listener.unit === unit) {
        listener.callback();
      }
    }
  };

  const getTickByListeners = () => {
    const listenersByUnit = groupBy(listeners, 'unit');

    return {
      'day': 1000 * 60,
      'hour': 1000 * 60,
      'minute': 1000,
      'second': 100,
    }[(() => {
      if (has(listenersByUnit, 'second')) {
        return 'second';
      }

      if (has(listenersByUnit, 'minute')) {
        return 'minute';
      }

      if (has(listenersByUnit, 'hour')) {
        return 'hour';
      }

      if (has(listenersByUnit, 'day')) {
        return 'day';
      }

      return null;
    })()] ?? null;
  };

  const createLoop = () => {
    clearInterval(interval);

    const tick = getTickByListeners();

    if (!tick) {
      return null;
    }

    return setInterval(() => {
      const now = new Date();

      if (DateTime.fromJSDate(now).day !== DateTime.fromJSDate(timestamp).day) {
        executeListenersByUnit('second');
        executeListenersByUnit('minute');
        executeListenersByUnit('hour');
        executeListenersByUnit('day');
      } else if (DateTime.fromJSDate(now).hour !== DateTime.fromJSDate(timestamp).hour) {
        executeListenersByUnit('second');
        executeListenersByUnit('minute');
        executeListenersByUnit('hour');
      } else if (DateTime.fromJSDate(now).minute !== DateTime.fromJSDate(timestamp).minute) {
        executeListenersByUnit('second');
        executeListenersByUnit('minute');
      } else if (DateTime.fromJSDate(now).second !== DateTime.fromJSDate(timestamp).second) {
        executeListenersByUnit('second');
      }

      timestamp = now;
    }, tick);
  };

  interval = createLoop();

  onBeforeUnmount(() => {
    clearInterval(interval);
    listeners = [];
  });
};
