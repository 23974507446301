import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';

export class WorkflowStepGenericSuccess extends WorkflowStep {
  static get type() {
    return WorkflowStepTypes.GenericSuccess;
  }

  get type() {
    return WorkflowStepGenericSuccess.type;
  }

  get component() {
    return markRaw(require('./StepGenericSuccess.vue').default);
  }

  get transitions() {
    return {};
  }
}
