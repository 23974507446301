import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  workflowStepMixinWarehouseOperation,
} from '@/Modules/Workflow/Step/StepMixins/WorkflowStepMixinWarehouseOperation';
import {action} from '@designeo/vue-helpers';
import {
  WarehouseOperationPlantDirections,
  WarehouseOperations,
  WarehouseOperationStepFieldGroups,
} from '@/constants/warehouseOperations';
import {PlantDto} from '@/Model/Entity';

export class WorkflowStepWarehouseOperationPlant extends workflowStepMixinWarehouseOperation(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.WarehouseOperationPlant;
  }

  get type() {
    return WorkflowStepWarehouseOperationPlant.type;
  }

  get component() {
    return markRaw(require('./StepWarehouseOperationPlant.vue').default);
  }

  get layout() {
    return markRaw(require('../../Workflow/layout/custom/warehouseOperations/Layout.vue').default);
  }

  get disabledNextStep() {
    return !this.plant;
  }

  get plant() {
    if (this.warehouseOperationType !== WarehouseOperations.reloadingOfGoodsTypeDispense) {
      return this.operationEntityPropPlant;
    }

    if (this.step.direction === WarehouseOperationPlantDirections.source) {
      return this.operationEntityPropSourcePlant;
    }

    if (this.step.direction === WarehouseOperationPlantDirections.destination) {
      return this.operationEntityPropTargetPlant;
    }

    throw new Error('Unknown direction!');
  }

  set plant(plant: PlantDto) {
    if (this.warehouseOperationType !== WarehouseOperations.reloadingOfGoodsTypeDispense) {
      this.operationEntityPropPlant = plant;
      return;
    }

    if (this.step.direction === WarehouseOperationPlantDirections.source) {
      this.operationEntityPropSourcePlant = plant;
      return;
    }

    if (this.step.direction === WarehouseOperationPlantDirections.destination) {
      this.operationEntityPropTargetPlant = plant;
      return;
    }

    throw new Error('Unknown direction!');
  }

  createFieldDataPath(field: string) {
    switch (this.warehouseOperationType) {
    case WarehouseOperations.reloadingOfGoodsTypeDispense:
      if (!this.step.direction) {
        return `${WarehouseOperationStepFieldGroups.plant}.${field}`;
      }

      return [
        WarehouseOperationStepFieldGroups.plant,
        this.step.direction ?? this.index,
        field,
      ].join('.');
    case WarehouseOperations.receiptOfGoodsFromExternalSupplier:
    case WarehouseOperations.orderOfGoodsFromDistributionCenter:
      return `${WarehouseOperationStepFieldGroups.plant}.${field}`;
    }
  }

  get searchString() {
    return this.getFieldValue(this.createFieldDataPath(WorkflowStepField.searchString), '');
  }

  get transitions() {
    return {
      ...this.withFieldActions(this.createFieldDataPath(WorkflowStepField.searchString), (fieldActions) => ({
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.ADD_CHAR]: action((event: WorkflowInputEvent) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action((event: WorkflowInputEvent<any>) => {
          if (!this.disabledNextStep) {
            this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
          }
        }),
      })),
    };
  }
}
