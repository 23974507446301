import {emitTestEvent} from '@/Helpers/testEvent';
import {AppLoaderEvent, PrinterWSEvents} from '@/Modules/Core/types';
import {
  WorkflowStepErrors,
  WorkflowStepEvents,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {TestEvent} from '@/tests/e2e/helpers/testEvents';
import {markRaw} from 'vue';
import {apiDocumentCreate} from '@/Model/Action';
import PrinterResult from '@/Model/Entity/PrinterResult';
import {workflowStepMixinBalanceStock} from '../StepMixins/WorkflowStepMixinBalanceStock';
import {useSignalR} from '@/Helpers/signalR';
import {OutputType} from '@/Model/Entity';
import {OutputTypes} from '@/constants/outputTypes';
import {isActiveFeaturePrintDisplayOnScreen} from '@/Helpers/features';

export class WorkflowStepApiCreateBalanceStockSellDocument extends workflowStepMixinBalanceStock(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.ApiCreateBalanceStockSellDocument;
  }

  get type() {
    return WorkflowStepApiCreateBalanceStockSellDocument.type;
  }

  get component() {
    return markRaw(require('../TechnicalStep.vue').default);
  }

  get printTemplateTypeOverride(): OutputTypes {
    return this.step.printTemplateType ?? null;
  }

  async beforeEnter() {
    try {
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.ON));

      if (this.stepFinished || !this.sellDocument.hasItems) {
        this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
        return;
      }

      const {notificationsConnection} = useSignalR();

      const payment = this.configurationStore.createPayment(this.step?.paymentId);
      payment.setValue(this.sellDocument.header.total);
      this.sellDocument.addPayment(payment);

      if (this.printTemplateTypeOverride) {
        this.sellDocument.printTemplateType = new OutputType(this.printTemplateTypeOverride);
      }

      this.sellDocument.preflightSetup();

      const sellDocumentHeaderGUID = this.sellDocument.header.uniqueidentifier;

      let [{result}] = await notificationsConnection.addEventListenerWithTrigger(
        PrinterWSEvents.PROCESSED_DOC_MESSAGE,
        async (...args) => {
          const [
            {result, document} = {
              result: null,
              document: null,
            }, sellDocumentUniqueId,
          ] = args;

          if (sellDocumentUniqueId !== sellDocumentHeaderGUID) return false;

          const solvingResult = (await this.documentStatusStore.solve(result, document)).pop();

          Object.assign(result, solvingResult ?? result);

          return !!solvingResult;
        },
      )(async () => {
        return await apiDocumentCreate({
          input: this.sellDocument.toApiClone(),
        });
      });

      result = new PrinterResult(result ?? {});

      if (result.hasError) {
        throw new Error(result.errorMessage);
      }

      if (isActiveFeaturePrintDisplayOnScreen() && result.hasValidPrintContent) {
        await this.printContentStore.open(result.printContent);
      }

      this.stepFinished = true;

      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
    } catch (e) {
      console.error(e);
      this.messageBus.dispatchEvent(new CustomEvent(WorkflowStepEvents.ERROR, {
        detail: {
          type: WorkflowStepErrors.DOCUMENT_CREATE_FAILED,
          value: e,
        },
      }));
    } finally {
      emitTestEvent(`${TestEvent.WORKFLOW_METHOD_FINISHED}:balanceStockSellDocumentCreated`);
      this.messageBus.dispatchEvent(new Event(AppLoaderEvent.OFF));
    }
  }

  get transitions() {
    return {};
  }
}
