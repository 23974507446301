
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import PhIcon from '@/Components/PhIcon.vue';
import {useFilters} from '@/Helpers/filters';
import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import ShipmentNotificationListDto from '@/Model/Entity/ShipmentNotificationListDto';
import {DocumentTypes} from '@/constants/documentTypes';

export default defineComponent({
  components: {
    PhIcon,
  },
  props: {
    item: {
      type: Object as PropType<{
        isActive: boolean,
        entity: ShipmentNotificationListDto,
      }>,
      required: true,
    },
  },
  emits: ['select'],
  setup(props) {
    const configurationStore = useConfigurationStore();
    const {currencyFormat, dateFormat} = useFilters();

    const icon = computed(() => {
      switch (props.item.entity.documentSubType.value) {
      case DocumentTypes.GoodsTransferToPlant:
        return 'PhArrowsCounterClockwise';
      case DocumentTypes.GoodsReceiptFromPress:
        return 'PhNewspaper';
      default:
        return 'PhPackage';
      }
    });

    return {
      icon,
      dateFormat,
      currencyFormat: (val) => {
        return currencyFormat(val, configurationStore.configuration.value?.general?.localCurrency);
      },
    };
  },
});
