import {createEntity} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import FunctionButton from '../FunctionButton';


export default class GeneratedFunctionButtons extends createEntity({
  the1: {
    type: new EntityType(FunctionButton),
  },
  the2: {
    type: new EntityType(FunctionButton),
  },
  the3: {
    type: new EntityType(FunctionButton),
  },
  the4: {
    type: new EntityType(FunctionButton),
  },
  the5: {
    type: new EntityType(FunctionButton),
  },
  the6: {
    type: new EntityType(FunctionButton),
  },
  the7: {
    type: new EntityType(FunctionButton),
  },
  the8: {
    type: new EntityType(FunctionButton),
  },
}) {}
