import {
  computed,
  markRaw,
  ref,
} from 'vue';
import {
  get,
  isNil,
  isObject,
  map,
} from 'lodash-es';
import IconBackspace from '../Icons/IconBackspace.vue';
import IconEnterQwerty from '../Icons/IconEnterQwerty.vue';
import IconShift from '../Icons/IconShift.vue';
import IconGlobe from '../Icons/IconGlobe.vue';
import IconKeyboard from '../Icons/IconKeyboard.vue';
import {Languages} from '@/constants/languages';
import LanguageKey from './LanguageKey';

enum LayoutType {
  BASE = 'base',
  CAPS = 'caps',
  SPECIAL_CHARS = 'specialChars'
}

export default (defaultLayout, locale, emitter, {isPda = false} = {}) => {
  const keyboardLocale = ref(locale);
  const keyboardLayout = ref(defaultLayout);

  const delimiter = (classList = 'flex-grow lg:flex-grow-0 w-4') => ({
    delimiter: true,
    class: classList,
    slot: 'delimiter',
  });

  const changeLayout = (layout: LayoutType) => {
    keyboardLayout.value = layout;
  };

  const changeLocale = (locale: Languages) => {
    keyboardLocale.value = locale;
  };

  const toggleCaps = () => {
    switch (keyboardLayout.value) {
    case LayoutType.BASE:
      changeLayout(LayoutType.CAPS);
      return;
    case LayoutType.CAPS:
      changeLayout(LayoutType.BASE);
      return;
    case LayoutType.SPECIAL_CHARS:
      changeLayout(LayoutType.BASE);
      return;
    default:
      return;
    }
  };

  const emitKeyClick = (key) => {
    if (key.delimiter) return;

    emitter('click', key);
  };

  const shiftKey = {
    class: 'qwerty-key--muted',
    key: 'Shift',
    icon: IconShift,
    onClick: () => toggleCaps(),
    slot: 'shift',
  };

  const shiftKeyWide = {
    ...shiftKey,
    class: 'qwerty-key--wide qwerty-key--muted',
  };

  const spaceKey = {
    class: 'qwerty-key--untied w-full',
    key: ' ',
    slot: 'space',
  };

  const emptyKey = {
    class: 'qwerty-key--muted',
    onClick: () => {
      return;
    },
    slot: 'empty',
  };

  const enterKey = {
    class: 'qwerty-key--wide qwerty-key--muted',
    key: 'Enter',
    icon: IconEnterQwerty,
    slot: 'enter',
  };

  const enterKeyShort = {
    ...enterKey,
    class: 'qwerty-key--muted',
  };

  const backSpaceKey = {
    class: 'qwerty-key--muted',
    key: 'Backspace',
    icon: IconBackspace,
    slot: 'backspace',
  };

  const changeLang = (nextLocale: Languages, currentLocale: Languages) => ({
    class: 'qwerty-key--untied qwerty-key--muted',
    icon: LanguageKey(currentLocale),
    onClick: () => changeLocale(nextLocale),
    slot: 'language-toggle',
  });

  const changeLayoutKey = (layout: LayoutType) => ({
    class: 'qwerty-key--untied qwerty-key--muted qwerty-key--layout-toggle',
    key: '&123',
    onClick: () => changeLayout(layout),
    slot: 'layout-toggle',
  });

  const keyboardKey = {
    class: 'qwerty-key--untied qwerty-key--muted',
    icon: IconKeyboard,
    onClick: () => emitter('hide'),
    slot: 'ctrl',
  };

  const createLayout = (layout) => {
    return map(layout, (row) => {
      return map(row, (key) => {
        if (isNil(key)) {
          return {
            ...delimiter(),
            onClick: () => {},
          };
        }

        if (isObject(key) as any) {
          return {
            ...key,
            onClick: key.onClick ?? (() => emitKeyClick(key)),
          };
        }

        return {
          delimiter: false,
          key: key,
          slot: key,
          onClick: () => emitKeyClick({key}),
        };
      });
    });
  };

  /* eslint-disable */
  // Desktop Layouts
  const czechLayout = createLayout(markRaw([
    ['+', 'ě', 'š', 'č', 'ř', 'ž', 'ý', 'á', 'í', 'é', '='],
    ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', backSpaceKey],
    [delimiter(), 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', enterKey],
    [shiftKey, 'y', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', shiftKey],
    [changeLang(Languages.SK, Languages.CS), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const czechLayoutCaps = createLayout(markRaw([
    ['+', 'Ě', 'Š', 'Č', 'Ř', 'Ž', 'Ý', 'Á', 'Í', 'É', '='],
    ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P', backSpaceKey],
    [delimiter(), 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', enterKey],
    [shiftKey, 'Y', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', shiftKey],
    [changeLang(Languages.SK, Languages.CS), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const slovakLayout = createLayout(markRaw([
    ['+', 'ľ', 'š', 'č', 'ť', 'ž', 'ý', 'á', 'í', 'é', '='],
    ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', backSpaceKey],
    [delimiter(), 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', enterKey],
    [shiftKey, 'y', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', shiftKey],
    [changeLang(Languages.EN, Languages.SK), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const slovakLayoutCaps = createLayout(markRaw([
    ['+', 'Ľ', 'Š', 'Č', 'Ť', 'Ž', 'Ý', 'Á', 'Í', 'É', '='],
    ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P', backSpaceKey],
    [delimiter(), 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', enterKey],
    [shiftKey, 'Y', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', shiftKey],
    [changeLang(Languages.EN, Languages.SK), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const germanLayout = createLayout(markRaw([
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'ß'],
    ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'ü', backSpaceKey],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä', enterKeyShort],
    [shiftKeyWide, 'y', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', shiftKey],
    [changeLang(Languages.CS, Languages.DE), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const germanLayoutCaps = createLayout(markRaw([
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'ß'],
    ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P', 'Ü', backSpaceKey],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ö', 'Ä', enterKeyShort],
    [shiftKeyWide, 'Y', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', shiftKey],
    [changeLang(Languages.CS, Languages.DE), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const englishLayout = createLayout(markRaw([
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-'],
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', backSpaceKey],
    [delimiter(), 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', enterKey],
    [shiftKey, 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', shiftKey],
    [changeLang(Languages.DE, Languages.EN), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const englishLayoutCaps = createLayout(markRaw([
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-'],
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', backSpaceKey],
    [delimiter(), 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', enterKey],
    [shiftKey, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.', shiftKey],
    [changeLang(Languages.DE, Languages.EN), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, emptyKey, keyboardKey],
  ]))

  const specialCharsLayout = createLayout(markRaw([
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', backSpaceKey],
    [delimiter(), '-', '/', ':', ';', '(', ')', '€', '&', '@', enterKey],
    [shiftKey, '.', ',', '?', '!', '`', '%', '=', '^', '#', shiftKey],
    [emptyKey, changeLayoutKey(LayoutType.BASE), spaceKey, emptyKey, keyboardKey],
  ]))

  // PDA Layouts
  const pdaCzechLayout = createLayout(markRaw([
    ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p'],
    [delimiter(), 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    [shiftKey, 'y', 'x', 'c', 'v', 'b', 'n', 'm', backSpaceKey],
    [changeLang(Languages.SK, Languages.CS), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaCzechLayoutCaps = createLayout(markRaw([
    ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P'],
    [delimiter(), 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    [shiftKey, 'Y', 'X', 'C', 'V', 'B', 'N', 'M', backSpaceKey],
    [changeLang(Languages.SK, Languages.CS), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaSlovakLayout = createLayout(markRaw([
    ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p'],
    [delimiter(), 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    [shiftKey, 'y', 'x', 'c', 'v', 'b', 'n', 'm', backSpaceKey],
    [changeLang(Languages.EN, Languages.SK), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaSlovakLayoutCaps = createLayout(markRaw([
    ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P'],
    [delimiter(), 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    [shiftKey, 'Y', 'X', 'C', 'V', 'B', 'N', 'M', backSpaceKey],
    [changeLang(Languages.EN, Languages.SK), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaGermanLayout = createLayout(markRaw([
    ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'ü'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
    [shiftKey, 'y', 'x', 'c', 'v', 'b', 'n', 'm', backSpaceKey],
    [changeLang(Languages.CS, Languages.DE), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaGermanLayoutCaps = createLayout(markRaw([
    ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P', 'Ü'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ö', 'Ä'],
    [shiftKey, 'Y', 'X', 'C', 'V', 'B', 'N', 'M', backSpaceKey],
    [changeLang(Languages.CS, Languages.DE), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaEnglishLayout = createLayout(markRaw([
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    [delimiter(), 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    [shiftKey, 'z', 'x', 'c', 'v', 'b', 'n', 'm', backSpaceKey],
    [changeLang(Languages.DE, Languages.EN), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))

  const pdaEnglishLayoutCaps = createLayout(markRaw([
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    [delimiter(), 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    [shiftKey, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', backSpaceKey],
    [changeLang(Languages.DE, Languages.EN), changeLayoutKey(LayoutType.SPECIAL_CHARS), spaceKey, enterKey],
  ]))
  /* eslint-enable */

  const layouts = {
    [Languages.CS]: {
      [LayoutType.BASE]: isPda ? pdaCzechLayout : czechLayout,
      [LayoutType.CAPS]: isPda ? pdaCzechLayoutCaps : czechLayoutCaps,
      [LayoutType.SPECIAL_CHARS]: specialCharsLayout,
    },
    [Languages.SK]: {
      [LayoutType.BASE]: isPda ? pdaSlovakLayout : slovakLayout,
      [LayoutType.CAPS]: isPda ? pdaSlovakLayoutCaps : slovakLayoutCaps,
      [LayoutType.SPECIAL_CHARS]: specialCharsLayout,
    },
    [Languages.DE]: {
      [LayoutType.BASE]: isPda ? pdaGermanLayout : germanLayout,
      [LayoutType.CAPS]: isPda ? pdaGermanLayoutCaps : germanLayoutCaps,
      [LayoutType.SPECIAL_CHARS]: specialCharsLayout,
    },
    [Languages.EN]: {
      [LayoutType.BASE]: isPda ? pdaEnglishLayout : englishLayout,
      [LayoutType.CAPS]: isPda ? pdaEnglishLayoutCaps : englishLayoutCaps,
      [LayoutType.SPECIAL_CHARS]: specialCharsLayout,
    },
  };

  const currentLayout = computed(() => get(
    layouts,
    [keyboardLocale.value, keyboardLayout.value],
    layouts.cs.base,
  ));

  return {
    keyboardLocale,
    keyboardLayout,
    currentLayout,
    layouts,
    delimiter,
  };
};
