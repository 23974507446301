import {createEntity} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import DocumentAttachmentType from '../DocumentAttachmentType';
import {StringType} from '@designeo/apibundle-js';


export const DocumentAttachmentDtoPropDef = {
  data: {
    type: new ObjectType(),
  },
  type: {
    type: new EntityType(DocumentAttachmentType),
  },
  name: {
    type: new StringType(),
  },
};
export default class GeneratedDocumentAttachmentDto extends createEntity(DocumentAttachmentDtoPropDef) {}
