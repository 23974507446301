import {createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ObjectType} from '@designeo/apibundle-js';


export default class GeneratedBarcodeFormat extends createEntity({
  mask: {
    type: new StringType(),
  },
  parts: {
    type: new ObjectType(),
  },
}) {}
