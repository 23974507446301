import {action} from '@designeo/vue-helpers';
import {WorkflowStep} from '@/Modules/Workflow/Workflow/WorkflowStep';
import {
  WorkflowActions,
  WorkflowInputEvent,
  WorkflowStepEvents,
  WorkflowStepField,
  WorkflowStepTypes,
} from '@/Modules/Workflow/types';
import {markRaw} from 'vue';
import {workflowStepMixinKorunka} from '../StepMixins/WorkflowStepMixinKorunka';
import resolveDepositComponent from './Components/resolve';
import {Currencies} from '@/constants/currencies';
import * as yup from 'yup';
import {has} from 'lodash-es';
import {useFilters} from '@/Helpers/filters';

export class WorkflowStepKorunkaLotteryDeposit extends workflowStepMixinKorunka(WorkflowStep) {
  static get type() {
    return WorkflowStepTypes.KorunkaDeposit;
  }

  get type() {
    return WorkflowStepKorunkaLotteryDeposit.type;
  }

  get component() {
    return markRaw(require('./StepKorunkaLotteryDeposit.vue').default);
  }

  get gameComponent() {
    return resolveDepositComponent(this.korunkaType);
  }

  get canBeReturnedTo() {
    return !this.isRepeatBetTicket && !this.isTicketFromScan && !this.isPrepared;
  }

  get boosterDeposit(): ({value: number, currency: Currencies} | null) {
    return this.step?.boosterDeposit ?? null;
  }

  get depositOptions(): ({id: number, label: string, value: number, currency: Currencies}[]) {
    const {currencyFormat} = useFilters();

    if (this.isGameDvacetZa20Entity(this.entity) && this.entity.booster) {
      return [
        {
          id: -1,
          label: currencyFormat(
            this.boosterDeposit.value,
            Currencies.CZK,
            {maximumFractionDigits: 0, minimumFractionDigits: 0},
          ),
          value: this.boosterDeposit.value,
          currency: this.boosterDeposit.currency,
        },
      ];
    }

    return this.step?.depositOptions ?? [];
  }

  get validator(): yup.AnyObjectSchema {
    if (!this.customDepositEnabled) {
      return super.validator;
    }

    if (!this.customDeposit) {
      return super.validator;
    }

    let fieldValidator = yup.number()
      .transform((value) => {
        return Number.isNaN(value) ? undefined : value;
      })
      .required('validations.mixed.required');

    if (!this.step.customDepositValidationsByValue) {
      return yup.object().shape({
        [WorkflowStepField.customDepositValue]: fieldValidator,
      });
    }

    const betNumbers = this.isGameHopTropEntity(this.entity) ? this.entity.randomNumbers.length : null;

    if (!betNumbers) {
      return yup.object().shape({
        [WorkflowStepField.customDepositValue]: fieldValidator,
      });
    }

    if (has(this.step.customDepositValidationsByValue, [betNumbers, 'min'])) {
      fieldValidator = fieldValidator.min(
        this.step.customDepositValidationsByValue[betNumbers].min,
        'validations.number.min',
      );
    }

    if (has(this.step.customDepositValidationsByValue, [betNumbers, 'max'])) {
      fieldValidator = fieldValidator.max(
        this.step.customDepositValidationsByValue[betNumbers].max,
        'validations.number.max',
      );
    }

    return yup.object().shape({
      [WorkflowStepField.customDepositValue]: fieldValidator,
    });
  }

  get customDepositEnabled(): boolean {
    return this.step?.enableCustomDeposit ?? false;
  }

  get customDeposit() {
    return this.getFieldValue(WorkflowStepField.customDepositToggle, false);
  }

  set customDeposit(value: boolean) {
    this.dataSetter(WorkflowStepField.customDepositToggle, () => value);
  }

  get customDepositValue(): number {
    return parseInt(this.getFieldValue(WorkflowStepField.customDepositValue, '0'));
  }

  get disabledNextStep() {
    if (
      this.isTicketEntity(this.entity) ||
      this.isScanTicketEntity(this.entity) ||
      this.isCardEntity(this.entity) ||
      this.isPreparedTicketEntity(this.entity) ||
      this.isScanResultEntity(this.entity)
    ) return false;

    if (!this.customDeposit) {
      return !this.entity.deposit;
    }

    if (Number.isNaN(this.customDepositValue)) {
      return true;
    }

    return this.customDepositValue === 0;
  }

  async beforeEnter() {
    // NOTE: reset validation errors on re-enter
    this.validationError = {};

    this.ensureCustomerDisplayKorunkaPage();

    if (this.isRepeatBetTicket) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.isTicketFromScan) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    if (this.isPrepared) {
      this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
      return;
    }

    this.preselectFirstDepositOption();

    await this.syncBasicStepStateToCustomerDisplay();
    await this.syncEntityToCustomerDisplay();
  }

  preselectFirstDepositOption() {
    if (
      this.isTicketEntity(this.entity) ||
      this.isCardEntity(this.entity) ||
      this.isScanTicketEntity(this.entity) ||
      this.isPreparedTicketEntity(this.entity) ||
      this.isScanResultEntity(this.entity)
    ) return;

    const firstDepositOption = this.depositOptions?.[0];

    if (!this.entity.deposit && firstDepositOption) {
      this.entity.deposit = firstDepositOption;
    }
  }

  async beforeContinue() {
    /**
     * TODO change condition to this.isGame(this.entity) ?
     */
    if (this.customDeposit && this.isGameHopTropEntity(this.entity)) {
      this.entity.deposit = {id: -1, value: this.customDepositValue, currency: Currencies.CZK};
    }
  }

  async submit() {
    if (!await this.validate()) return;

    this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.NEXT));
  }

  get transitions() {
    return {
      ...this.withFieldActions(WorkflowStepField.customDepositValue, (fieldActions) => ({
        [WorkflowActions.CANCEL]: action(() => {
          this.messageBus.dispatchEvent(new Event(WorkflowStepEvents.PREV));
        }),
        [WorkflowActions.ADD_NUMBER]: action((event: WorkflowInputEvent<string>) => {
          fieldActions[WorkflowActions.ADD_NUMBER](event.value);
        }),
        [WorkflowActions.BACKSPACE]: action(() => {
          fieldActions[WorkflowActions.BACKSPACE]();
        }),
        [WorkflowActions.ENTER]: action(async () => {
          await this.submit();
        }),
      })),
    };
  }
}
