
import {
  defineComponent,
} from 'vue';
import {WorkflowStepField, WorkflowStepTypes} from '@/Modules/Workflow/types';
import {HLStep, HLInput} from '@/Modules/Workflow/Step/StepTools';
import InputBuffer from '@/Components/InputBuffer.vue';
import FormInput from '@/Components/FormInput.vue';
import InputSelect from '@/Components/InputSelect.vue';
import OrderDetail from '../StepEshopOrderOverview/OrderDetail.vue';
import IconLoaderPulse from '@/Components/Icons/IconLoaderPulse.vue';
import WorkflowTransitions from '../../Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepGenericSuccess',
  components: {
    HLStep,
    InputBuffer,
    HLInput,
    FormInput,
    InputSelect,
    OrderDetail,
    IconLoaderPulse,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.EshopOrderStornoReason;

    return {
      stepType,
      WorkflowStepField,
    };
  },
});
