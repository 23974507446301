import {BooleanType, createEntity} from '@designeo/apibundle-js';
import {StringType} from '@designeo/apibundle-js';
import {ArrayType} from '@designeo/apibundle-js';
import {EntityType} from '@designeo/apibundle-js';
import PosAttribute from '../PosAttribute';
import Shop from '../Shop';


export default class GeneratedPos extends createEntity({
  code: {
    type: new StringType(),
  },
  id: {
    type: new StringType(),
  },
  registrationId: {
    type: new StringType(),
  },
  name: {
    type: new StringType(),
  },
  posAttributes: {
    type: new ArrayType(new EntityType(PosAttribute)),
  },
  shop: {
    type: new EntityType(Shop),
  },
  isActive: {
    type: new BooleanType(),
  },
}) {}
