import {useConfigurationStore} from '@/Modules/Core/store/ConfigurationStore';
import {
  DocumentDto,
  DocumentItemDto,
  DocumentLoyaltyDto,
} from '@/Model/Entity';
import {
  cloneDeep,
  filter,
  mapValues,
} from 'lodash-es';
import {createPromoEngineWorker} from './createPromoEngineWorker';
import {apiV1PosPromotionsBulk} from '@/Model/Action';

import {
  createConfigureStore,
  createUseStore,
} from '@designeo/vue-helpers';
import PromotionBulkDto from '../../../Model/Entity/PromotionBulkDto';
import {toRaw} from 'vue';

export enum PromoEngineEvent {
  NEW_ARTICLE='NEW_ARTICLE',
  REQUESTING_PAYMENT='REQUESTING_PAYMENT',
  NEW_BON='NEW_BON',
}

interface PromoEngineOptions {
  noConfig: boolean,
}

export class PromoEngine {
  appId: any

  public promotionEngine: import('comlink').Remote<import('@designeo/pos-promotion-engine').PromotionEngine>
  private noConfig: boolean
  public enableLogs: boolean
  public promotionBulk: any

  constructor(private configuration: Partial<PromoEngineOptions> = {}) {
    this.noConfig = configuration.noConfig ?? false;
    this.enableLogs = typeof (localStorage) === 'undefined' ? false :
      localStorage.getItem('PROMO_ENGINE_LOGS_ENABLED') === 'true';
  }

  async updateConfiguration(promotionBulk: PromotionBulkDto = null) {
    try {
      this.promotionBulk = promotionBulk ?? this.promotionBulk;
      const promotionEngine = await this.getPromoEngineWorker();
      const configurationStore = useConfigurationStore();
      const mainClub = configurationStore.mainClubCode.value;
      const smallestNominal = Math.min(...configurationStore.localCurrency.value.nominals);
      const alwaysShowClubCodes = [];
      if (mainClub) {
        alwaysShowClubCodes.push(mainClub);
      }

      const language = configurationStore.configuration.value.general.language.value?.toLowerCase();
      const translations = mapValues(configurationStore.configuration.value.translations, (x) => {
        return cloneDeep(x.promotionEngine ?? {});
      });

      await promotionEngine.setOptions({
        smallestNominal,
        bonDiscount: {
          requiredRemainderAfterDiscount: smallestNominal,
          ...toRaw(configurationStore.configuration.value.promoEngine?.bonDiscount),
        },
        pointsBurningDiscount: {
          ...toRaw(configurationStore.configuration.value.promoEngine?.pointsBurningDiscount),
        },
        itemDiscount: {
          ...toRaw(configurationStore.configuration.value.promoEngine?.itemDiscount),
        },
        loyaltyPoints: {
          alwaysShowClubCodes,
          ...toRaw(configurationStore.configuration.value.promoEngine?.loyaltyPoints),
        },
        // @ts-ignore
        language,
        translations,
      });

      if (this.noConfig) {
        await promotionEngine.configure([], {});
        return;
      }

      const now = new Date();

      const validDefinitions = filter(this.promotionBulk.definition, ({validFrom, validTill}) => {
        return validFrom <= now && now <= validTill;
      });

      await promotionEngine.configure(
        [...validDefinitions],
        {...this.promotionBulk.articleGroups},
      );
    } catch (err) {
      console.warn('Failed to load promotions', err);
    }
  }

  async getPromoEngineWorker() {
    if (!this.promotionEngine) {
      this.promotionEngine = createPromoEngineWorker();
      await this.updateConfiguration();
    }
    return this.promotionEngine;
  }

  async processDocument(
    document: DocumentDto,
    event: PromoEngineEvent,
    {
      currentArticle = null,
    }: {
      currentArticle?: DocumentItemDto
    } = {},
  ) {
    const promotionEngine = await this.getPromoEngineWorker();
    document = document.clone();
    // clean up unknown promotions (these are for duplicate detection and will happend again in next run)
    document.promotions = (document.promotions ?? [])
      .filter((promo) => !('promoCode' in promo) || promo?.promoCode !== '$UNKNOWN');
    if (this.enableLogs) {
      // eslint-disable-next-line no-console
      console.log('[PROMO] input', document.toJson(), {currentArticle: currentArticle?.toJson()});
      // eslint-disable-next-line no-console
      console.time('[PROMO] evaluated in');
    }
    // @ts-ignore
    const result = await promotionEngine.evaluate(
      cloneDeep(document.toJson()),
      {
        currentArticle: currentArticle ? cloneDeep(currentArticle.toJson()) : undefined,
      },
    );
    if (this.enableLogs) {
      // eslint-disable-next-line no-console
      console.timeEnd('[PROMO] evaluated in');
      // eslint-disable-next-line no-console
      console.log('[PROMO] result', JSON.parse(JSON.stringify(result)));
    }
    const promotions = result.promotions;
    document = new DocumentDto(result);
    document.refreshTotalPrice();

    return {
      document, // this will recreate document from promo engine result
      promotions,
    };
  }
}

const symbol = 'PromoEngine';

export const configurePromoEngine = createConfigureStore<typeof PromoEngine>(symbol);
export const usePromoEngine = createUseStore(PromoEngine, symbol);
