import {includes} from 'lodash-es';
import {
  TransformStream,
} from '../streams';
import {KeyPress} from '@/Modules/Register/services/KeyboardBuffer';


const ignoredKeys = [
  'Shift',
  'Alt',
  'Control',
  'NumLock',
];

function isIgnoredKey(key: KeyPress) {
  return key.dir === 'up' ||
    includes(ignoredKeys, key.key);
}

function isShortcutKey(key: KeyPress) {
  return key.ctrl && !key.alt;
}

export function createKeyPressTransform() {
  return new TransformStream<KeyPress, KeyPress>({
    transform(chunk, controller) {
      if (isIgnoredKey(chunk) || isShortcutKey(chunk)) {
        return;
      }
      const key = {...chunk};
      delete key.dir;
      controller.enqueue(key);
    },
  });
}
