import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between pt-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsyncButton = _resolveComponent("AsyncButton")!
  const _component_IconCheck = _resolveComponent("IconCheck")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "transitionBack", _normalizeProps(_guardReactiveProps({goBack: _ctx.goBack, previousHidden: _ctx.previousHidden, previousDisabled: _ctx.previousDisabled})), () => [
      (_ctx.previousHidden)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (_openBlock(), _createBlock(_component_AsyncButton, {
            key: 1,
            test: 'wfTransitionsBack',
            onClick: _ctx.goBack,
            disabled: _ctx.previousDisabled,
            class: _normalizeClass(["py-2.5 px-7", [_ctx.previousDisabled ? 'btn-basic-light' : 'btn-danger']])
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentWorkflow.isActiveStepFirst ? 'IconCross' : 'IconUArrowLeft'), { class: "inline h-3" })),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.currentWorkflow.isActiveStepFirst ? 'workflow.stepUI.cancel' : 'action.back')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled", "class"]))
    ]),
    _renderSlot(_ctx.$slots, "transitionNext", _normalizeProps(_guardReactiveProps({goNext: _ctx.goNext, nextHidden: _ctx.nextHidden, nextDisabled: _ctx.nextDisabled})), () => [
      (_ctx.nextHidden)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : (_openBlock(), _createBlock(_component_AsyncButton, {
            key: 1,
            test: 'wfTransitionsNext',
            onClick: _ctx.goNext,
            disabled: _ctx.nextDisabled,
            class: _normalizeClass(["py-2.5 px-7", [_ctx.nextDisabled ? 'btn-basic-light' : 'btn-success']])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconCheck, { class: "inline h-3" }),
              _createTextVNode(" " + _toDisplayString(_ctx.currentWorkflow.isActiveStepLast ? _ctx.$t('workflow.stepUI.finish') : _ctx.$t('workflow.stepUI.continue')), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disabled", "class"]))
    ])
  ]))
}