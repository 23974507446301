
import {
  defineComponent,
} from 'vue';
import {HLStep} from '@/Modules/Workflow/Step/StepTools';
import {WorkflowStepTypes} from '../../types';
import WorkflowTransitions from '@/Modules/Workflow/Workflow/WorkflowTransitions.vue';

export default defineComponent({
  name: 'StepKorunkaLotteryNumbers',
  components: {
    HLStep,
    WorkflowTransitions,
  },
  setup() {
    const stepType = WorkflowStepTypes.KorunkaNumbers;

    return {
      stepType,
    };
  },
});
