import routes from './routes';
import LayoutWithoutHeader from '../../Layout/LayoutWithoutHeader.vue';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('../../ModuleSystem').ModuleSystem & {
  appId: any,
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export async function registerPosConfiguration(ctx: RequiredContext) {
  await ctx.registerLayout('posConfiguration', {
    path: '/',
    component: LayoutWithoutHeader,
    children: [],
  });

  await ctx.registerRoutes(routes, {
    layout: 'posConfiguration',
  });

  return {};
}
