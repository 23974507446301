import {
  EntityType,
  ObjectType,
  StringType,
} from '@designeo/apibundle-js';
import {DocumentDto} from '@/Model/Entity';
import {MergeCtor, MixinBase} from '@/Helpers/mixins';
import {sumBy} from 'lodash-es';

export const WarehouseOperationBaseAttributes = {
  operation: {
    type: new StringType(),
  },
};

export const WarehouseOperationLogisticDocumentAttributes = {
  logisticDocument: {
    type: new EntityType(DocumentDto),
  },
};

export const WarehouseOperationSummaryAttributes = {
  summary: {
    type: new ObjectType(),
  },
};

export const WarehouseOperationFilterAttributes = {
  filters: {
    type: new ObjectType(),
  },
};

export const WarehouseOperationPatch = <TBase extends MixinBase<{
  logisticDocument: DocumentDto;
}>>(superClass: TBase) => {
  const Derived = class WarehouseOperationPatch extends (superClass as MixinBase<{
    logisticDocument: DocumentDto;
  }>) {
    get totalPrice() {
      return sumBy(this.logisticDocument.logisticItems, (item) => item.quantity * item.price);
    }
  };

  return Derived as MergeCtor<typeof Derived, TBase>;
};
